import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    StyleSheet, View, ScrollView, Text,
} from 'react-native';

import { connect } from 'react-redux';
import ga from 'react-ga';
import get from 'lodash/get';
import Spinner from 'components/Spinner';
import { Snackbar } from '@material-ui/core';

import {
  signup, naverLogin, createNaverAccessToken, getNaverProfile,
  logEventPageview,
} from 'react-shared/actions';

import { Constants, Styles } from '../../constants';
import { TouchableHighlightWithContainer } from '../../components-v2';

import JoinForm from './JoinForm';
import { showLegalPolicy } from './LegalPolicyOverlayPage';
import { runAfterLoginDone } from '../../containers/auth/util';


const styles = StyleSheet.create({
  container: {
  },
  listContainer: {
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  bottomButtonContainer: {
    position: 'fixed',
    start: 0,
    end: 0,
    bottom: 0,
    backgroundColor: '#000',
    flexDirection: 'row',
  },
  buttonContainerStyle: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 20,
    paddingBottom: 12 + Constants.bottomMargin,
  },
  bottomButtonText: {
    textAlign: 'center',
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    color: '#fff',
    opacity: 0.5,
  },
  bottomButtonTextEnabled: {
    opacity: 1,
  },
});


class NaverCallbackPage extends Component {
  constructor(props) {
    super(props);
    this.inputForm = React.createRef();
    this.username = React.createRef();
    this.naverAccessToken = React.createRef();

    this.handleSignup = this.handleSignup.bind(this);

    this.state = {
      showSignup: false,
      snackbarMsg: '',
      snackbarOpen: false,
    };
  }

  componentDidMount() {
    const {
      code, state, router,
      createNaverAccessToken: createNaverAccessTokenFromProps,
      logEventPageview,
    } = this.props;

    if (!code) {
      router.push('/');
    }

    createNaverAccessTokenFromProps({ code, state })
      .then(resp => {
        if (resp.success) {
          this.handleLogin(resp.access_token);
        } else {
          alert('네이버 로그인에 실패했습니다. 잠시 후 다시 시도하세요.');
          this.props.router.push('/accounts/login');
        }
      });

    logEventPageview({ page_name: 'NaverCallback' });
  }

  showSnackbar = msg => {
    this.setState({
      snackbarMsg: msg,
      snackbarOpen: true,
    });
  }

  handleSignup() {
    const { name, email, phone, phoneVerified, password, birthYear,
      termAgreed, privacyAgreed, marketingAgreed, naverAccessToken } = this.state;
    const username = email;

    $('.edit-profile-save-btn').attr('disabled', true);

    this.props.signup({
      username,
      email,
      name,
      tel: phone,
      termAgreed,
      privacyAgreed,
      marketingAgreed,
      birthYear,
      naverAccessToken,
    }).then(() => {
      ga.event({
        category: 'SignUp',
        action: 'SignUp',
        label: 'Success',
      });

      // Facebook Pixel event 전송
      fbq('track', 'CompleteRegistration', {
        content_name: 'naver',
        currency: 'KRW',
        status: true,
        value: 0,
      });

      this.props.router.push('/accounts/join/done');
    }, () => {
      this.props.router.push('/accounts/login');
      ga.event({
        category: 'SignUp',
        action: 'SignUp',
        label: 'Fail',
      });
    });
    return true;
  }

  handleLogin = accessToken => this.props.naverLogin(accessToken).then(
    () => {
      runAfterLoginDone(this.props.router);
    },
    err => {
      if (err.status === 404) {
        this.props.getNaverProfile({ accessToken })
          .then(response => {
            this.setState({
              showSignup: true,
              naverAccessToken: accessToken,
              // naverId: response.id,
              email: response.email,
              username: response.email,
              name: response.name,
            });
          })
          .catch(err2 => {
            // eslint-disable-next-line no-console
            console.error(err2);
            alert('네이버 로그인에 실패했습니다. 잠시 후 다시 시도하세요.');
            this.props.router.push('/accounts/login');
          });
      } else if (err.status === 400) {
        alert('이미 가입되어 있는 이메일 계정입니다.');
        this.props.router.push('/accounts/login');
      } else {
        alert('네이버 로그인에 실패했습니다. 잠시 후 다시 시도하세요.');
        this.props.router.push('/accounts/login');
      }
    }
  )

  onChangeInputData(inputData) {
    let isEnabledJoinButton = false;

    // console.log(`onChangeInputData ${JSON.stringify(inputData)}`);
    if (inputData.email
      && inputData.phone
      && inputData.phoneVerified
      && inputData.termAgreed
      && inputData.privacyAgreed) {
      isEnabledJoinButton = true;
    }

    this.setState({ 
      ...inputData,
      isEnabledJoinButton
    });
  }

  renderInputForm() {
    const { router } = this.props;
    const { email, name } = this.state;
    return (
      <JoinForm
        router={router}
        onRef={ref => { this.joinForm = ref; }}
        onChange={inputData => this.onChangeInputData(inputData)}
        onPressTerms={type => {
          showLegalPolicy(this, type);
        }}
        editableEmail={false}
        email={email}
        name={name}
        disablePassword
        disableName
        />
    );
  }

  renderBottomButton() {
    const { isEnabledJoinButton } = this.state;
    let onPress = null;

    const bottomButtonContainerStyles = [
      styles.bottomButtonContainer
    ];

    const buttonStyles = [
      styles.bottomButtonText,
    ];

    if (isEnabledJoinButton) {
      buttonStyles.push(styles.bottomButtonTextEnabled);
      onPress = () => {
        if (!this.joinForm.validateInput()) {
          return;
        }

        this.handleSignup();
      };
    }

    return (
      <View style={bottomButtonContainerStyles}>
        <TouchableHighlightWithContainer
          containerStyle={styles.buttonContainerStyle}
          onPress={onPress}
          >
          <Text style={buttonStyles}>가입하기</Text>
        </TouchableHighlightWithContainer>
      </View>
    );
  }

  render() {
    const { showSignup } = this.state;
    if (!showSignup) {
      return <Spinner />;
    }

    const { showSmartBanner } = this.props;

    return (
      <View style={styles.container}>
        <ScrollView
          bounces={false}
          style={[styles.listContainer, showSmartBanner && { paddingBottom: Constants.smartBannerHeight }]}
        >
          {this.renderInputForm()}
        </ScrollView>
        {this.renderBottomButton()}
        <Snackbar
          autoHideDuration={2000}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
          style={{ textAlign: 'center' }}
        />
      </View>
    );
  }
}

NaverCallbackPage.propTypes = {
  code: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  signup: PropTypes.func.isRequired,
  naverLogin: PropTypes.func.isRequired,
  createNaverAccessToken: PropTypes.func.isRequired,
};

export default connect(
  (state, ownProps) => {
    const { params } = ownProps;

    return {
      code: get(ownProps.location, 'query.code'),
      state: get(ownProps.location, 'query.state'),
    };
  },
  { signup, naverLogin, createNaverAccessToken, getNaverProfile, logEventPageview }
)(NaverCallbackPage);
