import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadEntities } from 'react-shared';
import { Link } from 'react-router';
import {
  Button,
  Checkbox,
  Dialog,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core';
import { compose } from 'recompose';

import Spinner from 'components/Spinner';
import PropTypes from 'prop-types';
import * as adminActions from 'react-shared/adminActions';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
  container: {
    width: 960,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  table: {
    width: '100%',
    padding: 5,
  },
  tableCellCategory: {
    width: 150,
    maxWidth: 150,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tableCellName: {
    width: 650,
    maxWidth: 650,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  pageTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

export class FAQListPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { adminLoadFaqs } = this.props;
    adminLoadFaqs();
  }

  onButtonDelete(faq) {
    const { adminDeleteFaq } = this.props;

    const result = confirm(`이 FAQ를 삭제하시겠습니까?\n${faq.subject}`);
    if (result) {
      adminDeleteFaq({ faqId: faq._id });
    }
  }

  renderButtonEdit(faq) {
    return (
      <Link to={`/admin/faq/${faq._id}`}>
        <IconButton
          variant='contained'
          color='inherit'
          aria-label='Edit product'
        >
          <EditIcon />
        </IconButton>
      </Link>
    );
  }

  renderButtonDelete(product) {
    return (
      <IconButton
        variant='contained'
        color='secondary'
        aria-label='Delete product'
        onClick={() => { this.onButtonDelete(product); }}
      >
        <DeleteIcon />
      </IconButton>
    );
  }

  render() {
    const { classes, adminFaqs } = this.props;

    return (
      <div className={classes.container}>
        <Typography variant="h5" className={classes.pageTitle}>
          FAQ
        </Typography>
        <Link to={'/admin/faq/new'}>
          <Button
            variant='contained'
            color='default'
            className={classes.button}
            startIcon={<AddIcon />}
          >
            FAQ 추가
          </Button>
        </Link>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellCategory}>카테고리</TableCell>
              <TableCell className={classes.tableCellName}>제목</TableCell>
              <TableCell className=''></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adminFaqs.map(faq => (
              <TableRow key={`product_${faq._id}`}>
                <TableCell className={classes.tableCellCategory}>{faq.category}</TableCell>
                <TableCell className={classes.tableCellName}>{faq.subject}</TableCell>
                <TableCell>
                  {this.renderButtonEdit(faq)}
                  {this.renderButtonDelete(faq)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(state => ({
    ...loadEntities(state, 'adminFaqs', 'adminFaqs'),
  }), adminActions),
)(FAQListPage);
