import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, Text, View, TouchableHighlight } from 'react-native';
import NumberFormat from 'react-number-format';

import CustomDialog from '../components/CustomDialog';

import config from '../config';
import Colors from '../constants/Colors';
import Styles from '../constants/Styles';
import Constants from '../constants/Constants';

const containerSpacer = 10;
const buttonSpacer = 5;
const styles = StyleSheet.create({
  orderContainer: {
    paddingVertical: 20,
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  itemContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  stateContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: containerSpacer,
  },
  state: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 18,
    color: 'black',
    height: 21,
    lineHeight: 21,
  },
  orderDate: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 12,
    color: 'black',
    height: 15,
    lineHeight: 15,
  },
  itemInfoContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  itemImageContainer: {
    marginRight: 10,
    justifyContent: 'center',
  },
  itemInfoButtonContainer: {
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  itemInfoTextContainer: {
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  itemNameSuffixContainer: {
    flexDirection: 'row',
    marginTop: 4,
    height: 17,
    justifyContent: 'flex-start',
  },
  itemNameContainer: {
    flexShrink: 1,
  },
  itemName: {
    color: '#000',
    ...Styles.appleSDGothicNeoSemiBold,
    fontSize: 14,
    lineHeight: 17,
  },
  itemSuffixContainer: {
    marginLeft: 4,
  },
  itemNameSuffix: {
  },
  itemPrice: {
    color: '#000',
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 14,
    marginTop: 5,
    height: 17,
    lineHeight: 17,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: buttonSpacer * -1,
  },
  button: {
    flex: 1,
    height: 38,
    marginHorizontal: buttonSpacer,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.semiGray,
  },
  buttonText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 14,
    color: 'black',
    height: 17,
    marginTop: 1,
  },
});

const stylesHtml = {
  itemImage: {
    width: 100,
    height: 100,
    aspectRatio: 1,
    resizeMode: 'contain',
  },
};

function getTimestamp(str) {
  const dateFormat = /(\d{4})-(\d{2})-(\d{2})\s+(\d{2}):(\d{2}):(\d{2})/;
  const dateParts = str.match(dateFormat);
  return new Date(
    dateParts[1],
    parseInt(dateParts[2], 10) - 1,
    dateParts[3],
    dateParts[4],
    dateParts[5],
    dateParts[6],
  );
}

function getButton(label, onPress) {
  return (
    <TouchableHighlight
      style={styles.button}
      underlayColor={Colors.touchEffectColorWhite}
      onPress={onPress}
    >
      <Text style={styles.buttonText}>
        { label }
      </Text>
    </TouchableHighlight>
  );
}

export default class MyOrdersItem extends React.Component {
  state = {
    dialogOpen: false,
  };

  getOrderDate = item => {
    const datetime = item.regDt.split(' ');
    const ymd = datetime[0].split('-');

    return ymd.join('. ');
  }


  getOrderStatus = () => {
    const { order: { goods } } = this.props;
    goods.sort((a, b) => {
      const aTimestamp = getTimestamp((a.modDt) ? a.modDt : a.regDt);
      const bTimestamp = getTimestamp((b.modDt) ? b.modDt : b.regDt);
      if (aTimestamp > bTimestamp) {
        return -1;
      }
      if (aTimestamp < bTimestamp) {
        return 1;
      }
      return 0;
    });

    return {
      orderStatus: goods[0].orderStatus,
      orderStatusStr: goods[0].orderStatusStr,
    };
  }

  moveToOrderDetail = () => {
    const { router, order } = this.props;

    router.push({
      pathname: `/accounts/orders/${order.orderNo}`,
    });
  }

  openCancelDialog = () => {
    this.setState({
      dialogOpen: true,
    });
  }

  orderCancel = async () => {
    const { cancelUserOrder, order } = this.props;

    try {
      await cancelUserOrder(order.orderNo);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  showChatInquiry = () => {
    const chatUrl = config.get('chatInquiryUrl');
    window.location.href = chatUrl;
  }

  groupItems(goods) {
    const { order } = this.props;

    if (goods.length === 1) {
      return goods[0];
    }

    let { goodsNm } = goods[0];
    const { ...repGood } = goods[0];

    let goodsNmSuffix = '외 ' + (goods.length - 1) + '건';

    const { settlePrice } = order;
    const { regDt } = order;
    const { orderStatus, orderStatusStr } = this.getOrderStatus();

    return Object.assign(repGood, {
      goodsNm,
      goodsNmSuffix,
      settlePrice,
      regDt,
      orderStatus,
      orderStatusStr
    });
  }

  renderItem(item) {
    return (
      <View style={styles.itemContainer}>
        <View style={styles.stateContainer}>
          <Text style={styles.state}>
            { item.orderStatusStr }
          </Text>
          <Text style={styles.orderDate}>
            { this.getOrderDate(item) }
          </Text>
        </View>

        <View
          style={styles.itemInfoContainer}
        >
          <View style={styles.itemImageContainer}>
            <img
              referrerPolicy="no-referrer"
              alt=""
              style={stylesHtml.itemImage}
              src={item.imageSrc}
            />
          </View>
          <View style={styles.itemInfoButtonContainer}>
            <View style={styles.itemInfoTextContainer}>
              <View style={styles.itemNameSuffixContainer}>
                <View style={styles.itemNameContainer}>
                  <Text
                    style={styles.itemName}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    { item.goodsNm }
                  </Text>
                </View>
                { item.goodsNmSuffix && (
                <View style={styles.itemSuffixContainer}>
                  <Text
                    style={[styles.itemName, styles.itemNameSuffix]}
                    numberOfLines={1}
                  >
                    { item.goodsNmSuffix }
                  </Text>
                </View>
                )}
              </View>

              <NumberFormat
                value={item.settlePrice}
                displayType="text"
                thousandSeparator
                decimalScale={0}
                renderText={value => <Text style={styles.itemPrice}>{value}</Text>}
              />
            </View>

            {/*
            <View style={styles.buttonsContainer}>
              { item.orderStatus === 'o1'
                && (getButton('주문 취소', this.showChatInquiry)) }

              { item.orderStatus === 'p1'
                && (getButton('주문 취소', this.showChatInquiry)) }

              { item.orderStatus.startsWith('d')
                && (getButton('배송조회', this.moveToOrderDetail)) }

              { (item.orderStatus === 'g1'
                  || item.orderStatus.startsWith('d')
              )
                && (getButton('반품/교환', this.showChatInquiry)) }

              { item.orderStatus.startsWith('r')
                && (getButton('환불 정보', this.moveToOrderDetail)) }

              { item.orderStatus.startsWith('e')
                && (getButton('교환 정보', this.moveToOrderDetail)) }
              { item.orderStatus === 'e1'
                && (getButton('교환 철회', this.moveToOrderDetail)) }

              { item.orderStatus.startsWith('b')
                && (getButton('반품 정보', this.moveToOrderDetail)) }
              { item.orderStatus === 'b1'
                && (getButton('반품 철회', this.moveToOrderDetail)) }

              { getButton('1:1 문의', this.showChatInquiry) }
            </View>
            */}
          </View>
        </View>
      </View>
    );
  }

  render() {
    const { order: { goods, orderNo } } = this.props;
    const { dialogOpen } = this.state;

    if (!goods || goods.length < 1) {
      return null;
    }

    return (
      <View style={{ flex: 1, backgroundColor: 'white' }}>
        <TouchableHighlight
          style={styles.orderContainer}
          onPress={this.moveToOrderDetail}
          underlayColor={Colors.touchEffectColorWhite}
        >
          { this.renderItem(this.groupItems(goods)) }
        </TouchableHighlight>

        <CustomDialog
          dialogOpen={dialogOpen}
          key_={orderNo}
          description="주문을 취소하시겠습니까?"
          confirmText="네"
          cancelText="아니오"
          cancelColor="primary"
          confirmColor="default"
          onCancel={() => {
            this.setState({ dialogOpen: false });
          }}
          onConfirm={() => {
            this.setState({ dialogOpen: false });
            this.orderCancel();
          }}
          onClose={() => {
            this.setState({ dialogOpen: false });
          }}
        />
      </View>
    );
  }
}
