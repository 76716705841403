import React from 'react';
import { StyleSheet, Dimensions, View, Text, TextInput } from 'react-native';
// import { TouchableOpacity } from 'react-native-gesture-handler';
// import RNPickerSelect from 'react-native-picker-select';
import findIndex from 'lodash/findIndex';

import { Constants, Colors, Styles } from '../../../constants';

import {
  PaymentCreditCardIcon,
  PaymentTransferIcon,
  ChevronDownIcon,
} from '../../../icons-v2';

import {
  Button,
 } from '../../../components-v2/';


const windowLayout = {
  width: Dimensions.get('window').width,
};

const titleWidth = 75;
const bodyWidth = windowLayout.width - titleWidth - 20 * 2 - 10;


const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    marginEnd: 2,
    paddingBottom:28,
  },
  labelContainer: {
    flex: 1,
    width: 105,
    maxWidth: 105,
  },
  labelText: {
    color: '#000',
    fontSize: 14,
    lineHeight: 16.8,
    textAlign: 'left',
    textAlignVertical: 'center',
    ...Styles.pretendard400,
  },
  paymentButtonContainer: {
    flex: 1,
  },
  button: {
    width: '100%',
    height: 40,
    backgroundColor: '#fff',
    marginBottom: 12,
    borderColor: '#000',
    borderWidth: 0.5,
    padding: 0,
    paddingStart: 10,
    paddingEnd: 16,
    justifyContent: 'center',
  },
  buttonActive: {
    borderColor: '#000',
    borderWidth: 2,
  },
  buttonInnerComponentContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonIconContainer: {
  },
  buttonTextContainer: {
    justifyContent: 'center',
  },
  buttonText: {
    fontSize: 14,
    lineHeight: 16.8,
    color: '#000',
    ...Styles.pretendard600,
  },
  contentContainer: {
    marginStart: 10,
    marginTop: 20,
    marginBottom: 10,
  },
  contentRow: {
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'center',
  },
  contentTitle: {
    fontSize: 15,
    width: titleWidth,
    ...Styles.appleSDGothicNeoRegular,
  },
  contentBody: {
    width: bodyWidth,
    backgroundColor: 'rgb(246, 247, 248)',
    fontSize: 15,
    height: 52,
    paddingHorizontal: 16,
    ...Styles.appleSDGothicNeoRegular,
  },
  pickerIcon: {
    marginEnd: 10,
    marginTop: 7,
  },
});


const pickerSelectCommonStyles = {
  width: bodyWidth,
  paddingHorizontal: 10,
  fontSize: 16,
  paddingStart: 15,
  paddingEnd: 30, // to ensure the text is never behind the icon
  color: '#000',
  backgroundColor: 'rgb(246, 247, 248)',
  height: 52,
  ...Styles.appleSDGothicNeoRegular,
};

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    ...pickerSelectCommonStyles,
    paddingVertical: 12,
  },
  inputAndroid: {
    ...pickerSelectCommonStyles,
    paddingVertical: 8,
  },
  placeholder: {
    color: '#000',
  },
  iconContainer: {
    marginRight: 12,
    marginTop: 12,
  },
});


const itemList = [
  { key: 'card', name: '신용/체크카드', settleKind: 'pc' },
  { key: 'transfer', name: '실시간 계좌이체', settleKind: 'pb' },
  // { key: 'bank', name: '무통장입금', settleKind: 'gb' },
  // { key: 'phone', name: '휴대폰결제', settleKind: 'ph' },
  // { key: 'kakaoPay', name: '카카오페이', settleKind: '' },
  // { key: 'naverPay', name: '네이버페이', settleKind: 'nv' },
];


class PaymentMethod extends React.Component {
  state = {
    selectedKey: 'card',
    paymentInfo: {
      settleKind: 'pc',
      isValid: false,
    },
  };

  componentDidMount() {
    const { onPaymentMethodSelected } = this.props;
    const { paymentInfo } = this.state;
    onPaymentMethodSelected(paymentInfo);
  }


  onPressItem = key => () => {
    const { onPaymentMethodSelected } = this.props;
    const { paymentInfo } = this.state;

    const itemIdx = findIndex(itemList, ['key', key]);
    if (itemIdx < 0) {
      alert('아직 지원하지 않는 결제수단입니다.');
      return;
    }

    if (key !== 'bank') {
      delete paymentInfo.bankAccount;
      delete paymentInfo.bankSender;
    }

    const item = itemList[itemIdx];
    const { settleKind } = item;
    if (!settleKind) {
      alert('아직 지원하지 않는 결제수단입니다.');
      return;
    }
    paymentInfo.settleKind = settleKind;

    this.setState({
      paymentInfo,
      selectedKey: key,
    });
    onPaymentMethodSelected(paymentInfo);
  }

  validateInputField() {
    const {
      paymentInfo: {
        settleKind,
        bankSender,
        bankAccount,
      },
    } = this.state;

    if (settleKind === 'gb') {
      if (!bankSender) {
        alert('입금자명을 입력해주세요.');
        return false;
      }

      if (!bankAccount) {
        alert('입금계좌를 선택해주세요.');
        return false;
      }
    }
    return true;
  }

  renderItem(item) {
    const {
      name,
      key,
    } = item;

    const { selectedKey } = this.state;

    const isSelected = key === selectedKey;

    return (
      <View key={key} style={styles.buttonContainer}>
        <Button
          key={key}
          onPress={this.onPressItem(key)}
          containerStyle={[styles.button, isSelected && styles.buttonActive]}
          innerComponent={
            <View style={styles.buttonInnerComponentContainer}>
              <View style={styles.buttonIconContainer}>
                {key === "card" ? <PaymentCreditCardIcon /> : <PaymentTransferIcon />}
              </View>
              <View style={styles.buttonTextContainer}>
                <Text style={styles.buttonText}>{name}</Text>
              </View>
            </View>
          }
        />
      </View>
    );
  }

  renderContent() {
    const { selectedKey, paymentInfo: { bankSender } } = this.state;
    const { bank } = this.props;

    if (selectedKey === 'bank') {
      const onChangeText = text => {
        const { onPaymentMethodSelected } = this.props;
        this.setState(({ paymentInfo: prevPaymentInfo }) => ({
          paymentInfo: {
            ...prevPaymentInfo,
            bankSender: text,
            isValid: false,
          },
        }), () => {
          const { paymentInfo } = this.state;
          onPaymentMethodSelected(paymentInfo);
        });
      };

      const placeholder = {
        label: '입금하실 계좌를 선택해 주세요',
        value: null,
        color: '#8F8F8F',
      };

      const items = bank.map(item => ({
        label: `${item.bankName} ${item.accountNumber} ${item.depositor}`,
        value: item.sno,
      }));

      const onBackAccountSelected = sno => {
        const { onPaymentMethodSelected } = this.props;
        this.setState(({ paymentInfo: prevPaymentInfo }) => ({
          paymentInfo: {
            ...prevPaymentInfo,
            bankAccount: sno,
          },
        }), () => {
          const { paymentInfo } = this.state;
          onPaymentMethodSelected(paymentInfo);
        });
      };

      return (
        <View style={styles.contentContainer}>
          <View style={styles.contentRow}>
            <Text style={styles.contentTitle}>입금자명</Text>
            <TextInput
              style={styles.contentBody}
              value={bankSender}
              onChangeText={onChangeText}
            />
          </View>

          <View style={styles.contentRow}>
            <Text style={styles.contentTitle}>입금계좌</Text>
            {/* <RNPickerSelect
              onValueChange={(value, index) => index > 0 && onBackAccountSelected(value)}
              placeholder={placeholder}
              style={pickerSelectStyles}
              items={items}
              useNativeAndroidPickerStyle={false}
              Icon={() => <ChevronDownIcon />}
            /> */}
          </View>
        </View>
      );
    }

    return null;
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.labelContainer}>
          <Text style={styles.labelText}>
            결제 방법
          </Text>
        </View>
        <View style={styles.paymentButtonContainer}>
          {itemList.map(item => this.renderItem(item))}
        </View>
      </View>
    );
  }
}


export default PaymentMethod;
