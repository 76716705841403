import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View, Dimensions } from 'react-native';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';

import { Constants, Styles, SizeData } from '../../constants';
import {
  SizeListWithCheckBox, Button, SizeGuideModal,
} from '../../components-v2';


const {
  height: windowHeight,
} = Dimensions.get('window');


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  listContainer: {
  },
  confirmButtonContainer: {
    position: 'absolute',
    bottom: 0,
    start: 0,
    end: 0,
    backgroundColor: '#000000',
  },
  confirmButton: {
    paddingTop: 20,
    paddingBottom: 21,
    borderWidth: 0,
  },
  confirmButtonText: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
    letterSpacing: '2%',
    color: '#ffffff',

  },
});


export class MySizeSelectPage extends React.Component {
  state = {
    selectedItemList: [],
  }

  constructor(props) {
    super(props);

    this.onPressConfirm = this.onPressConfirm.bind(this);
  }

  componentDidMount() {
    const {
      setGlobalState, auth, type, optionList,
      logEventPageview,
    } = this.props;

    setGlobalState({ showMainBottomTabBar: false });

    let selectedSizeList = [];

    // console.log(`MySizeSelectPage type=${type}`);

    if (type === 'clothing') {
      selectedSizeList = auth.mySize && auth.mySize.clothing ? auth.mySize.clothing : [];
    } else if (type === 'shoes') {
      selectedSizeList = auth.mySize && auth.mySize.shoes ? auth.mySize.shoes : [];
    }

    const selectedItemList = optionList.reduce((acc, opt) => {
      selectedSizeList.forEach(sizeName => {
        if (opt.name === sizeName) {
          acc.push(opt);
        }
      });
      return acc;
    }, []);

    // console.log(`MySizeSelectPage selectedItemList=${JSON.stringify(selectedItemList)}`);
    this.setState({
      selectedItemList,
    });

    logEventPageview({ page_name: 'MySizeSelect' });
  }

  componentWillUnmount() {
    const { setGlobalState } = this.props;

    setGlobalState({ showMainBottomTabBar: true });
  }

  onChangeSelectedItemList = (selectedItemList) => {
    this.setState({ selectedItemList });
  }

  onPressSizeGuide = () => {
    const { setModalProps } = this.props;

    setModalProps({
      isVisible: true,
      children: this.renderSizeGuide(),
    });
  }

  onPressConfirm = () => {
    const { auth, router, updateUser, type } = this.props;
    const { selectedItemList } = this.state
    selectedItemList.sort((lhs, rhs) => (lhs.id > rhs.id))

    const params = { mySize: { [type]: selectedItemList.map(s => s.name) } }
    try {
      updateUser(auth._id, params)
        .then(() => router.go(-1));
    } catch (error) {
      console.error('error=', error);
      alert('저장 중 오류가 발생하였습니다. 잠시 후 다시 시도해주세요.');
    }
  }

  renderSizeGuide() {

    const { type, setModalProps } = this.props;

    const onClose = () => {
      setModalProps({ isVisible: false });
    };
  
    return (
      <SizeGuideModal
        onClose={onClose}
        targetSubType={type}
        enableBackButton={false}
        />
    );
  }

  renderConfirmButton() {
    return (
      <View style={styles.confirmButtonContainer}>
        <Button
          containerStyle={styles.confirmButton}
          titleStyle={styles.confirmButtonText}
          title="선택 완료"
          onPress={this.onPressConfirm}
        />
      </View>
    );
  }

  render() {
    const {
      type, modalProps, setModalProps,
      showSmartBanner,
      optionList,
    } = this.props;
    const { selectedItemList } = this.state;

    const containerHeight = windowHeight
      - (showSmartBanner && Constants.smartBannerHeight)
      - Constants.mainHeaderHeight;
    // console.log(`containerHeight=${containerHeight}`);

    const containerStyle = {
      ...styles.container,
      height: containerHeight,
    };

    const listContainerHeight = containerHeight - Constants.bottomTabBarHeight;
    // console.log(`listContainerHeight=${listContainerHeight}`);

    const listContainerStyle = {
      ...styles.listContainer,
      height: listContainerHeight,
    }

    console.log(`MySizeSelectPage selectedItemList=${JSON.stringify(selectedItemList)}`);

    return (
      <View style={containerStyle}>
        <SizeListWithCheckBox
          onChangeSelectedItemList={this.onChangeSelectedItemList}
          selectedItemList={selectedItemList}
          itemList={optionList}
          containerStyle={listContainerStyle}
          onPressSizeGuide={this.onPressSizeGuide}
          subType={type}
          modalProps={modalProps}
          setModalProps={setModalProps}
          isInModal={false}
          />
        {this.renderConfirmButton()}
      </View>
    );
  }
}

export default connect(
  (state, { params }) => {
    const { type } = params;

    const optionList = SizeData.SIZE_LIST.filter((e) => e.subType == type);

    return {
      auth: state.auth,
      type,
      optionList,
    };
  },
  actions,
)(MySizeSelectPage);
