import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import qs from 'qs';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';

import config from '../../config';
import Styles from '../../constants/Styles';

const styles = {
  container: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    display: 'table',
  },
  message: {
    textAlign: 'center',
    fontSize: 24,
    display: 'table-cell',
    verticalAlign: 'middle',
    paddingBottom: 150,
    ...Styles.appleSDGothicNeoBold,
  },
};


class CheckoutPaymentGatewayPage extends React.Component {
  componentDidMount() {
    const { logEventPageview } = this.props;

    document.getElementById('paymentGatewayForm').submit();

    logEventPageview({ page_name: 'CheckoutPaymentGateway' });
  }

  render() {
    const {
      auth: { token },
      orderNo,
      uniqueOrderCheckData,
    } = this.props;

    const path = '/heartit_api/order_payment_gate_web.php';
    const godomallMobileBaseUrl = config.get('godomallMobileBaseUrl');

    const targetUrl = `${godomallMobileBaseUrl}${path}`;

    const { protocol } = window.location;
    const { host } = window.location;
    const returnUrl = `${protocol}//${host}/shop/checkoutFinish/`;

    return (
      <div style={styles.container}>
        <p style={styles.message}>결제 준비 중</p>
        <form
          id="paymentGatewayForm"
          method="POST"
          action={targetUrl}
        >
          <input
            type="hidden"
            id="token"
            name="token"
            value={token}
            readOnly
          />

          <input
            type="hidden"
            id="orderNo"
            name="orderNo"
            value={orderNo}
            readOnly
          />

          <input
            type="hidden"
            id="uniqueOrderCheckData"
            name="uniqueOrderCheckData"
            value={uniqueOrderCheckData}
            readOnly
          />

          <input
            type="hidden"
            id="returnUrl"
            name="returnUrl"
            value={returnUrl}
            readOnly
          />
        </form>
      </div>
    );
  }
}


export function navigateToCheckoutPaymentGateway(orderNo, uniqueOrderCheckData) {
  const query = {
    orderNo,
    uniqueOrderCheckData,
  };

  const path = `/shop/checkoutPaymentGateway/${qs.stringify(query)}`;
  browserHistory.push(path);
}


export default connect(
  (state, props) => {
    const { params: { query } } = props;
    const queryObject = qs.parse(query);
    const {
      orderNo,
      uniqueOrderCheckData,
    } = queryObject;

    return {
      auth: state.auth,
      shopSessionId: state.entities.shop.sessionId,
      orderNo,
      uniqueOrderCheckData,
    };
  },
  actions,
)(CheckoutPaymentGatewayPage);
