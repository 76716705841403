import React from 'react'

import { 
  StyleSheet, View, Text, FlatList,
} from 'react-native';

import { Colors, Styles } from '../constants';
import { ArrowRightIcon } from '../icons-v2';
import TouchableHighlightWithContainer from './TouchableHighlightWithContainer';

import showFilterSizeStandardModal from './ProductFilterControl/filter/FilterSizeStandardModal';


const styles = StyleSheet.create({
  container: {
  },
  headerContainer: {
    flexDirection: 'row',
  },
  headerButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTitleText: {
    ...Styles.pretendard400,
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
  },
  headerNameText: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
  },
  dropdownContainer: {
    marginTop: 8,
    marginStart: 57,
    marginBottom: 2,
  },
  dropdownItemContainer: {
  },
  dropdownItemText: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 11,
    lineHeight: 18,
    color: Colors.defaultGray08,
  },
  dropdownItemTextSelected: {
    ...Styles.appleSDGothicNeoBold,
  },
  dropdownItemSeparator: {
    height: 19,
  },
});


class SizeStandardSelector extends React.Component {

  renderHeader() {
    const {
      sizeStandardList,
      selectedSizeStandard,
      onChange,
    } = this.props;

    const onPress = () => {
      showFilterSizeStandardModal(this, sizeStandardList, selectedSizeStandard, onChange);
    };

    return (
      <View style={styles.headerContainer}>
        <Text style={styles.headerTitleText}>사이즈 기준: </Text>
        <TouchableHighlightWithContainer
          onPress={onPress.bind(this)}
          >
          <View style={styles.headerButton}>
            <Text style={styles.headerNameText}>{selectedSizeStandard.name}</Text>
            <View style={{ width: 4 }} />
            <ArrowRightIcon />
          </View>
        </TouchableHighlightWithContainer>
      </View>
    );
  }

  render() {
    const {
      containerStyle,
    } = this.props;

    return (
      <View style={[styles.container, containerStyle]}>
        {this.renderHeader()}
      </View>
    );
  }
}


export default SizeStandardSelector;
