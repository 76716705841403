import React from 'react'
import { connect } from 'react-redux';

import { actions } from 'react-shared';

import { StyleSheet, View, Text, ScrollView, Dimensions } from 'react-native';
import { Styles, Constants, Colors, SortingRule } from '../../../constants';
import { navigateToProductPage } from '../../shop/ProductPage';

import {
  ProductFilterControl,
  CloudinaryImage,
  Products,
} from '../../../components-v2';

import intersection from 'lodash/intersection';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';


const { height: windowHeight } = Dimensions.get('window');


const styleHtml = {
  media: {
    maxHeight: 'min(calc(100vw - 20px), 940px)',
  },
  productImage: {
    width: '100%',
    aspectRatio: 166/221,
    borderWidth: 1,
    borderColor: Colors.productImageBorder,
    overflow: 'hidden',
  },
};

const styles = StyleSheet.create({
  container: {
  },
  title: {
    marginTop: 14,
    marginHorizontal: 30,
    fontSize: 24,
    lineHeight: 28.8,
    textAlign: 'left',
    ...Styles.pretendard700,
  },
  subtitle: {
    marginTop: 6,
    lineHeight: 16.8,
    ...Styles.pretendard400,
    opacity: 0.5,
    marginHorizontal: 30,
  },
  description: {
    marginTop: 20,
    marginHorizontal: 30,
    fontSize: 15,
    lineHeight: 22,
    textAlign: 'left',
    ...Styles.pretendard400,
  },
  productFilterControl: {
    paddingTop: 10,
    paddingBottom: 20,
  },
  productListContainer: {
    ...Styles.fullWidth,
  },
  productFlatListContainer: {
    marginHorizontal: 20,
  },
  itemContainerStyle: {
    flex: 1,
    marginHorizontal: 3 / 2,
  },
  columnWrapperStyle: {
    marginHorizontal: -3 / 2,
    ...Styles.fullWidth,
  },
  productTextContainer: {
    marginTop: 6,
  },
  footer: {
    height: 100,
  },

  deliveryContainer: {
    backgroundColor: '#000',
    paddingTop: 11,
    height: 43,
  },
  deliveryTextContainer: {
    marginLeft: 18,
    marginRight: 18,
    flexDirection:'row'
  },
  deliveryTitleText: {
    color: '#fff',
    width: 160,
    marginEnd:12,
    ...Styles.pretendard400,
  },
  deliveryText: {
    color: '#fff',
    display:'inline-block',
    marginBottom:1,
    lineHeight:19.2,
    ...Styles.pretendard400,
  },
  deliveryBoldText: {
    color: '#fff',
    ...Styles.pretendard700,
  },
  deliveryBoldTextRed: {
    color: '#E94949',
    ...Styles.pretendard700,
  }
});



class ExhibitionPage extends React.Component {

  state = {
    brandList: [],
    filterList: [],
    sortingRule: SortingRule.SORTING_RULE_EXHIBITION[0],
    products: [],
    isMarketing: false,
  };

  componentDidMount() {
    const {
      exhibitionId,
      loadExhibition,
      loadShopBrandList,
      logEventPageview,
      getPartnerShippingProducts,
    } = this.props;

    getPartnerShippingProducts();
    loadExhibition(exhibitionId)
      .then(() => {
        const { exhibition } = this.props;
        this.setState({
          filterList: (exhibition.filterList) ? exhibition.filterList : [],
          sortingRule: (exhibition.sortBy) ? exhibition.sortBy : SortingRule.SORTING_RULE_EXHIBITION[0],
        }, () => {
          this.getProducts();
        });
      });
    loadShopBrandList()
      .then(result => {
        const brandList = result.data.map(d => ({
          id: d.cateCd,
          type: 'brand',
          name: d.cateNm,
        }));
        this.setState({ brandList });
      });

    logEventPageview({ page_name: 'Exhibition', page_key: exhibitionId });
  }

  onChangeFilterList(newActiveFilterList) {
    this.setState({
      filterList: newActiveFilterList,
    }, () => {
      this.getProducts();
    });
  }

  onChangeSortingRule(newSortingRule) {
    this.setState({
      sortingRule: newSortingRule,
    }, () => {
      this.getProducts();
    });
  }

  getProductPrice(product) {
    let sellPrice = 0;
    const {
      sellPrice: { amount: sellPriceAmount },
    } = product.retails[0];
    sellPrice = sellPriceAmount;
    return sellPrice;
  }

  getProducts(nextPage=false) {
    const { exhibition: { products: exhibitionProducts=[] } } = this.props;
    const { filterList, sortingRule } = this.state;

    const brandList = [];
    const colorList = [];
    let priceMin = null;
    let priceMax = null;
    // eslint-disable-next-line no-unused-vars
    if (filterList.length > 0) {
      filterList.forEach(f => {
        if (f.type === 'brand') {
          brandList.push(f.id);
        }
        if (f.type === 'size') {
          // FIXME: apply size
        }
        if (f.type === 'color') {
          colorList.push(f.code);
        }
        if (f.type === 'price') {
          priceMin = f.min;
          priceMax = f.max;
        }
      });
    }

    // Brand filter
    let products = exhibitionProducts.filter(p => {
      const brandCd = p.brandCd || p.godoBrandCd;
      if (brandList.length === 0) return true;

      const ret = intersection([brandCd], brandList);
      return ret.length > 0;
    });
    // Color filter
    products = products.filter(p => {
      const productColor = p.godoColor || [];
      if (colorList.length === 0) return true;

      const ret = intersection(productColor, colorList);
      return ret.length > 0;
    });
    // Price filter
    products = products.filter(p => {
      if (priceMin === null || priceMax === null) return true;
      try {
        const sellPrice = this.getProducts(product);
        return (sellPrice >= priceMin && sellPrice <= priceMax);
      } catch (e) {
        console.error(e);
        return false;
      }
    });

    products = products.map(product => {
      if (product.hasOwnProperty('viewCnt')) {
        product['viewCnt3'] = product['viewCnt']['3'];
        product['viewCnt15'] = product['viewCnt']['15'];
      } else {
        product['viewCnt3'] = 0;
        product['viewCnt15'] = 0;
      }

      return product;
    });

    // Sorting
    if (sortingRule.value === 'g.goodsPrice asc') {
      products = sortBy(products, (p) => { return this.getProductPrice(p) });
    } else if (sortingRule.value === 'g.goodsPrice desc') {
      products = sortBy(products, (p) => { return this.getProductPrice(p) }).reverse();
    } else if (sortingRule.value === 'popularity') {
      products = orderBy(products, ['crawled', 'viewCnt3', 'viewCnt15', 'createdAt'], ['asc', 'desc', 'desc', 'desc']);
    } else {
      products = sortBy(products, (p) => { return new Date(p.createdAt) }).reverse();
    }

    // 품절 상품 맨 뒤로
    products = [...products.filter(p => p.soldOut != 'y'), ...products.filter(p => p.soldOut == 'y')]

    this.setState({ products });
  }

  renderMedia() {
    const { exhibition } = this.props;
    if (!exhibition) {
      return;
    }

    const { publicId } = exhibition.thumbnailList[0];

    return (
      <View style={{ marginStart: 20 }}>
        <CloudinaryImage
          key={`image${publicId}`}
          publicId={publicId}
          options={{
            crop: 'limit',
            height: 1080,
            width: 1080,
          }}
          style={styleHtml.media}
          />
      </View>
    );
  }

  render() {
    const {
      exhibition,
      modalProps,
      setModalProps,
      exhibitionId
    } = this.props

    if (!exhibition) {
      return null;
    }

    const {
      title,
      subtitle,
      description,
    } = exhibition;

    const { router, showSmartBanner } = this.props;
    const { brandList, filterList, sortingRule, products, isMarketing } = this.state;
    const onPressProduct = (item) => {
      const productId = item.godoGoodsNo ? item.godoGoodsNo : item.goodsNo;
      navigateToProductPage({ productId }, router);
    };

    const containerHeight = windowHeight
      - Constants.mainHeaderHeight
      - (showSmartBanner ? Constants.smartBannerHeight : 0);

    return (
      <ScrollView
        style={[
          styles.container,
          { height: containerHeight },
        ]}
        stickyHeaderIndices={(isMarketing) ? [6] : [5]}
        showsVerticalScrollIndicator={false}
        >
        { (isMarketing) && (
          <View style={styles.deliveryContainer}>
            <View style={styles.deliveryTextContainer}>
                <Text style={styles.deliveryText}>
                    <Text style={styles.deliveryBoldText}>30/50/100만원 이상</Text> 구매시 추가 <Text style={styles.deliveryBoldTextRed}>1.5만/3만/7만원</Text> 할인
                </Text>
            </View>
          </View>
        )}
        {this.renderMedia()}
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subtitle}>{subtitle}</Text>
        <Text style={styles.description}>{description}</Text>
        <View style={{ height: 50 }} />
        <ProductFilterControl
          router={router}
          brandList={brandList}
          activeFilterList={filterList}
          sortItemList={SortingRule.SORTING_RULE_EXHIBITION}
          sortingRule={sortingRule}
          modalProps={modalProps}
          setModalProps={setModalProps}
          onChangeFilterList={(newActiveFilterList) => this.onChangeFilterList(newActiveFilterList)}
          onChangeSortingRule={(newSortingRule) => this.onChangeSortingRule(newSortingRule)}
          containerStyle={styles.productFilterControl}
          />
        <Products
          horizontal={false}
          numColumns={2}
          products={products}
          onPressProduct={onPressProduct}
          brandList={brandList}
          loadData={() => null}
          listHeaderComponentStyle={{ width: 0 }}
          listFooterComponentStyle={{ width: 0 }}
          containerStyle={styles.productListContainer}
          flatListStyle={styles.productFlatListContainer}
          itemContainerStyle={styles.itemContainerStyle}
          productImageStyle={styleHtml.productImage}
          productTextContainerStyle={styles.productTextContainer}
          columnWrapperStyle={styles.columnWrapperStyle}
          isSmallVersion={false}
          showLikeButton={true}
          showTags={true}
          router={router}
          setModalProps={setModalProps}
          modalProps={modalProps}
          />
        <View style={styles.footer} />
      </ScrollView>
    );
  }
}

export default connect(
  (state, { router, params: { exhibitionId } }) => {
    const exhibition = state.entities.homeMdCurations[exhibitionId];

    return {
      exhibitionId,
      exhibition,
      showSmartBanner: state.globalLayoutInfo.showSmartBanner,
    };
  },
  actions,
)(ExhibitionPage);
