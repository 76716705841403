import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import startsWith from 'lodash/startsWith';

import config from '../config';
import VocIcon from '../icons/VocIcon';


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'sticky',
  },
});

class ChatInquiryIcon extends React.Component {
  componentDidMount() {
  }

  showChatInquiry = () => {
    const chatUrl = config.get('chatInquiryUrl');
    window.location.href = chatUrl;
  }

  render() {
    const { pathName, hideChatInquiry } = this.props;

    // console.log('ChatInquiryIcon render hideChatInquiry=', hideChatInquiry);

    if (hideChatInquiry) {
      return null;
    }

    if (!startsWith(pathName, '/product')) {
      return null;
    }

    return (
      <TouchableOpacity
        onPress={this.showChatInquiry}
      >
        <View style={styles.container}>
          <VocIcon />
        </View>
      </TouchableOpacity>
    );
  }
}

ChatInquiryIcon.propTypes = {
  hideChatInquiry: PropTypes.bool,
};

ChatInquiryIcon.defaultProps = {
  hideChatInquiry: false,
};

export default connect(
  state => ({
    hideChatInquiry: state.globalLayoutInfo.hideChatInquiry,
  }),
)(ChatInquiryIcon);
