import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';

import Styles from '../constants/Styles';
import ExpandIcon from '../icons/ExpandIcon';

const styles = StyleSheet.create({
  loadMoreButton: {
    height: 55,
    borderRadius: 3,
    paddingVertical: 6,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadMoreText: {
    height: 19,
    marginStart: 21,
    marginEnd: 20,
    ...Styles.appleSDGothicNeoBold,
    fontSize: 15,
    lineHeight: 19,
    color: '#fff',
  },
  loadMoreIcon: {
    marginStart: 29,
    marginEnd: 27,
  },
});


function LoadMoreButton(props) {
  const { onPress } = props;

  return (
    <TouchableOpacity
      onPress={onPress}
    >
      <View style={styles.loadMoreButton}>
        <Text style={styles.loadMoreText}>더보기</Text>
        <View style={styles.loadMoreIcon}>
          <ExpandIcon />
        </View>
      </View>
    </TouchableOpacity>
  );
}

export default LoadMoreButton;
