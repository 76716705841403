import React, { Component } from 'react';
import {
  StyleSheet, ScrollView, View, Text, Alert,
} from 'react-native';
import { connect } from 'react-redux';

import { actions } from 'shared';

import {
  CheckBox, Button,
} from '../../../components-v2';

 import EditShippingAddressList from './EditShippingAddressList';
import ChangePasswordForm from './ChangePasswordForm';

import { Constants, Colors, Styles } from '../../../constants';
import EditBasicProfileForm from '../../../components-v2/EditBasicProfileForm';

import { navigateToCreateShippingAddress } from '../ShippingAddressEditPage';


const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Constants.viewHorizontalPadding,
    marginBottom: Constants.innerViewBottomSpace,
  },
  sectionHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTopWidth: 0.5,
    borderColor: Colors.lineGray,
  },
  sectionHeaderButton: {
    borderWidth: 0,
    borderRadius: Constants.touchEffectBorderRadius,
    padding: Constants.touchEffectWidth,
    margin: -1 * Constants.touchEffectWidth,
    marginTop: 1 - Constants.touchEffectWidth,
  },
  sectionHeaderButtonText: {
    fontSize: 14,
    lineHeight: 16.8,
    color: '#000',
    ...Styles.pretendard400,
  },
  sectionHeaderText: {
    color: '#000',
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
    ...Styles.pretendard600,
    marginTop: 12.5,
    marginBottom: 12,
  },
  profileInputContainer: {
    paddingBottom: 60,
  },
  inputLabelContainer: {
    marginTop: 40,
    marginBottom: 16,
  },
  inputLabelText: {
    color: '#000',
    fontSize: 14,
    lineHeight: 14,
    opacity: 0.5,
    ...Styles.pretendard400,
  },
  inputText: {
    padding: 0,
    color: '#000',
    fontSize: 16,
    lineHeight: 19.2,
    textAlignVertical: 'center',
    ...Styles.pretendard400,
  },
  inputBorder: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 0,
    paddingBottom: 8,
    borderBottomWidth: 1,
    borderColor: '#000',
  },
  fullWidthButtonContainer: {
    flex: 1,
  },
  fullWidthButton: {
    width: '100%',
    padding: 0,
    height: 40,
    backgroundColor: '#000',
    borderWidth: 0.5,
    borderColor: '#000',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullWidthButtonText: {
    color: '#fff',
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard600,
  },
  itemButton: {
  },
  itemContainer: {
    paddingVertical: 0,
    paddingRight: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  itemText: {
    height: 19,
    lineHeight: 19,
    ...Styles.appleSDGothicNeoMedium,
    fontSize: 15,
    marginTop: 16,
    marginBottom: 15,
  },
  passwordChangeContainer: {
    marginTop: 4,
    marginLeft: 20,
  },
  membershipWithdrawContainer: {
  },
  membershipWithdrawButton: {
    borderWidth: 0.5,
    borderColor: '#000',
    backgroundColor: '#fff',
    padding: 0,
  },
  membershipWithdrawButtonText: {
    color: Colors.primaryRed,
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard600,
  },
  checkBoxRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
  checkBoxText: {
    marginStart: 8,
    color: '#000',
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard400,
  },
  marketingAgreementContainer: {
  },
  errorContainer: {
    height: 23.5,
    justifyContent:'center',
  },
  errorText: {
    flexWrap: 'wrap',
    fontSize: 12,
    color: Colors.primaryRed,
    ...Styles.pretendard400,
  },
  socialTypeContainer: {
    width: '100%',
    marginTop: 8,
    marginBottom: 30 - 40,
  },
  socialTypeText: {
    flexWrap: 'wrap',
    fontSize: 12,
    lineHeight: 14.4,
    color: Colors.primaryBlue,
    ...Styles.pretendard400,
  },
});

class EditProfilePage extends Component {
  toast = React.createRef();

  phoneNumberInputRef = null;

  constructor(props) {
    super(props);

    const {
      auth: {
        email,
        name,
        tel,
        birthday,
        marketingAgreementType: {
          sms: marketingSmsAgreed = false,
          email: marketingEmailAgreed = false,
          push: marketingPushAgreed = false,
        } = {},
      },
    } = this.props;

    this.state = {
      birthday,
      email,
      name,
      phone: tel,
      phoneVerified: true,
      phoneIgnoreOldUser: false,
      marketingSmsAgreed,
      marketingEmailAgreed,
      marketingPushAgreed,
      phoneError: '',
    };
  }

  onPressChangePassword = () => {
    const { navigation } = this.props;
    navigation.navigate('ChangePassword');
  };

  onPressMembershipWithdraw = () => {
    const { router } = this.props;
    router.push('/accounts/withdraw');
  }

  onPressCancel = () => {
    const { navigation } = this.props;
    navigation.pop();
  };

  onPressSave = async () => {
    if (!this.validateInputField()) {
      return;
    }

    const {
      name,
      phone,
      birthday,
      marketingSmsAgreed,
      marketingEmailAgreed,
      marketingPushAgreed,
    } = this.state;

    const params = {
      name,
      tel: phone,
      birthday,
      marketingAgreementType: {
        sms: marketingSmsAgreed,
        email: marketingEmailAgreed,
        push: marketingPushAgreed,
      },
    };

    const { updateUser, auth, router } = this.props;

    try {
      await updateUser(auth._id, params);
      router.go(-1);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error=', error);
      this.showSnackbar('저장 중 오류가 발생하였습니다. 잠시 후 다시 시도해주세요.');
    }
  };

  showIgnoreOldUserAlert = email => {
    Alert.alert(
      '이미 가입된 휴대폰 번호입니다.',
      `${email} 으로 가입된 번호입니다. 그래도 이 번호를 사용하시겠습니까?`,
      [
        {
          text: '취소',
          style: 'cancel',
          onPress: () => {
          },
        },
        {
          text: '사용하기',
          onPress: () => this.setState({ phoneIgnoreOldUser: true }),
        },
      ],
      { cancelable: false },
    );
  }

  renderErrorMsg = msg => (
    <View style={styles.errorContainer}>
      <Text style={styles.errorText}>
        {msg}
      </Text>
    </View>
  )

  componentDidMount() {
    const {
      logEventPageview,
    } = this.props;

    logEventPageview({ page_name: 'EditProfile' });
  }

  validateInputField() {
    const { name, phone, phoneVerified } = this.state;

    if (!name || name === '') {
      //this.showMessage('이름을 입력하세요.');
      return false;
    }

    if (!phone || phone === '') {
      //this.showMessage('휴대폰 번호를 입력하세요.');
      return false;
    }

    if (!phoneVerified) {
      //this.showMessage('휴대폰 번호 인증을 해주세요.');
      return false;
    }

    return true;
  }

  showMessage(msg) {
    this.toast.current.show(msg, 2000);
  }

  renderBasicInfo() {
    const {
      auth,
      modalProps, setModalProps,
    } = this.props;
    const {
      email,
      name,
      phone,
      phoneVerified,
      birthday,
    } = this.state;

    const onChange = (newValue) => {
      this.setState(newValue);
    };

    return (
      <React.Fragment>
        <View style={styles.sectionHeaderContainer}>
          <Text style={styles.sectionHeaderText}>기본 정보 수정</Text>
        </View>

        <EditBasicProfileForm
          email={email}
          name={name}
          phone={phone}
          phoneVerified={phoneVerified}
          birthday={birthday}
          onChange={onChange.bind(this)}
          auth={auth}
          modalProps={modalProps}
          setModalProps={setModalProps}
          />
      </React.Fragment>
    );
  }

  renderShippingInfo() {
    const { router } = this.props;

    return (
      <React.Fragment>
        <View style={styles.sectionHeaderContainer}>
          <Text style={styles.sectionHeaderText}>배송지 정보 수정</Text>
          <Button
            containerStyle={styles.sectionHeaderButton}
            titleStyle={styles.sectionHeaderButtonText}
            title="배송지 추가"
            onPress={() => navigateToCreateShippingAddress()}
          />
        </View>

        <View style={{ height: 20 }} />

        <EditShippingAddressList
          router={router}
          />
      </React.Fragment>
    )
  }

  renderMarketingAgreement() {
    const {
      marketingSmsAgreed, marketingEmailAgreed, marketingPushAgreed,
    } = this.state;

    return (
      <React.Fragment>
        <View style={styles.sectionHeaderContainer}>
          <Text style={styles.sectionHeaderText}>광고성 정보 수신 설정</Text>
        </View>

        <View style={styles.marketingAgreementContainer}>
          <View style={styles.checkBoxRow}>
            <CheckBox
              onPress={() => this.setState({ marketingPushAgreed: !marketingPushAgreed })}
              checked={marketingPushAgreed}
            />
            <Text style={[styles.checkBoxText, (marketingPushAgreed) ? {...Styles.pretendard600} : {}]}>
              앱 푸시
            </Text>
          </View>

          <View style={styles.checkBoxRow}>
            <CheckBox
              onPress={() => this.setState({ marketingSmsAgreed: !marketingSmsAgreed })}
              checked={marketingSmsAgreed}
            />
            <Text style={[styles.checkBoxText, (marketingSmsAgreed) ? {...Styles.pretendard600} : {}]}>
              문자 메시지
            </Text>
          </View>

          <View style={styles.checkBoxRow}>
            <CheckBox
              onPress={() => this.setState({ marketingEmailAgreed: !marketingEmailAgreed })}
              checked={marketingEmailAgreed}
            />
            <Text style={[styles.checkBoxText, (marketingEmailAgreed) ? {...Styles.pretendard600} : {}]}>
              이메일
            </Text>
          </View>
        </View>
      </React.Fragment>
    )
  }

  render() {
    const { navigation } = this.props;



    return (
      <ScrollView
        style={styles.container}
        {...Constants.scrollViewCommonProps}
      >
        {this.renderBasicInfo()}

        <View style={{ height: 20 }} />

        {this.renderShippingInfo()}

        {this.renderMarketingAgreement()}

        <View style={{ height: 40 }} />

        <View style={styles.fullWidthButtonContainer}>
          <Button
            containerStyle={styles.fullWidthButton}
            titleStyle={styles.fullWidthButtonText}
            onPress={() => this.onPressSave()}
            disabled={!this.validateInputField()}
            disabledOpacity={0.5}
            title="정보 저장"
          />
        </View>

        <View style={{ height: 60 }}/>

        <View style={styles.sectionHeaderContainer}>
          <Text style={styles.sectionHeaderText}>비밀번호 변경</Text>
        </View>

        <ChangePasswordForm />

        <View style={{ height: 20}}/>

        <View style={[styles.membershipWithdrawContainer, styles.fullWidthButtonContainer]}>
          <Button
            containerStyle={[styles.fullWidthButton, styles.membershipWithdrawButton]}
            titleStyle={[styles.fullWidthButtonText, styles.membershipWithdrawButtonText]}
            title="회원탈퇴하기"
            onPress={this.onPressMembershipWithdraw}
          />
        </View>

        <View style={{ height: 40}}/>

      </ScrollView>
    );
  }
}


export default connect(
  state => ({ auth: state.auth }),
  actions
)(EditProfilePage);
