import React from 'react'

import FilterPriceModal from "./FilterPriceModal";


function showFilterPriceModal(parent, priceMin, priceMax, priceFilter, onChange) {
  const {
    modalProps,
    setModalProps,
  } = parent.props;

  const {
    children,
    historyStack=[],
  } = modalProps();

  const newChildren = (
    <FilterPriceModal
      priceMin={priceMin}
      priceMax={priceMax}
      priceFilter={priceFilter}
      onChange={onChange}
      setModalProps={setModalProps}
      modalProps={modalProps}
      />
  );

  historyStack.push(children);

  setModalProps({
    children: newChildren,
    historyStack,
  });
}


export default showFilterPriceModal;
