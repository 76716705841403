import React from 'react';
import {
  StyleSheet, Text, View,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions, loadEntities } from 'shared';
import MyPageHeartist from '../nativeComponents/MyPageHeartist';

import Styles from '../constants/Styles';
import Constants from '../constants/Constants';


const styles = StyleSheet.create({
  container: {
  },
  listHeaderContainer: {
    paddingHorizontal: Constants.viewHorizontalPadding,
    paddingTop: 20,
    paddingBottom: 10,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  listFooterContainer: {
    height: Constants.bottomSpaceForTabBar,
  },
  listHeaderText: {
    fontSize: 20,
    lineHeight: 24,
    height: 24,
    color: 'black',
    ...Styles.appleSDGothicNeoBold,
  },
});

export class HeartistsPage extends React.Component {
  componentDidMount() {
    this.loadData(false);
  }

  loadData(nextPage) {
    const { loadHeartists } = this.props;
    loadHeartists(nextPage);
  }

  renderHeartist(heartist) {
    const { router, followUser, unfollowUser, auth } = this.props;

    return (
      <GridListTile key={heartist._id}>
        <MyPageHeartist
          heartist={heartist}
          router={router}
          followUser={followUser}
          unfollowUser={unfollowUser}
          auth={auth}
        />
      </GridListTile>
    );
  }

  renderListHeader = title => (
    <View style={styles.listHeaderContainer}>
      <Text style={styles.listHeaderText}>
        {title}
      </Text>
    </View>
  )

  renderListFooter = () => (
    <View style={styles.listFooterContainer} />
  )

  render() {
    const { heartists } = this.props;
    const followingHeartists = heartists.filter(heartist => heartist.following);
    const unfollowingHeartists = heartists.filter(heartist => !heartist.following);

    return (
      <View style={styles.container}>

        {this.renderListHeader('모든 하티스트')}

        <GridList
          cellHeight="auto"
          cols={1}
          spacing={0}
        >
          {followingHeartists
            .concat(unfollowingHeartists)
            .map(item => this.renderHeartist(item))}
        </GridList>

        {this.renderListFooter()}

      </View>
    );
  }
}


export default connect(
  state => ({
    auth: state.auth,
    ...loadEntities(state, 'heartists', 'heartists'),
  }),
  actions,
)(HeartistsPage);
