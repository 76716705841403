import React, { Component } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  StyleSheet, View, Text, TouchableOpacity, TextInput,
  TouchableHighlight,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import ga from 'react-ga';

import { actions } from 'react-shared';
import PhoneVerificationForm from '../../../nativeComponents/PhoneVerificationForm';
import CustomDialog from '../../../components/CustomDialog';
import Styles from '../../../constants/Styles';
import Colors from '../../../constants/Colors';
import ListLeftIcon from '../../../icons/ListLeftIcon';
import CheckBox from '../../../nativeComponents/CheckBox';
import EditShippingAddressList from './EditShippingAddressList';


const styles = StyleSheet.create({
  container: {

  },
  sectionHeaderContainer: {
    paddingStart: 20,
    paddingTop: 20,
    paddingBottom: 10,
  },
  sectionHeaderText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 18,
    height: 21,
    lineHeight: 21,
  },
  profileInputContainer: {
    paddingHorizontal: 20,
    paddingTop: 10,
    paddingBottom: 20,
  },
  sectionDivider: {
    height: 10,
    backgroundColor: Colors.semiGray,
    width: '100%',
  },
  inputText: {
    fontSize: 15,
    color: 'black',
    paddingTop: 17,
    paddingBottom: 16,
    paddingHorizontal: 16,
  },
  inputBorder: {
    width: '100%',
    marginBottom: 10,
    backgroundColor: Colors.semiGray,
  },
  itemButton: {

  },
  itemContainer: {
    paddingVertical: 0,
    paddingRight: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  itemText: {
    height: 19,
    lineHeight: 19,
    ...Styles.appleSDGothicNeoMedium,
    fontSize: 15,
    marginTop: 16,
    marginBottom: 15,
  },
  itemDivider: {
    height: 1,
    backgroundColor: Colors.lightGray,
  },
  passwordChangeContainer: {
    marginTop: 4,
    marginLeft: 20,
  },
  membershipWithdrawContainer: {
    borderTopWidth: 1,
    borderTopColor: '#ecedee',
    marginTop: 4,
    marginLeft: 20,
  },
  checkBoxRow: {
    marginTop: 15,
    flexDirection: 'row',
  },
  checkBoxText: {
    height: 19,
    marginTop: 2,
    marginStart: 12,
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    lineHeight: 19,
  },
  marketingAgreementContainer: {
    marginTop: -5,
    paddingBottom: 30,
    paddingHorizontal: 20,
  },
  bottomButtonContainer: {
    flexDirection: 'row',
    paddingTop: 38,
    paddingHorizontal: 20 - 3.5,
    paddingBottom: 20,
  },
  bottomButton: {
    flex: 1,
    marginHorizontal: 3.5,
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomButtonLight: {
    backgroundColor: Colors.semiGray,
  },
  bottomButtonDark: {
    backgroundColor: '#000',
  },
  bottomButtonText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 14,
    height: 17,
    lineHeight: 17,
    marginTop: 1,
  },
  bottomButtonTextDark: {
    color: '#fff',
  },
  errorContainer: {
    width: '100%',
    marginTop: -4,
    marginBottom: 15,
  },
  errorText: {
    flexWrap: 'wrap',
    lineHeight: 20,
    fontSize: 15,
    color: 'rgb(252,43,56)',
    ...Styles.appleSDGothicNeoLight,
  },
  socialTypeContainer: {
    width: '100%',
    marginTop: -4,
    marginBottom: 15,
  },
  socialTypeText: {
    flexWrap: 'wrap',
    lineHeight: 20,
    fontSize: 15,
    color: 'rgb(25, 67, 255)',
    ...Styles.appleSDGothicNeoLight,
  },
});


class EditProfilePage extends Component {
  constructor(props) {
    super(props);
    this.inputForm = React.createRef();
    this.showSnackbar = this.showSnackbar.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    const {
      auth: {
        email,
        name,
        tel,
        birthYear,
        marketingAgreementType: {
          sms: marketingSmsAgreed = false,
          email: marketingEmailAgreed = false,
        } = {},
      },
    } = this.props;

    this.state = {
      snackbarMsg: '',
      snackbarOpen: false,
      birthYear,
      email,
      name,
      phone: tel,
      phoneVerified: true,
      phoneError: '',
      marketingSmsAgreed,
      marketingEmailAgreed,
    };
  }

  phoneNumberInputRef = null;

  onPressChangePassword = () => {
    const { router } = this.props;
    router.push('/accounts/changePassword');
  };

  onPressMembershipWithdraw = () => {
    const { router } = this.props;
    router.push('/accounts/withdraw');
  }

  onPressCancel = () => {
    const { router } = this.props;
    router.push('/accounts/settings');
  };

  onPressSave = async () => {
    if (!this.validateInputField()) {
      return;
    }

    const {
      name,
      phone,
      birthYear,
      marketingSmsAgreed,
      marketingEmailAgreed,
    } = this.state;

    const params = {
      name,
      tel: phone,
      birthYear,
      marketingAgreementType: {
        sms: marketingSmsAgreed,
        email: marketingEmailAgreed,
      },
    };

    const { updateUser, auth, router } = this.props;

    try {
      await updateUser(auth._id, params);
      router.push('/accounts/settings');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error=', error);
      this.showSnackbar('저장 중 오류가 발생하였습니다. 잠시 후 다시 시도해주세요.');
    }
  };

  renderErrorMsg = msg => (
    <View style={styles.errorContainer}>
      <Text style={styles.errorText}>
        {msg}
      </Text>
    </View>
  )

  handleDelete(event) {
    if (confirm('Are your sure to delete your account?')) {  //eslint-disable-line
      this.props.deleteUser(this.props.auth._id).then(
        () => {
          window.location.href = '/';
          ga.event({
            category: 'SignUp',
            action: 'Delete Account',
            label: 'Success',
          });
        },
        () => {
          alert('Please contact to the site admin.');  //eslint-disable-line
          ga.event({
            category: 'SignUp',
            action: 'Delete Account',
            label: 'Fail',
          });
        }
      );
    }
    event.preventDefault();
  }

  validateInputField() {
    const { name, phone, phoneVerified } = this.state;

    if (!name || name === '') {
      this.showSnackbar('이름을 입력하세요.');
      return false;
    }

    if (!phone || phone === '') {
      this.showSnackbar('휴대폰 번호를 입력하세요.');
      return false;
    }

    if (!phoneVerified) {
      this.showSnackbar('휴대폰 번호 인증을 해주세요.');
      return false;
    }

    return true;
  }

  showSnackbar(msg) {
    this.setState({
      snackbarMsg: msg,
      snackbarOpen: true,
    });
  }

  renderSocialAccountType() {
    const { auth } = this.props;

    let socialAccountTypeStr = '';
    if (auth.facebook) {
      socialAccountTypeStr = 'Facebook으로 연결됨';
    } else if (auth.naver) {
      socialAccountTypeStr = 'Naver로 연결됨';
    } else if (auth.kakao) {
      socialAccountTypeStr = 'Kakao로 연결됨';
    } else if (auth.appleId) {
      socialAccountTypeStr = 'Apple ID로 연결됨';
    }

    if (!socialAccountTypeStr) {
      return null;
    }

    return (
      <View style={styles.socialTypeContainer}>
        <Text style={styles.socialTypeText}>
          { socialAccountTypeStr }
        </Text>
      </View>
    );
  }

  renderPhoneVerificationForm() {
    const {
      phone, phoneVerified, phoneIgnoreOldUser,
      phoneError,
      showIgnoreOldUserDialog, ignoreOldUserEmail,
    } = this.state;

    const showIgnoreOldUserAlert = email => {
      this.setState({
        showIgnoreOldUserDialog: true,
        ignoreOldUserEmail: email,
      });
    };

    return (
      <View style={{ width: '100%' }}>
        <PhoneVerificationForm
          onPhoneNumberInputRef={ref => { this.phoneNumberInputRef = ref; }}
          defaultPhoneNumber={phone}
          defaultVerified={phoneVerified}
          showAlert={msg => { this.setState({ phoneError: msg }); }}
          showIgnoreOldUserAlert={oldUserEmail => showIgnoreOldUserAlert(oldUserEmail)}
          ignoreOldUser={phoneIgnoreOldUser}
          onChangePhoneNumber={value => { this.setState({ phone: value }); }}
          verificationChanged={verified => { this.setState({ phoneVerified: verified }); }}
          showErrorBorder={phoneError.length > 0}
        />
        { phoneError.length > 0 && this.renderErrorMsg(phoneError) }
        <CustomDialog
          dialogOpen={showIgnoreOldUserDialog}
          onConfirm={() => {
            this.setState({
              showIgnoreOldUserDialog: false,
              phoneIgnoreOldUser: true,
            });
          }}
          onCancel={() => {
            this.setState({
              showIgnoreOldUserDialog: false,
              phoneIgnoreOldUser: false,
            });
          }}
          onClose={() => {
            this.setState({ showIgnoreOldUserDialog: false });
          }}
          confirmColor="default"
          confirmText="사용하기"
          cancelText="취소"
          title="이미 가입된 휴대폰 번호입니다."
          description={(
            <div>
              {`${ignoreOldUserEmail} 으로 가입된 번호입니다. 그래도 이 번호를 사용하시겠습니까?`}
            </div>
          )}
        />

      </View>
    );
  }

  render() {
    const {
      email, name, birthYear,
      marketingSmsAgreed, marketingEmailAgreed,
    } = this.state;

    return (
      <View style={styles.container}>
        <View style={styles.sectionHeaderContainer}>
          <Text style={styles.sectionHeaderText}>내 정보</Text>
        </View>

        <View style={styles.profileInputContainer}>
          <TextInput
            ref={this.name}
            autoCapitalize="none"
            keyboardType="email-address"
            onChangeText={val => this.setState({ email: val })}
            placeholder="이메일"
            placeholderTextColor={Colors.placeholderColor}
            style={[
              styles.inputBorder,
              styles.inputText,
            ]}
            value={email}
            editable={false}
          />

          {this.renderSocialAccountType()}

          <TextInput
            autoCapitalize="none"
            keyboardType="default"
            textContentType="name"
            onChangeText={val => this.setState({ name: val })}
            placeholder="이름"
            placeholderTextColor={Colors.placeholderColor}
            style={[
              styles.inputBorder,
              styles.inputText,
              { outline: 'none' },
            ]}
            value={name}
            onSubmitEditing={() => {
              if (this.phoneNumberInputRef) {
                this.phoneNumberInputRef.focus();
              }
            }}
          />

          {this.renderPhoneVerificationForm()}

          <TextInput
            autoCapitalize="none"
            keyboardType="numeric"
            textContentType="name"
            onChangeText={val => this.setState({ birthYear: val })}
            placeholder="생년월일 입력 (선택 사항)"
            placeholderTextColor={Colors.placeholderColor}
            style={[
              styles.inputBorder,
              styles.inputText,
            ]}
            value={birthYear}
          />

          {/* <BirthDateSelect
            containerStyle={[styles.inputBorder, styles.inputText]}
            onBirthDateChanged={val => this.setState({ birthYear: val })}
            value={birthYear}
            placeholder='생년월일 입력 (선택 사항)'
          /> */}

        </View>

        <View style={styles.sectionDivider} />

        <View style={styles.sectionHeaderContainer}>
          <Text style={styles.sectionHeaderText}>배송지 정보</Text>
        </View>
        <EditShippingAddressList />

        <View style={styles.sectionDivider} />

        <View style={styles.sectionHeaderContainer}>
          <Text style={styles.sectionHeaderText}>광고 정보 수신</Text>
        </View>

        <View style={styles.marketingAgreementContainer}>
          <View style={styles.checkBoxRow}>
            <CheckBox
              onPress={() => this.setState({ marketingSmsAgreed: !marketingSmsAgreed })}
              checked={marketingSmsAgreed}
            />
            <Text style={[styles.checkBoxText]}>
              문자 메시지
            </Text>
          </View>

          <View style={styles.checkBoxRow}>
            <CheckBox
              onPress={() => this.setState({ marketingEmailAgreed: !marketingEmailAgreed })}
              checked={marketingEmailAgreed}
            />
            <Text style={[styles.checkBoxText]}>
              이메일
            </Text>
          </View>
        </View>

        <View style={styles.sectionDivider} />

        <View style={styles.sectionHeaderContainer}>
          <Text style={styles.sectionHeaderText}>비밀번호 변경</Text>
        </View>

        <View style={styles.passwordChangeContainer}>
          <TouchableHighlight
            underlayColor={Colors.touchEffectColorWhite}
            onPress={this.onPressChangePassword}
            style={styles.itemButton}
          >
            <View style={styles.itemContainer}>
              <Text style={styles.itemText}>비밀번호 변경</Text>
              <ListLeftIcon />
            </View>
          </TouchableHighlight>
        </View>

        <View style={styles.membershipWithdrawContainer}>
          <TouchableHighlight
            underlayColor={Colors.touchEffectColorWhite}
            onPress={this.onPressMembershipWithdraw}
            style={styles.itemButton}
          >
            <View style={styles.itemContainer}>
              <Text style={styles.itemText}>탈퇴하기</Text>
              <ListLeftIcon />
            </View>
          </TouchableHighlight>
        </View>

        <View style={[
          styles.bottomButtonContainer,
          { zIndex: 0 },
        ]}
        >
          <TouchableOpacity
            style={[
              styles.bottomButton,
              styles.bottomButtonLight,
              { zIndex: 0 },
            ]}
            onPress={this.onPressCancel}
          >
            <Text style={[
              styles.bottomButtonText,
              styles.bottomButtonTextLight,
            ]}
            >
              취소
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.bottomButton, styles.bottomButtonDark]}
            onPress={() => this.onPressSave()}
          >
            <Text style={[
              styles.bottomButtonText,
              styles.bottomButtonTextDark,
            ]}
            >
              저장
            </Text>
          </TouchableOpacity>
        </View>
        <Snackbar
          autoHideDuration={2000}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
          style={{ textAlign: 'center' }}
        />
      </View>
    );
  }
}

EditProfilePage.propTypes = {
  auth: PropTypes.object.isRequired,
  deleteUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
};

export default connect(
  state => ({ auth: state.auth }),
  actions
)(EditProfilePage);
