import React from 'react'

import { 
  StyleSheet, View, Text,
} from 'react-native';
import { Styles, Colors, Constants } from '../../../constants';

import {
  TouchableHighlightWithContainer,
} from '../../../components-v2';

import BackIcon from '../../../icons-v2/BackIcon';


const styles = StyleSheet.create({
  container: {
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerTitle: {
    fontSize: 16,
    lineHeight: 19.2,
    marginTop: 23,
    marginBottom: 12,
    ...Styles.pretendard700,
  },
  headerLeftContainer: {
    marginTop: 21,
    marginBottom: 9,
    marginStart: 16,
  },
  headerLeftText: {
    fontSize: 14,
    lineHeight: 16.8,
    marginStart: 4,
    marginTop: 3,
    marginBottom: 4,
    ...Styles.pretendard400,
  },
  headerRightContainer: {
    paddingTop: 24,
    paddingEnd: Constants.viewHorizontalPadding,
    paddingBottom: 13,
  },
  headerRightText: {
    fontSize: 14,
    lineHeight: 16.8,
    color: Colors.defaultGray08,
    ...Styles.pretendard400,
  },
});


class FilterHeader extends React.PureComponent {

  closeModal() {
    const { setModalProps } = this.props;

    setModalProps({
      isVisible: false,
      children: null,
    });
  }

  render() {
    const {
      title,
      leftButtonTitle,
      onPressLeftButton,
    } = this.props;

    return (
      <View style={styles.headerContainer}>
        <View style={styles.headerLeftContainer}>
          <TouchableHighlightWithContainer
            onPress={onPressLeftButton}
            >
            { leftButtonTitle ? (
              <View />
            ) : (
              <BackIcon />
            )}
          </TouchableHighlightWithContainer>
        </View>

        <Text style={styles.headerTitle}>{title}</Text>

        <View style={styles.headerRightContainer}>
          <TouchableHighlightWithContainer
            onPress={() => this.closeModal()}
            >
            <Text style={styles.headerRightText}>닫기</Text>
          </TouchableHighlightWithContainer>
        </View>
    </View>
    );
  }
}


export default FilterHeader;
