import { Cloudinary } from 'cloudinary-core';
import React from 'react';
import config from '../config'

const cloudinary = Cloudinary.new();
cloudinary.config({
  ...config.get('cloudinary'),
  secure: true,
});

export default ({ publicId, style, options }) => {
  return (<img style={{...style}} src={cloudinary.url(publicId, options)} />);
};
