import React from 'react';
import {
  StyleSheet, View, TouchableHighlight, Text,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';

import AppLogoBannerIcon from '../icons/AppLogoBannerIcon';
import CloseBannerButton from '../icons/CloseBannerButton';
import Colors from '../constants/Colors';
import Styles from '../constants/Styles';
import Constants from '../constants/Constants';


const webStyles = {
  container: {
    zoom: '1',
    WebkitBoxSizing: 'borderBox',
    MozBoxSizing: 'borderBox',
    MsBoxSizing: 'borderBox',
    OBoxSizing: 'borderBox',
    boxSizing: 'borderBox',
    background: '#000',
    position: 'relative',
  },
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingVertical: 10.5,
    paddingStart: 12.5,
    paddingEnd: 19 - 4,
  },
  logoButton: {
    width: 52,
    height: 52,
    paddingTop: 11,
    paddingBottom: 9,
    paddingHorizontal: 4,
    borderRadius: 10,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bodyContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'center',
  },
  contentContainerButton: {
    marginTop: 18 - 10.5 - Constants.touchEffectWidth,
    marginStart: 10.5 - Constants.touchEffectWidth,
    marginBottom: 17 - 10.5 - Constants.touchEffectWidth,
    ...Styles.touchEffect,
  },
  contentContainer: {
  },
  titleText: {
    height: 19,
    lineHeight: 19,
    ...Styles.appleSDGothicNeoBold,
    fontSize: 15,
    color: '#fff',
  },
  descText: {
    height: 16,
    marginTop: 3,
    lineHeight: 16,
    ...Styles.appleSDGothicNeoMedium,
    fontSize: 13,
    color: '#fff',
    opacity: 0.7,
  },
  closeIconButton: {
    height: 20,
    width: 20,
    justifyContent: 'center',
    ...Styles.touchEffect,
  },
});

export default class SmartBanner extends React.Component {
  onPressDownload = () => {
    const { platform } = this.props;
    switch (platform) {
    case 'ios':
      window.location.href = 'https://itunes.apple.com/app/heartIt/id1207115066?ls=1&mt=8';
      break;

    case 'android':
      window.location.href = 'market://details?id=kr.heartit.heartit';
      break;

    default:
      break;
    }
  };

  render() {
    const { onClickClose } = this.props;

    return (
      <div style={webStyles.container}>
        <View style={styles.container}>
          <TouchableHighlight
            underlayColor={Colors.touchEffectColorBlack}
            style={styles.logoButton}
            onPress={this.onPressDownload}
          >
            <AppLogoBannerIcon />
          </TouchableHighlight>

          <View style={styles.bodyContainer}>
            <TouchableHighlight
              underlayColor={Colors.touchEffectColorBlack}
              style={styles.contentContainerButton}
              onPress={this.onPressDownload}
            >
              <View style={styles.contentContainer}>
                <Text style={styles.titleText}>하트잇 2.0 앱 다운로드</Text>
                <Text style={styles.descText}>그녀들의 잇명품 쇼핑 데스티네이션 #하트잇</Text>
              </View>
            </TouchableHighlight>

            <TouchableHighlight
              underlayColor={Colors.touchEffectColorBlack}
              style={styles.closeIconButton}
              onPress={onClickClose}
            >
              <CloseBannerButton />
            </TouchableHighlight>
          </View>
        </View>
      </div>
    );
  }
}
