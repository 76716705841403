const SIZE_LIST = [
  { id: '201', type: 'size', subType: 'clothing', name: 'XXS', alias: { US: '0',  UK: '4',  IT: '36', FR: '32', GER: '30', KR: '33',  Numerical: '00', Jeans: '23-24' } },
  { id: '202', type: 'size', subType: 'clothing', name: 'XS',  alias: { US: '2',  UK: '6',  IT: '38', FR: '34', GER: '32', KR: '44',  Numerical: '0',  Jeans: '25-26' } },
  { id: '203', type: 'size', subType: 'clothing', name: 'S',   alias: { US: '4',  UK: '8',  IT: '40', FR: '36', GER: '34', KR: '55',  Numerical: '1',  Jeans: '27' } },
  { id: '204', type: 'size', subType: 'clothing', name: 'M',   alias: { US: '6',  UK: '10', IT: '42', FR: '38', GER: '36', KR: '66',  Numerical: '2',  Jeans: '28' } },
  { id: '205', type: 'size', subType: 'clothing', name: 'L',   alias: { US: '8',  UK: '12', IT: '44', FR: '40', GER: '38', KR: '77',  Numerical: '3',  Jeans: '29' } },
  { id: '206', type: 'size', subType: 'clothing', name: 'XL',  alias: { US: '10', UK: '14', IT: '46', FR: '42', GER: '40', KR: '88',  Numerical: '4',  Jeans: '30' } },
  { id: '207', type: 'size', subType: 'clothing', name: 'XXL', alias: { US: '12', UK: '16', IT: '48', FR: '44', GER: '42', KR: '99',  Numerical: '5',  Jeans: '31' } },
  { id: '208', type: 'size', subType: 'clothing', name: '3XL', alias: { US: '14', UK: '18', IT: '50', FR: '46', GER: '44', KR: '3XL', Numerical: '6',  Jeans: '32' } },
  { id: '209', type: 'size', subType: 'clothing', name: '4XL', alias: { US: '16', UK: '20', IT: '52', FR: '48', GER: '46', KR: '4XL', Numerical: '7',  Jeans: '33' } },
  { id: '211', type: 'size', subType: 'shoes', name: '210', alias: { US: '3.5',  UK: '1'  , 'EU/IT': '34',   FR: '35'   } },
  { id: '212', type: 'size', subType: 'shoes', name: '215', alias: { US: '4',    UK: '1.5', 'EU/IT': '34.5', FR: '35.5' } },
  { id: '213', type: 'size', subType: 'shoes', name: '220', alias: { US: '4.5',  UK: '2'  , 'EU/IT': '35',   FR: '36'   } },
  { id: '214', type: 'size', subType: 'shoes', name: '225', alias: { US: '5',    UK: '2.5', 'EU/IT': '35.5', FR: '36.5' } },
  { id: '215', type: 'size', subType: 'shoes', name: '230', alias: { US: '5.5',  UK: '3'  , 'EU/IT': '36',   FR: '37'   } },
  { id: '216', type: 'size', subType: 'shoes', name: '235', alias: { US: '6',    UK: '3.5', 'EU/IT': '36.5', FR: '37.5' } },
  { id: '217', type: 'size', subType: 'shoes', name: '240', alias: { US: '6.5',  UK: '4'  , 'EU/IT': '37',   FR: '38'   } },
  { id: '218', type: 'size', subType: 'shoes', name: '245', alias: { US: '7',    UK: '4.5', 'EU/IT': '37.5', FR: '38.5' } },
  { id: '219', type: 'size', subType: 'shoes', name: '250', alias: { US: '7.5',  UK: '5'  , 'EU/IT': '38',   FR: '39'   } },
  { id: '220', type: 'size', subType: 'shoes', name: '255', alias: { US: '8',    UK: '5.5', 'EU/IT': '38.5', FR: '39.5' } },
  { id: '221', type: 'size', subType: 'shoes', name: '260', alias: { US: '8.5',  UK: '6',   'EU/IT': '39',   FR: '40'   } },
  { id: '222', type: 'size', subType: 'shoes', name: '265', alias: { US: '9',    UK: '6.5', 'EU/IT': '39.5', FR: '40.5' } },
  { id: '223', type: 'size', subType: 'shoes', name: '270', alias: { US: '9.5',  UK: '7',   'EU/IT': '40',   FR: '41'   } },
  { id: '224', type: 'size', subType: 'shoes', name: '275', alias: { US: '10',   UK: '7.5', 'EU/IT': '40.5', FR: '41.5' } },
  { id: '225', type: 'size', subType: 'shoes', name: '280', alias: { US: '10.5', UK: '8',   'EU/IT': '41',   FR: '42'   } },
];

const SIZE_STANDARD_LIST = [
  { id: '0', type: 'size', subType: 'clothing', name: 'International' },
  { id: '1', type: 'size', subType: 'clothing', name: 'US' },
  { id: '2', type: 'size', subType: 'clothing', name: 'UK' },
  { id: '3', type: 'size', subType: 'clothing', name: 'IT' },
  { id: '4', type: 'size', subType: 'clothing', name: 'FR' },
  { id: '5', type: 'size', subType: 'clothing', name: 'GER' },
  // { i'd': 6, type: 'size', subType: 'clothing', name: 'JPN' },
  { id: '7', type: 'size', subType: 'clothing', name: 'KR' },
  { id: '8', type: 'size', subType: 'clothing', name: 'Numerical' },
  { id: '9', type: 'size', subType: 'clothing', name: 'Jeans' },
  { id: '10', type: 'size', subType: 'shoes', name: 'International' },
  { id: '11', type: 'size', subType: 'shoes', name: 'US' },
  { id: '12', type: 'size', subType: 'shoes', name: 'UK' },
  { id: '13', type: 'size', subType: 'shoes', name: 'EU/IT' },
  { id: '14', type: 'size', subType: 'shoes', name: 'FR' },
];


export default {
  SIZE_LIST,
  SIZE_STANDARD_LIST,
};
