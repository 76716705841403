import React from 'react';
import { Dimensions, StyleSheet, View, Text } from 'react-native';

import { Colors, Constants, Styles } from '../../../../constants';

import {
  Button,
} from '../../../../components-v2';

import _styles from '../../../../css/modules/order.module.css';

const { height: windowHeight } = Dimensions.get('window');

const styleHtml = {
  blankContainer: {
    position: 'absolute',
    top: windowHeight / 2 - 98 / 2 + 64 / 2,
    left: 0,
    width: '100%',
  },
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  separator: {
    height: 0.5,
    backgroundColor: Colors.lineGray,
    marginHorizontal: Constants.viewHorizontalPadding,
  },
  blankContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -1 * (Constants.statusBarHeight + Constants.mainHeaderHeight),
  },
  blankInnerContainer: {
    width: 240,
    height: 98,
    paddingHorizontal: Constants.viewHorizontalPadding,
    flexDirection: 'column',
    alignItems: 'center',
  },
  blankText: {
    fontSize: 16,
    lineHeight: 19.2,
    textAlign: 'center',
    color: Colors.defaultGray08,
    ...Styles.pretendard400,
  },
  blankGoShopButton: {
    height: 40,
    paddingVertical: 10,
    paddingHorizontal: 40,
    borderWidth: 0.5,
    backgroundColor: '#000',
    justifyContent: 'center',
    alignItems: 'center',
  },
  blankGoShopButtonText: {
    color: '#fff',
    fontSize: 13,
    lineHeight: 19.2,
    ...Styles.pretendard600,
  },
});



class Blank extends React.Component {

  render() {
    const { router } = this.props;

    return (
      <div className={_styles.blank_container}>
          <View style={styles.blankContainer}>
          <View style={styles.blankInnerContainer}>
              <Text style={styles.blankText}>주문 내역이 없습니다.</Text>
              <View style={{ height: 40 }} />
              <Button
                containerStyle={styles.blankGoShopButton}
                titleStyle={styles.blankGoShopButtonText}
                onPress={() => { router.push('/shop')}}
                title="쇼핑하러 가기"
                />
          </View>
          </View>
      </div>
      );
  }
}

export default Blank;
