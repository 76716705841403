import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  const { color = '#000' } = props;

  return (
    <Svg
      width='100%'
      height='100%'
      viewBox="0 0 335 159"
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M335 0H0v69.088C4.131 70.984 7 75.157 7 80c0 4.843-2.869 9.016-7 10.912V159h335V90.912c-4.131-1.896-7-6.069-7-10.912 0-4.843 2.869-9.016 7-10.912V0z"
        fill={color}
      />
    </Svg>
  )
}

export default SvgComponent

