import get from 'lodash/get';
import set from 'lodash/set';

const CLOUDINARY_CDN = 'img7.heartit.kr';

const cloudinaryDefault = {
  cloud_name: 'heartit',
  api_key: '774868752424468',
  upload_preset: 'pn3ose6m',
  video_upload_preset: 'tx8gt27e',
  cname: CLOUDINARY_CDN,
  secure_distribution: CLOUDINARY_CDN,
};

const cloudinaryProd = Object.assign({}, cloudinaryDefault, { secure: true });

const config = {
  replica: {
    cloudinary: cloudinaryDefault,
    shorten: 'http://app.dev.heartit.kr/ss/',
    ga: {
      id: 'UA-70022523-2',
    },
    pixelId: '2194357560730630',
    extraHeaders: {
      'heartit-agent': 'web',
    },
    kakao: {
      jsKey: '6464bc1f819b05ed51da5ac4a609cdc5',
    },
    chatInquiryUrl: 'https://lc1.lunasoft.co.kr/lunachat/api-connect/@하트잇/main',
    godomallMobileBaseUrl: 'http://m.heartitr9527.godomall.com/',
    preOrderCateCd: '001',
    categoryThumbnailFolder: 'category-thumbnails-dev',
  },
  development: {
    cloudinary: cloudinaryDefault,
    shorten: 'http://localhost:3300/ss/',
    ga: {
      id: 'UA-70022523-2',
    },
    pixelId: '2194357560730630',
    extraHeaders: {
      'heartit-agent': 'web',
    },
    kakao: {
      jsKey: '6464bc1f819b05ed51da5ac4a609cdc5',
    },
    chatInquiryUrl: 'https://lc1.lunasoft.co.kr/lunachat/api-connect/@하트잇/main',
    godomallMobileBaseUrl: 'http://m.heartitr9527.godomall.com/',
    preOrderCateCd: '001',
    categoryThumbnailFolder: 'category-thumbnails-dev',
  },
  staging: {
    cloudinary: cloudinaryProd,
    shorten: ' https://app.stage.heartit.kr/ss/',
    ga: {
      id: 'UA-70022523-1',
    },
    pixelId: '1723364567887145',
    extraHeaders: {
      'heartit-agent': 'web',
    },
    kakao: {
      jsKey: '71a72781547f64a0dc2ca15d21a92186',
    },
    chatInquiryUrl: 'https://lc1.lunasoft.co.kr/lunachat/api-connect/@하트잇/main',
    godomallMobileBaseUrl: 'http://heartitr9527.godomall.com/',
    categoryThumbnailFolder: 'category-thumbnails',
    preOrderCateCd: '003',
  },
  production: {
    cloudinary: cloudinaryProd,
    shorten: 'https://heartit.kr/ss/',
    ga: {
      id: 'UA-70022523-1',
    },
    pixelId: '1723364567887145',
    extraHeaders: {
      'heartit-agent': 'web',
    },
    kakao: {
      jsKey: 'dfe771d1d4f37424f281429b225de5ea',
    },
    chatInquiryUrl: 'https://lc1.lunasoft.co.kr/lunachat/api-connect/@하트잇/main',
    godomallMobileBaseUrl: 'http://m.heartitr9527.godomall.com/',
    preOrderCateCd: '003',
    categoryThumbnailFolder: 'category-thumbnails',
  },
}[process.env.NODE_ENV || 'development'];

export default {
  set: (path, value) => set(config, path, value),
  get: path => get(config, path),
};
