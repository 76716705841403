import React from 'react'

import ShippingAddressSelectListPage from './ShippingAddressSelectListPage';


export function showShippingAddressSelectList(parent, selectedAddressId, addressSelectedCallback) {
  const { setModalProps } = parent.props;

  const children = (
    <ShippingAddressSelectListPage
      setModalProps={setModalProps}
      selectedAddressId={Number(selectedAddressId)}
      addressSelectedCallback={addressSelectedCallback}
      />
  )

  setModalProps({
    isVisible: true,
    children,
  });
}
