import React from 'react';
import { StyleSheet, View, Text } from 'react-native';

import { Constants, Styles } from '../../../constants';

import {
  Button,
} from '../../../components-v2';

import {
  ChevronDownIcon,
} from '../../../icons-v2';

const styles = StyleSheet.create({
  container: {

  },
  headerContainer: {
    flexShrink: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: Constants.viewHorizontalPadding,
    paddingTop: 13,
    paddingBottom: 12,
    width: '100%',
    backgroundColor: '#ffffff',
  },
  headerSelectAllContainer: {
    flexDirection: 'row'
  },
  headerText: {
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
    ...Styles.pretendard600,
  },
  headerButtonContainer: {
    flexDirection: 'row',
    paddingTop: 1,
    marginHorizontal: -25 / 2,
  },
  headerButton: {
    marginVertical: -1 * Constants.touchEffectWidth,
    marginHorizontal: 25 / 2 - 1 * Constants.touchEffectWidth,
    padding: Constants.touchEffectWidth,
    borderRadius: Constants.touchEffectBorderRadius,
    borderWidth: 0,
    borderColor: 'transparent',
  },
  headerButtonText: {
    fontSize: 14,
    lineHeight: 16.8,
    height: 17,
    ...Styles.pretendard400,
  },
  itemSeperator: {
    height: 0.5,
    backgroundColor: '#cfcfcf',
    marginHorizontal: Constants.viewHorizontalPadding,
    marginTop: 10,
  },
});

class Header extends React.Component {
  render() {
    const {
      disabled,
      followingBrands=[],
      sortingRule={},
      onPressSortingRule
    } = this.props;

    return (
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <View style={styles.headerSelectAllContainer}>
            <Text style={[styles.headerText, disabled ? { opacity: 0.5 } : {}]}>전체 브랜드 {followingBrands.length}개</Text>
          </View>
          <View style={styles.headerButtonContainer}>
            <Button
              containerStyle={styles.headerButton}
              innerComponent={(
                <View style={{flexDirection: 'row', alignItems: 'center',}}>
                  <Text style={[styles.headerButtonText, { marginEnd: 6 }]}>{sortingRule.name}</Text>
                  <ChevronDownIcon />
                </View>
              )}
              disabled={disabled}
              disabledOpacity={0.5}
              onPress={onPressSortingRule}
            />
          </View>
        </View>
        <View style={styles.itemSeperator} />
      </View>
    );
  }
}

export default Header;
