import * as React from 'react';

function SvgIcTabShopSelected(props) {
  const { active = true } = props;

  const border = active ? '#fff' : '#000';
  const body = active ? '#000' : '#fff';

  return (
    <svg width={24} height={24} {...props}>
      <defs>
        <path id="icTabShopSelected_svg__a" d="M0 0h8v8H0z" />
        <path id="icTabShopSelected_svg__c" d="M0 0h8v8H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill={border} stroke={body} strokeWidth={1.2} d="M22 22H2V2h20z" />
        <g transform="translate(15 2)">
          <mask id="icTabShopSelected_svg__b" fill={border}>
            <use xlinkHref="#icTabShopSelected_svg__a" />
          </mask>
          <path
            stroke={body}
            strokeWidth={1.7}
            d="M9 0v2.042l-.005.203C8.891 4.405 7.142 6.125 5 6.125c-2.21 0-4-1.828-4-4.083h0V0"
            mask="url(#icTabShopSelected_svg__b)"
          />
        </g>
        <g transform="matrix(-1 0 0 1 9 2)">
          <mask id="icTabShopSelected_svg__d" fill={border}>
            <use xlinkHref="#icTabShopSelected_svg__c" />
          </mask>
          <path
            stroke={body}
            strokeWidth={1.7}
            d="M9 0v2.042l-.005.203C8.891 4.405 7.142 6.125 5 6.125c-2.21 0-4-1.828-4-4.083h0V0"
            mask="url(#icTabShopSelected_svg__d)"
          />
        </g>
        <path
          stroke={body}
          strokeWidth={1.7}
          d="M16 1v3.042l-.005.203c-.104 2.16-1.853 3.88-3.995 3.88-2.21 0-4-1.828-4-4.083V1"
        />
      </g>
    </svg>
  );
}

export default SvgIcTabShopSelected;
