/* eslint-disable import/prefer-default-export */
import get from 'lodash/get';
import { normalize } from 'normalizr';
import qs from 'qs';

import createFetchAction from '../util/createFetchActionWithShopAuth';
import { getNextPageUrl } from './shop';
import * as schemas from '../schemas';

const shippingAddressPaginationKey = 'shippingAddresses';

export function loadShippingAddressList(nextPage, query) {
  const key = shippingAddressPaginationKey;
  // console.log('loadShippingAddressList nextPage=', nextPage);

  return createFetchAction({
    type: 'LOAD_SHOP_USER_SHIPPING_ADDRESS',
    method: 'GET',
    endpoint: state => {
      const base = `/api/v2/shop/user/shipping?${qs.stringify(query)}`;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    request: {
      pagination: { key },
      payload: { isFetching: true },
    },
    transform: ({ json, response }) => {
      // console.log('loadShippingAddressList json=', json);

      if (json.success !== true) {
        return {};
      }

      const { data: { shippingList } } = json;

      // console.log('loadShippingAddressList shippingList=', shippingList);
      const payload = normalize(shippingList, schemas.shippingAddresses);
      // console.log('loadShippingAddressList payload=', payload);
      payload.nextPageUrl = getNextPageUrl(response);

      // console.log('loadShippingAddressList payload=', payload);

      return payload;
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
      // meta: { entities: { update: false } },
    },
  });
}

export function createShippingAddress(params) {
  return createFetchAction({
    type: 'CREATE_SHIPPING_ADDRESS',
    method: 'POST',
    body: params,
    endpoint: '/api/v2/shop/user/shipping',
    doDispatch: false,
  });
}

export function updateShippingAddress(shippingAddressId, params) {
  const key = shippingAddressPaginationKey;

  return createFetchAction({
    type: 'UPDATE_SHIPPING_ADDRESS',
    endpoint: `/api/v2/shop/user/shipping/${shippingAddressId}`,
    method: 'PUT',
    body: params,
    transform: ({ json, state }) => {
      if (!json.success) {
        return {};
      }

      const { shippingAddresses } = state.entities;

      const newShippingAddress = {
        ...shippingAddresses[shippingAddressId],
        ...params,
      };

      const { defaultFl } = params;
      if (defaultFl === 'y') {
        Object.values(shippingAddresses).forEach(value => { value.defaultFl = 'n'; });
      }

      shippingAddresses[shippingAddressId] = newShippingAddress;

      const payload = {
        result: get(state.pagination, key, {}).ids,
        entities: {
          shippingAddresses,
        },
        nextPageUrl: get(state.pagination, key, {}).nextPageUrl,
      };

      return payload;
    },
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}


export function deleteShipppingAddress(shippingAddressId) {
  const key = shippingAddressPaginationKey;

  return createFetchAction({
    type: 'DELETE_SHIPPING_ADDRESS',
    endpoint: `/api/v2/shop/user/shipping/${shippingAddressId}`,
    method: 'DELETE',
    transform: ({ json, state }) => {
      if (!json.success) {
        return {};
      }

      const { shippingAddresses } = state.entities;

      delete shippingAddresses[shippingAddressId];

      const { ids } = get(state.pagination, key, {});
      const index = ids.indexOf(shippingAddressId);
      if (index >= 0) {
        ids.splice(index, 1);
      }

      const payload = {
        result: ids,
        entities: {
          shippingAddresses,
        },
        nextPageUrl: get(state.pagination, key, {}).nextPageUrl,
      };

      return payload;
    },
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}


export function getAddressSearchResult(keyword) {
  return createFetchAction({
    type: 'GET_SHOP_USER_ADDRESS_SEARCH_RESULT',
    method: 'POST',
    body: {
      keyword,
    },
    endpoint: '/api/v2/shop/user/shipping/search',
    doDispatch: false,
  });
}

export function getUserSummary() {
  return createFetchAction({
    type: 'GET_USER_SUMMARY',
    method: 'GET',
    endpoint: '/api/v2/shop/user/summary',
    transform: ({ json, state }) => {
      // console.log('GET_USER_SUMMARY', json);
      if (json.success !== true) {
        return {};
      }

      const { ...newAuth } = state.auth;
      return {
        auth: Object.assign(newAuth, { summary: json.data }),
      };
    },
  });
}

export function getUserCouponList(nextPage, query) {
  const key = 'userCouponList';
  const base = `/api/v2/shop/user/coupon?${qs.stringify(query)}`;

  return createFetchAction({
    type: 'LOAD_USER_COUPON_LIST',
    method: 'GET',
    endpoint: state => {
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      console.log(nextPage ? next : base);
      return nextPage ? next : base;
    },
    transform: ({ json }) => {
      if (json.success !== true) {
        return {};
      }

      const result = {};

      Object.assign(result, normalize(json.data.list, schemas.userCouponList));

      let nextPageUrl = null;
      try {
        if (parseInt(json.data.page.now, 10) < parseInt(json.data.page.end, 10)) {
          const newNextPage = parseInt(json.data.page.now, 10) + 1;
          nextPageUrl = base + '&page=' + newNextPage;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      Object.assign(result, { nextPageUrl });
      return result;
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}

export function getUserMileageList(nextPage, query) {
  const key = 'userMileageList';
  const base = `/api/v2/shop/user/mileage?${qs.stringify(query)}`;

  return createFetchAction({
    type: 'LOAD_USER_MILEAGE_LIST',
    method: 'GET',
    endpoint: state => {
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      console.log(nextPage ? next : base);
      return nextPage ? next : base;
    },
    transform: ({ json }) => {
      if (json.success !== true) {
        return {};
      }

      const result = {};

      Object.assign(result, normalize(json.data.list, schemas.userMileageList));

      let nextPageUrl = null;
      try {
        if (parseInt(json.data.page.now, 10) < parseInt(json.data.page.end, 10)) {
          const newNextPage = parseInt(json.data.page.now, 10) + 1;
          nextPageUrl = base + '&page=' + newNextPage;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      Object.assign(result, { nextPageUrl });
      return result;
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}

export function withdrawMember() {
  return createFetchAction({
    type: 'WITHDRAW_MEMBER',
    method: 'POST',
    endpoint: '/api/v2/shop/user/withdraw',
    doDispatch: false,
  });
}
