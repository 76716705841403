import React from 'react';
import {
  StyleSheet, View, Text,
} from 'react-native';
import NumberFormat from 'react-number-format';

import { formatPrice } from 'shared/util';
import { Colors, Styles } from '../constants';
import { getCrawlerProductImageStyle } from 'shared/util/product';


const stylesHtml = {
  itemImage: {
    width: 112,
    height: 149,
    objectFit: 'cover',
    marginRight: 16,
    border: 'solid',
    borderWidth: 1,
    borderColor: Colors.productImageBorder,
    overflow: 'hidden',
  },
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  itemContainer: {
    flexDirection: 'column',
  },
  itemContentContainer: {
    flexDirection: 'row',
  },
  itemContent: {
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  itemBrandNameContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: 2,
  },
  itemBrand: {
    fontSize: 14,
    lineHeight: 16.8,
    color: '#000',
    marginBottom: 2,
    ...Styles.pretendard600,
  },
  itemName: {
    lineHeight: 16.8,
    fontSize: 14,
    color: '#000',
    marginBottom: 2,
    ...Styles.pretendard400,
  },
  optionQuantityContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  quantityText: {
    color: '#000',
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard400,
  },
  itemPriceContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  itemOrgPriceContainer: {
    flexDirection: 'row',
  },
  itemSellPrice: {
    marginTop: 4,
    fontSize: 16,
    lineHeight: 19.2,
    color: '#000',
    ...Styles.pretendard400,
  },
  itemOrgPrice: {
    lineHeight: 16.8,
    fontSize: 14,
    color: '#000',
    ...Styles.pretendard400,
  },
  lineThrough: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  preOrderLabelContainer: {
    flexDirection: 'row',
    marginBottom: 8,
    marginTop: 1,
  },
  preOrderLabel: {
    borderRadius: 3,
    borderWidth: 0.5,
    borderColor: '#000',
    height: 17,
    paddingHorizontal: 4,
    paddingVertical: 0,
    backgroundColor: '#fff',
  },
  preOrderLabelText: {
    fontSize: 10,
    color: '#000',
    lineHeight: 17,
    ...Styles.appleSDGothicNeoSemiBold,
  },
});


export function getOrderProductInfo(item) {
  const itemOrgPrice = (Number.parseInt(item.fixedPrice, 10) + Number.parseInt(item.optionPrice, 10))
  const itemSellPrice = (Number.parseInt(item.goodsPrice, 10) + Number.parseInt(item.optionPrice, 10))
  const discount = Math.round(100 * (1 - itemSellPrice / itemOrgPrice));

  const optionList = item.optionInfo.map(element => {
    const { optionName, optionValue, optionPrice } = element;
    let optionPriceInfo = '';
    // console.log('renderItem optionPrice', optionPrice);
    if (optionPrice !== undefined) {
      const optionPriceInt = Number.parseInt(optionPrice, 10);
      if (optionPriceInt !== 0) {
        optionPriceInfo = ` (${formatPrice(optionPriceInt)})`;
      }
    }

    return (
      <Text key={`option_${optionValue}`} style={styles.itemName}>
        {optionValue}
      </Text>
    );
  });

  return {
    goodsImageURL: item.imageSrc,
    brandNm: item.brandNm,
    itemName:item.goodsNm,
    itemCount:item.goodsCnt,
    itemOrgPrice:itemOrgPrice,
    itemSellPrice:itemSellPrice,
    discount:discount,
    optionList:optionList,
    product: item,
  };
}

class ProductInfo extends React.Component {

  renderItemInfo = () => {
    const { type, goodsImageURL, brandNm, itemName,
      itemCount, itemOrgPrice, itemSellPrice, discount,
      optionList, product, enableImageFitContain = true,
    } = this.props;

    const crawlerImageStyle = getCrawlerProductImageStyle(product, enableImageFitContain);
    const crawlerImageWebStyle = {
      objectFit: crawlerImageStyle.resizeMode,
      backgroundColor: crawlerImageStyle.backgroundColor
    };

    return (
      <View style={styles.container}>
        <View style={styles.itemContentContainer}>
          <img
            referrerPolicy="no-referrer"
            alt=""
            style={{...stylesHtml.itemImage, ...crawlerImageWebStyle}}
            src={goodsImageURL}
          />
          <View style={styles.itemContent}>
            <View style={styles.itemBrandNameContainer}>
              <Text style={styles.itemBrand}>{brandNm}</Text>
              <Text
                style={styles.itemName}
                multiline={true}
                numberOfLines={(type == "E") ? 2: 1}
              >
                {itemName}
              </Text>
              <View style={styles.optionQuantityContainer}>
                {optionList.length > 0 ? (
                  optionList
                ): (
                  <Text key={`option_undefined`} style={styles.itemName}>
                    단일 옵션
                  </Text>
                )}

                {(type == "B" || type == "D" || type == "E") && (
                  <Text style={styles.quantityText}>{` - ${itemCount}개`}</Text>
                )}
              </View>
            </View>

            <View style={{ height: (type == "A" || type == "B") ? 20: 10 }}/>

            <View style={styles.itemPriceContainer}>
              <View style={styles.itemOrgPriceContainer}>
                <NumberFormat
                  value={itemOrgPrice}
                  displayType="text"
                  thousandSeparator
                  renderText={value => (
                    <Text style={[styles.itemOrgPrice, styles.lineThrough]}>
                      {`${value}원`}
                    </Text>
                  )}
                />
                <Text
                  style={[styles.itemOrgPrice, {fontWeight: '600', color: Colors.primaryRed, marginStart:6 }]}
                >
                  {`${discount}%`}
                </Text>
              </View>

              <NumberFormat
                value={itemSellPrice}
                displayType="text"
                thousandSeparator
                renderText={value => (
                  <Text style={styles.itemSellPrice}>
                    {`${value}원`}
                  </Text>
                )}
              />
            </View>
          </View>
        </View>
      </View>
    );
  }

  render() {
    return (
      <View style={styles.itemContainer}>
        {this.renderItemInfo()}
      </View>
    );
  }
}


export default ProductInfo;
