import React from 'react';
import { StyleSheet, View, Text, FlatList, TouchableHighlight, Dimensions } from 'react-native';

import config from '../../../../config';
import { Constants, Styles } from '../../../../constants';
import { Image } from '../../../../components-v2';
import CloudinaryImage from '../../../../components-v2/CloudinaryImage';
import CloudinaryVideo from '../../../../components-v2/CloudinaryVideo';

import HomeFeedSectionHeader from './HomeFeedSectionHeader';

import _styles from '../../../../css/modules/carousel.module.css';
import { ArrowForwardIosIcon, ArrowBackIosIcon } from '../../../../icons-v2';

const { width: windowWidth } = Dimensions.get('window');

const styleHtml = {
  image: {
    resizeMode: 'cover',
    width: '100%',
  },
};

const styles = StyleSheet.create({
  container: {
  },
  listContainer: {
  },
  itemSeparator: {
    width: 2,
  },
  itemContainer: {
  },
  itemContainerSmall: {
    width: 200,
  },
  title: {
    ...Styles.appleSDGothicNeoMedium,
    paddingStart: Constants.viewHorizontalPadding,
    marginTop: 6.62,
    lineHeight: 12.5,
    fontSize: 10,
  },
});


class FeedPostList extends React.PureComponent {

  listRef = React.createRef();

  state = {
    isLeftEnd:false,
    isRightEnd:false,
    nowIndex:0
  }

  handleLeftClickEvent(e) {

    const nowIndex = this.state.nowIndex;
    let newIndex = 0;
    if(nowIndex > 2){
      newIndex = nowIndex - 2;
      this.listRef.scrollToIndex({animated: true, index: newIndex});
    }else{
      newIndex = 0;
      this.listRef.scrollToOffset({animated: true, index: newIndex});
    }
    this.setState({nowIndex : newIndex});
  }

  handleRightClickEvent(e) {
    const nowIndex = this.state.nowIndex;
    let newIndex = nowIndex + 2;
    try{
      this.listRef.scrollToIndex({animated: true, index: newIndex});
      this.setState({nowIndex : newIndex});
    }catch(e){

    }
  }

  renderItem(post, aspectRatio, itemContainerStyle) {
    return (
      <View key={post._id} style={[styles.itemContainer, itemContainerStyle]}>
        <TouchableHighlight onPress={ post.onPress }>
          { post.thumbnail.resourceType === 'video' ? (
            <CloudinaryVideo
              publicId={post.thumbnail.publicId}
              resizeMode="cover"
              aspectRatio={aspectRatio}
              style={styleHtml.image}
              options={{
                crop: 'limit',
                format: 'jpg',
                height: 640,
                width: 640,
                start_offset: 0,
              }}
              thumbnailOnly={true}
            />
          ) : (
            <CloudinaryImage
              publicId={post.thumbnail.publicId}
              style={styleHtml.image}
              aspectRatio={aspectRatio}
            />
          )}
        </TouchableHighlight>
      </View>
    )
  }

  renderContent() {
    const {
      posts
    } = this.props;

    const {
      isLeftEnd,
      isRightEnd,
    } = this.state;
    // Show 1 post
    if (posts.length == 1) {
      return this.renderItem(posts[0], 355/240, { marginLeft: 20 })
    }

    // Show posts
    return (
      <div className={_styles._desktop_carousel}>
        <FlatList
          ref={ref => this.listRef = ref }
          keyExtractor={(item, idx) => { return `${idx}`}} // eslint-disable-line no-underscore-dangle
          data={posts}
          renderItem={({ item }) => this.renderItem(item, 200/240, styles.itemContainerSmall)}
          style={styles.listContainer}
          ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
          horizontal
          showsHorizontalScrollIndicator={false}
          ListHeaderComponent={<View style={{ width: 20 }}/>}
          ListFooterComponent={<View style={{ width: 20 }}/>}
        />
        {!isLeftEnd &&
        <div className={_styles._desktop_carousel__left}
          onClick={this.handleLeftClickEvent.bind(this)}>
          <ArrowBackIosIcon
            color={"#333333"}
            style={{position:'absolute', top:'50%', left:'50%', width:"24px", height:'24px', transform:'translate(-50%, -50%)'}}/>
        </div>}
        {!isRightEnd &&
        <div className={_styles._desktop_carousel__right}
          onClick={this.handleRightClickEvent.bind(this)}>
          <ArrowForwardIosIcon
            color={"#333333"}
            style={{position:'absolute', top:'50%', left:'50%', width:"24px", height:'24px', transform:'translate(-50%, -50%)'}}/>
        </div>}
      </div>
    )
  }

  render() {
    const {
      post,
      title,
      onPressSeeAll=null,
    } = this.props;

    const headerProps = {}
    if (onPressSeeAll != null) {
      headerProps.showSeeAll = true
      headerProps.onPressSeeAll = onPressSeeAll
    }

    return (
      <View style={styles.container}>
        <HomeFeedSectionHeader
          title={title}
          {...headerProps}
          />
        {this.renderContent()}
      </View>
    );
  }
}


export default FeedPostList
