import React from 'react';
import PropTypes from 'prop-types';
import {
  Dimensions, View, StyleSheet,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

import Spinner from '../../../components/Spinner';
import Styles from '../../../constants/Styles';
import Constants from '../../../constants/Constants';
import PreOrderProductList from './PreOrderProductList';

const PRODUCT_PER_PAGE = 6;

const { height: windowHeight } = Dimensions.get('window');

const HEADER_HEIGHT = 48;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 10,
    minHeight: windowHeight - Constants.mainHeaderHeight - Constants.tabbarHeight - 48,
  },
  headerContainer: {
    height: HEADER_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginEnd: 6,
  },
  headerCategoryButton: {
    height: 32,
  },
  headerCategoryContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginEnd: -4,
  },
  headerCategoryText: {
    fontSize: 16,
    ...Styles.SFUITextSemiBold,
    height: 19,
    marginTop: 3,
    marginBottom: 2,
    marginStart: 6,
  },
  headerButton: {
    marginStart: 12,
  },
  optionContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});

const stylesHtml = {
  appBar: {
    top: Constants.mainHeaderHeight + Constants.tabbarHeight,
    shadows: ['none'],
  },
  appBarWithSmartBanner: {
    top: Constants.mainHeaderHeight + Constants.tabbarHeight + Constants.smartBannerHeight,
  },
};

const subTabHeight = 19 + 4 * 2;

const useStyles = () => ({
  subTabs: {
    backgroundColor: '#fff',
    paddingLeft: 8,
  },
  subTabIndicator: {
    height: 0,
  },
  subTab: {
    color: '#000',
    opacity: 0.4,
    padding: 4,
    marginLeft: 8,
    marginRight: 8,
    marginTop: 15,
    marginBottom: 6,
    height: subTabHeight,
    minHeight: subTabHeight,
    borderRadius: 4,
    minWidth: 1,
  },
  subTabSelected: {
    opacity: 1,
  },
  subTabWrapper: {
    height: 19,
    ...Styles.appleSDGothicNeoSemiBold,
    fontSize: 15,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


class ShopTabSubTab extends React.Component {
  state = {
    index: 0,
    currentTab: '',
    tabRoutes: [],
  };

  isPreOrder = false;

  constructor(props) {
    super(props);

    const { categoryName } = this.props;
    this.isPreOrder = categoryName === 'PRE ORDER';
  }

  componentDidMount() {
    const { tabRoutes, subCategoryId } = this.props;
    if (tabRoutes && tabRoutes.length > 0) {
      let index = tabRoutes.findIndex(element => element.key === subCategoryId);
      if (index === -1) {
        index = 0;
      }

      this.setState({
        tabRoutes,
        index,
        currentTab: tabRoutes[index].key,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    const { shopScreenTab, tabKey } = nextProps;

    if (shopScreenTab === tabKey) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { shopScreenTab, subCategoryId } = this.props;
    const { tabRoutes, currentTab } = this.state;

    if ((prevState.tabRoutes.length === 0 && tabRoutes.length > 0)
        || prevState.currentTab !== currentTab
        || prevProps.shopScreenTab !== shopScreenTab
    ) {
      this.loadData(false);
    }

    if (prevProps.subCategoryId !== subCategoryId) {
      let index = tabRoutes.findIndex(element => element.key === subCategoryId);
      if (index === -1) {
        index = 0;
      }

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        tabRoutes,
        index,
        currentTab: tabRoutes[index].key,
      });
    }
  }

  componentWillUnmount() {
    this.setState = () => null;
  }

  handleIndexChange = (event, index) => {
    const { tabRoutes } = this.state;
    if (tabRoutes.length <= 0) {
      return;
    }

    const { categoryId, router } = this.props;
    router.push(`/shop?categoryId=${categoryId}&subCategoryId=${tabRoutes[index].key}`);

    this.setState({
      index,
      currentTab: tabRoutes[index].key,
    });
  }

  onPressProduct = product => () => {
    const { router } = this.props;
    router.push(`/product/${product.goodsNo}`);
  }

  loadData = nextPage => {
    const { loadProducts } = this.props;
    const { currentTab } = this.state;

    const query = {
      cateCd: currentTab,
      count: PRODUCT_PER_PAGE,
    };

    loadProducts(query, nextPage);
  }


  renderScene = () => {
    const { currentTab } = this.state;
    const { index, tabRoutes } = this.state;
    const { title } = tabRoutes[index];
    const { router } = this.props;

    return (
      <View style={styles.container}>
        <PreOrderProductList
          categoryId={currentTab}
          categoryName={title}
          loadData={this.loadData}
          router={router}
        />
      </View>
    );
  }

  render() {
    const { classes, showSmartBanner } = this.props;
    const { index, tabRoutes: routes } = this.state;

    if (routes.length <= 0) {
      return (<Spinner />);
    }

    return (
      <div>
        <AppBar
          position="sticky"
          style={{
            ...stylesHtml.appBar,
            ...(showSmartBanner && stylesHtml.appBarWithSmartBanner),
          }}
          elevation={0}
        >
          <Tabs
            value={index}
            onChange={this.handleIndexChange}
            // variant="fullWidth"
            classes={{
              root: classes.subTabs,
              indicator: classes.subTabIndicator,
            }}
          >
            {routes.map(route => (
              <Tab
                key={route.key}
                label={route.title}
                classes={{
                  root: classes.subTab,
                  selected: classes.subTabSelected,
                  wrapper: classes.subTabWrapper,
                }}
                TouchRippleProps={{ classes: {
                  rippleVisible: classes.rippleVisible,
                } }}
              />
            ))}
            <div style={stylesHtml.tabbarBorderBottom} />
          </Tabs>
        </AppBar>

        {routes.map((route, routeIndex) => (
          <TabPanel className={classes.tabs} key={route.key} value={routeIndex} index={index}>
            {this.renderScene(route)}
          </TabPanel>
        ))}
      </div>
    );
  }
}

export default withStyles(useStyles)(connect(
  state => ({
    showSmartBanner: state.globalLayoutInfo.showSmartBanner,
  }),
)(ShopTabSubTab));
