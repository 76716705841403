import Parser from 'url-parse';

export function getChangedUrl(url, protocol, host, pathname, query) {
  if (!url) {
    return null;
  }
  const urlObj = new Parser(url, true);
  if (protocol) {
    urlObj.set('protocol', protocol);
  }
  if (host) {
    urlObj.set('host', host);
  }
  if (pathname) {
    urlObj.set('pathname', pathname);
  }
  if (query) {
    Object.keys(query).forEach(key => {
      urlObj.query[key] = query[key];
    });
  }
  return urlObj.toString();
}

export function getParsedUrlObj(url) {
  if (!url) {
    return null;
  }
  return new Parser(url, true);
}
