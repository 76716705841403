import React from 'react';
import {
  StyleSheet, ScrollView, View, Text,
} from 'react-native';
import { connect } from 'react-redux';

import { actions } from 'shared';

import CollapseSection from '../../nativeComponents/CollapseSection';

import Styles from '../../constants/Styles';
import Colors from '../../constants/Colors';
import Constants from '../../constants/Constants';


const styles = StyleSheet.create({
  container: {
  },
  scrollContainer: {
    paddingHorizontal: Constants.viewHorizontalPadding,
    paddingBottom: Constants.bottomTabBarHeight,
  },
  sectionContainer: {
    borderColor: Colors.lightGray,
    borderBottomWidth: 1,
  },
  sectionTouchStyle: {
  },
  sectionTitleContainer: {
    paddingVertical: 20,
  },
  sectionTitleText: {
    fontSize: 16,
    lineHeight: 19,
    ...Styles.appleSDGothicNeoBold,
  },
  sectionContentsContainer: {
    marginBottom: 10,
    marginTop: -4,
  },
  faqItemContainer: {
    backgroundColor: 'white',
    borderTopWidth: 1,
    borderColor: Colors.lightGray,
  },
  faqItemTitleContainer: {
    paddingVertical: 10,
  },
  faqItemDescriptionContainer: {
    paddingVertical: 18,
    paddingHorizontal: 20,
    backgroundColor: Colors.semiGray,
    marginBottom: 10,
  },
  faqItemTitleText: {
    fontSize: 14,
    lineHeight: 17,
    ...Styles.appleSDGothicNeoMedium,
  },
  faqItemDescriptionText: {
    fontSize: 14,
    lineHeight: 20,
    ...Styles.appleSDGothicNeoRegular,
  },
});


class FAQPage extends React.Component {
  componentDidMount() {
    const { loadFAQ, logEventPageview } = this.props;

    loadFAQ();

    logEventPageview({ page_name: 'FAQ' });
  }

  renderFaqItem = (faqItem, initialCollapse) => {
    const { _id, subject, content } = faqItem;
    return (
      <CollapseSection
        outerTouch={false}
        key={_id}
        iconSize='small'
        initialCollapse={initialCollapse}
        title={subject}
        titleContainerStyle={styles.faqItemTitleContainer}
        titleTextStyle={styles.faqItemTitleText}
        children={
          <View style={styles.faqItemDescriptionContainer}>
            <Text style={styles.faqItemDescriptionText}>
              {content}
            </Text>
          </View>
        }
      />
    );
  }

  renderSectionContents = (contents, isFirstSection) => {
    return (
      <View style={styles.sectionContentsContainer}>
        {contents.map((faqItem, idx) => {
          const initialCollapse = !(isFirstSection && idx === 0);
          return this.renderFaqItem(faqItem, initialCollapse);
        })}
      </View>
    );
  }

  renderSection = (key, idx) => {
    const { faqList } = this.props;
    const isFirstSection = idx === 0;

    if (faqList[key].length === 0) {
      return null;
    }

    return (
      <CollapseSection
        outerTouch={false}
        key={key}
        iconSize='large'
        containerStyle={styles.sectionContainer}
        initialCollapse={!isFirstSection}
        title={key}
        titleContainerStyle={styles.sectionTitleContainer}
        titleTextStyle={styles.sectionTitleText}
        children={this.renderSectionContents(faqList[key], isFirstSection)}
      />
    );
  }

  render() {
    const { faqList } = this.props;
    const keys = Object.keys(faqList);

    return (
      <ScrollView
        contentContainerStyle={styles.scrollContainer}
        style={styles.container}
      >
        {keys.map((key, idx) => {
          return this.renderSection(key, idx);
        })}
      </ScrollView>
    );
  }
}

export default connect(
  state => {
    return {
      faqList: state.entities.faqList,
    };
  },
  actions
)(FAQPage);
