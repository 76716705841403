import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Checkbox,
  GridList,
  GridListTile,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { compose } from 'recompose';
import { loadEntities } from 'react-shared';
import * as adminActions from 'react-shared/adminActions';
import { has, values } from 'lodash';
import dayjs from 'dayjs';

import { Cloudinary } from 'cloudinary-core';
import config from '../../config'
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Edit from '@material-ui/icons/Edit';
import Media from '../Media';

const cloudinary = Cloudinary.new();
cloudinary.config({
  ...config.get('cloudinary'),
  secure: true,
});


const styles = theme => ({
  divSearchFilter: {
    marginTop: 20,
  },
  textField: {
    width: 300,
  },
  input: {
    ...theme.typography.subheading,
  },
  table: {
    width: '100%',
    padding: 5,
  },
  dialogueGridListTile: {
    cursor: 'pointer',
    width: 240,
    height: 240,
  },
});


class MatchingPostSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.handleLoadMore(false);
  }

  handleLoadMore(nextPage) {
    const { productId } = this.props;
    this.props.adminLoadProductMatchingPosts({ productId, nextPage });
  }

  static renderPostDate(post) {
    if (post.featured) {
      // TODO: fix conditions if all the featured scores become valid Epoch time value
      if (post.featured.score > 10000) {
        return (
          <span style={{ fontSize: '11px' }}>
            {dayjs(post.featured.score).format('YYYY-MM-DD (HH:mm)')}
          </span>
        );
      } else if (post.featured.at) {
        return (
          <span style={{ fontSize: '11px' }}>
            {dayjs(post.featured.at).format('YYYY-MM-DD (HH:mm)')}
          </span>
        );
      }
    }
    return (
      <span style={{ color: '#bbbbbb', textDecoration: 'line-through', fontSize: '10px' }}>
        {'created at ' + dayjs(parseInt(post._id.substring(0, 8), 16) * 1000).format('YYYY-MM-DD (HH:mm)')}
      </span>
    );
  }

  renderPostsTable() {
    const {
      classes,
      adminProductMatchingPosts,
      matchingPosts,
      pagination: { isFetching, nextPageUrl } = {},
      onCheckMatchingPost
    } = this.props;
    const postList = adminProductMatchingPosts
      .filter((post) => !has(matchingPosts, post._id))
      .sort((a, b) => b.id - a.id);
    return (
      <div style={{ width: '100%' }}>
        <GridList cols={5} cellHeight={240}>
          {postList.map((post, idx) => {
            return (
              <GridListTile
                key={`matchingpostselector_${post._id}_${idx}`}
                className={classes.dialogueGridListTile}
              >
                {MatchingPostSelector.renderPostDate(post)}
                <Media
                  media={post.media[0]}
                  options={{ width: 200, height: 200, crop: 'limit' }}
                  style={{ paddingTop: '100%' }} />
                <div style={{ flex: 1, textAlign: 'center' }}>
                  <Button
                    variant="outlined"
                    onClick={() => (onCheckMatchingPost && onCheckMatchingPost(post))}>
                    <Add />
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      window.open(`/admin/posts/${post._id}`);
                    }}>
                    <Edit />
                  </Button>
                </div>
              </GridListTile>
            );
          })}
        </GridList>
        {(!isFetching && nextPageUrl) && (
          <div style={{ flex: 1, textAlign: 'center' }}>
            <p style={{ textAlign: 'center', marginTop: 10 }}>
              <Button variant="outlined" onClick={() => this.handleLoadMore(true)}>
                더 불러오기
              </Button>
            </p>
          </div>
        )}
      </div>
    );
  }

  renderMatchingPostsTable() {
    const { classes, matchingPosts, onCheckMatchingPost } = this.props;
    const postList = values(matchingPosts).sort((a, b) => b.id - a.id);
    return (
      <div style={{ width: '100%' }}>
        <GridList cols={5} cellHeight={240}>
          {postList.map((post, idx) => {
            return (
              <GridListTile
                key={`matchingpostselector_${post._id}_${idx}`}
                className={classes.dialogueGridListTile}
              >
                {MatchingPostSelector.renderPostDate(post)}
                <Media
                  media={post.media[0]}
                  options={{ width: 200, height: 200, crop: 'limit' }}
                  style={{ paddingTop: '100%' }} />
                <div style={{ flex: 1, textAlign: 'center' }}>
                  <Button
                    variant="outlined"
                    onClick={() => (onCheckMatchingPost && onCheckMatchingPost(post))}>
                    <Remove color='error' />
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      window.open(`/admin/posts/${post._id}`);
                    }}>
                    <Edit />
                  </Button>
                </div>
              </GridListTile>
            );
          })}
        </GridList>
      </div>
    );
  }

  render() {
    const { matchingPosts } = this.props;
    const matchingPostsCount = values(matchingPosts).length;
    return (
      <Card elevation={0}>
        <CardContent>
          <Accordion expanded={true} style={{ marginTop: '16px' }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
            >
              <Typography>선택된 포스트가 {matchingPostsCount}개 있습니다.</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {this.renderMatchingPostsTable()}
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={true}>
            <AccordionDetails>
              {this.renderPostsTable()}
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(state => ({
    ...loadEntities(state, 'adminProductMatchingPosts', 'adminProductMatchingPosts'),
  }), adminActions)
)(MatchingPostSelector);
