import React from 'react'

import LegalPolicyOverlayPage from './LegalPolicyOverlayPage';


export function showLegalPolicy(parent, type) {
  const { setModalProps } = parent.props;

  const children = (
    <LegalPolicyOverlayPage
    type={type}
      setModalProps={setModalProps}
      />
  )

  setModalProps({
    isVisible: true,
    children,
  });
}
