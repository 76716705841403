import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View, Text, TouchableHighlight } from 'react-native';

import FoldIcon from '../../../icons/FoldIcon';
import UnfoldIcon from '../../../icons/UnfoldIcon';
import Styles from '../../../constants/Styles';

const styles = StyleSheet.create({
  container: {
  },
  title: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 16,
    paddingBottom: 10,
    paddingHorizontal: 20,
  },
  itemCollapseButton: {
    margin: -4,
  },
  titleText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 18,
    height: 21,
    marginTop: 4,
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});


export default function Section(props) {
  const { title, titleContainerStyle, children } = props;

  const [isCollapsed, setCollapsed] = useState(false);

  return (
    <View style={styles.container}>
      <View
        style={[styles.title, titleContainerStyle]}
      >
        <Text style={styles.titleText}>{title}</Text>

        <TouchableHighlight
          onPress={() => setCollapsed(!isCollapsed)}
          style={[styles.itemCollapseButton, styles.touchEffect]}
          underlayColor="rgba(0, 0, 0, 0.1)"
        >
          {isCollapsed ? <UnfoldIcon /> : <FoldIcon />}
        </TouchableHighlight>
      </View>

      <View style={{ display: isCollapsed ? 'none' : 'flex' }}>
        {children}
      </View>

    </View>
  );
}
