import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadHeartists, updateHeartists, updateHeartist } from 'react-shared/adminActions';
import { loadEntities } from 'react-shared/util';
import ThumbnailList from 'components/admin/ThumbnailList';
import Spinner from 'components/Spinner';

class ListHeartistPage extends Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    this.props.loadHeartists();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (window.pageYOffset > document.body.clientHeight - window.innerHeight - 1
      && !this.props.pagination.isFetching && this.props.pagination.nextPageUrl) {
      this.handleLoadMore();
    }
  }

  handleLoadMore() {
    this.props.loadHeartists(true);
  }

  handleUpdate(items, oldIndex, newIndex) {
    let maxScore = null;
    let minScore = null;
    for (let i = 0; i < items.length; i++) {
      const currentScore = items[i].featured ? items[i].featured.score : null;
      if (currentScore !== null) {
        if (maxScore === null || currentScore > maxScore) {
          maxScore = currentScore;
        }
        if (minScore === null || currentScore < minScore) {
          minScore = currentScore;
        }
      }
    }
    maxScore = (maxScore === null) ? 1000 : maxScore;
    minScore = (minScore === null) ? 100 : minScore;
    const lastIndex = items.length - 1;

    function median(index1, index2) {
      const score1 = items[index1].featured.score;
      if (!items[index2].featured || items[index2].featured.score === null) {
        return Math.round(score1 - 2);
      }
      const score2 = items[index2].featured.score;
      const avg = (score1 + score2) / 2;
      const intAvg = Math.round(avg);
      if (score1 > intAvg && intAvg > score2) {
        return intAvg;
      }
      return avg;
    }

    this.props.updateHeartists(items.map(item => item._id));
    const userId = items[newIndex]._id;

    if (newIndex === 0) {
      this.props.updateHeartist(userId, {
        featured: { score: Math.round(maxScore + 4) },
      });
    } else if (items.length === 2) {
      // TODO: Check if featured === undefined
      this.props.updateHeartist(items[0]._id, {
        featured: { score: items[1].featured.score },
      });
      this.props.updateHeartist(items[1]._id, {
        featured: { score: items[0].featured.score },
      });
    } else if (newIndex === lastIndex) {
      this.props.updateHeartist(userId, {
        featured: { score: Math.round(minScore - 2) },
      });
      // this.props.updateHeartist(items[lastIndex - 1]._id, {
      //   featured: { score: median(lastIndex - 2, lastIndex - 1) },
      // });
    } else {
      this.props.updateHeartist(userId, {
        featured: { score: median(newIndex - 1, newIndex + 1) },
      });
    }
  }

  static getMedia(heartist) {
    const { lastPost: { media: { 0: media } } } = heartist;
    return media;
  }

  static renderCaption(item) {
    const score = item.featured ? item.featured.score : 'NO SCORE';
    return (
      <div>
        <p>{item.username}</p>
        <p>{score}</p>
      </div>
    );
  }

  render() {
    const { pagination: { isFetching }, heartists } = this.props;
    return (
      <div className="container">
        <ThumbnailList
          items={heartists}
          renderCaption={ListHeartistPage.renderCaption}
          getMedia={ListHeartistPage.getMedia}
          onUpdate={this.handleUpdate}
          sortable
        />
        {isFetching && <Spinner />}
      </div>
    );
  }
}

ListHeartistPage.propTypes = {
  heartists: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  loadHeartists: PropTypes.func.isRequired,
  updateHeartists: PropTypes.func.isRequired,
  updateHeartist: PropTypes.func.isRequired,
};

export default connect(
  state => loadEntities(state, 'heartists', 'heartists'),
  { loadHeartists, updateHeartists, updateHeartist }
)(ListHeartistPage);
