import React from 'react';
import PropTypes from 'prop-types';
import {
  Dimensions, StyleSheet,
  View, Text, TouchableOpacity, ActivityIndicator, TouchableHighlight,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import '../css/carousel.css';

// eslint-disable-next-line import/no-extraneous-dependencies
import { formatPrice } from 'shared/util';
import { br2nl } from '../utils';

import Styles from '../constants/Styles';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';

import LikeSelectedIcon from '../icons/LikeSelectedIcon14';
import LikeSelectedHalfIcon from '../icons/LikeSelectedHalfIcon14';
import LikeNormalIcon from '../icons/LikeNormalIcon14';


const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
  container: {
  },
  itemInfoContainer: {
    paddingHorizontal: 20,
    paddingTop: 20,
  },
  itemInfoStartContainer: {
    flex: 1,
    marginBottom: 16,
  },
  itemInfoHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  reviewIconContainer: {
    marginTop: 2,
    flexDirection: 'row',
  },
  itemInfoBodyContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemInfoText: {
    height: 16,
    fontSize: 13,
    lineHeight: 16,
    color: 'black',
    ...Styles.appleSDGothicNeoRegular,
  },
  itemContent: {
    marginBottom: 22,
  },
  itemSubject: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 14,
    lineHeight: 17,
    marginTop: 4,
    marginBottom: 6,
  },
  itemSubjectHeightLimit: {
    maxHeight: 51,
  },
  itemSubjectLarge: {
    marginTop: 2,
    lineHeight: 21,
  },
  itemOptionText: {
    fontSize: 12,
    lineHeight: 15,
    color: 'rgba(0,0,0,0.5)',
    ...Styles.SFUITextRegular,
    marginBottom: 4,
  },
  loadingIndicator: {
    marginTop: 20,
    marginBottom: 20,
  },
  optionControlContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  itemControlButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 14 - Constants.touchEffectWidth,
    marginBottom: -Constants.touchEffectWidth,
  },
  itemControlButton: {
    marginLeft: 6,
  },
  itemControlButtonText: {
    fontSize: 16,
    height: 19,
    ...Styles.appleSDGothicNeoBold,
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});

const webStyles = {
  likeIcon: {
    marginRight: 6,
  },
  imageSwiper: {
    marginTop: 16,
    height: windowWidth - Constants.viewHorizontalPadding * 2,
  },
  itemInfoImage: {
    marginLeft: 16,
    width: 80,
    height: 80,
    marginBottom: 20,
  },
};

const indicatorStyles = {
  backgroundColor: 'rgba(0,0,0,0.2)',
  width: 24,
  height: 2,
  display: 'inline-block',
  margin: '0 2px 0 2px',
};

const renderReviewStar = reviewStar => {
  const starCount = Math.max(Math.min(reviewStar, 5), 0);

  const stars = [];

  for (let i = 0; i < Math.floor(starCount); i++) {
    stars.push(
      <LikeSelectedIcon key={`selected${i}`} style={webStyles.likeIcon} />
    );
  }

  if (starCount % 1 >= 0.5) {
    stars.push(
      <LikeSelectedHalfIcon key="selectedHalf" style={webStyles.likeIcon} />
    );
  }

  const iconCount = stars.length;
  for (let i = 0; i < 5 - iconCount; i++) {
    stars.push(
      <LikeNormalIcon key={`normal${i}`} style={webStyles.likeIcon} />
    );
  }
  return (
    <View style={styles.reviewIconContainer}>
      {stars}
    </View>
  );
};


function parseImageListFromContent(contents) {
  const imgTags = contents.match(/<img ([^>]+)>/g);

  const imageList = !imgTags ? [] : imgTags.map(imgTag => {
    const url = imgTag.match(/src="([^"]+)"/);
    // const width = imgTag.match(/width="([^"]+)"/);
    // const height = imgTag.match(/height="([^"]+)"/);
    return {
      url: (url) ? url[1] : '',
      // width: (width) ? width[1] : null,
      // height: (height) ? height[1] : null,
    };
  });

  return imageList;
}

class ReviewItem extends React.Component {
  state = {
    open: false,
  };

  componentDidMount() {
    // console.log('ReviewItem componentDidMount');
    // console.log('ReviewItem componentDidMount props=', this.props);
  }

  componentDidUpdate() {
    // console.log('ReviewItem componentDidUpdate');
    // console.log('ReviewItem componentDidUpdate props=', this.props);
  }

  onPressItem = () => {
    this.setState(({ open }) => {
      if (!open) {
        this.loadReviewArticle();
      }

      return {
        open: !open,
      };
    });
  }


  loadReviewArticle() {
    // console.log('ReviewItem loadReviewArticle');
    const { loadArticleDetail } = this.props;
    loadArticleDetail();
  }

  getThumbnailImageUrl() {
    const {
      article: item,
    } = this.props;

    const {
      viewListImage,
      attachImageSrc,
      channel,
      contents,
    } = item;

    let thumbnailImage = attachImageSrc || viewListImage;

    if (channel === 'naverpay' && contents) {
      const imageList = parseImageListFromContent(contents);

      if (imageList.length > 0) {
        const naverPayImageUrl = imageList[0].url;
        if (naverPayImageUrl) {
          thumbnailImage = naverPayImageUrl;
        }
      }
    }

    return thumbnailImage;
  }

  renderIndicator = (onClickHandler, isSelected, index, label) => {
    if (isSelected) {
      return (
        <li
          key={index}
          style={{ ...indicatorStyles, backgroundColor: 'black' }}
        />
      );
    }
    return (
      <li
        style={indicatorStyles}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        role="button"
        tabIndex={0}
        title={`${label} ${index + 1}`}
        aria-label={`${label} ${index + 1}`}
      />
    );
  }

  renderContent() {
    const { article: item } = this.props;

    // console.log('ReviewItem renderContent item=', item);

    const {
      contents, uploadImageList,
      viewListImage, attachImageSrc,
      channel,
    } = item;

    // Show loading indicator until loadReviewArticle finishes.
    if (typeof contents === 'undefined') {
      return (
        <View style={styles.itemContent}>
          <ActivityIndicator style={styles.loadingIndicator} color="gray" />
        </View>
      );
    }

    if (contents === '') {
      return null;
    }

    // console.log('renderContent item=', item);

    // console.log('ReviewItem renderContent uploadImageList=', uploadImageList);

    // <Image key={fid} source={{ uri: imgPath }} style={styles.imageSwiperImage} />
    const imageList = (uploadImageList || []).map(({ fid, imgPath }) => (
      <img key={fid} alt="" referrerPolicy="no-referrer" src={imgPath} />
    ));

    if (channel === 'naverpay') {
      const naverPayImageList = parseImageListFromContent(contents);

      naverPayImageList.forEach((image, index) => {
        const naverPayImageUrl = image.url;
        if (naverPayImageUrl) {
          imageList.push((
            // eslint-disable-next-line react/no-array-index-key
            <img key={`naver${index}`} alt="" referrerPolicy="no-referrer" src={naverPayImageUrl} />
          ));
        }
      });
    }

    if (imageList.length === 0) {
      const thumbnailImage = attachImageSrc || viewListImage;

      imageList.push(
        // <Image key={0} source={{ uri: thumbnailImage }} style={styles.imageSwiperImage} />
        <div key={0}>
          <img alt="" referrerPolicy="no-referrer" src={thumbnailImage} />
        </div>
      );
    }

    const hideSwipeIndicator = (imageList.length < 2);

    return (
      <View style={styles.itemContent}>
        <Carousel
          renderThumbs={() => null}
          renderIndicator={(hideSwipeIndicator) ? () => null : this.renderIndicator}
          statusFormatter={() => ''}
          style={webStyles.imageSwiper}
          renderArrowPrev={() => null}
          renderArrowNext={() => null}
        >
          {imageList}
        </Carousel>
      </View>
    );
  }


  render() {
    const { open } = this.state;
    const {
      article: item, controlButtonEnabled = true, largeThumbnail = false, detailEnabled = false,
      onPressModify, onPressDelete,
    } = this.props;

    const {
      contents, writerId, regDt, goodsPt,
      auth,
      extraData,
    } = item;

    const writer = (writerId || '').substring(0, 4) + '****';
    let date = (regDt || '').slice(0, -3);
    date = date.replace(/-/gi, '.');
    // eslint-disable-next-line prefer-destructuring
    date = date.split(' ')[0];

    const thumbnailImage = this.getThumbnailImageUrl();

    let contentsText = br2nl(contents);
    contentsText = contentsText.replace(/<p>/gi, '');
    contentsText = contentsText.replace(/<\/p>/gi, '</br>');
    contentsText = contentsText.replace(/&nbsp;/gi, ' ');
    contentsText = contentsText.replace(/<[^>]*>/gi, '');
    contentsText = contentsText.replace(/^\s+|\s+$/g, '');

    const optionInfo = extraData && extraData.arrOrderGoodsData[0] ? extraData.arrOrderGoodsData[0].optionInfo : '';
    const optionInfoList = JSON.parse(optionInfo);
    const optionInfoText = optionInfoList.reduce((acc, option) => {
      // eslint-disable-next-line no-unused-vars
      const [optionName, optionValue, _, optionPrice] = option;
      let optionString = `${optionName}: ${optionValue}`;
      if (optionPrice) {
        const optionPriceInt = Number.parseInt(optionPrice, 10);
        optionString += ` (${formatPrice(optionPriceInt)})`;
      }

      return `${acc}\n${optionString}`;
    }, '');

    return (
      <View style={styles.container}>
        <View style={styles.itemInfoContainer}>
          <View style={styles.itemInfoHeaderContainer}>
            {renderReviewStar(goodsPt)}
            <Text style={styles.itemInfoText}>{`${writer} ${date}`}</Text>
          </View>
          <View style={styles.itemInfoBodyContainer}>
            <TouchableOpacity
              style={styles.itemInfoStartContainer}
              onPress={this.onPressItem}
              disabled={!detailEnabled}
            >
              <View style={{ flex: 1, justifyContent: 'flex-start' }}>
                <Text
                  style={[
                    styles.itemSubject,
                    (open) ? styles.itemSubjectLarge : styles.itemSubjectHeightLimit,
                  ]}
                  numberOfLines={open ? 0 : 3}
                >
                  {contentsText}
                </Text>

                <View style={[styles.optionControlContainer]}>
                  <Text style={[styles.itemOptionText]} numberOfLines={0}>
                    {optionInfoText}
                  </Text>
                  {(open && controlButtonEnabled) && (
                    <View style={styles.itemControlButtonContainer}>
                      { auth.modify === 'y'
                        && (
                          <TouchableHighlight
                            style={[
                              styles.touchEffect,
                              styles.itemControlButton,
                            ]}
                            underlayColor={Colors.touchEffectColorWhite}
                            onPress={onPressModify}
                          >
                            <Text style={styles.itemControlButtonText}>수정</Text>
                          </TouchableHighlight>
                        )}
                      { auth.delete === 'y'
                        && (
                          <TouchableHighlight
                            style={[
                              styles.touchEffect,
                              styles.itemControlButton,
                            ]}
                            underlayColor={Colors.touchEffectColorWhite}
                            onPress={onPressDelete}
                          >
                            <Text style={styles.itemControlButtonText}>삭제</Text>
                          </TouchableHighlight>
                        )}
                    </View>
                  )}
                </View>
              </View>
            </TouchableOpacity>
            {(!open && !largeThumbnail) && (
              <img alt="" style={webStyles.itemInfoImage} referrerPolicy="no-referrer" src={thumbnailImage} />
            )}
          </View>
          {(open || largeThumbnail) && this.renderContent()}
        </View>
      </View>
    );
  }
}


ReviewItem.propTypes = {
  article: PropTypes.object.isRequired,
  loadArticleDetail: PropTypes.func.isRequired,
  controlButtonEnabled: PropTypes.bool,
  onPressModify: PropTypes.func,
  onPressDelete: PropTypes.func,
};


ReviewItem.defaultProps = {
  controlButtonEnabled: false,
  onPressModify: () => null,
  onPressDelete: () => null,
};

export default ReviewItem;
