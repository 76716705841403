import React from 'react'
import PropTypes from 'prop-types';

import {
    StyleSheet, View, Text, FlatList,
} from 'react-native';

import { Styles } from '../../constants';

import BottomSheetModal from '../BottomSheetModal';
import TouchableHighlightWithContainer from '../TouchableHighlightWithContainer';
import { ListSelectedIcon } from '../../icons-v2';


const styles = StyleSheet.create({
  container: {
  },
  itemContainer: {
    paddingStart: 20,
    paddingEnd: 24,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemText: {
    ...Styles.pretendard400,
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
    marginTop: 25,
    marginBottom: 24,
  },
  itemTextSelected: {
    ...Styles.pretendard600,
  },
});

class PickerModal extends BottomSheetModal {
  renderItem(item, index) {
    const {
      selectedItem,
      onChange,
    } = this.props;

    const isSelected = !!selectedItem && item.id === selectedItem.id;

    const itemTextStyles = [
      styles.itemText,
      isSelected && styles.itemTextSelected,
    ];

    const onPress = () => {
      // console.log(`PickerModal.onPress(${index}) item=`, item);
      onChange(item, index);
    }

    const name = item.displayName || item.name;

    return (
      <TouchableHighlightWithContainer
        onPress={onPress}
        >
        <View style={styles.itemContainer}>
          <Text style={itemTextStyles}>{name}</Text>
          {isSelected && <ListSelectedIcon /> }
        </View>
      </TouchableHighlightWithContainer>
    );
  }

  renderBody() {
    const { itemList } = this.props;

    return (
      <View style={styles.container}>
        <FlatList
          keyExtractor={(item, index) => `${index}`}
          data={itemList}
          renderItem={({ item, index }) => this.renderItem(item, index)}
          ListHeaderComponent={() => <View style={{ height: 6 }} />}
          bounces={false}
          />
      </View>
    );
  }
}


PickerModal.propTypes = {
  title: PropTypes.string.isRequired,
  itemList: PropTypes.array.isRequired,
  selectedItem: PropTypes.object,
};


PickerModal.defaultProps = {
  title: '',
  itemList: [],
  selectedItem: {},
};


export default PickerModal;
