
// KAKKO 정책 동일: 8 ~ 32 자의 영문 대소문자, 숫자, 특수문자를 조합
const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#\$%\^&\*\(\)_\+\-=\{\}\[\]\:;"'<>,\.\?])[A-Za-z\d!@#\$%\^&\*\(\)_\+\-=\{\}\[\]\:;"'<>,\.\?]{8,32}$/;

export function validatePassword(password) {

  return password.match(passwordPattern);
}

export const PASSWORD_INVALID_ERROR_TEXT = '비밀번호는 8 ~ 32 자의 영문 대소문자, 숫자, 특수문자를 조합하여 입력하세요.';
