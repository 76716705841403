import React from 'react';
import {
  View, Text, TouchableOpacity, TouchableHighlight, StyleSheet,
  Dimensions,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';

import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import CheckIcon from '../icons/CheckIcon';
import Styles from '../constants/Styles';
import Colors from '../constants/Colors';

const { height: windowHeight } = Dimensions.get('window');

const useStyles = () => ({
  root: {
  },
  paperAnchorBottom: {
  },
  gridList: {
    paddingTop: 15,
    paddingBottom: 29,
  },
});

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    position: 'absolute',
    top: 0,
    bottom: 0,
    start: 0,
    end: 0,
  },
  outside: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  dropdownContainer: {
    backgroundColor: 'black',
    width: '100%',
    paddingTop: 5,
    paddingBottom: 5,
    maxHeight: windowHeight / 2,
  },
  itemButton: {
    borderRadius: 4,
    paddingStart: 16,
    paddingEnd: 10,
    marginHorizontal: 20,
  },
  itemContainer: {
    height: 52,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemText: {
    color: '#fff',
    ...Styles.SFUITextSemiBold,
    fontSize: 16,
    height: 19,
  },
  categoryPickerItemIcon: {
    color: '#fff',
  },
});


export class BottomModalDropdown extends React.Component {
  render() {
    const anchor = 'bottom';
    const { classes, data, open, onClose, onPressItem, curIndex } = this.props;

    return (
      <View>
        <Drawer
          anchor={anchor}
          open={open}
          onClose={onClose}
          transitionDuration={0}
          classes={{
            root: classes.root,
            paperAnchorBottom: classes.paperAnchorBottom,
          }}
        >
          <TouchableOpacity
            onPress={onClose}
            style={styles.outside}
          >
            <View style={styles.dropdownContainer}>
              <GridList
                cols={1}
                spacing={4}
                cellHeight={56}
                classes={{
                  root: classes.gridList,
                }}
              >
                {data.map(({ title }, index) => (
                  <GridListTile
                  // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    <TouchableHighlight
                      onPress={onPressItem(index)}
                      underlayColor={Colors.touchEffectColorBlack}
                      style={styles.itemButton}
                    >
                      <View style={styles.itemContainer}>
                        <Text style={styles.itemText}>{title}</Text>
                        { curIndex === index && (<CheckIcon />)}
                      </View>
                    </TouchableHighlight>
                  </GridListTile>
                ))}
              </GridList>
            </View>
          </TouchableOpacity>
        </Drawer>
      </View>
    );
  }
}

export default withStyles(useStyles)(BottomModalDropdown);
