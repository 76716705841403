import React from 'react';
import {
  StyleSheet, View, Text,
} from 'react-native';
import NumberFormat from 'react-number-format';
import { connect }  from 'react-redux';
import { actions, loadEntities } from 'shared';
import { isHeartitProduct } from 'shared/util/product';

import CloudinaryImage from './CloudinaryImage';
import Button from './Button';
import CheckBox from './CheckBox';
import { Constants, Styles, Colors } from '../constants';
import { getCrawlerProductImageStyle } from 'shared/util/product';


const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    marginHorizontal: Constants.viewHorizontalPadding,
  },
  headerCheckbox: {
    marginBottom: 3,
  },
  headerButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: -10
  },
  headerButton: {
    padding: Constants.touchEffectWidth,
    borderWidth: 0,
    borderRadius: Constants.touchEffectBorderRadius,
    marginHorizontal: 10,
  },
  headerButtonText: {
    color: '#000',
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard400,
  },
  itemContainer: {
    flex: 1,
    flexDirection: 'column',
    padding: 0,
    paddingStart: Constants.viewHorizontalPadding,
    paddingEnd: Constants.viewHorizontalPadding,
    borderWidth: 0,
    borderRadius: 0,
  },
  itemContentContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  itemImage: {
    width: 112,
    height: 149,
    resizeMode: 'cover',
    marginEnd: 16,
    borderWidth: 1,
    borderColor: Colors.productImageBorder,
    overflow: 'hidden',
  },
  itemContent: {
    flex: 1,
    flexGrow: 1,
  },
  itemBrandNameContainer: {
    flexDirection: 'column',
  },
  itemBrand: {
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard600,
  },
  itemNameOption: {
    marginTop: 2,
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard400,
  },
  heartitLabelContainer: {
    flexDirection: 'row',
    marginTop: 6,
  },
  heartitLabel: {
    paddingHorizontal: 6,
    paddingVertical: 4,
    borderWidth: 0.5,
    borderColor: '#000',
  },
  heartitLabelText: {
    fontSize: 11,
    lineHeight: 13.2,
    ...Styles.pretendard400,
  },
  itemPriceContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  itemOrgPrice: {
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard400,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  itemSellPrice: {
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard400,
  },
  itemDiscount: {
    color: '#FF0000',
    marginLeft: 6,
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard600,
  },
});


class WishlistItem extends React.Component {
  componentDidMount() {
    const {
      loadShopProduct,
      productId,
    } = this.props;

    loadShopProduct({ productId });
  }

  isSoldOut = () => {
    const { shopProduct: product = { soldOut: 'n', soldOutFl: 'n' }} = this.props;
    const { soldOut, soldOutFl, stockCnt: stockCntStr } = product;
    const stockCnt = parseInt(stockCntStr, 10);
    return (soldOut === 'y' || soldOutFl === 'y' || stockCnt === 0);
  }

  renderEditHeader = () => {
    const { item: product, onChecked, checked,
      deleteWishlist, addToCart, checkoutNow,
    } = this.props;
    return (
      <View style={styles.headerContainer}>
        <CheckBox
          baseStyle={styles.headerCheckbox}
          checked={checked}
          onPress={() => {
            onChecked(!checked);
          }}
        />
        <View style={styles.headerButtonContainer}>
          <Button
            containerStyle={styles.headerButton}
            titleStyle={styles.headerButtonText}
            title="쇼핑백에 담기"
            disabled={this.isSoldOut()}
            onPress={() => addToCart([product])}
          />
          <Button
            containerStyle={styles.headerButton}
            titleStyle={styles.headerButtonText}
            title="바로 구매하기"
            disabled={this.isSoldOut()}
            onPress={() => checkoutNow([product])}
          />
          <Button
            containerStyle={styles.headerButton}
            titleStyle={styles.headerButtonText}
            title="삭제"
            onPress={() => deleteWishlist(product)}
          />
        </View>
      </View>
    );
  }

  renderItemInfo = () => {
    const { router, logEventClick, item: product, shopProduct, partnerShippingProducts } = this.props;
    const { godoGoodsNo } = product;
    let { isSeasonOut } = product;
    isSeasonOut = !!isSeasonOut;
    const hasGodoGoodsNo = !!godoGoodsNo;

    const retails = (product && product.retails) ? product.retails[0] : {
      orgPrice: { amount: 0 },
      sellPrice: { amount: 0 },
    };

    let {
      orgPrice: { amount: orgPrice },
      sellPrice: { amount: sellPrice },
    } = retails;

    let discountRate = orgPrice > sellPrice ? Math.round((1 - sellPrice / orgPrice) * 100) : 0;

    if (shopProduct) {
      let selectedOption = shopProduct.option.filter((option) => { return option.sno === product.optionKey });
      if (selectedOption.length > 0) {
        selectedOption = selectedOption[0];
        const itemOrgPrice = (Number.parseInt(shopProduct.fixedPrice, 10) + Number.parseInt(selectedOption.optionPrice, 10))
        const itemSellPrice = (Number.parseInt(shopProduct.goodsPrice, 10) + Number.parseInt(selectedOption.optionPrice, 10))
        const discount = Math.round(100 * (1 - itemSellPrice / itemOrgPrice));

        orgPrice = itemOrgPrice;
        sellPrice = itemSellPrice;
        discountRate = discount > 0 ? discount : 0;
      }
    }

    const { media, salesEndYmd, timestampNowYmd } = product;

    /*
    const optionList = option.map(element => {
      const { optionName, optionValue, optionPrice } = element;
      let optionPriceInfo = '';
      // console.log('renderItem optionPrice', optionPrice);
      if (optionPrice !== undefined) {
        const optionPriceInt = Number.parseInt(optionPrice, 10);
        if (optionPriceInt !== 0) {
          optionPriceInfo = ` (${formatPrice(optionPriceInt)})`;
        }
      }

      return (
        <Text key={`option_${optionValue}`} style={styles.itemName}>
          {optionValue}
        </Text>
      );
    });

    const showPreOrderLabel = preOrder === 'y';
    */
    let showHeartitProductBadge = false;
    if (isHeartitProduct(product)) {
      const partnerShipping = partnerShippingProducts.filter(p => p.goodsNo == godoGoodsNo);
      if (partnerShipping.length > 0) {
        showHeartitProductBadge = partnerShipping[0].heartitBadge;
      } else {
        showHeartitProductBadge = true;
      }
    }

    const crawlerImageStyle = getCrawlerProductImageStyle(product);
    const crawlerImageWebStyle = {
      objectFit: crawlerImageStyle.resizeMode,
      backgroundColor: crawlerImageStyle.backgroundColor
    };

    return (
      <View style={{ flex: 1}}>
        <Button
          containerStyle={styles.itemContainer}
          onPress={() => {
            logEventClick({
              page_name: 'Wishlist',
              click_object_name: 'product',
              click_object_key: product.godoGoodsNo
            });
            router.push(`/product/${product.godoGoodsNo}`);
          }}
          disabled={this.isSoldOut()}
          innerComponent={
            <View style={styles.itemContentContainer}>
              <View style={styles.itemImage}>
                <CloudinaryImage
                  // eslint-disable-next-line no-prototype-builtins
                  publicId={(media.hasOwnProperty('publicId')) ? media.publicId : media.public_id}
                  style={crawlerImageWebStyle}
                />
              </View>
              <View style={styles.itemContent}>
                <View style={styles.itemBrandNameContainer}>
                  <Text style={styles.itemBrand}>{product.brand}</Text>
                  <Text style={styles.itemNameOption}>{product.name}</Text>
                  <Text style={styles.itemNameOption}>{product.optionVal}</Text>
                  {showHeartitProductBadge ? (
                    <View style={styles.heartitLabelContainer}>
                      <View style={styles.heartitLabel}>
                        <Text style={styles.heartitLabelText}>하트잇상품</Text>
                      </View>
                    </View>
                  ): (<View style={{ height: 10 }} />)}
                </View>

                <View style={[styles.itemPriceContainer, { marginTop: 10 }]}>
                  <NumberFormat
                    value={orgPrice}
                    displayType="text"
                    thousandSeparator
                    renderText={value => (
                      <Text style={styles.itemOrgPrice}>
                        {`${value}원`}
                      </Text>
                    )}
                  />
                  <Text style={styles.itemDiscount}>{`${discountRate}%`}</Text>
                </View>
                <View style={[styles.itemPriceContainer, { marginTop: 4 }]}>
                  <NumberFormat
                    value={sellPrice}
                    displayType="text"
                    thousandSeparator
                    renderText={value => (
                      <Text style={styles.itemSellPrice}>
                        {`${value}원`}
                      </Text>
                    )}
                  />
                </View>
              </View>
            </View>
          }
        />
      </View>
    );
  }

  render() {
    const { showHeaderControl = true } = this.props;

    return (
      <View style={styles.container}>
        {showHeaderControl && this.renderEditHeader()}
        {this.renderItemInfo()}
      </View>
    );
  }
}


export default connect(
  (state, { navigation, ...otherProps }) => {
    const { item: product } = otherProps;
    return {
      auth: state.auth,
      historySwitch: state.settings.historySwitch,
      productId: product.godoGoodsNo,
      shopProduct: product.godoGoodsNo ? state.entities.shopProducts[product.godoGoodsNo] : undefined,
      ...loadEntities(state, 'partnerShippingProducts', 'partnerShippingProducts'),
    };
  },
  actions,
)(WishlistItem);
