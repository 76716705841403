import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import qs from 'qs';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';

import config from '../../config';
import Styles from '../../constants/Styles';

const styles = {
  container: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    display: 'table',
  },
  message: {
    textAlign: 'center',
    fontSize: 24,
    display: 'table-cell',
    verticalAlign: 'middle',
    paddingBottom: 150,
    ...Styles.appleSDGothicNeoBold,
  },
};


class CheckoutNaverPayCartWithoutAuthGatewayPage extends React.Component {

  state = {
    godoToken: "",
  }

  constructor(props) {
    super(props);
    const { setGlobalLayoutInfo } = props;
    setGlobalLayoutInfo('showProgress', true);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  loadGodoToken =  async () => {

    const errorMessage = '카트 아이템을 결제하는 도중 오류가 발생하였습니다.';

    try{
      const { getCartTokenWithoutAuth,
       orderItems} = this.props;
        const ret = await getCartTokenWithoutAuth();
        if (!ret.success) {
          this.showAlert(`${errorMessage} (E0${ret.errorCode})`);
          return;
        }

        this.setState({ godoToken : ret.data[0] }, () => {
          if (orderItems) {
            document.getElementById('token').value = ret.data[0];
            document.getElementById('naverPayFormButton').click();
          }
        });
    }catch (error){
      // eslint-disable-next-line no-console
      console.log('loadData error', error);

      if (error.errorCode) {
        this.showAlert(`${errorMessage} (E1${error.errorCode})`);
      } else {
        this.showAlert(errorMessage);
      }
    }

  }

  setCookie(name, value)
  {
    const today = new Date();
    const expiry = new Date(today.getTime() + 30 * 24 * 3600 * 1000); // plus 30 days
    document.cookie = name + "=" + escape(value) + ";";// path=/; expires=" + expiry.toGMTString();
  }

  onFormSubmit = (event) => {
    //event.preventDefault();
    const {
      godoToken
    } =  this.state;

    this.setCookie("GD5SESSID", godoToken);
    return true;
  }

  componentDidMount() {
    const { orderItems, logEventPageview, getCartTokenWithoutAuth } = this.props;

    this.loadGodoToken();

    logEventPageview({ page_name: 'CheckoutNaverPayCartWithoutAuthGateway' });
  }

  render() {
    const {
      auth: { token },
      shopSessionId,
      orderItems,
    } = this.props;

    if (!orderItems) {
      return (
        <div></div>
      );
    }

    const path = '/heartit_api/order_naver_pay_web_without_auth_multi_cart.php';
    const godomallMobileBaseUrl = config.get('godomallMobileBaseUrl');

    const targetUrl = `${godomallMobileBaseUrl}${path}`;

    const { protocol } = window.location;
    const { host } = window.location;
    const returnUrl = `${protocol}//${host}/shop/checkoutFinish/`;

    return (
      <div style={styles.container}>
        <form
          id="naverPayForm"
          method="POST"
          onSubmit={this.onFormSubmit}
          action={targetUrl}
        >
          <input
            type="hidden"
            id="token"
            name="token"
            value={token}
            readOnly
          />

          {orderItems.map(sno => (
            <input
              type="hidden"
              id={`cartSno_${sno}`}
              name="cartSno[]"
              value={sno}
              readOnly
            />
          ))}

          <input
            type="hidden"
            id="returnUrl"
            name="returnUrl"
            value={returnUrl}
            readOnly
          />
          <button id="naverPayFormButton" type="submit"></button>
        </form>
      </div>
    );
  }
}


export function navigateToCheckoutNaverPayCartWithoutAuthGateway({ orderItems, newWindow, holdPage=false }) {
  const query = { orderItems, holdPage };

  const path = `/shop/checkoutNaverPayNoAuthCart/${qs.stringify(query)}`;
  if (newWindow) {
    newWindow.location = path;
  } else {
    window.open(path);
  }

  if (!holdPage) {
    browserHistory.push('/shop');
  }
}


export default connect(
  (state, props) => {
    const { params: { query } } = props;
    const queryObject = qs.parse(query);
    const {
      orderItems,
    } = queryObject;

    return {
      auth: state.auth,
      shopSessionId: state.entities.shop.sessionId,
      orderItems,
    };
  },
  actions,
)(CheckoutNaverPayCartWithoutAuthGatewayPage);
