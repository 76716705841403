import { normalize } from 'normalizr';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';


export * from '../actions';


// admin actions for MD curations
export function adminLoadPreorderExhibitions() {
  const key = 'adminPreorderExhibitions';

  return createFetchAction({
    type: 'ADMIN_LOAD_PREORDER_EXHIBITIONS',
    endpoint: '/api/v2/admin/preorderExhibitions',
    transform: ({ json }) => normalize(json, schemas.adminPreorderExhibitions),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}

// admin MD curations sorting
export function adminUpdatePreorderExhibitions(preorderExhibitions) {

  return createFetchAction({
    type: 'ADMIN_UPDATE_PREORDER_EXHIBITIONS',
    endpoint: '/api/v2/admin/preorderExhibitions',
    method: 'PUT',
    body: preorderExhibitions,
    transform: ({ json }) => normalize(json, schemas.adminPreorderExhibition),
    success: {
      pagination: {
        key: 'adminPreorderExhibitions',
        type: 'PREPEND',
      }
    },
  });
}

export function adminGetPreorderExhibition(id) {
  return createFetchAction({
    type: 'ADMIN_GET_PREORDER_EXHIBITION',
    endpoint: `/api/v2/admin/preorderExhibitions/${id}`,
    transform: ({ json }) => normalize(json, schemas.adminPreorderExhibition),
  });
}

export function adminCreatePreorderExhibition(preorderExhibition) {
  return createFetchAction({
    type: 'ADMIN_CREATE_PREORDER_EXHIBITION',
    endpoint: '/api/v2/admin/preorderExhibitions',
    method: 'POST',
    body: preorderExhibition,
    transform: ({ json }) => normalize(json, schemas.adminPreorderExhibition),
    success: () => ({
      pagination: {
        key: 'adminPreorderExhibitions',
        type: 'PREPEND',
      },
    }),
  });
}

export function adminUpdatePreorderExhibition(id, preorderExhibition) {
  return createFetchAction({
    type: 'ADMIN_UPDATE_PREORDER_EXHIBITION',
    endpoint: `/api/v2/admin/preorderExhibitions/${id}`,
    method: 'PUT',
    body: preorderExhibition,
    transform: ({ json }) => normalize(json, schemas.adminPreorderExhibition),
  });
}

export function adminDeletePreorderExhibition(id) {
  const payload = { entities: { adminPreorderExhibitions: {} } };
  payload.entities.adminPreorderExhibitions[id] = null;
  return createFetchAction({
    type: 'ADMIN_DELETE_PREORDER_EXHIBITION',
    endpoint: `/api/v2/admin/preorderExhibitions/${id}`,
    method: 'DELETE',
    success: {
      payload,
    },
  });
}
