import React from 'react';
import { ScrollView, Text, Alert, FlatList, StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';

import { actions } from 'shared';
import OrderDetailItem from '../../../components-v2/OrderDetailItem';
import Spinner from '../../../components-v2/Spinner';

import Colors from '../../../constants/Colors';
import Styles from '../../../constants/Styles';
import Constants from '../../../constants/Constants';

import Button from '../../../components-v2/Button';

import { navigateToProductPage } from '../../shop/ProductPage';
import { getPrices } from './utils';


const settlePriceStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 16,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  containerLast: {
    marginTop: 4,
    marginBottom: 0,
  },
  labelText: {
    color: Colors.defaultGray08,
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard400,
  },
  labelTextBold: {
    ...Styles.pretendard600,
  },
  valueText: {
    color: Colors.defaultGray08,
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard400,
  },
  valueTextLarge: {
    color: '#000',
    fontSize: 24,
    lineHeight: 28.8,
    marginTop: -1,
    ...Styles.pretendard400,
  },
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  sectionSeparator: {
    height: 0.5,
    marginHorizontal: Constants.viewHorizontalPadding,
    backgroundColor: Colors.lineGray,
  },
  sectionContainer: {
    marginTop: 22,
    marginBottom: 40,
    marginHorizontal: Constants.viewHorizontalPadding,
  },
  sectionHeaderContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 12.5,
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  sectionHeaderText: {
    fontSize: 16,
    lineHeight: 19.2,
    color: '#000',
    ...Styles.pretendard600,
  },
  orderNameContainer: {
    flex: 1,
    flexGrow: 1,
  },
  orderDateContainer: {
    flexShrink: 1,
    marginBottom: 1,
  },
  orderDate: {
    fontSize: 14,
    color: 'black',
    lineHeight: 16.8,
    opacity: 0.5,
    ...Styles.pretendard400,
  },
  orderName: {
    fontSize: 16,
    color: 'black',
    lineHeight: 19.2,
    ...Styles.pretendard600,
  },
  orderNumberContainer: {
    paddingHorizontal: Constants.viewHorizontalPadding,
    paddingVertical: 20,
  },
  orderNumberText: {
    fontSize: 20,
    color: '#000',
    lineHeight: 24,
    ...Styles.pretendard600,
  },
  addressText: {
    marginBottom: 10,
    color: '#000',
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard400,
  },
  paymentButtonContainer: {
    paddingVertical: 0,
    paddingStart: 10,
    paddingEnd: 16,
    borderWidth: 0.5,
    color: '#000',
    width: '100%',
    height: 40,
  },
  paymentInnerContainer: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paymentText: {
    color: '#000',
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard600,
  }
});


function renderReceiverAddress(orderInfo) {
  if (!orderInfo) {
    return null;
  }

  const {
    receiverName, receiverCellPhone,
    receiverAddress, receiverAddressSub,
    orderMemo,
  } = orderInfo;
  return (
    <View style={styles.sectionContainer}>
      <View style={{ width: 185 }}>
        <Text style={styles.addressText}>{receiverName}</Text>
        <Text style={styles.addressText}>{receiverAddress + ', ' + receiverAddressSub}</Text>
        <Text style={[styles.addressText, {marginBottom: 0}]}>{receiverCellPhone}</Text>
      </View>
    </View>
  );
}

class OrderDetailPage extends React.Component {
  componentDidMount() {
    const { setGlobalState, order, logEventPageview, orderNo } = this.props;
    setGlobalState({ showMainBottomTabBar: false });

    if (!order) {
      this.loadData();
    }

    logEventPageview({ page_name: 'OrderDetail', page_key: orderNo });
  }

  componentWillUnmount() {
    const { setGlobalState } = this.props;

    setGlobalState({ showMainBottomTabBar: true });
  }

  componentDidUpdate(prevProps) {
    const { isGodoLoggedIn } = this.props;

    if (!prevProps.isGodoLoggedIn && isGodoLoggedIn) {
      this.loadData();
    }
  }

  loadData = () => {
    const { getOrderDetail, orderNo } = this.props;

    getOrderDetail(orderNo);
  }

  cancelUserOrder = async () => {
    const { cancelUserOrder, order, orderNo } = this.props;

    let msg = '입금대기중에는 개별 상품 취소가 불가능합니다. 전체 주문을 취소하시겠습니까?';
    if (order.goods.length === 1) {
      msg = '주문을 취소하시겠습니까?';
    }

    Alert.alert(
      null,
      msg,
      [
        {
          text: '아니오',
          style: 'cancel',
        },
        {
          text: '네',
          onPress: async () => {
            try {
              await cancelUserOrder(orderNo);
              this.loadData();
            } catch (e) {
              // eslint-disable-next-line no-console
              console.error(e);
            }
          },
        },
      ],
      { cancelable: false },
    );
  }

  renderHeader = () => (
    <View style={{ paddingTop: Constants.touchEffectWidth }} />
  )

  renderFooter = () => (
    <View style={{ padding: 10 }} />
  )


  renderSettlePriceLine = (label, value, prefix, setLast) => (
    <View style={[settlePriceStyles.container, setLast && settlePriceStyles.containerLast]}>
      <Text style={[
        settlePriceStyles.labelText,
        setLast && settlePriceStyles.labelTextBold,
      ]}
      >
        {label}
      </Text>
      <NumberFormat
        value={value}
        displayType="text"
        thousandSeparator
        decimalScale={0}
        prefix={prefix}
        renderText={
          text => (
            <Text style={[
              settlePriceStyles.valueText,
              setLast && settlePriceStyles.valueTextLarge,
            ]}
            >
              {text}원
            </Text>
          )}
      />
    </View>
  )


  renderSettlePrice = () => {
    const { order } = this.props;
    const { settlePrice, settleName } = order;
    const {
      totalGoodsPrice,
      totalMileage,
      totalCoupon,
      totalRefundMileage,
      totalRefundPrice,
    } = getPrices(order.goods);
    const totalDelivery = order.orderDeliveryInfo.deliveryPolicyCharge;

    let { paymentDt } = order.goods[0];
    if (paymentDt === '0000-00-00 00:00:00') {
      paymentDt = '입금 대기중';
    } else {
      paymentDt = paymentDt.slice(0, -3).replace(/-/gi, '.');
    }

    return (
      <View style={[styles.sectionContainer, { marginTop: 32 }]}>
        {this.renderSettlePriceLine('총 상품 금액', totalGoodsPrice, '', false)}
        {this.renderSettlePriceLine('총 배송비', totalDelivery, '+ ', false)}
        {this.renderSettlePriceLine('쿠폰 할인', totalCoupon, '- ', false)}
        {this.renderSettlePriceLine('포인트 사용', totalMileage, '- ', false)}
        {this.renderSettlePriceLine('총 결제 금액', settlePrice, '', true)}

      </View>
    );
  }

  renderPaymentType = () => {
    const { order } = this.props;
    const { settlePrice, settleName } = order;
    const {
      totalGoodsPrice,
      totalMileage,
      totalCoupon,
      totalRefundMileage,
      totalRefundPrice,
    } = getPrices(order.goods);
    const totalDelivery = order.orderDeliveryInfo.deliveryPolicyCharge;

    let { paymentDt } = order.goods[0];
    if (paymentDt === '0000-00-00 00:00:00') {
      paymentDt = '입금 대기중';
    } else {
      paymentDt = paymentDt.slice(0, -3).replace(/-/gi, '.');
    }

    return (
      <View style={styles.sectionContainer}>
        <View style={{ flex: 1 }}>
          <Button
            containerStyle={styles.paymentButtonContainer}
            innerComponent={(
              <View style={styles.paymentInnerContainer}>
                <View>
                </View>
                <Text style={styles.paymentText}>{settleName}</Text>
              </View>
            )}
          />
        </View>
      </View>
    );
    /*
        <View style={settlePriceStyles.smallLineContainer}>
          <Text style={settlePriceStyles.smallLabel}>
            {'결제 일시 : '}
          </Text>
          <Text style={settlePriceStyles.smallValue}>
            {paymentDt}
          </Text>
        </View>

        { totalRefundPrice > 0 && (
          this.renderSettlePriceLine('환불 금액', totalRefundPrice, '', false)
        )}
        { totalRefundMileage > 0 && (
          this.renderSettlePriceLine('적립금 환불', totalRefundMileage, '', false)
        )}
        */
  }

  getOrderDate = item => {
    const datetime = item.regDt.split(' ');
    const ymd = datetime[0].split('-');

    return ymd.join('. ');
  }

  onPressProduct = goodsNo => {
    const { router } = this.props;
    navigateToProductPage({ productId: goodsNo }, router);
  }

  render() {
    const {
      shippingAddresses,
      router,
      auth,
      historySwitch,
      order,
      orderNo,
      getOrderDetail,
      settleUserOrder,
      ...props
    } = this.props;

    if (!order) {
      return (<Spinner />);
    }

    const orderInfo = order.orderInfo || order;

    let orderName = order.goods[0].goodsNm;
    if (order.goods.length > 1) {
      orderName += ` 외 ${order.goods.length - 1}건`;
    }

    return (
      <ScrollView
        style={styles.container}
        {...Constants.scrollViewCommonProps}
      >
        <View style={styles.orderNumberContainer}>
          <Text style={styles.orderNumberText}>
            주문 번호 {orderNo}
          </Text>
        </View>

        <View style={styles.sectionSeparator} />

        <View style={[styles.sectionHeaderContainer, {marginBottom: 32}]}>
          <View style={styles.orderNameContainer}>
            <Text
              style={styles.sectionHeaderText}
              numberOfLines={1}
            >
              { orderName }
            </Text>
          </View>
          <View style={styles.orderDateContainer}>
            <Text style={styles.orderDate}>
              { this.getOrderDate(order) }
            </Text>
          </View>
        </View>

        <FlatList
          {...props}
          data={order.goods}
          keyExtractor={item => item.sno}
          renderItem={({ item }) => (
            <OrderDetailItem
              orderNo={orderNo}
              good={item}
              router={router}
              auth={auth}
              historySwitch={historySwitch}
              cancelUserOrder={this.cancelUserOrder}
              onPressProduct={this.onPressProduct}
              getOrderDetail={getOrderDetail}
              settleUserOrder={settleUserOrder}
            />
          )}
        />

        <View style={styles.sectionSeparator} />

        <View style={styles.sectionHeaderContainer}>
          <Text style={styles.sectionHeaderText}>
            배송지 정보
          </Text>
        </View>

        {renderReceiverAddress(orderInfo)}

        <View style={styles.sectionSeparator} />

        <View style={styles.sectionHeaderContainer}>
          <Text style={styles.sectionHeaderText}>
            결제수단
          </Text>
        </View>

        {this.renderPaymentType()}

        <View style={styles.sectionSeparator} />

        <View style={styles.sectionHeaderContainer}>
          <Text style={styles.sectionHeaderText}>
            최종 결제 금액
          </Text>
        </View>

        {this.renderSettlePrice()}

        <View style={{ height: Constants.bottomMargin }} />
      </ScrollView>
    );
  }
}


export default connect(
  (state, ownProps) => {
    const { orderNo } = ownProps.params;
    // eslint-disable-next-line no-prototype-builtins
    const isOrderInList = state.entities.userOrderList.hasOwnProperty(orderNo);
    let order = null;
    try {
      order = (isOrderInList) ? state.entities.userOrderList[orderNo] : state.entities.orderDetails[orderNo];
    // eslint-disable-next-line no-empty
    } catch (ignore) {
    }


    // order = {
    //   orderNo,
    //   goods: [
    //     {
    //       sno: 1,
    //       brandNm: 'Toteme',
    //       goodsNm: '시그니처 울 캐시미어 코트 카멜',
    //       imageSrc: 'https://nowinparis.com/web/upload/NNEditor/20210415/JSPS852457_WSB73019_1_shop1_164714.jpg',
    //       optionInfo: [
    //         { optionName: 'S', optionValue: 'S', optionPrice: 1000000 },
    //       ],
    //       fixedPrice: 550000,
    //       goodsPrice: 190000,
    //       optionPrice: 1000000,
    //       goodsCnt: 1,
    //       orderStatus: 'delivered',
    //       orderStatusStr: '배송완료',
    //       paymentDt: '2022-01-10 11:00:23',
    //       deliveryTraceUrl: 'https://www.dhl.com/kr-en/home/tracking.html?tracking-id=231231231',
    //       totalDivisionCouponOrderDcPrice: 1000,
    //       totalDivisionUseMileage: 500,
    //     },
    //     {
    //       sno: 2,
    //       brandNm: 'Toteme',
    //       goodsNm: '시그니처 울 캐시미어 코트 카멜',
    //       imageSrc: 'https://nowinparis.com/web/upload/NNEditor/20210415/JSPS852457_WSB73019_1_shop1_164714.jpg',
    //       optionInfo: [
    //         { optionName: 'XS', optionValue: 'XS', optionPrice: 2000000 },
    //       ],
    //       fixedPrice: 550000,
    //       goodsPrice: 190000,
    //       optionPrice: 2000000,
    //       goodsCnt: 2,
    //       orderStatus: 'shipping',
    //       orderStatusStr: '배송중',
    //       paymentDt: '2022-01-10 11:00:23',
    //       deliveryTraceUrl: 'https://www.dhl.com/kr-en/home/tracking.html?tracking-id=231231231',
    //       totalDivisionCouponOrderDcPrice: 3000,
    //       totalDivisionUseMileage: 500,
    //     },
    //   ],
    //   regDt: '2022-01-10 11:00:23',
    //   orderDeliveryInfo: {
    //     deliveryPolicyCharge: 5000,
    //   },
    //   orderInfo: {
    //     receiverName: '홍길동',
    //     receiverCellPhone: '010-1234-5232',
    //     receiverAddress: '서울시 서초구 서초대로 00길',
    //     receiverAddressSub: '0000타워 0층 0호',
    //   },
    //   settleName: '네이버 페이',
    //   settlePrice: 5570000,
    // };


    return {
      auth: state.auth,
      // eslint-disable-next-line no-prototype-builtins
      isGodoLoggedIn: state.entities.shop.hasOwnProperty('sessionId'),
      orderNo,
      order,
    };
  },
  actions,
)(OrderDetailPage);
