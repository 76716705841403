import React from 'react';

import {
  StyleSheet, Dimensions, View, Text, TouchableOpacity, TextInput,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';

import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';

import { actions } from 'react-shared';
import CustomDialog from '../../components/CustomDialog';
import AlertDialog from '../../components/AlertDialog';
import Styles from '../../constants/Styles';
import Colors from '../../constants/Colors';
import Constants from '../../constants/Constants';


const windowHeight = Dimensions.get('window').height;


const styles = StyleSheet.create({
  container: {
  },
  sectionHeaderContainer: {
    paddingStart: 20,
    paddingEnd: 20,
    paddingTop: 45,
  },
  sectionHeaderText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 22,
    lineHeight: 30,
  },
  userInfoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 36,
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  userInfoSubContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    flex: 1,
  },
  userInfoValue: {
    ...Styles.SFUITextSemiBold,
    fontSize: 16,
    color: 'black',
    height: 19,
    marginBottom: 4,
  },
  userInfoText: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 13,
    color: 'black',
    height: 16,
  },
  bottomButtonContainer: {
    flexDirection: 'row',
    paddingTop: 23,
    paddingHorizontal: 20 - 3.5,
    position: 'absolute',
    width: '100%',
    bottom: 0,
    paddingBottom: 20,
  },
  bottomButton: {
    flex: 1,
    marginHorizontal: 3.5,
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomButtonLight: {
    backgroundColor: Colors.semiGray,
  },
  bottomButtonDark: {
    backgroundColor: '#000',
  },
  bottomButtonText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 14,
    height: 17,
    lineHeight: 17,
    marginTop: 1,
  },
  bottomButtonTextDark: {
    color: '#fff',
  },
});


class MembershipWithdrawPage extends React.Component {
  state = {
    showWithdrawDialog: false,
    alert: {
      open: false,
      title: null,
      content: null,
      buttons: [],
    },
  };

  componentDidMount() {
    const { getUserSummary } = this.props;

    if (this.isLoggedIn()) {
      getUserSummary();
    }
  }

  isLoggedIn(props = null) {
    if (props === null) {
      const { auth } = this.props;
      // eslint-disable-next-line no-prototype-builtins
      return auth.hasOwnProperty('_id');
    }
    // eslint-disable-next-line no-prototype-builtins
    return props.auth.hasOwnProperty('_id');
  }

  logoutAndReset = async () => {
    const { logout } = this.props;
    // OneSignal.removeExternalUserId();
    if (global.rollbar) {
      global.rollbar.clearPerson();
    }

    try {
      await logout();
    } finally {
      this.setState({ logoutDialogOpen: false }, () => {
        window.location.href = '/';
      });
    }
  }

  getLevel() {
    const { auth: { summary } } = this.props;
    if (this.isLoggedIn() && summary) {
      return summary.levelName;
    } else {
      return '-';
    }
  }

  getCouponCount() {
    const { auth: { summary } } = this.props;
    if (this.isLoggedIn() && summary) {
      return summary.couponCount;
    } else {
      return '-';
    }
  }

  getPoint() {
    const { auth: { summary } } = this.props;
    if (this.isLoggedIn() && summary) {
      return summary.mileagePoint;
    } else {
      return '-';
    }
  }

  handleWithdraw = async () => {
    const { router, withdrawMember } = this.props;
    try {
      const result = await withdrawMember();
      if (result.success) {
        this.setState({
          alert: {
            open: true,
            title: '탈퇴가 완료되었습니다. 첫 화면으로 이동합니다.',
            content: null,
            buttons: [
              {
                text: '확인',
                onPress: () => this.logoutAndReset(),
              },
            ],
          },
        });
      } else {
        this.setState({
          alert: {
            open: true,
            title: '탈퇴 과정에서 오류가 발생했습니다. 문제가 계속되면 1:1 문의하기를 사용해 문의해주세요.',
            content: null,
            buttons: [
              {
                text: '확인',
                onPress: () => router.push('/accounts/edit'),
              },
            ],
          },
        });
      }
    } catch (e) {
      if (e.status === 401) {
        this.setState({
          alert: {
            open: true,
            title: '세션 정보가 만료되었습니다. 앱을 다시 시작합니다.',
            content: null,
            buttons: [
              {
                text: '확인',
                onPress: () => this.logoutAndReset(),
              },
            ],
          },
        });
      } else if (e.status === 403) {
        this.setState({
          alert: {
            open: true,
            title: '관리자 및 하티스트 계정은 별도 문의가 필요합니다.',
            content: null,
            buttons: [
              {
                text: '확인',
                onPress: () => router.push('/accounts/edit'),
              },
            ],
          },
        });
      } else {
        this.setState({
          alert: {
            open: true,
            title: '탈퇴 과정에서 오류가 발생했습니다. 문제가 계속되면 1:1 문의하기를 사용해 문의해주세요.',
            content: null,
            buttons: [
              {
                text: '확인',
                onPress: () => router.push('/accounts/edit'),
              },
            ],
          },
        });
      }
    }
  }

  onPressCancel = () => {
    const { router } = this.props;
    router.push('/accounts/edit');
  };

  onPressWithdraw = () => {
    this.setState({ showWithdrawDialog: true });
  }

  renderAlert() {
    const {
      alert: {
        open,
        title,
        content,
        buttons,
      },
    } = this.state;

    return (
      <div>
        <AlertDialog
          open={open}
          title={title}
          content={content}
          buttons={buttons}
        />
      </div>
    );
  }

  render() {
    const { showSmartBanner } = this.props;
    const { showWithdrawDialog } = this.state;

    const containerHeight = windowHeight
      - Constants.mainHeaderHeight - (showSmartBanner ? Constants.smartBannerHeight : 0);

    return (
      <View style={[
        styles.container,
        {
          height: containerHeight,
        },
      ]}
      >
        <View style={styles.sectionHeaderContainer}>
          <Text style={styles.sectionHeaderText}>탈퇴하시면 쿠폰과 적립금이 소멸됩니다. 그래도 탈퇴하시겠습니까?</Text>
        </View>

        <View style={styles.userInfoContainer}>
          <View style={styles.userInfoSubContainer}>
            <Text style={styles.userInfoValue}>
              {this.getLevel()}
            </Text>
            <Text style={styles.userInfoText}>
              회원 등급
            </Text>
          </View>

          <View style={styles.userInfoSubContainer}>
            <Text style={styles.userInfoValue}>
              {this.getCouponCount()}
            </Text>
            <Text style={styles.userInfoText}>
              쿠폰
            </Text>
          </View>

          <View style={styles.userInfoSubContainer}>
            <NumberFormat
              value={this.getPoint()}
              displayType="text"
              thousandSeparator
              allowEmptyFormatting
              defaultValue="-"
              decimalScale={0}
              renderText={value => <Text style={styles.userInfoValue}>{value}</Text>}
            />
            <Text style={styles.userInfoText}>
              포인트
            </Text>
          </View>
        </View>

        <View style={[
          styles.bottomButtonContainer,
        ]}
        >
          <TouchableOpacity
            style={[
              styles.bottomButton,
              styles.bottomButtonLight,
            ]}
            onPress={this.onPressCancel}
          >
            <Text style={[
              styles.bottomButtonText,
              styles.bottomButtonTextLight,
            ]}
            >
              취소
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.bottomButton, styles.bottomButtonDark]}
            onPress={this.onPressWithdraw}
          >
            <Text style={[
              styles.bottomButtonText,
              styles.bottomButtonTextDark,
            ]}
            >
              탈퇴
            </Text>
          </TouchableOpacity>
        </View>
        <CustomDialog
          dialogOpen={showWithdrawDialog}
          onConfirm={() => {
            this.setState({
              showWithdrawDialog: false,
            });
            this.handleWithdraw()
          }}
          onCancel={() => {
            this.setState({
              showWithdrawDialog: false,
            });
          }}
          onClose={() => {
            this.setState({ showWithdrawDialog: false });
          }}
          confirmColor="default"
          confirmText={(<span style={{ color: '#ff3e3e' }}>영구삭제</span>)}
          cancelText="취소"
          title=""
          description={(
            <span style={{ color: 'black' }}>
              쿠폰과 적립금은 복구가 불가능합니다. 계정을 영구 삭제하시겠습니까?
            </span>
          )}
        />
        {this.renderAlert()}
      </View>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
    showSmartBanner: state.globalLayoutInfo.showSmartBanner,
  }),
  actions
)(MembershipWithdrawPage);
