import React from 'react';
import { FlatList, View, StyleSheet } from 'react-native';
import Collection from './Collection';
import HomeFeedSectionHeader from './HomeFeedSectionHeader';

import Styles from '../constants/Styles';
import Constants from '../constants/Constants';


const MAX_COLLETIONS = 4;

export default class Collections extends React.Component {
  render() {
    const {
      collections, onPressPost, onPressHeartist,
      containerStyle, ...props
    } = this.props;

    Object.assign(props, {
      numColumns: 2,
      columnWrapperStyle: styles.columnWrapper,
      renderItem: ({ item }) => (
        <Collection
          style={styles.collectionItem}
          post={item}
          onPressPost={onPressPost}
          onPressHeartist={onPressHeartist}
        />
      ),
      contentContainerStyle: containerStyle,
      style: styles.gridContainer,
    });

    return (
      <View>
        <HomeFeedSectionHeader title="collection" />
        <FlatList
          {...props}
          data={collections.slice(0, MAX_COLLETIONS)}
          keyExtractor={item => item._id} // eslint-disable-line no-underscore-dangle
        />
      </View>
    );
  }
}

const itemSeparatorWidth = 9;
const itemMargin = (itemSeparatorWidth - Constants.touchEffectWidth * 2) / 2;
const styles = StyleSheet.create({
  columnWrapper: {
    width: '100%',
  },
  collectionItem: {
    width: '50%',
    ...Styles.touchEffect,
    margin:itemMargin,
  },
  gridContainer: {
    margin: -1 * Constants.touchEffectWidth - itemMargin,
  },
});
