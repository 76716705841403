import * as React from 'react';

function SvgIcLikeSelected(props) {
  return (
    <svg width={18} height={18} {...props}>
      <path
        d="M10.816 13.166l3.569-3.603a3.87 3.87 0 000-5.437 3.783 3.783 0 00-5.385 0 3.783 3.783 0 00-5.385 0 3.87 3.87 0 000 5.437L9 15l1.816-1.834z"
        fill="#EE2424"
        stroke="#EE2424"
        strokeWidth={1.5}
        fillOpacity={0.6}
        fillRule="evenodd"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgIcLikeSelected;

