import React from 'react';
import { Dimensions, StyleSheet, Animated, View } from 'react-native';
import { connect } from 'react-redux';

import { actions, loadEntities } from 'shared';
import { getProductSellPrice } from 'shared/util/product';

import { Constants, Styles } from '../../../constants';

import {
  WishlistItem, Button,
} from '../../../components-v2';
import Header from './Header';
import BlankPage from './BlankPage';

import Spinner from '../../../nativeComponents/Spinner';

import { checkoutItems } from '../../checkout/CheckoutPage';


const {
  height: windowHeight,
} = Dimensions.get('window');


const MAX_COUNT_PER_PAGE = 6;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  listContainer: {
  },
  footerButtonContainer: {
    paddingTop: 20,
    paddingBottom: 21,
    backgroundColor: '#000000',
    borderWidth: 0,
  },
  footerButtonText: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
    letterSpacing: '2%',
    color: '#ffffff',
  },
});


export class WishlistPage extends React.Component {
  constructor(props) {
    super(props);

    this.setEditMode = this.setEditMode.bind(this);
    global.favScreenRef = this;
  }

  listRef = React.createRef();

  state = {
    refreshing: false,
    isFetchingNextPage: false,
    filter: 'heartit',
    editMode: false,
    selectedItem: [],
    countResult: 0,
  };

  loadData = nextPage => {
    const { loadLikedProducts } = this.props;
    const { filter } = this.state;
    if (!loadLikedProducts) {
      return;
    }

    this.setState({ isFetchingNextPage: nextPage }, () => {
      loadLikedProducts(nextPage, MAX_COUNT_PER_PAGE, filter);
    });
  }

  scrollToTop = () => {
    this.listRef.scrollToOffset(0);
  }

  deleteWishlist = async (item) => {
    const { logEventClick, unlikeProduct, likedProducts, setGlobalState } = this.props;

    const relatedObject = {
      favProduct: item,
    };
    const productId = item._id || item.goodsNo;

    let { post } = this.props;
    if (!post && !!item.post) {
      // eslint-disable-next-line prefer-destructuring
      post = item.post;
    }

    if (post) {
      relatedObject.post = post;
    }

    try {
      logEventClick({
        page_name: 'Wishlist',
        click_object_name: 'wishlist_del',
        click_object_key: item.godoGoodsNo || productId
      });
      await unlikeProduct(productId, relatedObject);
      this.setState(({ selectedItem }) => ({ 
        selectedItem: selectedItem.filter(e => e._id !== item._id),
      }));

      if (likedProducts.filter(x => x.liked).length === 0) {
        this.setState({ editMode: false });
        setGlobalState({ showMainBottomTabBar: true });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('WishlistItem delete', error);
    }
  }

  addToCart = async (itemList) => {
    const { logEventClick, addItemToCart } = this.props;
    const errorMsg = '상품을 쇼핑백에 담는 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.';
    try {
      await itemList.reduce(async (promise, item) => {
        await promise;
        const option = item.optionKey != -1 ? item.optionKey : '';
        logEventClick({
          page_name: 'Wishlist',
          click_object_name: 'cart_add',
          click_object_key: item.godoGoodsNo
        });
        const ret = await addItemToCart(item.godoGoodsNo, 1, option, false);
        if (!ret.success) {
          alert(`${errorMsg} (E1${ret.errorCode})`);
          return;
        }
      }, Promise.resolve());

      const valueToSum = itemList.reduce((sum, item) => {
        let productPrice = getProductSellPrice(item);
        // FIXME
        let optionPrice = 0;
        return sum + productPrice + optionPrice;
      }, 0);
      const param = {
        content_type: "product",
        contents: JSON.stringify(itemList.map((item) => {
          return {
            id: item.godoGoodsNo,
            quantity: 1
          }
        })),
        value: valueToSum,
        currency: "KRW",
      };
      fbq('track', 'AddToCart', param);

      alert('상품을 쇼핑백에 담았습니다');
    } catch (error) {
      if (error.errorCode) {
        alert(`${errorMsg} (E2${error.errorCode})`);
      } else {
        alert(errorMsg);
      }
    }
  }

  checkoutNow = async (itemList) => {
    const { auth, addItemToCart } = this.props;
    const errorMsg = '오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.';
    try {
      const itemIndexList = await itemList.reduce(async (promise, item) => {
        const result = await promise.then();
        
        const option = item.optionKey != -1 ? item.optionKey : '';
        const ret = await addItemToCart(item.godoGoodsNo, 1, option, true);
        if (!ret.success) {
          alert(`${errorMsg} (E1${ret.errorCode})`);
          return;
        }

        const { sno } = ret.data;

        result.push(sno);

        return Promise.resolve(result);
      }, Promise.resolve([]));
      checkoutItems(itemIndexList, auth);  

    } catch (error) {
      console.error(error);

      if (error.errorCode) {
        alert(`${errorMsg} (E2${error.errorCode})`);
      } else {
        alert(errorMsg);
      }
    }
  }

  setEditMode = (enable) => {
    const { likedProducts, setGlobalState } = this.props;

    if (likedProducts.filter(x => x.liked).length === 0) {
      this.setState({ editMode: false }, () => {
        setGlobalState({ showMainBottomTabBar: true });
      });
    } else {
      this.setState({ editMode: enable }, () => {
        setGlobalState({ showMainBottomTabBar: !enable });
      });
    }
  }

  componentDidMount() {
    const { logEventPageview, getPartnerShippingProducts } = this.props;

    this.loadData(false);
    getPartnerShippingProducts();

    logEventPageview({ page_name: 'Wishlist' });
  }

  componentDidUpdate(prevProps, prevState) {
    const { pagination, loadLikedProducts, likedProducts } = this.props;
    const { filter, countResult } = this.state;

    if (prevProps.pagination.isFetching && !pagination.isFetching) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isFetchingNextPage: false, refreshing: false });
    }

    if ((!prevProps.loadLikedProducts && loadLikedProducts)
      || (prevState.filter !== filter)
    ) {
      this.loadData(false);
    }

    if (prevProps.pagination.countResult !== countResult) {
      this.setState({ countResult: pagination.countResult });
    }

    if (prevProps.likedProducts != likedProducts) {
      if (likedProducts.filter(x => x.liked).length == 0) {
        this.setState({ editMode: false});
      }
    }
  }

  componentWillUnmount() {
    const { setGlobalState } = this.props;
    setGlobalState({ showMainBottomTabBar: true });
  }

  scrollToTop() {
    if (this.listRef) {
      this.listRef.scrollToIndex({ index: 0 });
    }
  }


  renderHeader = (disabled = false) => {
    const { likedProducts } = this.props;
    const { editMode, selectedItem, countResult } = this.state;

    const onPressSelectAll = () => {
      if (this.state.selectedItem.length === likedProducts.filter(x => x.liked).length) {
        this.setState({
          selectedItem: []
        });
      } else {
        this.setState({
          selectedItem: [...likedProducts.filter(x => x.liked)]
        });
      }
    }

    const onPressEdit = () => {
      this.setEditMode(true);
    }

    return (
      <Header
        disabled={disabled}
        countResult={countResult}
        likedProducts={likedProducts.filter(x => x.liked)}
        editMode={editMode}
        selectedItem={selectedItem}
        onPressSelectAll={onPressSelectAll.bind(this)}
        onPressEdit={onPressEdit.bind(this)}
        onPressAddToCart={this.addToCart.bind(this)}
        onPressDeleteWishlist={this.deleteWishlist.bind(this)}
        />
    );
  }

  renderItem = ({ item, index }) => {
    const { router } = this.props;
    const { editMode } = this.state;

    return (
      <View>
        <View 
          style={{ 
            height: 0.5,
            backgroundColor: '#CFCFCF',
            marginTop: 10,
            marginBottom: 20.5,
            marginHorizontal: Constants.viewHorizontalPadding,
          }}
          />

        <WishlistItem
          showHeaderControl={editMode}
          item={item}
          deleteWishlist={this.deleteWishlist.bind(this)}
          addToCart={this.addToCart.bind(this)}
          checkoutNow={this.checkoutNow.bind(this)}
          checked={this.state.selectedItem.filter(x => x._id === item._id).length > 0}
          router={router}
          onChecked={(checked) => {
            this.setState((prevState) => {
              if (checked) {
                return {
                  selectedItem: [...prevState.selectedItem, item]
                };
              } else {
                return {
                  selectedItem: [...prevState.selectedItem.filter(x => x._id != item._id)]
                };
              }
            });
          }}
        />
        <View style={{ height: 10 }} />
      </View>
    );
  }

  renderFooter() {
    const onPress = () => {
      this.setEditMode(false);
    };

    return (
      <Button
        title='취소'
        containerStyle={styles.footerButtonContainer}
        titleStyle={styles.footerButtonText}
        onPress={onPress.bind(this)}
        />
    );
  }

  render() {
    const {
      router,
      likedProducts,
      pagination,
      showSmartBanner,
    } = this.props;
    const { isFetchingNextPage, refreshing } = this.state;

    if (!isFetchingNextPage && pagination.isFetching && !refreshing) {
      return (<Spinner />);
    }

    if (!pagination.isFetching && likedProducts.filter(product => product.liked).length === 0) {
      return (
        <BlankPage
          HeaderComponent={this.renderHeader(true)}
          router={router}
          />
      );
    }

    const listStyles = [styles.listContainer];
    listStyles.push({
      height: windowHeight
        - (showSmartBanner && Constants.smartBannerHeight)
        - Constants.mainHeaderHeight
        - Constants.bottomTabBarHeight,
    });

    return (
      <View style={styles.container}>
        <Animated.FlatList
          ref={ref => { this.listRef = ref; }}
          data={likedProducts.filter(x => x.liked)}
          stickyHeaderIndices={[0]}
          keyExtractor={item => item._id}
          onEndReached={() => {
            if (refreshing || pagination.isFetching || pagination.nextPageUrl === null) {
              return;
            }
            this.loadData(true);
          }}
          onEndReachedThreshold={0.2}
          showsVerticalScrollIndicator={false}
          onRefresh={() => {
            if (!refreshing) {
              this.setState({ refreshing: true }, () => {
                this.loadData(false);
              });
            }
          }}
          refreshing={refreshing}
          style={listStyles}
          renderItem={this.renderItem.bind(this)}
          ListHeaderComponent={this.renderHeader(false)}
          ListFooterComponent={(
            <View 
              style={{ 
                height: 0.5,
                backgroundColor: '#CFCFCF',
                marginTop: 10,
                marginBottom: 20.5,
                marginHorizontal: Constants.viewHorizontalPadding,
              }}
              />
          )} 
          />
          {this.state.editMode && this.renderFooter()}
      </View>
    );
  }
}

export function favScrollToTop() {
  if (global.favScreenRef) {
    global.favScreenRef.scrollToTop();
  }
}


export default connect(
  state => {
    return {
      auth: state.auth,
      historySwitch: state.settings.historySwitch,
      ...loadEntities(state, 'likedProducts', 'likedProducts'),
    };
  },
  actions,
)(WishlistPage);
