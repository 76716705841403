import React from 'react';
import {
    StyleSheet, View, ScrollView, Text,
} from 'react-native';
import { connect } from 'react-redux';

import { actions } from 'shared';

import { Constants, Styles } from '../../../constants';
import {
  TouchableHighlightWithContainer, BirthdaySelector,
} from '../../../components-v2';

import TipBox from './TipBox';
import ClothesSizeSelector from './ClothesSizeSelector';
import ShoesSizeSelector from './ShoesSizeSelector';
import { runAfterJoinDone } from '../../../containers/auth/util';


const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  listContainer: {
  },
  descContainer: {
    marginTop: 20,
    marginBottom: 45,
  },
  descTitle: {
    ...Styles.pretendard600,
    fontSize: 20,
    lineHeight: 24,
    marginBottom: 20,
  },
  descDetail: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 23,
  },
  birthdayContainerStyle: {
    paddingTop: 16,
    paddingBottom: 9,
    borderBottomWidth: 0.5,
    borderBottomColor: '#000',
  },
  birthdayInputContentStyle: {
    opacity: 1,
  },
  sizeInputContentStyle: {
    opacity: 1,
  },
  sizeContainerStyle: {
    marginTop: 6,
    paddingVertical: 13,
    paddingLeft: 20,
    paddingRight: 15,
    borderWidth: 0.5,
    borderColor: '#000',
  },
  bottomButtonContainer: {
    position: 'absolute',
    width:'100%',
    start: 0,
    end: 0,
    bottom: 0,
    backgroundColor: '#000',
    flexDirection: 'row',
  },
  bottomButton: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 20,
    paddingBottom: 12 + Constants.bottomMargin,
  },
  bottomButtonText: {
    textAlign: 'center',
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    color: '#ffffff',
  },
  bottomButtonTextLeft: {
  },
  bottomButtonTextRight: {
  },
});


class JoinDonePage extends React.Component {
  state = {
    selectedClothesSizeList: [],
    selectedShoesSizeList: [],
  };

  componentDidMount() {
    const { logEventPageview } = this.props;

    logEventPageview({ page_name: 'JoinDone' });
  }

  bootstrapAsync = async () => {
  };

  renderInputForm() {
    const { auth: { name='' } } = this.props;

    return (
      <View style={styles.descContainer}>
        <Text style={styles.descTitle}>가입이 완료되었습니다.</Text>
        <Text style={styles.descDetail}>
          {name}님, 회원가입을 추가합니다.{"\n"}
          추가 정보를 입력하면 더 많은 혜택을 드려요.
        </Text>
      </View>
    );
  }

  renderBottomButton() {
    const { router } = this.props;

    const onPressLater = () => {
      runAfterJoinDone(router);
    };
    const onPressSave = async () => {
      const { auth, updateUser } = this.props;
      const {
        birthday: bdata,
        selectedClothesSizeList,
        selectedShoesSizeList
      } = this.state;

      if (!bdata) {
        alert('생년월일을 입력해주세요.');
        return;
      }
      if (!selectedClothesSizeList || selectedClothesSizeList.length === 0) {
        alert('옷 사이즈를 선택해주세요.');
        return;
      }
      if (!selectedShoesSizeList || selectedShoesSizeList.length === 0) {
        alert('신발 사이즈를 선택해주세요.');
        return;
      }

      const params = {
        birthYear: bdata.split('.')[0],
        birthday: bdata,
        mySize: {
          clothing: selectedClothesSizeList.map(s => s.name),
          shoes: selectedShoesSizeList.map(s => s.name),
        },
      };
      try {
        await updateUser(auth._id, params);
        runAfterJoinDone(router);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error=', error);
        alert('저장 중 오류가 발생하였습니다. 잠시 후 다시 시도해주세요.');
      }
    };

    return (
      <View style={styles.bottomButtonContainer}>
        <TouchableHighlightWithContainer
          onPress={onPressLater}
          containerStyle={styles.bottomButton}
          >
          <Text style={[styles.bottomButtonText, styles.bottomButtonTextLeft]}>
            다음에 입력하기
          </Text>
        </TouchableHighlightWithContainer>

        <View style={{ width: 4 }} />

        <TouchableHighlightWithContainer
          onPress={onPressSave}
          containerStyle={styles.bottomButton}
          >
          <Text style={[styles.bottomButtonText, styles.bottomButtonTextRight]}>
            입력 사항 저장하기
          </Text>
        </TouchableHighlightWithContainer>
      </View>
    );
  }

  renderContent() {
    const {
      modalProps,
      setModalProps,
    } = this.props;
    const {
      birthday,
      selectedClothesSizeList,
      selectedShoesSizeList,
    } = this.state;

    const onChangeBirthday = birthday => {
      this.setState({ birthday });
    }

    const onChangeSelectedClothesSizeList = selectedSizeList => {
      this.setState({ selectedClothesSizeList: selectedSizeList });
    }

    const onChangeSelectedShoesSizeList = selectedSizeList => {
      this.setState({ selectedShoesSizeList: selectedSizeList });
    }

    return (
      <View style={styles.contentContainer}>
        {this.renderInputForm()}
        <TipBox value='생년월일을 입력하면 생일에 맞춰 쿠폰을 제공해드립니다.' />

        <View style={{ height: 16 }} />

        <BirthdaySelector
          inputContainerStyle={styles.birthdayContainerStyle}
          inputContentStyle={birthday && styles.birthdayInputContentStyle}
          date={birthday}
          onChangeDate={onChangeBirthday}
          modalProps={modalProps}
          setModalProps={setModalProps}
          />

        <View style={{ height: 50 }} />

        <TipBox value='사이즈를 선택하면 쇼핑시 내 사이즈 재고 필터링이 가능합니다.' />

        <View style={{ height: 15 }} />

        <ClothesSizeSelector
          inputContainerStyle={styles.sizeContainerStyle}
          inputContentStyle={styles.sizeInputContentStyle}
          onChangeSelectedSizeList={onChangeSelectedClothesSizeList}
          selectedSizeList={selectedClothesSizeList}
          showDropdownIcon
          modalProps={modalProps}
          setModalProps={setModalProps}
          />

        <View style={{ height: 20 }} />

        <ShoesSizeSelector
          inputContainerStyle={styles.sizeContainerStyle}
          inputContentStyle={styles.birthdayInputContentStyle}
          onChangeSelectedSizeList={onChangeSelectedShoesSizeList}
          selectedSizeList={selectedShoesSizeList}
          showDropdownIcon
          modalProps={modalProps}
          setModalProps={setModalProps}
          />
      </View>
    )
  }

  render() {
    return (
      <div>
        <View style={styles.container}>
          <ScrollView
            bounces={false}
            style={styles.listContainer}
          >
            {this.renderContent()}
          </ScrollView>
        </View>
        {this.renderBottomButton()}
      </div>
    );
  }
}


export default connect(
  state => ({ auth: state.auth }),
  actions,
)(JoinDonePage);
