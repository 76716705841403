import * as React from 'react';

function SvgIcForward(props) {
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M10 8l4 4-4 4"
        stroke="#FFF"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIcForward;
