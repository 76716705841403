import React from 'react';
import { StyleSheet, ScrollView, View, Dimensions } from 'react-native';
import { connect } from 'react-redux';

import { actions, loadEntities } from 'shared';

import { Constants } from '../../../constants';
import { Heartist } from '../../../components-v2';

import TabHeader from './TabHeader';
import TabFeed from './TabFeed';
import TabPick from './TabPick';


const {
  height: windowHeight
} = Dimensions.get('window');


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
  },
  profileContainer: {
    zIndex: 99,
    width: '100%',
    maxWidth: 960,
    overflow: 'hidden',
    paddingBottom: 20,
  },
});


class ProfilePage extends React.Component {

  listRef = React.createRef();

  state = {}

  constructor(props) {
    super(props);
    global.settingsPageRef = this;
  }

  onChangeTab = (tab) => {
    const { router, tabname } = this.props;


    switch (tab.key) {
      case 'feed':
      case 'pick':
        if (tabname !== tab.key) {
          router.push(`/accounts/profile/${tab.key}`);
        }
        break;

      case 'following':
        router.push('/accounts/following');
        break;

      default:
        break;
    }
  }

  scrollToTop() {
    this.listRef.scrollTo({ x: 0, y: 0, animated: true });
  }

  componentDidMount() {
    const {
      loadFollowingHeartists,
      logEventPageview,
    } = this.props;

    loadFollowingHeartists(false);

    logEventPageview({ page_name: 'MyProfile' });
  }

  renderProfile() {
    const {
      followStatus,
      auth,
      getFollowStatus,
      followUser,
      unfollowUser,
      user,
      router,
    } = this.props;

    return (
      <View style={styles.profileContainer}>
        <Heartist
          followStatus={followStatus}
          auth={auth}
          getFollowStatus={getFollowStatus}
          followUser={followUser}
          unfollowUser={unfollowUser}
          user={user}
          router={router}
        />
      </View>
    );
  }

  renderTabBody() {
    const {
      user,
      showSmartBanner,
      loadStylePostCountByUser,
      loadStylePostListByUser,
      loadLikedProductsByUser,
      upperState,
      router,
      posts,
      tabname,
      stylePostsPagination,
      modalProps,
      setModalProps,
    } = this.props;

    const commonProps = {
      showSmartBanner,
      router,
    }

    if (tabname=== 'feed') {

      return (
        <TabFeed
          {...commonProps}
          pagination={stylePostsPagination}
          loadData={(nextPage, query) => {
            if (!nextPage) {
              loadStylePostCountByUser(user._id);
            }
            return loadStylePostListByUser(nextPage, user._id);
          }}
          posts={posts}
          />
      );
    }

    if (tabname === 'pick') {

      const onPressProduct = (item) => {
        const { router } = this.props;
        router.push(`/product/${item.godoGoodsNo}`);
      }
      const likedProductsKey = actions.loadLikedProductsByUserKey(user._id)
      const { products: likedProducts, pagination: likedProductsPagination } = loadEntities(
        upperState, likedProductsKey, likedProductsKey, 'pagination', 'products');

      return (
        <TabPick
          {...commonProps}
          loadData={(nextPage, query) => {
            if (!nextPage) {
              loadStylePostCountByUser(user._id);
            }
            return loadLikedProductsByUser(user._id, nextPage);
          }}
          pagination={likedProductsPagination}
          likedProducts={likedProducts}
          onPressProduct={onPressProduct}
          setModalProps={setModalProps}
          modalProps={modalProps}
          />
      );
    }
  }

  render() {
    const {
      showSmartBanner, postsCount, followingHeartists, tabname,
    } = this.props;

    const scrollViewStyles = [
      styles.scrollView,
      {
        height: windowHeight
          - (showSmartBanner && Constants.smartBannerHeight)
          - Constants.mainHeaderHeight
          - Constants.bottomTabBarHeight,
      }
    ]
    return (
      <View style={styles.container}>
        <ScrollView
          ref={(ref) => this.listRef = ref}
          style={scrollViewStyles}
          stickyHeaderIndices={[1]}
          showsVerticalScrollIndicator={false}
          >
          {this.renderProfile()}
          <TabHeader
            onChange={this.onChangeTab}
            tabname={tabname}
            postsCount={postsCount}
            followingCount={followingHeartists.length}
            />
          {this.renderTabBody()}
        </ScrollView>
      </View>
    );
  }
}


ProfilePage.propTypes = {

}


ProfilePage.defaultProps = {

};

export default connect(
  (state, { router, params: { tabname='feed' } }) => {
    const { entities: { heartists } } = state;
    const user = state.auth;
    const userId = state.auth._id;

    let followStatus = { following: false };
    // eslint-disable-next-line no-prototype-builtins
    if (state.entities.heartists.hasOwnProperty(userId)) {
      const { following } = state.entities.heartists[userId];
      if (following) {
        followStatus = { following };
      }
    }

    const { posts, pagination: stylePostsPagination } = loadEntities(state, `stylePosts.${userId}`, `stylePosts`, 'pagination', 'posts');
    const { followingHeartists } = loadEntities(state, 'followingHeartists', 'heartists', 'pagination', 'followingHeartists');
    const filteredFollowingHeartists = followingHeartists.filter(item => item.following);

    return {
      auth: state.auth,
      user,
      userId,
      historySwitch: state.settings.historySwitch,
      postsCount: state.entities.stylePostsCount[userId],
      followStatus,
      upperState: state,
      posts,
      stylePostsPagination,
      followingHeartists: filteredFollowingHeartists,
      tabname,
    };
  },
  actions,
)(ProfilePage);
