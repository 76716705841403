import React, {useRef} from 'react'
import { Animated, Dimensions, StyleSheet, View, Text } from 'react-native';
import { browserHistory } from 'react-router';

import { Styles, Constants } from '../../../constants';
import NaverPayTextType from '../../../icons/NaverPayTextType';
import NaverPayButtonImage from '../../../assets/images/npay_sp_payment.png';
import NaverPayZzimImage from '../../../assets/images/npay_sp_zzim.png';
import {
  SquareCheckBoxIcon, MinusIcon, PlusIcon,
  SoldoutAlarmIcon, SoldoutAlarmWhiteIcon,
} from '../../../icons-v2';
import {
  BottomSheetModal,
  TouchableHighlightWithContainer,
  SizeListWithCheckBox,
 } from '../../../components-v2';
import NumberFormat from 'react-number-format';
import { isHeartitProduct } from 'shared/util/product';

import RegistrationCoupon from './RegistrationCoupon';


const {
  width: windowWidth,
  height: windowHeight,
} = Dimensions.get('window');


const styleHtml = {
  footerContainer: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
  },
  naverPayButtonContainer: {
    backgroundImage: `url('${NaverPayButtonImage}')`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#00bd39',
    backgroundSize: '63px auto',
    backgroundPositionX: 'center',
    borderColor: '#00bd39',
    width: 129,
    height: 35,
    borderWidth: 1,
  },
  naverPayWishlistContainer: {
    backgroundImage: `url('${NaverPayZzimImage}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '33px auto',
    width: 35,
    height: 35,
    borderWidth: 1,
    borderColor: '#bfc4c7',
  },
};


const styles = StyleSheet.create({
  container: {
    paddingBottom:'60px'
  },
  listContainer: {
    maxHeight: windowHeight * 0.8,
  },
  listContentFooterContainer: {
    marginTop: 9.5,
  },
  listContentFooterItemContainer: {
    borderWidth: 0.5,
    marginHorizontal: Constants.viewHorizontalPadding,
    padding: 16,
  },
  listContentFooterTop: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingEnd: 1,
  },
  listContentFooterItemName: {
    ...Styles.pretendard400,
    fontSize: 14,
    lineHeight: 14,
    color: 'black',
    opacity: 0.5,
  },
  listContentFooterItemNameActive: {
    opacity: 1,
  },
  sizeSelectorDeleteText: {
    ...Styles.pretendard400,
    fontSize: 14,
    lineHeight: 14,
    color: 'rgba(44, 44, 46, 0.7)',
  },
  listContentFooterBottom: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 32,
  },
  sizeSelectorContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginStart: 4,
  },
  sizeSelectorCount: {
    ...Styles.pretendard400,
    fontSize: 18,
    lineHeight: 21.6,
    height: 22,
    width: 8 + 24 * 2,
    textAlign: 'center',
  },
  listContentFooterItemPrice: {
    ...Styles.pretendard400,
    fontSize: 18,
    lineHeight: 21.6,
    height: 22,
  },
  promotionTopContainer: {
    flexDirection: 'row',
    paddingTop: 20,
    paddingBottom: 5,
    alignItems:'center',
    justifyContent:'center'
  },
  promotionInnerContainer: {
    backgroundColor: '#000000',
    padding: 8,
    borderRadius:8
  },
  promotionText: {
    ...Styles.pretendard600,
    color:'#ffffff',
    fontSize: 12,
    lineHeight: 16.8,
  },
  footerTopContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 20,
    paddingBottom: 5,
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  couponBannerStyle: {
    paddingHorizontal: Constants.viewHorizontalPadding,
    marginTop: 15,
    marginBottom: 10,
  },
  naverPayEmptyContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingStart: Constants.viewHorizontalPadding,
    paddingEnd: Constants.viewHorizontalPadding + 1,
  },
  naverPayGuideContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  naverPayGuideText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 9,
    lineHeight: 12,
    marginTop: 1,
    marginStart: 3,
  },
  naverPayButtonsContainer: {
    flexDirection: 'row',
  },
  naverPayButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'rgb(0, 204, 8)',
    paddingVertical: 10,
    paddingHorizontal: 25.5,
  },
  naverPayButtonText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 12,
    lineHeight: 16,
    color: '#ffffff',
    marginStart: 3,
  },
  footerBottomContainer: {
    flex: 1,
    backgroundColor: '#000000',
    flexDirection: 'row',
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  footerButtonContainer: {
    width: '100%',
    paddingTop: 20,
    alignItems: 'center',
    paddingBottom: 21,
  },
  footerButtonText: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
    letterSpacing: '2%',
    color: '#ffffff',
    opacity: 0.5,
  },
  footerButtonTextActive: {
    opacity: 1,
  },
  soldoutItemContentContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 29,
    marginBottom: 26,
    marginStart: Constants.viewHorizontalPadding,
    marginEnd: Constants.viewHorizontalPadding + 4,
  },
  soldoutItemCheckBox: {
    marginTop: 2.5,
    marginEnd: 12,
  },
  soldoutItemText: {
    ...Styles.pretendard400,
    fontSize: 16,
    lineHeight: 19.2,
  },
  soldoutAlarmButtonContainer: {
    position: 'absolute',
    top: -8,
    end: 0,
    borderWidth: 0.5,
  },
  soldoutAlarmButton: {
    width: 160,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 7,
    paddingStart: 13,
    paddingEnd: 10,
  },
  soldoutAlarmButtonActive: {
    backgroundColor: '#000000',
  },
  soldoutAlarmText: {
    ...Styles.pretendard600,
    fontSize: 14,
    lineHeight: 16.8,
  },
  soldoutAlarmTextActive: {
    color: '#ffffff',
  },
  soldoutAlarmIcon: {
    marginStart: 3,
  },
});


class CheckoutModal extends BottomSheetModal {

  state = {
    isNaverPay: false
  };

  title = '구매옵션 선택하기';

  checkLogin = () => {
    const { auth, onClose } = this.props;
    if (!auth || !auth.token) {
      alert('로그인해주시기 바랍니다.');
      browserHistory.push('/accounts/login');
      onClose();
      return false;
    }
    return true;
  }

  onPressDelete = (item) => {
    const {
      onChangeSelectedItemList,
      selectedItemList,
    } = this.props;

    const newSelectedItemList = selectedItemList.filter((e) => e.id !== item.id);

    onChangeSelectedItemList(newSelectedItemList);
  };

  onPressSizeCountDec = (item) => {
    // console.log(`onPressSizeCountDec item=${JSON.stringify(item)}`);
    const {
      onChangeSelectedItemList,
      selectedItemList,
    } = this.props;

    const newSelectedItemList = [...selectedItemList];
    for (let i = 0; i < newSelectedItemList.length; i++) {
      const e = newSelectedItemList[i];
      if (item.id !== e.id) {
        continue;
      }
      e.count -= 1;
    }

    onChangeSelectedItemList(newSelectedItemList);
  };

  onPressSizeCountInc = (item) => {
    console.log(`onPressSizeCountDec item=${JSON.stringify(item)}`);
    const {
      onChangeSelectedItemList,
      selectedItemList,
    } = this.props;

    const newSelectedItemList = [...selectedItemList];
    for (let i = 0; i < newSelectedItemList.length; i++) {
      const e = newSelectedItemList[i];
      if (item.id !== e.id) {
        continue;
      }
      e.count += 1;
    }

    onChangeSelectedItemList(newSelectedItemList);
  };

  renderItemComponent = (item) => {
    const {
      product,
      soldoutAlarmData,
      onAddSoldoutFollowingProduct,
      onDeleteSoldoutFollowingProduct,
    } = this.props;

    let checkoutButtonText = '';
    let onPressSoldoutAlarm = null;
    const soldoutAlarmButtonStyles = [
      styles.soldoutAlarmButton
    ];
    const soldoutAlarmTextStyles = [
      styles.soldoutAlarmText,
    ];

    const stockCnt = parseInt(item.stockCnt, 10);
    const isSoldOut = (item.optionSellFl !== 'y' || (product.stockCnt !== '∞' && stockCnt === 0 ));
    if (!isSoldOut) {
      return null;
    }

    checkoutButtonText = '재입고 알림 설정하기'
    onPressSoldoutAlarm = () => {
      if (!this.checkLogin()) {
        return;
      }
      const body = {
        optionKey: item.id,
        optionVal: item.name,
      };
      onAddSoldoutFollowingProduct(item.productId, body);
    }

    // 품절시 재입고 알림 설정이 된경우
    let isSoldoutAlarmActivated = false;
    if(soldoutAlarmData){
      isSoldoutAlarmActivated = (soldoutAlarmData.findIndex(s => (s.optionKey === item.id)) >= 0);
      if (isSoldoutAlarmActivated) {
        checkoutButtonText = '재입고 알림 설정됨';
        onPressSoldoutAlarm = () => {
          onDeleteSoldoutFollowingProduct(item.productId, item.id);
        }
        soldoutAlarmButtonStyles.push(
          styles.soldoutAlarmButtonActive,
        )
        soldoutAlarmTextStyles.push(
          styles.soldoutAlarmTextActive,
        );
      }
    }


    return (
      <View style={styles.soldoutItemContentContainer}>
        <View style={styles.soldoutItemCheckBox}>
          <SquareCheckBoxIcon />
        </View>
        <Text style={styles.soldoutItemText}>{item.name}</Text>
        <TouchableHighlightWithContainer
          onPress={onPressSoldoutAlarm}
          containerStyle={styles.soldoutAlarmButtonContainer}
        >
          <View style={soldoutAlarmButtonStyles}>
            <Text style={soldoutAlarmTextStyles}>{checkoutButtonText}</Text>
            <View style={styles.soldoutAlarmIcon}>
              { isSoldoutAlarmActivated ? (
                <SoldoutAlarmWhiteIcon />
              ) : (
                <SoldoutAlarmIcon />
              )}
            </View>
          </View>
        </TouchableHighlightWithContainer>
      </View>
    );
  }

  renderListContentFooterItem(item, index) {
    const { product } = this.props;

    let name = '옵션을 선택해주세요';
    let count = 1;
    let price = 0;
    let productPrice = product !== undefined ? Number.parseInt(product.goodsPrice, 10) : 0;
    let optionPrice = item !== null ? Number.parseInt(item.optionPrice, 10) : 0;
    const listContentFooterItemNameStyles = [
      styles.listContentFooterItemName,
    ];
    let onPressDelete = null;
    let onPressSizeCountDec = null;
    let onPressSizeCountInc = null;

    if (item) {
      name = item.name;
      count = item.count;
      price = count * (productPrice + optionPrice);
      listContentFooterItemNameStyles.push(styles.listContentFooterItemNameActive);
      onPressDelete = () => this.onPressDelete(item);
      onPressSizeCountDec = () => this.onPressSizeCountDec(item);
      onPressSizeCountInc = () => this.onPressSizeCountInc(item);
    }

    return (
      <View
        key={`${index}`}
        style={styles.listContentFooterItemContainer}
        >
        <View style={styles.listContentFooterTop}>
          <Text style={listContentFooterItemNameStyles}>{name}</Text>
          { item && item.id && (
          <TouchableHighlightWithContainer
            onPress={onPressDelete}
          >
            <Text style={styles.sizeSelectorDeleteText}>삭제</Text>
          </TouchableHighlightWithContainer>
          )}
        </View>
        <View style={styles.listContentFooterBottom}>
          <View style={styles.sizeSelectorContainer}>
            <TouchableHighlightWithContainer
              onPress={onPressSizeCountDec}
              >
              <MinusIcon stroke={'#000'} />
            </TouchableHighlightWithContainer>
            <Text style={styles.sizeSelectorCount}>{count}</Text>
            <TouchableHighlightWithContainer
              onPress={onPressSizeCountInc}
            >
              <PlusIcon stroke={'#000'} />
            </TouchableHighlightWithContainer>
          </View>
          <NumberFormat
            value={price}
            displayType="text"
            thousandSeparator
            renderText={value => (
              <Text style={styles.listContentFooterItemPrice}>{value}원</Text>
            )}
            />
        </View>
      </View>
    )
  }

  renderPromotionDescription() {

    const vibrationAnim = useRef(new Animated.Value(0)).current;

    return (
      <View style={styles.promotionTopContainer}>
        <Animated.View style={[styles.promotionInnerContainer, { transform:[{translateX:vibrationAnim}] } ]}>
          <Text style={styles.promotionText}>
            프로모션 적용은 하트잇 결제만 가능합니다
          </Text>
        </Animated.View>
      </View>
    );
  }

  renderListContentFooter() {
    const {
      product={
        categoryList : []
      },
      selectedItemList
    } = this.props;

    let itemList = [null];
    if (selectedItemList.length > 0) {
      itemList = selectedItemList;
    }

    const isFatboy =  product.categoryList && Object.keys(product.categoryList).includes("018");


    return (
      <View style={styles.listContentFooterContainer}>
        {itemList.map((item, index) => this.renderListContentFooterItem(item, index))}
        {this.renderNaverPay()}
        {this.renderCouponBanner()}
        {isFatboy && this.renderPromotionDescription()}
      </View>
    );
  }

  renderNaverPay() {
    const {
      product={
        launchYmd: '0000-00-00',
        categoryList : []
      },
      selectedItemList,
      onPressNaverPay: onPressNaverPayParent,
      onPressNaverWishlist: onPressNaverWishlistParent,
    } = this.props;

    if (!isHeartitProduct(product)) {
      return (<View style={styles.naverPayEmptyContainer} />);
    }

    let onPressNaverPay = () => {
      alert('상품 옵션을 선택해주세요');
    }

    if (selectedItemList.length > 0) {
      onPressNaverPay = () => onPressNaverPayParent(product, selectedItemList);
    }

    let onPressNaverWishlist = () => {
      onPressNaverWishlistParent(product, selectedItemList);
    }

    const isPreOrder = !!product.launchYmd && product.launchYmd !== '0000-00-00';
    if (isPreOrder) {
      return (<View style={styles.naverPayEmptyContainer} />);
    }

    // fatboy 가능하도록 추가
    /*const isFatboy =  product.categoryList && Object.keys(product.categoryList).includes("018")
    if (isFatboy) {
      return (<View style={styles.naverPayEmptyContainer} />);
    }*/

    return (
      <View style={styles.footerTopContainer}>
        <View style={styles.naverPayGuideContainer}>
          <NaverPayTextType />
          <Text style={styles.naverPayGuideText}>네이버 ID로 간편구매</Text>
        </View>
        <View style={styles.naverPayButtonsContainer}>
          <TouchableHighlightWithContainer
            onPress={onPressNaverPay}
          >
            <View style={styleHtml.naverPayButtonContainer}>
            </View>
          </TouchableHighlightWithContainer>
          <TouchableHighlightWithContainer
            onPress={onPressNaverWishlist}
            style={{ marginLeft: 4 }}
          >
            <View style={styleHtml.naverPayWishlistContainer}>
            </View>
          </TouchableHighlightWithContainer>
        </View>
      </View>
    );
  }

  renderCouponBanner() {
    const {
      product={
        goodsNo: undefined,
        launchYmd: '0000-00-00',
      },
      router,
      onClose,
    } = this.props;

    const isPreOrder = !!product.launchYmd && product.launchYmd !== '0000-00-00';
    if (isPreOrder) {
      return (<View style={styles.couponBannerStyle} />);
    }

    return (
      <RegistrationCoupon
        router={router}
        containerStyle={styles.couponBannerStyle}
        productId={product.goodsNo}
        callback={() => onClose()}
      />
    );
  }

  renderBottomButton() {
    const {
      product,
      onPressAddToCart: onPressAddToCartParent,
      onPressCheckout: onPressCheckoutParent,
      selectedItemList,
    } = this.props;

    const footerButtonTextStyles = [
      styles.footerButtonText,
    ];


    let onPressAddToCart = () => {
      alert('상품 옵션을 선택해주세요');
    };
    let onPressCheckout = () => {
      alert('상품 옵션을 선택해주세요');
    };

    if (selectedItemList.length > 0) {
      footerButtonTextStyles.push(styles.footerButtonTextActive)
      onPressAddToCart = () => onPressAddToCartParent(product, selectedItemList);
      onPressCheckout = () => onPressCheckoutParent(product, selectedItemList);
    }

    return (
      <div style={styleHtml.footerContainer}>
        <View style={styles.footerBottomContainer}>
          <View style={{ flex: 1 / 2 }}>
            <TouchableHighlightWithContainer
              onPress={onPressAddToCart}
              >
              <View style={styles.footerButtonContainer}>
                <Text style={footerButtonTextStyles}>쇼핑백에 추가하기</Text>
              </View>
            </TouchableHighlightWithContainer>
          </View>
          <View style={{ flex: 1 / 2 }}>
            <TouchableHighlightWithContainer
              onPress={onPressCheckout}
              >
              <View style={styles.footerButtonContainer}>
                <Text style={footerButtonTextStyles}>바로 구매하기</Text>
              </View>
            </TouchableHighlightWithContainer>
          </View>
        </View>
      </div>
    );
  }

  renderBody() {

    const {
      product: {
        goodsNo: productId,
        option: optionList,
        optionImage : optionImageList
      },
      onPressSizeGuide,
      onChangeSelectedItemList,
      selectedItemList,
      setModalProps,
      modalProps,
    } = this.props;

    const itemList = optionList.map(o => {
      const _id = o.sno;
      const image = optionImageList.find(el => el.sno === _id);
      return {
        productId,
        id: o.sno,
        optionPrice: o.optionPrice,
        optionSellFl: o.optionSellFl,
        stockCnt: o.stockCnt,
        name: o.optionValue,
        image: image ? image.url : ""
      }
    });

    return(
      <View style={styles.container}>
        <SizeListWithCheckBox
          onChangeSelectedItemList={onChangeSelectedItemList}
          selectedItemList={selectedItemList}
          itemList={itemList}
          containerStyle={styles.listContainer}
          onPressSizeGuide={onPressSizeGuide}
          ListFooterComponent={() => this.renderListContentFooter()}
          ListItemComponent={this.renderItemComponent}
          modalProps={modalProps}
          setModalProps={setModalProps}
          />
        {this.renderBottomButton()}
      </View>
    )
  }
}


export default CheckoutModal;
