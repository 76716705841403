import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View, Text } from 'react-native';

import ShopDetailSectionHeader from './ShopDetailSectionHeader';
import ProductRelatedPosts from '../../../nativeComponents/ProductRelatedPosts';

import Colors from '../../../constants/Colors';
import Styles from '../../../constants/Styles';
import Constants from '../../../constants/Constants';


const marginVertical = 20;

const styles = StyleSheet.create({
  sectionContainer: {
    marginBottom: 20,
  },
  shippingReturnsContainer: {
    marginBottom: 39,
  },
  sectionContentsText: {
    lineHeight: 23,
    fontSize: 15,
    ...Styles.appleSDGothicNeoRegular,
  },
  spacer: {
    backgroundColor: Colors.semiGray,
    height: 10,
    marginHorizontal: -1 * Constants.viewHorizontalPadding,
  },
  infoContainer: {
    flexDirection: 'column',
    marginVertical: -1 * 5/2,
  },
  infoLineContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: 7,
    paddingBottom: 6,
    marginVertical: 5/2,
  },
  infoTitleContainer: {
  },
  infoValueContainer: {
    flex: 1,
    flexGrow: 1,
  },
  infoTitleText: {
    fontSize: 15,
    lineHeight: 19,
    color: 'black',
    ...Styles.appleSDGothicNeoRegular,
  },
  infoValueText: {
    fontSize: 15,
    lineHeight: 19,
    color: 'black',
    ...Styles.SFUITextMedium,
  },
});

const webStyles = {
  container: {
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
};


export default class TabDetail extends React.PureComponent {
  getTitleWidth = () => {
    const { product: { addInfo = [] }} = this.props;

    let length = 0;
    addInfo.forEach((info) => {
      const titleLength = info.infoTitle.length;
      if (titleLength > length) {
        length = titleLength;
      }
    });

    return length * 13 + 25;
  }

  renderDetailLine = (info, idx, titleWidth) => {
    const { infoTitle, infoValue } = info;

    return (
      <View key={idx.toString()} style={styles.infoLineContainer}>
        <View style={[styles.infoTitleContainer, {width: titleWidth}]}>
          <Text style={styles.infoTitleText} numberOfLines={1}>
            {infoTitle}
          </Text>
        </View>
        <View style={styles.infoValueContainer}>
          <Text style={styles.infoValueText}>
            {infoValue}
          </Text>
        </View>
      </View>
    );
  }

  removeHTMLTags = (exchangeInfo) => {
    if (!exchangeInfo) {
      return '';
    }

    let result = exchangeInfo.replace(/<p>/gi, '');
    result = result.replace(/<\/p>/gi, '\n');
    result = result.replace(/&nbsp;/gi, ' ');
    result = result.replace(/^\s+|\s+$/g, '');

    return result;
  }
  render() {
    const { tabViewContentHeight, product, productRelatedPosts, onPressRelatedPost } = this.props;
    //const htmlContent = product.description;
    const { editorsNote = '', infoExchange = '', addInfo = []} = product;
    const titleWidth = this.getTitleWidth();

    return (
      <View
        style={[
          webStyles.container,
          { minHeight: tabViewContentHeight - marginVertical },
        ]}
      >
          <ShopDetailSectionHeader title="Editor's note" />
          <View style={styles.sectionContainer}>
            <Text style={styles.sectionContentsText}>{ editorsNote }</Text>
          </View>
          <View style={styles.spacer} />

          <ShopDetailSectionHeader title="Details" />
          <View style={styles.sectionContainer}>
            <View style={styles.infoContainer}>
            { addInfo.map((info, idx) => { return this.renderDetailLine(info, idx, titleWidth) }) }
            </View>
          </View>
          <View style={styles.spacer} />

          <ShopDetailSectionHeader title="Shipping & returns" />
          <View style={[styles.sectionContainer, styles.shippingReturnsContainer]}>
            <Text style={styles.sectionContentsText}>{ this.removeHTMLTags(infoExchange) }</Text>
          </View>

        {/*htmlContent ? (
          // eslint-disable-next-line react/no-danger
          <div className="html-container" dangerouslySetInnerHTML={{ __html: htmlContent }} />
        ) : null*/}

        <ProductRelatedPosts
          onPressPost={onPressRelatedPost}
          productRelatedPosts={productRelatedPosts}
        />
      </View>
    );
  }
}
