import React from 'react';
import {
  StyleSheet, Text, View,
} from 'react-native';

import config from '../../../config';
import packageJson from '../../../../package.json';

import {
  Colors, Styles, Constants,
} from '../../../constants';


const styles = StyleSheet.create({
  container: {
  },
  csMenuHeader: {
    fontSize: 14,
    lineHeight: 18,
    paddingTop: 19.5,
    paddingHorizontal: Constants.viewHorizontalPadding,
    paddingBottom: 2,
    color: Colors.defaultGray08,
    ...Styles.pretendard400,
    opacity: 0.5,
  },
  versionText: {
    fontSize: 13,
    lineHeight: 18,
    color: Colors.defaultGray08,
    ...Styles.pretendard400,
  },
  versionStatusText: {
    fontSize: 13,
    lineHeight: 18,
    color: Colors.defaultGray08,
    opacity: 0.5,
    ...Styles.pretendard400,
  },
});


class CustomerSupportSection extends React.Component {
  
  showChatInquiry() {
    const chatUrl = config.get('chatInquiryUrl');
    window.location.href = chatUrl;
  }
  
  render() {
    const {
      menuButton,
      router,
    } = this.props;

    return (
      <View style={styles.container}>
        <Text style={styles.csMenuHeader}>고객센터</Text>

        {menuButton(
          '1:1 문의',
          () => { this.showChatInquiry(); },
          {
            buttonHideWithoutLogin: false,
            loginOnlyMenu: false,
          }
        )}
        {menuButton(
          'FAQ',
          () => router.push('/accounts/faq'),
          {
            buttonHideWithoutLogin: false,
            loginOnlyMenu: false,
          }
        )}
        {menuButton(
          '공지사항',
          () => router.push('/notice'),
          {
            buttonHideWithoutLogin: false,
            loginOnlyMenu: false,
          }
        )}
        {menuButton(
          '이용약관',
          () => router.push('/legal/terms'),
          {
            buttonHideWithoutLogin: false,
            loginOnlyMenu: false,
          }
        )}
        {menuButton(
          '개인정보 처리방침',
          () => router.push('/legal/privacy'),
          {
            buttonHideWithoutLogin: false,
            loginOnlyMenu: false,
          }
        )}

        {menuButton(
          '사업자 정보',
          () => router.push('/businessInfo'),
          {
            buttonHideWithoutLogin: false,
            loginOnlyMenu: false,
          }
        )}

        <View style={{ marginHorizontal: Constants.viewHorizontalPadding, marginVertical: 20, flexDirection: 'row', justifyContent: 'space-between'}}>
            <Text style={styles.versionText}>
              버전 정보 {packageJson.version}
            </Text>
            <Text style={styles.versionStatusText}>
              최신 버전 사용중
            </Text>
        </View>
      </View>
    );
  }
}


export default CustomerSupportSection;
