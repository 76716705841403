import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { camelizeKeys } from 'humps';
import dayjs from 'dayjs';
import Flatpickr from 'react-flatpickr';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader'


// import { browserHistory } from 'react-router';
// all codes using browserHistory are legacy in react-router v3;
// anyways, the solution preventing admins from accidently clicking backbutton, reload, or close window
// eslint-disable-next-line max-len
// I follow the https://stackoverflow.com/questions/32841757/detecting-user-leaving-page-with-react-router/50388073#50388073
// but this.props.route.path doesn't have exact path, I instead used this.props.router.location.pathname
import {
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import FiberNew from '@material-ui/icons/FiberNew';
// import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
// import partition from 'lodash/partition';
import pick from 'lodash/pick';

import * as adminActions from 'react-shared/adminActions';

import {
  objectDiff,
} from 'react-shared/util';

import config from '../../config';

const styles = theme => ({
  cancelConfirmButton: {

  },
  createPostButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  deletePostButton: {
    position: 'absolute',
    right: theme.spacing(12),
    top: theme.spacing(2),
  },
  snackbar: {
    textAlign: 'center',
  },
  formControlLabel: {

  },
  select: {
    width: 200,
  },
  link: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  outerButton: {
    marginRight: theme.spacing(2),
  },
  subMenuTitles: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    minHeight: 50,
  },
  thumbnailArea: {
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'table-cell',
  },
  fileListContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 3,
    paddingBottom: 3,
    margin: 5,
    border: '0.5px solid #000',
    borderRadius: 4,
  },
});

class EditorialPage extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUploadImage = this.handleUploadImage.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.state = {
      bucket: '',
      region: '',
      thumbnail: null,
      fileList: [],
      title: '',
      url: '',
      open: 0,
      close: 0,
      visible: true,
      isUpdatingPost: false,
    };
  }

  async componentDidMount() {
    const {
      adminGetEditorial,
      adminBucketNameForEditorial,
      editorialId,
      editorial,
      route,
      router
    } = this.props;

    if (editorialId !== 'new') {
      const { bucket, region } = await adminBucketNameForEditorial(editorialId);
      if (editorial) {
        this.setState({ ...editorial, bucket, region });
      } else {
        await adminGetEditorial(editorialId)
          .then(editorialData => this.setState({ ...editorialData, bucket, region }));
        // Todo: route to admin/posts when there's no post with such editorialId
      }
    }

    this.saveState();
    window.addEventListener('resize', () => this.handleResize());
    window.addEventListener('beforeunload', event => this.handleClose(event));
    this.mutationObserver = new MutationObserver(() => this.removeBodyStyle());
    this.mutationObserver.observe(document.body, {
      attributes: true,
      attributeFilter: ['style'],
    });
    router.setRouteLeaveHook(route, () => this.handleBackbutton());
  }

  componentDidUpdate(prevProps) {
    const { editorialId } = this.props;
    if (prevProps.editorialId !== editorialId && editorialId === 'new') {
      window.location.reload();
    }
  }

  componentWillUnmount() {
    this.mutationObserver.disconnect();
    window.removeEventListener('resize', () => this.handleResize());
    window.removeEventListener('beforeunload', event => this.handleClose(event));
  }

  saveState() {
    this.savedState = cloneDeep(this.state);
  }

  hasEditorialEdited() {
    return !!Object.keys(objectDiff(this.savedState, this.state)).length;
  }

  showSnackbar(message) {
    this.setState({
      snackbarMsg: message,
      snackbarOpen: true,
    });
  }

  handleClose(event) {
    if (this.hasEditorialEdited()) {
      event.preventDefault();
      event.returnValue = '에디토리얼을 작성중이시네요, 정말 창을 닫으시겠어요?';
      return event.returnValue;
    }
    return event;
  }

  removeBodyStyle() { // FIX!
    const {
      isItemDialogOpen: item,
      isWebItemDialogOpen: webItem,
    } = this.state;
    if (!item && !webItem && document.body.style.overflow === 'hidden') {
      document.body.style.overflow = '';
    }
  }

  handleBackbutton() {
    const { router: { location: { pathname } } } = this.props;
    if (this.hasEditorialEdited()) {
      // eslint-disable-next-line no-restricted-globals
      const toBeClosed = confirm('에디토리얼을 작성중이시네요, 정말 창을 닫으시겠어요?');
      if (!toBeClosed) {
        window.history.pushState(null, null, pathname);
        return false;
      }
    }
    return true;
  }

  handleResize() {
    if (window.innerWidth <= 600 && this.state.tilesInRow === 4) {
      this.setState({ tilesInRow: 1 });
    } else if (window.innerWidth > 600 && this.state.tilesInRow === 1) {
      this.setState({ tilesInRow: 4 });
    }
  }

  handleEditorialUrlChange(event) {
    this.setState({ url: event.target.value });
  }

  handleTitleChange(event) {
    this.setState({ title: event.target.value });
  }

  handleOpenChange(event) {
    this.setState({ open: Math.floor(new Date(event).getTime() / 1000) });
  }

  handleCloseChange(event) {
    this.setState({ close: Math.floor(new Date(event).getTime() / 1000) });
  }

  handleVisibleChange(event) {
    this.setState({ visible: event.target.value });
  }

  handleSubmit(event) {
    const fields = ['title', 'url', 'thumbnail', 'fileList', 'open', 'close', 'visible'];
    const editorial = pick(this.state, fields);
    const { adminCreateEditorial, adminUpdateEditorial, editorialId, router } = this.props;
    if (!editorial.thumbnail) {
      alert('썸네일을 등록해주세요.');
      return;
    }
    if (!editorial.title) {
      alert('제목을 입력해주세요.');
      return;
    }
    if (editorialId === 'new') {
      this.setState(
        { isUpdatingPost: true },
        () => adminCreateEditorial(editorial)
          .then(() => this.saveState())
          .then(() => {
            this.showSnackbar('Editorial created successfully.');
          })
          .catch(() => this.showSnackbar('Update failed.'))
          .then(() => router.push('/admin/editorials'))
      );
    } else {
      this.setState(
        { isUpdatingPost: true },
        () => adminUpdateEditorial(editorialId, editorial)
          .then(() => this.setState({ isUpdatingPost: false }, () => this.saveState()))
          .then(() => this.showSnackbar('Editorial updated succesfully.'))
          .catch(() => this.showSnackbar('Update failed.'))
      );
    }
    event.preventDefault();
  }

  handleUploadImage() {
    const uploadWidget = window.cloudinary.openUploadWidget({
      ...config.get('cloudinary'),
      sources: ['local', 'url', 'camera'],
      default_source: 'local',
      client_allowed_formats: ['jpg', 'jpeg', 'png', 'gif'],
      resource_type: 'image',
      folder: `editorial-thumbnails/${dayjs().format('YYYY-MM-DD')}`,
      multiple: false,
    }, (error, result) => {
      if (result && result.event === 'success') {
        uploadWidget.close();
        this.setState({ thumbnail: camelizeKeys(result.info) });
      }
    });
    uploadWidget.open();
  }

  handleUploadFile(file, callback) {
    const { editorialId, adminGetSignedUrlForEditorial } = this.props;
    const query = {
      objectName: file.name,
      contentType: file.type,
      method: 'putObject',
    };
    adminGetSignedUrlForEditorial(editorialId, query)
      .then((result) => callback(result))
      .catch((error) => {
        console.error(error);
        alert('파일 업로드에 실패했습니다.');
      });
  }

  renderOpen() {
    const { open } = this.state;

    return (
      <div>
        <FormControlLabel
          control={(
            <Checkbox
              checked={!!open}
              // disabled={open && open < 10000}
              onChange={event => {
                if (event.target.checked) {
                  this.setState({ open: (dayjs().valueOf() / 1000) });
                } else {
                  this.setState({ open: null });
                }
              }}
            />
          )}
          label={(open && open > 10000) ? 'Open at' : 'Open'}
        />
        <Flatpickr
          key={open * 1000}
          value={open * 1000}
          options={{
            enableTime: true,
            minTime: '06:00',
            maxTime: '23:59',
            // dateFormat: 'Y-m-d',
          }}
          data-enable-time
          onChange={event => this.handleOpenChange(event)}
        />
      </div>
    );
  }

  renderClose() {
    const { close } = this.state;

    return (
      <div>
        <FormControlLabel
          control={(
            <Checkbox
              checked={!!close}
              // disabled={close && close < 10000}
              onChange={event => {
                if (event.target.checked) {
                  this.setState({ close: (dayjs().valueOf() / 1000) });
                } else {
                  this.setState({ close: null });
                }
              }}
            />
          )}
          label={(close && close > 10000) ? 'Closed at' : 'Closed'}
        />
        <Flatpickr
          key={close * 1000}
          value={close * 1000}
          options={{
            enableTime: true,
            minTime: '06:00',
            maxTime: '23:59',
            // dateFormat: 'Y-m-d',
          }}
          data-enable-time
          onChange={event => this.handleCloseChange(event)}
        />
      </div>
    );
  }

  render() {
    const { classes, editorialId } = this.props;
    const { bucket, region, title, url, thumbnail, fileList } = this.state;
    const s3Url = `https://${bucket}.s3.${region}.amazonaws.com`;

    return (
      <div className="container">
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>에디토리얼 썸네일</Typography>
          { thumbnail ? (
            <div style={{ textAlign: 'center' }}>
              <img src={thumbnail.secureUrl} style={{ width: 500, height: 'auto' }} alt="" />
            </div>
          ) : (
            <Typography className={classes.thumbnailArea} variant="caption" style={{ height: 100 }}>등록된 썸네일이 없습니다</Typography>
          )}
          <br />
          <Button
            className={classes.outerButton}
            onClick={this.handleUploadImage}
            variant="contained"
          >
            썸네일 업로드
          </Button>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>
            에디토리얼 제목
          </Typography>
          <div>
            <TextField className={classes.link} value={title} onChange={event => this.handleTitleChange(event)} />
          </div>
        </Paper>
        { editorialId !== 'new' && (
          <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.subMenuTitles}>
              에디토리얼 URL
            </Typography>
            <div>
              <TextField inputProps={{ readOnly: true }} className={classes.link} value={url} onChange={event => this.handleEditorialUrlChange(event)} />
            </div>
          </Paper>
        )}
        { editorialId === 'new' ? (
          <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.subMenuTitles}>
              에디토리얼 컨텐츠
            </Typography>
            <Typography className={classes.thumbnailArea} variant="caption" style={{ height: 100 }}>에디토리얼을 한 번 저장한 이후에 컨텐츠를 업로드할 수 있습니다.</Typography>
          </Paper>
        ) : (
          <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.subMenuTitles}>
              에디토리얼 컨텐츠
            </Typography>
            { fileList.length > 0 ? (
              <div className={classes.fileListContainer}>
                { fileList.map((f) => {
                  return (
                    <div key={f.fileName} className={classes.fileContainer}>
                      <div style={{ marginBottom: 1 }}>{ f.originalFileName }</div>
                      <div style={{ marginBottom: 1, fontSize: '0.8em' }}>{ f.fileSize }</div>
                      <div style={{ marginBottom: 1, fontSize: '0.8em' }}>{ f.fileType }</div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <Typography className={classes.thumbnailArea} variant="caption" style={{ height: 100 }}>등록된 파일이 없습니다</Typography>
            )}
            <br />
            <DropzoneS3Uploader
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 20,
                borderWidth: 2,
                borderRadius: 2,
                borderColor: '#cfcfca',
                borderStyle: 'dashed',
                backgroundColor: '#fafafa',
                color: '#bdbdbd',
                outline: 'none',
                transition: 'border .24s ease-in-out',
              }}
              s3Url={s3Url}
              upload={{
                getSignedUrl: this.handleUploadFile,
                accept: "*/*",
                uploadRequestHeaders: {},
                contentDisposition: "inline",
              }}
              onError={(message) => {
                alert(message);
                console.error(message);
              }}
              onFinish={(result) => {
                const { fileList: oldFileList } = this.state;
                const newFileList = oldFileList.filter(f => f.fileUrl !== result.fileUrl);
                newFileList.push({
                  fileUrl: result.fileUrl,
                  fileName: result.filename,
                  fileSize: result.file.size,
                  fileType: result.file.type,
                  originalFileName: result.originalFilename,
                  publicUrl: result.publicUrl,
                })
                this.setState({ fileList: newFileList });
              }}
            >
              <div>파일을 끌어다 놓거나 클릭해서 선택하세요</div>
            </DropzoneS3Uploader>
          </Paper>
        )}
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>에디토리얼 디스플레이 시간</Typography>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css"></link>
          {this.renderOpen()}
          {this.renderClose()}
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>에디토리얼 상태</Typography>
          <div>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={this.state.visible}
                  onChange={(event, checked) => this.setState({ visible: checked })}
                />
              )}
              label="공개여부"
            />
          </div>
        </Paper>
        <div style={{ height: 200, position: 'relative' }}>
          <Button
            variant="contained"
            onClick={this.handleSubmit}
            className={classes.createPostButton}
            color="primary"
            disabled={this.state.isUpdatingPost}
          >
            {editorialId === 'new' ? '업로드' : '완료'}
          </Button>
          {
            editorialId !== 'new' && (
              <Button
                variant="contained"
                onClick={() => {
                  if (confirm('에디토리얼을 제거할까요?')) { // eslint-disable-line
                    this.props.adminDeleteEditorial(this.props.editorialId);
                    this.props.router.push('/admin/editorials');
                  }
                }}
                color="secondary"
                className={classes.deletePostButton}
              >
                제거
              </Button>
            )
          }
        </div>
        <Snackbar
          autoHideDuration={2000}
          className={classes.snackbar}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
        />
      </div>
    );
  }
}

EditorialPage.propTypes = {
  editorialId: PropTypes.string.isRequired,
  router: PropTypes.object.isRequired,
  adminCreateEditorial: PropTypes.func.isRequired,
  adminDeleteEditorial: PropTypes.func.isRequired,
  adminGetEditorial: PropTypes.func.isRequired,
  adminUpdateEditorial: PropTypes.func.isRequired,
};

export default compose(
  withStyles(
    styles
  ),
  connect((state, ownProps) => ({
    editorialId: ownProps.params.editorialId,
    editorial: state.entities.editorials[ownProps.params.editorialId],
  }), adminActions),
)(EditorialPage);
