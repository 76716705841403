import set from 'lodash/set';
import setWith from 'lodash/setWith';
import { normalize } from 'normalizr';
import qs from 'qs';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';


// 2015. 12. 29. [heekyu] get following status
export function getRelationship(targetUserId) {
  return createFetchAction({
    type: 'GET_RELATIONSHIP',
    endpoint: `/api/v2/instagram/users/${targetUserId}/relationship`,
    method: 'GET',
  });
}

export function loadEditorials() {
  const nextPage = false;
  const key = 'editorials';

  return createFetchAction({
    type: 'LOAD_EDITORIALS',
    endpoint: '/api/v2/editorials',
    transform: ({ json }) => normalize(json, schemas.editorials),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}


export function makeinstaTime() {
  return createFetchAction({
    type: 'MAKEINSTAGRAM_TIME',
  });
}


export function createPostShortenUrl(postId) {
  return createFetchAction({
    type: 'CREATE_POST_SHORTEN',
    endpoint: '/api/v2/shortener',
    method: 'POST',
    body: {
      original: `/posts/${postId}`,
      tag: {
        instagram: true,
      },
    },
    transform: ({ json }) => set({}, `entities.shortens.${postId}`, json),
  });
}

export function loadPostShortenUrl(postId) {
  return createFetchAction({
    type: 'LOAD_POST_SHORTEN',
    endpoint: `/api/v2/shortener/search?original=/posts/${postId}&instagram`,
    transform: ({ json }) => {
      if (json && json.length > 0) {
        return set({}, `entities.shortens.${postId}`, json[0]);
      }
      return json;
    },
  });
}

export function resetStore() {
  return {
    type: 'RESET',
  };
}

export function setPostsViewMode(viewMode, page) {
  return {
    type: 'POSTS_VIEW_MODE',
    viewMode,
    page,
  };
}


export function loadTagMedias(tag, minTagId, maxTagId) {
  return createFetchAction({
    type: 'LOAD_TAG_MEDIAS',
    endpoint: `/api/v2/instagram/tags/${tag}${minTagId ? `?minTagId=${minTagId}&maxTagId=${maxTagId}` : ''}`,
  });
}


export function loadRandomBanner(query) {
  const search = query ? `?${qs.stringify(query)}` : '';
  return createFetchAction({
    type: 'LOAD_RANDOM_BANNER',
    endpoint: `/api/v2/banners/random${search}`,
  });
}

export function saveBannerClickLog(banner, encodedURI) {
  return createFetchAction({
    type: 'SAVE_BANNER_CLICK_LOG',
    endpoint: `/link?redirect=no&banner=${banner.id}&url=${encodedURI}&history=no`,
  });
}

export function test500Error() {
  return createFetchAction({
    type: 'TEST',
    endpoint: '/api/v2/debug/err500',
    method: 'GET',
  });
}


export function setGlobalLayoutInfo(name, value) {
  return {
    type: 'UPDATE_GLOBAL_LAYOUT_INFO',
    name,
    value,
  };
}

export function updateRouterHistory(value, mode = 'append') {
  //console.log('UPDATE_ROUTER_HISTORY called', value, mode);
  return {
    type: 'UPDATE_ROUTER_HISTORY',
    mode,
    value,
  };
}

export function loadMarketingPopups() {
  const key = 'marketingPopups';

  return createFetchAction({
    type: 'LOAD_MARKETING_POPUPS',
    endpoint: '/api/v2/marketingPopups',
    method: 'GET',
    transform: ({ json }) => {
      return normalize(json, schemas.marketingPopups)
    },
    success: {
      meta: { entities: { update: true } },
      pagination: { key, type: 'APPEND' },
    },
  });
}

export function closeMarketingPopup(popupId) {
  const key = 'marketingPopups';

  const payload = setWith({}, ['entities', key, popupId], { userClosed: true }, Object);
  return {
    type: 'CLOSE_MARKETING_POPUP',
    payload,
    meta: { entities: { update: true } },
    pagination: { key, type: 'APPEND' },
  };
}
