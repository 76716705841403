import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View, Text } from 'react-native';

import {
  Constants,
  Styles,
  Colors,
 } from '../../constants';

import TouchableHighlightWithContainer from '../TouchableHighlightWithContainer';


const styles = StyleSheet.create({
  container: {
    paddingLeft: Constants.viewHorizontalPadding,
    paddingRight: Constants.viewHorizontalPadding,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: Constants.mainHeaderHeight,
    paddingTop: 16,
    paddingBottom: 14,
  },
  buttonText: {
    ...Styles.pretendard700,
    fontSize: 18,
    lineHeight: 29,
    opacity: 0.2,
  },
  buttonTextDark: {
    color: Colors.defaultGray05,
  },
  buttonTextActive: {
    opacity: 1.0,
  },
});


class HeaderLeftHome extends React.Component {

  render() {

    const {
      containerStyle,
      router,
      selectedTab,
      darkContent,
    } = this.props;

    const allTextStyles = [styles.buttonText];
    const followingTextStyles = [styles.buttonText];

    if (selectedTab === 'following') {
      followingTextStyles.push(styles.buttonTextActive);
    } else {
      allTextStyles.push(styles.buttonTextActive);
    }

    return (
      <View style={[styles.container, containerStyle]}>
        <TouchableHighlightWithContainer
          onPress={() => {
            router.push('/style/all');
          }}
          >
          <Text style={allTextStyles}>모든 스타일</Text>
        </TouchableHighlightWithContainer>

        <View style={{ width: 16 }} />

        <TouchableHighlightWithContainer
          onPress={() => {
            router.push('/style/following');
          }}
          >
          <Text style={followingTextStyles}>팔로잉</Text>
        </TouchableHighlightWithContainer>
      </View>
    );
  }
}


HeaderLeftHome.propTypes = {
  selectedTab: PropTypes.string,
};


HeaderLeftHome.defaultProps = {
  selectedTab: 'today',
};


export default HeaderLeftHome;
