import React from 'react';
import {
  StyleSheet, View, Dimensions, Animated, Platform,
  Text, ScrollView, FlatList,
} from 'react-native';
import NumberFormat from 'react-number-format';

import { Constants, Styles, Colors } from '../../../constants';
import Button from '../../../components-v2/Button';


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerContainer: {
    height: 80,
    borderWidth: 0.5,
    borderColor: '#000',
    marginTop: 10,
    marginBottom: 40,
    marginHorizontal: Constants.viewHorizontalPadding,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerContentContainer: {
    height: 45,
  },
  headerLabel: {
    color: '#000',
    opacity: 0.5,
    marginBottom: 3,
    fontSize: 15,
    lineHeight: 18,
    textAlign: 'center',
    ...Styles.pretendard400,
  },
  headerValue: {
    color: '#000',
    fontSize: 20,
    lineHeight: 24,
    textAlign: 'center',
    ...Styles.pretendard600,
  },
  contentHeaderContainer: {
    height: 40,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: Constants.viewHorizontalPadding,
    marginBottom: 10,
  },
  contentHeaderButtonsContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 0.5 / 2,
  },
  contentHeaderButtonContainer: {
    flex: 1,
    marginHorizontal: - 0.5 / 2,
    borderWidth: 0.5,
    borderColor: '#000',
    opacity: 0.3,
  },
  contentHeaderButton: {
    width: '100%',
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    borderWidth: 0,
  },
  contentHeaderButtonText: {
    fontSize: 16,
    lineHeight: 19.2,
    color: '#000',
    ...Styles.pretendard600,
  },
  blankContainer: {
    marginTop: 60 - 10,
    flex: 1,
  },
  blankText: {
    fontSize: 16,
    lineHeight: 19.2,
    textAlign: 'center',
    color: Colors.defaultGray08,
    ...Styles.pretendard400,
  },
  listItemContainer: {
    paddingVertical: 15,
    paddingHorizontal: Constants.viewHorizontalPadding,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  listItemDetailContainer: {
    flexDirection: 'column',
  },
  listItemPointContainer: {
    justifyContent: 'center',
  },
  listItemPointText: {
    fontSize: 16,
    lineHeight: 19.2,
    color: '#000',
    ...Styles.pretendard600,
  },
  listItemDetailText: {
    marginBottom: 3,
    fontSize: 16,
    lineHeight: 19.2,
    color: '#000',
    ...Styles.pretendard400,
  },
  listItemDateText: {
    fontSize: 13,
    lineHeight: 15.6,
    color: '#000',
    opacity: 0.5,
    ...Styles.pretendard400,
  }
});


class TabPoint extends React.PureComponent {
  state = {
    listFilter: 'all',
    isFetchingNextPage: false,
  }

  loadData = (nextPage) => {
    const { getUserMileageList } = this.props;
    const { isFetchingNextPage } = this.state;

    this.setState({ isFetchingNextPage: nextPage }, () => {
      getUserMileageList(nextPage)
        .then(() => this.setState({ isFetchingNextPage: false }));
    });
  }

  getPoint() {
    const { auth: { summary } } = this.props;
    if (summary) {
      return summary.mileagePoint;
    }

    return '-';
  }

  renderHeader = () => {
    return (
      <View style={styles.headerContainer}>
        <View style={styles.headerContentContainer}>
          <Text style={styles.headerLabel}>총 보유 포인트</Text>
          <NumberFormat
            value={this.getPoint()}
            displayType="text"
            thousandSeparator
            decimalScale={0}
            renderText={
              text => (
                <Text style={styles.headerValue}
                >
                  {text} P
                </Text>
              )}
          />
        </View>
      </View>
    );
  }

  renderListHeader = () => {
    const { listFilter } = this.state;

    return(
      <View style={styles.contentHeaderContainer}>
        <View style={styles.contentHeaderButtonsContainer}>
          <View style={[styles.contentHeaderButtonContainer, (listFilter == 'all') ? {opacity: 1}: {}]}>
            <Button
              containerStyle={styles.contentHeaderButton}
              titleStyle={styles.contentHeaderButtonText}
              title="전체"
              onPress={() => {this.setState({ listFilter: 'all'})}}
            />
          </View>
          <View style={[styles.contentHeaderButtonContainer, (listFilter == 'plus') ? {opacity: 1}: {}]}>
            <Button
              containerStyle={styles.contentHeaderButton}
              titleStyle={styles.contentHeaderButtonText}
              title="적립"
              onPress={() => {this.setState({ listFilter: 'plus'})}}
            />
          </View>
          <View style={[styles.contentHeaderButtonContainer, (listFilter == 'minus') ? {opacity: 1}: {}]}>
            <Button
              containerStyle={styles.contentHeaderButton}
              titleStyle={styles.contentHeaderButtonText}
              title="차감"
              onPress={() => {this.setState({ listFilter: 'minus'})}}
            />
          </View>
        </View>
      </View>
    );
  }

  renderListItem = ({ item, index }) => {
    let { detail, point, date } = item;
    let operator = "+";
    if (point < 0) {
      operator = "-";
      point = -1 * point;
    }

    const getDateString = (dateStr) => {
      const [date, time] = dateStr.split(' ');
      const [year, month, day] = date.split('-');
      const [hour, min, sec] = time.split(':');
      return `${year}년 ${month}월 ${day}일`;
    }

    return(
      <View style={styles.listItemContainer}>
        <View style={styles.listItemDetailContainer}>
          <Text style={styles.listItemDetailText}>
            {detail}
          </Text>
          <Text style={styles.listItemDateText}>{getDateString(date)}</Text>
        </View>
        <View style={styles.listItemPointContainer}>
          <NumberFormat
            value={point}
            displayType="text"
            thousandSeparator
            decimalScale={0}
            renderText={
              text => (
                <Text style={styles.listItemPointText}
                >
                  {`${operator} ${text} P`}
                </Text>
              )}
          />
        </View>
      </View>
    );
  }


  renderContent() {
    const { pagination, userMileageList= [] } = this.props;
    const { isFetchingNextPage } = this.state;
    const data = userMileageList.map(m => {
      return {
        detail: m.contents,
        point: parseInt(m.mileage, 10),
        date: m.regDt,
      };
    });

    return (
      <FlatList
        keyExtractor={(item, index ) => index.toString()}
        data={data.filter(x => {
          if (this.state.listFilter == "plus") {
            return x.point > 0
          } else if (this.state.listFilter == "minus") {
            return x.point < 0
          } else {
            return true;
          }
        })}
        ListHeaderComponent={this.renderListHeader()}
        ListFooterComponent={(<View style={{ height: Constants.bottomMargin }}/>)}
        renderItem={this.renderListItem}
        ListEmptyComponent={() => {
          return (
            <View style={styles.blankContainer}>
              <Text style={styles.blankText}>포인트 내역이 없습니다.</Text>
            </View>
          );
        }}
        onEndReached={() => {
          if (!pagination) {
            return;
          }
          if (isFetchingNextPage || pagination.isFetching || pagination.nextPageUrl === null) {
            return;
          }
          this.loadData(true);
        }}
      />
    );
  }

  render() {
    return (
      <View style={styles.container}>
        <ScrollView style={styles.scrollView}>
          {this.renderHeader()}
          {this.renderContent()}
        </ScrollView>
      </View>
    );
  }
}

export default TabPoint;
