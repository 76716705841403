import React from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheet, View,
} from 'react-native';

import { connect } from 'react-redux';
import { actions } from 'shared';

import { Styles } from '../constants';

import InputForm from './InputForm';

const VERIFICATION_TIME_LIMIT = 3 * 60; // 3 minutes


const styles = StyleSheet.create({
  container: {
  },
});


class PhoneVerificationForm extends React.Component {

  state = {
    phone: '',
    inputPhoneOTPText: '',
    phoneVerified: false,
    verificationOngoing: false,
    phoneVerifyingOTPSentAt: 0,
    phoneNumberErrorMessage: '',
    verificationErrorMessage: '',
    verificationTestCode: '',
    verificationFailed: false,
  };

  inputPhoneRef = null;
  inputPhoneOTPRef = null;

  getVerificationTimer = () => setInterval(() => {
    this.setState(prevState => {
      if (prevState.timestamp <= 1) {
        this.reset({
          verificationFailed: true,
          inputPhoneOTPText: '',
        });
        return {};
      }

      return { timestamp: prevState.timestamp - 1 };
    });
  }, 1000);

  onPressVerifyPhoneNumber = async () => {
    if (!this.validate()) {
      return;
    }

    const success = await this.requestSendVerificationCode();
    if (success) {
      this.reset({
        verificationOngoing: true,
        timer: this.getVerificationTimer(),
      }, () => {
        if (this.inputPhoneOTPRef) {
          this.inputPhoneOTPRef.focus();
        }
      });
    }
  };

  requestSendVerificationCode = async () => {
    const {
      ignoreOldUser,
      showIgnoreOldUserAlert,
      requestTelVerificationCode,
      requestTelVerificationCodeCallback,
    } = this.props;
    const {
      phone,
    } = this.state;

    const handler = requestTelVerificationCodeCallback || requestTelVerificationCode;

    try {
      const resp = await handler(phone, ignoreOldUser);

      this.setState({
        verificationTestCode: resp.code,
      })
      return true;
    } catch (e) {

      let { errorMessage = '' } = e;
      if (!errorMessage || errorMessage === '') {
        switch (e.status) {
        case 409:
          if (showIgnoreOldUserAlert) {
             showIgnoreOldUserAlert(e.email);
          } else {
            alert('이미 가입된 번호입니다.');
            this.setState({
              verificationOngoing: false,
            });
          }
          break;

        default:
          this.setState({
            verificationErrorMessage: '인증코드를 전송하는데 실패했습니다. 잠시 후 다시 시도하세요.',
          })

          // eslint-disable-next-line no-console
          console.log(`error=${errorMessage}`);
          break;
        }
      }
    }
    return false;
  }

  onPressCheckVerificationNumber = async () => {
    const verified = await this.requestPhoneNumberVerification();

    if (!verified) {
      this.setState({
        phoneVerified: false,
        inputPhoneOTPText: '',
        verificationFailed: true,
      });
      return;
    }

    this.reset({
      verificationErrorMessage: '',
      phoneVerified: true,
    });


    const { onChange } = this.props;
    const { phone } = this.state;

    onChange({
      phone,
      phoneVerified: true,
    });
  }

  requestPhoneNumberVerification = async () => {
    const {
      verifyTelVerificationCode,
      verifyTelVerificationCodeCallback,
    } = this.props;
    const {
      phone,
      inputPhoneOTPText,
    } = this.state;

    const handler = verifyTelVerificationCodeCallback || verifyTelVerificationCode;

    try {
      // eslint-disable-next-line no-unused-vars
      const resp = await handler(phone, inputPhoneOTPText);
      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      let errorMessage = '휴대폰번호 인증에 실패했습니다. 잠시 후 다시 시도하세요.';
      if (e.status === 408) {
        errorMessage = '너무 오래된 인증코드입니다. 인증코드를 다시 발급받으세요.';
      } else if (e.status === 429) {
        errorMessage = '인증에 너무 여러번 실패했습니다. 인증코드를 다시 발급받으세요.';
      } else if (e.status === 401) {
        errorMessage = '인증 번호 입력이 잘못되었습니다.';
      }
      this.setState({
        verificationErrorMessage: errorMessage,
      });
      console.log(`requestPhoneNumberVerification errorMessage=${errorMessage}`);
    }
    return false;
  }

  componentDidMount() {
    const { onRef, phone, phoneVerified } = this.props;
    onRef(this);

    this.setState({ 
      phone,
      phoneVerified,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      ignoreOldUser,
    } = this.props;

    if (prevProps.ignoreOldUser !== ignoreOldUser && ignoreOldUser) {
      this.onPressVerifyPhoneNumber();
    }
  }

  focus() {
    const { verificationOngoing } = this.state;

    if (verificationOngoing) {
      this.inputPhoneOTPRef.focus();
    } else {
      this.inputPhoneRef.focus();
    }
  }

  validate() {
    if (!this.inputPhoneRef.validate()) {
      return false;
    }

    this.setState({
      phoneNumberErrorMessage: '',
      verificationErrorMessage: '',
    });

    return true;
  }

  reset(option = {}, callback = () => {}) {
    const { timer } = this.state;
    if (timer) {
      clearInterval(timer);
    }

    this.setState({
      phoneVerified: false,
      verificationFailed: false,
      verificationOngoing: false,
      timer: null,
      timestamp: VERIFICATION_TIME_LIMIT,
      ...option,
    }, callback);
  }

  getRemainingTimeStr() {
    const { timestamp } = this.state;

    const minutes = Math.floor(timestamp / 60);
    const seconds = Math.ceil(timestamp - minutes * 60);

    let minutesString = minutes.toString();
    if (minutesString.length === 1) {
      minutesString = '0' + minutesString;
    }
    let secondsString = seconds.toString();
    if (secondsString.length === 1) {
      secondsString = '0' + secondsString;
    }

    return `${minutesString}분 ${secondsString}초`;
  }

  renderPhoneInput() {
    const {
      description,
    } = this.props;

    const {
      phone,
      phoneVerified,
      verificationFailed,
      verificationOngoing,
      phoneNumberErrorMessage: errorMessage,
    } = this.state;

    let rightButtonTitle = '인증 번호 받기';
    let onPressRightButton = () => null;
    let disableRightButton = true;

    if (phone.length > 0 && !phoneVerified) {
      onPressRightButton = this.onPressVerifyPhoneNumber;
      disableRightButton = false;
    }

    if (verificationFailed || verificationOngoing) {
      rightButtonTitle = '재발송하기';
      disableRightButton = false;
    }

    if (phoneVerified) {
      rightButtonTitle = '인증 번호 받기';
    }

    const onChangeText = val => {
      this.setState({ phone: val, phoneVerified: false });

      const { onChange } = this.props;
      onChange({
        phone: val,
        phoneVerified: false,
      });
    };

    return (
      <InputForm
        type='phone'
        title='휴대폰 번호'
        keyboardType="phone-pad"
        placeholder='휴대폰 번호를 입력해주세요.'
        value={phone}
        description={description}
        onInputRef={ref => { this.inputPhoneRef = ref; }}
        onChangeText={onChangeText}
        onSubmitEditing={onPressRightButton}
        enableInfoMessage
        enableErrorMessage
        errorMessage={errorMessage}
        rightButtonStyle={{ width: 124, opacity: disableRightButton ? 0.3 : 1 }}
        rightButtonTitle={rightButtonTitle}
        onPressRightButton={onPressRightButton}
        disableRightButton={disableRightButton}
        required
        />
    );
  }

  renderPhoneOTPInput() {
    const {
      isRegisterForm
    } = this.props;
    const {
      phoneVerified,
      verificationFailed,
      inputPhoneOTPText,
      verificationOngoing,
      verificationTestCode,
      verificationErrorMessage,
    } = this.state;

    if (!(verificationOngoing || verificationFailed)) {
      return null;
    }

    let onPressRightButton = () => null;

    if (inputPhoneOTPText.length > 0) {
      onPressRightButton = this.onPressCheckVerificationNumber
    }

    let infoMessage = '';
    let rightButtonTitle = '인증 번호 확인';

    if (verificationOngoing) {
      const remaingTime = this.getRemainingTimeStr();
      // XXX API스토어 서비스 종료에 따른 임시조치
      if (isRegisterForm) {
        infoMessage = `아래 번호를 입력하세요 (${remaingTime}전)`;
        if (verificationTestCode) {
          //infoMessage += `\n테스트용 인증코드 임시표시: ${verificationTestCode}`;
          infoMessage += `\n${verificationTestCode}`;
        }
      } else {
        infoMessage = `인증 번호를 발송했습니다 (${remaingTime}전)`;
      }
    }

    if (phoneVerified) {
      infoMessage = '인증 완료';
      onPressRightButton = () => null;
    }

    return (
      <View style={{ marginTop: 10 }}>
        <InputForm
          type='phoneOTP'
          keyboardType='number-pad'
          placeholder='인증 번호를 입력해주세요.'
          value={inputPhoneOTPText}
          onInputRef={ref => { this.inputPhoneOTPRef = ref; }}
          onChangeText={val => this.setState({ inputPhoneOTPText: val })}
          enableErrorMessage
          errorMessage={verificationErrorMessage}
          enableInfoMessage
          infoMessage={infoMessage}
          rightButtonStyle={{ width: 124 }}
          rightButtonTitle={rightButtonTitle}
          onPressRightButton={onPressRightButton}
          onSubmitEditing={onPressRightButton}
          />
      </View>
    );
  }

  render() {
    return (
      <View style={styles.container}>
        {this.renderPhoneInput()}
        {this.renderPhoneOTPInput()}
      </View>
    );
  }
}

PhoneVerificationForm.propTypes = {
  onChange: PropTypes.func,
  // 아이디 찾기 기능에서 사용
  requestTelVerificationCodeCallback: PropTypes.func,
  verifyTelVerificationCodeCallback: PropTypes.func,
  phone: PropTypes.string,
  phoneVerified: PropTypes.bool,
  description: PropTypes.string,
};

PhoneVerificationForm.defaultProps = {
  onChange: value => null,
  requestTelVerificationCodeCallback: null,
  verifyTelVerificationCodeCallback: null,
  phone: '',
  phoneVerified: false,
  description: '숫자만 입력 가능',
};

export default connect(() => ({
}), actions)
(PhoneVerificationForm);
