import React from 'react';

import { StyleSheet, View } from 'react-native';


const styles = StyleSheet.create({
  container: {
    width: '100%',
    aspectRatio: 1,
    backgroundColor: 'blue',
  },
})


class MockImage extends React.PureComponent {

  render() {
    const { style } = this.props;
    const randomColor = 'rgb(' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ')';

      return (
        <View style={[styles.container, {backgroundColor: randomColor}, style]}
          />
    );
  }
}

export default MockImage;
