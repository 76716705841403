import React from 'react';

import {
  Dimensions, StyleSheet, View, Text,
} from 'react-native';
import { Styles } from '../../../constants';
import Constants from '../../../constants/Constants';

import { parseDate, convertKstToUtc } from 'shared/util';


const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
  container: {
    paddingTop: 20.5,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  title: {
    ...Styles.appleSDGothicNeoRegular,
    fontWeight: '500',
    fontSize: 12,
    lineHeight: 14,
  },
  statusText: {
    ...Styles.pretendard600,
    fontSize: 15,
    lineHeight: 18,
    height: 18,
  },
  progressBarContainer: {
    height: 34,
    marginTop: 14,
  },
  progressBarBackground: {
    height: 34,
    backgroundColor: '#000000',
  },
  progressBarCurrent: {
    height: 34,
    position: 'absolute',
    top: 0,
    start: 0,
    backgroundColor: 'rgb(242, 242, 242)',
  },
  salePeriodContainer: {
    marginTop: 17,
    backgroundColor: '#000000',
    paddingTop: 17,
    paddingStart: 15,
    paddingBottom: 20,
  },
  salePeriod: {
    ...Styles.pretendard400,
    fontSize: 14,
    lineHeight: 16.8,
    height: 17,
    marginTop: 4,
  },
  shippingDate: {
    marginTop: 4,
    ...Styles.pretendard400,
    fontSize: 13,
    lineHeight: 15.6,
    height: 16,
    opacity: 0.5,
  },
  remainingTimesText: {
    height: 18,
    height: 18,
    position: 'absolute',
    top: 8,
    start: 0,
    bottom: 0,
    end: 8,
    textAlign: 'center',
    ...Styles.pretendard700,
    fontSize: 15,
    color: '#ffffff',
    mixBlendMode: 'difference',
  },
});


function getDateStr(date) {
  return `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일`;
}


function renderProgressBar(progressRate, status, remainTimes) {
  const progressRateValue = Math.max(Math.min(progressRate, 1), 0);
  const MAX_PROGRESSBAR_WIDTH = windowWidth - Constants.viewHorizontalPadding * 2;
  let progressBarWidth = MAX_PROGRESSBAR_WIDTH * progressRateValue;
  progressBarWidth = Math.max(progressBarWidth, 6);

  const progressStyle = {
    width: progressBarWidth,
  };

  let seconds = Math.floor(remainTimes / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);
  seconds %= 60;
  minutes %= 60;
  hours %= 24;
  let remainTimeText = days + '일 ' + hours + '시간 ' + minutes + '분 ' + seconds + '초후';

  switch (status) {
    case 'before':
      remainTimeText += ' 오픈'
      break;

    case 'ongoing':
      remainTimeText += ' 종료'
      break;

    case 'after':
    default:
      return null;
  }

  return (
    <View style={styles.progressBarContainer}>
      <View style={styles.progressBarBackground} />
      <View
        style={[
          styles.progressBarCurrent,
          progressStyle,
        ]}
        />
      <Text style={styles.remainingTimesText}>{remainTimeText}</Text>
    </View>
  );
}


class PreOrderInfo extends React.Component {

  componentDidMount() {
    this.interval = setInterval(() => this.setState({ time: Date.now()}), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  renderStatusText(status) {
    let statusText = '';

    // let seconds = Math.floor(remainTimes / 1000);
    // let minutes = Math.floor(seconds / 60);
    // let hours = Math.floor(minutes / 60);
    // let days = Math.floor(hours / 24);
    // seconds %= 60;
    // minutes %= 60;
    // hours %= 24;
    // let remainTimeText = days + '일 ' + hours + '시간 ' + minutes + '분 ' + seconds + '초후';

    switch (status) {
      case 'before':
        statusText = '오픈예정';
        // remainTimeText += ' 오픈'
        break;

      case 'ongoing':
        statusText = '진행중';
        // remainTimeText += ' 종료'
        break;

      case 'after':
      default:
        return null;
    }

    return (
      <Text style={styles.statusText}>프리오더 {statusText}</Text>
    ); 
  }

  render() {
    const {
      containerStyle,
      product: {
        salesStartYmd,
        salesEndYmd,
        launchYmd,
      },
    } = this.props;

    let status = 'after';
    let remainTimes = 0;
    let imageStyle = styles.imageInactive
    let progressRate = 1;

    const salesStartDate = convertKstToUtc(parseDate(salesStartYmd, true));
    const salesEndDate = convertKstToUtc(parseDate(salesEndYmd, true));

    const remainStartTimes = salesStartDate - new Date();
    const remainEndTimes = convertKstToUtc(parseDate(salesEndYmd, true)) - new Date();

    if (remainStartTimes > 0) {
      status = 'before';
      imageStyle = styles.image;
      remainTimes = remainStartTimes;
      progressRate = 0;
    } else if (remainEndTimes > 0) {
      status = 'ongoing';
      imageStyle = styles.image;
      remainTimes = remainEndTimes;
      progressRate = (Date.now() - salesStartDate) / (salesEndDate - salesStartDate);
    }

    const launchDate = parseDate(launchYmd);
    const launchDateStr = getDateStr(launchDate) + '부터 순차 배송';

    return (
      <View style={[styles.container, containerStyle]}>
        {this.renderStatusText(status)}

        <Text style={styles.salePeriod}>{getDateStr(salesStartDate)} ~ {getDateStr(salesEndDate)}</Text>

        <Text style={styles.shippingDate}>{launchDateStr}</Text>

        {renderProgressBar(progressRate, status, remainTimes)}
      </View>
    );
  }
}

export default PreOrderInfo;
