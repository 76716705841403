import { normalize, schema } from 'normalizr';
import assign from 'lodash/assign'
import get from 'lodash/get';
import set from 'lodash/set';
import setWith from 'lodash/setWith';
import qs from 'qs';

import * as schemas from '../schemas';
import createFetchAction from '../util/createFetchActionWithShopAuth';


export function getNextPageUrl(response) {
  const link = response.headers.get('link');
  return link;
}


// export function authenticateShopAuth() {
//   return createFetchAction({
//     type: 'AUTHENTICATE_SHOP_AUTH',
//     endpoint: `/api/v2/shop/auth/authenticate`,
//     transform: ({ json }) => {

//       // console.log("DEBUG loadShopProduct json", json);

//       if (json.success !== true) {
//         return {}
//       }

//       var payload = {}

//       const sessionId = json.data.sessionId;

//       payload = set(payload, 'entities.shop.sessionId', sessionId);
//       // console.log("DEBUG loadShopProduct payload", payload);

//       return payload;
//     },
//     success: {
//       meta: { entities: { update: true } },
//     }
//   });
// }


export function validateShopAuth() {
  return createFetchAction({
    type: 'VALIDATE_SHOP_AUTH',
    endpoint: '/api/v2/shop/auth/validate',
  });
}


export function loadShopProduct({ productId, postId, updateRecent }) {
  let endpoint = `/api/v2/shop/product/${productId}`;

  const queryParams = [];
  if (postId) {
    queryParams.push(`post=${postId}`);
  }
  if (updateRecent) {
    queryParams.push('updateRecent=y');
  }
  if (queryParams.length > 0) {
    endpoint += '?' + queryParams.join('&')
  }

  return createFetchAction({
    type: 'GET_PRODUCT_DETAIL',
    endpoint,
    transform: ({ json }) => {
      // console.log("loadShopProduct json", json);

      if (json.success !== true) {
        return {};
      }

      json.data._id = productId;

      const product = json.data;
      return normalize(product, schemas.shopProduct);
    },
    success: {
      meta: { entities: { update: true } },
    },
  });
}

export function getShopProductOption(productId, param) {
  return createFetchAction({
    method: 'POST',
    type: 'GET_PRODUCT_OPTION',
    body: param,
    endpoint: `/api/v2/shop/product/${productId}/option`,
  });
}


export function getShopProductQnaListPaginationKey(productId) {
  return `shopProductsQna${productId}`;
}

export function getShopProductQnaListEntitiesKey(productId) {
  return `shopProductsQna${productId}`;
}

export function loadShopProductQnaList(productId, nextPage, query) {
  const key = getShopProductQnaListPaginationKey(productId);

  return createFetchAction({
    type: 'LOAD_SHOP_PRODUCT_QNA_LIST',
    endpoint: state => {
      const base = `/api/v2/shop/product/${productId}/qna?${qs.stringify(query)}`;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    transform: ({ json, response }) => {
      if (json.success !== true) {
        // TODO: 에러 처리 필요
        // console.log(`loadShopProductQnaList failed errorCode=${json.errorCode}`);
        return {};
      }

      const articles = {};

      const nextPageUrl = getNextPageUrl(response);

      let payload = {
        result: [],
        nextPageUrl,
      };

      json.data.articles.forEach(article => {
        articles[article.sno] = article; payload.result.push(article.sno);
      });
      const entitiesKey = getShopProductQnaListEntitiesKey(productId);
      payload = set(payload, 'entities.' + entitiesKey, articles);
      return payload;
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}


export function getShopProductQnaArticleEntitiesKey(productId, articleId) {
  return getShopProductQnaListEntitiesKey(productId) + `[${articleId}]`;
}


export function createShopProductQnaArticle(productId, params) {
  // const key = getShopProductQnaListPaginationKey(productId);

  return createFetchAction({
    type: 'CREATE_SHOP_PRODUCT_QNA_LIST',
    method: 'POST',
    endpoint: `/api/v2/shop/product/${productId}/qna`,
    body: params,
    transform: ({ json }) => {
      if (json.success !== true) {
        let payload = {};

        if (json.errorCode === 400) {
          payload = set(payload, 'entities.shop.sessionId', '');
        }

        return payload;
      }

      const payload = {
      };

      return { payload };
    },
  });
}


export function loadShopProductQnaArticle(productId, articleId) {
  return createFetchAction({
    type: 'LOAD_SHOP_PRODUCT_QNA_ARTICLE',
    endpoint: `/api/v2/shop/product/${productId}/qna/${articleId}`,
    transform: ({ json }) => {
      if (json.success !== true) {
        // TODO: 에러 처리 필요
        return {};
      }

      let payload = {};

      const article = json.data;
      article._id = `${articleId}`;

      const articles = {};
      articles[articleId] = article;

      const entitiesKey = getShopProductQnaListEntitiesKey(productId);
      payload = set(payload, `entities.${entitiesKey}`, articles);
      return payload;
    },
    success: {
      meta: { entities: { update: true } },
    },
  });
}


export function modifyShopProductQnaArticle(productId, articleId, params) {
  return createFetchAction({
    type: 'MODIFY_SHOP_PRODUCT_QNA_LIST',
    method: 'PUT',
    endpoint: `/api/v2/shop/product/${productId}/qna/${articleId}`,
    body: params,
  });
}


export function deleteShopProductQnaArticle(productId, articleId) {
  return createFetchAction({
    type: 'DELETE_SHOP_PRODUCT_QNA_LIST',
    method: 'DELETE',
    endpoint: `/api/v2/shop/product/${productId}/qna/${articleId}`,
  });
}


export function getProductCategoryList() {
  return createFetchAction({
    type: 'GET_PRODUCT_CATEGORY_LIST',
    endpoint: '/api/v2/shop/category',
    transform: ({ json }) => {
      if (json.success !== true) {
        // TODO: 에러 처리 필요
        return [];
      }

      return set({}, 'entities.shop.categories', json.data);
    },
  });
}


let getProductListByCategoryPaused = false;
export function pauseOnceGetProductListByCategory() {
  getProductListByCategoryPaused = true;
}

export function getShopProductListByCategoryKey(categoryId, exhibition=false, query=null) {
  if (exhibition) {
    return `shopProducts_exhibition_${categoryId}`;
  } else if (query) {
    const hashCode = (str) => {
        let hash = 0;
        for (let i = 0, len = str.length; i < len; i++) {
            let chr = str.charCodeAt(i);
            hash = (hash << 5) - hash + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }
    const extraKey = hashCode(JSON.stringify(query));
    return `shopProducts_${categoryId}_${extraKey}`;
  } else {
    return `shopProducts_${categoryId}`;
  }
}

export function getProductListByCategory(categoryId, query, nextPage, exhibition=false) {
  const key = getShopProductListByCategoryKey(categoryId, exhibition);
  const params = query ? `?${qs.stringify(query).replace('%20', '+')}` : '';
  const schemaProductList = [new schema.Entity(key, {}, { idAttribute: 'goodsNo' })];

  if (getProductListByCategoryPaused) {
    getProductListByCategoryPaused = false;
    return async () => {};
  }

  return createFetchAction({
    type: 'GET_PRODUCT_LIST_CATEGORY',
    method: 'GET',
    endpoint: state => {
      const base = '/api/v2/shop/product' + params;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
    transform: ({ json, response }) => {
      if (json.success !== true) {
        console.error("GET_PRODUCT_LIST_CATEGORY FAILED", json);
        return {};
      }

      const { data: { goodsData } } = json;

      return assign(
        normalize(goodsData, schemaProductList),
        { nextPageUrl: getNextPageUrl(response) }
      );
    },
  });
}

export function getProductListByCategoryWithFilter(categoryId, query, nextPage, exhibition=false) {
  const key = getShopProductListByCategoryKey(categoryId, exhibition, query);
  const params = query ? `?${qs.stringify(query).replace('%20', '+')}` : '';
  const schemaProductList = [new schema.Entity(key, {}, { idAttribute: 'goodsNo' })];

  return createFetchAction({
    type: 'GET_PRODUCT_LIST_CATEGORY_WITH_FILTER',
    method: 'GET',
    endpoint: state => {
      const base = '/api/v2/shop/product/search' + params;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
      meta: { entities: { update: true } },
    },
    transform: ({ json, state, response }) => {
      if (json.success !== true) {
        // TODO: 에러 처리 필요
        console.error("GET_PRODUCT_LIST_CATEGORY_WITH_FILTER FAILED", json);
        return {};
      }

      const { data : { goodsData } } = json;
      let result = Object.assign(
        normalize(goodsData, schemaProductList),
        { nextPageUrl: getNextPageUrl(response) }
      );

      return result;
    },
  });
}

export function getSimilarProductList(query) {
  const params = query ? `?${qs.stringify(query).replace('%20', '+')}` : '';

  return createFetchAction({
    type: 'GET_SIMILAR_PRODUCT',
    method: 'GET',
    endpoint: state => {
      return '/api/v2/shop/product/search' + params;
    },
  });
}

export function getShopProductReviewListPaginationKey(productId) {
  return `shopProductsReview${productId}`;
}

export function getShopProductReviewListEntitiesKey(productId) {
  return `shopProductsReview${productId}`;
}

export function loadShopProductReviewList(productId, nextPage, query) {
  const key = getShopProductReviewListPaginationKey(productId);

  return createFetchAction({
    type: 'LOAD_SHOP_PRODUCT_REVIEW_LIST',
    endpoint: state => {
      const base = `/api/v2/shop/product/${productId}/review?${qs.stringify(query)}`;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    transform: ({ json, response }) => {
      if (json.success !== true) {
        // console.log(`loadShopProductReviewList failed errorCode=${json.errorCode}`);
        return {};
      }

      const data = json.data.articles;
      const articles = {};

      const nextPageUrl = getNextPageUrl(response);

      let payload = {
        result: [],
        nextPageUrl,
      };

      data.forEach(article => {
        articles[article.sno] = article;
        payload.result.push(article.sno);
      });
      const entitiesKey = getShopProductReviewListEntitiesKey(productId);
      payload = set(payload, 'entities.' + entitiesKey, articles);
      return payload;
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}

export function getShopProductReviewArticleEntitiesKey(productId, articleId) {
  return getShopProductReviewListEntitiesKey(productId) + `[${articleId}]`;
}


export function loadShopProductReviewArticle(productId, articleId) {
  return createFetchAction({
    type: 'LOAD_SHOP_PRODUCT_REVIEW_ARTICLE',
    endpoint: `/api/v2/shop/product/${productId}/review/${articleId}`,
    transform: ({ json }) => {
      if (json.success !== true) {
        // TODO: 에러 처리 필요
        return {};
      }

      let payload = {};

      const article = json.data;
      article._id = `${articleId}`;

      const articles = {};
      articles[articleId] = article;


      const entitiesKey = getShopProductReviewListEntitiesKey(productId);
      payload = set(payload, `entities.${entitiesKey}`, articles);
      return payload;
    },
    success: {
      meta: { entities: { update: true } },
    },
  });
}

export function createShopProductReviewArticle(productId, params) {
  return createFetchAction({
    type: 'CREATE_SHOP_PRODUCT_REVIEW',
    method: 'POST',
    endpoint: `/api/v2/shop/product/${productId}/review`,
    body: params,
    transform: ({ json }) => {
      if (json.success !== true) {
        let payload = {};

        if (json.errorCode === 400) {
          payload = set(payload, 'entities.shop.sessionId', '');
        }

        return payload;
      }

      const payload = {
      };

      return { payload };
    },
  });
}

export function modifyShopProductReviewArticle(productId, articleId, params) {
  return createFetchAction({
    type: 'MODIFY_SHOP_PRODUCT_REVIEW',
    method: 'PUT',
    endpoint: `/api/v2/shop/product/${productId}/review/${articleId}`,
    body: params,
  });
}

export function deleteShopProductReviewArticle(productId, articleId) {
  return createFetchAction({
    type: 'DELETE_SHOP_PRODUCT_REVIEW',
    method: 'DELETE',
    endpoint: `/api/v2/shop/product/${productId}/review/${articleId}`,
  });
}

export function loadShopBrandList() {
  return createFetchAction({
    type: 'LOAD_SHOP_BRAND_LIST',
    endpoint: '/api/v2/shop/brand',
  });
}

export function loadAndSaveShopBrandList() {
  const key = 'shopBrandList';

  return createFetchAction({
    type: 'LOAD_SHOP_BRAND_LIST',
    endpoint: '/api/v2/shop/brand',
    transform: ({ json }) => {
      return normalize(json.data, schemas.shopBrandList);
    },
    success: {
      pagination: { key, type: 'REFRESH' },
    }
  });
}

export function loadShopColorList() {
  return createFetchAction({
    type: 'LOAD_SHOP_COLOR_LIST',
    endpoint: '/api/v2/shop/color',
  });
}

export function loadShopPriceRange() {
  return createFetchAction({
    type: 'LOAD_SHOP_PRICE_RANGE',
    endpoint: '/api/v2/shop/product/priceRange',
  });
}
