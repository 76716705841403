import React from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import qs from 'qs';
import { browserHistory } from 'react-router';

import { actions } from 'shared';

import Colors from '../../constants/Colors';
import Styles from '../../constants/Styles';

const styles = StyleSheet.create({
  headerLeftButton: {
    marginRight: 10,
    width: 40,
    height: 40,
    backgroundColor: 'gray',
    fontSize: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerLeftButtonText: {
    fontSize: 10,
    color: 'black',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    start: 0,
    end: 0,
    justifyContent: 'center',
    maxWidth: 960,
    marginHorizontal: 'auto',
  },
  contentContainer: {
    paddingHorizontal: 20,
    paddingBottom: 100,
  },
  mainText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 24,
    marginBottom: 50,
    marginStart: 10,
    lineHeight: 29,
  },
  button: {
    backgroundColor: '#000',
    alignItems: 'center',
    marginTop: 10,
    height: 48,
    justifyContent: 'center',
  },
  buttonLight: {
    backgroundColor: Colors.lightGray,
  },
  buttonText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 14,
    height: 17,
    marginTop: 1,
    color: '#fff',
  },
  buttonTextLight: {
    color: '#000',
  },
});


class CheckoutFinishPage extends React.PureComponent {
  constructor(props) {
    super(props);

    const { params: { query } } = props;

    const queryObject = qs.parse(query);
    const {
      orderNo = '',
      modeFail = false,
    } = queryObject;

    this.orderNo = orderNo;
    this.modeFail = modeFail;
  }

  componentDidMount() {
    const { logEventPageview } = this.props;

    logEventPageview({ page_name: 'CheckoutFinish' });
  }

  render() {
    const { orderNo, modeFail } = this;
    const { router } = this.props;

    const moveToShop = () => {
      const path = '/shop';
      router.push(path);
    };

    const moveToOrderList = () => {
      if (orderNo !== '0') {
        const path = `/accounts/orders/${orderNo}`;
        router.push(path);
      } else {
        const path = '/accounts/orders/';
        router.push(path);
      }
    };

    const moveToCart = () => {
      const path = '/shop/cart';
      router.push(path);
    };

    if (modeFail === 'true') {
      return (
        <View style={styles.container}>
          <View style={styles.contentContainer}>
            <Text style={styles.mainText}>{'주문이\n취소되었습니다.'}</Text>

            <TouchableOpacity style={styles.button} onPress={moveToShop}>
              <Text style={styles.buttonText}>쇼핑 계속하기</Text>
            </TouchableOpacity>

            <TouchableOpacity style={[styles.button, styles.buttonLight]} onPress={moveToCart}>
              <Text style={[styles.buttonText, styles.buttonTextLight]}>카트 페이지로</Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    }

    return (
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          <Text style={styles.mainText}>{'주문이\n완료되었습니다'}</Text>

          <TouchableOpacity style={styles.button} onPress={moveToShop}>
            <Text style={styles.buttonText}>쇼핑 계속하기</Text>
          </TouchableOpacity>

          <TouchableOpacity style={[styles.button, styles.buttonLight]} onPress={moveToOrderList}>
            <Text style={[styles.buttonText, styles.buttonTextLight]}>주문 내역 페이지로</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}


export function navigateToCheckoutFinish(orderNo) {
  const query = {
    orderNo,
    modeFail: false,
  };

  const path = `/shop/checkoutFinish/${qs.stringify(query)}`;
  browserHistory.push(path);
}

export function navigateToCheckoutFailure() {
  const query = {
    orderNo: '',
    modeFail: true,
  };

  const path = `/shop/checkoutFinish/${qs.stringify(query)}`;
  browserHistory.push(path);
}


export default connect(
  (state) => {
  },
  actions,
)(CheckoutFinishPage);
