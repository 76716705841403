import React from 'react';
import {
  Dimensions, ActivityIndicator, TouchableHighlight,
  StyleSheet, View, Text,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';

import Spinner from '../../../nativeComponents/Spinner';
import FavItem from '../../../nativeComponents/FavItem';
import BlankResult from '../../../nativeComponents/BlankResult';
import LoadMoreButton from '../../../nativeComponents/LoadMoreButton';

import Colors from '../../../constants/Colors';
import Styles from '../../../constants/Styles';
import Constants from '../../../constants/Constants';

import RadioSelected from '../../../icons/controlRadioSelected';
import RadioNormal from '../../../icons/controlRadioNormal';


const { width: windowWidth, height: windowHeight } = Dimensions.get('window');

const MAX_COUNT_PER_PAGE = 10;


const styles = StyleSheet.create({
  closeAllButtonSize: {
    width: 70,
    height: 25,
  },
  historyContainerHeader: {
    display: 'flex',
    width: '100%',
    height: 25,
    flexDirection: 'row',
    paddingHorizontal: 12,
    justifyContent: 'space-between',
  },
  loadMoreButtonContainer: {
    width: '100%',
  },
  loadMoreButtonSize: {
    width: 90,
    height: 38,
  },
  productsTitle: {
    width: 68,
    height: 'auto',
    resizeMode: 'contain',
  },
  separator: {
    backgroundColor: Colors.lightGray,
    height: 1,
    marginStart: 20,
  },
  loadMoreContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const headerStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: 52,
    paddingLeft: Constants.viewHorizontalPadding,
    paddingRight: Constants.viewHorizontalPadding - 5 - Constants.touchEffectWidth,
    paddingTop: 13 - Constants.touchEffectWidth,
    paddingBottom: 15 - Constants.touchEffectWidth,
    marginBottom: -20,
  },
  iconTextContainer: {
    flexDirection: 'row',
  },
  button: {
    ...Styles.touchEffect
  },
  countText: {
    color: 'rgba(0, 0, 0, 0.45)',
    fontSize: 16,
    height: 19 + 2 + Constants.touchEffectWidth * 2,
    lineHeight: 19,
    textAilgnVertical: 'top',
    marginTop: 3 + Constants.touchEffectWidth,
    ...Styles.appleSDGothicNeoSemiBold,
  },
  filterText: {
    color: 'black',
    fontSize: 15,
    height: 19 + 2,
    lineHeight: 19,
    textAilgnVertical: 'top',
    marginTop: 3,
    marginLeft: 4,
    ...Styles.appleSDGothicNeoSemiBold,
  },
  textOff: {
    color: 'rgba(0, 0, 0, 0.45)',
  },
});


export default class HistoryTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetchingNextPage: false,
      now: Date.now(),
      filter: 'none',
    };
  }

  componentDidMount() {
    const { loadHistory } = this.props;
    const { filter } = this.state;
    if (loadHistory) {
      loadHistory(false, MAX_COUNT_PER_PAGE, filter);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { pagination, loadHistory } = this.props;
    const { filter } = this.state;

    if (prevProps.pagination.isFetching && !pagination.isFetching) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isFetchingNextPage: false });
    }

    if (!prevProps.loadHistory && loadHistory
      || (prevState.filter !== filter)
      ) {
      loadHistory(false, MAX_COUNT_PER_PAGE, filter);
    }
  }

  renderFooter = () => {
    const { pagination } = this.props;
    const { isFetchingNextPage } = this.state;

    if (!isFetchingNextPage && pagination.nextPageUrl) {
      return this.renderLoadMore();
    }

    if (pagination.nextPageUrl === null) {
      return null;
    }
    if (!isFetchingNextPage) {
      return null;
    }

    return (
      <View
        style={{
          position: 'relative',
          width: windowWidth,
          height: windowHeight / 5,
          paddingVertical: 20,
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <ActivityIndicator animating size="small" color="gray" />
      </View>
    );
  }

  renderSeparator = () => (
    <View style={styles.separator}>
    </View>
  )

  renderLoadMore() {
    const onPress = () => {
      const {
        pagination,
        loadHistory,
      } = this.props;
      const { filter } = this.state;

      if (!pagination.nextPageUrl) {
        return;
      }

      this.setState({ isFetchingNextPage: true }, () => {
        loadHistory(true, MAX_COUNT_PER_PAGE, filter);
      });
    };

    return (
      <View style={styles.loadMoreContainer}>
        <LoadMoreButton onPress={onPress} />
      </View>
    );
  }

  toggleFilter = () => {
    const { filter } = this.state;

    if (filter === 'heartit') {
      this.setState({ filter: 'none' });
    } else {
      this.setState({ filter: 'heartit' });
    }
  }

  renderHeader = () => {
    const { filter } = this.state;
    const { pagination: {countResult }} = this.props;

    return (
      <View style={headerStyles.container}>
        <Text style={headerStyles.countText}>총 { countResult }개 상품</Text>
        {/*
        <TouchableHighlight
          style={headerStyles.button}
          onPress={() => this.toggleFilter()}
          underlayColor={Colors.touchEffectColorWhite}
        >
          <View style={headerStyles.iconTextContainer}>
            { (filter === 'none') ? <RadioNormal /> : <RadioSelected />}
            <Text style={[headerStyles.filterText, (filter === 'none') ? headerStyles.textOff : {}]}>
              하트잇 상품
            </Text>
          </View>
        </TouchableHighlight>
        */}
      </View>
    );
  }

  render() {
    const {
      _clearAllHistory,
      histories,
      deleteHistory,
      loadHistory,
      pagination,
      _resetHistoryStore,
      auth,
      historySwitch,
      _test500Error,
      router,
      showSmartBanner,
      ...props
    } = this.props;
    const { now, isFetchingNextPage } = this.state;

    if (!isFetchingNextPage && pagination.isFetching) {
      return (<Spinner />);
    }

    if (!pagination.isFetching && histories.length === 0) {
      let topPosition = Constants.mainHeaderHeight + Constants.tabbarHeight;
      if (showSmartBanner) {
        topPosition += Constants.smartBannerHeight;
      }
      /*
      return (
        <View>
          {this.renderHeader()}
          <BlankResult
            router={router}
            title="최근 본 상품이 없습니다"
            description="최근 두달동안 확인한 상품이 여기에 표시됩니다"
            topPosition={topPosition}
          />
        </View>
      );
      */
      return (
        <BlankResult
          router={router}
          title="최근 본 상품이 없습니다"
          description="최근 두달동안 확인한 상품이 여기에 표시됩니다"
          topPosition={topPosition}
        />
      );
    }

    Object.assign(props, {
      renderItem: ({ item }) => (
        <FavItem
          clickedItem={item}
          deleteHistory={deleteHistory}
          auth={auth}
          historySwitch={historySwitch}
          now={now}
          router={router}
        />
      ),
    });


    const data = histories;

    return (
      <View>
        {this.renderHeader()}
        {data.map((item, index) => (
          <React.Fragment key={`fragment_${item._id}`}>
            {index > 0 && this.renderSeparator()}
            <FavItem
              clickedItem={item}
              deleteHistory={deleteHistory}
              auth={auth}
              historySwitch={historySwitch}
              now={now}
              router={router}
            />
          </React.Fragment>
        ))}
        {this.renderFooter()}
      </View>
    );
  }
}
