import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { pick, map, keys } from 'lodash';


const statNameMap = {
  'reqCount': '요청수',
  'respTimeAvg': '응답시간(Avg)',
  'respTimeMax': '응답시간(Max)',
  'respTimeMin': '응답시간(Min)',
};

const statDayOfWeekMap = {
  "1": "월",
  "2": "화",
  "3": "수",
  "4": "목",
  "5": "금",
  "6": "토",
  "7": "일",
};

class GodomallStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statisticsOptions: {
        title: {
          text: '',
        },
        series: [],
        xAxis: {
          tickmarkPlacement: 'on',
          reversed: true,
        },
      },
      kind: 'daily',
      visitStatRawData: {},
    };
  }

  loadStatistics(startDate, endDate) {
    const { loadData, godomallKey } = this.props;
    const { kind } = this.state;

    // console.log(`loadUserStatistics clickKey=${clickKey}, kind=${kind}`);

    loadData(startDate, endDate, godomallKey, kind)
      .then(resp => {
        const {
          data,
          title,
          description
        } = resp.content;
        this.setState({
          visitStatRawData: data,
          title,
          description,
        });

        var values = Object.keys(data).map(function(key) {
          return data[key];
      });
        const res = values.reduce((accumulator, datum) => {
          keys(datum).forEach(key => {
            if (!accumulator[key]) {
              accumulator[key] = [];
            }
            accumulator[key].push(datum[key]);
          });
          return accumulator;
        }, {})
        return res;
      })
      .then(data => {
        const series = map(
          pick(data, ['reqCount', 'respTimeAvg', 'respTimeMax', 'respTimeMin']),
          (value, key) => ({
              name: statNameMap[key],
              data: value,
              yAxis: (key == 'reqCount') ? 0 : 1,
          }),
        );
        let categories = data.date;
        if (kind == 'dayOfWeek') {
          categories = categories.map(v => statDayOfWeekMap[v]);
        }
        const { statisticsOptions: statisticsOptionsPrev } = this.state;

        this.setState({
          statisticsOptions: {
            ...statisticsOptionsPrev,
            yAxis: [{
              title: {
                text: "요청수"
              }
            }, {
              title: {
                text: "응답시간(ms)"
              },
              opposite: true
            }],
            xAxis: {
              categories,
              reversed: false,
            },
            series,
          },
        });
      });
  }

  componentDidMount() {
    const { startDate, endDate } = this.props;
    this.loadStatistics(startDate, endDate);
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate } = this.props;
    const { kind } = this.state;
    if (prevProps.startDate !== startDate || prevProps.endDate !== endDate || prevState.kind !== kind) {
      this.loadStatistics(startDate, endDate);
    }
  }

  render() {
    const {
      godomallKey
    } = this.props;

    const {
      statisticsOptions,
      kind,
    } = this.state;

    const title = Buffer.from(godomallKey, "base64").toString('utf8');

    return (
      <div>
        <div style={{ fontSize: '20px', marginLeft: '20px', fontWeight: 'bold' }}>
        고도몰 API {title} 요청수&nbsp;&nbsp;
          <select onChange={event => this.setState({ kind: event.target.value })} value={kind}>
            <option value="daily">일별</option>
            <option value="hourly">시간별</option>
            <option value="hourOfDay">시간대별</option>
            <option value="dayOfWeek">요일별</option>
            <option value="monthly">월별</option>
          </select>
        </div>

        <HighchartsReact
          containerProps={{ style: { height: '600px' } }}
          highcharts={Highcharts}
          options={statisticsOptions} />
      </div>
    );
  }
}


GodomallStatistics.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};


export default GodomallStatistics;
