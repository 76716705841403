import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { signup, appleLogin } from 'react-shared/actions';
import ga from 'react-ga';
import { Snackbar } from '@material-ui/core';
import get from 'lodash/get';
import jwtDecode from 'jwt-decode';

import Spinner from 'components/Spinner';

import { default as ProfileInputForm } from '../../nativeComponents/ProfileInputForm';
import { runAfterJoinDone } from './util';

const stylesHtml = {
  container: {
    margin: 20,
  },
  snackbar: {
    textAlign: 'center',
  },
};


class AppleIdCallbackPage extends Component {
  constructor(props) {
    super(props);
    this.inputForm = React.createRef();
    this.username = React.createRef();
    this.appleIdToken = React.createRef();

    this.handleSignup = this.handleSignup.bind(this);

    this.state = {
      showSignup: false,
      snackbarMsg: '',
      snackbarOpen: false,
    };
  }

  componentDidMount() {
    const { code, idToken, userData, router } = this.props;
    if (!code) {
      router.push('/');
    }

    this.handleLogin(code, idToken, userData);
  }

  showSnackbar = msg => {
    this.setState({
      snackbarMsg: msg,
      snackbarOpen: true,
    });
  }

  handleSignup(inputValues) {
    const { name, email, phone, birthYear,
      termAgreed, privacyAgreed, marketingAgreed, validationComplete } = inputValues;
    const username = this.username.current.value;
    const appleIdToken = this.appleIdToken.current.value;

    if (!validationComplete) {
      return false;
    }

    $('.edit-profile-save-btn').attr('disabled', true);

    this.props.signup({
      username,
      email,
      name,
      tel: phone,
      termAgreed,
      privacyAgreed,
      marketingAgreed,
      birthYear,
      appleIdAccessToken: appleIdToken,
    }).then(() => {
      runAfterJoinDone(this.props.router);
      ga.event({
        category: 'SignUp',
        action: 'SignUp',
        label: 'Success',
      });
    }, () => {
      this.props.router.push('/accounts/login');
      ga.event({
        category: 'SignUp',
        action: 'SignUp',
        label: 'Fail',
      });
    });
    return true;
  }

  handleLogin = (code, idToken, userData) => this.props.appleLogin(code, 'mobileWeb').then(
    () => {
      this.props.router.push('/');
    },
    err => {
      if (err.status === 404) {
        try {
          const profileResult = jwtDecode(idToken);
          const {
            // sub: appleId,
            email: appleEmail,
          } = profileResult;

          let appleName = '';
          if (userData) {
            const appleUser = JSON.parse(userData);
            if (appleUser.name) {
              appleName = appleUser.name.firstName;
              if (appleUser.name.middleName) {
                appleName += ` ${appleUser.name.middleName}`;
              }
              if (appleUser.name.lastName) {
                appleName += ` ${appleUser.name.lastName}`;
              }
            }
          }
          if (!appleName) {
            appleName = appleEmail;
          }

          this.setState({
            showSignup: true,
            appleIdToken: idToken,
            // appleId,
            email: appleEmail,
            name: appleName,
            username: appleEmail,
          });
        } catch (err2) {
          // eslint-disable-next-line no-console
          console.error(err2);
          alert('애플아이디 로그인에 실패했습니다. 잠시 후 다시 시도하세요.');
          this.props.router.push('/accounts/login');
        }
      } else if (err.status === 400) {
        alert('이미 가입되어 있는 이메일 계정입니다.');
        this.props.router.push('/accounts/login');
      } else {
        alert('애플아이디 로그인에 실패했습니다. 잠시 후 다시 시도하세요.');
        this.props.router.push('/accounts/login');
      }
    }
  )

  render() {
    const { router, legalPolicyType } = this.props;
    const { showSignup, username, email, name,
      appleIdToken,
      snackbarMsg, snackbarOpen } = this.state;

    if (!showSignup) {
      return <Spinner />;
    }

    return (
      <div style={stylesHtml.container}>
        <ProfileInputForm
          ref={this.inputForm}
          router={router}
          handleUpdate={this.handleSignup}
          btnText="가입하기"
          emailDefault={email}
          nameDefault={name}
          showAlertMessage={this.showSnackbar}
          showPolicy
          type="appleId"
          legalPolicyType={legalPolicyType}
        >
          <input type="hidden" ref={this.username} autoCapitalize="none" defaultValue={username} />
          <input type="hidden" ref={this.appleIdToken} defaultValue={appleIdToken} />
        </ProfileInputForm>

        <Snackbar
          autoHideDuration={2000}
          message={snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={snackbarOpen}
          style={stylesHtml.snackbar}
        />
      </div>
    );
  }
}


AppleIdCallbackPage.propTypes = {
  code: PropTypes.string.isRequired,
  // state: PropTypes.string.isRequired,
  idToken: PropTypes.string.isRequired,
  signup: PropTypes.func.isRequired,
  appleLogin: PropTypes.func.isRequired,
};

export default connect(
  (state, ownProps) => {
    const { params } = ownProps;
    const { legalPolicyType = null } = params;

    return {
      code: get(ownProps.location, 'query.code'),
      idToken: get(ownProps.location, 'query.idToken'),
      // state: get(ownProps.location, 'query.state'),
      userData: get(ownProps.location, 'query.user'),
      legalPolicyType,
    };
  },
  { signup, appleLogin }
)(AppleIdCallbackPage);
