import React from 'react';
import {
    StyleSheet, View, Text,
} from 'react-native';

import { connect } from 'react-redux';

import { actions } from 'shared';

import { 
  InputForm,
  PhoneVerificationForm,
  TouchableHighlightWithContainer,
} from '../../../components-v2';
import { Styles } from '../../../constants';

import TabBase from './TabBase';


const styles = StyleSheet.create({
  container: {
  },
  inputContainer: {
    marginTop: 40,
  },
  resultContainer: {
    marginTop: 60,
  },
  resultText: {
    ...Styles.pretendard400,
    fontSize: 20,
    lineHeight: 28,
  },
  resultTextId: {
    ...Styles.pretendard600,
  },
  returnButtonContainer: {
  },
  returnButtonText: {
    textAlign: 'center',
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    color: '#fff',
    backgroundColor: '#000',
    paddingVertical: 10.5,
  }
});

class FindPasswordTab extends TabBase {
  inputEmailRef = null;
  inputPhoneRef = null;
  inputPasswordRef = null;
  inputPasswordConfirmRef = null;


  requestTelVerificationCodeCallback = async phone => {
    const {
      requestFindPasswordWithSMSVerificationCode,
    } = this.props;
    const { email } = this.state;

    try {
      const resp = await requestFindPasswordWithSMSVerificationCode(email, phone);
      return resp;
    } catch (e) {
      console.error(e);

      if (e.status === 404) {
        e.showAlert = false;
        this.alert('가입되어 있지 않은 번호입니다');
      }
      throw e;
    }
  };

  verifyTelVerificationCodeCallback = async (phone, code) => {
    const {
      verifyFindPasswordWithSMSVerificationCode
    } = this.props;
    const { email } = this.state;

    this.setState({
      nameErrorMessage: '',
      phoneErrorMessage: '',
    });

    const resp = await verifyFindPasswordWithSMSVerificationCode(email, phone, code);
    this.setState({
      verified: true,
      bottomButtonTitle: '확인',
      tel: phone,
      code: code,
    });
  };

  componentDidMount() {
    if (this.inputEmailRef) {
      this.inputEmailRef.focus();
    }
  }

  componentDidUpdate(prevProps) {
    const { focused } = this.props;
    if (!prevProps.focused && focused) {
      if (this.inputEmailRef) {
        this.inputEmailRef.focus();
      }
    }
  }

  updateInputData(newState) {
    const state = {
      ...this.state,
      ...newState,
    }

    const {
      passwordResetDone,
    } = state;

    let isEnabledBottomButton = true;
    if (passwordResetDone) {
      isEnabledBottomButton = false;
    }

    this.setState({
      ...newState,
      isEnabledBottomButton,
    });
  }

  validateInput() {
    const {
      verified,
      password,
      passwordConfirm,
    } = this.state;

    if (!verified) {
      if (!this.inputEmailRef.validate()) {
        return false;
      }

      if (!this.inputPhoneRef.validate()) {
        return false;
      }  
    } else {
      if (!this.inputPasswordRef.validate()) {
        return false;
      }

      if (!this.inputPasswordConfirmRef.validate()) {
        return false;
      }

      if (password !== passwordConfirm) {
        this.setState({ passwordConfirmErrorMessage: '새 비밀번호가 일치하지 않습니다.' })
        return false;
      }
    }

    this.setState({ passwordConfirmErrorMessage: null })
    return true;
  }

  renderEmailInput() {
    const { email } = this.state;

    return (
      <InputForm
        type='email'
        title='아이디(이메일 주소)'
        placeholder='가입시 사용한 이메일 주소를 입력해주세요.'
        keyboardType='email-address'
        value={email}
        onInputRef={ref => { this.inputEmailRef = ref; }}
        onChangeText={val => this.updateInputData({ email: val })}
        onSubmitEditing={() => this.inputPhoneRef.focus()}
        enableErrorMessage
        />
    );
  }

  renderPhoneInput() {
    const onChange = value => {
      const {
        phone,
        phoneVerified, 
      } = value;

      this.updateInputData({
        phone,
        phoneVerified,
      })
    };

    return (
      <PhoneVerificationForm
        onRef={ref => { this.inputPhoneRef = ref; }}
        onChange={onChange}
        requestTelVerificationCodeCallback={this.requestTelVerificationCodeCallback}
        verifyTelVerificationCodeCallback={this.verifyTelVerificationCodeCallback}
        />
    );
  }

  renderInputForm() {
    return (
      <View style={styles.inputContainer}>
        {this.renderEmailInput()}
        <View style={{ height: 40 }} />
        {this.renderPhoneInput()}
      </View>
    )
  }

  onPressBottomButton() {
    const {
      router,
      changePasswordWithSMSVerificationCode,
    } = this.props;
    const {
      email,
      tel,
      code,
      password,
      verified,
    } = this.state;

    if (verified) {
      if (!this.validateInput()) {
        return;
      }

      changePasswordWithSMSVerificationCode(email, tel, code, password)
        .then(() => {
          this.setState({ 
            passwordResetDone: true,
            isEnabledBottomButton: false,
          });
        })
        .catch((e) => {
          this.alert('비밀번호를 바꾸는데 실패했습니다.\n다시 시도해주세요.');
        });
      return;
    }

    router.goBack();
  }

  renderPasswordReset() {
    const {
      password,
      passwordConfirm,
      passwordConfirmErrorMessage,
    } = this.state;

    return (
      <View style={styles.inputContainer}>
        <InputForm
          type='password'
          title='새 비밀번호 입력'
          placeholder='8자 이상 영문, 숫자, 특수문자 조합으로 입력해주세요.'
          value={password}
          onInputRef={ref => { this.inputPasswordRef = ref; }}
          onChangeText={val => this.updateInputData({ password: val })}
          onSubmitEditing={() => this.inputPasswordConfirmRef.focus()}
          enableErrorMessage
          minLength={8}
          />
        <View style={{ height: 40 }} />
        <InputForm
          type='password'
          title='새 비밀번호 확인'
          placeholder='새 비밀번호를 한번 더 입력해주세요.'
          value={passwordConfirm}
          onInputRef={ref => { this.inputPasswordConfirmRef = ref; }}
          onChangeText={val => this.updateInputData({ passwordConfirm: val })}
          enableErrorMessage
          errorMessage={passwordConfirmErrorMessage}
          />
      </View>
    )
  }

  renderResultView() {
    const { router } = this.props;
    const { email = '' } = this.state;

    return (
      <View style={styles.resultContainer}>
        <Text style={styles.resultText}>
          <Text style={styles.resultTextId}>{email}</Text>
          님의
        </Text>
        <Text style={[styles.resultText, {marginBottom: 20}]}>비밀번호 재설정이 완료되었습니다.</Text>
        <TouchableHighlightWithContainer
          containerStyle={styles.returnButtonContainer}
          onPress={() => router.goBack()}
        >
          <Text style={styles.returnButtonText}>로그인 페이지로 돌아가기</Text>
        </TouchableHighlightWithContainer>
      </View>
    );
  }

  renderContent() {
    const {
      verified,
      passwordResetDone, 
    } = this.state;

    return (
      <View style={styles.container}>
        { !verified && this.renderInputForm() }
        { verified && !passwordResetDone && this.renderPasswordReset() }
        { verified && passwordResetDone && this.renderResultView() }
      </View>
    )
  }
}


export default connect(
  null,
  actions
)(FindPasswordTab);
