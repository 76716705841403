import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Dimensions, TouchableHighlight, StyleSheet, Text, View } from 'react-native';

import Styles from '../constants/Styles';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';

import HeartitBlankPageLogo from '../icons/HeartitBlankPageLogo';
import EmptyCartIcon from '../icons/EmptyCartIcon';

const initialLayout = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
};


const emptyStyles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    minHeight: initialLayout.height,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  relativeContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 1000,
  },
  contentContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  cartImage: {
    width: 100,
    height: 100,
  },
  alertTextContainer: {
    marginTop: 17,
    flexDirection: 'column',
  },
  alertTextLarge: {
    fontSize: 18,
    lineHeight: 21,
    color: 'black',
    textAlign: 'center',
    ...Styles.appleSDGothicNeoSemiBold,
  },
  alertTextSmall: {
    fontSize: 16,
    lineHeight: 19,
    marginTop: 8,
    color: 'rgba(0,0,0,0.5)',
    textAlign: 'center',
    ...Styles.appleSDGothicNeoMedium,
  },
  continueButtonContainer: {
    marginTop: 25,
    backgroundColor: Colors.semiGray,
  },
  continueButton: {
    backgroundColor: 'transparent',
    height: 48,
    paddingTop: 15,
    paddingBottom: 16,
    paddingLeft: 91,
    paddingRight: 92,
  },
  continueButtonText: {
    fontSize: 14,
    lineHeight: 17,
    height: 17,
    color: 'black',
    ...Styles.appleSDGothicNeoBold,
  },
});


class BlankResult extends React.Component {
  onPressContinueShopping = () => {
    const { router } = this.props;

    router.push('/shop');
  }

  render() {
    const {
      fullHeight = true,
      containerStyle = {},
      title, description = null, showContinueButton = true,
      iconType = 'logo',
      topPosition,
      header = null,
    } = this.props;

    let resultContainerStyle = [
      emptyStyles.container,
      containerStyle,
    ];
    if (!fullHeight) {
      resultContainerStyle = [emptyStyles.relativeContainer, containerStyle];
    }

    let textContainerMargin = {};
    let contentContainer = {};
    let icon = (<HeartitBlankPageLogo />);
    if (iconType === 'cart') {
      icon = (<EmptyCartIcon />);
      textContainerMargin = { marginTop: 15 };
      contentContainer = {
        marginTop: -(Constants.statusBarHeight + Constants.mainHeaderHeight),
      };
    }
    if (topPosition) {
      contentContainer.marginTop = topPosition;
      contentContainer.marginBottom = topPosition;
    }
    if (header) {
      contentContainer.marginTop = contentContainer.marginTop + 52;
      contentContainer.marginBottom = contentContainer.marginBottom + 52;
    }

    return (
      <View style={resultContainerStyle}>
        { header && (
          <View style={[emptyStyles.headerContainer, {top: topPosition}]}>
            { header }
          </View>
         )}
        <View style={[emptyStyles.contentContainer, contentContainer]}>
          <View style={emptyStyles.cartImage}>
            {icon}
          </View>
          <View style={[emptyStyles.alertTextContainer, textContainerMargin]}>
            <Text style={emptyStyles.alertTextLarge}>
              {title}
            </Text>
            { (description && description.length > 0) && (
              <Text style={emptyStyles.alertTextSmall}>
                {description}
              </Text>
            )}
          </View>
          { showContinueButton && (
            <View style={emptyStyles.continueButtonContainer}>
              <TouchableHighlight
                onPress={this.onPressContinueShopping}
                underlayColor={Colors.touchEffectColorBlack}
                style={emptyStyles.continueButton}
              >
                <Text style={emptyStyles.continueButtonText}>
                  쇼핑 계속하기
                </Text>
              </TouchableHighlight>
            </View>
          )}
        </View>
      </View>
    );
  }
}

export default BlankResult;
