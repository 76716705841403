// eslint-disable-next-line import/prefer-default-export
export function getNextPageUrl(response) {
  const link = response.headers.get('link');
  if (!link) {
    return null;
  }

  const nextLink = link.split(',').find(str => (str.indexOf('rel="next"') > -1));
  if (!nextLink) {
    return null;
  }

  return nextLink.split(';')[0].slice(1, -1);
}
