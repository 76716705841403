import React from 'react'
import { connect } from 'react-redux';
import { 
  StyleSheet, View,
  ScrollView, Text, TouchableOpacity, TouchableWithoutFeedback,
} from 'react-native';
import NumberFormat from 'react-number-format';

import { actions } from 'shared';

import {
  Styles, Constants, SizeData,
} from '../../../../constants';
import {
  SwitchDisableIcon,
  SwitchEnableIcon,
  SwitchEnableActiveIcon,
  ArrowRightIcon,
} from '../../../../icons-v2';

import FilterBaseModal from '../FilterBaseModal';
import TouchableHighlightWithContainer from '../../../TouchableHighlightWithContainer';
import showFilterBrandModal from '../FilterBrandModal';
import showFilterSizeModal from '../FilterSizeModal';
import showFilterColorModal from '../FilterColorModal';
import showFilterPriceModal from '../FilterPriceModal';


const styles = StyleSheet.create({
  container: {
  },
  itemContainer: {
    paddingHorizontal: Constants.viewHorizontalPadding,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemTitleContainer: {
  },
  itemTitle: {
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard400,
  },
  itemSubTitle: {
    marginTop: 3,
    fontSize: 13,
    lineHeight: 15.6,
    opacity: 0.5,
  },
  itemButtonContainer: {
    marginTop: 4,
  },
  submenuItemTitle: {
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard400,
  },
  submenuIcon: {
    marginTop: 6,
    marginEnd: 4,
  },
  filterItemListContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    paddingStart: Constants.viewHorizontalPadding,
    paddingEnd: Constants.viewHorizontalPadding - 10,
  },
  filterItemListItemContainer: {
    marginBottom: 10,
    marginEnd: 10,
  },
  filterItemContainer: {
    marginBottom: 9,
  },
  filterButtonContainer: {
    paddingVertical: 20,
  },
  filterItemButton: {
    paddingStart: 16,
    paddingEnd: 12,
    paddingVertical: 5.5,
    borderWidth: 0.5,
  },
  filterItemText: {
    fontSize: 15,
    lineHeight: 18,
    color: '#000000',
    ...Styles.pretendard600,
  },
});


class FilterMainModal extends FilterBaseModal {

  constructor(props) {
    // console.log('FilterMainModal.constructor');
    super(props);

    this.state.updateToggle = false;

    this.title = '필터';
    this.leftButtonTitle = '재설정';
  }

  getModalState() {
    const {
      modalProps,
    } = this.props;

    const {
      filterMainModalState = {
        applyMySize: false,
        applyFollowingBrands: false,
        filterItemList: [],
        mySize: {
          clothing: [],
          shoes: [],
        },
        priceMin: 0,
        priceMax: 10000000,
        followingBrands: [],
      },
    } = modalProps();

    // console.log(`filterMainModalState=`, filterMainModalState);

    return filterMainModalState;
  }

  setModalState(newState, callback) {
    // console.log(`setModalState newState=`, newState);
    const filterMainModalState = this.getModalState();

    const {
      setModalProps,
    } = this.props;

    const newFilterMainModalState = {
      ...filterMainModalState,
      ...newState,
    };
    // console.log(`setModalState newFilterMainModalState=`, newFilterMainModalState);

    setModalProps({
      filterMainModalState: newFilterMainModalState,
    }, () => {
      this.getModalState();

      callback && callback();
      const { updateToggle } = this.state;
      this.setState({ updateToggle: !updateToggle });
    });
  }

  componentDidMount() {
    // console.log(`FilterMainModal.componentDidMount`);

    const {
      auth,
      getFollowingBrandList,
      loadShopPriceRange,
      loadUser,
      brandList,
      filterList,
      logEventPageview,
    } = this.props;

    getFollowingBrandList()
      .then(result => {
        this.setModalState({ followingBrands: result.data }, () => {
          this.checkApplyingFollowingBrands()
        });
      });
    loadShopPriceRange()
      .then(result => {
        this.setModalState({
          priceMin: parseInt(result.data.minPrice),
          priceMax: parseInt(result.data.maxPrice),
        });
      });
    if (auth.hasOwnProperty('_id')) {
      loadUser(auth._id)
        .then(result => {
          this.setModalState({ mySize: result.mySize }, () => {
            this.checkApplyingMySize();
          });
        });
    }
    console.log(filterList);
    this.setModalState({ brandList, filterItemList: filterList });

    logEventPageview({ page_name: 'FilterMain' });
  }

  componentWillUnmount() {
    // console.log(`FilterMainModal.componentWillUnmount`);
  }

  onPressHeaderLeftButton() {
    this.setModalState({
      applyMySize: false,
      applyFollowingBrands: false,
      filterItemList: [],
    });
  }

  deleteFilterItem(id) {
    const { filterItemList } = this.getModalState();
    let newFilterItemList = [];

    filterItemList.forEach(item => {
      if (item.id != id) {
        newFilterItemList.push(item);
      }
    });

    // 운영상의 요청으로 products type 필터는 단독으로 적용할 수 없고, 유저에게 노출되지 않도록 수정
    // 마지막 유저 노출 필터가 삭제될 때 products type 필터도 함께 삭제 처리
    if (newFilterItemList.length === 1 && newFilterItemList[0].type === 'products') {
      newFilterItemList = [];
    }

    this.setModalState({filterItemList: newFilterItemList}, () => {
      this.checkApplyingMySize(() => {
        this.checkApplyingFollowingBrands();
      });
    });
  }

  checkApplyingMySize(callback) {
    const { filterItemList, mySize } = this.getModalState();
    const sizeFilterList = filterItemList.filter(f => f.type === 'size')

    // 내 사이즈의 값이 없을 때, 토글이 켜지는 것을 막기 위한 처리
    if (mySize.clothing.length === 0 && mySize.shoes.length === 0) {
      return;
    }

    let applyMySize = true;
  
    // console.log(`FitlerMainModal.checkApplyingMySize sizeFilterList=`, sizeFilterList);

    mySize.clothing.forEach(s => {
      // 내 사이즈 바로 적용으로 추가된 필터가 없으면
      if (!sizeFilterList.some(f => (f.subType === 'clothing' && f.name === s && f.addedByToggle === true))) {
        applyMySize = false;
      }
    });

    mySize.shoes.forEach(s => {
      // 내 사이즈 바로 적용으로 추가된 필터가 없으면
      if (!sizeFilterList.some(f => (f.subType === 'shoes' && f.name === s && f.addedByToggle === true))) {
        applyMySize = false;
      }
    });

    // console.log(`applyMySize=${applyMySize}`);

    this.setModalState({ applyMySize }, callback);
  }

  toggleApplyingMySize() {
    let { filterItemList } = this.getModalState();
    const { applyMySize, mySize } = this.getModalState();
    const sizeList = SizeData.SIZE_LIST;

    // 내 사이즈의 값이 없을 때, 토글이 켜지는 것을 막기 위한 처리
    if (mySize.clothing.length === 0 && mySize.shoes.length === 0) {
      return;
    }

    
    if (applyMySize) {
      mySize.clothing.forEach(s => {
        filterItemList = filterItemList.filter(f => (f.type !== 'size' || f.subType !== 'clothing' || f.name !== s || f.addedByToggle !== true))
      });
      mySize.shoes.forEach(s => {
        filterItemList = filterItemList.filter(f => (f.type !== 'size' || f.subType !== 'shoes' || f.name !== s || f.addedByToggle !== true))
      });
    } else {
      mySize.clothing.forEach(s => {
        // 이미 추가된 필터도 토글로 추가하는 필터도 덮어 쓰기 위해 삭제
        filterItemList = filterItemList.filter(f => (f.type !== 'size' || f.subType !== 'clothing' || f.name !== s));

        const t = sizeList.find(f => (f.type === 'size' && f.subType === 'clothing' && f.name === s))
        if (!t)
          return;

        const newFilter = { ...t, addedByToggle: true };
        filterItemList.push(newFilter);
      });

      mySize.shoes.forEach(s => {
        // 이미 추가된 필터도 토글로 추가하는 필터도 덮어 쓰기 위해 삭제
        filterItemList = filterItemList.filter(f => (f.type !== 'size' || f.subType !== 'shoes' || f.name !== s));

        const t = sizeList.find(f => (f.type === 'size' && f.subType === 'shoes' && f.name === s))
        if (!t)
          return;

        const newFilter = { ...t, addedByToggle: true };
        filterItemList.push(newFilter);
      });
    }

    this.setModalState({
      applyMySize: !applyMySize,
      filterItemList: filterItemList,
    });
  }

  checkApplyingFollowingBrands() {
    const { filterItemList, followingBrands } = this.getModalState();
    const brandFilterList = filterItemList.filter(f => f.type === 'brand')

    let applyFollowingBrands = true;

    followingBrands.forEach(b => {
      // 내가 팔로우하는 브랜드 바로 적용으로 추가된 필터가 없으면
      if (!brandFilterList.forEach(f => (f.id === b.brandCd && f.addedByToggle === true))) {
        applyFollowingBrands = false;
      }
    })

    this.setModalState({ applyFollowingBrands });
  }

  toggleApplyingFollowingBrands() {
    // console.log(`toggleApplyingFollowingBrands`);
    let { filterItemList } = this.getModalState();
    const { applyFollowingBrands, followingBrands } = this.getModalState();

    if (applyFollowingBrands) {
      followingBrands.forEach(b => {
        filterItemList = filterItemList.filter(f => (f.type !== 'brand' || f.id !== b.brandCd))
      });
    } else {
      followingBrands.forEach(b => {
        filterItemList = filterItemList.filter(f => (f.type !== 'brand' || f.id !== b.brandCd))
        filterItemList.push({
          id: b.brandCd,
          type: 'brand',
          name: b.brandName,
          addedByToggle: true,
        });
      });
    }
    this.setModalState({
      applyFollowingBrands: !applyFollowingBrands,
      filterItemList: filterItemList,
    });
  }

  onPressApplyButton() {
    const {
      onChange,
      setModalProps,
    } = this.props;
    const { filterItemList } = this.getModalState();

    onChange(filterItemList);

    setModalProps({
      isVisible: false,
      children: null,
    });
  }

  isLoggedIn() {
    const { auth } = this.props;
    // eslint-disable-next-line no-prototype-builtins
    return auth.hasOwnProperty('_id');
  }

  renderFilterItemList() {
    const { filterItemList } = this.getModalState();

    // console.log(`renderFilterItemList ${filterItemList}`);

    if (filterItemList.length == 0) {
      return;
    }

    const renderItem = ({ item }) => {
      const children = [];

      if (item.type === 'price') {
        children.push((
          <NumberFormat
            key='min'
            value={item.min}
            displayType="text"
            thousandSeparator
            renderText={value => (
              <Text>{value}원</Text>
            )}
            />
        ))
        children.push('~');
        children.push((
          <NumberFormat
            key='max'
            value={item.max}
            displayType="text"
            thousandSeparator
            renderText={value => (
              <Text key='max'>{value}원</Text>
            )}
            />
        ))
      } else {
        children.push(`${item.name}`);
      }

      children.push(' X');

      return (
        <TouchableOpacity
          style={styles.filterItemButton}
          onPress={() => this.deleteFilterItem(item.id)}
        >
          <Text style={styles.filterItemText} children={children} />
        </TouchableOpacity>
      )};

    return (
      <View style={styles.filterItemListContainer}>
        {/*
           운영상의 요청으로 products type 필터는 단독으로 적용할 수 없고, 유저에게 노출되지 않도록 수정
        */}
        {filterItemList.filter(f => f.type !== 'products').map((item, index) => {
          return (
            <View
              key={`${index}`}
              style={styles.filterItemListItemContainer}
            >
              {renderItem({ item })}
            </View>
          );
        })}
      </View>
    );
  }

  onChangeSelectedSubItem = (type) => (selectedItemList) => {

    // console.log(`onChangeSelectedSubItem ${type} ${JSON.stringify(selectedItemList)}`);

    const { filterItemList } = this.getModalState();
    const newFilterItemList = filterItemList.filter((item) => item.type !== type);

    selectedItemList.forEach((item) => newFilterItemList.push(item));
    this.setModalState({ filterItemList: newFilterItemList }, () => {
      // console.log(this.getModalState().filterItemList, 'filterItemList');
      this.checkApplyingMySize(() => {
        this.checkApplyingFollowingBrands();
      });
    });
  }

  onPressBrand = () => {
    const {
      modalProps,
    } = this.props;
    // console.log(`FilterMainModal.onPressBrand modalProps=${JSON.stringify(modalProps())}`);

    const { filterItemList, brandList } = this.getModalState();
    const selectedBrandList = filterItemList.filter((value) => value.type === 'brand');

    // console.log(`FilterMainModal.onPressBrand brandList=${JSON.stringify(brandList)}`);
    // console.log(`FilterMainModal.onPressBrand selectedBrandList=${JSON.stringify(selectedBrandList)}`);

    showFilterBrandModal(this, brandList, selectedBrandList, this.onChangeSelectedSubItem('brand').bind(this));
  }

  onPressSize = () => {
    const { filterItemList } = this.getModalState();
    const selectedSizeList = filterItemList.filter((value) => value.type === 'size');
    showFilterSizeModal(this, selectedSizeList, this.onChangeSelectedSubItem('size').bind(this))
  }

  onPressColor = () => {
    const { filterItemList } = this.getModalState();
    const selectedColorList = filterItemList.filter((value) => value.type === 'color');
    showFilterColorModal(this, selectedColorList, this.onChangeSelectedSubItem('color').bind(this));
  }

  onPressPrice = () => {
    // const { navigation } = this.props;
    const { filterItemList, priceMin, priceMax } = this.getModalState();
    const priceFilterList = filterItemList.filter((value) => value.type === 'price');
    const priceFilter = priceFilterList.length > 0 && priceFilterList[0];
    // navigateToFilterPriceScreen(navigation, { priceMin, priceMax }, priceFilter, this.onChangeSelectedSubItem('price'));
    showFilterPriceModal(this, priceMin, priceMax, priceFilter, this.onChangeSelectedSubItem('price').bind(this));
  }

  renderBody() {

    const {
      navigation,
    } = this.props;

    const {
      applyMySize, applyFollowingBrands,
      mySize, followingBrands,
    } = this.getModalState();

    // console.log(`FilterMainModal.renderBody applyMySize=`, applyMySize);

    let sizeText = '';
    if (mySize.clothing.length > 0) {
      sizeText += '옷: ' + mySize.clothing.join(', ');
    } else {
      sizeText += '옷: 선택한 사이즈 없음';
    }
    if (mySize.shoes.length > 0) {
      sizeText += '\n신발: ' + mySize.shoes.join(', ');
    } else {
      sizeText += '\n신발: 선택한 사이즈 없음';
    }

    let brandText = '';
    if (followingBrands.length == 0) {
      brandText = '팔로우한 브랜드 없음';
    } else {
      brandText = followingBrands.slice(0, 5).reduce((prev, curr) => {
        prev.push(curr.brandName);
        return prev;
      }, []);
      if (followingBrands.length > 5) {
        brandText = brandText.concat(' 외 ', followingBrands.length - 5, '개');
      }
      brandText = brandText.join(', ');
    }

    const submenuItems = [];
    submenuItems.push({ name: '브랜드', onPress: this.onPressBrand.bind(this) });
    submenuItems.push({ name: '사이즈', onPress: this.onPressSize.bind(this) });
    submenuItems.push({ name: '색상', onPress: this.onPressColor.bind(this) });
    submenuItems.push({ name: '가격', onPress: this.onPressPrice.bind(this) });

    return (
      <ScrollView
        style={styles.container}
        >
        <View style={{ height: 20 }} />

        { this.renderFilterItemList() }

        <View style={{ height: 15 }} />

        <View style={styles.itemContainer}>
          <View style={styles.itemTitleContainer}>
            <Text style={styles.itemTitle}>내 사이즈 바로 적용</Text>
            { (this.isLoggedIn()) ? (
              <Text style={styles.itemSubTitle}>{sizeText}</Text>
              ) : (
              <Text style={styles.itemSubTitle}>로그인 시 사용 가능</Text>
            )}
          </View>
          <View style={styles.itemButtonContainer}>
            <TouchableWithoutFeedback
              onPress={() => this.toggleApplyingMySize()}
              disabled={!this.isLoggedIn()}
              >
                <View>
                  {(!this.isLoggedIn()) ? (
                    <SwitchDisableIcon />
                  ) : (
                    applyMySize
                  ) ? (
                    <SwitchEnableActiveIcon />
                  ) : (
                    <SwitchEnableIcon />
                  )}
                </View>
            </TouchableWithoutFeedback>
          </View>
        </View>

        <View style={{ height: 30 }} />

        <View style={styles.itemContainer}>
          <View style={styles.itemTitleContainer}>
            <Text style={styles.itemTitle}>내가 팔로우하는 브랜드 바로 적용</Text>
            <Text style={styles.itemSubTitle}>{brandText}</Text>
          </View>
          <TouchableWithoutFeedback onPress={() => this.toggleApplyingFollowingBrands()}>
            <View>
              {(applyFollowingBrands) ? (
                <SwitchEnableActiveIcon />
              ) : (
                <SwitchEnableIcon />
              )}
            </View>
          </TouchableWithoutFeedback>
        </View>

        <View style={{ height: 20 }} />

        {submenuItems.map((item, index) => (
          <View key={`submenu_${index}`} style={styles.filterItemContainer}>
            <TouchableHighlightWithContainer onPress={item.onPress}>
              <View style={[styles.itemContainer, styles.filterButtonContainer]}>
                <View style={styles.itemTitleContainer}>
                  <Text style={styles.submenuItemTitle}>{item.name}</Text>
                </View>
                <View style={styles.submenuIcon}>
                  <ArrowRightIcon />
                </View>
              </View>
            </TouchableHighlightWithContainer>
          </View>
        ))}

      </ScrollView>
    )
  }
}


export default connect(
  state => ({ auth: state.auth }),
  actions,
)(FilterMainModal);
