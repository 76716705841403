import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  root: {
    zIndex: "2000 !important",
  },
});


function AlertDialog(props) {
  const classes = useStyles();
  const { 
    open, title, content, buttons, titleStyle,
  } = props;
  // const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    // setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        root: classes.root
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <span style={{ fontSize: 16, ...titleStyle }}>{title}</span>
      </DialogTitle>
      {content && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ fontSize: 16, whiteSpace: 'pre-line' }}>
            {content}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {buttons.map(({ text, onPress }) => (
          <Button key={text} onClick={onPress} color="primary">
            {text}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
}

export function initAlert(parentComponent) {
  const { state = {} } = parentComponent;

  state.alert = {
    open: false,
    title: null,
    content: null,
    buttons: [],
    titleStyle: {},
  };

  parentComponent.state = state;

  parentComponent.alert = showAlert(parentComponent);
}

export const showAlert = parentComponent => (msg, props={}) => {
  const { 
    buttons=[
      {
        text: '확인',
        onPress: () => {
        },
      },
    ],
    content=null,
    titleStyle={},
  } = props;

  

  buttons.forEach(button => {
    const callback = button.onPress;
    const onPress = () => {
      parentComponent.setState({ alert: { open: false } }, callback);
    }
    button.onPress = onPress;
  })

  parentComponent.setState({
    alert: {
      open: true,
      title: msg,
      content,
      buttons,
      titleStyle,
    },
  });
}

export function renderAlert(parentComponent) {
  const {
    alert: {
      open,
      title,
      content,
      buttons,
      titleStyle,
    },
  } = parentComponent.state;

  return (
    <div>
      <AlertDialog
        open={open}
        title={title}
        content={content}
        buttons={buttons}
        titleStyle={titleStyle}
      />
    </div>
  );
}

export default AlertDialog;
