import React from 'react';
import { Dimensions, StyleSheet, View, ActivityIndicator } from 'react-native';

import { Constants, Styles, Colors } from '../../../constants';

import {
  CartItem
} from '../../../components-v2';


const {
  height: windowHeight,
} = Dimensions.get('window');


const styles = StyleSheet.create({
  container: {
  },
  itemDivider: {
    height: 0.5,
    backgroundColor: Colors.defaultGray02,
    marginHorizontal: Constants.viewHorizontalPadding,
  },
  loadingIndicatorContainer: {
    height: 200,
    backgroundColor: '#00000020',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

class ItemList extends React.Component {

  renderItem(item) {
    const {
      loadData,
      checkedItems,
      onPressCheckItem,
      onPressDeleteItem,
      onPressCheckoutItem,
      setModalProps,
      modalProps,
    } = this.props;
    const isChecked = checkedItems.indexOf(item.sno) >= 0;

    return (
      <CartItem
        key={item.sno}
        isChecked={isChecked}
        item={item}
        onPressCheckItem={onPressCheckItem(item)}
        onPressDeleteItem={onPressDeleteItem(item)}
        onPressCheckoutItem={onPressCheckoutItem(item)}
        showTopControl
        showBottomControl
        showLikeButton
        loadData={loadData}
        setModalProps={setModalProps}
        modalProps={modalProps}
      />
    );
  }

  render() {
    const { itemListInCart, isFetchingData } = this.props;

    // console.log('CartScreen itemListInCart', itemListInCart);

    const isLoadingIndicatorContainerVisible = isFetchingData && (itemListInCart.length === 0);

    const itemList = itemListInCart.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <View key={index}>
        {index > 0 && <View style={styles.itemDivider} />}
        {this.renderItem(item)}
      </View>
    ));

    return (
      <View style={styles.container}>
        { isLoadingIndicatorContainerVisible && (
          <View style={styles.loadingIndicatorContainer}>
            <ActivityIndicator color="gray" size="large" />
          </View>
        )}
        {itemList}
      </View>
    );
  }
}

export default ItemList;
