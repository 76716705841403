import React from 'react';
import PropTypes from 'prop-types';
import {
    StyleSheet, View, Text, TextInput,
} from 'react-native';


import { Styles, Colors } from '../constants';
import TouchableHighlightWithContainer from './TouchableHighlightWithContainer';
import {
  PasswordHideIcon,
  PasswordShowIcon,
} from '../icons-v2';


const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  inputTitleText: {
    ...Styles.pretendard400,
    fontSize: 14,
    lineHeight: 14,
    opacity: 0.5,
  },
  inputFormContainer: {
    flexDirection: 'row',
  },
  textInputBoxContainer: {
    flex: 1,
  },
  textInputContainer: {
    justifyContent: 'center',
  },
  inputForm: {
    ...Styles.pretendard400,
    fontSize: 16,
    color: '#000000',
    padding: 0, // Android에 자동으로 입력되는 패딩을 제거
    lineHeight: 19.2,
    height: 19 + 16 + 9,
    paddingTop: 16,
    paddingBottom: 9,
    outlineStyle: 'none',
  },
  inputDescription: {
    ...Styles.pretendard400,
    fontSize: 12,
    lineHeight: 14,
    opacity: 0.5,
    marginTop: 8,
  },
  inputInfoMsgText: {
    ...Styles.pretendard400,
    fontSize: 12,
    lineHeight: 14,
    color: Colors.primaryBlue,
    marginTop: 8,
  },
  inputErrorMsgText: {
    ...Styles.pretendard400,
    fontSize: 12,
    lineHeight: 14,
    color: Colors.errorText,
    marginTop: 8,
  },
  passwordIcon: {
    position: 'absolute',
    end: 6,
    bottom: 3,
  },
  itemSeparator: {
    height: 0.5,
    backgroundColor: '#000000',
  },
  rightButtonContainer: {
    marginStart: 10,
    alignSelf: 'flex-end',
  },
  rightButton: {
    borderWidth: 0.5,
    borderColor: '#000000',
    justifyContent: 'center',
  },
  rightButtonText: {
    ...Styles.pretendard600,
    fontSize: 14,
    lineHeight: 16.8,
    paddingVertical: 11.5,
    textAlign: 'center',
  },
});


class InputForm extends React.Component {

  state = {
    isPasswordHidden: true,
    errorMessage: '',
    infoMessage: '',
  };
  inputRef = null;

  onInputRef = ref => {
    this.inputRef = ref;

    const { onInputRef } = this.props;
    onInputRef(this);
  }

  focus() {
    if (!this.inputRef) {
      return;
    }

    this.inputRef.focus();
  }

  validate() {
    const { value, type, minLength, required, title } = this.props;

    if (required && (!value || value === '')) {
      this.setState({ errorMessage: `${title} 입력은 필수입니다.`, infoMessage: '' });
      return false;
    }

    // Validate Email Address
    if (type === 'email') {
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if (regex.test(value) === false) {
        this.setState({ errorMessage: '올바른 형식의 이메일을 입력해주세요', infoMessage: '' });
        return false;
      }  
    }

    if (minLength > 0 && value.length < minLength) {
      this.setState({ errorMessage: `${minLength}자 이상 입력해주세요.`, infoMessage: '' });
      return false;
    }

    if (type === 'password') {
      let regex1 = /(?=.*[A-Za-z])/;
      if (regex1.test(value) === false) {
        this.setState({ errorMessage: '영문, 숫자, 특수문자 조합으로 입력해주세요.', infoMessage: '' });
        return false;
      }  

      let regex2 = /(?=.*[0-9])/;
      if (regex2.test(value) === false) {
        this.setState({ errorMessage: '영문, 숫자, 특수문자 조합으로 입력해주세요.', infoMessage: '' });
        return false;
      }  

      let regex3 = /(?=.[!@#$%^&])/;
      if (regex3.test(value) === false) {
        this.setState({ errorMessage: '영문, 숫자, 특수문자 조합으로 입력해주세요.', infoMessage: '' });
        return false;
      }  
    }

    if (type === 'phone') {
      let regex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
      if (regex.test(value) === false) {
        this.setState({ errorMessage: '휴대폰 번호 형식을 확인하세요.' });
        return false;
      }
    }

    this.setState({ errorMessage: '', infoMessage: '입력 완료' });
    return true;
  }

  renderPasswordHiddenButton() {

    const {
      type
    } = this.props;

    if (type !== 'password') {
      return null;
    }

    const {
      isPasswordHidden,
    } = this.state;

    return (
      <TouchableHighlightWithContainer
        onPress={() => this.setState({ isPasswordHidden: !isPasswordHidden })}
        containerStyle={styles.passwordIcon}
      >
        {isPasswordHidden ? (<PasswordHideIcon />) : (<PasswordShowIcon />)}
      </TouchableHighlightWithContainer>
    )
  }

  renderRightButton() {
    const {
      rightButtonTitle,
      onPressRightButton,
      rightButtonStyle,
      disableRightButton,
    } = this.props;

    if (!rightButtonTitle) {
      return null;
    }
  
    const rightButtonContainerStyles = [
      styles.rightButtonContainer,
    ];

    return (
      <View style={rightButtonContainerStyles}>
        <TouchableHighlightWithContainer
          onPress={onPressRightButton}
          style={[styles.rightButton, rightButtonStyle]}
          disabled={disableRightButton}
          >
          <Text style={styles.rightButtonText}>{rightButtonTitle}</Text>
        </TouchableHighlightWithContainer>
      </View>
    )
  }

  render() {
    const {
      type,
      title,
      placeholder,
      description,
      keyboardType,
      onChangeText,
      onSubmitEditing,
      value,
      enableErrorMessage,
      errorMessage: errorMessageFromProps,
      enableInfoMessage,
      infoMessage: infoMessageFromProps,
      editable,
    } = this.props;

    const {
      errorMessage: errorMessageFromState,
      infoMessage: infoMessageFromState,
      isPasswordHidden,
    } = this.state;

    const props = {};

    if (type === 'password') {
      props.secureTextEntry = isPasswordHidden;
    }

    const inputTitleTextStyles = [styles.inputTitleText];
    const itemSeparatorStyles = [styles.itemSeparator];

    let errorMessage = errorMessageFromProps || errorMessageFromState;
    if (errorMessage !== '') {
      itemSeparatorStyles.push({
        backgroundColor: Colors.errorText,
      })
    }
    let infoMessage = infoMessageFromProps || infoMessageFromState;
    if (infoMessage !== '') {
      itemSeparatorStyles.push({
        backgroundColor: Colors.primaryBlue,
      })
    }

    return (
      <View style={styles.container}>
        { title && (
          <Text style={inputTitleTextStyles}>{title}</Text>
        )}
        <View style={styles.inputFormContainer}>
          <View style={styles.textInputBoxContainer}>
            <View style={styles.textInputContainer}>
              <TextInput
                ref={this.onInputRef}
                autoCapitalize='none'
                keyboardType={keyboardType}
                onChangeText={onChangeText}
                placeholder={placeholder}
                placeholderTextColor='#AEAEB2'
                style={styles.inputForm}
                value={value}
                blurOnSubmit={false}
                onSubmitEditing={onSubmitEditing}
                editable={editable}
                {...props}
                />
              {this.renderPasswordHiddenButton()}
            </View>
            <View style={itemSeparatorStyles} />
          </View>
          {this.renderRightButton()}
        </View>
        { !errorMessage && !infoMessage && description && description !== '' && (
          <Text style={styles.inputDescription}>{description}</Text>
        )}
        { infoMessage !== '' && enableInfoMessage && (
          <Text style={styles.inputInfoMsgText}>{infoMessage}</Text>
        )}
        { errorMessage !== '' && enableErrorMessage && (
          <Text style={styles.inputErrorMsgText}>{errorMessage}</Text>
        )}
      </View>
    );
  }
}

InputForm.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  onInputRef: PropTypes.func,
  onChangeText: PropTypes.func,
  onSubmitEditing: PropTypes.func,
  keyboardType: PropTypes.string,
  enableErrorMessage: PropTypes.bool,
  minLength: PropTypes.number,
  rightButtonTitle: PropTypes.string,
  onPressRightButton: PropTypes.func,
  required: PropTypes.bool,
  disableRightButton: PropTypes.bool,
};

InputForm.defaultProps = {
  type: 'string',
  value: '',
  title: null,
  onInputRef: ref => null,
  onChangeText: value => null,
  onSubmitEditing: () => null,
  keyboardType: 'default',
  enableErrorMessage: false,
  minLength: 0,
  rightButtonTitle: null,
  onPressRightButton: () => null,
  required: false,
  disableRightButton: false,
};

export default InputForm;
