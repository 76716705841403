import assign from 'lodash/assign';
import get from 'lodash/get';
import { normalize } from 'normalizr';
import qs from 'qs';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';
import { getNextPageUrl } from '../util';


export function loadPost(postId) {
  return createFetchAction({
    type: 'LOAD_POST',
    endpoint: `/api/v2/posts/${postId}`,
    transform: ({ json }) => normalize(json, schemas.post),
  });
}

export function loadFeatured(nextPage, query) {
  const key = 'featured';
  return createFetchAction({
    type: 'LOAD_FEATURED',
    endpoint: state => {
      const base = `/api/v2/posts/featured?${qs.stringify(query)}`;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    transform: ({ json, response }) => assign(normalize(json, schemas.posts), {
      nextPageUrl: getNextPageUrl(response),
    }),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}

export function loadPromotion() {
  return createFetchAction({
    type: 'LOAD_PROMOTION',
    endpoint: '/api/v2/posts/promotion',
    transform: ({ json }) => normalize(json, schemas.promotions),
  });
}

export function loadCollections() {
  return createFetchAction({
    type: 'LOAD_COLLECTION',
    endpoint: '/api/v2/posts/collection',
    transform: ({ json }) => normalize(json, schemas.collections),
  });
}

export function loadIntroFeatured(nextPage) {
  const key = 'featured';
  return createFetchAction({
    type: 'LOAD_INTRO_FEATURED',
    endpoint: state => {
      const base = '/api/v2/posts/introFeatured';
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    transform: ({ json }) => normalize(json, schemas.posts),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}

export function loadLiked(nextPage, query) {
  const key = 'liked';
  return createFetchAction({
    type: 'LOAD_LIKED',
    endpoint: state => {
      const base = `/api/v2/posts/liked?${qs.stringify(query)}`;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    transform: ({ json, response }) => assign(normalize(json, schemas.posts), {
      nextPageUrl: getNextPageUrl(response),
    }),
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}
