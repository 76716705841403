import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  adminLoadPostsFeatured,
  adminDeletePost,
  adminUpdatePost,
  adminUpdateFeaturedList,
} from 'react-shared/adminActions';
import { loadEntities } from 'react-shared/util';
import { Link } from 'react-router';
import ThumbnailList from 'components/admin/ThumbnailList';
import Spinner from 'components/Spinner';

class FeaturedPostPage extends Component {
  constructor(props) {
    super(props);
    this.renderCaption = this.renderCaption.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.renderCaption = this.renderCaption.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    this.props.adminLoadPostsFeatured();
    window.addEventListener('scroll', () => this.handleScroll());
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => this.handleScroll());
  }

  static getMedia(post) {
    return post.media[0];
  }

  handleLoadMore() {
    this.props.adminLoadPostsFeatured(true);
  }

  handleScroll() {
    if (window.pageYOffset > document.body.clientHeight - window.innerHeight - 1
        && !this.props.pagination.isFetching && this.props.pagination.nextPageUrl) {
      this.handleLoadMore();
    }
  }

  handleUpdate(items, oldIndex, newIndex) {
    const lastIndex = items.length - 1;
    function median(index1, index2) {
      return (items[index1].featured.score + items[index2].featured.score) / 2;
    }
    this.props.adminUpdateFeaturedList(items.map(item => item._id));
    const postId = items[newIndex]._id;
    if (newIndex === 0) {
      this.props.adminUpdatePost(postId, {
        featured: { score: items[1].featured.score + 1 },
      });
    } else if (items.length === 2) {
      this.props.adminUpdatePost(items[0]._id, {
        featured: { score: items[1].featured.score },
      });
      this.props.adminUpdatePost(items[1]._id, {
        featured: { score: items[0].featured.score },
      });
    } else if (newIndex === lastIndex) {
      this.props.adminUpdatePost(postId, {
        featured: { score: items[lastIndex - 1].featured.score },
      });
      this.props.adminUpdatePost(items[lastIndex - 1]._id, {
        featured: { score: median(lastIndex - 2, lastIndex - 1) },
      });
    } else {
      this.props.adminUpdatePost(postId, {
        featured: { score: median(newIndex - 1, newIndex + 1) },
      });
    }
  }

  renderCaption(post) {
    return (
      <div>
        <p>{post.owner.username}</p>
        <Link className="btn btn-default" to={`/admin/posts/${post._id}`}>
          <span className="glyphicon glyphicon-edit"></span>
        </Link>
        <button
          type="button"
          className="btn btn-default"
          onClick={
            () => confirm('Are you sure to delete this post?') &&  //eslint-disable-line
                  this.props.adminDeletePost(post._id)
          }
        >
          <span className="glyphicon glyphicon-trash"></span>
        </button>
      </div>
    );
  }

  render() {
    const { pagination: { isFetching }, posts, router } = this.props;
    router.replace('/admin/stylePosts');

    return (
      <div className="container">
        <ThumbnailList
          getMedia={FeaturedPostPage.getMedia}
          items={posts}
          renderCaption={this.renderCaption}
          onUpdate={this.handleUpdate}
          // onLoadMore={this.handleLoadMore.bind(this)}
          // {...pagination}
          isSortable={false}
        />
        {isFetching && <Spinner />}
      </div>
    );
  }
}

FeaturedPostPage.propTypes = {
  posts: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  adminLoadPostsFeatured: PropTypes.func.isRequired,
  adminDeletePost: PropTypes.func.isRequired,
  adminUpdatePost: PropTypes.func.isRequired,
  adminUpdateFeaturedList: PropTypes.func.isRequired,
};

export default connect(
  state => loadEntities(state, 'featured', 'posts'),
  { adminLoadPostsFeatured, adminDeletePost, adminUpdatePost, adminUpdateFeaturedList }
)(FeaturedPostPage);
