import React from 'react';
import {
    StyleSheet, View, Text
} from 'react-native';

import { Styles } from '../constants';

import SelectorBase from '../containers-v2/auth/JoinDonePage/SelectorBase';


const styles = StyleSheet.create({
  container: {
    paddingTop: 16,
    paddingBottom: 9,
    borderBottomWidth: 0.5,
    borderBottomColor: '#000',
  },
  title: {
    ...Styles.pretendard400,
    fontSize: 14,
    lineHeight: 14,
    opacity: 0.5,
    marginBottom: 16,
  },
});

class BirthdaySelector extends SelectorBase {

  title = '생년월일 입력';
  placeholder = '생년월일을 입력해주세요';
  state = {
    type: 'text',
  }

  render() {
    const {
      date,
      onChangeDate,
    } = this.props;

    const {
      type
    } = this.state;

    let value = date;
    if (value) {
      if (type === 'date') {
        value = value.replaceAll('.', '-');
      } else if (type === 'text') {
        value = value.replace('.', '년 ');
        value = value.replace('.', '월 ');
        value += '일';
      }
    }

    const onFocus = e => {
      // console.log('BirthdaySelector.onFocus e=', e);
      this.setState({ type: 'date' });
    }

    const onBlur = e => {
      // console.log('BirthdaySelector.onBlur e=', e);
      this.setState({ type: 'text' });
    }

    const onChange = e => {
      let value = e.target.value;
      // console.log('BirthdaySelector.onChange value=', value);

      if (type === 'date') {
        value = value.replaceAll('-', '.');
      } else if (type === 'text') {
        value = value.replace('년 ', '.');
        value = value.replace('월 ', '.');
        value = value.replace('일', '');
      }

      onChangeDate(value);
    }

    return (
      <View style={[styles.container]}>
        <Text style={styles.title}>{this.title}</Text>
        <input
          // type="text"
          type={type}
          style={{
            borderWidth: 0,
            padding: 0,
            outline: 'none',
          }}
          value={value}
          onChange={onChange}
          placeholder={this.placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </View>
    )
  }
}

export default BirthdaySelector;
