/* eslint-disable no-console */
import assign from 'lodash/assign';
import get from 'lodash/get';
import set from 'lodash/set';
import merge from 'lodash/merge';
import { normalize } from 'normalizr';
import qs from 'qs';

import createFetchAction from '../util/createFetchAction';
import createFetchActionWithShopAuth from '../util/createFetchActionWithShopAuth';
import * as schemas from '../schemas';
import { getNextPageUrl } from '../util';


export function registerPushToken(pushToken, playerId) {
  return createFetchAction({
    type: 'REGISTER_PUSH_TOKEN',
    endpoint: '/api/v2/users/registerPushToken',
    method: 'POST',
    body: {
      pushToken,
      playerId,
      subscribed: true,
    },
  });
}

export function getFollowStatus(userId) {
  const key = 'followingHeartists';

  return createFetchAction({
    type: 'GET_FOLLOW_STATUS',
    endpoint: `/api/v2/users/${userId}/follow`,
    method: 'GET',
    transform: ({ json }) => {
      if (Object.prototype.hasOwnProperty.call(json, 'status') && json.status !== 200) {
        //console.error('GET_FOLLOW_STATUS FAILED', json);
        return {};
      }

      return normalize({ _id: userId, following: json.following }, schemas.heartist);
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: 'APPEND' },
      meta: { entities: { update: true } },
    },
  });
}

export function followUser(userId) {
  const key = 'followingHeartists';

  return createFetchAction({
    type: 'FOLLOW_USER',
    endpoint: `/api/v2/users/${userId}/follow`,
    method: 'POST',
    transform: ({ json }) => {
      if (Object.prototype.hasOwnProperty.call(json, 'status') && json.status !== 200) {
        //console.error('FOLLOW FAILED', json);
        return {};
      }

      return normalize({ _id: userId, following: true }, schemas.heartist);
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: 'APPEND' },
      meta: { entities: { update: true } },
    },
  });
}

export function unfollowUser(userId) {
  const key = 'followingHeartists';

  return createFetchAction({
    type: 'UNFOLLOW_USER',
    endpoint: `/api/v2/users/${userId}/follow`,
    method: 'DELETE',
    transform: ({ json }) => {
      if (Object.prototype.hasOwnProperty.call(json, 'status') && json.status !== 200) {
        //console.error('UNFOLLOW FAILED', json);
        return {};
      }

      return normalize({ _id: userId, following: false }, schemas.heartist);
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: 'APPEND' },
      meta: { entities: { update: true } },
    },
  });
}

export function updateUser(userId, profile) {
  return createFetchActionWithShopAuth({
    type: 'UPDATE_USER',
    endpoint: `/api/v2/users/${userId}`,
    method: 'PUT',
    body: profile,
    transform: ({ json, state }) => {
      const ret = normalize(json, schemas.user);
      if (state.auth._id === userId) {
        const { ...newAuth } = state.auth;
        merge(newAuth, json);
        ret.auth = newAuth;
      }
      return ret;
    },
  });
}

export function deleteUser(userId) {
  return createFetchAction({
    type: 'DELETE_USER',
    endpoint: `/api/v2/users/${userId}`,
    method: 'DELETE',
  });
}

export function loadUsers() {
  return createFetchAction({
    type: 'LOAD_USERS',
    endpoint: '/api/v2/users',
    transform: ({ json }) => normalize(json, schemas.users),
    success: {
      pagination: { key: 'users', type: 'REFRESH' },
    },
  });
}

export function loadHeartists(nextPage) {
  const key = 'heartists';
  return createFetchAction({
    type: 'LOAD_HEARTISTS',
    endpoint: state => {
      const base = '/api/v2/users/heartists';
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    transform: ({ json }) => normalize(json, schemas.heartists),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
      meta: { entities: { update: true } },
    },
  });
}

export function loadFollowerHeartists(nextPage) {
  const key = 'followers';
  return createFetchAction({
    type: 'LOAD_FOLLOWER_HEARTISTS',
    endpoint: '/api/v2/users/heartists/followed',
    transform: ({ json }) => normalize(json, schemas.followers),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
      meta: { entities: { update: true } },
    },
  });
}

export function loadFollowingHeartists(nextPage) {
  const key = 'followingHeartists';
  return createFetchAction({
    type: 'LOAD_FOLLOWING_HEARTISTS',
    endpoint: '/api/v2/users/heartists/following',
    transform: ({ json }) => {
      json.forEach(heartist => {
        heartist.following = true;
      });

      return normalize(json, schemas.heartists);
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
      meta: { entities: { update: true } },
    },
  });
}

export function loadHeartistsPicks() {
  return createFetchAction({
    type: 'LOAD_HEARTISTS_PICK',
    endpoint: '/api/v2/users/heartists/picked',
    transform: ({ json }) => normalize(json, schemas.heartistsPicks),
  });
}

export function updateHeartist(userId, body) {
  return createFetchAction({
    type: 'UPDATE_HEARTIST',
    endpoint: `/api/v2/users/${userId}`,
    method: 'PUT',
    body,
    transform: ({ json }) => normalize(json, schemas.heartist),
    success: {
      meta: { entities: { update: true } },
    },
  });
}

export function loadUsersPostsCount(userId) {
  return createFetchAction({
    type: 'LOAD_USERS_POST_COUNT',
    endpoint: `/api/v2/users/${userId}/posts/count`,
    transform: ({ json }) => set({}, `entities.usersPostsCount.${userId}`, json),
  });
}

export function loadCreators() {
  return createFetchAction({
    type: 'LOAD_CREATORS',
    endpoint: '/api/v2/users?role=creator',
    transform: ({ json }) => normalize(json, schemas.users),
    success: {
      pagination: { key: 'creators', type: 'REFRESH' },
    },
  });
}

export function loadUserByName(username) {
  return createFetchAction({
    type: 'LOAD_USER',
    endpoint: `/api/v2/users?username=${username}`,
    transform: ({ json }) => normalize(json, schemas.user),
  });
}

export function loadUser(userId) {
  return createFetchAction({
    type: 'LOAD_USER',
    endpoint: `/api/v2/users/${userId}`,
    transform: ({ json }) => normalize(json, schemas.user),
  });
}

export function loadRecentPosts(userId, nextPage, query) {
  const key = `userPosts.${userId}`;
  return createFetchAction({
    type: 'LOAD_RECENT_POSTS',
    endpoint: state => {
      const base = `/api/v2/users/${userId}/posts/recent?${qs.stringify(query)}`;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    transform: ({ json, response }) => assign(normalize(json, schemas.posts), {
      nextPageUrl: getNextPageUrl(response),
    }),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}

export function loadFeedBeforePosts({ userId, nextPage, ltId, limit = 3 }) {
  const key = 'userBeforePosts';
  return createFetchAction({
    type: 'LOAD_FEED_BEFORE_POSTS',
    endpoint: state => {
      const base = `/api/v2/users/${userId}/posts?${qs.stringify({ ltId, limit })}`;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    transform: ({ json, response }) => assign(normalize(json, schemas.feedBeforePosts), {
      nextPageUrl: getNextPageUrl(response),
    }),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}

export function loadFeedAfterPosts({ userId, nextPage, gtId, limit = 3 }) {
  const key = 'userAfterPosts';
  return createFetchAction({
    type: 'LOAD_FEED_AFTER_POSTS',
    endpoint: state => {
      const base = `/api/v2/users/${userId}/posts?${qs.stringify({ gtId, limit })}`;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    transform: ({ json, response }) => assign(normalize(json, schemas.feedAfterPosts), {
      nextPageUrl: getNextPageUrl(response),
    }),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'PREPEND' : 'REFRESH' },
    },
  });
}

export function clearFeedAllPosts() {
  return (dispatch, getState) => {
    const state = getState();
    state.pagination.userAfterPosts = {};
    state.pagination.userBeforePosts = {};
    state.entities.feedAfterPosts = {};
    state.entities.feedBeforePosts = {};
    dispatch({
      type: 'CLEAR_FEED_ALL_POSTS',
      payload: state,
    });
  }
}

export function loadFeaturedHeartists(nextPage) {
  const key = 'featuredHeartists';
  return createFetchAction({
    type: 'LOAD_FEATURED_HEARTISTS',
    endpoint: state => {
      const base = '/api/v2/users/heartists/recommended';
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    transform: ({ json }) => normalize(json, schemas.heartists),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
      meta: { entities: { update: true } },
    },
  });
}

export function updateHeartists(heartists) {
  return {
    type: 'UPDATE_HEARTISTS',
    payload: { result: heartists },
    pagination: { key: 'heartists', type: 'REFRESH' },
  };
}


export function changePassword(userId, params) {
  return createFetchAction({
    type: 'CHANGE_PASSWORD',
    endpoint: `/api/v2/users/${userId}/password`,
    method: 'POST',
    body: params,
  });
}

export function loadPushHistories(userId, nextPage) {
  const key = 'pushHistories';
  return createFetchAction({
    type: 'LOAD_PUSH_HISTORIES',
    endpoint: state => {
      const base =`/api/v2/users/${userId}/pushHistory`;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    transform: ({ json, response }) => {
      return assign(
        normalize(json, schemas.pushHistories),
        { nextPageUrl: getNextPageUrl(response) }
      );
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
      meta: { entities: { update: true } },
    },
  });
}

export function loadPushHistoryCount(userId) {
  const key = 'pushHistoryCount';
  return createFetchAction({
    type: 'LOAD_PUSH_HISTORY_COUNT',
    endpoint: `/api/v2/users/${userId}/pushHistory/unreadCount`,
    transform: ({ json }) => set({}, 'entities.pushHistoryCount', json),
  });
}
