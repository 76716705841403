import * as React from 'react';

function SvgBtnSoundOff(props) {
  return (
    <svg width={32} height={32} {...props}>
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <circle cx={15} cy={15} r={15} fill="#000" opacity={0.348} />
        <path
          fill="#FFF"
          d="M14.986 8.572a.315.315 0 01.514.243v6.596l1.89 1.624a2.914 2.914 0 00-.699-4.302l.629-.985a4.079 4.079 0 011.893 3.452c0 .966-.335 1.879-.93 2.6l1.254 1.077a5.717 5.717 0 001.33-3.677 5.727 5.727 0 00-2.659-4.848l.63-.985a6.892 6.892 0 013.197 5.833 6.888 6.888 0 01-1.61 4.439l1.644 1.412-.762.887-.176-.151-1.534-1.317-.901-.774-1.287-1.105-.282-.242-1.627-1.397-5.58-4.79L7 9.657l.228-.265.534-.622 3.405 2.924zm-6.523 3.59l7.037 5.999v3.39a.315.315 0 01-.514.243l-4.394-3.591H8.035a.602.602 0 01-.601-.601v-4.838a.6.6 0 01.6-.601h.429z"
        />
      </g>
    </svg>
  );
}

export default SvgBtnSoundOff;

