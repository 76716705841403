import qs from 'qs';
import createFetchAction from "../util/createFetchAction";


export function getCategorySaleProducts(categorySaleId, query={}) {
  const params = query ? `?${qs.stringify(query).replace('%20', '+')}` : '';
  return createFetchAction({
    type: 'GET_CATEGORY_SALE_PRODUCTS',
    endpoint: `/api/v2/categorySales/${categorySaleId}` + params,
    method: 'GET',
    doDispatch: false,
  });
}
