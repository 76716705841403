import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from 'components/Spinner';
import { loadEntities } from 'react-shared';
import * as adminActions from 'react-shared/adminActions';
import { Link } from 'react-router';
import MDCuration from 'components/admin/MDCuration';

const styles = {
  container: {
    margin: '0 auto',
    maxWidth: '450px',
  },
};

export class ListMDCurationPage extends Component {
  componentDidMount() {
    const { adminMdCurations, adminLoadMdCurations } = this.props;
    if (!adminMdCurations || adminMdCurations.length === 0) {
      adminLoadMdCurations();
    }
  }

  render() {
    const {
      adminMdCurations,
      pagination: { isFetching },
    } = this.props;
    const mdCurationsList = adminMdCurations.sort(
      (a, b) => b.created - a.created,
    );
    return (
      <div style={styles.container}>
        <br />
        <Link className="btn btn-primary" to="/admin/mdCurations/new">
          기획전 추가
        </Link>
        {mdCurationsList.length > 0
          && mdCurationsList.map(mdCuration => (
            <MDCuration
              mdCuration={mdCuration}
              key={`mdCuration_${mdCuration.id}`}
            />
          ))}
        {isFetching && <Spinner />}
      </div>
    );
  }
}

ListMDCurationPage.propTypes = {
  adminLoadMdCurations: PropTypes.func.isRequired,
  adminMdCurations: PropTypes.array,
  // pagination: PropTypes.object,
};

ListMDCurationPage.defaultProps = {
  adminMdCurations: {},
};

export const mapStateToProps = state => ({
  ...loadEntities(state, 'adminMdCurations', 'adminMdCurations'),
});

export default connect(
  mapStateToProps,
  adminActions,
)(ListMDCurationPage);
