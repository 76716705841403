import React from 'react'
import { Platform, StyleSheet, View, Text, FlatList, Share } from 'react-native';
import { config } from 'shared';

import { Styles, Colors, Constants } from '../../../constants';

import TouchableHighlightWithContainer from '../../../components-v2/TouchableHighlightWithContainer';
import BottomSheetModal from '../../../components-v2/BottomSheetModal';


const styles = StyleSheet.create({
  container: {
    paddingBottom: 39 + Constants.bottomMargin,
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 20,
    paddingTop: 25,
    paddingBottom: 25,
  },
  itemText: {
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard400,
  },
});

class MoreModal extends BottomSheetModal {

  title = '더보기'

  state = {
  };

  onPressEdit = () => {
    const { setModalProps, post, router } = this.props;
    setModalProps({
      isVisible: false,
    });
    router.push(`/style/upload/${post._id}`);
  }

  onPressShare = () => {
    const { onPressShare:onPressShareCallback, setModalProps, router, post } = this.props;
    onPressShareCallback();
    setModalProps({
      isVisible: false,
    });
  }

  onPressDelete = async () => {
    const { setModalProps, router, post, deleteStylePost } = this.props;
    await deleteStylePost(post._id);
    setModalProps({
      isVisible: false,
    }, () => router.replace('/style/all'));
  }

  renderBody() {
    const { post } = this.props;

    return (
      <View style={styles.container}>
        <View style={{ height: 6 }} />

        <TouchableHighlightWithContainer
          onPress={this.onPressShare}
          >
          <View style={styles.itemContainer}>
            <Text style={styles.itemText}>공유하기</Text>
          </View>
        </TouchableHighlightWithContainer>

        <TouchableHighlightWithContainer
          onPress={this.onPressEdit}
          >
          <View style={styles.itemContainer}>
            <Text style={styles.itemText}>수정하기</Text>
          </View>
        </TouchableHighlightWithContainer>

        <TouchableHighlightWithContainer
          onPress={this.onPressDelete}
          >
          <View style={styles.itemContainer}>
            <Text style={styles.itemText}>삭제하기</Text>
          </View>
        </TouchableHighlightWithContainer>
      </View>
    );
  }
}


export default MoreModal;
