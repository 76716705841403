import { Cloudinary } from 'cloudinary-core';
import React, { Component } from 'react';
import { StyleSheet, View, Text, TouchableWithoutFeedback } from 'react-native';
import VisibilitySensor from 'react-visibility-sensor';

import config from '../config'

import Styles from '../constants/Styles';

import SoundOnIcon from '../icons/btnSoundOn';
import SoundOffIcon from '../icons/btnSoundOff';
import SoundXIcon from '../icons/btnSoundX';

import Image from './Image';


const cloudinary = Cloudinary.new();
cloudinary.config({
  ...config.get('cloudinary'),
  secure: true,
});

const styles = StyleSheet.create({
  overlayContainer: {
    position: 'absolute',
    zIndex: 1000,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlayTextContainer: {
    position: 'relative',
    flexShrink: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    borderRadius: 2,
  },
  overlayAudioStateContainer: {
    position: 'absolute',
    bottom: 9,
    right: 9,
    height: 32,
    width: 32,
    backgroundColor: 'transparent',
  },
  overlayText: {
    color: 'white',
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: 17,
    height: 17,
    marginTop: 14,
    marginBottom: 13,
    marginLeft: 17,
    marginRight: 18,
    backgroundColor: 'transparent',
    ...Styles.appleSDGothicNeoSemiBold,
  },
});

const stylesHtml = {
  container: {
    // width: '100%',
    height: 0,
    paddingTop: '100%',
  },
  videoContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  mediaVideoPlaybuttonContainer: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
}

export default class CloudinaryVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paused: false,
      muted: true,
      hasAudio: false,
      showAudioStateIcon: false,
      showOverlayText: false,
    };
    this.player = React.createRef();
  }

  onVideoVisilbityChange = (isVisible) => {
    if (!this.player) {
      return;
    }

    if (!isVisible) {
      this.player.pause();
      this.player.currentTime = 0;
    } else {
      this.player.play();
    }
  }

  onPlay = (evt) => {
    this.setState({ paused: false });
  }

  onPause = (evt) => {
    this.setState({ paused: true });
  }

  onError = _error => {
    // console.warn('CloudinaryVideo Error', error);
  };

  onLoadedData = evt => {
    const { audioTracks = null } = this.player;

    const newState = {};
    if (audioTracks === null || audioTracks.length > 0) {
      newState.hasAudio = true;
      newState.showAudioStateIcon = true;
      newState.showOverlayText = false;
    } else {
      newState.hasAudio = false;
      newState.showAudioStateIcon = false;
      newState.showOverlayText = false;
    }

    this.setState(newState);
  }

  onPressVideo = evt => {
    const { muted, hasAudio, showAudioStateIcon, showOverlayText } = this.state;

    const newState = {};
    if (hasAudio) {
      newState.muted = !muted;
    } else if (!showOverlayText && !showAudioStateIcon) {
      newState.showAudioStateIcon = true;
    } else if (!showOverlayText && showAudioStateIcon) {
      newState.showOverlayText = true;
    } else {
      newState.showAudioStateIcon = false;
      newState.showOverlayText = false;
    }

    this.setState(newState);
  }

  renderVideo() {
    const { publicId, options, style, containerStyle, aspectRatio } = this.props;
    const { paused, muted, hasAudio, showAudioStateIcon, showOverlayText } = this.state;

    const pausedState = paused ? { paused: paused.toString() } : {};

    const containerStyls = {
      ...stylesHtml.container,
      containerStyle,
      paddingTop: `${1 / aspectRatio * 100}%`,
    }

    return (
      <div style={containerStyls}>
        <div style={stylesHtml.videoContainer}>
          <View style={styles.overlayContainer}>
          {showOverlayText && (
            <View style={styles.overlayTextContainer}>
              <Text style={styles.overlayText}>소리가 없는 동영상입니다</Text>
            </View>
          )}
          {showAudioStateIcon && (
            <View style={styles.overlayAudioStateContainer}>
              { hasAudio && muted && (
                <SoundOffIcon />
              )}
              { hasAudio && !muted && (
                <SoundOnIcon />
              )}
              { !hasAudio && (
                <SoundXIcon />
              )}
            </View>
          )}
          </View>

          <video
            style={{ ...style }}
            src={cloudinary.video_url(publicId, options)}
            ref={ref => { this.player = ref; }}
            onError={this.onError}
            onPlay={this.onPlay}
            onPause={this.onPause}
            muted={muted}
            {...pausedState}
            onLoadedData={() => this.onLoadedData()}
            poster={ cloudinary.video_url(publicId, {
              crop: 'limit',
              format: 'jpg',
              height: 640,
              width: 640,
              start_offset: 0,
            })}
            autoPlay
            loop
            playsInline
          />
        </div>
      </div>
    );
  }

  renderThumbnail() {
    const { publicId, style, options, ...props } = this.props;

    const source = cloudinary.video_url(publicId, options);

    return (
      <Image
        style={{...style}}
        source={source}
        {...props}
        />
    );
  }

  render() {
    const { thumbnailOnly } = this.props;

    if (thumbnailOnly === true) {
      return this.renderThumbnail();
    }

    return (
      <VisibilitySensor onChange={this.onVideoVisilbityChange}>
        <TouchableWithoutFeedback
          onPress={() => this.onPressVideo()}
        >
            {this.renderVideo()}
        </TouchableWithoutFeedback>
      </VisibilitySensor>
    );
  }
}
