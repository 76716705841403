import React from 'react';
import {
  StyleSheet, View, Text,
} from 'react-native';
import NumberFormat from 'react-number-format';

import { formatPrice } from 'shared/util';
import { parseDate, getRemaingTime } from 'shared/util/dateTools';
import { Colors, Styles } from '../constants';
import { getCrawlerProductImageStyle } from 'shared/util/product';


const stylesHtml = {
  itemImage: {
    width: 166,
    height: 221,
    objectFit: 'cover',
    border: 'solid',
    borderWidth: 0.5,
    borderColor: Colors.productImageBorder,
    overflow: 'hidden',
  },
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  itemContainer: {
    width: 166,
    flexDirection: 'column',
  },
  itemImageTimerContainer: {
    position: 'relative',
  },
  itemTimerContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 26,
    backgroundColor: '#000',
    justifyContent: 'center',
  },
  itemTimerBackground: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    backgroundColor: Colors.primaryRed,
  },
  itemTimerTextContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: 26,
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemTimer: {
    color: '#fff',
    fontSize: 13.5,
    lineHeight: 14,
    textAlign: 'center',
    letterSpacing: -0.01,
    fontVariantNumeric: 'tabular-nums',
    ...Styles.pretendard700,
  },
  itemContentContainer: {
    flexDirection: 'column',
  },
  itemContent: {
    marginTop: 10,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  itemBrandNameContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: 2,
  },
  itemBrand: {
    fontSize: 13,
    lineHeight: 14.32,
    color: '#000',
    marginBottom: 3,
    ...Styles.pretendard600,
  },
  itemName: {
    lineHeight: 14.32,
    fontSize: 13,
    color: '#000',
    marginBottom: 2,
    ...Styles.pretendard400,
  },
  itemPriceContainer: {
    flexDirection: 'row',
  },
  itemSellPrice: {
    fontSize: 13,
    lineHeight: 14.4,
    color: '#000',
    ...Styles.pretendard400,
  },
  itemStockContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 1,
  },
  itemStock: {
    fontSize: 12,
    lineHeight: 13.2,
    color: '#000',
    ...Styles.pretendard500,
    marginTop: 6,
    paddingVertical: 4,
    paddingHorizontal: 6,
    borderWidth: 0.5,
    borderColor: '#000',
  },
});


class TimeSaleProduct extends React.Component {
  state = {
    timerText: '',
    timerBarIndex: 166,
  }

  constructor(props) {
    super(props);

    this.state = {
      ...this.getTimerInfo()
    }
  }

  getTimerInfo = () => {
    const { product } = this.props;
    if (!product) {
      return {};
    }

    const startDt = parseDate(product.timeSaleStartDt, true);
    const endDt = parseDate(product.timeSaleEndDt, true);
    let { days, hours, minutes, seconds, milliseconds } = getRemaingTime(endDt, true);
    hours = `0${hours}`.slice(-2);
    minutes = `0${minutes}`.slice(-2);
    seconds = `0${seconds}`.slice(-2);
    let deciseconds = Math.floor(milliseconds / 100);

    const timerText = `${days}일 ${ hours }:${ minutes }:${ seconds }.${ deciseconds }`;

    const totalDuration = endDt - startDt;
    const remainedDuration = endDt - Date.now();
    const timerBarIndex = (1 - (remainedDuration/totalDuration)) * 166;

    return {
      timerText,
      timerBarIndex
    };
  }

  componentDidMount(){
    this.interval = setInterval(
      () => this.setState(this.getTimerInfo()),
      100
    );
  }

  componentWillUnmount(){
   clearInterval(this.interval);
  }

  renderItemInfo = () => {
    const {
      product, enableImageFitContain = true,
    } = this.props;
    const { brandNm, goodsNm, goodsImageSrc } = product;
    const { timerText, timerBarIndex } = this.state;

    const itemOrgPrice = Number.parseInt(product.fixedPrice, 10);
    const itemSellPrice = Number.parseInt(product.goodsPrice, 10);
    const discount = Math.round(100 * (1 - itemSellPrice / itemOrgPrice));
    const stock = Number.parseInt(product.stockCnt, 10);

    const crawlerImageStyle = getCrawlerProductImageStyle(product, enableImageFitContain);
    const crawlerImageWebStyle = {
      objectFit: crawlerImageStyle.resizeMode,
      backgroundColor: crawlerImageStyle.backgroundColor
    };

    return (
      <View style={styles.container}>
        <View style={styles.itemContentContainer}>
          <View style={styles.itemImageTimerContainer}>
            <img
              alt=""
              style={{...stylesHtml.itemImage, ...crawlerImageWebStyle}}
              referrerPolicy="no-referrer"
              src={goodsImageSrc}
            />
            <View style={styles.itemTimerContainer}>
              <View style={[styles.itemTimerBackground, {left: timerBarIndex }]} />
              <View style={styles.itemTimerTextContainer}>
                <Text
                  style={styles.itemTimer}
                  numberOfLines={1}
                >
                  {timerText}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.itemContent}>
            <View style={styles.itemBrandNameContainer}>
              <Text
                style={styles.itemBrand}
                numberOfLines={1}
                ellipsizeMode='tail'
              >
                {brandNm}
              </Text>
              <Text
                style={styles.itemName}
                numberOfLines={1}
                ellipsizeMode='tail'
              >
                {goodsNm}
              </Text>
            </View>

            <View style={styles.itemPriceContainer}>
              <NumberFormat
                value={itemSellPrice}
                displayType="text"
                thousandSeparator
                renderText={value => (
                  <Text style={styles.itemSellPrice}>
                    {`${value}원`}
                  </Text>
                )}
              />
              <Text
                style={[styles.itemSellPrice, {color: Colors.primaryRed, marginStart:6, ...Styles.pretendard700 }]}
              >
                {`${discount}%`}
              </Text>
            </View>

            {!Number.isNaN(stock) && (
              <View style={styles.itemStockContainer}>
                <Text
                  style={styles.itemStock}
                >
                 {`남은 수량 ${stock}개`}
                </Text>
              </View>
            )}
          </View>
        </View>
      </View>
    );
  }

  render() {
    return (
      <View style={styles.itemContainer}>
        {this.renderItemInfo()}
      </View>
    );
  }
}


export default TimeSaleProduct;
