import React from 'react';

import {
  StyleSheet, View, Text, FlatList, Alert,
} from 'react-native';
import { TouchableHighlightWithContainer } from '../../../components-v2';
import PostThumbnail from '../../../components-v2/PostThumbnail';
import { Constants, Styles } from '../../../constants';

import _styles from '../../../css/modules/carousel.module.css';
import { ArrowForwardIosIcon, ArrowBackIosIcon } from '../../../icons-v2';

const styles = StyleSheet.create({
  container: {
    marginTop: 40,
  },
  listContainer: {
    marginTop: 10,
  },
  title: {
    ...Styles.pretendard700,
    fontSize: 18,
    lineHeight: 21.6,
    marginStart: Constants.viewHorizontalPadding,
  },
  image: {
    height: 221,
    width: 166,
    aspectRatio: 166/221,
  },
  itemSeparator: {
    width: 4,
  },
  listHeaderFooter: {
    width: Constants.viewHorizontalPadding,
  },
  itemContainer: {
  },
  thumbnailContainer: {
    width: 166,
    height: 221,
  },
  thumbnail: {
    aspectRatio: null,
    width: 166,
    height: 221,
    resizeMode: 'cover',
  },
  name: {
    ...Styles.pretendard600,
    fontSize: 15,
    lineHeight: 18,
    marginTop: 6,
  },
});


class Heartists extends React.PureComponent {

  listRef = React.createRef();

  state = {
    isLeftEnd:false,
    isRightEnd:false,
    nowIndex:0
  };

  handleMomentEnd(event) {
    const index = Math.floor(
        Math.floor(event.nativeEvent.contentOffset.x) /
        Math.floor(event.nativeEvent.layoutMeasurement.width));
    this.setState({nowIndex : index});
  }


  handleLeftClickEvent(e) {

    const nowIndex = this.state.nowIndex;
    let newIndex = 0;
    if(nowIndex > 2){
      newIndex = nowIndex - 2;
      this.listRef.scrollToIndex({animated: true, index: newIndex});
    }else{
      newIndex = 0;
      this.listRef.scrollToOffset({animated: true, index: newIndex});
    }
    this.setState({nowIndex : newIndex});
  }

  handleRightClickEvent(e) {
    const nowIndex = this.state.nowIndex;
    let newIndex = nowIndex + 2;
    try{
      this.listRef.scrollToIndex({animated: true, index: newIndex});
      this.setState({nowIndex : newIndex});
    }catch(e){

    }
  }

  onPressItem(item) {
    const { router } = this.props;
    router.push(`/style/feed/${item._id}`);
  }

  renderItem(item) {
    return (
      <View style={styles.itemContainer}>
        <TouchableHighlightWithContainer
          onPress={() => this.onPressItem(item)}
        >
          <PostThumbnail
            containerStyle={styles.thumbnailContainer}
            mediaStyle={styles.thumbnail}
            post={item}
          />
        </TouchableHighlightWithContainer>
        <Text style={styles.name}>@{item.owner.name}</Text>
      </View>
    );
  }

  render() {
    const {
      containerStyle,
      heartists,
    } = this.props;

    const {
      isLeftEnd,
      isRightEnd,
    } = this.state;
    //console.log(`heartists=${JSON.stringify(heartists)}`);

    return (
      <View style={[styles.container, containerStyle]}>
        <Text style={styles.title}>이 상품을 착용한 하티스트</Text>
        <div className={_styles._desktop_carousel}>
          <FlatList
          ref={ref => this.listRef = ref }
            data={heartists}
            renderItem={({ item }) => this.renderItem(item)}
            style={styles.listContainer}
            ListHeaderComponent={() => <View style={styles.listHeaderFooter} />}
            ListFooterComponent={() => <View style={styles.listHeaderFooter} />}
            ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
            horizontal
            showsHorizontalScrollIndicator={false}
            />
          {!isLeftEnd &&
          <div className={_styles._desktop_carousel__left}
            style={{height : 'calc(100% - 24px)' }}
            onClick={this.handleLeftClickEvent.bind(this)}>
            <ArrowBackIosIcon
              color={"#333333"}
              style={{position:'absolute', top:'50%', left:'50%', width:"24px", height:'24px', transform:'translate(-50%, -50%)'}}/>
          </div>}
          {!isRightEnd &&
          <div className={_styles._desktop_carousel__right}
            style={{height : 'calc(100% - 24px)' }}
            onClick={this.handleRightClickEvent.bind(this)}>
            <ArrowForwardIosIcon
              color={"#333333"}
              style={{position:'absolute', top:'50%', left:'50%', width:"24px", height:'24px', transform:'translate(-50%, -50%)'}}/>
          </div>}
        </div>
      </View>
    );
  }
}


export default Heartists;
