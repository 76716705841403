import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, Text, View, TouchableHighlight } from 'react-native';
import NumberFormat from 'react-number-format';

import config from '../config';
import Colors from '../constants/Colors';
import Styles from '../constants/Styles';
import Constants from '../constants/Constants';
import { moveToReviewWrite } from '../containers/my/ReviewWritePage';

const buttonSpacer = 3.5;
const styles = StyleSheet.create({
  itemContainer: {
    flex: 1,
    flexDirection: 'column',
    marginHorizontal: Constants.viewHorizontalPadding,
  },
  infoButtonContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  itemInfoContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  buttonsContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: buttonSpacer * -1,
    marginTop: 10,
    backgroundColor: 'transparent',
  },
  priceContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
    alignItems: 'flex-end',
  },
  /* itemInfoContainer */
  itemImageContainer: {
    marginRight: 10,
    justifyContent: 'center',
  },
  itemInfoTextContainer: {
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  itemNameBrandContainer: {
    flexDirection: 'column',
  },
  itemBrand: {
    height: 16,
    marginTop: 4,
    ...Styles.SFUIDisplaySemiBold,
    fontSize: 14,
    lineHeight: 16,
  },
  itemName: {
    height: 17,
    marginTop: 6,
    ...Styles.SFUIDisplayRegular,
    fontSize: 14,
    lineHeight: 17,
  },
  itemOptionContainer: {
    marginTop: 8,
    flexDirection: 'column',
  },
  itemOption: {
    ...Styles.appleSDGothicNeoRegular,
    lineHeight: 15,
    fontSize: 12,
    opacity: 0.6,
  },
  /* priceContainer */
  itemOrgPrice: {
    height: 20,
    ...Styles.SFUITextRegular,
    fontSize: 16,
    lineHeight: 20,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  itemSettlePrice: {
    height: 30,
    ...Styles.SFUITextSemiBold,
    fontSize: 24,
    lineHeight: 30,
  },
  /* buttonContainer */
  button: {
    flex: 1,
    height: 38,
    marginHorizontal: buttonSpacer,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.semiGray,
  },
  buttonText: {
    height: 17,
    marginTop: 1,
    ...Styles.appleSDGothicNeoBold,
    fontSize: 14,
    lineHeight: 17,
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});

const stylesHtml = {
  itemImage: {
    width: 100,
    aspectRatio: 1,
    resizeMode: 'contain',
  },
};

function getButton(label, onPress) {
  return (
    <TouchableHighlight
      style={styles.button}
      underlayColor={Colors.touchEffectColorWhite}
      onPress={onPress}
    >
      <Text style={styles.buttonText}>
        { label }
      </Text>
    </TouchableHighlight>
  );
}

class OrderDetailItem extends React.Component {
  showChatInquiry = () => {
    const chatUrl = config.get('chatInquiryUrl');
    window.location.href = chatUrl;
  }

  moveToShippingInfo = () => {
    const { good } = this.props;

    const { deliveryTraceUrl = null } = good;
    console.log('deliveryTraceUrl', deliveryTraceUrl);
    if (deliveryTraceUrl) {
      window.location.href = deliveryTraceUrl;
    }
  }

  renderOption = (option, index) => (
    <Text
      key={index.toString()}
      style={styles.itemOption}
      numberOfLines={1}
      ellipsizeMode="tail"
    >
      { `${option.optionName} : ${option.optionValue}` }
    </Text>
  )

  render() {
    const { good, onPressProduct, orderNo } = this.props;
    // console.log(good);

    if (good.optionInfo.findIndex(option => option.optionName === '수량') < 0) {
      good.optionInfo.push({
        optionName: '수량',
        optionValue: good.goodsCnt,
      });
    }

    const orgPrice = (parseFloat(good.fixedPrice) + parseFloat(good.optionPrice)) * parseInt(good.goodsCnt, 10);
    const goodsPrice = (parseFloat(good.goodsPrice) + parseFloat(good.optionPrice)) * parseInt(good.goodsCnt, 10);

    return (
      <View style={styles.itemContainer}>
        <View style={styles.infoButtonContainer}>
          <TouchableHighlight
            onPress={() => { onPressProduct(good.goodsNo); }}
            underlayColor={Colors.touchEffectColorWhite}
            style={[styles.touchEffectContainer, styles.touchEffect]}
          >
            <View
              style={styles.itemInfoContainer}
            >
              <View style={styles.itemImageContainer}>
                <img
                  referrerPolicy="no-referrer"
                  alt=""
                  style={stylesHtml.itemImage}
                  src={good.imageSrc}
                />
              </View>
              <View style={styles.itemInfoTextContainer}>
                <View style={styles.itemNameBrandContainer}>
                  <Text
                    style={styles.itemBrand}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    { good.brandNm }
                  </Text>
                  <Text
                    style={styles.itemName}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    { good.goodsNm }
                  </Text>
                </View>
                <View style={styles.itemOptionContainer}>
                  { good.optionInfo.map((option, index) => this.renderOption(option, index)) }
                </View>
              </View>
            </View>
          </TouchableHighlight>
          <View style={styles.buttonsContainer}>
            { good.orderStatus === 'o1'
              && (getButton('주문 취소', () => { this.showChatInquiry(); })) }

            { good.orderStatus === 'p1'
              && (getButton('주문 취소', () => { this.showChatInquiry(); })) }

            { good.orderStatus.startsWith('d')
              && (getButton('배송조회', this.moveToShippingInfo)) }

            { (good.orderStatus === 'g1'
                || good.orderStatus.startsWith('d')
            )
              && (getButton('반품/교환', () => { this.showChatInquiry(); })) }

            { good.orderStatus.startsWith('r')
              && (getButton('환불 정보', () => {})) }

            { good.orderStatus.startsWith('e')
              && (getButton('교환 정보', () => {})) }
            { good.orderStatus === 'e1'
              && (getButton('교환 철회', () => { this.showChatInquiry(); })) }

            { good.orderStatus.startsWith('b')
              && (getButton('반품 정보', () => {})) }
            { good.orderStatus === 'b1'
              && (getButton('반품 철회', () => { this.showChatInquiry(); })) }

            { good.orderStatus === 'd2'
              ? getButton('리뷰 작성', () => { moveToReviewWrite(orderNo, good.sno); })
              : getButton('1:1 문의', () => { this.showChatInquiry(); })
            }
          </View>
        </View>
        <View style={styles.priceContainer}>
          <NumberFormat
            value={orgPrice}
            displayType="text"
            thousandSeparator
            decimalScale={0}
            renderText={value => (
              <Text style={styles.itemOrgPrice}>
                {value}
                <Text style={Styles.appleSDGothicNeoRegular}>원</Text>
              </Text>
            )}
          />
          <NumberFormat
            value={goodsPrice}
            displayType="text"
            thousandSeparator
            decimalScale={0}
            renderText={value => (
              <Text style={styles.itemSettlePrice}>
                {value}
                <Text style={Styles.appleSDGothicNeoSemiBold}>원</Text>
              </Text>
            )}
          />
        </View>
      </View>
    );
  }
}

export default OrderDetailItem;
