import React from 'react';
import { ScrollView, StyleSheet, View, ActivityIndicator } from 'react-native';
import { connect } from 'react-redux';
import { actions, selectPostDate, loadEntities } from 'shared';
import VisibilitySensor from 'react-visibility-sensor';
import Post from '../nativeComponents/Post';
import Spinner from '../components/Spinner';

import Constants from '../constants/Constants';
import Colors from '../constants/Colors';
import { openOutlinkPage } from './OutlinkGatewayPage';
import { generateFirebaseEvent } from '../utils';


export class PostListPage extends React.Component {
  state = {
    userId: null,
    initialized: false,
    allowedToLoadFeedAfterPosts: false,
    existsFeedAfterPosts: true,
    allowedToLoadFeedBeforePosts: false,
    existsFeedBeforePosts: true,
  };

  componentDidMount() {
    const { post, loadPost, router, logEventPageview, postId } = this.props;

    if (post) {
      this.setState({ userId: post.owner._id }, () => {
        this.initializeFeedPosts();
      });
    } else {
      loadPost(router.params.postId)
        .then(post => {
          this.setState({ userId: post.owner._id }, () => {
            this.initializeFeedPosts();
          });
        });
    }

    logEventPageview({ page_name: 'Post', page_key: postId });
  }

  componentWillUnmount() {
    const { clearFeedAllPosts } = this.props;
    clearFeedAllPosts();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      const { scrollHeight: prevScrollHeight, pageYOffset: prevPageYOffset } = snapshot;
      const elem = document.getElementById('divFeedAfterPosts');
      const scrollHeight = elem ? elem.scrollHeight : 0;
      const delta = scrollHeight - prevScrollHeight;
      window.scrollTo(0, prevPageYOffset + delta);
    }

    if (!prevState.initialized && this.state.initialized) {
      // 30 and 20 from spacerWrapper, 10 from spacer
      // 20 from ActivityIndicator, 10 as a padding
      const scrollPos = 30 + 20 + 10 + 20 + 10;
      window.scrollTo(0, scrollPos);
      this.setState({
        allowedToLoadFeedAfterPosts: true,
      });
    }
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { feedAfterPosts: prevFeedAfterPosts } = prevProps;
    const { feedAfterPosts } = this.props;
    const { initialized } = prevState;

    if (!initialized) {
      return null;
    }

    if (prevFeedAfterPosts.length !== feedAfterPosts.length) {
      const elem = document.getElementById('divFeedAfterPosts');
      const scrollHeight = elem ? elem.scrollHeight : 0;
      const pageYOffset = window.pageYOffset;
      return { scrollHeight, pageYOffset };
    }

    return null;
  }

  initializeFeedPosts = async () => {
    const { router, loadFeedBeforePosts, loadFeedAfterPosts } = this.props;
    const { userId } = this.state;
    loadFeedBeforePosts({ userId, ltId: router.params.postId })
      .then(() => {
        this.setState({
          initialized: true,
          allowedToLoadFeedBeforePosts: true,
        });
      });
  }

  loadFeedAfterPosts = async (isVisible) => {
    const { router, feedAfterPosts, userAfterPagination, loadFeedAfterPosts } = this.props;
    const { userId, allowedToLoadFeedAfterPosts } = this.state;

    console.log(isVisible);
    console.log(userAfterPagination);

    if (!isVisible) return;
    if (!allowedToLoadFeedAfterPosts) return;

    if (!userAfterPagination.isFetching) {
      if (userAfterPagination.nextPageUrl) {
        this.setState({ allowedToLoadFeedAfterPosts: false }, () => {
          loadFeedAfterPosts({ userId, nextPage: userAfterPagination.nextPageUrl })
            .then(afterPosts => {
              if (afterPosts.length < 3) {
                this.setState({ existsFeedAfterPosts: false });
              }
              this.setState({ allowedToLoadFeedAfterPosts: true });
            })
            .catch(() => {
              this.setState({ allowedToLoadFeedAfterPosts: true });
            });
        });
      } else if (feedAfterPosts.length === 0) {
        this.setState({ allowedToLoadFeedAfterPosts: false }, () => {
          loadFeedAfterPosts({ userId, gtId: router.params.postId })
            .then(afterPosts => {
              if (afterPosts.length < 3) {
                this.setState({ existsFeedAfterPosts: false });
              }
              this.setState({ allowedToLoadFeedAfterPosts: true });
            })
            .catch(() => {
              this.setState({ allowedToLoadFeedAfterPosts: true });
            });
        });
      }
    }
  };

  loadFeedBeforePosts = async (isVisible) => {
    const { userBeforePagination, loadFeedBeforePosts } = this.props;
    const { userId, allowedToLoadFeedBeforePosts } = this.state;

    if (!isVisible) return;
    if (!allowedToLoadFeedBeforePosts) return;

    if (!userBeforePagination.isFetching
        && userBeforePagination.nextPageUrl !== null) {
      this.setState({ allowedToLoadFeedBeforePosts: false }, () => {
        loadFeedBeforePosts({ userId, nextPage: userBeforePagination.nextPageUrl })
          .then(beforePosts => {
            if (beforePosts.length < 3) {
              this.setState({ existsFeedBeforePosts: false });
            }
            this.setState({ allowedToLoadFeedBeforePosts: true });
          })
          .catch(() => {
            this.setState({ allowedToLoadFeedBeforePosts: true });
          });
      });
    }
  };

  onPressProduct = product => {
    const { router, post, historySwitch, auth } = this.props;
    const { token } = auth;

    // eslint-disable-next-line no-prototype-builtins
    if (!post && product.hasOwnProperty('post')) {
      // eslint-disable-next-line prefer-destructuring
      post = product.post;
    }

    // TODO: Post 정보가 없는 경우 이벤트를 보내지 않아도 괜찮은지 확인 필요
    if (post && post.owner) {
      generateFirebaseEvent({ product, post, where: 'post' });
    }

    if (product.godoGoodsNo) {
      router.push({
        pathname: `/product/${product.godoGoodsNo}`,
        state: { postId: post._id },
      });
      return;
    }

    openOutlinkPage(product, post, historySwitch, token, router);
  }

  renderPost = post => {
    const { router, historySwitch, auth } = this.props;
    return (
      <React.Fragment key={post._id}>
        <Post
          onPressHeartist={(userId, username) => router.push(`/heartists/${username}`)}
          onPressProduct={this.onPressProduct}
          post={post}
          postDate={selectPostDate('postScreen')}
          historySwitch={historySwitch}
          auth={auth}
          enableProductLabelSimilar
          enableProductLabelHeartitLogo
          enableProductDeemedSeasonOut
        />
        <View style={stylesHtml.spacerWrapper}>
          <View style={stylesHtml.spacer} />
        </View>
      </React.Fragment>
    );
  }

  render() {
    const { router, post, historySwitch, auth } = this.props;
    const { feedBeforePosts, feedAfterPosts } = this.props;
    const { initialized, existsFeedAfterPosts, existsFeedBeforePosts } = this.state;

    if (!initialized) {
      return (
        <div style={stylesHtml.container}>
          <Spinner />
        </div>
      );
    }

    return (
      <div id="divFeedContainer" style={stylesHtml.container}>
        { existsFeedAfterPosts && (
          <React.Fragment>
            <VisibilitySensor onChange={this.loadFeedAfterPosts} offset={{ top: 80 }}>
              <View><ActivityIndicator size="small" /></View>
            </VisibilitySensor>
            <View style={stylesHtml.spacerWrapper}>
              <View style={stylesHtml.spacer} />
            </View>
          </React.Fragment>
        )}
        <div id="divFeedAfterPosts">
          {feedAfterPosts && feedAfterPosts.map(this.renderPost) }
        </div>
        <div id="divFeedCurrentPost">
          <Post
            onPressHeartist={(userId, username) => router.push(`/heartists/${username}`)}
            onPressProduct={this.onPressProduct}
            post={post}
            postDate={selectPostDate('postScreen')}
            historySwitch={historySwitch}
            auth={auth}
            enableProductLabelSimilar
            enableProductLabelHeartitLogo
            enableProductDeemedSeasonOut
          />
        </div>
        <View style={stylesHtml.spacerWrapper}>
          <View style={stylesHtml.spacer} />
        </View>
        <div id="divFeedBeforePosts">
          {feedBeforePosts && feedBeforePosts.map(this.renderPost)}
        </div>
        { existsFeedBeforePosts && (
          <VisibilitySensor onChange={this.loadFeedBeforePosts}>
            <View><ActivityIndicator size="small" /></View>
          </VisibilitySensor>
        )}
      </div>
    );
  }
}

const stylesHtml = {
  container: {
    paddingLeft: Constants.viewHorizontalPadding,
    paddingRight: Constants.viewHorizontalPadding,
    paddingBottom: 40,
    paddingTop: 20,
  },
  spacerWrapper: {
    paddingTop: 30,
    paddingBottom: 20,
    marginLeft: -Constants.viewHorizontalPadding,
    marginRight: -Constants.viewHorizontalPadding,
  },
  spacer: {
    paddingTop: 10,
    backgroundColor: Colors.semiGray,
  },
}

export default connect(
  (state, { router }) => {
    const postId = router.params.postId;
    return {
      auth: state.auth,
      historySwitch: state.settings.historySwitch,
      postId,
      post: state.entities.posts[postId],
      ...loadEntities(state, `userBeforePosts`, 'feedBeforePosts', 'userBeforePagination'),
      ...loadEntities(state, `userAfterPosts`, 'feedAfterPosts', 'userAfterPagination'),
    };
  },
  actions,
)(PostListPage);
