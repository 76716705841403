import { normalize } from 'normalizr';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';


export function getPartnerShippingProducts() {
  const key = "partnerShippingProducts"
  return createFetchAction({
    type: 'GET_PARTNER_SHIPPING_PRODUCTS',
    endpoint: '/api/v2/partnerShipping',
    method: 'GET',
    transform: ({ json }) => normalize(json, schemas.partnerShippingProducts),
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}
