import React from 'react';

import {
  StyleSheet, View, Text,
} from 'react-native';
import { Styles } from '../../../constants';

import { parseDate, convertKstToUtc } from 'shared/util';


const styles = StyleSheet.create({
  container: {
    marginTop: 20.5,
    marginBottom: 20,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  header: {
    ...Styles.pretendard600,
    fontSize: 15,
    lineHeight: 18,
  },
  body: {
    ...Styles.pretendard400,
    fontSize: 15,
    lineHeight: 18,
    marginLeft: 20,
  },
  itemSeparator: {
    height: 16,
  },
  shippingStartDate: {
    ...Styles.pretendard700,
    fontWeight: '700',
    fontSize: 15,
    lineHeight: 18,
  },
  additionalDiscountInfo: {
    ...Styles.pretendard400,
    fontWeight: '400',
    color:'#E94949',
    fontSize: 15,
    lineHeight: 20,
  },
});

function getDateStrAbbriv(date) {
  const dayOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
  return `${date.getMonth() + 1}/${date.getDate()}, ${dayOfWeek[date.getDay()]}`;
}

function getDateStr(date, year=true) {
  if (year) {
    return `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일`;
  } else {
    return `${date.getMonth() + 1}/${date.getDate()}`;
  }
}


class ShippingInfo extends React.PureComponent {
  render() {
    const {
      containerStyle,
      partnerShippingProducts,
      product: {
        goodsNo,
        salesEndYmd,
        launchYmd,
        deliveryDays='',
        memo: txtMemo,
        additionalSaleInfo="",
        categoryList=[]
      },
    } = this.props;

    let parser = null;
    if (txtMemo) {
      const memo = JSON.parse(txtMemo);
      parser = (memo.parser) ? memo.parser : null;
    }

    const isPreOrder = !!launchYmd && launchYmd !== '0000-00-00';
    let launchDateStr = '';

    let shippingMethod = '하트잇 프리오더 배송';
    let shippingPeriod = '';

    if (isPreOrder) {
      const salesEndDate = parseDate(salesEndYmd, true);
      const launchDate = parseDate(launchYmd);
      const delta = launchDate - salesEndDate;
      const deltaDays = Math.floor(delta / 1000 / 60 / 60 / 24);
      const deltaWeeks = Math.ceil(deltaDays / 7);
      launchDateStr = getDateStr(launchDate);
      shippingPeriod = `부터 순차 배송 예정\n\n프리오더 상품은 프리오더 기간 종료일로부터\n${deltaWeeks}주 이후에 배송이 시작됩니다`;
    } else {
      const deliveryStart = new Date();
      const deliveryEnd = new Date();
      // 주말일 경우 예상 배송일을 수정: 토요일 +2일, 일요일 +1일
      let addDay = 0;
      if (deliveryStart.getDay() === 0) {
        addDay = 1;
      } else if (deliveryStart.getDay() === 6) {
        addDay = 2;
      }

      if (parser === null) {
        shippingMethod = '하트잇 배송';
        deliveryStart.setDate(deliveryStart.getDate() + 2 + addDay);
        deliveryEnd.setDate(deliveryEnd.getDate() + 3 + addDay);
      } else if (parser === 'farfetch'
        || parser === 'mytheresa'
        || parser === 'cettire'
        || parser === 'net-a-porter'
        || parser === '24s'
        || parser === 'ln-cc') {
        shippingMethod = '파트너사 배송';
        deliveryStart.setDate(deliveryStart.getDate() + 7 + addDay);
        deliveryEnd.setDate(deliveryEnd.getDate() + 10 + addDay);
      } else {
        shippingMethod = '하트잇 배송';
        deliveryStart.setDate(deliveryStart.getDate() + 7 + addDay);
        deliveryEnd.setDate(deliveryEnd.getDate() + 10 + addDay);
      }

      // 도착 예정범위 시작이 주말일 경우 도착 에정일을 수정: 토요일 +2일, 일요일 +1일
      addDay = 0;
      if (deliveryStart.getDay() === 0) {
        addDay = 1;
      } else if (deliveryStart.getDay() === 6) {
        addDay = 2;
      }
      deliveryStart.setDate(deliveryStart.getDate() + addDay);
      deliveryEnd.setDate(deliveryEnd.getDate() + addDay);

      // 도착 예정범위 끝이 주말일 경우 도착 에정일을 수정: 토요일 +2일, 일요일 +1일
      addDay = 0;
      if (deliveryEnd.getDay() === 0) {
        addDay = 1;
      } else if (deliveryEnd.getDay() === 6) {
        addDay = 2;
      }
      deliveryEnd.setDate(deliveryEnd.getDate() + addDay);

      const deliveryStartStr = getDateStr(deliveryStart, false);
      const deliveryEndStr = getDateStr(deliveryEnd, false);

      let partnerShipping = partnerShippingProducts.filter(p => p.goodsNo == goodsNo);
      partnerShipping = (partnerShipping.length > 0) ? partnerShipping[0]: null;
      if (partnerShipping && !(partnerShipping.heartitShipping)) {
        shippingMethod = '파트너사 배송';
        launchDateStr = getDateStr(new Date(partnerShipping.shippingStartAt));
        shippingPeriod = `부터 순차 배송 예정`;
      } else {
        if(categoryList && Object.keys(categoryList).includes("018")){
          shippingMethod = '파트너사 배송';
        }
        shippingPeriod = `오늘(${getDateStrAbbriv(new Date())}) 주문시 ${deliveryStartStr} - ${deliveryEndStr} 도착예정`
      }
    }

    return (
      <View style={[styles.container, containerStyle]}>
        <View style={styles.row}>
          <Text style={styles.header}>배송 방법</Text>
          <Text style={styles.body}>{shippingMethod}</Text>
        </View>
        <View style={styles.itemSeparator} />
        <View style={styles.row}>
          <Text style={styles.header}>배송 기간</Text>
          <Text style={styles.body}>
            <Text style={styles.shippingStartDate}>{launchDateStr}</Text>
            {shippingPeriod}
          </Text>
        </View>
        {additionalSaleInfo.length > 0 && (
          <>
            <View style={styles.itemSeparator} />
            <View style={styles.row}>
              <Text style={styles.header}>추가 할인</Text>
              <Text style={styles.body}>
                <Text style={styles.additionalDiscountInfo}>{additionalSaleInfo}</Text>
              </Text>
            </View>
          </>
        )}
      </View>
    );
  }
}

export default ShippingInfo;
