import * as React from 'react';

function SvgIcLikeNormal18(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd" strokeLinejoin="round">
            <g stroke="#000" strokeWidth="1.5">
                <g>
                    <g>
                        <path d="M10.816 13.166l3.569-3.603c1.487-1.501 1.487-3.936 0-5.437-1.487-1.501-3.898-1.501-5.385 0-1.487-1.501-3.898-1.501-5.385 0-1.487 1.501-1.487 3.936 0 5.437L9 15l1.816-1.834z" transform="translate(-97 -530) translate(20 525) translate(77 5)"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>

  );
}

export default SvgIcLikeNormal18;
