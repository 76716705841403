import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { pick, map, keys } from 'lodash';


const statNameMap = {
  'click_unique': '클릭 유저수',
  'click_sum': '클릭수',
  'pageview_unique': '조회 유저수',
  'pageview_sum': '조회수',
};

const statDayOfWeekMap = {
  "1": "월",
  "2": "화",
  "3": "수",
  "4": "목",
  "5": "금",
  "6": "토",
  "7": "일",
};

class ClickStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visitStatisticsOptions: {
        title: {
          text: '',
        },
        series: [],
        xAxis: {
          tickmarkPlacement: 'on',
          reversed: true,
        },
      },
      kind: 'daily',
      visitStatRawData: {},
    };
  }

  loadUserStatistics(startDate, endDate) {
    const { loadData, clickKey } = this.props;
    const { kind } = this.state;

    // console.log(`loadUserStatistics clickKey=${clickKey}, kind=${kind}`);

    loadData(startDate, endDate, clickKey, kind)
      .then(resp => {
        const {
          data,
          title,
          description
        } = resp.content;
        this.setState({
          visitStatRawData: data,
          title,
          description,
        });

        var values = Object.keys(data).map(function(key) {
          return data[key];
      });
        const res = values.reduce((accumulator, datum) => {
          keys(datum).forEach(key => {
            if (!accumulator[key]) {
              accumulator[key] = [];
            }
            accumulator[key].push(datum[key]);
          });
          return accumulator;
        }, {})
        return res;
      })
      .then(data => {
        const series = map(
          pick(data, ['click_unique', 'click_sum', 'pageview_unique', 'pageview_sum']),
          (value, key) => ({
              name: statNameMap[key],
              data: value,
          }),
        );
        let categories = data.date;
        if (kind == 'dayOfWeek') {
          categories = categories.map(v => statDayOfWeekMap[v]);
        }
        const { visitStatisticsOptions: visitStatisticsOptionsPrev } = this.state;

        const summary = { 
          click_unique_app: data.click_unique_app.reduce((sum, value) => (sum + value), 0),
          click_unique_web: data.click_unique_web.reduce((sum, value) => (sum + value), 0),
          click_sum_app: data.click_sum_app.reduce((sum, value) => (sum + value), 0),
          click_sum_web: data.click_sum_web.reduce((sum, value) => (sum + value), 0),
        };

        this.setState({
          visitStatisticsOptions: {
            ...visitStatisticsOptionsPrev,
            xAxis: {
              categories,
              reversed: false,
            },
            series,
          },
          visitStatSummary: summary,
        });
      });
  }

  componentDidMount() {
    const { startDate, endDate } = this.props;
    this.loadUserStatistics(startDate, endDate);
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate } = this.props;
    const { kind } = this.state;
    if (prevProps.startDate !== startDate || prevProps.endDate !== endDate || prevState.kind !== kind) {
      this.loadUserStatistics(startDate, endDate);
    }
  }

  renderItemName() {
    const { clickKey } = this.props;
    const {
      title,
      description,
    } = this.state;

    const tokens = clickKey.split('.');
    const type = tokens[0];
    const name = tokens[1]

    switch (type) {
      case 'exhibition':
        return (
          <a href={`/admin/mdCurations/${name}`} target='_blank' title={description}>{title}</a>
        );
      case 'preorder':
        return (
          <a href={`/admin/preorderExhibitions/${name}`} target='_blank' title={description}>{title}</a>
        );
      case 'restock_alarm_on':
      case 'restock_alarm_off':
      case 'cart_add':
      case 'cart_del':
      case 'wishlist_add':
      case 'wishlist_del':
      case 'product':
        return (
          <a href={`/product/${name}`} target='_blank' title={description}>{title || clickKey}</a>
        );
      default:
        return name;
    }
  }

  render() {
    const {
      visitStatisticsOptions,
      kind,
      title,
    } = this.state;

    return (
      <div>
        <div style={{ fontSize: '20px', marginLeft: '20px', fontWeight: 'bold' }}>
          # {this.renderItemName()} Click Statistics&nbsp;&nbsp;
          <select onChange={event => this.setState({ kind: event.target.value })} value={kind}>
            <option value="daily">일별</option>
            <option value="hourOfDay">시간대별</option>
            <option value="dayOfWeek">요일별</option>
            <option value="monthly">월별</option>
          </select>
        </div>

        <HighchartsReact
          containerProps={{ style: { height: '600px' } }}
          highcharts={Highcharts}
          options={visitStatisticsOptions} />
      </div>
    );
  }
}


ClickStatistics.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};


export default ClickStatistics;
