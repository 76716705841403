import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

import TabLevel from './TabLevel';
import TabCoupon from './TabCoupon';
import TabPoint from './TabPoint';

import { Styles, Colors, Constants } from '../../../constants';


const TabBarHeight = 49;

const useStyles = () => ({
  tabs: {
    backgroundColor: '#fff',
    paddingLeft: Constants.viewHorizontalPadding,
    paddingRight: Constants.viewHorizontalPadding,
  },
  indicator: {
    backgroundColor: '#000',
    height: 0,
  },
  tab: {
    height: TabBarHeight,
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.3,
  },
  tabSelected: {
    opacity: 1,
  },
  tabWrapper: {
    fontSize: 18,
    lineHeight: 21.6,
    color: '#000',
    ...Styles.pretendard700,
  },
  rippleVisible: {
    opacity: 1,
    color: Colors.touchEffectColorWhite,
    animation: '0ms',
  },
});


const styles = {
  appBar: {
    top: 0,//Constants.mainHeaderHeight,
    shadows: ['none'],
    backgroundColor: '#fff',
  },
  appBarWithSmartBanner: {
    top: Constants.smartBannerHeight,// +Constants.mainHeaderHeight,
    shadows: ['none'],
    backgroundColor: '#fff',
  },
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={Styles.fullWidth}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


class MemberInfoTabView extends React.Component {

  state = {
    index: 0,
    routes: [
      { key: 'tabLevel', title: '회원 등급' },
      { key: 'tabCoupon', title: '쿠폰' },
      { key: 'tabPoint', title: '포인트' },
    ],
  };

  constructor(props) {
    super(props);

    const {
      initialTab
    } = props;

    switch (initialTab) {
      case 'coupon':
        this.state.index = 1;
        break;

      case 'point':
        this.state.index = 2;
        break;
    }
  }

  renderTabBar = props => {
    const {
      index, routes
    } = this.state;
    const {
      classes, showSmartBanner,
    } = this.props;

    const appBarStyle = showSmartBanner ? styles.appBarWithSmartBanner : styles.appBar;
    return (
      <AppBar position="sticky" style={appBarStyle} elevation={0}>
        <Tabs
          value={index}
          onChange={this.onChange}
          variant="fullWidth"
          classes={{
            root: classes.tabs,
            indicator: classes.indicator,
          }}
        >
          {routes.map(route => {
            return (
              <Tab
                key={route.key}
                label={route.title}
                styles={{minWidth:0}}
                classes={{ root: classes.tab, selected: classes.tabSelected, wrapper: classes.tabWrapper }}
                TouchRippleProps={{ classes: { rippleVisible: classes.rippleVisible } }}
              />
            );
          })}
        </Tabs>
      </AppBar>
    );
  };

  renderScene = ({ route }) => {
    const {
      auth, historySwitch,
      getUserCouponList,
      getUserMileageList,
      couponData,
      mileageData,
      showSmartBanner,
    } = this.props;
    const { index } = this.state;

    const props = {
      route,
      auth,
      historySwitch,
      showSmartBanner,
    };

    switch (route.key) {
      case 'tabLevel':
        return (
          <TabPanel value={index} index={0}>
            <TabLevel {...props} />
          </TabPanel>
        );

      case 'tabCoupon':
        return (
          <TabPanel value={index} index={1}>
            <TabCoupon
              {...props}
              {...couponData}
              getUserCouponList={getUserCouponList}
            />
          </TabPanel>
        );

      case 'tabPoint':
        return (
          <TabPanel value={index} index={2}>
            <TabPoint
              {...props}
              {...mileageData}
              getUserMileageList={getUserMileageList}
            />
          </TabPanel>
        );

      default:
        return null;
    }
  };

  onChange = (event, newValue) => {
    this.setState({ index: newValue });
  };

  render() {
    const { routes } = this.state;
    return (
      <div style={{ ...Styles.fullWidth, display: 'flex', flexDirection: 'column' }}>
        {this.renderTabBar()}

        <div style={{ ...Styles.fullWidth, display: 'flex', flexDirection: 'column' }}>
          {routes.map(route => {
            return this.renderScene({ route });
          })}
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(connect(
  state => ({
    showSmartBanner: state.globalLayoutInfo.showSmartBanner,
  }),
  null,
)(MemberInfoTabView));
