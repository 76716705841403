import React from 'react';
import {
  View, StyleSheet, Text, TouchableHighlight,
} from 'react-native';

import InstagramProfileImage from './InstagramProfileImage';
import PostThumbnail from './PostThumbnail';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';


export default class Collection extends React.PureComponent {
  getProductText() {
    const { post } = this.props;

    const productCount = post.products.length;
    if (productCount === 1) {
      return '';
    }
    return `다음 외 ${productCount - 1}개 상품`;
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { post, onPressPost, onPressHeartist, style } = this.props;
    const productCount = post.products.length;

    return (
      <TouchableHighlight
        key={post._id}
        underlayColor={Colors.touchEffectColorWhite}
        onPress={() => onPressPost(post._id)}
        style={style}
      >
        <View>
          <PostThumbnail
            post={post}
          />

          <View
            style={styles.detailContainer}
          >
            <InstagramProfileImage
              style={stylesHtml.profileImage}
              username={post.owner.username}
            />
            <View style={styles.textContainer}>
              <Text style={styles.profileText}>{post.owner.username}</Text>
              {(productCount > 1) &&
                (<Text style={styles.productText}>
                    {this.getProductText()}
                 </Text>)
              }
            </View>
          </View>
        </View>
      </TouchableHighlight>
    );
  }
}

const styles = StyleSheet.create({
  detailContainer: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 6,
  },
  textContainer: {
    flex: 1,
    flexGrow: 1,
    marginLeft: 10,
    justifyContent: 'center',
    marginVertical: 3,
  },
  profileText: {
    color: 'black',
    lineHeight: 14,
    ...Styles.SFUITextRegular,
    ...Styles.fontSize12,
  },
  productText: {
    marginTop: 4,
    color: 'black',
    lineHeight: 16,
    ...Styles.appleSDGothicNeoBold,
    ...Styles.fontSize13,
  },
});

const stylesHtml = {
  profileImage: {
    border: `1px solid ${Colors.profileImageBorder}`,
    borderRadius: 20,
    height: 40,
    width: 40,
  }
};
