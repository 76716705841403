import { camelCase } from 'lodash';
import React from 'react';
import { StyleSheet, View, ScrollView, Image, Text, TextInput, Switch } from 'react-native';
import { connect } from 'react-redux';
import { actions } from 'shared';
import { isEmail } from 'validator';
import { Snackbar } from '@material-ui/core';

import Button from '../nativeComponents/Button';
import Colors from '../constants/Colors';

export class LoginWithEmailVerifyScreen extends React.Component {
  constructor(props) {
    super(props);
    const { router, sendEmailVerification } = this.props;
    console.log(router);
    this.state = {
      snackbarMsg: '',
      snackbarOpen: false,
    };
  }

  async handleSendVerificationEmail() {
    const { router, sendEmailVerification } = this.props;
    const email = router.location.state.email;

    let msg;
    try {
      await sendEmailVerification(email);
      msg = '인증메일을 다시 보냈습니다. 메일을 확인하세요.';
    } catch (e) {
      msg = '인증메일을 다시 보내는데 실패했습니다. 잠시 후 다시 시도하세요.';
    }
    this.showSnackbar(msg);
  }

  showSnackbar(msg) {
    this.setState({
      snackbarMsg: msg,
      snackbarOpen: true,
    });
  }

  render() {
    const { router } = this.props;

    return (
      <View style={styles.container}>
        <ScrollView
          contentContainerStyle={styles.inputContainer}
          keyboardShouldPersistTaps="handled"
          scrollEnabled={false}
        >
          <Text style={styles.titleText}>이메일인증 필요</Text>
          <Text style={styles.descText}>
            이메일 인증이 완료되지 않았습니다.
            이메일 인증은 회원 가입시에 받으신 메일 내용에 따라 진행할 수 있습니다.
          </Text>
          <Text style={styles.descText}>
            만약 메일을 받지 못하셨다면, 아래 '인증메일 다시보내기' 버튼을 눌러
            인증을 위한 메일을 다시 받으실 수 있습니다.
          </Text>
          <Button
            containerStyle={styles.sendButton}
            onPress={() => this.handleSendVerificationEmail()}
            title="인증메일 다시보내기 / SEND VERIFICATION AGAIN"
            titleStyle={styles.sendText}
          />
          <Button
            containerStyle={styles.returnToLoginButton}
            onPress={() => router.push('/accounts/login')}
            title="이메일로 로그인하기 / LOGIN WITH EMAIL"
          />
        </ScrollView>
        <Snackbar
          autoHideDuration={2000}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
          style={{ textAlign: 'center' }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    flex: 1,
    justifyContent: 'space-between',
    margin: 25,
  },
  disabled: {
    backgroundColor: '#cccccc',
    borderColor: 'lightgrey',
    marginTop: 15,
    width: '100%',
  },
  inputContainer: {
    alignItems: 'center',
  },
  titleText: {
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 40,
  },
  descText: {
    fontSize: 18,
    marginBottom: 30,
  },
  sendButton: {
    backgroundColor: 'black',
    marginTop: 15,
    width: '100%',
  },
  sendText: {
    color: 'white',
  },
  returnToLoginButton: {
    marginTop: 15,
    width: '100%',
  },
});

export default connect(
  state => state,
  actions,
)(LoginWithEmailVerifyScreen);
