import React from 'react';
import { StyleSheet, View, Text, Platform, Image, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { actions } from 'shared';

import { Constants, Styles } from '../../../constants';
import {
  TouchableHighlightWithContainer,
  CloudinaryImage,
} from '../../../components-v2';
import { navigateToPreOrderDetailPage } from '../PreOrderDetailPage';


import Alarm18Icon from '../../../icons-v2/Alarm18Icon';
import Alarm18WhiteIcon from '../../../icons-v2/Alarm18WhiteIcon';


const styles = StyleSheet.create({
  container: {
  },
  remainDaysContainer: {
    position: 'absolute',
    justifyContent: 'flex-start',
    start: 30,
    bottom : 0,
    height: 37,
    flex: 1,
    flexShrink: 1,
    flexDirection: 'row',
  },
  remainDays: {
    flexShrink: 1,
    ...Styles.pretendard700,
    fontSize: 24,
    lineHeight: 28.8,
    color: '#FFFFFF',
    height: 37,
    paddingVertical: 4,
    paddingHorizontal: 10,
    textAlign: 'center',
    textAlignVertical: 'center',
    backgroundColor: '#000',
  },
  contentContainer: {
    marginTop: 14,
    paddingStart: 30,
  },
  statusText: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    marginRight: 10,
  },
  remainTimeText: {
    ...Styles.pretendard400,
    fontSize: 16,
    lineHeight: 19.2,
  },
  brand: {
    ...Styles.pretendard700,
    fontSize: 24,
    lineHeight: 28.8,
  },
  description: {
    ...Styles.pretendard400,
    fontSize: 18,
    lineHeight: 26,
    marginTop: 2,
  },
  period: {
    marginTop: 14 - 4,
    ...Styles.pretendard400,
    fontSize: 14,
    lineHeight: 16.8,
    marginTop: 6,
    opacity: 0.5,
  },
  buttonContainer: {
    marginTop: 24,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  button: {
    padding: null, // TouchableHighlightWithContainer 에 설정된 Styles.touchEffect 값 제거
    paddingHorizontal: 20,
    paddingTop: 10 - 2,
    paddingBottom: 10 - 2,
    borderWidth: 0.5,
  },
  buttonActive: {
    padding: null, // TouchableHighlightWithContainer 에 설정된 Styles.touchEffect 값 제거
    backgroundColor: 'black',
    paddingHorizontal: 20,
    paddingTop: 10 - 2,
    paddingBottom: 10 - 2,
    borderWidth: 0.5,
  },
  buttonText: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
  },
  buttonTextActive: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    color: 'white',
  },
});

const stylesHtml = {
  image: {
    marginLeft: 20,
    width: '100%',
    objectFit: 'cover',
  },
  imageInactive: {
    marginLeft: 20,
    width: '100%',
    objectFit: 'cover',
  },
}

class PreOrderItem extends React.PureComponent {
  componentDidMount() {
    this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  goToShopping = () => {
    const { 
      router,
      item,
      logEventClick,
    } = this.props;

    navigateToPreOrderDetailPage({preorderExhibitionId: item.id}, router);
    logEventClick({
      page_name: 'PreOrder',
      click_object_name: 'preorder',
      click_object_key: item.id
    });
  }

  renderStatusText(status, remainTimes) {
    let statusText = '';

    let seconds = Math.floor(remainTimes / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);
    seconds %= 60;
    minutes %= 60;
    hours %= 24;
    let remainTimeText = days + '일 ' + hours + '시간 ' + minutes + '분 ' + seconds + '초후';

    switch (status) {
      case 'before':
        statusText = '오픈예정';
        remainTimeText += ' 오픈'
        break;

      case 'ongoing':
        statusText = '진행중';
        remainTimeText += ' 종료'
        break;

      case 'after':
      default:
        return null;
    }

    return (
      <View style={{ marginHorizontal: 20, marginBottom: 6, flexDirection: 'row' }}>
        <Text style={styles.statusText}>{statusText}</Text>
        <Text style={styles.remainTimeText}>{remainTimeText}</Text>
      </View>
    ); 
  }

  renderButton(status) {
    const { item, following, setPreOrderAlarm, unsetPreOrderAlarm } = this.props;
    let styleButton = styles.button;
    let styleButtonText = styles.buttonText;
    let buttonText = null;
    let buttonIcon = null;
    let onPress = null;

    switch (status) {
      case 'before':
        if (following) {
          styleButton = styles.buttonActive;
          styleButtonText = styles.buttonTextActive;
          buttonText = '프리오더 알림 설정됨';
          buttonIcon = (<Alarm18WhiteIcon style={{ marginLeft: 6 }} />);
          onPress = () => unsetPreOrderAlarm(item.id);
        } else {
          buttonText = '프리오더 알림 설정하기';
          buttonIcon = (<Alarm18Icon style={{ marginLeft: 6 }} />);
          onPress = () => setPreOrderAlarm(item.id);
        }
        break;

      case 'ongoing':
        buttonText = '쇼핑 바로가기';
        onPress = () => this.goToShopping();
        break;

      case 'after':
      default:
        return null;
    }

    return (
      <View style={styles.buttonContainer}>
        <TouchableHighlightWithContainer
          style={styleButton}
          onPress={onPress}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={styleButtonText}>{buttonText}</Text>
            {buttonIcon}
          </View>
        </TouchableHighlightWithContainer>
      </View>
    );
  }

  formatDate(date) {
    const year = date.getFullYear().toString().slice(2);
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return year + '년 ' + month + '월 ' + day + '일';
  }

  getRemainDays(date) {
    const diffTime = date - new Date();
    const remainDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    if (remainDays <= 0) {
      return 0;
    }

    return remainDays;
  }

  render() {
    const { item } = this.props;
    const preorderStartDate = new Date(item.preorderStartDate * 1000);
    const preorderEndDate = new Date(item.preorderEndDate * 1000);

    let status = 'after';
    let remainTimes = 0;
    let imageStyle = stylesHtml.imageInactive
    let TouchableComponent = View;
    let goToShopping = () => null;

    const remainStartTimes = preorderStartDate - new Date();
    const remainEndTimes = preorderEndDate - new Date();

    let remainStartDays = -1;
    if (remainStartTimes > 0) {
      let today = new Date();
      const monthStr = (today.getMonth() + 1 > 9) ? (today.getMonth() + 1).toString() : "0" + (today.getMonth() + 1).toString();
      today = new Date(`${today.getFullYear()}-${monthStr}-${today.getDate()}`);
      const startDayMonthStr = (preorderStartDate.getMonth() + 1 > 9) ? (preorderStartDate.getMonth() + 1).toString() : "0" + (preorderStartDate.getMonth() + 1).toString();
      let startDay = new Date(`${preorderStartDate.getFullYear()}-${startDayMonthStr}-${preorderStartDate.getDate()}`);
      remainStartDays = (startDay - today) / (1000 * 60 * 60 * 24);
    }

    if (remainStartTimes > 0) {
      status = 'before';
      imageStyle = stylesHtml.image;
      remainTimes = remainStartTimes;
    } else if (remainEndTimes > 0) {
      status = 'ongoing';
      imageStyle = stylesHtml.image;
      remainTimes = remainEndTimes;
      TouchableComponent = TouchableOpacity;
      goToShopping = () => this.goToShopping();
    }

    const period_start = this.formatDate(preorderStartDate);
    const period_end = this.formatDate(preorderEndDate);

    const { publicId } = item.thumbnailList[0];
    return (
      <View style={styles.container}>
        {this.renderStatusText(status, remainTimes)}
        <View>
          <View style={{ width: '100%' }}>
            <TouchableComponent onPress={goToShopping}>
              <CloudinaryImage
                publicId={publicId}
                style={imageStyle}
                options={{
                  crop: 'limit',
                  height: 1080,
                  width: 1080,
                }}
                aspectRatio={355 / 230}
              />
            </TouchableComponent>
          </View>
          { remainStartDays >= 0 ?  (
            <View style={styles.remainDaysContainer}>
            <Text style={styles.remainDays}>D-{remainStartDays}</Text>
            </View>
          ) : null }
          { remainEndTimes < 0 && (
            <View style={styles.remainDaysContainer}>
              <Text style={styles.remainDays}>END</Text>
            </View>
          )}
        </View>

        <View style={styles.contentContainer}>

          <TouchableComponent onPress={goToShopping}>
            <Text style={styles.brand}>{item.title}</Text>
            <Text style={styles.description}>{item.subtitle}</Text>
          </TouchableComponent>
          <Text style={styles.period}>{period_start} ~ {period_end}</Text>

          {this.renderButton(status)}
        </View>
      </View>
    );
  }
}

export default connect(
  (state, { navigation }) => ({
  }),
  actions,
)(PreOrderItem);
