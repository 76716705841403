/* eslint-disable import/prefer-default-export */

export function formatPrice(price) {
  if (price === 0) {
    return '';
  }

  const sign = price > 0 ? '+' : '-';
  const unsignedPrice = price > 0 ? price : -price;

  const zeroPad = (num, places) => String(num).padStart(places, '0');

  if (unsignedPrice < 1000) {
    const formattedPrice = zeroPad(unsignedPrice, 3);
    return `${sign}${formattedPrice}`;
  } if (unsignedPrice < 1000 * 1000) {
    const part1 = Math.floor(unsignedPrice / 1000);
    const part2 = zeroPad(unsignedPrice % 1000, 3);
    const formattedPrice = `${part1},${part2}`;
    return `${sign}${formattedPrice}`;
  }
  const part1 = Math.floor(unsignedPrice / 1000 / 1000);
  const part2 = zeroPad(Math.floor(unsignedPrice / 1000) % 1000, 3);
  const part3 = zeroPad(unsignedPrice % 1000, 3);
  const formattedPrice = `${part1},${part2},${part3}`;
  return `${sign}${formattedPrice}`;
}
