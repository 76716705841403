import React from 'react';
import PropTypes from 'prop-types';
import {
  View, StyleSheet, Text, ActivityIndicator,
  // eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

// eslint-disable-next-line import/no-extraneous-dependencies
import { loadEntities } from 'shared';
import Spinner from '../../../components/Spinner';
import Product from '../../../nativeComponents/Product';
import Constants from '../../../constants/Constants';
import Styles from '../../../constants/Styles';
import HeartitBlankPageLogo from '../../../icons/HeartitBlankPageLogo';
import LoadMoreButton from '../../../nativeComponents/LoadMoreButton';


const HEADER_HEIGHT = 48;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  listContainer: {
    marginHorizontal: 6,
    marginTop: 4,
  },
  product: {
    marginBottom: 11,
  },
  productContainer: {
    width: '100%',
  },
  imageContainerStyle: {
    paddingTop: '70%',
  },
  blankViewContainer: {
    position: 'absolute',
    start: 0,
    end: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: Constants.mainHeaderHeight + Constants.tabbarHeight + HEADER_HEIGHT,
  },
  blankViewContentContainer: {
    alignItems: 'center',
  },
  blankViewMessage: {
    height: 24,
    marginTop: 15,
    ...Styles.appleSDGothicNeoBold,
    fontSize: 20,
    lineHeight: 24,
  },
  loadMoreContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  sellerInfoContainer: {
    marginTop: 30,
    marginHorizontal: -10,
    marginBottom: -1 * Constants.tabbarHeight,
  },
});


class PreOrderProductList extends React.Component {
  state = {
    isFetchingNextPage: false,
  };

  componentDidUpdate(prevProps) {
    const { pagination } = this.props;

    if (prevProps.pagination.isFetching && !pagination.isFetching) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isFetchingNextPage: false });
    }
  }

  onPressProduct = product => () => {
    const { router } = this.props;
    router.push(`/product/${product.goodsNo}`);
  }

  renderProduct = product => (
    <GridListTile key={product.goodsNo}>
      <Product
        onPressProduct={this.onPressProduct(product)}
        product={product}
        style={styles.product}
        imageContainerStyle={styles.imageContainerStyle}
        containerStyle={styles.productContainer}
        isShopProduct
        isPreOrder
        enableLabelPreOrder
      />
    </GridListTile>
  );

  renderFooter = () => {
    const { isFetchingNextPage } = this.state;
    const { pagination } = this.props;

    const showIndicator = pagination.nextPage !== null && isFetchingNextPage;

    return (
      <View>
        {showIndicator && (
          <View
            style={{
              position: 'relative',
              width: '100%',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              paddingVertical: 20,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <ActivityIndicator animating size="small" color="gray" />
          </View>
        )}
      </View>
    );
  }

  renderBlankView() {
    const { showSmartBanner, categoryName } = this.props;

    let message = '진행중인 프리오더가 없습니다';

    if (categoryName === '예정') {
      message = '예정된 프리오더가 없습니다';
    } else if (categoryName === '종료') {
      message = '종료된 프리오더가 없습니다';
    }

    return (
      <View style={styles.blankViewContainer}>
        <View style={[
          styles.blankViewContentContainer,
          {
            marginBottom: showSmartBanner ? Constants.smartBannerHeight : 0,
          },
        ]}
        >
          <HeartitBlankPageLogo />
          <Text style={styles.blankViewMessage}>{message}</Text>
        </View>
      </View>
    );
  }

  loadData(nextPage) {
    const { loadData } = this.props;
    this.setState({ isFetchingNextPage: nextPage }, () => {
      loadData(nextPage);
    });
  }

  renderLoadMore() {
    const onPress = () => {
      const {
        pagination,
      } = this.props;

      if (pagination.nextPageUrl) {
        this.loadData(true);
      }
    };

    return (
      <View style={styles.loadMoreContainer}>
        <LoadMoreButton onPress={onPress} />
      </View>
    );
  }

  render() {
    const { shopProducts, pagination } = this.props;

    if (pagination.isFetching && (!shopProducts || shopProducts.length === 0)) {
      return (<Spinner />);
    }

    const data = [
      ...shopProducts,
    ];

    return (
      <View style={styles.container}>
        {data.length > 0 ? (
          <View style={styles.listContainer}>
            <GridList
              cellHeight="auto"
              cols={1}
              spacing={1}
            >
              {data.map(product => this.renderProduct(product))}
            </GridList>
          </View>
        ) : (
          this.renderBlankView()
        )}

        {(!pagination.isFetching && pagination.nextPageUrl) ? (
          this.renderLoadMore()
        ) : (
          null
        )}
        {data.length > 0 && this.renderFooter()}
      </View>
    );
  }
}

PreOrderProductList.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  categoryId: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  shopProducts: PropTypes.array,
  loadData: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

PreOrderProductList.defaultProps = {
  shopProducts: [],
};


export default connect(
  (state, props) => {
    const { categoryId } = props;
    const key = `shopProducts_${categoryId}`;

    return ({
      ...loadEntities(state, key, 'shopProducts', 'pagination', 'shopProducts'),
    });
  },
)(PreOrderProductList);
