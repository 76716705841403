import React from 'react'

import { 
  Dimensions, StyleSheet, View, Text, Animated, FlatList,
} from 'react-native';

import { Constants, Styles, Colors, SizeData } from '../constants'


const {
  width: windowWidth,
  height: windowHeight,
} = Dimensions.get('window');

const WIDTH_ITEM_TITLE = 80;
const WIDTH_ITEM_DATA = 64;

const styles = StyleSheet.create({
  container: {
  },
  columnContainer: {
    borderBottomWidth: 0.5,
    borderBottomColor: '#000',
  },
  columnHeaderContainer: {
    borderTopWidth: 0.5,
    borderTopColor: '#000',
    borderBottomWidth: 0.5,
    borderBottomColor: '#000',
  },
  itemContainer: {
    borderBottomWidth: 0.5,
    borderBottomColor: '#000',
  },
  sectionContainer: {
    marginTop: 20,
  },
  sizeHeader: {
    marginHorizontal: Constants.viewHorizontalPadding,
    marginBottom: 20,
  },
  sectionTitle: {
    ...Styles.pretendard600,
    fontSize: 14,
    lineHeight: 18,
    color: Colors.defaultGray08,
  },
  itemHeaderText: {
    paddingTop: 4.5,
    paddingBottom: 15,
    fontSize: 12,
    lineHeight: 14.4,
    ...Styles.pretendard600,
  },
  itemTitleText: {
    width: WIDTH_ITEM_TITLE,
  },
  itemText: {
    ...Styles.pretendard400,
    fontSize: 12,
    lineHeight: 14.4,
    paddingTop: 5,
    paddingBottom: 15,
  },
  itemDataText: {
    width: WIDTH_ITEM_DATA,
  },
});


class SizeGuide extends React.Component {
  state = {
    lastContentHeight: 0,
    totalContentHeight: windowHeight,
  }

  renderTitle(item) {
    return (
      <View style={styles.columnContainer}>
        <View style={styles.columnHeaderContainer}>
          <Text style={[styles.itemTitleText, styles.itemHeaderText]}>{item.name}</Text>
        </View>
        {item.alias.map((e, index) => (e.name !== 'International') && (
          <Text
            key={`${index}`}
            style={[styles.itemText, styles.itemTitleText]}
            >
            {e.name}
          </Text>
        ))}
      </View>
    )
  }

  renderItem(item) {
    if (item.type === 'title') {
      return this.renderTitle(item)
    }

    return (
      <View style={styles.itemContainer}>
        <View style={styles.columnHeaderContainer}>
          <Text style={[styles.itemDataText, styles.itemHeaderText]}>{item.name}</Text>
        </View>
        {Object.entries(item.alias).map(([key, value], index) => (
          <Text
            key={`${index}`}
            style={[styles.itemText, styles.itemDataText]}
            >
            {value}
          </Text>
        ))}
      </View>
    )
  }

  renderSection(title, subType, isLast) {
    const sizeStandardList = SizeData.SIZE_STANDARD_LIST.filter((item) => item.subType === subType);
    const sizeItems = SizeData.SIZE_LIST.filter((item) => item.subType === subType);

    const data =[
      { type: 'title', name: 'Size', alias: sizeStandardList },
      ...sizeItems,
    ];

    return (
      <View style={styles.sectionContainer}
        { ...(isLast && {
          onLayout: e => {
            this.setState({ lastContentHeight: e.nativeEvent.layout.height })
          }
        })}
      >
        <View style={styles.sizeHeader}>
          <Text style={styles.sectionTitle}>{title} 사이즈</Text>
        </View>

        <Animated.FlatList
          keyExtractor={(item, index) => `${index}`} // eslint-disable-line no-underscore-dangle
          data={data}
          renderItem={({ item }) => this.renderItem(item)}
          ListHeaderComponent={() => <View style={{ width: Constants.viewHorizontalPadding }} />}
          ListFooterComponent={() => <View style={{ width: Constants.viewHorizontalPadding }} />}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          horizontal
          bounces={false}
          />

      </View>
    );
  }

  render() {
    const { targetSubType } = this.props;

    const {
      lastContentHeight,
      totalContentHeight,
    } = this.state;

    const data = [
      {title: '옷', subType: 'clothing'},
      {title: '신발', subType: 'shoes', isLast: true},
    ];

    let initialScrollIndex = 0;
    if (targetSubType) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].subType === targetSubType) {
          initialScrollIndex = i;
          break;
        }
      }  
    }

    const footerHeight = 62;

    return (
      <FlatList
        keyExtractor={(item, index) => `${index}`} // eslint-disable-line no-underscore-dangle
        ref={ref => this.list = ref}
        style={styles.container}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        bounces={false}
        data={data}
        renderItem={({ item: { title, subType, isLast=false }}) => this.renderSection(title, subType, isLast)}
        ListFooterComponent={() => <View style={{ height: footerHeight }} />}
        ItemSeparatorComponent={() => (<View style={{ height: 25 }} />)}
        onScrollToIndexFailed={() => {}}
        onLayout={e => {
          const totalContentHeight = e.nativeEvent.layout.height;
          this.setState({ totalContentHeight }, () => {
            this.list.scrollToIndex({
              animated: false,
              index: initialScrollIndex,
            });
          })
        }}
      />
    );
  }   
}

export default SizeGuide;
