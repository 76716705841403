import React from 'react';
import {
  StyleSheet, Text, TextInput, View,
  TouchableHighlight, Dimensions,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { isEmail } from 'validator';

import BirthDateSelect from '../components/BirthDateSelect';
import CustomDialog from '../components/CustomDialog';

import CheckComplete from '../icons/CheckComplete';

import CheckBox from './CheckBox';
import PhoneVerificationForm, { isPhoneNumber } from './PhoneVerificationForm';
import Button from './Button';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';
import LegalPolicyPage from '../containers/LegalPolicyPage';


const { width: windowWidth } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  termContainer: {
    marginTop: 10,
    marginBottom: 30,
  },
  joinButtonContainer: {
    ...Styles.buttonDark,
    marginBottom: 20,
  },
  joinDisabledButtonContainer: {
    ...Styles.buttonDarkDisabled,
  },
  joinButton: {
    backgroundColor: 'transparent',
    width: '100%',
    height: 48,
    padding: 0,
    borderWidth: 0,
  },
  joinText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 14,
    marginTop: 16,
    marginBottom: 15,
    height: 17,
    lineHeight: 17,
    ...Styles.buttonDarkText,
  },
  joinDisabledText: {
    ...Styles.buttonDarkTextDisabled,
  },
  inputText: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    color: 'black',
    paddingTop: 17,
    paddingBottom: 16,
    paddingHorizontal: 16,
    height: 52,
  },
  inputBorder: {
    width: '100%',
    marginBottom: 10,
    backgroundColor: Colors.semiGray,
  },
  checkIcon: {
    position: 'absolute',
    right: 14,
    top: 14,
    bottom: 14,
    width: 24,
    height: 24,
  },
  errorInputBorder: {
    borderWidth: 1,
    borderColor: 'rgb(255, 62, 62)',
  },
  agreeContainer: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  agreeCheckboxContainer: {
    marginEnd: 12,
  },
  agreeCheckbox: {
    marginEnd: 12,
    width: 24,
    height: 24,
    backgroundColor: 'rgba(0, 0, 0, 0.201)',
  },
  agreeContentContainer: {
    maxWidth: windowWidth - 20 * 2 - 24 - 12,
  },
  agreeText: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 14,
    lineHeight: 17,
    marginTop: 3,
    marginBottom: 4,
    color: 'black',
  },
  agreeLink: {
    ...Styles.appleSDGothicNeoMedium,
    fontSize: 14,
    color: 'black',
    textDecorationLine: 'underline',
    lineHeight: 17,
    height: 17,
  },
  agreeLinkButton: {
    height: 17,
  },
  agreeAllContainer: {
    marginTop: 10,
    marginBottom: 20,
  },
  agreeAllText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 15,
    lineHeight: 19,
    height: 19,
    marginTop: 2,
    marginBottom: 3,
  },
  agreeTermContainer: {
  },
  agreeTermText: {
  },
  agreePrivacyContainer: {
  },
  agreePrivacyText: {
  },
  agreeMarketingContainer: {
    marginBottom: 0,
  },
  agreeMarketingText: {
  },
  errorContainer: {
    width: '100%',
    marginTop: -4,
    marginBottom: 15,
  },
  errorText: {
    flexWrap: 'wrap',
    lineHeight: 20,
    fontSize: 15,
    color: 'rgb(252,43,56)',
    ...Styles.appleSDGothicNeoLight,
  },
  socialTypeContainer: {
    width: '100%',
    marginTop: -4,
    marginBottom: 15,
  },
  socialTypeText: {
    flexWrap: 'wrap',
    lineHeight: 20,
    fontSize: 15,
    color: 'rgb(25, 67, 255)',
    ...Styles.appleSDGothicNeoLight,
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});


class ProfileInputForm extends React.Component {
  submitButton = null;

  nameInputRef = null;

  phoneNumberInputRef = null;

  constructor(props) {
    super(props);

    const {
      emailDefault, emailEditable = false,
      nameDefault, phoneDefault, birthYearDefault,
    } = props;

    this.state = {
      birthYear: '',
      email: '',
      emailEditable: true,
      name: '',
      phone: '',
      phoneVerified: false,
      phoneIgnoreOldUser: false,
      termAgreed: false,
      privacyAgreed: false,
      marketingAgreed: false,
      emailError: '',
      nameError: '',
      phoneError: '',
      termAgreedError: '',
      privacyAgreedError: '',
      showAgreeDialog: false,
      showIgnoreOldUserDialog: false,
      ignoreOldUserEmail: '',
    };

    if (emailDefault && emailDefault !== '') {
      this.state.email = emailDefault;
      this.state.emailEditable = emailEditable;
    }
    if (nameDefault) {
      this.state.name = nameDefault;
    }
    if (phoneDefault) {
      this.state.phone = phoneDefault;
    }
    if (birthYearDefault) {
      this.state.birthYear = new Date(birthYearDefault);
    }
  }

  resetErrorMessage = ({
    emailError = '',
    nameError = '',
    phoneError = '',
    termAgreedError = '',
    privacyAgreedError = '',
  }, callback = () => {}) => {
    this.setState({
      emailError,
      nameError,
      phoneError,
      termAgreedError,
      privacyAgreedError,
    }, callback);
  }

  componentDidMount() {
    // console.log('ProfileInputForm componentDidMount');

    const {
      emailDefault, emailEditable = false,
      nameDefault, phoneDefault, birthYearDefault,
    } = this.props;

    const newState = {};
    if (emailDefault && emailDefault !== '') {
      newState.email = emailDefault;
      newState.emailEditable = emailEditable;
    }
    if (nameDefault) {
      newState.name = nameDefault;
    }
    if (phoneDefault) {
      newState.phone = phoneDefault;
    }
    if (birthYearDefault) {
      newState.birthYear = new Date(birthYearDefault);
    }
    this.setState(newState);
  }

  onPressCheckboxAll = () => {
    const { termAgreed, privacyAgreed, marketingAgreed } = this.state;
    const checked = termAgreed && privacyAgreed && marketingAgreed;
    this.setState({
      termAgreed: !checked,
      privacyAgreed: !checked,
      marketingAgreed: !checked,
    });
  }

  onPressTerms = type => () => {
    const { router } = this.props;

    router.push(`${window.location.pathname}/legal/${type}${window.location.search}`);
  };

  onSubmit = evt => {
    evt.preventDefault();

    const { showPolicy, handleUpdate: onSubmitCallback } = this.props;
    const { email, name, phone, phoneVerified, birthYear: birthYearInDate,
      termAgreed, privacyAgreed, marketingAgreed } = this.state;

    if (!this.verifyInputValues()) {
      return;
    }

    let birthYear;
    if (birthYearInDate) {
      const year = birthYearInDate.getFullYear();
      const month = birthYearInDate.getMonth() + 1;
      const day = birthYearInDate.getDate();
      birthYear = [year, month, day].join('/');
    }

    if (showPolicy) {
      onSubmitCallback({
        email,
        name,
        phone,
        phoneVerified,
        birthYear,
        termAgreed,
        privacyAgreed,
        marketingAgreed,
        validationComplete: true,
      });
      return;
    }

    onSubmitCallback({
      email,
      name,
      phone,
      phoneVerified,
      birthYear,
      validationComplete: true,
    });
  }

  onPressSubmitButton = () => {
    if (this.submitButton) {
      this.submitButton.click();
    }
  }

  verifyEmail = (val = null) => {
    let { email } = this.state;
    if (val !== null) {
      email = val;
    }

    if (!email || email === '') {
      return '이메일을 입력하세요.';
    }

    if (!isEmail(email)) {
      return '이메일 형식을 확인하세요.';
    }

    return '';
  }

  verifyName = (val = null) => {
    let { name } = this.state;
    if (val !== null) {
      name = val;
    }

    if (!name || name === '') {
      return '이름을 입력하세요.';
    }

    return '';
  }

  verifyInputValues(defaultValue = {}) {
    const { type } = this.props;
    const { phone, phoneVerified, termAgreed, privacyAgreed } = this.state;

    let verified = true;
    let newState = {};

    newState.emailError = this.verifyEmail();
    newState.nameError = this.verifyName();

    if (type !== 'appleId') {
      if (!phone || phone === '') {
        newState.phoneError = '휴대폰 번호를 입력하세요.';
      } else if (!isPhoneNumber(phone)) {
        newState.phoneError = '휴대폰 번호 형식을 확인하세요.';
      } else if (!phoneVerified) {
        newState.phoneError = '휴대폰 번호 인증을 해주세요.';
      }
    }

    if (!termAgreed) {
      newState.termAgreedError = '이용약관 동의가 필요합니다';
    }
    if (!privacyAgreed) {
      newState.privacyAgreedError = '개인정보 수집 및 이용 동의가 필요합니다';
    }

    Object.values(newState).forEach(msg => {
      if (msg.length > 0) {
        verified = false;
      }
    });

    newState = { ...newState, ...defaultValue };
    verified = verified && Object.values(defaultValue).length <= 0;
    this.resetErrorMessage(newState, this.showAgreeCheckAlert);

    return verified;
  }

  showAgreeCheckAlert = () => {
    const { termAgreed, privacyAgreed } = this.state;
    if (termAgreed && privacyAgreed) {
      return;
    }

    this.setState({ showAgreeDialog: true });
  }

  showIgnoreOldUserAlert = email => {
    this.setState({
      showIgnoreOldUserDialog: true,
      ignoreOldUserEmail: email,
    });
  }

  async handleAll() {
    if (!this.verifyInputValues()) {
      return;
    }
    this.handleJoin();
  }

  renderErrorMsg = msg => (
    <View style={styles.errorContainer}>
      <Text style={styles.errorText}>
        {msg}
      </Text>
    </View>
  )

  getSocialAccountTypeStr = () => {
    const { type } = this.props;

    switch (type) {
    case 'facebook':
      return 'Facebook으로 연결됨';
    case 'naver':
      return 'Naver로 연결됨';
    case 'kakao':
      return 'Kakao로 연결됨';
    case 'appleId':
      return 'Apple ID로 연결됨';
    default:
      break;
    }

    return '';
  }

  isJoinButtonDisabled = () => {
    const { type } = this.props;
    const {
      email, phone, phoneVerified, name, termAgreed, privacyAgreed,
      emailError, nameError, phoneError,
    } = this.state;

    return (
      email.length === 0 || name.length === 0
        || (type !== 'appleId' && phone.length === 0)
        || (type !== 'appleId' && !phoneVerified)
        || !termAgreed || !privacyAgreed
        || emailError.length > 0
        || nameError.length > 0 || phoneError.length > 0
    );
  }

  renderPhoneVerificationForm() {
    const { router } = this.props;
    const {
      phone, phoneVerified, phoneIgnoreOldUser,
      phoneError,
      showIgnoreOldUserDialog, ignoreOldUserEmail,
    } = this.state;

    return (
      <View style={{ width: '100%' }}>
        <PhoneVerificationForm
          onPhoneNumberInputRef={ref => { this.phoneNumberInputRef = ref; }}
          defaultPhoneNumber={phone}
          defaultVerified={phoneVerified}
          showAlert={msg => { this.setState({ phoneError: msg }); }}
          showIgnoreOldUserAlert={oldUserEmail => this.showIgnoreOldUserAlert(oldUserEmail)}
          ignoreOldUser={phoneIgnoreOldUser}
          onChangePhoneNumber={value => { this.setState({ phone: value }); }}
          verificationChanged={verified => { this.setState({ phoneVerified: verified }); }}
          showErrorBorder={phoneError.length > 0}
        />
        { phoneError.length > 0 && this.renderErrorMsg(phoneError) }

        <CustomDialog
          dialogOpen={showIgnoreOldUserDialog}
          onConfirm={() => {
            this.setState({
              showIgnoreOldUserDialog: false,
              phoneIgnoreOldUser: true,
            });
          }}
          onCancel={() => {
            this.setState({
              showIgnoreOldUserDialog: false,
              phoneIgnoreOldUser: false,
            }, () => {
              router.push({
                pathname: '/accounts/login/',
              });
            });
          }}
          onClose={() => {
            this.setState({ showIgnoreOldUserDialog: false });
          }}
          confirmColor="default"
          confirmText="가입 진행하기"
          cancelText="로그인하기"
          title="이미 가입된 휴대폰 번호입니다."
          description={(
            <div>
              {`${ignoreOldUserEmail} 으로 가입된 번호입니다. 그래도 가입을 진행하시겠습니까?`}
            </div>
          )}
        />
      </View>
    );
  }

  renderInputForm() {
    const { btnText, showPolicy = true, children } = this.props;
    const {
      birthYear, email, emailEditable,
      name, termAgreed, privacyAgreed, marketingAgreed,
      emailError, nameError,
      termAgreedError, privacyAgreedError,
      showAgreeDialog,
    } = this.state;

    return (
      <View style={styles.container}>
        <form onSubmit={this.onSubmit}>
          <View style={styles.inputBorder}>
            <TextInput
              ref={this.name}
              autoCapitalize="none"
              keyboardType="email-address"
              onChangeText={val => this.setState({ email: val, emailError: this.verifyEmail(val) })}
              placeholder="이메일"
              placeholderTextColor={Colors.placeholderColor}
              style={[
                styles.inputText,
                emailError.length > 0 ? styles.errorInputBorder : {},
              ]}
              value={email}
              editable={emailEditable}
              onSubmitEditing={() => {
                if (this.nameInputRef) {
                  this.nameInputRef.focus();
                }
              }}
            />
            {email.length > 0 && emailError.length <= 0 && (
              <View style={styles.checkIcon}>
                <CheckComplete />
              </View>
            )}
          </View>
          { emailError.length <= 0 && (
            <View style={styles.socialTypeContainer}>
              <Text style={styles.socialTypeText}>
                { this.getSocialAccountTypeStr() }
              </Text>
            </View>
          )}
          { emailError.length > 0 && this.renderErrorMsg(emailError) }

          { this.props.type !== 'appleId' && (
            <React.Fragment>
              <View style={styles.inputBorder}>
                <TextInput
                  ref={ref => { this.nameInputRef = ref; }}
                  autoCapitalize="none"
                  keyboardType="default"
                  textContentType="name"
                  onChangeText={val => this.setState({ name: val, nameError: this.verifyName(val) })}
                  placeholder="이름"
                  placeholderTextColor={Colors.placeholderColor}
                  style={[
                    styles.inputText,
                    nameError.length > 0 ? styles.errorInputBorder : {},
                    { outline: 'none' },
                  ]}
                  value={name}
                  onSubmitEditing={() => {
                    if (this.phoneNumberInputRef) {
                      this.phoneNumberInputRef.focus();
                    }
                  }}
                />
                {name.length > 0 && nameError.length <= 0 && (
                  <View style={styles.checkIcon}>
                    <CheckComplete />
                  </View>
                )}
              </View>
              { nameError.length > 0 && this.renderErrorMsg(nameError) }

              {this.renderPhoneVerificationForm()}
            </React.Fragment>
          )}

          <BirthDateSelect
            containerStyle={[styles.inputBorder, styles.inputText]}
            onBirthDateChanged={val => this.setState({ birthYear: val })}
            value={birthYear}
            placeholder="생년월일 입력 (선택 사항)"
          />

          {children}

          {showPolicy && (
            <View style={styles.termContainer}>
              <View style={[styles.agreeContainer, styles.agreeAllContainer]}>
                <View style={styles.agreeCheckboxContainer}>
                  <CheckBox
                    onPress={this.onPressCheckboxAll}
                    checked={termAgreed && privacyAgreed && marketingAgreed}
                  />
                </View>
                <Text style={[styles.agreeText, styles.agreeAllText]}>
                  전체 동의
                </Text>
              </View>
              <View style={[styles.agreeContainer, styles.agreeTermContainer]}>
                <View style={styles.agreeCheckboxContainer}>
                  <CheckBox
                    onPress={() => this.setState({ termAgreed: !termAgreed })}
                    checked={termAgreed}
                  />
                </View>
                <View style={styles.agreeContentContainer}>
                  <Text style={[styles.agreeText, styles.agreeTermText]}>
                    (필수)본인은 14세 이상이며 이용약관에 동의합니다

                    <View style={styles.agreeLinkButton}>
                      <TouchableHighlight
                        onPress={this.onPressTerms('terms')}
                        underlayColor={Colors.touchEffectColorWhite}
                        style={styles.touchEffect}
                      >
                        <Text style={styles.agreeLink}>
                          본문보기
                        </Text>
                      </TouchableHighlight>
                    </View>
                  </Text>
                </View>
              </View>
              <View style={[styles.agreeContainer, styles.agreePrivacyContainer]}>
                <View style={styles.agreeCheckboxContainer}>
                  <CheckBox
                    onPress={() => this.setState({ privacyAgreed: !privacyAgreed })}
                    checked={privacyAgreed}
                  />
                </View>
                <View style={styles.agreeContentContainer}>
                  <Text style={[styles.agreeText, styles.agreePrivacyText]}>
                    (필수)개인정보 수집 및 이용에 동의합니다

                    <View style={styles.agreeLinkButton}>
                      <TouchableHighlight
                        onPress={this.onPressTerms('privacy')}
                        underlayColor={Colors.touchEffectColorWhite}
                        style={styles.touchEffect}
                      >
                        <Text style={styles.agreeLink}>
                          본문보기
                        </Text>
                      </TouchableHighlight>
                    </View>
                  </Text>
                </View>
              </View>
              <View style={[styles.agreeContainer, styles.agreeMarketingContainer]}>
                <View style={styles.agreeCheckboxContainer}>
                  <CheckBox
                    onPress={() => this.setState({ marketingAgreed: !marketingAgreed })}
                    checked={marketingAgreed}
                  />
                </View>
                <View style={styles.agreeContentContainer}>
                  <Text style={[styles.agreeText, styles.agreeMarketingText]}>
                    (선택)마케팅 정보 수신에 동의합니다

                    {/* <View style={styles.agreeLinkButton}>
                      <TouchableHighlight
                        onPress={this.onPressTerms('marketing')}
                        underlayColor={Colors.touchEffectColorWhite}
                        style={styles.touchEffect}
                      >
                        <Text style={styles.agreeLink}>
                          본문보기
                        </Text>
                      </TouchableHighlight>
                    </View> */}
                  </Text>
                </View>
              </View>
            </View>
          )}

          <View style={styles.joinButtonContainer}>
            <Button
              containerStyle={styles.joinButton}
              onPress={this.onPressSubmitButton}
              title={btnText}
              titleStyle={styles.joinText}
              underlayColor={Colors.touchEffectColorBlack}
              disabled={this.isJoinButtonDisabled()}
            />
          </View>
          <button
            ref={ref => { this.submitButton = ref; }}
            className="d-none edit-profile-save-btn"
            type="submit"
          >
            {btnText}
          </button>
        </form>

        <CustomDialog
          dialogOpen={showAgreeDialog}
          onConfirm={() => this.setState({ showAgreeDialog: false })}
          onClose={() => this.setState({ showAgreeDialog: false })}
          showCancel={false}
          confirmColor="default"
          description={(
            <div style={{ textAlign: 'center' }}>
              { (!termAgreed) ? termAgreedError : privacyAgreedError }
            </div>
          )}
        />

      </View>
    );
  }

  render() {
    const { legalPolicyType } = this.props;
    if (legalPolicyType) {
      return <LegalPolicyPage params={{ type: legalPolicyType }} />;
    }

    return this.renderInputForm();
  }
}

export default ProfileInputForm;
