import * as React from 'react';

function SvgImgLogoKakao(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={54} height={54} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={27} cy={27} r={27} fill="#ECDC58" />
        <path
          fill="#FFF"
          d="M26.5 15C18.492 15 12 20.008 12 26.187c0 4.021 2.75 7.546 6.879 9.517a669.218 669.218 0 00-1.258 4.624c-.196.77.29.759.607.552.25-.162 3.974-2.633 5.58-3.7.872.127 1.772.194 2.692.194 8.008 0 14.5-5.01 14.5-11.187C41 20.008 34.508 15 26.5 15"
        />
      </g>
    </svg>
  );
}

export default SvgImgLogoKakao;

