import React from 'react';
import {
  StyleSheet, View, Dimensions, Animated, Platform,
  Text, ScrollView, FlatList,
} from 'react-native';
import NumberFormat from 'react-number-format';

import { Constants, Styles, Colors } from '../../../constants';
import Button from '../../../components-v2/Button';
import ChevronRightIcon from '../../../icons-v2/ChevronRightIcon';

const initialLayout = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
  headerContainer: {
    paddingTop: 10,
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  currentLevelContainer: {
    flexDirection: 'row',
    borderWidth: 0.5,
    borderColor: '#000',
    height: 80,
  },
  currentLevelSubContainer: {
    flex: 1,
    marginVertical: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  currentLevelTitle: {
    fontSize: 15,
    lineHeight: 18,
    color: '#000',
    ...Styles.pretendard400,
    opacity: 0.5,
  },
  currentLevelValue: {
    fontSize: 20,
    lineHeight: 24,
    color: '#000',
    ...Styles.pretendard600,
    marginTop: 3,
  },
  levelBarContainer: {
    marginTop: 20,
    marginBottom: 38,
  },
  pointContainer: {
    flexDirection: 'row',
  },
  pointTextContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  pointTextCenterContainer: {
    position: 'absolute',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pointText: {
    fontSize: 13,
    lineHeight: 15.6,
    color: '#000',
    opacity: 0.5,
    ...Styles.pretendard400,
    marginTop: 6,
  },
  barContainer: {
    height: 34,
  },
  backgroundBar: {
    flexDirection: 'row',
    width: '100%'//Math.min(initialLayout.width, 960) - Constants.viewHorizontalPadding * 2,
  },
  backgroundBarSegment: {
    flex: 1,
    height: 34,
    backgroundColor: '#0000000f',
    marginRight: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  activeBarWing: {
    position: 'absolute',
    top: -6,
    ...Platform.select({
      ios: {
        zIndex: 999,
      },
      android: {
        elevation: 999,
      },
    }),
    height: 34 + 6 * 2,
    width: 2,
    backgroundColor: '#000',
  },
  activeBar: {
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    ...Platform.select({
      ios: {
        zIndex: 999,
      },
      android: {
        elevation: 999,
      },
    }),
    height: 34,
    backgroundColor: 'transparent',
  },
  levelSegmentText: {
    fontSize: 13,
    lineHeight: 15.6,
    ...Styles.pretendard400,
    color: '#000',
    backgroundColor: 'transparent',
  },
  levelSegmentTextActive: {
    color: '#fff',
    ...Styles.pretendard600,
  },
  levelBenefitContainer: {
  },
  levelBenefitTitleContainer: {
  },
  levelBenefitTitle: {
    fontSize: 18,
    lineHeight: 21.6,
    color: '#000',
    ...Styles.pretendard600,
  },
  levelBenefitContentContainer: {
    marginTop: 4,
  },
  levelBenefitContentText: {
    color: '#000',
    fontSize: 15,
    lineHeight: 18,
    ...Styles.pretendard400,
  },
  levelBenefitDetailButtonContainer: {
    flex: 1,
    marginTop: 20,
    marginBottom: 40 - 0.5 / 2,
  },
  levelBenefitDetailButton: {
    height: 25,
    padding: 0,
    borderWidth: 0,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  levelBenefitDetailButtonText: {
    fontSize: 16,
    lineHeight: 19.2,
    color: Colors.primaryBlue,
    ...Styles.pretendard600,
  },
  contentHeaderContainer: {
    height: 40,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: Constants.viewHorizontalPadding,
    marginBottom: 10,
  },
  contentHeaderButtonsContainer: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 0.5 / 2,
  },
  contentHeaderButtonContainer: {
    flex: 1,
    marginHorizontal: - 0.5 / 2,
    borderWidth: 0.5,
    borderColor: '#000',
    opacity: 0.3,
  },
  contentHeaderButton: {
    width: '100%',
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    borderWidth: 0,
  },
  contentHeaderButtonText: {
    fontSize: 16,
    lineHeight: 19.2,
    color: '#000',
    ...Styles.pretendard600,
  },
  blankContainer: {
    marginTop: 60 - 10,
    flex: 1,
  },
  blankText: {
    fontSize: 16,
    lineHeight: 19.2,
    textAlign: 'center',
    color: Colors.defaultGray08,
    ...Styles.pretendard400,
  },
  listItemContainer: {
    paddingVertical: 15,
    paddingHorizontal: Constants.viewHorizontalPadding,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  listItemDetailContainer: {
    flexDirection: 'column',
  },
  listItemPointContainer: {
    justifyContent: 'center',
  },
  listItemPointText: {
    fontSize: 16,
    lineHeight: 19.2,
    color: '#000',
    ...Styles.pretendard600,
  },
  listItemDetailText: {
    marginBottom: 3,
    fontSize: 16,
    lineHeight: 19.2,
    color: '#000',
    ...Styles.pretendard400,
  },
  listItemDateText: {
    fontSize: 13,
    lineHeight: 15.6,
    color: '#000',
    opacity: 0.5,
    ...Styles.pretendard400,
  }
});


class TabLevel extends React.PureComponent {
  getLevel() {
    const { auth: { summary } } = this.props;
    if (summary) {
      return summary.levelName;
    }
    return '';
  }

  getPoint() {
    // FIXME
    const { auth: { summary } } = this.props;
    if (summary) {
      console.log(summary);
      return 0;
    }
    return 0;
  }

  getLevelBenefit() {
    //FIXME:
    const { auth: { summary } } = this.props;
    return [
      "5% 할인 생일 쿠폰"
    ];
  }

  getLevelBarPosition() {
    const point = this.getPoint();
    const fullWidth = Math.min(initialLayout.width, 960) - 2 * Constants.viewHorizontalPadding;
    const segmentWidth = fullWidth / 4;

    if (point < 2) {
      return 2;
    } else if (point <= 50) {
      return  segmentWidth * point / 50;
    } else if (point <= 100) {
      return  1 * segmentWidth + segmentWidth * (point - 50) / (100 - 50);
    } else if (point <= 200) {
      return  2 * segmentWidth  + segmentWidth * (point - 100) / (200 - 100);
    } else {
      const tempMax = Math.max(1000, point);
      return  3 * segmentWidth  + segmentWidth * (point - 200) / (tempMax - 200);
    }
  }

  renderHeader() {
    return (
      <View style={styles.headerContainer}>
        <View style={styles.currentLevelContainer}>
          <View style={styles.currentLevelSubContainer}>
            <Text style={styles.currentLevelTitle}>현재 등급</Text>
            <Text style={styles.currentLevelValue}>{this.getLevel()}</Text>
          </View>
          <View style={styles.currentLevelSubContainer}>
            <Text style={styles.currentLevelTitle}>현재 점수</Text>
            <Text style={styles.currentLevelValue}>{this.getPoint()}점</Text>
          </View>
        </View>

        <View style={styles.levelBarContainer}>
          <View style={styles.barContainer}>
            <View style={styles.backgroundBar}>
              <View style={styles.backgroundBarSegment}>
                <Text style={styles.levelSegmentText}>
                  프렌즈
                </Text>
              </View>
              <View style={styles.backgroundBarSegment}>
                <Text style={styles.levelSegmentText}>
                  러버
                </Text>
              </View>
              <View style={styles.backgroundBarSegment}>
                <Text style={styles.levelSegmentText}>
                  매니아
                </Text>
              </View>
              <View style={styles.backgroundBarSegment}>
                <Text style={styles.levelSegmentText}>
                  VIP
                </Text>
              </View>
            </View>

            <View style={[styles.activeBarWing, {left: this.getLevelBarPosition() - 2}]} />
            <View style={[styles.activeBar, {width: this.getLevelBarPosition()}]}>
              <View style={styles.backgroundBar}>
                {/*<View style={[styles.backgroundBarSegment, {backgroundColor: '#000'}]}>
                  <Text style={[styles.levelSegmentText, styles.levelSegmentTextActive]}>
                    프렌즈
                  </Text>
                </View>
                <View style={[styles.backgroundBarSegment, {backgroundColor: '#000'}]}>
                  <Text style={[styles.levelSegmentText, styles.levelSegmentTextActive]}>
                    러버
                  </Text>
                </View>
                <View style={[styles.backgroundBarSegment, {backgroundColor: '#000'}]}>
                  <Text style={[styles.levelSegmentText, styles.levelSegmentTextActive]}>
                    매니아
                  </Text>
                </View>
                <View style={[styles.backgroundBarSegment, {backgroundColor: '#000'}]}>
                  <Text style={[styles.levelSegmentText, styles.levelSegmentTextActive]}>
                    VIP
                  </Text>
                </View>*/}
              </View>
            </View>

          </View>

          <View style={styles.pointContainer}>
            <View style={styles.pointTextContainer}>
              <Text style={styles.pointText}>50점</Text>
            </View>
            <View style={styles.pointTextCenterContainer}>
              <Text style={styles.pointText}>100점</Text>
            </View>
            <View style={styles.pointTextContainer}>
              <Text style={styles.pointText}>200점</Text>
            </View>
          </View>
        </View>

        <View style={styles.levelBenefitContainer}>
          <View style={styles.levelBenefitTitleContainer}>
            <Text style={styles.levelBenefitTitle}>{this.getLevel()} 등급 혜택</Text>
          </View>
          <View style={styles.levelBenefitContentContainer}>
            {this.getLevelBenefit().map(benefit => {
              return (
                <Text style={styles.levelBenefitContentText}>{benefit}</Text>
              );
            })}
          </View>

          <View style={styles.levelBenefitDetailButtonContainer}>
            <Button
              containerStyle={styles.levelBenefitDetailButton}
              innerComponent={(
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Text style={styles.levelBenefitDetailButtonText}>
                    등급 점수 기준 및 등급별 혜택 안내
                  </Text>
                  <ChevronRightIcon color={Colors.primaryBlue} />
                </View>
              )}
            />
          </View>

        </View>
      </View>
    );
  }

  render() {
    return (
      <View style={styles.container}>
        <ScrollView style={styles.scrollView}>
          {this.renderHeader()}
        </ScrollView>
      </View>
    );
  }
}

export default TabLevel;
