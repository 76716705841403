import * as React from 'react';

function SvgIcBack(props) {
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M15.414 3.515L6.93 12l8.485 8.485"
        stroke="#000"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIcBack;

