import React from 'react';

import {
  StyleSheet, View, Text, TouchableHighlight,
} from 'react-native';
import { Styles, Colors } from '../../../constants';
import { ChevronDownIcon } from '../../../icons-v2';


const styles = StyleSheet.create({
  container: {
    marginTop: 10,
  },
  titleContainer: {
    paddingVertical: 25,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleText: {
    ...Styles.pretendard400,
    fontSize: 16,
    lineHeight: 19.2,
  },
  titleIcon: {
    marginEnd: 3,
  },
  bodyContainer: {
    marginBottom: 30,
  },
  infoContainer: {
    flexDirection: 'column',
  },
  infoLineContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: 7,
    paddingBottom: 6,
    paddingTop: 4,
    marginVertical: 5 / 2,
  },
  infoTitleText: {
    fontSize: 15,
    lineHeight: 22,
    color: 'black',
    ...Styles.pretendard400,
  },
  infoValueContainer: {
    flex: 1,
    flexGrow: 1,
  },
  infoValueText: {
    fontSize: 15,
    lineHeight: 22,
    color: 'black',
    ...Styles.pretendard400,
  },
});


class ProductDetail extends React.PureComponent {

  state = {
    opened: false,
    updated : false
  }

  getTitleWidth = () => {
    const { product: { addInfo = [] } } = this.props;

    let length = 0;
    addInfo.forEach(info => {
      const titleLength = info.infoTitle.length;
      if (titleLength > length) {
        length = titleLength;
      }
    });

    return (length * 13 + 25);
  }

  renderDetailLine = (info, idx, titleWidth) => {
    const { infoTitle, infoValue } = info;

    return (
      <View key={idx.toString()} style={styles.infoLineContainer}>
        <View style={[styles.infoTitleContainer, { width: titleWidth }]}>
          <Text style={styles.infoTitleText} numberOfLines={1}>
            {infoTitle}
          </Text>
        </View>
        <View style={styles.infoValueContainer}>
          <Text style={styles.infoValueText}>
            {infoValue}
          </Text>
        </View>
      </View>
    );
  }

  render() {
    const {
      product: { addInfo = [] },
      containerStyle,
      collapsed
    } = this.props;

    const {
      opened,
      updated
    } = this.state;

    if(collapsed){
      if(!updated){
        this.setState({opened: collapsed, updated:true});
      }
    }
    
    const titleIconStyle = {};
    const onPress = () => {
      this.setState({ opened: !opened });
    }

    if (opened) {
      titleIconStyle.transform = [
        { scaleY: -1 }
      ];
    }

    const titleWidth = this.getTitleWidth();

    return (
      <View style={[styles.container, containerStyle]}>
        <TouchableHighlight
          onPress={onPress}
          underlayColor={Colors.touchEffectColorWhite}
          style={[Styles.touchEffectLight]}
        >
          <View style={styles.titleContainer}>
            <Text style={styles.titleText}>상품 상세정보</Text>
            <ChevronDownIcon style={[styles.titleIcon, titleIconStyle]} />
          </View>
        </TouchableHighlight>
        { opened && (
          <View style={styles.bodyContainer}>
            <View style={styles.infoContainer}>
              {addInfo.map((info, idx) => this.renderDetailLine(info, idx, titleWidth)) }
            </View>
          </View>
        )}
      </View>
    );
  }
}

export default ProductDetail;
