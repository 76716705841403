import React from 'react';

import SelectorBase from './SelectorBase';
import SizeSelectorModal from './SizeSelectorModal';


class ShoesSizeSelector extends SelectorBase {

  title = '신발 사이즈';
  placeholder = '신발 사이즈를 선택해주세요.';

  onPressSelector() {
    const {
      setModalProps,
    } = this.props;

    const children = this.showSelector();

    setModalProps({
      isVisible: true,
      children: children,
    });
  }

  showSelector() {
    const {
      onChangeSelectedSizeList,
      selectedSizeList,
      modalProps,
      setModalProps,
    } = this.props;

    const onClose = () => {
      setModalProps({
        isVisible: false,
        children: [],
      });
    };

    const onPressSelector = (selectedItemList) => {
      const value = selectedItemList.map(e => e.name).join(', ');
      this.setState({ 
        value,
      }, () => {
        onChangeSelectedSizeList(selectedItemList);
        setModalProps({
          isVisible: false,
          children: [],
        });
      });
    };

    return (
      <SizeSelectorModal
        type='shoes'
        onClose={onClose}
        onPressSelector={onPressSelector}
        selectedSizeList={selectedSizeList}
        modalProps={modalProps}
        setModalProps={setModalProps}
        />
    );
  }
}

export default ShoesSizeSelector;
