import React from 'react';
import {
  Dimensions,
  View, Text,
  StyleSheet, TouchableHighlight,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';
// import FilterIcon from '../../../icons/FilterIcon';
import SortingIcon from '../../../icons/SortingIcon';
import DropdownIcon from '../../../icons/DropdownIcon';
import BottomModalDropdown from '../../../nativeComponents/BottomModalDropdown';
import Styles from '../../../constants/Styles';
import Colors from '../../../constants/Colors';
import Constants from '../../../constants/Constants';
import ProductList from './ProductList';


const PRODUCT_PER_PAGE = 12;

const { height: windowHeight } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 10,
    minHeight: windowHeight - Constants.mainHeaderHeight - Constants.tabbarHeight - 48,
  },
  headerContainer: {
    height: 48,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginEnd: 6,
  },
  headerContainerLiving: {
    height: 18,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginEnd: 6,
  },
  headerCategoryButton: {
    height: 32,
  },
  headerCategoryContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginEnd: -4,
  },
  headerCategoryText: {
    fontSize: 16,
    height: 19,
    lineHeight: 19,
    ...Styles.SFUITextSemiBold,
    marginTop: 3,
    marginBottom: 2,
    marginStart: 6,
  },
  headerButton: {
    marginStart: 12,
  },
  optionContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
  promotionContainer: {
    backgroundColor: '#000',
    marginTop: 3,
    marginLeft: -10,
    marginRight: -10,
    paddingTop: 18,
    height: 70,
  },
  promotionTextContainer: {
    marginLeft: 18,
    lineHeight: 18,
    color: 'white',
    fontSize: 14,
    ...Styles.appleSDGothicNeoRegular,
  },
});


class ShopTabDropdown extends React.Component {
  state = {
    index: 0,
    currentTab: '',
    tabRoutes: [],
    sortTypeIndex: 0,
    currentSort: 'g.hitCnt desc',
    sortTypes: [
      { value: 'g.hitCnt desc', title: '인기도 순' },
      { value: 'g.orderGoodsCnt desc', title: '누적판매순' },
      { value: 'g.goodsPrice asc', title: '낮은가격순' },
      { value: 'g.regDt desc', title: '최신등록순' },
      { value: 'g.reviewCnt desc', title: '리뷰많은순' },
    ],
    filterFreeDelivery: {
      label: '무료 배송',
      value: 'freeDelivery',
      state: false,
    },
  };

  isPreOrder = false;

  constructor(props) {
    super(props);

    const { categoryName } = this.props;
    this.isPreOrder = categoryName === 'PRE ORDER';
  }

  componentDidMount() {
    const { categoryName, tabRoutes, subCategoryId, router } = this.props;
    if (categoryName && categoryName.toUpperCase() === 'NEW') {
      this.setState({ currentSort: 'g.regDt desc', sortTypeIndex: 3 });
    }

    if (tabRoutes && tabRoutes.length > 0) {
      if (tabRoutes.length === 1 && tabRoutes[0].virtual) {
        this.setState({
          tabRoutes: [],
          currentTab: tabRoutes[0].key,
        });
      } else {
        let index = tabRoutes.findIndex(element => element.key === subCategoryId);
        if (index === -1) {
          index = 0;
        }

        this.setState({
          tabRoutes,
          index,
          currentTab: tabRoutes[index].key,
        });
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    const { shopScreenTab, tabKey } = nextProps;

    if (shopScreenTab === tabKey) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { shopScreenTab, subCategoryId } = this.props;
    const { tabRoutes, currentTab, currentSort, filterFreeDelivery } = this.state;

    if ((prevState.tabRoutes.length === 0 && tabRoutes.length > 0)
        || prevState.currentTab !== currentTab
        || prevState.currentSort !== currentSort
        || prevState.filterFreeDelivery.state !== filterFreeDelivery.state
        || prevProps.shopScreenTab !== shopScreenTab
    ) {
      this.loadData(false);
    }

    if (prevProps.subCategoryId !== subCategoryId) {
      let index = tabRoutes.findIndex(element => element.key === subCategoryId);
      if (index === -1) {
        index = 0;
      }

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        tabRoutes,
        index,
        currentTab: tabRoutes[index].key,
      });
    }
  }

  componentWillUnmount() {
    this.setState = () => null;
  }

  handleIndexChange = index => () => {
    const { tabRoutes } = this.state;
    if (tabRoutes.length <= 0) {
      return;
    }

    const { categoryId, router } = this.props;

    router.push(`/shop?categoryId=${categoryId}&subCategoryId=${tabRoutes[index].key}`);

    this.setState({
      index,
      currentTab: tabRoutes[index].key,
      categoryPickerVisible: false,
    });
  }

  onPressCategoryPicker = () => {
    this.setState({ categoryPickerVisible: true });
  };

  onSortChanged = index => () => {
    const { sortTypes } = this.state;
    this.setState({
      sortTypeIndex: index,
      currentSort: sortTypes[index].value,
      sortingPickerVisible: false,
    },);
  }

  onPressSorting = () => {
    this.setState({ sortingPickerVisible: true });
  };

  onPressFilter = () => {
    const { filterFreeDelivery: { ...newFilterFreeDelivery } } = this.state;
    newFilterFreeDelivery.state = !newFilterFreeDelivery.state;
    this.setState({ filterFreeDelivery: newFilterFreeDelivery });
  };

  loadData = nextPage => {
    const { loadProducts } = this.props;
    const { currentTab, filterFreeDelivery } = this.state;

    const query = {
      cateCd: currentTab,
      count: PRODUCT_PER_PAGE,
      sortBy: this.getSortBy(),
    };

    if (filterFreeDelivery.state) {
      Object.assign(query, {
        filter: filterFreeDelivery.value,
      });
    }

    loadProducts(query, nextPage);
  }

  getSortBy = () => {
    const { sortTypes, sortTypeIndex } = this.state;

    if (this.showSortingButton()) {
      return sortTypes[sortTypeIndex].value;
    } else {
      return 'none';
    }
  }

  showSortingButton = () => {
    const { categoryName } = this.props;
    const { tabRoutes, index } = this.state;
    const currentCategory = tabRoutes.length > 0 ? tabRoutes[index].title : '';

    return !(
      (categoryName.toLowerCase() === 'brand' && currentCategory.toLowerCase() === 'all')
      || (categoryName.toLowerCase() === 'living')
    );
  }

  renderHeader = () => {
    const { categoryName } = this.props;
    const {
      tabRoutes,
      index,
    } = this.state;

    const currentCategory = tabRoutes.length > 0 ? tabRoutes[index].title : '';

    return (
      <View style={categoryName.toUpperCase() === 'LIVING' ? styles.headerContainerLiving : styles.headerContainer}>
        { tabRoutes.length > 0 && (
          <TouchableHighlight
            underlayColor={Colors.touchEffectColorWhite}
            onPress={this.onPressCategoryPicker}
            style={[styles.headerCategoryButton, styles.touchEffect]}
          >
            <View style={styles.headerCategoryContainer}>
              <Text style={styles.headerCategoryText}>{currentCategory}</Text>
              <DropdownIcon />
            </View>
          </TouchableHighlight>
        )}
        { !this.isPreOrder && this.showSortingButton() && (
          <View style={styles.optionContainer}>
            <TouchableHighlight
              underlayColor={Colors.touchEffectColorWhite}
              onPress={this.onPressSorting}
              style={[styles.headerButton, styles.touchEffect]}
            >
              <SortingIcon />
            </TouchableHighlight>

            {/* <TouchableHighlight
              underlayColor={Colors.touchEffectColorWhite}
              onPress={this.onPressFilter}
              style={[styles.headerButton, styles.touchEffect]}
            >
              <FilterIcon />
            </TouchableHighlight> */}
          </View>
        )}
      </View>
    );
  }

  renderCategoryPicker = () => {
    const {
      categoryPickerVisible = false,
      tabRoutes,
      index,
    } = this.state;

    if (tabRoutes.length === 0) {
      return null;
    }

    return (
      <BottomModalDropdown
        data={tabRoutes}
        curIndex={index}
        onClose={() => this.setState({ categoryPickerVisible: false })}
        onPressItem={this.handleIndexChange}
        open={categoryPickerVisible}
      />
    );
  };

  renderSortingPicker = () => {
    const {
      sortingPickerVisible = false,
      sortTypes,
      sortTypeIndex,
    } = this.state;

    return (
      <BottomModalDropdown
        data={sortTypes}
        curIndex={sortTypeIndex}
        onClose={() => this.setState({ sortingPickerVisible: false })}
        onPressItem={this.onSortChanged}
        open={sortingPickerVisible}
      />
    );
  }

  render() {
    const { currentTab } = this.state;
    const { router, categoryName } = this.props;

    return (
      <View style={styles.container}>
        {this.renderHeader()}
        <ProductList
          categoryId={currentTab}
          loadData={this.loadData}
          router={router}
        />
        {this.renderCategoryPicker()}
        {this.renderSortingPicker()}
      </View>
    );
  }
}

export default connect(
  null,
  actions,
)(ShopTabDropdown);
