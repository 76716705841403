import React from 'react';
import {
  StyleSheet, Text, View,
} from 'react-native';

import {
  Styles, Constants,
} from '../../../constants';

import {
  Button,
} from '../../../components-v2';


const styles = StyleSheet.create({
  container: {
  },
  sectionHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  sectionHeaderTitleText: {
    color: '#000000',
    lineHeight: 19.2,
    height: 19,
    fontSize: 16,
    ...Styles.pretendard600,
    marginTop: 12.5,
  },
  sectionHeaderButtonContainer: {
    marginTop: 13.5,
  },
  sectionHeaderButton: {
    borderWidth: 0,
    borderRadius: Constants.touchEffectWidth,
    padding: Constants.touchEffectWidth,
    margin: -1 * Constants.touchEffectWidth,
    marginEnd: 1 -1 * Constants.touchEffectWidth,
  },
  sectionHeaderButtonText: {
    color: '#000000',
    fontSize: 14,
    lineHeight: 16.8,
    height: 17,
    ...Styles.pretendard400,
  },
  sectionContentContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: 24,
    marginBottom: 10,
  },
  sectionContentSubContainer: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
    paddingTop: 12,
  },
  sectionContentValueButton: {
    borderWidth: 0,
    borderRadius: Constants.touchEffectBorderRadius,
    padding: Constants.touchEffectWidth,
    margin: -1 * Constants.touchEffectWidth,
  },
  sectionContentValue: {
    fontSize: 16,
    lineHeight: 19.2,
    color: '#000',
    ...Styles.pretendard400,
  },
  sectionContentText: {
    marginTop: 3,
    fontSize: 12,
    lineHeight: 14.4,
    color: '#000',
    opacity: 0.5,
    ...Styles.pretendard400,
  },
});


class OrderInfoSection extends React.Component {
  
  render() {
    const {
      orderCount,
      router,
    } = this.props;

    const {
      orderConfirm,
      shippingPending,
      shipping,
      delivered,
      cancel,
    } = orderCount;

    return (
      <View style={styles.container}>
        <View style={styles.sectionHeaderContainer}>
          <Text style={styles.sectionHeaderTitleText}>
            주문 내역
          </Text>
          <View style={styles.sectionHeaderButtonContainer}>
            <Button
              containerStyle={styles.sectionHeaderButton}
              titleStyle={styles.sectionHeaderButtonText}
              title="주문 내역 상세보기"
              onPress={() => {
                router.push('/accounts/orders');
              }}
            />
          </View>
        </View>

        <View style={styles.sectionContentContainer}>
          <View style={styles.sectionContentSubContainer}>
            <Button
              containerStyle={styles.sectionContentValueButton}
              titleStyle={styles.sectionContentValue}
              title={orderConfirm}
              onPress={() => {
                router.push('/accounts/orders?step=orderConfirm');
              }}
            />
            <Text style={styles.sectionContentText}>
              결제완료
            </Text>
          </View>

          <View style={styles.sectionContentSubContainer}>
            <Button
              containerStyle={styles.sectionContentValueButton}
              titleStyle={styles.sectionContentValue}
              title={shippingPending}
              onPress={() => {
                router.push('/accounts/orders?step=shippingPending');
              }}
            />
            <Text style={styles.sectionContentText}>
              상품준비중
            </Text>
          </View>

          <View style={styles.sectionContentSubContainer}>
            <Button
              containerStyle={styles.sectionContentValueButton}
              titleStyle={styles.sectionContentValue}
              title={shipping}
              onPress={() => {
                router.push('/accounts/orders?step=shipping');
              }}
            />
            <Text style={styles.sectionContentText}>
              배송중
            </Text>
          </View>

          <View style={styles.sectionContentSubContainer}>
            <Button
              containerStyle={styles.sectionContentValueButton}
              titleStyle={styles.sectionContentValue}
              title={delivered}
              onPress={() => {
                router.push('/accounts/orders?step=delivered');
              }}
            />
            <Text style={styles.sectionContentText}>
              배송완료
            </Text>
          </View>

          <View style={styles.sectionContentSubContainer}>
            <Button
              containerStyle={styles.sectionContentValueButton}
              titleStyle={styles.sectionContentValue}
              title={cancel}
              onPress={() => {
                router.push('/accounts/orders?step=cancel');
              }}
            />
            <Text style={styles.sectionContentText}>
              반품/교환
            </Text>
            <Text style={styles.sectionContentText}>
              취소
            </Text>
          </View>
        </View>
      </View>
    );
  }
}


export default OrderInfoSection;
