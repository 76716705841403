/* eslint-disable import/prefer-default-export */
import { normalize } from 'normalizr';
import qs from 'qs';
import get from 'lodash/get';
import merge from 'lodash/merge';

import * as schemas from '../schemas';
import createFetchAction from '../util/createFetchActionWithShopAuth';


export function loadUserReviewList(nextPage, query) {
  const key = 'userReviewList';
  const base = `/api/v2/shop/user/review?${qs.stringify(query)}`;

  return createFetchAction({
    type: 'LOAD_USER_REVIEW_LIST',
    method: 'GET',
    endpoint: state => {
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    transform: ({ json }) => {
      if (json.success !== true) {
        return {};
      }

      const result = {};

      Object.assign(result, normalize(json.data.articles, schemas.userReviewList));

      let nextPageUrl = null;
      try {
        if (parseInt(json.data.page.now, 10) < parseInt(json.data.page.end, 10)) {
          const newNextPage = parseInt(json.data.page.now, 10) + 1;
          nextPageUrl = base + '&page=' + newNextPage;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      Object.assign(result, { nextPageUrl });
      return result;
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}

export function loadUserReviewDetail(sno) {
  return createFetchAction({
    type: 'GET_USER_REVIEW_DETAIL',
    method: 'GET',
    endpoint: `/api/v2/shop/user/review/${sno}`,
    transform: ({ json, state }) => {
      if (json.success !== true) {
        return {};
      }

      const result = {};
      // eslint-disable-next-line no-prototype-builtins
      // if (state.entities.userReviewList.hasOwnProperty(sno)) {
      merge(
        result,
        normalize([json.data], schemas.userReviewList)
      );
      // }
      return result;
    },
    success: {
      meta: { entities: { update: true } },
    },
  });
}

export function modifyUserReview(sno, params) {
  return createFetchAction({
    type: 'MODIFY_USER_REVIEW',
    method: 'PUT',
    endpoint: `/api/v2/shop/user/review/${sno}`,
    body: params,
  });
}

export function deleteUserReview(sno) {
  return createFetchAction({
    type: 'DELETE_USER_REVIEW',
    method: 'DELETE',
    endpoint: `/api/v2/shop/user/review/${sno}`,
  });
}
