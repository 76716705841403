import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadEntities } from 'react-shared';
import { Link } from 'react-router';
import {
  Button,
  Checkbox,
  Dialog,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
  withStyles,
} from '@material-ui/core';
import { compose } from 'recompose';
import has from 'lodash/has';

import { Cloudinary } from 'cloudinary-core';
import config from '../../config'
const cloudinary = Cloudinary.new();
cloudinary.config({
  ...config.get('cloudinary'),
  secure: true,
});

import Spinner from 'components/Spinner';
import PropTypes from 'prop-types';
import * as adminActions from 'react-shared/adminActions';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterNoneIcon from '@material-ui/icons/FilterNone';

const styles = theme => ({
  divSearchFilter: {
    marginTop: 20,
  },
  textField: {
    width: 300,
  },
  input: {
    ...theme.typography.subheading,
  },
  table: {
    width: '100%',
    padding: 5,
  },
  tableCellType: {
    width: 60,
    maxWidth: 60,
  },
  tableCellName: {
    width: 400,
    maxWidth: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tableCellBrand: {
    width: 100,
  },
  divPrice: {
    textAlign: 'right',
  },
  spanDiscPct: {
    fontSize: 10,
    color: 'red',
  },
  spanOrgPrice: {
    fontSize: 10,
    textDecoration: 'line-through',
  },
  spanSellPrice: {
    fontSize: 14,
  }
});

export class ListProduct extends Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  componentDidMount() {
    this.handleLoadMore(false);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isGodoOnly !== this.props.isGodoOnly) {
      this.handleLoadMore(false);
    }
  }

  handleScroll() {
    if (this.props.addMode) {
      return;
    }
    if (window.pageYOffset > document.body.clientHeight - window.innerHeight - 1
        && !this.props.pagination.isFetching && this.props.pagination.nextPageUrl) {
      this.handleLoadMore(true);
    }
  }

  handleLoadMore(nextPage) {
    const { adminLoadProducts, searchKeyword, isGodoOnly, filters=null } = this.props;
    this.setSearchKeyword(searchKeyword);
    adminLoadProducts({
      nextPage,
      keyword: searchKeyword,
      isGodoOnly,
      filters
    });
  }

  setSearchKeyword(keyword) {
    const params = new URLSearchParams(window.location.search);
    if (keyword) {
      params.set('keyword', keyword);
      window.history.replaceState({}, "", decodeURIComponent(`${window.location.pathname}?${params}`));
    } else {
      params.delete('keyword');
      window.history.replaceState({}, "", decodeURIComponent(`${window.location.pathname}`));
    }
  }

  onButtonEdit(product) {
  }

  onButtonDelete(product) {
    const { adminDeleteProduct } = this.props;

    const result = confirm(`이 상품을 삭제하시겠습니까?\n${product.name}`);
    if (result) {
      adminDeleteProduct({ productId: product._id });
    }
  }

  renderPrice(product) {
    const { classes } = this.props;
    const retail = product.retails[product.defaultRetailIdx];

    let orgPrice = 0;
    let sellPrice = 0;
    let discPct = 0;
    if (retail && retail.orgPrice && retail.sellPrice) {
      orgPrice = retail.orgPrice.amount;
      sellPrice = retail.sellPrice.amount;
      discPct = Math.round((sellPrice - orgPrice)/orgPrice * 100);
    }

    if (orgPrice && orgPrice > sellPrice) {
      return (
        <div className={classes.divPrice}>
          <span className={classes.spanDiscPct}>{discPct}%</span>&nbsp;
          <span className={classes.spanOrgPrice}>₩{orgPrice.toLocaleString('en')}</span><br />
          <span className={classes.spanSellPrice}>₩{sellPrice.toLocaleString('en')}</span>
        </div>
      );
    } else {
      return (
        <div className={classes.divPrice}>
          <span className={classes.spanSellPrice}>₩{sellPrice.toLocaleString('en')}</span>
        </div>
      );
    }
  }

  renderButtonEdit(product) {
    return (
      <Link to={`/admin/products/${product._id}`}>
        <IconButton
          variant='contained'
          color='inherit'
          aria-label='Edit product'
          onClick={() => { this.onButtonEdit(product); }}
        >
          <EditIcon />
        </IconButton>
      </Link>
    );
  }

  renderButtonDelete(product) {
    return (
      <IconButton
        variant='contained'
        color='secondary'
        aria-label='Delete product'
        onClick={() => { this.onButtonDelete(product); }}
      >
        <DeleteIcon />
      </IconButton>
    );
  }

  renderButtonCreatePopup(product) {
    return (
      <Link to={`/admin/marketingPopups/new/?productId=${product._id}`}>
        <IconButton
          variant='contained'
          color='inherit'
          aria-label='Create popup'
        >
          <FilterNoneIcon />
        </IconButton>
      </Link>
    );
  }

  render() {
    const { classes, adminProducts, pagination: { isFetching, nextPageUrl }, addMode,
        onCheckProduct, onChangeKeyword, onChangeIsGodoOnly } = this.props;
    const productList = adminProducts.sort((a, b) => b.id - a.id);

    return (
      <div>
        <div className={classes.divSearchFilter}>
          <TextField
            className={classes.textField}
            style={{ marginRight: 20 }}
            InputProps={{ className: classes.input }}
            onChange={onChangeKeyword}
            onKeyPress={(evt) => {
              if (evt.key === 'Enter') {
                this.handleLoadMore(false);
              }
            }}
            placeholder="Search product"
            value={this.props.searchKeyword}
          />
          <Button
            variant="contained"
            size="small"
            onClick={() => this.handleLoadMore(false)}
            style={{ marginRight: 20 }}
          >
            검색
          </Button>
          <span className={classes.label}>고도몰 상품만 보기</span>
          <Checkbox
            name="godoOnly"
            checked={this.props.isGodoOnly}
            onClick={onChangeIsGodoOnly}
          />
        </div>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {addMode && <TableCell className=''></TableCell>}
              <TableCell className=''></TableCell>
              <TableCell className={classes.tableCellType}>종류</TableCell>
              <TableCell className={classes.tableCellName}>상품명</TableCell>
              <TableCell className={classes.tableCellBrand}>브랜드</TableCell>
              <TableCell className=''>가격</TableCell>
              <TableCell className=''></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productList.map(product => (
              <TableRow key={`product_${product._id}`}>
                {addMode && (
                  <TableCell>
                    <Checkbox
                      name={`product_checkbox_${product._id}`}
                      checked={has(this.props.checkedProducts, product._id)}
                      onChange={event => (onCheckProduct && onCheckProduct(event, product))}
                    />
                  </TableCell>
                )}
                <TableCell>
                  {product.media ? (
                    <img src={product.media.secure_url || product.media.secureUrl || cloudinary.url(product.media.publicId)} style={{ width: 60, height: 60 }} />
                  ) : (
                    <div style={{ width: 60, height: 60, backgroundColor: '#ddd' }}></div>
                  )}
                </TableCell>
                <TableCell className={classes.tableCellType}>{product.godoGoodsNo ? '고도' : '아웃'}</TableCell>
                <TableCell className={classes.tableCellName}>{product.name}</TableCell>
                <TableCell className={classes.tableCellBrand}>{product.brand}</TableCell>
                <TableCell>{this.renderPrice(product)}</TableCell>
                {addMode ? (
                  <TableCell></TableCell>
                ) : (
                  <TableCell>
                    {this.renderButtonEdit(product)}
                    {this.renderButtonCreatePopup(product)}
                    {this.renderButtonDelete(product)}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {(!isFetching && nextPageUrl) && (
          <p style={{ textAlign: 'center', marginTop: 10 }}>
            <Button variant="outlined" onClick={() => this.handleLoadMore(true)}>
              더 불러오기
            </Button>
          </p>
        )}
      </div>
    );
  }
}

ListProduct.propTypes = {
  adminLoadProducts: PropTypes.func.isRequired,
  adminProducts: PropTypes.array,
  // pagination: PropTypes.object,
};

export default compose(
  withStyles(styles),
  connect(state => ({
    ...loadEntities(state, 'adminProducts', 'adminProducts'),
  }), adminActions),
)(ListProduct);
