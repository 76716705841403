import * as React from "react"
import Svg, { Path } from "react-native-svg"

function HeaderShareIcon(props) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M5 12v7a1 1 0 001 1h12a1 1 0 001-1v-7M12 3l5 5M12 3L7 8"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
      <Path
        d="M12 3v11"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default HeaderShareIcon

