import { normalize } from 'normalizr';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';


// admin actions for marketing popups
export function adminLoadMarketingPopups() {
  const nextPage = false;
  const key = 'adminMarketingPopups';

  return createFetchAction({
    type: 'ADMIN_LOAD_MARKETING_POPUPS',
    endpoint: '/api/v2/admin/marketingPopups',
    transform: ({ json }) => normalize(json, schemas.adminMarketingPopups),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}

export function adminGetMarketingPopup(popupId) {
  return createFetchAction({
    type: 'ADMIN_GET_MARKETING_POPUP',
    endpoint: `/api/v2/admin/marketingPopups/${popupId}`,
    transform: ({ json }) => normalize(json, schemas.adminMarketingPopup),
  });
}

export function adminCreateMarketingPopup(popup) {
  return createFetchAction({
    type: 'ADMIN_CREATE_MARKETING_POPUP',
    endpoint: '/api/v2/admin/marketingPopups',
    method: 'POST',
    body: popup,
    transform: ({ json }) => normalize(json, schemas.adminMarketingPopup),
    success: () => ({
      pagination: {
        key: 'adminMarketingPopups',
        type: 'PREPEND',
      },
    }),
  });
}

export function adminUpdateMarketingPopup(popupId, popup) {
  return createFetchAction({
    type: 'ADMIN_UPDATE_MARKETING_POPUP',
    endpoint: `/api/v2/admin/marketingPopups/${popupId}`,
    method: 'PUT',
    body: popup,
    transform: ({ json }) => normalize(json, schemas.adminMarketingPopup),
  });
}

export function adminDeleteMarketingPopup(popupId) {
  const payload = { entities: { adminMarketingPopups: {} } };
  payload.entities.adminMarketingPopups[popupId] = null;

  return createFetchAction({
    type: 'ADMIN_DELETE_MARKETING_POPUP',
    endpoint: `/api/v2/admin/marketingPopups/${popupId}`,
    method: 'DELETE',
    success: {
      payload,
      // payload: set({}, `entities.adminMarketingPopups.${popupId}`, null),
    },
  });
}
