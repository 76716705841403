import React from 'react';
import {
  StyleSheet, View, Text, TouchableHighlight,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions, loadEntities } from 'shared';

import Constants from '../../../constants/Constants';
import Styles from '../../../constants/Styles';
import Colors from '../../../constants/Colors';
import ShippingAddress from '../../../nativeComponents/ShippingAddress';
import {
  navigateToEditShippingAddress,
  navigateToCreateShippingAddress,
} from '../../ShippingAddressEditPage';


const styles = StyleSheet.create({
  container: {
  },
  editShippingAddressContainer: {
    marginVertical: 10,
  },
  blankText: {
    height: 21,
    ...Styles.appleSDGothicNeoMedium,
    lineHeight: 21,
    fontSize: 18,
    opacity: 0.5,
    marginTop: 66,
    marginBottom: 67,
    textAlign: 'center',
  },
  buttonContainer: {
    flexDirection: 'row',
    marginHorizontal: Constants.viewHorizontalPadding - 3.5,
  },
  button: {
    flex: 1,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: 38,
    backgroundColor: '#000',
    marginHorizontal: 3.5,
  },
  buttonLight: {
    backgroundColor: '#fff',
  },
  buttonText: {
    marginStart: 1,
    height: 17,
    ...Styles.appleSDGothicNeoBold,
    lineHeight: 17,
    fontSize: 14,
    color: '#fff',
  },
  buttonTextLight: {
    marginStart: 1,
    height: 17,
    ...Styles.appleSDGothicNeoBold,
    lineHeight: 17,
    fontSize: 14,
    color: '#000',
  },
});


class EditShippingAddressList extends React.Component {
  componentDidMount() {
    const { loadShippingAddressList } = this.props;
    loadShippingAddressList();
  }

  componentWillUnmount() {
    this.setState = () => null;
  }

  onPressCreateShippingAddresss = () => {
    navigateToCreateShippingAddress();
  };

  onPressEditShippingAddress = item => () => {
    navigateToEditShippingAddress(item.sno);
  }

  onPressDeleteShippingAddress = item => () => {
    const { deleteShipppingAddress } = this.props;
    deleteShipppingAddress(item.sno);
  }

  render() {
    const { shippingAddresses } = this.props;

    return (
      <React.Fragment>
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <Text style={styles.buttonText}>배송지 목록</Text>
          </View>
          <TouchableHighlight
            style={[styles.button, styles.buttonLight]}
            underlayColor={Colors.touchEffectColorWhite}
            onPress={this.onPressCreateShippingAddresss}
          >
            <Text style={[styles.buttonText, styles.buttonTextLight]}>새로운 배송지</Text>
          </TouchableHighlight>
        </View>
        <View style={styles.editShippingAddressContainer}>
          {shippingAddresses.length === 0 ? (
            <Text style={styles.blankText}>저장된 배송지가 없습니다.</Text>
          ) : shippingAddresses.map(item => (
            <ShippingAddress
              key={item.sno}
              item={item}
              onPressEditButton={this.onPressEditShippingAddress(item)}
              onPressDeleteButton={this.onPressDeleteShippingAddress(item)}
              hideRadioButton
            />
          ))}
        </View>
      </React.Fragment>
    );
  }
}


export default connect(
  state => ({
    ...loadEntities(state, 'shippingAddresses', 'shippingAddresses'),
  }),
  actions,
)(EditShippingAddressList);
