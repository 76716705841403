import { normalize } from 'normalizr';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';


// admin actions for banners
export function adminLoadBanners() {
  const nextPage = false;
  const key = 'banners';

  return createFetchAction({
    type: 'ADMIN_LOAD_BANNERS',
    endpoint: '/api/v2/admin/banners',
    transform: ({ json }) => normalize(json, schemas.banners),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}

export function adminGetBanner(bannerId) {
  return createFetchAction({
    type: 'ADMIN_GET_BANNER',
    endpoint: `/api/v2/admin/banners/${bannerId}`,
    transform: ({ json }) => normalize(json, schemas.banners),
  });
}

export function adminCreateBanner(banner) {
  return createFetchAction({
    type: 'ADMIN_CREATE_BANNER',
    endpoint: '/api/v2/admin/banners',
    method: 'POST',
    body: banner,
    transform: ({ json }) => normalize(json, schemas.banners),
    success: () => ({
      pagination: {
        key: 'banners',
        type: 'PREPEND',
      },
    }),
  });
}

export function adminUpdateBanner(bannerId, banner) {
  return createFetchAction({
    type: 'ADMIN_UPDATE_BANNER',
    endpoint: `/api/v2/admin/banners/${bannerId}`,
    method: 'PUT',
    body: banner,
    transform: ({ json }) => normalize(json, schemas.banners),
  });
}

export function adminDeleteBanner(bannerId) {
  const payload = { entities: { banners: {} } };
  payload.entities.banners[bannerId] = null;

  return createFetchAction({
    type: 'ADMIN_DELETE_BANNER',
    endpoint: `/api/v2/admin/banners/${bannerId}`,
    method: 'DELETE',
    success: {
      payload,
      // payload: set({}, `entities.banners.${bannerId}`, null),
    },
  });
}
