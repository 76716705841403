import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as adminActions from 'react-shared/adminActions';
import { loadEntities } from 'react-shared/util';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

import '../../../css/datepicker.css';
import '../../../css/admin/datepicker.css';
import UserStatistics from './UserStatistics/UserStatistics';


const styles = {
  dataPickerContainer: {
    margin: '10px',
  },
  dataPickerItemContainer: {
    float: 'left',
  },
};

const MAX_DATE = dayjs().toDate();


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: dayjs().subtract(7, 'day').toDate(),
      endDate: MAX_DATE,
      allPeriod: false,
      timeUnit: 'dau',
    };
  }

  static getDays(startDate, endDate) {
    return parseInt((endDate - startDate + 20000) / 86400000, 10) + 1;
  }

  handleDateChange(date, which) {
    this.setState({
      [which]: date,
    });
  }

  handleAllPeriodChange() {
    this.setState(prevState => ({ allPeriod: !prevState.allPeriod }));
  }

  renderDatePicker() {
    const { startDate, endDate, allPeriod } = this.state;

    return (
      <div style={styles.dataPickerContainer}>
        <div style={styles.dataPickerItemContainer}>
          <DatePicker
            dateFormat="yyyy-MM-dd"
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={date => this.handleDateChange(date, 'startDate')}
            disabled={allPeriod}
            selectsStart
            maxDate={MAX_DATE}
          />
        </div>
        <div style={styles.dataPickerItemContainer}>
        ~
        </div>
        <div style={styles.dataPickerItemContainer}>
          <DatePicker
            dateFormat="yyyy-MM-dd"
            selected={endDate}
            startDate={startDate}
            endDate={endDate}
            onChange={date => this.handleDateChange(date, 'endDate')}
            disabled={allPeriod}
            selectsEnd
            minDate={startDate}
            maxDate={MAX_DATE}
          />
        </div>
        <div style={styles.dataPickerItemContainer}>
          &nbsp;
          <input type="checkbox" checked={allPeriod} onChange={() => this.handleAllPeriodChange()} />
          전체 기간 검색
        </div>
      </div>
    );
  }

  renderContent() {
    const { allPeriod, timeUnit } = this.state;
    const { visitCount, getVisitCount } = this.props; // eslint-disable-line max-len

    let { startDate, endDate } = this.state;

    const startDateStr = allPeriod
      ? dayjs('2019-07-05').format('YYYY-MM-DD')
      : dayjs(startDate).format('YYYY-MM-DD');

    const endDateStr = allPeriod
      ? dayjs(MAX_DATE).format('YYYY-MM-DD')
      : dayjs(endDate).format('YYYY-MM-DD');

    return (
      <div style={{ clear: 'both' }}>
        <UserStatistics
          startDate={startDateStr}
          endDate={endDateStr}
          loadData={getVisitCount}
          visitCount={visitCount}
          unit={timeUnit}
          />
      </div>
    );
  }

  render() {
    return (
      <div class="dashboard">
        {this.renderDatePicker()}
        {this.renderContent()}
      </div>
    );
  }
}


Dashboard.propTypes = {
  // adminGetPostCount: PropTypes.func.isRequired,
  // adminGetUserCount: PropTypes.func.isRequired,
};


export default connect(
  state => ({
    ...loadEntities(state, 'featured', 'posts'),
    viewMode: state.viewModes.home,
    initialViewMode: state.viewModes.initial.home,
    visitCount: state.admin.visitCount,
  }),
  adminActions,
)(Dashboard);
