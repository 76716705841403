import React from 'react';
import {
  StyleSheet, View,
} from 'react-native';

import Header from './Header';


const styles = StyleSheet.create({
  container: {
  },
});


export class BasePage extends React.Component {
  constructor(props) {
    super(props);

    this.type = '';
  }

  componentDidMount() {
  }

  renderBody() {
    return null;
  }

  render() {
    const {
      router,
    } = this.props;

    return (
      <View style={styles.container}>
        <Header
          type={this.type}
          router={router}
          />
        {this.renderBody()}
      </View>
    );
  }
}


export default BasePage;
