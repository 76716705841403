import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View, Text, TouchableHighlight } from 'react-native';
import { connect } from 'react-redux';

import { browserHistory } from 'react-router';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';
import ForwardIcon from '../../../icons/ForwardIcon';

import ProductImages from './ProductImages';
import ProductInfo from './ProductInfo';
import PreOrderInfo from './PreOrderInfo';
import TabView from './TabView';
import { default as Footer, height as footerHeight } from './Footer';
import ProductOptionSelect from '../../../nativeComponents/ProductOptionSelect';
import Styles from '../../../constants/Styles';
import Colors from '../../../constants/Colors';

const webStyles = {
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 50,
  },
  addToCartSuccessMessageContainer: {
    position: 'fixed',
    overflow: 'hidden',
    bottom: footerHeight,
    padding: 10,
    start: 0,
    end: 0,

  },
  addToCartSuccessMessageContent: {
    backgroundColor: '#000',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingStart: 20,
    paddingEnd: 10,
    height: 52,
    paddingTop: 14,
    paddingBottom: 14,
  },
  addToCartSuccessMessageText: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 14,
    color: '#fff',
    lineHeight: 17,
    height: 17,
    marginTop: 4,
    marginBottom: 3,
  },
  moveToCartButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  moveToCartButtonText: {
    ...Styles.appleSDGothicNeoSemiBold,
    fontSize: 12,
    color: '#fff',
    height: 15,
    lineHeight: 15,
    marginTop: 5,
    marginBottom: 4,
  },
});


export class ProductPage extends React.Component {
  state = {
    isOptionSelectVisible: false,
    isAddToCartSuccessMessageVisible: false,
  };

  componentDidMount() {
    actions.pauseOnceGetProductListByCategory();

    const {
      loadShopProduct,
      loadProductRelatedPosts,
      productId,
      router,
    } = this.props;
    const { state } = router.location;
    const postId = state && state.postId;

    loadShopProduct({ productId, postId });
    loadProductRelatedPosts(productId);
  }

  componentWillUnmount() {
    this.setState = () => null;

    clearTimeout(this.hideMessageTimer);
    const { setGlobalLayoutInfo } = this.props;
    setGlobalLayoutInfo('hideChatInquiry', false);
  }

  onPressSavingDeliveryFee = () => {
  }

  onPressDeliveryInfo = () => {
  }

  onPressRelatedPost = postId => {
    const { router } = this.props;
    router.push(`/posts/${postId}`);
  }

  onPressCart = () => {
    const { product = {} } = this.props;
    const { isOptionSelectVisible } = this.state;

    if (product.soldOut === 'y') {
      const msg = '상품이 품절되었습니다.';
      alert(msg);
      return;
    }

    if (!isOptionSelectVisible) {
      this.setState({ isOptionSelectVisible: true });
    }
  }

  onItemAddedToCart = () => {
    // console.log('ProductPage onItemAddedToCart()');
    const { setGlobalLayoutInfo } = this.props;

    setTimeout(() => {
      setGlobalLayoutInfo('hideChatInquiry', true);
      this.setState({
        isOptionSelectVisible: false,
        isAddToCartSuccessMessageVisible: true,
      });
      this.hideMessageTimer = setTimeout(() => {
        this.setState({ isAddToCartSuccessMessageVisible: false });
        setGlobalLayoutInfo('hideChatInquiry', false);
      }, 10000);
    }, 1000);
  }

  onItemNaverPay = () => {
    // console.log('ProductPage onItemNaverPay()');
    this.setState({ isOptionSelectVisible: false });
  }

  onPressCheckout = () => {
    const { product = {} } = this.props;
    const { isOptionSelectVisible } = this.state;

    if (product.soldOut === 'y') {
      const msg = '상품이 품절되었습니다.';
      alert(msg);
      return;
    }

    if (!isOptionSelectVisible) {
      this.setState({ isOptionSelectVisible: true });
    }
  }

  onPressMoveToCart = () => {
    // console.log('onPressMoveToCart');
    browserHistory.push('/shop/cart');
  };

  onCloseOptionSelect = () => {
    this.setState({ isOptionSelectVisible: false });
  }

  renderAddToCartSuccessMessage = () => {
    const { isAddToCartSuccessMessageVisible } = this.state;

    if (!isAddToCartSuccessMessageVisible) {
      return null;
    }

    return (
      <View style={styles.addToCartSuccessMessageContainer}>
        <TouchableHighlight
          underlayColor="rgba(255, 255, 255, 0.2)"
          onPress={this.onPressMoveToCart}
        >
          <View style={styles.addToCartSuccessMessageContent}>
            <Text style={styles.addToCartSuccessMessageText}>장바구니에 상품이 담겼습니다.</Text>
            <View
              style={styles.moveToCartButton}
            >
              <Text style={styles.moveToCartButtonText}>장바구니 바로가기</Text>
              <ForwardIcon />
            </View>
          </View>
        </TouchableHighlight>
      </View>
    );
  }

  renderMainContent() {
    const {
      router,
      product = {
        title: ' ',
        brandNm: ' ',
        reviewStar: 0,
        reviewCnt: 0,
        goodsPrice: 0,
        discPricePct: 0,
        color: '',
        imagesDetail: [],
        description: '',
      },
    } = this.props;

    return (
      <View>
        <ProductImages product={product} />

        <ProductInfo product={product} router={router} />

        <PreOrderInfo product={product} />

        <View key="divier" style={{ height: 10, backgroundColor: Colors.semiGray }} />
      </View>
    );
  }


  renderBottomScrollContent() {
    const {
      productId,
      product = {
        title: ' ',
        brandNm: ' ',
        reviewStar: 0,
        reviewCnt: 0,
        goodsPrice: 0,
        discPricePct: 0,
        color: '',
        imagesDetail: [],
        description: '',
      },
      router,
      productRelatedPosts,
    } = this.props;

    const {
      launchYmd,
    } = product;

    const isPreOrder = !!launchYmd && launchYmd !== '0000-00-00';

    return (
      <View>
        <TabView
          router={router}
          product={product}
          productId={productId}
          isPreOrder={isPreOrder}
          productRelatedPosts={productRelatedPosts}
          onPressRelatedPost={this.onPressRelatedPost}
        />
      </View>
    );
  }


  render() {
    const { product, productId } = this.props;
    const { isOptionSelectVisible } = this.state;

    // console.log(`ProductPage render product=`, product);

    return (
      <View style={styles.container}>
        {this.renderMainContent()}
        {this.renderBottomScrollContent()}

        {this.renderAddToCartSuccessMessage()}

        {isOptionSelectVisible && (
          <ProductOptionSelect
            productId={productId}
            open={isOptionSelectVisible}
            onClose={this.onCloseOptionSelect}
            onItemAddedToCart={this.onItemAddedToCart}
            onItemNaverPay={this.onItemNaverPay}
          />
        )}
        <div style={webStyles.footer}>
          <Footer
            product={product}
            onPressCart={this.onPressCart}
            onPressCheckout={this.onPressCheckout}
          />
        </div>
      </View>
    );
  }
}


export default connect(
  (state, ownProps) => {
    const { productId } = ownProps.params;
    const productRelatedPosts = state.entities.shopProductsRelatedPosts[productId];

    return {
      auth: state.auth,
      productId,
      product: state.entities.shopProducts[productId],
      productRelatedPosts: productRelatedPosts ? Object.values(productRelatedPosts) : [],
    };
  },
  actions,
)(ProductPage);
