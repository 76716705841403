/* eslint-disable no-restricted-globals */
import React from 'react';
import { connect } from 'react-redux';
import { actions } from 'shared';
import {
  StyleSheet, View, Text,
} from 'react-native';
import NumberFormat from 'react-number-format';
import { browserHistory } from 'react-router';

import { parseDate } from 'shared/util';

import { CheckIcon, PlusIcon } from '../../../icons-v2';
import { Styles } from '../../../constants';
import { 
  TouchableHighlightWithContainer, Button,
} from '../../../components-v2';



const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    marginBottom: 20,
  },
  brandContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  brand: {
    ...Styles.pretendard700,
    fontSize: 20,
    lineHeight: 24,
  },
  followButtonContainer: {
    backgroundColor: "#000",
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 0,
    paddingVertical: 6,
    paddingLeft: 11,
    paddingRight: 7,
  },
  followingButtonContainer: {
    backgroundColor: '#fff',
    borderWidth: 0.5,
    borderColor: '#000',
  },
  followButtonText: {
    fontSize: 15,
    lineHeight: 18,
    color: "#fff",
    ...Styles.pretendard600,
    marginRight: 4,
  },
  followingButtonText: {
    color: '#000',
  },
  titleContainer: {
    marginTop: 2,
  },
  title: {
    ...Styles.pretendard400,
    fontSize: 15,
    lineHeight: 18,
  },
  priceInfo: {
    marginTop: 16,
  },
  priceInfoSoldOut: {
    opacity: 0.45,
  },
  priceDiscountInfo: {
    flexDirection: 'row',
  },
  discountRate: {
    marginStart: 8,
    ...Styles.pretendard600,
    fontSize: 15,
    lineHeight: 18,
    color: '#e94949',
  },
  productOrgPrice: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
    ...Styles.pretendard400,
    fontSize: 15,
    lineHeight: 18,
  },
  sellPriceContainer: {
    marginTop: 2,
    flexDirection: 'row',
  },
  sellPrice: {
    ...Styles.pretendard400,
    fontSize: 18,
    lineHeight: 21.6,
  },
  labelContainer: {
    // GUI 수치 상으로는 marginTop을 넣어야 하나 앞의 가격 텍스트 높이와 비교했을 때 marginTop을 안 넣는 것이 더 GUI 디자인에 가까워 주석처리
    // marginTop: 1, 
    paddingVertical: 4,
    paddingHorizontal: 6,
    borderWidth: 0.5,
    marginStart: 6,
    height: 21,
  },
  labelText: {
    alignItems: 'center',
    fontSize: 11,
    height: 13,
    lineHeight: 12.5,
    ...Styles.pretendard400,
  },
});


class ProductInfo extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isPreOrder: false,
      isBrandFollowing: false,
      followChanging: false,
    };
  }


  componentDidMount() {
    const {
      product: {
        brandCd,
        brandFollow,
      },
    } = this.props;
    if (brandCd) {
      this.setPreOrderState();
      this.setState({ isBrandFollowing: brandFollow });
    }
  }

  componentDidUpdate(prevProps) {
    const { product } = this.props;
    const { brandFollow } = product;

    if (prevProps.product._id !== product._id) {
      this.setPreOrderState();
      this.setState({ isBrandFollowing: brandFollow });
    }
  }

  setPreOrderState() {
    const {
      product: {
        launchYmd,
        salesEndYmd,
      },
    } = this.props;

    const newState = {
      isPreOrder: launchYmd && launchYmd !== '0000-00-00',
    };

    if (newState.isPreOrder) {
      newState.launchYmd = parseDate(launchYmd);
      newState.salesEndYmd = parseDate(salesEndYmd);
    }

    this.setState(newState);
  }

  renderLabel = () => {
    const { isPreOrder } = this.state;

    if (!isPreOrder) {
      return null;
    }

    return (
      <View style={styles.labelContainer}>
        <Text style={styles.labelText}>프리오더</Text>
      </View>
    );
  }

  isReviewExist = () => {
    const { product: { reviewCnt = 0 } } = this.props;
    return reviewCnt > 0;
  }

  renderFollowButton() {
    const {
      auth,
      getBrandFollowStatus,
      followBrand,
      unfollowBrand,
      product: { brandCd },
    } = this.props;
    const {
      isBrandFollowing,
      followChanging,
    } = this.state;

    const onPress = () => {
      if (!auth || !auth.token) {
        alert('로그인해주시기 바랍니다.');
        browserHistory.push('/accounts/login');
      }
      if (followChanging) {
        return;
      }
      if (isBrandFollowing) {
        this.setState({ isBrandFollowing: false, followChanging: true });
        unfollowBrand(brandCd)
          .then(result => {
            return getBrandFollowStatus(brandCd);
          })
          .then(result => this.setState({ isBrandFollowing: result.data, followChanging: false }));
      } else {
        this.setState({ isBrandFollowing: true, followChanging: true });
        followBrand(brandCd)
          .then(result => {
            return getBrandFollowStatus(brandCd);
          })
          .then(result => this.setState({ isBrandFollowing: result.data, followChanging: false }));
      }
    }

    return (
      <Button
        innerComponent={(
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={[styles.followButtonText, (isBrandFollowing && styles.followingButtonText)]}>
              { isBrandFollowing ? '팔로우' : '팔로우' }
            </Text>
            { isBrandFollowing ? (<CheckIcon />) : (<PlusIcon />)}
          </View>
        )}
        containerStyle={[styles.followButtonContainer, (isBrandFollowing && styles.followingButtonContainer)]}
        onPress={onPress}
        disabled={followChanging}
        disabledOpacity={1}
      />
    );
  }

  render() {
    const { router, containerStyle, product } = this.props;
    const discountRate = Math.round(product.discPricePct * 100);
    const isSoldOut = product.soldOut === 'y';
    const onPressBrand = () => {
      router.push({
        pathname: '/shop',
        state: {
          // Shop탭에서 브랜드 목록 로딩이 아직 안 되었을 수 있으므로 필터 표시에 필요한 모든 정보를 넘겨줘야 함
          brandCd: {
            id: product.brandCd,
            type: 'brand',
            name: product.brandNm,
          }
        },
      });
    };

    return (
      <View style={[styles.container, containerStyle]}>
        <View style={styles.brandContainer}>
          <TouchableHighlightWithContainer
            onPress={onPressBrand}
          >
            <Text style={styles.brand}>{product.brandNm}</Text>
          </TouchableHighlightWithContainer>
          {this.renderFollowButton()}
        </View>
        <View style={styles.titleContainer}>
          <Text numberOfLines={2} style={styles.title}>{product.title}</Text>
        </View>

        <View style={[
          styles.priceInfo,
          isSoldOut && styles.priceInfoSoldOut,
        ]}
        >
          <View>
            {discountRate > 0 && (
              <View style={styles.priceDiscountInfo}>
                <NumberFormat
                  value={Math.floor(product.fixedPrice)}
                  displayType="text"
                  thousandSeparator
                  renderText={value => (
                    <Text style={[
                      styles.productOrgPrice,
                      (product.goodsPrice > 0 ? styles.colorBlack : styles.colorHidden),
                    ]}
                    >
                      {value}원
                    </Text>
                  )}
                />
                <Text style={styles.discountRate}>
                  {isNaN(discountRate) ? '' : `${discountRate}%`}
                </Text>
              </View>
            )}
          </View>
          <View style={styles.sellPriceContainer}>
            <NumberFormat
              value={product.goodsPrice}
              displayType="text"
              thousandSeparator
              decimalScale={0}
              renderText={value => (
                <Text style={styles.sellPrice}>
                  {value}원
                </Text>
              )}
            />

            { this.renderLabel() }
          </View>
        </View>
      </View>
    );
  }
}

export default connect(
  (state, {}) => ({
    auth: state.auth,
  }),
  actions,
)(ProductInfo);
