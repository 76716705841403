import React from 'react';
import { connect } from 'react-redux';

import { actions } from 'react-shared';

class LegalPolicyPage extends React.PureComponent {
  state = {
    contents: null,
  };

  componentDidMount() {
    const {
      logEventPageview, type,
    } = this.props;

    this.loadPolicy();

    logEventPageview({ page_name: 'LegalPolicy', page_key: type });
  }

  async loadPolicy() {
    const { getPolicy, type } = this.props;

    try {
      const ret = await getPolicy(type);
      const text = await ret.text();

      this.setState({ contents: text });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('LegalPolicyPage error', error);
    }
  }

  render() {
    const { contents } = this.state;

    return (
      // eslint-disable-next-line react/no-danger
      <div className="html-container" dangerouslySetInnerHTML={{ __html: contents }} />
    );
  }
}

export default connect((state, { params }) => {
  const { type } = params;
  return {
    type,
  };
}, actions)(LegalPolicyPage);
