import React from 'react';
import {
  StyleSheet, View, Dimensions, Text, FlatList,
} from 'react-native';

import { Constants, Styles, Colors } from '../../../constants';
import { getDateTimeString } from 'shared/util';
import CouponBackground from '../../../icons-v2/CouponBackground';


const {
  height: windowHeight
} = Dimensions.get('window');

const stylesHtml = {
  blankContainer: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: `${-1 * (Constants.mainHeaderHeight + 49)}px`,
    marginBottom: `${-1 * Constants.bottomMargin}px`,
    overflow: 'hidden',
    touchAction: 'none',
  },
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerContainer: {
    marginVertical: 20,
  },
  headerTitle: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19,
    height: 19,
  },
  headerContent: {
    marginTop: 10,
    ...Styles.pretendard400,
    color: 'rgba(0, 0, 0, 0.5)',
    lineHeight: 18,
    fontSize: 12,
  },
  listView: {
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  blankText: {
    fontSize: 16,
    lineHeight: 19.2,
    color: Colors.defaultGray08,
    textAlign: 'center',
    ...Styles.pretendard400,
  },
  blankInnerContainer: {
    width: 240,
    height: 19,
    alignItems: 'center',
  },
  itemContainer: {
    height: 159 + 14 + 44,
  },
  itemCoupon: {
    height: 159,
  },
  itemCouponTextContainer: {
    position: 'absolute',
    paddingTop: 14,
    paddingBottom: 18,
    paddingStart: 20,
  },
  itemDiscount: {
    color: '#fff',
    lineHeight: 60,
    fontSize: 50,
    ...Styles.pretendard700,
  },
  itemTitle: {
    marginTop: 23,
    color: '#fff',
    fontSize: 18,
    lineHeight: 21.6,
    ...Styles.pretendard600,
  },
  itemExpireAt: {
    marginTop: 4,
    color: '#fff',
    fontSize: 15,
    lineHeight: 18,
    ...Styles.pretendard400,
  },
  itemDescriptionContainer: {
    marginTop: 14,
  },
  itemDescription: {
    fontSize: 18,
    lineHeight: 21.6,
    color: '#000',
    ...Styles.pretendard600,
  },
  itemCreatedAt: {
    marginTop: 4,
    color: '#000',
    fontSize:15,
    lineHeight: 18,
    ...Styles.pretendard400,
  },
});


class TabCoupon extends React.PureComponent {
  state = {
    isFetchingNextPage: false,
  }

  loadData = (nextPage) => {
    const { getUserCouponList } = this.props;
    const { isFetchingNextPage } = this.state;

    this.setState({ isFetchingNextPage: nextPage }, () => {
      getUserCouponList(nextPage)
        .then(() => this.setState({ isFetchingNextPage: false }));
    });
  }

  renderItem = ({ item, index }) => {
    const { title, description, discount, expireAt, createdAt,
      backgroundColor, discountColor, benefitType,
      couponMaxBenefitType, couponMaxBenefit
    } = item;

    return (
      <View style={styles.itemContainer}>
        <View style={styles.itemCoupon}>
          <CouponBackground color={backgroundColor} />
          <View style={styles.itemCouponTextContainer}>
            <Text style={[styles.itemDiscount, {color: discountColor}]}>
              { couponMaxBenefitType === 'y' ? `￦${(parseInt(couponMaxBenefit).toLocaleString())}`
              : benefitType === 'percent' && `${discount}% OFF`}
              { benefitType === 'fix' && `${discount}원 OFF`}
            </Text>
            <Text style={styles.itemTitle}>
              {title}
            </Text>
            <Text style={styles.itemExpireAt}>
              {expireAt}
            </Text>
          </View>
        </View>
        <View style={styles.itemDescriptionContainer}>
          <Text style={styles.itemDescription}>
            {description}
          </Text>
          <Text style={styles.itemCreatedAt}>
            {createdAt}
          </Text>
        </View>
      </View>
    );
  }

  renderBlank = () => {
    return (
      <div style={stylesHtml.blankContainer}>
        <View style={styles.blankInnerContainer}>
          <View style={{ flex: 1, justifyContent: 'flex-start' }}>
            <Text style={styles.blankText}>등록된 쿠폰이 없습니다.</Text>
          </View>
        </View>
      </div>
    );
  }

  getExpireAt(dateStr) {
    const [date, time] = dateStr.split(' ');
    const [year, month, day] = date.split('-');
    const [hour, min, sec] = time.split(':');
    return `${year}년 ${month}월 ${day}일 ${hour}:${min}까지 사용 가능`;
  }

  renderHeader() {
    return (
      <View style={styles.headerContainer}>
        <Text style={styles.headerTitle}>쿠폰 사용 안내</Text>
        <Text style={styles.headerContent}>
          주문당 1개의 쿠폰을 적용하실 수 있습니다. (일부상품 제외){'\n'}
          프리오더 상품의 경우 쿠폰 사용이 제한됩니다.{'\n'}
          네이버페이 결제시에는 쿠폰 사용이 제한됩니다.{'\n'}
          고객님의 변심으로 인한 취소, 반품시에는 쿠폰이 재발급되지 않습니다.
        </Text>
      </View>
    );
  }

  render() {
    let { pagination, userCouponList = [] } = this.props;
    const {
      showSmartBanner,
    } = this.props;

    const { isFetchingNextPage } = this.state;
    const couponList = userCouponList.map(c => {

      return ({
        title: c.couponNm,
        description: c.couponDescribed,
        expireAt: this.getExpireAt(c.memberCouponEndDate),
        createdAt: getDateTimeString(new Date(c.regDt.replace(/-/g, '/'))),
        discount: parseInt(c.couponBenefit, 10),
        benefitType: c.couponBenefitType,
        backgroundColor: '#000',
        discountColor: Colors.primaryRed,
        couponMaxBenefitType : c.couponMaxBenefitType,
        couponMaxBenefit : c.couponMaxBenefit,
      });
    });

    if (couponList.length === 0) {
      return this.renderBlank();
    }

    const listViewStyles = [styles.listView];
    listViewStyles.push({
      height: windowHeight
        - (showSmartBanner && Constants.smartBannerHeight)
        - Constants.mainHeaderHeight
        - 49,
    });

    return (
      <View style={styles.container}>
        <FlatList
          data={couponList}
          style={listViewStyles}
          contentContainerStyle={(couponList.length === 0) ? { flex: 1 } : {}}
          keyExtractor={(item, index) => index.toString()}
          renderItem={this.renderItem}
          ListHeaderComponent={this.renderHeader()}
          ListFooterComponent={(<View style={{ height: 20 + Constants.bottomMargin }}/>)}
          ItemSeparatorComponent={() => {
            return (
              <View style={{ height: 40 }}/>
            );
          }}
          onEndReached={() => {
            if (!pagination) {
              return;
            }
            if (isFetchingNextPage || pagination.isFetching || pagination.nextPageUrl === null) {
              return;
            }
            this.loadData(true);
          }}
        />
      </View>
    );
  }
}

export default TabCoupon;
