import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Dimensions, StyleSheet, View, TextInput } from 'react-native';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions, loadEntities } from 'shared';
import Styles from '../../../constants/Styles';
import Colors from '../../../constants/Colors';

import ShippingAddressInfoTabList from './ShippingAddressInfoTabList';
import ShippingAddressInfoTabNew from './ShippingAddressInfoTabNew';

const initialLayout = { width: Dimensions.get('window').width };


const useStyles = () => ({
  tabs: {
    paddingLeft: 20 - 3.5,
    paddingRight: 20 - 3.5,
    height: 38,
    minHeight: 38,
  },
  indicator: {
    height: 0,
  },
  tab: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    backgroundColor: '#fff',
    overflow: 'hidden',
    width: (initialLayout.width - 20 * 2 - 7) / 2,
    textAlign: 'center',
    height: 38,
    minHeight: 38,
    padding: 0,
    marginLeft: 3.5,
    marginRight: 3.5,
    color: '#000',
  },
  tabSelected: {
    backgroundColor: '#000',
    color: '#fff',
  },
  tabWrapper: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 14,
    height: 17,
    marginTop: 11,
    marginBottom: 10,
  },
  rippleVisible: {
    opacity: 1,
    color: Colors.touchEffectColorWhite,
    animation: '0ms',
  },
});


const styles = StyleSheet.create({
  container: {
  },
  memoInputBox: {
    backgroundColor: 'rgb(246, 247, 248)',
    marginTop: 10,
    marginBottom: 20,
    marginHorizontal: 20,
    paddingTop: 16,
    paddingBottom: 10,
    paddingHorizontal: 16,
    height: 52,
    fontSize: 15,
    ...Styles.appleSDGothicNeoBoldRegular,
  },
});


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export class ShippingAddressInfo extends React.Component {
  listTabRef = React.createRef();

  newTabRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      index: 1,
      shippingAddressInfo: {
        orderMemo: '',
      },
    };

    const { shippingAddresses } = this.props;

    if (shippingAddresses.length > 0) {
      this.state.index = 0;
    }
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentDidUpdate(prevProps) {
    const { shippingAddresses } = this.props;
    const { shippingAddresses: prevShippingAddresses } = prevProps;

    if (prevShippingAddresses.length !== shippingAddresses.length
      && shippingAddresses.length <= 0) {
      this.updateRoute(1);
    }
  }

  updateRoute = index => {
    const { shippingAddresses } = this.props;
    if (index === 0 && shippingAddresses.length <= 0) {
      return;
    }

    this.setState({ index });
  }

  onDataChanged = data => {
    const { shippingAddressInfo } = this.state;
    const { onDataChanged } = this.props;

    const newShippingAddressInfo = {
      ...shippingAddressInfo,
      ...data,
    };

    if (!isEqual(shippingAddressInfo, newShippingAddressInfo)) {
      this.setState({ shippingAddressInfo: newShippingAddressInfo });
    }
    onDataChanged(newShippingAddressInfo);
  }

  onChangeOrderMemo = orderMemo => {
    this.onDataChanged({ orderMemo });
  }

  validateInputField() {
    const { index } = this.state;
    switch (index) {
    case 0:
      return this.listTabRef.current.validateInputField();

    case 1:
      return this.newTabRef.current.validateInputField();

    default:
      return true;
    }
  }

  render() {
    const {
      classes, shippingAddresses,
    } = this.props;

    const {
      index,
      // routes,
      shippingAddressInfo,
    } = this.state;
    const { orderMemo } = shippingAddressInfo;

    const newProps = {
      ...this.props,
      // isFocused: (index === routes.indexOf(route)),
      onDataChanged: this.onDataChanged,
      updateRoute: this.updateRoute,
    };

    return (
      <View style={styles.container}>
        <Tabs
          value={index}
          onChange={(event, newValue) => this.setState({ index: newValue })}
          variant="fullWidth"
          classes={{
            root: classes.tabs,
            indicator: classes.indicator,
          }}
        >
          <Tab
            label="배송지 목록"
            classes={{
              root: classes.tab,
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            disabled={shippingAddresses.length <= 0}
            TouchRippleProps={{ classes: { rippleVisible: classes.rippleVisible } }}
          />
          <Tab
            label="새로운 배송지"
            classes={{
              root: classes.tab,
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            TouchRippleProps={{ classes: { rippleVisible: classes.rippleVisible } }}
          />
        </Tabs>

        <TabPanel value={index} index={0}>
          <ShippingAddressInfoTabList
            ref={this.listTabRef}
            isFocused={index === 0}
            {...newProps}
            shippingAddresses={shippingAddresses}
          />

        </TabPanel>
        <TabPanel value={index} index={1}>
          <ShippingAddressInfoTabNew
            ref={this.newTabRef}
            isFocused={index === 1}
            {...newProps}
          />
        </TabPanel>

        <TextInput
          style={[styles.memoInputBox, { outline: 'none' }]}
          placeholder="배송시 요청사항을 남겨주세요"
          placeholderTextColor="#3F3F3F"
          value={orderMemo}
          onChangeText={this.onChangeOrderMemo}
        />
      </View>
    );
  }
}

export default withStyles(useStyles)(connect(
  state => ({
    ...loadEntities(state, 'shippingAddresses', 'shippingAddresses'),
  }),
  actions,
  null,
)(ShippingAddressInfo));
