import React from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, StyleSheet, View, Text } from 'react-native';

import Masonry from 'react-masonry-css'
import { Waypoint } from 'react-waypoint';

import {
  Styles, Constants,
} from '../../../constants';

import {
  Button, StyleFeed
} from '../../../components-v2';


const stylesHtml = {
  blankContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    touchAction: 'none',
  },
};

const styles = StyleSheet.create({
  container: {
  },
  blankContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  blankContentContainer: {
    width: 240,
    alignItems: 'center',
  },
  blankText: {
    marginBottom: 20,
    fontSize: 16,
    lineHeight: 23,
    height: 46,
    ...Styles.pretendard400,
    textAlign: 'center',
  },
  blankButton: {
    width: 200,
    paddingVertical: 10.5,
    borderWidth: 0,
    borderColor: 'transparent',
    backgroundColor: '#000',
    justifyContent: 'center',
    alignItems: 'center',
  },
  blankButtonText: {
    fontSize: 16,
    lineHeight: 19.2,
    color: '#fff',
    ...Styles.pretendard600,
    height: 19,
  },
  itemContainer: {
    marginBottom: 40,
  },
});


class TabFeed extends React.Component {

  state = {
    refreshing: false,
    isFetchingNextPage: false,
  };

  componentDidMount() {
    const { loadData } = this.props;
    if (loadData) {
      loadData(false, {});
    }
  }

  componentDidUpdate(prevProps) {
    const { loadData } = this.props;
    if (!prevProps.loadData && loadData) {
      loadData(false, {});
    }

    const { pagination } = this.props;

    if (prevProps.pagination.isFetching && !pagination.isFetching) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isFetchingNextPage: false, refreshing: false });
    }
  }

  renderBlank() {
    const {
      showSmartBanner,
      router
    } = this.props;

    const containerStyles = {
        ...stylesHtml.blankContainer,
        height: `calc(100vh - ${(Constants.mainHeaderHeight + 94 + 49) + (showSmartBanner && Constants.smartBannerHeight) + Constants.bottomTabBarHeight}px)`,
    };

    return (
      <div style={containerStyles}>
        <View style={styles.blankContainer}>
          <View style={styles.blankContentContainer}>
            <Text style={styles.blankText}>사진을 업로드하면 내 피드와 스타일 메뉴에 공유됩니다.</Text>
            <Button
              title="피드 업로드하기"
              containerStyle={styles.blankButton}
              titleStyle={styles.blankButtonText}
              onPress={() => {router.push('/style/upload')}}
              />
          </View>
        </View>
      </div>
    )
  }

  renderFooter = () => {
    const { loadData, pagination } = this.props;
    const { isFetchingNextPage, refreshing } = this.state;

    return (
      <React.Fragment>
        <Waypoint
          onEnter={() => {
              if (isFetchingNextPage || refreshing || pagination.isFetching || pagination.nextPageUrl === null) {
                return;
              }
              loadData(true, {});
          }}
        />
        { pagination.nextPageUrl !== null && <ActivityIndicator size="small" color="gray" /> }
        <View style={{ height: Constants.viewHorizontalPadding }} />
      </React.Fragment>
    );
  }

  renderStyleFeedItem({ item }) {
    const { router } = this.props;
    return (
      <StyleFeed
        key={item._id}
        containerStyle={styles.itemContainer}
        styleFeed={item}
        enableViewsCount
        router={router}
      />
    );
  }

  render() {
    const {
      posts, pagination, refreshing,
    } = this.props;

    if (!posts || posts.length == 0) {
      return this.renderBlank();
    }

    return (
      <View style={styles.container}>
        <View style={{ height: 10 }} />

        <Masonry
          breakpointCols={2}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {posts.map(item => {
            return this.renderStyleFeedItem({ item })
          })}
        </Masonry>
        {this.renderFooter()}
      </View>
    )
  }
}


TabFeed.propTypes = {
  showSmartBanner: PropTypes.bool,
}


TabFeed.defaultProps = {
  showSmartBanner: false,
};


export default TabFeed;
