import * as React from "react"
import Svg, { Rect, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={51}
      height={30}
      viewBox="0 0 51 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Rect width={51} height={30} rx={15} fill="#000" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 28c7.18 0 13-5.82 13-13S43.18 2 36 2 23 7.82 23 15s5.82 13 13 13z"
        fill="#fff"
      />
    </Svg>
  )
}

export default SvgComponent
