import React from 'react';
import { TouchableOpacity, TouchableHighlight, Dimensions, View, StyleSheet, Text } from 'react-native';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import startsWith from 'lodash/startsWith';
import endsWith from 'lodash/endsWith';

import InputBase from '@material-ui/core/InputBase';

import { Colors } from '../../constants';
import { reactNativeStyles as Styles } from '../../constants/Styles';

import ArrowBack from './ArrowBack';


const useStyles = theme => ({
  inputContainer: {
    height: 22,
    paddingTop: 3,
  },
  input: {
    fontSize: 16,
    lineHeight: '19px',
    height: 19,
    fontWeight: '400',
    ...Styles.pretendardVariable,
  },
});

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingLeft: 16,
    paddingTop: 21,
  },
  containerBottomBorder: {
    borderBottomWidth: 1,
    borderColor: Colors.lightGray,
  },
  backButtonContainer: {
  },
  headerButtonContainer: {
    marginTop: 1,
    marginLeft: 16,
    marginEnd: 20,
  },
  searchBarContainer: {
    flex: 1,
    flexGrow: 1,
  },
  searchButtonContainer: {
  },
  searchButton: {
  },
  searchButtonText: {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: '600',
    ...Styles.pretendardVariable,
  },
});

class HeaderSearchBar extends React.Component {
  state = {
    searchKeyword: '',
    isSearchScreen: true,
  };


  componentDidMount() {
    const { router, searchKeyword } = this.props;
    const { isSearchScreen } = this.state;

    this.setState({
      searchKeyword,
      isSearchScreen: (
        startsWith(router.location.pathname, '/shop/search')
        && !endsWith(router.location.pathname, 'search/result')
      ),
    }, () => {
      if (isSearchScreen) {
        this.searchBar && this.searchBar.focus();
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { router } = this.props;
    const { searchKeyword: stateSearchKeyword, isSearchScreen } = this.state;

    if (endsWith(router.location.pathname, 'search/result') && isSearchScreen) {
      this.setState({ isSearchScreen: false });
    } else if (endsWith(router.location.pathname, '/shop/search') && !isSearchScreen) {
      this.setState({ isSearchScreen: true }, () => {
        this.searchBar && this.searchBar.focus();
      });
    }

    const routerSearchKeyword = (router.location.state ? router.location.state.searchKeyword : '') || '';
    const searchKeyword = routerSearchKeyword || stateSearchKeyword;
    if (prevState.searchKeyword !== searchKeyword) {
      this.onChangeText(searchKeyword, router.location.state.submit);
    }
  }

  onChangeText = (searchKeyword, submit) => {
    const { callbacks } = this.props;

    this.setState({ searchKeyword }, () => {
      if (callbacks && callbacks.hasOwnProperty('onChangeText')) {
        callbacks.onChangeText(searchKeyword);
      }
      if (submit) {
        this.onSubmitEditing();
      }
      this.searchBar && this.searchBar.focus();
    });
  }

  onKeyPress = (evt) => {
    if (evt.key === 'Enter') {
      this.onSubmitEditing();
    }
  }

  onSubmitEditing = _evt => {
    const { callbacks } = this.props;
    const { searchKeyword } = this.state;

    if (callbacks && callbacks.hasOwnProperty('onSubmitEditing')) {
      callbacks.onSubmitEditing(searchKeyword);
    }
  }

  onClear = () => {
    const { callbacks } = this.props;

    if (callbacks && callbacks.hasOwnProperty('onClear')) {
      callbacks.onClear();
    }
  }

  onPressSearchButton = () => {
    const { searchKeyword } = this.state;
    if (searchKeyword.length > 0) {
      this.onSubmitEditing();
    }
  }

  moveToSearch = keyword => {
    const { router } = this.props;
    const { searchKeyword } = this.state;

    if (keyword === null) {
      router.push({
        pathname: '/shop/search',
        state: { searchKeyword, submit: false },
      });
    } else {
      router.push({
        pathname: '/shop/search',
        state: { searchKeyword: keyword, submit: false },
      });
    }
  }

  render() {
    const { classes, callbacks, router } = this.props;
    const { searchKeyword, isSearchScreen } = this.state;

    const containerStyle = [styles.container];

    return (
      <View style={containerStyle}>
        <View style={styles.backButtonContainer}>
          <View style={Styles.touchEffectContainerInHeader}>
            <ArrowBack
              backButtonStyle='arrow'
              router={router}
            />
          </View>
        </View>
        <TouchableOpacity
          style={styles.searchBarContainer}
          onPress={(isSearchScreen) ? null : () => this.moveToSearch(null)}
        >
          <InputBase
            ref={searchBar => { this.searchBar = searchBar; }}
            placeholder="브랜드,아이템,태그를 검색해보세요"
            onChange={evt => this.onChangeText(evt.target.value, false)}
            onKeyPress={this.onKeyPress}
            value={searchKeyword}
            fullWidth={true}
            readOnly={!isSearchScreen}
            autoCapitalize="none"
            classes={{ root: classes.inputContainer, input: classes.input }}
          />
        </TouchableOpacity>
        <View style={styles.headerButtonContainer}>
          <View style={styles.searchButtonContainer}>
            <View style={Styles.touchEffectContainer}>
              <TouchableHighlight
                underlayColor={Colors.touchEffectColorWhite}
                onPress={(isSearchScreen) ? this.onPressSearchButton : () => this.moveToSearch('')}
                style={[styles.searchButton, Styles.touchEffect]}
              >
                <Text style={styles.searchButtonText}>{(isSearchScreen) ? '검색':'취소'}</Text>
              </TouchableHighlight>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

export default withStyles(useStyles)(connect(
  state => ({
  }),
  null,
)(HeaderSearchBar));
