import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { camelizeKeys } from 'humps';
import dayjs from 'dayjs';
import Flatpickr from 'react-flatpickr';
// import { browserHistory } from 'react-router';
// all codes using browserHistory are legacy in react-router v3;
// anyways, the solution preventing admins from accidently clicking backbutton, reload, or close window
// eslint-disable-next-line max-len
// I follow the https://stackoverflow.com/questions/32841757/detecting-user-leaving-page-with-react-router/50388073#50388073
// but this.props.route.path doesn't have exact path, I instead used this.props.router.location.pathname
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  GridList,
  GridListTile,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import FiberNew from '@material-ui/icons/FiberNew';
import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import partition from 'lodash/partition';
import pick from 'lodash/pick';
import without from 'lodash/without';
import difference from 'lodash/difference';
import findIndex from 'lodash/findIndex';

import * as adminActions from 'react-shared/adminActions';

import {
  loadEntities,
  objectDiff,
  textToClipboard,
  bigDecimalToBase64,
} from 'react-shared/util';

import {
  Media,
  Spinner,
} from 'components';

import {
  InstagramMediaDialog,
  InstagramSearchDialog,
  ItemList,
  ThumbnailList,
  ProductSelector,
} from 'components/admin';

import config from '../../config'
import { Update } from '@material-ui/icons';
import { has } from 'lodash';

const styles = theme => ({
  cancelConfirmButton: {

  },
  createPostButton: {
    position: 'absolute',
    right: theme.spacing.unit * 2,
    top: theme.spacing.unit * 2,
  },
  deletePostButton: {
    position: 'absolute',
    right: theme.spacing.unit * 12,
    top: theme.spacing.unit * 2,
  },
  deleteMediaButton: {
    width: theme.spacing.unit * 3,
    height: theme.spacing.unit * 3,
    float: 'right',
  },
  snackbar: {
    textAlign: 'center',
  },
  formControlLabel: {

  },
  dialogPaper: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
    width: '100%',
  },
  dialogPaperProductList: {
    marginTop: theme.spacing.unit * 2,
    paddingLeft: 10,
    paddingRight: 10,
    width: 960,
    maxWidth: 960,
  },
  select: {
    width: 200,
  },
  mediaId: {
    width: 280,
  },
  link: {
    width: '100%',
  },
  gridListTileBarIconButton: {
    color: '#ffffff',
  },
  paper: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
  },
  outerButton: {
    marginRight: theme.spacing.unit * 2,
  },
  subMenuTitles: {
    marginBottom: theme.spacing.unit * 2,
  },
  thumbnailArea: {
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'table-cell',
  },
  textField: {
    minHeight: 50,
  },
  textFieldHalf: {
    minHeight: 50,
    width: 280,
  },
  year: {
    width: 55,
    marginLeft: 20,
    textAlign: 'center',
  },
  dateTime: {
    width: 32,
    marginLeft: 20,
    textAlign: 'center',
  },
  productCaption: {
    display: 'flex',
    justifyContent: 'space-around',
  }
});

class PostPage extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUploadImage = this.handleUploadImage.bind(this);
    this.handleUploadVideo = this.handleUploadVideo.bind(this);
    this.handleUploadImageFromInstagramPost = this.handleUploadImageFromInstagramPost.bind(this);
    this.handleAddProduct = this.handleAddProduct.bind(this);
    this.handleAddProductSubmit = this.handleAddProductSubmit.bind(this);
    this.handleRemoveSalePrice = this.handleRemoveSalePrice.bind(this);
    this.addInstagramMediaId = this.addInstagramMediaId.bind(this);
    this.renderProductCaption = this.renderProductCaption.bind(this);
    this.renderMediaCaption = this.renderMediaCaption.bind(this);
    // this.onCloseInstagramMediaDialog = this.onCloseInstagramMediaDialog.bind(this);
    this.parseMediasFromInstagram = this.parseMediasFromInstagram.bind(this);
    this.handleInstagramMediaDialogScroll = this.handleInstagramMediaDialogScroll.bind(this);
    this.handleMediaCopyClick = this.handleMediaCopyClick.bind(this);
    this.state = {
      featured: {},
      instagramMedias: [],
      instagramUser: {},
      instagramUserMedias: [],
      isInstagramMediaDialogOpen: false,
      isInstagramMediasFetching: false,
      isInstagramSearchDialogOpen: false,
      isInstagramGetByUrlDialogOpen: false,
      instagramPostUrl: '',
      instagramPostMedias: [],
      isInstagramPostFetching: false,
      isItemDialogOpen: false,
      isRecommend: false,
      isUpdatingPost: false,
      products: [],
      checkedProducts: {},
      newCheckedProducts: {},
      productSearchKeyword: '',
      isGodoOnly: false,
      link: '',
      media: [],
      noMoreInstagramMedias: false,
      ownerId: '564052b35ad3179774046915',
      sale: '',
      tilesInRow: 4,
      promotion: false,
      collectionMode: false,
      description: '',
      visible: false,
      displayAspectRatio: 1,
    };
  }

  async componentDidMount() {
    const { loadCreators, loadPost, loadPostShortenUrl, postId, post, route, router, shorten, users } = this.props;
    if (users.length === 0) {
      await loadCreators();
    }
    if (postId !== 'new') {
      if (post) {
        const checkedProducts = {};
        post.products.forEach((product) => {
          checkedProducts[product._id] = product;
        });
        this.setState({
          checkedProducts,
          newCheckedProducts: {},
          ownerId: post.owner._id,
          ...post,
        });
      } else {
        await loadPost(postId)
          .then(postData => {
            const checkedProducts = {};
            postData.products.forEach((product) => {
              checkedProducts[product._id] = product;
            });
            this.setState({
              checkedProducts,
              newCheckedProducts: {},
              ownerId: postData.owner._id,
              ...postData,
            });
          });
        // Todo: route to admin/posts when there's no post with such postId
      }
      if (!shorten) {
        await loadPostShortenUrl(postId);
      }
    }
    this.saveState();
    window.addEventListener('resize', () => this.handleResize());
    window.addEventListener('beforeunload', event => this.handleClose(event));
    this.mutationObserver = new MutationObserver(() => this.removeBodyStyle());
    this.mutationObserver.observe(document.body, {
      attributes: true,
      attributeFilter: ['style'],
    });
    router.setRouteLeaveHook(route, () => this.handleBackbutton());
  }

  componentDidUpdate(prevProps) {
    const { postId } = this.props;
    if (prevProps.postId !== postId && postId === 'new') {
      window.location.reload();
    }
  }

  componentWillUnmount() {
    this.mutationObserver.disconnect();
    window.removeEventListener('resize', () => this.handleResize());
    window.removeEventListener('beforeunload', event => this.handleClose(event));
  }

  saveState() {
    this.savedState = cloneDeep(this.state);
  }

  hasPostEdited() {
    return !!Object.keys(objectDiff(this.savedState, this.state)).length;
  }

  static getMediaFromItem(item) {
    return item.media;
  }

  // onCloseInstagramMediaDialog() {
  //   const dialog = this.refs.instagramMediaDialog.refs.dialogContent;
  //   dialog.removeEventListener('scroll', this.handleInstagramMediaDialogScroll);
  //   dialog.removeEventListener('touchmove', this.handleInstagramMediaDialogScroll);
  // }

  getLastInstagramMediaId() {
    const medias = this.state.instagramUserMedias;
    return medias.length > 0 ? medias[medias.length - 1].id : 0;
  }

  showInstagramMediaDialog(user) {
    this.setState({
      instagramUser: user,
      instagramUserMedias: [],
      isInstagramSearchDialogOpen: false,
      isInstagramMediaDialogOpen: true,
      noMoreInstagramMedias: true,
    }, () => {
      this.fetchInstagramUserMedias(user.instagram.id);

      // const dialog = this.refs.instagramMediaDialog.refs.dialogContent;
      // dialog.addEventListener('scroll', this.handleInstagramMediaDialogScroll);
      // dialog.addEventListener('touchmove', this.handleInstagramMediaDialogScroll);
    });
  }

  showSnackbar(message) {
    this.setState({
      snackbarMsg: message,
      snackbarOpen: true,
    });
  }

  handleClose(event) {
    if (this.hasPostEdited()) {
      event.preventDefault();
      event.returnValue = '포스트를 작성중이시네요, 정말 창을 닫으시겠어요?';
      return event.returnValue;
    }
    return event;
  }

  removeBodyStyle() {
    const {
      isInstagramMediaDialogOpen: media,
      isInstagramSearchDialogOpen: search,
      isInstagramGetByUrlDialogOpen: getByUrl,
      isItemDialogOpen: item,
    } = this.state;
    if (!media && !search && !getByUrl && !item && document.body.style.overflow === 'hidden') {
      document.body.style.overflow = '';
    }
  }

  handleBackbutton() {
    const { router: { location: { pathname } } } = this.props;
    if (this.hasPostEdited()) {
      // eslint-disable-next-line no-restricted-globals
      const toBeClosed = confirm('포스트를 작성중이시네요, 정말 창을 닫으시겠어요?');
      if (!toBeClosed) {
        window.history.pushState(null, null, pathname);
        return false;
      }
    }
    return true;
  }

  handleResize() {
    if (window.innerWidth <= 600 && this.state.tilesInRow === 4) {
      this.setState({ tilesInRow: 1 });
    } else if (window.innerWidth > 600 && this.state.tilesInRow === 1) {
      this.setState({ tilesInRow: 4 });
    }
  }

  handleDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  handleLinkChange(event) {
    this.setState({ link: event.target.value });
  }

  handleSaleChange(event) {
    this.setState({ sale: event.target.value });
  }

  handleSubmit(event) {
    const fields = [
      'sale', 'link', 'media', 'ownerId',
      'items', 'products', 'featured', 'instagramMedias', 'visible',
      'promotion', 'collectionMode', 'description',
      'displayAspectRatio',
    ];
    const post = pick(this.state, fields);
    if (!post.link) {
      post.sale = null;
    }

    const { adminCreatePost, adminUpdatePost, createPostShortenUrl, postId, router } = this.props;
    if (post.media.length === 0) {
      alert('포스트 이미지/동영상이 등록되지 않았습니다');
      return;
    }
    if (postId === 'new') {
      this.setState(
        { isUpdatingPost: true },
        () => adminCreatePost(post)
          .then(newPost => createPostShortenUrl(newPost._id))
          .then(() => this.saveState())
          .catch(() => this.showSnackbar('Update failed.'))
          .then(() => {
            this.showSnackbar('Post uploaded successfully.');
          })
          .then(() => router.push('/admin/posts'))
      );
    } else {
      this.setState(
        { isUpdatingPost: true },
        () => adminUpdatePost(postId, post)
          .then(() => this.setState({ isUpdatingPost: false }, () => this.saveState()))
          .then(() => this.showSnackbar('Post updated succesfully.'))
          .catch(() => this.showSnackbar('Update failed.'))
      );
    }
    event.preventDefault();
  }

  handleUploadImage() {
    const {
      displayAspectRatio,
    } = this.state;

    const croppingAspectRatio = Number.parseFloat(displayAspectRatio);
    // console.log('croppingRatio=', croppingRatio);

    const uploadWidget = window.cloudinary.openUploadWidget({
      ...config.get('cloudinary'),
      sources: ['local', 'url', 'camera'],
      default_source: 'local',
      cropping: true,
      croppingShowDimensions: true,
      croppingAspectRatio,
      client_allowed_formats: ['jpg', 'jpeg', 'png', 'gif'],
      resource_type: 'image',
      multiple: false,
    }, (error, result) => {
      if (error) {
        // eslint-disable-next-line no-console
        console.error('handleUploadImage openUploadWidget error=', error);
      } else {
        // eslint-disable-next-line no-console
        // console.log('handleUploadImage openUploadWidget result=', result);
      }

      if (result && result.event === 'success') {
        uploadWidget.close();
        this.setState(prevState => ({ media: prevState.media.concat(camelizeKeys([result.info])) }));
      }
    });

    uploadWidget.open();
  }

  handleUploadVideo() {
    const cloudinaryConfig = config.get('cloudinary');
    cloudinaryConfig.upload_preset = cloudinaryConfig.video_upload_preset;
    const uploadWidget = window.cloudinary.openUploadWidget({
      ...cloudinaryConfig,
      sources: ['local', 'url', 'camera'],
      default_source: 'local',
      client_allowed_formats: ['mp4', 'mov', 'avi'],
      resource_type: 'video',
      multiple: false,
    }, (error, result) => {
      if (error) {
        // eslint-disable-next-line no-console
        console.error('handleUploadImage openUploadWidget error=', error);
      } else {
        // eslint-disable-next-line no-console
        // console.log('handleUploadImage openUploadWidget result=', result);
      }

      if (result && result.event === 'success') {
        uploadWidget.close();
        this.setState(prevState => ({ media: prevState.media.concat(camelizeKeys([result.info])) }));
      }
    });

    uploadWidget.open();
  }

  handleUploadImageFromInstagramPost() {
    navigator.clipboard.readText().then(mediaOrgLink => {
      const widget = window.cloudinary.createUploadWidget({
        ...config.get('cloudinary'),
        default_source: 'url',
        cropping: 'server',
        cropping_default_selection_ratio: 1.0,
        cropping_aspect_ratio: 1.0,
      }, (error, result) => {
        if (result) {
          this.setState(
            prevState => ({ media: prevState.media.concat(camelizeKeys(result)) }),
            () => { this.showSnackbar(`Media has been added successfully`); },
          );
        }
      });
      widget.open(mediaOrgLink);
      this.showSnackbar(`${mediaOrgLink} copied to clipboard`);
    });
  }

  handleAddProduct() {
    const { checkedProducts } = this.state;
    const newCheckedProducts = { ...checkedProducts };
    this.setState({ newCheckedProducts, isItemDialogOpen: true });
  }

  handleRemoveSalePrice() {
    const { adminRemoveSalePrice, postId } = this.props;
    if (confirm('모든 상품/유사상품에 대해 할인가를 제거합니다.')) {
      adminRemoveSalePrice(postId)
        .then(() => this.showSnackbar('할인가 제거가 완료되었습니다.'))
        .catch(e => this.showSnackbar(`실패했습니다. ${e}`));
    }
  }

  handleAddProductSubmit() {
    const { products: prevProducts, checkedProducts, newCheckedProducts } = this.state;
    let products = cloneDeep(prevProducts);
    const oldKeys = Object.keys(checkedProducts);
    const newKeys = Object.keys(newCheckedProducts);

    const deletedKeys = difference(oldKeys, newKeys);
    deletedKeys.forEach((key) => {
      delete checkedProducts[key];
      products = products.filter(product => (product._id !== key));
    });

    newKeys.forEach((key) => {
      const newProduct = cloneDeep(newCheckedProducts[key]);
      checkedProducts[key] = newProduct;
      const productIdx = findIndex(products, ['_id', key]);
      if (productIdx >= 0) {
        const oldProduct = products[productIdx];
        newProduct.isRecommend = oldProduct.isRecommend;
        newProduct.isSeasonOut = oldProduct.isSeasonOut;
        products[productIdx] = newProduct;
      } else {
        newProduct.isRecommend = false;
        newProduct.isSeasonOut = false;
        products.push(newProduct);
      }
    });

    this.setState({
      products,
      checkedProducts,
      newCheckedProducts: {},
      isItemDialogOpen: false,
    });
  }

  addInstagramMediaId(id) {
    this.setState(prevState => {
      const instagramMedias = cloneDeep(prevState.instagramMedias);
      instagramMedias.push({ id });
      return {
        instagramMedias,
        isInstagramMediaDialogOpen: false,
      };
    });
    // this.onCloseInstagramMediaDialog();
  }

  fetchInstagramUserMedias(userId) {
    if (!userId) {
      throw new Error('Instagram ID is not specified!');
    }
    const { auth: { token } } = this.props;
    const url = `/api/v2/instagram/users/${userId}/media/recent?maxId=${this.getLastInstagramMediaId()}`;
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    this.setState({ isInstagramMediasFetching: true }, () => {
      fetch(url, { headers })
        .then(response => {
          if (!response.ok) {
            throw new Error('해당 Instagram 계정의 재로그인이 필요합니다.');
          } else {
            return response.json();
          }
        })
        .then(medias => {
          if (medias.length === 0) { throw new Error('No media'); }
          this.setState(prevState => ({
            instagramUserMedias: prevState.instagramUserMedias.concat(medias),
            isInstagramMediasFetching: false,
            noMoreInstagramMedias: medias.length < 28,
          }));
        })
        .catch(error => {
          this.setState({
            isInstagramMediasFetching: false,
            noMoreInstagramMedias: true,
          });

          alert(error); // eslint-disable-line no-alert
        });
    });
  }

  handleInstagramMediaDialogScroll(event) {
    const { noMoreInstagramMedias, isInstagramMediasFetching, instagramUser: { instagram: { id } } } = this.state;
    const { target: { scrollTop, scrollHeight, clientHeight } } = event;
    if (!noMoreInstagramMedias && !isInstagramMediasFetching && scrollTop > scrollHeight - clientHeight - 1) {
      this.fetchInstagramUserMedias(id);
    }
  }

  parseMediasFromInstagram() {
    const { instagramPostUrl: url } = this.state;
    const { auth: { token } } = this.props;
    const parseEndpoint = '/api/v2/admin/instagram/parse';
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    const method = 'POST';
    const data = JSON.stringify({ url });
    this.setState({ isInstagramPostFetching: true }, () => fetch(parseEndpoint, { headers, method, body: data })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(instagramPostMedias => {
        this.setState({ instagramPostMedias, isInstagramPostFetching: false });
      })
      .catch(error => alert(error)));
  }

  handleMediaCopyClick(media) {
    this.props.adminSearchPosts({ instagramMediaId: media.id }).then(res => {
      if (res.length === 0) {
        this.addInstagramMediaId(media.id);
        textToClipboard(media.images.standard_resolution.url);
        this.handleUploadImage();
      } else {
        alert(`다른 포스트에 업로드된 미디어입니다 : ${res[0]._id}`);
      }
    });
  }

  renderMediaCaption(item) {
    return (
      <Button
        variant="outlined"
        onClick={() => this.setState(prevState => ({ media: without(prevState.media, item) }))}
      >
        Delete
      </Button>
    );
  }

  renderProductCaption(product, index) {
    const { classes } = this.props;
    return (
      <div className={classes.productCaption}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            window.open(`/admin/products/${product._id}`, '_blank');
          }}
        >
          <Edit />
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            this.setState(prevState => {
              const checkedProducts = prevState.checkedProducts;
              delete checkedProducts[product._id];
              return {
                products: without(prevState.products, product),
                checkedProducts,
              };
            });
          }}
        >
          <Delete color="error" />
        </Button>
      </div>
    );
  }

  renderFeatured() {
    const { featured: { score } } = this.state;
    // const { classes } = this.props;
    // const setFeaturedScore = timeObject => {
    //   let currentScore = dayjs(score);
    //   Object.keys(timeObject).forEach(unit => {
    //     currentScore = currentScore.set(unit, timeObject[unit]);
    //   });
    //   this.setState({ featured: { score: currentScore.valueOf() } });
    // };
    return (
      <div>
        <FormControlLabel
          control={(
            <Checkbox
              checked={!!score}
              disabled={score && score < 10000}
              onChange={event => {
                if (event.target.checked) {
                  this.setState({ featured: { score: dayjs().valueOf() } });
                } else {
                  this.setState({ featured: { score: null } });
                }
              }}
            />
          )}
          label={(score && score > 10000) ? 'Featured at' : 'Featured'}
        />
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css"></link>
        <Flatpickr
          key={score}
          value={score}
          options={{
            enableTime: true,
          }}
          onChange={event => this.setState({ featured: { score: (new Date(event).getTime()) } })}
        />
        {/* {score && score > 10000 && (
          <div style={{ display: 'inline-block' }}>
            <TextField
              className={classes.year}
              type="number"
              value={dayjs(score).year()}
              onChange={event => setFeaturedScore({ 'year': event.target.value })}
            />
            년
            <TextField
              className={classes.dateTime}
              type="number"
              value={dayjs(score).month() + 1}
              onChange={event => setFeaturedScore({ 'month': event.target.value - 1 })}
            />
            월
            <TextField
              className={classes.dateTime}
              type="number"
              value={dayjs(score).date()}
              onChange={event => setFeaturedScore({ 'date': event.target.value })}
            />
            일
            <TextField
              className={classes.dateTime}
              type="number"
              value={dayjs(score).hour()}
              onChange={event => setFeaturedScore({ 'hour': event.target.value })}
            />
            시
            <TextField
              className={classes.dateTime}
              type="number"
              value={dayjs(score).minute()}
              onChange={event => setFeaturedScore({ 'minute': event.target.value })}
            />
            분
          </div>
        )} */}
      </div>
    );
  }

  renderPostImageVideo() {
    const { classes } = this.props;
    const {
      displayAspectRatio,
    } = this.state;

    return (
      <Paper className={classes.paper}>
        <Typography variant="h6" className={classes.subMenuTitles}>포스트 이미지/동영상</Typography>
        <Typography variant="subheading">
          표시 비율:&nbsp;&nbsp;
          <Select
            className={classes.select}
            value={displayAspectRatio}
            onChange={event => {
              this.setState({
                displayAspectRatio: event.target.value,
              });
            }}
          >
            <MenuItem value="1" key="postpage_posttype_normal">
              <Typography variant="subheading">1:1</Typography>
            </MenuItem>
            <MenuItem value="0.8" key="postpage_posttype_promotion">
              <Typography variant="subheading">4:5</Typography>
            </MenuItem>
            <MenuItem value="0.5625" key="postpage_posttype_collection">
              <Typography variant="subheading">9:16 (IGTV)</Typography>
            </MenuItem>
          </Select>
        </Typography>

        <br />
        <br />

        <ThumbnailList
          getMedia={media => media}
          items={this.state.media}
          renderCaption={this.renderMediaCaption}
          isSortable={false}
          displayAspectRatio={displayAspectRatio}
        />
        <Button
          className={classes.outerButton}
          onClick={this.handleUploadImage}
          variant="contained"
        >
          이미지 업로드
        </Button>
        <Button
          className={classes.outerButton}
          onClick={this.handleUploadVideo}
          variant="contained"
        >
          동영상 업로드
        </Button>
        {/*<Button
          className={classes.outerButton}
          onClick={() => this.setState({ isInstagramGetByUrlDialogOpen: true })}
          variant="contained"
        >
          인스타그램 포스트 URL에서 추출하기
        </Button>*/}
      </Paper>
    );
  }

  render() {
    const { classes, postId, shorten, users, router } = this.props;
    router.replace('/admin/stylePosts');

    const { products, newProduct,
      isInstagramPostFetching, description, link, sale } = this.state;
    const [normalProducts, recommendProducts] = partition(products, { isRecommend: false });

    const postType = (() => {
      if (this.state.promotion) {
        return "promotion"
      } else if (this.state.collectionMode) {
        return "collection"
      } else {
        return "normal"
      }
    })();

    return (
      <div className="container">
        {
          shorten && (
            <Paper className={classes.paper}>
              바로가기 링크:
              <a href={`${config.get('shorten')}${shorten.shorten}`} target="_blank" rel="noopener noreferrer">
                {`${config.get('shorten')}${shorten.shorten}`}
              </a>
            </Paper>
          )
        }
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>인플루언서</Typography>
          <Select
            className={classes.select}
            value={this.state.ownerId}
            onChange={event => this.setState({ ownerId: event.target.value })}
          >
            {users.sort((a, b) => (a.username < b.username ? -1 : 1)).map(user => (
              <MenuItem value={user._id} key={`postpage_creatorlist_menuitem_${user.username}`}>
                <Typography variant="subheading">{user.username}</Typography>
              </MenuItem>
            ))}
          </Select>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>인스타그램</Typography>
          <Button
            className={classes.outerButton}
            onClick={() => this.setState({ isInstagramSearchDialogOpen: true })}
            variant="contained"
          >
            인스타그램 포스팅 찾기
          </Button>
          <div>
            {this.state.instagramMedias.map((item, index) => {
              // const name = `instagramMedias[${index}].id`;
              // eslint-disable-next-line max-len, prefer-template
              const path = bigDecimalToBase64(item.id.split('_')[0]);
              const postURL = `https://instagram.com/p/${path}`;
              return (
                <div key={item.id}>
                  <TextField
                    /* valueLink={linkState(this, name)} */
                    className={classes.mediaId}
                    value={this.state.instagramMedias[index].id}
                  />
                  <a
                    className={classes.mediaId}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={postURL}
                  >
                    {postURL}
                  </a>
                  <IconButton
                    className={classes.deleteMediaButton}
                    onClick={() => {
                      this.setState(prevState => {
                        const instagramMedias = cloneDeep(prevState.instagramMedias);
                        instagramMedias.splice(index, 1);
                        return { instagramMedias };
                      });
                    }}
                  >
                    <Close />
                  </IconButton>
                </div>
              );
            })}
          </div>
        </Paper>
        <InstagramSearchDialog
          classes={classes}
          cols={this.state.tilesInRow}
          onCloseButtonClicked={() => this.setState({ isInstagramSearchDialogOpen: false })}
          onProfileClicked={user => this.showInstagramMediaDialog(user)}
          open={this.state.isInstagramSearchDialogOpen}
          users={users.filter(user => user.instagram).sort(user => (user.username === 'heartit.kr' ? -1 : 1))}
        />
        <InstagramMediaDialog
          classes={classes}
          cols={this.state.tilesInRow}
          isFetching={this.state.isInstagramMediasFetching}
          medias={this.state.instagramUserMedias}
          onCopyClicked={this.handleMediaCopyClick}
          onMediaClicked={this.addInstagramMediaId}
          onCloseButtonClicked={() => this.setState({ isInstagramMediaDialogOpen: false })}
          onScroll={this.handleInstagramMediaDialogScroll}
          open={this.state.isInstagramMediaDialogOpen}
          username={this.state.instagramUser.username}
        />
        {this.renderPostImageVideo()}
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>
            <Tooltip title="포스트에 대한 상세 설명 텍스트입니다.">
              <FiberNew color="secondary" style={{ verticalAlign: 'middle' }} />
            </Tooltip>
            &nbsp;포스트 설명
          </Typography>
          <div>
            <TextField
              className={classes.link}
              value={description}
              multiline={true}
              rows={5}
              rowsMax={10}
              placeholder="포스트 상세 설명 입력"
              onChange={event => this.handleDescriptionChange(event)}
            />
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>
            <Tooltip title="메인 이미지가 링크로 동작하는 포스트에만 필요한 항목입니다">
              <FiberNew color="secondary" style={{ verticalAlign: 'middle' }} />
            </Tooltip>
            &nbsp;포스트 링크
          </Typography>
          <div>
            <TextField className={classes.link} value={link} onChange={event => this.handleLinkChange(event)} />
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>
            <Tooltip title="포스트 링크가 있을 경우, 세일률을 설정할 수 있습니다">
              <FiberNew color="secondary" style={{ verticalAlign: 'middle' }} />
            </Tooltip>
            &nbsp;Discount Rate (10 ~ 90, 할인하지 않는 경우 빈칸)
          </Typography>
          <div>
            <TextField
              type="number"
              inputProps={{ step: 10, max: 90, min: 10 }}
              className={classes.link}
              value={sale || ''}
              onChange={event => this.handleSaleChange(event)}
              disabled={!link}
            />
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>상품</Typography>
          {
            normalProducts.length > 0 ? (
              <ItemList
                getMedia={PostPage.getMediaFromItem}
                items={products}
                renderCaption={this.renderProductCaption}
                onChange={(normals, recommends) => this.setState(
                  { products: normals.concat(recommends) }
                )
                }
                isRecommendList={false}
              />
            ) : (
                <Typography
                  variant="caption"
                  className={classes.thumbnailArea}
                  style={{ height: 100 }}
                >
                  등록된 상품이 없습니다
                </Typography>
              )
          }
          <Button
            className={classes.outerButton}
            onClick={this.handleAddProduct}
            variant="contained"
          >
            상품 추가/수정
          </Button>
          <Button
            className={classes.outerButton}
            onClick={this.handleRemoveSalePrice}
            variant="contained"
          >
            일괄 할인제거
          </Button>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>유사 상품</Typography>
          {
            recommendProducts.length > 0 ? (
              <ItemList
                getMedia={PostPage.getMediaFromItem}
                items={products}
                renderCaption={this.renderProductCaption}
                onChange={(normals, recommends) => this.setState(
                  { products: normals.concat(recommends) }
                )}
                isRecommendList
              />
            ) : (
                <Typography
                  variant="caption"
                  className={classes.thumbnailArea}
                  style={{ height: 100 }}
                >
                  등록된 유사상품이 없습니다
                </Typography>
              )
          }
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>포스트 상태</Typography>
          {this.renderFeatured()}
          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.visible}
                onChange={(event, checked) => this.setState({ visible: checked })}
              />
            )}
            label="Visible"
          />
          <br />
          <br />
          <Typography variant="subheading">포스트 종류</Typography>
          <Select
            className={classes.select}
            value={postType}
            onChange={event => {
              this.setState({
                promotion: event.target.value === "promotion",
                collectionMode: event.target.value === "collection",
              });
            }}
          >
            <MenuItem value="normal" key="postpage_posttype_normal">
              <Typography variant="subheading">일반 포스트</Typography>
            </MenuItem>
            <MenuItem value="promotion" key="postpage_posttype_promotion">
              <Typography variant="subheading">프로모션 포스트</Typography>
            </MenuItem>
            <MenuItem value="collection" key="postpage_posttype_collection">
              <Typography variant="subheading">컬렉션 포스트</Typography>
            </MenuItem>
          </Select>
        </Paper>
        <div style={{ height: 200, position: 'relative' }}>
          <Button
            variant="fab"
            onClick={this.handleSubmit}
            className={classes.createPostButton}
            color="primary"
            disabled={this.state.isUpdatingPost}
          >
            {postId === 'new' ? '업로드' : '완료'}
          </Button>
          {
            postId !== 'new' && (
              <Button
                variant="fab"
                onClick={() => {
                  if (confirm('포스트를 제거할까요?')) { // eslint-disable-line
                    this.props.adminDeletePost(this.props.post._id);
                    this.props.router.push('/admin/posts');
                  }
                }}
                color="secondary"
                className={classes.deletePostButton}
              >
                제거
              </Button>
            )
          }
        </div>
        <Dialog open={this.state.isItemDialogOpen} fullWidth maxWidth={'lg'} classes={{ paper: classes.dialogPaperProductList }}>
          <DialogTitle>
            상품 추가/수정
          </DialogTitle>
          <DialogContent>
            <ProductSelector
              searchKeyword={this.state.productSearchKeyword}
              isGodoOnly={this.state.isGodoOnly}
              checkedProducts={this.state.newCheckedProducts}
              onChangeKeyword={event => this.setState({ productSearchKeyword: event.target.value })}
              onChangeIsGodoOnly={event => this.setState({ isGodoOnly: event.target.checked })}
              onCheckProduct={product => {
                const { newCheckedProducts } = this.state;
                if (!has(newCheckedProducts, product._id)) {
                  newCheckedProducts[product._id] = product;
                } else {
                  delete newCheckedProducts[product._id];
                }
                this.setState({ newCheckedProducts });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.cancelConfirmButton}
              onClick={this.handleAddProductSubmit}
            >
              Confirm
            </Button>
            <Button
              className={classes.cancelConfirmButton}
              onClick={() => {
                this.setState({ newCheckedProducts: {}, isItemDialogOpen: false });
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          classes={{ paper: classes.dialogPaper }}
          open={this.state.isInstagramGetByUrlDialogOpen}
          maxWidth="md"
        >
          <DialogTitle>인스타그램 포스트 링크에서 이미지/동영상 추출하기</DialogTitle>
          <TextField
            className={classes.textField}
            type="text"
            label="url"
            onChange={event => this.setState({ instagramPostUrl: event.target.value })}
            value={this.state.instagramPostUrl}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                this.parseMediasFromInstagram();
              }
            }}
          />
          <Button
            variant="contained"
            onClick={this.parseMediasFromInstagram}
            disabled={!this.state.instagramPostUrl.match(/https?:\/\/.+/)}
          >
            Parse
          </Button>
          <GridList cols={this.state.tilesInRow} cellHeight={240}>
            {isInstagramPostFetching ? <Spinner /> : this.state.instagramPostMedias.map(mediaInfo => (
              <GridListTile
                key={`postpage_instagramMediaDialog_${mediaInfo.thumbnail}`}
                className={classes.gridListTile}
                onClick={() => {
                  this.setState(prevState => ({
                    isInstagramGetByUrlDialogOpen: true,
                  }), () => {
                    if (mediaInfo.type === "video") {
                      window.alert("Video has to be downloaded and uploaded manually");
                      window.open(mediaInfo.url, "_blank");
                    } else {
                      textToClipboard(mediaInfo.url);
                      this.handleUploadImageFromInstagramPost();
                    }
                  });
                }}
              >
                <img
                  src={mediaInfo.thumbnail}
                  style={{ cursor: 'pointer' }}
                  alt="Clipboard Img"
                />
              </GridListTile>
            ))}
          </GridList>
          <DialogActions>
            <Button
              className={classes.cancelConfirmButton}
              onClick={() => this.setState({ isInstagramGetByUrlDialogOpen: false })}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {<Snackbar
          autoHideDuration={2000}
          className={classes.snackbar}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
        />}
      </div>
    );
  }
}

PostPage.defaultProps = {
  post: null,
  shorten: null,
};

PostPage.propTypes = {
  auth: PropTypes.object.isRequired,
  post: PropTypes.object,
  postId: PropTypes.string.isRequired,
  router: PropTypes.object.isRequired,
  shorten: PropTypes.object,
  adminCreatePost: PropTypes.func.isRequired,
  adminDeletePost: PropTypes.func.isRequired,
  createPostShortenUrl: PropTypes.func.isRequired,
  loadCreators: PropTypes.func.isRequired,
  loadPost: PropTypes.func.isRequired,
  loadPostShortenUrl: PropTypes.func.isRequired,
  adminSearchPosts: PropTypes.func.isRequired,
  adminUpdatePost: PropTypes.func.isRequired,
};

export default compose(
  withStyles(
    styles
  ),
  connect((state, ownProps) => ({
    auth: state.auth,
    postId: ownProps.params.postId,
    post: state.entities.posts[ownProps.params.postId],
    shorten: state.entities.shortens[ownProps.params.postId],
    users: loadEntities(state, 'creators', 'users').users,
  }), adminActions),
)(PostPage);
