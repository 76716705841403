import React from 'react'
import { StyleSheet, View, Text, Image } from 'react-native';
import { browserHistory } from 'react-router';

import { Styles, Constants } from '../../../constants';
import {
  TouchableHighlightWithContainer,
 } from '../../../components-v2';

import CheckoutModal from './CheckoutModal';
import SizeGuideModal from '../../../components-v2/SizeGuideModal';

import { checkoutItems } from '../../checkout/CheckoutPage';
import { navigateToCheckoutNaverPayGateway } from '../../../containers/shop/CheckoutNaverPayGatewayPage';
import { navigateToCheckoutNaverPayWithoutAuthGateway } from '../../../containers/shop/CheckoutNaverPayWithoutAuthGatewayPage';
import { getProductSellPrice } from 'shared/util/product';


const styleHtml = {
  container: {
    height: '100vh',
    width: '100%',
    justifyContent: 'flex-start',
  },
};

const styles = StyleSheet.create({
  container: {
  },
  modalView: {
    justifyContent: 'flex-start',
    margin: 0,
  },
  resultContentContainer: {
    paddingTop: 19,
    paddingBottom: 20,
    paddingHorizontal: Constants.viewHorizontalPadding,
    backgroundColor: '#ffffff',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  resultContentTopContainer: {
    marginBottom: 20,
  },
  resultContentImage: {
    width: 64,
    height: 85,
    aspectRatio: 64/85,
    borderWidth: 0.5,
    borderColor: '#CFCFCF',
  },
  itemImage: {
    flex: 1,
    resizeMode: 'cover',
  },
  resultContentDetailContainer: {
    justifyContent: 'flex-end',
    position: 'absolute',
    start: 64 + 16,
    top: 0,
    bottom: 0,
    end: 0,
  },
  resultContentDescriptionText: {
    ...Styles.pretendard400,
    fontSize: 13,
    lineHeight: 15.6,
    position: 'absolute',
    top: 0,
    start: 0,
    end: 0,
  },
  resultContentBrandText: {
    ...Styles.pretendard600,
    fontSize: 14,
    lineHeight: 16.8,
  },
  resultContentName: {
    ...Styles.pretendard400,
    fontSize: 14,
    lineHeight: 16.8,
    height: 17,
    marginTop: 2,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  resultContentOption: {
    ...Styles.pretendard400,
    fontSize: 14,
    lineHeight: 16.8,
    height: 17,
    marginTop: 2,
  },
  resultContentButtonText: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    textAlign: 'center',
    paddingVertical: 10,
    borderWidth: 0.5,
    borderColor: '#000000',
  },
});


class CheckoutControl extends React.Component {

  state = {
    selectedItemList: [],
  };

  componentDidMount() {
    const { product } = this.props;

    if (product && product.option && product.option.length === 0) {
      const selectedItemList = [{
        count: 1,
        displayName: '',
        id: undefined,
        name: '단일 옵션',
        optionPrice: '0.00',
        optionSellFl: 'y',
        productId: product.goodsNo,
        stockCnt: product.stockCnt,
      }];
      this.setState({ selectedItemList });
    }
  }

  componentDidUpdate(prevProps) {
    const { product } = this.props;
    if (product && prevProps.product !== product
      && product.option && product.option.length === 0) {
      const selectedItemList = [{
        count: 1,
        displayName: '',
        id: undefined,
        name: '단일 옵션',
        optionPrice: '0.00',
        optionSellFl: 'y',
        productId: product.goodsNo,
        stockCnt: product.stockCnt,
      }];
      this.setState({ selectedItemList });
    }
  }

  onChangeSelectedItemList = (selectedItemList) => {
    // console.log(`onChangeSelectedItemList selectedItemList=${JSON.stringify(selectedItemList)}`);
    const newSelectedItemList = [];

    selectedItemList.forEach((item) => {
      newSelectedItemList.push({
        ...item,
        count: item.count || 1,
      })
    });

    this.setState({
      selectedItemList: newSelectedItemList,
    });
  }

  onClose = () => {
    // console.log(`onClose`);

    const { onClose } = this.props;
    this.setState({
      selectedItemList: [],
    })
    onClose && onClose();
  }

  onPressAddToCart = async (product, selectedItemList) => {
    const { auth, logEventClick, addItemToCart, addItemToCartWithoutAuth, setModalProps } = this.props;

    /*if (!auth || !auth.token) {
      alert('로그인해주시기 바랍니다.');
      setModalProps({ isVisible: false }, () => {
        browserHistory.push('/accounts/login');
      });
      return;
    }*/

    const errorMsg = '상품을 카트에 추가하는 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.';
    try {
      logEventClick({
        page_name: 'Product',
        page_key: product.goodsNo,
        click_object_name: 'cart_add',
        click_object_key: product.goodsNo
      });

      if (!auth || !auth.token) {
        const ret = await addItemToCartWithoutAuth(
          product.goodsNo,
          selectedItemList.map(item => item.count),
          selectedItemList.map(item => item.id),
          false,
        );
        if (!ret.success) {
          alert(`${errorMsg} (E1${ret.errorCode})`);
          return;
        }
      }else{
        const ret = await addItemToCart(
          product.goodsNo,
          selectedItemList.map(item => item.count),
          selectedItemList.map(item => item.id),
          false,
        );
        if (!ret.success) {
          alert(`${errorMsg} (E1${ret.errorCode})`);
          return;
        }
      }


      const productPrice = getProductSellPrice(product);
      const valueToSum = selectedItemList.reduce((sum, item) => {
        let optionPrice = Number.parseInt(item.optionPrice, 10);
        return sum + productPrice + optionPrice;
      }, 0);
      fbq('track', 'AddToCart', {
        content_type: "product",
        contents: JSON.stringify(selectedItemList.map((item) => {
          return {
            id: product.goodsNo,
            quantity: item.count
          }
        })),
        value: valueToSum,
        currency: "KRW",
      });

      this.setModalState({ mode: 'result' }, () => {
        setTimeout(() => {
          this.resetModalState(() => {
            setModalProps({ isVisible: false });
          });
        }, 2000);
      });
    } catch (error) {
      setModalProps({ isVisible: false });
      if (error.errorCode) {
        alert(`${errorMsg} (E2${error.errorCode})`);
      } else {
        alert(errorMsg);
      }
    }
  };

  onPressNaverPay = (product, selectedItemList) => {
    const { auth, addItemToCart, setModalProps } = this.props;
    if ((!auth || !auth.token) && selectedItemList.length > 1) {
      const msg = '한 번에 하나의 옵션만 네이버페이 결제가 가능합니다.';
      alert(msg);
      return;
    }

    setModalProps({ isVisible: false }, async () => {
      const errorMsg = '네이버페이 주문을 생성하는 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.';
      try {
        const newWindow = window.open('/shop/checkoutNaverPay/', '_blank');
        if (auth && auth.token) {
          const ret = await addItemToCart(
            product.goodsNo,
            selectedItemList.map(item => item.count),
            selectedItemList.map(item => item.id),
            true
          );
          if (!ret.success) {
            alert(`${errorMsg} (E1${ret.errorCode})`);
            return;
          }
          const itemIndexList = ret.data.snoArray;
          navigateToCheckoutNaverPayGateway({
            orderItems: itemIndexList,
            holdPage: true,
            newWindow
          });
        } else {
          navigateToCheckoutNaverPayWithoutAuthGateway({
            productId: product.goodsNo,
            itemCount: selectedItemList[0].count,
            selectedOptionId: selectedItemList[0].id ? selectedItemList[0].id : '',
            holdPage: true,
            newWindow,
          });
        }
      } catch (error) {
        console.error(error);
        if (error.errorCode) {
          alert(`${errorMsg} (E2${error.errorCode})`);
        } else {
          alert(errorMsg);
        }
      }
    });
  };

  onPressNaverWishlist = (product, selectedItemList) => {
    const { auth, setModalProps } = this.props;

    setModalProps({ isVisible: false }, async () => {
      try {
        const html = `
  <form method='post' action='http://heartitr9527.godomall.com/goods/naver_pay_wish.php' target='heartitNaverZzim'>
    <input type="hidden" name="wishGoodsNo" value="${product.goodsNo}">
    <input type="hidden" name="popupMode" value="y">
  </form>
`;
        const naverZzimWindow = window.open("", "heartitNaverZzim", "width=400, height=400, resizable=yes, toolbar=no, menubar=no");
        $(html).appendTo('body').submit().remove();
      } catch (error) {
        console.error(error);
        if (error.errorCode) {
          alert(`${errorMsg} (E2${error.errorCode})`);
        } else {
          alert(errorMsg);
        }
      }
    });
  };

  onPressCheckout = async (product, selectedItemList) => {
    const { auth, addItemToCart, setModalProps } = this.props;
    if (!auth || !auth.token) {
      alert('로그인해주시기 바랍니다.');
      setModalProps({ isVisible: false }, () => {
        browserHistory.push('/accounts/login');
      });
      return;
    }

    const errorMsg = '오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.';
    try {
      const ret = await addItemToCart(
        product.goodsNo,
        selectedItemList.map(item => item.count),
        selectedItemList.map(item => item.id),
        true
      );
      if (!ret.success) {
        alert(`${errorMsg} (E1${ret.errorCode})`);
        return;
      }
      const itemIndexList = ret.data.snoArray;
      setModalProps({ isVisible: false }, () => {
        // console.log(`CheckoutControl itemIndexList=${JSON.stringify(itemIndexList)}`);
        checkoutItems(itemIndexList, auth);
      });

    } catch (error) {
      console.error(error);

      if (error.errorCode) {
        alert(`${errorMsg} (E2${error.errorCode})`);
      } else {
        alert(errorMsg);
      }
    }
  };

  getModalState() {
    const {
      modalProps,
    } = this.props;

    const {
      checkoutControlState = {
        mode: 'checkout',
      },
    } = modalProps();

    // console.log(`filterMainModalState=${JSON.stringify(filterMainModalState)}`);

    return checkoutControlState;
  }

  setModalState(newState, callback) {
    // console.log(`setModalState newState=${JSON.stringify(newState)}`);
    const checkoutControlState = this.getModalState();

    const {
      setModalProps,
    } = this.props;

    setModalProps({
      checkoutControlState: {
        ...checkoutControlState,
        ...newState,
      },
    }, () => {
      callback && callback();
      const { updateToggle } = this.state;
      this.setState({ updateToggle: !updateToggle });
    });
  }

  resetModalState(callback) {
    const {
      setModalProps,
    } = this.props;

    setModalProps({
      checkoutControlState: undefined,
    }, () => {
      callback && callback();
      const { updateToggle } = this.state;
      this.setState({ updateToggle: !updateToggle });
    });
  }

  renderResultContentImage() {
    const {
      product: {
        media,
        imagesDetail,
      }
    } = this.props;

    let imageElement = null;
    // console.log(`product=${JSON.stringify(this.props.product)}`);
    // console.log(`media=${media}`);

    if (media !== undefined) {
      imageElement = (
        <CloudinaryImage
          options={{
            crop: 'limit',
            height: 300,
            width: 300,
          }}
          publicId={media.publicId}
          style={styles.itemImage}
        />
      );
    }

    if (imagesDetail) {
      const goodsImageSrc = imagesDetail[0];
      // console.log(`goodsImageSrc=${goodsImageSrc}`);
      imageElement = (
        <Image
          style={styles.itemImage}
          source={{ uri: goodsImageSrc }}
        />
      );
    }

    return (
      <View style={styles.resultContentImage}>
        {imageElement}
      </View>
    );
  }

  renderResultContent() {
    const {
      product,
      onPressGoToShoppingBack: onPressGoToShoppingBackParent,
      setModalProps,
    } = this.props;

    const {
      selectedItemList
    } = this.state;

    // console.log(`renderResultContent product=${product}`);

    if (!product) {
      return <View />;
    }

    const {
      brandNm: brand,
      title: name,
    } = product;
    // console.log(`renderResultContent selectedItemList=${JSON.stringify(selectedItemList)}`);

    const option = !selectedItemList ? '' : selectedItemList.map(
      (item) => `${item.name} ${item.count}개`).join(', ');

    const onPressGoToShoppingBack = () => {
      this.resetModalState(() => {
        setModalProps({ isVisible: false });
      });
      onPressGoToShoppingBackParent();
    };

    const onClick = () => {
      this.resetModalState(() => {
        setModalProps({ isVisible: false });
      });
    };

    return (
      <div style={styleHtml.container} onClick={onClick}>
        <div onClick={e => e.stopPropagation()}>
          <View style={styles.resultContentContainer}>
            <View style={styles.resultContentTopContainer}>
              {this.renderResultContentImage()}
              <View style={styles.resultContentDetailContainer} >
                <Text style={styles.resultContentDescriptionText}>쇼핑백에 상품이 추가되었습니다.</Text>
                <Text style={styles.resultContentBrandText}>{brand}</Text>
                <Text style={styles.resultContentName}>{name}</Text>
                <Text style={styles.resultContentOption}>{option}</Text>
              </View>
            </View>
            <TouchableHighlightWithContainer
              onPress={onPressGoToShoppingBack}
            >
              <Text style={styles.resultContentButtonText}>쇼핑백 바로가기</Text>
            </TouchableHighlightWithContainer>
          </View>
        </div>
      </div>
    );
  }

  renderCheckoutModal() {
    const {
      router,
      parent,
      product,
      onPressCheckout,
      onPressNaverPay,
      onPressNaverWishlist,
      setModalProps,
      modalProps,
      loadSoldoutFollowingProduct,
      addSoldoutFollowingProduct,
      deleteSoldoutFollowingProduct,
      logEventClick,
      auth,
    } = this.props;

    const {
      selectedItemList
    } = this.state;

    const onClose = () => {
      setModalProps({ isVisible: false });
    };

    const onPressSizeGuide = () => {
      this.setModalState({ mode: 'sizeGuide' });
    };

    const { soldoutAlarmData } = this.getModalState();

    return (
      <CheckoutModal
        router={router}
        // isVisible={isVisible}
        product={product}
        onClose={onClose}
        onPressSizeGuide={onPressSizeGuide}
        onPressAddToCart={this.onPressAddToCart.bind(this)}
        onPressCheckout={this.onPressCheckout}
        onPressNaverPay={this.onPressNaverPay}
        onPressNaverWishlist={this.onPressNaverWishlist}
        selectedItemList={selectedItemList}
        onChangeSelectedItemList={this.onChangeSelectedItemList}
        soldoutAlarmData={soldoutAlarmData}
        modalProps={modalProps}
        setModalProps={setModalProps}
        onAddSoldoutFollowingProduct={(productId, body) => {
          logEventClick({
            page_name: 'Product',
            page_key: productId,
            click_object_name: 'restock_alarm_on',
            click_object_key: productId,
          });
          addSoldoutFollowingProduct(productId, body)
            .then(() => {
              loadSoldoutFollowingProduct(productId)
                .then((result) => {
                  this.setModalState({ soldoutAlarmData: result.data });
                  parent.setState({ soldoutAlarmData: result.data });
                });
              });
        }}
        onDeleteSoldoutFollowingProduct={(productId, optionKey) => {
          logEventClick({
            page_name: 'Product',
            page_key: productId,
            click_object_name: 'restock_alarm_off',
            click_object_key: productId,
          });
          deleteSoldoutFollowingProduct(productId, optionKey)
            .then(() => {
              loadSoldoutFollowingProduct(productId)
                .then((result) => {
                  this.setModalState({ soldoutAlarmData: result.data });
                  parent.setState({ soldoutAlarmData: result.data });
                });
              });
        }}
        auth={auth}
        contentHeightOffset={0}
        />
    );
  }

  renderSizeGuide() {

    const { subType, setModalProps } = this.props;

    const onClose = () => {
      setModalProps({ isVisible: false });
    };

    const onPressBack = () => {
      this.setModalState({ mode: 'checkout'})
    };

    return (
      <SizeGuideModal
        onClose={onClose}
        onPressBack={onPressBack}
        targetSubType={subType}
        />
    );
  }

  render() {
    const {
      mode
    } = this.getModalState();

    if (mode === 'checkout') {
      return this.renderCheckoutModal();
    }

    if (mode === 'sizeGuide') {
      return this.renderSizeGuide();
    }

    if (mode === 'result') {
      return this.renderResultContent();
    }

    return null;
  }
}


export function showCheckout(parent) {
  const {
    router,
    modalProps,
    setModalProps,
    product,
    addItemToCart,
    addItemToCartWithoutAuth,
    loadSoldoutFollowingProduct,
    addSoldoutFollowingProduct,
    deleteSoldoutFollowingProduct,
    logEventClick,
    auth,
  } = parent.props;
  const {
    soldoutAlarmData
  } = parent.state;

  const children = (
    <CheckoutControl
      router={router}
      modalProps={modalProps}
      setModalProps={setModalProps}
      parent={parent}
      product={product}
      auth={auth}
      soldoutAlarmData={soldoutAlarmData}
      addItemToCart={addItemToCart}
      addItemToCartWithoutAuth={addItemToCartWithoutAuth}
      onPressGoToShoppingBack={() => router.push('/shop/cart')}
      loadSoldoutFollowingProduct={loadSoldoutFollowingProduct}
      addSoldoutFollowingProduct={addSoldoutFollowingProduct}
      deleteSoldoutFollowingProduct={deleteSoldoutFollowingProduct}
      logEventClick={logEventClick}
      />
  );

  setModalProps({
    isVisible: true,
    children: children,
    checkoutControlState: {
      mode: 'checkout',
      soldoutAlarmData,
    },
  });
}
