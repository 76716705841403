import React from 'react';
import {
    StyleSheet, View, Text
} from 'react-native';
import { Colors, Styles } from '../../../constants';


const styles = StyleSheet.create({
  header: {
    ...Styles.pretendard600,
  },
  content: {
    ...Styles.pretendard400,
    fontSize: 12,
    lineHeight: 14.4,
    color: '#1F51FF',
  },
});

class TipBox extends React.Component {
  render() {
    const { value } = this.props;

    return (
      <Text style={styles.content}>
        <Text style={styles.header}>Tip&nbsp;</Text>
        {value}
      </Text> 
    )
  }
}

export default TipBox;
