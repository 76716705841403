/* eslint-disable max-len */
import React from 'react';
import { View, Image, StyleSheet, TouchableOpacity } from 'react-native';
import { generateNormalizer } from 'shared';
import CloudinaryImage from './CloudinaryImage';
import CloudinaryVideo from './CloudinaryVideo';

const normalize = sale => generateNormalizer(0, 100)(sale);

export default class PostThumbnail extends React.PureComponent {
  renderMedia = () => {
    const { post } = this.props;
    const { media: { 0: media }, sale } = post;

    return (
      <View
        style={styles.containerStyle}
        ref={elem => {
          if (elem) elem.style.setProperty('padding-top', '100%', 'important');
        }}
      >
        {media.resourceType === 'video'
          ? (
            <CloudinaryVideo
              publicId={media.publicId}
              options={{
                crop: 'limit',
                format: 'jpg',
                height: 640,
                width: 640,
                start_offset: 0,
              }}
              style={stylesHtml.gridMediaVideo}
              thumbnailOnly={true}
            />

          ) : (
            <CloudinaryImage
              publicId={media.publicId}
              style={stylesHtml.gridMediaImage}
              options={{
                crop: 'lfill',
                aspect_ratio: '1',
                height: 640,
                width: 640,
              }}
            />
          )}
        {sale && (
          <Image
            source={{ uri: `https://s3.ap-northeast-2.amazonaws.com/heartit-static/icons/post/sale/${normalize(sale)}.png` }}
            style={styles.saleIcon}
          />
        )}
      </View>
    );
  }

  render() {
    const { onPress, post } = this.props;

    if (onPress) {
      return (
        <TouchableOpacity
          onPress={() => onPress && onPress(post._id)} // eslint-disable-line no-underscore-dangle
        >
          {this.renderMedia()}
        </TouchableOpacity>
      );
    } else {
      return (
        <View>
          {this.renderMedia()}
        </View>
      );
    }
  }
}

const styles = StyleSheet.create({
  containerStyle: {
    position: 'relative',
    height: 0,
    paddingTop: '100%',
  },
  saleIcon: {
    aspectRatio: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '33%',
    resizeMode: 'contain',
  },
});

const stylesHtml = {
  gridMediaVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  gridMediaImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
};
