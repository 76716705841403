import React from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheet, View, Text, TextInput, TouchableHighlight,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';
import { isMobilePhone, isNumeric } from 'validator';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';

import CheckComplete from '../icons/CheckComplete';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';


const VERIFICATION_TIME_LIMIT = 3 * 60; // 3 minutes

export function isPhoneNumber(val) {
  return isMobilePhone(val, 'ko-KR') && isNumeric(val, { no_symbols: true });
}


const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'column',
  },
  inputButtonContainer: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  inputText: {
    width: '100%',
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    color: 'black',
    paddingTop: 17,
    paddingLeft: 16,
    paddingBottom: 16,
    height: 52,
  },
  checkIcon: {
    position: 'absolute',
    right: 14,
    top: 14,
    bottom: 14,
    width: 24,
    height: 24,
  },
  errorInputBorder: {
    borderWidth: 1,
    borderColor: 'rgb(255, 62, 62)',
  },
  inputContainer: {
    flex: 1,
    marginRight: 10,
    backgroundColor: Colors.semiGray,
    height: 52,
  },
  button: {
    paddingLeft: 19,
    paddingRight: 19,
    backgroundColor: Colors.semiGray,
    borderWidth: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    ...Styles.appleSDGothicNeoBold,
    color: 'black',
    fontSize: 14,
  },
  buttonTextDisabled: {
    opacity: 0.45,
  },
  remainTimeContainer: {
    marginBottom: 10,
  },
  remainTimeText: {
    fontSize: 13,
    color: 'rgb(255,62,62)',
    ...Styles.appleSDGothicNeoRegular,
  },
});


class PhoneVerificationForm extends React.Component {
  constructor(props) {
    super(props);

    const { defaultPhoneNumber = '', defaultVerified = false } = props;
    this.state = {
      phone: defaultPhoneNumber,
      phoneVerified: defaultVerified,
      verificationFailed: false,
      verificationCode: '',
      verificationOngoing: false,
      // verificationTimeout: false,
      timer: null,
      timestamp: VERIFICATION_TIME_LIMIT,
      phoneError: false,
      verificationCodeError: false,
    };
  }

  verificationCodeRef = null;

  reset = (option = {}, callback = () => {}) => {
    const { timer } = this.state;
    if (timer) {
      clearInterval(timer);
    }

    this.setState({
      phoneVerified: false,
      verificationFailed: false,
      verificationCode: '',
      verificationOngoing: false,
      // verificationTimeout: false,
      timer: null,
      timestamp: VERIFICATION_TIME_LIMIT,
      ...option,
    }, callback);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      onChangePhoneNumber: onChangePhoneNumberCallback,
      verificationChanged: verificationChangedCallback,
      ignoreOldUser,
    } = this.props;
    const {
      phone,
      phoneVerified,
    } = this.state;

    if (phone !== prevState.phone && onChangePhoneNumberCallback) {
      onChangePhoneNumberCallback(phone);
    }
    if (phoneVerified !== prevState.phoneVerified && verificationChangedCallback) {
      verificationChangedCallback(phoneVerified);
    }
    if (prevProps.ignoreOldUser !== ignoreOldUser && ignoreOldUser) {
      this.onPressVerifyPhoneNumber();
    }
  }

  alertNotPhoneNumber = () => {
    const { showAlert } = this.props;
    if (showAlert) {
      showAlert('휴대폰 번호 형식을 확인하세요.');
    }
  }

  alertVerificationFailed = () => {
    // requestPhoneNumberVerification의 오류 메시지를 덮어써서 비활성화 처리 함
    // const { showAlert } = this.props;
    // if (showAlert) {
    //   showAlert('휴대폰 번호 인증에 실패했습니다.');
    // }
  }

  onChangePhoneNumber = phone => {
    this.reset({ phone });
  }

  requestSendVerificationCode = async () => {
    const {
      showAlert,
      showIgnoreOldUserAlert,
      ignoreOldUser,
      requestTelVerificationCode,
      requestTelVerificationCodeCallback,
    } = this.props;
    const { phone } = this.state;

    const handler = requestTelVerificationCodeCallback || requestTelVerificationCode;

    try {
      const resp = await handler(phone, ignoreOldUser);
      if (resp.code) {
        showAlert(`테스트용 인증코드 임시표시: ${resp.code}`);
      } else {
        showAlert('');
      }
      return true;
    } catch (e) {
      const { showAlert: showAlertFlag = true } = e;
      if (!showAlertFlag) {
        return false;
      }

      let { errorMessage = '' } = e;
      if (!errorMessage || errorMessage === '') {
        switch (e.status) {
        case 409:
          if (showIgnoreOldUserAlert) {
            showIgnoreOldUserAlert(e.email);
          } else {
            errorMessage = '이미 등록된 휴대폰번호입니다.';
            showAlert(errorMessage);
          }
          break;

        default:
          errorMessage = '인증코드 발급에 실패했습니다. 잠시 후 다시 시도하세요.';
          showAlert(errorMessage);
          break;
        }
      }
    }
    return false;
  }

  requestPhoneNumberVerification = async () => {
    const {
      showAlert,
      verifyTelVerificationCode,
      verifyTelVerificationCodeCallback,
    } = this.props;
    const { phone, verificationCode } = this.state;

    const handler = verifyTelVerificationCodeCallback || verifyTelVerificationCode;

    try {
      // eslint-disable-next-line no-unused-vars
      const resp = await handler(phone, verificationCode);
      this.setState({ phoneError: false, verificationCodeError: false });
      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      let errorMessage = '휴대폰번호 인증에 실패했습니다. 잠시 후 다시 시도하세요.';
      if (e.status === 408) {
        errorMessage = '너무 오래된 인증코드입니다. 인증코드를 다시 발급받으세요.';
      } else if (e.status === 429) {
        errorMessage = '인증에 너무 여러번 실패했습니다. 인증코드를 다시 발급받으세요.';
      } else if (e.status === 401) {
        errorMessage = '인증코드가 잘못되었습니다. 다시 확인 후 입력하세요.';
      }
      this.setState({ phoneError: false, verificationCodeError: true });
      if (showAlert) {
        showAlert(errorMessage);
      }
    }
    return false;
  }

  getVerificationTimer = () => setInterval(() => {
    this.setState(prevState => {
      if (prevState.timestamp <= 1) {
        this.reset({
          // verificationTimeout: true,
          verificationFailed: true,
        });
        return {};
      }

      return { timestamp: prevState.timestamp - 1 };
    });
  }, 1000)

  onPressVerifyPhoneNumber = async () => {
    if (!this.validateInput()) {
      return;
    }

    const success = await this.requestSendVerificationCode();
    if (success) {
      this.reset({
        verificationOngoing: true,
        timer: this.getVerificationTimer(),
      }, () => {
        if (this.verificationCodeRef) {
          this.verificationCodeRef.focus();
        }
      });
    }
  }

  onPressCheckVerificationNumber = async () => {
    const { showAlert } = this.props;
    const verified = await this.requestPhoneNumberVerification();

    if (verified) {
      showAlert('');
      this.reset({
        phoneVerified: true,
      });
    } else {
      this.alertVerificationFailed();
      this.setState({
        phoneVerified: false,
        verificationCode: '',
        verificationFailed: true,
      });
    }
  }

  getTimestampStr = () => {
    const { timestamp } = this.state;

    const minutes = Math.floor(timestamp / 60);
    const seconds = Math.ceil(timestamp - minutes * 60);

    let minutesString = minutes.toString();
    if (minutesString.length === 1) {
      minutesString = '0' + minutesString;
    }
    let secondsString = seconds.toString();
    if (secondsString.length === 1) {
      secondsString = '0' + secondsString;
    }

    return `${minutesString}:${secondsString}`;
  }

  validateInput() {
    const { phone, phoneVerified } = this.state;
    const { showAlert, validateInputCallback } = this.props;

    const inputData = {
      phone,
      phoneVerified,
    };

    if (!validateInputCallback(inputData)) {
      this.setState({ phoneError: true, verificationCodeError: false });
      return false;
    }

    if (!isPhoneNumber(phone)) {
      this.alertNotPhoneNumber();
      this.setState({ phoneError: true, verificationCodeError: false });
      return false;
    }

    if (showAlert) {
      showAlert('');
    }
    this.setState({ phoneError: false, verificationCodeError: false });
    return true;
  }

  render() {
    const {
      phone, phoneVerified, verificationCode,
      verificationFailed, verificationOngoing,
      phoneError, verificationCodeError,
    } = this.state;
    const {
      showErrorBorder,
      onPhoneNumberInputRef,
    } = this.props;

    let verificationButtonText = '인증번호';
    if (verificationFailed || verificationOngoing) {
      verificationButtonText = '다시받기';
    }
    if (phoneVerified) {
      verificationButtonText = '인증번호';
    }

    return (
      <View style={styles.container}>
        <View
          style={styles.inputButtonContainer}
        >
          <View style={styles.inputContainer}>
            <TextInput
              ref={onPhoneNumberInputRef}
              autoCapitalize="none"
              keyboardType="numeric"
              onChangeText={this.onChangePhoneNumber}
              placeholder="휴대폰 번호 (숫자만 입력)"
              placeholderTextColor={Colors.placeholderColor}
              style={[
                styles.inputText,
                (showErrorBorder || phoneError) && styles.errorInputBorder,
                { outline: 'none' },
              ]}
              value={phone}
              onSubmitEditing={() => this.onPressVerifyPhoneNumber()}
            />
            {phoneVerified && (
              <View style={styles.checkIcon}>
                <CheckComplete />
              </View>
            )}
          </View>
          <TouchableHighlight
            style={styles.button}
            underlayColor={Colors.touchEffectColorWhite}
            onPress={this.onPressVerifyPhoneNumber}
            disabled={phoneVerified || phone.length <= 0}
          >
            <Text style={[
              styles.buttonText,
              phone.length <= 0 ? styles.buttonTextDisabled : {},
            ]}
            >
              { verificationButtonText }
            </Text>
          </TouchableHighlight>
        </View>
        <View
          style={[
            styles.inputButtonContainer,
            (!verificationOngoing && !verificationFailed) && { display: 'none' },
          ]}
        >
          <View
            style={styles.inputContainer}
          >
            <TextInput
              ref={ref => { this.verificationCodeRef = ref; }}
              autoCapitalize="none"
              onChangeText={val => this.setState({ verificationCode: val })}
              placeholder="인증번호"
              placeholderTextColor={Colors.placeholderColor}
              style={[
                styles.inputText,
                verificationCodeError && styles.errorInputBorder,
                { outline: 'none' },
              ]}
              value={verificationCode}
              keyboardType="number-pad"
              onSubmitEditing={this.onPressCheckVerificationNumber}
            />
          </View>
          <TouchableHighlight
            style={styles.button}
            underlayColor={Colors.touchEffectColorWhite}
            disabled={!verificationOngoing}
            onPress={this.onPressCheckVerificationNumber}
          >
            <Text style={styles.buttonText}>인증확인</Text>
          </TouchableHighlight>
        </View>
        {(verificationOngoing) && (
          <View style={styles.remainTimeContainer}>
            <Text style={styles.remainTimeText}>
              {`남은 시간 ${this.getTimestampStr()}`}
            </Text>
          </View>
        )}
      </View>
    );
  }
}

PhoneVerificationForm.propTypes = {
  onChangePhoneNumber: PropTypes.func.isRequired,
  verificationChanged: PropTypes.func.isRequired,
  defaultPhoneNumber: PropTypes.string,
  defaultVerified: PropTypes.bool,
  showAlert: PropTypes.func,
  showIgnoreOldUserAlert: PropTypes.func,
  ignoreOldUser: PropTypes.bool,
  validateInputCallback: PropTypes.func,
  requestTelVerificationCodeCallback: PropTypes.func,
  verifyTelVerificationCodeCallback: PropTypes.func,
};

PhoneVerificationForm.defaultProps = {
  defaultPhoneNumber: '',
  defaultVerified: false,
  showAlert: () => null,
  showIgnoreOldUserAlert: null,
  ignoreOldUser: false,
  validateInputCallback: () => true,
  requestTelVerificationCodeCallback: null,
  verifyTelVerificationCodeCallback: null,
};

export default connect(
  null,
  actions
)(PhoneVerificationForm);
