import React from 'react';
import { isSafari, isMobileSafari } from 'react-device-detect';

import {
  Dimensions, StyleSheet, View, Text, FlatList, Alert,
} from 'react-native';
import NumberFormat from 'react-number-format';

import { Constants, Styles } from '../../../constants';
import { ChevronRightIcon } from '../../../icons-v2';
import { TouchableHighlightWithContainer as TouchableHighlight } from '../../../components-v2';

const { width } = Dimensions.get('window');


const styles = StyleSheet.create({
  container: {
    marginTop: 40,
    marginHorizontal: Constants.viewHorizontalPadding,
  },
  title: {
    fontSize: 18,
    lineHeight: 21.6,
    ...Styles.pretendard700,
  },
  listContainer: {
    marginTop: 10,
    paddingVertical: ((isSafari || isMobileSafari) ? 0.5: Math.ceil(0.5)),
  },
  columnWrapperStyle: {
    marginHorizontal: ((isSafari || isMobileSafari) ? 0.5: Math.ceil(0.5) / 2),
  },
  itemContainer: {
    flex: 1,
    borderWidth: ((isSafari || isMobileSafari) ? 0.5: Math.ceil(0.5)),
    margin: -1 * ((isSafari || isMobileSafari) ? 0.5: Math.ceil(0.5) / 2),
    borderColor: '#000',
  },
  itemButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 11 - 0.5 / 2,
    paddingLeft: 14 - 0.5 / 2,
    paddingRight: 32 - 20 - 0.5 / 2,
    backgroundColor: 'transparent',
  },
  leftItemContainer: {
  },
  itemName: {
    ...Styles.pretendard400,
    fontSize: 13,
    lineHeight: 15.6,
    color: 'black',
    opacity: 0.5,
  },
  itemPrice: {
    ...Styles.pretendard400,
    fontSize: 16,
    lineHeight: 19.2,
    marginTop: 3,
  },
  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 26 - 4,
    paddingBottom: 4,
  },
});


class PriceComparison extends React.PureComponent {
  renderItem({ name, price, onPress }, index) {
    if (index > 5) {
      return null;
    }
    if (index > 4) {
      return (
        <View style={styles.itemContainer}>
          <TouchableHighlight
            style={[Styles.touchEffect]}
            onPress={() => {
              alert('검색 결과 모두 보기');
            }}
            underlayColor={Constants.touchEffectColorWhite}
          >
            <View style={styles.itemButtonContainer}>
              <View style={styles.leftItemContainer}>
                <Text style={styles.itemName}>검색 결과 모두 보기</Text>
                <Text style={styles.itemPrice}>6+</Text>
              </View>
            </View>
          </TouchableHighlight>
        </View>
      );
    } else {
      return (
        <View style={styles.itemContainer}>
          <TouchableHighlight
            style={[Styles.touchEffect]}
            onPress={onPress}
            >
            <View style={styles.itemButtonContainer}>
              <View style={styles.leftItemContainer}>
                <Text style={styles.itemName}>{name}</Text>
                <NumberFormat
                  value={price}
                  displayType="text"
                  thousandSeparator
                  renderText={value => (
                    <Text style={styles.itemPrice}>
                      {value}원
                    </Text>
                  )}
                  />
              </View>
              <ChevronRightIcon />
            </View>
          </TouchableHighlight>
        </View>
      );
    }
  }

  render() {
    const {
      containerStyle,
    } = this.props;

    // FIXME
    const items = [
    ];

    return (
      <View style={[styles.container, containerStyle]}>
        <Text style={styles.title}>네이버 쇼핑 가격 비교</Text>
        <FlatList
          data={items}
          renderItem={({ item, index }) => this.renderItem(item, index)}
          numColumns={2}
          style={styles.listContainer}
          columnWrapperStyle={styles.columnWrapperStyle}
          />
      </View>
    );
  }
}

export default PriceComparison;
