import * as React from 'react';

function SvgIcDropdown(props) {
  const { color = '#000' } = props;

  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M16 10l-4 4-4-4"
        stroke={color}
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIcDropdown;
