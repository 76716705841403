import React from 'react'

import FilterSizeStandardModal from "./FilterSizeStandardModal";

function showFilterSizeStandardModal(parent, sizeStandardList, selectedSizeStandard, onChange) {
    const {
      modalProps,
      setModalProps,
      isInModal,
    } = parent.props;
  
    const {
      children,
      historyStack=[],
    } = modalProps();
  
    const newChildren = (
      <FilterSizeStandardModal
        sizeStandardList={sizeStandardList}
        selectedSizeStandard={selectedSizeStandard}
        onChange={onChange}
        setModalProps={setModalProps}
        modalProps={modalProps}
        enableBackButton={isInModal}
        />
    );
  
    historyStack.push(children);
  
    setModalProps({
      isVisible: true,
      children: newChildren,
      historyStack,
    });
  }
  
  
  export default showFilterSizeStandardModal;
  