import * as React from "react"
import Svg, { Rect } from "react-native-svg"

function RadioButtonIcon(props) {
  const {
    checked=false,
  } = props;

  if (checked) {
    return (
      <Svg
        width={14}
        height={15}
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <Rect x={0.5} y={1} width={13} height={13} rx={6.5} stroke="#000" />
        <Rect x={3} y={3.5} width={8} height={8} rx={4} fill="#000" />
      </Svg>
    );
  } else {
    return (
      <Svg
        width={14}
        height={15}
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <Rect x={0.5} y={1} width={13} height={13} rx={6.5} stroke="#000" />
      </Svg>
    );
  }
}

export default RadioButtonIcon

