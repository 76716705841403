import React from 'react';

import {
  StyleSheet, View, Text, Alert,
} from 'react-native';
import { TouchableHighlightWithContainer } from '../../../components-v2';
import { Styles } from '../../../constants';
import {
  ProductInquiryKakaoIcon
 } from '../../../icons-v2';
import config from '../../../config';

const styleHtml = {
  inqueryIconContainer: {
    position: 'absolute',
    left: 9.78,
    top: 9,
  },
};


const styles = StyleSheet.create({
  container: {
    marginTop: 60,
  },
  title: {
    ...Styles.pretendard700,
    fontSize: 18,
    lineHeight: 21.6,
  },
  note: {
    marginTop: 10,
    ...Styles.pretendard400,
    fontSize: 15,
    lineHeight: 22,
  },
  inquiryContainer: {
    marginTop: 20,
    borderColor: '#000',
    borderWidth: 0.5,
    paddingTop: 11,
    paddingBottom: 10,
  },
  inquiryText: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    textAlign: 'center',
  },
  inqueryIcon: {
    position: 'absolute',
    start: 9.78,
    top: 9,
  }
});


class EditorNote extends React.PureComponent {

  render() {
    const { product, containerStyle } = this.props;
    const onPress = () => {
      const chatUrl = config.get('chatInquiryUrl');
      window.location.href = chatUrl;
    }

    return (
      <View style={[styles.container, containerStyle]}>
        <Text style={styles.title}>에디터 노트</Text>
        <Text style={styles.note}>{product.editorsNote}</Text>
        <TouchableHighlightWithContainer
          onPress={onPress}
          >
            <View style={styles.inquiryContainer}>
              <div style={styleHtml.inqueryIconContainer}>
                <ProductInquiryKakaoIcon style={styles.inqueryIcon} />
              </div>
              <Text style={styles.inquiryText}>카카오톡으로 상품 문의하기</Text>
            </View>
        </TouchableHighlightWithContainer>
      </View>
    );
  }
}

export default EditorNote;
