import * as React from 'react';

function SvgIcEmptyBag(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100" height="101" viewBox="0 0 100 101">
        <defs>
            <path id="pm9rjxb3ga" d="M0 0H47V42H0z"/>
        </defs>
        <g fill="none" fillRule="evenodd" opacity=".197">
            <g>
                <g>
                    <g>
                        <g transform="translate(-138 -295) translate(138 295) translate(9 3.611) translate(17)">
                            <mask id="b9m9mwcopb" fill="#fff">
                                <use xlinkHref="#pm9rjxb3ga"/>
                            </mask>
                            <path stroke="#000" strokeWidth="3.5" d="M23.5 5.476c9.941 0 18 8.06 18 18v27c0 2.762-2.239 5-5 5h-26c-2.761 0-5-2.238-5-5v-27c0-9.94 8.059-18 18-18z" mask="url(#b9m9mwcopb)"/>
                        </g>
                        <path stroke="#000" strokeLinejoin="round" strokeWidth="3.5" d="M0 34.389L82 34.389 76.271 86.389 5.729 86.389z" transform="translate(-138 -295) translate(138 295) translate(9 3.611)"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  );
}

export default SvgIcEmptyBag;

