import React from 'react';
import {
  Dimensions, StyleSheet, View, Text,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';

import { Styles, Constants } from '../../../constants';
import ShippingAddressEditForm from '../../../components-v2/ShippingAddressEditForm';
import TouchableHighlightWithContainer from '../../../components-v2/TouchableHighlightWithContainer';
import OverlayPage from '../../../components-v2/OverlayPage';


const {
  width: windowWidth,
} = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    paddingTop: 20,
    paddingStart: 20,
    paddingEnd: 20,
  },
  spinnerTextStyle: {
    color: '#FFF',
  },
  arrowButtonContainer: {
    flex: 1,
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    marginStart: 16,
  },
  title: {
  },
  footer: {
    flexDirection: 'row',
    backgroundColor: '#000000',
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  footerButton: {
    flex: 1,
    alignItems: 'center',
    height: 60,
    justifyContent: 'center',
  },
  footerButtonText: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    textAlign: 'center',
    color: '#fff',
    marginTop: 20,
    marginBottom: 21,
    height: 19,
    width: (windowWidth - Constants.viewHorizontalPadding * 2) / 2,
  },
});

const webStyles = {
  footer: {
    height: 20 + 19 + 21
    ,
    position: 'absolute',
    width:'100%',
    bottom: 0,
    start: 0,
    end: 0,
    backgroundColor: '#fff',
  },
};

class ShippingAddressEditPage extends OverlayPage {
  editFormRef = React.createRef();

  state = {
    changedData: {},
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {
      loadShippingAddressList,
      logEventPageview,
      shippingAddressId,
    } = this.props;

    loadShippingAddressList();

    logEventPageview({ page_name: 'ShippingAddressEdit', page_key: shippingAddressId });
  }

  componentWillUnmount() {
    const { setGlobalLayoutInfo } = this.props;
    setGlobalLayoutInfo('showProgress', false);
    this.setState = () => null;
  }

  getMergedData = (forSave = false) => {
    const {
      shippingAddress: orgShippingAddress,
      shippingAddressEmpty,
    } = this.props;
    const { changedData } = this.state;

    const mergedData = {
      ...orgShippingAddress,
      ...changedData,
      ...(shippingAddressEmpty && { defaultFl: 'y' }),
    };

    const {
      sno,
      defaultFl,
      newDefaultFl,
      shippingTitle,
      shippingName,
      shippingZonecode,
      shippingAddress,
      shippingAddressSub,
      shippingCellPhone,
    } = mergedData;

    const data = {
      sno,
      defaultFl,
      newDefaultFl,
      shippingTitle,
      shippingName,
      shippingZonecode,
      shippingAddress,
      shippingAddressSub,
      shippingCellPhone,
    };

    if (forSave) {
      data.defaultFl = (defaultFl === 'y' || newDefaultFl) ? 'y' : 'n';
      delete data.newDefaultFl;
    }

    return data;
  }

  onPressCancelButton = () => {
    this.onPressBackButton();
  }

  onPressSaveButton = async () => {
    if (!this.validateInputField()) {
      return;
    }

    const { shippingAddressId, setGlobalLayoutInfo, onShippingAddressEditDone } = this.props;

    const params = this.getMergedData(true);

    const errorMessage = '저장 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.';
    setGlobalLayoutInfo('showProgress', true);

    try {
      const { createShippingAddress, updateShippingAddress } = this.props;
      let ret;
      if (shippingAddressId) {
        ret = await updateShippingAddress(shippingAddressId, params);
      } else {
        ret = await createShippingAddress(params);
      }

      setGlobalLayoutInfo('showProgress', false);
      console.log('ShippingAddressEditPage onPressSaveButton ret=', ret);
      if (ret.success === true) {
        alert('저장되었습니다.');
        this.onPressBackButton();
        onShippingAddressEditDone && onShippingAddressEditDone();
      } else {
        alert(`${errorMessage} (E0${ret.errorCode})`);
      }
    } catch (error) {
      if (error.errorCode) {
        alert(`${errorMessage} (E1${error.errorCode})`);
      } else {
        alert(errorMessage);
      }
      setGlobalLayoutInfo('showProgress', false);
    }
  };

  onDataChanged = changedData => {
    this.setState(({ changedData: prevChangedData }) => ({
      changedData: {
        ...prevChangedData,
        ...changedData,
      },
    }));
  }

  validateInputField() {
    // console.log("ShippingAddressInfoTabNew validateInputField");

    return this.editFormRef.current && this.editFormRef.current.validateInputField();
  }

  renderContent() {
    const { isNewAddress } = this.props;
    const data = this.getMergedData();
    // console.log('ShippingAddressEditScreen data=', data);

    const saveButtonTextStyles = [
      styles.footerButtonText,
    ];

    const saveButtonText = isNewAddress ? '입력 완료' : '수정 완료';

    return (
      <View style={styles.container}>
        <View style={styles.scrollView}>
          <ShippingAddressEditForm
            ref={this.editFormRef}
            data={data}
            isNewAddress={isNewAddress}
            onDataChanged={this.onDataChanged}
            showCheckBoxSetDefault
            profileEditStyle={false}
          />
        </View>
        <View style={webStyles.footer}>
          <View style={styles.footer}>
            <TouchableHighlightWithContainer
              containerStyle={styles.footerButton}
              onPress={this.onPressCancelButton}
            >
              <Text style={styles.footerButtonText}>
                취소
              </Text>
            </TouchableHighlightWithContainer>
            <TouchableHighlightWithContainer
              containerStyle={styles.footerButton}
              onPress={this.onPressSaveButton}
            >
              <Text style={saveButtonTextStyles}>
                {saveButtonText}
              </Text>
            </TouchableHighlightWithContainer>
          </View>
        </View>
      </View>
    );
  }
}


export default connect(
  (state, props) => {
    const { shippingAddressId = null } = props;

    return {
      auth: state.auth,
      shippingAddressId,
      isNewAddress: shippingAddressId === null,
      shippingAddress: state.entities.shippingAddresses[shippingAddressId],
      shippingAddressEmpty: Object.keys(state.entities.shippingAddresses).length === 0,
    };
  },
  actions,
)(ShippingAddressEditPage);
