import React, { Component } from 'react';

export default class GreetingPage extends Component {
  // eslint-disable-next-line class-methods-use-this
  goInstagram() {
    window.open('https://www.instagram.com/heartit.kr');
  }

  goHome = () => {
    this.props.router.push('/');
  }

  render() {
    const containerStyle = { textAlign: 'center', marginBottom: '50px' };
    return (
      <div style={containerStyle}>
        <div>
          <img
            className="greeting-thankyou"
            src="https://cdn.heartit.kr/img/r2/icon/thankyou.png"
            alt=""
          />
        </div>
        <div>
          <img
            className="greeting-heartit-to-bag"
            src="https://cdn.heartit.kr/img/r2/icon/intro-heartit-to-bag.png"
            alt=""
          />
        </div>
        <div>
          <img
            className="greeting-message-ko"
            src="https://cdn.heartit.kr/img/r2/icon/message_ko.png"
            alt=""
          />
        </div>
        <div>
          <button className="intro-signup-button" type="button" onClick={this.goInstagram}>
            하트잇 인스타그램 계정 가기
          </button>
        </div>
        <div>
          <button className="greeting-gohome-btn" type="button" onClick={this.goHome}>
            하트잇 홈화면 바로 가기
          </button>
        </div>
        <div className="greeting-divider"></div>
        <div>
          <img
            className="greeting-message-en"
            src="https://cdn.heartit.kr/img/r2/icon/message_en.png"
            alt=""
          />
        </div>
        <div>
          <button className="intro-signup-button text-avenir-next" type="button" onClick={this.goInstagram}>
            Visit heart it instagram account
          </button>
        </div>
        <div>
          <button className="greeting-gohome-btn text-avenir-next" type="button" onClick={this.goHome}>
            Go to main page
          </button>
        </div>
      </div>
    );
  }
}
/*
// TODO: replace with util.loadEntities().
function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}
*/
