import React from 'react';
import {
  TouchableHighlight, StyleSheet, FlatList, View, Text, Image,
} from 'react-native';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { actions, loadEntities, dateFromNow } from 'shared';

import Colors from '../../constants/Colors';
import Styles from '../../constants/Styles';
import Constants from '../../constants/Constants';
import {
  initAlert, renderAlert,
} from '../../components/AlertDialog';

import { navigateToProductPage } from '../shop/ProductPage';
import { navigateToPreOrderDetailPage } from '../home/PreOrderDetailPage';


const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: Constants.viewHorizontalPadding,
  },
  itemContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingVertical: 16,
    justifyContent: 'space-between',
    borderBottomWidth: 0.5,
    borderBottomColor: Colors.lineGray,
  },
  textContainer: {
    flex: 1,
    marginEnd: 16,
  },
  textDate: {
    fontSize: 13,
    lineHeight: 15.6,
    opacity: 0.5,
    ...Styles.pretendard400,
  },
  textDescription: {
    fontSize: 14,
    lineHeight: 21,
    ...Styles.pretendard400,
  },
  thumbnailContainer: {
    width: 64,
    height: 85,
  },
  thumbnail: {
    height: '100%',
    borderWidth: 0.5,
    borderColor: '#cfcfcf',
  },
  itemSeparator: {
    height: 0.5,
    backgroundColor: Colors.lineGray,
  }
});


export class PushHistoryPage extends React.Component {

  state = {
    isFetchingNextPage: true,
    refreshing: false,
  };

  constructor(props) {
    super(props);

    initAlert(this);
  }

  componentDidMount() {
    const { auth, logEventPageview } = this.props;

    if (!auth || !auth.token) {
      this.alert('로그인해주시기 바랍니다.', {
        buttons: [
          {
            text: '확인',
            onPress: () => {
              browserHistory.push('/accounts/login');
            },
          },
        ]
      });
      return;
    }

    this.loadData();

    logEventPageview({ page_name: 'PushHistory' });
  }

  loadData = async (nextPage) => {
    const { auth, loadPushHistories } = this.props;
    this.setState({ isFetchingNextPage: nextPage }, () => {
      loadPushHistories(auth._id, nextPage);
    });
  };

  refresh() {
    const { auth, loadPushHistories } = this.props;
    const { refreshing } = this.state;

    if (!refreshing) {
      this.setState({ refreshing: true });
      loadPushHistories(auth._id, false).then(this.setState({ refreshing: false }));
    }
  }

  itemOnPress(payload) {
    const { router } = this.props;
    if (payload.type === 'restock'
      || payload.type === 'restock_cart'
      || payload.type === 'restock_wishlist') {
      navigateToProductPage({ productId: payload.goodsNo }, router);
    } else if (payload.type === 'preorder_user_b24h'
      || payload.type === 'preorder_user_open'
      || payload.type === 'preorder_user_e24h'
      || payload.type === 'preorder_user_e01h') {
      navigateToPreOrderDetailPage({ preorderExhibitionId: payload.id }, router);
    }
  }

  renderItem = ({ item }) => {
    let payload = { type: 'none' };
    try {
      payload = JSON.parse(item.payload);
    } catch(e) {
      console.error('Failed to parse pushHistory.payload');
    }
    const createdAt = new Date(item.createdAt);
    return (
      <TouchableHighlight
        underlayColor={Colors.touchEffectColorWhite}
        onPress={() => this.itemOnPress(payload)}
      >
        <View style={styles.itemContainer}>
          <View style={styles.textContainer}>
            <Text style={styles.textDate}>{dateFromNow(createdAt)}</Text>
            <Text style={styles.textDescription}>{item.description}</Text>
          </View>
          { item.thumbnailUrl ? (
            <View style={styles.thumbnailContainer}>
              <Image
                style={styles.thumbnail}
                source={{ uri: item.thumbnailUrl }}
              />
            </View>
          ) : (
            <View style={styles.thumbnailContainer}></View>
          )}
        </View>
      </TouchableHighlight>
    );
  }

  render() {
    const { pushHistories, pagination } = this.props;
    const { refreshing } = this.state;
    const data = pushHistories;

    return (
      <View style={styles.container}>
        <FlatList
          contentContainerStyle={styles.list}
          data={data}
          keyExtractor={item => item._id}
          renderItem={this.renderItem}
          ListHeaderComponent={<View style={styles.itemSeparator} />}
          ListFooterComponent={null}
          onEndReachedThreshold={0.25}
          onEndReached={() => pagination.nextPageUrl && loadData(true)}
          onRefresh={() => { this.refresh(); }}
          refreshing={refreshing}
        />
        {renderAlert(this)}
      </View>
    );
  }
}


export default connect(
  state => {
    return {
      auth: state.auth,
      ...loadEntities(state, 'pushHistories', 'pushHistories'),
    };
  },
  actions,
)(PushHistoryPage);
