import React, { Component } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, TouchableHighlight, Text, View } from 'react-native';

import ArrowBack from '../nativeComponents/ArrowBack';

import HeaderSearchIcon from '../icons/HeaderSearchIcon';
import HeaderBagIcon from '../icons/HeaderBagIcon';

import Colors from '../constants/Colors';
import { reactNativeStyles as Styles } from '../constants/Styles';
import Constants from '../constants/Constants';


const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  containerBorder: {
    borderBottomWidth: 1,
    borderColor: Colors.lightGray,
  },
  button: {
    marginTop: 16 - Constants.touchEffectWidth,
  },
  buttonSearch: {
    marginRight: 20,
  },
  leftContainer: {
    paddingLeft: Constants.viewHorizontalPadding,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  centerContainer: {
    position: 'absolute',
    backgroundColor: 'transparent',
    zIndex: -1,
    width: '100%',
    height: Constants.mainHeaderHeight,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightContainer: {
    paddingRight: Constants.viewHorizontalPadding,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  titleText: {
    color: 'black',
    fontSize: 16,
    lineHeight: 19,
    height: 19,
    marginTop: 19,
    marginBottom: 18,
    ...Styles.appleSDGothicNeoSemiBold,
  },
});


export default class HeaderTextTitleBar extends Component {
  render() {
    const {
      router, title, containerStyle, noborder,
      showSearchButton = false, showCartButton = true,
    } = this.props;

    const containerStyles = [styles.container, containerStyle];
    if (!noborder) {
      containerStyles.push(styles.containerBorder);
    }

    return (
      <View style={containerStyles}>
        <View style={styles.leftContainer}>
          <View style={[Styles.touchEffectContainerInHeader, styles.button]}>
            <ArrowBack
              backButtonStyle="arrow"
              router={router}
            />
          </View>
        </View>
        <View style={styles.centerContainer}>
          <Text style={styles.titleText}>
            {title}
          </Text>
        </View>
        <View style={styles.rightContainer}>
          {showSearchButton && (
            <View style={[Styles.touchEffectContainerInHeader, styles.button, styles.buttonSearch]}>
              <TouchableHighlight
                style={Styles.touchEffectInHeader}
                onPress={() => {
                  router.push('/shop/search');
                }}
                underlayColor={Colors.touchEffectColorWhite}
              >
                <HeaderSearchIcon />
              </TouchableHighlight>
            </View>
          )}

          {showCartButton && (
            <View style={[Styles.touchEffectContainerInHeader, styles.button]}>
              <TouchableHighlight
                style={Styles.touchEffectInHeader}
                onPress={() => {
                  router.push('/shop/cart');
                }}
                underlayColor={Colors.touchEffectColorWhite}
              >
                <HeaderBagIcon />
              </TouchableHighlight>
            </View>
          )}
        </View>
      </View>
    );
  }
}
