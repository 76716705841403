import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, Dimensions, ActivityIndicator,
  TouchableHighlight, Text, View } from 'react-native';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions, loadEntities } from 'shared';

import BlankResult from '../../nativeComponents/BlankResult';
import LoadMoreButton from '../../nativeComponents/LoadMoreButton';

import Styles from '../../constants/Styles';
import Colors from '../../constants/Colors';
import Constants from '../../constants/Constants';


const { width: windowWidth, height: windowHeight } = Dimensions.get('window');
const MAX_COUNT_PER_PAGE = 2;


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  noticeContainer: {
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  noticeBorder: {
    marginHorizontal: Constants.viewHorizontalPadding,
    borderBottomWidth: 1,
    borderBottomColor: '#ecedee',
  },
  noticeHeader: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: 20,
    paddingBottom: 20,
  },
  noticeSubject: {
    flexGrow: 1,
    fontSize: 16,
    fontWeight: 'bold',
    ...Styles.appleSDGothicNeo,
  },
  noticeDate: {
    fontSize: 15,
    color: 'rgba(0, 0, 0, 0.5)',
    ...Styles.appleSDGothicNeo,
  },
  footer: {
  },
  empty: {
  },
  footerSpinnerContainer: {
    position: 'relative',
    width: '100%',
    height: windowHeight / 5,
    paddingVertical: 20,
    marginTop: 10,
    marginBottom: 10,
  },
  loadMoreContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 20,
  },
});


function getTimeStr(timestamp) {
  const datetime = new Date(timestamp);
  const year = datetime.getFullYear();
  const month = datetime.getMonth() + 1;
  const date = datetime.getDate();

  return year + '.' + month + '.' + date;
}

export class NoticePage extends React.Component {
  state = {
    isFetchingNextPage: false,
  };

  componentDidMount() {
    this.loadData(false);
  }

  componentDidUpdate(prevProps) {
    const { pagination } = this.props;

    if (prevProps.pagination.isFetching && !pagination.isFetching) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isFetchingNextPage: false });
    }
  }

  loadData(nextPage) {
    const { loadNotices } = this.props;
    loadNotices(nextPage);
  }

  renderFooter = () => {
    const { pagination, notices } = this.props;
    const { isFetchingNextPage } = this.state;

    if (pagination.nextPageUrl === null || !notices || notices.length === 0) {
      return (
        <View style={styles.footer}>
        </View>
      );
    }

    if (pagination.isFetching && isFetchingNextPage) {
      return (
        <View style={[styles.footer, styles.footerSpinnerContainer]}>
          <ActivityIndicator animating size="small" color="gray" />
        </View>
      );
    }

    return (
      <View style={[styles.footer, styles.loadMoreContainer]}>
        <LoadMoreButton
          onClick={() => {
            if (!pagination.nextPageUrl) {
              return;
            }
            this.setState({ isFetchingNextPage: true }, () => {
              this.loadData(true);
            });
          }}
        />
      </View>
    );
  }


  renderNotice(notice) {
    const { auth, historySwitch, router } = this.props;

    return (
      <GridListTile key={notice._id}>
        <TouchableHighlight
          underlayColor={Colors.touchEffectColorWhite}
          onPress={() => router.push(`/notice/${notice._id}`)}
        >
          <View style={styles.noticeContainer}>
            <View style={styles.noticeHeader}>
              <Text style={styles.noticeSubject}>
                  { notice.subject }
              </Text>
              <Text style={styles.noticeDate}>{ getTimeStr(notice.createdAt) }</Text>
            </View>
          </View>
        </TouchableHighlight>
        <View style={styles.noticeBorder} />
      </GridListTile>
    );
  }

  render() {
    const { router, notices, pagination } = this.props;

    if (!pagination.isFetching && notices.length === 0) {
      return (
        <BlankResult
          router={router}
          title='공지사항이 없습니다'
        />
      );
    }

    return (
      <View style={styles.container}>
        <GridList
          cellHeight="auto"
          cols={1}
          spacing={0}
        >
          {notices.map(notice => this.renderNotice(notice))}
        </GridList>

        {this.renderFooter()}
      </View>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
    ...loadEntities(state, 'notices', 'notices'),
  }),
  actions,
)(NoticePage);
