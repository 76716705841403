import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { View, StyleSheet, TouchableHighlight } from 'react-native';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { actions } from 'react-shared';

import CustomDialog from '../components/CustomDialog';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';

import LikeNormalIcon from '../icons/LikeNormalIcon24';
import LikeSelectedIcon from '../icons/LikeSelectedIcon24';
import LikeNormalSmallIcon from '../icons/LikeNormalIcon18';
import LikeSelectedSmallIcon from '../icons/LikeSelectedIcon18';


const buttonStyles = StyleSheet.create({
  imageContainerStyle: {
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});


class LikeButton extends React.Component {
  isProcessing = false;

  constructor(props) {
    super(props);
    const { item: { liked = false } = {} } = props;

    this.state = {
      liked,
      dialogOpen: false,
    };
  }

  componentDidUpdate() {
    this.syncLikedState();
  }

  toggleItemLike = async () => {
    const {
      item, heartistsPick, isFavProduct, isShopProduct, unlikeProduct, likeProduct,
      auth, historyId = null,
    } = this.props;
    const { token } = auth;
    const { liked } = this.state;

    if (!auth || !token) {
      this.setState({ dialogOpen: true });
      return;
    }

    const relatedObject = { heartistsPick };

    let { post } = this.props;
    if (!post && !!item.post) {
      // eslint-disable-next-line prefer-destructuring
      post = item.post;
    }

    if (post) {
      relatedObject.post = post;
    }
    // console.log('toggleItemLike item', item);
    // console.log('toggleItemLike relatedObject', relatedObject);

    if (isShopProduct) {
      Object.assign(relatedObject, { shopProduct: item });
    }
    if (isFavProduct) {
      Object.assign(relatedObject, { favProduct: item, historyId });
    }

    const productId = item._id || item.goodsNo;
    this.setState({ liked: !liked });

    this.isProcessing = true;

    try {
      if (liked) {
        await unlikeProduct(productId, relatedObject);
      } else {
        await likeProduct(productId, relatedObject);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('LikeButton toggleItemLike error', error);
    }

    this.isProcessing = false;
  }

  closeDialog = () => {
    this.setState({ dialogOpen: false });
  }

  syncLikedState() {
    const {
      item: { liked: likedInProps = false } = {},
    } = this.props;
    const { liked: likedInState } = this.state;
    // console.log(`LikeButton syncLikedState likedInProps=${likedInProps} likedInState=${likedInState}`);

    if (likedInProps !== likedInState && !this.isProcessing) {
      this.setState({ liked: likedInProps });
    }
  }

  render() {
    const { liked, dialogOpen } = this.state;
    const {
      styles, iconProps, item,
      underlayColor = Colors.touchEffectColorWhite,
      isSmallVersion = false,
    } = this.props;

    // console.log(`LikeButton render liked=${liked}`);

    if (!item) {
      return null;
    }

    const productId = item._id || item.goodsNo;

    let LikeIcon;
    if (isSmallVersion) {
      LikeIcon = liked ? LikeSelectedSmallIcon : LikeNormalSmallIcon;
    } else {
      LikeIcon = liked ? LikeSelectedIcon : LikeNormalIcon;
    }

    return (
      <View style={styles}>
        <TouchableHighlight
          style={buttonStyles.touchEffect}
          onPress={this.toggleItemLike}
          underlayColor={underlayColor}
        >
          <LikeIcon {...iconProps} />
        </TouchableHighlight>

        <CustomDialog
          dialogOpen={dialogOpen}
          key_={productId}
          description="상품을 찜하시려면 로그인 하시기 바랍니다."
          confirmText="로그인"
          cancelText="확인"
          cancelColor="default"
          confirmColor="primary"
          onCancel={() => {
            this.closeDialog();
          }}
          onConfirm={() => {
            this.closeDialog();
            browserHistory.push('/accounts/login');
          }}
          onClose={() => {
            this.closeDialog();
          }}
        />
      </View>
    );
  }
}


export default connect(
  (state, ownProps) => {
    const { isShopProduct = false, item } = ownProps;

    const newProps = {
      auth: state.auth,
    };

    // console.log('LikeButton connect isShopProduct=', isShopProduct);
    // console.log('LikeButton connect item=', item);

    if (isShopProduct) {
      if (item) {
        const productId = item._id || item.goodsNo;
        newProps.productId = productId;
        if (state.entities.shopProducts.hasOwnProperty(productId)) {
          newProps.item = state.entities.shopProducts[productId];
          // console.log('Change likeButton item to shopProducts');
        } else {
          // console.log('Maintain likeButton item from cart');
        }
      }
    } else {
      let { post } = ownProps;
      if (!post && item.post) {
        // eslint-disable-next-line prefer-destructuring
        post = item.post;
      }

      if (item && post) {
        const { _id: productId } = item;
        const postInStates = state.entities.posts[post._id];
        if (postInStates) {
          const product = postInStates.products.find(entry => entry._id === productId);
          if (product) {
            product.post = postInStates;
            newProps.productId = productId;
            newProps.item = product;
          }
        }
      }
    }

    // console.log('LikeButton connect newProps=', newProps);

    return newProps;
  },
  actions,
)(LikeButton);
