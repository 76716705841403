import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { TouchableHighlightWithContainer } from '../../../components-v2';

import { Colors, Constants, Styles } from '../../../constants';

import { SquareCheckBoxIcon } from '../../../icons-v2';


const styles = StyleSheet.create({
  container: {
    borderTopWidth: 0.5,
    borderTopColor: '#cfcfcf',
    paddingTop: 20.5,
    marginBottom: 20,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 20,
    alignItems: 'center',
  },
  itemText: {
    fontSize: 14,
    lineHeight: 16.8,
    marginStart: 8,
    color: '#000',
    ...Styles.pretendard400,
  },
  itemTextChecked: {
    ...Styles.pretendard600,
  },
});


const items = [
  { isRequired: true, content: '만 14세 이상 결제 동의' },
  { isRequired: true, content: '서비스 이용약관 및 개인정보 수집 이용 동의' },
];


class Terms extends React.Component {
  state = {
    isCheckedList: [],
  };

  onPress = (item, index) => {
    this.setState(({ isCheckedList: prevIsCheckedList }) => {
      const isCheckedList = [
        ...prevIsCheckedList,
      ];

      isCheckedList[index] = !isCheckedList[index];

      return {
        isCheckedList,
      };
    });
  };

  validateInputField() {
    const { isCheckedList } = this.state;

    for (let i = 0; i < items.length; i++) {
      const { isRequired, content } = items[i];
      const isChecked = isCheckedList[i];
      if ((!isChecked) && isRequired) {
        alert(`${content}에 체크해주세요.`);
        return false;
      }
    }

    return true;
  }

  renderItem(item, index) {
    const { content } = item;
    const { isCheckedList } = this.state;

    const isChecked = isCheckedList[index];

    return (
      <View key={index} style={styles.row}>
        <TouchableHighlightWithContainer
            onPress={() => this.onPress(item, index)}
            >
          <SquareCheckBoxIcon
            checked={isChecked}
          />
        </TouchableHighlightWithContainer>
        <Text style={[styles.itemText, isChecked && styles.itemTextChecked]}>{content}</Text>
      </View>
    );
  }

  render() {
    return (
      <View style={styles.container}>
        {items.map((item, index) => this.renderItem(item, index))}
      </View>
    );
  }
}


export default Terms;
