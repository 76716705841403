import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, Text } from 'react-native';

import { Constants, Styles } from '../../../constants';
import { Button } from '../../../components-v2';


const styles = StyleSheet.create({
  container: {
    height: 49,
    flexDirection: 'row',
    paddingHorizontal: Constants.viewHorizontalPadding,
    backgroundColor: '#ffffff',
    ...Styles.fullWidth,
  },
  labelButton: {
    flex: 1,
    borderWidth: 0,
    padding: 0,
  },
  labelContainer: {
    marginVertical: 4,
  },
  labelText: {
    fontSize: 18,
    lineHeight: 21.6,
    height: 22,
    color: '#000',
    opacity: 0.5,
    textAlign: 'center',
    ...Styles.pretendard700,
  },
  labelTextFocused: {
    opacity: 1,
  },
  labelCountText: {
    marginTop: 2,
    fontSize: 14,
    lineHeight: 16.8,
    height: 17,
    color: '#000',
    opacity: 0.5,
    textAlign: 'center',
    ...Styles.pretendard400,
  },
  labelCountTextFocused: {
    opacity: 1,
  },
});


class TabHeader extends React.Component {
  routes = [
    { key: 'feed', title: 'Feed' },
    { key: 'pick', title: 'Pick' },
    { key: 'following', title: 'Following' },
  ];

  renderLabel = ({ route, focused }) => {
    const {
      onChange, postsCount, followingCount
    } = this.props;

    let count = 0;
    if (route.key === 'feed') {
      count = postsCount.feedCount;
    } else if (route.key === 'pick') {
      count = postsCount.pickCount;
    } else {
      count = followingCount;
    }

    return (
      <View style={{ flex: 1 }} key={`${route.key}`}>
        <Button
          containerStyle={styles.labelButton}
          onPress={() => onChange(route)}
          key={route.key}
          nested
          innerComponent={(
            <View style={styles.labelContainer}>
              <Text
                style={[styles.labelText, focused && styles.labelTextFocused]}
              >
                {route.title}
              </Text>
              <Text
                style={[styles.labelCountText, focused && styles.labelCountTextFocused]}
              >
                { count }
              </Text>
            </View>
          )}
        />
      </View>
    );
  }

  render() {
    const {
      tabname,
    } = this.props;

    return (
      <View style={styles.container}>
        {this.routes.map(route => 
          this.renderLabel({ route, focused: route.key == tabname })
        )}
      </View>
    );
  }
}


TabHeader.propTypes = {
  focusedTab: PropTypes.object,
  onChange: PropTypes.func,
  followingCount: PropTypes.number,
  postsCount: PropTypes.object,
}


TabHeader.defaultProps = {
  followingCount: 0,
  postsCount: { feedCount: 0, pickCount: 0 },
};


export default TabHeader;
