import React from 'react';
import { StyleSheet, View, Text } from 'react-native';

import Styles from '../../../constants/Styles';


const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    marginBottom: 10,
  },
  text: {
    fontSize: 18,
    lineHeight: 21,
    height: 21,
    textTransform: 'uppercase',
    ...Styles.appleSDGothicNeoBold,
  },
});

export default class ShopDetailSectionHeader extends React.Component {
  render() {
    const { title } = this.props;

    return (
      <View style={styles.container}>
        <Text style={styles.text}>
          { title }
        </Text>
      </View>
    );
  }
}
