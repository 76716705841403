/* eslint-disable import/prefer-default-export */

export function br2nl(str) {
  const replaceStr = '\n';
  const targetStr = str.split(replaceStr).join('');
  // Includes <br>, <BR>, <br />, </br>
  // eslint-disable-next-line no-useless-escape
  return targetStr.replace(/<\s*\/?br\s*[\/]?>/gi, replaceStr);
}

// export function moveHomeWithSuccessMsg(router) {
//   if (!router) {
//     return;
//   }

//   router.push({
//     pathname: '/',
//     state: { toast: '가입이 완료되었습니다.' },
//   });
// }

export function getPlatform() {
  if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    return 'ios';
  } else if (navigator.userAgent.match(/Android/i)) {
    return 'android';
  }

  return null;
}

const trimUrl = (link, length) => link
  .replace(/http[s]?:\/\//, '') // to reduce length
  .substring(0, length || 98); // to avoid event parameter length error in firebase analytics


function getSuffix5(str) {
  if (!str) {
    return null;
  }
  if (str.length < 6) {
    return str;
  }
  return str.slice(-5);
}

const buildGodoLink = product => {
  return `heartit.kr/product/${product.godoGoodsNo}`;
}

const buildlWhere = where => {
  switch (where) {
    case 'post':
      return 'p';
    case 'history':
      return 'h';
    default: 
      // undefined
      return 'u';
  }
}

export const generateFirebaseEvent = ({ product, post, where, event = 'click_item' }) => {
  if (!post) {
    return;
  }
  const isGodo = !!product.godoGoodsNo;
  const { username } = post.owner;
  const heartist5 = getSuffix5(username);
  const retail = product.retails[product.defaultRetailIdx];
  const orgLink = retail.orgLink || retail.link;
  const link = isGodo ? buildGodoLink(product) : orgLink;
  const prefixWhere = buildlWhere(where);
  const { _id } = post;
  firebase.analytics().logEvent(event, {
    platform: 'web',
    link: trimUrl(link),
    where,
    heartist: username,
    postId: _id,
    postId_link: `${_id}_${trimUrl(link, 74)}`,
    all: `${prefixWhere}_w_${heartist5}_${_id}_${trimUrl(link, 65)}`,
  });
};

export function getOrderCount(summary) {
  const orderConfirm = parseInt(summary.eachOrderStatus.p1.count, 10);
  const shippingPending = parseInt(summary.eachOrderStatus.g1.count, 10);
  const shipping = parseInt(summary.eachOrderStatus.d1.count, 10);
  const delivered = parseInt(summary.eachOrderStatus.d2.count, 10)
    + parseInt(summary.eachOrderStatus.s1.count, 10);
  const cancel = parseInt(summary.eachOrderStatus.b1.count, 10)
    + parseInt(summary.eachOrderStatus.b2.count, 10)
    + parseInt(summary.eachOrderStatus.b3.count, 10)
    + parseInt(summary.eachOrderStatus.b4.count, 10)
    + parseInt(summary.eachOrderStatus.e1.count, 10)
    + parseInt(summary.eachOrderStatus.e2.count, 10)
    + parseInt(summary.eachOrderStatus.e3.count, 10)
    + parseInt(summary.eachOrderStatus.e4.count, 10)
    + parseInt(summary.eachOrderStatus.e5.count, 10)
    + parseInt(summary.eachOrderStatus.r1.count, 10)
    + parseInt(summary.eachOrderStatus.r2.count, 10)
    + parseInt(summary.eachOrderStatus.r3.count, 10);
  return {
    orderConfirm,
    shippingPending,
    shipping,
    delivered,
    cancel,
  }
}
