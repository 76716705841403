import set from 'lodash/set';

import createFetchAction from '../util/createFetchAction';


export function getVisitCount(startDate, endDate, kind) {
  return createFetchAction({
    type: 'GET_VISIT_COUNT',
    endpoint: `/api/v2/admin/stats/visit?start=${startDate}&end=${endDate}&kind=${kind}`,
    transform: ({ json }) => set({}, `admin.visitCount.${kind}.${startDate}~${endDate}`, json),
  });
}


export function getPageviewCountOverall(startDate, endDate, kind) {
  return createFetchAction({
    type: 'GET_PAGEVIEW_COUNT_OVERALL',
    endpoint: `/api/v2/admin/stats/pageviewOverall/${kind}?start=${startDate}&end=${endDate}`,
    transform: ({ json }) => set({}, `admin.pageviewCountOverall.${kind}.${startDate}~${endDate}`, json.data),
  });
}


export function getPageviewCount(startDate, endDate, key, kind) {
  return createFetchAction({
    type: 'GET_PAGEVIEW_COUNT',
    endpoint: `/api/v2/admin/stats/pageview/${key}?kind=${kind}&start=${startDate}&end=${endDate}`,
  });
}


export function getClickCountOverall(startDate, endDate, kind) {
  return createFetchAction({
    type: 'GET_CLICK_COUNT_OVERALL',
    endpoint: `/api/v2/admin/stats/clickOverall/${kind}?start=${startDate}&end=${endDate}`,
    transform: ({ json }) => set({}, `admin.clickCountOverall.${kind}.${startDate}~${endDate}`, json.data),
  });
}

export function getClickCount(startDate, endDate, key, kind) {
  return createFetchAction({
    type: 'GET_CLICK_COUNT',
    endpoint: `/api/v2/admin/stats/click/${key}?kind=${kind}&start=${startDate}&end=${endDate}`,
  });
}

export function getPostClickCount(postId, startDate, endDate) {
  return createFetchAction({
    type: 'GET_POST_CLICK_COUNT',
    endpoint: `/api/v2/admin/es/count/${postId}?start=${startDate}&end=${endDate}`,
    transform: ({ json }) => set({}, `admin.postClickCount.${postId}.${startDate}~${endDate}`, json),
  });
}

export function getUserStatistics(days, endDate, kind) {
  return createFetchAction({
    type: 'GET_ACTIVE_USERS',
    endpoint: `/api/v2/admin/stats?limit=${days}&start=${endDate}&kind=${kind}`,
    transform: ({ json }) => set({}, 'admin.userStatistics', json),
  });
}

export function getSnapshotCount(startDate, endDate, kind) {
  return createFetchAction({
    type: 'GET_SNAPSHOT_COUNT',
    endpoint: `/api/v2/admin/stats/snapshot/${kind}?start=${startDate}&end=${endDate}`,
    transform: ({ json }) => set({}, `admin.snapshotCount.${kind}.${startDate}~${endDate}`, json.data),
  });
}

export function getSnapshotDetail(startDate, endDate, kind, key) {
  return createFetchAction({
    type: 'GET_SNAPSHOT_DETAIL',
    endpoint: `/api/v2/admin/stats/snapshot/${kind}/${key}?start=${startDate}&end=${endDate}`
  });
}

export function getGodomallRequestCount(startDate, endDate) {
  return createFetchAction({
    type: 'GET_GODOMALL_REQUEST_COUNT',
    endpoint: `/api/v2/admin/stats/godomallRequest?start=${startDate}&end=${endDate}`,
    transform: ({ json }) => set({}, `admin.godomallRequest.${startDate}~${endDate}`, json.data),
  });
}

export function getGodomallRequestDetail(startDate, endDate, key, kind) {
  return createFetchAction({
    type: 'GET_GODOMALL_REQUEST_DETAIL',
    endpoint: `/api/v2/admin/stats/godomallRequest/${key}?kind=${kind}&start=${startDate}&end=${endDate}`,
  });
}
