import AuthPage from './AuthPage';
export default AuthPage;


export function navigateToAuthPage(router, pathAfterAuthSuccess=null) {

  if (pathAfterAuthSuccess) {
    sessionStorage.setItem('pathAfterAuthSuccess', pathAfterAuthSuccess);
  } else {
    sessionStorage.removeItem('pathAfterAuthSuccess');
  }

  if (!router) {
    return;
  }

  router.push({
    pathname: '/accounts/login',
  });
}
