import React from 'react'

import { 
  StyleSheet, View,
} from 'react-native';

import { Styles, Constants } from '../../../../constants';

import { withStyles } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';

const useStyles = theme => ({
  inputContainer: {
    marginLeft: Constants.viewHorizontalPadding,
    marginRight: Constants.viewHorizontalPadding,
    marginTop: 18,
    paddingTop: 8,
    paddingBottom: 8,
    height: 8 + 19 + 8,
  },
  input: {
    "&::placeholder": {
      opacity: 0.2,
      color: '#000000',
    },
    fontSize: 16,
    lineHeight: 19.2,
    color: 'black',
    padding: 0,
    ...Styles.pretendard400,
  },
});


const styles = StyleSheet.create({
  container: {
  },
  bottomBar: {
    marginHorizontal: Constants.viewHorizontalPadding,
    height: 1,
    backgroundColor: '#000',
  },
});


class BrandSearchBar extends React.Component {

  state = {
    searchKeyword: '',
  };

  onChangeText(searchKeyword, submit) {
    const {
      onChangeSearchKeyword
    } = this.props;

    this.setState({ searchKeyword }, () => {
      onChangeSearchKeyword(searchKeyword);
    });
  }

  render() {
    const {
      classes
    } = this.props;

    const {
      searchKeyword
    } = this.state;

    return (
      <View style={styles.container}>
        <InputBase
          placeholder='브랜드명 검색'
          onChange={evt => this.onChangeText(evt.target.value, false)}
          value={searchKeyword}
          fullWidth={true}
          autoCapitalize='none'
          classes={{ root: classes.inputContainer, input: classes.input }}
          />
          <View style={styles.bottomBar} />
      </View>
    );
  }
}


export default withStyles(useStyles)(BrandSearchBar);
