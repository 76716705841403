import React from 'react';

import {
  StyleSheet, View, Text,
} from 'react-native';

import { Styles, Constants, Colors } from '../../../constants';

import { Products } from '../../../components-v2';
import { navigateToProductPage } from '../ProductPage';


const styles = StyleSheet.create({
  container: {
    marginTop: 60,
  },
  listContainer: {
  },
  title: {
    ...Styles.pretendard700,
    fontSize: 18,
    lineHeight: 21.6,
    marginStart: Constants.viewHorizontalPadding,
    marginBottom: 10,
  },
  productImage: {
    height: 220,
    width: 165,
    aspectRatio: 165/220,
    borderWidth: 1,
    borderColor: Colors.productImageBorder,
    overflow: 'hidden',
  },
  productTextContainer: {
    marginTop: 6,
  },
  itemSeparator: {
    width: 3,
  },
  itemContainer: {
  },
});


class SimilarProducts extends React.PureComponent {

  render() {
    const {
      title,
      containerStyle,
      items,
      setModalProps,
      modalProps,
      router,
    } = this.props;

    const onPressProduct = item => {
      const {
        goodsNo: productId,
      } = item;

      navigateToProductPage({ productId }, router);
    };

    return (
      <View style={[styles.container, containerStyle]}>
        <Text style={styles.title}>{title}</Text>
        <Products
          horizontal
          isSmallVersion={false}
          showLikeButton
          products={items}
          onPressProduct={onPressProduct}
          itemSeparatorStyle={ styles.itemSeparator }
          listHeaderComponentStyle={{ width: Constants.viewHorizontalPadding }}
          listFooterComponentStyle={{ width: Constants.viewHorizontalPadding }}
          containerStyle={styles.listContainer}
          itemContainerStyle={{ width: 166 }}
          productImageStyle={styles.productImage}
          productTextContainerStyle={styles.productTextContainer}
          setModalProps={setModalProps}
          modalProps={modalProps}
        />
      </View>
    );
  }
}


export default SimilarProducts;
