import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Dimensions } from 'react-native';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions, loadEntities } from 'shared';
import HistoryTab from './HistoryTab';
import LikedProductsTab from './LikedProductsTab';

import Colors from '../../../constants/Colors';
import Constants from '../../../constants/Constants';
import Styles from '../../../constants/Styles';

const initialLayout = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
};

const stylesHtml = {
  appBar: {
    top: Constants.mainHeaderHeight,
    shadows: ['none'],
  },
  appBarWithSmartBanner: {
    top: Constants.mainHeaderHeight + Constants.smartBannerHeight,
    shadows: ['none'],
  },
  container: {
    minHeight: initialLayout.height,
  },
  tabbarBorderBottom: {
    position: 'absolute',
    bottom: 0,
    height: 1,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
};

const useStyles = () => ({
  tabs: {
    backgroundColor: '#fff',
  },
  indicator: {
    backgroundColor: '#000',
    height: 3,
  },
  tab: {
    color: '#000',
    height: 56,
    opacity: 0.4,
  },
  tabSelected: {
    opacity: 1,
  },
  tabWrapper: {
    ...Styles.appleSDGothicNeoExtraBold,
    fontSize: 15,
    height: 19,
    marginTop: 13,
    marginBottom: 13,
  },
  rippleVisible: {
    opacity: 1,
    color: Colors.touchEffectColorWhite,
    animation: '0ms',
  },
});


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export class MyHistoryPage extends React.Component {
  state = {
    index: 1,
  };

  componentDidMount() {
  }

  onChange = (event, index) => {
    this.setState({
      index,
    });
  }

  render() {
    const { classes, showSmartBanner,
      pagination, loadHistory, histories,
      loadLikedProducts, likedProducts,
      clearAllHistory, deleteHistory, resetHistoryStore,
      auth, historySwitch,
      ...props } = this.props;
    const { index } = this.state;
    const appBarStyle = showSmartBanner ? stylesHtml.appBarWithSmartBanner : stylesHtml.appBar;

    return (
      <div style={stylesHtml.container}>
        <AppBar position="sticky" style={appBarStyle} elevation={0}>
          <Tabs
            value={index}
            onChange={this.onChange}
            variant="fullWidth"
            classes={{
              root: classes.tabs,
              indicator: classes.indicator,
            }}
          >
            <Tab
              label="최근 본 상품"
              classes={{
                root: classes.tab,
                wrapper: classes.tabWrapper,
              }}
              TouchRippleProps={{ classes: { rippleVisible: classes.rippleVisible } }}
            />
            <Tab
              label="찜한 상품"
              classes={{
                root: classes.tab,
                wrapper: classes.tabWrapper,
              }}
              TouchRippleProps={{ classes: { rippleVisible: classes.rippleVisible } }}
            />
            <div style={stylesHtml.tabbarBorderBottom} />
          </Tabs>
        </AppBar>
        <div>

        </div>
        <TabPanel value={index} index={0}>
          <HistoryTab
            loadHistory={loadHistory}
            histories={histories}
            pagination={pagination.histories}
            clearAllHistory={clearAllHistory}
            deleteHistory={deleteHistory}
            resetHistoryStore={resetHistoryStore}
            auth={auth}
            historySwitch={historySwitch}
            showSmartBanner={showSmartBanner}
            {...props}
          />
        </TabPanel>
        <TabPanel value={index} index={1}>
          <LikedProductsTab
            loadLikedProducts={loadLikedProducts}
            likedProducts={likedProducts}
            pagination={pagination.likedProducts}
            auth={auth}
            historySwitch={historySwitch}
            showSmartBanner={showSmartBanner}
            {...props}
          />
        </TabPanel>
      </div>
    );
  }
}


export default withStyles(useStyles)(connect(
  state => {
    const historyEntities = loadEntities(state, 'histories', 'histories');
    const likedProductsEntities = loadEntities(state, 'likedProducts', 'likedProducts');
    const pagination = {
      histories: historyEntities.pagination,
      likedProducts: likedProductsEntities.pagination,
    };

    return {
      auth: state.auth,
      historySwitch: state.settings.historySwitch,
      likedProducts: likedProductsEntities.likedProducts,
      histories: historyEntities.histories,
      pagination,
      showSmartBanner: state.globalLayoutInfo.showSmartBanner,
    };
  },
  actions,
)(MyHistoryPage));
