import * as React from 'react';

function SvgLogoNaverTextType(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={52} height={10} {...props}>
      <path
        d="M46.637.123c2.579 0 4.536.796 4.536 3.275 0 1.476-.816 2.394-2.082 2.748l2.88 3.6h-4.095l-2.128-3.005v3.006h-3.563V.123zm-5.912 0v2.506h-3.696v1.133h3.405v2.365h-3.405V7.24h3.809v2.507H33.5V.123h7.225zm-15.485 0l1.756 5.803h.098L28.85.123h3.886l-4.01 9.624h-3.364L21.352.123h3.888zm-5.812 0l3.75 9.624h-3.964l-.347-1.257h-2.943l-.346 1.257h-3.964l3.75-9.624h4.064zM3.624.11l3.35 4.863V.11h3.61v9.637h-3.62L3.61 4.885v4.862H0V.11h3.624zm13.82 2.981h-.097l-.84 3.174h1.777l-.84-3.174zm28.942-.547h-.75v1.895h.764c.33 0 .732-.083.94-.252.207-.169.304-.394.304-.678 0-.298-.091-.533-.29-.706-.199-.173-.609-.259-.968-.259z"
        fill="#5AC451"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgLogoNaverTextType;
