import React from 'react';
import { View, StyleSheet, FlatList } from 'react-native';
import HeartistProfile from './HeartistProfile';

import Styles from '../constants/Styles';
import Constants from '../constants/Constants';

const MAX_HEARTISTS = 10;

export default class FeaturedHeartists extends React.Component {
  render() {
    const {
      containerStyle, onPressHeartist,
      featuredHeartists, ...props
    } = this.props;

    Object.assign(props, {
      ItemSeparatorComponent: () => <View style={{ padding: 8 - Constants.touchEffectWidth }} />,
      renderItem: ({ item: heartist }) => (
        <HeartistProfile
          heartist={heartist}
          onPressHeartist={() => onPressHeartist(heartist._id, heartist.username)}
        />
      ),
    });

    return (
      <FlatList
        {...props}
        horizontal
        data={featuredHeartists.slice(0, MAX_HEARTISTS)}
        keyExtractor={heartist => heartist._id} // eslint-disable-line no-underscore-dangle
        style={[styles.heartistList, Styles.touchEffectContainer]}
        showsHorizontalScrollIndicator={false}
      />
    );
  }
}

const styles = StyleSheet.create({
  heartistList: {
  },
});
