import { Cloudinary } from 'cloudinary-core';
import React from 'react';
import config from '../config';
import Image from './Image';

const cloudinary = Cloudinary.new();
cloudinary.config({
  ...config.get('cloudinary'),
  secure: true,
});



export default ({ publicId, style, options, ...props }) => {

  const sourceWithVersion = cloudinary.url(publicId, options);
  const source = sourceWithVersion.replace('/v1/', '/');

  // console.log(`source=${source}`);

  return (
    <Image
      style={{...style}}
      source={source}
      {...props}
      />
  );
};
