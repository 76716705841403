import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View, Text } from 'react-native';

import CheckBox from '../../../nativeComponents/CheckBox';

import AlertDialog from '../../../components/AlertDialog';
import Styles from '../../../constants/Styles';


const styles = StyleSheet.create({
  container: {
    marginStart: 20,
    marginEnd: 20,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 20,
    alignItems: 'center',
  },
  itemText: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    height: 19,
    marginStart: 12,
    marginTop: 2,
  },
});


const items = [
  { isRequired: true, content: '만 14세 이상 결제 동의' },
  { isRequired: true, content: '서비스 이용약관 및 개인정보 수집 이용 동의' },
];


export default class Terms extends React.Component {
  state = {
    isCheckedList: [],
    alert: {
      open: false,
      title: null,
      content: null,
      buttons: [],
    },
  };

  onPress = (item, index) => isChecked => {
    this.setState(({ isCheckedList: prevIsCheckedList }) => {
      const isCheckedList = [
        ...prevIsCheckedList,
      ];

      isCheckedList[index] = isChecked;

      return {
        isCheckedList,
      };
    });
  };

  validateInputField() {
    const { isCheckedList } = this.state;

    for (let i = 0; i < items.length; i++) {
      const { isRequired, content } = items[i];
      const isChecked = isCheckedList[i];
      if ((!isChecked) && isRequired) {
        this.alert(`${content}를 하셔야 합니다.`);
        return false;
      }
    }

    return true;
  }

  renderItem(item, index) {
    const { content } = item;
    const { isCheckedList } = this.state;

    const isChecked = isCheckedList[index];

    return (
      <View key={index} style={styles.row}>
        <CheckBox
          checked={isChecked}
          onPress={this.onPress(item, index)}
        />
        <Text style={styles.itemText}>{content}</Text>
      </View>
    );
  }

  alert(msg) {
    this.setState({
      alert: {
        open: true,
        title: msg,
        content: null,
        buttons: [
          {
            text: '확인',
            onPress: () => {
              this.setState({ alert: { open: false } });
            },
          },
        ],
      },
    });
  }

  renderAlert() {
    const {
      alert: {
        open,
        title,
        content,
        buttons,
      },
    } = this.state;

    return (
      <div>
        <AlertDialog
          open={open}
          title={title}
          content={content}
          buttons={buttons}
        />
      </div>
    );
  }

  render() {
    return (
      <View style={styles.container}>
        {items.map((item, index) => this.renderItem(item, index))}
        {this.renderAlert()}
      </View>
    );
  }
}
