import React from 'react';
import {
  StyleSheet, Text, View,
} from 'react-native';

import {
  Styles, Constants,
} from '../../../constants';

import {
  Button
} from '../../../components-v2';


const styles = StyleSheet.create({
  container: {
    marginHorizontal: Constants.viewHorizontalPadding,
  },
  buttonContainer: {
    flex: 1,
  },
  welcomeText: {
    fontSize: 20,
    lineHeight: 28,
    height: 54,
    ...Styles.pretendard600,
    marginVertical: 20,
  },
  button: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 10.5,
    backgroundColor: '#000000',
  },
  buttonText: {
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
    ...Styles.pretendard600,
    color: '#ffffff',
  },
});


class LoginGuideSection extends React.Component {
  
  render() {
    const { onPressLogin } = this.props;

    return (
      <View style={styles.container}>
        <View style={styles.buttonContainer}>
          <Text style={styles.welcomeText}>
            환영합니다. <br/>
            로그인 또는 회원가입 해주세요.
          </Text>
          <Button
            containerStyle={styles.button}
            titleStyle={styles.buttonText}
            title="로그인 / 회원가입"
            onPress={onPressLogin}
          />
        </View>
      </View>
    );
  }
}


export default LoginGuideSection;
