import React from 'react';
import {
  StyleSheet, Text, View,
} from 'react-native';

import Styles from '../constants/Styles';
import Constants from '../constants/Constants';


const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Constants.viewHorizontalPadding,
    paddingTop: 0,
    paddingBottom: 72
  },
  title: {
    marginBottom: 20,
    color: '#000',
    fontSize: 20,
    lineHeight: 24,
    ...Styles.pretendard600,
  },
  content: {
    color: '#000',
    fontSize: 12,
    lineHeight: 18,
    ...Styles.pretendard400,
  },
  footer: {
    marginTop: 10,
    color: '#00000080',
    fontSize: 12,
    lineHeight: 18,
    ...Styles.pretendard400,
  },
});


class BusinessInfo extends React.Component {

  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.content}>
          (주) 해브잇 | 대표이사 : 서수아
        </Text>
        <Text style={styles.content}>
          메일 : cs@heartit.kr
        </Text>
        <Text style={styles.content}>
          전화번호 : 02-518-7795
        </Text>
        <Text style={styles.content}>
          사업자 등록번호 : 227-87-01833
        </Text>
        <Text style={styles.content}>
          개인정보관리자 : 윤영채
        </Text>
        <Text style={styles.content}>
          통신판매 사업자번호 : 2021-서울서초-0032
        </Text>
        <Text style={styles.content}>
          주소 : 서울 용산구 이태원로 200 제니타스빌딩 5F
        </Text>
        <Text style={styles.content}>
          호스팅 제공자 : 엔에이치엔고도(주)
        </Text>

        <Text style={styles.footer}>
        HAVIT @ Corp. All rights reserved.
        </Text>
      </View>
    );
  }
}


export default BusinessInfo;
