import React from 'react';
import { StyleSheet, View, Text } from 'react-native';

import { Constants, Styles } from '../../../constants';

import {
  Button,
} from '../../../components-v2';


const stylesHtml = {
  blankContainer: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: `${-1 * (Constants.mainHeaderHeight + 44 + 10)}px`,
    marginBottom: `${-1 * (Constants.bottomMargin + Constants.bottomTabBarHeight)}px`,
    overflow: 'hidden',
    touchAction: 'none',
  },
  container: {
    flex: 1,
    marginBottom: Constants.bottomTabBarHeight + Constants.bottomMargin,
    overflow: 'hidden',
    touchAction: 'none',
  },
};


const styles = StyleSheet.create({
  itemSeperator: {
    height: 0.5,
    backgroundColor: '#cfcfcf',
    marginHorizontal: Constants.viewHorizontalPadding,
    marginTop: 10,
  },
  blankContainer: {
    flex: 1,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
  },
  blankTextContainer: {
    alignItems: 'center',
  },
  blankLargeText: {
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard400,
    height: 19,
  },
  blankSmallTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    opacity: 0.5,
    marginTop: 10,
  },
  blankSmallText: {
    width: 240,
    flexWrap: 'wrap',
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard400,
    textAlign: 'center',
  },
  blankSmallBoldText: {
    ...Styles.pretendard700,
  },
  blankShoppingButtonContainer: {
    height: 40,
  },
  blankShoppingButton: {
    borderWidth: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
    paddingHorizontal: 56.5,
    paddingVertical: 10.5,
  },
  blankShoppingButtonText: {
    color: '#fff',
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard600,
  },
});

class BlankPage extends React.Component {
  render() {
    const {
      HeaderComponent,
      router,
    } = this.props;

    return (
      <div style={stylesHtml.container}>
        {HeaderComponent}

        <View style={styles.itemSeperator} />

        <div style={stylesHtml.blankContainer}>
          <View style={styles.blankContainer}>
            <View style={styles.blankTextContainer}>
              <Text style={styles.blankLargeText}>위시리스트에 담긴 상품이 없습니다.</Text>
              <View style={styles.blankSmallTextContainer}>
                <Text style={styles.blankSmallText} multiline numOfLines={2}>
                  상품 썸네일의&nbsp;
                  <Text style={styles.blankSmallBoldText}>하트</Text>
                  를 눌러 위시리스트에 담긴 상품을 여기서 볼 수 있습니다.
                </Text>
              </View>
            </View>

            <View style={{ height: 40 }} />

            <View style={styles.blankShoppingButtonContainer}>
              <Button
                containerStyle={styles.blankShoppingButton}
                titleStyle={styles.blankShoppingButtonText}
                title="쇼핑하러가기"
                onPress={() => router.push('/shop')}
              />
            </View>
          </View>
        </div>
      </div>
    );
  }
}

export default BlankPage;
