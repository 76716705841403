import React, { Component } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
} from '@material-ui/core';

const styles = {
  expandButton: {
    justifyContent: 'flex-end',
  },
};

export default class GuideCardItem extends Component {
  constructor(props) {
    super(props);
    this.state = { expanded: false };
  }

  toggleExpanded() {
    this.setState(state => ({ expanded: !state.expanded }));
  }

  render() {
    const { headerCloseButton, title, subheader, style, children } = this.props;
    return (
      <Card style={style}>
        <CardHeader
          title={title}
          subheader={subheader}
          action={
            headerCloseButton && this.state.expanded && (
              <CardActions
                disableActionSpacing
                style={styles.expandButton}
              >
                <Button
                  onClick={() => this.toggleExpanded()}
                  aria-expanded={this.state.expanded}
                  aria-label="Show More"
                >
                  닫기
                </Button>
              </CardActions>
            )
          }
        />
        <Collapse
          in={this.state.expanded}
          timeout="auto"
          unmountOnExit
        >
          <Divider />
          <CardContent>
            {children}
          </CardContent>
        </Collapse>
        <CardActions
          disableActionSpacing
          style={styles.expandButton}
        >
          <Button
            onClick={() => this.toggleExpanded()}
            aria-expanded={this.state.expanded}
            aria-label="Show More"
          >
            {this.state.expanded ? '닫기' : '더보기'}
          </Button>
        </CardActions>
      </Card>
    );
  }
}
