import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';

import { actions } from 'shared';

import Colors from '../../constants/Colors';
import Styles from '../../constants/Styles';


const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    start: 0,
    end: 0,
    justifyContent: 'center',
    maxWidth: 960,
    marginHorizontal: 'auto',
  },
  contentContainer: {
    paddingHorizontal: 20,
    paddingBottom: 30,
  },
  mainText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 22,
    marginBottom: 52,
    marginStart: 10,
    lineHeight: 27,
    height: 54,
  },
  button: {
    backgroundColor: '#000',
    alignItems: 'center',
    marginTop: 10,
    height: 48,
    justifyContent: 'center',
  },
  buttonLight: {
    backgroundColor: Colors.lightGray,
  },
  buttonText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 14,
    height: 17,
    marginTop: 1,
    color: '#fff',
  },
  buttonTextLight: {
    color: '#000',
  },
});

class ReviewWriteSuccessPage extends React.Component {

  componentDidMount() {
    const { logEventPageview } = this.props;

    logEventPageview({ page_name: 'ReviewWriteSucces' });
  }

  render() {
    const { router } = this.props;

    const moveToShop = () => {
      const path = '/shop';
      router.push(path);
    };

    const moveToReviewList = () => {
      const path = '/accounts/reviews';
      router.push(path);
    };

    let mainText = '리뷰 작성이\n완료되었습니다';
    const currentUrl = window.location.href;
    if (currentUrl.includes('Edit')) {
      mainText = '리뷰 수정이\n완료되었습니다';
    }

    return (
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          <Text style={styles.mainText}>{mainText}</Text>
          <TouchableOpacity style={styles.button} onPress={moveToShop}>
            <Text style={styles.buttonText}>쇼핑 계속하기</Text>
          </TouchableOpacity>

          <TouchableOpacity style={[styles.button, styles.buttonLight]} onPress={moveToReviewList}>
            <Text style={[styles.buttonText, styles.buttonTextLight]}>내가 쓴 리뷰 보러가기</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}


export default connect(
  null,
  actions
)(ReviewWriteSuccessPage);
