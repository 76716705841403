import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View, Text, TouchableHighlight, ActivityIndicator } from 'react-native';
import { connect } from 'react-redux';
import setWith from 'lodash/setWith';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import NumberFormat from 'react-number-format';

import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import { browserHistory } from 'react-router';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';
// eslint-disable-next-line import/no-extraneous-dependencies
import { formatPrice } from 'shared/util';
import PlusIcon from '../icons/PlusIcon';
import MinusIcon from '../icons/MinusIcon';
import Styles from '../constants/Styles';
import Footer from '../containers/shop/ProductPage/Footer';
import NaverPayButton from './NaverPayButton';

import CustomDialog from '../components/CustomDialog';

import { checkoutItems } from '../containers/shop/CheckoutPage';
import { navigateToCheckoutNaverPayGateway } from '../containers/shop/CheckoutNaverPayGatewayPage';
import { navigateToCheckoutNaverPayWithoutAuthGateway } from '../containers/shop/CheckoutNaverPayWithoutAuthGatewayPage';

const LOGIN_ALERT = '로그인해주시기 바랍니다.';

const useStyles = () => ({
  root: {
  },
  paperAnchorBottom: {
  },
});

const webStyles = StyleSheet.create({
  contentContainer: {
    paddingStart: 20,
    paddingEnd: 20,
    paddingBottom: 20,
    backgroundColor: '#000',
    minHeight: 100,
  },
  handleContainer: {
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 16,
  },
  handle: {
    height: 4,
    width: 51,
    borderRadius: 2,
    backgroundColor: 'rgb(77, 77, 77)',
  },
  itemCounterContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: 10,
    height: 44,
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
  },
  itemCounterControlContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 24,
  },
  itemCounterButton: {
  },
  itemCounterCountText: {
    ...Styles.appleSDGothicNeoExtraBold,
    fontSize: 20,
    width: 40,
    textAlign: 'center',
    color: '#fff',
  },
  itemPriceText: {
    color: '#fff',
    height: 19,
    fontSize: 15,
    ...Styles.appleSDGothicNeoRegular,
  },
  totalInfoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    marginLeft: 10,
    marginRight: 9,
  },
  totalInfoItemCountText: {
    fontSize: 15,
    ...Styles.appleSDGothicNeoRegular,
    color: '#fff',
    height: 19,
    marginTop: 3,
    marginBottom: 7,
  },
  totalInfoPriceText: {
    fontSize: 20,
    color: '#fff',
    ...Styles.appleSDGothicNeoExtraBold,
    height: 21,
    marginTop: 2,
    marginBottom: 6,
  },
  loadingIndicatorContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    start: 0,
    end: 0,
    backgroundColor: '#00000020',
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerNaverPay: {
    paddingBottom: 20,
    backgroundColor: '#000',
  },
});

const sortSelectStyles = {
  root: base => ({
    ...base,
  }),
  control: base => ({
    ...base,
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    paddingStart: 15,
    marginTop: 4,
    height: 52,
    color: '#fff',
    borderWidth: 0,
    ...Styles.SFUITextSemiBold,
    fontSize: 16,
    borderRadius: 0,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  indicatorSeparator: base => ({
    ...base,
    width: 0,
  }),
  valueContainer: base => ({
    ...base,
    padding: 0,
    height: 52,
  }),
  placeholder: base => ({
    ...base,
    color: '#fff',
    top: 28,
    marginLeft: 16,
    height: 19,
  }),
  singleValue: base => ({
    ...base,
    color: '#fff',
    top: 28,
    marginLeft: 16,
    height: 19,
  }),
  indicatorsContainer: base => ({
    ...base,
    marginRight: 8,
  }),
};


export class ProductOptionSelect extends React.Component {
  state = {
    selectedOptionList: [],
    optionTree: [],
    optionTreeInitialized: false,
    optionCount: 0,
    itemCount: 1,
    isLoadingIndicatorContainerVisible: true,
    showAlert: false,
    alertMsg: '',
  };

  constructor(props) {
    super(props);

    const { selectedItemIndex, selectedItemCount = 1 } = props;

    this.state.selectedItemIndex = selectedItemIndex;
    this.state.itemCount = Number.parseInt(selectedItemCount, 10);
  }

  componentDidMount() {
    const {
      loadShopProduct,
      productId,
    } = this.props;

    // console.log(`componentDidUpdate product=`, product);

    loadShopProduct({ productId });
    this.loadOptionTree();
  }

  componentDidUpdate(prevProp) {
    const { product: prevProduct } = prevProp;
    const { product } = this.props;

    if (prevProduct === undefined || prevProduct !== product) {
      this.loadOptionTree();
    }
  }

  componentWillUnmount() {
    this.setState = () => null;
  }

  async addItemToCart(directCheckout = false) {
    const { optionCount, selectedOptionList } = this.state;

    if (optionCount > 0) {
      if (selectedOptionList.length < optionCount) {
        const unselectedOptionIndex = selectedOptionList.length;
        const msg = `${this.getOptionName(unselectedOptionIndex)} 옵션을 선택해주세요.`;
        this.setState({ showAlert: true, alertMsg: msg });
        return;
      }
    }

    const selectedOptionId = this.getSelectedOptionId();
    // console.log(`onPressCart selectedOptionId`, selectedOptionId);

    const { auth } = this.props;
    if (!auth || !auth.token) {
      this.setState({ showAlert: true, alertMsg: LOGIN_ALERT });
      return;
    }

    const {
      addItemToCart, updateItemInCart, product,
    } = this.props;
    const { goodsNo: productId } = product;
    const { itemCount } = this.state;
    let { selectedItemIndex } = this.state;
    const errorMsg = '상품을 카트에 추가하는 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.';

    this.setState({ isLoadingIndicatorContainerVisible: true });

    try {
      let ret = {};

      if (selectedItemIndex === undefined) {
        // console.log(`onPressCart call addItemToCart(${productId}, ${itemCount}, ${selectedOptionId})`);
        ret = await addItemToCart(productId, itemCount, selectedOptionId, directCheckout);
        // console.log('onPressCart addItemToCart return', ret);
      } else {
        // console.log(`onPressCart call updateItemInCart(${selectedItemIndex}, ${productId}`
        //   + `, ${itemCount}, ${selectedOptionId})`);
        ret = await updateItemInCart(selectedItemIndex, productId, itemCount, selectedOptionId);
        // console.log('onPressCart updateItemInCart return', ret);
      }

      if (!ret.success) {
        const msg = `${errorMsg} (E1${ret.errorCode})`;
        this.setState({ showAlert: true, alertMsg: msg });
        return;
      }

      const { data: { sno } } = ret;

      if (selectedItemIndex === undefined && sno !== undefined) {
        selectedItemIndex = sno;
      }

      const { onItemAddedToCart } = this.props;
      if (!directCheckout && onItemAddedToCart) {
        onItemAddedToCart();
      }

      if (directCheckout === true && selectedItemIndex !== undefined) {
        checkoutItems([selectedItemIndex], auth);
      }
    } catch (error) {
      // console.log('loadNextOption getShopProductOption failed:', error);

      if (error.errorCode) {
        const msg = `${errorMsg} (E2${error.errorCode})`;
        this.setState({ showAlert: true, alertMsg: msg });
      } else {
        this.setState({ showAlert: true, alertMsg: errorMsg });
      }
    } finally {
      this.setState({ isLoadingIndicatorContainerVisible: false });
    }
  }

  loadOptionTree = async () => {
    const { product } = this.props;

    if (product === undefined) {
      return;
    }

    // console.log(`loadOptionTree optionTreeInitialized=${this.state.optionTreeInitialized}`);
    // console.log('loadOptionTree product=', product);
    const { optionName = '', option = [], optionDivision = {}, optionDivisionStock = {}, optionDisplayFl } = product;

    let optionCount = 0;
    if (optionName) {
      optionCount = Array.isArray(optionName) ? optionName.length : 1;
    }

    const optionTree = [];

    if (optionCount === 1) {
      optionTree[0] = option.map(item => ({
        name: item.optionValue,
        optionPrice: Number.parseInt(item.optionPrice, 10),
        stockCnt: Number.parseInt(item.stockCnt, 10),
        sno: item.sno,
      }));
    } else if (optionCount > 1) {
      optionTree[0] = Object.keys(optionDivision).map(key => ({
        name: option[key].optionValue1,
        stockCnt: Number.parseInt(optionDivisionStock[key].stockCnt, 10),
      }));
    }

    // console.log(`componentDidUpdate optionCount`, optionCount);
    // console.log(`componentDidUpdate optionTree`, optionTree);

    // 카트내 상품 정보 수정인 경우 해당 정보가 있어야 함
    const { selectedItemOption } = this.props;
    // console.log('loadOptionTree selectedItemOption', selectedItemOption);


    const selectedOptionList = [];

    this.setState({ isLoadingIndicatorContainerVisible: true });

    try {
      let optionList = optionTree[0];

      let targetSelectedItemOption = selectedItemOption;
      // 다단계 옵션이 1단계로 펼쳐져 처리되는 경우
      if (optionDisplayFl === 's') {
        targetSelectedItemOption = [{
          optionValue: selectedItemOption.map(e => e.optionValue).join('/'),
        }];
      }

      // console.log('loadOptionTree targetSelectedItemOption', targetSelectedItemOption);

      for (let depth = 0; depth < targetSelectedItemOption.length; depth++) {
        // const optionList = this.getOptionList(depth);
        // console.log('loadOptionTree optionList', optionList);
        const selectedOption = targetSelectedItemOption[depth];
        const filteredOptionList = optionList.filter(e => e.name === selectedOption.optionValue);
        if (filteredOptionList.length <= 0) {
          break;
        }

        // console.log('loadOptionTree filteredOptionList', filteredOptionList);

        const [firstFilteredOptionList] = filteredOptionList;
        selectedOptionList[depth] = firstFilteredOptionList;
        // selectedOptionList[depth] = filteredOptionList[0];

        const selectedOptionNameList = selectedOptionList.map(item => (item.name));
        // console.log('loadOptionTree filteredOptionList', filteredOptionList);

        // eslint-disable-next-line no-await-in-loop
        const nextOptionList = await this.loadNextOptionFromServer(depth, selectedOptionList);
        const nextOptionDepth = depth + 1;
        optionList = nextOptionList;

        const newOptionTreeNode = setWith(
          optionTree[nextOptionDepth] || {},
          selectedOptionNameList,
          nextOptionList,
          Object,
        );
        optionTree[nextOptionDepth] = newOptionTreeNode;
      }

      // console.log('loadOptionTree selectedOptionList', selectedOptionList);
    } catch (error) {
      // console.log('loadOptionTree error', error);
    }

    this.setState({
      optionCount,
      optionTree,
      optionTreeInitialized: true,
      selectedOptionList,
      isLoadingIndicatorContainerVisible: false,
    });
  }

  loadNextOptionFromServer = async (depth, selectedOptionList) => {
    // console.log(`loadNextOption(${depth})`, selectedOptionList);

    const { product, getShopProductOption } = this.props;
    if (product === undefined) {
      // console.log('loadNextOption product is not ready yet');
      return [];
    }

    const { goodsNo: productId, mileageFl } = product;

    const selectedOptionNameList = selectedOptionList.map(item => (item.name));

    const params = {
      optionVal: selectedOptionNameList,
      optionKey: depth,
      mileageFl,
    };

    try {
      // console.log(`loadNextOptionFromServer getShopProductOption send productId=${productId}`, params);
      const ret = await getShopProductOption(productId, params);
      // console.log('loadNextOptionFromServer getShopProductOption return', ret);

      if (ret.success !== true) {
        const msg = `옵션 목록을 불러오는 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다. (E0${ret.errorCode})`;
        this.setState({ showAlert: true, alertMsg: msg });
        return [];
      }

      const { data } = ret;
      const { nextOption = [], optionPrice, stockCnt } = data;

      // console.log('loadNextOptionFromServer getShopProductOption nextOption', nextOption);

      const optionList = nextOption.map((item, index) => ({
        name: item,
        optionPrice: Number.parseInt(optionPrice[index], 10),
        stockCnt: Number.parseInt(stockCnt[index], 10),
      }));
      // console.log('loadNextOptionFromServer getShopProductOption optionList', optionList);

      return optionList;
    } catch (error) {
      // console.log('loadNextOptionFromServer getShopProductOption failed:', error);

      if (error.errorCode) {
        const msg =
          `옵션 목록을 불러오는 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다. (E1${error.errorCode})`;
        this.setState({ showAlert: true, alertMsg: msg });
      } else {
        const msg =
          '옵션 목록을 불러오는 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.';
        this.setState({ showAlert: true, alertMsg: msg });
      }

      return [];
    }
  }


  loadNextOption = async (depth, selectedOptionList) => {
    const optionList = await this.loadNextOptionFromServer(depth, selectedOptionList);

    if (optionList.length === 0) {
      return;
    }

    const selectedOptionNameList = selectedOptionList.map(item => (item.name));
    const nextOptionDepth = depth + 1;

    this.setState(prevState => {
      const { optionTree } = prevState;

      const newOptionTree = cloneDeep(optionTree);
      // console.log('loadNextOption getShopProductOption selectedOptionList', selectedOptionNameList);
      const newOptionTreeNode = setWith(
        newOptionTree[nextOptionDepth] || {},
        selectedOptionNameList,
        optionList,
        Object,
      );
      newOptionTree[nextOptionDepth] = newOptionTreeNode;
      // console.log('loadNextOption getShopProductOption optionTree', optionTree);
      // console.log('loadNextOption getShopProductOption newOptionTreeNode', newOptionTreeNode);
      // console.log('loadNextOption getShopProductOption newOptionTree', newOptionTree);

      return {
        optionTree: newOptionTree,
      };
    });
  }


  onOptionSelected = (depth, option) => {
    const { product } = this.props;
    // console.log(`onOptionSelected(${depth}) option=`, option);

    if (product.stockFl === 'y' && option.stockCnt === 0) {
      return;
    }

    this.setState(prevState => {
      const { optionCount, selectedOptionList } = prevState;

      const newSelectedOptionList = selectedOptionList.slice(0, depth);
      newSelectedOptionList[depth] = option;

      const newState = {
        selectedOptionList: newSelectedOptionList,
      };

      if (depth < optionCount - 1) {
        this.loadNextOption(depth, newSelectedOptionList);
      }

      return newState;
    });
  };

  onPressDecItemCount = () => {
    // console.log("onPressDecItemCount");

    const { itemCount } = this.state;
    if (itemCount > 1) {
      this.setState({ itemCount: itemCount - 1 });
    }
  };

  onPressIncItemCount = () => {
    // console.log("onPressIncItemCount");

    const { itemCount } = this.state;
    this.setState({ itemCount: itemCount + 1 });
  };

  onPressCart = () => {
    const { product } = this.props;
    if (product.soldOut === 'y') {
      const msg = '상품이 품절되었습니다.';
      this.setState({ showAlert: true, alertMsg: msg });
      return;
    }

    this.addItemToCart();
  }

  onPressCheckout = () => {
    const { product } = this.props;
    if (product.soldOut === 'y') {
      const msg = '상품이 품절되었습니다.';
      this.setState({ showAlert: true, alertMsg: msg });
      return;
    }

    this.addItemToCart(true);
  }

  onPressNaverPay = async () => {
    const { optionCount, selectedOptionList } = this.state;
    const { auth } = this.props;

    if (optionCount > 0) {
      if (selectedOptionList.length < optionCount) {
        const unselectedOptionIndex = selectedOptionList.length;
        const msg = `${this.getOptionName(unselectedOptionIndex)} 옵션을 선택해주세요.`;
        this.setState({ showAlert: true, alertMsg: msg });
        return;
      }
    }

    const selectedOptionId = this.getSelectedOptionId();
    // console.log(`onPressCart selectedOptionId`, selectedOptionId);

    const {
      addItemToCart, updateItemInCart, addItemToCartWithoutAuth, product,
    } = this.props;
    const { goodsNo: productId } = product;
    const { selectedItemIndex, itemCount } = this.state;
    const errorMsg = '네이버페이 주문 생성 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.';

    this.setState({ isLoadingIndicatorContainerVisible: true });

    try {
      const newWindow = window.open('/shop/checkoutNaverPay/', '_blank');
      if (auth && auth.token) {
        let ret;
        if (selectedItemIndex === undefined) {
          ret = await addItemToCart(productId, itemCount, selectedOptionId, true);
        } else {
          ret = await updateItemInCart(selectedItemIndex, productId, itemCount, selectedOptionId);
        }

        if (!ret.success) {
          const msg = `${errorMsg} (E1${ret.errorCode})`;
          this.setState({ showAlert: true, alertMsg: msg });
          return;
        }

        const { onItemNaverPay } = this.props;
        if (onItemNaverPay) {
          onItemNaverPay();
        }

        const { data: { sno } } = ret;
        navigateToCheckoutNaverPayGateway({ orderItems: [sno || selectedItemIndex], holdPage: true, newWindow });
      } else {
        const { onItemNaverPay } = this.props;
        if (onItemNaverPay) {
          onItemNaverPay();
        }
        navigateToCheckoutNaverPayWithoutAuthGateway({ productId, itemCount, selectedOptionId, holdPage: true, newWindow });
      }
    } catch (error) {
      console.log(error);
      if (error.errorCode) {
        const msg = `${errorMsg} (E2${error.errorCode})`;
        this.setState({ showAlert: true, alertMsg: msg });
      } else {
        this.setState({ showAlert: true, alertMsg: errorMsg });
      }
    } finally {
      this.setState({ isLoadingIndicatorContainerVisible: false });
    }
  };

  getOptionName(depth) {
    const { product } = this.props;
    const { optionName } = product;
    return Array.isArray(optionName) ? optionName[depth] : optionName;
  }

  getSelectedOptionName(depth) {
    const nameList = this.getSelectedOptionNameList();
    return nameList[depth];
  }

  getSelectedOptionId() {
    const { optionCount, selectedOptionList } = this.state;
    if (optionCount === 0) {
      return 0;
    }

    if (optionCount === 1) {
      return selectedOptionList[0].sno || 0;
    }

    const selectedOptionNameList = this.getSelectedOptionNameList();

    const { product } = this.props;
    const { option } = product;

    const matchedOption = option.filter(item => {
      const matchedName = selectedOptionNameList.filter((name, index) => {
        const key = `optionValue${index + 1}`;
        return item[key] === name;
      });
      return matchedName.length === optionCount;
    });

    // console.log('getSelectedOptionId matchedOption', matchedOption);

    return matchedOption[0].sno || 0;
  }

  getSelectedOptionNameList(selectedOptionList) {
    let target = selectedOptionList;
    if (target === undefined) {
      const { selectedOptionList: targetSelectedOptionList } = this.state;
      target = targetSelectedOptionList;
    }

    const selectedOptionNameList = target.map(item => (item.name));

    return selectedOptionNameList;
  }

  getOptionList(depth) {
    const { optionTree } = this.state;

    // console.log(`getOptionList(${depth}) optionTree`, optionTree);
    if (optionTree.length === 0) {
      return [];
    }

    if (depth === 0) {
      const optionList = optionTree[0];
      // console.log(`getOptionList(${depth}) return`, optionList);
      return optionList || [];
    }
    const selectedOptionNameList = this.getSelectedOptionNameList().slice(0, depth);
    const optionList = get(optionTree[depth], selectedOptionNameList);
    // console.log(`getOptionList(${depth}) return`, optionList);
    return optionList || [];
  }

  getOptionPrice() {
    const { optionCount, selectedOptionList } = this.state;

    // 상품 옵션이 없는 경우
    if (optionCount === 0) {
      return 0;
    }

    const { optionPrice = 0 } = (selectedOptionList[optionCount - 1] || {});
    return optionPrice;
  }

  getTotalPrice() {
    const { product } = this.props;
    const { itemCount } = this.state;

    let productPrice = 0;
    if (product !== undefined) {
      productPrice = Number.parseInt(product.goodsPrice, 10);
    }

    const optionPrice = this.getOptionPrice();
    // console.log("getTotalPrice optionPrice=", optionPrice);

    productPrice += optionPrice;
    // console.log("getTotalPrice productPrice=", productPrice);

    const totalPrice = itemCount * productPrice;

    return totalPrice;
  }


  renderOptionPicker(depth) {
    const { product } = this.props;
    const { optionCount } = this.state;

    const optionName = this.getOptionName(depth);
    const selectedOptionName = this.getSelectedOptionName(depth) || null;
    const optionList = this.getOptionList(depth);

    // console.log(`renderOptionPicker(${depth}) optionName=`, optionName);
    // console.log(`renderOptionPicker(${depth}) optionList=`, optionList);
    // console.log(`renderOptionPicker(${depth}) selectedOptionName=`, selectedOptionName);

    const items = optionList.map(option => {
      let label = option.name;
      if ((depth === (optionCount - 1)) && option.optionPrice) {
        const optionPrice = Number.parseInt(option.optionPrice, 10);
        if (optionPrice !== 0) {
          label += `: ${formatPrice(optionPrice)}`;
        }
      }
      if (product.stockFl === 'y' && option.stockCnt === 0) {
        label += ' (품절)';
      }
      return {
        label,
        value: option.name,
        option,
      };
    });

    const selectedOption = items.find(e => e.value === selectedOptionName) || null;
    // console.log(`renderOptionPicker(${depth}) selectedOption=`, selectedOption);

    return (
      <Select
        key={`picker_${depth}`}
        value={selectedOption}
        onChange={data => data.value !== null && this.onOptionSelected(depth, data.option)}
        options={items}
        isClearable={false}
        isSearchable={false}
        styles={sortSelectStyles}
        menuPortalTarget={document.body}
        menuPlacement="top"
        menuPosition="fixed"
        placeholder={optionName}
        clearIndicator
      />
    );
  }

  renderOptionPickerList() {
    const { product = {} } = this.props;
    const { optionName = '' } = product;

    const { optionCount } = this.state;

    // 상품 옵션이 없는 경우
    if (!optionName) {
      return null;
    }

    const optionPickerList = [];

    // console.log(`renderOptionPickerList optionCount`, optionCount);

    for (let i = 0; i < optionCount; i++) {
      optionPickerList.push(this.renderOptionPicker(i));
    }

    return (
      <View>
        {optionPickerList}
      </View>
    );
  }

  renderItemCounter() {
    const { optionTreeInitialized, optionCount, selectedOptionList } = this.state;

    if (!optionTreeInitialized) {
      return null;
    }

    if (optionCount > 0) {
      if (selectedOptionList.length < optionCount) {
        return null;
      }
    }

    const { product = {} } = this.props;
    const { optionName = '' } = product;
    const { itemCount } = this.state;
    const totalPrice = this.getTotalPrice();

    return (
      <View style={[
        webStyles.itemCounterContainer,
        optionName === '' && { marginTop: 4 },
      ]}
      >
        <View style={webStyles.itemCounterControlContainer}>
          <TouchableHighlight
            underlayColor="rgb(255, 255, 255, 0.2)"
            style={webStyles.itemCounterButton}
            onPress={this.onPressDecItemCount}
          >
            <MinusIcon />
          </TouchableHighlight>

          <Text style={webStyles.itemCounterCountText}>
            {itemCount}
          </Text>

          <TouchableHighlight
            underlayColor="rgb(255, 255, 255, 0.2)"
            style={webStyles.itemCounterButton}
            onPress={this.onPressIncItemCount}
          >
            <PlusIcon />
          </TouchableHighlight>
        </View>
        <NumberFormat
          value={totalPrice}
          displayType="text"
          thousandSeparator
          renderText={value => (
            <Text style={webStyles.itemPriceText}>
              {`${value}원`}
            </Text>
          )}
        />
      </View>
    );
  }

  renderTotalInfo() {
    const { optionTreeInitialized, optionCount, selectedOptionList } = this.state;

    if (!optionTreeInitialized) {
      return null;
    }

    if (optionCount > 0) {
      if (selectedOptionList.length < optionCount) {
        return null;
      }
    }


    const { itemCount } = this.state;

    const totalPrice = this.getTotalPrice();

    return (
      <View style={webStyles.totalInfoContainer}>
        <Text style={webStyles.totalInfoItemCountText}>
          {`총 ${itemCount}개 상품`}
        </Text>
        <NumberFormat
          value={totalPrice}
          displayType="text"
          thousandSeparator
          renderText={value => (
            <Text style={webStyles.totalInfoPriceText}>
              {`${value}원`}
            </Text>
          )}
        />
      </View>
    );
  }

  render() {
    const anchor = 'bottom';
    const { product, open, onClose, classes } = this.props;
    const { isLoadingIndicatorContainerVisible, showAlert, alertMsg } = this.state;
    const isPreOrder = !!product && !!product.launchYmd && product.launchYmd !== '0000-00-00';
    const isLiving = !!product && !!product.cateNm && product.cateNm.toUpperCase() === 'LIVING';

    // console.log(`render() product=`, product);

    return (
      <View>
        <Drawer
          anchor={anchor}
          open={open}
          onClose={onClose}
          transitionDuration={0}
          classes={classes}
        >
          <View
            style={[webStyles.contentContainer]}
          >
            <View style={webStyles.handleContainer}>
              <View style={webStyles.handle} />
            </View>
            {this.renderOptionPickerList()}
            {this.renderItemCounter()}
            {this.renderTotalInfo()}
            { isLoadingIndicatorContainerVisible && (
              <View style={webStyles.loadingIndicatorContainer}>
                <ActivityIndicator color="gray" size="large" />
              </View>
            )}
          </View>

          { (!isPreOrder && !isLiving) && (
            <View style={webStyles.containerNaverPay}>
              <NaverPayButton
                onPressButton={this.onPressNaverPay}
                darkMode={true}
              />
            </View>
          )}
          <Footer
            onPressCart={this.onPressCart}
            product={product}
            onPressCheckout={this.onPressCheckout}
          />
        </Drawer>

        <CustomDialog
          dialogOpen={showAlert}
          description={alertMsg}
          onCancel={() => this.setState({ showAlert: false }, () => {
            if (alertMsg === LOGIN_ALERT) {
              browserHistory.push('/accounts/login');
            }
          })}
          showConfirm={false}
          showCancel
          cancelText='확인'
          cancelColor='primary'
        />
      </View>
    );
  }
}

export default withStyles(useStyles)(connect(
  (state, { productId }) => ({
    auth: state.auth,
    product: state.entities.shopProducts[productId],
  }),
  actions,
)(ProductOptionSelect));
