import React from 'react';
import {
  Dimensions, View, StyleSheet, ActivityIndicator,
} from 'react-native';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';
import Constants from '../constants/Constants';

import Spinner from './Spinner';


const { width: windowWidth, height: windowHeight } = Dimensions.get('window');
const styles = StyleSheet.create({
  container: {
      position: 'relative',
      width: '100%',
      height: windowHeight / 5,
      paddingVertical: 20,
      marginTop: 10,
      marginBottom: 10,
  },
  space: {
    height: Constants.innerViewBottomSpace,
  },
});


export default ({ isFetchingNextPage, containerStyle, spaceStyle }) => {
  return (
      <View>
        {isFetchingNextPage && (
          <View style={[styles.container, containerStyle]}>
            <ActivityIndicator animating size="small" color="gray" />
          </View>
        )}
        <View style={[styles.space, spaceStyle]} />
      </View>
    );
}
