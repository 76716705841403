import React from 'react';
import { StyleSheet, Platform, View, Text } from 'react-native';

import {
  Colors, Constants, Styles,
} from '../../../constants';

import {
  Button,
} from '../../../components-v2';


export default function Section(props) {
  const { title, headerButtonText = null, headerButtonPress = null, titleContainerStyle, children, childrenContainerStyle } = props;

  return (
    <View style={styles.container}>
      <View style={[styles.title, titleContainerStyle]}>
        <Text style={styles.titleText}>{title}</Text>
        {!!headerButtonPress && (
          <Button
            containerStyle={styles.headerButtonContainer}
            titleStyle={styles.headerButtonText}
            title={headerButtonText}
            onPress={headerButtonPress}
          />
        )}
      </View>

      <View style={[styles.childrenContainer, childrenContainerStyle ]}>
        {children}
      </View>

    </View>
  );
}


const styles = StyleSheet.create({
  container: {
  },
  title: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 13.5,
    borderTopWidth: 0.5,
    borderTopColor: Colors.lineGray,
  },
  titleText: {
    fontSize: 16,
    lineHeight: 19.2,
    textAlignVertical: 'center',
    textAlign: 'right',
    color: '#000',
    ...Styles.pretendard600,
  },
  headerButtonText: {
    fontSize: 14,
    lineHeight: 16.8,
    textAlignVertical: 'center',
    textAlign: 'right',
    color: '#000',
    ...Styles.pretendard400,
  },
  headerButtonContainer: {
    borderWidth: 0,
    borderRadius: Constants.touchEffectBorderRadius,
    borderColor: 'transparent',
    padding: Constants.touchEffectWidth,
    margin: -1 * Constants.touchEffectWidth,
  },
  childrenContainer: {
    paddingTop: 22,
  },
});
