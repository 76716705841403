/* eslint-disable max-len */
import React, { Component } from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PanelItem from '../../components/admin/GuideExpansionPanel';
import CardItem from '../../components/admin/GuideCardItem';

const styles = {
  container: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  div: {
    whiteSpace: 'pre-line',
    marginBottom: 20,
  },
  cardItem: {
    width: '100%',
    marginBottom: 10,
  },
  linksDiv: {
    marginTop: 10,
    marginBottom: 20,
    whiteSpace: 'pre-line',
  },
  img: {
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.125)',
  },
  imgDiv: {
    flex: 1,
    flexDirection: 'row',
    whiteSpace: 'pre-line',
    marginBottom: 20,
  },
  imgItem: {
    width: '50%',
    display: 'inline-block',
    paddingLeft: 5,
    paddingRight: 5,
  },
  imgTitle: {
    marginTop: 20,
    marginBottom: 20,
    textAlign: 'center',
    width: '100%',
  },
  horizontalImg: {
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.125)',
    marginBottom: 10,
  },
  collageContainer: {
    flexDirection: 'row',
    display: 'flex',
    marginBottom: 20,
    paddingTop: 20,
  },
  imgContainer: {
    paddingRight: 2.5,
    width: '50%',
  },
  imgTextContainer: {
    paddingLeft: 2.5,
    width: '50%',
    flexDirection: 'column',
    display: 'flex',
  },
  textDiv: {
    width: '100%',
    padding: '20px 10px 10px 10px',
    whiteSpace: 'pre-line',
  },
  sideDiv: {
    whiteSpace: 'pre-line',
    padding: 10,
  },
  note: {
    margin: '0px 0px 25px 0px',
    textAlign: 'center',
    color: 'red',
  },
  redText: {
    color: 'red',
  },
  queryExplanation: {
    marginBottom: 10,
  },
  underlinedText: {
    textDecoration: 'underline',
  },
};

export default class GuidePage extends Component {
  render() {
    return (
      <div className="container" style={styles.container}>
        <PanelItem
          defaultExpanded={false}
          expandIcon={<ExpandMoreIcon />}
          header="Google Analytics"
          headerUrl="https://analytics.google.com/analytics/web/#/report-home/a70022523w107164133p112372255"
          tooltip="Google Analytics 바로가기"
        >
          <CardItem
            title="GA Properties 목록"
            subheader="마지막 업데이트: 2019-07-01"
            style={styles.cardItem}
            headerCloseButton
          >
            <Typography variant="body2" style={styles.note}>
              링크를 클릭하면 해당 페이지가 열립니다.
            </Typography>
            <Typography variant="body2" style={styles.linksDiv}>
              {'데이터 뷰: \nProperty (Propery Number) / View (View Number)'}
            </Typography>
            <div style={styles.linksDiv}>
              {'하트잇 웹: \n'}
              <a
                href="https://analytics.google.com/analytics/web/#/report/trafficsources-all-traffic/a70022523w107164133p112372255"
                target="_blank"
                rel="noopener noreferrer"
              >
                {'Heart it (UA-70022523-1) / Hartit Web Data - 20151124 (112372255)'}
              </a>
            </div>
            <div style={styles.linksDiv}>
              {'에디토리얼 통합: \n'}
              <a
                href="https://analytics.google.com/analytics/web/#/report-home/a70022523w202174056p196020657"
                target="_blank"
                rel="noopener noreferrer"
              >
                {'heartit-editorials (UA-70022523-15) / All Web Site Data (196020657)'}
              </a>
              {'\n=> 퍼스널 컬러와 스프링 에센셜 에디토리얼의 트랙킹 데이터를 보시려면 아래 별도 페이지 참조\n\n'}
              {'퍼스널 컬러 에디토리얼: \n'}
              <a
                href="https://analytics.google.com/analytics/web/#/report-home/a70022523w192443070p188217334"
                target="_blank"
                rel="noopener noreferrer"
              >
                {'heartit-personal-color (UA-70022523-10) / All Web Site Data (188217334)\n'}
              </a>
              {'스프링 에센셜 에디토리얼: \n'}
              <a
                href="https://analytics.google.com/analytics/web/#/report-home/a70022523w199818269p194182426"
                target="_blank"
                rel="noopener noreferrer"
              >
                {'heartit-spring-essential (UA-70022523-14) / All Web Site Data (194182426)\n'}
              </a>
            </div>
            <div style={styles.linksDiv}>
              {'하트잇 샵: \n'}
              <a
                href="https://analytics.google.com/analytics/web/#/report-home/a70022523w187049754p183902532"
                target="_blank"
                rel="noopener noreferrer"
              >
                {'godo5_heartit_shop (UA-70022523-8) / All Web Site Data (183902532)'}
              </a>
            </div>
          </CardItem>
          <CardItem
            title="UTM Parameters"
            subheader="마지막 업데이트: 2019-07-01"
            style={styles.cardItem}
            headerCloseButton
          >
            <div style={styles.linksDiv}>
              <Typography variant="body2" style={styles.redText}>Campaign URL Builder: </Typography>
              <Tooltip title="Campaign URL Builder 페이지 열기">
                <a
                  href="https://ga-dev-tools.appspot.com/campaign-url-builder/"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  https://ga-dev-tools.appspot.com/campaign-url-builder/
                </a>
              </Tooltip>
            </div>
            <Typography variant="title">[General Rule]</Typography>
            <div style={styles.div}>
              {`
                영소문자, .(dot), _(underscore), -(minus sign), [0-9] (숫자) 만 사용\n
                주의) 공백문자 / 영대문자 / 한글 사용금지
                : 사용가능한 경우도 있지만, tracking system에 오류를 만들거나 encoding되어서 알아보기 어려울 수 있습니다.
              `}
            </div>
            <Typography variant="title">[utm_source]</Typography>
            <div style={styles.div}>
              {`
                사이트의 domain name 사용
                모두가 아는 경우 축약형으로 사용 가능\n
                예시)
                www.google.com -> google
                www.facebook.com -> facebook
                instagram.com -> instagram
                heartit.kr -> heartit
                shop.heartit.kr
                blog.naver.com
              `}
            </div>
            <Typography variant="title">[utm_medium]</Typography>
            <div style={styles.div}>
              {`
                해당 사이트 내에서 구체적인 위치나 방법을 명시\n
                예시)
                feed, banner, feed_ads, cpc, editorial, editorial_list, sms, push, 등등
              `}
            </div>
            <Typography variant="title">[utm_campaign]</Typography>
            <div style={styles.div}>
              {`
                모든 link를 구분할 수 있도록 추가 정보를 붙여서 표시
                : 구체적으로 적어주는 것이 추후에 GA에서 조회할 때 편리할 수 있습니다.\n
                예시)
                feed-video-by-yoon
                feed-video-by-lee
                banner80
                editorial1500
                push20190619
                avam-pre-order
              `}
            </div>
            <Typography variant="title">[UTM Parameters별 데이터 확인 방법 예시]</Typography>
            <div style={styles.linksDiv}>
              <Typography variant="body1">
                {'1) utm_source/medium으로 데이터 확인: \n'}
                <a
                  href="https://analytics.google.com/analytics/web/#/report/trafficsources-all-traffic/a70022523w107164133p112372255/"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  Heart it (UA-70022523-1) / Hartit Web Data - 20151124 (112372255) 예시 바로 가기
                </a>
              </Typography>
              <Typography variant="body2">{'=> 경로: Acquisition > All Traffic > Source/Medium'}</Typography>
            </div>
            <div style={styles.linksDiv}>
              <Typography variant="body1">
                {'2) utm_campaign으로 데이터 확인: \n'}
                <a
                  href="https://analytics.google.com/analytics/web/#/report/trafficsources-campaigns/a70022523w202174056p196020657/"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  heartit-editorials (UA-70022523-15) / All Web Site Data (196020657) 예시 바로 가기
                </a>
              </Typography>
              <Typography variant="body2">{'=> 경로: Acquisition > Campaigns > All Campaigns'}</Typography>
            </div>
          </CardItem>
        </PanelItem>

        <PanelItem
          defaultExpanded={false}
          expandIcon={<ExpandMoreIcon />}
          header="Firebase"
          headerUrl="https://console.firebase.google.com/project/heartit-react/overview"
          tooltip="Firebase 바로가기"
        >
          <CardItem
            title="[개발팀 ONLY] App Release시 확인 사항"
            subheader="마지막 업데이트: 2019-07-02"
            style={styles.cardItem}
            headerCloseButton={false}
          >
            <div style={styles.linksDiv}>
              <Typography variant="body1">
                앱스토어에서 100% Release를 하기 전에 반드시 Firebase의 Crash-free users를 확인해야 합니다.
              </Typography>
              <Typography variant="body1">
                Crash-free users는 Project Overview의 Quality 또는 Latest Release에서 확인 가능합니다.
              </Typography>
              <Typography variant="body2" style={styles.underlinedText}>
                Crash-free users가 99% 이상일 때에만 앱스토어에서 100% Release로 전환해 주세요.
              </Typography>
            </div>
          </CardItem>
          <CardItem
            title="Firebase로 앱의 아이템 클릭 수 교차 검증하기"
            subheader="마지막 업데이트: 2019-07-02"
            style={styles.cardItem}
            headerCloseButton
          >
            <div style={styles.linksDiv}>
              <Typography variant="body2">
                {`Firebase에서 특정 날짜로 추출한 click_item의 수와
                  Kibana Discover에서 kst: YYYY-MM-DD AND agent: app AND where: post로 검색한 결과값이
                  크게 차이나지 않아야 합니다.
                  (Firebase에서 클릭 이벤트 기록 시 약간의 누락이 있는 것으로 보고 있습니다.)
                `}
              </Typography>
            </div>
            <div style={styles.div}>
              <Typography variant="body2" style={styles.redText}>예시 링크</Typography>
              <Tooltip title="클릭 시 해당 페이지가 열립니다.">
                <Typography variant="body1">
                  <a
                    href="https://console.firebase.google.com/project/heartit-react/analytics/app/android:kr.heartit.heartit/events~2Freport~2Fclick_item%3Ft=1&params=_u..pageSize%253D25%2526_r..eventId%253Dclick_item%2526_u.date00%253D20190701%2526_u.date01%253D20190701&cs=app.m.events.detail&r=default&g=1"
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    2019년 7월 1일의 Firebase click_item 수: 14,004
                  </a>
                </Typography>
              </Tooltip>
              <Tooltip title="클릭 시 해당 페이지가 열립니다.">
                <Typography variant="body1">
                  <a
                    href="http://kibana.heartit.kr/_plugin/kibana/app/kibana#/discover?_g=()&_a=(columns:!(_source),index:'6c94fd90-7204-11e9-bbf7-abe204ab700f',interval:auto,query:(language:lucene,query:'kst:%20%20%202019-07-01%20AND%20agent:%20app%20AND%20where:%20post'),sort:!(_score,desc))"
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    2019년 7월 1일의 Kibana item click수: 14,345
                  </a>
                </Typography>
              </Tooltip>
              <Typography variant="body1" style={styles.underlinedText}>
                {'Firebase와 Kibana의 클릭 이벤트 차이는 < 5% (2%대)'}
              </Typography>
              <Typography variant="body1">
                {'(다른 날짜를 비교해도 Firebase와 Kibana의 클릭 이벤트 차이(%)는 거의 같습니다.)'}
              </Typography>
            </div>
          </CardItem>
        </PanelItem>

        <PanelItem
          defaultExpanded={false}
          expandIcon={<ExpandMoreIcon />}
          header="OneSignal"
          headerUrl="https://app.onesignal.com"
          tooltip="OneSignal 바로가기"
        >
          <CardItem
            title="Push 메세지 보내기"
            subheader="마지막 업데이트: 2019-07-02"
            style={styles.cardItem}
            headerCloseButton
          >
            <Typography variant="title">[New Push 생성하기]</Typography>
            <div style={styles.collageContainer}>
              <div style={styles.imgContainer}>
                <img
                  src="https://cdn.heartit.kr/img/r2/admin/os_guideImg7.png"
                  title="OneSignal create new push"
                  alt="OneSignal create new push"
                  style={styles.img}
                />
              </div>
              <div style={styles.imgTextContainer}>
                <img
                  src="https://cdn.heartit.kr/img/r2/admin/os_guideImg8.png"
                  title="OneSignal create new push"
                  alt="OneSignal create new push"
                  style={styles.img}
                />
                <div style={styles.textDiv}>
                  {`(상단 이미지) Messages 탭에서\nNEW PUSH 버튼 클릭\n\n
                    (좌측 이미지) Dashboard 페이지의 우측에서 COMMON ACTIONS 아래\nSEND A PUSH MESSAGE 클릭\n\n
                    아래 링크 클릭:
                  `}
                  <a
                    href="https://app.onesignal.com/apps/e5dfe991-396b-4da7-ac09-a41cc3d22431/notifications/new"
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    OneSignal New Push 바로가기
                  </a>
                </div>
              </div>
            </div>

            <Typography variant="title">[세그먼트 나눠서 보내기]</Typography>
            <div style={styles.collageContainer}>
              <div style={styles.imgContainer}>
                <img
                  src="https://cdn.heartit.kr/img/r2/admin/os_guideImg10.png"
                  title="Use different segments"
                  alt="Send push notifications for ios-old/ios-new/android separately"
                  style={styles.img}
                />
              </div>
              <div style={styles.imgTextContainer}>
                <div style={styles.sideDiv}>
                  {`메세지의 랜딩 페이지가 하트잇 앱인 경우,
                    앱이 구동되려면 heartit://으로 시작하는
                    딥링크(Deep Link)를 써야 합니다.\n
                    예전 앱에는 딥링크 설정이 되어있지 않으므로
                    세그먼트를 나눠서 보내주세요.
                    => iOS-new, iOS-old, Android 사용\n
                    랜딩 페이지가 하트잇 앱이 아닌 경우에는
                    (=하트잇 샵/일반 URL/인스타그램 주소 등)
                    세그먼트를 나눌 필요 없이 한번에 보낼 수
                    있습니다.
                  `}
                </div>
              </div>
            </div>

            <Typography variant="title">[메세지 적기]</Typography>
            <div style={styles.div}>
              {`
                (이미지 생략) 2번 Message 항목에서 Push로 보내고자 하는 메세지를 적어줍니다.
                Android와 iOS 기종에서 메세지가 어떻게 보이는지 우측에서 이미지로 확인할 수 있습니다.
              `}
            </div>

            <Typography variant="title">[기종 별 메세지 설정]</Typography>
            <div style={styles.collageContainer}>
              <div style={styles.imgContainer}>
                <img
                  src="https://cdn.heartit.kr/img/r2/admin/os_guideImg13.png"
                  title="Notification settings for iOS"
                  alt="Notification settings for iOS"
                  style={styles.img}
                />
              </div>
              <div style={styles.imgTextContainer}>
                <div style={styles.sideDiv}>
                  {`3번 Options 항목에서 필요 시\n기종별로 메세지 설정을 해 줄 수 있습니다.
                    보통 다른 설정은 그대로 두고 iOS 설정에서 BADGES만 Set to로 두고 있습니다.`}
                </div>
              </div>
            </div>

            <Typography variant="title">[Launching URL 설정]</Typography>
            <div style={styles.collageContainer}>
              <div style={styles.imgContainer}>
                <img
                  src="https://cdn.heartit.kr/img/r2/admin/os_guideImg14.png"
                  title="Deep link url example"
                  alt="Deep link url example"
                  style={styles.img}
                />
                <img
                  src="https://cdn.heartit.kr/img/r2/admin/os_guideImg15.png"
                  title="Url example"
                  alt="Url example"
                  style={styles.img}
                />
                <img
                  src="https://cdn.heartit.kr/img/r2/admin/os_guideImg16.png"
                  title="Mobile url example"
                  alt="Mobile url example"
                  style={styles.img}
                />
              </div>
              <div style={styles.imgTextContainer}>
                <div style={styles.sideDiv}>
                  {`iOS-new나 android 세그먼트의 폰은 딥링크 URL을 걸어주세요.\n
                    iOS-old는 하트잇 웹의 URL을 걸어주세요.\n
                    하트잇 샵 등 일반 URL은
                    m.으로 시작하는 모바일 URL이 있는 경우
                    반드시 모바일 URL을 걸어줘야 합니다.
                  `}
                </div>
              </div>
            </div>

            <div style={styles.div}>
              {`트랙킹 링크를 붙여줘야 하는 경우, 혹은 URL이 확실하지 않을 때에는 개발팀에 문의 바랍니다.
              `}
            </div>

            <Typography variant="title">[발송 스케쥴링]</Typography>
            <div style={styles.div}>
              {`
                (이미지 생략) 4번 Schedule 항목에서는 메세지 발송시간과 방법을 선택할 수 있습니다.
                필요 시 우측에 보이는 설명을 보고 적절히 설정해주면 됩니다. 
              `}
            </div>
          </CardItem>
          <CardItem
            title="Push Message 클릭율 확인하기"
            subheader="마지막 업데이트: 2019-07-02"
            style={styles.cardItem}
            headerCloseButton
          >
            <div style={styles.div}>
              <Typography variant="body2" style={styles.imgTitle}>
                <Tooltip title="해당 페이지 바로 열기">
                  <a
                    href="https://app.onesignal.com/apps/e5dfe991-396b-4da7-ac09-a41cc3d22431/campaigns"
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    OneSignal Messages
                  </a>
                </Tooltip>
              </Typography>
              <img
                src="https://cdn.heartit.kr/img/r2/admin/os_guideImg3.png"
                title="OneSignal Messages"
                alt="OneSignal Messages"
                style={styles.horizontalImg}
              />
              <div style={styles.div}>
                {`
                  발송한 Push 메세지 별 총 발송 건수(Sent)와 발송 건수 대비 클릭율(Clicked)을 확인할 수 있습니다.
                `}
              </div>
              <Typography variant="body2" style={styles.imgTitle}>
                <Tooltip title="해당 페이지 바로 열기">
                  <a
                    href="https://app.onesignal.com/apps/e5dfe991-396b-4da7-ac09-a41cc3d22431/notifications"
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    OneSignal Delivery
                  </a>
                </Tooltip>
              </Typography>
              <img
                src="https://cdn.heartit.kr/img/r2/admin/os_guideImg5.png"
                title="OneSignal Delivery"
                alt="OneSignal Delivery"
                style={styles.horizontalImg}
              />
              <div style={styles.div}>
                {`
                  개별 메세지에 대한 여러 정보를 확인할 수 있습니다.
                  각 메세지의 DELIVERY 위에 마우스를 위치시키면 Delivery Statistics를 알 수 있습니다.
                `}
              </div>
              <Typography variant="body2" style={styles.imgTitle}>
                {'OneSignal Message Report\n'}
                <a
                  href="https://app.onesignal.com/apps/e5dfe991-396b-4da7-ac09-a41cc3d22431/notifications/3191217d-771e-4d34-8f4a-ca53ee85e796"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  메세지 상세 페이지 예시
                </a>
              </Typography>
              <img
                src="https://cdn.heartit.kr/img/r2/admin/os_guideImg6.png"
                title="OneSignal Message Report"
                alt="OneSignal Message Report"
                style={styles.horizontalImg}
              />
              <div style={styles.div}>
                {`
                  Messages탭이나 Delivery탭에서 개별 메세지를 눌렀을때 보이는 페이지입니다.
                  (개별 메세지 우측 OPTIONS의 View로도 열람 가능)\n
                  실시간으로 몇 명이 Push 메세지를 열어봤는지, 혹은 최근 2분(120초)/30분/하루(24시간)/5일간
                  메세지를 확인한 사람의 수를 그래프로 확인할 수 있습니다.\n
                  Delivery Statistics와 Conversion Statistics(클릭율)를 확인할 수 있습니다.
                `}
              </div>
            </div>
          </CardItem>
        </PanelItem>

        <PanelItem
          defaultExpanded={false}
          expandIcon={<ExpandMoreIcon />}
          header="Kibana"
          headerUrl="http://kibana.heartit.kr/_plugin/kibana/app/kibana#/dashboard/6da4abc0-76d9-11e9-bbf7-abe204ab700f?_g=()"
          tooltip="Kibana 대시보드 바로가기"
        >
          <Typography variant="body2" style={styles.note}>
            Kibana의 모든 페이지는 보안 상의 이유로 사내에서만 접근 가능합니다!
          </Typography>
          <Typography variant="body2" style={styles.note}>
            <a
              href="http://kibana.heartit.kr/_plugin/kibana/app/kibana#/dashboard/6da4abc0-76d9-11e9-bbf7-abe204ab700f?_g=()"
              target="blank"
              rel="noopener noreferrer"
            >
              Kibana 대시보드 바로가기
            </a>
          </Typography>
          <CardItem
            title="Discover - Search Query 예시"
            subheader="마지막 업데이트: 2019-07-03"
            style={styles.cardItem}
            headerCloseButton
          >
            <div style={styles.linksDiv}>
              {'참고자료: \n'}
              <a
                href="https://www.elastic.co/guide/en/elasticsearch/reference/6.7/query-dsl-query-string-query.html#query-string-syntax"
                target="blank"
                rel="noopener noreferrer"
              >
                Kibana Query 관련 Documentation 보기 (Query string syntax부터 아래로 보시면 됩니다.)
              </a>
            </div>
            <div style={styles.linksDiv}>
              {'1) 2019년 7월 2일 자 앱에서의 모든 아이템 클릭을 검색: '}
              <a
                href="http://kibana.heartit.kr/_plugin/kibana/app/kibana#/discover?_g=()&_a=(columns:!(_source),index:ad4a71b0-9e20-11e9-adae-6700c34d26c1,interval:auto,query:(language:lucene,query:'kst:+2019-07-02+AND+agent:+app+AND+where:+post'),sort:!(_score,desc))"
                target="blank"
                rel="noopener noreferrer"
              >
                예시 바로 보기
              </a>
              <Typography variant="body1" style={styles.redText}>
                주의: 날짜 검색은 반드시 kst로 해 주세요.
              </Typography>
            </div>
            <div style={styles.linksDiv}>
              {'2) 2019년 6월 26일부터 7월 2일 사이 웹에서의 모든 배너 클릭을 검색: '}
              <a
                href="http://kibana.heartit.kr/_plugin/kibana/app/kibana#/discover?_g=()&_a=(columns:!(_source),index:ad4a71b0-9e20-11e9-adae-6700c34d26c1,interval:auto,query:(language:lucene,query:'kst:+%5B2019-06-26+TO+2019-07-02%5D+AND+agent:+web+AND+where:+banner'),sort:!(_score,desc))"
                target="blank"
                rel="noopener noreferrer"
              >
                예시 바로 보기
              </a>
            </div>
            <div style={styles.linksDiv}>
              {'3) 앱과 웹에서 포스트 아이디가 5cf6364693e9794718ae4b85인 포스트 클릭을 검색: \n'}
              <a
                href="http://kibana.heartit.kr/_plugin/kibana/app/kibana#/discover?_g=()&_a=(columns:!(_source),index:ad4a71b0-9e20-11e9-adae-6700c34d26c1,interval:auto,query:(language:lucene,query:'post:+5cf6364693e9794718ae4b85'),sort:!(_score,desc))"
                target="blank"
                rel="noopener noreferrer"
              >
                예시 1 바로 보기
              </a>
              {' | '}
              <a
                href="http://kibana.heartit.kr/_plugin/kibana/app/kibana#/discover?_g=()&_a=(columns:!(_source),index:ad4a71b0-9e20-11e9-adae-6700c34d26c1,interval:auto,query:(language:lucene,query:'post:+5cf6364693e9794718ae4b85+AND+(agent:+app+OR+web)'),sort:!(_score,desc))"
                target="blank"
                rel="noopener noreferrer"
              >
                예시 2 바로 보기
              </a>
              {' | '}
              <a
                href="http://kibana.heartit.kr/_plugin/kibana/app/kibana#/discover?_g=()&_a=(columns:!(_source),index:ad4a71b0-9e20-11e9-adae-6700c34d26c1,interval:auto,query:(language:lucene,query:'post:+5cf6364693e9794718ae4b85+AND+(agent:+*)'),sort:!(_score,desc))"
                target="blank"
                rel="noopener noreferrer"
              >
                예시 3 바로 보기
              </a>
              <Typography variant="body1" style={styles.redText}>
                주의: agent, post, where 등에는 unknown이나 unspecified라는 값이 있을 수 있습니다.
              </Typography>
              <Typography variant="body1">
                {`
                예시 3의 agent: *에서 "*"는 agent의 모든 값을 포함하여 검색합니다.
                위의 검색 예시에서는 agent: unknown인 경우와 agent가 기록되지 않은 경우가 없기 때문에
                예시 1, 2, 3의 값이 동일하게 나옵니다.\n
                `}
              </Typography>
              <div style={styles.linksDiv}>
                {'4) 안드로이드 폰에서 (웹/앱/unknown 모두 포함) 1700번 에디토리얼 '}
                <span style={styles.underlinedText}>안에서</span>
                {' 일어난 클릭을 검색: \n'}
                <a
                  href="http://kibana.heartit.kr/_plugin/kibana/app/kibana#/discover?_g=()&_a=(columns:!(_source),index:ad4a71b0-9e20-11e9-adae-6700c34d26c1,interval:auto,query:(language:lucene,query:'ua.os.name:+Android+AND+editorial:+1700'),sort:!(_score,desc))"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  예시 바로 보기
                </a>
                <Typography variant="body2">
                  예시 이외의 다른 방식으로도 같은 결과를 검색할 수 있습니다.
                </Typography>
              </div>
            </div>
          </CardItem>
          <CardItem
            title="Dashboard 관련 안내"
            subheader="마지막 업데이트: 2019-07-03"
            style={styles.cardItem}
            headerCloseButton
          >
            <Typography variant="body2" style={styles.imgTitle}>
              <a
                href="http://kibana.heartit.kr/_plugin/kibana/app/kibana#/dashboard/6da4abc0-76d9-11e9-bbf7-abe204ab700f?_g=()"
                target="blank"
                rel="noopener noreferrer"
              >
                Kibana 대시보드 바로가기
              </a>
            </Typography>
            <div style={styles.linksDiv}>
              {`Daily Active Users(DAU)는 매일 Slack의 #stats 채널에
              다른 몇가지 지표와 함께 업데이트 되고 있습니다.
              Kibana의 Dashboard에서 보여지고 있는 Unique User (that clicked) / day는`}
              <div style={styles.underlinedText}>Daily Clicked Users (DCU)로,</div>
              {'하트잇 서비스에 로그인 한 후 한번이라도 클릭한 사람을 다시 집계한 것이니 참고바랍니다.'}
            </div>
          </CardItem>
        </PanelItem>
      </div>
    );
  }
}
