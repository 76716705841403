import createFetchAction from '../util/createFetchAction';


function logAppRawEvent({ deviceId, event, os, userId = null, data = null }) {
  return createFetchAction({
    doDispatch: false,
    type: 'ADMIN_LOG_EVENT',
    endpoint: `/api/v2/raw-events`,
    method: 'POST',
    body: {
      deviceId,
      event,
      os,
      userId,
      data
    },
  });
}

function logActiveUserEvent({ deviceId, os, userId = null }) {
  return logAppRawEvent({ deviceId, event: 'active', os, userId });
}


function logPageviewEvent({ deviceId, os, data, userId=null }) {
  return logAppRawEvent({ deviceId, event: 'pageview', os, userId, data });
}


function logAddToFavoriteEvent({ deviceId, os, data, userId }) {
  return logAppRawEvent({ deviceId, event: 'add_to_favorite', os, userId, data });
}


function logEvent({ eventType, data={} }) {
  return createFetchAction({
    doDispatch: false,
    type: 'LOG_EVENT',
    endpoint: `/api/v2/stats`,
    method: 'POST',
    body: {
      eventType,
      ...data,
    },
  });
}


function logEventPageview({ page_name, page_key=null }) {
  return logEvent({
    eventType: 'pageview',
    data: {
      page_name,
      page_key,
    },
  });
}


function logEventClick({ page_name, page_key=null, click_object_name,
  click_object_key=null }) {
  return logEvent({
    eventType: 'click',
    data: {
      page_name,
      page_key,
      click_object_name,
      click_object_key,  
    },
  });
}


export {
  logActiveUserEvent,
  logPageviewEvent,
  logAddToFavoriteEvent,
  logEventPageview,
  logEventClick,
}
