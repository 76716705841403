import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from 'components/Spinner';
import { loadEntities } from 'react-shared';
import styled from 'styled-components';
import * as adminActions from 'react-shared/adminActions';
import { Link } from 'react-router';

import { SizeData, SortingRule } from '../../../constants';

import NotificationList from '../../../components-v2/Admin/NotificationList';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';

import Media from 'components/Media';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import moment from 'moment';
import 'moment/locale/ko';

moment.locale('ko');

const styles = {
  container: {
    position:'relative',
    margin: '0 auto',
    width: '100%',
    maxWidth: '1200px',
  },
  add_link :{
    position:'absolute',
    right:'0px',
    top:'24px'
  },
  add_button :{
    fontSize:'16px'
  },
  top_header:{
    position:'relative',
    width:'100%',
    marginBottom:'24px'
  },
  top_container:{
    position:'relative',
    width:'100%',
    padding:'18px 0px',
  },
  top_container_box:{
    border:'1px solid #aaaaaa',
    borderRadius:'6px',
    padding:'12px'
  },
  top_header_title : {
    fontSize:'14px',
    color:'#444444'
  },
  top_header_value : {
    fontSize:'17px',
    fontWeight:'900',
    color:'#444444'
  },
  thumbnailArea: {
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'table-cell',
  },
  headerType : {
    fontSize:'14px',
    color:'#444444',
    marginBottom:'4px'
  },
  headerTitle : {
    fontSize:'18px',
    color:'#222222',
    marginBottom:'4px'
  },
  headerDescription : {
    fontSize:'14px',
    color:'#444444',
    whiteSpace:'pre-line'
  },
  headerId : {
    fontSize:'14px',
    color:'#666666'
  },
  visible:{
    position:'absolute',
    top:'8px',
    left:'8px'
  },
  listTitle : {
    fontSize:'20px',
    color:'#222222',
    marginBottom:'8px',
    marginTop:'18px'
  },
  listDescription : {
    fontSize:'13px',
    color:'#777777',
    marginBottom:'18px',
  },
  listSubTitle : {
    fontSize:'14px',
    color:'#444444',
    marginBottom:'12px',
    marginTop:'12px'
  },
  openButton : {
    position:'absolute',
    top:'24px',
    right:'24px'
  },
  drawerContainer : {
    position:'relative',
    padding:'24px',
    width:'320px',
  }
};

const sortByList = SortingRule.SORTING_RULE_EXHIBITION;

const ThumbnailListContainer = styled.div`
  position:relative;
  width:180px;
  &:after {
    clear: both;
    content: "";
    display: table;
  }
`;

export class MDCurationPushPage extends Component {

  constructor(props){
    super(props);

    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.toggleEditDrawer = this.toggleEditDrawer.bind(this);
    this.openNewNotification = this.openNewNotification.bind(this);

    this.handleNewTitle = this.handleNewTitle.bind(this);
    this.handleNewDescription = this.handleNewDescription.bind(this);
    this.handleNewTime = this.handleNewTime.bind(this);

    this.handleEditTitle = this.handleEditTitle.bind(this);
    this.handleEditDescription = this.handleEditDescription.bind(this);
    this.handleEditTime = this.handleEditTime.bind(this);

    this.handleNewInitialize = this.handleNewInitialize.bind(this);
    this.handleNewNotificationUpload = this.handleNewNotificationUpload.bind(this);

    this.handleEditInitialize = this.handleEditInitialize.bind(this);
    this.handleEditNotificationUpload = this.handleEditNotificationUpload.bind(this);

    this.handleRemoveNotification = this.handleRemoveNotification.bind(this);

    this.state = {
      type: 'pre-order',
      title: '',
      subtitle: '',
      description: '',
      thumbnailList: [],
      thumbnailAspectRatio: 1,
      visible: true,
      isUpdatingPost: false,
      // Filter
      category: 'none',
      categoryList: [],
      sortByValue: sortByList[0].value,
      sortByList,
      brandList: [],
      colorList: [],
      filterList: [],
      newFilterList: [],
      // Products
      products: [],
      checkedProducts: {},
      newCheckedProducts: {},
      isProductDialogOpen: false,
      drawerOpen: false,
      editDrawerOpen : false,
      newTitle : "",
      newDescription : "",
      newTime : moment(),
      editTitle : "",
      editDescription : "",
      editTime : moment(),
      editNotificationId : "",
      notificationList:[]
    };

  }
  async componentDidMount() {
    const { mdCurationId, mdCuration, adminGetMdCuration, adminLoadNotifications } = this.props;

    if (mdCurationId !== 'new') {
      if (mdCuration) {
        const checkedProducts = {};
        mdCuration.products.forEach((product) => {
          checkedProducts[product._id] = product;
        });
        await this.setState({
          ...mdCuration,
          checkedProducts,
          sortByValue: mdCuration.sortBy.value,
        });
      } else {
        await adminGetMdCuration(mdCurationId)
          .then(mdCurationData => {
            const checkedProducts = {};
            mdCurationData.products.forEach((product) => {
              checkedProducts[product._id] = product;
            });
            this.setState({
              ...mdCurationData,
              checkedProducts,
              sortByValue: mdCurationData.sortBy.value,
            });
            console.log(mdCurationData);
          });
        // Todo: route to admin/posts when there's no post with such mdCurationId
      }

      await adminLoadNotifications({
        type : "exhibition",
        key : mdCurationId
      }).then((el) => {
        this.setState((prev) => ({notificationList : el}));
      });

    }
  }

  openNewNotification(event){
    this.toggleDrawer(event, true);
  }

  toggleDrawer(event, open) {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({ drawerOpen: open });
  };

  toggleEditDrawer(event, open) {
    this.setState({ editDrawerOpen: open });
  };

  handleNewTitle(event){
    this.setState({ newTitle: event.target.value });
  }

  handleNewDescription(event){
    this.setState({ newDescription: event.target.value });
  }

  handleNewTime(value){
    this.setState({ newTime: value });
  }

  handleEditTitle(event){
    this.setState({ editTitle: event.target.value });
  }

  handleEditDescription(event){
    this.setState({ editDescription: event.target.value });
  }

  handleEditTime(value){
    this.setState({ editTime: value });
  }

  handleNewInitialize(){
    this.setState({
      newTitle : "",
      newDescription : "",
      newTime : moment()
    });
  }

  handleEditInitialize(){
    const { adminGetNotification } = this.props;
    const { editNotificationId } = this.state;
    if(editNotificationId && editNotificationId.length > 0){
      adminGetNotification(notification)
      .then((el) => {
        this.setState({
          editTitle : el.title,
          editDescription : el.description,
          editTime : moment(el.sentAt)
        });
      });
    }
  }

  handleEditNotificationUpload(){
    const {
      editNotificationId,
      editTitle,
      editDescription,
      editTime } = this.state;
    const { adminUpdateNotification, mdCurationId, adminLoadNotifications  } = this.props;
    if(editNotificationId && editNotificationId.length > 0){
      adminUpdateNotification({
        notificationId : editNotificationId,
        notification :  {
          title : editTitle,
          description : editDescription,
          sendAt : editTime,
          payload:{type : "exhibition_user_open", id : mdCurationId},
        }
      }).then(() => {
        adminLoadNotifications({
          type : "exhibition",
          key : mdCurationId
        }).then((el) => {
          this.setState((prev) => ({notificationList : el}));
        });
      }).finally(() => {
        this.setState({
          editTitle : "",
          editDescription : "",
          editTime : moment(),
          editDrawerOpen: false
        });
      });
    }
  }

  handleNewNotificationUpload(){
    const { newTitle, newDescription, newTime, thumbnailList } = this.state;
    const { adminCreateNotification, mdCurationId, adminLoadNotifications  } = this.props;
    adminCreateNotification({
      notification:{
        type : "exhibition",
        key : mdCurationId,
        title : newTitle,
        description : newDescription,
        thumbnailUrl : (thumbnailList.length > 0 ? thumbnailList[0].thumbnailUrl : ""),
        payload:{type : "exhibition_user_open", id : mdCurationId},
        sendAt : newTime
      }
    }).then(() => {
      adminLoadNotifications({
        type : "exhibition",
        key : mdCurationId
      }).then((el) => {
        this.setState((prev) => ({notificationList : el}));
      });
    }).finally(() => {
      this.setState({
        newTitle : "",
        newDescription : "",
        newTime : moment(),
        drawerOpen: false
      });
    });
  }

  handleRemoveNotification(notification){
    const { adminDeleteNotification, adminLoadNotifications, mdCurationId } = this.props;
    adminDeleteNotification({notificationId:notification})
    .then(() => {

    }).finally(() => {
      adminLoadNotifications({
        type : "exhibition",
        key : mdCurationId
      }).then((el) => {
        this.setState((prev) => ({notificationList : el}));
      });
    });
  }

  openEditNotification(notification){
    const { adminGetNotification } = this.props;

    this.toggleEditDrawer(null, true);

    adminGetNotification(notification)
    .then((el) => {
      this.setState({
        editNotificationId:notification,
        editTitle : el.title,
        editDescription : el.description,
        editTime : moment(el.sentAt)
      });
    });
  }

  render() {
    const {
      mdCuration,
      mdCurationId,
      pagination: { isFetching },
    } = this.props;

    const {
      thumbnailList,
      thumbnailAspectRatio,
      visible,
      title,
      description,
      type,
      drawerOpen,
      editDrawerOpen,
      newTitle,
      newDescription,
      newTime,
      editTitle,
      editDescription,
      editTime,
      notificationList
    } = this.state;

    return (
      <div style={styles.container}>
        <Container sx={styles.top_container}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}>
          {thumbnailList.length > 0 && <ThumbnailListContainer>
            <Media
              media={thumbnailList[0]}
              options={{ width: 250, height: 250, crop: 'limit' }}
              style={{ paddingTop: `${1 / thumbnailAspectRatio * 100}%` }}
            />
            {visible && <Chip sx={styles.visible} label="활성화" color="primary" />}
          </ThumbnailListContainer>}
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}>
            <Typography sx={styles.headerType}>
              {(type === "curation") ? "기획전" :
              (type === "pre-order") ? "프리오더" :
              (type === "special-price") ? "특가전" : "기타"}
            </Typography>
            <Typography sx={styles.headerTitle}>
              {title}
            </Typography>
            <Typography sx={styles.headerDescription}>
              {description}
            </Typography>
            <Typography sx={styles.headerId}>
              {mdCurationId}
            </Typography>
          </Stack>
        </Stack>
        <Typography sx={styles.listTitle}>
          푸시 알림 리스트
        </Typography>
        <Typography sx={styles.listDescription}>
          * 아직 대기중인 알림은 분홍색, 진행중인 알림은 초록색, 마무리된 알림은 회색으로 표기됩니다.
        </Typography>

        <NotificationList
          mdCurationId={mdCurationId}
          pushNotifications={notificationList}
          adminDeleteNotification={this.handleRemoveNotification}
          adminEditNotification={(pushNotificationId) => this.openEditNotification(pushNotificationId)}/>
        </Container>

        <Drawer
          anchor={"right"}
          open={drawerOpen}
          onClose={(event) => this.toggleDrawer(event,false)}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box
            sx={styles.drawerContainer}
            role="presentation">
            <Typography sx={styles.listTitle}>
              새 푸시 추가하기
            </Typography>
            <Typography sx={styles.listSubTitle}>
              제목
            </Typography>
            <TextField
              id="outlined-basic"
              label="푸시 제목"
              value={newTitle}
              onChange={this.handleNewTitle}
              sx={{width:'100%'}}
              variant="outlined" />
            <Typography sx={styles.listSubTitle}>
              푸시 내용
            </Typography>
            <TextField
              id="outlined-multiline-static"
              label="푸시 내용"
              sx={{width:'100%'}}
              multiline
              placeholder="푸시 내용을 입력해주세요"
              rows={4}
              value={newDescription}
              onChange={this.handleNewDescription}/>
            <Typography sx={styles.listSubTitle}>
              전송 시간
            </Typography>
            <DateTimePicker
              sx={{
                width:'100%',
                marginBottom:'24px'
              }}
              value={newTime}
              onChange={this.handleNewTime}
              label="전송 시간" />
            <Stack
              direction="row"
              sx={{width:'100%'}}
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}>
              <Button
                onClick={this.handleNewInitialize}
                variant="text">초기화</Button>
              <Button
                onClick={this.handleNewNotificationUpload}
                variant="outlined">등록하기</Button>
            </Stack>
          </Box>
          </LocalizationProvider>
        </Drawer>

        <Drawer
          anchor={"right"}
          open={editDrawerOpen}
          onClose={(event) => this.toggleEditDrawer(event,false)}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box
            sx={styles.drawerContainer}
            role="presentation">
            <Typography sx={styles.listTitle}>
              기존 푸시 수정하기
            </Typography>
            <Typography sx={styles.listSubTitle}>
              제목
            </Typography>
            <TextField
              id="outlined-basic"
              label="푸시 제목"
              value={editTitle}
              onChange={this.handleEditTitle}
              sx={{width:'100%'}}
              variant="outlined" />
            <Typography sx={styles.listSubTitle}>
              푸시 내용
            </Typography>
            <TextField
              id="outlined-multiline-static"
              label="푸시 내용"
              sx={{width:'100%'}}
              multiline
              placeholder="푸시 내용을 입력해주세요"
              rows={4}
              value={editDescription}
              onChange={this.handleEditDescription}/>
            <Typography sx={styles.listSubTitle}>
              전송 시간
            </Typography>
            <DateTimePicker
              sx={{
                width:'100%',
                marginBottom:'24px'
              }}
              value={editTime}
              onChange={this.handleEditTime}
              label="전송 시간" />
            <Stack
              direction="row"
              sx={{width:'100%'}}
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}>
              <Button
                onClick={this.handleEditInitialize}
                variant="text">초기화</Button>
              <Button
                onClick={this.handleEditNotificationUpload}
                variant="outlined">수정하기</Button>
            </Stack>
          </Box>
          </LocalizationProvider>
        </Drawer>
        <Button
          sx={styles.openButton}
          onClick={this.openNewNotification}
          variant="outlined">새 푸시 추가</Button>
      </div>
    );
  }
}

MDCurationPushPage.propTypes = {
  mdCurationId: PropTypes.string.isRequired,
  router: PropTypes.object.isRequired,
  //mdCuration: PropTypes.object.isRequired,
  //adminLoadMdCurations: PropTypes.func.isRequired,
  //adminMdCurations: PropTypes.array,
  // pagination: PropTypes.object,
};

MDCurationPushPage.defaultProps = {
  adminMdCurations: {},
  //mdCuration:{}
};

export const mapStateToProps = ( state, ownProps ) => ({
  mdCurationId: ownProps.params.mdCurationId,
  mdCuration: state.entities.mdCurations[ownProps.params.mdCurationId],
  ...loadEntities(state, 'adminNotifications', 'adminNotifications'),
});

export default connect(
  mapStateToProps,
  adminActions,
)(MDCurationPushPage);
