import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { loadEntities } from 'react-shared';
import * as adminActions from 'react-shared/adminActions';

import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import Media from 'components/Media';


const styles = {
  container: {
    border: '5px solid #fff',
    padding: '5px 15px',
    textAlign: 'center',
  },
  headerContainer: {
    height: '40px',
    marginBottom: '12.5px',
    width: '100%',
  },
  headerLeft: {
    display: 'block',
    position:'relative',
    height: '84px',
    marginBottom: '12.5px',
    marginTop: '12.5px',
    textAlign: 'left',
    width: '100%'
  },
  headerImage: {
    borderRadius: '50%',
    height: '40px',
    width: '40px',
  },
  username: {
    color: 'black',
    fontFamily: 'Avenir Next',
    fontSize: '16px',
    marginLeft: '7.5px',
  },
  headerRight: {
    display: 'block',
    position:'relative',
    height: '72px',
    // marginBottom: '12.5px',
    textAlign: 'left',
    width: '100%',
  },
  date: {
    color: '#909090',
    fontSize: '13px',
  },
  dateSuffix: {
    color: '#909090',
    fontSize: '8px',
    lineHeight: '36px',
    verticalAlign: 'top',
  },
  thumbnailImage: {
    width: 250,
    height: 250,
    crop: 'limit',
  },
  abstract: {
    color: 'black',
    fontSize: '10px',
    textAlign: 'left',
    textDecoration: 'none',
  },
  link: {
    color: 'black',
    textAlign: 'right',
  },
  arrow: {
    height: '7px',
    marginLeft: '5px',
    width: '15px',
  },
  offline: {
    color: '#000080',
  },
  online: {
    color: '#FF0000',
  },
  visible:{
    position:'absolute',
    top:'8px',
    left:'8px'
  },
  opened:{
    position:'absolute',
    top:'8px',
    right:'8px'
  },
  headerTab:{
    fontSize:'12px',
    color:'#666666'
  },
  headerTitle : {
    fontSize:'18px',
    color:'#222222'
  },
  headerId : {
    fontSize:'14px',
    color:'#666666'
  },
  headerDate : {
    fontSize:'14px',
    color:'#666666'
  },
  buttonGroup:{
    position:'relative',
    marginBottom:"8px",
    width:'100%'
  }
};

const ThumbnailListContainer = styled.div`
  position:relative;
  &:after {
    clear: both;
    content: "";
    display: table;
  }
`;

// export default
class PreorderExhibition extends Component {
  static renderEditButton(mdCurationId) {
    return (
      <Link to={`/admin/preorderExhibitions/${mdCurationId}`}>
        <Button
          variant="outlined" startIcon={<EditIcon />}>
          수정하기
        </Button>
      </Link>
    );
  }

  renderDeleteButton(mdCurationId) {
    return (
      <Button
        sx={{height:"32px"}}
        color="secondary"
        variant="outlined" startIcon={<DeleteIcon />}
        onClick={
          () => confirm('해당 프리오더를 삭제하시겠습니까?') && this.props.adminDeleteMdCuration(mdCurationId) //eslint-disable-line
        }>
        삭제하기
      </Button>
    );
  }

  renderCaption(mdCurationId) {

    return (
      <Stack
        sx={styles.buttonGroup}
        direction="row" spacing={1}>
        {PreorderExhibition.renderEditButton(mdCurationId)}
        {this.renderDeleteButton(mdCurationId)}
      </Stack>
    );
  }

  static renderDate(date) {
    if (!date) {
      return '없음';
    }
    return dayjs(date).format('YYYY-MM-DD (HH:mm)');
  }

  render() {
    const {
      isSortable,
      preorderExhibition: {
        id,
        title,
        description,
        thumbnailList,
        thumbnailAspectRatio,
        preorderStartDate,
        preorderEndDate,
        created,
        visible,
        fromNow
      },
    } = this.props;
    // const convertedUrl = getChangedUrl(url, window.location.protocol, window.location.host, null, {
    //   utm_source: window.location.hostname,
    //   utm_medium: 'heartit-mdCuration-list',
    //   utm_campaign: 'heartit-mdCuration-list',
    //   agent: 'web',
    // });
    return (
      <Grid item xs={12} sm={6} md={4} lg={3}
        sx={{
          paddingTop:isSortable && "16px",
          paddingLeft:isSortable && "16px"
        }}>
        {isSortable &&
          <div className={"handle"}>
            <OpenWithIcon />
          </div>
        }
        <ThumbnailListContainer>
          <Media
            media={thumbnailList[0]}
            options={{ width: 250, height: 250, crop: 'limit' }}
            style={{ paddingTop: `${1 / thumbnailAspectRatio * 100}%`, opacity : !visible && 0.5  }}
          />
          {visible && <Chip sx={styles.visible} label="활성화" color="primary" />}
          {!isSortable && ((fromNow === 1) ? <Chip sx={styles.opened} label="오픈예정" color="info" /> :
          (fromNow === -1) ? <Chip sx={styles.opened} label="종료" color="secondary" /> :
                            <Chip sx={styles.opened} label="활성화" color="success" />)}
        </ThumbnailListContainer>
        <div style={styles.headerLeft}>
          <Typography sx={styles.headerTitle}>
            {title}
          </Typography>
          <Typography sx={styles.headerId}>
            {id}
          </Typography>
          <Typography sx={styles.headerDate}>
            {PreorderExhibition.renderDate(created)}
          </Typography>
          <Typography sx={styles.headerTab}>
            {PreorderExhibition.renderDate(preorderStartDate * 1000)} ~ {PreorderExhibition.renderDate(preorderEndDate * 1000)}
          </Typography>
        </div>
        {!isSortable &&
          <div style={styles.headerRight}>
          {this.renderCaption(id)}
          <Link to={`/admin/preorderExhibitions/push/${id}`}>
            <Button variant="text">푸시 알림 관리</Button>
          </Link>
        </div>}
      </Grid>
    );
  }
}

PreorderExhibition.propTypes = {
  preorderExhibition: PropTypes.object.isRequired,
};

export const mapStateToProps = state => ({
  ...loadEntities(state, 'preorderExhibitions', 'preorderExhibitions'),
});

export default connect(
  mapStateToProps,
  adminActions,
)(PreorderExhibition);
