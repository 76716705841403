import { normalize } from 'normalizr';
import qs from 'qs';
import get from 'lodash/get';
import merge from 'lodash/merge';

import * as schemas from '../schemas';
/* eslint-disable import/prefer-default-export */
import createFetchAction from '../util/createFetchActionWithShopAuth';


export function createOrder(itemIndexList) {
  return createFetchAction({
    type: 'CREATE_SHOP_ORDER',
    method: 'POST',
    endpoint: '/api/v2/shop/order',
    body: {
      cartIdx: itemIndexList,
    },
    doDispatch: false,
  });
}


export function getPromotionCouponList(itemIndexList) {
  return createFetchAction({
    type: 'GET_SHOP_ORDER_PROMOTION_COUPON_LIST',
    method: 'POST',
    endpoint: '/api/v2/shop/order/promo/coupon',
    body: {
      cartSno: itemIndexList,
    },
    doDispatch: false,
  });
}


export function applyPromotion(params) {
  return createFetchAction({
    type: 'APPLY_SHOP_ORDER_PROMOTION',
    method: 'POST',
    endpoint: '/api/v2/shop/order/promo/apply',
    body: params,
    doDispatch: false,
  });
}


export function checkoutOrder(params) {
  return createFetchAction({
    type: 'CHECKOUT_SHOP_ORDER',
    method: 'POST',
    endpoint: '/api/v2/shop/order/checkout',
    body: params,
    doDispatch: false,
  });
}

export function getUserOrderList(nextPage, page, query) {
  const key = 'userOrderList';
  const base = `/api/v2/shop/user/order?${qs.stringify(query)}`;

  return createFetchAction({
    type: 'GET_USER_ORDER_LIST',
    method: 'GET',
    endpoint: state => {
      const next = base + '&page=' + page;
      return nextPage ? next : base;
    },
    transform: ({ json }) => {
      if (json.success !== true) {
        return {};
      }

      const result = {};

      Object.assign(result, normalize(json.data.orderData, schemas.userOrderList));

      let nextPageUrl = null;
      try {
        if (parseInt(json.data.page.now, 10) < parseInt(json.data.page.end, 10)) {
          const newNextPage = parseInt(json.data.page.now, 10) + 1;
          nextPageUrl = base + '&page=' + newNextPage;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      Object.assign(result, { nextPageUrl });
      return result;
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}

export function getOrderDetail(orderNo) {
  return createFetchAction({
    type: 'GET_ORDER_DETAIL',
    method: 'GET',
    endpoint: `/api/v2/shop/user/order/${orderNo}`,
    transform: ({ json, state }) => {
      if (json.success !== true) {
        return {};
      }

      const result = {};
      // eslint-disable-next-line no-prototype-builtins
      if (state.entities.userOrderList.hasOwnProperty(orderNo)) {
        merge(
          result,
          normalize([json.data], schemas.userOrderList)
        );
      }
      merge(
        result,
        normalize([json.data], schemas.orderDetails)
      );
      return result;
    },
  });
}

export function cancelUserOrder(orderNo) {
  return createFetchAction({
    type: 'CANCEL_USER_ORDER',
    method: 'PUT',
    endpoint: `/api/v2/shop/user/order/${orderNo}/cancel`,
    transform: ({ json }) => {
      // console.log("CANCEL_USER_ORDER", json);
      if (json.success !== true) {
        return {};
      }
      return {};
    },
    request: {
      payload: { isFetching: true },
    },
  });
}

export function settleUserOrder(orderNo, goodsNoList) {
  return createFetchAction({
    type: 'SETTLE_USER_ORDER',
    method: 'PUT',
    endpoint: `/api/v2/shop/user/order/${orderNo}/settle`,
    body: { goodsNoList },
    transform: ({ json }) => {
      if (json.success !== true) {
        return {};
      }
      return {};
    },
    request: {
      payload: { isFetching: true },
    },
  });
}

export function exchangeUserOrder(orderNo, body) {
  return createFetchAction({
    type: 'EXCHANGE_USER_ORDER',
    method: 'PUT',
    endpoint: `/api/v2/shop/user/order/${orderNo}/exchange`,
    body,
    transform: ({ json }) => {
      if (json.success !== true) {
        return {};
      }
      return {};
    },
    request: {
      payload: { isFetching: true },
    },
  });
}

export function returnUserOrder(orderNo, body) {
  return createFetchAction({
    type: 'REFUND_USER_ORDER',
    method: 'PUT',
    endpoint: `/api/v2/shop/user/order/${orderNo}/return`,
    body,
    transform: ({ json }) => {
      if (json.success !== true) {
        return {};
      }
      return {};
    },
    request: {
      payload: { isFetching: true },
    },
  });
}
