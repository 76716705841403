import React from 'react';
import { connect } from 'react-redux';
import { actions } from 'shared';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View, Text } from 'react-native';
import PropTypes from 'prop-types';

import { Constants } from '../../constants';

import ShareDialog from '../../components/ShareDialog';

import {
  HeaderBagIcon,
  HeaderBellIcon,
  HeaderCameraIcon,
  HeaderSearchIcon,
  HeaderShareIcon,
} from '../../icons-v2';

import TouchableHighlightWithContainer from '../TouchableHighlightWithContainer';


const styles = StyleSheet.create({
  container: {
    paddingRight: Constants.viewHorizontalPadding,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: Constants.mainHeaderHeight,
  },
  button: {
    marginStart: 15,
  },
  cartBadge: {
    position: 'absolute',
    top: -6,
    right: -6,
    borderRadius: 10,
    paddingStart: 5,
    paddingRight: 4,
    paddingVertical: 2,
    backgroundColor: '#000',
    alignItems: 'center',
  },
  cartBadgeText: {
    color: '#fff',
    fontSize: 11,
    lineHeight: 13,
    textAlign: 'center',
  },
  alertBadge: {
    position: 'absolute',
    top: -6,
    right: -6,
    borderRadius: 10,
    paddingStart: 5,
    paddingRight: 4,
    paddingVertical: 2,
    backgroundColor: '#000',
    alignItems: 'center',
  },
  alertBadgeText: {
    color: '#fff',
    fontSize: 11,
    lineHeight: 13,
    textAlign: 'center',
  },
});


class HeaderRight extends React.Component {
  state = {
    openShareModal: false,
  }

  handleShareModalClose = () => {
    this.setState({ openShareModal: false });
  }

  onPressShare = () => {
    this.setState({ openShareModal: true });
  }

  render() {
    const {
      containerStyle,
      router,
      showCameraButton,
      showSearchButton,
      showBellButton,
      showCartButton,
      showShareButton,
      darkContent,
      itemListInCart,
      pushHistoryCount,
      product = null,
    } = this.props;

    const buttonColor = (darkContent) ? '#000000' : '#ffffff';
    const cartItemCount = itemListInCart.length > 99 ? '+99' : `${itemListInCart.length}`;
    const cartBadgeWidth = (() => {
      if (cartItemCount.length > 2) {
        return { width: 32 };
      } else if (cartItemCount.length > 1) {
        return { width: 24 };
      } else if (cartItemCount === '0') {
        return { opacity: 0 };
      } else {
        return { width: 18 };
      }
    })();

    const alertCount = pushHistoryCount > 99 ? '+99' : `${pushHistoryCount}`;
    const alertBadgeWidth = (() => {
      if (alertCount.length > 2) {
        return { width: 32 };
      } else if (alertCount.length > 1) {
        return { width: 24 };
      } else if (alertCount === '0') {
        return { opacity: 0 };
      } else {
        return { width: 18 };
      }
    })();

    return (
      <View style={[styles.container, containerStyle]}>
        { showCameraButton && (
          <TouchableHighlightWithContainer
            style={[styles.button]}
            onPress={() => {
              // router.push('');
              router.push('/style/upload');
            }}
          >
            <HeaderCameraIcon color={buttonColor} />
          </TouchableHighlightWithContainer>
        )}

        { showSearchButton && (
          <TouchableHighlightWithContainer
            style={[styles.button]}
            onPress={() => {
              router.push('/shop/search');
            }}
          >
            <HeaderSearchIcon color={buttonColor} />
          </TouchableHighlightWithContainer>
        )}

        { showBellButton && (
          <TouchableHighlightWithContainer
            style={[styles.button]}
            onPress={() => {
              router.push('/push');
            }}
          >
            <View>
              <HeaderBellIcon color={buttonColor} />
              <View style={[styles.alertBadge, alertBadgeWidth]}>
                <Text style={styles.alertBadgeText}>{alertCount}</Text>
              </View>
            </View>
          </TouchableHighlightWithContainer>
        )}

        { showShareButton && (
          <TouchableHighlightWithContainer
            style={[styles.button]}
            onPress={this.onPressShare}
          >
            <HeaderShareIcon color={buttonColor} />
          </TouchableHighlightWithContainer>
        )}

        { showCartButton && ( 
          <TouchableHighlightWithContainer
            style={[styles.button]}
            onPress={() => {
              router.push('/shop/cart');
            }}
          >
            <View>
              <HeaderBagIcon color={buttonColor} />
              <View style={[styles.cartBadge, cartBadgeWidth]}>
                <Text style={styles.cartBadgeText}>{cartItemCount}</Text>
              </View>
            </View>
          </TouchableHighlightWithContainer>
        )}

        {(product && showShareButton) && (
          <ShareDialog
            open={this.state.openShareModal}
            onClose={this.handleShareModalClose}
            product={product}
          />
        )}
      </View>
    );
  }
}


HeaderRight.propTypes = {
  showCameraButton: PropTypes.bool,
  showSearchButton: PropTypes.bool,
  showBellButton: PropTypes.bool,
  showShareButton: PropTypes.bool,
  showCartButton: PropTypes.bool,
  itemListInCart: PropTypes.array,
  pushHistoryCount: PropTypes.object,
};

export default connect(
  (state, { router }) => {
    const { productId = null } = router.params;

    return {
      auth: state.auth,
      product: (productId) ? state.entities.shopProducts[productId]: null,
    };
  },
  actions,
)(HeaderRight);
