import React from 'react';
import {
  StyleSheet, ScrollView, View, Text, Image, Dimensions,
  TextInput,
  TouchableWithoutFeedback, TouchableOpacity,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import get from 'lodash/get';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';
import { br2nl } from '../../utils';
import { uploadShopProductReviewFile } from '../../utils/uploadFile';

import Colors from '../../constants/Colors';
import Styles from '../../constants/Styles';

import LikeSelectedIcon from '../../icons/LikeSelectedIcon';
import CloseIcon from '../../icons/CloseIcon18';
import LikeSelectedHalfIcon from '../../icons/LikeSelectedHalfIcon';
import LikeNormalIcon from '../../icons/LikeNormalIcon';
import ImageUploadIcon from '../../icons/ImageUploadIcon';

const MIN_COMMENT_LENGTH = 10;
const MAX_UPLOAD_FILE_COUNT = 5;

const windowLayout = {
  width: Dimensions.get('window').width,
};

const uploadImageButtonColumnCount = 4;
const uploadImageButtonSize = (windowLayout.width - 20 * 2 + 5) / uploadImageButtonColumnCount - 5;

const styles = StyleSheet.create({
  container: {
  },
  orderItemContainer: {
    paddingVertical: 20,
    marginStart: 20,
    paddingEnd: 20,
    borderBottomColor: Colors.lightGray,
    borderBottomWidth: 1,
    flexDirection: 'row',
  },
  itemImage: {
    width: 100,
    height: 133,
    marginEnd: 10,
  },
  itemInfoContainer: {
    flex: 1,
    paddingTop: 4,
  },
  itemNameText: {
    fontSize: 14,
    marginBottom: 5,
    ...Styles.appleSDGothicNeoSemiBold,
  },
  brandNameText: {
    fontSize: 14,
    marginBottom: 17,
    ...Styles.appleSDGothicNeoRegular,
  },
  optionText: {
    fontSize: 13,
    marginTop: 2,
    ...Styles.appleSDGothicNeoRegular,
  },
  inputFormContainer: {
    padding: 20,
    alignItems: 'center',
  },
  reviewRatingText: {
    fontSize: 18,
    marginBottom: 7,
    ...Styles.appleSDGothicNeoRegular,
  },
  reviewRatingContainer: {
    marginBottom: 17,
    justifyContent: 'center',
  },
  renderReviewRatingSelectContainer: {
    flexDirection: 'row',
    position: 'absolute',
    start: -24,
  },
  renderReviewRatingSelectToucharea: {
    width: 24,
    height: 24,
  },
  likeIcon: {
    marginLeft: 3,
    marginRight: 3,
  },
  reviewInputTextContainer: {
    backgroundColor: Colors.semiGray,
    height: windowLayout.width - 20 * 2,
    width: windowLayout.width - 20 * 2,
    marginBottom: 7.5,
  },
  inputText: {
    marginHorizontal: 20,
    marginTop: 20,
    height: windowLayout.width - 20 * 2 - 20 - 39,
    textAlignVertical: 'top',
  },
  inputTextCount: {
    position: 'absolute',
    bottom: 10,
    end: 20,
    opacity: 0.4,
    fontSize: 14,
    ...Styles.appleSDGothicNeoRegular,
  },
  uploadImageContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: windowLayout.width - 20 * 2 + 2.5 * 2,
    flexWrap: 'wrap',
  },
  uploadImageButton: {
    width: uploadImageButtonSize,
    height: uploadImageButtonSize,
    margin: 2.5,
    backgroundColor: Colors.semiGray,
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadImageItem: {
    margin: 2.5,
    width: uploadImageButtonSize,
    height: uploadImageButtonSize,
    backgroundColor: Colors.semiGray,
  },
  uploadImageItemDeleteButton: {
    position: 'absolute',
    top: 0,
    end: 0,
    backgroundColor: '#000',
  },
  uploadImageItemImage: {
    position: 'absolute',
    top: 0,
    start: 0,
    width: uploadImageButtonSize,
    height: uploadImageButtonSize,
  },
  submitButton: {
    marginTop: 17.5,
    width: windowLayout.width - 20 * 2,
    backgroundColor: Colors.lightGray,
    height: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },
  submitButtonEnabled: {
    backgroundColor: '#000',
  },
  submitButtonText: {
    fontSize: 14,
    color: '#000',
    ...Styles.appleSDGothicNeoBold,
  },
  submitButtonTextEnabled: {
    color: '#fff',
  },
});

class ReviewWritePage extends React.Component {
  state = {
    reviewRating: 0,
    comment: '',
    uploadImageList: [],
    delAttachmentIdxs: [],
    uploadFileLastIdx: -1,
  };

  inputOpenFileRef = React.createRef();

  componentDidMount() {
    const { logEventPageview } = this.props;

    this.loadData();

    logEventPageview({ page_name: 'ReviewWrite' });
  }

  componentDidUpdate(prevProps) {
    const { orderItem: prevOrderItem } = prevProps;
    const { orderItem: curOrderItem } = this.props;

    if (prevOrderItem === undefined && curOrderItem !== undefined) {
      const { setGlobalLayoutInfo } = this.props;
      this.isLoading = false;
      setGlobalLayoutInfo('showProgress', false);
    }

    this.loadData();
  }

  componentWillUnmount() {
    const { setGlobalLayoutInfo } = this.props;
    this.isLoading = false;
    setGlobalLayoutInfo('showProgress', false);
  }

  onPressUploadImageButton = () => {
    const { uploadImageList } = this.state;
    if (uploadImageList.length >= MAX_UPLOAD_FILE_COUNT) {
      alert(`사진은 최대 ${MAX_UPLOAD_FILE_COUNT}개까지 첨부할 수 있습니다.`);
      return;
    }

    this.inputOpenFileRef.current.click();
  };

  onUploadImage = event => {
    event.stopPropagation();
    event.preventDefault();

    const file = event.target.files[0];
    // console.log('onUploadImage file=', file);

    const reader = new FileReader();
    reader.onloadend = uploadFile => {
      const data = uploadFile.target.result;
      const imageData = data.split(';base64,')[1];
      const source = {
        fileName: file.name,
        size: file.size,
        type: file.type,
        data: imageData,
        dataUrl: data,
      };
      // console.log('onUploadImage', source);
      this.upload(source, file);
    };
    reader.readAsDataURL(file);

    event.target.value = null;
  }

  onPressSubmit = async () => {
    const {
      router,
      createShopProductReviewArticle,
      modifyShopProductReviewArticle,
      productId,
      orderItem,
      setGlobalLayoutInfo,
    } = this.props;

    const {
      articleId, comment, uploadImageList, delAttachmentIdxs,
      uploadFileLastIdx, reviewRating,
    } = this.state;

    // console.log("onPressSubmit uploadImageList", uploadImageList);

    if (!comment) {
      alert('내용을 입력해주세요.');
      return;
    }

    const params = {
      subject: comment.slice(0, 20),
      contents: comment,
      goodsPt: reviewRating,
      orderGoodsNo: orderItem.sno,
      attachments: uploadImageList
        .filter(({ saveFileNm }) => saveFileNm !== undefined)
        .map(
          ({ uploadFileNm, saveFileNm }, index) => Object.assign({}, {
            fid: uploadFileLastIdx + index + 1,
            uploadFileNm,
            saveFileNm,
          }),
        ),
    };

    setGlobalLayoutInfo('showProgress', true);

    try {
      let ret = {};

      if (!articleId) {
        // console.log(`createShopProductReviewArticle send, ${productId}`, params);
        ret = await createShopProductReviewArticle(productId, params);
        // console.log('createShopProductReviewArticle return', ret);
      } else {
        params.delAttachmentIdxs = delAttachmentIdxs;
        // console.log(`modifyShopProductReviewArticle send, ${productId}, ${articleId}`, params);
        ret = await modifyShopProductReviewArticle(productId, articleId, params);
        // console.log("modifyShopProductReviewArticle return", ret);
      }


      if (ret.success === true) {
        if (!articleId) {
          router.push('/accounts/reviewWriteSuccess');
        } else {
          router.push('/accounts/reviewEditSuccess');
        }
      } else {
        alert(`저장 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다. (E2${ret.errorCode})`);
      }
    } catch (error) {
      // console.log("onPressSubmit error", error);
      if (error.errorCode) {
        alert(`저장 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다. (E3${error.errorCode})`);
      } else {
        alert('저장 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.');
      }
    } finally {
      setGlobalLayoutInfo('showProgress', false);
    }
  };

  loadData() {
    const {
      setGlobalLayoutInfo,
      articleId, loadUserReviewDetail,
      orderNo,
    } = this.props;

    // console.log('loadUserReviewDetail articleId=', articleId);
    // console.log('loadUserReviewDetail orderNo=', orderNo);
    if (articleId && !orderNo) {
      // console.log('loadUserReviewDetail articleId=', articleId);
      loadUserReviewDetail(articleId);
      if (!this.isLoading) {
        this.isLoading = true;
        setGlobalLayoutInfo('showProgress', true);
      }
      return;
    }

    const { orderItem, getOrderDetail } = this.props;
    if (orderItem === undefined) {
      // console.log('getOrderDetail orderNo=', orderNo);
      getOrderDetail(orderNo);
      // setGlobalLayoutInfo('showProgress', true);
      if (!this.isLoading) {
        this.isLoading = true;
        setGlobalLayoutInfo('showProgress', true);
      }
    }

    const { article: articleInProps } = this.props;
    const { article: articleInState } = this.state;
    if (articleInProps && !articleInState) {
      let contentsText = br2nl(articleInProps.contents);
      contentsText = contentsText.replace(/<p>/gi, '');
      contentsText = contentsText.replace(/<\/p>/gi, '\n');
      contentsText = contentsText.replace(/&nbsp;/gi, ' ');
      contentsText = contentsText.replace(/^\s+|\s+$/g, '');

      const uploadImageList = (articleInProps.uploadImageList || []).map(
        ({ fid, imgPath }) => ({
          fid,
          uri: imgPath,
        }),
      );
      const uploadFileLastIdx = uploadImageList.length > 0
        ? Number.parseInt(uploadImageList[uploadImageList.length - 1].fid, 10) : -1;

      this.setState({
        article: articleInProps,
        articleId,
        comment: contentsText,
        reviewRating: articleInProps.goodsPt,
        uploadImageList,
        uploadFileLastIdx,
      });
    }
  }

  // onUploadImageStart() {
  // console.log('onUploadFileStart');
  // this.setState({ saving: true });
  // }

  // onUploadImageEnd() {
  // console.log('onUploadFileEnd');
  // this.setState({ saving: false });
  // }

  async upload(file, formFile) {
    const {
      productId, auth,
    } = this.props;

    // this.onUploadImageStart();

    try {
      const ret = await uploadShopProductReviewFile(auth.token, productId, formFile);

      // console.log('uploadShopProductReviewFile return', ret);

      const uploadFile = {
        uri: file.dataUrl,
        uploadFileNm: ret.data.uploadFileNm,
        saveFileNm: ret.data.saveFileNm,
      };

      this.setState(({ uploadImageList }) => ({
        uploadImageList: uploadImageList.concat(uploadFile),
      }));
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.log('uploadShopProductReviewFile failed error=', error);

      if (error.errorCode) {
        alert(
          `이미지 첨부 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다. (E${error.errorCode})`,
        );
      } else {
        alert('이미지 첨부 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.');
      }
    } finally {
      // this.onUploadImageEnd();
    }
  }

  renderOrderItem() {
    const { orderItem } = this.props;

    // console.log('ReviewWriteScreen renderOrderItem orderItem=', orderItem);

    const {
      imageSrc,
      goodsNm: itemName,
      brandNm: brandName,
      optionInfo,
    } = orderItem;

    // console.log('ReviewWriteScreen renderOrderItem orderItem=', orderItem);

    let optionInfoList = [];

    if (Array.isArray(optionInfo)) {
      optionInfoList = optionInfo;
    }

    if (typeof optionInfo === 'string') {
      optionInfoList = JSON.parse(optionInfo);
    }


    return (
      <View style={styles.orderItemContainer}>
        <Image
          style={styles.itemImage}
          source={{ uri: imageSrc }}
        />
        <View style={styles.itemInfoContainer}>
          <Text key="itemNameText" style={styles.itemNameText}>{itemName}</Text>
          <Text key="brandNameText" style={styles.brandNameText}>{brandName}</Text>
          {optionInfoList.map(({ optionName, optionValue }) => (
            <Text key={optionValue} style={styles.optionText}>{`${optionName}: ${optionValue}`}</Text>
          ))}
        </View>
      </View>
    );
  }

  renderReviewRatingSelect() {
    const touchArea = [];

    const onPressReviewRatingSelect = reviewRating => () => {
      this.setState({ reviewRating });
    };

    for (let i = 0; i < 6; i++) {
      touchArea.push(
        <TouchableWithoutFeedback
          key={i}
          onPress={onPressReviewRatingSelect(i)}
        >
          <View
            style={styles.renderReviewRatingSelectToucharea}
          />
        </TouchableWithoutFeedback>
      );
    }

    return (
      <View style={styles.renderReviewRatingSelectContainer}>
        {touchArea}
      </View>
    );
  }

  renderReviewRatingIcon() {
    const { reviewRating } = this.state;

    const count = Math.max(Math.min(reviewRating, 5), 0);

    const icons = [];

    for (let i = 0; i < Math.floor(count); i++) {
      icons.push(
        <View key={`LikeSelectedIcon${i}`} style={styles.likeIcon}>
          <LikeSelectedIcon />
        </View>
      );
    }

    if (count % 1 >= 0.5) {
      icons.push(
        <View key="LikeSelectedHalfIcon" style={styles.likeIcon}>
          <LikeSelectedHalfIcon />
        </View>
      );
    }

    const iconCount = icons.length;
    for (let i = 0; i < 5 - iconCount; i++) {
      icons.push(
        <View key={`LikeNormalIcon${i}`} style={styles.likeIcon}>
          <LikeNormalIcon opacity={0.251} />
        </View>
      );
    }
    return (
      <View style={{ flexDirection: 'row' }}>
        {icons}
      </View>
    );
  }

  renderInputText() {
    const { comment } = this.state;
    const commentLength = comment.length;
    const MAX_COMMENT_LENGTH = 1000;

    const onChangeText = text => {
      let newComment = text;

      if (newComment.length > MAX_COMMENT_LENGTH) {
        newComment = text.slice(0, MAX_COMMENT_LENGTH);
      }

      this.setState({ comment: newComment });
    };

    return (
      <View style={styles.reviewInputTextContainer}>
        <TextInput
          placeholder="최소 10자 이상 입력해주세요"
          placeholderTextColor={Colors.placeholderColor}
          value={comment}
          onChangeText={onChangeText}
          style={[styles.inputText, { outline: 'none' }]}
          multiline
        />
        <Text style={styles.inputTextCount}>{`${commentLength}/${MAX_COMMENT_LENGTH}`}</Text>
      </View>
    );
  }

  renderUploadImage() {
    const onPressItemDelete = file => () => {
      const filter = item => {
        // console.log("onPressItemDelete item=", Object.assign({}, item));

        if ((file.fid !== undefined) && (file.fid === item.fid)) {
          // Remove a previously attached image file
          // console.log("onPressItemDelete a previously attached image file");
          return false;
        }

        if ((file.saveFileNm !== undefined) && (file.saveFileNm === item.saveFileNm)) {
          // Remove a new image file
          // console.log("onPressItemDelete a new attached image file");
          return false;
        }

        return true;
      };

      this.setState(({ uploadImageList, delAttachmentIdxs }) => {
        const newState = {};

        if (file.fid !== undefined) {
          // Remove a previously attached image file
          newState.delAttachmentIdxs = delAttachmentIdxs.concat(file.fid);
        }

        newState.uploadImageList = uploadImageList.filter(filter);
        // console.log("onPressItemDelete newState=", newState);

        return newState;
      });
    };

    const { uploadImageList } = this.state;

    // console.log('renderUploadImage uploadImageList=', uploadImageList);

    return (
      <View style={styles.uploadImageContainer}>
        <TouchableOpacity
          style={styles.uploadImageButton}
          onPress={this.onPressUploadImageButton}
        >
          <ImageUploadIcon />
          <input
            ref={this.inputOpenFileRef}
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={this.onUploadImage}
          />
        </TouchableOpacity>
        {uploadImageList.map((item, index) => (
          <View
            // eslint-disable-next-line react/no-array-index-key
            key={`uploadImage${index}`}
            style={styles.uploadImageItem}
          >
            <Text>{item.date}</Text>
            <Image
              style={styles.uploadImageItemImage}
              source={item}
            />
            <TouchableOpacity
              style={styles.uploadImageItemDeleteButton}
              onPress={onPressItemDelete(item, index)}
            >
              <CloseIcon />
            </TouchableOpacity>
          </View>
        ))}
      </View>
    );
  }

  renderReviewInputForm() {
    let submitButtonEnabled = true;
    const { comment } = this.state;

    if (comment.length < MIN_COMMENT_LENGTH) {
      submitButtonEnabled = false;
    }

    return (
      <View style={styles.inputFormContainer}>
        <Text style={styles.reviewRatingText}>평점</Text>
        <View style={styles.reviewRatingContainer}>
          {this.renderReviewRatingIcon()}
          {this.renderReviewRatingSelect()}
        </View>
        {this.renderInputText()}
        {this.renderUploadImage()}
        <TouchableOpacity
          style={[
            styles.submitButton,
            submitButtonEnabled && styles.submitButtonEnabled,
          ]}
          disabled={!submitButtonEnabled}
          onPress={this.onPressSubmit}
        >
          <Text style={[
            styles.submitButtonText,
            submitButtonEnabled && styles.submitButtonTextEnabled,
          ]}
          >
            등록하기
          </Text>
        </TouchableOpacity>
      </View>
    );
  }

  render() {
    const { orderItem } = this.props;

    if (orderItem === undefined) {
      return null;
    }

    return (
      <View>
        <ScrollView style={styles.container} bounces={false}>
          {this.renderOrderItem()}
          {this.renderReviewInputForm()}
        </ScrollView>
      </View>
    );
  }
}

export function moveToReviewWrite(orderNo, orderItemNo) {
  // console.log(`moveToReviewWrite(${orderNo}, ${orderItemNo})`);
  const path = `/accounts/orders/${orderNo}/orderItem/${orderItemNo}/reviewWrite/`;
  browserHistory.push(path);
}

export function moveToReviewEdit(articleId) {
  const path = `/accounts/reviews/edit/${articleId}`;
  browserHistory.push(path);
}

export default connect((state, { params }) => {
  const { articleId } = params;
  let { orderNo, orderItemNo } = params;
  let article = null;


  if (articleId) {
    // console.log('ReviewWritePage articleId=', articleId);
    article = get(state, `entities.userReviewList[${articleId}]`, {});
    // console.log('ReviewWritePage article=', article);
    const extraData = article.extraData || {};
    // console.log('ReviewWritePage extraData=', extraData);

    orderItemNo = !!extraData && !!extraData.arrOrderGoodsNo ? extraData.arrOrderGoodsNo[0] : null;
    const goods = !!extraData && !!extraData.arrOrderGoodsData ? extraData.arrOrderGoodsData : [];
    // eslint-disable-next-line prefer-destructuring
    orderNo = goods.length > 0 && goods[0].orderNo;
  }

  // const { orderNo, orderItemNo } = params;
  let goods = [];
  goods = get(state, `entities.userOrderList[${orderNo}].goods`, []);
  if (goods.length === 0) {
    goods = get(state, `entities.orderDetails[${orderNo}].goods`, []);
  }
  const orderItem = goods.find(item => item.sno === orderItemNo);
  const productId = get(orderItem, 'goodsNo');

  // console.log('ReviewWritePage connect orderNo=', orderNo);
  // console.log('ReviewWritePage connect orderItemNo=', orderItemNo);
  // console.log('ReviewWritePage connect orderItem=', orderItem);
  // console.log('ReviewWritePage connect productId=', productId);

  return {
    auth: state.auth,
    orderNo,
    orderItemNo,
    orderItem,
    productId,
    articleId,
    article,
  };
}, actions)(ReviewWritePage);
