import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SoldoutAlarmIcon(props) {
  const { color='#000' } = props;
  return (
    <Svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M12 6a4 4 0 10-8 0c0 1.05-.101 2.598-.258 3.333-.285 1.334-1.075 2-1.075 2h10.666s-.79-.666-1.075-2C12.101 8.598 12 7.05 12 6zM6.114 11.334a2 2 0 103.772 0"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default SoldoutAlarmIcon

