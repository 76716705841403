import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import NumberFormat from 'react-number-format';

import assign from 'lodash/assign';
import { camelizeKeys } from 'humps';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  GridList,
  GridListTile,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import { Spinner } from 'components';
import { textToClipboard } from 'react-shared/util';
import * as adminActions from 'react-shared/adminActions';

const styles = theme => ({
  subMenuTitles: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
  },
  thumbnailArea: {
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'table-cell',
  },
  textField: {
    marginLeft: 10,
    marginRight: 10,
    minHeight: 50,
    width: 700,
  },
  textFieldHalf: {
    marginLeft: 10,
    marginRight: 10,
    minHeight: 50,
    width: 340,
  },
  confirmButton: {
    marginLeft: 10,
    marginRight: 10,
    width: 700,
    marginTop: 5,
    marginBottom: 15,
  },
  dialogPaper: {
    marginTop: 10,
    padding: 10,
    width: 740,
  },
  dialogueGridListTile: {
    cursor: 'pointer',
    width: 240,
    height: 240,
  },
});

class FAQCategoryPage extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      category: { _id: null },
      snackbarMsg: '',
      snackbarOpen: false,
      ongoingRequest: false,
    };
  }

  async componentDidMount() {
    const { adminGetFaqCategory, categoryId } = this.props;
    if (categoryId !== 'new') {
      const category = await adminGetFaqCategory({ categoryId });
      const newState = { category };
      this.setState(newState);
    }
  }

  componentDidUpdate(prevProps) {
    const { categoryId } = this.props;
    if (prevProps.categoryId !== categoryId && categoryId === 'new') {
      window.location.reload();
    }
  }

  showSnackbar(message) {
    this.setState({
      snackbarMsg: message,
      snackbarOpen: true,
    });
  }

  handleSubmit(event) {
    const { category } = this.state;
    const { adminCreateFaqCategory, adminUpdateFaqCategory, categoryId, router } = this.props;
    if (!category.name) {
      alert('모든 정보를 입력해야 합니다.');
      return;
    }

    if (categoryId === 'new') {
      this.setState(
        { ongoingRequest: true },
        () => adminCreateFaqCategory({ category })
          .catch((e) => this.showSnackbar(`카테고리 추가에 실패했습니다: ${e}`))
          .then(() => {
            this.showSnackbar('카테고리 추가에 성공했습니다.');
          })
          .then(() => router.push('/admin/faq/category'))
      );
    } else {
      this.setState(
        { ongoingRequest: true },
        () => adminUpdateFaqCategory({ category })
          .then((updatedCategory) => this.setState({ category: updatedCategory, ongoingRequest: false }))
          .then(() => this.showSnackbar('카테고리 수정에 성공했습니다.'))
          .catch((e) => this.showSnackbar(`카테고리 수정에 실패했습니다: ${e}`))
          .finally(() => this.setState({ ongoingRequest: false }))
      );
    }
    event.preventDefault();
  }

  render() {
    const { classes, categoryId } = this.props;
    const { category } = this.state;

    return (
      <div className="container" style={{ marginTop: 20 }}>
        <Typography variant="h6">
          {category._id ? 'FAQ 카테고리 수정' : 'FAQ 카테고리 추가'}
        </Typography>
        <Paper className={classes.paper}>
          <Typography variant="subtitle1" className={classes.subMenuTitles}>
            카테고리 정보
          </Typography>
          <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', marginLeft: 10, marginRight: 10, marginBottom: 10 }}>
            <TextField
              className={classes.textField}
              type="text"
              label="카테고리 이름을 입력하세요"
              placeholder="카테고리 이름"
              onChange={event => {
                category.name = event.target.value;
                this.setState({ category });
              }}
              value={category.name || ''}
            />
          </div>
          <div style={{ padding: 5 }}></div>
        </Paper>
        <div style={{ padding: 5 }}></div>
        <Paper className={classes.paper}>
          <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
            <Button
              className={classes.confirmButton}
              onClick={this.handleSubmit}
              variant="contained"
              disabled={this.state.ongoingRequest}
            >
              카테고리
              {category._id ? '변경' : '추가'}
            </Button>
          </div>
        </Paper>

        {<Snackbar
          autoHideDuration={2000}
          className={classes.snackbar}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
        />}
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect((state, ownProps) => ({
    auth: state.auth,
    categoryId: ownProps.params.categoryId,
  }), adminActions),
)(FAQCategoryPage);
