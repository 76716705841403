import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, ScrollView, Text } from 'react-native';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Spinner from 'components/Spinner';

import {
  signup, kakaoLogin, createKakaoAccessToken, getKakaoProfile,
  logEventPageview,
} from 'react-shared/actions';

import { Snackbar } from '@material-ui/core';
import { Constants, Styles } from '../../constants';
import { TouchableHighlightWithContainer } from '../../components-v2';

import JoinForm from './JoinForm';
import { showLegalPolicy } from './LegalPolicyOverlayPage';
import { runAfterLoginDone } from '../../containers/auth/util';


const styles = StyleSheet.create({
  container: {
  },
  listContainer: {
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  bottomButtonContainer: {
    position: 'fixed',
    start: 0,
    end: 0,
    bottom: 0,
    backgroundColor: '#000',
    flexDirection: 'row',
  },
  buttonContainerStyle: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 20,
    paddingBottom: 12 + Constants.bottomMargin,
  },
  bottomButtonText: {
    textAlign: 'center',
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    color: '#fff',
    opacity: 0.5,
  },
  bottomButtonTextEnabled: {
    opacity: 1,
  },
});


class KakaoCallbackPage extends Component {
  constructor(props) {
    super(props);
    this.inputForm = React.createRef();
    this.username = React.createRef();
    this.name = React.createRef();
    this.kakaoAccessToken = React.createRef();

    this.state = {
      showSignup: false,
      snackbarMsg: '',
      snackbarOpen: false,
    };
  }

  componentDidMount() {
    const {
      code, router,
      // state,
      createKakaoAccessToken: createKakaoAccessTokenFunc,
      logEventPageview,
    } = this.props;
    if (!code) {
      router.push('/');
    }

    createKakaoAccessTokenFunc({ code })
      .then(resp => {
        if (resp.success) {
          this.handleLogin(resp.access_token);
        } else {
          alert('카카오 로그인에 실패했습니다. 잠시 후 다시 시도하세요.');
          this.props.router.push('/accounts/login');
        }
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error('카카오 로그인에 실패했습니다. 잠시 후 다시 시도하세요.', err);
        alert('카카오 로그인에 실패했습니다. 잠시 후 다시 시도하세요.');
        this.props.router.push('/accounts/login');
      });

    logEventPageview({ page_name: 'KakaoCallback' });
  }

  showSnackbar = msg => {
    this.setState({
      snackbarMsg: msg,
      snackbarOpen: true,
    });
  }

  handleLogin = accessToken => this.props.kakaoLogin(accessToken).then(
    () => {
      runAfterLoginDone(this.props.router);
    },
    err => {
      if (err.status === 404) {
        this.props.getKakaoProfile({ accessToken })
          .then(response => {
            let name = response.name;
            if (!name) {
              name = response.email.split('@')[0];
            }
            this.setState({
              showSignup: true,
              kakaoAccessToken: accessToken,
              // kakaoId: response.id,
              email: response.email,
              username: response.email,
              name,
            });
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.error(error);
            alert('카카오 로그인에 실패했습니다. 잠시 후 다시 시도하세요.');
            this.props.router.push('/accounts/login');
          });
      } else if (err.status === 400) {
        alert('이미 가입되어 있는 이메일 계정입니다.');
        this.props.router.push('/accounts/login');
      } else {
        alert('카카오 로그인에 실패했습니다. 잠시 후 다시 시도하세요.');
        this.props.router.push('/accounts/login');
      }
    }
  )

  async handleJoin() {
    const { router, signup } = this.props;
    const { name, email, phone, phoneVerified, password,
      termAgreed, privacyAgreed, marketingAgreed, kakaoAccessToken } = this.state;
    const username = email;
    console.log(this.state);

    this.setState({ requestJoinOngoing: true });

    try {
      const { _id, token: bearerToken } = await signup({
        username,
        email,
        password,
        passwordCheck: password,
        name,
        tel: phone,
        telVerified: phoneVerified,
        termAgreed,
        privacyAgreed,
        marketingAgreed,
        kakaoAccessToken,
      });
      if (bearerToken) {
        firebase.analytics().logEvent('sign_up', {
          username,
          email,
          platform: 'web',
        });

        // Facebook Pixel event 전송
        fbq('track', 'CompleteRegistration', {
          content_name: 'kakao',
          currency: 'KRW',
          status: true,
          value: 0,
        });
      }
      router.push('/accounts/join/done');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      if (e.status === 400) {
        const yes = confirm('이미 가입된 이메일입니다.\n비밀번호 찾기로 이동할까요?');
        if (yes) {
          router.push('/accounts/login/email/findPassword');
        }
        return;
      }

      let msg = '가입에 실패했습니다. 잠시 후 다시 시도하세요.';
      if (e.status === 401) {
        msg = '휴대폰번호를 인증해야 합니다.';
      }

      const newState = {
        snackbarOpen: false,
        snackbarMsg: msg,
      };
      this.setState(newState);
    }
  }

  onChangeInputData(inputData) {
    let isEnabledJoinButton = false;

    // console.log(`onChangeInputData ${JSON.stringify(inputData)}`);
    if (inputData.email
      && inputData.phone
      && inputData.phoneVerified
      && inputData.termAgreed
      && inputData.privacyAgreed) {
      isEnabledJoinButton = true;
    }

    this.setState({ 
      ...inputData,
      isEnabledJoinButton
    });
  }

  renderInputForm() {
    const { router } = this.props;
    const { email, name } = this.state;
    return (
      <JoinForm
        router={router}
        onRef={ref => { this.joinForm = ref; }}
        onChange={inputData => this.onChangeInputData(inputData)}
        onPressTerms={type => {
          showLegalPolicy(this, type);
        }}
        editableEmail={false}
        email={email}
        name={name}
        disablePassword
        disableName
        />
    );
  }

  renderBottomButton() {
    const { isEnabledJoinButton } = this.state;
    let onPress = null;

    const bottomButtonContainerStyles = [
      styles.bottomButtonContainer
    ];

    const buttonStyles = [
      styles.bottomButtonText,
    ];

    if (isEnabledJoinButton) {
      buttonStyles.push(styles.bottomButtonTextEnabled);
      onPress = () => {
        if (!this.joinForm.validateInput()) {
          return;
        }

        this.handleJoin();
      };
    }

    return (
      <View style={bottomButtonContainerStyles}>
        <TouchableHighlightWithContainer
          containerStyle={styles.buttonContainerStyle}
          onPress={onPress}
          >
          <Text style={buttonStyles}>가입하기</Text>
        </TouchableHighlightWithContainer>
      </View>
    );
  }

  render() {
    const { showSignup } = this.state;
    if (!showSignup) {
      return <Spinner />;
    }

    const { showSmartBanner } = this.props;

    return (
      <View style={styles.container}>
        <ScrollView
          bounces={false}
          style={[styles.listContainer, showSmartBanner && { paddingBottom: Constants.smartBannerHeight }]}
        >
          {this.renderInputForm()}
        </ScrollView>
        {this.renderBottomButton()}
        <Snackbar
          autoHideDuration={2000}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
          style={{ textAlign: 'center' }}
        />
      </View>
    );
  }
}


KakaoCallbackPage.propTypes = {
  code: PropTypes.string.isRequired,
  // state: PropTypes.string.isRequired,
  signup: PropTypes.func.isRequired,
  kakaoLogin: PropTypes.func.isRequired,
  createKakaoAccessToken: PropTypes.func.isRequired,
};

export default connect(
  (state, ownProps) => {
    const { params } = ownProps;

    return {
      code: get(ownProps.location, 'query.code'),
      // state: get(ownProps.location, 'query.state'),
    };
  },
  { signup, kakaoLogin, createKakaoAccessToken, getKakaoProfile, logEventPageview }
)(KakaoCallbackPage);
