import { createStore, applyMiddleware, compose } from 'redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import createLogger from 'redux-logger';
import thunk from 'redux-thunk';
// eslint-disable-next-line import/no-unresolved
import { reducer } from 'react-shared';

export default function configureStore(initialState) {
  const middlewares = [
    applyMiddleware(thunk),
  ];

  if (process.env.NODE_ENV === 'development') {
    //middlewares.push(applyMiddleware(createLogger()));
  }
  // const finalCreateStore = compose(...middlewares)(createStore);

  // const store = finalCreateStore(reducer, initialState);
  
  const store = createStore(reducer, initialState, compose(...middlewares));

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('react-shared/reducers', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('react-shared/reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
