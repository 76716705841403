import * as React from 'react';

function SvgIcLikeNormal(props) {
  const { fill = 'none' } = props;

  return (
    <svg width={24} height={24} {...props}>
      <path
        // eslint-disable-next-line max-len
        d="M14.421 17.555l4.759-4.804a5.163 5.163 0 000-7.25 5.044 5.044 0 00-7.18 0 5.044 5.044 0 00-7.18 0 5.163 5.163 0 000 7.25L12 20l2.421-2.445z"
        stroke="#000"
        strokeWidth={1.5}
        fill={fill}
        fillRule="evenodd"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgIcLikeNormal;
