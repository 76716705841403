import React, { Component } from 'react';

const styles = {
  container: {
    textAlign: 'center',
    padding: '10px',
    marginBottom: 50,
  },
  divider: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    height: '1px',
    width: '100%',
  },
  snsContainer: {
    marginTop: '20px',
  },
  snsImg: {
    width: '26px',
    height: '26px',
    margin: '0 7px',
  },
  policyContainer: {
    marginTop: '20px',
  },
  policy: {
    color: '#929292',
    fontSize: '11px',
  },
  copyright: {
    marginTop: '11px',
    fontSize: '9px',
    color: '#929292',
    marginLeft: '7px',
    marginRight: '7px',
  },
};


export default class Footer extends Component {
  render() {
    return (
      <div style={styles.container}>
        <div style={styles.divider}></div>
        <div style={styles.snsContainer}>
          <a href="https://www.instagram.com/heartit.kr" target="_blank" rel="noopener noreferrer">
            <img style={styles.snsImg} src="https://cdn.heartit.kr/img/r2/icon/ic_sns_insta.png" alt="" />
          </a>
          <a href="https://www.facebook.com/heartit.kr/" target="_blank" rel="noopener noreferrer">
            <img style={styles.snsImg} src="https://cdn.heartit.kr/img/r2/icon/ic_sns_fb.png" alt="" />
          </a>
        </div>
        <div style={styles.policyContainer}>
          {/* <a href="#">회사소개</a>
          |
          */}
          <a style={styles.policy} href="/legal/terms">개인정보취급방침</a>
        </div>
        <div style={styles.copyright}>
          copyright(c) (주)하트잇 all rights reserved
        </div>
      </div>
    );
  }
}
