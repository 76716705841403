import assign from 'lodash/assign';
import get from 'lodash/get';
import set from 'lodash/set';
import { normalize } from 'normalizr';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';
import { getNextPageUrl } from '../util';


// admin actions for posts
export function adminCreateStylePost(post) {
  return createFetchAction({
    type: 'ADMIN_CREATE_STYLE_POST',
    endpoint: '/api/v2/admin/stylePosts',
    method: 'POST',
    body: post,
    transform: ({ json }) => normalize(json, schemas.stylePost),
    success: state => ({
      pagination: {
        key: `stylePosts.${state.auth._id}`,
        type: 'PREPEND',
      },
    }),
  });
}

export function adminDeleteStylePost(postId) {
  return createFetchAction({
    type: 'ADMIN_DELETE_STYLE_POST',
    endpoint: `/api/v2/admin/stylePosts/${postId}`,
    method: 'DELETE',
    success: {
      payload: set({}, `entities.stylePosts.${postId}`, null),
    },
  });
}

export function adminUpdateStylePost(postId, post) {
  return createFetchAction({
    type: 'ADMIN_UPDATE_STYLE_POST',
    endpoint: `/api/v2/admin/stylePosts/${postId}`,
    method: 'PUT',
    body: post,
    transform: ({ json }) => normalize(json, schemas.stylePost),
  });
}

export function adminLoadStylePosts(nextPage, ownerId) {
  const key = ownerId ? `userStylePosts.${ownerId}` : 'stylePosts';
  const ownerQuery = ownerId ? '&owner=' + ownerId : '';

  return createFetchAction({
    type: 'ADMIN_LOAD_STYLE_POSTS',
    endpoint: state => {
      const base = '/api/v2/admin/stylePosts?page=0' + ownerQuery;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    transform: ({ json, response }) => assign(normalize(json, schemas.stylePosts), {
      nextPageUrl: getNextPageUrl(response),
    }),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}
