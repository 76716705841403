import React from 'react';
import {
  Alert, Image, StyleSheet, Text, View, TouchableHighlight,
} from 'react-native';
import NumberFormat from 'react-number-format';

import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import Styles from '../constants/Styles';

import ProductInfo from './ProductInfo';
import { getOrderProductInfo } from './ProductInfo';
import Button from './Button';


const buttonSpacer = 5;
const styles = StyleSheet.create({
  container: {
  },
  headerContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  headerFirstLine: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerSecondLine: {
    marginTop: 12,
  },
  orderNameContainer: {
    flex: 1,
    flexGrow: 1,
  },
  orderDateContainer: {
    flexShrink: 1,
    marginBottom: 1,
  },
  orderName: {
    fontSize: 16,
    color: 'black',
    lineHeight: 19.2,
    ...Styles.pretendard600,
  },
  itemContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  stateInfoContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  stateContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 20,
    marginBottom: 6,
  },
  state: {
    fontSize: 14,
    color: 'black',
    lineHeight: 16.8,
    ...Styles.pretendard600,
  },
  orderDate: {
    fontSize: 14,
    color: 'black',
    lineHeight: 16.8,
    opacity: 0.5,
    ...Styles.pretendard400,
  },
  orderTotalPrice: {
    fontSize: 16,
    color: Colors.defaultGray08,
    lineHeight: 19.2,
    ...Styles.pretendard400,
  },
  itemName: {
    color: '#000',
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard400,
  },
  orderDetailButton:{
    width: '100%',
    height: 40,
    borderColor: '#000',
    borderWidth: 0.5,
    paddingVertical: 10,
    paddingHorizontal: 40,
    backgroundColor: '#000',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  orderDetailButtonText:{
    fontSize: 16,
    lineHeight: 19.2,
    color: '#fff',
    ...Styles.pretendard600,
  },
});


class MyOrdersItem extends React.Component {
  getOrderDate = item => {
    const datetime = item.regDt.split(' ');
    const ymd = datetime[0].split('-');

    return ymd.join('.');
  }

  moveToOrderDetail = () => {
    const { router, order } = this.props;

    router.push({
      pathname: `/accounts/orders/${order.orderNo}`,
    });
  }

  renderItem = (item) => {
    const productInfo = getOrderProductInfo(item);
    return (
      <View style={styles.stateInfoContainer}>
        <View style={styles.stateContainer}>
          <Text style={styles.state}>
            { item.orderStatusStr }
          </Text>
        </View>

        <ProductInfo
          type="B"
          {...productInfo}
        />
      </View>
    );
  }

  render() {
    const { order, containerStyle } = this.props;

    const { goods } = order;

    if (!goods || goods.length < 1) {
      return null;
    }

    let orderName = `${goods[0].goodsNm}`;
    if (goods.length > 1) {
      orderName += ` 외 ${goods.length - 1}건`;
    }

    let orderTotalPrice = 0;
    for (let item of goods) {
      const productInfo = getOrderProductInfo(item);
      orderTotalPrice += productInfo.itemSellPrice;
    }

    return (
      <View style={[styles.container, containerStyle]}>
        <View style={styles.headerContainer}>
          <View style={styles.headerFirstLine}>
            <View style={styles.orderNameContainer}>
              <Text
                style={styles.orderName}
                numberOfLines={1}
              >
                { orderName }
              </Text>
            </View>
            <View style={styles.orderDateContainer}>
              <Text style={styles.orderDate}>
                { this.getOrderDate(order) }
              </Text>
            </View>
          </View>

          <View style={styles.headerSecondLine}>
            <NumberFormat
              value={orderTotalPrice}
              displayType="text"
              thousandSeparator
              prefix='총 '
              suffix='원'
              renderText={value => (
                <Text style={styles.orderTotalPrice}>
                  {value}
                </Text>
              )}
            />
          </View>
        </View>

        <View style={styles.itemContainer}>
          { goods.map(this.renderItem)}
        </View>

        <View style={{ flex: 1 }}>
          <Button
            containerStyle={styles.orderDetailButton}
            titleStyle={styles.orderDetailButtonText}
            title="주문 상세"
            onPress={this.moveToOrderDetail}
          />
        </View>
      </View>
    );
  }
}


export default MyOrdersItem;
