import React from 'react';
import {
  Dimensions, StyleSheet, Text, View,
} from 'react-native';
import { connect } from 'react-redux';

import { actions } from 'shared';

import { Constants, Styles } from '../../../constants';
import {
  InputForm, TouchableHighlightWithContainer,
} from '../../../components-v2';

import EditImage from './EditImage';


const {
  height: windowHeight,
} = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  itemSeparator: {
    height: 1,
  },
  bottomContainer: {
    position: 'absolute',
    bottom: 0,
    start: 0,
    end: 0,
    height: Constants.bottomSpaceForTabBar,
    backgroundColor: '#000000',
  },
  bottomButtonText: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    textAlign: 'center',
    color: '#ffffff',
    marginTop: 20,
    marginBottom: 21,
  },
});


class EditMyProfilePage extends React.Component {

  state = {};
  profileNameRef = null;
  oneLineSummaryRef = null;
  instagramUsernameRef = null;

  onPressSave = async () => {
    const { router, updateUser, userId } = this.props;

    const {
      newProfileImage,
      newProfileName,
      newOneLineSummary,
      newInstagramUsername
    } = this.state;

    const profile = {};
    if (newProfileImage !== undefined) {
      profile.profileImage = newProfileImage;
    }

    if (newProfileName !== undefined) {
      profile.name = newProfileName;
    }

    if (newOneLineSummary !== undefined) {
      profile.oneLineSummary = newOneLineSummary;
    }

    if (newInstagramUsername !== undefined) {
      profile.instagramUsername = newInstagramUsername;
    }

    try {
      await updateUser(userId, profile);
    } catch (error) {
      console.error(error);
      alert('저장 중 오류가 발생하였습니다. 잠시 후 다시 시도해주세요.');
    }

    router.goBack();
  };

  componentDidMount() {
    const {
      logEventPageview,
    } = this.props;

    logEventPageview({ page_name: 'EditMyProfile' });
  }

  renderInputInstragramUsername() {
    const {
      instagramUsername,
    } = this.props;

    const {
      newInstagramUsername=instagramUsername,
    } = this.state;

    const onChangeText = val => {
      let newInstagramUsername = val;

      if (newInstagramUsername === '@') {
        newInstagramUsername = '';
      }

      if (newInstagramUsername.length > 0 && newInstagramUsername[0] !== '@') {
        newInstagramUsername = '@' + newInstagramUsername;
      }

      if (newInstagramUsername.length > 1 && newInstagramUsername[1] === '@') {
        newInstagramUsername = newInstagramUsername.slice(1);
      }

      this.setState({ newInstagramUsername });
    };

    return (
      <InputForm
        onInputRef={ref => this.instagramUsernameRef = ref}
        title='인스타그램 주소'
        ItemSeparatorStyle={styles.itemSeparator}
        onSubmitEditing={this.onPressSave}
        placeholder='@'
        value={newInstagramUsername}
        onChangeText={onChangeText}
        />
    )
  }

  renderBottom() {
    return (
      <View style={styles.bottomContainer}>
        <TouchableHighlightWithContainer
          containerStyle={styles.buttonContainerStyle}
          onPress={this.onPressSave}
        >
          <Text style={styles.bottomButtonText}>정보 저장</Text>
        </TouchableHighlightWithContainer>
      </View>
    )
  }

  render() {
    const {
      profileImage,
      profileName,
      oneLineSummary,
      showSmartBanner,
    } = this.props;

    const {
      newProfileImage=profileImage,
      newProfileName=profileName,
      newOneLineSummary=oneLineSummary,
    } = this.state;

    const containerStyles = [styles.container];
    containerStyles.push({
      height: windowHeight
        - (showSmartBanner && Constants.smartBannerHeight)
        - Constants.mainHeaderHeight
    });

    return (
      <View style={containerStyles}>
        <EditImage
          profileImage={newProfileImage}
          onChange={image => this.setState({ newProfileImage: image })}
          />

        <View style={{ height: 30 }} />

        <InputForm
          onInputRef={ref => this.profileNameRef = ref}
          title='프로필 표시 이름'
          ItemSeparatorStyle={styles.itemSeparator}
          value={newProfileName}
          onChangeText={val => this.setState({ newProfileName: val })}
          onSubmitEditing={() => this.oneLineSummaryRef.focus()}
          />

        <View style={{ height: 40 }} />

        <InputForm
          onInputRef={ref => this.oneLineSummaryRef = ref}
          title='소개 내용'
          ItemSeparatorStyle={styles.itemSeparator}
          value={newOneLineSummary}
          onChangeText={val => this.setState({ newOneLineSummary: val })}
          onSubmitEditing={() => this.instagramUsernameRef.focus()}
          />

        <View style={{ height: 40 }} />

        {this.renderInputInstragramUsername()}

        {this.renderBottom()}
      </View>
    );
  }
}


export default connect(
  (state) => {
    const {
      auth: {
        _id: userId,
        name,
        oneLineSummary,
        profileImage,
        instagramUsername,
      },
    } = state;

    return {
      userId,
      profileImage,
      profileName: name,
      oneLineSummary,
      instagramUsername,
    };
  },
  actions,
)(EditMyProfilePage);
