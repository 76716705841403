import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router, applyRouterMiddleware, browserHistory } from 'react-router';
import { useScroll } from 'react-router-scroll';
import { cloudinary } from './cloudinary';

import configureStore from './configureStore';
// import { closeAllPopup } from 'react-shared/util/commonFunctions';
import configureRoutes from './routes/admin';
import config from './config';

const initialState = window.__INITIAL_STATE__;
window.extraHeaders = config.get('extraHeaders');
if (!initialState.viewModes) {
  initialState.viewModes = {
    home: 1,
    heartist: 1,
    liked: 1,
    initial: {
      heartist: {
        heartit_picks: 2,
      },
    },
  };
}
const store = configureStore(initialState);
const routes = configureRoutes(store);

render(
  <Provider store={store}>
    <Router history={browserHistory} routes={routes} render={applyRouterMiddleware(useScroll())} />
  </Provider>,
  document.getElementById('root')
);
