import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as adminActions from 'react-shared/adminActions';
import { get, orderBy } from 'lodash';

const styles = {
  table: {
    margin: "auto",
    width: "calc(100% - 40px)",
    marginTop: 20,
    marginBottom: 20,
  },
  table_cell: {
    padding: "8px 15px",
    height: 43,
    textAlign: 'center',
    border: '1px solid',
    fontSize: 12,
  },
  table_header: {
    background: '#F6F6F6',
  },
  table_body: {
  },
  table_column_no: {
    width: 60,
  },
  table_column_click_count: {
    width: 100,
  },
  table_column_button: {
    width: 100,
  },
}

class StatsSnapshotEventList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { startDate, endDate } = this.props;
    this.loadData(startDate, endDate);
  }

  componentDidUpdate(prevProps, prevState) {
    const { kind, startDate, endDate } = this.props;
    if (prevProps.startDate !== startDate || prevProps.endDate !== endDate || prevProps.kind !== kind) {
      this.loadData(startDate, endDate);
    }
  }

  loadData(startDate, endDate) {
    const { getSnapshotCount, kind } = this.props;
    getSnapshotCount(startDate, endDate, kind);
  }

  renderItemName(item) {
    console.log('renderItemName item=', item);
    return (
      <a href={`/product/${item.name}`} target='_blank' title={item.description}>{item.title || item.name}</a>
    );
  }

  render() {

    const { snapshotCount } = this.props;

    if (!snapshotCount) {
      return null;
    }

    const styleTableHeader = {
      ...styles.table_cell,
      ...styles.table_header,
    };

    const styleTableBody = {
      ...styles.table_cell,
      ...styles.table_body,
    }

    let items = Object.values(snapshotCount).map(item => ({
      ...item,
      name: item.key,
    }));

    items = orderBy(items, ['count'], ['desc']);  

    return (
      <div>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={{
                ...styleTableHeader,
                ...styles.table_column_no,
              }}>
                No
              </th>
              <th style={styleTableHeader}>
                페이지
              </th>
              <th style={{
                ...styleTableHeader,
                ...styles.table_column_click_count,
                }}>
                현황
              </th>
              <th style={{
                ...styleTableHeader,
                ...styles.table_column_button,
              }}>
              </th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td style={styleTableBody}>
                  {index + 1}
                </td>
                <td style={styleTableBody}>
                  {this.renderItemName(item)}
                </td>
                <td style={styleTableBody}>
                  {item.count}
                </td>
                <td style={styleTableBody}>
                  <a href={`/admin/stats/snapshot/${item.key}`}>자세히보기</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}


export default connect(
  (state, props) => {
    const { kind, startDate, endDate } = props;
    const snapshotCount = get(state.admin.snapshotCount, `${kind}[${startDate}~${endDate}]`)

    return {
      snapshotCount,
    };
  },
  adminActions,
) (StatsSnapshotEventList);
