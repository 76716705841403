import React from 'react';

import { View, StyleSheet, Text, FlatList } from 'react-native';
import { 
  TouchableHighlightWithContainer, CloudinaryImage,
} from '../../../components-v2';
import { Constants, Styles } from '../../../constants';
import { BlankImageIcon } from '../../../icons-v2';

import config from '../../../config';


const styleHtml = {
  thumbnail: {
    width: 70,
    height: 70,
    backgroundColor: '#efefef',
    clipPath: 'circle(35px at 35px 35px)',
    backgroundColor: '#dedede',
  },
  maskedView: {
    width: 70,
    height: 70,
  },
}


const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    minHeight: 20 + 94 + 20,
  },
  itemContainer: {
  },
  thumbnailBorderContainer: {
    width: 70,
    height: 70,
  },
  thumbnailContainer: {
    position: 'absolute',
    width: 70,
    height: 70,
    borderRadius: 60,
    borderWidth: 0.5,
    borderColor: '#cfcfcf',
    backgroundColor: 'transparent',
    zIndex: 999,
  },
  thumbnailContainerSelected: {
    borderWidth: 1,
    borderColor: '#000000',
  },
  contentContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 4,
  },
  itemTextContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#ffffff',
    paddingBottom: 2,
  },
  itemTextContainerSelected: {
    borderBottomColor: '#000000',
  },
  itemText: {
    color: '#000',
    fontSize: 13,
    lineHeight: 18,
    ...Styles.pretendard400,
    maxWidth: 70,
    textAlign: 'center',
  },
  itemTextSelected: {
    ...Styles.pretendard700,
  }
});

class CategoryList extends React.Component {

  renderItem(item) {
    const {
      selectedCategoryItem,
      selectedSubCategoryList,
      onSelected,
    } = this.props;

    const thumbnailContainerStyles = [styles.thumbnailContainer];
    const itemTextContainerStyles = [styles.itemTextContainer];
    const itemTextStyles = [styles.itemText];

    let isSelected = false;

    if (!selectedCategoryItem || selectedCategoryItem.cateNm === "전체" || !selectedCategoryItem.children) {
      if (item.cateCd === selectedCategoryItem.cateCd) {
        isSelected = true;
      }  
    } else {
      if (!selectedSubCategoryList || selectedSubCategoryList.length === 0) {
        if (item.cateNm === "전체" || item.cateNm === "ALL") {
          isSelected = true;
        }
      } else {
        selectedSubCategoryList.forEach(subCategoryItem => {
          if (item.cateCd === subCategoryItem.cateCd) {
            isSelected = true;
          }
        });
      }
    }

    if (isSelected) {
      thumbnailContainerStyles.push(styles.thumbnailContainerSelected);
      itemTextContainerStyles.push(styles.itemTextContainerSelected);
      itemTextStyles.push(styles.itemTextSelected);
    }

    const onPress = () => {
      if (selectedCategoryItem
        && selectedCategoryItem.cateNm !== "전체"
        && selectedCategoryItem.children) {
          onSelected(selectedCategoryItem, item);
      } else {
        const subCategory = item.children && item.children.length > 0 ? item.children[0] : null;
        onSelected(item, subCategory);
      }

      this.subCategoryListRef && this.subCategoryListRef.scrollToIndex({ 
        index: 0,
      }); 
    }

    // console.log(`Categorylist item=${JSON.stringify(item)}`);

    const imageStyles = styleHtml.thumbnail;

    // console.log(`imageStyles=${JSON.stringify(imageStyles)}`);

    let thumbnailFolder = config.get('categoryThumbnailFolder');

    return (
      <View style={styles.itemContainer}>
        <View style={[styles.thumbnailBorderContainer]}>
          <div style={styleHtml.maskedView}>
            <TouchableHighlightWithContainer
              onPress={onPress}
              style={{ borderRadius: 60 }}
              >
              <View>
                <View style={thumbnailContainerStyles} />
                <CloudinaryImage 
                  publicId={`${thumbnailFolder}/${item.cateCd}-221003`}
                  options={{
                    crop: 'limit',
                    height: 80 * 2,
                    width: 80 * 2,
                  }}
                  style={imageStyles}
                  />
              </View>
            </TouchableHighlightWithContainer>
          </div>
        </View>

        <View style={styles.contentContainer}>
          <View style={itemTextContainerStyles}>
            <Text style={itemTextStyles}>{item.cateNm}</Text>
          </View>
        </View>
      </View>
    )
  }

  render() {
    const { 
      categories,
      selectedCategoryItem,
    } = this.props;

    let items = [...categories];
    if (selectedCategoryItem && selectedCategoryItem.cateNm !== "전체") {
      if (selectedCategoryItem.children) {
        items = [...selectedCategoryItem.children];
      }
    }

    return (
      <View style={styles.container}>
        <FlatList
          keyExtractor={(item, index) => `${index}`}
          renderItem={({ item }) => this.renderItem(item)}
          data={items}
          horizontal
          ItemSeparatorComponent={() => <View style={{ width: 14 }} />}
          ListHeaderComponent={() => <View style={{ width: Constants.viewHorizontalPadding }} />}
          ListFooterComponent={() => <View style={{ width: Constants.viewHorizontalPadding }} />}
          showsHorizontalScrollIndicator={false}
      />
      </View>
    )
  }
}

export default CategoryList;
