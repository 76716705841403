import url from 'url';
import config from '../config';

if (typeof fetch === 'undefined') {
  // eslint-disable-next-line global-require, no-undef
  require('isomorphic-fetch');
}

export default function createFetchAction(options) {
  const {
    bailout,
    endpoint,
    method = 'GET',
  } = options;
  return (dispatch, getState) => {
    const state = getState();
    const extraHeaders = global.extraHeaders || {};
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'heartit-agent': extraHeaders['heartit-agent'] || 'app',
      'heartit-agent-version': global.appVersion || 'web',
      'heartit-agent-os': global.platformOS || 'web',
    };
    function resolve(obj) {
      return typeof obj === 'function' ? obj(state) : obj;
    }

    if (bailout && bailout(state)) { return null; }

    const apiRoot = config.get('apiRoot') || 'api.heartit.kr';
    return fetch(url.resolve(apiRoot, resolve(endpoint)), {
      method,
      headers,
    }).then(response => { // eslint-disable-line arrow-body-style
      if (!response.ok) {
        return Promise.reject(response);
      }
      return Promise.resolve(response);
    });
  };
}


// eslint-disable-next-line import/prefer-default-export
export function getPolicy(type) {
  return createFetchAction({
    endpoint: `/api/v2/policy/${type}`,
  });
}
