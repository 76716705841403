import React from 'react';
import { StyleSheet } from 'react-native';

import CloseIcon from '../icons/CloseIcon';

import {
  HeaderBackIcon
} from '../icons-v2';

import {
  TouchableHighlightWithContainer
} from '../components-v2';


const styles = StyleSheet.create({
  closeButton: {
  },
  arrowButtonContainer: {
    marginEnd: 10,
  },
  arrowButton: {
  },
});


export default ({ backButtonStyle, onPress, navigation }) => {
  const onPressButton = () => {
    if (onPress) {
      onPress();
    } else if (backButtonStyle === 'close') {
      navigation.dismiss();
    } else {
      navigation.goBack();
    }
  };

  const showBackButton = type => {
    if (type === 'close') {
      return (
        <TouchableHighlightWithContainer
          style={[styles.closeButton]}
          onPress={onPressButton}
        >
          <CloseIcon fontSize="large" />
        </TouchableHighlightWithContainer>
      );
    }

    return (
      <TouchableHighlightWithContainer
        style={[styles.arrowButtonContainer]}
        onPress={onPressButton}
      >
        <HeaderBackIcon />
      </TouchableHighlightWithContainer>
    );
  };

  return showBackButton(backButtonStyle);
};
