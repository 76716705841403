import React, { Component } from 'react';

export default class EmbedInstagram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: '',
    };
  }

  async componentDidMount() {
    const url = '/api/v2/instagram/users/1672806696/media/recentOne';
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    await fetch(url, { headers })
      .then(response => {
        if (!response.ok) {
          throw new Error('Instagram 재로그인이 필요합니다.');
        } else {
          return response.json();
        }
      })
      .then(medias => {
        if (medias.length === 0) {
          throw new Error('No media');
        }
        const index = Math.floor((Math.random() * medias.length) + 0);
        this.setState({ link: medias[index].link + 'embed' });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  render() {
    const { link } = this.state;
    return (
      <div style={{ marginTop: '45px' }}>
        <iframe
          title="EmbedInstagram"
          src={link}
          frameBorder="0"
          marginWidth="10"
          style={{ border: 0, width: '100%', height: '540px', maxWidth: '450px' }}
        />
      </div>
    );
  }
}
