import React from 'react';
import { StyleSheet, View, ActivityIndicator } from 'react-native';

import Constants from '../constants/Constants';


export default class Spinner extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  render() {
    const { isOverlay = false, imageContainerStyle } = this.props;

    return (
      <View style={styles.spinnerContainer}>
        <ActivityIndicator color="gray" size="large" />
        {/*<View style={[styles.spinnerContainer, isOverlay ? styles.spinnerContainerForOverlay : {}]}>
          <View style={[styles.imageContainer, imageContainerStyle, isOverlay ? styles.imageContainerForOverlay : {}]}>
            <img
              alt=""
              src="https://s3.ap-northeast-2.amazonaws.com/heartit-cdn/img/r3/icon/heartit_loading.gif"
            />
          </View>
        </View>*/}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  spinnerContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  imageContainer: {
    flex: 1,
    marginBottom: Constants.bottomSpaceForTabBar,
    justifyContent: 'center',
    alignItems: 'center',
  },
  /*
  spinnerContainerForOverlay: {
    position: 'relative',
    backgroundColor: 'transparent',
  },
  */
  spinnerContainerForOverlay: {
    backgroundColor: 'white',
  },
  imageContainerForOverlay: {
    marginBottom: 0,
  },
});
