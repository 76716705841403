import React, { Component } from "react";
import Media from "../Media";
import HighCharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import { map } from 'lodash';
import moment from 'moment';
import { Button, Card, CardContent, CardActions, Dialog, DialogContent, DialogActions, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'

const styles = {
  card: {
    margin: '6px',
    padding: '4px',
    width: '300px',
    minHeight: '350px',
  },
  clickedCard: {
    paddingTop: '64px',
    position: 'fixed', 
    top: 0, 
    left: 0, 
    background: 'rgba(0, 0, 0, 0.7)', 
    width: '100%', 
    height: '100%',
    zIndex: 100,
  },
}

export default class AdminProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClicked: false,
      productOptions: {
        title: null,
        series: [],
        xAxis: {
          tickmarkPlacement: 'on',
        },
        yAxis: {
          type: 'logarthmic',
        },
      }
    }
  }

  loadData() {
    const { loadDailyProductStats, since, until, statProduct: { product } } = this.props;
    const productId = product._id;
    loadDailyProductStats({ productId, since, until }).then((data) => {
      this.setState({
        productOptions: {
          title: {
            text: `Daily Product Performance(${since} ~ ${until})`,
            floating: false,
            margin: 15,
            align: 'center',
            style: {
              fontSize: '15px',
              fontWeight: 'bold',
            }
          },
          yAxis: [
            {
              title: {
                text: '클릭',
              },
              type: 'logarthmic'
            },
            {
              title: {
                text: '찜하기',
              },
              type: 'logarthmic',
              opposite: true,
            },
          ],
          xAxis: {
            title: {
              text: 'date',
            },
            categories: map(data, (datum) => moment(datum.date).format('YYYY-MM-DD')),
            reversed: false,
            labels: {
              formatter: function () {
                return `${moment(this.value).format('DD')}`
              }
            }
          },
          series: [
            { name: '클릭(android)', data: map(data, (datum) => datum.androidPageviews), yAxis: 0 },
            { name: '클릭(ios)', data: map(data, (datum) => datum.iosPageviews), yAxis: 0 },
            { name: '찜하기(android)', data: map(data, (datum) => datum.androidAddToFavorites), yAxis: 1 },
            { name: '찜하기(ios)', data: map(data, (datum) => datum.iosAddToFavorites), yAxis: 1 },
          ]
        }
      })
    })
  }

  componentDidUpdate(_, prevState) {
    const { isClicked } = this.state;
    if (prevState.isClicked !== isClicked && isClicked) {
      this.loadData();
    }
  }

  renderProductName() {
    const { statProduct: { product } } = this.props;
    return (
      <h2 style={{ fontWeight: 'bold' }}>{product.name}</h2>
    )
  }

  renderProduct() {
    const { statProduct: { product, androidPageviews, androidAddToFavorites, iosPageviews, iosAddToFavorites } } = this.props;
    const mediaOptions = {
      width: 200,
      height: 200,
      crop: 'limit',
    }
    return (
      <>
        <div style={{ width: '200px', margin: 'auto' }}>
          <Media 
            media={product.media}
            options={mediaOptions}
            />
        </div>
        <table style={{ width: '100%', margin: '12px 0' }}>
          <tbody>
            <tr>
              <td></td>
              <th>android</th>
              <th>ios</th>
            </tr>
            <tr>
              <th>클릭</th>
              <td>{androidPageviews}</td>
              <td>{iosPageviews}</td>
            </tr>
            <tr>
              <th>찜하기</th>
              <td>{androidAddToFavorites}</td>
              <td>{iosAddToFavorites}</td>
            </tr>
          </tbody>
        </table>
      </>
    )
  }

  renderModal() {
    const { statProduct: { product } } = this.props;
    const { isClicked, productOptions } = this.state;
    return (
      <Dialog open={isClicked} fullWidth onBackdropClick={() => this.setState({ isClicked: false })}>
        <DialogActions id={`admin-product-dialog-${product._id}`}>
          <h2 style={{ marginLeft: '6px', fontWeight: 'bold', fontSize: '20px' }}>{product.name}</h2>
          <div style={{ flex: '1 1 auto' }}></div>
          <Button onClick={() => this.setState({ isClicked: false })}>
            <Close />
          </Button>
        </DialogActions>
        <DialogContent>
          {this.renderProduct()}
          <HighchartsReact highcharts={HighCharts} options={productOptions} />
        </DialogContent>
      </Dialog>
    )
  }

  render() {
    const { statProduct: { product } } = this.props;
    const { isClicked } = this.state;
    return (
      <Card style={styles.card}>
        <CardContent>
          <h5 style={{ fontSize: '20px', fontWeight: 'bold' }}>{product.name}</h5>
          {this.renderProduct()}
        </CardContent>
        <CardActions>
          <Button onClick={() => this.setState({ isClicked: !isClicked })}>
            <span>상세보기</span>
          </Button>
        </CardActions>
        {this.renderModal()}
      </Card>
    )
  }
}