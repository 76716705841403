import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';

import { actions, loadEntities } from 'react-shared';
import ShopTabDropdown from './ShopTabDropdown';
import ShopTabSubTab from './ShopTabSubTab';
import Spinner from '../../../components/Spinner';
import SellerInfo from '../../../nativeComponents/SellerInfo';
import Popup from '../../../nativeComponents/Popup';

import Styles from '../../../constants/Styles';
import Constants from '../../../constants/Constants';
import Colors from '../../../constants/Colors';


const useStyles = () => ({
  tabs: {
    backgroundColor: '#fff',
    paddingBottom: 0,
  },
  indicator: {
    backgroundColor: '#000',
    height: 3,
  },
  tab: {
    color: '#000',
    opacity: 0.4,
    minWidth: 50,
    paddingLeft: 18,
    paddingRight: 18,
  },
  tabSelected: {
    opacity: 1,
  },
  tabWrapper: {
    height: 18,
    marginTop: 13,
    marginBottom: 13,
    ...Styles.SFUITextHeavy,
    fontSize: 15,
  },
  rippleVisible: {
    opacity: 1,
    color: Colors.touchEffectColorWhite,
    animation: '0ms',
  },
});

const stylesHtml = {
  appBar: {
    top: Constants.mainHeaderHeight,
    shadows: ['none'],
  },
  appBarWithSmartBanner: {
    top: Constants.mainHeaderHeight + Constants.smartBannerHeight,
    shadows: ['none'],
  },
  tabbarBorderBottom: {
    position: 'absolute',
    bottom: 0,
    height: 1,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export class ShopPage extends React.Component {
  state = {
    index: 0,
    currentTab: '',
    routes: [],
    // Settings for marketing popup
    showPopup: false,
    popup: null,
  };

  componentDidMount() {
    this.loadData();
    this.updateTabRoutes();

    // Marketing popup
    const { loadMarketingPopups } = this.props;
    loadMarketingPopups();
    this.showPopup();
    window.addEventListener('focus', this.onFocus);

    const { router } = this.props;
    this.backListener = router.listen(location => {
      if (location.action === 'POP') {
        const { categoryId } = this.props;
        const { routes, currentTab } = this.state;
        if (routes.length === 0) {
          return;
        }

        if (currentTab === categoryId) {
          return;
        }

        let index = routes.findIndex(element => element.key === categoryId);
        if (index === -1) {
          index = 0;
        }

        this.setState({
          index,
          currentTab: routes[index].key,
        });
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.onFocus);
    this.setState = () => null;
    this.backListener();
  }

  componentDidUpdate(prevProps) {
    const { categories } = this.props;

    if (prevProps.categories.length === 0 && categories.length > 0) {
      this.updateTabRoutes();
    }

    const { marketingPopups = [] } = this.props;
    const { marketingPopups: prevMarketingPopups = [] } = prevProps;
    if (prevMarketingPopups.length === 0 && marketingPopups.length > 0) {
      this.showPopup();
    }
  }

  updateTabRoutes() {
    const { categories, categoryId, router, brandNm } = this.props;
    if (categories.length <= 0) {
      return;
    }

    const routes = categories.map(category => {
      if (!category.children) {
        category.children = [{
          virtual: true,
          cateCd: category.cateCd,
          cateNm: category.cateNm,
        }];
      }

      return {
        key: category.cateCd,
        title: category.cateNm,
        subRoutes: category.children.map(child => ({
          virtual: child.virtual ? child.virtual : false,
          key: child.cateCd,
          title: child.cateNm,
        })),
      };
    });

    if (brandNm) {
      let routeBrand = routes.find(element => element.key === '005');
      let subRouteBrand = routeBrand.subRoutes.find(element => element.title.toUpperCase() === brandNm.toUpperCase());
      if (!subRouteBrand) {
        router.replace('/shop?categoryId=005');
        window.location.reload();
        return;
      }
      router.replace(`/shop?categoryId=005&subCategoryId=${subRouteBrand.key}`);
      window.location.reload();
      return;
    }

    let index = routes.findIndex(element => element.key === categoryId);
    if (index === -1) {
      index = 0;
    }

    this.setState({
      routes,
      index,
      currentTab: categories[index].cateCd,
    });
  }

  loadData() {
    const { getProductCategoryList } = this.props;
    getProductCategoryList();
  }

  loadProducts = async (query, nextPage) => {
    const { getProductListByCategory } = this.props;
    try {
      // console.log('ShopPage getProductListByCategory query=', query);
      // eslint-disable-next-line no-unused-vars
      const ret = await getProductListByCategory(query.cateCd, query, nextPage);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('ShopPage getProductListByCategory error=', error);
    }
  }

  onChange = (event, index) => {
    const { routes } = this.state;
    if (routes.length <= 0) {
      return;
    }

    this.props.router.push(`/shop?categoryId=${routes[index].key}`);
    this.setState({
      index,
      currentTab: routes[index].key,
    });
  }

  onFocus = () => {
    this.showPopup(false);
  }

  showPopup = (closeOnly = false) => {
    const {
      marketingPopups,
    } = this.props;

    if (closeOnly || marketingPopups.length <= 0) {
      document.body.style.overflow = null;
      this.setState({ showPopup: false, popup: null });
      return;
    }

    const userDisabledList = JSON.parse(sessionStorage.getItem(Constants.marketingPopupStorageKey) || '[]');
    const enabledPopups = marketingPopups.filter(popup => {
      return !popup.userClosed && !userDisabledList.includes(popup._id) && popup.displayTab === 'shop';
    });

    // Open popup screen
    if (enabledPopups.length > 0) {
      document.body.style.overflow = 'hidden';
      this.setState({ showPopup: true, popup: enabledPopups[0] });
    } else {
      document.body.style.overflow = null;
      this.setState({ showPopup: false, popup: null });
    }
  }

  renderScene = route => {
    const {
      getProductListByCategory,
      likeProduct, unlikeProduct, ...props
    } = this.props;
    const { currentTab } = this.state;

    const categoryName = route.title;

    if (categoryName === 'PRE ORDER') {
      return (
        <ShopTabSubTab
          loadProducts={this.loadProducts}
          tabKey={route.key}
          categoryName={route.title}
          tabRoutes={route.subRoutes}
          likeProduct={likeProduct}
          unlikeProduct={unlikeProduct}
          shopScreenTab={currentTab}
          {...props}
        />
      );
    }

    return (
      <ShopTabDropdown
        loadProducts={this.loadProducts}
        tabKey={route.key}
        categoryName={route.title}
        tabRoutes={route.subRoutes}
        likeProduct={likeProduct}
        unlikeProduct={unlikeProduct}
        shopScreenTab={currentTab}
        {...props}
      />
    );
  };

  renderPopup = () => {
    const { popup = null } = this.state;
    const { closeMarketingPopup, router, historySwitch, auth } = this.props;

    if (!popup) {
      return null;
    }

    return (
      <Popup
        {...popup}
        onPopupClosedCallback={this.showPopup}
        closeMarketingPopup={closeMarketingPopup}
        historySwitch={historySwitch}
        auth={auth}
        router={router}
      />
    );
  }

  render() {
    const { index, routes, showPopup } = this.state;
    const { classes, router, showSmartBanner } = this.props;
    const appBarStyle = showSmartBanner ? stylesHtml.appBarWithSmartBanner : stylesHtml.appBar;

    if (routes.length <= 0) {
      return (<Spinner />);
    }

    return (
      <div>
        <AppBar position="sticky" style={appBarStyle} elevation={0}>
          <Tabs
            value={index}
            onChange={this.onChange}
            variant="scrollable"
            classes={{
              root: classes.tabs,
              indicator: classes.indicator,
            }}
          >
            {routes.map(route => (
              <Tab
                key={route.key}
                label={route.title}
                classes={{
                  root: classes.tab,
                  selected: classes.tabSelected,
                  wrapper: classes.tabWrapper,
                }}
                TouchRippleProps={{ classes: {
                  rippleVisible: classes.rippleVisible,
                } }}
              />
            ))}
            <div style={stylesHtml.tabbarBorderBottom} />
          </Tabs>
        </AppBar>

        {routes.map((route, routeIndex) => (
          <TabPanel className={classes.tabs} key={route.key} value={routeIndex} index={index}>
            {this.renderScene(route)}
          </TabPanel>
        ))}

        <SellerInfo
          onPressTerms={() => router.push('/legal/terms')}
          onPressPrivacy={() => router.push('/legal/privacy')}
        />

        {showPopup && (this.renderPopup())}
      </div>
    );
  }
}

export default withStyles(useStyles)(connect(
  (state, ownProps) => {
    const parsed = queryString.parse(ownProps.location.search.substring(1));
    const { categoryId, subCategoryId, brandNm } = parsed;

    return {
      auth: state.auth,
      historySwitch: state.settings.historySwitch,
      categories: state.entities.shop.categories,
      fetchStatus: state.fetchStatus,
      showSmartBanner: state.globalLayoutInfo.showSmartBanner,
      categoryId,
      subCategoryId,
      brandNm,
      marketingPopups: loadEntities(state, 'marketingPopups', 'marketingPopups').marketingPopups,
    };
  },
  actions,
)(ShopPage));
