import React from 'react';
import {
  StyleSheet, Text, View,
} from 'react-native';
import { connect } from 'react-redux';

import { actions } from 'shared';

import Styles from '../../../constants/Styles';
import Constants from '../../../constants/Constants';


const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Constants.viewHorizontalPadding,
    paddingTop: 84,
  },
  container_in: {
    paddingHorizontal: Constants.viewHorizontalPadding,
    paddingTop: 36,
    paddingBottom:36,
    marginBottom:84,
    backgroundColor:'#dddddd',
    borderRadius:16
  },
  title: {
    marginBottom: 84,
    color: '#000',
    fontSize: 28,
    lineHeight: 24,
    textAlign: 'center',
    ...Styles.pretendard600,
  },
  content: {
    color: '#000',
    fontSize: 16,
    lineHeight: 32,
    marginBottom:16,
    textAlign: 'center',
    ...Styles.pretendard400,
  },
  content_bold: {
    color: '#000',
    fontSize: 16,
    lineHeight: 24,
    marginBottom:16,
    textAlign: 'center',
    ...Styles.pretendard900,
  },
  content_light: {
    color: '#222',
    fontSize: 16,
    lineHeight: 20,
    marginBottom:8,
    textAlign: 'center',
    ...Styles.pretendard400,
  },
  footer: {
    marginTop: 10,
    color: '#00000080',
    fontSize: 16,
    lineHeight: 18,
    textAlign: 'center',
    ...Styles.pretendard400,
  },
});


class NoticePage extends React.Component {
  componentDidMount() {
    const {
      setGlobalState,
      logEventPageview,
    } = this.props;

    setGlobalState({ showMainBottomTabBar: false });
    logEventPageview({ page_name: 'NoticeMain' });
  }

  componentWillUnmount() {
    const { setGlobalState } = this.props;
    //setGlobalState({ showMainBottomTabBar: true });
  }

  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.title}>
          2차 서버 점검 안내
        </Text>
        <View style={styles.container_in}>
          <Text style={styles.content_bold}>
            안녕하세요 하트잇입니다.
          </Text>
          <Text style={styles.content}>
            사이트 안정화 및 더 나은 서비스를 제공하고자 서버 점검 작업을 실시합니다.
          </Text>
          <Text style={styles.content}>
            작업 시간 동안 홈페이지 및 앱 접속이 제한되므로, 불편하시겠지만 고객님들의 많은 양해를 부탁드리며 더 나은 모습으로 서비스를 제공 드릴 예정입니다.
          </Text>
          <Text style={styles.content}>
            반품 및 교환에 대한 문의는 아래로 문의 부탁드립니다.
          </Text>
          <Text style={styles.content_bold}>
            24.9.2 (월) 9:00 ~ 24.9.9 (월) 18:00
          </Text>
          <Text style={styles.content_light}>
            T. 02-518-7795
          </Text>
          <Text style={styles.content_light}>
            E. CS@heartit.kr
          </Text>
          <Text style={styles.content_light}>
            카카오톡 채널 : 하트잇
          </Text>
        </View>

        <Text style={styles.footer}>
        HAVIT @ Corp. All rights reserved.
        </Text>
      </View>
    );
  }
}


export default connect(
  state => ({}),
  actions,
)(NoticePage);
