import React from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheet, View, Text, TextInput,
} from 'react-native';

import { Styles, Colors, Constants } from '../constants';
import { RadioButtonIcon } from '../icons-v2';
import AddressSearch from './AddressSearch';
import Button from './Button';
import CheckBox from './CheckBox';

import { initAlert, renderAlert } from '../components/AlertDialog';


const placeholderColor = 'rgba(0, 0, 0, 0.2)'


const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    marginEnd: 2,
  },
  infoRow: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginStart: 20,
  },
  addressRow: {
    marginTop: 16,
  },
  infoTitle: {
    fontSize: 14,
    lineHeight: 14,
    color: '#000',
    opacity: 0.5,
    marginBottom: 16,
    ...Styles.pretendard400,
  },
  infoContents: {
    width: '100%',
    fontSize: 16,
    lineHeight: 19.2,
    padding: 0,
    paddingBottom: 8,
    justifyContent: 'center',
    textAlign: 'left',
    color: '#000',
    borderBottomWidth: 0.5,
    borderBottomColor: '#000',
    ...Styles.pretendard400,
    outlineStyle: 'none',
  },
  zonecodeContainer: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
  },
  infoTitleAddress: {
    marginBottom: 4,
  },
  infoContentsZonecode: {
    flex: 1,
  },
  addressSearchButton: {
    width: 120,
    height: 40,
    padding: 0,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 0.5,
    borderColor: '#000',
    marginStart: 10,
  },
  addressSearchText: {
    color: '#000',
    fontSize: 14,
    lineHeight: 16.8,
    textAlign: 'left',
    textAlignVertical: 'top',
    ...Styles.pretendard600,
  },
  checkBox: {
  },
  checkBoxText: {
    fontSize: 14,
    lineHeight: 16.8,
    marginStart: 8,
    color: '#000',
    ...Styles.pretendard400,
  },
  checkBoxCheckedText: {
    ...Styles.pretendard600,
  },
  checkBoxRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 30,
  },
  buttonContainer: {
    flex: 1,
    alignSelf: 'stretch',
    flexDirection: 'row',
  },
  cancelSaveButtonContainer: {
    flex: 1,
    height: 60,
  },
  cancelSaveButton: {
    width: '100%',
    borderWidth: 0.5,
    borderColor: '#000',
    paddingHorizontal: 25,
    paddingVertical: 6,
  },
  cancelSaveButtonText: {
    color: '#000',
    fontSize: 11,
    lineHeight: 21,
    textAlign: 'center',
    textAlignVertical: 'top',
    ...Styles.appleSDGothicNeoRegular,
  },
  radioButtonActive: {
    padding: 0,
    margin: 0,
    borderWidth: 0,
    marginEnd: 8,
  },
  radioButtonText: {
    fontSize: 14,
    lineHeight: 16.8,
    textAlign: 'left',
    color: '#000',
    ...Styles.pretendard600,
  },
});


class ShippingAddressEditForm extends React.Component {
  state = {
    showAddressSearch: false,
  };

  shippingTitleInputRef = null;

  shippingNameInputRef = null;

  shippingAddressSubInputRef = null;

  shippingCellPhoneInputRef = null;

  constructor(props) {
    super(props);

    initAlert(this);
  }


  componentDidMount() {
    const { navigation } = this.props;
    if (this.shippingTitleInputRef) {
      this.shippingTitleInputRef.focus();
    }
  }

  onCloseAddressSearch = () => {
    this.setState({ showAddressSearch: false });
  };

  onSelectAddress = addressInfo => {
    const { onDataChanged } = this.props;
    const { zoneCode, roadAddress } = addressInfo;
    // console.log('ShippingAddressEditForm addressInfo=', addressInfo);

    onDataChanged({
      shippingZonecode: zoneCode,
      shippingAddress: roadAddress,
      isValid: false,
    });

    this.setState({ showAddressSearch: false }, () => {
      if (this.shippingAddressSubInputRef) {
        this.shippingAddressSubInputRef.focus();
      }
    });
  };

  validateInputField() {
    // console.log('ShippingAddressEditForm validateInputField');
    const {
      data = {},
    } = this.props;

    const {
      shippingTitle = '',
      shippingName = '',
      shippingZonecode = '',
      shippingAddress = '',
      shippingAddressSub = '',
      shippingCellPhone = '',
    } = data;

    if (!shippingTitle) {
      this.alert('배송지 이름을 입력하지 않았습니다.');
      return false;
    }

    if (!shippingName) {
      this.alert('수령인 이름을 입력하지 않았습니다.');
      return false;
    }

    if (!shippingZonecode) {
      this.alert('우편번호를 입력하지 않았습니다.');
      return false;
    }

    if (!shippingAddress) {
      this.alert('주소를 입력하지 않았습니다.');
      return false;
    }

    if (!shippingAddressSub) {
      this.alert('상세주소를 입력하지 않았습니다.');
      return false;
    }

    if (!shippingCellPhone) {
      this.alert('연락처를 입력하지 않았습니다.');
      return false;
    }

    return true;
  }

  renderAddressSearch() {
    const { showAddressSearch } = this.state;

    if (!showAddressSearch) {
      return null;
    }

    return (
      <AddressSearch
        onClose={this.onCloseAddressSearch}
        onPressItem={this.onSelectAddress}
      />
    );
  }

  render() {
    const {
      data = {},
      containerStyle,
      onDataChanged,
      onPressSave,
      onPressConfirm,
      onPressCancel,
      showCheckBoxSetDefault,
      showCheckBoxSaveToShippingAddress,
      isNewAddress,
      profileEditStyle,
    } = this.props;

    const {
      defaultFl = 'n',
      newDefaultFl = false,
      shippingTitle = '',
      shippingName = '',
      shippingZonecode = '',
      shippingAddress = '',
      shippingAddressSub = '',
      shippingCellPhone = '',
      saveToShippingAddressList = false,
    } = data;

    if (defaultFl === 'y' && !newDefaultFl) {
      onDataChanged({ newDefaultFl: true });
    }

    if (showCheckBoxSaveToShippingAddress) {
      if (newDefaultFl && !saveToShippingAddressList) {
        onDataChanged({ saveToShippingAddressList: true });
      }
    }

    const phoneNumber = shippingCellPhone.replace(/-/g, '');

    const onPressAddressSearchButton = () => {
      // navigateToAddressSearchScreen(navigation,
      //   addressInfo => {
      //     const { zoneCode, roadAddress } = addressInfo;
      //     // console.log('ShippingAddressEditForm addressInfo=', addressInfo);
      //     onDataChanged({
      //       shippingZonecode: zoneCode,
      //       shippingAddress: roadAddress,
      //       isValid: false,
      //     });
      //   });
      this.setState({ showAddressSearch: true });
    };

    const infoRowStyle = (profileEditStyle) ? {...styles.infoRow, marginStart: 0} : styles.infoRow;

    return (
      <View style={[styles.container, containerStyle]}>
        {!profileEditStyle && (
          <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
            <Button
              containerStyle={styles.radioButtonActive}
              innerComponent={(<RadioButtonIcon checked={true} />)}
              onPress={() => {}}
            />
            {!isNewAddress && (defaultFl === 'y' ? (
              <Text style={styles.radioButtonText}>기본 배송지</Text>
            ) : (
              <Text style={styles.radioButtonText}>추가 배송지</Text>
            ))}
            {isNewAddress && (
              <Text style={styles.radioButtonText}>새로운 배송지 추가</Text>
            )}
          </View>
        )}

        <View style={infoRowStyle}>
          <Text style={styles.infoTitle}>배송지 이름</Text>
          <TextInput
            ref={ref => { this.shippingTitleInputRef = ref; }}
            style={styles.infoContents}
            value={shippingTitle}
            onChangeText={text => onDataChanged({ shippingTitle: text, isValid: false })}
            placeholder="배송지 이름 입력"
            placeholderTextColor={placeholderColor}
            onSubmitEditing={() => {
              if (this.shippingNameInputRef) {
                this.shippingNameInputRef.focus();
              }
            }}
          />
        </View>

        <View style={{ height: 40 }} />

        <View style={infoRowStyle}>
          <Text style={styles.infoTitle}>수령인 이름</Text>
          <TextInput
            ref={ref => { this.shippingNameInputRef = ref; }}
            style={styles.infoContents}
            value={shippingName}
            onChangeText={text => onDataChanged({ shippingName: text, isValid: false })}
            onSubmitEditing={onPressAddressSearchButton}
            placeholder="수령인 이름 입력"
            placeholderTextColor={placeholderColor}
          />
        </View>

        <View style={{ height: 40 }} />

        <View style={infoRowStyle}>
          <Text style={[styles.infoTitle, styles.infoTitleAddress]}>주소</Text>
          <View style={styles.zonecodeContainer}>
            <TextInput
              style={[styles.infoContents, styles.infoContentsZonecode]}
              maxLength={5}
              value={shippingZonecode}
              editable={false}
              placeholder="우편번호 입력"
              placeholderTextColor={placeholderColor}
            />
            <Button
              containerStyle={styles.addressSearchButton}
              titleStyle={styles.addressSearchText}
              onPress={onPressAddressSearchButton}
              title="우편번호 검색"
            />
          </View>
        </View>

        <View style={[infoRowStyle, styles.addressRow]}>
          <TextInput
            style={styles.infoContents}
            value={shippingAddress}
            editable={false}
            placeholder="주소 입력"
            placeholderTextColor={placeholderColor}
          />
        </View>

        <View style={[infoRowStyle, styles.addressRow]}>
          <TextInput
            ref={ref => { this.shippingAddressSubInputRef = ref; }}
            style={styles.infoContents}
            placeholder="상세주소 입력"
            placeholderTextColor={placeholderColor}
            value={shippingAddressSub}
            onChangeText={text => onDataChanged({ shippingAddressSub: text, isValid: false })}
            onSubmitEditing={() => {
              if (this.shippingCellPhoneInputRef) {
                this.shippingCellPhoneInputRef.focus();
              }
            }}
          />
        </View>

        <View style={{ height: 40 }} />

        <View style={infoRowStyle}>
          <Text style={styles.infoTitle}>연락처</Text>
          <TextInput
            ref={ref => { this.shippingCellPhoneInputRef = ref; }}
            style={styles.infoContents}
            maxLength={11}
            value={phoneNumber}
            keyboardType="number-pad"
            onChangeText={text => {
              onDataChanged({ shippingCellPhone: text, isValid: false });
            }}
            placeholder="연락처 숫자 입력"
            placeholderTextColor={placeholderColor}
          />
        </View>

        { (defaultFl !== 'y' && showCheckBoxSetDefault)
          && (
            <View style={[infoRowStyle, styles.checkBoxRow]}>
              <CheckBox
                baseStyle={styles.checkBox}
                checked={newDefaultFl}
                fixed={defaultFl === 'y'}
                onPress={isChecked => onDataChanged({ newDefaultFl: isChecked })}
              />
              <Text style={[styles.checkBoxText, newDefaultFl && styles.checkBoxCheckedText]}>기본 배송지로 지정</Text>
            </View>
          )
        }

        { showCheckBoxSaveToShippingAddress
          && (
            <View style={[infoRowStyle, styles.checkBoxRow]}>
              <Text style={styles.infoTitle} />
              <CheckBox
                baseStyle={styles.checkBox}
                checked={saveToShippingAddressList}
                onPress={isChecked => onDataChanged({ saveToShippingAddressList: isChecked })}
              />
              <Text style={[styles.checkBoxText, saveToShippingAddressList && styles.checkBoxCheckedText]}>배송지 목록에 추가</Text>
            </View>
          )
        }

        <View style={{ height: 40 }} />

        {this.renderAddressSearch()}
        {renderAlert(this)}
      </View>
    );
  }
}


ShippingAddressEditForm.propTypes = {
  profileEditStyle: PropTypes.bool,
};


ShippingAddressEditForm.defaultProps = {
  profileEditStyle: false,
};


export default ShippingAddressEditForm;
