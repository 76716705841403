import React from 'react';
import {
  StyleSheet, View, Text,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';

import { Styles } from '../../../constants';

import {
  InputForm,
  PhoneVerificationForm,
  TouchableHighlightWithContainer,
} from '../../../components-v2';


import TabBase from './TabBase';


const styles = StyleSheet.create({
  container: {
  },
  inputContainer: {
    marginTop: 40,
  },
  resultContainer: {
    marginTop: 60,
  },
  resultText: {
    ...Styles.pretendard400,
    fontSize: 20,
    lineHeight: 28,
  },
  resultTextId: {
    ...Styles.pretendard600,
  },
  returnButtonContainer: {
  },
  returnButtonText: {
    textAlign: 'center',
    fontSize: 16,
    lineHeight: 19.2,
    color: '#fff',
    backgroundColor: '#000',
    paddingVertical: 10.5,
    ...Styles.pretendard600,
  }
});


class FindIdTab extends TabBase {
  inputNameRef = null;
  inputPhoneRef = null;

  requestTelVerificationCodeCallback = async phone => {
    const {
      requestFindEmailWithSMSVerificationCode,
    } = this.props;
    const { name } = this.state;

    try {
      const resp = await requestFindEmailWithSMSVerificationCode(name, phone);
      return resp;
    } catch (e) {
      console.error(e);

      if (e.status === 404) {
        e.showAlert = false;
        this.alert('가입되어 있지 않은 번호입니다');
      }
      throw e;
    }
  };

  verifyTelVerificationCodeCallback = async (phone, code) => {
    const {
      verifyFindEmailWithSMSVerificationCode,
    } = this.props;
    const { name } = this.state;

    this.setState({
      nameErrorMessage: '',
      phoneErrorMessage: '',
    });

    const resp = await verifyFindEmailWithSMSVerificationCode(name, phone, code);

    this.setState({
      id: resp.email,
      verified: true,
      isEnabledBottomButton: false,
    });
  };

  componentDidMount() {
    if (this.inputNameRef) {
      this.inputNameRef.focus();
    }
  }

  componentDidUpdate(prevProps) {
    const { focused } = this.props;
    if (!prevProps.focused && focused) {
      if (this.inputNameRef) {
        this.inputNameRef.focus();
      }
    }
  }

  showPhoneVerificationError = msg => {
    this.setState({ phoneErrorMessage: msg });
  }

  validateInput() {
    if (!this.inputNameRef.validate()) {
      return false;
    }

    if (!this.inputPhoneRef.validate()) {
      return false;
    }

    return true;
  }

  renderNameInput() {
    const { name } = this.state;

    return (
      <InputForm
        title='이름'
        placeholder='가입시 입력한 이름을 입력해주세요.'
        value={name}
        onInputRef={ref => { this.inputNameRef = ref; }}
        onChangeText={val => this.setState({ name: val })}
        onSubmitEditing={() => this.inputPhoneRef.focus()}
        enableErrorMessage
        minLength={2}
        required
        />
    );
  }

  renderPhoneInput() {
    const onChange = value => {
      const {
        phone,
        phoneVerified,
      } = value;
      this.setState({
        phone,
        phoneVerified,
      })
    };

    return (
      <PhoneVerificationForm
        onRef={ref => { this.inputPhoneRef = ref; }}
        onChange={onChange}
        requestTelVerificationCodeCallback={this.requestTelVerificationCodeCallback}
        verifyTelVerificationCodeCallback={this.verifyTelVerificationCodeCallback}
        />
    );
  }

  renderInputForm() {
    return (
      <View style={styles.inputContainer}>
        {this.renderNameInput()}
        <View style={{ height: 40 }} />
        {this.renderPhoneInput()}
      </View>
    )
  }

  onPressBottomButton() {
    const { router } = this.props;
    router.goBack();
  }

  renderResultView() {
    const { router } = this.props;
    const { id = '' } = this.state;

    return (
      <View style={styles.resultContainer}>
        <Text style={[styles.resultText, { marginBottom: 20 }]}>아이디 찾기 결과입니다.</Text>
        <Text style={styles.resultText}>회원님의 아이디는</Text>
        <Text style={[styles.resultText, { marginBottom: 20}]}>
          <Text style={styles.resultTextId}>{id}</Text>
          &nbsp;입니다.
        </Text>
        <TouchableHighlightWithContainer
          containerStyle={styles.returnButtonContainer}
          onPress={() => router.goBack()}
        >
          <Text style={styles.returnButtonText}>로그인 페이지로 돌아가기</Text>
        </TouchableHighlightWithContainer>
      </View>
    );
  }

  renderContent() {
    const { verified } = this.state;

    return (
      <View style={styles.container}>
        { verified ? this.renderResultView() : this.renderInputForm()}
      </View>
    )
  }
}


export default connect(
  state => ({
    showSmartBanner: state.globalLayoutInfo.showSmartBanner,
  }),
  actions
)(FindIdTab);
