import * as React from 'react';

function SvgIcLikeSelectedHalf(props) {
  return (
    <svg width={14} height={14} {...props}>
      <g
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <path
          stroke="#000"
          d="M7 12.229l1.412-1.38 2.776-2.713a2.848 2.848 0 000-4.094 3.01 3.01 0 00-4.188 0"
          opacity={0.251}
        />
        <path
          fill="#EE2424"
          fillOpacity={0.6}
          stroke="#EE2424"
          strokeLinecap="round"
          d="M7 4.042a3.01 3.01 0 00-4.188 0 2.848 2.848 0 000 4.094L7 12.229"
        />
      </g>
    </svg>
  );
}

export default SvgIcLikeSelectedHalf;
