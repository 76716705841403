import assign from 'lodash/assign';
import get from 'lodash/get';
import { normalize } from 'normalizr';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';
import { getNextPageUrl } from '../util';

// admin actions for notices
export function adminLoadNotices() {
  const key = 'adminNotices';

  return createFetchAction({
    type: 'ADMIN_LOAD_NOTICES',
    endpoint: '/api/v2/admin/notice',
    transform: ({ json, response }) => assign(normalize(json, schemas.adminNotices), {
      nextPageUrl: getNextPageUrl(response),
    }),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}

export function adminCreateNotice({ notice }) {
  return createFetchAction({
    type: 'ADMIN_CREATE_NOTICE',
    endpoint: '/api/v2/admin/notice',
    method: 'POST',
    body: notice,
    transform: ({ json }) => normalize(json, schemas.adminNotice),
    success: () => ({
      pagination: {
        key: 'adminNotices',
        type: 'PREPEND',
      },
    }),
  });
}

export function adminGetNotice({ noticeId }) {
  return createFetchAction({
    type: 'ADMIN_GET_NOTICE',
    endpoint: `/api/v2/admin/notice/${noticeId}`,
    method: 'GET',
    doDispatch: false,
  });
}

export function adminUpdateNotice({ notice }) {
  return createFetchAction({
    type: 'ADMIN_UPDATE_NOTICE',
    endpoint: `/api/v2/admin/notice/${notice._id}`,
    method: 'PUT',
    body: notice,
    transform: ({ json }) => normalize(json, schemas.adminNotice),
  });
}

export function adminDeleteNotice({ noticeId }) {
  const payload = { entities: { adminNotices: {} } };
  payload.entities.adminNotices[noticeId] = null;

  return createFetchAction({
    type: 'ADMIN_DELETE_NOTICE',
    endpoint: `/api/v2/admin/notice/${noticeId}`,
    method: 'DELETE',
    success: {
      payload,
    },
  });
}
