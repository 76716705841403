import { Colors } from '../constants';
import { getThumbnailBackgroundColor } from './getThumbnailBackgroundColor';
import { convertKstToUtc, parseDate } from './dateTools';
import orderBy from 'lodash/orderBy';


function isProductInShop(product) {
  const { goodsNo } = product;

  return !!goodsNo;
}

function getProductId(product) {
  return product._id || product.godoGoodsNo || product.goodsNo;
}


function getProductShopId(product) {
  return product.godoGoodsNo || product.goodsNo;
}


function getProductSellPrice(product) {
  const {
    retails = null,
  } = product;

  let sellPrice = 0;

  if (retails !== null && retails.length > 0) {
    try {
      const {
        sellPrice: { amount: sellPriceAmount },
      } = retails[0];
      sellPrice = sellPriceAmount;
    } catch (e) {
      // console.error(e);
    }
  }

  if (isProductInShop(product)) {
    sellPrice = Number.parseInt(product.goodsPrice, 10);
  }

  return sellPrice;
}


function getProductInfo(product) {
  const {
    launchYmd,
    salesStartYmd,
    salesEndYmd,
    timestampNowYmd,
    goodsNo,
    soldOut,
    soldOutFl,
    stockCnt: stockCntStr,
  } = product;
  let { isSeasonOut } = product;

  const isPreOrder = !!launchYmd && launchYmd !== '0000-00-00';
  let launchDate = null;
  let salesStartDate = null;
  let salesEndDate = null;
  let timestampNow = Date.now();
  if (isPreOrder) {
    launchDate = convertKstToUtc(parseDate(launchYmd));
    salesStartDate = convertKstToUtc(parseDate(salesStartYmd, true));
    salesEndDate = convertKstToUtc(parseDate(salesEndYmd, true));
  }
  if (!!timestampNowYmd) {
      timestampNow = convertKstToUtc(parseDate(timestampNowYmd, true));
  }

  const isShopProduct = isProductInShop(product);
  const stockCnt = parseInt(stockCntStr, 10);
  const isSoldOut = (soldOut === 'y' || soldOutFl === 'y' || stockCnt === 0);
  isSeasonOut = !!isSeasonOut;

  const sellPrice = getProductSellPrice(product);

  return {
    goodsNo,
    isPreOrder,
    launchDate,
    salesStartDate,
    salesEndDate,
    timestampNow,
    isShopProduct,
    isSoldOut,
    isSeasonOut,
    sellPrice,
  };
}

function isHeartitProduct(product) {
  if (!!!product)
    return true;

  const goodsNo = product.godoGoodsNo || product.goodsNo;
  if (!goodsNo)
    return false;

  const memo = (product.memo) ? JSON.parse(product.memo) : null;
  const isHeartitProduct = !!!(product.crawled || (memo && memo.parser))

  return isHeartitProduct;
}

function getProductParser(product) {
  if (!!!product) {
    return null;
  }

  if (product.hasOwnProperty('crawlerInfo')
      && product.crawlerInfo
      && product.crawlerInfo.hasOwnProperty('parser')) {
    return product.crawlerInfo.parser;
  }

  const memo = (product.memo) ? JSON.parse(product.memo) : null;
  let parser = null;
  if (memo) {
    parser = (memo.parser) ? memo.parser : null;
  }
  if (!parser && product.parser) {
    parser = product.parser;
  }
  return parser;
}

function getCrawlerProductImageStyle(product, enableImageFitContain = true) {
  const parser = getProductParser(product);

  const getResizeMode = () => {
    if (enableImageFitContain) {
      if (isHeartitProduct(product)) {
        return 'contain';
      } else {
        switch (parser) {
          case '24s':
          case 'farfetch':
          case 'mytheresa':
          case 'cettire':
          case 'mustit':
          case 'ln-cc':
            return 'contain';

          default:
            break;
        }
      }

      return 'cover';
    }
  }

  const getBackgroundColor = () => {
    let backgroundColor = Colors.semiGray;
    if (parser) {
      backgroundColor = getThumbnailBackgroundColor(parser);
    }
    return backgroundColor;
  }

  return {
    'resizeMode': getResizeMode(),
    'backgroundColor': getBackgroundColor()
  };
}

function convertMongoProductToGodoShopListProduct(mongoProduct) {
  return {
    ...mongoProduct,
    goodsNm: mongoProduct.name,
    goodsNo: mongoProduct.godoGoodsNo,
    goodsDisplayFl: mongoProduct.godoGoodsDisplayFl ? 'y': 'n',
    goodsSellFl: mongoProduct.godoGoodsSellFl ? 'y': 'n',
    brandCd: mongoProduct.godoBrandCd,
    brandNm: mongoProduct.brand,
    reviewStar: mongoProduct.godoReviewStar,
    fixedPrice: mongoProduct.retails[0].orgPrice.amount + ".00",
    goodsPrice: mongoProduct.retails[0].sellPrice.amount + ".00",
    discPricePct: mongoProduct.retails[0].sale / 100,
    infoExchange: mongoProduct.godoExchangeInfo,
    soldOut: mongoProduct.soldOut,
    addInfo: JSON.stringify(mongoProduct.godoAddInfo),
    option: mongoProduct.godoOption,
    launchYmd: mongoProduct.launchYmd,
    salesStartYmd: mongoProduct.salesStartYmd,
    salesEndYmd: mongoProduct.salesEndYmd,
    memo: mongoProduct.crawlerInfo ? JSON.stringify({
      parser: mongoProduct.crawlerInfo.parser,
      crawled_url: mongoProduct.crawlerInfo.productUrl,
      fxrate: mongoProduct.crawlerInfo.fxrate.reduce((prev, f) => {
        prev[f.currency] = f.rate;
        return prev;
      }, {}),
    }) : null,
    goodsImageSrc: mongoProduct.mediaOrgLink,
    liked: mongoProduct.liked ? mongoProduct.liked: false,
  }
}

function convertMongoProductToGodoShopDetailProduct(mongoProduct) {
  return {
    ...convertMongoProductToGodoShopListProduct(mongoProduct),
    title: mongoProduct.name,
    imagesMagnify: [mongoProduct.mediaOrgLink]
  }
}

function convertCartProductToGodoShopDetailProduct(cartProduct) {
  return {
    ...cartProduct,
    option: cartProduct.godoOption,
    optionName: cartProduct.godoOptionName,
    title: cartProduct.goodsNm,
    imagesMagnify: cartProduct.imagesDetail
  }
}


function productsSortByPopularity(products, removeSoldOut=true) {
  products = products.filter(product => typeof product == 'object').map(product => {
    if (product.hasOwnProperty('godoViewCnts')) {
      product['viewCnt3'] = product['godoViewCnts']['3'];
      product['viewCnt15'] = product['godoViewCnts']['15'];
    } else {
      product['viewCnt3'] = 0;
      product['viewCnt15'] = 0;
    }

    return product;
  });
  products = orderBy(products, ['viewCnt3', 'viewCnt15', 'createdAt'], ['desc', 'desc', 'desc']);

  if (removeSoldOut) {
    // 품절 상품 제거
    products = [...products.filter(p => p.soldOut != 'y')];
  } else {
    // 품절 상품 맨 뒤로
    products = [...products.filter(p => p.soldOut != 'y'), ...products.filter(p => p.soldOut == 'y')];
  }

  return products;
}

export {
  getProductId,
  getProductShopId,
  getProductSellPrice,
  getProductInfo,
  isHeartitProduct,
  getProductParser,
  getCrawlerProductImageStyle,
  convertMongoProductToGodoShopListProduct,
  convertMongoProductToGodoShopDetailProduct,
  convertCartProductToGodoShopDetailProduct,
  productsSortByPopularity,
};
