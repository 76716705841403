import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Dimensions, StyleSheet, View, Text } from 'react-native';

// eslint-disable-next-line import/no-extraneous-dependencies
import { parseDate, getRemaingTime, convertKstToUtc } from 'shared/util';
import Styles from '../../../constants/Styles';
import Colors from '../../../constants/Colors';
import Constants from '../../../constants/Constants';

const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    paddingBottom: 20,
  },
  divider: {
    marginTop: 10,
    height: 10,
    backgroundColor: Colors.semiGray,
  },
  title: {
    height: 21,
    marginTop: 20,
    marginHorizontal: Constants.viewHorizontalPadding,
    ...Styles.SFUITextBold,
    fontSize: 18,
    lineHeight: 21,
  },
  contentContainer: {
    marginHorizontal: Constants.viewHorizontalPadding,
    marginTop: 22,
  },
  mainMessage: {
    height: 19,
    ...Styles.appleSDGothicNeoBold,
    fontSize: 16,
    lineHeight: 19,
  },
  progressBarContainer: {
    height: 6,
    marginTop: 15,
  },
  progressBarBackground: {
    height: 6,
    borderRadius: 3,
    backgroundColor: '#000',
    opacity: 0.1,
  },
  progressBarCurrent: {
    height: 6,
    position: 'absolute',
    top: 0,
    start: 0,
    borderRadius: 3,
    backgroundColor: 'rgb(20, 196, 137)',
  },
  saleDate: {
    height: 16,
    marginTop: 10,
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 13,
    lineHeight: 16,
    opacity: 0.7,
  },
  launchDate: {
    height: 17,
    marginTop: 6,
    ...Styles.appleSDGothicNeoSemiBold,
    fontSize: 14,
    lineHeight: 17,
  },
});


function getRemaingTimeText(targetDate) {
  const {
    minutes,
    hours,
    days,
  } = getRemaingTime(targetDate);

  let preOrderRemaingTimeText = '';
  if (days > 0) {
    preOrderRemaingTimeText += `${days}일 `;
  }

  if (hours > 0) {
    preOrderRemaingTimeText += `${hours}시간 `;
  }

  if (minutes > 0) {
    preOrderRemaingTimeText += `${minutes}분 `;
  }

  preOrderRemaingTimeText += '남음';

  return preOrderRemaingTimeText;
}

function getDateStr(date) {
  return `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`;
}


function renderProgressBar(progressRate) {
  const progressRateValue = Math.max(Math.min(progressRate, 1), 0);
  const MAX_PROGRESSBAR_WIDTH = windowWidth - Constants.viewHorizontalPadding * 2;
  let progressBarWidth = MAX_PROGRESSBAR_WIDTH * progressRateValue;
  progressBarWidth = Math.max(progressBarWidth, 6);

  const progressStyle = {
    width: progressBarWidth,
  };

  return (
    <View style={styles.progressBarContainer}>
      <View style={styles.progressBarBackground} />
      <View
        style={[
          styles.progressBarCurrent,
          progressStyle,
        ]}
      />
    </View>
  );
}


class PreOrderInfo extends React.PureComponent {
  render() {
    const {
      product: {
        launchYmd,
        salesStartYmd,
        salesEndYmd,
        timestampNowYmd,
      },
    } = this.props;

    const isPreOrder = !!launchYmd && launchYmd !== '0000-00-00';

    if (!isPreOrder) {
      return null;
    }

    const salesStartDate = convertKstToUtc(parseDate(salesStartYmd, true));
    const salesEndDate = convertKstToUtc(parseDate(salesEndYmd, true));
    const launchDate = convertKstToUtc(parseDate(launchYmd));
    const timestampNow = convertKstToUtc(parseDate(timestampNowYmd, true));

    let mainMessage = '곧 프리오더가 시작됩니다.';
    let progressRate = 0;

    if ((salesStartDate - timestampNow) < 0) {
      if ((salesEndDate - timestampNow) > 0) {
        mainMessage = getRemaingTimeText(salesEndDate);
        progressRate = (timestampNow - salesStartDate) / (salesEndDate - salesStartDate);
      } else {
        mainMessage = '프리오더가 종료되었습니다.';
        progressRate = 1;
      }
    }

    const startDateStr = getDateStr(salesStartDate);
    const endDateStr = getDateStr(salesEndDate);
    const launchDateStr = getDateStr(launchDate);

    return (
      <View style={styles.container}>
        <View style={styles.divider} />
        <Text key="title" style={styles.title}>PRE-ORDER</Text>
        <View style={styles.contentContainer}>
          <Text key="mainMessage" style={styles.mainMessage}>{mainMessage}</Text>
          {renderProgressBar(progressRate)}
          <Text key="saleDate" style={styles.saleDate}>
            {`주문가능 기간 ${startDateStr} - ${endDateStr}`}
          </Text>
          <Text key="launchDate" style={styles.launchDate}>
            <Text style={Styles.SFUITextSemiBold}>{launchDateStr}</Text>
            {' 부터 순차 배송 예정'}
          </Text>
        </View>
      </View>
    );
  }
}


export default PreOrderInfo;
