import React from 'react';
import { StyleSheet, Text, View, TouchableHighlight } from 'react-native';

import config from '../config';
import Colors from '../constants/Colors';
import Styles from '../constants/Styles';
import Constants from '../constants/Constants';
//FIXME
import { moveToReviewWrite } from '../containers/my/ReviewWritePage';
import ProductInfo from './ProductInfo';
import Button from './Button';
import { getOrderProductInfo } from './ProductInfo';

import Ic14ArrowRight from '../icons-v2/Ic14ArrowRight';


const buttonSpacer = 3;
const styles = StyleSheet.create({
  itemContainer: {
    flex: 1,
    flexDirection: 'column',
    marginHorizontal: Constants.viewHorizontalPadding,
    marginBottom: 20,
  },
  stateInfoContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  stateContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 6,
  },
  state: {
    fontSize: 14,
    color: 'black',
    lineHeight: 16.8,
    height: 17,
    ...Styles.pretendard600,
  },
  infoButtonContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  itemInfoContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  inquiryButton: {
    padding: Constants.touchEffectWidth,
    margin: -1 * Constants.touchEffectWidth,
    marginTop: 40 - 39.93,
    marginBottom: 1,
    borderRadius: Constants.touchEffectBorderRadius,
    borderWidth: 0,
  },
  inquiryButtonTextContainer: {
    flexDirection: 'row',
  },
  inquiryButtonText: {
    fontSize: 14,
    color: '#000',
    lineHeight: 16.8,
    height: 17,
    marginEnd: 2,
    ...Styles.pretendard400,
  },
  buttonsContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: buttonSpacer * -1,
    marginVertical: 20,
    backgroundColor: 'transparent',
  },
  priceContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
    alignItems: 'flex-end',
  },
  /* itemInfoContainer */
  itemImageContainer: {
    marginRight: 10,
    justifyContent: 'center',
  },
  itemImage: {
    width: 100,
    aspectRatio: 1,
    resizeMode: 'contain',
  },
  itemInfoTextContainer: {
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
  },
  itemNameBrandContainer: {
    flexDirection: 'column',
  },
  itemBrand: {
    height: 16 + 6,
    marginTop: 4,
    ...Styles.SFUIDisplaySemiBold,
    fontSize: 14,
    lineHeight: 16,
    textAlignVertical: 'top',
  },
  itemName: {
    height: 17 + 8,
    ...Styles.SFUIDisplayRegular,
    fontSize: 14,
    lineHeight: 17,
    textAlignVertical: 'top',
  },
  itemOptionContainer: {
    flexDirection: 'column',
  },
  itemOption: {
    ...Styles.appleSDGothicNeoRegular,
    lineHeight: 15,
    fontSize: 12,
    opacity: 0.6,
  },
  /* priceContainer */
  itemOrgPrice: {
    height: 20,
    ...Styles.SFUITextRegular,
    fontSize: 16,
    lineHeight: 20,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  itemSettlePrice: {
    height: 30,
    ...Styles.SFUITextSemiBold,
    fontSize: 24,
    lineHeight: 30,
  },
  /* buttonContainer */
  button: {
    flex: 1,
    height: 34,
    marginHorizontal: buttonSpacer / 2,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderWidth: 0.5,
    borderColor: '#000',
    paddingVertical: 6,
    paddingHorizontal: 16,
  },
  buttonText: {
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard400,
  },
});


function getButton(label, onPress) {
  return (
    <TouchableHighlight
      style={styles.button}
      underlayColor={Colors.touchEffectColorWhite}
      onPress={onPress}
    >
      <Text style={styles.buttonText}>
        { label }
      </Text>
    </TouchableHighlight>
  );
}

class OrderDetailItem extends React.Component {
  /*
  showChatInquiry = () => {
    const { navigation } = this.props;
    const chatUrl = Keys.chatInquiry.kakao.url;
    navigation.navigate('OutlinkModal', {
      url: chatUrl,
      title: '1:1 문의하기',
      backButtonStyle: 'close',
      product: null,
    });
  }
  */
  showChatInquiry = () => {
    const chatUrl = config.get('chatInquiryUrl');
    window.location.href = chatUrl;
  }

  moveToShippingInfo = () => {
    const { good } = this.props;
    const { deliveryTraceUrl = null } = good;

    if (deliveryTraceUrl) {
      window.open(deliveryTraceUrl);
    }
  }

  confirmOrder = () => {
    const { orderNo, good, getOrderDetail, settleUserOrder } = this.props;
    const yes = confirm("정말 구매확정을 진행하시겠습니까?\n구매확정을하면 교환/반품이 불가능합니다.");
    if (yes) {
      settleUserOrder(orderNo, [good.sno])
        .then(() => getOrderDetail(orderNo));
    }
  }

  requestExchange = () => {
    const { good, orderNo, router } = this.props;
    router.push({
      pathname: `/accounts/orderManage/exchange/${orderNo}`,
      search: `?selectedItem=${good.sno}`
    });
  }

  showExchangeInfo = () => {
  }

  requestReturn = () => {
    const { good, orderNo, router } = this.props;
    router.push({
      pathname: `/accounts/orderManage/refund/${orderNo}`,
      search: `?selectedItem=${good.sno}`
    });
  }

  showReturnInfo = () => {
  }

  showRefundInfo = () => {
  }

  showCancelInfo = () => {
  }

  renderOption = ({ item: option }) => (
    <Text
      style={styles.itemOption}
      numberOfLines={1}
      ellipsizeMode="tail"
    >
      { `${option.optionName} : ${option.optionValue}` }
    </Text>
  )

  render() {
    const { good, onPressProduct, orderNo, navigation } = this.props;

    return (
      <View style={styles.itemContainer}>
        <View style={styles.infoButtonContainer}>
          <View style={styles.stateInfoContainer}>
            <View style={styles.stateContainer}>
              <Text style={styles.state}>
                { good.orderStatusStr }
              </Text>
              <Button
                containerStyle={styles.inquiryButton}
                innerComponent={(
                  <View style={styles.inquiryButtonTextContainer}>
                    <Text style={styles.inquiryButtonText}>1:1 문의</Text>
                    <View style={{ marginTop: 1}}>
                      <Ic14ArrowRight />
                    </View>
                  </View>
                )}
                onPress={() => { this.showChatInquiry(); }}
              />
            </View>
          </View>

          <TouchableHighlight
            onPress={() => { onPressProduct(good.goodsNo); }}
            underlayColor={Colors.touchEffectColorWhite}
            style={[Styles.touchEffectContainer, Styles.touchEffect]}
          >
            <ProductInfo
              type="B"
              {...getOrderProductInfo(good)}
            />
          </TouchableHighlight>

          { !['p1', 'g1'].includes(good.orderStatus) && (
            <View style={styles.buttonsContainer}>
              { good.orderStatus.startsWith('d')
                && (getButton('구매확정', this.confirmOrder)) }

              { good.orderStatus.startsWith('d')
                && (getButton('배송조회', this.moveToShippingInfo)) }

              { good.orderStatus.startsWith('d')
                && (getButton('교환요청', this.requestExchange)) }

              { good.orderStatus.startsWith('d')
                && (getButton('반품요청', this.requestReturn)) }

              { good.orderStatus === 's1'
                && (getButton('리뷰 작성', () => { moveToReviewWrite(orderNo, good.sno, navigation); }))
              }

              { good.orderStatus.startsWith('r')
                && (getButton('환불 정보', this.showRefundInfo)) }

              { good.orderStatus.startsWith('e')
                && (getButton('교환 정보', this.showExchangeInfo)) }

              { good.orderStatus.startsWith('b')
                && (getButton('반품 정보', this.showReturnInfo)) }

              { good.orderStatus.startsWith('c')
                && (getButton('취소 정보', this.showCancelInfo)) }

            </View>
          )}
        </View>
      </View>
    );
  }
}

export default OrderDetailItem;
