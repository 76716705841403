import React from 'react';
import { StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';

import { actions, loadEntities } from 'shared';

import MemberInfoTabView from './MemberInfoTabView';


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});


class MemberInfoPage extends React.Component {
  isLoggedIn(props = null) {
    if (props === null) {
      const { auth } = this.props;
      // eslint-disable-next-line no-prototype-builtins
      return auth.hasOwnProperty('_id');
    }
    // eslint-disable-next-line no-prototype-builtins
    return props.auth.hasOwnProperty('_id');
  }

  componentDidMount() {
    const {
      setGlobalState,
      getUserSummary,
      getUserCouponList,
      getUserMileageList,
      logEventPageview,
    } = this.props;

    if (this.isLoggedIn()) {
      getUserSummary();
      getUserCouponList();
      getUserMileageList();
    }
    setGlobalState({ showMainBottomTabBar: false });

    logEventPageview({ page_name: 'MemberInfo' });
  }

  componentWillUnmount() {
    const { setGlobalState } = this.props;
    setGlobalState({ showMainBottomTabBar: true });
  }

  render() {
    return (
      <View style={styles.container}>
        <MemberInfoTabView
          {...this.props}
        />
      </View>
    );
  }
}


export function moveToMemberInfo(router, initialTab=null) {
  if (!router) {
    return;
  }

  router.push({
    pathname: '/accounts/memberInfo',
    state: { initialTab },
  });

}

export default connect(
  (state, { router }) => {
    let initialTab = null;

    if (router.location.hasOwnProperty('state')
        && router.location.state
        && router.location.state.hasOwnProperty('initialTab')) {
          initialTab = router.location.state.initialTab;
    }

    return {
      auth: state.auth,
      couponData: loadEntities(state, 'userCouponList', 'userCouponList'),
      mileageData: loadEntities(state, 'userMileageList', 'userMileageList'),
      initialTab,
    }
  },
  actions,
)(MemberInfoPage);
