import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from 'components/Spinner';
import { loadEntities } from 'react-shared';
import { Link } from 'react-router';
import Banner from 'components/admin/Banner';
import * as adminActions from 'react-shared/adminActions';

const styles = {
  container: {
    margin: '0 auto',
    maxWidth: '450px',
  },
};

export class ListBannerPage extends Component {
  componentDidMount() {
    const { banners, adminLoadBanners } = this.props;
    if (!banners || banners.length === 0) {
      adminLoadBanners();
    }
  }

  render() {
    const {
      banners,
      pagination: { isFetching },
    } = this.props;
    const bannersList = banners.sort((a, b) => b.id - a.id);
    return (
      <div style={styles.container}>
        <br />
        <Link className="btn btn-primary" to="/admin/banners/new">
          New Banner
        </Link>
        {bannersList.length > 0 && bannersList.map(banner => <Banner banner={banner} key={`banner_${banner.id}`} />)}
        {isFetching && <Spinner />}
      </div>
    );
  }
}

ListBannerPage.propTypes = {
  adminLoadBanners: PropTypes.func.isRequired,
  banners: PropTypes.array,
  // pagination: PropTypes.object,
};

ListBannerPage.defaultProps = {
  banners: {},
};

export const mapStateToProps = state => ({
  ...loadEntities(state, 'banners', 'banners'),
});

export default connect(
  mapStateToProps,
  adminActions,
)(ListBannerPage);
