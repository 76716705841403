import { normalize } from 'normalizr';
import qs from 'qs';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';

export * from '../actions';


// admin actions for editorials
export function adminLoadEditorials() {
  const key = 'adminEditorials';

  return createFetchAction({
    type: 'ADMIN_LOAD_EDITORIALS',
    endpoint: '/api/v2/admin/editorials',
    transform: ({ json }) => normalize(json, schemas.adminEditorials),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}

export function adminGetEditorial(editorialId) {
  return createFetchAction({
    type: 'ADMIN_GET_EDITORIAL',
    endpoint: `/api/v2/admin/editorials/${editorialId}`,
    transform: ({ json }) => normalize(json, schemas.adminEditorial),
  });
}

export function adminCreateEditorial(editorial) {
  return createFetchAction({
    type: 'ADMIN_CREATE_EDITORIAL',
    endpoint: '/api/v2/admin/editorials',
    method: 'POST',
    body: editorial,
    transform: ({ json }) => normalize(json, schemas.adminEditorial),
    success: () => ({
      pagination: {
        key: 'adminEditorials',
        type: 'PREPEND',
      },
    }),
  });
}

export function adminUpdateEditorial(editorialId, editorial) {
  // const payload = { entities: { adminEditorials: {} } };
  // payload.entities.adminEditorial[editorialId] = editorial;

  return createFetchAction({
    type: 'ADMIN_UPDATE_EDITORIAL',
    endpoint: `/api/v2/admin/editorials/${editorialId}`,
    method: 'PUT',
    body: editorial,
    transform: ({ json }) => normalize(json, schemas.adminEditorial),
    // success: {
    //   payload,
    // },
  });
}

export function adminDeleteEditorial(editorialId) {
  const payload = { entities: { adminEditorials: {} } };
  payload.entities.adminEditorials[editorialId] = null;
  return createFetchAction({
    type: 'ADMIN_DELETE_EDITORIAL',
    endpoint: `/api/v2/admin/editorials/${editorialId}`,
    method: 'DELETE',
    success: {
      payload,
      // payload: set({}, `entities.adminEditorials.${editorialId}`, null),
    },
  });
}

export function adminBucketNameForEditorial(editorialId, query) {
  return createFetchAction({
    type: 'ADMIN_GET_SIGNED_URL_FOR_EDITORIAL',
    endpoint: '/api/v2/admin/editorials/${editorialId}/s3/bucket_name',
    method: 'GET',
    doDispatch: false,
  });
}

export function adminGetSignedUrlForEditorial(editorialId, query) {
  const params = query ? `?${qs.stringify(query).replace('%20', '+')}` : '';
  return createFetchAction({
    type: 'ADMIN_GET_SIGNED_URL_FOR_EDITORIAL',
    endpoint: `/api/v2/admin/editorials/${editorialId}/s3/signed_url` + params,
    method: 'GET',
    doDispatch: false,
  });
}
