import React from 'react';
import {
  StyleSheet, View, Text, TouchableHighlight,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';

// eslint-disable-next-line import/no-extraneous-dependencies
import { parseDate } from 'shared/util';
import LikeButton from '../../../nativeComponents/LikeButton';
import Colors from '../../../constants/Colors';
import Styles from '../../../constants/Styles';
import Constants from '../../../constants/Constants';

export const height = Constants.shopProductFoooterHeight;


const styles = StyleSheet.create({
  footer: {
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#000',
    height,
  },
  footerButtonText: {
    height: 23,
    color: '#fff',
    ...Styles.appleSDGothicNeoBold,
    fontSize: 19,
    lineHeight: 23,
    marginTop: 18,
    marginBottom: 19,
  },

  footerButtonCommon: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  footerButtonCommonDisabled: {
    opacity: 0.45,
  },
  likeButton: {
    width: 44,
    height,
    paddingHorizontal: 10 - Constants.touchEffectWidth,
    paddingTop: 15 - Constants.touchEffectWidth,
    paddingBottom: 21 - Constants.touchEffectWidth,
  },
  cartButton: {
    flex: 1,
  },
  checkoutButton: {
    flex: 1,
  },
  soldOutText: {
    ...Styles.SFUITextBold,
  },
});


class Footer extends React.Component {
  render() {
    const {
      onPressCart, onPressCheckout,
      product = {}, style,
    } = this.props;

    const {
      soldOut,
      salesEndYmd,
    } = product;
    const isSoldOut = soldOut === 'y';

    let checkoutText = '바로 구매';
    let checkoutDisabled = false;
    if (isSoldOut) {
      checkoutText = 'SOLD OUT';
      checkoutDisabled = true;
    } else if (!!salesEndYmd && salesEndYmd[0] !== '0') {
      const salesEndDate = parseDate(salesEndYmd, true);
      if ((salesEndDate - Date.now()) < 0) {
        checkoutText = 'CLOSED';
        checkoutDisabled = true;
      }
    }

    return (
      <View style={[
        styles.footer,
        style,
      ]}
      >
        <TouchableHighlight
          underlayColor={Colors.touchEffectColorBlack}
          style={[
            styles.footerButtonCommon,
            checkoutDisabled && styles.footerButtonCommonDisabled,
            styles.cartButton,
          ]}
          onPress={onPressCart}
          disabled={checkoutDisabled}
        >
          <Text style={styles.footerButtonText}>장바구니</Text>
        </TouchableHighlight>

        <View
          style={[styles.footerButtonCommon, styles.likeButton]}
        >
          <LikeButton
            item={product}
            isShopProduct
            iconProps={{ fill: '#fff' }}
          />
        </View>


        <TouchableHighlight
          underlayColor={Colors.touchEffectColorBlack}
          style={[
            styles.footerButtonCommon,
            checkoutDisabled && styles.footerButtonCommonDisabled,
            styles.checkoutButton,
          ]}
          onPress={onPressCheckout}
          disabled={checkoutDisabled}
        >
          <Text style={[
            styles.footerButtonText,
            checkoutDisabled && styles.soldOutText,
          ]}
          >
            {checkoutText}
          </Text>
        </TouchableHighlight>
      </View>
    );
  }
}

export default Footer;
