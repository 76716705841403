import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, Dimensions, ActivityIndicator, View } from 'react-native';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions, loadEntities } from 'shared';

import MyOrdersItem from '../../nativeComponents/MyOrdersItem';
import BlankResult from '../../nativeComponents/BlankResult';

import Colors from '../../constants/Colors';
import LoadMoreButton from '../../nativeComponents/LoadMoreButton';


const { width: windowWidth, height: windowHeight } = Dimensions.get('window');
const MAX_COUNT_PER_PAGE = 2;


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  footer: {
  },
  header: {
  },
  empty: {
  },
  footerSpinnerContainer: {
    position: 'relative',
    width: windowWidth,
    height: windowHeight / 5,
    paddingVertical: 20,
    marginTop: 10,
    marginBottom: 10,
  },
  loadMoreContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 20,
  },
});

const webStyles = {
  buttonLoadMore: {
    width: 340,
    marginTop: 15,
    marginBottom: 15,
  },
};


function getTimeStr(timestamp) {
  const datetime = new Date(timestamp);
  const year = datetime.getFullYear();
  const month = datetime.getMonth() + 1;
  const date = datetime.getDate();

  return year + '-' + month + '-' + date;
}

export class MyOrdersPage extends React.Component {
  state = {
    isFetchingNextPage: false,
  };

  componentDidMount() {
    this.loadData(false);
  }

  componentDidUpdate(prevProps) {
    const { isGodoLoggedIn, pagination } = this.props;

    if (prevProps.pagination.isFetching && !pagination.isFetching) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isFetchingNextPage: false });
    }

    if (!prevProps.isGodoLoggedIn && isGodoLoggedIn) {
      this.loadData(false);
    }
  }

  loadData(nextPage) {
    const { getUserOrderList } = this.props;

    const currentTimestamp = Date.now();
    // NOTE: 조회 기간 기본 1년으로 지정
    const query = {
      startDate: getTimeStr(currentTimestamp - 1000 * 86400 * 365),
      endDate: getTimeStr(currentTimestamp),
      count: MAX_COUNT_PER_PAGE,
    };
    getUserOrderList(nextPage, query);
  }

  cancelUserOrder = orderNo => {
    const { cancelUserOrder, getOrderDetail } = this.props;

    this.setState({ isFetchingNextPage: true }, async () => {
      await cancelUserOrder(orderNo);
      getOrderDetail(orderNo);
    });
  }


  renderHeader = () => (
    <View style={styles.header} />
  );

  renderFooter = () => {
    const { pagination, userOrderList } = this.props;
    const { isFetchingNextPage } = this.state;

    if (pagination.nextPageUrl === null || !userOrderList || userOrderList.length === 0) {
      return (
        <View style={styles.footer}>
        </View>
      );
    }

    if (pagination.isFetching && isFetchingNextPage) {
      return (
        <View style={[styles.footer, styles.footerSpinnerContainer]}>
          <ActivityIndicator animating size="small" color="gray" />
        </View>
      );
    }

    return (
      <View style={[styles.footer, styles.loadMoreContainer]}>
        <LoadMoreButton
          style={webStyles.buttonLoadMore}
          onClick={() => {
            if (!pagination.nextPageUrl) {
              return;
            }
            this.setState({ isFetchingNextPage: true }, () => {
              this.loadData(true);
            });
          }}
          variant="outlined"
        />
      </View>
    );
  }


  renderOrder(order) {
    const { auth, historySwitch, router } = this.props;

    return (
      <GridListTile key={order.orderNo}>
        <MyOrdersItem
          order={order}
          router={router}
          auth={auth}
          historySwitch={historySwitch}
          cancelUserOrder={this.cancelUserOrder}
        />
        <View style={{ height: 10, backgroundColor: Colors.semiGray }} />
      </GridListTile>
    );
  }

  render() {
    const { router, userOrderList, pagination } = this.props;

    if (!pagination.isFetching && userOrderList.length === 0) {
      return (
        <BlankResult
          router={router}
          title='주문내역이 없습니다'
        />
      );
    }

    return (
      <View style={styles.container}>
        {this.renderHeader()}

        <GridList
          cellHeight="auto"
          cols={1}
          spacing={0}
        >
          {userOrderList.map(order => this.renderOrder(order))}
        </GridList>

        {this.renderFooter()}
      </View>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
    // eslint-disable-next-line no-prototype-builtins
    isGodoLoggedIn: state.entities.shop.hasOwnProperty('sessionId'),
    ...loadEntities(state, 'userOrderList', 'userOrderList'),
  }),
  actions,
)(MyOrdersPage);
