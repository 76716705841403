import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import FeedUploadCameraIcon from '../icons-v2/FeedUploadCameraIcon';
import FeedUploadPlusIcon from '../icons-v2/FeedUploadPlusIcon';
import Button from '../components-v2/Button';

import { Constants, Styles, Colors } from '../constants';


const styles = StyleSheet.create({
  container: {
    padding: 0,
    width: 112,
    height: 112,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 0.5,
    borderColor: '#cfcfcf',
  },
  containerLong: {
    padding: 0,
    width: 112,
    height: 149,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 0.5,
    borderColor: '#cfcfcf',
  },
  iconTextContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    opacity: 0.5,
  },
  iconContainer: {
    marginBottom: 8,
  },
  textContainer: {
  },
  textStyle: {
    fontSize: 13,
    lineHeight: 15.6,
    ...Styles.pretendard400,
  },
});


class StyleUploadButton extends React.PureComponent {

  renderInnerComponent() {
    const { type = 'camera' } = this.props;
    if (type == 'camera') {
      return (
        <View style={styles.iconTextContainer}>
          <View style={styles.iconContainer}>
            <FeedUploadCameraIcon />
          </View>
          <View style={styles.textContainer}>
            <Text style={styles.textStyle}>사진 첨부하기</Text>
          </View>
        </View>
      );
    } else {
      return (
        <View style={styles.iconTextContainer}>
          <View style={[styles.iconContainer, {marginBottom: 10}]}>
            <FeedUploadPlusIcon />
          </View>
          <View style={styles.textContainer}>
            <Text style={styles.textStyle}>상품 추가하기</Text>
          </View>
        </View>
      );
    }
  }

  render() {
    const { onPress } = this.props;
    const { type = 'camera' } = this.props;
    if (type === 'camera') {
      return (
        <Button
          containerStyle={styles.container}
          innerComponent={this.renderInnerComponent()}
          onPress={onPress}
        />
      );
    } else {
      return (
        <Button
          containerStyle={styles.containerLong}
          innerComponent={this.renderInnerComponent()}
          onPress={onPress}
        />
      );
    }
  }
}

export default StyleUploadButton;
