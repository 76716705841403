import React from 'react';
import {
  StyleSheet, View, Text,
} from 'react-native';

import { TouchableHighlightWithContainer } from '../../../components-v2';
import { Constants, Styles } from '../../../constants';
import { initAlert, renderAlert } from '../../../components/AlertDialog';

import _styles from '../../../css/modules/main.module.css';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  inputContainer: {
  },
  bottomContainer: {
    position: 'absolute',
    width:'100%',
    start: 0,
    end: 0,
    bottom: 0,
    backgroundColor: '#000',
    flexDirection: 'row',
    
  },
  buttonContainerStyle: {
    flex: 1,
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 20,
    paddingBottom: 21,
  },
  bottomButtonText: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    textAlign: 'center',
    color: '#ffffff',
  },
});

class TabBase extends React.Component {

  state = {
    isEnabledBottomButton: true,
    bottomButtonTitle: '취소',
  }

  constructor(props) {
    super(props);

    initAlert(this);
  }

  onPressBottomButton() {
  }

  renderContent() {
  }

  renderBottom() {
    const {
      bottomButtonTitle,
      isEnabledBottomButton
    } = this.state;

    let onPress = null;
    const buttonStyles = [
      styles.bottomButtonText,
    ];

    if (!isEnabledBottomButton) {
      return null;
    }

    if (isEnabledBottomButton) {
      buttonStyles.push(styles.bottomButtonTextEnabled);
      onPress = () => this.onPressBottomButton();
    }

    return (
      <View style={styles.bottomContainer}>
        <TouchableHighlightWithContainer
          containerStyle={styles.buttonContainerStyle}
          onPress={onPress}
        >
          <Text style={buttonStyles}>{bottomButtonTitle}</Text>
        </TouchableHighlightWithContainer>
      </View>
    )
  }

  render() {
    return (
      <div>
        <View style={styles.container}>
          {this.renderContent()}
          {renderAlert(this)}
        </View>
        {this.renderBottom()}
      </div>
    )
  }
}

export default TabBase;
