import React from 'react';
import { StyleSheet, View, Text } from 'react-native';

import { Constants, Styles } from '../../../constants';

import {
  Button,
} from '../../../components-v2';


const styleHtml = {
  blankContainer: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: `${-1 * (Constants.mainHeaderHeight + 44 + 10)}px`,
    marginBottom: `${-1 * Constants.bottomMargin}px`,
    overflow: 'hidden',
    touchAction: 'none',
  },
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: Constants.bottomTabBarHeight + Constants.bottomMargin,
  },
  blankContainer: {
    flex: 1,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: 240,
    margin: 'auto',
  },
  blankText: {
    marginBottom: 40,
    color: '#2c2c2e',
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
    ...Styles.pretendard400,
  },
  blankGoShoppingButton: {
    width: 200,
    paddingVertical: 10.5,
    borderWidth: 0,
    borderColor: 'transparent',
    backgroundColor: '#000',
    justifyContent: 'center',
    alignItems: 'center',
  },
  blankGoShoppingButtonText: {
    fontSize: 16,
    lineHeight: 19.2,
    color: '#fff',
    ...Styles.pretendard600,
  },
});

class BlankPage extends React.Component {
  render() {
    const {
      router,
    } = this.props;

    return (
      <div style={styleHtml.blankContainer}>
        <View style={styles.blankContainer}>
          <Text style={styles.blankText}>쇼핑백에 담긴 상품이 없습니다.</Text>
          <Button
            title="쇼핑하러가기"
            containerStyle={styles.blankGoShoppingButton}
            titleStyle={styles.blankGoShoppingButtonText}
            onPress={() => {router.push('/shop')}}
            />
        </View>
      </div>
    );
  }
}

export default BlankPage;
