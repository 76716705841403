/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheet, Text, View, TouchableHighlight,
} from 'react-native';

import CloudinaryImage from './CloudinaryImage';
import CloudinaryVideo from './CloudinaryVideo';
import InstagramProfileImage from '../nativeComponents/InstagramProfileImage';
import Button from '../components-v2/Button';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';


const hashtagLimit = 4;

const styleHtml = {
  profileImage: {
    borderColor: Colors.profileImageBorder,
    backgroundColor: '#cfcfca',
    borderRadius: 32,
    borderWidth: 0,
    height: 36,
    width: 36,
  },
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  mediaCountContainer: {
    position: 'relative',
  },
  mediaContainer: {
    position: 'relative',
  },
  countContainer: {
    position: 'absolute',
    bottom: 0,
    end: 0,
    backgroundColor: '#00000020',
    paddingStart: 4,
    paddingEnd: 4,
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  countText: {
    fontSize: 12,
    lineHeight: 14.4,
    color: '#fff',
    ...Styles.pretendard600,
  },
  hashtagContainer: {
    marginTop: 5,
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    margin: -2,
  },
  hashtagButton: {
    borderWidth: 0,
    padding: 0,
    marginHorizontal: 3,
    alignItems: 'center',
    justifyContent: 'center',
  },
  hashtagButtonText: {
    fontSize: 11,
    lineHeight: 13.2,
    fontWeight: '400',
    color: 'black',
    opacity: 0.5,
    ...Styles.pretendardVariable,
  },
  viewsCountContainer: {
    marginTop: 1,
  },
  brandText: {
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 14.4,
    ...Styles.pretendardVariable,
  },
  viewsCountText: {
    marginTop: 2,
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 14.4,
    ...Styles.pretendardVariable,
  },
  profileContainer: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 8,
    marginBottom: 4,
    alignItems: 'center',
  },
  profileText: {
    marginStart: 10,
    fontSize: 13,
    fontWeight: '600',
    lineHeight: 15.6,
    alignItems: 'center',
    flex: 1,
    ...Styles.pretendardVariable,
  },
  mediaImage: {
    resizeMode: 'cover',
    width: '100%',
  },
  mediaVideo: {
    width: '100%',
  },
});

function PostMedia(props) {
  const {
    inFocusedScreen,
    displayAspectRatio,
    mediaPublicId,
    resourceType,
  } = props;


  return resourceType === 'video' ? (
    <CloudinaryVideo
      key={`video${mediaPublicId}`}
      publicId={mediaPublicId}
      resizeMode="cover"
      style={styles.mediaVideo}
      aspectRatio={displayAspectRatio}
      //inFocusedScreen={inFocusedScreen}
      options={{
        crop: 'limit',
        format: 'jpg',
        height: 640,
        width: 640,
        start_offset: 0,
      }}
      thumbnailOnly={true}
    />
  ) : (
    <CloudinaryImage
      key={`image${mediaPublicId}`}
      publicId={mediaPublicId}
      options={{
        crop: 'limit',
        height: 640,
        width: 640,
      }}
      aspectRatio={displayAspectRatio}
      style={styles.mediaImage}
    />
  );
}


class StyleFeed extends React.Component {

  render() {
    const { router,
      containerStyle, enableViewsCount,
      hideHeartistName = false,
      styleFeed: post } = this.props;
    const { tags: hashtag=[], media: {0: m}, displayAspectRatio = 1 } = post;
    const mediaCount = post.media.length;
    const brandList = post.products.map(p => p.brand);
    const brandListLength = new Set(brandList).size;

    // console.log('StyleFeed post=', post);
    // console.log('StyleFeed displayAspectRatio=', displayAspectRatio);

    return (
      <TouchableHighlight
        key={post._id}
        underlayColor={Colors.touchEffectColorWhite}
        onPress={() => {
          router.push(`/style/feed/${post._id}`);
        }}
        style={[styles.container, containerStyle]}
      >
        <View>
          <View style={styles.mediaCountContainer}>
            <PostMedia
              mediaPublicId={m.publicId}
              resourceType={m.resourceType}
              displayAspectRatio={displayAspectRatio}
              style={styles.mediaContainer}
            />
            {mediaCount > 1 && (
                <View style={styles.countContainer}>
                  <Text style={styles.countText}>+{mediaCount - 1}</Text>
                </View>
            )}
          </View>

          {!hideHeartistName && (
            <View
              style={styles.profileContainer}
            >
              <InstagramProfileImage
                style={styleHtml.profileImage}
                user={post.owner}
                username={post.owner.username}
              />
              <Text style={styles.profileText}>{post.owner.name}</Text>
            </View>
          )}
          {hideHeartistName && (
            <View style={{ height: 6 }}/>
          )}

          {(hideHeartistName || enableViewsCount) && (
            <View
              style={styles.viewsCountContainer}
            >
              { !hideHeartistName && brandListLength > 0 && (
                <Text style={styles.brandText}>
                  {brandList[0]}{(brandListLength > 1) ? ` 외 ${brandListLength - 1}개 브랜드`: ""}
                </Text>
              )}
              <Text style={[styles.viewsCountText, (hideHeartistName) ? { color: Colors.defaultGray08 } : {}]}>
                조회수 {post.countView ? post.countView : 0} · 추천 {post.countRecommend ? post.countRecommend : 0} · 댓글 {post.countReply ? post.countReply : 0}
              </Text>
            </View>
          )}
          <View style={styles.hashtagContainer}>
            { hashtag.slice(0, hashtagLimit).map((tag, idx) =>
              (
                <Button
                  key={`${post._id}-hashtag-${idx}`}
                  title={"#" + tag.title}
                  containerStyle={styles.hashtagButton}
                  titleStyle={styles.hashtagButtonText}
                  onPress={() => {
                    router.push({
                      pathname: router.location.pathname,
                      state: { hashtag: tag.title },
                    });
                  }}
                />
              )
            )}

            { hashtag.length > hashtagLimit && (
              <Text style={[styles.hashtagButtonText, {margin: 2}]}>
                +{hashtag.length - hashtagLimit}
              </Text>
            )}
          </View>
        </View>
      </TouchableHighlight>
    );
  }
}

export default StyleFeed;
