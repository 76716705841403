import React from 'react'

import FilterColorModal from "./FilterColorModal";


function showFilterColorModal(parent, selectedColorList, onChange) {
    const {
      modalProps,
      setModalProps,
    } = parent.props;
  
    const {
      children,
      historyStack=[],
    } = modalProps();
  
    const newChildren = (
      <FilterColorModal
        selectedColorList={selectedColorList}
        onChange={onChange}
        setModalProps={setModalProps}
        modalProps={modalProps}
        />
    );
  
    historyStack.push(children);
  
    setModalProps({
      children: newChildren,
      historyStack,
    });
  }
  
  
  export default showFilterColorModal;
  