import * as React from 'react';

function Svg12BtnIconHide(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} {...props}>
      <path
        d="M4.793 4.192l6.607 6.61A1.997 1.997 0 0113 10a2.002 2.002 0 011.2 3.6l6.007 6.007-1.414 1.414-2.578-2.577a9.345 9.345 0 01-2.944.553l-.246.003c-5.863 0-9.263-5.405-9.893-6.502a1.005 1.005 0 010-.996 15.65 15.65 0 012.702-3.439L3.379 5.607l1.414-1.415zm18.075 7.31c.176.309.176.687 0 .996-.433.755-1.476 2.382-3.12 3.82l-3.377-3.375a3.504 3.504 0 00-4.314-4.314L9.212 5.782a9.424 9.424 0 013.517-.779c5.977-.187 9.5 5.387 10.139 6.499zM9.5 12a3.504 3.504 0 003.76 3.49l-3.75-3.752A3.26 3.26 0 009.5 12z"
        fill="#000"
        fillRule="evenodd"
        opacity={0.3}
      />
    </svg>
  );
}

export default Svg12BtnIconHide;

