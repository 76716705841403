import React from 'react';
import {
  Dimensions, ActivityIndicator, View, Text,
  StyleSheet, Platform, ScrollView,
  TouchableHighlight,
} from 'react-native';

import { navigateToProductPage } from '../containers-v2/shop/ProductPage';
import { ProductFilterControl, Products } from '../components-v2';

import Styles from '../constants/Styles';
import Constants from '../constants/Constants';

const { width: windowWidth } = Dimensions.get('window');

const PRODUCT_PER_PAGE = 20;

const stylesHtml = {
  productImage: {
    width: '100%',
    aspectRatio: 166/221,
    borderWidth: 0.5,
    borderColor: '#cfcfcf',
  },
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
  },
  itemSeparator: {
    marginBottom: 20,
  },
  productFilterControl: {
    paddingTop: 10,
    paddingBottom: 20,
  },
  productListContainer: {
    ...Styles.fullWidth,
  },
  productFlatListContainer: {
    marginHorizontal: 20,
  },
  itemContainerStyle: {
    flex: 1,
    marginHorizontal: 3 / 2,
  },
  columnWrapperStyle: {
    marginHorizontal: -3 / 2,
    ...Styles.fullWidth,
  },
  productTextContainer: {
    marginTop: 6,
  },
});


export default class ProductSearchResultList extends React.Component {
  state = {
    refreshing: false,
    isFetchingNextPage: false,
    filterList: [],
    sortingRule: { id: '1002', value: 'popularity', name: '인기순' },
    windowHeight: window.innerHeight,
  };

  isCloseToBottom({ layoutMeasurement, contentOffset, contentSize }) {
    const paddingToBottom = 100;
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    );
  }

  onScroll({ nativeEvent }, pagination) {
    const {
      isFetchingNextPage,
      refreshing,
    } = this.state;

    if (this.isCloseToBottom(nativeEvent)) {
      if (!pagination) {
        return;
      }
      if (isFetchingNextPage || refreshing || pagination.isFetching || pagination.nextPageUrl === null) {
        return;
      }
      this.loadData(true);
    }
  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate(prevProps) {
    const { searchKeyword, pagination } = this.props;

    if (prevProps.searchKeyword !== searchKeyword) {
      this.getProducts(false);
    }
  }

  loadData(nextPage) {
    this.setState({ isFetchingNextPage: nextPage }, () => {
      this.getProducts(nextPage);
    });
  }

  onChangeFilterList(newActiveFilterList) {
    this.setState({
      filterList: newActiveFilterList,
    }, () => {
      this.getProducts(false);
    });
  }

  onChangeSortingRule(newSortingRule) {
    this.setState({
      sortingRule: newSortingRule,
    }, () => {
      this.getProducts(false);
    });
  }

  onPressProduct = (product) => {
    const { router } = this.props;
    navigateToProductPage({
      productId: product.goodsNo,
    }, router);
  }

  onSortChanged = index => () => {
    const { sortTypes } = this.state;
    this.setState({
      sortTypeIndex: index,
      currentSort: sortTypes[index].value,
      sortingPickerVisible: false,
    },);
  }

  onPressSorting = () => {
    this.setState({ sortingPickerVisible: true });
  };

  onPressFilter = () => {
    const { filterFreeDelivery: { ...newFilterFreeDelivery } } = this.state;
    newFilterFreeDelivery.state = !newFilterFreeDelivery.state;
    this.setState({ filterFreeDelivery: newFilterFreeDelivery });
  }

  getProducts(nextPage=false) {
    const { loadData, searchKeyword } = this.props;
    const { filterList, sortingRule } = this.state;

    const query = {
      keyword: searchKeyword,
      count: PRODUCT_PER_PAGE,
      sortBy: sortingRule.value,
    };
    const brandList = [];
    const colorList = [];
    filterList.forEach(f => {
      if (f.type === 'brand') {
        brandList.push(f.id);
      }
      if (f.type === 'size') {
        // FIXME: apply size
      }
      if (f.type === 'color') {
        colorList.push(f.code);
      }
      if (f.type === 'price') {
        query['priceList'] = [f.min, f.max].join(',');
      }
    });
    if (brandList.length > 0) {
      query['brandList'] = brandList.join(',');
    }
    if (colorList.length > 0) {
      query['colorList'] = colorList.join(',');
    }

    loadData(query, nextPage)
      .catch((error) => console.error('ProductSearchResultList loadData error=', error))
      .finally(() => this.setState({ isFetchingNextPage: false, refreshing: false }));
  }

  render() {
    const { searchResults, pagination, router, brandList, setModalProps, modalProps, ...props } = this.props;
    const { isFetchingNextPage, refreshing, filterList, sortingRule, windowHeight } = this.state;

    const scrollViewHeight = windowHeight - Constants.mainHeaderHeight;
    const scrollViewStyles = [styles.scrollView];
    scrollViewStyles.push({
      height: scrollViewHeight,
    });

    return (
      <View style={styles.container}>
        <ScrollView
          style={scrollViewStyles}
          onScroll={(events) => this.onScroll(events, pagination)}
          scrollEventThrottle={1}
          stickyHeaderIndices={[0]}
          showsVerticalScrollIndicator={false}
          >
          <ProductFilterControl
            router={router}
            activeFilterList={filterList}
            sortingRule={sortingRule}
            brandList={brandList}
            onChangeFilterList={(newActiveFilterList) => this.onChangeFilterList(newActiveFilterList)}
            onChangeSortingRule={(newSortingRule) => this.onChangeSortingRule(newSortingRule)}
            containerStyle={styles.productFilterControl}
            setModalProps={setModalProps}
            modalProps={modalProps}
            />
          <Products
            horizontal={false}
            numColumns={2}
            products={searchResults}
            pagination={pagination}
            onPressProduct={this.onPressProduct}
            brandList={brandList}
            loadData={(nextPage) => this.loadData(nextPage)}
            listHeaderComponentStyle={{ width: 0 }}
            listFooterComponentStyle={{ width: 0, height: 80 }}
            containerStyle={styles.productListContainer}
            flatListStyle={styles.productFlatListContainer}
            itemContainerStyle={styles.itemContainerStyle}
            productImageStyle={stylesHtml.productImage}
            productTextContainerStyle={styles.productTextContainer}
            columnWrapperStyle={styles.columnWrapperStyle}
            isFetchingNextPage={isFetchingNextPage}
            isSmallVersion={false}
            showLikeButton={true}
            showTags={true}
            router={router}
            setModalProps={setModalProps}
            modalProps={modalProps}
            disableSpinner={!isFetchingNextPage}
            />
        </ScrollView>
      </View>
    );
  }
}
