import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View, FlatList } from 'react-native';

import Post from '../../../nativeComponents/Post';
import Collections from '../../../nativeComponents/Collections';
import FeaturedHeartists from '../../../nativeComponents/FeaturedHeartists';
import HeartistsPicks from '../../../nativeComponents/HeartistsPicks';
import Histories from '../../../nativeComponents/Histories';
import HomeFeedSectionHeader from '../../../nativeComponents/HomeFeedSectionHeader';
import Spinner from '../../../components/Spinner';
import Colors from '../../../constants/Colors';
import Constants from '../../../constants/Constants';
import LoadMoreButton from '../../../nativeComponents/LoadMoreButton';


const count = 24;

const styles = StyleSheet.create({
  spacer: {
    paddingTop: 10,
    backgroundColor: Colors.semiGray,
  },
  itemContainer: {
    paddingTop: 10,
    paddingBottom: 30,
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  loadMoreContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

class HomeFeed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      loading: false,
      refreshing: false,
      extraData: new Date().getTime(),
    };
  }

  componentDidMount() {
    this.loadData(false);
  }

  componentDidUpdate(prevProps) {
    const {
      loadPosts, loadPromotions, loadHeartistsPicks,
      loadHistory, loadFeaturedHeartists, loadCollections,
    } = this.props;

    if (!prevProps.loadPosts && loadPosts) {
      loadPosts(false, { count });
    }

    if (!prevProps.loadPromotions && loadPromotions) {
      loadPromotions();
    }

    if (!prevProps.loadHeartistsPicks && loadHeartistsPicks) {
      loadHeartistsPicks();
    }

    if ((!prevProps.loadHistory && loadHistory)
            || (!this.isLoggedIn(prevProps) && this.isLoggedIn())) {
      loadHistory(false);
    }

    if (!prevProps.loadCollections && loadCollections) {
      loadCollections();
    }

    if (!prevProps.loadFeaturedHeartists && loadFeaturedHeartists) {
      loadFeaturedHeartists(false);
    }
  }

  renderItem = ({ item, index }) => {
    const {
      onPressHeartist, onPressPost, onPressProduct,
      postDate, historySwitch, auth, router,
    } = this.props;

    switch (item.renderType) {
    case 'featuredHeartists':
      return (
        <View style={styles.itemContainer}>
          <HomeFeedSectionHeader
            title="HEARTIST"
            showSeeAll
            onPressSeeAll={() => router.push('/heartists')}
          />
          <View style={{ marginHorizontal: -1 * Constants.viewHorizontalPadding }}>
            <FeaturedHeartists
              featuredHeartists={item.data}
              onPressHeartist={onPressHeartist}
              ListHeaderComponent={<View style={{ width: Constants.viewHorizontalPadding }}></View>}
              ListFooterComponent={<View style={{ width: Constants.viewHorizontalPadding }}></View>}
            />
          </View>
        </View>
      );

    case 'heartistsPicks':
      return (
        <View style={styles.itemContainer}>
          <HomeFeedSectionHeader
            title="HEARTIST'S PICK"
          />
          <HeartistsPicks
            data={item.data}
            historySwitch={historySwitch}
            auth={auth}
            onPressProduct={onPressProduct}
            onPressHeartist={onPressHeartist}
          />
        </View>
      );

    case 'collections':
      return (
        <View style={styles.itemContainer}>
          <Collections
            containerStyle={{}}
            onPressHeartist={onPressHeartist}
            onPressPost={onPressPost}
            collections={item.data}
          />
        </View>
      );

    case 'histories':
      return (
        <View style={styles.itemContainer}>
          <Histories
            histories={item.data}
            historySwitch={historySwitch}
            auth={auth}
            onPressProduct={onPressProduct}
          />
        </View>
      );

    case 'post':
    default:
      return (
        <View style={[styles.itemContainer, (index > 0) ? { paddingTop: 20 } : null]}>
          <Post
            onPressHeartist={onPressHeartist}
            onPressProduct={onPressProduct}
            post={item}
            postDate={postDate}
            historySwitch={historySwitch}
            auth={auth}
            enableProductLabelSimilar
            enableProductLabelHeartitLogo
            enableProductDeemedSeasonOut
          />
        </View>
      );
    }
  }


  buildFeedList = () => {
    const {
      posts, promotions, heartistsPicks, collections, histories, featuredHeartists,
    } = this.props;
    const { initialized } = this.state;

    const feedList = [];
    if (!initialized) {
      return feedList;
    }

    posts.forEach(element => {
      element.renderType = 'post';
      feedList.push(element);
    });

    let cursor = 0;
    // 1 Basic Feed
    cursor += 1;

    // Featured Heartists
    const featuredHeartistsIndex = cursor;
    feedList.splice(featuredHeartistsIndex, 0, { renderType: 'featuredHeartists', data: featuredHeartists });
    cursor += 1;

    // 1 Basic Feed
    cursor += 1;

    // Histories
    const historiesIndex = cursor;
    if (this.isLoggedIn() && histories.length >= 3) {
      feedList.splice(historiesIndex, 0, { renderType: 'histories', data: histories });
      cursor += 1;

      // 1 Basic Feed
      cursor += 1;
    }

    // Promotion Feeds
    const promotionPostIndex = cursor;
    promotions.forEach((element, index) => {
      element.renderType = 'post';
      feedList.splice(promotionPostIndex + index, 0, element);
    });
    cursor += promotions.length;

    // Collection
    const collectionPostIndex = cursor;
    if (collections.length > 0) {
      feedList.splice(collectionPostIndex, 0, { renderType: 'collections', data: collections });
      cursor += 1;
    }

    // Heartist's Picks
    const heartistsPicksIndex = cursor;
    if (heartistsPicks.length > 0) {
      const heartistsPicksElement = {
        renderType: 'heartistsPicks',
        data: heartistsPicks,
      };

      feedList.splice(heartistsPicksIndex, 0, heartistsPicksElement);
      cursor += 1;
    }

    return feedList;
  }


  loadData = nextPage => {
    const {
      loadPosts,
      loadPromotions,
      loadHeartistsPicks,
      loadHistory,
      loadFeaturedHeartists,
      loadCollections,
    } = this.props;
    this.setState({ loading: true });

    const promises = [];
    promises.push(loadPosts(nextPage, { count }));
    loadPromotions();
    loadHeartistsPicks();
    if (this.isLoggedIn()) {
      loadHistory(false);
    }
    loadFeaturedHeartists();
    loadCollections();
    Promise.all(promises)
      .then(() => {
        this.setState({ initialized: true, loading: false });
      })
      .catch(() => {
        this.setState({ initialized: true, loading: true });
      });
  }

  isLoggedIn(props = null) {
    if (props === null) {
      const { auth } = this.props;
      // eslint-disable-next-line no-prototype-builtins
      return auth.hasOwnProperty('_id');
    }
    // eslint-disable-next-line no-prototype-builtins
    return props.auth.hasOwnProperty('_id');
  }

  // eslint-disable-next-line class-methods-use-this
  scrollToTop() {
    window.scrollTo(0, 0);
  }

  renderLoadMore() {
    const onPress = () => {
      const {
        pagination,
      } = this.props;

      if (pagination.nextPageUrl) {
        this.loadData(true);
      }
    };

    return (
      <View style={styles.loadMoreContainer}>
        <LoadMoreButton onPress={onPress} />
      </View>
    );
  }

  render = () => {
    const {
      router, containerStyle, pagination, loadPosts, ...props
    } = this.props;

    const {
      initialized, loading, refreshing, extraData,
    } = this.state;

    if (!initialized) {
      return (<Spinner />);
    }

    const feedList = this.buildFeedList();
    return (
      <div style={{ maxWidth: 960, marginLeft: 'auto', marginRight: 'auto' }}>
        <FlatList
          {...props}
          data={feedList}
          keyExtractor={(_item, index) => index.toString()} // eslint-disable-line no-underscore-dangle
          extraData={extraData}
          onRefresh={() => {
            if (!refreshing) {
              this.setState({ refreshing: true });
              this.loadData(false).then(this.setState({ refreshing: false }));
            }
          }}
          refreshing={refreshing}
          ItemSeparatorComponent={() => <View style={styles.spacer} />}
          ListHeaderComponent={<View style={{ height: 10 }} />}
          onEndReachedThreshold={0.5}
          renderItem={this.renderItem}
          contentContainerStyle={containerStyle}
        />
        {(!loading && pagination.nextPageUrl) ? (
          this.renderLoadMore()
        ) : null}
        {loading ? (<Spinner />) : (null)}
      </div>
    );
  }
}

export default HomeFeed;
