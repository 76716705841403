import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as adminActions from 'react-shared/adminActions';
import { loadEntities } from 'react-shared/util';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import '../../css/datepicker.css';
import DAUStatistics from '../../components/admin/DAUStatitics';
import WAUStatistics from '../../components/admin/WAUStatitics';
import MAUStatistics from '../../components/admin/MAUStatitics';
import AdminProductList from '../../components/admin/AdminProductList';

class Dashboard2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: dayjs().subtract(7, 'day').toDate(),
      endDate: dayjs().subtract(1, 'day').toDate(),
      allPeriod: false,
      timeUnit: 'dau',
    };
  }

  static getDays(startDate, endDate) {
    return parseInt((endDate - startDate + 20000) / 86400000, 10) + 1;
  }

  handleDateChange(date, which) {
    this.setState({
      [which]: date,
    });
  }

  getSince() {
    const { startDate, allPeriod } = this.state;
    return allPeriod ? '2019-07-05' : dayjs(startDate).format('YYYY-MM-DD');
  }

  getUntil() {
    const { endDate, allPeriod } = this.state;
    return allPeriod ? dayjs().subtract(1, 'day').format('YYYY-MM-DD') : dayjs(endDate).format('YYYY-MM-DD')
  }

  handleAllPeriodChange() {
    this.setState(prevState => ({ allPeriod: !prevState.allPeriod }));
  }

  render() {
    const { startDate, endDate, allPeriod } = this.state;
    const { getStatDAUs, getStatWAUs, getStatMAUs,
      getAccumulatedStatProducts, accumulatedStatProduct, statProductPagination,
      getStatProducts,
    } = this.props; // eslint-disable-line max-len
    const since = this.getSince();
    const until = this.getUntil();
    return (
      <div>
        <DAUStatistics loadDAUs={getStatDAUs} />
        <WAUStatistics loadWAUs={getStatWAUs} />
        <MAUStatistics loadMAUs={getStatMAUs} />
        <div style={{ textAlign: 'right', margin: '10px' }}>
          <DatePicker
            dateFormat="yyyy-MM-dd"
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={date => this.handleDateChange(date, 'startDate')}
            disabled={allPeriod}
            selectsStart
            maxDate={dayjs().subtract(1, 'day').toDate()}
          />
          ~
          <DatePicker
            dateFormat="yyyy-MM-dd"
            selected={endDate}
            startDate={startDate}
            endDate={endDate}
            onChange={date => this.handleDateChange(date, 'endDate')}
            disabled={allPeriod}
            selectsEnd
            minDate={startDate}
            maxDate={dayjs().subtract(1, 'day').toDate()}
          />
          &nbsp;
          <input type="checkbox" checked={allPeriod} onChange={() => this.handleAllPeriodChange()} />
          전체 기간 검색
        </div>
        <div>
          <AdminProductList
            since={since} 
            until={until} 
            loadData={getAccumulatedStatProducts}
            loadDailyProductStats={getStatProducts}
            pagination={statProductPagination}
            statProducts={accumulatedStatProduct}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    ...loadEntities(state, 'accumulatedStatProducts', 'accumulatedStatProduct', 'statProductPagination'),
  }),
  adminActions,
)(Dashboard2);
