import { Constants } from 'react-shared/constants';

/* eslint-disable no-useless-escape */
/* eslint-disable max-len */

export default {
  ...Constants,
  smartBannerHeight: 73,
  maxWidth: 960,
  bottomMargin: 20,
  marketingPopupStorageKey: 'marketingPopupDisabledList',
};
