import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { camelizeKeys } from 'humps';
import dayjs from 'dayjs';
import Flatpickr from 'react-flatpickr';

import { SizeData, SortingRule } from '../../constants';

// import { browserHistory } from 'react-router';
// all codes using browserHistory are legacy in react-router v3;
// anyways, the solution preventing admins from accidently clicking backbutton, reload, or close window
// eslint-disable-next-line max-len
// I follow the https://stackoverflow.com/questions/32841757/detecting-user-leaving-page-with-react-router/50388073#50388073
// but this.props.route.path doesn't have exact path, I instead used this.props.router.location.pathname
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControlLabel,
  Paper,
  Select,
  MenuItem,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  ItemList,
  ThumbnailList,
  ProductSelector,
} from 'components/admin';

import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import FiberNew from '@material-ui/icons/FiberNew';
import ExpandMore from '@material-ui/icons/ExpandMore';
import has from 'lodash/has';
import pick from 'lodash/pick';
import without from 'lodash/without';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import findIndex from 'lodash/findIndex';

import * as adminActions from 'react-shared/adminActions';

import {
  objectDiff,
} from 'react-shared/util';

import config from '../../config';

const styles = theme => ({
  cancelConfirmButton: {

  },
  csvButtonContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  createPostButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  deletePostButton: {
    position: 'absolute',
    right: theme.spacing(12),
    top: theme.spacing(2),
  },
  snackbar: {
    textAlign: 'center',
  },
  formControlLabel: {

  },
  select: {
    width: 200,
  },
  link: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  outerButton: {
    marginRight: theme.spacing(2),
  },
  subMenuTitles: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    minHeight: 50,
  },
  thumbnailArea: {
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'table-cell',
  },
  filterContainer: {
    borderWidth: 0.5,
    borderColor: '#afadad',
    borderStyle: 'solid',
    marginRight: 3,
  },
  filterTypeText: {
    fontSize: 12,
  },
  filterNameText: {
    fontSize: 14,
    marginRight: 8,
  },
  productCaption: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  productCaptionName: {
    fontSize: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginBottom: 5,
  },
});

const sortByList = SortingRule.SORTING_RULE_EXHIBITION;

class PreorderExhibitionPage extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUploadImage = this.handleUploadImage.bind(this);
    this.handleAddFilter = this.handleAddFilter.bind(this);
    this.handleAddProduct = this.handleAddProduct.bind(this);
    this.handleAddProductSubmit = this.handleAddProductSubmit.bind(this);
    this.renderProductCaption = this.renderProductCaption.bind(this);
    this.renderMediaCaption = this.renderMediaCaption.bind(this);
    this.state = {
      title: '',
      subtitle: '',
      description: '',
      thumbnailList: [],
      thumbnailAspectRatio: 1,
      visible: true,
      isUpdatingPost: false,
      // Filter
      category: 'none',
      categoryList: [],
      sortByValue: sortByList[0].value,
      sortByList,
      brandList: [],
      colorList: [],
      filterList: [],
      newFilterList: [],
      // Products
      products: [],
      checkedProducts: {},
      newCheckedProducts: {},
      isProductDialogOpen: false,
    };
  }

  getProductsCSV = () => {
    const { title, products } = this.state;
    if (products.length == 0) {
      alert('등록된 상품이 없습니다.');
      return;
    }

    let lineArray = ['고도몰 상품코드,상품명'];
    products.forEach((product, idx) => {
        let line = `${product.godoGoodsNo},${product.name}`;
        lineArray.push(line);
    });
    let csvContent = "data:text/csv;charset=utf-8,\ufeff" + lineArray.join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("class", "d-none");
    let filename = title.length > 0 ? `${title}_프리오더_상품목록.csv`: `제목_미정_프리오더_상품목록.csv`;
    filename = filename.replaceAll(' ', '_');
    link.setAttribute("download", filename);
    document.body.appendChild(link); // Required for FF
    link.click();
    link.remove();
  }

  async componentDidMount() {
    const { adminGetPreorderExhibition, preorderExhibitionId, preorderExhibition, route, router } = this.props;
    const { getProductCategoryList, loadShopBrandList, loadShopColorList } = this.props;

    if (preorderExhibitionId !== 'new') {
      if (preorderExhibition) {
        const checkedProducts = {};
        preorderExhibition.products.forEach((product) => {
          checkedProducts[product._id] = product;
        });
        await this.setState({
          ...preorderExhibition,
          checkedProducts,
          sortByValue: preorderExhibition.sortBy.value,
        });
      } else {
        await adminGetPreorderExhibition(preorderExhibitionId)
          .then(preorderExhibitionData => {
            const checkedProducts = {};
            preorderExhibitionData.products.forEach((product) => {
              checkedProducts[product._id] = product;
            });
            this.setState({
              ...preorderExhibitionData,
              checkedProducts,
              sortByValue: preorderExhibitionData.sortBy.value,
            })
          });
        // Todo: route to admin/posts when there's no post with such preorderExhibitionId 
      }
    }
    const brandData = await loadShopBrandList();
    const brandList = brandData.data.map(d => ({
      id: d.cateCd,
      type: 'brand',
      name: d.cateNm,
    }));
    this.setState({ brandList });

    const colorData = await loadShopColorList();
    const colorList = colorData.data.map(d => ({
      id: d.itemCd,
      type: 'color',
      name: d.itemNm.split('^|^')[0],
      code: d.itemNm.split('^|^')[1],
    }));
   this.setState({ colorList });

    const { data: categoryData } = await getProductCategoryList();
    const categoryList = categoryData.reduce((acc, category) => {
      acc.push(category);
      if (category.children) {
        category.children.forEach(subcategory => {
          subcategory.cateNm = `${category.cateNm} > ${subcategory.cateNm}`;
          acc.push(subcategory)
        });
      }
      return acc;
    }, []);
    this.setState({ categoryList });

    this.saveState();
    window.addEventListener('resize', () => this.handleResize());
    window.addEventListener('beforeunload', event => this.handleClose(event));
    this.mutationObserver = new MutationObserver(() => this.removeBodyStyle());
    this.mutationObserver.observe(document.body, {
      attributes: true,
      attributeFilter: ['style'],
    });
    router.setRouteLeaveHook(route, () => this.handleBackbutton());
  }

  componentDidUpdate(prevProps) {
    const { preorderExhibitionId } = this.props;
    if (prevProps.preorderExhibitionId !== preorderExhibitionId && preorderExhibitionId === 'new') {
      window.location.reload();
    }
  }

  componentWillUnmount() {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
      this.mutationObserver = undefined;
    }
    window.removeEventListener('resize', () => this.handleResize());
    window.removeEventListener('beforeunload', event => this.handleClose(event));
  }

  saveState() {
    this.savedState = cloneDeep(this.state);
  }

  hasPreorderExhibitionEdited() {
    return !!Object.keys(objectDiff(this.savedState, this.state)).length;
  }

  showSnackbar(message) {
    this.setState({
      snackbarMsg: message,
      snackbarOpen: true,
    });
  }

  handleClose(event) {
    if (this.hasPreorderExhibitionEdited()) {
      event.preventDefault();
      event.returnValue = '기획전을 작성중이시네요, 정말 창을 닫으시겠어요?';
      return event.returnValue;
    }
    return event;
  }

  removeBodyStyle() { // FIX!
    const {
      isFilterDialogOpen: filter,
      isProductDialogOpen: product,
    } = this.state;
    if (!filter && !product && document.body.style.overflow === 'hidden') {
      document.body.style.overflow = '';
    }
  }

  handleBackbutton() {
    const { router: { location: { pathname } } } = this.props;
    if (this.hasPreorderExhibitionEdited()) {
      // eslint-disable-next-line no-restricted-globals
      const toBeClosed = confirm('기획전을 작성중이시네요, 정말 창을 닫으시겠어요?');
      if (!toBeClosed) {
        window.history.pushState(null, null, pathname);
        return false;
      }
    }
    return true;
  }

  handleResize() {
    if (window.innerWidth <= 600 && this.state.tilesInRow === 4) {
      this.setState({ tilesInRow: 1 });
    } else if (window.innerWidth > 600 && this.state.tilesInRow === 1) {
      this.setState({ tilesInRow: 4 });
    }
  }

  handleTitleChange(event) {
    this.setState({ title: event.target.value });
  }

  handleSubtitleChange(event) {
    this.setState({ subtitle: event.target.value });
  }

  handleDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  handlePreorderStartDateChange(event) {
    this.setState({ preorderStartDate: Math.floor(new Date(event).getTime() / 1000) });
  }

  handlePreorderEndDateChange(event) {
    this.setState({ preorderEndDate: Math.floor(new Date(event).getTime() / 1000) });
  }

  handleVisibleChange(event) {
    this.setState({ visible: event.target.value });
  }

  handleSubmit(event) {
    const fields = [
      'title', 'subtitle', 'description',
      'products', 'category', 'filterList',
      'thumbnailList', 'thumbnailAspectRatio',
      'preorderStartDate', 'preorderEndDate', 'visible'
    ];
    const preorderExhibition = pick(this.state, fields);
    preorderExhibition.sortBy = sortByList.filter(s => s.value === this.state.sortByValue)[0];
    const { adminCreatePreorderExhibition, adminUpdatePreorderExhibition, preorderExhibitionId, router } = this.props;
    if (preorderExhibition.thumbnailList.length === 0) {
      alert('썸네일을 등록해주세요.');
      return;
    }
    if (!preorderExhibition.title) {
      alert('제목을 입력해주세요.');
      return;
    }
    if (!preorderExhibition.description) {
      alert('설명을 입력해주세요.');
      return;
    }
    if (!preorderExhibition.preorderStartDate) {
      alert('프리오더 시작일자를 입력해주세요.');
      return;
    }
    if (!preorderExhibition.preorderEndDate) {
      alert('프리오더 종료일자를 입력해주세요.');
      return;
    }
    if (preorderExhibitionId === 'new') {
      this.setState(
        { isUpdatingPost: true },
        () => adminCreatePreorderExhibition(preorderExhibition)
          .then(() => this.saveState())
          .then(() => {
            this.showSnackbar('기획전을 생성했습니다.');
          })
          .catch(() => this.showSnackbar('기획전 생성에 실패했습니다.'))
          .then(() => router.push('/admin/preorderExhibitions'))
      );
    } else {
      this.setState(
        { isUpdatingPost: true },
        () => adminUpdatePreorderExhibition(preorderExhibitionId, preorderExhibition)
          .then(() => this.setState({ isUpdatingPost: false }, () => this.saveState()))
          .then(() => this.showSnackbar('기획전을 업데이트 했습니다.'))
          .catch(() => this.showSnackbar('기획전 업데이트에 실패했습니다.'))
          .finally(() => this.setState({ isUpdatingPost: false }))
      );
    }
    event.preventDefault();
  }

  handleUploadImage() {
    const {
      thumbnailAspectRatio,
    } = this.state;
    const croppingAspectRatio = Number.parseFloat(thumbnailAspectRatio);

    const uploadWidget = window.cloudinary.openUploadWidget({
      ...config.get('cloudinary'),
      sources: ['local', 'url', 'camera'],
      default_source: 'local',
      cropping: true,
      croppingShowDimensions: true,
      croppingAspectRatio,
      client_allowed_formats: ['jpg', 'jpeg', 'png', 'gif'],
      resource_type: 'image',
      folder: `mdcuration-thumbnails/${dayjs().format('YYYY-MM-DD')}`,
      multiple: false,
    }, (error, result) => {
      if (result && result.event === 'success') {
        uploadWidget.close();
        this.setState(prevState => ({ thumbnailList: prevState.thumbnailList.concat(camelizeKeys([result.info])) }));
      }
    });
    uploadWidget.open();
  }

  handleAddFilter() {
    const { filterList } = this.state;
    const newFilterList = [...filterList];
    this.setState({ newFilterList, isFilterDialogOpen: true });
  }

  handleAddProduct() {
    const { checkedProducts } = this.state;
    const newCheckedProducts = { ...checkedProducts };
    this.setState({ newCheckedProducts, isProductDialogOpen: true });
  }

  handleAddProductSubmit() {
    const { products: prevProducts, checkedProducts, newCheckedProducts } = this.state;
    let products = cloneDeep(prevProducts);
    const oldKeys = Object.keys(checkedProducts);
    const newKeys = Object.keys(newCheckedProducts);

    const deletedKeys = difference(oldKeys, newKeys);
    deletedKeys.forEach((key) => {
      delete checkedProducts[key];
      products = products.filter(product => (product._id !== key));
    });

    newKeys.forEach((key) => {
      const newProduct = cloneDeep(newCheckedProducts[key]);
      checkedProducts[key] = newProduct;
      const productIdx = findIndex(products, ['_id', key]);
      if (productIdx >= 0) {
        const oldProduct = products[productIdx];
        products[productIdx] = newProduct;
      } else {
        products.push(newProduct);
      }
    });

    this.setState({
      products,
      checkedProducts,
      newCheckedProducts: {},
      isProductDialogOpen: false,
    });
  }

  renderProductCaption(product, index) {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.productCaptionName}>{product.name}</div>
        <div className={classes.productCaption}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              window.open(`/admin/products/${product._id}`, '_blank');
            }}
          >
            <Edit />
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              this.setState(prevState => {
                const checkedProducts = prevState.checkedProducts;
                delete checkedProducts[product._id];
                return {
                  products: without(prevState.products, product),
                  checkedProducts,
                };
              });
            }}
          >
            <Delete color="error" />
          </Button>
        </div>
      </div>
    );
  }

  renderMediaCaption(item) {
    return (
      <Button
        variant="outlined"
        onClick={() => this.setState(prevState => ({ thumbnailList: without(prevState.thumbnailList, item) }))}
      >
        Delete
      </Button>
    );
  }

  renderThumbnail() {
    const { classes } = this.props;
    const {
      thumbnailAspectRatio,
    } = this.state;

    return (
      <Paper className={classes.paper}>
        <Typography variant="h6" className={classes.subMenuTitles}>썸네일 이미지</Typography>
        <Typography variant="subheading">
          표시 비율:&nbsp;&nbsp;
          <Select
            className={classes.select}
            value={thumbnailAspectRatio}
            onChange={event => {
              this.setState({
                thumbnailAspectRatio: event.target.value,
              });
            }}
          >
            <MenuItem value="1" key="postpage_posttype_normal">
              <Typography variant="subheading">1:1</Typography>
            </MenuItem>
            <MenuItem value="0.8" key="postpage_posttype_promotion">
              <Typography variant="subheading">4:5</Typography>
            </MenuItem>
            <MenuItem value="0.5625" key="postpage_posttype_collection">
              <Typography variant="subheading">9:16 (IGTV)</Typography>
            </MenuItem>
          </Select>
        </Typography>

        <br />
        <br />

        <ThumbnailList
          getMedia={media => media}
          items={this.state.thumbnailList}
          renderCaption={this.renderMediaCaption}
          isSortable={false}
          displayAspectRatio={thumbnailAspectRatio}
        />
        <Button
          className={classes.outerButton}
          onClick={this.handleUploadImage}
          variant="contained"
        >
          이미지 업로드
        </Button>
      </Paper>
    );
  }

  renderActiveFilter(filterListKey) {
    const { classes } = this.props;
    const { [filterListKey]: filterList } = this.state;
    return (
      <Grid container style={{ paddingBottom: 10 }}>
        {filterList.map((filter, idx) => {
          const filterType = ((type) => {
            if (type === 'brand') {
              return '브랜드';
            } else if (type === 'size') {
              return '사이즈'
            } else if (type === 'color') {
              return '색상'
            } else {
              return '알수없음';
            }
          })(filter.type)
          return (
            <Grid item key={`${idx}`} className={classes.filterContainer}>
              <Grid container alignItems='center'>
                <Grid item>
                  <IconButton
                    size='small'
                    onClick={() => {
                      filterList.splice(idx, 1);
                      this.setState({ [filterListKey]: filterList });
                    }}
                  >
                    <Close size='small' />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Grid container direction='column'>
                    <Grid item className={classes.filterTypeText}>{filterType}</Grid>
                    <Grid item className={classes.filterNameText}>{filter.name}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    );
  }

  renderBrandTable() {
    const { newFilterList, brandList } = this.state;
    const filteredBrandList = brandList
      .filter((b) => findIndex(newFilterList, function(f) { return (b.type !== 'brand' || f.id === b.id) }) < 0);
    return (
      <Table style={{ borderWidth: 0.5, borderColor: '#cfcfcf', borderStyle: 'solid', marginRight: 5 }}>
        <TableHead>
          <TableRow>
            <TableCell className=''></TableCell>
            <TableCell className=''>브랜드명</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredBrandList.map(brand => (
            <TableRow key={`brand_${brand.id}`}>
              <TableCell>
                <Button
                  onClick={() => {
                    const { newFilterList } = this.state;
                    const oldIdx = findIndex(
                      newFilterList,
                      function(f) { return (f.id === brand.id && f.type === 'brand') }
                    );
                    if (oldIdx < 0) {
                      newFilterList.push(brand);
                      this.setState(newFilterList);
                    }
                  }}
                >
                  <Add />
                </Button>
              </TableCell>
              <TableCell>
                {brand.name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  renderSizeTable() {
    const { newFilterList } = this.state;
    const filteredSizeList = SizeData.SIZE_LIST
      .filter((sz) => findIndex(
        newFilterList,
        function(f) { return (f.id === sz.id) }
      ) < 0);
    return (
      <Table style={{ borderWidth: 0.5, borderColor: '#cfcfcf', borderStyle: 'solid', marginRight: 5 }}>
        <TableHead>
          <TableRow>
            <TableCell className=''></TableCell>
            <TableCell className=''>사이즈</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredSizeList.map(size => {
            return (
              <TableRow key={`size_${size.id}`}>
                <TableCell>
                  <Button
                    onClick={() => {
                      const { newFilterList } = this.state;
                      const oldIdx = findIndex(
                        newFilterList,
                        function(f) { return (f.id === size.id && f.type === 'size') }
                      );
                      if (oldIdx < 0) {
                        newFilterList.push(size);
                        this.setState(newFilterList);
                      }
                    }}
                  >
                    <Add />
                  </Button>
                </TableCell>
                <TableCell>
                  {size.name}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  renderColorTable() {
    const { newFilterList, colorList } = this.state;
    const filteredColorList = colorList
      .filter((b) => findIndex(newFilterList, function(f) { return (f.type === 'color' && f.id === b.id) }) < 0);
    return (
      <Table style={{ borderWidth: 0.5, borderColor: '#cfcfcf', borderStyle: 'solid' }}>
        <TableHead>
          <TableRow>
            <TableCell className=''></TableCell>
            <TableCell className=''>색상</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredColorList.map(color=> (
            <TableRow key={`color_${color.id}`}>
              <TableCell>
                <Button
                  onClick={() => {
                    const { newFilterList } = this.state;
                    const oldIdx = findIndex(
                      newFilterList,
                      function(f) { return (f.id === color.id && color.type === 'color') }
                    );
                    if (oldIdx < 0) {
                      newFilterList.push(color);
                      this.setState(newFilterList);
                    }
                  }}
                >
                  <Add />
                </Button>
              </TableCell>
              <TableCell>
                {color.name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  renderFilterDialog() {
    const { classes } = this.props;
    const { newFilterList } = this.state;
    return (
      <Dialog open={this.state.isFilterDialogOpen} fullWidth maxWidth={'lg'} classes={{ paper: classes.dialogPaperProductList }}>
        <DialogTitle>
          필터 추가/수정
        </DialogTitle>
        <DialogContent>
          <Accordion style={{ marginTop: '16px' }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
            >
              <Typography>선택된 필터가 {newFilterList.length}개 있습니다.</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {this.renderActiveFilter('newFilterList')}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionDetails>
              {this.renderBrandTable()}
              {this.renderSizeTable()}
              {this.renderColorTable()}
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.cancelConfirmButton}
            onClick={() => {
              this.setState({ filterList: newFilterList, newFilterList: [], isFilterDialogOpen: false });
            }}
          >
            Confirm
          </Button>
          <Button
            className={classes.cancelConfirmButton}
            onClick={() => {
              this.setState({ newFilterList: [], isFilterDialogOpen: false });
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderProductDialog() {
    const { classes } = this.props;
    const { newFilterList } = this.state;
    return (
      <Dialog open={this.state.isProductDialogOpen} fullWidth maxWidth={'lg'} classes={{ paper: classes.dialogPaperProductList }}>
        <DialogTitle>
          상품 추가/수정
        </DialogTitle>
        <DialogContent>
          <ProductSelector
            searchKeyword={this.state.productSearchKeyword}
            isGodoOnly={true}
            checkedProducts={this.state.newCheckedProducts}
            onChangeKeyword={event => this.setState({ productSearchKeyword: event.target.value })}
            onChangeIsGodoOnly={() => null}
            onCheckProduct={product => {
              const { newCheckedProducts } = this.state;
              if (!has(newCheckedProducts, product._id)) {
                newCheckedProducts[product._id] = product;
              } else {
                delete newCheckedProducts[product._id];
              }
              this.setState({ newCheckedProducts });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.cancelConfirmButton}
            onClick={this.handleAddProductSubmit}
          >
            Confirm
          </Button>
          <Button
            className={classes.cancelConfirmButton}
            onClick={() => {
              this.setState({ newCheckedProducts: {}, isProductDialogOpen: false });
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderPreorderStartDate() {
    const { preorderStartDate } = this.state;

    return (
      <div style={{ marginBottom: 5 }}>
        <Typography variant="subtitle1">
          프리오더 시작일시:&nbsp;&nbsp;
          <Flatpickr
            key={preorderStartDate * 1000}
            value={preorderStartDate * 1000}
            options={{
              enableTime: true,
              minTime: '00:00',
              maxTime: '23:59',
              // dateFormat: 'Y-m-d',
            }}
            data-enable-time
            onChange={event => this.handlePreorderStartDateChange(event)}
          />
        </Typography>
      </div>
    );
  }

  renderPreorderEndDate() {
    const { preorderEndDate } = this.state;

    return (
      <div style={{ marginBottom: 5 }}>
        <Typography variant="subtitle1">
          프리오더 종료일시:&nbsp;&nbsp;
          <Flatpickr
            key={preorderEndDate * 1000}
            value={preorderEndDate * 1000}
            options={{
              enableTime: true,
              minTime: '06:00',
              maxTime: '23:59',
              // dateFormat: 'Y-m-d',
            }}
            data-enable-time
            onChange={event => this.handlePreorderEndDateChange(event)}
          />
        </Typography>
      </div>
    );
  }

  render() {
    const { classes, preorderExhibitionId } = this.props;
    const {
      type, title, subtitle, description, products,
      thumbnailList, sortByValue, sortByList,
      category, categoryList, filterList } = this.state;
    return (
      <div className="container">
        <div className={classes.csvButtonContainer}>
          <Button
            className={classes.outerButton}
            onClick={this.getProductsCSV}
            variant="contained"
          >
            상품코드 엑셀다운
          </Button>
          <small>현재 페이지에 표시중인 상품 목록을 다운받습니다.(수정중인 경우 수정중인 상품목록)</small>
        </div>
        {this.renderThumbnail()}
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>
            기획전 제목
          </Typography>
          <div>
          {/* 프리오더 제목 Multiline ( accept new line ) */}
            <TextField
              className={classes.link}
              value={title}
              multiline={true}
              minRows={1}
              onChange={event => this.handleTitleChange(event)} />
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>
            기획전 부제목
          </Typography>
          {/* 프리오더 부제목 Multiline ( accept new line ) */}
          <div>
            <TextField
              className={classes.link}
              value={subtitle}
              multiline={true}
              minRows={2}
              onChange={event => this.handleSubtitleChange(event)} />
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>
            기획전 설명
          </Typography>
          <div>
            <TextField
              className={classes.link}
              value={description}
              multiline={true}
              minRows={5}
              maxRows={10}
              placeholder="기획전 상세 설명 입력"
              onChange={event => this.handleDescriptionChange(event)}
            />
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>기획전 상태</Typography>
          <div>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={this.state.visible}
                  onChange={(event, checked) => this.setState({ visible: checked })}
                />
              )}
              label="공개여부"
            />
          </div>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css"></link>
          {this.renderPreorderStartDate()}
          {this.renderPreorderEndDate()}
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>상품</Typography>
          {
            products.length > 0 ? (
              <ItemList
                getMedia={(item) => item.media}
                items={products}
                renderCaption={this.renderProductCaption}
                onChange={(normals, recommends) => this.setState(
                  { products: normals.concat(recommends) }
                )
                }
                isRecommendList={false}
                showRecommend={false}
                showSeasonOut={false}
              />
            ) : (
                <Typography
                  variant="caption"
                  className={classes.thumbnailArea}
                  style={{ height: 100 }}
                >
                  등록된 상품이 없습니다
                </Typography>
              )
          }
          <Button
            className={classes.outerButton}
            onClick={this.handleAddProduct}
            variant="contained"
          >
            상품 추가/수정
          </Button>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>정렬</Typography>
          <Typography variant="subheading">
            정렬 선택:&nbsp;&nbsp;
            <Select
              className={classes.select}
              value={sortByValue}
              onChange={event => {
                this.setState({
                  sortByValue: event.target.value,
                });
              }}
            >
              { sortByList.map(sort => {
                return (
                  <MenuItem value={sort.value} key={sort.value}>
                    <Typography variant="subheading">{sort.name}</Typography>
                  </MenuItem>
                )
              })}
            </Select>
          </Typography>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>필터</Typography>
          {
            filterList.length > 0 ? (
              this.renderActiveFilter('filterList')
            ) : (
                <Typography
                  variant="caption"
                  className={classes.thumbnailArea}
                  style={{ height: 100 }}
                >
                  등록된 필터가 없습니다
                </Typography>
              )
          }
          <Button
            className={classes.outerButton}
            onClick={this.handleAddFilter}
            variant="contained"
          >
            필터 추가/수정
          </Button>
        </Paper>
        <div style={{ height: 200, position: 'relative' }}>
          <Button
            variant="fab"
            onClick={this.handleSubmit}
            className={classes.createPostButton}
            color="primary"
            disabled={this.state.isUpdatingPost}
          >
            {preorderExhibitionId === 'new' ? '업로드' : '완료'}
          </Button>
          {
            preorderExhibitionId !== 'new' && (
              <Button
                variant="fab"
                onClick={() => {
                  if (confirm('기획전을 제거할까요?')) { // eslint-disable-line
                    this.props.adminDeletePreorderExhibition(this.props.preorderExhibitionId);
                    this.props.router.push('/admin/preorderExhibitions');
                  }
                }}
                color="secondary"
                className={classes.deletePostButton}
              >
                제거
              </Button>
            )
          }
        </div>
        {this.renderFilterDialog()}
        {this.renderProductDialog()}
        <Snackbar
          autoHideDuration={2000}
          className={classes.snackbar}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
        />
      </div>
    );
  }
}

PreorderExhibitionPage.propTypes = {
  preorderExhibitionId: PropTypes.string.isRequired,
  router: PropTypes.object.isRequired,
  adminCreatePreorderExhibition: PropTypes.func.isRequired,
  adminDeletePreorderExhibition: PropTypes.func.isRequired,
  adminGetPreorderExhibition: PropTypes.func.isRequired,
  adminUpdatePreorderExhibition: PropTypes.func.isRequired,
};

export default compose(
  withStyles(
    styles
  ),
  connect((state, ownProps) => ({
    preorderExhibitionId: ownProps.params.preorderExhibitionId,
    preorderExhibition: state.entities.preorderExhibitions[ownProps.params.preorderExhibitionId],
  }), adminActions),
)(PreorderExhibitionPage);
