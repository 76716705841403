import React from 'react';
import { View, Text, StyleSheet, FlatList, TouchableHighlight } from 'react-native';

import { connect } from 'react-redux';
import { actions } from 'shared';

import PostThumbnail from '../../../components-v2/PostThumbnail';
import Button from '../../../components-v2/Button';

import Colors from '../../../constants/Colors';
import Constants from '../../../constants/Constants';
import Styles from '../../../constants/Styles';

import _styles from '../../../css/modules/carousel.module.css';
import { ArrowForwardIosIcon, ArrowBackIosIcon } from '../../../icons-v2';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginStart: 16,
    marginEnd: 17,
    marginBottom: 20,
  },
  titleTextContainer: {
    marginTop: 3,
  },
  titleText: {
    fontSize: 18,
    lineHeight: 21.6,
    color: '#000',
    ...Styles.pretendard700,
  },
  showAllButtonContainer: {
  },
  showAllButton: {
    padding: 0,
    backgroundColor: '#fff',
    borderRadius: 0,
    borderWidth: 0,
    borderColor: 'transparent',
  },
  showAllButtonText: {
    fontSize: 13,
    lineHeight: 15.6,
    color: '#000',
    justifyContent: 'flex-start',
    ...Styles.pretendard600,
  },
  itemContainer:{
    flexDirection: 'column',
  },
  thumbnailContainer:{
    width: 160,
    height: 240,
  },
  thumbnail:{
    aspectRatio: null,
    width: 160,
    height: 240,
    resizeMode: 'cover',
  },
  heartistNameContainer: {
    marginTop: 6,
  },
  heartistNameText: {
    fontSize: 15,
    lineHeight: 18,
    ...Styles.pretendard600,
  },
});


class StyleFeedHorizontalList extends React.Component {

  listRef = React.createRef();

  state = {
    isLeftEnd:false,
    isRightEnd:false,
    nowIndex:0
  };

  handleMomentEnd(event) {
    const index = Math.floor(
        Math.floor(event.nativeEvent.contentOffset.x) /
        Math.floor(event.nativeEvent.layoutMeasurement.width));
    this.setState({nowIndex : index});
  }

  handleLeftClickEvent(e) {

    const nowIndex = this.state.nowIndex;
    let newIndex = 0;
    if(nowIndex > 2){
      newIndex = nowIndex - 2;
      this.listRef.scrollToIndex({animated: true, index: newIndex});
    }else{
      newIndex = 0;
      this.listRef.scrollToOffset({animated: true, index: newIndex});
    }
    this.setState({nowIndex : newIndex});
  }

  handleRightClickEvent(e) {
    const nowIndex = this.state.nowIndex;
    let newIndex = nowIndex + 2;
    try{
      this.listRef.scrollToIndex({animated: true, index: newIndex});
      this.setState({nowIndex : newIndex});
    }catch(e){

    }
  }

  renderHeader = () => {
    const { title, onPressShowAll } = this.props;

    return (
      <View style={styles.titleContainer}>
        <View style={styles.titleTextContainer}>
          <Text style={styles.titleText}>
          {title}
          </Text>
        </View>
        <View style={styles.showAllButtonContainer}>
          <Button
            containerStyle={styles.showAllButton}
            titleStyle={styles.showAllButtonText}
            title="VIEW ALL"
            onPress={onPressShowAll}
          />
        </View>
      </View>
    );
  }

  render() {
    let { router, scrollToTop, showHeartistId = false, containerStyle, data, ...props } = this.props;

    const {
      isLeftEnd,
      isRightEnd,
    } = this.state;

    return (
      <View style={[styles.container, containerStyle]}>
        {this.renderHeader()}
        <div className={_styles._desktop_carousel}>
          <FlatList
            horizontal
            ref={ref => this.listRef = ref }
            onMomentumScrollEnd={this.handleMomentEnd}
            showsHorizontalScrollIndicator={false}
            style={styles.feedList}
            data={data}
            ListHeaderComponent={() => <View style={{ width: 16 }}/>}
            ListFooterComponent={() => <View style={{ width: 16 }}/>}
            ItemSeparatorComponent={() => <View style={{ width: 4 }}/>}
            renderItem={({item, index}) => (
              <TouchableHighlight
                key={item._id}
                underlayColor={Colors.touchEffectColorWhite}
                style={Styles.touchEffect}
                onPress={() => {
                  router.push(`/style/feed/${item._id }`)
                }}
              >
                <View style={styles.itemContainer}>
                  <PostThumbnail
                    containerStyle={styles.thumbnailContainer}
                    mediaStyle={styles.thumbnail}
                    post={item}
                   />
                  {showHeartistId && (
                    <View style={styles.heartistNameContainer}>
                      <Text style={styles.heartistNameText}>@{item.owner.name}</Text>
                    </View>
                  )}
                </View>
              </TouchableHighlight>
             )
            }
            {...props}
          />
          {!isLeftEnd &&
          <div className={_styles._desktop_carousel__left}
            style={{height : showHeartistId ? 'calc(100% - 24px)' : '100%' }}
            onClick={this.handleLeftClickEvent.bind(this)}>
            <ArrowBackIosIcon
              color={"#333333"}
              style={{position:'absolute', top:'50%', left:'50%', width:"24px", height:'24px', transform:'translate(-50%, -50%)'}}/>
          </div>}
          {!isRightEnd &&
          <div className={_styles._desktop_carousel__right}
            style={{height : showHeartistId ? 'calc(100% - 24px)' : '100%' }}
            onClick={this.handleRightClickEvent.bind(this)}>
            <ArrowForwardIosIcon
              color={"#333333"}
              style={{position:'absolute', top:'50%', left:'50%', width:"24px", height:'24px', transform:'translate(-50%, -50%)'}}/>
          </div>}
        </div>
      </View>
    );
  }
}


export default StyleFeedHorizontalList;
