import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import dayjs from 'dayjs';
import Flatpickr from 'react-flatpickr';

// import { browserHistory } from 'react-router';
// all codes using browserHistory are legacy in react-router v3;
// anyways, the solution preventing admins from accidently clicking backbutton, reload, or close window
// eslint-disable-next-line max-len
// I follow the https://stackoverflow.com/questions/32841757/detecting-user-leaving-page-with-react-router/50388073#50388073
// but this.props.route.path doesn't have exact path, I instead used this.props.router.location.pathname
import {
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import FiberNew from '@material-ui/icons/FiberNew';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';

import * as adminActions from 'react-shared/adminActions';
import { objectDiff } from 'react-shared/util';

import config from '../../config';

const styles = theme => ({
  cancelConfirmButton: {

  },
  createPostButton: {
    position: 'absolute',
    right: theme.spacing.unit * 2,
    top: theme.spacing.unit * 2,
  },
  deletePostButton: {
    position: 'absolute',
    right: theme.spacing.unit * 12,
    top: theme.spacing.unit * 2,
  },
  deleteMediaButton: {
    width: theme.spacing.unit * 3,
    height: theme.spacing.unit * 3,
    float: 'right',
  },
  snackbar: {
    textAlign: 'center',
  },
  formControlLabel: {

  },
  dialogPaper: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
    width: '100%',
  },
  select: {
    width: 200,
  },
  mediaId: {
    width: 280,
  },
  link: {
    width: '100%',
  },
  gridListTileBarIconButton: {
    color: '#ffffff',
  },
  gridListTile: {
    cursor: 'pointer',
    width: 240,
    height: 240,
  },
  paper: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
  },
  outerButton: {
    marginRight: theme.spacing.unit * 2,
  },
  subMenuTitles: {
    marginBottom: theme.spacing.unit * 2,
  },
  thumbnailArea: {
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'table-cell',
  },
  textField: {
    minHeight: 50,
  },
  year: {
    width: 55,
    marginLeft: 20,
    textAlign: 'center',
  },
  dateTime: {
    width: 32,
    marginLeft: 20,
    textAlign: 'center',
  },
});

class BannerPage extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUploadImage = this.handleUploadImage.bind(this);
    // this.handleAddItem = this.handleAddItem.bind(this);
    // this.handleAddItemImage = this.handleAddItemImage.bind(this);
    // this.handleAddItemSubmit = this.handleAddItemSubmit.bind(this);
    // this.renderItemCaption = this.renderItemCaption.bind(this);
    // this.renderMediaCaption = this.renderMediaCaption.bind(this);
    // this.onCloseInstagramMediaDialog = this.onCloseInstagramMediaDialog.bind(this);
    // this.parseImagesFromShop = this.parseImagesFromShop.bind(this);
    // this.showWebItemDialog = this.showWebItemDialog.bind(this);
    // this.handleConfirmWebItemDialog = this.handleConfirmWebItemDialog.bind(this);
    // this.handleMediaCopyClick = this.handleMediaCopyClick.bind(this);
    this.state = {
      image: '',
      title: '',
      weight: 0,
      url: '',
      deeplink: '',
      open: 0,
      close: 0,
      prod: false,
      test: false,
      isUpdatingPost: false,
    };
  }

  async componentDidMount() {
    const { adminGetBanner, bannerId, banner, route, router } = this.props;
    if (bannerId !== 'new') {
      if (banner) {
        await this.setState({ ...banner });
      } else {
        await adminGetBanner(bannerId)
          .then(bannerData => this.setState({ ...bannerData }));
        // Todo: route to admin/posts when there's no post with such bannerId
      }
    }
    this.saveState();
    window.addEventListener('resize', () => this.handleResize());
    window.addEventListener('beforeunload', event => this.handleClose(event));
    this.mutationObserver = new MutationObserver(() => this.removeBodyStyle());
    this.mutationObserver.observe(document.body, {
      attributes: true,
      attributeFilter: ['style'],
    });
    router.setRouteLeaveHook(route, () => this.handleBackbutton());
  }

  componentDidUpdate(prevProps) {
    const { bannerId } = this.props;
    if (prevProps.bannerId !== bannerId && bannerId === 'new') {
      window.location.reload();
    }
  }

  componentWillUnmount() {
    this.mutationObserver.disconnect();
    window.removeEventListener('resize', () => this.handleResize());
    window.removeEventListener('beforeunload', event => this.handleClose(event));
  }

  saveState() {
    this.savedState = cloneDeep(this.state);
  }

  hasBannerEdited() {
    return !!Object.keys(objectDiff(this.savedState, this.state)).length;
  }

  showSnackbar(message) {
    this.setState({
      snackbarMsg: message,
      snackbarOpen: true,
    });
  }

  handleClose(event) {
    if (this.hasBannerEdited()) {
      event.preventDefault();
      event.returnValue = '배너를 작성중이시네요, 정말 창을 닫으시겠어요?';
      return event.returnValue;
    }
    return event;
  }

  removeBodyStyle() { // FIX!
    const {
      isItemDialogOpen: item,
      isWebItemDialogOpen: webItem,
    } = this.state;
    if (!item && !webItem && document.body.style.overflow === 'hidden') {
      document.body.style.overflow = '';
    }
  }

  handleBackbutton() {
    const { router: { location: { pathname } } } = this.props;
    if (this.hasBannerEdited()) {
      // eslint-disable-next-line no-restricted-globals
      const toBeClosed = confirm('배너를 작성중이시네요, 정말 창을 닫으시겠어요?');
      if (!toBeClosed) {
        window.history.pushState(null, null, pathname);
        return false;
      }
    }
    return true;
  }

  handleResize() {
    if (window.innerWidth <= 600 && this.state.tilesInRow === 4) {
      this.setState({ tilesInRow: 1 });
    } else if (window.innerWidth > 600 && this.state.tilesInRow === 1) {
      this.setState({ tilesInRow: 4 });
    }
  }

  handleUrlChange(event) {
    this.setState({ url: event.target.value });
  }

  handleDeeplinkChange(event) {
    this.setState({ deeplink: event.target.value });
  }

  handleTitleChange(event) {
    this.setState({ title: event.target.value });
  }

  handleWeightChange(event) {
    this.setState({ weight: event.target.value });
  }

  handleOpenChange(event) {
    this.setState({ open: Math.floor(new Date(event).getTime() / 1000) });
  }

  handleCloseChange(event) {
    this.setState({ close: Math.floor(new Date(event).getTime() / 1000) });
  }

  handleProdChange(event) {
    this.setState({ prod: event.target.value });
  }

  handleTestChange(event) {
    this.setState({ test: event.target.value });
  }

  handleSubmit(event) {
    const fields = ['title', 'image', 'weight', 'url', 'deeplink', 'open', 'close', 'prod', 'test'];
    const banner = pick(this.state, fields);
    const { adminCreateBanner, adminUpdateBanner, bannerId, router } = this.props;
    if (banner.image.length === 0) {
      alert('배너 이미지가 등록되지 않았습니다');
      return;
    }
    if (bannerId === 'new') {
      this.setState(
        { isUpdatingPost: true },
        () => adminCreateBanner(banner)
          .then(() => {
            this.showSnackbar('Banner created successfully.');
          })
          .catch(() => this.showSnackbar('Update failed.'))
          .then(() => router.push('/admin/banners'))
      );
    } else {
      this.setState(
        { isUpdatingPost: true },
        () => adminUpdateBanner(bannerId, banner)
          .then(() => this.setState({ isUpdatingPost: false }, () => this.saveState()))
          .then(() => this.showSnackbar('Banner updated succesfully.'))
          .catch(() => this.showSnackbar('Update failed.'))
      );
    }
    event.preventDefault();
  }

  handleUploadImage() {
    window.cloudinary.openUploadWidget({
      ...config.get('cloudinary'),
      default_source: 'url',
      cropping: 'server',
      cropping_default_selection_ratio: 1.0,
    }, (error, result) => {
      if (result) { // FIX!
        this.setState({ image: result[0].secure_url });
      }
    });
  }

  renderCreated() {
    const { created } = this.state;

    return (
      <div>
        <FormControlLabel
          control={(
            <Checkbox
              checked={!!created}
              // disabled={created && created < 10000}
              onChange={event => {
                if (event.target.checked) {
                  this.setState({ created: (dayjs().valueOf() / 1000) });
                } else {
                  this.setState({ created: null });
                }
              }}
            />
          )}
          label={(created && created > 10000) ? 'Created at' : 'Created'}
        />
        <Flatpickr
          key={created * 1000}
          value={created * 1000}
          options={{
            enableTime: true,
            minTime: '06:00',
            maxTime: '23:59',
            // dateFormat: 'Y-m-d',
          }}
          data-enable-time
          onChange={event => this.handleCreatedChange(event)}
        />
      </div>
    );
  }

  renderOpen() {
    const { open } = this.state;

    return (
      <div>
        <FormControlLabel
          control={(
            <Checkbox
              checked={!!open}
              // disabled={open && open < 10000}
              onChange={event => {
                if (event.target.checked) {
                  this.setState({ open: (dayjs().valueOf() / 1000) });
                } else {
                  this.setState({ open: null });
                }
              }}
            />
          )}
          label={(open && open > 10000) ? 'Open at' : 'Open'}
        />
        <Flatpickr
          key={open * 1000}
          value={open * 1000}
          options={{
            enableTime: true,
            minTime: '06:00',
            maxTime: '23:59',
            dateFormat: 'Y-m-d',
          }}
          data-enable-time
          onChange={event => this.handleOpenChange(event)}
        />
      </div>
    );
  }

  renderClose() {
    const { close } = this.state;

    return (
      <div>
        <FormControlLabel
          control={(
            <Checkbox
              checked={!!close}
              // disabled={close && close < 10000}
              onChange={event => {
                if (event.target.checked) {
                  this.setState({ close: (dayjs().valueOf() / 1000) });
                } else {
                  this.setState({ close: null });
                }
              }}
            />
          )}
          label={(close && close > 10000) ? 'Closed at' : 'Closed'}
        />
        <Flatpickr
          key={close * 1000}
          value={close * 1000}
          options={{
            enableTime: true,
            minTime: '06:00',
            maxTime: '23:59',
            dateFormat: 'Y-m-d',
          }}
          data-enable-time
          onChange={event => this.handleCloseChange(event)}
        />
      </div>
    );
  }

  render() {
    const { classes, bannerId } = this.props;
    const { title, weight, url, deeplink } = this.state;
    // const [normalItems, recommendItems] = partition(items, { isRecommend: false });
    return (
      <div className="container">
        <Paper className={classes.paper}>
          <Typography variant="title" className={classes.subMenuTitles}>배너 이미지</Typography>
          <img src={this.state.image} style={{ width: 500, height: 'auto' }} alt="" />
          <Button
            className={classes.outerButton}
            onClick={this.handleUploadImage}
            variant="contained"
          >
            배너 이미지 업로드
          </Button>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="title" className={classes.subMenuTitles}>
            <Tooltip title="Banner의 제목을 입력하세요.">
              <FiberNew color="secondary" style={{ verticalAlign: 'middle' }} />
            </Tooltip>
            &nbsp;Banner Title
          </Typography>
          <div>
            <TextField className={classes.link} value={title} onChange={event => this.handleTitleChange(event)} />
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="title" className={classes.subMenuTitles}>
            <Tooltip title="배너 Weight를 입력해 주세요. (음수는 넣으면 안됩니다.)">
              <FiberNew color="secondary" style={{ verticalAlign: 'middle' }} />
            </Tooltip>
            &nbsp;Weight
          </Typography>
          <div>
            <TextField
            //   inputProps={{ step: 10, max: 90, min: 10 }}
              className={classes.link}
              value={weight}
              onChange={event => this.handleWeightChange(event)}
            //   disabled={!link}
            />
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="title" className={classes.subMenuTitles}>
            <Tooltip title="Banner의 URL을 입력하세요.">
              <FiberNew color="secondary" style={{ verticalAlign: 'middle' }} />
            </Tooltip>
            &nbsp;Banner URL
          </Typography>
          <div>
            <TextField className={classes.link} value={url} onChange={event => this.handleUrlChange(event)} />
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="title" className={classes.subMenuTitles}>
            <Tooltip title="Banner의 deeplink를 입력하세요.">
              <FiberNew color="secondary" style={{ verticalAlign: 'middle' }} />
            </Tooltip>
            &nbsp;Banner Deeplink
          </Typography>
          <div>
            <TextField className={classes.link} value={deeplink} onChange={event => this.handleDeeplinkChange(event)} />
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="title" className={classes.subMenuTitles}>배너 디스플레이 시간</Typography>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css"></link>
          {this.renderOpen()}
          {this.renderClose()}
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="title" className={classes.subMenuTitles}>배너 상태</Typography>
          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.prod}
                onChange={(event, checked) => this.setState({ prod: checked })}
              />
            )}
            label="Production Server"
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.test}
                onChange={(event, checked) => this.setState({ test: checked })}
              />
            )}
            label="Test Server"
          />
        </Paper>
        <div style={{ height: 200, position: 'relative' }}>
          <Button
            variant="fab"
            onClick={this.handleSubmit}
            className={classes.createPostButton}
            color="primary"
            disabled={this.state.isUpdatingPost}
          >
            {bannerId === 'new' ? '업로드' : '완료'}
          </Button>
          {
            bannerId !== 'new' && (
              <Button
                variant="fab"
                onClick={() => {
                  if (confirm('배너를 제거할까요?')) { // eslint-disable-line
                    this.props.adminDeleteBanner(this.props.bannerId);
                    this.props.router.push('/admin/banners');
                  }
                }}
                color="secondary"
                className={classes.deletePostButton}
              >
                제거
              </Button>
            )
          }
        </div>
        <Snackbar
          autoHideDuration={2000}
          className={classes.snackbar}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
        />
      </div>
    );
  }
}

BannerPage.propTypes = {
  adminCreateBanner: PropTypes.func.isRequired,
  adminDeleteBanner: PropTypes.func.isRequired,
  adminGetBanner: PropTypes.func.isRequired,
  adminUpdateBanner: PropTypes.func.isRequired,
  bannerId: PropTypes.string.isRequired,
  router: PropTypes.object.isRequired,
};

export default compose(
  withStyles(
    styles
  ),
  connect((state, ownProps) => ({
    bannerId: ownProps.params.bannerId,
    banner: state.entities.banners[ownProps.params.bannerId],
  }), adminActions),
)(BannerPage);
