import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View, Text } from 'react-native';

import {
  Constants,
  Styles,
  Colors,
 } from '../../constants';

import TouchableHighlightWithContainer from '../TouchableHighlightWithContainer';


const styles = StyleSheet.create({
  container: {
    paddingLeft: Constants.viewHorizontalPadding,
    paddingRight: Constants.viewHorizontalPadding,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: Constants.mainHeaderHeight,
    paddingTop: 18,
    paddingBottom: 19,
  },
  buttonText: {
    ...Styles.pretendard700,
    fontSize: 18,
    lineHeight: 29,
    height: 29,
    opacity: 0.2,
  },
  buttonTextActive: {
    opacity: 1.0,
  },
});


class HeaderLeftFavorite extends React.Component {

  render() {

    const {
      containerStyle,
      router,
      selectedTab,
    } = this.props;

    const wishlistTextStyles = [styles.buttonText];
    const brandTextStyles = [styles.buttonText];

    if (selectedTab === 'brand') {
      brandTextStyles.push(styles.buttonTextActive);
    } else {
      wishlistTextStyles.push(styles.buttonTextActive);
    }

    return (
      <View style={[styles.container, containerStyle]}>
        <TouchableHighlightWithContainer
          onPress={() => {
            router.push('/favorite');
          }}
          >
          <Text style={wishlistTextStyles}>위시리스트</Text>
        </TouchableHighlightWithContainer>

        <View style={{ width: 16 }} />

        <TouchableHighlightWithContainer
          onPress={() => {
            router.push('/favorite/brand');
          }}
          >
          <Text style={brandTextStyles}>마이 브랜드</Text>
        </TouchableHighlightWithContainer>
      </View>
    );
  }
}


HeaderLeftFavorite.propTypes = {
  selectedTab: PropTypes.string,
};


HeaderLeftFavorite.defaultProps = {
  selectedTab: 'history',
};


export default HeaderLeftFavorite;
