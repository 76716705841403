import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { pick, pickBy, map, values, keys /* , mapKeys */ } from 'lodash';

export default class UserStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeUsersOptions: {
        title: null,
        series: [],
        xAxis: {
          tickmarkPlacement: 'on',
          reversed: true,
        },
      },
      userStatisticsOptions: {
        title: null,
        series: [],
        xAxis: {
          tickmarkPlacement: 'on',
          reversed: true,
        },
      },
      userBehaviorsOptions: {
        title: null,
        series: [],
        xAxis: {
          tickmarkPlacement: 'on',
          reversed: true,
        },
        yAxis: {
          type: 'logarithmic',
        },
      },
      unit: 'dau',
    };
  }

  loadUserStatistics(days, endDate) {
    const { loadData } = this.props;
    const { unit } = this.state;
    loadData(days, endDate, 'total')
      .then(data => data.reduce((accumulator, datum) => {
        keys(datum).forEach(key => {
          if (!accumulator[key]) {
            accumulator[key] = [];
          }
          accumulator[key].push(datum[key]);
        });
        return accumulator;
      }, {}))
      .then(data => {
        const userData = data.all.length !== 0 ? data.all : [];
        data.userDelta = userData.reduce((accumulator, datum, index) => {
          if (index < userData.length - 1) {
            accumulator.push(datum - userData[index + 1]);
          }
          return accumulator;
        }, []);
        return data;
      })
      .then(data => {
        this.setState({
          activeUsersOptions: {
            xAxis: {
              categories: data.date,
            },
            series: map(pickBy(data, (value, key) => key.includes(unit)), (value, key) => ({ name: key, data: value })),
          },
          userStatisticsOptions: {
            xAxis: {
              categories: data.date,
            },
            series: map(
              pick(data, ['followers', 'all', 'followerDelta', 'userDelta']),
              (value, key) => ({
                name: key === 'all' ? 'users' : key,
                data: value,
                visible: !key.includes('Delta'),
              })
            ),
          },
          userBehaviorsOptions: {
            xAxis: {
              categories: data.date,
            },
            series: map(
              pick(data, ['dailyPost', 'dailyEditorial', 'dailyBanner']),
              (value, key) => ({
                name: key.replace('daily', ''),
                data: value,
                visible: key.includes('Post'),
              })
            ),
          },
        });
      });
  }

  componentDidMount() {
    const { days, endDate } = this.props;
    this.loadUserStatistics(days, endDate);
  }

  componentDidUpdate(prevProps, prevState) {
    const { days, endDate } = this.props;
    const { unit } = this.state;
    if (prevProps.days !== days || prevProps.endDate !== endDate || prevState.unit !== unit) {
      this.loadUserStatistics(days, endDate);
    }
  }

  render() {
    const { activeUsersOptions, userStatisticsOptions, userBehaviorsOptions, unit } = this.state;
    return (
      <div>
        <div style={{ fontSize: '20px', marginLeft: '20px', fontWeight: 'bold' }}>
          # User Statistics
        </div>
        <HighchartsReact highcharts={Highcharts} options={userStatisticsOptions} />
        <div style={{ fontSize: '20px', marginLeft: '20px', fontWeight: 'bold' }}>
          # Active Users&nbsp;&nbsp;
          <select onChange={event => this.setState({ unit: event.target.value })} value={unit}>
            <option value="dau">Daily</option>
            <option value="wau">Weekly</option>
            <option value="mau">Monthly</option>
          </select>
        </div>
        <HighchartsReact highcharts={Highcharts} options={activeUsersOptions} />
        <div style={{ fontSize: '20px', marginLeft: '20px', fontWeight: 'bold' }}>
          # User Behaviors (clicks)
        </div>
        <HighchartsReact highcharts={Highcharts} options={userBehaviorsOptions} />
      </div>
    );
  }
}

UserStatistics.propTypes = {
  days: PropTypes.number.isRequired,
  endDate: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};
