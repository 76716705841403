import React from 'react';
import {
    StyleSheet, View, Text
} from 'react-native';
import { TouchableHighlightWithContainer } from '../../../components-v2';
import { Colors, Styles } from '../../../constants';
import { ChevronDownIcon } from '../../../icons-v2';


const styles = StyleSheet.create({
  container: {
  },
  title: {
    ...Styles.pretendard400,
    fontSize: 14,
    lineHeight: 14,
    opacity: 0.5,
    marginBottom: 6,
  },
  contentPlaceholderText: {
    ...Styles.pretendard400,
    fontSize: 16,
    lineHeight: 19.2,
    color: '#000',
    opacity: 0.2,
  },
  contentText: {
    color: '#000000',
  },
});

class SelectorBase extends React.Component {

  state = {}
  title = '';
  placeholder = '';

  onPressSelector() {
  }

  render() {
    const {
      containerStyle,
      inputContainerStyle,
      inputContentStyle,
      showDropdownIcon,
    } = this.props;

    const {
      value
    } = this.state;

    const content = value || this.placeholder;
    const contentStyles = [
      styles.contentPlaceholderText,
    ];

    if (value) {
      contentStyles.push(styles.contentText);
    }

    const onPress = () => {
      this.onPressSelector();
    }

    return (
      <View style={[styles.container, containerStyle]}>
        <Text style={styles.title}>{this.title}</Text>
        <TouchableHighlightWithContainer
          containerStyle={inputContainerStyle}
          onPress={onPress}
          >
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <View>
              <Text style={[contentStyles, inputContentStyle]}>{content}</Text>
            </View>
            { showDropdownIcon && (
              <ChevronDownIcon />
            )}
          </View>
        </TouchableHighlightWithContainer>
      </View>
    )
  }
}

export default SelectorBase;
