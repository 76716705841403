import React from 'react';
import {
  StyleSheet, View, Dimensions, TouchableHighlight, Text,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import Product from '../../../nativeComponents/Product';
import LoadMoreButton from '../../../nativeComponents/LoadMoreButton';
import FilterIcon from '../../../icons/FilterIcon';
import SortingIcon from '../../../icons/SortingIcon';
import HeartitBlankPageLogoSmall from '../../../icons/HeartitBlankPageLogoSmall';
import Spinner from '../../../components/Spinner';

import Constants from '../../../constants/Constants';
import Styles from '../../../constants/Styles';


const initialLayout = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
};
const count = 24;
const columnCount = 2;
const itemSpacing = 1;
const containerWidth = initialLayout.width - (Constants.viewHorizontalPadding - Constants.touchEffectWidth) * 2;
const productContainerSize = (containerWidth - itemSpacing * (columnCount - 1)) / columnCount;
const topMargin = 20;

const styles = StyleSheet.create({
  headerContainer: {
    height: topMargin,
  },
  headerButton: {
    marginStart: 20,
    borderRadius: 4,
  },
  productContainer: {
    width: '100%',
  },
  productImageContainerStyle: {
  },
  loadMoreContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 20,
  },
  blankViewContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: Constants.bottomSpaceForTabBar,
  },
  blankViewText: {
    height: 21,
    marginTop: 10,
    opacity: 0.5,
    ...Styles.appleSDGothicNeoMedium,
    fontSize: 18,
    lineHeight: 21,
  },
});

const stylesHtml = {
  productContainer: {
    marginBottom: 12,
    marginRight: 1,
    width: productContainerSize,
  },
  productContainerLast: {
    marginRight: 0,
  },
};

function renderBlankView() {
  return (
    <View style={styles.blankViewContainer}>
      <HeartitBlankPageLogoSmall />
      <Text style={styles.blankViewText}>표시할 상품이 없습니다</Text>
    </View>
  );
}


class TabPick extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      // refreshing: false,
    };
  }

  loadData = nextPage => {
    const { loadRecentPosts } = this.props;
    this.setState({ loading: true });
    if (loadRecentPosts) {
      loadRecentPosts(nextPage, { count })
        .then(this.setState({ loading: false }));
    }
  }


  onPressSorting = () => {
  };

  onPressFilter = () => {
  };

  renderHeader = () => (
    <View style={styles.headerContainer}>
      <TouchableHighlight
        underlayColor="rgba(0, 0, 0, 0.1)"
        onPress={this.onPressSorting}
        style={styles.headerButton}
      >
        <SortingIcon />
      </TouchableHighlight>
      <TouchableHighlight
        underlayColor="rgba(0, 0, 0, 0.1)"
        onPress={this.onPressFilter}
        style={styles.headerButton}
      >
        <FilterIcon />
      </TouchableHighlight>
    </View>
  )

  renderProduct = ({ post, product }, index) => {
    const { onPressProduct } = this.props;

    const isLast = (index + 1) % columnCount === 0;

    const gridListTileStyle = {
      ...stylesHtml.productContainer,
      ...(isLast && stylesHtml.productContainerLast),
    };

    if (!post) {
      return (
        <GridListTile
          key={new Date().getTime()}
          style={gridListTileStyle}
        >
          <View />
        </GridListTile>
      );
    }

    return (
      <GridListTile
        key={`${post._id}-${product._id}`}
        style={gridListTileStyle}
      >
        <Product
          containerStyle={styles.productContainer}
          imageContainerStyle={styles.productImageContainerStyle}
          // eslint-disable-next-line react/jsx-no-bind
          onPressProduct={onPressProduct.bind(this, product, { post })}
          product={product}
          enableLabelPreOrder
        />
      </GridListTile>
    );
  };

  renderLoadMore() {
    const onPress = () => {
      const {
        pagination,
      } = this.props;

      if (pagination.nextPageUrl) {
        this.loadData(true);
      }
    };

    return (
      <View style={styles.loadMoreContainer}>
        <LoadMoreButton onPress={onPress} />
      </View>
    );
  }

  render() {
    const {
      posts, pagination,
      largeHeaderHeight,
      smallHeaderHeight,
      // onPressPostThumbnail,
      itemCount,
    } = this.props;
    const {
      loading,
      // refreshing,
    } = this.state;

    const products = [];
    const productIds = [];
    posts.forEach(post => {
      (post.products || []).forEach(product => {
        if (productIds.indexOf(`${product._id}`) < 0 && product.godoGoodsNo) {
          productIds.push(`${product._id}`);
          products.push({ post, product });
        }
      });
    });

    const minHeightLarge = initialLayout.height - smallHeaderHeight - topMargin;
    const minHeightSmall = initialLayout.height - largeHeaderHeight - topMargin;
    const minHeight = (minHeightSmall > (initialLayout.height / 2)) ? minHeightSmall : minHeightLarge;

    const containerStyle = {
      marginTop: topMargin,
      minHeight,
    };

    if (itemCount === 0) {
      return (
        <View style={containerStyle}>
          {renderBlankView()}
        </View>
      );
    }

    return (
      <View style={containerStyle}>
        <GridList
          cols={columnCount}
          cellHeight="auto"
          spacing={0}
          style={Styles.touchEffectContainer}
        >
          {/* <GridListTile key='header' cols={3} style={{ height: 48 }}>
            {this.renderHeader()}
          </GridListTile> */}
          {products.map((product, index) => this.renderProduct(product, index))}
        </GridList>

        {(!loading && pagination.nextPageUrl) ? (
          this.renderLoadMore()
        ) : <View style={{ height: Constants.bottomSpaceForTabBar + 20 }} />}
        {loading ? (<Spinner />) : (null)}
      </View>
    );
  }
}

export default TabPick;
