import React from 'react';
import { connect } from 'react-redux';

import {
  StyleSheet, View, FlatList,
 } from 'react-native';

import { actions, loadEntities } from 'react-shared';
import { productsSortByPopularity } from 'shared/util/product';

import { Constants } from '../../../../constants';

import FeedExhibition from './FeedExhibition';
import FeedProductList from './FeedProductList';
import FeedCategorySaleList from './FeedCategorySaleList';
import FeedPostList from './FeedPostList';
import FeedTimeSaleList from './FeedTimeSaleList';


const styleHtml = {
  container: {
  },
  header: {
    position: 'fixed',
    height: Constants.mainHeaderHeight,
    top: 0,
    left: 0,
    zIndex: 1030,
    width: '100%',
    backgroundColor: '#ffffff',
  },
};

const styles = StyleSheet.create({
  listContainer: {
  },
  itemContainer: {
  },
  itemSeperator: {
    height: 48,
  },
  footer: {
    height: 96,
  },
});


class HomeFeed extends React.Component {
  state = {
    headerVisible: true,
  };

  componentDidMount() {
    const {
      auth,
      loadEditorials,
      loadHomeNewProducts,
      loadHomeDailyPopularProducts,
      loadHomeWeeklyPopularProducts,
      loadHomeStylePickPosts,
      loadHomeMdCurations,
      loadHomeHeartitCurations,
      loadHomeCategorySales,
      loadHomeTimeSales,
      loadHistory,
      loadItemListInCart,
      loadPushHistoryCount,
      logEventPageview,
    } = this.props;
    loadEditorials();
    loadHomeNewProducts();
    loadHomeDailyPopularProducts();
    loadHomeWeeklyPopularProducts();
    loadHomeStylePickPosts();
    loadHomeMdCurations();
    loadHomeHeartitCurations();
    loadHomeCategorySales();
    loadHomeTimeSales();
    if (auth && auth.hasOwnProperty('_id')) {
      loadHistory();
      loadItemListInCart();
      loadPushHistoryCount(auth._id);
    }

    logEventPageview({ page_name: 'Home' });
  }

  componentDidUpdate(prevProps) {
  }

  componentWillUnmount() {
  }

  buildFeedList = () => {
    const feedList = [];

    const {
      auth,
      router,
      editorials,
      homeNewProducts,
      homeDailyPopularProducts,
      homeWeeklyPopularProducts,
      homeStylePickPosts,
      homeHeartitCurations,
      homeCategorySales,
      homeTimeSales,
      histories,
    } = this.props;

    feedList.push({type: FeedExhibition, props : {
      router,
    }});

    // Time Sale
    if (homeTimeSales.length > 0) {
      feedList.push({type: FeedTimeSaleList, props : {
        title: 'Time Sale',
        products: homeTimeSales,
        router,
      }});
    }

    // Heart it Curation
    if (homeHeartitCurations.length > 0) {
      feedList.push({type: FeedProductList, props : {
        title: homeHeartitCurations[0].title,
        products: productsSortByPopularity(homeHeartitCurations[0].products),
        router,
      }});
    }

    feedList.push({type: FeedProductList, props : {
      title: 'New In',
      products: homeNewProducts,
      router,
      onPressSeeAll: () => {
        router.push({
          pathname: '/shop',
          state: {
            sortingRule: { id: 1001, value: 'g.regDt desc', name: '최신순' },
          },
        });
      },
    }});

    // HEART IT Editorial
    if (editorials.length > 0) {
      const editorial = editorials[0];
      feedList.push({type: FeedPostList, props : {
        title: 'Heart It Editorial',
        posts: [
          {
            title: editorial.title,
            thumbnail: editorial.thumbnail,
            onPress: () => {
              window.open(editorial.url);
            }
          }
        ],
      }});
    }

    // Recent View
    if (auth && auth.hasOwnProperty('_id') && histories.length > 0) {
      feedList.push({type: FeedProductList, props : {
        title: 'Recent View',
        products: histories.filter(h => h.product.soldOut != 'y').map(h => h.product),
        router,
      }});
    }

    // Daily Best
    feedList.push({type: FeedProductList, props : {
      title: 'Trend Now',
      products: homeDailyPopularProducts,
      router,
    }});

    // STYLE PICK
    feedList.push({type: FeedPostList, props : {
      title: 'Style Pick',
      posts: homeStylePickPosts.map(post => ({
        title: post.popularTag.tag[0].title,
        thumbnail: post.media[0],
        onPress: () => {
          router.push(`/style/feed/${post._id}`);
        },
      })),
      onPressSeeAll: () => router.push('/style/all'),
    }});

    // Weekly Best
    feedList.push({type: FeedProductList, props : {
      title: 'Weekly Best',
      products: homeWeeklyPopularProducts,
      router,
    }});

    homeCategorySales.forEach(categorySale => {
      feedList.push({type: FeedCategorySaleList, props : {
        title: categorySale.title,
        description: categorySale.description,
        categorySale: categorySale,
        router,
        onPressSeeAll: () => {
          const filters = categorySale.filterList;
          const priceFilter = filters.filter(x => x.type == "price");
          const keywordFilter = filters.filter(x => x.type == "keyword");
          const crawlerFilter = filters.filter(x => x.type == "crawler");
          let shopFilterParams = {
            brandCd: filters.filter(x => x.type == "brand"),
            keywordFilter: keywordFilter.length > 0 ? keywordFilter: null,
            priceFilter: priceFilter.length > 0 ? priceFilter[0]: null,
            crawlerFilter: crawlerFilter.length > 0 ? crawlerFilter[0]: null,
            productsFilter: categorySale.products.length > 0 ? categorySale.products: null,
          };
          if (filters.length === 0) {
            shopFilterParams = {
              resetFilter: true,
            }
          }

          router.push({
            pathname: '/shop',
            state: {
              cateCd: categorySale.category != 'none' ? categorySale.category : null,
              ...shopFilterParams,
              sortingRule: categorySale.sortBy,
            },
          });
        },
      }});
    });

    return feedList;
  }

  renderItem = ({ item, index }) => {

    const { navigation } = this.props;

    let FeedType = item.type;

    return (
      <View
        key={index}
        style={styles.itemContainer}
        >
        <FeedType
          navigation={navigation}
          {...item.props}
          />
      </View>
    )
  }

  render() {
    const feedList = this.buildFeedList();

    return (
      <div style={styleHtml.container}>
        <FlatList
          keyExtractor={(item, idx) => { return `${idx}`}} // eslint-disable-line no-underscore-dangle
          renderItem={this.renderItem}
          ItemSeparatorComponent={() => <View style={styles.itemSeperator} />}
          style={styles.listContainer}
          data={feedList}
          ListFooterComponent={()=> <View style={styles.footer} />}
          scrollEventThrottle={1}
          />
      </div>
    );
  }
}


export default connect(
  (state, { router }) => ({
    auth: state.auth,
    ...loadEntities(state, 'editorials', 'editorials'),
    ...loadEntities(state, 'homeNewProducts', 'homeNewProducts'),
    ...loadEntities(state, 'homeDailyPopularProducts', 'homeDailyPopularProducts'),
    ...loadEntities(state, 'homeWeeklyPopularProducts', 'homeWeeklyPopularProducts'),
    ...loadEntities(state, 'homeStylePickPosts', 'homeStylePickPosts'),
    ...loadEntities(state, 'histories', 'histories'),
    ...loadEntities(state, 'homeHeartitCurations', 'homeHeartitCurations'),
    ...loadEntities(state, 'homeCategorySales', 'homeCategorySales'),
    ...loadEntities(state, 'homeTimeSales', 'homeTimeSales'),
  }),
  actions,
)(HomeFeed);
