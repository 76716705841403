import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadEntities } from 'react-shared';
import { Link } from 'react-router';
import {
  Button,
  Checkbox,
  Dialog,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core';
import { compose } from 'recompose';

import Spinner from 'components/Spinner';
import PropTypes from 'prop-types';
import * as adminActions from 'react-shared/adminActions';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
  container: {
    width: 960,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  table: {
    width: '100%',
    padding: 5,
  },
  tableCellName: {
    width: 700,
    maxWidth: 700,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tableCellDate: {
    width: 100,
    maxWidth: 100,
    overflow: 'hidden',
  },
  pageTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

export class NoticeListPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { adminLoadNotices } = this.props;
    adminLoadNotices();
  }

  onButtonDelete(notice) {
    const { adminDeleteNotice } = this.props;

    const result = confirm(`이 공지사항을 삭제하시겠습니까?\n${notice.subject}`);
    if (result) {
      adminDeleteNotice({ noticeId: notice._id });
    }
  }

  renderButtonEdit(notice) {
    return (
      <Link to={`/admin/notice/${notice._id}`}>
        <IconButton
          variant='contained'
          color='inherit'
          aria-label='Edit Notice'
        >
          <EditIcon />
        </IconButton>
      </Link>
    );
  }

  renderButtonDelete(product) {
    return (
      <IconButton
        variant='contained'
        color='secondary'
        aria-label='Delete product'
        onClick={() => { this.onButtonDelete(product); }}
      >
        <DeleteIcon />
      </IconButton>
    );
  }

  render() {
    const { classes, adminNotices } = this.props;

    return (
      <div className={classes.container}>
        <Typography variant="h5" className={classes.pageTitle}>
          공지사항
        </Typography>
        <Link to={'/admin/notice/new'}>
          <Button
            variant='contained'
            color='default'
            className={classes.button}
            startIcon={<AddIcon />}
          >
            공지사항 추가
          </Button>
        </Link>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellName}>제목</TableCell>
              <TableCell className={classes.tableCellDate}>작성일시</TableCell>
              <TableCell className=''></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adminNotices.map(notice=> (
              <TableRow key={`${notice._id}`}>
                <TableCell className={classes.tableCellName}>{notice.subject}</TableCell>
                <TableCell className={classes.tableCellDate}>{notice.createdAt.slice(0, 10).replace(/-/g, '.')}</TableCell>
                <TableCell>
                  {this.renderButtonEdit(notice)}
                  {this.renderButtonDelete(notice)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(state => ({
    ...loadEntities(state, 'adminNotices', 'adminNotices'),
  }), adminActions),
)(NoticeListPage);
