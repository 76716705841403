import * as React from 'react';

function SvgImgLogoFacebook(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={54} height={54} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={27} cy={27} r={27} fill="#3C5CA9" />
        <path
          fill="#FFF"
          d="M32.436 27.352H39l-.864 7.174h-5.7L32.5 54h-6.909l-.064-19.474H20v-7.174h5.527v-6.254C25.527 10.429 39 13.372 39 13.372v6.254h-3.455c-3.322 0-3.109 3.311-3.109 3.311v4.415z"
        />
      </g>
    </svg>
  );
}

export default SvgImgLogoFacebook;

