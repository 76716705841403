import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, MenuItem, Select, Typography, withStyles,
} from '@material-ui/core';
import { Link } from 'react-router';
import { compose } from 'recompose';
import dayjs from 'dayjs';
import {
  adminDeletePost,
  adminLoadPosts,
  adminSetPostFeatured,
  adminSetPostUnfeatured,
  loadCreators,
} from 'react-shared/adminActions';
import { loadEntities } from 'react-shared/util';
import Spinner from 'components/Spinner';
import ThumbnailList from 'components/admin/ThumbnailList';
import Media from 'components/Media';

import FilterNoneIcon from '@material-ui/icons/FilterNone';

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    minHeight: 50,
    padding: 20,
  },
});

class ListPostPage extends Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.renderCaption = this.renderCaption.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.handleFeaturedClick = this.handleFeaturedClick.bind(this);
    this.featuredDialogClose = this.featuredDialogClose.bind(this);
    this.featuredDialogAgree = this.featuredDialogAgree.bind(this);
    this.state = {
      _id: '',
      // setFeaturedDialogOpen: false,
      // unsetFeaturedDialogOpen: false,
      featuredDialogOpen: false,
      selectedPost: null,
      hideItemPost: false,
    };
  }

  componentDidMount() {
    this.props.loadCreators();
    ListPostPage.fetchData(this.props);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if (this.props.userId !== prevProps.userId) {
      ListPostPage.fetchData(this.props);
    }
  }

  static fetchData(props, nextPage) {
    props.adminLoadPosts(nextPage, props.userId);
  }

  static getMedia(post) {
    return post.media[0];
  }

  handleScroll() {
    if (window.pageYOffset > document.body.clientHeight - window.innerHeight - 1
        && !this.props.pagination.isFetching && this.props.pagination.nextPageUrl) {
      this.handleLoadMore();
    }
  }

  handleLoadMore() {
    ListPostPage.fetchData(this.props, true);
  }

  handleFeaturedClick(post) {
    this.setState({
      featuredDialogOpen: true,
      selectedPost: post,
    });
  }

  featuredDialogClose() {
    this.setState({
      featuredDialogOpen: false,
      selectedPost: null,
    });
  }

  featuredDialogAgree(type) {
    if (type === 'set') {
      this.props.adminSetPostFeatured(this.state.selectedPost._id);
    } else if (type === 'unset') {
      this.props.adminSetPostUnfeatured(this.state.selectedPost._id);
    }
    this.featuredDialogClose();
  }

  static guessMedia(item) {
    if (Array.isArray(item.media)) { return item.media[0]; }
    return item.media || item;
  }

  renderFeaturedDialog(type) {
    const media = ListPostPage.guessMedia(this.state.selectedPost);
    const mediaOptions = {
      width: 250,
      height: 250,
      crop: 'limit',
    };
    // console.log(this.state.selectedPost.featured);
    return (
      <Dialog open={this.state.featuredDialogOpen} onClose={() => { this.setState({ featuredDialogOpen: false }); }}>
        <DialogTitle>
Are you sure to
          {type === 'set' ? 'FEATURE' : 'UNFEATURE'}
          {' '}
this post?
        </DialogTitle>
        <DialogContent>
          <Media media={media} options={mediaOptions} />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.featuredDialogClose} color="primary">
              Disagree
          </Button>
          <Button onClick={() => this.featuredDialogAgree(type)} color="primary" autoFocus>
              Agree
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderSetFeatured(post) {
    if (!post.featured || !post.featured.score) {
      return (
        <button
          type="button"
          className="btn btn-default btn-small"
          onClick={() => this.handleFeaturedClick(post, 'set')}
        >
          <span className="glyphicon glyphicon-thumbs-up"></span>
        </button>
      );
    }
    return (
      <button
        type="button"
        className="btn btn-default btn-small"
        onClick={() => this.handleFeaturedClick(post, 'unset')}
        style={{ backgroundColor: '#ff6699' }}
      >
        <span className="glyphicon glyphicon-thumbs-up"></span>
      </button>
    );
  }

  

  static renderEditButton(post) {
    return (
      <Link className="btn btn-default btn-small" to={`/admin/posts/${post._id}`}>
        <span className="glyphicon glyphicon-edit"></span>
      </Link>
    );
  }

  renderDeleteButton(post) {
    return (
      <button
        type="button"
        className="btn btn-default btn-small"
        onClick={
          () => confirm('Are you sure to delete this post?') &&  //eslint-disable-line
                this.props.adminDeletePost(post._id)
        }
      >
        <span className="glyphicon glyphicon-trash"></span>
      </button>
    );
  }

  renderCreatePopupButton(post) {
    return (
      <Link to={`/admin/marketingPopups/new/?postId=${post._id}`}>
        <button
          type="button"
          className="btn btn-default btn-small"
        >
            <FilterNoneIcon style={{ width: 16, height: 15 }} />
        </button>
      </Link>
    );
  }

  renderCaption(post) {
    const captionStyle = { height: 30, textAlign: 'center' };
    return (
      <div>
        <p>{post.owner.username}</p>
        <div style={captionStyle}>
          {this.renderSetFeatured(post)}
          {ListPostPage.renderEditButton(post)}
        </div>
        <div style={captionStyle}>
          {this.renderCreatePopupButton(post)}
          {this.renderDeleteButton(post)}
        </div>
      </div>
    );
  }

  static renderDate(post) {
    if (post.featured) {
      // TODO: fix conditions if all the featured scores become valid Epoch time value
      if (post.featured.score > 10000) {
        return dayjs(post.featured.score).format('YYYY-MM-DD (HH:mm)');
      } else if (post.featured.at) {
        return dayjs(post.featured.at).format('YYYY-MM-DD (HH:mm)');
      }
    }
    return (
      <span style={{ color: '#bbbbbb', textDecoration: 'line-through', fontSize: '10px' }}>
        {'created at ' + dayjs(parseInt(post._id.substring(0, 8), 16) * 1000).format('YYYY-MM-DD (HH:mm)')}
      </span>
    );
  }

  render() {
    const { classes, posts, pagination: { isFetching }, router, users } = this.props;
    router.replace('/admin/stylePosts');

    const { hideItemPost } = this.state;
    const menuItems = [{ username: '모든 포스트 보기', userId: '', _id: '' }].concat(users);
    return (
      <div className="container">
        <div className={`row ${classes.root}`}>
          <Select
            value={this.state._id}
            onChange={event => {
              this.setState({ _id: event.target.value });
              router.push(event.target.value
                ? `/admin/posts?userId=${event.target.value}`
                : '/admin/posts');
            }}
            style={{ width: 300 }}
            displayEmpty
          >
            {menuItems.map(menuItem => (
              <MenuItem
                key={`listpostpage_menuitem_${menuItem._id}`}
                value={menuItem._id}
              >
                <Typography variant="subheading">
                  {menuItem.username}
                </Typography>
              </MenuItem>
            ))}
          </Select>
          <div style={{ display: 'inline-block' }}>
            &nbsp;
            <input type="checkbox" onChange={event => this.setState({ hideItemPost: event.target.checked })} />
            &nbsp;아이템 포스트 숨기기
          </div>
        </div>
        <ThumbnailList
          getMedia={ListPostPage.getMedia}
          items={hideItemPost ? posts.filter(post => post.kind !== 'item') : posts}
          renderCaption={this.renderCaption}
          // {...pagination}
          // onLoadMore={this.handleLoadMore.bind(this)}
          isSortable={false}
          renderHeader={post => ListPostPage.renderDate(post)}
        />
        {isFetching && <Spinner />}
        {this.state.selectedPost && this.renderFeaturedDialog(
          this.state.selectedPost.featured && this.state.selectedPost.featured.score
            ? 'unset' : 'set'
        )}
      </div>
    );
  }
}

ListPostPage.propTypes = {
  adminDeletePost: PropTypes.func.isRequired,
  loadCreators: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  adminLoadPosts: PropTypes.func.isRequired,
  posts: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  // role: PropTypes.string,
  adminSetPostFeatured: PropTypes.func.isRequired,
  adminSetPostUnfeatured: PropTypes.func.isRequired,
  userId: PropTypes.string,
  // username: PropTypes.string,
  users: PropTypes.array.isRequired,
};

ListPostPage.defaultProps = {
  userId: '',
};

export default compose(
  withStyles(styles),
  connect((state, ownProps) => {
    const { userId } = ownProps.location.query;
    return {
      userId,
      // username: state.auth.username,
      users: loadEntities(state, 'creators', 'users').users,
      ...loadEntities(state, userId ? `userPosts.${userId}` : 'posts', 'posts'),
    };
  }, {
    adminDeletePost,
    adminLoadPosts,
    adminSetPostFeatured,
    adminSetPostUnfeatured,
    loadCreators,
  }),
)(ListPostPage);
