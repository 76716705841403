import React from 'react';
import {
  Dimensions, StyleSheet, View, TextInput, Text,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';
import {
  default as PhoneVerificationForm, isPhoneNumber,
} from '../../../nativeComponents/PhoneVerificationForm';
import Button from '../../../nativeComponents/Button';

import Styles from '../../../constants/Styles';
import Colors from '../../../constants/Colors';
import { initAlert, showAlert, renderAlert } from '../../../components/AlertDialog';
import Constants from '../../../constants/Constants';

const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  inputFormContainer: {
    flex: 1,
    paddingTop: 10,
  },
  inputText: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    paddingHorizontal: 16,
    paddingTop: 17,
    paddingBottom: 16,
    backgroundColor: Colors.semiGray,
    height: 52,
    lineHeight: 19,
  },
  inputBorder: {
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
  },
  errorMessage: {
    lineHeight: 20,
    marginTop: -4,
    marginBottom: 15,
    ...Styles.appleSDGothicNeoLight,
    fontSize: 15,
    color: 'rgb(252, 43, 56)',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    marginBottom: 20,
  },
  bottomButtonContainer: {
    ...Styles.buttonDark,
  },
  bottomButtonContainerDisabled: {
    ...Styles.buttonDark,
    ...Styles.buttonDarkDisabled,
  },
  bottomButton: {
    backgroundColor: 'transparent',
    height: 48,
    padding: 0,
    borderWidth: 0,
  },
  bottomButtonText: {
    fontSize: 14,
    height: 17,
    lineHeight: 17,
    marginTop: 16,
    marginBottom: 15,
    ...Styles.appleSDGothicNeoBold,
    ...Styles.buttonDarkText,
  },
  bottomButtonTextDisabled: {
    fontSize: 14,
    height: 17,
    lineHeight: 17,
    marginTop: 16,
    marginBottom: 15,
    ...Styles.appleSDGothicNeoBold,
    ...Styles.buttonDarkText,
    ...Styles.buttonDarkTextDisabled,
  },
  resultContainer: {
    flex: 1,
    paddingTop: 27,
  },
  resultText: {
    marginTop: 5,
    marginStart: 10,
    ...Styles.appleSDGothicNeoBold,
    fontSize: 22,
    height: 27,
    lineHeight: 27,
  },
});

class FindIdTab extends React.Component {
  state = {
    name: '',
    nameErrorMessage: '',
    phone: '',
    phoneErrorMessage: '',
    phoneVerified: false,
    id: '',
    verified: false,
  };

  nameInputRef = null;

  phoneNumberInputRef = null;

  constructor(props) {
    super(props);

    initAlert(this);
    this.alert = showAlert(this);
  }

  componentDidMount() {
    this.nameInputRef.focus();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.focused && this.props.focused) {
      this.nameInputRef.focus();
    }
  }

  requestTelVerificationCodeCallback = async phone => {
    const {
      requestFindEmailWithSMSVerificationCode,
    } = this.props;
    const { name } = this.state;

    try {
      const resp = await requestFindEmailWithSMSVerificationCode(name, phone);
      return resp;
    } catch (e) {
      if (e.status === 404) {
        e.showAlert = false;
        this.alert('가입되어 있지 않은 번호입니다');
      }
      throw e;
    }
  };

  verifyTelVerificationCodeCallback = async (phone, code) => {
    const {
      verifyFindEmailWithSMSVerificationCode,
    } = this.props;
    const { name } = this.state;

    this.setState({
      nameErrorMessage: '',
      phoneErrorMessage: '',
    });

    const resp = await verifyFindEmailWithSMSVerificationCode(name, phone, code);

    this.setState({ id: resp.email });
  };

  onPressSubmit = () => {
    const { id } = this.state;
    if (!!id && id !== '') {
      this.setState({ verified: true });
    }
  };

  showPhoneVerificationError = msg => {
    this.setState({ phoneErrorMessage: msg });
  }

  validatePhoneVerificationFormInput = () => {
    const {
      name,
      phone,
    } = this.state;

    this.setState({
      nameErrorMessage: '',
      phoneErrorMessage: '',
    });

    if (!name || name === '') {
      this.setState({ nameErrorMessage: '이름을 입력하세요.' });
      return false;
    }

    if (!phone || phone === '') {
      this.setState({ phoneErrorMessage: '휴대폰 번호를 입력하세요.' });
      return false;
    }

    if (!isPhoneNumber(phone)) {
      this.setState({ phoneErrorMessage: '휴대폰 번호 형식을 확인하세요.' });
      return false;
    }
    return true;
  }

  validateInput() {
    const {
      name,
      phone,
      phoneVerified,
    } = this.state;

    if (!name || name === '') {
      return false;
    }

    if (!phone || phone === '') {
      return false;
    }

    if (!isPhoneNumber(phone)) {
      return false;
    }

    if (!phoneVerified) {
      return false;
    }

    return true;
  }

  renderInputForm() {
    const {
      name,
      nameErrorMessage,
      phone,
      phoneErrorMessage,
      phoneVerified,
    } = this.state;

    const submitButtomDisabled = !this.validateInput();

    return (
      <View style={styles.inputFormContainer}>
        <TextInput
          ref={ref => { this.nameInputRef = ref; }}
          autoCapitalize="none"
          keyboardType="default"
          onChangeText={val => this.setState({ name: val, nameErrorMessage: '' })}
          placeholder="이름"
          placeholderTextColor={Colors.placeholderColor}
          style={[
            styles.inputBorder,
            styles.inputText,
            { outline: 'none' },
          ]}
          value={name}
          onSubmitEditing={() => {
            if (this.phoneNumberInputRef) {
              this.phoneNumberInputRef.focus();
            }
          }}
        />

        {!!nameErrorMessage && (
          <Text style={[styles.errorMessage]}>
            {nameErrorMessage}
          </Text>
        )}

        <PhoneVerificationForm
          onPhoneNumberInputRef={ref => { this.phoneNumberInputRef = ref; }}
          defaultPhoneNumber={phone}
          defaultVerified={phoneVerified}
          showAlert={this.showPhoneVerificationError}
          onChangePhoneNumber={value => { this.setState({ phone: value, phoneErrorMessage: '' }); }}
          verificationChanged={verified => { this.setState({ phoneVerified: verified, phoneErrorMessage: '' }); }}
          validateInputCallback={this.validatePhoneVerificationFormInput}
          requestTelVerificationCodeCallback={this.requestTelVerificationCodeCallback}
          verifyTelVerificationCodeCallback={this.verifyTelVerificationCodeCallback}
        />

        {!!phoneErrorMessage && (
          <Text style={[styles.errorMessage]}>
            {phoneErrorMessage}
          </Text>
        )}

        <View style={styles.footer}>
          <View style={(submitButtomDisabled) ? styles.bottomButtonContainerDisabled : styles.bottomButtonContainer}>
            <Button
              containerStyle={styles.bottomButton}
              underlayColor={Colors.touchEffectColorBlack}
              onPress={this.onPressSubmit}
              disabled={submitButtomDisabled}
              title="확인"
              titleStyle={(submitButtomDisabled) ? styles.bottomButtonTextDisabled : styles.bottomButtonText}
            />
          </View>
        </View>

        {renderAlert(this)}
      </View>
    );
  }

  renderResultView() {
    const { router } = this.props;
    const { id } = this.state;

    return (
      <View style={styles.resultContainer}>
        <Text style={styles.resultText}>아이디 찾기 결과입니다.</Text>
        <Text style={styles.resultText}>{id}</Text>

        <View style={styles.footer}>
          <View style={styles.bottomButtonContainer}>
            <Button
              containerStyle={styles.bottomButton}
              underlayColor={Colors.touchEffectColorBlack}
              onPress={() => router.push('/accounts/login')}
              title="로그인 페이지로"
              titleStyle={styles.bottomButtonText}
            />
          </View>
        </View>
      </View>
    );
  }


  render() {
    const { showSmartBanner } = this.props;
    const { verified } = this.state;

    const minHeight = windowHeight - (showSmartBanner ? Constants.smartBannerHeight : 0)
    - Constants.mainHeaderHeight - Constants.tabbarHeight;

    return (
      <View style={[styles.container, { height: minHeight }]}>
        {verified ? this.renderResultView() : this.renderInputForm()}
      </View>
    );
  }
}


export default connect(
  state => ({
    showSmartBanner: state.globalLayoutInfo.showSmartBanner,
  }),
  actions
)(FindIdTab);
