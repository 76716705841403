import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, Image, Alert } from 'react-native';
import { connect } from 'react-redux';

import { actions } from 'shared';

import { TouchableHighlightWithContainer } from '../../../components-v2';
import { HeaderCameraIcon } from '../../../icons-v2';
import { uploadProfileImage } from '../../../utils/uploadFile';


const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    alignItems: 'center',
  },
  contentContainer: {
    height: 100,
    width: 100,
  },
  imageContainer: {
    height: 100,
    width: 100,
    backgroundColor: '#cfcfca',
    borderRadius: 50,
    overflow: 'hidden',
  },
  image: {
    flex: 1,
  },
  cameraIconContainer: {
    position: 'absolute',
    bottom: 0,
    end: 0,
    width: 32,
    height: 32,
    backgroundColor: '#DEDEDE',
    borderRadius: 16,
    padding: 4,
  },
});


class EditImage extends React.Component {

  state = {
    saving: false,
  };

  inputOpenFileRef = React.createRef();


  onPress = () => {
    this.inputOpenFileRef.current.click();
  };

  onUploadImage = event => {
    event.stopPropagation();
    event.preventDefault();

    const file = event.target.files[0];
    // console.log('onUploadImage file=', file);

    const reader = new FileReader();
    reader.onloadend = uploadFile => {
      const data = uploadFile.target.result;
      const imageData = data.split(';base64,')[1];
      const source = {
        fileName: file.name,
        size: file.size,
        type: file.type,
        data: imageData,
        dataUrl: data,
      };
      // console.log('onUploadImage', source);
      this.upload(source, file);
    };
    reader.readAsDataURL(file);

    event.target.value = null;
  }

  async upload(file, formFile) {
    const {
      productId, auth,
    } = this.props;

    this.onUploadImageStart();

    try {

      const ret = await uploadProfileImage(auth.token, auth._id, formFile);
      // console.log('uploadProfileImage return', ret);

      const uploadImage = {
        ...ret.data,
      };

      const { onChange } = this.props;
      onChange(uploadImage);

    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('uploadProfileImage failed error=', error);

      if (error.errorCode) {
        alert(`이미지 첨부 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다. (E${error.errorCode})`);
      } else {
        alert('이미지 첨부 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.');
      }
    } finally {
      this.onUploadImageEnd();
    }
  }

  onUploadImageStart() {
    // console.log('onUploadFileStart');
    this.setState({ saving: true });
  }

  onUploadImageEnd() {
    // console.log('onUploadFileEnd');
    this.setState({ saving: false });
  }

  render() {
    const { profileImage } = this.props;

    // console.log('EditImage uploadImage=', uploadImage);
    const imgSource = profileImage ? profileImage.url : null;

    return (
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          <View style={styles.imageContainer}>
            <Image
              style={styles.image}
              source={{ uri: imgSource }}
              />
          </View>
          <TouchableHighlightWithContainer
            onPress={this.onPress}
            >
            <View style={styles.cameraIconContainer}>
              <HeaderCameraIcon />
            </View>
          </TouchableHighlightWithContainer>
          <input
            ref={this.inputOpenFileRef}
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={this.onUploadImage}
          />
        </View>
      </View>
    )
  }
}


EditImage.propTypes = {
  onChange: PropTypes.func,
  profileImage: PropTypes.object,
};


EditImage.defaultProps = {
  onChange: () => null,
  profileImage: undefined,
};


export default connect(
  (state) => ({
    auth: state.auth,
  }),
  actions
)(EditImage);
