import * as React from 'react';

function SvgIcTabMySelected(props) {
  const { active = true } = props;

  const border = active ? '#fff' : '#000';
  const body = active ? '#000' : '#fff';

  return (
    <svg width={24} height={24} {...props}>
      <defs>
        <path
          id="icTabMySelected_svg__a"
          d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11z"
        />
        <path
          id="icTabMySelected_svg__c"
          d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill={border}
          fillOpacity={0.4}
          stroke={body}
          strokeWidth={1.5}
          d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11z"
        />
        <mask id="icTabMySelected_svg__b" fill={border}>
          <use xlinkHref="#icTabMySelected_svg__a" />
        </mask>
        <use fill={border} xlinkHref="#icTabMySelected_svg__a" />
        <path
          stroke={body}
          strokeWidth={2}
          d="M21 25a9 9 0 11-18.001-.001A9 9 0 0121 25zM15.385 9.385a3.385 3.385 0 11-6.77-.002 3.385 3.385 0 016.77.002z"
          mask="url(#icTabMySelected_svg__b)"
        />
        <use
          stroke={body}
          strokeLinejoin="round"
          strokeWidth={1.2}
          xlinkHref="#icTabMySelected_svg__c"
        />
      </g>
    </svg>
  );
}

export default SvgIcTabMySelected;
