import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import dayjs from 'dayjs';
import Flatpickr from 'react-flatpickr';

import {
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import FiberNew from '@material-ui/icons/FiberNew';
import cloneDeep from 'lodash/cloneDeep';

import * as adminActions from 'react-shared/adminActions';
import { objectDiff } from 'react-shared/util';

import config from '../../config';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
  },
  keywordText: {
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  updateKeywordButton: {
    position: 'absolute',
    right: theme.spacing.unit * 2,
    top: theme.spacing.unit * 2,
  },
});

class SearchKeywordPage extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      keywordData: '',
      isUpdatingKeyword: false,
    };
  }

  async componentDidMount() {
    const { adminGetRecommendedSearchKeyword, route, router } = this.props;
    const data = await adminGetRecommendedSearchKeyword();
    this.setState({
      keywordData: data.keywordData.join('\n'),
    });
    this.saveState();
    console.log(this.state);
    router.setRouteLeaveHook(route, () => this.handleBackbutton());
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', event => this.handleClose(event));
  }

  saveState() {
    this.savedState = cloneDeep(this.state);
  }

  hasKeywordEdited() {
    return !!Object.keys(objectDiff(this.savedState, this.state)).length;
  }

  showSnackbar(message) {
    this.setState({
      snackbarMsg: message,
      snackbarOpen: true,
    });
  }

  handleClose(event) {
    if (this.hasKeywordEdited()) {
      event.preventDefault();
      event.returnValue = '수정 중인 내용이 있습니다. 정말 창을 닫으시겠어요?';
      return event.returnValue;
    }
    return event;
  }

  handleBackbutton() {
    const { router: { location: { pathname } } } = this.props;
    if (this.hasKeywordEdited()) {
      // eslint-disable-next-line no-restricted-globals
      const toBeClosed = confirm('배너를 작성중이시네요, 정말 창을 닫으시겠어요?');
      if (!toBeClosed) {
        window.history.pushState(null, null, pathname);
        return false;
      }
    }
    return true;
  }

  handleSubmit(event) {
    const { keywordData } = this.state;
    const { adminUpdateRecommendedSearchKeyword } = this.props;
    this.setState(
      { isUpdatingKeyword: true },
      async () => {
        try {
          const newData = await adminUpdateRecommendedSearchKeyword({ keywordData });
          this.setState({
            keywordData: newData.keywordData.join('\n'),
          });
          this.saveState();
          this.showSnackbar('Keyword has been updated succesfully.');
        } catch(e) {
          console.error(e);
          this.showSnackbar('Update failed.')
        } finally {
          this.setState({ isUpdatingKeyword: false });
        }
      },
    );
    event.preventDefault();
  }

  render() {
    const { keywordData } = this.state;
    const { classes } = this.props;
    return (
      <div className="container">
        <Paper className={classes.paper}>
          <Typography variant="title" className={classes.subMenuTitles}>추천 검색어 목록</Typography>
          <div>
            <TextField
              className={classes.keywordText}
              multiline={true}
              rows={10}
              rowsMax={20}
              value={keywordData}
              placeholder="추천 검색어 입력"
              onChange={event => {
                this.setState({ keywordData: event.target.value });
              }}
            />
            <Typography variant="caption">
              추천 검색어를 줄바꿈으로 구분하여 입력하세요.
            </Typography>
          </div>
        </Paper>
        <div style={{ height: 200, position: 'relative' }}>
          <Button
            variant="fab"
            onClick={this.handleSubmit}
            className={classes.updateKeywordButton}
            color="primary"
            disabled={this.state.isUpdatingKeyword}
          >
            저장
          </Button>
        </div>
        <Snackbar
          autoHideDuration={2000}
          className={classes.snackbar}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
        />
      </div>
    );
  }
}

SearchKeywordPage.propTypes = {
  adminGetRecommendedSearchKeyword: PropTypes.func.isRequired,
  adminUpdateRecommendedSearchKeyword: PropTypes.func.isRequired,
};

export default compose(
  withStyles(
    styles
  ),
  connect(
    (state, ownProps) => ({}),
    adminActions
  ),
)(SearchKeywordPage);
