import React from 'react';
import { connect } from 'react-redux';

import {
  Platform, StyleSheet, View, ScrollView, Animated, Text,
} from 'react-native';
import { browserHistory } from 'react-router';

import { actions, loadEntities } from 'shared';
import { parseDate, convertKstToUtc } from 'shared/util';

import { Constants, Styles, Colors } from '../../../constants';
import {
  SoldoutAlarmWhiteIcon, SoldoutAlarmIcon,
} from '../../../icons-v2';

import {
  TouchableHighlightWithContainer
} from '../../../components-v2';

import {
  addToWishlist,
} from '../../../components-v2/WishlistControl';

import {
  showCheckout,
} from './CheckoutControl';


import ProductImages from './ProductImages';
import ProductInfo from './ProductInfo';
import ShippingInfo from './ShippingInfo';
import RegistrationCoupon from './RegistrationCoupon';
import PreOrderInfo from './PreOrderInfo';
import Heartists from './Heartists';
import EditorNote from './EditorNote';
import ShippingAndRefund from './ShippingAndRefund';
import ProductDetail from './ProductDetail';
import ProductReviews from './ProductReviews';
import PriceComparison from './PriceComparison';
import SimilarProducts from './SimilarProducts';
import { initAlert, renderAlert, showAlert } from '../../../components/AlertDialog';
import { moveToMemberInfo } from '../../my/MemberInfoPage';

import _styles from '../../../css/modules/product.module.css';


const headerHeight = Constants.mainHeaderHeight + Constants.mainHeaderMarginTop + ((Platform.OS === 'android') ? 0 : Constants.statusBarHeight);


const styleHtml = {
  container: {
  },
  listContainer: {
  },
  footer: {
    position: 'fixed',
    width: 'min(960px, 100vw)',
    marginLeft: 'auto',
    marginRight: 'auto',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#ffffff',
    height: 60,
  }
};

const styles = StyleSheet.create({
  scrollView: {
  },
  header: {
    height: headerHeight,
    position: 'absolute',
    top: 0,
    start: 0,
    width: '100%',
    backgroundColor: '#ffffff',
  },
  scrollViewBottom: {
    height: 89,
  },
  contentContainer: {
  },
  contentStyle: {
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  itemSeparator: {
    height: 0.5,
    backgroundColor: Colors.lineGray,
    marginHorizontal: Constants.viewHorizontalPadding,
  },
  footerContainer: {
    width: '100%',
    height: 60,
    backgroundColor: '#000000',
    flexDirection: 'row',
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  footerButtonContainer: {
    width: '100%',
    height: 60,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  footerLeftButtonText: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    letterSpacing: '2%',
    color: '#a2a4a6',
  },
  footerRightButtonText: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    letterSpacing: '2%',
    color: '#ffffff',
  },

  deliveryContainer: {
    backgroundColor: '#000',
    paddingTop: 11,
    height: 43,
  },
  deliveryTextContainer: {
    marginLeft: 18,
    marginRight: 18,
    flexDirection:'row'
  },
  deliveryTitleText: {
    color: '#fff',
    width: 160,
    marginEnd:12,
    ...Styles.pretendard400,
  },
  deliveryText: {
    color: '#fff',
    display:'inline-block',
    marginBottom:1,
    lineHeight:19.2,
    ...Styles.pretendard400,
  },
  deliveryBoldText: {
    color: '#fff',
    ...Styles.pretendard700,
  },
  deliveryBoldTextRed: {
    color: '#E94949',
    ...Styles.pretendard700,
  }
});


class ProductPage extends React.Component {

  containerRef = React.createRef();

  state = {
    headerVisible: false,
    soldoutAlarmData: [],
    similarProductList: [],
    sameBrandProductList: [],
    collapsed: false,
    isMarketing: false,
  };

  checkLogin = () => {
    const { auth, router } = this.props;
    if (!auth || !auth.token) {
      alert('로그인해주시기 바랍니다.');
      browserHistory.push('/accounts/login');
      return false;
    }
    return true;
  }

  constructor(props) {
    super(props);

    initAlert(this);
    this.showAlert = showAlert(this);
  }

  componentWillUnmount() {
  }

  componentDidMount() {
    const {
      logEventPageview, productId,
      moveFrom, router,
    } = this.props;

    this.loadProduct(this.props);

    logEventPageview({ page_name: 'Product', page_key: productId });


    const hasShowenJoineDoneCouponeAlert = sessionStorage.getItem('hasShowenJoineDoneCouponeAlert') || false;
    if (moveFrom == 'JoinDone' && !hasShowenJoineDoneCouponeAlert) {
      sessionStorage.setItem('hasShowenJoineDoneCouponeAlert', 'true');
      /*
      this.showAlert('가입 완료! 15% 할인쿠폰이\n발급되었습니다.', {
        buttons: [
          {
            text: '닫기',
          },
          {
            text: '쿠폰 확인하기',
            onPress: () => {
              moveToMemberInfo(router, 'coupon');
            }
          }
        ],
        titleStyle: {
          display : 'inline-block',
          whiteSpace: 'pre-wrap',
          textAlign: 'center',
        },
      });
      */
    }

  }

  componentWillReceiveProps(newProps) {
    const { productId } = this.props;
    if (productId !== newProps.productId) {
      this.loadProduct(newProps);
    }
  }

  loadProduct(props) {
    const {
      loadShopProduct,
      loadProductRelatedPosts,
      loadShopProductReviewList,
      logPageviewEvent,
      getSimilarProductList,
      loadSoldoutFollowingProduct,
      auth,
      productId,
      postId,
      loadLikedProducts,
    } = props;

    loadShopProduct({ productId, postId, updateRecent: true })
      .then(({ data: product }) => {
        //console.log(product);
        if(product.categoryList && Object.keys(product.categoryList).includes("018")){
          this.setState({collapsed:true, isMarketing:true});
        }

        getSimilarProductList({
          'cateGoods[]': '',
          count: 21,
          sortBy: '',
          'brandList': product.brandCd,
        }).then(result => {
          this.setState({ sameBrandProductList: result.data.goodsData.filter(good => good.goodsNo !== productId).slice(0, 20) });
        });
        let colorList = [];
        if (product.color) {
          colorList = product.color.match(/background-color:#([A-Fa-f0-9]*)/g).map(c => c.substring(18));
        }
        const midCategoryList = product.categoryList ? Object.keys(product.categoryList).filter(c => c.length === 6) : [];
        const midCategory = midCategoryList.length > 0 ? midCategoryList[0] : '';
        getSimilarProductList({
          'cateGoods[]': midCategory,
          count: 21,
          sortBy: '',
          colorList: colorList.join(","),
        }).then(result => {
          const productList = result.data.goodsData.filter(good => good.goodsNo !== productId).slice(0, 20);
          const sameBrandProductList = productList.filter(good => good.brandCd === product.brandCd);
          const otherBrandProductList = productList.filter(good => good.brandCd !== product.brandCd);
          this.setState({ similarProductList: [...sameBrandProductList, ...otherBrandProductList] });
        });

        fbq('track', 'ViewContent', {
          content_ids: [productId],
          content_category: midCategory,
          content_name: product.title,
          content_type: 'product',
          currency: 'KRW',
          value: product.goodsPrice,
        });


      });
    loadProductRelatedPosts(productId);
    loadSoldoutFollowingProduct(productId)
      .then(result => {
        this.setState({ soldoutAlarmData: result.data });
      });
    loadShopProductReviewList(productId, false);
    logPageviewEvent({
      os: Platform.OS,
      data: { godoGoodsNo: productId },
      userId: auth._id
    });
    if(document.querySelector("[hi_tag='MAIN_CONTAINER']")){
        document.querySelector("[hi_tag='MAIN_CONTAINER']").scrollTo(0,0);
    }

  }

  renderHeader() {
    return (
      <Animated.View
        style={[styles.header, { opacity: this.state.headerFadeAnimation }]}
        />
    )
  }

  renderCheckoutButton() {
    const {
      product={
        soldOut: 'n',
        soldOutFl: 'n',
        launchYmd: '0000-00-00',
      },
      productId,
      addSoldoutFollowingProduct,
      loadSoldoutFollowingProduct,
      deleteSoldoutFollowingProduct,
      logEventClick,
    } = this.props;
    const { soldOut, soldOutFl, stockCnt: stockCntStr } = product;
    const { soldoutAlarmData } = this.state;

    let checkoutButtonText = '구매하기'
    let onPressCheckout = () => showCheckout(this);

    // 품절시 재입고 알림 설정
    const stockCnt = parseInt(stockCntStr, 10);
    let isSoldOut = (soldOut === 'y' || soldOutFl === 'y' || stockCnt === 0);
    if (product.option && product.option.length > 0) {
      let isOptionSoldOut = true;
      product.option.forEach((option) => {
        const optionStockCnt = parseInt(option.stockCnt, 10);
        const isCurrentOptionSoldOut = (option.optionSellFl !== 'y' || (product.stockCnt !== '∞' && optionStockCnt === 0 ));
        isOptionSoldOut = isOptionSoldOut && isCurrentOptionSoldOut;
      });
      isSoldOut = isSoldOut || isOptionSoldOut;
    }

    if (isSoldOut) {
      checkoutButtonText = '재입고 알림 설정'
      if (product.option && product.option.length === 0) {
        onPressCheckout = () => {
          if (!this.checkLogin()) {
            return;
          }
          logEventClick({
            page_name: 'Product',
            page_key: productId,
            click_object_name: 'restock_alarm_on',
            click_object_key: productId
          });
          return addSoldoutFollowingProduct(productId, {optionKey: "no_option", optionVal: "no_option"})
            .then(() => {
              return loadSoldoutFollowingProduct(productId)
            })
            .then((result) => {
              this.setState({ soldoutAlarmData: result.data });
            });
        }
      }
    }

    // 품절시 재입고 알림 설정이 된경우
    const isSoldoutAlarmActivated = (soldoutAlarmData.length > 0);
    if (isSoldOut && isSoldoutAlarmActivated) {
      checkoutButtonText = '재입고 알림 설정됨';
      if (product.option && product.option.length === 0) {
        onPressCheckout = () => {
          logEventClick({
            page_name: 'Product',
            page_key: productId,
            click_object_name: 'restock_alarm_off',
            click_object_key: productId
          });
          return deleteSoldoutFollowingProduct(productId, "no_option")
            .then(() => {
              return loadSoldoutFollowingProduct(productId)
            })
            .then((result) => {
              this.setState({ soldoutAlarmData: result.data });
            });
        }
      }
    }

    const isPreOrder = !!product.launchYmd && product.launchYmd !== '0000-00-00';
    if (!isSoldOut && isPreOrder) {
      let checkoutButtonText = '프리오더 종료';
      let onPressCheckout = () => null;
      let textStyle = styles.footerLeftButtonText;

      const salesStartDate = convertKstToUtc(parseDate(product.salesStartYmd, true));
      const salesEndDate = convertKstToUtc(parseDate(product.salesEndYmd, true));
      const remainStartTimes = salesStartDate - new Date();
      const remainEndTimes = convertKstToUtc(parseDate(product.salesEndYmd, true)) - new Date();

      if (remainStartTimes > 0) {
        checkoutButtonText = '프리오더 예정'
      } else if (remainEndTimes > 0) {
        checkoutButtonText = '구매하기';
        onPressCheckout = () => showCheckout(this);
        textStyle = styles.footerRightButtonText;
      }

      return (
        <TouchableHighlightWithContainer
          onPress={onPressCheckout}
          >
          <View style={styles.footerButtonContainer}>
            <Text style={textStyle}>{checkoutButtonText}</Text>
          </View>
        </TouchableHighlightWithContainer>
      );
    }

    return (
      <TouchableHighlightWithContainer
        onPress={onPressCheckout.bind(this)}
        >
        <View style={styles.footerButtonContainer}>
          <Text style={styles.footerRightButtonText}>{checkoutButtonText}</Text>
          { isSoldOut && (isSoldoutAlarmActivated ? (
            <SoldoutAlarmWhiteIcon
              style={{ marginStart: 3 }}
              />
          ) : (
            <SoldoutAlarmIcon
              color='#fff'
              style={{ marginStart: 3 }}
              />
          ))}
        </View>
      </TouchableHighlightWithContainer>
    );
  }

  renderFooter() {
    const { product } = this.props;

    let wishlistButtonText = '위시리스트 담기';
    if (product && product.liked) {
      wishlistButtonText += ' 완료';
    }

    const onPressAddToWishlist = () => {
      product.goodsNm = product.title;
      product.goodsImageSrc = product.imagesDetail[0];
      addToWishlist(this, product);
    }

    return (
      <div className={_styles.hi_product_footer}>
        <View style={styles.footerContainer}>
          <View style={{ flex: 1 / 2 }}>
            <TouchableHighlightWithContainer
              onPress={onPressAddToWishlist.bind(this)}
              >
              <View style={styles.footerButtonContainer}>
                <Text style={styles.footerLeftButtonText}>{wishlistButtonText}</Text>
              </View>
            </TouchableHighlightWithContainer>
          </View>
          <View style={{ flex: 1 / 2 }}>
            {this.renderCheckoutButton()}
          </View>
        </View>
      </div>
    );
  }

  renderMainContent() {
    const {
      router,
      product = {
        title: ' ',
        brandNm: ' ',
        reviewStar: 0,
        reviewCnt: 0,
        goodsPrice: 0,
        discPricePct: 0,
        color: '',
        imagesDetail: [],
        description: '',
        launchYmd: '0000-00-00',
        categoryList:[]
      },
      productRelatedPosts: heartists,
      partnerShippingProducts,
      setModalProps,
      modalProps,
      productId,
    } = this.props;

    const {
      collapsed,
      isMarketing
    } = this.state;

    const {
      imagesDetail, launchYmd,
    } = product;

    const isPreOrder = !!launchYmd && launchYmd !== '0000-00-00';

    return (
      <View style={styles.contentContainer}>
        {
          isMarketing &&
          <View style={styles.deliveryContainer}>
            <View style={styles.deliveryTextContainer}>
                <Text style={styles.deliveryText}>
                    <Text style={styles.deliveryBoldText}>30/50/100만원 이상</Text> 구매시 추가 <Text style={styles.deliveryBoldTextRed}>1.5만/3만/7만원</Text> 할인
                </Text>
            </View>
          </View>
        }
        <ProductImages imagesDetail={imagesDetail} product={product} />

        <ProductInfo
          router={router}
          product={product}
          containerStyle={styles.contentStyle}
          />

        <View style={styles.itemSeparator} />

        <ShippingInfo
          product={product}
          containerStyle={styles.contentStyle}
          partnerShippingProducts={partnerShippingProducts}
          />

        { !isPreOrder && (
          <RegistrationCoupon
            router={router}
            containerStyle={[styles.contentStyle, { marginTop: 15 }]}
            productId={productId}
          />
        )}

        { isPreOrder && (
          <React.Fragment>
            <View style={styles.itemSeparator} />

            <PreOrderInfo
              product={product}
              containerStyle={styles.contentStyle}
              />
          </React.Fragment>
        )}

        { heartists && heartists.length > 0 && (
          <Heartists
            router={router}
            heartists={heartists}
            />
        )}

        <EditorNote
          product={product}
          containerStyle={styles.contentStyle}
          />

        <ProductDetail
          product={product}
          containerStyle={styles.contentStyle}
          collapsed={collapsed} />

        <ShippingAndRefund
          product={product}
          containerStyle={styles.contentStyle}
          collapsed={collapsed} />

        { !isPreOrder && (
          <ProductReviews
            containerStyle={styles.contentStyle}
            product={product}
            productId={productId}
            />
        )}

        { false && (<PriceComparison />)}

        <SimilarProducts
          title={'이 상품과 비슷한 아이템'}
          items={this.state.similarProductList}
          setModalProps={setModalProps}
          modalProps={modalProps}
          router={router}
          />

        <SimilarProducts
          title={'이 상품과 같은 브랜드 상품'}
          items={this.state.sameBrandProductList}
          setModalProps={setModalProps}
          modalProps={modalProps}
          router={router}
          />
      </View>
    );
  }

  render() {
    return (
      <div style={styleHtml.container}>
        <ScrollView
          ref={ref => this.containerRef = ref}
          style={styles.scrollView}
          >
          {this.renderMainContent()}
          <View style={styles.scrollViewBottom} />
        </ScrollView>
        {this.renderFooter()}
        {this.renderHeader()}
        {renderAlert(this)}
      </div>
    )
  }
}


export default connect(
  (state, { params: { productId }, router }) => {
    const productRelatedPosts = state.entities.shopProductsRelatedPosts[productId];

    let moveFrom = null;
    if (router.location.hasOwnProperty('state')
        && router.location.state
        && router.location.state.hasOwnProperty('moveFrom')) {
      moveFrom = router.location.state.moveFrom;
    }

    return {
      auth: state.auth,
      product: state.entities.shopProducts[productId],
      productRelatedPosts: productRelatedPosts ? Object.values(productRelatedPosts) : [],
      ...loadEntities(state, 'partnerShippingProducts', 'partnerShippingProducts'),
      productId,
      moveFrom,
    };
  },
  actions,
)(ProductPage);
