import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import { actions, selectPostDate } from 'shared';
import Post from '../nativeComponents/Post';
import Spinner from '../components/Spinner';

import Constants from '../constants/Constants';
import { openOutlinkPage } from './OutlinkGatewayPage';
import { generateFirebaseEvent } from '../utils';


export class PostPage extends React.Component {
  componentDidMount() {
    const { post, loadPost, router } = this.props;
    if (!post) {
      loadPost(router.params.postId);
    }
  }

  onPressProduct = product => {
    const { router, post, historySwitch, auth } = this.props;
    const { token } = auth;

    // eslint-disable-next-line no-prototype-builtins
    if (!post && product.hasOwnProperty('post')) {
      // eslint-disable-next-line prefer-destructuring
      post = product.post;
    }

    // TODO: Post 정보가 없는 경우 이벤트를 보내지 않아도 괜찮은지 확인 필요
    if (post && post.owner) {
      generateFirebaseEvent({ product, post, where: 'post' });
    }

    if (product.godoGoodsNo) {
      router.push({
        pathname: `/product/${product.godoGoodsNo}`,
        state: { postId: post._id },
      });
      return;
    }

    openOutlinkPage(product, post, historySwitch, token, router);
  }

  render() {
    const { router, post, historySwitch, auth } = this.props;

    return (
      <div style={stylesHtml.container}>
        {post ? (
          <Post
            onPressHeartist={(userId, username) => router.push(`/heartists/${username}`)}
            onPressProduct={this.onPressProduct}
            post={post}
            postDate={selectPostDate('postScreen')}
            historySwitch={historySwitch}
            auth={auth}
            enableProductLabelSimilar
            enableProductLabelHeartitLogo
            enableProductDeemedSeasonOut
          />
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

const stylesHtml = {
  container: {
    paddingLeft: Constants.viewHorizontalPadding,
    paddingRight: Constants.viewHorizontalPadding,
    paddingBottom: 40,
    paddingTop: Constants.touchEffectWidth,
  },
}

export default connect(
  (state, { router }) => {
    const postId = router.params.postId;
    return {
      auth: state.auth,
      historySwitch: state.settings.historySwitch,
      post: state.entities.posts[postId],
    };
  },
  actions,
)(PostPage);
