import React from 'react';
import {
  StyleSheet, View, TouchableHighlight, Text,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';

import {
  AppLogoBannerIcon, Ic14XIcon,
} from '../icons-v2';
import {
  Colors, Styles, Constants,
} from '../constants';


const webStyles = {
  container: {
    zoom: '1',
    WebkitBoxSizing: 'borderBox',
    MozBoxSizing: 'borderBox',
    MsBoxSizing: 'borderBox',
    OBoxSizing: 'borderBox',
    boxSizing: 'borderBox',
    background: '#F6F7F8',
    position: 'relative',
  },
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingEnd: 19,
  },
  logoButton: {
    width: 50,
    height: 50,
    marginStart: 12,
    marginVertical: 12,
    borderRadius: 12,
  },
  bodyContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'center',
  },
  contentContainerButton: {
    marginTop: 20 - Constants.touchEffectWidth,
    marginStart: 10 - Constants.touchEffectWidth,
    marginBottom: 19 - Constants.touchEffectWidth,
    ...Styles.touchEffect,
  },
  contentContainer: {
  },
  titleText: {
    ...Styles.pretendard700,
    size: 14,
    lineHeight: 16.8,
  },
  descText: {
    marginTop: 4,
    ...Styles.appleSDGothicNeoMedium,
    fontSize: 12,
    lineHeight: 14.4,
  },
  closeIconButton: {
    height: 36,
    width: 36,
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'background: rgba(235, 0, 255, 0.3)',
    ...Styles.touchEffect,
  },
});

export default class SmartBanner extends React.Component {
  onPressDownload = () => {
    const { platform } = this.props;
    switch (platform) {
    case 'ios':
      window.location.href = 'https://itunes.apple.com/app/heartIt/id1207115066?ls=1&mt=8';
      break;

    case 'android':
      window.location.href = 'market://details?id=kr.heartit.heartit';
      break;

    default:
      break;
    }
  };

  render() {
    const { onClickClose } = this.props;

    return (
      <div style={webStyles.container}>
        <View style={styles.container}>
          <TouchableHighlight
            underlayColor={Colors.touchEffectColorBlack}
            style={styles.logoButton}
            onPress={this.onPressDownload}
          >
            <AppLogoBannerIcon />
          </TouchableHighlight>

          <View style={styles.bodyContainer}>
            <TouchableHighlight
              underlayColor={Colors.touchEffectColorBlack}
              style={styles.contentContainerButton}
              onPress={this.onPressDownload}
            >
              <View style={styles.contentContainer}>
                <Text style={styles.titleText}>하트잇 2.0 앱 다운로드</Text>
                <Text style={styles.descText}>그녀들의 잇명품 쇼핑 데스티네이션 #하트잇</Text>
              </View>
            </TouchableHighlight>

            <TouchableHighlight
              underlayColor={Colors.touchEffectColorBlack}
              style={styles.closeIconButton}
              onPress={onClickClose}
            >
              <Ic14XIcon />
            </TouchableHighlight>
          </View>
        </View>
      </div>
    );
  }
}
