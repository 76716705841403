import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import set from 'lodash/set';
import transform from 'lodash/transform';

import BigNumber from 'bignumber.js';
import config from "../config.js";

export * from './commonFunctions';
export * from './urlFn';
export * from './formatPrice';
export * from './getNextPageUrl';
export * from './dateTools';
export * from './passwordValidator';
export * from './heartistInfo';
export * from './preloadInitialData';
export * from './getThumbnailBackgroundColor';


export function setState(component, name, value) {
  const state = set(cloneDeep(component.state), name, value);
  component.setState(state);
}

export function linkState(component, name) {
  function handleChange(value) {
    setState(component, name, value);
  }
  return {
    value: get(component.state, name),
    requestChange: handleChange,
  };
}

export function loadEntities(state, paginationKey, entitiesKey, paginationName, entitiesName) {
  const pagination = get(state.pagination, paginationKey, {});
  const ids = pagination.ids || [];
  const entities = ids.map(id => get(state.entities, `${entitiesKey}[${id}]`));
  const paginationOutputName = paginationName || 'pagination';
  const entitiesOutputName = entitiesName || entitiesKey;
  const { nextPageUrl, isFetching, countResult } = pagination;
  
  return {
    [paginationOutputName]: { nextPageUrl, isFetching, countResult },
    [entitiesOutputName]: entities.filter(entity => !!entity),
  };
}


export function objectDiff(object, base) {
  /**
   * Deep diff between two object, using lodash
   * @param  {Object} object Object compared
   * @param  {Object} base   Object to compare with
   * @return {Object}        Return a new object who represent the diff
   */
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] = isObject(value) && isObject(base[key]) ? objectDiff(value, base[key]) : value;
    }
  });
}

export function generateNormalizer(min = -Infinity, max = Infinity, decimals = 0) {
  return number => {
    const removeNegativeZero = numberString => (numberString === '-0' ? '0' : numberString);
    if (typeof number !== 'number') {
      return NaN;
    }
    if (number < min) {
      return removeNegativeZero(min.toFixed(decimals));
    }
    if (number > max) {
      return removeNegativeZero(max.toFixed(decimals));
    }
    return removeNegativeZero(number.toFixed(decimals));
  };
}

export function textToClipboard(text) {
  navigator.clipboard.writeText(text)
    .catch(err => {
      // eslint-disable-next-line no-console
      console.error('clipboard에 접근하지 못함', err);
    });
}

export function bigDecimalToBase64(integer) {
  let quotient;
  const divisor = 64;
  let dividend = new BigNumber(integer);
  let remainder;
  const base64numbers = [];
  let base64string = '';
  const mapper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';

  while (dividend.div(divisor) >= 1) {
    quotient = dividend.dividedToIntegerBy(divisor);
    remainder = dividend.mod(divisor);
    dividend = quotient;
    base64numbers.push(remainder);
  }
  base64numbers.push(dividend);

  base64numbers.reverse().forEach(base64number => {
    base64string += mapper[base64number];
  });
  return base64string;
}


export const getTrackingLink = (link, post, historySwitch, token) => {
  const encodedURL = encodeURIComponent(link);
  const heartist = (post && post.owner) ? post.owner.username : 'unknown';
  const encodedHeartist = encodeURIComponent(heartist);
  // const bearerTokenUrl = global.bearerToken ? `&tk=${global.bearerToken}` : '';
  const bearerTokenUrl = token ? `&tk=${token}` : '';
  // eslint-disable-next-line max-len
  const apiRoot = config.get('apiRoot') || '';

  const history = historySwitch ? 'yes' : 'no';
  // const history = __DEV__ ? 'yes' : 'no';
  // const history = 'yes';
  const postId = post ? post._id : '';
  return `${apiRoot}/link/v2?url=${encodedURL}&post=${postId}&heartist=${encodedHeartist}&agent=app&v=${global.appVersion}${bearerTokenUrl}&os=${global.platformOS}&history=${history}`;
};


export function isEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}


export function generateRandomString(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
