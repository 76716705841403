import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';
import { getDateTimeString } from 'shared/util';

import { Styles } from '../../constants';


const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
  },
  noticeContainer: {
  },
  noticeHeader: {
    flex: 1,
    paddingTop: 20,
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#ecedee',
  },
  noticeSubject: {
    flexGrow: 1,
    fontSize: 20,
    lineHeight: 24,
    ...Styles.pretendard600,
  },
  noticeDate: {
    marginTop: 10,
    fontSize: 14,
    lineHeight: 16.8,
    color: 'rgba(0, 0, 0, 0.5)',
    ...Styles.pretendard400,
  },
  noticeBody: {
    marginTop: 20,
    marginBottom: 112,
  },
  noticeContent: {
    fontSize: 12,
    lineHeight: 18,
    ...Styles.pretendard400,
  },
});


export class NoticeViewPage extends React.Component {
  state = {
    notice: { _id: null },
  };

  componentDidMount() {
    const { logEventPageview, noticeId } = this.props;

    this.loadData();

    logEventPageview({ page_name: 'NoticeView', page_key: noticeId });
  }

  loadData = async () => {
    const { loadNotice, noticeId } = this.props;
    const notice = await loadNotice(noticeId);
    this.setState({ notice });
  }

  render() {
    const { notice } = this.state;

    return (
      <View style={styles.container}>
        <View style={styles.noticeContainer}>
          <View style={styles.noticeHeader}>
            <Text style={styles.noticeSubject}>{ notice.subject }</Text>
            <Text style={styles.noticeDate}>{ getDateTimeString(new Date(notice.createdAt), true) }</Text>
          </View>
          <View style={styles.noticeBody}>
            <Text style={styles.noticeContent}>{ notice.content }</Text>
          </View>
        </View>
      </View>
    );
  }
}

export default connect(
  (state, { params }) => {
    const { noticeId } = params;
    return {
      auth: state.auth,
      noticeId,
    };
  },
  actions,
)(NoticeViewPage);
