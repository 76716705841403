import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from 'components/Spinner';
import { loadEntities } from 'react-shared';
import * as adminActions from 'react-shared/adminActions';
import { Link } from 'react-router';
import PreorderExhibition from 'components/admin/PreorderExhibition';

const styles = {
  container: {
    margin: '0 auto',
    maxWidth: '450px',
  },
};

export class ListPreorderExhibitionPage extends Component {
  componentDidMount() {
    const { adminPreorderExhibitions, adminLoadPreorderExhibitions } = this.props;
    if (!adminPreorderExhibitions || adminPreorderExhibitions.length === 0) {
      adminLoadPreorderExhibitions();
    }
  }

  render() {
    const {
      adminPreorderExhibitions,
      pagination: { isFetching },
    } = this.props;
    const adminPreorderExhibitionsList = adminPreorderExhibitions.sort(
      (a, b) => b.created - a.created,
    );
    return (
      <div style={styles.container}>
        <br />
        <Link className="btn btn-primary" to="/admin/preorderExhibitions/new">
          프리오더 기획전 추가
        </Link>
        {adminPreorderExhibitionsList.length > 0
          && adminPreorderExhibitionsList.map(preorderExhibition => (
            <PreorderExhibition
              preorderExhibition={preorderExhibition}
              key={`preorderExhibition_${preorderExhibition.id}`}
            />
          ))}
        {isFetching && <Spinner />}
      </div>
    );
  }
}

ListPreorderExhibitionPage.propTypes = {
  adminLoadPreorderExhibitions: PropTypes.func.isRequired,
  adminPreorderExhibitions: PropTypes.array,
  // pagination: PropTypes.object,
};

ListPreorderExhibitionPage.defaultProps = {
  adminPreorderExhibitions: {},
};

export const mapStateToProps = state => ({
  ...loadEntities(state, 'adminPreorderExhibitions', 'adminPreorderExhibitions'),
});

export default connect(
  mapStateToProps,
  adminActions,
)(ListPreorderExhibitionPage);
