import createFetchAction from '../util/createFetchAction';


export function adminGetCrawlerList() {
  return createFetchAction({
    type: 'ADMIN_GET_CRAWLER_LIST',
    endpoint: '/api/v2/admin/crawler/',
    doDispatch: false,
  });
}
