import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as adminActions from 'react-shared/adminActions';
import { loadEntities } from 'react-shared/util';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import '../../css/datepicker.css';
import { PostPerformanceList, UserStatistics } from 'components/admin';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: dayjs().subtract(7, 'day').toDate(),
      endDate: dayjs().subtract(1, 'day').toDate(),
      allPeriod: false,
      timeUnit: 'dau',
    };
  }

  static getDays(startDate, endDate) {
    return parseInt((endDate - startDate + 20000) / 86400000, 10) + 1;
  }

  handleDateChange(date, which) {
    this.setState({
      [which]: date,
    });
  }

  handleAllPeriodChange() {
    this.setState(prevState => ({ allPeriod: !prevState.allPeriod }));
  }

  render() {
    const { startDate, endDate, allPeriod, timeUnit } = this.state;
    const { loadFeatured, posts, pagination, clickCount, getPostClickCount, userStatistics, getUserStatistics } = this.props; // eslint-disable-line max-len
    return (
      <div>
        <div style={{ textAlign: 'right', margin: '10px' }}>
          <DatePicker
            dateFormat="yyyy-MM-dd"
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={date => this.handleDateChange(date, 'startDate')}
            disabled={allPeriod}
            selectsStart
            maxDate={dayjs().subtract(1, 'day').toDate()}
          />
          ~
          <DatePicker
            dateFormat="yyyy-MM-dd"
            selected={endDate}
            startDate={startDate}
            endDate={endDate}
            onChange={date => this.handleDateChange(date, 'endDate')}
            disabled={allPeriod}
            selectsEnd
            minDate={startDate}
            maxDate={dayjs().subtract(1, 'day').toDate()}
          />
          &nbsp;
          <input type="checkbox" checked={allPeriod} onChange={() => this.handleAllPeriodChange()} />
          전체 기간 검색
        </div>
        <div>
          <UserStatistics
            days={allPeriod
              ? Dashboard.getDays(dayjs('2019-07-05').toDate(), dayjs().subtract(1, 'day').toDate())
              : Dashboard.getDays(startDate, endDate)}
            endDate={allPeriod
              ? dayjs().subtract(1, 'day').format('YYYY-MM-DD')
              : dayjs(endDate).format('YYYY-MM-DD')}
            loadData={getUserStatistics}
            userStatistics={userStatistics}
            unit={timeUnit}
          />
        </div>
        <div>
          <div style={{ fontSize: '20px', marginLeft: '20px', fontWeight: 'bold' }}>
            # Post/Item Click
          </div>
          <PostPerformanceList
            loadData={loadFeatured}
            forceReload
            posts={posts}
            needProfileHead
            pagination={pagination}
            getPostClickCount={getPostClickCount}
            startDate={allPeriod ? '2019-07-05' : dayjs(startDate).format('YYYY-MM-DD')}
            endDate={allPeriod ? dayjs().subtract(1, 'day').format('YYYY-MM-DD') : dayjs(endDate).format('YYYY-MM-DD')}
            clickCount={clickCount}
          />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  // adminGetPostCount: PropTypes.func.isRequired,
  // adminGetUserCount: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    ...loadEntities(state, 'featured', 'posts'),
    viewMode: state.viewModes.home,
    clickCount: state.admin.postClickCount,
    initialViewMode: state.viewModes.initial.home,
    userStatistics: state.admin.userStatistics,
  }),
  adminActions,
)(Dashboard);
