import React from 'react'

import { 
  StyleSheet, View, Text, FlatList,
} from 'react-native';

import { Colors, Styles } from '../../../../constants';
import { TouchableHighlightWithContainer } from '../../..';


const styles = StyleSheet.create({
  container: {
  },
  buttonContainer: {
    flex: 1,
  },
  itemContainer: {
    borderWidth: 0.5,
    paddingVertical: 11 - 0.5,
    alignItems: 'center',
    opacity: 0.3,
  },
  itemContainerSelected: {
    opacity: 1
  },
  dummyItem: {
    flex: 1,
  },
  itemText: {
    ...Styles.pretendard400,
    fontSize: 15,
    lineHeight: 18,
    height: 18,
  },
  itemTextSelected: {
    ...Styles.pretendard600,
  },
});


class SizeSelector extends React.Component {

  onPressItem(item) {
    const { selectedSizeList, onChange } = this.props;

    let newSelectedSizeList = selectedSizeList;
    if (!this.isSelected(item)) {
      newSelectedSizeList.push(item);
    } else {
      newSelectedSizeList = newSelectedSizeList.filter((e) => e.id !== item.id);
    }

    onChange(newSelectedSizeList);
  }

  isSelected(item) {
    const { selectedSizeList } = this.props;

    return selectedSizeList.some((e) => e.id === item.id);
  }

  renderItem(item, index) {

    if (!item.name) {
      return (
        <React.Fragment>
          { index % 4 > 0 && (<View style={{ width: 5 }} />)}
          <View style={styles.dummyItem} />          
        </React.Fragment>
      );
    }

    const { selectedSizeStandard } = this.props;
    let name = item.name;

    if (selectedSizeStandard.name !== 'International') {
      const aliasName = item.alias[selectedSizeStandard.name];
      name = `${selectedSizeStandard.name} ${aliasName}`;
    }

    const itemContainerStyle = [styles.itemContainer];
    const itemTextStyle = [styles.itemText];

    const isSelected = this.isSelected(item);
    if (isSelected) {
      itemContainerStyle.push(styles.itemContainerSelected);
      itemTextStyle.push(styles.itemTextSelected);
    }

    return (
      <React.Fragment>
        { index % 4 > 0 && (<View style={{ width: 5 }} />)}
        <View style={styles.buttonContainer}>
          <TouchableHighlightWithContainer
            onPress={() => this.onPressItem(item)}
            >
            <View style={itemContainerStyle}>
              <Text style={itemTextStyle}>{name}</Text>
            </View>
          </TouchableHighlightWithContainer>
        </View>
      </React.Fragment>
    );
  }

  render() {
    const {
      containerStyle,
      sizeItems,
    } = this.props;

    const data = sizeItems;
    const remains = data.length % 4;

    // console.log(`render data.length=${data.length}`);
    // console.log(`render remains=${remains}`);

    for (let i = 0; i < 4 - remains; i++) {
      data.push({});
    }

    return (
      <View style={[styles.container, containerStyle]}>
        <FlatList
          keyExtractor={(item, index) => `${index}`} // eslint-disable-line no-underscore-dangle
          data={sizeItems}
          renderItem={({ item, index }) => this.renderItem(item, index)}
          ItemSeparatorComponent={() => <View style={{ height: 5 }} />}
          ItemCol
          columnWrapperStyle={{ justifyContent: 'space-between' }}
          numColumns={4}
          />
      </View>
    );
  }
}


export default SizeSelector;
