import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from 'components/Spinner';
import { loadEntities } from 'react-shared';
import * as adminActions from 'react-shared/adminActions';
import { Link } from 'react-router';
import CategorySale from 'components/admin/CategorySale';

const styles = {
  container: {
    margin: '0 auto',
    maxWidth: '450px',
  },
};

export class ListCategorySalePage extends Component {
  componentDidMount() {
    const { adminCategorySales, adminLoadCategorySales } = this.props;
    if (!adminCategorySales || adminCategorySales.length === 0) {
      adminLoadCategorySales();
    }
  }

  render() {
    const {
      adminCategorySales,
      pagination: { isFetching },
    } = this.props;
    const categorySalesList = adminCategorySales.sort(
      (a, b) => a.weight - b.weight,
    );

    return (
      <div style={styles.container}>
        <br />
        <Link className="btn btn-primary" to="/admin/categorySales/new">
          카테고리 세일 추가
        </Link>
        {categorySalesList.length > 0
          && categorySalesList.map(categorySale => (
            <CategorySale
              categorySale={categorySale}
              key={`categorySale_${categorySale.id}`}
            />
          ))}
        {isFetching && <Spinner />}
      </div>
    );
  }
}

ListCategorySalePage.propTypes = {
  adminLoadCategorySales: PropTypes.func.isRequired,
  adminCategorySales: PropTypes.array,
};

ListCategorySalePage.defaultProps = {
  adminCategorySales: {},
};

export const mapStateToProps = state => ({
  ...loadEntities(state, 'adminCategorySales', 'adminCategorySales'),
});

export default connect(
  mapStateToProps,
  adminActions,
)(ListCategorySalePage);
