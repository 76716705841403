import React, { Component } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import SupervisorAccountIconOutlined from '@material-ui/icons/SupervisorAccountOutlined';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'react-shared';
import { Constants } from '../../constants';

import Toast from '../../nativeComponents/Toast';

import Titles from '../../routes/Titles';

import HeaderLeftHome from './HeaderLeftHome';
import HeaderLeftStyle from './HeaderLeftStyle';
import HeaderLeftFavorite from './HeaderLeftFavorite';
import HeaderLeftMy from './HeaderLeftMy';
import HeaderLeftText from './HeaderLeftText';
import HeaderRight from './HeaderRight';
import HeaderSearchBar from './HeaderSearchBar';


const styles = StyleSheet.create({
  border: {
    overflow: 'hidden',
  },
  container: {
    width: '100%',
    height: Constants.mainHeaderHeight,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 960,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  containerBackground: {
    flex: 1,
    position: 'absolute',
    top: 0,
    bottom: 0,
    start: 0,
    end: 0,
    backgroundColor: '#ffffff',
  },
  leftContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  titleContainer: {
    position: 'absolute',
    zIndex: -1,
    width: '100%',
    height: '100%',
    paddingTop: 8,
    paddingBottom: 3,
    justifyContent: 'center',
    alignItems: 'center',
  },
  toastContainer: {
    position: 'absolute',
    zIndex: 1000,
    width: '100%',
    height: 52,
    marginTop: 10,
  },
  rightContainer: {
    position: 'absolute',
    top: 0,
    end: 0,
  },
});

function setHomeFeedPadding(showToast) {
  const elem = document.getElementById('header_container');
  if (!elem) {
    return;
  }
  elem.style.marginBottom = showToast ? '-6px' : '0px';
}

class Navigation extends Component {
  state = {
    showToast: false,
    headerBackgroundVisible: false,
  };

  handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
    const stRaw = window.pageYOffset || document.documentElement.scrollTop;
    const st = Math.min(
      stRaw,
      scrollHeight,
    );

    // console.log(`st=${st}`);

    if (st > 250) {
      this.headerFadeIn();
    } else {
      this.headerFadeOut();
    }
  };

  headerFadeIn = () => {
    this.setState({ headerBackgroundVisible: true });
  };

  headerFadeOut = () => {
    this.setState({ headerBackgroundVisible: false });
  };

  componentDidMount() {
    const { toastMsg } = this.props;
    if (toastMsg !== null) {
      this.setState({ showToast: true });
      setHomeFeedPadding(true);
    }

    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    const { toastMsg } = this.props;
    const { toastMsg: prevToastMsg } = prevProps;

    if (toastMsg !== prevToastMsg && toastMsg !== null) {
      this.setState({ showToast: true });
      setHomeFeedPadding(true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  reload() {
    const { router, loadFeatured } = this.props;
    if (window.location.pathname === '/') {
      // loadFeatured();
      const { setGlobalLayoutInfo } = this.props;
      setGlobalLayoutInfo('homeScrollTop', true);
    } else {
      router.push('/');
    }
  }

  renderHeader = () => {
    const { router } = this.props;
    const path = router.location.pathname;

    const matches = Titles.filter((reObj) => {
      const { pathRegex } = reObj;
      return path.match(pathRegex) !== null;
    });

    if (matches.length <= 0) {
      return this.renderDefaultHeader();
    }

    const { type, title, borderWidth, ...props } = matches[0];
    switch (type) {
    case 'search':
      return this.renderSearchHeader(borderWidth <= 0, props);
    case 'home':
    case 'text':
    default:
      return this.renderDefaultHeader(title, type, props);
    }
  }

  renderSearchHeader = (noborder, props) => {
    const { router } = this.props;

    const callbacks = (router.location.state ? router.location.state.callbacks : {}) || {};
    const searchKeyword = (router.location.state ? router.location.state.searchKeyword : '') || '';

    return (
      <View style={styles.border}>
        <View style={styles.container}>
          <HeaderSearchBar
            {...props}
            router={router}
            searchKeyword={searchKeyword}
            callbacks={callbacks}
            noborder={noborder}
          />
        </View>
      </View>
    );
  }

  renderToastHeader = () => {
    const { toastMsg } = this.props;
    const { showToast } = this.state;

    return (
      <View style={styles.border}>
        <View style={[styles.container, { height: Constants.mainHeaderHeight + 6 }]}>
          <View style={styles.toastContainer}>
            <Toast
              show={showToast}
              duration={2000}
              msg={toastMsg}
              hideToastCallback={() => {
                setHomeFeedPadding(false);
                this.setState({ showToast: false });
              }}
            />
          </View>
        </View>
      </View>
    );
  }

  renderLeftHeader(title, type, darkContent, props) {
    const {
      router,
      headerOptions: {
        HeaderLeftComponent,
        headerLeftProps,
      },
    } = this.props;

    if (HeaderLeftComponent) {
      return HeaderLeftComponent(headerLeftProps);
    }

    const commonProps = {
      containerStyle: styles.leftContainer,
      router: router,
    };

    if (type === 'home') {
      const {
        selectedTab,
      } = props;

      return (
        <HeaderLeftHome
          {...commonProps}
          selectedTab={selectedTab}
          darkContent={darkContent}
          />
      );
    }

    if (type === 'style') {
      const {
        selectedTab,
      } = props;

      return (
        <HeaderLeftStyle
          {...commonProps}
          selectedTab={selectedTab}
          darkContent={darkContent}
          />
      );
    }

    if (type === 'favorite') {
      const {
        selectedTab,
      } = props;

      return (
        <HeaderLeftFavorite
          {...commonProps}
          selectedTab={selectedTab}
          />
      );
    }

    if (type === 'my') {
      const {
        selectedTab,
      } = props;

      return (
        <HeaderLeftMy
          {...commonProps}
          selectedTab={selectedTab}
          />
      );
    }

    if (type === 'text') {
      const {
        defaultRouterBackPath,
        showBackButton=true,
      } = props;
      return (
        <HeaderLeftText
          {...commonProps}
          title={title}
           showBackButton={showBackButton}
          defaultRouterBackPath={defaultRouterBackPath}
          />
      );
    }
  }

  renderDefaultHeader(title='', type='', props={}) {
    const {
      auth: { roles, username }, router, history, itemListInCart, pushHistoryCount,
    } = this.props;

    const {
      showCameraButton=false,
      showSearchButton=false,
      showBellButton=false,
      showCartButton=false,
      showShareButton=false,
      showBackButton=true,
      transparentBackground=false,
      darkContent: darkContentFromProps,
    } = props;

    const containerBackgroundStyles = [
      styles.containerBackground,
    ];
    let darkContent = true;

    if (transparentBackground === true)  {
      const {
        headerBackgroundVisible,
      } = this.state;

      darkContent = darkContentFromProps || headerBackgroundVisible;

      containerBackgroundStyles.push({
        opacity: headerBackgroundVisible ? 1 : 0,
      });
    }

    const { showToast } = this.state;

    if (showToast) {
      return this.renderToastHeader();
    }


    return (
      <View style={styles.border}>
        <View style={containerBackgroundStyles}>
        </View>
        <View style={styles.container}>
          {this.renderLeftHeader(title, type, darkContent, props)}

          <HeaderRight
            containerStyle={styles.rightContainer}
            router={router}
            showCameraButton={showCameraButton}
            showSearchButton={showSearchButton}
            showBellButton={showBellButton}
            showShareButton={showShareButton}
            showCartButton={showCartButton}
            darkContent={darkContent}
            itemListInCart={itemListInCart}
            pushHistoryCount={pushHistoryCount}
            />
        </View>
      </View>
    );
  }

  render() {
    return this.renderHeader();
  }
}

Navigation.propTypes = {
  auth: PropTypes.object.isRequired,
  loadFeatured: PropTypes.func.isRequired,
  headerOptions: PropTypes.object,
};


Navigation.defaultProps = {
  headerOptions: {},
};


export default connect(
  (state, { router }) => ({
    auth: state.auth,
    toastMsg: (
      router.location.hasOwnProperty('state')
      && router.location.state
      && router.location.state.hasOwnProperty('toast')) ? router.location.state.toast : null,
  }),
  actions
)(Navigation);
