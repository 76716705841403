import * as React from 'react';

function SortingIcon(props) {
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M9 6h11M4 6h3m2 6h11M4 12h3m2 6h11M4 18h3"
        stroke="#000"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SortingIcon;
