import React from 'react';
import {
  StyleSheet, View, Text,
} from 'react-native';
import { connect } from 'react-redux';

import { actions, loadEntities } from 'shared';

import {
  Styles,
} from '../../../constants/';

import {
  ShippingAddress
 } from '../../../components-v2';


 import { navigateToEditShippingAddress } from '../ShippingAddressEditPage';


const styles = StyleSheet.create({
  container: {
  },
  editShippingAddressContainer: {
    marginBottom: 20,
  },
  blankText: {
    fontSize: 11,
    height: 19,
    lineHeight: 19,
    color: '#000',
    textAlign: 'left',
    ...Styles.appleSDGothicNeoSemiBold,
  },
});


class EditShippingAddressList extends React.Component {
  componentDidMount() {
    const { loadShippingAddressList } = this.props;
    loadShippingAddressList();
  }

  componentWillUnmount() {
    this.setState = () => null;
  }

  onPressEditShippingAddress = item => () => {
    const { navigation } = this.props;

    navigateToEditShippingAddress(item.sno);
  }

  onPressDeleteShippingAddress = item => () => {
    const { deleteShipppingAddress } = this.props;
    deleteShipppingAddress(item.sno);
  }

  render() {
    const { shippingAddresses } = this.props;

    return (
      <React.Fragment>
        <View style={styles.editShippingAddressContainer}>
          {shippingAddresses.length === 0 ? (
            <Text style={styles.blankText}>저장된 배송지 정보가 없습니다.</Text>
          ) : shippingAddresses.map(item => (
            <View key={item.sno} style={{marginBottom: 40}}>
              <ShippingAddress
                key={item.sno}
                item={item}
                onPressEditButton={this.onPressEditShippingAddress(item)}
                onPressDeleteButton={this.onPressDeleteShippingAddress(item)}
                profileEditStyle
                onDataChanged={() => {}}
              />
            </View>
          ))}
        </View>
      </React.Fragment>
    );
  }
}


export default connect(
  state => ({
    ...loadEntities(state, 'shippingAddresses', 'shippingAddresses'),
  }),
  actions,
)(EditShippingAddressList);
