import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { getChangedUrl } from 'react-shared/util/urlFn';
import InstagramProfile from './InstagramProfile';
import PostDate from './PostDate';

const styles = {
  container: {
    border: '5px solid #fff',
    marginBottom: '30px',
    marginTop: '95px',
    padding: '0px 15px',
    textAlign: 'center',
  },
  headerContainer: {
    height: '40px',
    marginBottom: '12.5px',
    width: '100%',
  },
  headerLeft: {
    display: 'inline-block',
    float: 'left',
    height: '40px',
    marginBottom: '12.5px',
    textAlign: 'left',
    textTransform: 'lowercase',
    width: '50%',
  },
  headerImage: {
    borderRadius: '50%',
    height: '40px',
    width: '40px',
  },
  username: {
    color: 'black',
    fontFamily: 'Avenir Next',
    fontSize: '16px',
    marginLeft: '7.5px',
  },
  headerRight: {
    display: 'inline-block',
    height: '40px',
    lineHeight: '40px',
    marginBottom: '12.5px',
    textAlign: 'right',
    width: '50%',
  },
  date: {
    color: '#909090',
    fontSize: '13px',
  },
  dateSuffix: {
    color: '#909090',
    fontSize: '8px',
    lineHeight: '36px',
    verticalAlign: 'top',
  },
  mainImage: {
    marginBottom: '14px',
    width: '100%',
  },
  abstract: {
    color: 'black',
    fontSize: '10px',
    textAlign: 'left',
    textDecoration: 'none',
  },
  link: {
    color: 'black',
    textAlign: 'left',
  },
  arrow: {
    height: '7px',
    marginLeft: '5px',
    width: '15px',
  },
};

export default class Editorial extends Component {
  render() {
    const { editorial: { abstract, createdDate, mainImageUrl, url, user } } = this.props;
    const convertedUrl = getChangedUrl(url, window.location.protocol, window.location.host, null, {
      utm_source: window.location.hostname,
      utm_medium: 'heartit-editorial-list',
      utm_campaign: 'heartit-editorial-list',
      agent: 'web',
    });
    return (
      <div style={styles.container}>
        <div style={styles.headerContainer}>
          <div style={styles.headerLeft}>
            <Link to={`/${user}`}>
              <InstagramProfile style={styles.headerImage} instagramId={user} />
              <span style={styles.username}>{user}</span>
            </Link>
          </div>
          <div style={styles.headerRight}>
            <PostDate date={new Date(createdDate)} />
          </div>
        </div>
        <a href={convertedUrl}>
          <img src={mainImageUrl} style={styles.mainImage} alt="" />
        </a>
        <p style={styles.abstract}>
          {abstract.split('\n').map((line, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={`editorial_${createdDate}_abstract_${index}`}>
              {line}
              <br />
            </span>
          ))}
        </p>
        <br />
        <a href={convertedUrl}>
          <p style={styles.link}>
            <b>더 보기</b>
            <img
              style={styles.arrow}
              src="https://cdn.heartit.kr/img/r2/icon/icArrowRight.png"
              alt=""
            />
          </p>
        </a>
      </div>
    );
  }
}

Editorial.propTypes = {
  editorial: PropTypes.object.isRequired,
};
