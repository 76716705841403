import * as React from 'react';

function SvgLogoNaverSymbolType(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
      <g fill="none" fillRule="evenodd">
        <rect width={18} height={18} fill="#FFF" rx={0.5} />
        <path
          fill="#989797"
          d="M4 13.5h3.333V9l3.334 4.5H14v-9h-3.333V9L7.333 4.5H4z"
        />
      </g>
    </svg>
  );
}

export default SvgLogoNaverSymbolType;
