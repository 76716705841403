import React from 'react';
import { Image, Text, View, StyleSheet, Alert, TouchableHighlight, Platform } from 'react-native';
import { connect } from 'react-redux';

import { actions } from 'shared';

import { Colors, Styles, Constants } from '../constants';
import LikeNormalIcon from '../icons-v2/LikeNormalIcon';
import LikeSelectedIcon from '../icons-v2/LikeSelectedIcon';
import LikeNormalSmallIcon from '../icons/LikeNormalIcon18';
import LikeSelectedSmallIcon from '../icons/LikeSelectedIcon18';
// import { getUniqueId } from 'react-native-device-info';
// import Modal from 'react-native-modal';

import Button from '../components-v2/Button';


const buttonStyles = StyleSheet.create({
  imageContainerStyle: {
  },
});

const styles = StyleSheet.create({
  container: {
  },
  modalView: {
    justifyContent: 'flex-start',
    margin: 0,
  },
  resultContentContainer: {
    paddingTop: 18 + Constants.mainHeaderMarginTop + ((Platform.OS === 'android') ? 0 : Constants.statusBarHeight),
    paddingBottom: 30,
    paddingHorizontal: Constants.viewHorizontalPadding,
    backgroundColor: '#ffffff',
  },
  resultContentImage: {
    width: 63,
    height: 84,
    aspectRatio: 63/84,
  },
  resultContentDetailContainer: {
    justifyContent: 'flex-end',
    position: 'absolute',
    start: 63 + 15,
    top: 0,
    bottom: 0,
    end: 0,
  },
  resultContentDescriptionText: {
    fontSize: 12,
    lineHeight: 16,
    position: 'absolute',
    top: 0,
    start: 0,
    end: 0,
    ...Styles.appleSDGothicNeoRegular,
  },
  resultContentBrandText: {
    fontSize: 12,
    lineHeight: 16,
    ...Styles.appleSDGothicNeoExtraBold,
  },
  resultContentName: {
    fontSize: 12,
    lineHeight: 17,
    marginTop: 7,
    ...Styles.appleSDGothicNeoMedium,
  },
  resultContentOption: {
    fontSize: 12,
    lineHeight: 17,
    ...Styles.appleSDGothicNeoMedium,
  },
});



class LikeButton extends React.Component {
  isProcessing = false;

  constructor(props) {
    super(props);
    const { item: { liked = false } = {} } = props;

    this.state = {
      liked,
      isResultModalVisible: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    this.syncLikedState();
    const { liked: prevLiked, isResultModalVisible: prevIsResultModalVisible } = prevState;
    const { liked, isResultModalVisible } = this.state;

    if (liked != prevLiked && isResultModalVisible != prevIsResultModalVisible && isResultModalVisible) {
      setInterval(() => {
        this.setState({isResultModalVisible: false});
      }, 2000);
    }
  }

  toggleItemLike = async () => {
    const {
      item, heartistsPick, isFavProduct, isShopProduct, unlikeProduct, likeProduct,
      auth, historyId = null, logAddToFavoriteEvent,
    } = this.props;
    const { token } = auth;
    const { liked } = this.state;

    if (!auth || !token) {
      Alert.alert(
        null,
        '상품을 찜하시려면 로그인 하시기 바랍니다.',
        [{ text: 'close' }],
        { cancelable: false },
      );
      return;
    }

    const relatedObject = { heartistsPick };

    let { post } = this.props;
    if (!post && !!item.post) {
      // eslint-disable-next-line prefer-destructuring
      post = item.post;
    }

    if (post) {
      relatedObject.post = post;
    }
    // console.log('toggleItemLike item', item);
    // console.log('toggleItemLike relatedObject', relatedObject);


    if (isShopProduct) {
      Object.assign(relatedObject, { shopProduct: item });
    }
    if (isFavProduct) {
      Object.assign(relatedObject, { favProduct: item, historyId });
    }

    const productId = item._id || item.goodsNo;
    let newState = {
      liked: !liked
    };
    if (!liked) {
      newState['isResultModalVisible'] = true;
    }
    this.setState(newState);

    this.isProcessing = true;

    try {
      if (liked) {
        await unlikeProduct(productId, relatedObject);
      } else {
        await likeProduct(productId, relatedObject);
        // Send stat data to server (do not wait for the response, fire-and-forget)
        // logAddToFavoriteEvent({
        //   deviceId: getUniqueId(), 
        //   os: Platform.OS, 
        //   data: isShopProduct ? ({ godoGoodsNo: productId }) : ({ productId: item._id }),
        //   userId: auth._id
        // });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('LikeButton toggleItemLike error', error);
    }

    this.isProcessing = false;
  }

  syncLikedState() {
    const {
      item: { liked: likedInProps = false } = {},
    } = this.props;
    const { liked: likedInState } = this.state;

    if (likedInProps !== likedInState && !this.isProcessing) {
      this.setState({ liked: likedInProps });
    }
  }

  render() {
    const { liked } = this.state;
    const {
      styles, iconProps,
      underlayColor = Colors.touchEffectColorWhite,
      isSmallVersion = false,
      onPress = this.toggleItemLike,
      textButton = false,
      textButtonProps = {},
    } = this.props;

    if (textButton) {
      const {
        likeProduct,
        unlikeProduct,
        selectedOptionId,
        selectedOptionValue,
        optionName,
      } = this.props;

      return (
        <View>
          <Button
            {...textButtonProps}
            onPress={onPress}
            title={liked ? "위시리스트 담기 완료": "위시리스트 담기"}
          />
        </View>
      );
    }

    let LikeIcon;

    // console.log(`LikeButton isShopProduct=${isShopProduct} liked=${liked}`);

    if (isSmallVersion) {
      LikeIcon = liked ? LikeSelectedSmallIcon : LikeNormalSmallIcon;
    } else {
      LikeIcon = liked ? LikeSelectedIcon : LikeNormalIcon;
    }

    return (
      <TouchableHighlight
        style={[buttonStyles.imageContainerStyle, Styles.touchEffect].concat(styles)}
        onPress={onPress}
        underlayColor={underlayColor}
      >
        <LikeIcon {...iconProps} />
      </TouchableHighlight>
    );
  }
}


export default connect(
  (state, ownProps) => {
    const { isShopProduct = false, item } = ownProps;

    const newProps = {
      auth: state.auth,
    };

    // console.log('LikeButton connect isShopProduct=', isShopProduct);
    // console.log('LikeButton connect item=', item);

    if (isShopProduct) {
      if (item) {
        const productId = item._id || item.goodsNo;
        newProps.productId = productId;
        if (state.entities.shopProducts.hasOwnProperty(productId)) {
          newProps.item = state.entities.shopProducts[productId]
        }
      }
    } else {
      let { post } = ownProps;
      if (!post && item.post) {
        // eslint-disable-next-line prefer-destructuring
        post = item.post;
      }

      if (item && post) {
        const { _id: productId } = item;
        const postInStates = state.entities.posts[post._id];
        if (postInStates) {
          const product = postInStates.products.find(entry => entry._id === productId);
          if (product) {
            product.post = postInStates;
            newProps.productId = productId;
            newProps.item = product;
          }  
        }
      }
    }

    // console.log('LikeButton connect newProps=', newProps);

    return newProps;
  },
  actions,
)(LikeButton);
