/* eslint-disable import/prefer-default-export */
import set from 'lodash/set';
import createFetchAction from '../util/createFetchActionWithShopAuth';


export function addItemToCart(productId, count, option, directCheckout=false) {
  return createFetchAction({
    type: 'ADD_SHOP_ITEM_TO_CART',
    method: 'POST',
    endpoint: '/api/v2/shop/cart',
    body: {
      goodsNo: productId,
      count,
      option,
      directCheckout,
    },
  });
}

export function addItemToCartWithoutAuth(productId, count, option, directCheckout=false) {
  return createFetchAction({
    type: 'ADD_SHOP_ITEM_TO_CART_WITHOUT_AUTH',
    method: 'POST',
    endpoint: '/api/v2/shop/cart/noauth',
    body: {
      goodsNo: productId,
      count,
      option,
      directCheckout,
    },
  });
}


export function loadItemListInCart() {
  return createFetchAction({
    type: 'LOAD_SHOP_ITEM_LIST_IN_CART',
    endpoint: '/api/v2/shop/cart',
    transform: ({ json }) => {
      if (json.success !== true) {
        // console.log('LOAD_SHOP_ITEM_LIST_IN_CART error=', json);
        return {};
      }
      const items = json.data.cartInfo;
      // console.log('LOAD_SHOP_ITEM_LIST_IN_CART itemCount=', items.length);
      const deliveries = json.data.setDeliveryInfo;
      const cart = {
        items,
        deliveries,
      };

      const payload = {
        entities: set({}, ['shop', 'cart'], cart),
      };

      return payload;
    },
  });
}

export function loadItemListInCartWithoutAuth() {
  return createFetchAction({
    type: 'LOAD_SHOP_ITEM_LIST_IN_CART_WITHOUT_AUTH',
    endpoint: '/api/v2/shop/cart/noauth',
    transform: ({ json }) => {
      if (json.success !== true) {
        // console.log('LOAD_SHOP_ITEM_LIST_IN_CART error=', json);
        return {};
      }
      const items = json.data.cartInfo;
      // console.log('LOAD_SHOP_ITEM_LIST_IN_CART itemCount=', items.length);
      const deliveries = json.data.setDeliveryInfo;
      const cart = {
        items,
        deliveries,
      };

      const payload = {
        entities: set({}, ['shop', 'cart'], cart),
      };

      return payload;
    },
  });
}


export function calculatePriceForItemsInCart(itemIndexList) {
  return createFetchAction({
    type: 'CALCULATE_SHOP_ITEM_PRICE_IN_CART',
    method: 'PUT',
    endpoint: '/api/v2/shop/cart',
    body: {
      cartSno: itemIndexList,
    },
  });
}

export function calculatePriceForItemsInCartWithoutAuth(itemIndexList) {
  return createFetchAction({
    type: 'CALCULATE_SHOP_ITEM_PRICE_IN_CART_WITHOUT_AUTH',
    method: 'PUT',
    endpoint: '/api/v2/shop/cart/noauth',
    body: {
      cartSno: itemIndexList,
    },
  });
}

export function getCartTokenWithoutAuth() {
  return createFetchAction({
    type: 'GET_CART_TOKEN_WITHOUT_AUTH',
    endpoint: '/api/v2/shop/cart/noauthtoken',
  });
}

export function updateItemInCart(itemId, productId, count, option) {
  return createFetchAction({
    type: 'UPDATE_SHOP_ITEM_IN_CART',
    method: 'PUT',
    endpoint: `/api/v2/shop/cart/${itemId}`,
    body: {
      goodsNo: productId,
      count,
      option,
    },
  });
}

export function updateItemInCartWithoutAuth(itemId, productId, count, option) {
  return createFetchAction({
    type: 'UPDATE_SHOP_ITEM_IN_CART_WITHOUT_AUTH',
    method: 'PUT',
    endpoint: `/api/v2/shop/cart/noauth/${itemId}`,
    body: {
      goodsNo: productId,
      count,
      option,
    },
  });
}


export function deleteItemInCart(itemId) {
  return createFetchAction({
    type: 'DELETE_SHOP_ITEM_IN_CART',
    method: 'DELETE',
    endpoint: `/api/v2/shop/cart/${itemId}`,
  });
}

export function deleteItemInCartWithoutAuth(itemId) {
  return createFetchAction({
    type: 'DELETE_SHOP_ITEM_IN_CART_WITHOUT_AUTH',
    method: 'DELETE',
    endpoint: `/api/v2/shop/cart/noauth/${itemId}`,
  });
}
