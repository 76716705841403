import createFetchAction from '../util/createFetchAction';
import qs from 'qs';
import setWith from 'lodash/setWith';


export function getFollowingBrandList(query={}) {
  const params = query ? `?${qs.stringify(query).replace('%20', '+')}` : '';
  return createFetchAction({
    type: 'GET_FOLLOWING_BRAND_LIST',
    endpoint: '/api/v2/brands/following' + params,
    method: 'GET',
    doDispatch: false,
  });
}

export function getBrandFollowStatus(brandCd) {
  return createFetchAction({
    type: 'GET_BRAND_FOLLOW_STATUS',
    endpoint: `/api/v2/brands/${brandCd}/follow`,
    method: 'GET',
    transform: ({ json, state }) => {
      return updateFollowingBrandState(brandCd, json.data, state);
    },
    success: {
      meta: { entities: { update: true } },
    },
  });
}

export function followBrand(brandCd) {
  return createFetchAction({
    type: 'FOLLOW_BRAND',
    endpoint: `/api/v2/brands/${brandCd}/follow`,
    method: 'POST',
    doDispatch: false,
  });
}

export function unfollowBrand(brandCd) {
  return createFetchAction({
    type: 'UNFOLLOW_BRAND',
    endpoint: `/api/v2/brands/${brandCd}/follow`,
    method: 'DELETE',
    doDispatch: false,
  });
}

function updateFollowingBrandState(brandCd, following, state) {
  const brandFilteredProducts = [];
  Object.keys(state.entities.shopProducts).forEach(key => {
    const product = state.entities.shopProducts[key];
    if (product.brandCd == brandCd) {
      brandFilteredProducts.push(product);
    }
  });

  return brandFilteredProducts.reduce((prev, product) => {
    product.brandFollow = following;
    return setWith(prev, ['entities', 'shopProducts', product.goodsNo], product, Object);
  }, {});
}
