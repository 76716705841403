import assign from 'lodash/assign';
import get from 'lodash/get';
import { normalize } from 'normalizr';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';
import { getNextPageUrl } from '../util';

// admin actions for Wassily
export function adminLoadWassilyCSReservations() {
  return createFetchAction({
    type: 'ADMIN_LOAD_WASSILY_CS_RESERVATIONS',
    endpoint: '/api/v2/admin/wassily/reservation',
    method: 'GET',
    doDispatch: false,
  });
}

export function adminDeleteWassilyCSReservation({ rid }) {
  return createFetchAction({
    type: 'ADMIN_DELETE_WASSILY_CS_RESERVATION',
    endpoint: `/api/v2/admin/wassily/reservation/${rid}`,
    method: 'DELETE',
    doDispatch: false,
  });
}

export function adminDownloadWassilyCSOrderCSV() {
  return createFetchAction({
    type: 'ADMIN_DOWNLOAD_WASSILY_ORDER_CSV',
    endpoint: '/api/v2/admin/wassily/order/csv',
    method: 'GET',
    doDispatch: false,
  });
}
