import React from 'react';
import PropTypes from 'prop-types';
import {
    StyleSheet, View, Text,
} from 'react-native';

import { Styles } from '../../constants';
import CustomDialog from '../../components/CustomDialog';
import { 
  InputForm,
  PhoneVerificationForm,
  TouchableHighlightWithContainer,
  CheckBox,
} from '../../components-v2';


const styles = StyleSheet.create({
  container: {
    paddingTop: 22,
  },
  termContainer: {
    marginTop: 11.75,
  },
  agreeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 21,
  },
  agreeAllContainer: {
    marginStart: 0,
    marginBottom: 20,
  },
  agreeCheckboxContainer: {
    marginTop: 3,
  },
  agreeText: {
    ...Styles.pretendard400,
    fontSize: 13,
    lineHeight: 15.6,
    marginStart: 8,
  },
  agreeAllText: {
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard600,
  },
  agreeLinkButton: {
    height: 18,
    paddingTop: 3
  },
  agreeLink: {
    ...Styles.pretendard400,
    fontSize: 13,
    lineHeight: 15.6,
    textDecorationLine: 'underline',
  },
});


class JoinForm extends React.Component {

  state = {
    inputEmailText: '',
    inputPasswordText: '',
    inputNameText: '',
    phone: '',
    phoneVerified: false,
    termAgreed: false,
    privacyAgreed: false,
    marketingAgreed: false,
    showIgnoreOldUserDialog: false,
    phoneIgnoreOldUser: false,
    ignoreOldUserEmail: '',
  };

  inputEmailRef = null;
  inputPasswordRef = null;
  inputNameRef = null;
  inputPhoneRef = null;

  componentDidMount() {
    const { onRef } = this.props;
    onRef(this);

    let { email='', name='' } = this.props;
    if (email && email !== '') {
      this.setState({
        inputEmailText: email,
      });
    }
    if (name && name !== '') {
      this.setState({
        inputNameText: name,
      });
    }
  }

  updateInputData(newState, callback=(() => null)) {

    this.setState(newState, callback)

    const state = {
      ...this.state,
      ...newState,
    }

    const {
      inputEmailText,
      inputPasswordText,
      inputNameText,
      phone,
      phoneVerified,
      termAgreed,
      privacyAgreed,
      marketingAgreed,
    } = state;

    const { onChange } = this.props;
    onChange({
      email: inputEmailText,
      password: inputPasswordText,
      name: inputNameText,
      phone,
      phoneVerified,
      termAgreed,
      privacyAgreed,
      marketingAgreed,
    });
  }

  showIgnoreOldUserAlert(email) {
    this.setState({
      showIgnoreOldUserDialog: true,
      ignoreOldUserEmail: email,
    });
  }

  validateInput() {
    const {
      disablePassword,
      disableName,
      disablePhone,
    } = this.props;

    if (!this.inputEmailRef.validate()) {
      return false;
    }

    if (!disablePassword && !this.inputPasswordRef.validate()) {
      return false;
    }

    if (!disableName && !this.inputNameRef.validate()) {
      return false;
    }

    if (!disablePhone && !this.inputPhoneRef.validate()) {
      return false;
    }

    return true;
  }

  renderEmailInput() {
    const { editableEmail } = this.props;
    const { inputEmailText } = this.state;

    return (
      <View style={{ marginBottom: 40 }}>
        <InputForm
          type='email'
          title='이메일 주소'
          placeholder='이메일 주소를 입력해주세요.'
          description='예) heartit@heartit.kr'
          keyboardType='email-address'
          value={inputEmailText}
          onInputRef={ref => { this.inputEmailRef = ref; }}
          onChangeText={val => {
            this.updateInputData({ inputEmailText: val }, () => this.inputEmailRef.validate());
          }}
          onSubmitEditing={() => this.inputPasswordRef.focus()}
          enableInfoMessage
          enableErrorMessage
          editable={editableEmail}
          />
      </View>
    );
  }

  renderPasswordInput() {
    const { disablePassword } = this.props;
    const { inputPasswordText } = this.state;

    if (disablePassword) {
      return null;
    }

    return (
      <View style={{ marginBottom: 40 }}>
        <InputForm
          type='password'
          title='비밀번호'
          placeholder='비밀번호를 입력해주세요.'
          description='8자 이상 영문, 숫자, 특수문자 조합'
          value={inputPasswordText}
          onInputRef={ref => { this.inputPasswordRef = ref; }}
          onChangeText={val => {
            this.updateInputData({ inputPasswordText: val }, () => this.inputPasswordRef.validate());
          }}
          onSubmitEditing={() => this.inputNameRef.focus()}
          enableInfoMessage
          enableErrorMessage
          minLength={8}
          />
      </View>
    );
  }

  renderNameInput() {
    const { disableName } = this.props;
    const { inputNameText } = this.state;

    if (disableName) {
      return null;
    }

    return (
      <View style={{ marginBottom: 40 }}>
        <InputForm
          title='이름'
          placeholder='사용자 이름을 입력해주세요.'
          value={inputNameText}
          onInputRef={ref => { this.inputNameRef = ref; }}
          onChangeText={val => {
            this.updateInputData({ inputNameText: val }, () => this.inputNameRef.validate());
          }}
          onSubmitEditing={() => this.inputPhoneRef.focus()}
          enableInfoMessage
          enableErrorMessage
          minLength={2}
          />
      </View>
    );
  }

  renderPhoneInput() {
    const { disablePhone } = this.props;
    if (disablePhone) {
      return null;
    }

    const onChange = value => {
      const {
        phone,
        phoneVerified,
      } = value;

      this.updateInputData({
        phone,
        phoneVerified,
      }, () => this.inputPhoneRef.validate())
    };

    const { router } = this.props;
    const {
      showIgnoreOldUserDialog,
      phoneIgnoreOldUser,
      ignoreOldUserEmail,
    } = this.state;

    return (
      <View style={{ marginBottom: 40 }}>
        <PhoneVerificationForm
          onRef={ref => { this.inputPhoneRef = ref; }}
          onChange={onChange}
          showIgnoreOldUserAlert={oldUserEmail => this.showIgnoreOldUserAlert(oldUserEmail)}
          ignoreOldUser={phoneIgnoreOldUser}
          isRegisterForm={true}
          />

        <CustomDialog
          dialogOpen={showIgnoreOldUserDialog}
          onConfirm={() => {
            this.setState({
              showIgnoreOldUserDialog: false,
              phoneIgnoreOldUser: true,
            });
          }}
          onCancel={() => {
            this.setState({
              showIgnoreOldUserDialog: false,
              phoneIgnoreOldUser: false,
            }, () => {
              router.push({
                pathname: '/accounts/login/',
              });
            });
          }}
          onClose={() => {
            this.setState({ showIgnoreOldUserDialog: false });
          }}
          confirmColor="default"
          confirmText="가입 진행하기"
          cancelText="로그인하기"
          title="이미 가입된 휴대폰 번호입니다."
          description={(
            <div>
              {`${ignoreOldUserEmail} 으로 가입된 번호입니다. 그래도 가입을 진행하시겠습니까?`}
            </div>
          )}
        />
      </View>
    );
  }

  renderTerms() {
    const {
      onPressTerms,
    } = this.props;
    const {
      termAgreed,
      privacyAgreed,
      marketingAgreed,
    } = this.state;

    const allAgreed = termAgreed && privacyAgreed && marketingAgreed;

    const onPressCheckboxAll = () => {
      this.updateInputData({
        termAgreed: !allAgreed,
        privacyAgreed: !allAgreed,
        marketingAgreed: !allAgreed,
      });
    }

    return (
      <View style={styles.termContainer}>
        <View style={[styles.agreeContainer, styles.agreeAllContainer]}>
          <View style={styles.agreeCheckboxContainer}>
            <CheckBox
              onPress={onPressCheckboxAll}
              checked={allAgreed}
            />
          </View>
          <Text style={[styles.agreeText, styles.agreeAllText]}>전체 동의</Text>
        </View>
        <View style={[styles.agreeContainer]}>
          <View style={styles.agreeCheckboxContainer}>
            <CheckBox
              onPress={() => this.updateInputData({ termAgreed: !termAgreed })}
              checked={termAgreed}
            />
          </View>
          <View style={styles.agreeContentContainer}>
            <Text style={[styles.agreeText]}>
              (필수) 본인은 14세 이상이며&nbsp;
              <View style={styles.agreeLinkButton}>
                <TouchableHighlightWithContainer
                  onPress={() => onPressTerms('terms')}
                  >
                  <Text style={styles.agreeLink}>
                  이용약관(보기)
                  </Text>
                </TouchableHighlightWithContainer>
              </View>
              에 동의합니다.
            </Text>
          </View>
        </View>
        <View style={[styles.agreeContainer]}>
          <View style={styles.agreeCheckboxContainer}>
            <CheckBox
              onPress={() => this.updateInputData({ privacyAgreed: !privacyAgreed })}
              checked={privacyAgreed}
            />
          </View>
          <View style={styles.agreeContentContainer}>
            <Text style={[styles.agreeText]}>
              (필수)&nbsp;
              <View style={styles.agreeLinkButton}>
                <TouchableHighlightWithContainer
                  onPress={() => onPressTerms('privacy')}
                  >
                  <Text style={styles.agreeLink}>
                  개인정보 수집 및 이용(보기)
                  </Text>
                </TouchableHighlightWithContainer>
              </View>
              에 동의합니다.
            </Text>
          </View>
        </View>
        <View style={[styles.agreeContainer, styles.agreeMarketingContainer]}>
          <View style={styles.agreeCheckboxContainer}>
            <CheckBox
              onPress={() => this.updateInputData({ marketingAgreed: !marketingAgreed })}
              checked={marketingAgreed}
            />
          </View>
          <View style={styles.agreeContentContainer}>
            <Text style={[styles.agreeText]}>
              (선택) 마케팅 정보 수신에 동의합니다
            </Text>
          </View>
        </View>
      </View>
    );
  }
  
  render() {
    return (
      <View style={styles.container}>
        {this.renderEmailInput()} 
        {this.renderPasswordInput()}
        {this.renderNameInput()}
        {this.renderPhoneInput()}
        {this.renderTerms()}
      </View>
    );
  }
}

JoinForm.propTypes = {
  onRef: PropTypes.func,
  onChange: PropTypes.func,
  showIgnoreOldUserAlert: PropTypes.func,
  editableEmail: PropTypes.bool,
  disablePassword: PropTypes.bool,
  disableName: PropTypes.bool,
  disablePhone: PropTypes.bool,
};

JoinForm.defaultProps = {
  onRef: ref => null,
  onChange: inputData => null,
  showIgnoreOldUserAlert: oldEmail => null,
  editableEmail: true,
  disablePassword: false,
  disableName: false,
  disablePhone: false,
};

export default JoinForm;
