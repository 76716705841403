import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import InstagramProfile from './InstagramProfile';

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '25px',
    marginBottom: '20px',
  },
  imageContainer: {
    marginBottom: '20px',
  },
  image: {
    borderRadius: '50%',
    width: '90px',
  },
  name: {
    fontSize: '20px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  buttonContainer: {
    marginTop: '17.5px',
    marginBottom: '20px',
    width: '100%',
  },
  button: {
    color: 'black',
    cursor: 'pointer',
    border: '1px solid #000',
    textAlign: 'center',
    height: '42.5px',
    lineHeight: '42.5px',
    width: '150px',
    display: 'inline-block',
  },
  buttonIcon: {
    width: '17.5px',
    height: '17.5px',
    marginRight: '10px',
  },
};

export default class User extends Component {
  render() {
    const { user: { username, instagram } } = this.props;

    return (
      <div>
        <div style={styles.container}>
          <div style={styles.imageContainer}>
            <Link to={`/${username}`}>
              <InstagramProfile instagramId={username} style={styles.image} />
            </Link>
          </div>
          <div style={styles.name}>
            {username}
          </div>
          {instagram && (
            <div style={styles.buttonContainer}>
              <a href={`https://www.instagram.com/${username}`} target="_blank" rel="noopener noreferrer">
                <div style={styles.button}>
                  <img src="https://cdn.heartit.kr/img/r2/button/logo-black.png" alt="" style={styles.buttonIcon} />
                  follow
                </div>
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}

User.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
    instagram: PropTypes.object,
  }).isRequired,
};
