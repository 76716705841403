import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import NumberFormat from 'react-number-format';

import {
  Colors, Constants, Styles,
} from '../../../constants';


const styles = StyleSheet.create({
  container: {
    marginStart: 1,
    marginBottom: 40,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  rowLast: {
    marginTop: 4,
    marginBottom: 0,
  },
  smallText: {
    fontSize: 16,
    lineHeight: 19.2,
    color: Colors.defaultGray08,
    textAlign: 'left',
    textAlignVertical: 'center',
    ...Styles.pretendard400,
  },
  largeText: {
    color: Colors.defaultGray08,
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard600,
  },
  largePriceText: {
    color: Colors.defaultGray08,
    fontSize: 24,
    lineHeight: 28.8,
    ...Styles.pretendard400,
  },
  promo: {
    color: '#ee2424',
  },
});


export default function Statement(props) {
  const {
    checkoutInfo: {
      settlePrice,
      totalDeliveryCharge = 0,
      totalCouponOrderDcPrice = 0,
      useMileage = 0,
      totalMemberDcPrice = 0,
      totalGoodsPrice,
    } = {},
  } = props;

  const items = [
    { key: 'totalGoodsPrice', name: '총 상품 금액', value: totalGoodsPrice, sign: '' },
    { key: 'totalDeliveryCharge', name: '총 배송비', value: totalDeliveryCharge, sign: '' },
    { key: 'totalCouponOrderDcPrice', name: '쿠폰 할인', value: parseInt(totalCouponOrderDcPrice).toLocaleString(), sign: '-' },
    { key: 'useMileage', name: '포인트 사용', value: useMileage, sign: '-' },
    { key: 'totalMemberDcPrice', name: '프로모션 할인', value: totalMemberDcPrice, sign: '-' },
  ];

  return (
    <View style={styles.container}>
      { items.map(item => {
        if (item.key === 'totalMemberDcPrice') {
          if (item.value) {
            return (
              <View key={item.key} style={styles.row}>
                <Text style={[styles.smallText, styles.promo]}>{item.name}</Text>
                <NumberFormat
                  value={item.value}
                  displayType="text"
                  thousandSeparator
                  renderText={value => (
                    <Text style={[styles.smallText, styles.promo]}>
                      {`${item.sign} ${value}원`}
                    </Text>
                  )}
                />
              </View>
            );
          } else {
            return null;
          }
        } else {
          return (
            <View key={item.key} style={styles.row}>
              <Text style={styles.smallText}>{item.name}</Text>
              <NumberFormat
                value={item.value}
                displayType="text"
                thousandSeparator
                renderText={value => (
                  <Text style={styles.smallText}>
                    {`${item.sign} ${value}원`}
                  </Text>
                )}
              />
            </View>
          );
        }
      })}

      <View style={[styles.row, styles.rowLast]}>
        <Text style={styles.largeText}>총 결제 예상 금액</Text>
        <NumberFormat
          value={settlePrice}
          displayType="text"
          thousandSeparator
          renderText={value => (
            <Text style={styles.largePriceText}>
              {value}
              {'원'}
            </Text>
          )}
        />
      </View>
    </View>
  );
}
