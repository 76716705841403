import assign from 'lodash/assign';
import get from 'lodash/get';
import { normalize } from 'normalizr';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';
import { getNextPageUrl } from '../util';


function adminLoadAppVersions({ type, key, platform, nextPage }) {
  return createFetchAction({
    type,
    method: 'GET',
    endpoint: state => {
      const base = `/api/v2/admin/versions?page=0&platform=${platform}`;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      if (nextPage) {
        return next;
      }
      return base;
    },
    transform: ({ json, response }) => assign(normalize(json, schemas[key]), {
      nextPageUrl: getNextPageUrl(response),
    }),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    }
  });
}

export function adminLoadAndroidAppVersions({ nextPage }) {
  const key = 'adminAndroidAppVersions';
  const type = 'ADMIN_LOAD_ANDROID_APP_VERSIONS';
  return adminLoadAppVersions({
    key,
    type,
    nextPage,
    platform: 'android',
  });
}

export function adminLoadIosAppVersions({ nextPage }) {
  const key = 'adminIosAppVersions';
  const type = 'ADMIN_LOAD_IOS_APP_VERSIONS';
  return adminLoadAppVersions({
    key,
    type,
    nextPage,
    platform: 'ios',
  });
}

export function adminCreateAppVersion({ appVersion }) {
  const key = appVersion.platform === 'android' ? 'adminAndroidAppVersion' : 'adminIosAppVersion';
  return createFetchAction({
    type: 'ADMIN_CREATE_APP_VERSION',
    endpoint: '/api/v2/admin/versions',
    method: 'POST',
    body: appVersion,
    transform: ({ json }) => normalize(json, schemas[key]),
    success: () => ({
      pagination: {
        key: `${key}s`,
        type: 'PREPEND',
      },
    }),
  });
}

export function adminUpdateAppVersion({ appVersion }) {
  const key = appVersion.platform === 'android' ? 'adminAndroidAppVersion' : 'adminIosAppVersion';
  return createFetchAction({
    type: 'ADMIN_UPDATE_APP_VERSION',
    endpoint: `/api/v2/admin/versions/${appVersion._id}`,
    method: 'PUT',
    body: appVersion,
    transform: ({ json }) => normalize(json, schemas[key])
  });
}

export function adminDeleteAppVersion({ appVersion }) {
  const key = appVersion.platform === 'android' ? 'adminAndroidAppVersions' : 'adminIosAppVersions';
  const payload = { entities: { [key]: {} } };
  payload.entities[key][appVersion._id] = null;
  return createFetchAction({
    type: 'ADMIN_DELETE_APP_VERSION',
    endpoint: `/api/v2/admin/versions/${appVersion._id}`,
    method: 'DELETE',
    success: {
      payload,
    }
  });
}