import * as React from 'react';

function SvgIcFold(props) {
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M18 15l-6-6-6 6"
        stroke="#000"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIcFold;
