import url from 'url';

import { config } from 'shared';


export async function uploadFileToServer(bearerToken, endpoint, uploadFile) {
  const apiRoot = config.get('apiRoot') || 'api.heartit.kr';
  const targetUrl = url.resolve(apiRoot, endpoint);
  const extraHeaders = global.extraHeaders || {};

  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${bearerToken}`,
    'heartit-agent': extraHeaders['heartit-agent'] || 'app',
    'heartit-agent-version': global.appVersion || 'web',
    'heartit-agent-os': global.platformOS || 'web',
  };

  // console.log('uploadFileToServer uploadFile=', uploadFile);

  const body = new FormData();
  body.append('fileName', uploadFile.name);
  // body.append('fileData', uploadFile.data);
  body.append('fileData', uploadFile);
  // body.append('fileData', uploadFile.data, uploadFile.fileName);

  const options = {
    method: 'POST',
    headers,
    body,
  };

  const promise = fetch(targetUrl, options);

  const ret = await promise;
  // console.log('uploadFileToServer response', ret);

  const statusCode = ret.status;
  if (statusCode !== 200) {
    const error = {
      errorCode: '1' + statusCode,
    };

    throw error;
  }

  const json = await ret.json();

  if (json.success !== true) {
    throw json;
  }

  return json;
}


export async function uploadShopProductQnaFile(bearerToken, productId, uploadFile) {
  const endpoint = `/api/v2/shop/product/${productId}/qna/file`;

  return uploadFileToServer(bearerToken, endpoint, uploadFile);
}

export async function uploadShopProductReviewFile(bearerToken, productId, uploadFile) {
  const endpoint = `/api/v2/shop/product/${productId}/review/file`;

  return uploadFileToServer(bearerToken, endpoint, uploadFile);
}

export async function uploadStylePostPhoto(bearerToken, uploadFile) {
  const endpoint = '/api/v2/stylePosts/photos';
  return uploadFileToServer(bearerToken, endpoint, uploadFile);
}

export async function uploadProfileImage(bearerToken, userId, uploadFile) {
  const endpoint = `/api/v2/users/${userId}/profileImage`;
  return uploadFileToServer(bearerToken, endpoint, uploadFile);
}
