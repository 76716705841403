import * as React from 'react';

function CheckIcon(props) {
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M6.574 10.856l4.46 4.46 6.698-6.7"
        stroke="#FFF"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default CheckIcon;
