import React from 'react'
import { connect } from 'react-redux';
import { actions } from 'shared';

import { 
  StyleSheet, View,
} from 'react-native';

import { ListWithCheckBox } from '../../..';

import FilterBaseModal from '../FilterBaseModal';
import BrandSearchBar from './BrandSearchBar';


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  listContainer: {
    marginTop: 0,
  },
  itemContainerStyle: {
    marginTop: 25,
    marginBottom: 24,
  },
});


class FilterBrandModal extends FilterBaseModal {
  constructor(props) {
    // console.log(`FilterBrandModal.constructor`);
    super(props);
  }

  state = {
    searchKeyword: '',
    selectedBrandList: [],
  };

  title = '브랜드';

  componentDidMount() {
    // console.log(`FilterBrandModal.componentDidMount`);
    const { selectedBrandList, logEventPageview } = this.props;
    // const selectedBrandList = navigation.getParam('selectedBrandList', []);
    this.setState({ selectedBrandList });

    logEventPageview({ page_name: 'FilterBrand' });
  }

  componentWillUnmount() {
    // console.log(`FilterBrandModal.componentWillUnmount`);
  }

  onChangeSearchKeyword = (searchKeyword) => {
    this.setState({ searchKeyword });
  }

  onPressApplyButton() {
    const { onChange } = this.props;
    const { selectedBrandList } = this.state;

    const {
      modalProps,
      setModalProps,
    } = this.props;

    const {
      historyStack=[],
    } = modalProps();

    const children = historyStack.pop();

    setModalProps({
      children,
      historyStack,
    }, () => {
      onChange(selectedBrandList);
    });
  }

  onChangeSelectedBrandList = (selectedBrandList) => {
    // console.log(`onChangeSelectedBrandList selectedBrandList=${selectedBrandList}`);
    this.setState({
      selectedBrandList,
    })
  };

  renderSearchBar() {
    return (
      <BrandSearchBar
        onChangeSearchKeyword={this.onChangeSearchKeyword}
        />
    );
  }

  renderBody() {
    const { brandList } = this.props;
    const { searchKeyword, selectedBrandList } = this.state;

    // console.log(`FilterBrandModal.renderBody brandList=${brandList}`);

    return (
      <View style={styles.container}>
        {this.renderSearchBar()}
        <ListWithCheckBox
          searchKeyword={searchKeyword}
          onChangeSelectedItemList={this.onChangeSelectedBrandList}
          selectedItemList={selectedBrandList}
          itemList={brandList}
          ItemContainerStyle={styles.itemContainerStyle}
          containerStyle={styles.listContainer}
          enableIndex
          sortItemByName
          />
      </View>
    )
  }
}


export default connect(
  (state, { router }) => ({
    auth: state.auth,
    // brandList: navigation.getParam('brandList', []),
  }),
  actions,
)(FilterBrandModal);
