import React from 'react';
import { StyleSheet, View, Text } from 'react-native';

import { Constants, Styles } from '../../../../constants';

import {
  Button,
} from '../../../../components-v2';

import { getOrderCount } from '../../../../utils';


const styles = StyleSheet.create({
  sectionContainer: {
    backgroundColor: '#ffffff',
  },
  sectionContentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 12,
  },
  sectionContentSubContainer: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
    height: 64,
    paddingTop: 12,
  },
  sectionContentValueButton: {
    borderWidth: 0,
    borderRadius: Constants.touchEffectBorderRadius,
    padding: Constants.touchEffectWidth,
    margin: -1 * Constants.touchEffectWidth,
  },
  sectionContentValue: {
    fontSize: 16,
    lineHeight: 19.2,
    marginBottom: 5,
    color: '#000',
    ...Styles.pretendard400,
  },
  sectionContentText: {
    fontSize: 12,
    lineHeight: 14.4,
    color: '#000',
    opacity: 0.5,
    fontWeight: '400',
    ...Styles.pretendard400,
  },
});

class Header extends React.Component {

  getOrderCount() {
    const { auth: { summary } } = this.props;
    if (summary) {
      return getOrderCount(summary);
    }

    return {
      orderConfirm: 0,
      shippingPending: 0,
      shipping: 0,
      delivered: 0,
      cancel: 0,
    };
  }

  render() {
    const { router } = this.props;

    const {
      orderConfirm,
      shippingPending,
      shipping,
      delivered,
      cancel,
    } = this.getOrderCount();

    return (
      <View style={styles.sectionContainer}>
        <View style={styles.sectionContentContainer}>
          <View style={styles.sectionContentSubContainer}>
            <Button
              containerStyle={styles.sectionContentValueButton}
              titleStyle={styles.sectionContentValue}
              title={orderConfirm}
              onPress={() => {
                router.push('/accounts/orders?step=orderConfirm');
              }}
            />
            <Text style={styles.sectionContentText}>
              결제완료
            </Text>
          </View>

          <View style={styles.sectionContentSubContainer}>
            <Button
              containerStyle={styles.sectionContentValueButton}
              titleStyle={styles.sectionContentValue}
              title={shippingPending}
              onPress={() => {
                router.push('/accounts/orders?step=shippingPending');
              }}
            />
            <Text style={styles.sectionContentText}>
              상품준비중
            </Text>
          </View>

          <View style={styles.sectionContentSubContainer}>
            <Button
              containerStyle={styles.sectionContentValueButton}
              titleStyle={styles.sectionContentValue}
              title={shipping}
              onPress={() => {
                router.push('/accounts/orders?step=shipping');
              }}
            />
            <Text style={styles.sectionContentText}>
              배송중
            </Text>
          </View>

          <View style={styles.sectionContentSubContainer}>
            <Button
              containerStyle={styles.sectionContentValueButton}
              titleStyle={styles.sectionContentValue}
              title={delivered}
              onPress={() => {
                router.push('/accounts/orders?step=delivered');
              }}
            />
            <Text style={styles.sectionContentText}>
              배송완료
            </Text>
          </View>

          <View style={styles.sectionContentSubContainer}>
            <Button
              containerStyle={styles.sectionContentValueButton}
              titleStyle={styles.sectionContentValue}
              title={cancel}
              onPress={() => {
                router.push('/accounts/orders?step=cancel');
              }}
            />
            <View style={{flexDirection: 'column', alignItems: 'center'}}>
              <Text style={styles.sectionContentText}>
                반품/교환
              </Text>
              <Text style={styles.sectionContentText}>
                취소
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  }
}


export default Header;
