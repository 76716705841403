import * as React from 'react';

function SvgIcShare(props) {
  return (
    <svg width={24} height={24} {...props}>
      <g stroke="#000" strokeWidth={1.5} fill="none" fillRule="evenodd">
        <path strokeLinejoin="round" d="M17.656 9.586L12 3.929 6.343 9.586" />
        <path d="M12 4v11" />
        <path strokeLinejoin="round" d="M19 13v6H5v-6" />
      </g>
    </svg>
  );
}

export default SvgIcShare;

