import * as React from 'react';

function SvgIcHeaderBag(props) {
  return (
    <svg width={24} height={24} {...props}>
      <defs>
        <path id="icHeaderBag_svg__a" d="M0 0h10v7H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(7 1)">
          <mask id="icHeaderBag_svg__b" fill="#fff">
            <use xlinkHref="#icHeaderBag_svg__a" />
          </mask>
          <rect
            width={8.333}
            height={11.667}
            x={0.833}
            y={0.833}
            stroke="#000"
            strokeWidth={1.5}
            mask="url(#icHeaderBag_svg__b)"
            rx={4.167}
          />
        </g>
        <path
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M3 8h18l-.889 12H3.889z"
        />
      </g>
    </svg>
  );
}

export default SvgIcHeaderBag;

