import * as React from 'react';

function SvgBtnVocIcon(props) {
  return (
    <svg width={60} height={60} {...props}>
      <defs>
        <filter
          id="btnVocIcon_svg__a"
          width="204.3%"
          height="204.3%"
          x="-52.2%"
          y="-39.1%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy={6} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation={7}
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
        </filter>
        <circle id="btnVocIcon_svg__b" cx={23} cy={23} r={23} />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g fill="#000" transform="translate(7 7)">
          <use
            filter="url(#btnVocIcon_svg__a)"
            xlinkHref="#btnVocIcon_svg__b"
          />
          <use xlinkHref="#btnVocIcon_svg__b" />
        </g>
        <path
          // eslint-disable-next-line max-len
          d="M30 20.576c-6.628 0-12 4.238-12 9.466 0 3.403 2.276 6.385 5.693 8.053-.251.935-.909 3.388-1.041 3.912-.162.651.24.643.502.467.207-.137 3.289-2.227 4.619-3.13.721.107 1.466.164 2.227.164 6.627 0 12-4.239 12-9.466 0-5.228-5.373-9.466-12-9.466"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export default SvgBtnVocIcon;
