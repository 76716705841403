import React from 'react'

import { StyleSheet, View, Text, ScrollView } from 'react-native';
import NumberFormat from 'react-number-format';

import { Constants, Styles } from '../../constants';
import { FilterIcon, SortIcon, CrossIcon } from '../../icons-v2';
import Ic24RefreshIcon from '../../icons-v2/Ic24RefreshIcon';
import Ic14ArrowDown from '../../icons-v2/Ic14ArrowDown';

import TouchableHighlightWithContainer from '../TouchableHighlightWithContainer';
import showFilterMainModal from './filter/FilterMainModal';
import SortModal from './SortModal';


const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: 'white',
    paddingHorizontal: Constants.viewHorizontalPadding,
    paddingTop: 10,
    paddingBottom: 20,
    justifyContent: 'space-between',
  },
  searchRuleButton: {
    backgroundColor: 'black',
  },
  searchRuleButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    paddingVertical: 6,
  },
  searchRuleButtonText: {
    color: 'white',
    fontSize: 15,
    lineHeight: 18,
    ...Styles.pretendard600,
  },
  filterContainer: {
    flexDirection: 'row',
  },
  filterItemContainer: {
    backgroundColor: 'white',
    borderWidth: 0.5,
    borderColor: 'black',
  },
  filterItemText: {
    color: 'black',
  },
  filterItemDeleteButton: {
  },
  sortButtonTouchContainer: {
    backgroundColor: 'white',
    marginTop: 6,
    marginBottom: 7,
  },
  sortButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  sortButtonText: {
    color: 'black',
    fontSize: 14,
    lineHeight: 16.8,
    marginEnd: 4,
    ...Styles.pretendard500
  },
});


class ProductFilterControl extends React.PureComponent {

  state = {
  }

  onChangeFilterList = (newFilterList) => {
    // console.log(`onChangeFilterList newFilterList=${newFilterList}`);

    const { activeFilterList, onChangeFilterList } = this.props;
    if (!onChangeFilterList) {
      return;
    }

    let mergedFilter = newFilterList.filter(f => f.type == "merged");
    if (mergedFilter.length > 0) {
      mergedFilter = mergedFilter[0];
    } else {
      mergedFilter = null;
    }

    if (mergedFilter === null) {
      return onChangeFilterList(newFilterList);
    } else {
      return onChangeFilterList([ ...newFilterList.filter(f => f.type !== "merged"), ...activeFilterList.filter(f => f.type == "products" || f.type == "crawler")]);
    }
  }

  onChangeSortingRule = (sortingRule) => {
    // console.log(`onChangeSortingRule sortingRule=${sortingRule}`);

    const { onChangeSortingRule } = this.props;

    onChangeSortingRule && onChangeSortingRule(sortingRule);
  }


  showSortControl() {
    const {
      setModalProps,
      sortingRule,
      sortItemList,
    } = this.props;

    const children = (
      <SortModal
        itemList={sortItemList}
        selectedItem={sortingRule}
        onChange={this.onChangeSortingRule}
        setModalProps={setModalProps}
        />
    );

    setModalProps({
      isVisible: true,
      children: children,
    });
  }

  clearFilter() {
    const {
      onChangeFilterList,
    } = this.props;

    onChangeFilterList([]);
  }

  mergeFilters(filterList) {
    /*
    let productFilter = filterList.filter(f => f.type == "products");
    if (productFilter.length > 0) {
      productFilter = productFilter[0];
    } else {
      productFilter = null;
    }

    let crawlerFilter = filterList.filter(f => f.type == "crawler");
    if (crawlerFilter.length > 0) {
      crawlerFilter = crawlerFilter[0];
    } else {
      crawlerFilter = null;
    }

    if (productFilter === null && crawlerFilter === null) {
      return filterList;
    }

    const newFilter = {
      id: 'merged',
      type: 'merged',
      name: '카테고리 세일 제외',
    }

    return [ ...filterList.filter(f => f.type != "products" && f.type != "crawler"), newFilter];
    */
    return filterList;
  }

  render() {
    const {
      activeFilterList,
      containerStyle,
      modalProps,
      brandList,
      sortingRule,
    } = this.props;

    const mergedFilterList = this.mergeFilters(activeFilterList);
    const onPressFilter = () => {
      showFilterMainModal(this, brandList, mergedFilterList, this.onChangeFilterList);
    };

    const filterLength = activeFilterList.filter(f => f.type !== "products").length;

    return (
      <View style={styles.container}>
        <View style={styles.filterContainer}>
          <TouchableHighlightWithContainer
            onPress={onPressFilter}
            containerStyle={styles.searchRuleButton}
          >
            <View style={styles.searchRuleButtonContainer}>
              <FilterIcon style={{ marginRight: 4 }} color={(filterLength > 0) ? "#00FFFF": "#FFF"} />
              <Text style={[styles.searchRuleButtonText, (filterLength > 0) ? {color: '#00FFFF'}: {}]}>
                필터{filterLength > 0 ? ` ${filterLength}`: ''}
              </Text>
            </View>
          </TouchableHighlightWithContainer>

          <View style={{width: 4}}/>

          {activeFilterList.length > 0 ? (
            <TouchableHighlightWithContainer
              onPress={() => this.clearFilter()}
              containerStyle={styles.searchRuleButton}
            >
              <View style={styles.searchRuleButtonContainer}>
                <Ic24RefreshIcon style={{ marginRight: 4 }}/>
                <Text style={styles.searchRuleButtonText}>초기화</Text>
              </View>
            </TouchableHighlightWithContainer>
          ): null}
        </View>

        <View>
          <TouchableHighlightWithContainer
            onPress={() => this.showSortControl()}
            containerStyle={styles.sortButtonTouchContainer}
          >
            <View style={styles.sortButtonContainer}>
              <SortIcon style={{ marginRight: 4 }} />
              <Text style={styles.sortButtonText}>{sortingRule.name}</Text>
              <Ic14ArrowDown />
            </View>
          </TouchableHighlightWithContainer>
        </View>
      </View>
    );
  }
}


export default ProductFilterControl;
