import React from 'react'

import { StyleSheet, View, Text } from 'react-native';

import { TouchableHighlightWithContainer as TouchableHighlight } from '../../../../components-v2';

import { Styles, Colors } from '../../../../constants';


const styles = StyleSheet.create({
  container: {
    paddingBottom: 10,
    paddingHorizontal: 20,
    marginTop: -4,
  },
  titleContainer:{
    flex:1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    ...Styles.pretendard700,
    fontSize: 18,
    lineHeight: 21.6,
  },
  descriptionContainer: {
    marginTop: 5,
    marginBottom: 1,
  },
  description: {
    ...Styles.pretendard500,
    fontSize: 13,
    lineHeight: 15.6,
    color: 'black',
    opacity: 0.4,
  },
  buttonSeeAllContainer: {
    marginTop: 4,
  },
  buttonSeeAll: {
    ...Styles.pretendard600,
    fontSize: 13,
    lineHeight: 15.6,
  },
});


class HomeFeedSectionHeader extends React.PureComponent {

  render() {
    const { title, description, showSeeAll, onPressSeeAll } = this.props;

    return (
      <View style={styles.container}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>
            {title}
          </Text>
          {showSeeAll && onPressSeeAll && (
            <TouchableHighlight
              underlayColor={Colors.touchEffectColorWhite}
              style={[styles.buttonSeeAllContainer, Styles.touchEffect, Styles.touchEffectContainer]}
              onPress={onPressSeeAll}
            >
              <Text style={styles.buttonSeeAll}>
                VIEW ALL
              </Text>
            </TouchableHighlight>
          )}
        </View>
        {description && (
          <View style={styles.descriptionContainer}>
            <Text style={styles.description}>
              {description}
            </Text>
          </View>
        )}
      </View>
    );
  }
}

export default HomeFeedSectionHeader;
