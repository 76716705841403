import assign from 'lodash/assign';
import get from 'lodash/get';
import { normalize } from 'normalizr';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';
import { getNextPageUrl } from '../util';

// admin actions for faq categories
export function adminLoadFaqCategories() {
  const key = 'adminFaqCategories';

  return createFetchAction({
    type: 'ADMIN_LOAD_FAQ_CATEGORIES',
    endpoint: '/api/v2/admin/faq/category',
    transform: ({ json, response }) => assign(normalize(json, schemas.adminFaqCategories), {
      nextPageUrl: getNextPageUrl(response),
    }),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}

export function adminCreateFaqCategory({ category }) {
  return createFetchAction({
    type: 'ADMIN_CREATE_FAQ_CATEGORY',
    endpoint: '/api/v2/admin/faq/category',
    method: 'POST',
    body: category,
    transform: ({ json }) => normalize(json, schemas.adminFaqCategory),
    success: () => ({
      pagination: {
        key: 'adminFaqCategories',
        type: 'PREPEND',
      },
    }),
  });
}

export function adminGetFaqCategory({ categoryId }) {
  return createFetchAction({
    type: 'ADMIN_GET_FAQ_CATEGORY',
    endpoint: `/api/v2/admin/faq/category/${categoryId}`,
    method: 'GET',
    doDispatch: false,
  });
}

export function adminUpdateFaqCategory({ category }) {
  return createFetchAction({
    type: 'ADMIN_UPDATE_FAQ_CATEGORY',
    endpoint: `/api/v2/admin/faq/category/${category._id}`,
    method: 'PUT',
    body: category,
    transform: ({ json }) => normalize(json, schemas.adminFaqCategory),
  });
}

export function adminDeleteFaqCategory({ categoryId }) {
  const payload = { entities: { adminFaqCategories: {} } };
  payload.entities.adminFaqCategories[categoryId] = null;

  return createFetchAction({
    type: 'ADMIN_DELETE_FAQ_CATEGORY',
    endpoint: `/api/v2/admin/faq/category/${categoryId}`,
    method: 'DELETE',
    success: {
      payload,
    },
  });
}

// admin actions for faqs
export function adminLoadFaqs() {
  const key = 'adminFaqs';

  return createFetchAction({
    type: 'ADMIN_LOAD_FAQS',
    endpoint: '/api/v2/admin/faq',
    transform: ({ json, response }) => assign(normalize(json, schemas.adminFaqs), {
      nextPageUrl: getNextPageUrl(response),
    }),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}

export function adminCreateFaq({ faq }) {
  return createFetchAction({
    type: 'ADMIN_CREATE_FAQ',
    endpoint: '/api/v2/admin/faq',
    method: 'POST',
    body: faq,
    transform: ({ json }) => normalize(json, schemas.adminFaqs),
    success: () => ({
      pagination: {
        key: 'adminFaqs',
        type: 'PREPEND',
      },
    }),
  });
}

export function adminGetFaq({ faqId }) {
  return createFetchAction({
    type: 'ADMIN_GET_FAQ',
    endpoint: `/api/v2/admin/faq/${faqId}`,
    method: 'GET',
    doDispatch: false,
  });
}

export function adminUpdateFaq({ faq }) {
  return createFetchAction({
    type: 'ADMIN_UPDATE_FAQ',
    endpoint: `/api/v2/admin/faq/${faq._id}`,
    method: 'PUT',
    body: faq,
    transform: ({ json }) => normalize(json, schemas.adminFaq),
  });
}

export function adminDeleteFaq({ faqId }) {
  const payload = { entities: { adminFaqs: {} } };
  payload.entities.adminFaqs[faqId] = null;

  return createFetchAction({
    type: 'ADMIN_DELETE_FAQ',
    endpoint: `/api/v2/admin/faq/${faqId}`,
    method: 'DELETE',
    success: {
      payload,
    },
  });
}
