import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M8.57 14.662c.514.41 1 .796 1.43 1.166v.005c.43-.373.916-.758 1.43-1.166l.007-.005c1.81-1.436 3.861-3.062 4.767-5.012a4.696 4.696 0 00.463-1.984 3.443 3.443 0 00-1.076-2.5A3.65 3.65 0 0013 4.168a4.189 4.189 0 00-2.218.637c-.29.185-.553.406-.782.659a3.703 3.703 0 00-.781-.659A4.192 4.192 0 007 4.167a3.65 3.65 0 00-2.59 1 3.443 3.443 0 00-1.077 2.499c.008.684.165 1.36.463 1.98.904 1.946 2.949 3.571 4.756 5.007l.011.009h.007z"
        stroke="#000"
        strokeWidth={1.125}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default SvgComponent
