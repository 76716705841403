// export function closeAllPopup() {
//   // 2015. 11. 14. [heekyu] this event calls twice in transparent-background. why?
//   console.log('closeAllPopup called');
//   if ($('.transparent-background').css('display') === 'block') {
//     $('.navbar-toggle').click();
//     $('.transparent-background').css('display', 'none');
//   }
// }
export function validatePhoneNumber(value) {
  if (!value || value.length > 22) {
    return false;
  }
  const validChars = '()- ';
  let i = 0;
  if (value[i] === '+') {
    i++;
  }
  let digital = 0;
  for (; i < value.length; i++) {
    if (value[i] >= '0' && value[i] <= '9') {
      digital++;
      continue;
    }
    if (validChars.indexOf(value[i]) === -1) {
      return false;
    }
  }
  if (digital < 7) {
    return false;
  }
  return true;
}
// 2015. 12. 30. [heekyu] login function can use anyware
export function doLogin() {
  // TODO: Delete require if not indispensable.
  // eslint-disable-next-line global-require, no-undef
  const loadInstagramAuthUrl = require('../actions').loadInstagramAuthUrlDispatch;
  const cb = res => {
    window.location.href = res.payload.url;
  };
  loadInstagramAuthUrl()(cb, () => {});
}

// convert to milliseconds.
export function instagramMediaIdToTime(mediaId) {
  const postTime = Math.floor(parseInt(mediaId.split('_')[0], 10) / (2 ** 23));
  const offsetForInstagramTime = Date.UTC(2011, 0, 1) + 20380021100;

  return postTime + offsetForInstagramTime;
}

export function instagramMediaIdToDate(mediaId) {
  return new Date(instagramMediaIdToTime(mediaId));
}

export function objectIdToDate(objectId) {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
}

export function getIsoFormatDateString(date) {
  return date.toISOString().replace('T', ' ').split('.')[0];
}

export function getKrIsoFormatDateString(date) {
  const krOffsetNineHoursInMs = 9 * 60 * 60 * 1000;
  return getIsoFormatDateString(new Date(date.getTime() + krOffsetNineHoursInMs));
}

/* export function selectPostDate(post) {
  if (post.featured && post.featured.score > 10000) {
    return new Date(post.featured.score);
  }
  if (post.featured && post.featured.at) {
    return new Date(post.featured.at);
  }
  let targetMediaId = null;
  for (let i = 0; i < post.instagramMedias.length; ++i) {
    if (post.instagramMedias[i].id.split('_')[1] === post.owner.instagram.id) {
      targetMediaId = post.instagramMedias[i].id;
      break;
    }
  }
  if (targetMediaId) {
    return instagramMediaIdToDate(targetMediaId);
  }
  return objectIdToDate(post._id);
} */

export function selectPostDate(screen) {
  if (screen === 'homeScreen') {
    return post => {
      if (post.featured && post.featured.score > 10000) {
        return new Date(post.featured.score);
      }
      if (post.featured && post.featured.at) {
        return new Date(post.featured.at);
      }
      return objectIdToDate(post._id);
    };
  }
  if (screen === 'heartistScreen') {
    return post => objectIdToDate(post._id);
  }

  if (screen === 'postScreen') {
    return post => new Date(post.created)
  }

  // TODO: may need to add liked screen
  return post => objectIdToDate(post._id);
}
