import { Button } from '@material-ui/core';
import React, { Component } from 'react';
import AdminProduct from './AdminProduct';

export default class AdminProductList extends Component {
  constructor(props) {
    super(props);
  }

  loadData(since, until) {
    const { loadData } = this.props;
    loadData({ since, until });
  }

  loadMore() {
    const { pagination, loadData, since, until } = this.props;
    loadData({ nextPage: pagination.nextPageUrl, since, until })
  }

  componentDidMount() {
    const { since, until } = this.props;
    this.loadData(since, until);
  }

  componentDidUpdate(prevProps) {
    const { since, until } = this.props;
    if (prevProps.since !== since || prevProps.until !== until) {
      this.loadData(since, until);
    }
  }

  render() {
    const { since, until, loadDailyProductStats, statProducts } = this.props;
    return (
      <div>
        <h2 style={{ fontWeight: 'bold', fontSize: '20px' }}># Product Performance</h2>
        <div style={{ display: 'flex' }}>
          {statProducts.map((statProduct, idx) => (
            <AdminProduct 
              key={`statProduct-card-${idx}`}
              since={since}
              until={until}
              statProduct={statProduct}
              loadDailyProductStats={loadDailyProductStats}
            />
          ))}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => this.loadMore()}>더보기</Button>
        </div>
      </div>
    )
  }
}