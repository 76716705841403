import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadEntities } from 'react-shared';
import { compose } from 'recompose';
import NumberFormat from 'react-number-format';

import assign from 'lodash/assign';
import { camelizeKeys } from 'humps';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  GridList,
  GridListTile,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import { Spinner } from 'components';
import { textToClipboard } from 'react-shared/util';
import * as adminActions from 'react-shared/adminActions';

const styles = theme => ({
  subMenuTitles: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
  },
  thumbnailArea: {
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'table-cell',
  },
  textField: {
    marginLeft: 10,
    marginRight: 10,
    minHeight: 50,
    width: 680,
  },
  textFieldHalf: {
    marginLeft: 10,
    marginRight: 10,
    minHeight: 50,
    width: 340,
  },
  confirmButton: {
    marginLeft: 10,
    marginRight: 10,
    width: 700,
    marginTop: 5,
    marginBottom: 15,
  },
  dialogPaper: {
    marginTop: 10,
    padding: 10,
    width: 740,
  },
  dialogueGridListTile: {
    cursor: 'pointer',
    width: 240,
    height: 240,
  },
});

class FAQPage extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      faq: { _id: null },
      snackbarMsg: '',
      snackbarOpen: false,
      ongoingRequest: false,
    };
  }

  async componentDidMount() {
    const { adminGetFaq, adminLoadFaqCategories, faqId } = this.props;
    adminLoadFaqCategories();
    if (faqId !== 'new') {
      const faq = await adminGetFaq({ faqId });
      const newState = { faq };
      this.setState(newState);
    }
  }

  componentDidUpdate(prevProps) {
    const { faqId } = this.props;
    if (prevProps.faqId !== faqId && faqId === 'new') {
      window.location.reload();
    }
  }

  showSnackbar(message) {
    this.setState({
      snackbarMsg: message,
      snackbarOpen: true,
    });
  }

  handleSubmit(event) {
    const { faq } = this.state;
    const { adminCreateFaq, adminUpdateFaq, faqId, router } = this.props;
    if (!faq.category || !faq.subject || !faq.content) {
      alert('모든 정보를 입력해야 합니다.');
      return;
    }

    if (faqId === 'new') {
      this.setState(
        { ongoingRequest: true },
        () => adminCreateFaq({ faq })
          .catch((e) => this.showSnackbar(`FAQ 추가에 실패했습니다: ${e}`))
          .then(() => {
            this.showSnackbar('FAQ 추가에 성공했습니다.');
          })
          .then(() => router.push('/admin/faq'))
      );
    } else {
      this.setState(
        { ongoingRequest: true },
        () => adminUpdateFaq({ faq })
          .then((updatedFaq) => this.setState({ faq: updatedFaq, ongoingRequest: false }))
          .then(() => this.showSnackbar('FAQ 수정에 성공했습니다.'))
          .catch((e) => this.showSnackbar(`FAQ 수정에 실패했습니다: ${e}`))
          .finally(() => this.setState({ ongoingRequest: false }))
      );
    }
    event.preventDefault();
  }

  render() {
    const { classes, faqId, adminFaqCategories } = this.props;
    const { faq } = this.state;

    return (
      <div className="container" style={{ marginTop: 20 }}>
        <Typography variant="h6">
          {faq._id ? 'FAQ 수정' : 'FAQ 추가'}
        </Typography>
        <Paper className={classes.paper}>
          <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', marginLeft: 10, marginRight: 10, marginBottom: 10 }}>
            <Select
              className={classes.textField}
              value={faq.category || ''}
              onChange={event => {
                faq.category = event.target.value;
                this.setState({ faq });
              }}
            >
              {adminFaqCategories.map(category => (
                <MenuItem value={category.name} key={category._id}>
                  <Typography variant="subheading">{category.name}</Typography>
                </MenuItem>
              ))}
            </Select>
            <TextField
              className={classes.textField}
              type="text"
              label="FAQ 제목을 입력하세요"
              placeholder="FAQ 제목"
              onChange={event => {
                faq.subject = event.target.value;
                this.setState({ faq });
              }}
              value={faq.subject|| ''}
            />
            <TextField
              className={classes.textField}
              type="text"
              label="FAQ 본문을 입력하세요"
              placeholder="FAQ 본문"
              onChange={event => {
                faq.content = event.target.value;
                this.setState({ faq });
              }}
              multiline={true}
              rows={5}
              value={faq.content|| ''}
            />
          </div>
          <div style={{ padding: 5 }}></div>
        </Paper>
        <div style={{ padding: 5 }}></div>
        <Paper className={classes.paper}>
          <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
            <Button
              className={classes.confirmButton}
              onClick={this.handleSubmit}
              variant="contained"
              disabled={this.state.ongoingRequest}
            >
              FAQ
              {faq._id ? '변경' : '추가'}
            </Button>
          </div>
        </Paper>

        {<Snackbar
          autoHideDuration={2000}
          className={classes.snackbar}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
        />}
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect((state, ownProps) => ({
    auth: state.auth,
    faqId: ownProps.params.faqId,
    ...loadEntities(state, 'adminFaqCategories', 'adminFaqCategories'),
  }), adminActions),
)(FAQPage);
