import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
} from '@material-ui/core';
import BorderColor from '@material-ui/icons/BorderColor';
import FileCopy from '@material-ui/icons/FileCopy';
import InstagramProfile from '../InstagramProfile';
import Spinner from '../Spinner';

export default class InstagramMediaDialog extends Component {
  render() {
    const { classes, cols, isFetching, medias, open, username,
      onCopyClicked, onMediaClicked, onCloseButtonClicked, onScroll } = this.props;
    return (
      <Dialog open={open} maxWidth="md" onScroll={onScroll}>
        <DialogTitle>
          {username
            && <InstagramProfile instagramId={username} style={{ width: 100, height: 100 }} />}
          {username}
        </DialogTitle>
        <GridList cols={cols} ref={c => { this.instagramMediaDialogContent = c; }} cellHeight={240}>
          {medias.map(media => (
            <GridListTile key={media.id} className={classes.gridListTile}>
              <img src={media.images.standard_resolution.url} alt="" />
              <GridListTileBar
                actionIcon={(
                  <span>
                    <IconButton
                      onClick={() => onCopyClicked(media)}
                      classes={{ colorPrimary: classes.gridListTileBarIconButton }}
                      color="primary"
                    >
                      <FileCopy />
                    </IconButton>
                    <IconButton
                      onClick={() => onMediaClicked(media.id)}
                      classes={{ colorPrimary: classes.gridListTileBarIconButton }}
                      color="primary"
                    >
                      <BorderColor />
                    </IconButton>
                  </span>
                )}
              />
            </GridListTile>
          ))}
        </GridList>
        {isFetching && <Spinner />}
        <DialogActions>
          <Button onClick={onCloseButtonClicked}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

/*
InstagramMediaDialog.propTypes = {
  classes: PropTypes.object,
  cols: PropTypes.number,
  isFetching: PropTypes.bool,
  medias: PropTypes.array,
  onCopyClicked: PropTypes.func,
  onMediaClicked: PropTypes.func,
  onCloseButtonClicked: PropTypes.func,
  open: PropTypes.bool,
  username: PropTypes.string.isRequired,
};

InstagramMediaDialog.defaultProps = {
  open: false,
  cols: 3,
  medias: [],
  isFetching: false,
};
*/
