import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadEntities } from 'react-shared';
import { compose } from 'recompose';
import NumberFormat from 'react-number-format';

import assign from 'lodash/assign';
import { camelizeKeys } from 'humps';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  GridList,
  GridListTile,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import { Spinner } from 'components';
import { textToClipboard } from 'react-shared/util';
import * as adminActions from 'react-shared/adminActions';

const styles = theme => ({
  subMenuTitles: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
  },
  thumbnailArea: {
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'table-cell',
  },
  textField: {
    marginLeft: 10,
    marginRight: 10,
    minHeight: 50,
    width: 680,
  },
  textFieldHalf: {
    marginLeft: 10,
    marginRight: 10,
    minHeight: 50,
    width: 340,
  },
  confirmButton: {
    marginLeft: 10,
    marginRight: 10,
    width: 700,
    marginTop: 5,
    marginBottom: 15,
  },
  dialogPaper: {
    marginTop: 10,
    padding: 10,
    width: 740,
  },
  dialogueGridListTile: {
    cursor: 'pointer',
    width: 240,
    height: 240,
  },
});

class NoticePage extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      notice: { _id: null },
      snackbarMsg: '',
      snackbarOpen: false,
      ongoingRequest: false,
    };
  }

  async componentDidMount() {
    const { adminGetNotice, noticeId } = this.props;
    if (noticeId !== 'new') {
      const notice = await adminGetNotice({ noticeId });
      const newState = { notice };
      this.setState(newState);
    }
  }

  componentDidUpdate(prevProps) {
    const { noticeId } = this.props;
    if (prevProps.noticeId !== noticeId && noticeId === 'new') {
      window.location.reload();
    }
  }

  showSnackbar(message) {
    this.setState({
      snackbarMsg: message,
      snackbarOpen: true,
    });
  }

  handleSubmit(event) {
    const { notice } = this.state;
    const { adminCreateNotice, adminUpdateNotice, noticeId, router } = this.props;
    if (!notice.subject || !notice.content) {
      alert('모든 정보를 입력해야 합니다.');
      return;
    }

    if (noticeId === 'new') {
      this.setState(
        { ongoingRequest: true },
        () => adminCreateNotice({ notice })
          .catch((e) => this.showSnackbar(`공지사항 추가에 실패했습니다: ${e}`))
          .then(() => {
            this.showSnackbar('공지사항 추가에 성공했습니다.');
          })
          .then(() => router.push('/admin/notice'))
      );
    } else {
      this.setState(
        { ongoingRequest: true },
        () => adminUpdateNotice({ notice })
          .then((updatedNotice) => this.setState({ notice: updatedNotice, ongoingRequest: false }))
          .then(() => this.showSnackbar('공지사항 수정에 성공했습니다.'))
          .catch((e) => this.showSnackbar(`공지사항 수정에 실패했습니다: ${e}`))
          .finally(() => this.setState({ ongoingRequest: false }))
      );
    }
    event.preventDefault();
  }

  render() {
    const { classes, noticeId } = this.props;
    const { notice } = this.state;

    return (
      <div className="container" style={{ marginTop: 20 }}>
        <Typography variant="h6">
          {notice._id ? '공지사항 수정' : '공지사항 추가'}
        </Typography>
        <Paper className={classes.paper}>
          <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', marginLeft: 10, marginRight: 10, marginBottom: 10 }}>
            <TextField
              className={classes.textField}
              type="text"
              label="공지사항 제목을 입력하세요"
              placeholder="공지사항 제목"
              onChange={event => {
                notice.subject = event.target.value;
                this.setState({ notice });
              }}
              value={notice.subject|| ''}
            />
            <TextField
              className={classes.textField}
              type="text"
              label="공지사항 본문을 입력하세요"
              placeholder="공지사항 본문"
              onChange={event => {
                notice.content = event.target.value;
                this.setState({ notice });
              }}
              multiline={true}
              rows={10}
              value={notice.content|| ''}
            />
          </div>
          <div style={{ padding: 5 }}></div>
        </Paper>
        <div style={{ padding: 5 }}></div>
        <Paper className={classes.paper}>
          <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
            <Button
              className={classes.confirmButton}
              onClick={this.handleSubmit}
              variant="contained"
              disabled={this.state.ongoingRequest}
            >
              공지사항
              {notice._id ? '변경' : '추가'}
            </Button>
          </div>
        </Paper>

        {<Snackbar
          autoHideDuration={2000}
          className={classes.snackbar}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
        />}
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect((state, ownProps) => ({
    auth: state.auth,
    noticeId: ownProps.params.noticeId,
  }), adminActions),
)(NoticePage);
