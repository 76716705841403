import React from 'react';
import { View, StyleSheet, Animated } from 'react-native';
import { connect } from 'react-redux';

import { selectPostDate, actions } from 'shared';

import { Constants, Styles } from '../../../constants';
import { generateFirebaseEvent } from '../../../utils';
import { openOutlinkPage } from '../../OutlinkGatewayPage';

import StyleFeedDetail from './StyleFeedDetail';


const count = 4;


export class StyleDetailAllScreen extends React.Component {
  state = {
    inFocusedScreen: true,
    refreshing: false,
  };

  componentDidMount() {
    const {
      logEventPageview, userId,
    } = this.props;

    this.loadData(false);

    logEventPageview({ page_name: 'StyleDetailAll', page_key: userId });
  }

  componentWillUnmount() {
  }

  loadData = async (nextPage, query) => {
    const { loadStylePostListByUser } = this.props;
    const userId = navigation.getParam('userId');
    try {
      // eslint-disable-next-line no-unused-vars
      const ret = await loadStylePostListByUser(nextPage, userId);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('StyleDetailAllScreen loadStylePostByUser error=', error);
    }
  }

  onPressProduct = product => {
    const { router, historySwitch, auth } = this.props;
    let { post } = this.props;
    const { token } = auth;

    const retail = product.retails[product.defaultRetailIdx];
    const link = retail.orgLink || retail.link;

    // eslint-disable-next-line no-prototype-builtins
    if (!post && product.hasOwnProperty('post')) {
      // eslint-disable-next-line prefer-destructuring
      post = product.post;
    }

    // TODO: Post 정보가 없는 경우 이벤트를 보내지 않아도 괜찮은지 확인 필요
    if (post && post.owner) {
      generateFirebaseEvent({ product, post, where: 'post' });
    }

    if (product.godoGoodsNo) {
      router.push(`/product/${product.godoGoodsNo}`);
      return;
    }

    openOutlinkPage(product, post, historySwitch, token, router);
  }

  scrollToTop() {
    if (this.listRef) {
      this.listRef.scrollToIndex({ index: 0 });
    }
  }

  renderItem = ({ item: post }) => {
    const { auth, historySwitch } = this.props;
    const { inFocusedScreen } = this.state;

    return (
      <StyleFeedDetail
        onPressHeartist={userId => {
          //navigation.push({
          //  routeName: 'Heartist',
          //  params: { userId, viewMode: getViewModeForUserId(userId) },
          //  key: `Heartist.${userId}`,
          //})
        }}
        onPressProduct={this.onPressProduct}
        post={post}
        postDate={selectPostDate('postScreen')}
        historySwitch={historySwitch}
        auth={auth}
        inFocusedScreen={inFocusedScreen}
        showBottomLine={false}
      />
    );
  }

  render() {
    const {
      pagination, auth, historySwitch, posts,
    } = this.props;
    const {
      refreshing,
    } = this.state;
    console.log(pagination);

    return (
      <View style={[styles.container, Styles.screenHeaderSpace]}>
        <Animated.FlatList
          ref={ref => { this.listRef = ref; }}
          data={Object.values(posts)}
          keyExtractor={post => post._id} // eslint-disable-line no-underscore-dangle
          renderItem={this.renderItem}
          onEndReached={() => pagination.nextPageUrl !== null && this.loadData(true, { count })}
          onEndReachedThreshold={0.5}
          onRefresh={() => {
            if (!refreshing) {
              this.setState({ refreshing: true });
              this.loadData(false, { count }).then(this.setState({ refreshing: false }));
            }
          }}
          refreshing={refreshing}
          removeClippedSubviews={Platform.OS === 'android'}
          contentContainerStyle={styles.contentContainer}
          scrollEventThrottle={16}
          {...Constants.scrollViewCommonProps}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginBottom: Constants.innerViewBottomSpace,
  },
  contentContainer: {
  },
});

export default connect(
  (state, {}) => {
    const { entities: { heartists } } = state;
    const userId = navigation.getParam('userId');

    return {
      auth: state.auth,
      historySwitch: state.settings.historySwitch,
      userId,s
      //posts: state.entities.stylePosts[userId] || [],
      //pagination: state.pagination.stylePosts[userId],
    };
  },
  actions,
)(StyleDetailAllScreen);
