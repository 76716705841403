import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as adminActions from 'react-shared/adminActions';
import { get, orderBy } from 'lodash';

const styles = {
  table: {
    margin: "auto",
    width: "calc(100% - 40px)",
    marginTop: 20,
    marginBottom: 20,
  },
  table_cell: {
    padding: "8px 15px",
    height: 43,
    textAlign: 'center',
    border: '1px solid',
    fontSize: 12,
  },
  table_header: {
    background: '#F6F6F6',
  },
  table_body: {
  },
  table_column_no: {
    width: 60,
  },
  table_column_pageview_godo_count: {
    width: 120,
  },
  table_column_click_count: {
    width: 100,
  },
  table_column_button: {
    width: 120,
  },
}

class StatsClickEventList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortBy: 'pageview',
    };
  }

  componentDidMount() {
    const { startDate, endDate } = this.props;
    this.loadData(startDate, endDate);
  }

  componentDidUpdate(prevProps, prevState) {
    const { kind, startDate, endDate } = this.props;
    if (prevProps.startDate !== startDate || prevProps.endDate !== endDate || prevProps.kind !== kind) {
      this.loadData(startDate, endDate);
    }
  }

  loadData(startDate, endDate) {
    const {
      getPageviewCountOverall,
      kind,
    } = this.props;
    getPageviewCountOverall(startDate, endDate, kind);
  }

  renderItemType(item) {
    const tokens = item.name.split('.');
    const type = tokens[0];
    let itemType = '';

    switch (type) {
      case 'exhibition':
        itemType = '기획전';
        break;
      case 'preorder':
        itemType = '프리오더';
        break;
      default:
        break;
    }

    return itemType;
  }

  renderItemName(item) {
    const tokens = item.name.split('.');
    const type = tokens[0];
    const name = tokens[1]

    switch (tokens[0]) {
      case 'exhibition':
        return (
          <a href={`/admin/mdCurations/${name}`} target='_blank' title={item.description}>{item.title}</a>
        );
      case 'preorder':
        return (
          <a href={`/admin/preorderExhibitions/${name}`} target='_blank' title={item.description}>{item.title}</a>
        );
      case 'restock_alarm_on':
      case 'restock_alarm_off':
      case 'cart_add':
      case 'cart_del':
      case 'wishlist_add':
      case 'wishlist_del':
      case 'product':
        return (
          <a href={`/product/${name}`} target='_blank' title={item.description}>{item.title || item.name}</a>
        );
      default:
        return name;
    }
  }

  render() {

    const {
      pageviewCountOverall,
      kind,
    } = this.props;

    if (!pageviewCountOverall) {
      return null;
    }

    const styleTableHeader = {
      ...styles.table_cell,
      ...styles.table_header,
    };

    const styleTableBody = {
      ...styles.table_cell,
      ...styles.table_body,
    }

    let items = Object.values(pageviewCountOverall).map(item => {
      const ret = {
        ...item,
        name: item.key,
      };

      if (typeof ret.clickCount === 'undefined') {
        ret.clickCount = 0;
      }

      if (typeof ret.pageviewCount === 'undefined') {
        ret.pageviewCount = 0;
      }

      if (typeof ret.godoViewCnts !== 'undefined') {
        ret.godoViewCnt3 = ret.godoViewCnts['3'] || 0;
        ret.godoViewCnt15 = ret.godoViewCnts['15'] || 0;
      } else {
        ret.godoViewCnt3 = 0;
        ret.godoViewCnt15 = 0;
      }

      return ret;
    });

    let {
      sortBy
    } = this.state;

    let showPageview = false;
    let showGodoPageview = false;
    switch (kind) {
      case 'product':
        showGodoPageview = true;
      case 'exhibition':
        showPageview = true;
        break;

      default:
        sortBy = 'click';
        break;
    }

    switch (sortBy) {
      case 'pageview':
        items = orderBy(items, ['pageviewCount', 'clickCount'], ['desc', 'desc']);
        break;

      case 'pageviewGodo3':
        items = orderBy(items, ['godoViewCnt3', 'pageviewCount', 'clickCount'], ['desc', 'desc', 'desc']);
        break;

      case 'pageviewGodo15':
        items = orderBy(items, ['godoViewCnt15', 'pageviewCount', 'clickCount'], ['desc', 'desc', 'desc']);
        console.log('pageviewGodo15 items=', items);
        break;

      case 'click':
        items = orderBy(items, ['clickCount', 'pageviewCount'], ['desc', 'desc']);  
        break;
    }

    const onPressPageview = () => {
      const newState = {
        sortBy: 'pageview',
      };

      this.setState(newState);
    };

    const onPressPageviewGodo3 = () => {
      const newState = {
        sortBy: 'pageviewGodo3',
      };

      this.setState(newState);
    };

    const onPressPageviewGodo15 = () => {
      console.log('onPressPageviewGodo15');
      const newState = {
        sortBy: 'pageviewGodo15',
      };

      this.setState(newState);
    };

    const onPressClick = () => {
      const newState = {
        sortBy: 'click',
      };

      this.setState(newState);
    };


    return (
      <div>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={{
                ...styleTableHeader,
                ...styles.table_column_no,
              }} rowSpan="2">
                No
              </th>
              { showPageview && (
                <th style={{
                  ...styleTableHeader,
                  ...styles.table_column_click_count,
                  }} rowSpan="2">
                  종류
                </th>
              )}
              <th style={styleTableHeader} rowSpan="2">
                페이지
              </th>
              { showPageview && (
                <th style={{
                  ...styleTableHeader,
                  ...styles.table_column_click_count,
                  }} rowSpan="2">
                  <a href='#' onClick={onPressPageview}>조회수</a>
                </th>
              )}
              <th style={{
                ...styleTableHeader,
                ...styles.table_column_click_count,
                }} rowSpan="2">
                <a href='#' onClick={onPressClick}>클릭수</a>
              </th>
              { showGodoPageview && (
                <th style={{
                  ...styleTableHeader,
                  ...styles.table_column_click_count,
                  }} colSpan="2">
                  고도몰 조회수
                </th>
              )}
              <th style={{
                ...styleTableHeader,
                ...styles.table_column_button,
              }} rowSpan="2">
              </th>

            </tr>
            <tr>
              { showGodoPageview && (
                <th style={{
                  ...styleTableHeader,
                  ...styles.table_column_click_count,
                  }}>
                  <a href='#' onClick={onPressPageviewGodo3}>최근 3일</a>
                </th>
              )}
              { showGodoPageview && (
                <th style={{
                  ...styleTableHeader,
                  ...styles.table_column_click_count,
                  }}>
                  <a href='#' onClick={onPressPageviewGodo15}>최근 15일</a>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td style={styleTableBody}>
                  {index + 1}
                </td>
                { showPageview && (
                  <td style={styleTableBody}>
                    {this.renderItemType(item)}
                  </td>
                )}
                <td style={styleTableBody}>
                  {this.renderItemName(item)}
                </td>
                { showPageview && (
                  <td style={{ ...styleTableBody, textAlign: 'right'}}>
                    {item.pageviewCount.toLocaleString() || '-'}
                  </td>
                )}
                <td style={{ ...styleTableBody, textAlign: 'right'}}>
                  {item.clickCount.toLocaleString() || '-'}
                </td>
                { showGodoPageview && (
                  <td style={{ ...styleTableBody, textAlign: 'right'}}>
                    {item.godoViewCnt3.toLocaleString() || '-'}
                  </td>
                )}
                { showGodoPageview && (
                  <td style={{ ...styleTableBody, textAlign: 'right'}}>
                    {item.godoViewCnt15.toLocaleString() || '-'}
                  </td>
                )}
                <td style={styleTableBody}>
                  <a href={`/admin/stats/click/${item.key}`}>자세히보기</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}


export default connect(
  (state, props) => {
    const { kind, startDate, endDate } = props;
    const clickCountOverall = get(state.admin.clickCountOverall, `${kind}[${startDate}~${endDate}]`)
    const pageviewCountOverall = get(state.admin.pageviewCountOverall, `${kind}[${startDate}~${endDate}]`)

    return {
      clickCountOverall,
      pageviewCountOverall,
    };
  },
  adminActions,
) (StatsClickEventList);
