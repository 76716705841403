import merge from 'lodash/merge';
import url from 'url';
import set from 'lodash/set';
import endsWith from 'lodash/endsWith';

import config from '../config';

if (typeof fetch === 'undefined') {
  // TODO: Delete require if not indispensable.
  // eslint-disable-next-line global-require, no-undef
  require('isomorphic-fetch');
}

function getGodoConfig() {
  let godoHost = config.get('godoHost');
  let godoMallId = config.get('godoMallId');

  if (godoHost === undefined) {
    if (endsWith(location.hostname, 'dev.heartit.kr')) {
      godoHost = 'wehavit1.godomall.com';
      godoMallId = '859006';
    } else if (endsWith(location.hostname, 'stage.heartit.kr')) {
      godoHost = 'heartitr9527.godomall.com';
      godoMallId = '714370';
    } else if (endsWith(location.hostname, 'heartit.kr')) {
      godoHost = 'heartitr9527.godomall.com';
      godoMallId = '714370';
    } else {
      godoHost = 'wehavit1.godomall.com';
      godoMallId = '859006';
    }
  }
  return { godoHost, godoMallId };
}

async function sendGodoVisit() {
  const { godoHost, godoMallId } = getGodoConfig();

  const headers = {
    Accept: '*/*',
    'Accept-Language': 'ko-KR,ko;q=0.9,en-US;q=0.8,en;q=0.7',
    'Content-Type': 'application/json; charset=UTF-8',
    Origin: `http://${godoHost}`,
    Referer: `http://${godoHost}`,
    'X-Requested-With': 'XMLHttpRequest',
  };
  const payload = {
    'base_time': new Date().toISOString(),
    country: 'kr',
    domain: godoHost,
    'mall_id': godoMallId,
    refer: `http://${godoHost}`,
    solution: 'G5',
    url: '',
    'user_id': null,
  };

  const targetUrl = 'https://collector-statistics.nhn-commerce.com/http.msg';
  const method = 'POST';
  const response = await fetch(targetUrl, {
    method,
    headers,
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    console.error(response);
  }
}

async function doRequest(params) {
  // console.log('doRequest', params);
  const {
    state, bearerToken, credentials, resolve, method, endpoint, body,
  } = params;

  const bearer = bearerToken || state.auth.token;
  const extraHeaders = global.extraHeaders || {};
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: bearer ? `Bearer ${bearer}` : '',
    'heartit-agent': extraHeaders['heartit-agent'] || 'app',
    'heartit-agent-version': global.appVersion || 'web',
    'heartit-agent-os': global.platformOS || 'web',
  };
  if (config.get('userAgent')) {
    headers['User-Agent'] = config.get('userAgent');
  }

  const apiRoot = config.get('apiRoot') || 'api.heartit.kr';
  const targetUrl = url.resolve(apiRoot, resolve(endpoint));
  // console.log('createFetchActionWithShopAuth targetUrl=', targetUrl);


  const response = await fetch(targetUrl, {
    method,
    headers,
    credentials,
    body: JSON.stringify(body),
  });
  // console.log('createFetchActionWithShopAuth response=', response);

  const json = await response.json();

  return { response, json };
}

async function doRequestWithAuth(params, retry = 1) {
  const ret = await doRequest(params);
  const { response, json } = ret;
  // console.log('doRequestWithAuth ret=', ret);

  if (!response.ok) {
    return ret;
  }

  if (json.success === true) {
    return ret;
  }

  if (retry <= 0) {
    return ret;
  }

  if (json.errorCode === 400) {
    // 고도몰 인증 세션 만료 오류
    const paramsForAuth = {
      ...params,
      method: 'GET',
      endpoint: '/api/v2/shop/auth/authenticate',
      body: undefined,
    };

    const {
      response: authResp,
      json: authJson,
    } = await doRequest(paramsForAuth);
    // console.log('doRequestWithAuth auth response=', authResp);
    // console.log('doRequestWithAuth auth json=', authJson);

    if (!authResp.ok) {
      return ret;
    }

    if (authJson.success !== true) {
      return ret;
    }

    const { sessionId } = authJson.data;

    const { dispatch } = params;
    dispatch({
      type: 'AUTHENTICATE_SHOP_AUTH',
      payload: set({}, 'entities.shop.sessionId', sessionId),
    });

    return doRequestWithAuth(params, retry - 1);
  }

  return ret;
}


export default function createFetchActionWithShopAuth(options) {
  const {
    bailout,
    bearerToken,
    body,
    credentials,
    endpoint,
    method = 'GET',
    transform,
    type,
    doDispatch = true,
  } = options;
  const { request, success = {}, failure = {} } = options;

  return async (dispatch, getState) => {
    const state = getState();
    function resolve(obj) {
      return typeof obj === 'function' ? obj(state) : obj;
    }

    if (bailout && bailout(state)) { return null; }

    const reqWithType = Object.assign({ type }, request);
    if (request && doDispatch) { dispatch(reqWithType); }
    sendGodoVisit();

    const paramForReqeust = {
      state,
      bearerToken,
      credentials,
      resolve,
      method,
      endpoint,
      body,
      dispatch,
    };
    const {
      response,
      json,
    } = await doRequestWithAuth(paramForReqeust);

    if (!response.ok) {
      if (doDispatch) {
        dispatch(merge({
          type,
          error: json,
        }, resolve(failure)));
      }
      return Promise.reject(json);
    }

    if (doDispatch) {
      dispatch(merge({
        type,
        payload: transform ? transform({ json, response, state }) : json,
      }, resolve(success)));
    }
    return Promise.resolve(json);
  };
}
