import merge from 'lodash/merge';
import url from 'url';
import config from '../config';

if (typeof fetch === 'undefined') {
  // TODO: Delete require if not indispensable.
  // eslint-disable-next-line global-require, no-undef
  require('isomorphic-fetch');
}

export default function createFetchAction(options) {
  const {
    bailout,
    bearerToken,
    body,
    credentials,
    endpoint,
    method = 'GET',
    transform,
    type,
    doDispatch = true,
  } = options;
  const { request, success = {}, failure = {} } = options;
  return (dispatch, getState) => {
    const state = getState();

    const bearer = bearerToken || state.auth.token;
    const extraHeaders = global.extraHeaders || {};
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: bearer ? `Bearer ${bearer}` : '',
      'heartit-agent': extraHeaders['heartit-agent'] || 'app',
      'heartit-agent-version': global.appVersion || 'web',
      'heartit-agent-os': global.platformOS || 'web',
    };
    if (config.get('userAgent')) {
      headers['User-Agent'] = config.get('userAgent');
    }

    function resolve(obj) {
      return typeof obj === 'function' ? obj(state) : obj;
    }

    if (bailout && bailout(state)) { return null; }

    const reqWithType = Object.assign({ type }, request);
    if (request && doDispatch) { dispatch(reqWithType); }

    const apiRoot = config.get('apiRoot') || 'api.heartit.kr';

    //console.log(url.resolve(apiRoot, resolve(endpoint)));
    // TODO: Define fetch explicitly
    // eslint-disable-next-line no-undef
    return fetch(url.resolve(apiRoot, resolve(endpoint)), {
      method,
      headers,
      credentials,
      body: JSON.stringify(body),
    }).then(response => { // eslint-disable-line arrow-body-style
      return response.json().then(json => ({ json, response }));
    }).then(({ json, response }) => {
      if (!response.ok) {
        if (doDispatch) {
          dispatch(merge({
            type,
            error: json,
          }, resolve(failure)));
        }
        return Promise.reject(json);
      }
      if (doDispatch) {
        dispatch(merge({
          type,
          payload: transform ? transform({ json, response, state }) : json,
        }, resolve(success)));
      }
      return Promise.resolve(json);
    });
  };
}
