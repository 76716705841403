import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View, Dimensions } from 'react-native';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

// eslint-disable-next-line import/no-extraneous-dependencies
import { isEmpty } from 'shared/util';
import PostThumbnail from '../../../nativeComponents/PostThumbnail';
import LoadMoreButton from '../../../nativeComponents/LoadMoreButton';
import Spinner from '../../../components/Spinner';
import Constants from '../../../constants/Constants';


const initialLayout = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
};
const count = 24;
const columnCount = 3;
const layoutHorizontalPadding = 20;
const itemSpacing = 4 / 2;
const postSize = parseInt((initialLayout.width - layoutHorizontalPadding * 2) / columnCount - itemSpacing, 10);
const topMargin = 20;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: layoutHorizontalPadding,
    paddingBottom: layoutHorizontalPadding,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingEnd: 5,
    paddingTop: 12,
    paddingBottom: 12,
  },
  headerButton: {
    marginStart: 20,
    borderRadius: 4,
  },
  postThumbnail: {
    width: postSize,
    height: postSize,
    backgroundColor: 'transparent',
  },
  itemSeparator: {
    padding: itemSpacing,
  },
  loadMoreContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 20,
  },
});


export default class TabFeed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      // refreshing: false,
    };
  }

  loadData = nextPage => {
    const { loadRecentPosts } = this.props;
    this.setState({ loading: true });
    if (loadRecentPosts) {
      loadRecentPosts(nextPage, { count })
        .then(this.setState({ loading: false }));
    }
  }

  renderPost = post => {
    const {
      onPressPostThumbnail, navigation,
    } = this.props;


    if (isEmpty(post) || post.empty) {
      return (
        <GridListTile key={new Date().getTime()}>
          <View
            style={styles.postThumbnail}
          />
        </GridListTile>
      );
    }

    return (
      <GridListTile key={post._id}>
        <PostThumbnail
          containerStyle={styles.postThumbnail}
          post={post}
          onPress={onPressPostThumbnail}
          navigation={navigation}
        />
      </GridListTile>
    );
  };

  onPressSorting = () => {
  };

  onPressFilter = () => {
  };

  renderLoadMore() {
    const onPress = () => {
      const {
        pagination,
      } = this.props;

      if (pagination.nextPageUrl) {
        this.loadData(true);
      }
    };

    return (
      <View style={styles.loadMoreContainer}>
        <LoadMoreButton onPress={onPress} />
      </View>
    );
  }

  render() {
    const {
      posts, pagination,
      largeHeaderHeight,
      smallHeaderHeight,
    } = this.props;
    const {
      loading,
      // refreshing,
    } = this.state;

    const data = [
      ...posts,
    ];

    const minHeightLarge = initialLayout.height - smallHeaderHeight - topMargin;
    const minHeightSmall = initialLayout.height - largeHeaderHeight - topMargin;
    const minHeight = (minHeightSmall > (initialLayout.height / 2)) ? minHeightSmall : minHeightLarge;

    const containerStyle = {
      minHeight,
    };

    return (
      <View style={containerStyle}>
        <GridList
          cellHeight={postSize}
          cols={columnCount}
        >
          <GridListTile key="header" cols={3} style={{ height: 20 }}>
          </GridListTile>
          {data.map(post => this.renderPost(post))}
        </GridList>
        {(!loading && pagination.nextPageUrl) ? (
          this.renderLoadMore()
        ) : (
          <View style={{ height: Constants.bottomSpaceForTabBar + 20 }} />
        )}
        {loading ? (<Spinner />) : (null)}
      </View>
    );
  }
}
