import React from 'react';
import {
  StyleSheet, View, TextInput,
  Text,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';
import { isEmail } from 'validator';
import { Snackbar } from '@material-ui/core';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';

import Button from '../../../nativeComponents/Button';

import Styles from '../../../constants/Styles';
import Colors from '../../../constants/Colors';


const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    flex: 1,
  },
  disabled: {
    backgroundColor: '#cccccc',
    borderColor: 'lightgrey',
    marginTop: 15,
    width: '100%',
  },
  inputContainer: {
    alignItems: 'center',
  },
  inputText: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    paddingHorizontal: 16,
    paddingTop: 17,
    paddingBottom: 16,
    backgroundColor: Colors.semiGray,
    height: 52,
  },
  inputBorder: {
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
  },
  loginButton: {
    backgroundColor: 'black',
    marginTop: 15,
    width: '100%',
  },
  findPasswordText: {
    color: 'white',
  },
  footer: {
    marginTop: 50,
    marginBottom: 20,
  },
  bottomButtonContainer: {
    ...Styles.buttonDark,
  },
  bottomButton: {
    backgroundColor: 'transparent',
    height: 48,
    padding: 0,
    borderWidth: 0,
  },
  bottomButtonText: {
    fontSize: 14,
    height: 17,
    lineHeight: 17,
    marginTop: 16,
    marginBottom: 15,
    ...Styles.appleSDGothicNeoBold,
    ...Styles.buttonDarkText,
  },
  resultContainer: {
    flex: 1,
    paddingTop: 27,
  },
  resultText: {
    marginTop: 5,
    marginStart: 10,
    ...Styles.appleSDGothicNeoBold,
    fontSize: 22,
    height: 54,
    lineHeight: 27,
  },
});

class FindPasswordTab extends React.Component {
  state = {
    email: '',
    emailSent: false,
    snackbarMsg: '',
    snackbarOpen: false,
  };

  emailInputRef = null;

  componentDidMount() {
    if (this.emailInputRef) {
      this.emailInputRef.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.focused && this.props.focused) {
      if (this.emailInputRef) {
        this.emailInputRef.focus();
      }
    }
  }

  async handleFindPassword() {
    const { sendFindPassword } = this.props;
    const { email } = this.state;

    try {
      await sendFindPassword(email);
      this.setState({ emailSent: true });
    } catch (e) {
      let msg = '';
      if (e.status === 404) {
        msg = '가입되지 않은 이메일입니다. 확인 후 다시 시도하세요.';
      } else {
        msg = '비밀번호 찾기 메일을 보내는데 실패했습니다. 잠시 후 다시 시도하세요.';
      }
      this.openSnackbar(msg);
    }
  }

  openSnackbar = msg => {
    this.setState({
      snackbarMsg: msg,
      snackbarOpen: true,
    });
  }

  createInputCheckMsg() {
    const { email } = this.state;
    let msg;
    if (!email || email === '') {
      msg = '이메일을 입력하세요.';
    } else if (!isEmail(email)) {
      msg = '이메일 형식을 확인하세요.';
    }
    return msg;
  }

  async handleAll() {
    const { email } = this.state;
    const msg = this.createInputCheckMsg();
    if (msg) {
      this.openSnackbar(msg);
    }
    if (email && isEmail(email)) {
      this.handleFindPassword();
    }
  }

  renderInputForm() {
    const { email, snackbarMsg, snackbarOpen } = this.state;

    return (
      <View style={styles.container}>
        <TextInput
          ref={ref => { this.emailInputRef = ref; }}
          autoCapitalize="none"
          keyboardType="email-address"
          onChangeText={val => this.setState({ email: val })}
          placeholder="이메일"
          placeholderTextColor={Colors.placeholderColor}
          style={[
            styles.inputBorder,
            styles.inputText,
            { outline: 'none' },
          ]}
          value={email}
          onSubmitEditing={() => this.handleAll()}
        />
        <View style={styles.footer}>
          <View style={styles.bottomButtonContainer}>
            <Button
              containerStyle={styles.bottomButton}
              underlayColor={Colors.touchEffectColorBlack}
              onPress={() => this.handleAll()}
              title="확인"
              titleStyle={styles.bottomButtonText}
            />
          </View>
        </View>

        <Snackbar
          autoHideDuration={2000}
          message={snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={snackbarOpen}
          style={{ textAlign: 'center' }}
        />
      </View>
    );
  }

  render() {
    const { emailSent } = this.state;
    const { router } = this.props;

    if (emailSent) {
      return (
        <View style={styles.resultContainer}>
          <Text style={styles.resultText}>
            {'비밀번호 찾기 메일을 보냈습니다.\n메일을 확인하세요.'}
          </Text>

          <View style={styles.footer}>
            <View style={styles.bottomButtonContainer}>
              <Button
                containerStyle={styles.bottomButton}
                underlayColor={Colors.touchEffectColorBlack}
                onPress={() => router.push('/accounts/login')}
                title="로그인 페이지로"
                titleStyle={styles.bottomButtonText}
              />
            </View>
          </View>
        </View>
      );
    }

    return this.renderInputForm();
  }
}


export default connect(
  state => ({ auth: state.auth }),
  actions,
)(FindPasswordTab);
