import React from 'react';
import {
  StyleSheet, Text, TouchableHighlight, View,
} from 'react-native';

import CustomDialog from '../components/CustomDialog';

import InstagramProfileImage from './InstagramProfileImage';
import CheckIcon from '../icons/CheckIcon';

import Styles from '../constants/Styles';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';


export default class Heartist extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  onPressFollow() {
    const { auth, followUser, unfollowUser, user } = this.props;
    const { token } = auth;

    if (!auth || !token) {
      this.setState({ dialogOpen: true });
      return;
    }

    if (user.following) {
      return unfollowUser(user._id);
    }
    return followUser(user._id);
  }

  onPressInsta() {
    const { user } = this.props;
    window.open(`https://www.instagram.com/${user.username}`);
  }

  // eslint-disable-next-line class-methods-use-this
  getFollowerCount() {
    const { user } = this.props;
    const followers = user.instagramFollowers;

    /*
    if (followers > 100000000) {
      return `${(followers / 100000000).toFixed(1)}억`;
    }

    if (followers > 10000) {
      return `${(followers / 10000).toFixed(1)}만`;
    }
    */

    if (followers > 1000) {
      return `${(followers / 1000).toFixed(1)}k`;
    }

    return followers;
  }

  getHeartistSize = () => {
    const { user } = this.props;
    if (!user.creatorProfile) {
      return null;
    }

    const height = user.creatorProfile.height || '';
    const footSize = user.creatorProfile.footSize || '';
    const topWearSize = user.creatorProfile.topWearSize || '';
    const bottomWearSize = user.creatorProfile.bottomWearSize || '';

    if (height && footSize && topWearSize && bottomWearSize) {
      return {
        tall: `${height}cm`,
        foot: `${footSize}mm`,
        upper: `${topWearSize}`,
        lower: `${bottomWearSize}`,
      };
    }
    return null;
  }

  getHeartistDescription = () => {
    const { user } = this.props;
    return user.oneLineSummary || user.username;
  }

  loadData() {
    const { getFollowStatus, userId } = this.props;
    console.log(userId);
    getFollowStatus(userId);
  }

  renderSize = (label, value, style) => {
    return (
      <View style={[styles.sizeContainer, style]}>
        <Text style={styles.sizeLabel}>
          {label}
        </Text>
        <Text style={styles.sizeValue}>
          {value}
        </Text>
      </View>
    );
  }

  renderDescription = () => {
    const heartistSize = this.getHeartistSize();
    const description = this.getHeartistDescription();

    return (
      <View style={styles.descriptionContainer}>
        <Text style={styles.descriptionText}>
          {description}
        </Text>

        <View style={styles.sizeTotalContainer}>
          {heartistSize && (
            <View style={styles.sizeColContainer}>
              {this.renderSize('키', heartistSize.tall, {width: 70, marginBottom: 5})}
              {this.renderSize('상의', heartistSize.upper, {width: 70})}
            </View>
          )}
          {heartistSize && (
            <View style={[styles.sizeColContainer]}>
              {this.renderSize('신발', heartistSize.foot, {width: 88, marginBottom: 5})}
              {this.renderSize('하의', heartistSize.lower, {width: 88})}
            </View>
          )}
        </View>
      </View>
    );
  }

  render() {
    const { router, user } = this.props;
    const { following } = user;
    const { dialogOpen } = this.state;

    if (!user) {
      return null;
    }

    const followerCount = this.getFollowerCount();
    const followButtonText = (following) ? '팔로잉' : '팔로우';

    return (
      <View style={styles.container}>
        <View style={styles.profileContainer}>
          <InstagramProfileImage username={user.username} style={stylesHtml.profileImage} />
          <View style={styles.profile}>
            <Text style={styles.profileText}>{user.username}</Text>
            <View style={styles.followerContainer}>
              <Text style={styles.followerText}>팔로워</Text>
              <Text style={styles.followerCount}>{followerCount}</Text>
            </View>
          </View>
        </View>

        {this.renderDescription()}

        <View style={styles.buttonsContainer}>
          <View style={(following) ? styles.buttonFollowing : styles.buttonDefault}>
            <TouchableHighlight
              style={styles.button}
              onPress={() => this.onPressFollow()}
              underlayColor={(following) ? Colors.touchEffectColorBlack : Colors.touchEffectColorWhite}
            >
              <View style={styles.buttonContainer}>
                <Text style={[styles.buttonText, following && styles.buttonTextActive]}>
                  {followButtonText}
                </Text>
                {following && <CheckIcon />}
              </View>
            </TouchableHighlight>
          </View>
          <View style={styles.buttonDefault}>
            <TouchableHighlight
              style={styles.button}
              onPress={() => this.onPressInsta()}
              underlayColor={Colors.touchEffectColorWhite}
            >
              <Text style={styles.buttonText}>인스타그램</Text>
            </TouchableHighlight>
          </View>
        </View>

        <CustomDialog
          dialogOpen={dialogOpen}
          key_={user._id}
          description={user.username + ' 하티스트를 팔로우하려면 로그인 하시기 바랍니다.'}
          confirmText="로그인"
          cancelText="확인"
          cancelColor="default"
          confirmColor="primary"
          onCancel={() => {
            this.setState({ dialogOpen: false });
          }}
          onConfirm={() => {
            this.setState({ dialogOpen: false });
            router.push('/accounts/login');
          }}
          onClose={() => {
            this.setState({ dialogOpen: false });
          }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Constants.viewHorizontalPadding,
    paddingBottom: 10,
  },
  profileContainer: {
    flexDirection: 'row',
    marginBottom: 17,
  },
  descriptionContainer: {
    flexDirection: 'column',
    marginBottom: 15,
  },
  sizeTotalContainer: {
    marginTop: 10,
    width: 193,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sizeColContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  sizeContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  sizeLabel: {
    lineHeight: 17,
    ...Styles.appleSDGothicNeoRegular,
    ...Styles.fontSize14,
  },
  sizeValue: {
    lineHeight: 16,
    marginBottom: 1,
    ...Styles.SFUITextMedium,
    ...Styles.fontSize14,
  },
  descriptionText: {
    lineHeight: 17,
    ...Styles.appleSDGothicNeoBold,
    ...Styles.fontSize14,
  },
  profile: {
    flex: 1,
    flexDirection: 'column',
    marginStart: 16,
    marginTop: 14,
    marginBottom: 15,
  },
  profileText: {
    color: 'black',
    marginBottom: 8,
    lineHeight: 24,
    ...Styles.SFUITextSemiBold,
    ...Styles.fontSize20,
  },
  followerContainer: {
    flexDirection: 'row',
    opacity: 1,
  },
  followerText: {
    alignSelf:'flex-end',
    color: 'black',
    lineHeight: 19,
    ...Styles.appleSDGothicNeoRegular,
    ...Styles.fontSize15,
  },
  followerCount: {
    alignSelf:'flex-end',
    marginStart: 6,
    marginBottom: 1,
    color: 'black',
    lineHeight: 18,
    ...Styles.SFUITextSemiBold,
    ...Styles.fontSize15,
  },
  buttonsContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: -3.5,
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  buttonDefault: {
    flex: 1,
    backgroundColor: Colors.semiGray,
    margin: 3.5,
  },
  buttonFollowing: {
    flex: 1,
    backgroundColor: 'black',
    margin: 3.5,
  },
  buttonText: {
    color: 'black',
    paddingTop: 11,
    paddingBottom: 10,
    lineHeight: 17,
    ...Styles.appleSDGothicNeoBold,
    ...Styles.fontSize14,
  },
  buttonTextActive: {
    color: '#fff',
  },
});

const stylesHtml = {
  profileImage: {
    border: `1px solid ${Colors.profileImageBorder}`,
    borderRadius: 40,
    height: 80,
    width: 80,
  },
};
