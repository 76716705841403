import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  loadInstagramAuthUrl,
  loadFacebookAuthUrl,
  loadIntroFeatured,
} from 'react-shared/actions';
import { connect } from 'react-redux';
import { loadEntities } from 'react-shared/util';

const styles = {
  container: {
    padding: '80px 32px 50px',
    textAlign: 'center',
    userSelect: 'none',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, '
                + 'Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
  },
  titleContainer: {
    fontWeight: 'bold',
    margin: '0',
    padding: '0',
    fontSize: '20px',
  },
  p: {
    margin: '0',
    padding: '30px 0 66px',
    fontSize: '15px',
    lineHeight: '25px',
  },
  img: {
    width: '194px',
  },
  hiddenText: {
    display: 'none',
  },
  point1: {
    display: 'inline-block',
    fontWeight: '700',
    color: '#f96f6f',
  },
  point2: {
    display: 'inline-block',
    paddingTop: '30px',
    fontWeight: '700',
  },
  fbjoin: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '480px',
    height: '3em',
    margin: '0 auto',
    border: '1px solid rgb(149, 149, 149)',
    fontSize: '13px',
    color: '#858787',
    textDecoration: 'none',
  },
  fblogin: {
    padding: '3px 0 0 1em',
    fontSize: '13px',
    color: '#6a6a6a',
  },
  fbloginbtn: {
    display: 'inline-block',
    padding: '1em',
    color: '#f96f6f',
    textDecoration: 'none',
  },
};

class IntroPage extends Component {
  constructor(props) {
    super(props);
    this.handleInstagramLogin = this.handleInstagramLogin.bind(this);
    this.handleFacebookLogin = this.handleFacebookLogin.bind(this);
  }

  async handleInstagramLogin() {
    const json = await this.props.loadInstagramAuthUrl();
    window.location.href = json.url;
  }

  async handleFacebookLogin() {
    const json = await this.props.loadFacebookAuthUrl();
    window.location.href = json.url;
  }

  render() {
    return (
      <div style={styles.container}>
        <h1 style={styles.titleContainer}>하트잇 서비스 이용 안내</h1>
        <p style={styles.p}>
          <img
            style={styles.img}
            srcSet="https://img7.heartit.kr/heartit/images/text.png 1x,
                    https://img7.heartit.kr/heartit/images/text_2x.png 2x,
                    https://img7.heartit.kr/heartit/images/text_3x.png 3x"
            src="https://img7.heartit.kr/heartit/images/text.png"
            alt=""
          />
          <span style={styles.hiddenText}>
            <span style={styles.point1}>2020.03.31</span>
              부터
            <br />
              인스타그램으로 로그인이
            <br />
              중단됩니다.
            <br />
            <span style={styles.point2}>History, My Account</span>
            <br />
              페이지 이용을 위해서는
            <br />
            <span style={styles.point2}>페이스북</span>
            <br />
              으로 가입 및 로그인을
            <br />
              부탁드립니다.
          </span>
        </p>
        <a href="#" style={styles.fbjoin} onClick={this.handleFacebookLogin}>
          페이스북으로 가입
        </a>
        <div style={styles.fblogin}>
          이미 회원이신가요?
          <a href="#" style={styles.fbloginbtn} onClick={this.handleFacebookLogin}>페이스북으로 로그인 </a>
        </div>
      </div>
    );
  }
}

IntroPage.propTypes = {
  loadInstagramAuthUrl: PropTypes.func.isRequired,
  loadFacebookAuthUrl: PropTypes.func.isRequired,
};

export default connect(
  (state, ownProps) => ({
    ...loadEntities(state, 'featured', 'posts'),
    location: ownProps.location,
  }),
  { loadIntroFeatured, loadInstagramAuthUrl, loadFacebookAuthUrl }
)(IntroPage);
