import React from 'react';
import { connect } from 'react-redux';
import {
  StyleSheet, Dimensions, View, ScrollView,
} from 'react-native';

import { actions } from 'shared';
import { Constants } from '../../../constants';

import OverlayPage from '../../../components-v2/OverlayPage';


const windowWidth = Dimensions.get('window').width;

const paddingHorizontal = 20;

const styles = StyleSheet.create({
  container: {
  },
  scrollView: {
  },
});


class LegalPolicyOverlayPage extends OverlayPage {
  state = {
    contents: null,
  };

  componentDidMount() {
    const {
      logEventPageview,
    } = this.props;

    this.loadPolicy();

    logEventPageview({ page_name: 'LegalPolicy' });
  }

  async loadPolicy() {
    const { getPolicy, type } = this.props;

    try {
      const ret = await getPolicy(type);
      const text = await ret.text();

      this.setState({ contents: text });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('LegalPolicyPage error', error);
    }
  }

  renderContent() {
    const { contents } = this.state;

    return (
      <View style={styles.container}>
        <ScrollView
          style={styles.scrollView}
          {...Constants.scrollViewCommonProps}
        >
          <div dangerouslySetInnerHTML={{ __html: contents }} />
        </ScrollView>
      </View>
    );
  }
}

export default connect(
  (state) => {},
  actions,
)(LegalPolicyOverlayPage);



