import createFetchAction from '../util/createFetchAction';
import qs from 'qs';


export function getCouponDisabledList() {
  return createFetchAction({
    type: 'GET_COUPON_DISABLED_LIST',
    endpoint: '/api/v2/couponDisabled',
    method: 'GET',
    doDispatch: false,
  });
}
