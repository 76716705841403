import React, { Component } from 'react';
import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TextField,
  Select,
  FormControl,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import moment from 'moment';
import { compose } from 'recompose';

import { Edit, Delete } from '@material-ui/icons';

const styles = theme => ({
  tableCellVersion: {
    maxWidth: 180,
  },
  tableCellEnabled: {
    maxWidth: 120,
  },
  tableCellMandatory: {
    maxWidth: 120,
  },
  tableCellEdit: {
    maxWidth: 200,
  },
  tableCellCreatedAt: {
    maxWidth: 150,
  },
});

export class ListAppVersion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: null,
      selectedAppVersion: {
        version: null,
        mandatory: false,
        enabled: false,
      },
    };
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  componentDidMount() {
    const { loadAppVersions } = this.props;
    loadAppVersions({ nextPage: false })
      .then((appVersions) => this.setState({ appVersions }));
  }

  handleLoadMore(nextPage) {
    const { loadAppVersions } = this.props;
    loadAppVersions({ nextPage });
  }

  handleEditMode(appVersion) {
    const { version, mandatory, enabled } = appVersion;
    this.setState({
      editId: appVersion._id,
      selectedAppVersion: {
        version, mandatory, enabled,
      },
    });
  }

  handleCreateMode() {
    this.setState({
      editId: 'new',
      selectedAppVersion: {
        version: '',
        mandatory: false,
        enabled: false,
      },
    });
  }

  handleDeleteMode(appVersion) {
    this.setState({ editId: null });
    if (confirm(`이 버젼(${appVersion.version})을 삭제하시겠습니까?`)) {
      this.deleteAppVersion(appVersion);
    }
  }

  updateAppVersion(appVersion) {
    const { updateAppVersion } = this.props;
    const { editId } = this.state;
    updateAppVersion({ ...appVersion, _id: editId })
      .then(() => this.setState({ editId: null }));
  }

  deleteAppVersion(appVersion) {
    const { deleteAppVersion } = this.props;
    deleteAppVersion({ appVersion });
  }

  createAppVersion(appVersion) {
    const { createAppVersion } = this.props;
    createAppVersion(appVersion)
      .then(() => this.setState({ editId: null }));
  }

  confirmAppVersion() {
    const { editId, selectedAppVersion } = this.state;
    if (editId === 'new') {
      this.createAppVersion(selectedAppVersion);
    } else {
      this.updateAppVersion(selectedAppVersion);
    }
  }

  renderEditRow() {
    const { classes } = this.props;
    const { selectedAppVersion } = this.state;
    return (
      <>
        <TableCell className={classes.tableCellVersion}>
          <TextField 
            value={selectedAppVersion.version}
            onChange={(e) => this.setState({
              selectedAppVersion: { ...selectedAppVersion, version: e.currentTarget.value },
            })}
          />
        </TableCell>
        <TableCell className={classes.tableCellEnabled}>
          <FormControl>
            <Select 
              value={selectedAppVersion.enabled}
              onChange={(e) => this.setState({
                selectedAppVersion: { ...selectedAppVersion ,enabled: e.target.value},
              })}
            >
              <MenuItem value={true}>Y</MenuItem>
              <MenuItem value={false}>N</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
        <TableCell className={classes.mandatoryCol}>
          <FormControl>
            <Select
              value={selectedAppVersion.mandatory} 
              onChange={(e) => {
                console.log(e, e.currentTarget.value);
                this.setState({ 
                selectedAppVersion: { ...selectedAppVersion, mandatory: e.target.value },
              })}}>
              <MenuItem value={true}>Y</MenuItem>
              <MenuItem value={false}>N</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
        <TableCell colSpan={2} className={classes.tableCellEdit}>
          <Button onClick={() => this.confirmAppVersion()}>
             확인
          </Button>
          <Button onClick={() => this.setState({ editId: null })}>
             취소
          </Button>
        </TableCell>
        <TableCell></TableCell>
      </>
    );
  }

  renderRow(appVersion) {
    const { classes } = this.props;
    return (
      <>
        <TableCell className={classes.tableCellVersion}>{appVersion.version}</TableCell>
        <TableCell className={classes.tableCellEnabled}>{appVersion.enabled ? 'Y' : 'N'}</TableCell>
        <TableCell className={classes.tableCellMandatory}>{appVersion.mandatory ? 'Y' : 'N'}</TableCell>
        <TableCell className={classes.tableCellEdit}>
          <IconButton onClick={() => this.handleEditMode(appVersion)}>
            <Edit />
          </IconButton>
          <IconButton onClick={() => this.handleDeleteMode(appVersion)}>
            <Delete />
          </IconButton>
        </TableCell>
        <TableCell className={classes.tableCellCreatedAt}>{moment(appVersion.createdAt).format('YYYY-MM-DD HH:mm:ss') }</TableCell>
      </>
    );
  }

  render() {
    const { appVersions, pagination: { isFetching, nextPageUrl } } = this.props;
    const { editId } = this.state;

    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row-reverse', padding: '0 16px' }}>
          <Button 
            color='secondary' 
            variant='contained'
            onClick={() => this.handleCreateMode()}
          >
            앱 버젼 추가하기
          </Button>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>버젼명</TableCell>
              <TableCell>가능여부</TableCell>
              <TableCell>강제 업데이트 여부</TableCell>
              <TableCell>수정</TableCell>
              <TableCell>등록일</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {editId === 'new' ? (<TableRow>{this.renderEditRow()}</TableRow>) : undefined}
            {appVersions.map(appVersion => (
              <TableRow key={`app_version_${appVersion._id}`}>
                {appVersion._id === editId ? this.renderEditRow() : this.renderRow(appVersion)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {(!isFetching && nextPageUrl) && (
          <p style={{ textAlign: 'center', marginTop: 10 }}>
            <Button variant="outlined" onClick={() => this.handleLoadMore(true)}>
              더 불러오기
            </Button>
          </p>
        )}
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
)(ListAppVersion);
