import React, { Component } from "react";
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from "react-datepicker";

const CUSTOM = 'custom';

class DateRangeSelector extends Component {
  constructor(props) {
    super(props);
    const { dateRanges, initialDateRangeIndex } = props;
    const date = dateRanges.length ? dateRanges[initialDateRangeIndex] : {};

    this.state = {
      allPeriod: false,
      since: moment(date.since).toDate(),
      until: moment(date.until).toDate(),
      selected: date.name || CUSTOM,
    };
  }

  setDateRange(value) {
    const { dateRanges = [], onChangeDateRange } = this.props;
    const item = dateRanges.find((item) => item.name === value);
    if (!!item) {
      const since = moment(item.since).toDate();
      const until = moment(item.until).toDate();
      this.setState({ since, until, selected: item.name });
      onChangeDateRange({ since, until });
    } else {
      this.setState({ selected: CUSTOM })
    }
  }

  setSince(since) {
    const { selected, until } = this.state;
    const { onChangeDateRange } = this.props;
    this.setState({ since });
    if (selected === CUSTOM) {
      onChangeDateRange({ since, until });
    }
  }

  setUntil(until) {
    const { selected, since } = this.state;
    const { onChangeDateRange } = this.props;
    this.setState({ until });
    if (selected === CUSTOM) {
      onChangeDateRange({ since, until });
    }
  }

  handleOnChangeAllPeriod() {
    const { onChangeDateRange } = this.props;
    let { since, until } = this.state;
    if (!this.state.allPeriod) {
      since = moment('2019-07-05').toDate();
      until = moment().subtract(1, 'day').toDate();
      onChangeDateRange({ since, until });
    }
    this.setState({
      allPeriod: !this.state.allPeriod,
      since, 
      until,
      selected: CUSTOM,
    });
  }

  render() {
    const { dateRanges = [] } = this.props;
    const { since, until, selected, allPeriod } = this.state;
    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <select value={selected} style={{ height: '54px' }} onChange={event => this.setDateRange(event.currentTarget.value)} disabled={allPeriod}>
            {dateRanges.map((date) => (
              <option key={date.name} value={date.name}>
                {date.name}
              </option>
            ))}
            <option value={CUSTOM}>직접 지정</option>
          </select>
          <DatePicker 
            dateFormat="yyyy-MM-dd"
            selected={since}
            startDate={since}
            endDate={until}
            selectsStart
            onChange={since => this.setSince(since)}
            disabled={allPeriod || selected !== CUSTOM}
            maxDate={moment().subtract(1, 'day').toDate()}
          />
          ~
          <DatePicker 
            dateFormat="yyyy-MM-dd"
            selected={until}
            startDate={since}
            endDate={until}
            selectsEnd
            onChange={until => this.setUntil(until)}
            disabled={allPeriod || selected !== CUSTOM}
            minDate={since}
            maxDate={moment().subtract(1, 'day').toDate()}
          />
        </div>
        <div>
          <input type="checkbox" checked={allPeriod} onChange={() => this.handleOnChangeAllPeriod()} />
          전체 기간 검색
        </div>
      </>
    )
  }
}

DateRangeSelector.propTypes = {
  dateRanges: PropTypes.array.isRequired,
  onChangeDateRange: PropTypes.func.isRequired,
  initialDateRangeIndex: PropTypes.number,
}

export default DateRangeSelector;
