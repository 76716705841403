/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  StyleSheet,
  View, Text, ActivityIndicator,
  TouchableHighlight, TouchableOpacity,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';

import { browserHistory } from 'react-router';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';

import { checkoutItems } from './CheckoutPage';
import { navigateToCheckoutNaverPayGateway } from './CheckoutNaverPayGatewayPage';

import AlertDialog from '../../components/AlertDialog';
import CartItem from '../../nativeComponents/CartItem';
import BlankResult from '../../nativeComponents/BlankResult';
import ProductOptionSelect from '../../nativeComponents/ProductOptionSelect';
import NaverPayButton from '../../nativeComponents/NaverPayButton';

import CheckNormalIcon from '../../icons/CheckNormalIcon';
import CheckSelectedIcon from '../../icons/CheckSelectedIcon';

import Colors from '../../constants/Colors';
import Styles from '../../constants/Styles';


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingStart: 20,
    paddingEnd: 16,
    backgroundColor: Colors.semiGray,
    height: 56,
  },
  checkAllItemButton: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 20,
    marginBottom: 12,
  },
  checkAllItemButtonText: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    marginStart: 12,
    marginTop: 2,
    height: 19,
  },
  deleteCheckedItemButton: {
    height: 27,
    marginTop: 18,
    marginBottom: 11,
  },
  deleteCheckedItemText: {
    ...Styles.appleSDGothicNeoSemiBold,
    fontSize: 15,
    marginTop: 2,
  },
  itemListContainer: {
  },
  itemContainer: {
    marginBottom: 20,
  },
  itemContentContainer: {
    backgroundColor: '#F5F5F5',
    paddingTop: 10,
    paddingBottom: 10,
    paddingStart: 10,
    paddingEnd: 10,
    marginBottom: 5,
  },
  itemDivider: {
    height: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  summaryContainer: {
  },
  dividerLine: {
    height: 10,
    backgroundColor: Colors.semiGray,
  },
  summaryRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    marginStart: 20,
    marginEnd: 20,
  },
  summaryTitle: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    height: 19,
  },
  summaryTitleLast: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 15,
    height: 19,
  },
  summaryContent: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 15,
    height: 19,
  },
  promoTitle: {
    color: '#ee2424',
  },
  promoContent: {
    color: '#ee2424',
  },
  totalPrice: {
    ...Styles.SFUITextSemiBold,
    fontSize: 24,
    height: 30,
  },
  checkoutCheckedItemButton: {
    alignItems: 'center',
    backgroundColor: '#000',
    marginHorizontal: 20,
    marginBottom: 5,
    height: 48,
    justifyContent: 'center',
  },
  checkoutCheckedItemText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 14,
    color: '#fff',
    height: 17,
    marginTop: 1,
  },
  loadingIndicatorContainer: {
    height: 200,
    backgroundColor: '#00000020',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footer: {
    marginTop: 20,
    marginBottom: 30,
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});


export class CartPage extends React.Component {
  state = {
    isFetchingData: false,
    checkedItems: [],
    totalGoodsPrice: 0,
    totalDeliveryCharge: 0,
    totalMemberDcPrice: 0,
    totalSettlePrice: 0,
    isOptionSelectVisible: false,
    alert: {
      open: false,
      title: null,
      content: null,
      buttons: [],
    },
  };

  componentDidMount() {
    const { auth } = this.props;
    if (!auth || !auth.token) {
      alert('로그인해주시기 바랍니다.');
      browserHistory.push('/accounts/login');
      return;
    }

    this.loadData();

    const { itemListInCart } = this.props;
    if (itemListInCart.length > 0) {
      this.onPressCheckAllItem();
    }
  }


  componentDidUpdate(prevProps) {
    const { itemListInCart: prevItemListInCart } = prevProps;
    const { itemListInCart: curItemListInCart } = this.props;

    const prevItemIndexList = prevItemListInCart.map(item => item.sno);
    const curItemIndexList = curItemListInCart.map(item => item.sno);

    const { checkedItems } = this.state;
    const newCheckedItems = checkedItems.filter(index => curItemIndexList.indexOf(index) >= 0);

    curItemIndexList.forEach(index => {
      if (prevItemIndexList.indexOf(index) < 0) {
        newCheckedItems.push(index);
      }
    });

    let needUpdate = (checkedItems.length !== newCheckedItems.length);

    if (!needUpdate) {
      for (let i = 0; i < newCheckedItems.length; i++) {
        if (checkedItems.indexOf(newCheckedItems[i]) < 0) {
          needUpdate = true;
          break;
        }
      }
    }

    if (prevItemListInCart !== curItemListInCart) {
      needUpdate = true;
    }

    if (needUpdate) {
      this.loadTotalPrice(newCheckedItems);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ checkedItems: newCheckedItems });
    }
  }

  componentWillUnmount() {
    this.setState = () => null;
  }

  loadData = async () => {
    const errorMessage = '장바구니의 상품을 불러오는 중 오류가 발생하였습니다.';
    this.setState({ isFetchingData: true });
    try {
      const {
        loadItemListInCart,
      } = this.props;

      const ret = await loadItemListInCart();
      // console.log('loadData ret', ret);

      if (!ret.success) {
        alert(`${errorMessage} (E1${ret.errorCode})`);
        return;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.log('loadData error', error);

      if (error.errorCode) {
        alert(`${errorMessage} (E2${error.errorCode})`);
      } else {
        alert(errorMessage);
      }
    } finally {
      this.setState({ isFetchingData: false });
    }
  };

  loadTotalPrice = async checkedItems => {
    const errorMessage = '결제 금액 계산 중 오류가 발생하였습니다.';

    try {
      const { calculatePriceForItemsInCart } = this.props;
      const ret = await calculatePriceForItemsInCart(checkedItems);
      // console.log('loadData ret', ret);

      if (!ret.success) {
        alert(`${errorMessage} (E0${ret.errorCode})`);
        return;
      }
      const { totalGoodsPrice, totalDeliveryCharge, totalMemberDcPrice, totalSettlePrice } = ret.data;

      this.setState({
        totalGoodsPrice,
        totalDeliveryCharge,
        totalMemberDcPrice,
        totalSettlePrice,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('loadData error', error);

      if (error.errorCode) {
        alert(`${errorMessage} (E1${error.errorCode})`);
      } else {
        alert(errorMessage);
      }
    }
  }


  deleteItem = async item => {
    const { deleteItemInCart } = this.props;
    const errorMessage = '삭제 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.';

    try {
      const ret = await deleteItemInCart(item.sno);

      if (!ret.success) {
        alert(`${errorMessage} (E0${ret.errorCode})`);
        return;
      }

      alert('삭제되었습니다.');
      this.loadData();
    } catch (error) {
      // console.log('onPressDeleteItem error', error);

      if (error.errorCode) {
        alert(`${errorMessage} (E1${error.errorCode})`);
      } else {
        alert(errorMessage);
      }
    }
  };

  deleteCheckedItem = async () => {
    const { deleteItemInCart } = this.props;
    const { checkedItems } = this.state;
    const errorMessage = '삭제 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.';

    try {
      for (let i = 0; i < checkedItems.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        const ret = await deleteItemInCart(checkedItems[i]);
        // console.log('deleteCheckedItem deleteItemInCart return', ret);

        if (!ret.success) {
          alert(`${errorMessage} (E0${ret.errorCode})`);
          return;
        }
      }

      alert('삭제되었습니다.');
      this.loadData();
    } catch (error) {
      // console.log('deleteCheckedItem error', error);

      if (error.errorCode) {
        alert(`${errorMessage} (E1${error.errorCode})`);
      } else {
        alert(errorMessage);
      }
    }
  }

  onPressModifyItem = item => () => {
    // console.log('onPressModifyItem item=', item);

    this.setState({
      isOptionSelectVisible: true,
      selectedProduct: item,
    });
  }


  onPressCheckoutItem = item => () => {
    // console.log('onPressCheckoutItem item=', item);
    const { auth } = this.props;
    checkoutItems([item.sno], auth);
  }

  onPressCheckItem = item => () => {
    this.setState(prevState => {
      const { checkedItems: prevCheckedItems } = prevState;
      const checkedItems = [...prevCheckedItems];
      const itemId = item.sno;
      const index = checkedItems.indexOf(itemId);
      if (index >= 0) {
        checkedItems.splice(index, 1);
      } else {
        checkedItems.push(itemId);
      }

      this.loadTotalPrice(checkedItems);

      return {
        checkedItems,
      };
    });
  };


  onPressDeleteItem = item => () => {
    this.setState({
      alert: {
        open: true,
        title: '상품을 삭제하시겠습니까?',
        content: null,
        buttons: [
          {
            text: '삭제',
            onPress: () => {
              this.deleteItem(item);
              this.setState({ alert: { open: false } });
            },
          },
          {
            text: '취소',
            onPress: () => {
              this.setState({ alert: { open: false } });
            },
          },
        ],
      },
    });
  }

  onPressCheckAllItem = () => {
    this.setState(prevState => {
      const { checkedItems: prevCheckedItems } = prevState;
      const { itemListInCart } = this.props;
      const isCheckedAll = prevCheckedItems.length === itemListInCart.length;
      const newCheckedItems = isCheckedAll ? [] : itemListInCart.map(item => item.sno);

      this.loadTotalPrice(newCheckedItems);

      return {
        checkedItems: newCheckedItems,
      };
    });
  };

  onPressDeleteCheckedItems = () => {
    const { checkedItems } = this.state;

    if (checkedItems.length === 0) {
      this.setState({
        alert: {
          open: true,
          title: '선택한 상품이 없습니다. 삭제할 상품을 선택해주세요.',
          content: null,
          buttons: [
            {
              text: '확인',
              onPress: () => {
                this.setState({ alert: { open: false } });
              },
            },
          ],
        },
      });
      return;
    }

    this.setState({
      alert: {
        open: true,
        title: '선택한 상품을 삭제하시겠습니까?',
        content: null,
        buttons: [
          {
            text: '삭제',
            onPress: () => {
              this.deleteCheckedItem();
              this.setState({ alert: { open: false } });
            },
          },
          {
            text: '취소',
            onPress: () => {
              this.setState({ alert: { open: false } });
            },
          },
        ],
      },
    });
  };

  onPressCheckoutCheckedItems = () => {
    const { checkedItems } = this.state;
    const { auth } = this.props;

    if (checkedItems.length === 0) {
      alert('주문할 상품을 선택해주세요.');
      return;
    }

    checkoutItems(checkedItems, auth);
  };

  onPressCheckoutCheckedItemsNaverPay = () => {
    const { checkedItems } = this.state;
    const { itemListInCart } = this.props;

    if (checkedItems.length === 0) {
      alert('주문할 상품을 선택해주세요.');
      return;
    }

    for (let idx = 0; idx < checkedItems.length; idx++) {
      const item = itemListInCart.find(item => item.sno === checkedItems[idx]);
      if (item && item.preOrder === 'y') {
        alert('프리오더 상품은\n네이버페이로 결제할 수 없습니다.');
        return;
      }
      if (item && item.cateNm.toUpperCase() === 'LIVING') {
        alert('오더메이드 제품은\n네이버페이로 결제할 수 없습니다.');
        return;
      }
    }

    navigateToCheckoutNaverPayGateway({ orderItems: checkedItems });
  };

  onCloseOptionSelect = () => {
    this.setState({ isOptionSelectVisible: false });
  }

  onItemAddedToCart = () => {
    this.setState({ isOptionSelectVisible: false });
    this.loadData();
  }

  onItemNaverPay = () => {
    this.setState({ isOptionSelectVisible: false });
    this.loadData();
  }

  renderItem = item => {
    const { checkedItems } = this.state;
    const isChecked = checkedItems.indexOf(item.sno) >= 0;

    return (
      <CartItem
        key={item.sno}
        isChecked={isChecked}
        item={item}
        onPressCheckItem={this.onPressCheckItem(item)}
        onPressDeleteItem={this.onPressDeleteItem(item)}
        onPressModifyItem={this.onPressModifyItem(item)}
        onPressCheckoutItem={this.onPressCheckoutItem(item)}
        showTopControl
        showBottomControl
        showLikeButton
      />
    );
  }


  renderItemList = () => {
    const { itemListInCart } = this.props;

    // console.log('CartScreen itemListInCart', itemListInCart);
    const { isFetchingData } = this.state;

    const isLoadingIndicatorContainerVisible = isFetchingData && (itemListInCart.length === 0);

    const itemList = itemListInCart.map((item, index) => (
      <View key={`cartItem${index}`}>
        {index > 0 && <View style={styles.itemDivider} />}
        {this.renderItem(item)}
      </View>
    ));

    return (
      <View style={styles.itemListContainer}>
        { isLoadingIndicatorContainerVisible && (
          <View style={styles.loadingIndicatorContainer}>
            <ActivityIndicator color="gray" size="large" />
          </View>
        )}
        {itemList}
      </View>
    );
  }

  onPressContinueShopping = () => {
    const { router } = this.props;

    router.push('/shop');
  }

  renderEmpty = () => {
    const { router } = this.props;

    return (
      <BlankResult
        iconType="cart"
        title="장바구니에 담긴 상품이 없습니다"
        description="새로운 상품을 담아주세요"
        router={router}
      />
    );
  }

  renderHeader() {
    const { checkedItems } = this.state;
    const { itemListInCart } = this.props;

    const isCheckedAll = (itemListInCart.length > 0) && (checkedItems.length === itemListInCart.length);

    return (
      <View style={styles.header}>
        <View style={styles.checkAllItemButton}>
          <TouchableHighlight onPress={this.onPressCheckAllItem}>
            {isCheckedAll ? <CheckSelectedIcon /> : <CheckNormalIcon />}
          </TouchableHighlight>
          <Text style={styles.checkAllItemButtonText}>
            전체 상품(
            {itemListInCart.length}
            )
          </Text>
        </View>

        <TouchableHighlight
          onPress={this.onPressDeleteCheckedItems}
          underlayColor={Colors.touchEffectColorWhite}
          style={[styles.deleteCheckedItemButton, styles.touchEffect]}
        >
          <Text style={styles.deleteCheckedItemText}>선택 삭제</Text>
        </TouchableHighlight>
      </View>
    );
  }

  renderSummary() {
    const { totalGoodsPrice, totalDeliveryCharge, totalMemberDcPrice, totalSettlePrice } = this.state;

    return (
      <View style={styles.summaryContainer}>
        <View style={styles.dividerLine} />
        <View style={styles.summaryRow}>
          <Text style={styles.summaryTitle}>총 상품 금액</Text>
          <NumberFormat
            value={totalGoodsPrice}
            displayType="text"
            thousandSeparator
            renderText={value => (
              <Text style={styles.summaryContent}>
                {value}
                원
              </Text>
            )}
          />
        </View>
        <View style={styles.summaryRow}>
          <Text style={styles.summaryTitle}>총 배송비</Text>
          <NumberFormat
            value={totalDeliveryCharge}
            displayType="text"
            thousandSeparator
            renderText={value => (
              <Text style={styles.summaryContent}>
                {value}
                원
              </Text>
            )}
          />
        </View>
        { totalMemberDcPrice > 0 && (
          <View style={styles.summaryRow}>
            <Text style={[styles.summaryTitle, styles.promoTitle]}>프로모션 할인</Text>
            <NumberFormat
              value={totalMemberDcPrice}
              displayType="text"
              thousandSeparator
              renderText={value => (
                <Text style={[styles.summaryContent, styles.promoContent]}>
                  -{value}
                  원
                </Text>
              )}
            />
          </View>
        )}
        <View style={styles.summaryRow}>
          <Text style={styles.summaryTitleLast}>총 결제 예상 금액</Text>
          <NumberFormat
            value={totalSettlePrice}
            displayType="text"
            thousandSeparator
            renderText={value => (
              <Text style={[styles.summaryContent, styles.totalPrice]}>
                {value}
                원
              </Text>
            )}
          />
        </View>
      </View>
    );
  }

  renderAlert() {
    const {
      alert: {
        open,
        title,
        content,
        buttons,
      },
    } = this.state;

    return (
      <div>
        <AlertDialog
          open={open}
          title={title}
          content={content}
          buttons={buttons}
        />
      </div>
    );
  }

  renderNaverPay() {
    const { itemListInCart } = this.props;
    const { checkedItems } = this.state;
    let shouldDisable = false;

    for (let idx = 0; idx < checkedItems.length; idx++) {
      const item = itemListInCart.find(item => item.sno === checkedItems[idx]);
      if (item && item.preOrder === 'y') {
        shouldDisable = true;
        break;
      }
      if (item && item.cateNm.toUpperCase() === 'LIVING') {
        shouldDisable = true;
        break;
      }
    }

    return (
      <NaverPayButton
        disabled={shouldDisable}
        onPressButton={this.onPressCheckoutCheckedItemsNaverPay}
      />
    );
  }

  render() {
    // console.log('render()');
    const {
      checkedItems, isOptionSelectVisible,
      selectedProduct: {
        goodsNo: productId,
        sno: selectedItemIndex,
        goodsCnt: selectedItemCount,
        option: selectedItemOption,
      } = {},
      isFetchingData,
    } = this.state;
    const { itemListInCart } = this.props;

    if (!isFetchingData && (!itemListInCart || itemListInCart.length === 0)) {
      return this.renderEmpty();
    }

    return (
      <View style={styles.container}>
        {this.renderHeader()}
        {this.renderItemList()}
        {this.renderSummary()}
        <View style={styles.footer}>
          <TouchableOpacity
            style={styles.checkoutCheckedItemButton}
            onPress={this.onPressCheckoutCheckedItems}
          >
            <Text style={styles.checkoutCheckedItemText}>
              {checkedItems.length}
              건 신용카드로 주문하기
            </Text>
          </TouchableOpacity>
          { this.renderNaverPay() }
        </View>
        {isOptionSelectVisible && (
          <ProductOptionSelect
            productId={productId}
            selectedItemIndex={selectedItemIndex}
            selectedItemCount={selectedItemCount}
            selectedItemOption={selectedItemOption}
            open={isOptionSelectVisible}
            onClose={this.onCloseOptionSelect}
            onItemAddedToCart={this.onItemAddedToCart}
            onItemNaverPay={this.onItemNaverPay}
          />
        )}
        {this.renderAlert()}
      </View>
    );
  }
}


export default connect(
  state => ({
    auth: state.auth,
    itemListInCart: state.entities.shop.cart.items,
  }),
  actions,
)(CartPage);
