import React from 'react';
import { Dimensions, StyleSheet, View, ScrollView } from 'react-native';
import { connect } from 'react-redux';

import { actions, loadEntities, getTrackingLink } from 'shared';

import { generateFirebaseEvent } from '../../../utils';

import { Constants } from '../../../constants';
import { Heartist } from '../../../components-v2';

import TabHeader from './TabHeader';
import TabFeed from './TabFeed';
import TabPick from './TabPick';


const {
  height: windowHeight
} = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {

  },
  profileContainer: {
    paddingTop: 10,
    paddingBottom: 20,
  },
});


export class HeartistProfilePage extends React.Component {
  state = {
    tabname: 'feed',
    isFeedUpdate : false,
    isPickUpdate : false,
  }

  componentDidMount() {
    const {
      userId,
      loadUser,
      loadStylePostCountByUser,
      logEventPageview,
    } = this.props;

    loadUser(userId);
    loadStylePostCountByUser(userId);

    logEventPageview({ page_name: 'HeartistProfile', page_key: userId, });
  }

  onPressPostThumbnail = postId => {
    const {
      navigation,
      user,
    } = this.props;

    navigation.push({
      routeName: 'Post',
      params: { postId, userId: user._id },
      key: `Post.${postId}`,
    });
  };

  onPressProduct = (product, { post }) => {
    const { historySwitch, auth, navigation } = this.props;
    const { token } = auth;

    const retail = product.retails[product.defaultRetailIdx];
    const link = retail.orgLink || retail.link;

    // eslint-disable-next-line no-prototype-builtins
    if (!post && product.hasOwnProperty('post')) {
      // eslint-disable-next-line prefer-destructuring
      post = product.post;
    }

    // TODO: Post 정보가 없는 경우 이벤트를 보내지 않아도 괜찮은지 확인 필요
    if (post && post.owner) {
      generateFirebaseEvent({ product, post, where: 'post' });
    }

    if (product.godoGoodsNo) {
      // navigateToProductScreen({ productId: product.godoGoodsNo, postId: post && post._id }, navigation);
      return;
    }

    const trackingLink = getTrackingLink(link, post, historySwitch, token);

    navigation.navigate('OutlinkModal', {
      url: trackingLink,
      title: link,
      backButtonStyle: 'close',
      product,
      post,
      showProductDisclaimer: true,
    });
  }

  onAfterFeedUpdate = () => {
    this.setState({isFeedUpdate:false});
  }

  renderProfile() {
    const {
      auth, router,
      followStatus, getFollowStatus, followUser, unfollowUser,
      user, userId,
    } = this.props;

    return (
      <View style={styles.profileContainer}>
        <Heartist
          auth={auth}
          router={router}
          getFollowStatus={getFollowStatus}
          followUser={followUser}
          unfollowUser={unfollowUser}
          userId={userId}
          user={user}
          followStatus={followStatus}
        />
      </View>
    );
  }

  renderTabBody() {
    const {
      showSmartBanner,
      pagination,
      router,
      user,
      userId,
      posts,
      loadStylePostCountByUser,
      loadStylePostListByUser,
      loadLikedProductsByUser,
      upperState,
      modalProps,
      setModalProps,
    } = this.props;

    const {
      tabname,
      isFeedUpdate,
      isPickUpdate
    } = this.state;

    const commonProps = {
      showSmartBanner,
      router,
      modalProps,
      setModalProps,
    }

    if (tabname === 'feed') {
      return (
        <TabFeed
          {...commonProps}
          isFeedUpdate={isFeedUpdate}
          onFeedUpdated={() => this.onAfterFeedUpdate()}
          pagination={pagination}
          loadData={(nextPage, query) => {
            if(!nextPage) {
              loadStylePostCountByUser(user._id);
            }
            return loadStylePostListByUser(nextPage, user._id);
          }}
          posts={posts}
          />
      );
    }

    if (tabname === 'pick') {
      const onPressProduct = (item) => {
        const { router } = this.props;
        router.push(`/product/${item.godoGoodsNo}`);
      }
      const likedProductsKey = actions.loadLikedProductsByUserKey(user._id)
      const { products: likedProducts, pagination: likedProductsPagination } = loadEntities(
        upperState, likedProductsKey, likedProductsKey, 'pagination', 'products');

      return (
        <TabPick
          {...commonProps}
          pagination={likedProductsPagination}
          loadData={(nextPage, query) => {
            if(!nextPage) {
              loadStylePostCountByUser(user._id);
            }
            return loadLikedProductsByUser(user._id, nextPage);
          }}
          likedProducts={likedProducts}
          onPressProduct={onPressProduct}
          />
      );
    }

    return null;
  }

  render() {
    const {
      user,
      showSmartBanner,
      postsCount,
    } = this.props;

    if (!user) {
      return null;
    }

    const { tabname } = this.state;

    const scrollViewStyles = {
      ...styles.scrollView,
      height: windowHeight
        - (showSmartBanner && Constants.smartBannerHeight)
        - Constants.mainHeaderHeight
      };

    const onChangeTab = (tab) => {
      if (tabname !== tab.key) {
        this.setState({ tabname: tab.key });
      }
    }

    const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
      const paddingToBottom = 20;
      return layoutMeasurement.height + contentOffset.y >=
        contentSize.height - paddingToBottom;
    };

    const handleScroll = event => {
      try{
        const scrollPosition = event.nativeEvent.contentOffset.y;

        if(isCloseToBottom(event.nativeEvent)){
          if(tabname === 'feed'){
            this.setState({isFeedUpdate : true});
          }

        }
      }catch(e){

      }
    };

    return (
      <View style={styles.container}>
        <ScrollView
          style={scrollViewStyles}
          stickyHeaderIndices={[1]}
          onScroll={handleScroll}
          scrollEventThrottle={16}
          showsVerticalScrollIndicator={false}
          >
          {this.renderProfile()}

          <TabHeader
            onChange={onChangeTab}
            tabname={tabname}
            postsCount={postsCount}
            />
          {this.renderTabBody()}
        </ScrollView>
      </View>
    );
  }
}


export default connect(
  (state, { router }) => {
    const { entities: { users } } = state;
    const { params: { userId } } = router;

    //console.log('userId', userId);

    let followStatus = { following: false };
    // eslint-disable-next-line no-prototype-builtins
    if (state.entities.heartists.hasOwnProperty(userId)) {
      const { following } = state.entities.heartists[userId];
      if (following) {
        followStatus = { following };
      }
    }

    const user = users[userId];

    // console.log('heartists', heartists);

    return {
      auth: state.auth,
      user,
      userId,
      historySwitch: state.settings.historySwitch,
      postsCount: state.entities.stylePostsCount[userId],
      followStatus,
      upperState: state,
      ...loadEntities(state, `stylePosts.${userId}`, `stylePosts`, 'pagination', 'posts'),
    };
  },
  actions,
)(HeartistProfilePage);
