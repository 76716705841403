import * as React from 'react';

function SvgIcTabLike(props) {
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M15.027 18.944l5.948-6.005c2.478-2.503 2.478-6.56 0-9.062a6.302 6.302 0 00-8.975 0 6.302 6.302 0 00-8.975 0c-2.478 2.502-2.478 6.56 0 9.061L12 22l3.027-3.056z"
        stroke="#FFF"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgIcTabLike;

