import React from 'react';
import { TouchableHighlight, StyleSheet, Text, View } from 'react-native';

import Styles from '../constants/Styles';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';

import HeartitBlankPageLogoSmall from '../icons/HeartitBlankPageLogoSmall';


const emptyStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  cartImage: {
    width: 74,
    height: 74,
  },
  alertTextContainer: {
    marginTop: 10,
  },
  alertText: {
    fontSize: 18,
    lineHeight: 21,
    color: 'rgba(0,0,0,0.5)',
    textAlign: 'center',
    ...Styles.appleSDGothicNeoMedium,
  },
});


export default class SmallBlank extends React.Component {
  render() {
    const { title } = this.props;

    return (
      <View style={emptyStyles.container}>
        <View style={emptyStyles.cartImage}>
          <HeartitBlankPageLogoSmall />
        </View>
        <View style={emptyStyles.alertTextContainer}>
          <Text style={emptyStyles.alertText}>
            {title}
          </Text>
        </View>
      </View>
    );
  }
}
