import React from 'react';
import {
  StyleSheet, TouchableHighlight,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import Fade from '@material-ui/core/Fade';

import Constants from '../constants/Constants';
import Colors from '../constants/Colors';
import TabBarIcon from './TabBarIcon';


const styles = {
  footerMenu: {
    backgroundColor: 'black',
    display: 'flex',
    width: 244,
    height: Constants.mainBottomTabBarHeight,
    borderRadius: 28,
    boxShadow: '0px 6px 14px 0px rgba(0,0,0,0.4)',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingLeft: 10 - 5,
    paddingRight: 9 - 5,
    pointerEvents: 'auto',
  },
  footerMenuItem: {
    marginLeft: 18,
    marginRight: 18,
  },
};

const stylesNative = StyleSheet.create({
  menuButton: {
    height: 48,
    width: 48,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 24,
    marginHorizontal: 5,
    marginVertical: 4,
  },
});


class MainBottomTabBar extends React.Component {
  lastScrollTop = 0;

  scrollDirection = 'up';

  state = {
    visible: true,
    temporaryForcedHide: false,
  };

  componentDidMount() {
    // console.log('MainBottomTabBar componentDidMount');
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    // console.log('MainBottomTabBar componentWillUnmount');
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate() {
    // console.log('MainBottomTabBar componentDidUpdate');
  }

  handleScroll = () => {
    // console.log('handleScroll e=', e);
    // const scrollTop = ('scroll', e.srcElement.scrollingElement.scrollTop);
    const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
    const stRaw = window.pageYOffset || document.documentElement.scrollTop;
    const st = Math.min(
      stRaw,
      scrollHeight,
    );

    // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    if (st > this.lastScrollTop) {
      // downscroll code
      if (this.scrollDirection === 'up') {
        // console.log('handleScroll detect down');
        this.scrollDirection = 'down';
        this.setState({ visible: false });
      }
    } if (st < this.lastScrollTop) {
      if (this.scrollDirection === 'down') {
        // console.log('handleScroll detect up');
        // iOS Safari에서 스크롤을 내렸을 때, bounce back에 의해 하단 바가 다시 표시되는 것을 막기 위한 예외 처리
        const safeMargin = 10;
        if (stRaw < (scrollHeight - safeMargin)) {
          this.scrollDirection = 'up';
          this.setState({ visible: true });
          // console.log('handleScroll visible enable');
        } else {
          // console.log('handleScroll visible skip');
        }
      }
    }

    this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
  }

  isVisible() {
    const { visible } = this.state;

    return visible;
  }

  renderFooterMenuItem({ menu }) {
    const { pathname, router } = this.props;

    let focused = false;
    switch (menu.name) {
    case 'shop':
      focused = (
        pathname.startsWith('/shop') || pathname.startsWith('/product')
      );
      break;
    case 'like':
      focused = (
        pathname.startsWith('/history')
      );
      break;
    case 'my':
      focused = (
        pathname.startsWith('/accounts')
      );
      break;
    case 'home':
    default:
      focused = (
        pathname.endsWith('/')
          || pathname.startsWith('/posts')
          || pathname.startsWith('/heartists')
      );
      break;
    }

    return (
      <TouchableHighlight
        key={menu.name}
        onPress={() => { router.push(menu.to); }}
        underlayColor={Colors.touchEffectColorBlack}
        style={stylesNative.menuButton}
      >
        <TabBarIcon focused={focused} name={menu.name} />
      </TouchableHighlight>
    );
  }

  render() {
    const { pathname } = this.props;
    const menus = [
      { name: 'home', to: '/' },
      { name: 'shop', to: '/shop' },
      { name: 'like', to: '/history' },
      { name: 'my', to: '/accounts/settings' },
    ];

    const footerMenuHiddenPathList = [
      '/product/',
      '/shop/cart',
      '/shop/checkout',
      '/accounts/edit',
      '/accounts/changePassword',
      '/accounts/withdraw',
      '/accounts/shippingAddress/edit',
      '/accounts/shippingAddress/create',
      '/accounts/reviews',
      '/accounts/reviewWriteSuccess',
      '/accounts/reviewEditSuccess',
      '/accounts/login',
      '/accounts/join',
      '/accounts/orders',
      '/accounts/following',
      '/accounts/faq',
      '/shop/search',
      '/legal',
      '/notice',
      '/outlink',
      '/heartists',
      path => path.startsWith('/accounts/orders/') && path.endsWith('/reviewWrite/'),
    ];

    for (let i = 0; i < footerMenuHiddenPathList.length; i++) {
      const rule = footerMenuHiddenPathList[i];
      if (typeof rule === 'function') {
        if (rule(pathname)) {
          return null;
        }
      }

      if (pathname.startsWith(rule)) {
        return null;
      }
    }

    const { visible, temporaryForcedHide } = this.state;

    return (
      <Fade in={visible && !temporaryForcedHide}>
        <div style={styles.footerMenu}>
          {menus.map(menu => this.renderFooterMenuItem({ menu }))}
        </div>
      </Fade>
    );
  }
}

export default MainBottomTabBar;
