import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import PostPerformance from './PostPerformance';
import Spinner from '../Spinner';

const styles = {
  container: {
    marginTop: '20px',
    textAlign: 'center',
  },
  post: {
    fontSize: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
    textAlign: 'left',
  },
  postContainer: {
    fontSize: 0,
    display: 'inline-block',
    width: '270px',
    margin: '10px',
    position: 'relative',
    verticalAlign: 'top',
  },
  loadMoreContainer: {
    margin: '62.5px auto 60px',
    textAlign: 'center',
    width: '100%',
  },
  loadMoreButton: {
    borderRadius: '50%',
    border: '1px solid #000',
    height: '100px',
    width: '100px',
    backgroundColor: 'white',
  },
};

export default class PostPerformanceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoScroll: !this.props.forceReload && this.props.posts.length,
    };
    this.loadMore = React.createRef();
  }

  componentDidMount() {
    const { forceReload, posts, loadData } = this.props;
    if (forceReload || !posts.length) {
      loadData();
    }
    window.addEventListener('scroll', () => this.handleScroll());
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => this.handleScroll());
  }

  handleScroll() {
    if (this.state.autoScroll
        && this.loadMore.current
        && this.loadMore.current.getBoundingClientRect().top <= window.innerHeight
        && !this.props.pagination.isFetching
        && this.props.pagination.nextPageUrl) {
      this.props.loadData(true);
    }
  }

  handleLoadMore() {
    this.setState({ autoScroll: true });
    this.props.loadData(true);
  }

  handleMouseOverPost(post) {
    const { getPostClickCount, startDate, endDate, clickCount } = this.props;
    if (!get(clickCount, `${post._id}.${startDate}~${endDate}`)) {
      getPostClickCount(post._id, startDate, endDate);
    }
  }

  renderPost(post) {
    const { startDate, endDate, clickCount } = this.props;
    const clickCountOfThisPost = get(clickCount, `${post._id}.${startDate}~${endDate}`);
    return (
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      <div style={styles.postContainer} key={post._id} onMouseEnter={() => this.handleMouseOverPost(post)}>
        {clickCountOfThisPost ? (
          <PostPerformance post={Object.assign({}, post, { sale: null })} needId clickCount={clickCountOfThisPost} />
        ) : <PostPerformance post={Object.assign({}, post, { sale: null })} needId />}
      </div>
    );
  }

  render() {
    const { posts, pagination: { isFetching, nextPageUrl } } = this.props;
    return (
      <div style={styles.container}>
        {posts.map(post => this.renderPost(post))}
        {nextPageUrl && !isFetching && (
          <div style={styles.loadMoreContainer} ref={this.loadMore}>
            <button
              type="button"
              style={styles.loadMoreButton}
              onClick={() => this.handleLoadMore()}
            >
            view more
            </button>
          </div>
        )}
        {isFetching !== false && <Spinner />}
      </div>
    );
  }
}

PostPerformanceList.propTypes = {
  loadData: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  posts: PropTypes.array.isRequired,
  forceReload: PropTypes.bool.isRequired,
};
