import { browserHistory } from 'react-router';
import qs from 'qs';

import CheckoutPage from './CheckoutPage';

export default CheckoutPage;


export function checkoutItems(itemIndexList, auth) {
  // console.log('checkoutItems itemIndexList', itemIndexList);

  if (!auth || !auth.token) {
    alert('로그인해주시기 바랍니다.');
    browserHistory.push('/accounts/login');
    return;
  }

  const query = {
    itemIndexList,
  };

  const path = `/shop/checkout/${qs.stringify(query)}`;
  browserHistory.push(path);
}
