import React from 'react';
import {
  FlatList, View, Text, StyleSheet, TextInput,
} from 'react-native';
import { connect } from 'react-redux';

import { actions } from 'shared';

import CloudinaryImage from '../../../components-v2/CloudinaryImage';
import Button from '../../../components-v2/Button';
import { BottomSheetModal } from '../../../components-v2';
import { Constants, Styles, Colors } from '../../../constants';


const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 8,
  },
  inputTagContainer: {
    flexDirection: 'row',
  },
  inputContainer: {
    flex: 1,
  },
  inputText: {
    color: '#000',
    fontSize: 16,
    lineHeight: 19.2,
    marginVertical: 0,
    marginHorizontal: Constants.viewHorizontalPadding,
    paddingHorizontal: 0,
    paddingVertical: 8,
    ...Styles.pretendard400,
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    outlineStyle: 'none',
  },
  searchResultItemContainer: {
    paddingHorizontal: 0,
    marginHorizontal: Constants.viewHorizontalPadding,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderBottomColor: Colors.defaultGray02,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  productContainer: {
    flexDirection: 'row',
  },
  productMediaContainer: {
    width: 64,
    height: 85,
    borderWidth: 0.5,
    borderColor: '#cfcfcf',
  },
  productTextContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingStart: 16
  },
  productBrand: {
    color: '#000',
    fontSize: 14,
    lineHeight: 16.8,
    textAlign: 'left',
    ...Styles.pretendard600,
  },
  productName: {
    marginTop: 2,
    color: '#000',
    fontSize: 14,
    lineHeight: 16.8,
    textAlign: 'left',
    ...Styles.pretendard400,
  },
  productOption: {
    marginTop: 2,
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard400,
  },
});


class StyleUploadAddProductPage extends BottomSheetModal {
  title = '관련 상품 추가'
  titleClose = '취소'
  subTitle = ''

  state = {
    inputValue: '',
    searchResult: [],
  };

  onChangeText = async (value) => {
    const { loadStylePostProducts } = this.props;
    this.setState({ inputValue: value });
    if (value.trim() !== '') {
      const result = await loadStylePostProducts({ keyword: value });
      this.setState({ searchResult: result.data });
    } else {
      this.setState({ searchResult: [] });
    }
  }

  renderMedia(item) {
    const { publicId } = item.media;
    return (
      <CloudinaryImage
        key={`image${publicId}`}
        publicId={publicId}
        aspectRatio={63/84}
      />
    )
  }

  renderSearchResultItem = ({ item, index }) => {
    const { callback } = this.props;
    const brand = item.brand;
    const productName = item.name;
    const productOption = item.optionVal;
    const price = item.retails[0].sellPrice.amount;
    const _id = item.godoGoodsNo || item._id;

    return (
      <Button
        key={`search-${index}`}
        containerStyle={styles.searchResultItemContainer}
        innerComponent={(
          <View style={styles.productContainer}>
            <View style={styles.productMediaContainer}>
              {this.renderMedia(item)}
            </View>
            <View style={styles.productTextContainer}>
              <Text style={styles.productBrand}>{ brand }</Text>
              <Text style={styles.productName}>{ productName.trim() }</Text>
              {productOption && (<Text style={styles.productOption}>{ productOption.trim() }</Text>)}
            </View>
          </View>
        )}
        onPress={() => {
          callback(item);
        }}
      />
    );
  }

  componentDidMount() {
    super.componentDidMount();

    const {
      logEventPageview,
    } = this.props;

    logEventPageview({ page_name: 'StyleUploadAddProduct' });
  }

  renderBody() {
    const { inputValue, searchResult } = this.state;

    return (
      <View style={styles.container}>
        <View style={styles.inputTagContainer}>
          <View style={styles.inputContainer}>
            <TextInput
              ref={(ref) => {this.inputRef = ref;}}
              style={styles.inputText}
              autoFocus
              enablesReturnKeyAutomatically
              keyboardType='default'
              placeholder="브랜드명, 상품명을 검색해보세요"
              placeholderTextColor={Colors.defaultGray02}
              returnKeyType='done'
              onChangeText={text => this.onChangeText(text)}
              value={inputValue}
            />
          </View>
        </View>
        <FlatList
          data={searchResult}
          ListHeaderComponent={() => (<View style={{ height: 4 }} />)}
          renderItem={this.renderSearchResultItem}
          removeClippedSubviews={false}
        />
      </View>
    );
  }
}


export default connect(
  (state) => {
    return {
      auth: state.auth,
    }
  },
  actions,
)(StyleUploadAddProductPage);
