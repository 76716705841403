import React from 'react';

import TabHomeIcon from '../icons/TabHomeIcon';
import TabHomeSelectedIcon from '../icons/TabHomeSelectedIcon';
import TabShopIcon from '../icons/TabShopIcon';
import TabShopSelectedIcon from '../icons/TabShopSelectedIcon';
import TabLikeIcon from '../icons/TabLikeIcon';
import TabLikeSelectedIcon from '../icons/TabLikeSelectedIcon';
import TabMyIcon from '../icons/TabMyIcon';
import TabMySelectedIcon from '../icons/TabMySelectedIcon';


export default ({ name, focused }) => {
  switch (name) {
  case 'home':
    return focused ? <TabHomeSelectedIcon /> : <TabHomeIcon />;

  case 'shop':
    return focused ? <TabShopSelectedIcon /> : <TabShopIcon />;

  case 'like':
    return focused ? <TabLikeSelectedIcon /> : <TabLikeIcon />;

  case 'my':
    return focused ? <TabMySelectedIcon /> : <TabMyIcon />;

  default:
    return null;
  }
};
