import React from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import has from 'lodash/has';

import { actions, selectPostDate } from 'shared';

import { Constants, Styles } from '../../../constants';
import { generateFirebaseEvent } from '../../../utils';
import ShareDialog from '../../../components/ShareDialog';
import { openOutlinkPage } from '../../OutlinkGatewayPage';

import StyleFeedDetail from './StyleFeedDetail';
import StyleFeedHorizontalList from './StyleFeedHorizontalList';



export class StyleDetailScreen extends React.Component {
  state = {
    inFocusedScreen: true,
    comments: [],
    stylePostsByTag: {},
    stylePostsByUser: [],
    openShareModal: false,
  };

  componentDidMount() {
    const {
      logEventPageview, stylePostId,
    } = this.props;

    this.loadData();

    logEventPageview({ page_name: 'StyleDetail', page_key: stylePostId });
  }

  componentDidUpdate(prevProps) {
    const { stylePostId } = this.props;
    if (prevProps.stylePostId !== stylePostId) {
      this.loadData();
    }
  }

  loadData() {
    const {
      stylePostId,
      loadStylePost,
      loadStylePostComments,
      loadStylePostByTag,
      loadStylePostByUser,
      getFollowStatus,
    } = this.props;
    loadStylePost(stylePostId)
      .then(post => {
        loadStylePostByUser(post.owner._id)
          .then(posts => {
            this.setState({ stylePostsByUser: posts.filter((p) => p._id !== post._id) });
          });
        post.tags.map(tag => {
          loadStylePostByTag(tag._id)
            .then(posts => {
              let stylePostsByTag = this.state.stylePostsByTag;
              stylePostsByTag[tag._id] = posts;
              this.setState({ stylePostsByTag });
            });
        });
        getFollowStatus(post.owner._id)
      });
    loadStylePostComments(stylePostId)
      .then(result => this.setState({ comments: result }));
  }

  onPressProduct = product => {
    const { router, historySwitch, auth } = this.props;
    let { post } = this.props;
    const { token } = auth;

    const retail = product.retails[product.defaultRetailIdx];
    const link = retail.orgLink || retail.link;

    // eslint-disable-next-line no-prototype-builtins
    if (!post && product.hasOwnProperty('post')) {
      // eslint-disable-next-line prefer-destructuring
      post = product.post;
    }

    // TODO: Post 정보가 없는 경우 이벤트를 보내지 않아도 괜찮은지 확인 필요
    if (post && post.owner) {
      generateFirebaseEvent({ product, post, where: 'post' });
    }

    if (product.godoGoodsNo) {
      router.push(`/product/${product.godoGoodsNo}`);
      return;
    }

    openOutlinkPage(product, post, historySwitch, token, router);
  }

  scrollToTop() {
    this.scrollViewRef.scrollTo(0);
  }

  render() {
    const { router, post, historySwitch, loadUser, auth, setModalProps } = this.props;
    const { followStatus, getFollowStatus, followUser, unfollowUser } = this.props;
    const { loadStylePost, toggleLikeStylePost, deleteStylePost } = this.props;
    const { inFocusedScreen, comments, stylePostsByTag, stylePostsByUser } = this.state;

    return (
      <ScrollView
        ref={ref => { this.scrollViewRef = ref; }}
        contentContainerStyle={styles.contentContainer}
        style={[styles.container, Styles.screenHeaderSpace]}
        {...Constants.scrollViewCommonProps}
      >
        {post && (
          <StyleFeedDetail
            onPressHeartist={async (userId) => {
              router.push(`/heartists/${userId}`);
            }}
            setModalProps={setModalProps}
            onPressShare={() => this.setState({ openShareModal: true })}
            onPressProduct={this.onPressProduct}
            followStatus={followStatus}
            followUser={(userId) => {
              followUser(userId)
                .then(() => getFollowStatus(userId));
            }}
            unfollowUser={(userId) => {
              unfollowUser(userId)
                .then(() => getFollowStatus(userId));
            }}
            toggleLikeStylePost={() => {
              toggleLikeStylePost(post._id)
                .then(() => loadStylePost(post._id));
            }}
            deleteStylePost={deleteStylePost}
            post={post}
            comments={comments}
            postDate={selectPostDate('postScreen')}
            historySwitch={historySwitch}
            auth={auth}
            inFocusedScreen={inFocusedScreen}
            showBottomLine={false}
            router={router}
          />
        )}

        <StyleFeedHorizontalList
          title={(post) ? `${post.owner.name}님의 다른 피드` : ''}
          data={stylePostsByUser}
          showHeartistId={false}
          scrollToTop={() => this.scrollToTop()}
          onPressShowAll={() => {
            router.push(`/heartists/${post.owner._id}`);
          }}
          router={router}
        />

        {post && post.tags.map((tag) => {
          const postsByTag = has(stylePostsByTag, tag._id) ? stylePostsByTag[tag._id] : [];
          return (
            <StyleFeedHorizontalList
              title={`#${tag.title}`}
              data={postsByTag}
              key={tag._id}
              containerStyle={{marginTop: 37}}
              showHeartistId={true}
              scrollToTop={() => this.scrollToTop()}
              onPressShowAll={() => {
                router.push({
                  pathname: '/style/all',
                  state: { hashtag: tag.title },
                });
              }}
              router={router}
            />
          );
        })}

        <View style={{ height: 63 }}/>

        {post && (<ShareDialog
          open={this.state.openShareModal}
          onClose={() => {this.setState({ openShareModal: false })}}
          post={post}
        />)}
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginBottom: Constants.innerViewBottomSpace,
  },
  contentContainer: {
  },
});

export default connect(
  (state, { params }) => {
    const { stylePostId } = params;
    const post = state.entities.stylePosts[stylePostId] || state.entities.followingStylePosts[stylePostId];

    let followStatus = { following: false };
    // eslint-disable-next-line no-prototype-builtins
    if (post && state.entities.heartists.hasOwnProperty(post.owner._id)) {
      const { following } = state.entities.heartists[post.owner._id];
      if (following) {
        followStatus = { following };
      }
    }

    return {
      auth: state.auth,
      historySwitch: state.settings.historySwitch,
      stylePostId,
      followStatus,
      post,
    };
  },
  actions,
)(StyleDetailScreen);
