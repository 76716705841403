import createFetchAction from '../util/createFetchAction';


export function adminGetRecommendedSearchKeyword() {
  return createFetchAction({
    type: 'ADMIN_GET_RECOMMENDED_SEARCH_KEYWORD',
    endpoint: '/api/v2/admin/search/keyword/recommended',
    doDispatch: false,
  });
}

export function adminUpdateRecommendedSearchKeyword({ keywordData }) {
  return createFetchAction({
    type: 'ADMIN_GET_RECOMMENDED_SEARCH_KEYWORD',
    endpoint: '/api/v2/admin/search/keyword/recommended',
    method: 'POST',
    body: { keywordData },
    doDispatch: false,
  });
}
