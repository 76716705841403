import React from 'react';
import PropTypes from 'prop-types';
import {
  View, Text, StyleSheet, Platform, FlatList, TouchableHighlight,
} from 'react-native';

import InstagramProfileImage from './InstagramProfileImage';
import Products from './Products';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';
import Constants from '../constants/Constants';


class HeartistsPicks extends React.Component {
  renderItem = item => {
    const { historySwitch, auth, router, onPressProduct, onPressHeartist } = this.props;
    const { products } = item;

    return (
      <View style={styles.contentsContainer}>
        <View style={[styles.header, styles.touchEffectContainer]}>
          <TouchableHighlight
            onPress={() => onPressHeartist(item._id, item.username)}
            underlayColor={Colors.touchEffectColorWhite}
            style={[styles.profileTouchEffectContainer, styles.touchEffect]}
          >
            <View style={styles.profileContainer}>
              <InstagramProfileImage username={item.username} style={stylesHtml.profileImage} />
              <Text style={styles.profileText}>{item.username}</Text>
            </View>
          </TouchableHighlight>
        </View>

        <Products
          historySwitch={historySwitch}
          auth={auth}
          products={products.filter(product => !product.isRecommend)}
          horizontal
          onPressProduct={onPressProduct}
          heartistUsername={item.username}
          heartistsPick={item}
          ListHeaderComponent={<View style={{width:Constants.viewHorizontalPadding}} />}
          ListFooterComponent={<View style={{width:Constants.viewHorizontalPadding}} />}
          enableProductLabelPreOrder
          enableProductLabelHeartitLogo
        />
      </View>
    );
  }


  render() {
    const { data } = this.props;

    const count = 3;
    const heartists = data.slice(0, count);

    return (
      <FlatList
        data={heartists}
        keyExtractor={item => item._id} // eslint-disable-line no-underscore-dangle
        ItemSeparatorComponent={() => <View style={{ height: 30 }} />}
        renderItem={({ item }) => this.renderItem(item)}
      />
    );
  }
}


const styles = StyleSheet.create({
  contentsContainer: {
    marginHorizontal: -1 * Constants.viewHorizontalPadding,
  },
  header: {
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  profileTouchEffectContainer: {
    marginBottom: 10,
  },
  profileContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  profileText: {
    marginLeft: 10,
    lineHeight: 19,
    ...Styles.SFUITextRegular,
    ...Styles.fontSize16,
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});

const stylesHtml = {
  profileImage: {
    border: `1px solid ${Colors.profileImageBorder}`,
    borderRadius: 20,
    height: 40,
    width: 40,
  },
};

HeartistsPicks.propTypes = {
};

HeartistsPicks.defaultProps = {
};

export default HeartistsPicks;
