import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { loadEntities } from 'react-shared';
import * as adminActions from 'react-shared/adminActions';
import { has, values } from 'lodash';

import { Cloudinary } from 'cloudinary-core';
import config from '../../config'
import { Add, Remove, ExpandMore, Search } from '@material-ui/icons';
import { ListProduct } from './ListProduct';

const cloudinary = Cloudinary.new();
cloudinary.config({
  ...config.get('cloudinary'),
  secure: true,
});


const styles = theme => ({
  divSearchFilter: {
    marginTop: 20,
  },
  textField: {
    width: 300,
  },
  input: {
    ...theme.typography.subheading,
  },
  table: {
    width: '100%',
    padding: 5,
  },
  tableCellType: {
    width: 60,
    maxWidth: 60,
  },
  tableCellName: {
    width: 300,
    maxWidth: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tableCellBrand: {
    width: 100,
  },
  divPrice: {
    textAlign: 'right',
  },
  spanDiscPct: {
    fontSize: 10,
    color: 'red',
  },
  spanOrgPrice: {
    fontSize: 10,
    textDecoration: 'line-through',
  },
  spanSellPrice: {
    fontSize: 14,
  }
});


class ProductSelector extends ListProduct {

  renderSearchBox() {
    const { classes, onChangeKeyword, searchKeyword, isGodoOnly, onChangeIsGodoOnly } = this.props;
    return (
      <div className={classes.divSearchFilter}>
        <TextField
          className={classes.textField}
          style={{ marginRight: 20 }}
          InputProps={{ className: classes.input }}
          onChange={onChangeKeyword}
          onKeyPress={(evt) => {
            if (evt.key === 'Enter') {
              this.handleLoadMore(false);
            }
          }}
          placeholder="Search product"
          value={searchKeyword}
        />
        <Button
          variant="contained"
          size="small"
          onClick={() => this.handleLoadMore(false)}
          style={{ marginRight: 20 }}
        >
          <Search />
        </Button>
        <span className={classes.label}>고도몰 상품만 보기</span>
        <Checkbox
          name="godoOnly"
          checked={isGodoOnly}
          onClick={onChangeIsGodoOnly}
        />
      </div>
    )
  }

  renderColumns() {
    const { classes } = this.props;
    return (
      <>
        <TableCell className=''></TableCell>
        <TableCell className={classes.tableCellType}>종류</TableCell>
        <TableCell className={classes.tableCellName}>상품명</TableCell>
        <TableCell className={classes.tableCellBrand}>브랜드</TableCell>
        <TableCell className=''>가격</TableCell>
        <TableCell className=''></TableCell>
      </>
    );
  }

  renderRow(product) {
    const { classes } = this.props;
    return (
      <>
        <TableCell>
          {product.media ? (
            <img src={product.media.secure_url || product.media.secureUrl || cloudinary.url(product.media.publicId)} style={{ width: 60, height: 60 }} />
          ) : (
              <div style={{ width: 60, height: 60, backgroundColor: '#ddd' }}></div>
            )}
        </TableCell>
        <TableCell className={classes.tableCellType}>{product.godoGoodsNo ? '고도' : '아웃'}</TableCell>
        <TableCell className={classes.tableCellName}><span title={product.name}>{product.name}</span></TableCell>
        <TableCell className={classes.tableCellBrand}>{product.brand}</TableCell>
        <TableCell>{this.renderPrice(product)}</TableCell>
      </>
    )
  }

  renderProductsTable() {
    const { adminProducts, checkedProducts, pagination: { isFetching, nextPageUrl }, onCheckProduct } = this.props;
    const productList = adminProducts
      .filter((product) => !has(checkedProducts, product._id))
      .sort((a, b) => b.id - a.id);
    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className=''></TableCell>
              {this.renderColumns()}
            </TableRow>
          </TableHead>
          <TableBody>
            {productList.map(product => (
              <TableRow key={`product_${product._id}`}>
                <TableCell>
                  <Button onClick={() => (onCheckProduct && onCheckProduct(product))}>
                    <Add />
                  </Button>
                </TableCell>
                {this.renderRow(product)}
              </TableRow>
            ))}
            {(!isFetching && nextPageUrl) && (
              <TableRow>
                <TableCell colSpan={7}>
                  <p style={{ textAlign: 'center', marginTop: 10 }}>
                    <Button variant="outlined" onClick={() => this.handleLoadMore(true)}>
                      더 불러오기
                    </Button>
                  </p>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </>
    );
  }

  renderCheckedProducsTable() {
    const { checkedProducts, onCheckProduct } = this.props;
    const productList = values(checkedProducts).sort((a, b) => b.id - a.id);
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className=''></TableCell>
            {this.renderColumns()}
          </TableRow>
        </TableHead>
        <TableBody>
          {productList.map(product => (
            <TableRow key={`checked_product_${product._id}`}>
              <TableCell>
                <Button onClick={() => (onCheckProduct && onCheckProduct(product))}>
                  <Remove color='error' />
                </Button>
              </TableCell>
              {this.renderRow(product)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  render() {
    const { checkedProducts } = this.props;
    const checkedProductCount = values(checkedProducts).length;
    return (
      <Card elevation={0}>
        <CardContent>
          {this.renderSearchBox()}
          <Accordion style={{ marginTop: '16px' }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
            >
              <Typography>선택된 상품이 {checkedProductCount}개 있습니다.</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {this.renderCheckedProducsTable()}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionDetails>
              {this.renderProductsTable()}
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(state => ({
    ...loadEntities(state, 'adminProducts', 'adminProducts'),
  }), adminActions)
)(ProductSelector);
