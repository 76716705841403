export function getBirthdayString(dateObj) {
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();

  let monthTxt = `${month}`;
  let dayTxt = `${day}`;
  if (month < 10) {
    monthTxt = `0${month}`;
  }
  if (day< 10) {
    dayTxt = `0${day}`;
  }

  return `${year}.${monthTxt}.${dayTxt}`;
}

// str format = '2020-10-30 11:23:43'
// eslint-disable-next-line import/prefer-default-export
export function parseDate(str, withTime = false) {
  if (str === null) {
    return null;
  }

  const year = str.substr(0, 4);
  const month = str.substr(5, 2) - 1;
  const day = str.substr(8, 2);
  const hours = withTime ? str.substr(11, 2) : 0;
  const minutes = withTime ? str.substr(14, 2) : 0;
  const seconds = withTime ? str.substr(14, 2) : 0;

  return new Date(year, month, day, hours, minutes, seconds);
}

export function getRemaingTime(destTime, withSeconds=false) {
  const now = Date.now();
  const remaingTime = new Date(destTime - now);
  const milliseconds = remaingTime % 1000;
  const seconds = Math.ceil((remaingTime / 1000)) % 60;
  const minutes = Math.ceil((remaingTime / 1000 / 60) % 60);
  const hours = Math.floor((remaingTime / 1000 / 60 / 60) % 24);
  const days = Math.floor(remaingTime / 1000 / 60 / 60 / 24);

  const result = {
    minutes,
    hours,
    days,
  };
  if (withSeconds) {
    result['seconds'] = seconds;
    result['milliseconds'] = milliseconds;
  }
  return result;
}

export function convertKstToUtc(dateTime) {
  // Javascript applies local timezone offset to naive timestamp.
  // tzOffset is used to correct this offset
  const tzOffset = new Date().getTimezoneOffset() * 60000;
  // KST offset (GMT+9:00)
  const kstOffset = 9 * 60 * 60000;
  // Convert to UTC (KST -> Local -> UTC)
  return new Date(dateTime.getTime() - tzOffset - kstOffset);
}

export function getDateTimeString(datetime, simple=false) {
  const month = datetime.getMonth() + 1;
  const day = datetime.getDate();
  const year = datetime.getFullYear();

  const now = Date.now();
  const delta = now - datetime;
  if (delta < 60 * 60 * 1000) {
    const min = Math.ceil(delta / (60 * 1000));
    return `${min}분전`;
  } else if (delta < 24 * 60 * 60 * 1000) {
    const hours = Math.round(delta / (60 * 60 * 1000));
    return `${hours}시간전`;
  } else if (delta < 9 * 24 * 60 * 60 * 1000) {
    const days = Math.round(delta / (24 * 60 * 60 * 1000));
    return `${days}일전`;
  } else if (delta < 365 * 24 * 60 * 60 * 1000) {
    if (simple) {
      return `${month}.${day}`;
    } else {
      return `${month}월 ${day}일`;
    }
  } else {
    if (simple) {
      return `${year}.${month}.${day}`;
    } else {
      return `${year}년 ${month}월 ${day}일`;
    }
  }

}

export function dateFromNow(date) {
  const DAYS_OF_WEEK = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
  const MONTHS_OF_YEAR = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const daysBetween = (date1, date2) => {
    const ONE_DAY_ON_SECONDS = 1000 * 60 * 60 * 24;
    const date1Ms = date1.getTime();
    const date2Ms = date2.getTime();

    const differenceMs = date2Ms - date1Ms;
    return Math.round(differenceMs / ONE_DAY_ON_SECONDS);
  }

  const getHoursFromDate = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
  }

  const dateFromNow = (date) => {
    const currentDate = new Date();

    if(date.getUTCDate() === currentDate.getUTCDate()
        && date.getUTCMonth() === currentDate.getUTCMonth()
        && date.getUTCFullYear() === currentDate.getUTCFullYear()
    ) {
      const hours = Math.floor(Math.abs(date - currentDate) / 36e5);
      if (hours === 0) {
        const minutes = Math.round(((Math.abs(date - currentDate) % 86400000) % 3600000) / 60000);
        return `${minutes}분전`;
      } else {
        return `${Math.floor(hours)}시간전`;
      }
    } else {
      if (date.getUTCFullYear() < currentDate.getUTCFullYear()) {
        return `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일`;
      } else if (daysBetween(date, currentDate) > 9) {
        return `${date.getMonth() + 1}월 ${date.getDate()}일`;
      } else {
        return `${daysBetween(date, currentDate)}일전`;
      }
    }
  }
  return dateFromNow(date);
}


export function getDateString(dateObj, delimiter='.') {
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();

  let monthTxt = `${month}`;
  let dayTxt = `${day}`;
  if (month < 10) {
    monthTxt = `0${month}`;
  }
  if (day< 10) {
    dayTxt = `0${day}`;
  }

  return `${year}${delimiter}${monthTxt}${delimiter}${dayTxt}`;
}
