/* eslint-disable react/no-danger */
import React from 'react';
import {
  StyleSheet, View, Text, Dimensions, TouchableHighlight, FlatList,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions, loadEntities } from 'shared';

import SmallBlank from '../../../nativeComponents/SmallBlank';
import ReviewItem from '../../../nativeComponents/ReviewItem';
import LikeNormalIcon from '../../../icons/LikeNormalIcon';
import LikeSelectedIcon from '../../../icons/LikeSelectedIcon';
import LikeSelectedHalfIcon from '../../../icons/LikeSelectedHalfIcon';

import Colors from '../../../constants/Colors';
import Styles from '../../../constants/Styles';
import Constants from '../../../constants/Constants';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const count = 5;

const webStyles = {
  contentImage: {
    width: '100%',
    aspectRatio: 1.3,
    marginTop: 4,
  },
  blankContainer: {
    height: 220,
  },
};


const styles = StyleSheet.create({
  headerContainer: {
    paddingTop: 30,
    paddingBottom: 10,
    alignItems: 'center',
  },
  reviewCount: {
    fontSize: 18,
    marginBottom: 20,
    ...Styles.appleSDGothicNeoRegular,
  },
  likeIcon: {
    marginHorizontal: 3,
  },
  reviewStarText: {
    color: 'rgb(238, 36, 36)',
    fontSize: 14,
    marginTop: 7,
    ...Styles.appleSDGothicNeoSemiBold,
  },
  itemSeparator: {
    borderTopWidth: 1,
    borderTopColor: Colors.lightGray,
    marginStart: 20,
  },
  footerContainer: {
    borderTopWidth: 1,
    borderTopColor: Colors.lightGray,
    marginStart: 20,
    paddingEnd: 20,
    paddingTop: 20,
    paddingBottom: 30,
    alignItems: 'center',
  },
  showAllButton: {
    borderWidth: 1,
    width: 164,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
  },
  showAllButtonText: {
    fontSize: 14,
    ...Styles.appleSDGothicNeoBold,
  },
  itemContainer: {
    marginTop: 14,
    marginBottom: 12,
    marginStart: 10,
  },
  secretArticleIconContainer: {
    marginEnd: 3,
  },
  itemSubject: {
    fontSize: 14,
    width: windowWidth - 20 - 70, // windoWidth - itemContainerMargin - itemInfoImage
  },
  attachImageIconContainer: {
    paddingStart: 2,
  },
  itemInfoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemInfoStartContainer: {

  },
  itemInfoSEndContainer: {
  },
  itemInfoTextContainer: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  itemInfoText: {
    fontSize: 12,
    color: '#666',
  },
  itemInfoSeparator: {
    height: '100%',
    borderStartWidth: 1,
    borderStartColor: '#ccc',
    marginStart: 10,
    marginEnd: 10,
  },
  itemInfoImage: {
    aspectRatio: 1.2,
    height: 50,
    marginEnd: 10,
  },
  itemContent: {
    backgroundColor: '#eee',
    paddingTop: 15,
    paddingStart: 20,
    paddingEnd: 20,
    paddingBottom: 15,
  },
  contentContainer: {
  },
  answerContainer: {
    marginTop: 10,
    marginBottom: 20,
    marginStart: 10,
    marginEnd: 15,
    borderStartWidth: 1,
    paddingStart: 10,
    paddingTop: 10,
    backgroundColor: '#eee',
  },
  sellerMarkContainer: {
    backgroundColor: '#555',
    paddingStart: 4,
    paddingEnd: 4,
    paddingTop: 3,
    paddingBottom: 3,
    borderRadius: 7,
  },
  sellerMarkText: {
    color: '#fff',
    fontSize: 8,
  },
  answer: {
    fontSize: 14,
  },
  answerDate: {
    marginTop: 10,
    color: '#666',
    fontSize: 10,
  },
  loadingIndicator: {
    marginTop: 20,
    marginBottom: 20,
  },
});


function renderReviewStar(reviewStar) {
  const starCount = Math.max(Math.min(reviewStar, 5), 0);

  const stars = [];
  for (let i = 0; i < Math.floor(starCount); i++) {
    stars.push(
      <View key={`starOn${i}`} style={styles.likeIcon}>
        <LikeSelectedIcon />
      </View>
    );
  }

  if (starCount % 1 >= 0.5) {
    stars.push(
      <View key="LikeSelectedHalfIcon" style={webStyles.likeIcon}>
        <LikeSelectedHalfIcon />
      </View>
    );
  }

  const iconsLength = stars.length;
  for (let i = 0; i < 5 - iconsLength; i++) {
    stars.push(
      <View key={`starOff${i}`} style={styles.likeIcon}>
        <LikeNormalIcon opacity={0.251} />
      </View>
    );
  }
  return (
    <View style={{ flexDirection: 'row' }}>
      {stars}
    </View>
  );
}


export class TabReview extends React.Component {
  state = {
    refreshing: false,
    openItems: [],
  };


  componentDidMount() {
    // console.log('TabReview componentDidMount()');

    this.loadReviewList(false, { count });
  }

  componentDidUpdate(prevProps) {
    // console.log('TabReview componentDidUpdate()');

    const {
      loadShopProductReviewList: prevLoadShopProductReviewList,
    } = prevProps;
    const { productId, loadShopProductReviewList } = this.props;

    if (!prevLoadShopProductReviewList && loadShopProductReviewList) {
      loadShopProductReviewList(productId, false, { count });
    }
  }

  loadReviewList = async (nextPage, query) => {
    const { productId, loadShopProductReviewList } = this.props;
    return loadShopProductReviewList(productId, nextPage, query);
  }

  loadReviewArticle = articleId => {
    const { productId, loadShopProductReviewArticle } = this.props;
    loadShopProductReviewArticle(productId, articleId);
  }

  onPressItem = ({ sno, auth: { view } }) => () => {
    const itemId = sno;

    if (view !== 'y') {
      alert('비밀글은 작성자 본인만 볼 수 있습니다.');
      return;
    }

    this.setState(prevState => {
      const { openItems } = prevState;
      const index = openItems.indexOf(itemId);

      if (index >= 0) {
        openItems.splice(index, 1);
      } else {
        openItems.push(itemId);
        this.loadReviewArticle(itemId);
      }

      return {
        openItems,
      };
    });
  }

  onPressShowAll = () => {
    const { router, productId } = this.props;

    router.push(`/product/${productId}/reviews`);
  }


  // onEndReached = () => {
  //   // console.log('TabReview onEndReached()');
  //   const {
  //     reviewListPagination,
  //   } = this.props;

  //   if (reviewListPagination.nextPageUrl) {
  //     this.loadReviewList(true, { count });
  //   }
  // };

  // renderImage = ({ fid, imgPath }) => {
  //   return (
  //     <img
  //       key={fid}
  //       alt={fid}
  //       style={webStyles.contentImage}
  //       src={imgPath}
  //     />
  //   );
  // }

  // renderItemContent = item => {

  //   const {
  //     contents, uploadImageList, answerSubject, answerContents, answerModDt,
  //   } = item;

  //   // Show loading indicator until loadReviewArticle finishes.
  //   if (typeof contents === 'undefined') {
  //     return (
  //       <View style={styles.itemContent}>
  //         <ActivityIndicator style={styles.loadingIndicator} color="gray" />
  //       </View>
  //     );
  //   }

  //   if (contents === '') {
  //     return null;
  //   }

  //   const answer = answerContents;
  //   const answerDate = (answerModDt || '').slice(0, -3);

  //   return (
  //     <View style={styles.itemContent}>
  //       <View style={styles.contentContainer}>
  //         <div className="html-container" dangerouslySetInnerHTML={{ __html: contents }} />
  //         {
  //           (uploadImageList || []).map(image => this.renderImage(image))
  //         }
  //       </View>
  //       {answer
  //         && (
  //           <View style={styles.answerContainer}>
  //             <Text style={styles.answer}>
  //               <View style={styles.sellerMarkContainer}>
  //                 <Text style={styles.sellerMarkText}>판매자</Text>
  //               </View>
  //             &nbsp;&nbsp;
  //               {answerSubject}
  //             </Text>
  //             <div className="html-container" dangerouslySetInnerHTML={{ __html: answer }} />
  //             <Text style={styles.answerDate}>{answerDate}</Text>
  //           </View>
  //         )
  //       }
  //     </View>
  //   );
  // }


  renderItem = ({ item }) => {
    const { productId } = this.props;

    return (
      <ReviewItem
        item={item}
        productId={productId}
        controlButtonEnabled={false}
      />
    );
  };

  renderHeader = () => {
    const { product: { reviewCnt, reviewStar } } = this.props;

    return (
      <View style={styles.headerContainer}>
        <Text style={styles.reviewCount}>
          {`리뷰 총 ${reviewCnt}건`}
        </Text>
        {renderReviewStar(reviewStar)}
        <Text style={styles.reviewStarText}>
          {`평균 ${reviewStar}점`}
        </Text>

      </View>
    );
  }

  renderFooter = () => (
    <View style={styles.footerContainer}>

      <TouchableHighlight
        underlayColor={Colors.touchEffectColorWhite}
        style={styles.showAllButton}
        onPress={this.onPressShowAll}
      >
        <Text style={styles.showAllButtonText}>전체보기</Text>
      </TouchableHighlight>
    </View>
  )

  render() {
    const {
      reviewListArticles,
      tabViewContentHeight,
    } = this.props;

    const { refreshing } = this.state;
    const articles = [
      ...reviewListArticles.slice(0, count),
    ];

    if (articles.length <= 0) {
      return (
        <View style={webStyles.blankContainer}>
          <SmallBlank title='상품 리뷰가 없습니다' />
        </View>
      );
    }
    return (
      <FlatList
        style={{ minHeight: tabViewContentHeight }}
        data={articles}
        keyExtractor={item => item.sno}
        renderItem={this.renderItem}
        ListHeaderComponent={this.renderHeader}
        ListFooterComponent={this.renderFooter}
        ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
        onRefresh={() => {
          if (!refreshing) {
            this.setState({ refreshing: true });
            this.loadReviewList(false, { count }).then(this.setState({ refreshing: false }));
          }
        }}
        refreshing={refreshing}
        onLayout={this.onLayout}
      />
    );
  }
}

export default connect(
  (state, { productId }) => ({
    ...loadEntities(state,
      actions.getShopProductReviewListPaginationKey(productId),
      actions.getShopProductReviewListEntitiesKey(productId),
      'reviewListPagination',
      'reviewListArticles'),
  }),
  actions,
)(TabReview);
