import React from 'react';
import { ScrollView, Text,
  TextInput, TouchableHighlight,
  StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';

import { actions, loadEntities } from 'shared';

import { Colors, Styles, Constants } from '../../../constants';

import Ic14ArrowDown from '../../../icons-v2/Ic14ArrowDown';
import Ic14ListSelected from '../../../icons-v2/Ic14ListSelected';
import RadioNormal from '../../../icons-v2/RadioNormalIcon';
import RadioSelected from '../../../icons-v2/RadioSelectedIcon';

import {
  Button, CheckBox, ShippingAddressInfo,
 } from '../../../components-v2';

 import { sectionStyles, modalStyles } from './commonStyles';


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  pickupMethodButton: {
    height: 17,
    padding: 0,
    marginVertical: 10,
    borderWidth: 0,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexShrink: 1,
  },
  pickupMethodLine: {
    flexShrink: 1,
    flexDirection: 'row',
  },
  pickupMethodText: {
    color: '#000',
    fontSize: 14,
    lineHeight: 16.8,
    marginStart: 8,
    ...Styles.pretendard400,
  },
  addressText: {
    marginBottom: 10,
    color: '#000',
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard400,
  },
  trackingInput: {
    paddingVertical: 13,
    paddingHorizontal: 20,
    borderWidth: 0.5,
    borderColor: '#000',
  },
  courierSelector: {
    height: 44,
    marginBottom: 10,
    borderWidth: 0.5,
    paddingHorizontal: 20,
    paddingVertical: 13,
  },
  courierSelectorInnerContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  courierSelectorText: {
    color: '#000',
    fontSize: 15,
    lineHeight: 18,
    ...Styles.pretendard400,
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
  checkboxLabel: {
    marginStart: 8,
    color: '#000',
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard600,
  },
  returnAddressLine: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  returnAddressValue: {
    flexGrow: 1,
  },
  returnAddressValueText: {
    fontSize: 16,
    lineHeight: 19.2,
    color: '#000',
    ...Styles.pretendard400,
  },
  returnAddressLabel: {
    width: 100,
  },
  returnAddressLabelText: {
    color: '#00000080',
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard400,
  },
  headerButton: {
    borderWidth: 0,
    borderRadius: Constants.touchEffectBorderRadius,
    padding: Constants.touchEffectWidth,
    marginVertical: 1 - Constants.touchEffectWidth,
    marginHorizontal: -1 * Constants.touchEffectWidth,
  },
});


function renderReceiverAddress(orderInfo) {
  if (!orderInfo) {
    return null;
  }

  const {
    receiverName, receiverCellPhone,
    receiverAddress, receiverAddressSub,
    orderMemo,
  } = orderInfo;
  return (
    <View style={sectionStyles.sectionContainer}>
      <View style={{ width: 185 }}>
        <Text style={styles.addressText}>{receiverName}</Text>
        <Text style={styles.addressText}>{receiverAddress + ', ' + receiverAddressSub}</Text>
        <Text style={[styles.addressText, {marginBottom: 0}]}>{receiverCellPhone}</Text>
      </View>
    </View>
  );
}

class PickupSelector extends React.Component {
  state = {
    checkoutInfo: {},
  }
  shippingAddressInfoRef = React.createRef();

  showSelectorModal = () => {
    const { setModalProps } = this.props;
    setModalProps({
      isVisible: true,
      isBottomPlaced: true,
      children: this.renderSelectorModal()
    });
  }

  renderSelectorModal = () => {
    const { setModalProps, selectedCourier, onChangeSelectedCourier } = this.props;
    const courierList = [
      "CJ대한통운", "우체국택배", "롯데택배", "로젠택배",
      "CVSnet 편의점택배(GS25)", "CU 편의점택배", "한진택배",
      "기타",
    ];

    const onClick = () => {
      setModalProps({isVisible: false, isBottomPlaced: false});
    };

    return (
      <div onClick={onClick}>
        <View style={modalStyles.innerContainer} onClick={e => e.stopPropagation()}>
          <View style={modalStyles.headerContainer}>
            <View style={{flex:1}}/>
            <View style={modalStyles.headerTitleContainer}>
              <Text style={modalStyles.headerTitleText}>
                택배사 선택
              </Text>
            </View>
            <View style={{flex:1, alignItems:'flex-end'}}>
              <TouchableHighlight
                onPress={() => onClick()}
                underlayColor={Colors.touchEffectColorWhite}
                style={modalStyles.headerButton}
              >
                <Text style={modalStyles.headerButtonText}>닫기</Text>
              </TouchableHighlight>
            </View>
          </View>
          <ScrollView style={modalStyles.contentContainer}>
            {courierList.map((courier, index) => {
              return (
                <TouchableHighlight
                  key={index}
                  style={modalStyles.selectorButton}
                  underlayColor={Colors.touchEffectColorWhite}
                  onPress={() => {
                    onClick();
                    onChangeSelectedCourier(courier);
                  }}
                >
                  <View style={modalStyles.contentLineContainer}>
                    <Text style={[modalStyles.contentText, (courier == selectedCourier) ? modalStyles.selectedContent: {}]}>
                      {courier}
                    </Text>
                    {(courier == selectedCourier) && (
                      <Ic14ListSelected />
                    )}
                  </View>
                </TouchableHighlight>
              );
            })}
          </ScrollView>
        </View>
      </div>
    );
  }

  renderTrackingInput = () => {
    const { selectedCourier, onChangeReturnTracking, returnTracking,
      withoutTracking, onChangeWithoutTracking,
    } = this.props;

    return (
      <View>
        <View style={sectionStyles.sectionHeaderContainer}>
          <Text style={sectionStyles.sectionHeaderText}>
            송장 정보 입력
          </Text>
        </View>

        <View style={sectionStyles.sectionContainer}>
          <Button
            containerStyle={styles.courierSelector}
            innerComponent={(
              <View style={styles.courierSelectorInnerContainer}>
                <Text style={styles.courierSelectorText}>
                  {selectedCourier ? selectedCourier: "택배사 선택"}
                </Text>
                <Ic14ArrowDown />
              </View>
            )}
            onPress={() => this.showSelectorModal()}
          />

          <TextInput
            onChangeText={(text) => onChangeReturnTracking(text)}
            value={returnTracking}
            style={styles.trackingInput}
            placeholder="송장번호를 입력해주세요."
            placeholderTextColor="#00000080"
          />

          <View style={styles.checkboxContainer}>
            <CheckBox
              checked={withoutTracking}
              onPress={() => {
                onChangeWithoutTracking(!withoutTracking)
              }}
            />
            <Text style={styles.checkboxLabel}>송장번호 없이 보내기</Text>
          </View>
        </View>
      </View>
    );
  }

  renderReturnAddress = () => {
    return (
      <View>
        <View style={sectionStyles.sectionHeaderContainer}>
          <Text style={sectionStyles.sectionHeaderText}>
            교환 보내실 곳
          </Text>
        </View>

        <View style={sectionStyles.sectionContainer}>
          <View style={styles.returnAddressLine}>
            <View style={styles.returnAddressLabel}>
              <Text style={styles.returnAddressLabelText}>택배사</Text>
            </View>
            <View style={styles.returnAddressValue}>
              <Text style={styles.returnAddressValueText}>우체국 택배 (착불)</Text>
            </View>
          </View>

          <View style={styles.returnAddressLine}>
            <View style={styles.returnAddressLabel}>
              <Text style={styles.returnAddressLabelText}>받는 사람</Text>
            </View>
            <View style={styles.returnAddressValue}>
              <Text style={styles.returnAddressValueText}>하트잇</Text>
            </View>
          </View>

          <View style={styles.returnAddressLine}>
            <View style={styles.returnAddressLabel}>
              <Text style={styles.returnAddressLabelText}>전화 번호</Text>
            </View>
            <View style={styles.returnAddressValue}>
              <Text style={styles.returnAddressValueText}>010-1234-5678</Text>
            </View>
          </View>

          <View style={[styles.returnAddressLine, { marginBottom: 0}]}>
            <View style={styles.returnAddressLabel}>
              <Text style={styles.returnAddressLabelText}>전화 번호</Text>
            </View>
            <View style={styles.returnAddressValue}>
              <Text style={styles.returnAddressValueText}>010-1234-5678</Text>
            </View>
          </View>

        </View>
      </View>
    );
  }

  onShippingAddressChanged = shippingAddressInfo => {
    console.log('onShippingAddressChanged shippingAddress=', shippingAddressInfo);
    const { auth, onChangeCheckoutInfo } = this.props;

    const {
      sno,
      shippingTitle,
      shippingPhone: orderPhone,
      shippingCellPhone,
      shippingName,
      shippingZonecode,
      shippingZipcode: receiverZipcode,
      shippingAddress,
      shippingAddressSub,
      orderMemo = '',
      saveToShippingAddressList,
      newDefaultFl,
    } = shippingAddressInfo;

    this.setState(({ checkoutInfo: prevCheckoutInfo }) => ({
      selectedAddressIndex: sno,
      checkoutInfo: {
        ...prevCheckoutInfo,
        orderPhone,
        orderCellPhone: shippingCellPhone,
        orderEmail: auth.email,
        receiverName: shippingName,
        receiverZonecode: shippingZonecode,
        receiverZipcode,
        receiverAddress: shippingAddress,
        receiverAddressSub: shippingAddressSub,
        receiverPhone: orderPhone,
        receiverCellPhone: shippingCellPhone,
        orderMemo,
      },
      shippingAddressInfo: {
        shippingTitle,
        shippingName,
        shippingZonecode,
        shippingAddress,
        shippingAddressSub,
        shippingCellPhone,
        saveToShippingAddressList,
        defaultFl: newDefaultFl ? 'y' : 'n',
      },
    }), () => {
      onChangeCheckoutInfo(this.state.checkoutInfo);
    });
  }

  addressSelectedCallback = () => {
    const { shippingAddresses } = this.props;
    const selectedAddressIndex = shippingAddresses.findIndex(x => x.sno === selectedAddressId);
    if (selectedAddressIndex >= 0) {
      this.setState({
        selectedAddressIndex: selectedAddressIndex,
        newAddress: false,
      });
    }
  }

  renderPickupAddress = () => {
    const { router, orderInfo, setModalProps } = this.props;

    const onRef = ref => {
      if (!ref)
        return;

      this.shippingAddressInfoRef = ref.getWrappedInstance();
    }

    return (
      <View>
        <View style={sectionStyles.sectionHeaderContainer}>
          <Text style={sectionStyles.sectionHeaderText}>
            수거지 정보
          </Text>
          <Button
            containerStyle={styles.headerButton}
            titleStyle={sectionStyles.sectionHeaderSubtext}
            title="배송지 목록"
            onPress={() => {
              if (this.shippingAddressInfoRef) {
                this.shippingAddressInfoRef.showAddressListScreen();
              }
            }}
          />
        </View>

        <View style={sectionStyles.sectionContainer}>
          <ShippingAddressInfo
            ref={onRef}
            router={router}
            onDataChanged={this.onShippingAddressChanged.bind(this)}
            addressEditScreenName="ShippingAddressEdit"
            setModalProps={setModalProps}
          />
        </View>
      </View>
    );
  }

  renderPickUpMethod = () => {
    const { selectedPickupMethod, onChangeSelectedPickupMethod } = this.props;

    return (
      <View style={sectionStyles.sectionContainer}>
        <Button
          containerStyle={styles.pickupMethodButton}
          innerComponent={(
            <View style={styles.pickupMethodLine}>
              {selectedPickupMethod == "requestPickup" ? <RadioSelected /> : <RadioNormal />}
              <Text style={[styles.pickupMethodText, selectedPickupMethod == "requestPickup" ? {...Styles.pretendard600}: {}]}>수거하러 와주세요</Text>
            </View>
          )}
          onPress={() => {
            onChangeSelectedPickupMethod("requestPickup");
          }}
        />
        <Button
          containerStyle={styles.pickupMethodButton}
          innerComponent={(
            <View style={styles.pickupMethodLine}>
              {selectedPickupMethod == "alreadySent" ? <RadioSelected /> : <RadioNormal />}
              <Text style={[styles.pickupMethodText, selectedPickupMethod == "alreadySent" ? {...Styles.pretendard600}: {}]}>이미 보냈어요</Text>
            </View>
          )}
          onPress={() => {
            onChangeSelectedPickupMethod("alreadySent");
          }}
        />
        <Button
          containerStyle={[styles.pickupMethodButton, {marginBottom: 0}]}
          innerComponent={(
            <View style={styles.pickupMethodLine}>
              {selectedPickupMethod == "direct" ? <RadioSelected /> : <RadioNormal />}
              <Text style={[styles.pickupMethodText, selectedPickupMethod == "direct" ? {...Styles.pretendard600}: {}]}>직접 택배로 보낼게요</Text>
            </View>
          )}
          onPress={() => {
            onChangeSelectedPickupMethod("direct");
          }}
        />
      </View>
    );
  }

  render() {
    const { selectedPickupMethod } = this.props;
    return (
      <View style={styles.container}>
        <View style={sectionStyles.sectionHeaderContainer}>
          <Text style={sectionStyles.sectionHeaderText}>
            수거 방법
          </Text>
        </View>

        {this.renderPickUpMethod()}

        <View style={sectionStyles.sectionSeparator} />

        {(selectedPickupMethod == "requestPickup") && 
          this.renderPickupAddress()
        }

        {(selectedPickupMethod == "alreadySent") && 
          this.renderTrackingInput()
        }

        {(selectedPickupMethod == "direct") && 
          this.renderReturnAddress()
        }
      </View>
    );
  }
}

export default PickupSelector;
