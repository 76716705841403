export function getThumbnailBackgroundColor(shopName) {
  switch(shopName) {
    case 'mustit':
    case 'farfetch':
    case 'mytheresa':
    case '24s':
    case 'cettire':
    case 'net-a-porter':
    case 'nowinparis':
    case 'okmall':
    case 'ssense':
    case 'wconcept':
    case 'zara':
      return '#fff';
    case 'ln-cc':
      return '#e2e2e0';
    default:
      return '#f6f7f8';
  }
}
