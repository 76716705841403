import React from 'react';
import { StyleSheet, View, Text } from 'react-native';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Constants, Colors } from '../../../constants';
import '../../../css/productDetailCarousel.css';
import { getThumbnailBackgroundColor } from 'shared/util';

import _styles from '../../../css/modules/carousel.module.css';
import { ArrowForwardIosIcon, ArrowBackIosIcon } from '../../../icons-v2';

import {
  Image
} from '../../../components-v2';


const styleHtml = {
  container: {
  },
};

const styles = StyleSheet.create({
  swiperDot: {
    flex: 1,
    backgroundColor: 'black',
    width: 'auto',
    height: 2,
    marginRight: 2,
    opacity: 0.2,
  },
  swiperDotActive: {
    flex: 1,
    backgroundColor: 'black',
    width: 'auto',
    height: 2,
    marginRight: 2,
  },
});


export default class ProductImages extends React.Component {
  scrollViewProps = null;
  state = {
    currentSlideIndex: 0,
    isLeftEnd:false,
    isRightEnd:false,
  };

  handleLeftClickEvent(e) {

    const nowIndex = this.state.currentSlideIndex;

    let newIndex = nowIndex-1;
    if(newIndex < 0){
      newIndex = 0;
    }

    this.setState({currentSlideIndex : newIndex});
  }

  handleRightClickEvent(e) {
    if (this.props.imagesDetail && this.props.imagesDetail.length > 0){
      const nowIndex = this.state.currentSlideIndex;
      let newIndex = nowIndex+1;
      if(newIndex > this.props.imagesDetail.length - 1){
        newIndex = this.props.imagesDetail.length - 1;
      }
      this.setState({currentSlideIndex : newIndex});
    }
  }

  shouldComponentUpdate(prevProps, nextState) {
    const oldProduct = prevProps.product;
    const newProduct = this.props.product;

    if (oldProduct && newProduct && newProduct._id && oldProduct._id === newProduct._id) {
      if( this.state.currentSlideIndex !== nextState.currentSlideIndex){
        return true;
      }
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    const { product: prevProduct } = prevProps;
    const { product } = this.props;
    if (prevProduct && product && prevProduct._id != product._id) {
      this.setState({ currentSlideIndex: 0 });
    }
  }

  renderIndicator(handler, isSelected, index, label) {
    if (isSelected) {
      return (
        <View style={styles.swiperDotActive} />
      );
    } else {
      return (
        <View style={styles.swiperDot} />
      );
    }
  }

  render() {
    const { imagesDetail, product } = this.props;
    const {
      currentSlideIndex,
      isLeftEnd,
      isRightEnd
    } = this.state;
    const imageBackground = { backgroundColor: '#fff', objectFit: 'contain' };

    const aspectRatio = 1;
    const imageList = (imagesDetail || []).map((image, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Image key={index} source={image} aspectRatio={aspectRatio} style={imageBackground} />
    ));

    const updateCurrentSlide = index => {
      let newIndex = index;
      if (!newIndex && newIndex !== 0) {
        newIndex = 0;
      }

      if (currentSlideIndex !== newIndex) {
        this.setState({ currentSlideIndex: newIndex });
      }
    };

    return (
      <div id="productDetailCarousel" style={styleHtml.container} className={_styles._desktop_carousel}>
        <Carousel
          // 상품이 바뀌면 re-render하기 위한 key
          key={`carousel-${product._id}`}
          renderThumbs={() => []}
          renderIndicator={this.renderIndicator}
          statusFormatter={(currentItem, total) => `${currentItem}/${total}`}
          selectedItem={currentSlideIndex}
          onChange={updateCurrentSlide}
          renderArrowPrev={() => null}
          renderArrowNext={() => null}
          autoFocus
          emulateTouch
          swipeable
        >
          {imageList}
        </Carousel>

        {!isLeftEnd &&
        <div className={_styles._desktop_carousel__left_product}
          onClick={this.handleLeftClickEvent.bind(this)}>
          <ArrowBackIosIcon
            color={"#222222"}
            style={{position:'absolute', top:'50%', left:'50%', width:"24px", height:'24px', transform:'translate(-50%, -50%)'}}/>
        </div>}
        {!isRightEnd &&
        <div className={_styles._desktop_carousel__right_product}
          onClick={this.handleRightClickEvent.bind(this)}>
          <ArrowForwardIosIcon
            color={"#222222"}
            style={{position:'absolute', top:'50%', left:'50%', width:"24px", height:'24px', transform:'translate(-50%, -50%)'}}/>
        </div>}
      </div>
    );
  }
}
