import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { pick, map, keys } from 'lodash';

import Summary from './Summary';
import UserStatList from './UserStatList';


const statNameMap = {
  'visit_unique': '방문자수',
  'visit_sum': '방문횟수',
  'visit_unique_member_new': '신규방문자수',
  'visit_unique_member_return': '재방문자수',
};

const statDayOfWeekMap = {
  "1": "월",
  "2": "화",
  "3": "수",
  "4": "목",
  "5": "금",
  "6": "토",
  "7": "일",
};

class UserStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visitStatisticsOptions: {
        title: {
          text: '',
        },
        series: [],
        xAxis: {
          tickmarkPlacement: 'on',
          reversed: true,
        },
        caption: {
          text: `
            <b>방문자수</b><br/>
            <p>앱/웹을 사용한 사용자 수 (앱/웹을 각각 카운트)<br/>
            　　- 로그인시: 유저 고유 아이디를 기준으로 중복 없이 카운트<br/>
            　　- 미로그인시: IP 주소를 기준으로 중복 없이 카운트</p>
            <br/>
            <b>방문횟수</b><br/>
            <p>앱/웹을 사용한 횟수 (60분 단위로 중복을 허용하며 카운트)</p><br/><br/>
            <b>신규방문자수</b><br/>
            <p>지난 30일동안 앱/웹을 사용한 적이 없는 회원 수</p><br/><br/>
            <b>재방문자수</b><br>
            <p>지난 30일동안 앱/웹을 사용한 적이 있는 회원 수</p><br/>
            `,
        },
      },
      kind: 'daily',
      visitStatRawData: {},
    };
  }

  loadUserStatistics(startDate, endDate) {
    const { loadData } = this.props;
    const { kind } = this.state;
    loadData(startDate, endDate, kind)
      .then(resp => {
        const data = resp.data;
        this.setState({
          visitStatRawData: data,
        });

        var values = Object.keys(data).map(function(key) {
          return data[key];
      });
        const res = values.reduce((accumulator, datum) => {
          keys(datum).forEach(key => {
            if (!accumulator[key]) {
              accumulator[key] = [];
            }
            accumulator[key].push(datum[key]);
          });
          return accumulator;
        }, {})
        return res;
      })
      .then(data => {
        const series = map(
          pick(data, ['visit_unique', 'visit_sum', 'visit_unique_member_new', 'visit_unique_member_return']),
          (value, key) => ({
              name: statNameMap[key],
              data: value,
          }),
        );
        let categories = data.date;
        if (kind == 'dayOfWeek') {
          categories = categories.map(v => statDayOfWeekMap[v]);
        }
        const { visitStatisticsOptions: visitStatisticsOptionsPrev } = this.state;

        const summary = { 
          visit_unique_app: data.visit_unique_app.reduce((sum, value) => (sum + value), 0),
          visit_unique_web: data.visit_unique_web.reduce((sum, value) => (sum + value), 0),
          visit_sum_app: data.visit_sum_app.reduce((sum, value) => (sum + value), 0),
          visit_sum_web: data.visit_sum_web.reduce((sum, value) => (sum + value), 0),
          visit_unique_member_new_app: data.visit_unique_member_new_app.reduce((sum, value) => (sum + value), 0),
          visit_unique_member_new_web: data.visit_unique_member_new_web.reduce((sum, value) => (sum + value), 0),
          visit_unique_member_return_app: data.visit_unique_member_return_app.reduce((sum, value) => (sum + value), 0),
          visit_unique_member_return_web: data.visit_unique_member_return_web.reduce((sum, value) => (sum + value), 0),
        };

        this.setState({
          visitStatisticsOptions: {
            ...visitStatisticsOptionsPrev,
            xAxis: {
              categories,
              reversed: false,
            },
            series,
          },
          visitStatSummary: summary,
        });
      });
  }

  componentDidMount() {
    const { startDate, endDate } = this.props;
    this.loadUserStatistics(startDate, endDate);
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate } = this.props;
    const { kind } = this.state;
    if (prevProps.startDate !== startDate || prevProps.endDate !== endDate || prevState.kind !== kind) {
      this.loadUserStatistics(startDate, endDate);
    }
  }

  render() {
    const {
      visitStatisticsOptions,
      kind,
      visitStatSummary,
      visitStatRawData,
    } = this.state;

    return (
      <div>
        <div style={{ fontSize: '20px', marginLeft: '20px', fontWeight: 'bold' }}>
          # Visit Statistics&nbsp;&nbsp;
          <select onChange={event => this.setState({ kind: event.target.value })} value={kind}>
            <option value="daily">일별</option>
            <option value="hourOfDay">시간대별</option>
            <option value="dayOfWeek">요일별</option>
            <option value="monthly">월별</option>
          </select>
        </div>

        <Summary visitStatSummary={visitStatSummary} />

        <HighchartsReact
          containerProps={{ style: { height: '600px' } }}
          highcharts={Highcharts}
          options={visitStatisticsOptions} />

        <UserStatList
          kind={kind}
          visitStatRawData={visitStatRawData}
          />
      </div>
    );
  }
}


UserStatistics.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};


export default UserStatistics;
