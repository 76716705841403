import React from 'react';
import {
  StyleSheet, TouchableHighlight, View, Text,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';

import {
  Constants,
  Colors,
  Styles,
} from '../constants';
import { shopScrollToTop } from '../containers-v2/shop/ShopPage/utils';
import { favScrollToTop } from '../containers-v2/fav/WishlistPage/WishlistPage';
import { styleScrollToTop } from '../containers-v2/style/StylePage/StylePage';
import { myScrollToTop } from '../containers-v2/my/SettingsPage';


const styles = {
  footerMenu: {
    backgroundColor: 'white',
    display: 'flex',
    width: '100%',
    height: Constants.mainBottomTabBarHeight,
    alignItems: 'flex-start',
    justifyContent: 'center',
    pointerEvents: 'auto',
  },
  footerMenuItem: {
  },
};

const stylesNative = StyleSheet.create({
  menuButton: {
    height: Constants.mainBottomTabBarHeight,
    height: 60,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  menuButtonText: {
    ...Styles.pretendardVariable,
    fontSize: 13,
    lineHeight: 15.6,
    fontWeight: '400',
    color: '#a2a4a6',
  },
  menuNameFocused: {
    marginTop: 10,
  },
  menuButtonDot: {
    lineHeight: 15.6,
    marginTop: -5,
  },
  menuButtonTextSelected: {
    color: 'black',
    fontWeight: '600',
  }
});


class MainBottomTabBar extends React.Component {
  lastScrollTop = 0;

  scrollDirection = 'up';

  state = {
    visible: true,
    temporaryForcedHide: false,
  };

  renderFooterMenuItem({ menu }) {
    const { pathname, router } = this.props;

    let focused = false;
    switch (menu.name) {
    case 'Style':
        focused = (
          pathname.startsWith('/style')
        );
        if (pathname === "/style/following") {
          menu.to = pathname;
        }
        break;
    case 'Shop':
      focused = (
        pathname.startsWith('/shop') || pathname.startsWith('/product')
      );
      break;
    case 'Favorite':
      focused = (
        pathname.startsWith('/favorite')
      );
      break;
    case 'My':
      focused = (
        pathname.startsWith('/accounts')
      );
      if (pathname.startsWith('/accounts/profile')) {
        menu.to = pathname;
      }
      break;
    case 'home':
    default:
      focused = (
        pathname === '/'
          || pathname.startsWith('/preorder')
      );
      if (pathname === "/preorder") {
        menu.to = "/preorder";
      }
      break;
    }

    // console.log(`pathname=${pathname}`);

    const menuButtonTextStyles = [
      stylesNative.menuButtonText,
    ];

    if (focused) {
      menuButtonTextStyles.push(stylesNative.menuButtonTextSelected);
    }

    const onPress = () => {
      switch (menu.name) {
        case 'Shop':
          if (focused) {
            shopScrollToTop();
          } else {
            router.push(menu.to);
          }
          break;

        case 'Favorite':
          if (focused) {
            favScrollToTop();
          } else {
            router.push(menu.to);
          }
          break;

        case 'Style':
          if (focused) {
            styleScrollToTop();
          } else {
            router.push(menu.to);
          }
          break;

        case 'My':
          if (focused && pathname.startsWith('/accounts/profile')) {
            myScrollToTop();
          } else {
            router.push(menu.to);
          }
          break;

        default:
          router.push(menu.to);
          break;
      }
    }

    return (
      <TouchableHighlight
        key={menu.name}
        onPress={onPress}
        underlayColor={Colors.touchEffectColorBlack}
        style={stylesNative.menuButton}
      >
        <View>
          <Text style={[menuButtonTextStyles, focused ? stylesNative.menuNameFocused : null]}>{menu.name}</Text>
          {focused && (<Text style={[menuButtonTextStyles, stylesNative.menuButtonDot]}>•</Text>)}
        </View>
      </TouchableHighlight>
    );
  }

  render() {
    const { pathname } = this.props;
    const menus = [
      { name: 'Home', to: '/' },
      { name: 'Style', to: '/style/all' },
      { name: 'Shop', to: '/shop' },
      { name: 'Favorite', to: '/favorite' },
      { name: 'My', to: '/accounts/settings' },
    ];

    const footerMenuHiddenPathList = [
      '/exhibition/',
      '/preorder/',
      '/style/upload',
      '/product/',
      '/shop/cart',
      '/shop/checkout',
      '/accounts/edit',
      '/accounts/changePassword',
      '/accounts/withdraw',
      '/accounts/shippingAddress/edit',
      '/accounts/shippingAddress/create',
      '/accounts/reviews',
      '/accounts/reviewWriteSuccess',
      '/accounts/reviewEditSuccess',
      '/accounts/login',
      '/accounts/join',
      '/accounts/orders',
      '/accounts/following',
      '/accounts/follower',
      '/accounts/faq',
      '/accounts/profile/edit',
      '/shop/search',
      '/legal',
      '/notice',
      '/outlink',
      '/heartists',
      '/push',
      path => path.startsWith('/accounts/orders/') && path.endsWith('/reviewWrite/'),
      path => path.startsWith('/style/') && path.endsWith('/replies'),
      path => path.startsWith('/style/feed/'),
    ];

    for (let i = 0; i < footerMenuHiddenPathList.length; i++) {
      const rule = footerMenuHiddenPathList[i];
      if (typeof rule === 'function') {
        if (rule(pathname)) {
          return null;
        }
      }

      if (pathname.startsWith(rule)) {
        return null;
      }
    }

    return (
      <div style={styles.footerMenu}>
        {menus.map(menu => this.renderFooterMenuItem({ menu }))}
      </div>
    );
  }
}

export default MainBottomTabBar;
