import React from 'react';
import { StyleSheet, Text } from 'react-native';

import Colors from '../constants/Colors';
import TouchableHighlightWithContainer from './TouchableHighlightWithContainer';

export default ({
  containerStyle, disabled, onPress, title, titleStyle,
  underlayColor = Colors.touchEffectColorWhite, innerComponent = null,
  disabledOpacity = 0.2
}) => (
  <TouchableHighlightWithContainer
    disabled={disabled}
    onPress={onPress}
    underlayColor={underlayColor}
    style={[styles.container, containerStyle, disabled ? { opacity: disabledOpacity } : null]}
  >
    { (innerComponent !== null) ? innerComponent: <Text style={[styles.title, titleStyle]}>{title}</Text> }
  </TouchableHighlightWithContainer>
);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    borderColor: 'dimgrey',
    borderWidth: 1,
    padding: 13,
  },
  title: {
    color: 'dimgrey',
    fontSize: 13,
  },
  disabled: {
    opacity: 0.2,
  },
});
