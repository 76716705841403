import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Alarm18Icon(props) {
  return (
    <Svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M13.5 6.75a4.5 4.5 0 10-9 0c0 1.181-.114 2.923-.29 3.75C3.89 12 3 12.75 3 12.75h12s-.89-.75-1.21-2.25c-.176-.827-.29-2.569-.29-3.75zM6.878 12.75a2.25 2.25 0 104.244 0"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Alarm18Icon

