import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Checkbox,
  Dialog,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core';
import { compose } from 'recompose';
import has from 'lodash/has';

import * as adminActions from 'react-shared/adminActions';
import { textToClipboard } from 'react-shared/util';


const styles = theme => ({
  pageTitle: {
    marginTop: 20,
  },
  divSearchFilter: {
    textAlign: 'right',
  },
  table: {
    width: '100%',
    padding: 5,
  },
  tableCellType: {
    width: 60,
    maxWidth: 60,
  },
  tableCellName: {
    width: 400,
    maxWidth: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tableCellBrand: {
    width: 100,
  },
  divPrice: {
    textAlign: 'right',
  },
  spanDiscPct: {
    fontSize: 10,
    color: 'red',
  },
  spanOrgPrice: {
    fontSize: 10,
    textDecoration: 'line-through',
  },
  spanSellPrice: {
    fontSize: 14,
  }
});

export class ListWassilyCSReservation extends Component {
  state = {
    reservations: [],
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { adminLoadWassilyCSReservations } = this.props;
    adminLoadWassilyCSReservations()
      .then((reservations) => {
        this.setState({ reservations });
      });
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onButtonDelete(r) {
    const { adminDeleteWassilyCSReservation } = this.props;
    const name = r.reservation.order.user.name;
    const confirmation = confirm(`${name} 고객님의 예약내역을 삭제하시겠습니까?`);
    if (!confirmation) {
      return;
    }

    adminDeleteWassilyCSReservation({ rid: r.reservation._id })
     .then(() => window.location.reload());
  }

  onButtonDownloadOrderCSV() {
    const { adminDownloadWassilyCSOrderCSV } = this.props;
    adminDownloadWassilyCSOrderCSV()
      .then(resp => {
        const blob = new Blob(['\uFEFF', resp.data], {type: 'text/csv'});
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = 'Wassily_CS_Orders.csv';
        a.click();
      });
  }

  render() {
    const { classes } = this.props;
    const { reservations } = this.state;
    return (
      <div>
        <Typography variant="h5" className={classes.pageTitle}>
          바실리체어 상담 예약 현황
        </Typography>
        <div className={classes.divSearchFilter}>
          <Button
            variant="contained"
            size="small"
            onClick={() => this.onButtonDownloadOrderCSV()}
            style={{ marginRight: 20 }}
          >
            CSV 파일 다운로드 (전체주문)
          </Button>
        </div>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>예약일시</TableCell>
              <TableCell>고객명</TableCell>
              <TableCell>주문정보</TableCell>
              <TableCell>이메일</TableCell>
              <TableCell>연락처</TableCell>
              <TableCell>주문처리</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { reservations.map(r => {
              if (r.reservation) {
                return (
                  <TableRow key={r.slot._id}>
                    <TableCell>{ r.slot.datetime }</TableCell>
                    <TableCell>{ r.reservation.order.user.name }</TableCell>
                    <TableCell>
                      { r.reservation.order.orderItems.map(o => {
                        return (
                          <div style={{ lineHeight: 1, marginTop: 10 }}>
                            <a target="_blank" href={"http://gdadmin.heartitr9527.godomall.com/order/order_view.php?orderNo=" + o.ordno}>{ o.ordno }</a><br />
                            <span style={{ fontSize: 12 }}>{ o.itemName } x { o.itemCount }</span>
                          </div>
                        );
                      })}
                    </TableCell>
                    <TableCell>{ r.reservation.order.godoEmail }</TableCell>
                    <TableCell>{ r.reservation.order.godoTel }</TableCell>
                    { r.answer ? (
                        <TableCell style={{ fontSize: 12 }}>
                          옵션: { r.answer.answer === 'refund' ? '즉시 환불' : '환불 유예' }<br />
                          UTG ID: { r.answer.utgUsername ? r.answer.utgUsername : '입력안함' }<br />
                          UTG 번호: { r.answer.utgPhone ? r.answer.utgPhone: '입력안함' }<br />
                          정보이전동의: { r.answer.utgPrivacyAccept ? '동의함' : '동의안함' }
                        </TableCell>
                      ) : (
                        <TableCell></TableCell>
                      )
                    }
                    <TableCell>
                        <Button size="small" onClick={() => this.onButtonDelete(r)}>삭제</Button><br />
                    </TableCell>
                  </TableRow>
                );
              } else {
                return (
                  <TableRow key={r.slot._id}>
                    <TableCell>{ r.slot.datetime }</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(state => ({}), adminActions),
)(ListWassilyCSReservation);
