import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import ga from 'react-ga';
import config from '../config';
import Backdrop from '@material-ui/core/Backdrop';
import { withStyles } from '@material-ui/core/styles';

import * as actions from 'react-shared/actions';
import { preloadInitialData } from 'react-shared'

import MarketingBanner from '../components/MarketingBanner';
import Spinner from '../nativeComponents/Spinner';
import { initAlert, showAlert, renderAlert } from '../components/AlertDialog';
import { checkMaintenance } from '../utils/shutter';

import _mainImage from '../assets/images/desktop_image.jpg';

import {
  Constants
 } from '../constants';

import _styles from  '../css/modules/main.module.css';

import {
  SmartBanner,
  MainBottomTabBar,
  Navigation,
  ChatInquiryIcon,
  Modal,
  BusinessInfo,
 } from '../components-v2';
import HomePage from './home/HomePage';
import ExhibitionPage from './home/ExhibitionPage/ExhibitionPage';
import PreOrderDetailPage from './home/PreOrderDetailPage/PreOrderDetailPage';
import StylePage from './style/StylePage/StylePage';
import StyleFollowPage from './style/StylePage/StyleFollowPage';
import StyleUploadPage from './style/StyleUploadPage/StyleUploadPage';
import StyleDetailPage from './style/StyleDetailPage/StyleDetailPage';
import ShopPage from './shop/ShopPage';
import ProductPage from './shop/ProductPage';
import CartPage from './shop/CartPage';
import WishlistPage from './fav/WishlistPage';
import MyBrandPage from './fav/MyBrandPage';
import JoinDonePage from './auth/JoinDonePage';
import MySizeSelectPage from './my/MySizeSelectPage';
import SettingsPage from './my/SettingsPage';
import MemberInfoPage from './my/MemberInfoPage';
import { OrderManagePage, OrderDetailPage, MyOrdersPage, OrderManageCompletePage } from './my/OrderPage';
import ProfilePage from './my/ProfilePage';
import FollowerHeartistsPage from './my/followerAndFollowing/FollowerHeartistsPage';
import FollowingHeartistsPage from './my/followerAndFollowing/FollowingHeartistsPage';
import HeartistProfilePage from './style/HeartistProfilePage';
import CheckoutPage from './checkout/CheckoutPage';
import SearchPage from './SearchPage';
import SearchResultPage from './SearchResultPage';
import BusinessInfoPage from './my/BusinessInfoPage';
import JoinWithEmailPage from './auth/JoinWithEmailPage';
import AppleIdCallbackPage from './auth/AppleIdCallbackPage';
import FacebookCallbackPage from './auth/FacebookCallbackPage';
import KakaoCallbackPage from './auth/KakaoCallbackPage';
import NaverCallbackPage from './auth/NaverCallbackPage';
import EditMyProfilePage from './my/EditMyProfilePage';
import StyleReplyPage from './style/StyleReplyPage/StyleReplyPage';

import NoticeMainPage from './notice/NoticePage';



const styles = {
  container: {
    position:'relative',
    maxWidth: 1080,
    display:'flex',
    justifyContent:'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100vh',
  },
  container_banner: {
    position:'relative',
    maxWidth: 440,
    display:'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100vh',
  },
  container_inpage: {
    maxWidth: 440,
    position:'relative',
    display:'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100vh',
    overflow:'hidden'
  },
  header: {
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 1030,
    width: '100%',
  },
  main_container: {
    width:'100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100vh',
    overflow:'scroll'
  },
  main: {
    width:'100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow:'scroll'
  },
  footerMenuContainer: {
    maxWidth: 960,
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
  },
  footerMenu: {
    backgroundColor: 'black',
    display: 'flex',
    width: 244,
    height: Constants.mainBottomTabBarHeight,
    borderRadius: 28,
    boxShadow: '0px 6px 14px 0px rgba(0,0,0,0.4)',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingLeft: 10 - 5,
    paddingRight: 9 - 5,
  },
  footerMenuItem: {
    marginLeft: 18,
    marginRight: 18,
  },
  chatInquiryIconContainer: {
    float: 'right',
    position: 'sticky',
    bottom: Constants.shopProductFoooterHeight + 22,
    paddingRight: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
};


const useStyles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff',
  },
});

class App extends Component {
  constructor(props) {
    super(props);

    this.setModalProps = this.setModalProps.bind(this);
    this.setGlobalState = this.setGlobalState.bind(this);

    initAlert(this);
    this.showAlert = showAlert(this);
  }


  state = {
    showSmartBanner: false,
    showMarketingBanner: true,
    platform: '',
    headerPosition: 'sticky',
    headerOptions: {},
    modalProps: {},
    lastLoadUserCouponList: 0,
    lastLoadShopBrandList: 0,
    shopBrandList: [],
  };

  headerLeftRenderer = null;


  componentDidMount() {
    const { router, auth, setGlobalLayoutInfo } = this.props;
    if (auth.token) {
      ga.set({ userId: auth._id });
      fbq('init', config.get('pixelId'), {external_id: auth._id});
      this.loadUserCouponList();
    } else {
      fbq('init', config.get('pixelId'));
    }
    this.loadShopBrandList();

    ga.event({
      category: '(ignore) Dev $pt',
      action: 'App',
      label: 'componentDidMount',
    });
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      this.setState({
        showSmartBanner: true,
        platform: 'ios',
        headerPosition: '-webkit-sticky',
      });
      setGlobalLayoutInfo('showSmartBanner', true);
    } else if (navigator.userAgent.match(/Android/i)) {
      this.setState({
        showSmartBanner: true,
        platform: 'android',
      });
      setGlobalLayoutInfo('showSmartBanner', true);
    } else if (navigator.userAgent.match(/Safari/i)) {
      this.setState({
        headerPosition: '-webkit-sticky',
      });
    }

    this.updateRouterHistory(router.location.pathname);

    preloadInitialData(this);
  }

  updateRouterHistory = (pathname) => {
    const { updateRouterHistory } = this.props;

    checkMaintenance((title, message) => {
      this.showAlert(title, {
        content: message,
        buttons: [
          {
            text: '확인',
            onPress: () => {
              window.location.href = "/";
            },
          },
        ],
      });
    }).then(() => {
      updateRouterHistory(pathname, 'append');
      fbq('track', "PageView");
      if (pathname == "/" || pathname.startsWith("/product/")) {
        this.loadUserCouponList();
      }
      if (pathname.startsWith("/shop")) {
        this.loadShopBrandList();
      }
    });
  }

  setModalProps = (props, callback) => {
    // console.log(`setModalProps(${JSON.stringify(props)})`);

    const { modalProps } = this.state;
    const newModalProps = {
      ...modalProps,
      ...props,
    }

    this.setState({ modalProps: newModalProps }, callback);
  }

  setGlobalState = (newState, callback) => {
    this.setState(newState, callback);
  }

  componentDidUpdate(prevProps) {
    const { pathname: prevPathname } = prevProps;
    const { pathname, auth, updateRouterHistory } = this.props;

    // console.log('pathname', pathname);
    if (pathname !== prevPathname) {
      this.updateRouterHistory(pathname);
    }

    if (!prevProps.auth.token && auth.token) {
      window.setTimeout(() => {
        //window.location.reload();
      }, 0);
    }
  }

  async handleLogout(event) {
    event.preventDefault();
    /*await this.props.logout();
    window.location.href = '/';
    window.location.reload();*/
  }

  handleSmartBannerClose() {
    this.setState({ showSmartBanner: false });

    const { setGlobalLayoutInfo } = this.props;
    setGlobalLayoutInfo('showSmartBanner', false);
  }

  handleMarketingBannerClose() {
    this.setState({ showMarketingBanner: false });
  }

  showMarketingBanner() {
    const { pathname } = this.props;
    const { showMarketingBanner } = this.state;
    return showMarketingBanner && (pathname === '/' || pathname.includes('/liked') || pathname.includes('/posts/'));
  }

  setHeaderOptions(options) {
    const { headerOptions } = this.state;
    const newHeaderOptions = {
      ...headerOptions,
      ...options,
    }

    this.setState({ headerOptions: newHeaderOptions });
  }

  loadUserCouponList = async () => {
    const { lastLoadUserCouponList } = this.state;
    const { auth, getUserCouponList } = this.props;

    if (!auth || !auth.token) {
      console.log('cannot loadUserCouponList for anonymous user');
      return;
    }

    const now = Date.now();
    const minRefresh = 60000;  // milliseconds
    const threshold = lastLoadUserCouponList + minRefresh;

    if (threshold > now) {
      console.log(`loadUserCouponList threshold check: ${threshold} > ${now}`);
      return;
    }

    try {
      this.setState({ lastLoadUserCouponList: now });
      await getUserCouponList();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('loadUserCouponList failed error=', error);
    }
  }

  loadShopBrandList = async () => {
    const { lastLoadShopBrandList } = this.state;
    const { auth, loadShopBrandList } = this.props;

    const now = Date.now();
    const minRefresh = 3 * 60000;  // milliseconds
    const threshold = lastLoadShopBrandList + minRefresh;

    if (threshold > now) {
      console.log(`loadShopBrandList threshold check: ${threshold} > ${now}`);
      return;
    }

    try {
      this.setState({ lastLoadShopBrandList: now });
      const res = await loadShopBrandList();
      this.setState({ shopBrandList: res.data });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('loadShopBrandList failed error=', error);
    }
  }

  renderHeader() {
    const {
      router,
      itemListInCart=[],
      pushHistoryCount,
    } = this.props;

    const {
      headerPosition,
      platform,
      showSmartBanner,
      headerOptions,
    } = this.state;

    const headerStyle = Object.assign({}, styles.header, { position: headerPosition });

    const pathName = router.location.pathname;

    return (
      <div id="header_container" style={headerStyle}>
        {showSmartBanner && <SmartBanner platform={platform} onClickClose={() => this.handleSmartBannerClose()} />}
        <Navigation
          router={router}
          pathName={pathName}
          headerOptions={headerOptions}
          itemListInCart={itemListInCart}
          pushHistoryCount={pushHistoryCount}
          />
        {this.showMarketingBanner() && <MarketingBanner onClose={() => this.handleMarketingBannerClose()} />}
      </div>
    );
  }

  renderMainBottomTabBar() {
    const {
      auth, router, pathname,
    } = this.props;

    const {
      showMainBottomTabBar=true,
    } = this.state;

    if (!showMainBottomTabBar) {
      return null;
    }

    return (
      <div className={_styles.hi_footer_menu_container}>
        <MainBottomTabBar
          auth={auth}
          router={router}
          pathname={pathname}
        />
      </div>
    );
  }

  renderModal() {
    const {
      modalProps,
    } = this.state;

    return (
      <Modal
        {...modalProps}
        setModalProps={this.setModalProps}
        />
    )
  }

  render() {
    // Injected by React Router
    const {
      children, router, classes,
      showProgress = false,
    } = this.props;

    const {
      headerOptions,
      showSmartBanner,
      shopBrandList,
    } = this.state;

    const pathName = router.location.pathname;

    const childrenNeedsFooter = React.Children.map(children, child => {
        if ( child.type === HomePage
          || child.type === ExhibitionPage
          || child.type === PreOrderDetailPage
          || child.type === SettingsPage
          || child.type === ProfilePage
        ) {
          return true;
        }
        return false;
    });

    const childrenWithMoreProps = React.Children.map(children, child => {
      if ( child.type === HomePage
        || child.type === ExhibitionPage
        || child.type === PreOrderDetailPage
        || child.type === StylePage
        || child.type === StyleFollowPage
        || child.type === StyleUploadPage
        || child.type === StyleDetailPage
        || child.type === StyleReplyPage
        || child.type === ShopPage
        || child.type === ProductPage
        || child.type === CartPage
        || child.type === WishlistPage
        || child.type === MyBrandPage
        || child.type === JoinDonePage
        || child.type === MySizeSelectPage
        || child.type === MemberInfoPage
        || child.type === MyOrdersPage
        || child.type === OrderDetailPage
        || child.type === OrderManagePage
        || child.type === OrderManageCompletePage
        || child.type === SettingsPage
        || child.type === ProfilePage
        || child.type === FollowerHeartistsPage
        || child.type === FollowingHeartistsPage
        || child.type === HeartistProfilePage
        || child.type === CheckoutPage
        || child.type === SearchResultPage
        || child.type === BusinessInfoPage
        || child.type === JoinWithEmailPage
        || child.type === AppleIdCallbackPage
        || child.type === FacebookCallbackPage
        || child.type === KakaoCallbackPage
        || child.type === NaverCallbackPage
        || child.type === EditMyProfilePage
        || child.type === NoticeMainPage
      ) {
        return React.cloneElement(child, {
          setHeaderOptions: options => this.setHeaderOptions(options),
          headerOptions,
          setModalProps: this.setModalProps,
          modalProps: () => {
            const {
              modalProps,
            } = this.state;
            // console.log(`App.modalProps() return ${modalProps}`);

            return modalProps;
          },
          setGlobalState: this.setGlobalState,
          shopBrandList: shopBrandList,
          showSmartBanner,
        });
      }

      return child;
    });


    if ($pt.isApp()) {
      ga.event({
        category: '(ignore) Dev $pt',
        action: 'App',
        label: 'componentDidMount',
      });
      return (
        <div>
          <div className="container">
            {childrenWithMoreProps}
          </div>
        </div>
      );
    } else {

      return (
        <div className={`${_styles.hi_box}`}>
          <div className={`${_styles.hi_container}`}>
            <div className={_styles.hi_aside}>
              <img className={_styles.hi_aside_image}
                src={_mainImage}
                />
            </div>
            <div className={`${_styles.hi_main}`}>
              <div hi_tag="MAIN_CONTAINER" className={_styles.hi_main_container}>
                {/*this.renderHeader()*/}
                <div hi_tag="main" className={_styles.hi_main_box}>
                  {childrenWithMoreProps}
                  {/*childrenNeedsFooter && childrenNeedsFooter[0] && <BusinessInfo />*/}
                </div>
              </div>
              {this.renderMainBottomTabBar()}
              <Backdrop className={classes.backdrop} open={showProgress}>
                <Spinner isOverlay />
              </Backdrop>
              <div style={styles.chatInquiryIconContainer}>
                <ChatInquiryIcon pathName={pathName} />
              </div>
              {this.renderModal()}
              {renderAlert(this)}
            </div>
          </div>
        </div>
      );
    }
  }
}

App.propTypes = {
  auth: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  logout: PropTypes.func.isRequired,
  // loadFeatured: PropTypes.func.isRequired,
};

export default withStyles(useStyles)(connect(
  (state, { router }) => ({
    auth: state.auth,
    showProgress: state.globalLayoutInfo.showProgress,
    pathname: router.getCurrentLocation().pathname,
    itemListInCart: state.entities.shop.cart.items,
    pushHistoryCount: state.entities.pushHistoryCount.count,
  }),
  actions,
)(App));
