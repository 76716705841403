import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { View } from 'react-native';

import Products from './Products';
import HomeFeedSectionHeader from './HomeFeedSectionHeader';


const MAX_RECENT_ITEMS = 10;

export default class Histories extends React.Component {
  render() {
    const { histories, historySwitch, auth, navigation, onPressProduct } = this.props;
    const products = histories.map(history => ({
      ...history.product,
      post: history.post,
    }));

    return (
      <View>
        <HomeFeedSectionHeader title="최근 본 상품" />
        <Products
          historySwitch={historySwitch}
          auth={auth}
          navigation={navigation}
          products={products.slice(0, MAX_RECENT_ITEMS)}
          onPressProduct={onPressProduct}
          horizontal
          enableProductLabelPreOrder
          enableProductLabelHeartitLogo
        />
      </View>
    );
  }
}
