import React from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheet, View, Text,
} from 'react-native';

import { connect } from 'react-redux';
import { actions } from 'shared';

import { Constants, Colors, Styles } from '../constants';
import { RadioButtonIcon } from '../icons-v2';
import Button from './Button';
import CheckBox from './CheckBox';
import ShippingAddressEditForm from './ShippingAddressEditForm';

import { initAlert, renderAlert } from '../components/AlertDialog';

import { showShippingAddressEdit } from '../containers-v2/checkout/ShippingAddressEditPage';


const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  addressContainer: {
    marginTop: 19.5,
    marginStart: 42 - Constants.viewHorizontalPadding,
  },
  startControlContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  startControlText: {
    fontSize: 14,
    lineHeight: 16.8,
    textAlign: 'left',
    color: '#000',
    ...Styles.pretendard400,
  },
  radioButtonDefault: {
    padding: 0,
    margin: 0,
    marginEnd: 8,
    borderWidth: 0,
  },
  infoContainer: {
    flex: 1,
    width: 210,
  },
  infoCheckContainer: {
    flex: 1,
  },
  checkContainer: {
    flex: 1,
  },
  checkboxLabelContainer: {
    flexDirection: 'row',
    marginTop: 15,
    height: 18,
  },
  checkbox: {
    marginTop: 1.5,
    marginBottom: 1.5,
    marginEnd: 8,
  },
  checkboxLabelText: {
    fontSize: 14,
    lineHeight: 16.8,
    marginStart: 8,
    color: '#000',
    ...Styles.pretendard400,
  },
  infoContents: {
    flex: 1,
    flexWrap: 'wrap',
    color: '#000',
    fontSize: 16,
    lineHeight: 19.2,
    textAlign: 'left',
    textAlignVertical: 'top',
    ...Styles.pretendard400,
  },
  endControlContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  editButton: {
    padding: Constants.touchEffectWidth,
    margin: -1 * Constants.touchEffectWidth,
    height: 17 + 2 * Constants.touchEffectWidth,
    borderRadius: Constants.touchEffectBorderRadius,
    borderWidth: 0,
    borderColor: 'transparent',
  },
  editButtonText: {
    fontSize: 14,
    lineHeight: 16.8,
    textAlign: 'right',
    textAlignVertical: 'center',
    color: '#000',
    ...Styles.pretendard400,
  },
});


class ShippingAddress extends React.Component {
  state = {
    editForm: false,
    data: {},
    changedData: {},
  }
  
  editFormRef = React.createRef();

  constructor(props) {
    super(props);

    initAlert(this);
  }

  componentDidMount() {
    const { isNewAddress, onDataChanged } = this.props;
    if (isNewAddress) {
      this.setState({ editForm: true });
    } else {
      this.setState({ data: this.getMergedData() });
    }

    const { selected } = this.props;
    if (selected) {
      onDataChanged(this.getMergedData());
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { data: prevData } = prevState;
    const { data } = this.state;
    const { onDataChanged, isNewAddress } = this.props;
    if (prevData != data) {
      onDataChanged(data);
    }

    const { item: prevItem, selected: prevSelected } = prevProps;
    const { item, selected } = this.props;
    if (selected != prevSelected && selected || prevItem != item && selected) {
      onDataChanged(this.getMergedData(true));
    }
    if (prevItem != item) {
      this.setState({
        data: {},
        changedData: {},
        editForm: false,
      });
    }
  }

  validateInputField = () => {
    const { editForm } = this.state;
    const { isNewAddress } = this.props;

    if (editForm) {
      const validation = this.editFormRef.current.validateInputField();
      if (validation) {
        const buttonText = (isNewAddress) ? "입력 완료" : "수정 완료"
        this.alert(`배송지 ${buttonText} 버튼을 눌러주세요`);
      }
      return false;
    }
    return true;
  }

  renderStartControl = () => {
    const {
      title,
      selected,
      onPressRadioButton,
      item,
      isNewAddress,
      profileEditStyle,
    } = this.props;

    // console.log(item, selected);

    let isDefaultAddress = false;
    if (item) {
      const { defaultFl } = item;
      isDefaultAddress = defaultFl === 'y';
    }

    return (
      <View style={[styles.startControlContainer, isNewAddress ? {marginEnd: 32}: {}]}>
        {!profileEditStyle && (
          <Button
            containerStyle={styles.radioButtonDefault}
            innerComponent={(<RadioButtonIcon checked={selected} />)}
            onPress={onPressRadioButton}
          />
        )}
        <Text style={[styles.startControlText, (selected || profileEditStyle) ? {...Styles.pretendard600}: {}]}>
          {item && ((isDefaultAddress) ? "기본 배송지": "추가 배송지")}
          {!item && "새로운 배송지 추가"}
        </Text>
      </View>
    );
  }

  renderSimpleInfo = () => {
    const {
      item,
    } = this.props;

    if (!item) {
      return (
        <View style={styles.infoContainer}>
          <Text style={[styles.infoContents, { color: Colors.defaultGray05 }]}>저장된 배송지가 없습니다.</Text>
        </View>
      );
    }

    const {
      shippingTitle: name,
    } = item;

    return (
      <View style={styles.infoContainer}>
        <Text style={styles.infoContents}>{name}</Text>
      </View>
    );
  }

  renderInfo = () => {
    let {
      item,
      isNewAddress,
    } = this.props;
    if (isNewAddress) {
      item = this.getMergedData(true);
    }

    if (!item) {
      return (<View />);
    }

    const {
      shippingTitle: name,
      shippingName: receiverName,
      shippingAddress,
      shippingAddressSub,
      shippingCellPhone,
    } = item;

    let phoneNumber = shippingCellPhone;
    if (shippingCellPhone && shippingCellPhone.length == 11) {
      phoneNumber = `${shippingCellPhone.substring(0, 3)}-${shippingCellPhone.substring(3, 7)}-${shippingCellPhone.substring(7)}`;
    }

    const address = `${shippingAddress} ${shippingAddressSub}`;
    return (
      <View style={styles.infoCheckContainer}>
        <View style={styles.infoContainer}>
          <Text style={styles.infoContents}>{name}</Text>
          <Text style={[styles.infoContents, { marginTop: 10 }]}>{receiverName}</Text>
          <Text style={[styles.infoContents, { marginTop: 10 }]}>{address}</Text>
          <Text style={[styles.infoContents, { marginTop: 10 }]}>{phoneNumber}</Text>
        </View>
        {isNewAddress && (
          <View style={styles.checkContainer}>
            <View style={styles.checkboxLabelContainer}>
              <CheckBox
                baseStyle={styles.checkbox}
                checked={ this.state.data.newDefaultFl == 'y' }
                onPress={() => {
                  this.setState((prevState) => {
                    return {
                      data: { ...prevState.data, newDefaultFl:  (prevState.data.newDefaultFl == 'y') ? 'n' : 'y' },
                    };
                  });
                }}
              />
              <Text style={[
                styles.checkboxLabelText,
                this.state.data.newDefaultFl == 'y' ? {...Styles.pretendard600} : {}
              ]}>
                기본 배송지로 지정
              </Text>
            </View>
            <View style={styles.checkboxLabelContainer}>
              <CheckBox
                baseStyle={styles.checkbox}
                checked={this.state.data.saveToShippingAddressList == 'y'}
                onPress={() => {
                  this.setState((prevState) => {
                    return {
                      data: { ...prevState.data, saveToShippingAddressList:  (prevState.data.saveToShippingAddressList == 'y') ? 'n' : 'y' },
                    };
                  });
                }}
              />
              <Text style={[
                styles.checkboxLabelText,
                this.state.data.saveToShippingAddressList == 'y' ? {...Styles.pretendard600} : {}
              ]}>
                배송지 목록에 저장
              </Text>
            </View>
          </View>
        )}
      </View>
    );
  }

  renderEndControl = () => {
    const {
      onPressDeleteButton: deleteCallback,
      onPressEditButton: editCallback,
      item, profileEditStyle,
      navigation,
      addressEditScreenName = "CheckoutShippingAddressCreate",
    } = this.props;

    let defaultFl = null;
    if (item) {
      defaultFl = item.defaultFl;
    }

    const onPressEditButton = () => {
      if (editCallback) {
        editCallback();
      } else {
        let title = "기본 배송지";
        if (defaultFl !== 'y') {
          title = "추가 배송지"
        }
        showShippingAddressEdit(this, item.sno);
      }
    };

    const onPressDeleteButton = () => {
      this.alert('배송지를 삭제하시겠습니까?', {
        buttons: [
          {
            text: '취소',
          },
          {
            text: '삭제',
            onPress: () => {
              deleteCallback();
            },
          },
        ],
      })
    };

    return (
      <View style={styles.endControlContainer}>
        <Button
          containerStyle={styles.editButton}
          titleStyle={styles.editButtonText}
          onPress={onPressEditButton}
          title="정보 수정"
        />
        {(profileEditStyle && defaultFl !== 'y') && (
          <Button
            containerStyle={[styles.editButton, {marginStart: 20}]}
            titleStyle={styles.editButtonText}
            onPress={onPressDeleteButton}
            title="삭제"
          />
        )}
      </View>
    );
  }

  getMergedData = (forSave = false) => {
    const {
      item: orgShippingAddress,
      shippingAddressEmpty,
    } = this.props;
    const { data: confirmedData, changedData } = this.state;

    const mergedData = {
      ...orgShippingAddress,
      ...confirmedData,
      ...changedData,
      ...(shippingAddressEmpty && { defaultFl: 'y' }),
    };

    const {
      sno,
      defaultFl,
      newDefaultFl,
      shippingTitle,
      shippingName,
      shippingZonecode,
      shippingAddress,
      shippingAddressSub,
      shippingCellPhone,
      saveToShippingAddressList,
    } = mergedData;

    const data = {};
    if (sno !== undefined) { data.sno = sno; }
    if (defaultFl !== undefined) { data.defaultFl = defaultFl; }
    if (newDefaultFl !== undefined) { data.newDefaultFl = newDefaultFl; }
    if (shippingTitle !== undefined) { data.shippingTitle = shippingTitle; }
    if (shippingName !== undefined) { data.shippingName = shippingName; }
    if (shippingZonecode !== undefined) { data.shippingZonecode = shippingZonecode; }
    if (shippingAddress !== undefined) { data.shippingAddress = shippingAddress; }
    if (shippingAddressSub !== undefined) { data.shippingAddressSub = shippingAddressSub; }
    if (shippingCellPhone !== undefined) { data.shippingCellPhone = shippingCellPhone; }
    if (saveToShippingAddressList !== undefined) { data.saveToShippingAddressList = saveToShippingAddressList; }

    if (forSave) {
      data.defaultFl = (defaultFl === 'y' || newDefaultFl) ? 'y' : 'n';
      delete data.newDefaultFl;
    }

    return data;
  }

  render() {
    const { item, selected, isNewAddress, profileEditStyle = false, hideEndControl = false, disabledSimpleInfo = false } = this.props;
    const { editForm } = this.state;

    return (
      <View>
        <View style={styles.container}>
          { this.renderStartControl()}
          { (!selected && !isNewAddress && !disabledSimpleInfo && !profileEditStyle) && this.renderSimpleInfo() }
          { ((!hideEndControl && selected && !editForm) || profileEditStyle) && this.renderEndControl()}
        </View>
        <View style={[styles.addressContainer, profileEditStyle ? {marginStart: 0, marginTop: 10} : {}]}>
          { ((selected && !editForm) || disabledSimpleInfo || profileEditStyle) && this.renderInfo()}
        </View>
        {renderAlert(this)}
      </View>
    );
  }
}


ShippingAddress.propTypes = {
  profileEditStyle: PropTypes.bool,
};


ShippingAddress.defaultProps = {
  profileEditStyle: false,
};


export default connect(
  (state, { navigation }) => {
    return {
      auth: state.auth,
      shippingAddressEmpty: Object.keys(state.entities.shippingAddresses).length === 0,
    };
  },
  actions,
  null,
  { withRef: true }
)(ShippingAddress);
