import * as React from "react"
import Svg, { Path } from "react-native-svg"

function LoginKakaoIcon(props) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.613c-5.643 0-10.217 3.616-10.217 8.078 0 2.903 1.938 5.448 4.847 6.872-.214.798-.774 2.89-.887 3.338-.138.556.204.548.428.399.176-.117 2.8-1.901 3.933-2.671.614.09 1.248.14 1.896.14 5.643 0 10.217-3.618 10.217-8.078 0-4.462-4.574-8.078-10.217-8.078z"
        fill="#F5BF00"
      />
    </Svg>
  )
}

export default LoginKakaoIcon

