import React from 'react';
import {
  View, Text, StyleSheet, TouchableHighlight,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';

import CustomDialog from '../components/CustomDialog';
import CloudinaryImage from './CloudinaryImage';

import {
  Colors, Styles, Constants,
} from '../constants';

import {
  ListSelectedIcon, PlusIcon,
} from '../icons-v2';

import Button from './Button';


const styles = StyleSheet.create({
  heartistContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 68,
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  profileImageContainer: {
    justifyContent: 'center',
    width: 48,
    height: 48,
    marginTop: 9,
    marginBottom: 11,
  },
  profileInfoContainer: {
    flex: 1,
    paddingStart: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonContainer: {
    justifyContent: 'center',
  },
  button: {
    padding: 0,
    paddingStart: 11 - 1,
    paddingEnd: 27 - 20 - 1,
    height: 30,
    width: 75,
    borderWidth: 1,
    borderColor: '#000',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  buttonUnfollow: {
    backgroundColor: '#fff',
  },
  buttonFollow: {
    backgroundColor: '#000',
  },
  buttonText: {
    color: '#000',
    fontSize: 15,
    lineHeight: 18,
    height: 18,
    marginEnd: 4,
    ...Styles.pretendard600,
  },
  buttonTextUnfollow: {
    color: '#000',
  },
  buttonTextFollow: {
    color: '#fff',
  },
  profileUsernameText: {
    ...Styles.SFUITextSemiBold,
    fontSize: 16,
    color: 'black',
    lineHeight: 19.2,
    ...Styles.pretendard400,
  },
  profileDetailText: {
    marginTop: 3,
    fontSize: 13,
    color: 'black',
    lineHeight: 15.6,
    opacity: 0.5,
    ...Styles.pretendard400,
  },
});

const stylesHtml = {
  profileImage: {
    border: `1px solid ${Colors.profileImageBorder}`,
    borderRadius: 24,
    height: 48,
    width: 48,
  },
};

class MyPageHeartist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoginDialog: false,
    };
  }

  onPressFollowing = heartist => {
    const { auth, followUser, unfollowUser } = this.props;
    const { token } = auth;

    if (!auth || !token) {
      this.setState({ showLoginDialog: true });
      return;
    }
    if (heartist.following) {
      unfollowUser(heartist._id);
    } else {
      followUser(heartist._id);
    }
  }

  render() {
    const { router, heartist } = this.props;
    const { showLoginDialog } = this.state;

    const onPressHeartist = () => router.push({
      pathname: `/heartists/${heartist._id}`,
    });

    const { feedCount = 0 } = heartist;
    const subText = `게시물 ${feedCount}개`;
    const username = heartist.username ? heartist.username.split('@')[0] : heartist.username;
    const name = heartist.name ? heartist.name : username;

    return (
      <TouchableHighlight
        underlayColor={Colors.touchEffectColorWhite}
        onPress={onPressHeartist}
      >
        <View
          style={styles.heartistContainer}
        >
          <View
            style={styles.profileImageContainer}
          >
            { heartist.profileImage ? (
              <CloudinaryImage
                options={{
                  crop: 'limit',
                  height: 160,
                  width: 160,
                }}
                publicId={heartist.profileImage.publicId}
                style={stylesHtml.profileImage}
              />
            ) : (
              <CloudinaryImage
                options={{
                  type: 'instagram_name',
                  default_image: 'default-profile.jpg',
                }}
                publicId={heartist.username}
                style={stylesHtml.profileImage}
              />
            )}
          </View>
          <View style={styles.profileInfoContainer}>
            <Text style={styles.profileUsernameText}>
              {name}
            </Text>
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={styles.profileDetailText}
            >
              {subText}
            </Text>
          </View>
          <View style={styles.buttonContainer}>
            <View style={(heartist.following) ? styles.buttonUnfollow : styles.buttonFollow}>
              <Button
                containerStyle={styles.button}
                underlayColor={(heartist.following) ? Colors.touchEffectColorBlack : Colors.touchEffectColorWhite}
                onPress={() => this.onPressFollowing(heartist)}
                innerComponent={(
                  <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                    <Text
                      style={[
                        styles.buttonText,
                        (heartist.following) ? styles.buttonTextUnfollow : styles.buttonTextFollow,
                      ]}
                    >
                      {(heartist.following) ? '팔로잉' : '팔로우'}
                    </Text>
                    {(heartist.following) ? <ListSelectedIcon /> : <PlusIcon />}
                  </View>
                )}
                />
            </View>
          </View>

          <CustomDialog
            dialogOpen={showLoginDialog}
            onConfirm={() => {
              router.push({
                pathname: '/accounts/login',
              });
            }}
            onCancel={() => this.setState({ showLoginDialog: false })}
            onClose={() => this.setState({ showLoginDialog: false })}
            description={`${heartist.username} 하티스트를 팔로우하려면 로그인 하시기 바랍니다.`}
          />
        </View>
      </TouchableHighlight>
    );
  }
}

export default MyPageHeartist;
