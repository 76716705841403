import { Alert } from 'react-native';


async function getShutterInfo(mode) {
  const url = `https://shutter.heartit.kr/maintenance/${mode}.json`;
  const response = await fetch(url);
  if (!response.ok) {
    return null;
  }
  return await response.json();
}


export function checkMaintenance(alertFunc) {
  return new Promise(async function (resolve, reject) {
    let info = null;
    try {
      switch (process.env.NODE_ENV) {
        case 'production':
          info = await getShutterInfo('live');
          break;
        case 'staging':
          info = await getShutterInfo('stage');
          break;
        default:
          info = await getShutterInfo('dev');
          break;
      }
    } catch (e) {
      console.error(e);
    }

    if (!info) {
      console.error('Cannot read maintenance mode. Skip');
      resolve();
      return;
    }

    if (!info.isMaintenance) {
      console.log('[Shutter] Maintenance mode is OFF');
      resolve();
      return;
    }

    console.log('[Shutter] Maintenance mode is ON');

    alertFunc(info.alert.title, info.alert.message);
    resolve();
  });
}
