import { normalize } from 'normalizr';
import get from 'lodash/get';
import set from 'lodash/set';
import setWith from 'lodash/setWith';
import qs from 'qs';

import * as schemas from '../schemas';
import createFetchAction from '../util/createFetchActionWithShopAuth';


export function loadFAQ(nextPage, count) {
  const key = 'faqList';
  return createFetchAction({
    type: 'LOAD_FAQ_LIST',
    method: 'GET',
    endpoint: '/api/v2/faq',
    transform: ({ json, response }) => {
      console.log('faq', json, response);
      return set({}, `entities.${key}`, json);
    },
  });
}
