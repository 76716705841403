import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { compose } from 'recompose';
import {
  Button,
  Checkbox,
  Dialog,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  withStyles,
} from '@material-ui/core';

import { loadHeartists, updateUser } from 'react-shared/adminActions';
import { loadEntities } from 'react-shared/util';
import ThumbnailList from 'components/admin/ThumbnailList';
import Spinner from 'components/Spinner';
import InstagramProfile from 'components/InstagramProfile';

const styles = theme => ({
  textField: {
    width: 300,
  },
  input: {
    ...theme.typography.subheading,
  },
  label: {
    fontSize: 15,
    paddingLeft: 20,
  },
  table: {
    maxWidth: 900,
  },
  profileCell: {
    ...theme.typography.title,
    maxWidth: 50,
  },
  nameCell: {
    ...theme.typography.subheading,
    textAlign: 'center',
    minWidth: 240,
  },
  sizeCell: {
    ...theme.typography.subheading,
    textAlign: 'center',
    minWidth: 100,
  },
  profileImage: {
    width: 50,
    height: 50,
    cursor: 'pointer',
    borderBottom: 'none',
  },
  icon: {
    width: 40,
    height: 40,
  },
});

class ListHeartistProfilePage extends Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    this.props.loadHeartists();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (window.pageYOffset > document.body.clientHeight - window.innerHeight - 1
      && !this.props.pagination.isFetching && this.props.pagination.nextPageUrl) {
      this.handleLoadMore();
    }
  }

  handleLoadMore() {
    this.props.loadHeartists(true);
  }

  handleUpdate(items, oldIndex, newIndex) {
  }

  static getMedia(heartist) {
    const { lastPost: { media: { 0: media } } } = heartist;
    return media;
  }

  render() {
    const { classes, pagination: { isFetching }, heartists } = this.props;
    return (
      <div className="container" style={{ marginLeft: 'auto', marginRight: 'auto', width: 'max-content' }}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableCell}>
              <TableCell className={classes.profileCell}></TableCell>
              <TableCell className={classes.nameCell}>하티스트명</TableCell>
              <TableCell colspan={5}>프로필정보</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {heartists.map(user => (
              <Fragment key={`listuserpage_table_${user.username}`}>
                <TableRow>
                  <TableCell className={classes.profileCell}>
                    <InstagramProfile
                      style={{ width: 50, height: 50, cursor: 'pointer' }}
                      instagramId={user.username || ''}
                      onClick={() => this.handleProfileClick(user)}
                    />
                  </TableCell>
                  <TableCell className={classes.nameCell}>
                    <Link to={`/${user.username}`}>{user.username}</Link>
                  </TableCell>
                  <TableCell className={classes.sizeCell}>
                    <TextField
                      type="text"
                      label="키"
                      placeholder="키"
                      onChange={event => {
                        const newHeartists = this.props.heartists;
                        const targetIdx = newHeartists.findIndex((prevUser) => prevUser._id === user._id);
                        if (newHeartists[targetIdx].creatorProfile) {
                          newHeartists[targetIdx].creatorProfile.height = event.target.value;
                        } else {
                          newHeartists[targetIdx].creatorProfile = { height: event.target.value };
                        }
                        this.setState({ heartists: newHeartists });
                      }}
                      value={user.creatorProfile && user.creatorProfile.height || ''}
                    />
                  </TableCell>
                  <TableCell className={classes.sizeCell}>
                    <TextField
                      type="text"
                      label="발사이즈"
                      placeholder="발사이즈"
                      onChange={event => {
                        const newHeartists = this.props.heartists;
                        const targetIdx = newHeartists.findIndex((prevUser) => prevUser._id === user._id);
                        if (newHeartists[targetIdx].creatorProfile) {
                          newHeartists[targetIdx].creatorProfile.footSize = event.target.value;
                        } else {
                          newHeartists[targetIdx].creatorProfile = { footSize: event.target.value };
                        }
                        this.setState({ heartists: newHeartists });
                      }}
                      value={user.creatorProfile && user.creatorProfile.footSize || ''}
                    />
                  </TableCell>
                  <TableCell className={classes.sizeCell}>
                    <TextField
                      type="text"
                      label="상의사이즈"
                      placeholder="상의사이즈"
                      onChange={event => {
                        const newHeartists = this.props.heartists;
                        const targetIdx = newHeartists.findIndex((prevUser) => prevUser._id === user._id);
                        if (newHeartists[targetIdx].creatorProfile) {
                          newHeartists[targetIdx].creatorProfile.topWearSize = event.target.value;
                        } else {
                          newHeartists[targetIdx].creatorProfile = { topWearSize: event.target.value };
                        }
                        this.setState({ heartists: newHeartists });
                      }}
                      value={user.creatorProfile && user.creatorProfile.topWearSize || ''}
                    />
                  </TableCell>
                  <TableCell className={classes.sizeCell}>
                    <TextField
                      type="text"
                      label="하의사이즈"
                      placeholder="하의사이즈"
                      onChange={event => {
                        const newHeartists = this.props.heartists;
                        const targetIdx = newHeartists.findIndex((prevUser) => prevUser._id === user._id);
                        if (newHeartists[targetIdx].creatorProfile) {
                          newHeartists[targetIdx].creatorProfile.bottomWearSize = event.target.value;
                        } else {
                          newHeartists[targetIdx].creatorProfile = { bottomWearSize: event.target.value };
                        }
                        this.setState({ heartists: newHeartists });
                      }}
                      value={user.creatorProfile && user.creatorProfile.bottomWearSize || ''}
                    />
                  </TableCell>
                  <TableCell className={classes.sizeCell}>
                    <Button
                      variant="contained"
                      onClick={(event) => {
                        this.props.updateUser(user._id, {
                          creatorProfile: user.creatorProfile,
                        });
                      }}>
                      수정
                    </Button>
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
        {isFetching && <Spinner />}
      </div>
    );
  }
}

ListHeartistProfilePage.propTypes = {
  heartists: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  loadHeartists: PropTypes.func.isRequired,
  updateHeartists: PropTypes.func.isRequired,
  updateHeartist: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  connect(
    state => loadEntities(state, 'heartists', 'heartists'),
    { loadHeartists, updateUser }
  ),
)(ListHeartistProfilePage);
