import assign from 'lodash/assign';
import get from 'lodash/get';
import set from 'lodash/set';
import { normalize } from 'normalizr';
import qs from 'qs';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';
import { getNextPageUrl } from '../util';


export function loadHistory(nextPage, count, filter) {
  const key = 'histories';
  const query = {};
  if (count) {
    query.limit = count;
  }
  if (filter) {
    query.filter = filter;
  }
  return createFetchAction({
    type: 'LOAD_HISTORY',
    endpoint: state => {
      const base = `/api/v2/histories?${qs.stringify(query)}`;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    transform: ({ json, response, state }) => {
      const nextPageUrl = getNextPageUrl(response);

      if (json.data) {
        const histories = normalize(json.data, schemas.histories);
        let countResult = 0;
        if (state.pagination.histories) {
          // eslint-disable-next-line prefer-destructuring
          countResult = state.pagination.histories.countResult;
        }
        if (json.countResult) {
          // eslint-disable-next-line prefer-destructuring
          countResult = json.countResult;
        }
        return assign(histories, { nextPageUrl, countResult });
      }

      return assign(normalize(json, schemas.histories), { nextPageUrl });
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}

export function deleteHistory(historyId) {
  const key = 'histories';

  return createFetchAction({
    type: 'DELETE_HISTORY',
    endpoint: `/api/v2/histories/${historyId}`,
    method: 'DELETE',
    transform: ({ state }) => {
      let payload = set({}, key, state.entities.histories);
      delete payload.histories[historyId];

      return assign(payload, {
        ids: Object.keys(payload.histories),
        countResult: state.pagination.histories.countResult - 1,
        nextPageUrl: state.pagination.histories.nextPageUrl,
      });
    },
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}

export function resetHistoryStore() {
  return {
    type: 'CLEARED_ALL_HISTORY',
  };
}

export function clearAllHistory() {
  return createFetchAction({
    type: 'CLEAR_ALL_HISTORY',
    endpoint: '/api/v2/histories',
    method: 'DELETE',
  });
}

export function turnOnHistory() {
  return { type: 'HISTORY_SWITCH_ON' };
}

export function turnOffHistory() {
  return { type: 'HISTORY_SWITCH_OFF' };
}
