import React from 'react';
import { Route } from 'react-router';
import configureClient from './client';
import {
  AdminApp,
  AdminDashboardPage, // eslint-disable-line no-unused-vars
  AdminFeaturedPostPage,
  AdminListPostPage,
  AdminListHiddenPostPage,
  AdminListUserPage,
  AdminListHeartistPage,
  AdminListHeartistProfilePage,
  AdminPostPage,
  AdminGuidePage,
  AdminListStylePostPage,
  AdminStylePostPage,
  AdminListEditorialPage,
  AdminEditorialPage,
  AdminListMDCurationPage,
  AdminMDCurationPage,
  AdminListPreorderExhibitionPage,
  AdminPreorderExhibitionPage,
  AdminListBannerPage,
  AdminBannerPage,
  AdminListMarketingPopupPage,
  AdminSearchKeywordPage,
  AdminListProductPage,
  AdminProductPage,
  AdminFAQCategoryListPage,
  AdminFAQCategoryPage,
  AdminFAQListPage,
  AdminFAQPage,
  AdminNoticeListPage,
  AdminNoticePage,
  AdminListAppVersionPage,
  AdminListWassilyCSReservationPage,
} from '../containers/admin';

import {
  AdminDashboardPage as AdminDashboard2Page,
  AdminMarketingPopupPage,
  AdminListHeartitCurationPage,
  AdminHeartitCurationPage,
  AdminListCategorySalePage,
  AdminCategorySalePage,
  AdminStatsClickPage,
  AdminStatsClickDetailPage,
  AdminStatsSnapshotPage,
  AdminStatsSnapshotDetailPage,
  AdminStatsGodomallPage,
  AdminStatsGodomallDetailPage,
  AdminMDCurationListPage,
  AdminMDCurationPushPage,
  AdminPreorderExhibitionListPage,
  AdminPreorderExhibitionPushPage,
} from '../containers-v2/admin';

export default function configure(store) {
  function checkAdmin(nextState, replace) {
    const state = store.getState();
    if (!state.auth.token) {
      replace('/accounts/login');
    }
    if (!state.auth.roles || !state.auth.roles.admin) {
      replace('/accounts/login');
    }
  }

  return (
    <Route>
      {configureClient(store)}
      <Route path="/admin" component={AdminApp} onEnter={checkAdmin}>
        <Route path="posts" component={AdminListPostPage} />
        <Route path="posts/featured" component={AdminFeaturedPostPage} />
        <Route path="posts/hidden" component={AdminListHiddenPostPage} />
        <Route path="posts/:postId" component={AdminPostPage} />
        <Route path="stylePosts" component={AdminListStylePostPage} />
        <Route path="stylePosts/:stylePostId" component={AdminStylePostPage} />
        <Route path="users" component={AdminListUserPage} />
        <Route path="creators" component={AdminListHeartistPage} />
        <Route path="creators/profile" component={AdminListHeartistProfilePage} />
        <Route path="dashboard" component={AdminDashboardPage} />
        <Route path="dashboard2" component={AdminDashboard2Page} />
        <Route path="stats/click" component={AdminStatsClickPage} />
        <Route path="stats/click/:key" component={AdminStatsClickDetailPage} />
        <Route path="stats/snapshot" component={AdminStatsSnapshotPage} />
        <Route path="stats/snapshot/:key" component={AdminStatsSnapshotDetailPage} />
        <Route path="stats/godomall" component={AdminStatsGodomallPage} />
        <Route path="stats/godomall/:key" component={AdminStatsGodomallDetailPage} />
        <Route path="guide" component={AdminGuidePage} />
        <Route path="editorials" component={AdminListEditorialPage} />
        <Route path="editorials/:editorialId" component={AdminEditorialPage} />
        <Route path="mdCurations-v2" component={AdminMDCurationListPage} />
        <Route path="mdCurations" component={AdminListMDCurationPage} />
        <Route path="mdCurations/push/:mdCurationId" component={AdminMDCurationPushPage} />
        <Route path="mdCurations/:mdCurationId" component={AdminMDCurationPage} />
        <Route path="categorySales" component={AdminListCategorySalePage} />
        <Route path="categorySales/:categorySaleId" component={AdminCategorySalePage} />
        <Route path="heartitCurations" component={AdminListHeartitCurationPage} />
        <Route path="heartitCurations/:heartitCurationId" component={AdminHeartitCurationPage} />
        <Route path="preorderExhibitions" component={AdminListPreorderExhibitionPage} />
        <Route path="preorderExhibitions/:preorderExhibitionId" component={AdminPreorderExhibitionPage} />
        <Route path="preorderExhibitions-v2" component={AdminPreorderExhibitionListPage} />
        <Route path="preorderExhibitions/push/:preorderExhibitionId" component={AdminPreorderExhibitionPushPage} />
        <Route path="banners" component={AdminListBannerPage} />
        <Route path="banners/:bannerId" component={AdminBannerPage} />
        <Route path="marketingPopups" component={AdminListMarketingPopupPage} />
        <Route path="marketingPopups/:popupId" component={AdminMarketingPopupPage} />
        <Route path="searchKeywords" component={AdminSearchKeywordPage} />
        <Route path="products" component={AdminListProductPage} />
        <Route path="products/:productId" component={AdminProductPage} />
        <Route path="faq/category" component={AdminFAQCategoryListPage} />
        <Route path="faq/category/:categoryId" component={AdminFAQCategoryPage} />
        <Route path="faq" component={AdminFAQListPage} />
        <Route path="faq/:faqId" component={AdminFAQPage} />
        <Route path="notice" component={AdminNoticeListPage} />
        <Route path="notice/:noticeId" component={AdminNoticePage} />
        <Route path="appVersions" component={AdminListAppVersionPage} />
        <Route path="wassily/reservations" component={AdminListWassilyCSReservationPage} />
      </Route>
    </Route>
  );
}
