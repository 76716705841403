import * as React from 'react';

function Svg12BtnIconShow(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} {...props}>
      <path
        d="M12.729 5.003c5.977-.187 9.5 5.387 10.139 6.499.176.309.176.687 0 .996-.854 1.488-4.07 6.358-9.597 6.499l-.246.003c-5.863 0-9.263-5.405-9.893-6.502a1.005 1.005 0 010-.996c.854-1.488 4.07-6.358 9.597-6.499zM13 8.5c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5zm0 1.5c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Svg12BtnIconShow;

