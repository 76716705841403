import React from 'react';
import {
  StyleSheet, Dimensions, View, Text, TextInput, TouchableOpacity,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';

import AlertDialog from '../components/AlertDialog';
import Styles from '../constants/Styles';
import Colors from '../constants/Colors';
import CheckBox from './CheckBox';
import AddressSearch from './AddressSearch';


const initialLayout = { width: Dimensions.get('window').width };
const titleWidth = 75;
const contentWidth = initialLayout.width - titleWidth - 40;
const addressSearchButtonWidth = 100;

const styles = StyleSheet.create({
  container: {
  },
  infoRow: {
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'center',
  },
  infoTitle: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    width: titleWidth,
    height: 19,
    marginTop: 1,
  },
  infoContents: {
    fontSize: 15,
    width: contentWidth,
    backgroundColor: Colors.semiGray,
    height: 52,
    paddingTop: 17,
    paddingBottom: 16,
    paddingStart: 16,
    paddingEnd: 16,
  },
  infoContentsZonecode: {
    width: contentWidth - addressSearchButtonWidth - 7,
  },
  addressSearchButton: {
    width: addressSearchButtonWidth,
    backgroundColor: Colors.semiGray,
    height: 52,
    alignItems: 'center',
    marginStart: 7,
    paddingTop: 10,
    paddingBottom: 10,
    justifyContent: 'center',
  },
  addressSearchText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 14,
  },
  phoneNumberPart: {
    width: (contentWidth - (7 + 7 + 8) * 2) / 3,
  },
  phoneNumberPartDivider: {
    backgroundColor: '#000',
    marginStart: 7,
    marginEnd: 7,
    width: 7,
    height: 1,
    marginVertical: 9,
  },
  checkBoxText: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    height: 19,
    marginTop: 2,
    marginBottom: 3,
  },
  checkBoxRow: {
    marginVertical: 10,
  },
});

const webStyles = {
  checkBox: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#DDDDDD',
    marginRight: 12,
  },
};


export default class ShippingAddressEditForm extends React.Component {
  state = {
    alert: {
      open: false,
      title: null,
      content: null,
      buttons: [],
    },
    showAddressSearch: false,
  };

  shippingTitleInputRef = null;

  shippingNameInputRef = null;

  shippingAddressSubInputRef = null;

  shippingCellPhonePart1InputRef = null;

  shippingCellPhonePart2InputRef = null;

  shippingCellPhonePart3InputRef = null;

  componentDidMount() {
    if (this.shippingTitleInputRef) {
      this.shippingTitleInputRef.focus();
    }
  }

  onCloseAddressSearch = () => {
    this.setState({ showAddressSearch: false });
  };

  onSelectAddress = addressInfo => {
    const { onDataChanged } = this.props;
    const { zoneCode, roadAddress } = addressInfo;
    // console.log('ShippingAddressEditForm addressInfo=', addressInfo);

    onDataChanged({
      shippingZonecode: zoneCode,
      shippingAddress: roadAddress,
      isValid: false,
    });

    this.setState({ showAddressSearch: false }, () => {
      if (this.shippingAddressSubInputRef) {
        this.shippingAddressSubInputRef.focus();
      }
    });
  };


  validateInputField() {
    // console.log('ShippingAddressEditForm validateInputField');
    const {
      data = {},
    } = this.props;

    const {
      shippingTitle = '',
      shippingName = '',
      shippingZonecode = '',
      shippingAddress = '',
      shippingAddressSub = '',
      shippingCellPhone = '',
    } = data;
    const phoneNumberParts = shippingCellPhone.split('-');

    if (!shippingTitle) {
      this.alert('배송지명을 입력해주세요.');
      return false;
    }

    if (!shippingName) {
      this.alert('수령인을 입력해주세요.');
      return false;
    }

    if (!shippingZonecode) {
      this.alert('우편번호를 입력해주세요.');
      return false;
    }

    if (!shippingAddress) {
      this.alert('주소를 입력해주세요.');
      return false;
    }

    if (!shippingAddressSub) {
      this.alert('상세주소를 입력해주세요.');
      return false;
    }

    if (!(phoneNumberParts.length === 3
      && phoneNumberParts[0].length >= 2
      && phoneNumberParts[1].length >= 3
      && phoneNumberParts[2].length === 4
    )) {
      this.alert('연락처를 입력해주세요.');
      return false;
    }

    return true;
  }

  renderAddressSearch() {
    const { showAddressSearch } = this.state;

    if (!showAddressSearch) {
      return null;
    }

    return (
      <AddressSearch
        onClose={this.onCloseAddressSearch}
        onPressItem={this.onSelectAddress}
      />
    );
  }

  alert(msg) {
    this.setState({
      alert: {
        open: true,
        title: msg,
        content: null,
        buttons: [
          {
            text: '확인',
            onPress: () => {
              this.setState({ alert: { open: false } });
            },
          },
        ],
      },
    });
  }

  renderAlert() {
    const {
      alert: {
        open,
        title,
        content,
        buttons,
      },
    } = this.state;

    return (
      <div>
        <AlertDialog
          open={open}
          title={title}
          content={content}
          buttons={buttons}
        />
      </div>
    );
  }

  render() {
    const {
      data = {},
      containerStyle,
      onDataChanged,
      showCheckBoxSetDefault,
      showCheckBoxSaveToShippingAddress,
    } = this.props;

    const {
      defaultFl = 'n',
      newDefaultFl = false,
      shippingTitle = '',
      shippingName = '',
      shippingZonecode = '',
      shippingAddress = '',
      shippingAddressSub = '',
      shippingCellPhone = '',
      saveToShippingAddressList = false,
    } = data;

    const phoneNumberParts = shippingCellPhone.split('-');

    if (defaultFl === 'y' && !newDefaultFl) {
      onDataChanged({ newDefaultFl: true });
    }

    if (showCheckBoxSaveToShippingAddress) {
      if (newDefaultFl && !saveToShippingAddressList) {
        onDataChanged({ saveToShippingAddressList: true });
      }
    }

    const onPressAddressSearchButton = () => {
      this.setState({ showAddressSearch: true });
    };

    return (
      <View style={[styles.container, containerStyle]}>
        <View style={styles.infoRow}>
          <Text style={styles.infoTitle}>배송지명</Text>
          <TextInput
            ref={ref => { this.shippingTitleInputRef = ref; }}
            style={[styles.infoContents, { outline: 'none' }]}
            value={shippingTitle}
            onChangeText={text => onDataChanged({ shippingTitle: text, isValid: false })}
            onSubmitEditing={() => {
              if (this.shippingNameInputRef) {
                this.shippingNameInputRef.focus();
              }
            }}
          />
        </View>
        <View style={styles.infoRow}>
          <Text style={styles.infoTitle}>수령인</Text>
          <TextInput
            ref={ref => { this.shippingNameInputRef = ref; }}
            style={[styles.infoContents, { outline: 'none' }]}
            value={shippingName}
            onChangeText={text => onDataChanged({ shippingName: text, isValid: false })}
            onSubmitEditing={onPressAddressSearchButton}
          />
        </View>
        <View style={[styles.infoRow, { marginBottom: 7 }]}>
          <Text style={styles.infoTitle}>주소</Text>
          <TextInput
            style={[styles.infoContents, styles.infoContentsZonecode]}
            maxLength={5}
            value={shippingZonecode}
            editable={false}
          />
          <TouchableOpacity
            style={styles.addressSearchButton}
            onPress={onPressAddressSearchButton}
          >
            <Text style={styles.addressSearchText}>우편번호 검색</Text>
          </TouchableOpacity>
        </View>
        <View style={[styles.infoRow, { marginBottom: 7 }]}>
          <Text style={styles.infoTitle} />
          <TextInput
            style={styles.infoContents}
            value={shippingAddress}
            editable={false}
          />
        </View>
        <View style={styles.infoRow}>
          <Text style={styles.infoTitle} />
          <TextInput
            ref={ref => { this.shippingAddressSubInputRef = ref; }}
            style={[styles.infoContents, { outline: 'none' }]}
            placeholder="상세주소 입력"
            placeholderTextColor={Colors.placeholderColor}
            value={shippingAddressSub}
            onChangeText={text => onDataChanged({ shippingAddressSub: text, isValid: false })}
            onSubmitEditing={() => {
              if (this.shippingCellPhonePart1InputRef) {
                this.shippingCellPhonePart1InputRef.focus();
              }
            }}
          />
        </View>
        <View style={styles.infoRow}>
          <Text style={styles.infoTitle}>연락처</Text>
          <TextInput
            ref={ref => { this.shippingCellPhonePart1InputRef = ref; }}
            style={[styles.infoContents, styles.phoneNumberPart, { outline: 'none' }]}
            maxLength={3}
            value={phoneNumberParts[0]}
            keyboardType="number-pad"
            onChangeText={text => {
              phoneNumberParts[0] = text;
              onDataChanged({ shippingCellPhone: phoneNumberParts.join('-'), isValid: false });
            }}
            onSubmitEditing={() => {
              if (this.shippingCellPhonePart2InputRef) {
                this.shippingCellPhonePart2InputRef.focus();
              }
            }}
          />

          <View style={styles.phoneNumberPartDivider} />

          <TextInput
            ref={ref => { this.shippingCellPhonePart2InputRef = ref; }}
            style={[
              styles.infoContents,
              styles.phoneNumberPart,
              { outline: 'none', marginHorizontal: 1 },
            ]}
            maxLength={4}
            value={phoneNumberParts[1]}
            keyboardType="number-pad"
            onChangeText={text => {
              phoneNumberParts[1] = text;
              onDataChanged({ shippingCellPhone: phoneNumberParts.join('-'), isValid: false });
            }}
            onSubmitEditing={() => {
              if (this.shippingCellPhonePart3InputRef) {
                this.shippingCellPhonePart3InputRef.focus();
              }
            }}
          />

          <View style={styles.phoneNumberPartDivider} />

          <TextInput
            ref={ref => { this.shippingCellPhonePart3InputRef = ref; }}
            style={[styles.infoContents, styles.phoneNumberPart, { outline: 'none' }]}
            maxLength={4}
            value={phoneNumberParts[2]}
            keyboardType="number-pad"
            onChangeText={text => {
              phoneNumberParts[2] = text;
              onDataChanged({ shippingCellPhone: phoneNumberParts.join('-'), isValid: false });
            }}
          />
        </View>
        { showCheckBoxSetDefault
          && (
            <View style={[styles.infoRow, styles.checkBoxRow]}>
              <Text style={styles.infoTitle} />
              <CheckBox
                baseStyle={webStyles.checkBox}
                checked={newDefaultFl}
                fixed={defaultFl === 'y'}
                onPress={isChecked => onDataChanged({ newDefaultFl: isChecked })}
              />
              <Text style={styles.checkBoxText}>기본 배송지로 등록</Text>
            </View>
          )
        }

        { showCheckBoxSaveToShippingAddress
          && (
            <View style={[styles.infoRow, styles.checkBoxRow]}>
              <Text style={styles.infoTitle} />
              <CheckBox
                baseStyle={webStyles.checkBox}
                checked={saveToShippingAddressList}
                onPress={isChecked => onDataChanged({ saveToShippingAddressList: isChecked })}
              />
              <Text style={styles.checkBoxText}>배송지 목록에 추가</Text>
            </View>
          )
        }
        {this.renderAddressSearch()}
        {this.renderAlert()}
      </View>
    );
  }
}
