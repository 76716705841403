import React, { Component } from 'react';
import ga from 'react-ga';
import Parser from 'url-parse';
import Cancel from '@material-ui/icons/Cancel';

const styles = {
  container: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '960px',
    position: 'relative',
  },
  image: {
    width: '100%',
  },
  closeImage: {
    fontSize: 32,
    position: 'absolute',
    right: '2%',
    top: 'calc(50% - 16px)',
    align: 'top',
    lineHeight: 0,
  },
};

function getUrlWithUtmSource(url, source) {
  if (!url) {
    return null;
  }
  const urlObj = new Parser(url, true);
  if (source) {
    urlObj.query.utm_source = source;
  }
  if (urlObj.host === 'heartit.kr') {
    urlObj.protocol = window.location.protocol;
    urlObj.host = window.location.host;
  }
  return urlObj.toString();
}

export default class MarketingBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: '',
      targetUrl: '',
      id: '',
      imageLoaded: false,
    };
  }

  async componentDidMount() {
    const url = '/api/v2/banners/random';
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    await fetch(url, { headers })
      .then(response => {
        if (!response.ok) {
          throw new Error('this account may be private.');
        } else if (response.status === 204) {
          return null;
        }
        return response.json();
      })
      .then(banner => {
        if (banner === null || banner === undefined) {
          throw new Error('No media');
        }
        this.setState({ imageUrl: banner.imageUrl, targetUrl: banner.targetUrl, id: banner.id });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  handleClickClose(event) {
    const { onClose } = this.props;
    event.preventDefault();
    onClose();
  }

  render() {
    if ($pt.isApp()) {
      ga.event({
        category: '(ignore) Dev $pt',
        action: 'MarketingBanner',
        label: 'render',
      });
      return null;
    } else {
      const { imageUrl, targetUrl, id, imageLoaded } = this.state;
      const targetUrlWithSource = getUrlWithUtmSource(targetUrl, window.location.hostname);
      const encodedUrl = encodeURIComponent(targetUrlWithSource);
      const bannerUrl = `/link?banner=${id}&url=${encodedUrl}&agent=web&history=no`;

      if (!imageUrl) {
        return null;
      }

      return (
        <div style={styles.container}>
          <a href={bannerUrl} target="_blank" rel="noopener noreferrer">
            <img style={styles.image} src={imageUrl} alt="" onLoad={() => this.setState({ imageLoaded: true })} />
            {imageLoaded && (
              <Cancel style={styles.closeImage} color="disabled" onClick={event => this.handleClickClose(event)} />
            )}
          </a>
        </div>
      );
    }
  }
}
