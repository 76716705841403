import React from 'react';
import {
  StyleSheet, Dimensions, View, Text, TouchableOpacity, TouchableHighlight,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';

import AlertDialog from '../components/AlertDialog';

import RadioSelectedIcon from '../icons/RadioSelectedIcon';
import RadioNormalIcon from '../icons/RadioNormalIcon';
import Colors from '../constants/Colors';
import Styles from '../constants/Styles';
import { getPlatform } from '../utils';


const initialLayout = { width: Dimensions.get('window').width };
const titleWidth = 71;
const contentsWidth = initialLayout.width - titleWidth - 20 * 2 - 12 - 24;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 20,
    marginHorizontal: 20,
  },
  infoContainer: {
  },
  infoRow: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  infoTitle: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    width: titleWidth,
    height: 19,
  },
  infoContents: {
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    width: contentsWidth,
    ...Styles.appleSDGothicNeoBold,
    fontSize: 15,
    lineHeight: 19,
  },
  endControlContainer: {
    flexDirection: 'row',
    position: 'absolute',
    top: -4,
    end: -4,
  },
  defaultShippingAddressContainer: {
    backgroundColor: '#000',
    borderRadius: 9.5,
    marginRight: 12,
    marginTop: 4,
    height: 19,
    paddingHorizontal: 7,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonDivider: {
    marginStart: 4,
    marginEnd: 4,
  },
  buttonText: {
    height: 19,
    ...Styles.appleSDGothicNeoSemiBold,
    fontSize: 15,
    lineHeight: 19,
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});

const webStyles = {
  radioButton: {
    marginRight: 12,
  },
  defaultShippingAddressText: {
    display: 'table-cell',
    verticalAlign: 'middle',
    backgroundColor: 'transparent',
    color: '#fff',
    lineHeight: 13,
    fontSize: 11,
    ...Styles.appleSDGothicNeoRegular,
  },
  defaultShippingAddressTextiOS: {
    backgroundColor: 'transparent',
    color: '#fff',
    fontSize: 11,
    ...Styles.appleSDGothicNeoRegular,
  },
};

function renderStartControl(props) {
  const {
    selected,
    onPressRadioButton,
    hideRadioButton,
  } = props;

  const Icon = selected ? RadioSelectedIcon : RadioNormalIcon;

  if (hideRadioButton) {
    return null;
  }

  return (
    <View style={styles.startControlContainer}>
      <TouchableOpacity
        onPress={onPressRadioButton}
        style={styles.radioButtonContainer}
      >
        <Icon style={webStyles.radioButton} />
      </TouchableOpacity>
    </View>
  );
}


function renderInfo(props) {
  const {
    item,
  } = props;

  const {
    shippingTitle: name,
    shippingName: receiverName,
    shippingAddress,
    shippingAddressSub,
    shippingCellPhone: phoneNumber,
  } = item;

  const address = `${shippingAddress} ${shippingAddressSub}`;
  return (
    <View style={styles.infoContainer}>
      <View style={styles.infoRow}>
        <Text style={styles.infoTitle}>배송지명</Text>
        <Text style={styles.infoContents}>{name}</Text>
      </View>
      <View style={styles.infoRow}>
        <Text style={styles.infoTitle}>수령인</Text>
        <Text style={styles.infoContents}>{receiverName}</Text>
      </View>
      <View style={styles.infoRow}>
        <Text style={styles.infoTitle}>주소</Text>
        <Text style={styles.infoContents}>{address}</Text>
      </View>
      <View style={styles.infoRow}>
        <Text style={styles.infoTitle}>연락처</Text>
        <Text style={styles.infoContents}>{phoneNumber}</Text>
      </View>
    </View>
  );
}

export default class ShippingAddress extends React.Component {
  state = {
    alert: {
      open: false,
      title: null,
      content: null,
      buttons: [],
    },
  };

  renderEndControl() {
    const {
      onPressEditButton: editCallback,
      onPressDeleteButton: deleteCallback,
      item,
    } = this.props;

    const {
      defaultFl,
    } = item;


    const isDefaultAddress = defaultFl === 'y';

    const onPressEditButton = () => {
      editCallback();
      this.setState({ alert: { open: false } });
    };

    const onPressDeleteButton = () => {
      this.setState({
        alert: {
          open: true,
          title: '배송지를 삭제하시겠습니까?',
          content: null,
          buttons: [
            {
              text: '취소',
              onPress: () => {
                this.setState({ alert: { open: false } });
              },
            },
            {
              text: '삭제',
              onPress: () => {
                deleteCallback();
                this.setState({ alert: { open: false } });
              },
            },
          ],
        },
      });
    };

    const defaultShippingAddressTextStyle = getPlatform() === 'ios'
      ? webStyles.defaultShippingAddressTextiOS
      : webStyles.defaultShippingAddressText;

    return (
      <View style={styles.endControlContainer}>
        { isDefaultAddress
          && (
            <View style={styles.defaultShippingAddressContainer}>
              <Text style={defaultShippingAddressTextStyle}>
                기본 배송지
              </Text>
            </View>
          )
        }

        <TouchableHighlight
          onPress={onPressEditButton}
          underlayColor={Colors.touchEffectColorWhite}
          style={styles.touchEffect}
        >
          <Text style={styles.buttonText}>수정</Text>
        </TouchableHighlight>

        { isDefaultAddress || [
          <View key="buttonDivider" style={styles.buttonDivider} />,
          <TouchableHighlight
            key="deleteButton"
            onPress={onPressDeleteButton}
            underlayColor={Colors.touchEffectColorWhite}
            style={styles.touchEffect}
          >
            <Text style={[
              styles.buttonText,
              { color: 'rgb(255, 62, 62)' },
            ]}
            >
              삭제
            </Text>
          </TouchableHighlight>,
        ]}

      </View>
    );
  }

  renderAlert() {
    const {
      alert: {
        open,
        title,
        content,
        buttons,
      },
    } = this.state;

    return (
      <div>
        <AlertDialog
          open={open}
          title={title}
          content={content}
          buttons={buttons}
        />
      </div>
    );
  }

  render() {
    return (
      <View style={styles.container}>
        {renderStartControl(this.props)}
        {renderInfo(this.props)}
        {this.renderEndControl()}
        {this.renderAlert()}
      </View>
    );
  }
}
