import React from 'react';
import { Dimensions, StyleSheet, Text, View } from 'react-native';

import Constants from '../constants/Constants';
import Styles from '../constants/Styles';


export default class Toast extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timer: null,
    };
  }

  componentDidMount() {
    const { show } = this.props;

    if (show) {
      this.setState({
        timer: this.getToastTimer(),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { show, msg } = this.props;

    if ((!show && prevProps.show)
      || (msg !== prevProps.msg)
    ) {
      this.clearToastTimer();
      this.setState({
        timer: this.getToastTimer(),
      });
    }
  }

  clearToastTimer = () => {
    const { timer } = this.state;

    if (timer) {
      clearTimeout(timer);
    }
  }

  getToastTimer = () => {
    const { duration = 2000 } = this.props;

    return setTimeout(() => {
      const { hideToastCallback } = this.props;
      hideToastCallback();
      this.setState({ timer: null });
    }, duration);
  }

  render() {
    const { show = false, toastContainerStyle = {}, toastTextStyle = {}, msg } = this.props;

    if (!show) {
      return null;
    }

    return (
      <View style={[styles.toastContainer, toastContainerStyle]}>
        <Text style={[styles.toastText, toastTextStyle]}>
          { msg }
        </Text>
      </View>
    );
  }
}

const { width: windowWidth } = Dimensions.get('window');
const styles = StyleSheet.create({
  toastContainer: {
    padding: 0,
    opacity: 1,
    borderRadius: 0,
    backgroundColor: 'black',
    marginHorizontal: 10,
    height: 52,
  },
  toastText: {
    color: 'white',
    marginTop: 18,
    marginBottom: 17,
    marginHorizontal: 20,
    lineHeight: 17,
    height: 17,
    fontSize: 14,
    ...Styles.appleSDGothicNeoRegular,
  },
});
