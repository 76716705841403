import React from 'react';
import {
    StyleSheet, View, Text, Alert,
} from 'react-native';

import { connect } from 'react-redux';
import { actions } from 'shared';
import { Colors, Constants, Styles } from '../../../constants';
import {
  TouchableHighlightWithContainer,
  InputForm,
} from '../../../components-v2';
import {
  LoginEmailIcon,
  LoginNaverIcon,
  LoginFacebookIcon,
  LoginKakaoIcon,
  LoginAppleIcon,
} from '../../../icons-v2';
import { runAfterLoginDone } from '../../../containers/auth/util';


const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Constants.viewHorizontalPadding,
    paddingTop: 40,
    paddingBottom: 40,
  },
  inputErrorMsgText: {
    ...Styles.pretendard400,
    fontSize: 12,
    lineHeight: 14,
    color: Colors.errorText,
    marginTop: 8,
  },
  findUserInfoContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 30,
  },
  findUserInfoText: {
    ...Styles.pretendard400,
    fontSize: 14,
    lineHeight: 16.8,
    color: '#000',
  },
  loginButtonText: {
    textAlign: 'center',
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    color: '#ffffff7f',
    backgroundColor: '#000',
    paddingVertical: 10.5,
  },
  loginButtonTextEnabled: {
    color: '#fff',
  },
  socialLoginButtonContainer: {
    marginTop: 30,
  },
  socialLoginButton: {
    borderWidth: 0.5,
    borderColor: '#000',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  socialLoginIcon: {
    position: 'absolute',
    left: 10,
  },
  socialLoginButtonText: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    textAlign: 'center',
    paddingTop: 11,
    paddingBottom: 10,
  },
});


class LoginForm extends React.Component {

  constructor(props) {
    super(props);

    this.onPressLoginButton = this.onPressLoginButton.bind(this);
  }

  state = {
    isPasswordHidden: true,
  };
  inputEmailRef = null;
  inputPasswordRef = null;

  validateInput() {
    if (!this.inputEmailRef.validate()) {
      return false;
    }

    return true;
  }

  componentDidUpdate() {
    const {
      inputEmailText = '',
      inputPasswordText = '',
      enabledLoginButton = false,
    } = this.state;

    if (inputEmailText !== '' && inputPasswordText !== '') {
      if (!enabledLoginButton) {
        this.setState({
          enabledLoginButton: true,
        })
      }
    } else {
      if (enabledLoginButton) {
        this.setState({
          enabledLoginButton: false,
        })
      }
    }
  }

  async handleLoginWithEmail() {
    const { router, loginWithEmailAccount, loadCurrentUser } = this.props;
    const { inputEmailText: email, inputPasswordText: password } = this.state;

    try {
      const userData = await loginWithEmailAccount(email, password);
      await loadCurrentUser(userData.token);
      runAfterLoginDone(router);
    } catch (e) {
      if (e.status === 403) {
        router.push({
          pathname: '/accounts/login/email/verify',
          state: { email },
        });
        return;
      }

      this.setState({ loginErrorMsg: '이메일 또는 비밀번호가 잘못되었습니다' });
    }
  }

  onPressLoginButton = () => {
    this.setState({
      inputEmailErrorMsg: '',
      loginErrorMsg: '',
    }, () => {
      if (!this.validateInput()) {
        return
      }

      this.handleLoginWithEmail();
    });
  }

  renderEmailInput() {
    const {
      inputEmailText,
    } = this.state;

    return (
      <InputForm
        type='email'
        title='이메일 주소'
        placeholder="이메일 주소를 입력해주세요."
        description="예) heartit@heartit.kr"
        keyboardType="email-address"
        value={inputEmailText}
        onInputRef={ref => { this.inputEmailRef = ref; }}
        onChangeText={val => this.setState({ inputEmailText: val })}
        onSubmitEditing={() => this.inputPasswordRef.focus()}
        enableErrorMessage
        />
    );
  }

  renderPasswordInput() {
    const {
      inputPasswordText,
    } = this.state;

    return (
      <InputForm
        type='password'
        title='비밀번호'
        placeholder='비밀번호를 입력해주세요'
        value={inputPasswordText}
        onInputRef={ref => { this.inputPasswordRef = ref; }}
        onChangeText={val => this.setState({ inputPasswordText: val })}
        onSubmitEditing={this.onPressLoginButton}
        />
    );
  }

  renderFindUserInfo() {
    const { router } = this.props;
    const onPress = () => {
      router.push('/accounts/login/email/recovery')
    };

    return (
      <View style={styles.findUserInfoContainer}>
        <TouchableHighlightWithContainer
          onPress={onPress}
        >
          <Text style={styles.findUserInfoText}>아이디/비밀번호 찾기</Text>
        </TouchableHighlightWithContainer>
      </View>
    )
  }

  renderLoginButton() {

    const { enabledLoginButton } = this.state;

    let onPress = () => null;

    const buttonStyles = [styles.loginButtonText];


    if (enabledLoginButton) {
      buttonStyles.push(styles.loginButtonTextEnabled);
      onPress = this.onPressLoginButton;
    }

    return (
      <TouchableHighlightWithContainer
        onPress={onPress}
      >
        <Text style={buttonStyles}>로그인</Text>

      </TouchableHighlightWithContainer>
    )
  }

  renderSocialLoginButtons() {
    const { router } = this.props;

    const onPressSignUpWithEmail = () => {
      router.push('/accounts/join/email');
    };
    const onPressSignUpWithNaver = async () => {
      const json = await this.props.loadNaverAuthUrl();
      window.location.href = json.url;
    }
    const onPressSignUpWithFacebook = async () => {
      const json = await this.props.loadFacebookAuthUrl();
      window.location.href = json.url;
    }
    const onPressSignUpWithKakao = async () => {
      const json = await this.props.loadKakaoAuthUrl();
      window.location.href = json.url;
    }
    const onPressSignUpWithApple = async () => {
      const json = await this.props.loadAppleAuthUrl();
      window.location.href = json.url;
    }

    const separator = (
      <View style={{ height: 16 }} />
    );

    return (
      <View style={styles.socialLoginButtonContainer}>
        <TouchableHighlightWithContainer
          onPress={onPressSignUpWithEmail}
          >
          <View style={styles.socialLoginButton}>
            <Text style={styles.socialLoginButtonText}>이메일로 가입하기</Text>
            <View style={styles.socialLoginIcon}>
              <LoginEmailIcon />
            </View>
          </View>
        </TouchableHighlightWithContainer>

        {separator}

        <TouchableHighlightWithContainer
          onPress={onPressSignUpWithNaver}
          >
          <View style={styles.socialLoginButton}>
            <Text style={styles.socialLoginButtonText}>네이버로 로그인하기</Text>
            <View style={styles.socialLoginIcon}>
              <LoginNaverIcon />
            </View>
          </View>
        </TouchableHighlightWithContainer>

        {separator}

        <TouchableHighlightWithContainer
          onPress={onPressSignUpWithFacebook}
          >
          <View style={styles.socialLoginButton}>
            <Text style={styles.socialLoginButtonText}>페이스북으로 로그인하기</Text>
            <View style={styles.socialLoginIcon}>
              <LoginFacebookIcon />
            </View>
          </View>
        </TouchableHighlightWithContainer>

        {separator}

        <TouchableHighlightWithContainer
          onPress={onPressSignUpWithKakao}
          >
          <View style={styles.socialLoginButton}>
            <Text style={styles.socialLoginButtonText}>카카오로 로그인하기</Text>
            <View style={styles.socialLoginIcon}>
              <LoginKakaoIcon />
            </View>
          </View>
        </TouchableHighlightWithContainer>

        {separator}

        <TouchableHighlightWithContainer
          onPress={onPressSignUpWithApple}
          >
          <View style={styles.socialLoginButton}>
            <Text style={styles.socialLoginButtonText}>애플아이디로 로그인하기</Text>
            <View style={styles.socialLoginIcon}>
              <LoginAppleIcon />
            </View>
          </View>
        </TouchableHighlightWithContainer>
      </View>
    );
  }

  render() {
    const { loginErrorMsg='' } = this.state;

    return (
      <View style={styles.container}>
        {this.renderEmailInput()} 
        <View style={{ height: 40 }} />
        {this.renderPasswordInput()}
        { loginErrorMsg !== '' && (
          <Text style={styles.inputErrorMsgText}>{loginErrorMsg}</Text>
        )}
        <View style={{ height: 40 }} />
        {this.renderLoginButton()}
        {this.renderFindUserInfo()}
        {this.renderSocialLoginButtons()}
      </View>
    );
  }
}


export default connect(
  null,
  actions,
)(LoginForm);
