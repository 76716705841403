import * as React from 'react';

function SvgIcLikeSelectedHalf(props) {
  return (
    <svg width={18} height={18} {...props}>
      <g
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <path
          stroke="#000"
          d="M9 15l1.816-1.834 3.569-3.603a3.87 3.87 0 000-5.437 3.783 3.783 0 00-5.385 0"
          opacity={0.251}
        />
        <path
          fill="#EE2424"
          fillOpacity={0.6}
          stroke="#EE2424"
          strokeLinecap="round"
          d="M9 4.126a3.783 3.783 0 00-5.385 0 3.87 3.87 0 000 5.437L9 15"
        />
      </g>
    </svg>
  );
}

export default SvgIcLikeSelectedHalf;
