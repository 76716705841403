import React from 'react';
import {
  StyleSheet, Text, TextInput, View, TouchableHighlight,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';
import { isEmail } from 'validator';
import { Snackbar } from '@material-ui/core';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';

import PasswordHideIcon from '../../icons/btnIconHide';
import PasswordShowIcon from '../../icons/btnIconShow';

import Styles from '../../constants/Styles';
import Colors from '../../constants/Colors';

import Button from '../../nativeComponents/Button';
import LogoNaverIcon from '../../icons/LogoNaverCircleIcon';
import LogoKakaoIcon from '../../icons/LogoKakaoCircleIcon';
import LogoFacebookIcon from '../../icons/LogoFacebookCircleIcon';
import Constants from '../../constants/Constants';

const LogoAppleIcon = require('../../icons/imgLogoApple.png');


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
  needLoginTextContainer: {
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginTop: 39,
    marginBottom: 32,
  },
  needLoginText: {
    height: 54,
    lineHeight: 27,
    fontSize: 22,
    ...Styles.appleSDGothicNeoBold,
  },
  loginButton: {
    height: 54,
    width: 54,
    borderRadius: 27,
    marginLeft: 7.5,
    marginRight: 7.5,
  },
  signInWithText: {
    height: 17,
    marginBottom: 20,
    textAlign: 'center',
    opacity: 0.55,
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 14,
    lineHeight: 17,
    color: 'rgb(34, 34, 34)',
  },
  socialLoginContainer: {
    width: '100%',
    marginTop: 32,
  },
  socialLoginButtonContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 60,
  },
});

const stylesEmailForm = StyleSheet.create({
  container: {
    width: '100%',
  },
  disabled: {
    backgroundColor: '#cccccc',
    borderColor: 'lightgrey',
    marginTop: 15,
    padding: 20,
    width: '100%',
  },
  inputContainer: {
    alignItems: 'center',
  },
  inputText: {
    width: '100%',
    paddingTop: 11,
    paddingBottom: 9,
    paddingLeft: 16,
    paddingRight: 48,
    fontSize: 15,
    lineHeight: 20,
    ...Styles.appleSDGothicNeoLight,
  },
  inputBorder: {
    marginTop: 10,
    marginBottom: 0,
    width: '100%',
    height: 40,
    backgroundColor: Colors.semiGray,
  },
  errorMessage: {
    height: 20,
    marginTop: 6,
    marginBottom: 5,
    ...Styles.appleSDGothicNeoLight,
    fontSize: 15,
    color: 'rgb(252, 43, 56)',
  },
  passwordErrorMessage: {
    marginBottom: -5,
  },
  passwordShowIconContainer: {
    position: 'absolute',
    top: 4,
    bottom: 4,
    right: 8,
    width: 24 + Constants.touchEffectWidth * 2,
    height: 24 + Constants.touchEffectWidth * 2,
  },
  passwordShowIcon: {
    ...Styles.touchEffect,
  },
  textButtonContainer: {
    marginTop: 20 - Constants.touchEffectWidth,
    marginHorizontal: -Constants.touchEffectWidth,
    marginBottom: -Constants.touchEffectWidth,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  findPasswordButtonContainer: {
    borderWidth: 0,
    padding: Constants.touchEffectWidth,
    borderRadius: Constants.touchEffectBorderRadius,
  },
  textButtonTitle: {
    fontSize: 14,
    ...Styles.appleSDGothicNeoRegular,
    color: 'rgb(34, 34, 34)',
  },
  loginButtonContainer: {
    backgroundColor: 'black',
    marginTop: 35,
    width: '100%',
    borderWidth: 0,
    borderRadius: 2,
    height: 56,
  },
  loginButton: {
    backgroundColor: 'transparent',
    paddingTop: 20,
    paddingBottom: 19,
    width: '100%',
    borderWidth: 0,
    height: 56,
  },
  loginButtonTitle: {
    color: 'white',
    height: 17,
    ...Styles.appleSDGothicNeoBold,
    fontSize: 14,
    lineHeight: 17,
  },
  joinWithEmailButton: {
    borderWidth: 1.5,
    borderColor: 'black',
    paddingTop: 20 - 1.5,
    paddingBottom: 19 - 1.5,
    marginTop: 10,
    width: '100%',
    height: 56,
    borderRadius: 2,
  },
  joinWithEmailButtonTitle: {
    color: 'black',
    height: 17,
    lineHeight: 17,
    fontSize: 14,
    ...Styles.appleSDGothicNeoBold,
  },
  emailError: {
    borderColor: 'red',
  },
});

const stylesHtml = {
  icon: {
  },
  iconApple: {
  },
};

export class AuthScreen extends React.Component {
  state = {
    email: '',
    password: '',
    showPassword: false,
    snackbarMsg: '',
    snackbarOpen: false,
  };

  passwordInputRef = null;

  componentDidMount() {
    this.bootstrapAsync();
  }

  bootstrapAsync = () => {
    const { auth, router } = this.props;
    if (auth && auth.token) {
      router.push('/');
    }
  };

  onPressLogin = () => {
    if (!this.validateInput()) {
      return;
    }

    this.handleLoginWithEmail();
  }

  async handleLoginWithEmail() {
    const { router, loginWithEmailAccount, loadCurrentUser } = this.props;
    const { email, password } = this.state;

    try {
      const userData = await loginWithEmailAccount(email, password);
      await loadCurrentUser(userData.token);
      router.push('/');
    } catch (e) {
      if (e.status === 403) {
        router.push({
          pathname: '/accounts/login/email/verify',
          state: { email },
        });
        return;
      }

      this.setState({ passwordErrorMessage: '이메일 또는 비밀번호가 잘못되었습니다' });
    }
  }

  validateInput() {
    const { email, password } = this.state;

    this.setState({
      emailErrorMessage: '',
      passwordErrorMessage: '',
    });

    if (!email || email === '') {
      this.setState({ emailErrorMessage: '이메일을 입력하세요' });
      return false;
    }

    if (!isEmail(email)) {
      this.setState({ emailErrorMessage: '이메일 형식을 확인하세요' });
      return false;
    }

    if (!password || password === '') {
      this.setState({ passwordErrorMessage: '비밀번호를 입력하세요' });
      return false;
    }

    return true;
  }

  showSnackbar(msg) {
    this.setState({
      snackbarMsg: msg,
      snackbarOpen: true,
    });
  }

  loginWithEmail() {
    const { router } = this.props;
    router.push('/accounts/login/email');
  }

  async loginWithFacebook() {
    const json = await this.props.loadFacebookAuthUrl();
    window.location.href = json.url;
  }

  async loginWithNaver() {
    const json = await this.props.loadNaverAuthUrl();
    window.location.href = json.url;
  }

  async loginWithKakao() {
    const json = await this.props.loadKakaoAuthUrl();
    window.location.href = json.url;
  }

  async loginWithApple() {
    const json = await this.props.loadAppleAuthUrl();
    window.location.href = json.url;
  }

  render() {
    const { router } = this.props;
    const {
      email, password, showPassword,
      emailErrorMessage, passwordErrorMessage,
    } = this.state;

    return (
      <View style={styles.container}>
        <View style={styles.needLoginTextContainer}>
          <Text style={styles.needLoginText}>
            로그인이 필요한
            {'\n'}
            페이지입니다.
          </Text>
        </View>

        <View style={stylesEmailForm.container}>
          <TextInput
            autoCapitalize="none"
            keyboardType="email-address"
            onChangeText={val => this.setState({ email: val })}
            placeholder="이메일 주소  예) heartit@heartit.kr"
            placeholderTextColor={Colors.placeholderColor}
            style={[
              stylesEmailForm.inputBorder,
              stylesEmailForm.inputText,
              email && !isEmail(email) ? stylesEmailForm.emailError : null,
              { outline: 'none' },
            ]}
            value={email}
            onSubmitEditing={() => {
              if (this.passwordInputRef) {
                this.passwordInputRef.focus();
              }
            }}
          />

          {!!emailErrorMessage && (
            <Text style={[stylesEmailForm.errorMessage]}>
              {emailErrorMessage}
            </Text>
          )}

          <View style={[stylesEmailForm.inputBorder]}>
            <TextInput
              ref={ref => { this.passwordInputRef = ref; }}
              autoCapitalize="none"
              keyboardType="default"
              onChangeText={val => this.setState({ password: val })}
              placeholder="비밀번호"
              placeholderTextColor={Colors.placeholderColor}
              style={[
                stylesEmailForm.inputText,
                { outline: 'none' },
              ]}
              secureTextEntry={!showPassword}
              value={password}
              onSubmitEditing={this.onPressLogin}
            />
            <View style={stylesEmailForm.passwordShowIconContainer}>
              <TouchableHighlight
                style={stylesEmailForm.passwordShowIcon}
                underlayColor={Colors.touchEffectColorWhite}
                onPress={() => { this.setState({ showPassword: !showPassword }); }}
              >
                {showPassword ? (<PasswordShowIcon />) : (<PasswordHideIcon />)}
              </TouchableHighlight>
            </View>
          </View>

          {!!passwordErrorMessage && (
            <Text style={[
              stylesEmailForm.errorMessage,
              stylesEmailForm.passwordErrorMessage,
            ]}
            >
              {passwordErrorMessage}
            </Text>
          )}

          <View style={stylesEmailForm.textButtonContainer}>
            <Button
              containerStyle={stylesEmailForm.findPasswordButtonContainer}
              titleStyle={stylesEmailForm.textButtonTitle}
              onPress={() => router.push('/accounts/login/email/recovery')}
              title="아이디/비밀번호 찾기"
            />
          </View>
          <View style={stylesEmailForm.loginButtonContainer}>
            <Button
              containerStyle={stylesEmailForm.loginButton}
              titleStyle={stylesEmailForm.loginButtonTitle}
              underlayColor={Colors.touchEffectColorBlack}
              onPress={this.onPressLogin}
              title="로그인"
            />
          </View>
          <Button
            containerStyle={stylesEmailForm.joinWithEmailButton}
            titleStyle={stylesEmailForm.joinWithEmailButtonTitle}
            onPress={() => router.push('/accounts/join/email')}
            title="이메일로 가입하기"
          />
        </View>

        <View style={styles.socialLoginContainer}>
          <Text style={styles.signInWithText}>
            소셜 계정으로 계속하기
          </Text>

          <View style={styles.socialLoginButtonContainer}>
            <TouchableHighlight
              style={styles.loginButton}
              onPress={() => this.loginWithNaver()}
              underlayColor={Colors.touchEffectColorWhite}
            >
              <LogoNaverIcon />
            </TouchableHighlight>

            <TouchableHighlight
              style={styles.loginButton}
              onPress={() => this.loginWithFacebook()}
              underlayColor={Colors.touchEffectColorWhite}
            >
              <LogoFacebookIcon />
            </TouchableHighlight>

            <TouchableHighlight
              style={styles.loginButton}
              onPress={() => this.loginWithKakao()}
              underlayColor={Colors.touchEffectColorWhite}
            >
              <LogoKakaoIcon />
            </TouchableHighlight>

            <TouchableHighlight
              style={styles.loginButton}
              onPress={() => this.loginWithApple()}
              underlayColor={Colors.touchEffectColorWhite}
            >
              <img alt="" src={LogoAppleIcon.default} />
            </TouchableHighlight>
          </View>
        </View>
        <Snackbar
          autoHideDuration={2000}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
          style={{ textAlign: 'center' }}
        />
      </View>
    );
  }
}


export default connect(
  state => ({ auth: state.auth }),
  actions,
)(AuthScreen);
