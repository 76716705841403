import React from 'react';
import { connect } from 'react-redux';
import {
  Dimensions, FlatList, View, Text, StyleSheet, TextInput,
} from 'react-native';

import { actions } from 'shared';

import Button from '../../../components-v2/Button';
import { CrossIcon } from '../../../icons-v2';
import { BottomSheetModal, TouchableHighlightWithContainer } from '../../../components-v2';
import { Constants, Styles, Colors } from '../../../constants';


const selectedTagSeparator = 4;

const {
  width: windowWidth,
} = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  inputTagContainer: {
    flexDirection: 'row',
  },
  inputContainer: {
    flex: 1,
  },
  inputText: {
    color: '#000',
    fontSize: 16,
    lineHeight: 19.2,
    marginVertical: 0,
    marginHorizontal: Constants.viewHorizontalPadding,
    paddingHorizontal: 0,
    paddingVertical: 8,
    ...Styles.pretendard400,
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    outlineStyle: 'none',
  },
  selectedTagContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingVertical: Constants.viewHorizontalPadding,
    marginHorizontal: Constants.viewHorizontalPadding,
    borderBottomWidth: 1,
    borderBottomColor: Colors.defaultGray02,
  },
  selectedTagButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 6,
    paddingBottom: 6,
    paddingStart: 16,
    paddingEnd: 11,
    marginEnd: selectedTagSeparator,
    marginTop: selectedTagSeparator,
  },
  selectedTagTitle: {
    fontSize: 15,
    lineHeight: 18,
    ...Styles.pretendard600,
  },
  listContainer: {
    backgroundColor: 'blue',
  },
  searchResultItemContainer: {
    marginHorizontal: Constants.viewHorizontalPadding,
    paddingVertical: 20,
    paddingHorizontal: 0,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderBottomColor: Colors.defaultGray02,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  searchResultText: {
    color: '#000',
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard400,
  },
  bottomContainer: {
    position: 'fixed',
    start: 0,
    end: 0,
    bottom: 0,
    backgroundColor: '#000',
    flexDirection: 'row',
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  buttonContainerStyle: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomButtonText: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    textAlign: 'center',
    color: '#ffffff',
    opacity: 0.5,
    marginTop: 20,
    marginBottom: 21,
    height: 19,
    width: windowWidth - Constants.viewHorizontalPadding * 2,

  },
  bottomButtonTextActive: {
    opacity: 1,
  },
});


class StyleUploadAddTagPage extends BottomSheetModal {
  title = '태그 추가'
  titleClose = '취소'
  subTitle = ''

  state = {
    inputValue: '',
    selectedTags: [],
  };

  onPressConfirm = () => {
    const { callback, setModalProps } = this.props;
    callback(this.state.selectedTags);
    setModalProps({
      isVisible: false,
      children: null,
    });
  }

  componentDidMount() {
    const { logEventPageview } = this.props;

    logEventPageview({ page_name: 'StyleUploadAddTag' });
  }

  renderSelectedTagItem = ({ item, index }) => {
    return (
      <Button
        key={`selected-${index}`}
        innerComponent={(
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={styles.selectedTagTitle}>#{item}</Text>
            <CrossIcon style={{ marginLeft: 4 }} />
          </View>
        )}
        containerStyle={styles.selectedTagButton}
        onPress={() => this.deleteSelectedTag(item)}
      />
    );
  }

  renderSearchResultItem = ({ item, index }) => {
    return (
      <Button
        key={`search-${index}`}
        title={`#${item}`}
        containerStyle={styles.searchResultItemContainer}
        titleStyle={styles.searchResultText}
        onPress={() => this.addSelectedTag(item)}
      />
    );
  }

  addSelectedTag = (tag) => {
    if (tag.length > 0 && this.state.selectedTags.findIndex(elem => elem == tag) == -1) {
      this.setState((state) => {
        return {
          selectedTags: [...state.selectedTags, tag]
        };
      });
    }
  }

  deleteSelectedTag = (tag) => {
    const { selectedTags } = this.state;
    this.setState({
      selectedTags: selectedTags.filter(elem => elem !== tag)
    });
  }

  onSubmitEditing = _evt => {
    const { inputValue } = this.state;
    console.log('onSubmitEditing', inputValue);

    const words = inputValue.split(' ').filter(elem => elem.length > 0);
    for (const tag of words) {
      this.addSelectedTag(tag);
    }

    this.setState({
      inputValue: '',
    });
    this.inputRef.focus();
  }

  onChangeText = async (value) => {
    const { loadStylePostTags } = this.props;
    this.setState({ inputValue: value });
    if (value.trim() !== '') {
      const result = await loadStylePostTags({ keyword: value });
      this.setState({ searchResult: result.data });
    } else {
      this.setState({ searchResult: [] });
    }
  }

  renderBottomButtons() {
    const { selectedTags } = this.state;
    return (
      <View style={styles.bottomContainer}>
        <TouchableHighlightWithContainer
          containerStyle={styles.buttonContainerStyle}
          onPress={this.onPressConfirm}
        >
          { selectedTags.length > 0 ? (
            <Text style={[styles.bottomButtonText, styles.bottomButtonTextActive]}>확인</Text>
          ) : (
            <Text style={styles.bottomButtonText}>확인</Text>
          )}
        </TouchableHighlightWithContainer>
      </View>
    );
  }

  renderBody() {
    const { inputValue, searchResult, selectedTags } = this.state;

    return (
      <View style={styles.container}>
        <View style={styles.inputTagContainer}>
          <View style={styles.inputContainer}>
            <TextInput
              ref={(ref) => {this.inputRef = ref;}}
              style={styles.inputText}
              autoFocus
              enablesReturnKeyAutomatically
              keyboardType='default'
              placeholder="태그를 입력해주세요"
              placeholderTextColor={Colors.defaultGray02}
              returnKeyType='done'
              onSubmitEditing={this.onSubmitEditing}
              onChangeText={text => this.onChangeText(text)}
              value={inputValue}
            />
          </View>
        </View>
        { selectedTags && selectedTags.length > 0 && (
          <View style={styles.selectedTagContainer}>
            {selectedTags.map((tag, index) => {
              return this.renderSelectedTagItem({item: tag, index});
            })}
          </View>
        )}
        <FlatList
          data={searchResult}
          renderItem={this.renderSearchResultItem}
          removeClippedSubviews={false}
        />
        {this.renderBottomButtons()}
      </View>
    );
  }
}


export default connect(
  null,
  actions,
)(StyleUploadAddTagPage);
