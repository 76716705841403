import React from 'react';
import {
  Dimensions, StyleSheet,
  View, ScrollView, Text,
} from 'react-native';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { actions } from 'shared';

import {
  initAlert, showAlert, renderAlert,
} from '../../../components/AlertDialog';

import { Constants, Styles, Colors } from '../../../constants';

import NaverPayTextType from '../../../icons/NaverPayTextType';
import NaverPaySymbolType from '../../../icons/NaverPaySymbolType';
import {
  Button,
  TouchableHighlightWithContainer,
} from '../../../components-v2';

import Header from './Header';
import BlankPage from './BlankPage';
import ItemList from './ItemList';
import PaymentSummary from './PaymentSummary';

import { checkoutItems } from '../../checkout/CheckoutPage';
import { navigateToCheckoutNaverPayGateway } from '../../../containers/shop/CheckoutNaverPayGatewayPage';
import { navigateToCheckoutNaverPayCartWithoutAuthGateway } from '../../../containers/shop/CheckoutNaverPayCartWithoutAuthGatewayPage';


const {
  height: windowHeight,
} = Dimensions.get('window');


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  checkoutCheckedItemButton: {
    borderWidth: 0,
    backgroundColor: '#000',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 20,
    paddingBottom: 21,
  },
  checkoutCheckedItemText: {
    fontSize: 16,
    color: '#fff',
    lineHeight: 19.2,
    height: 19,
    ...Styles.pretendard600,
  },
  naverPayGuideContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  naverPayMainContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    flex:1
  },
  naverPayContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 50 + Constants.bottomMargin,
    paddingStart: Constants.viewHorizontalPadding,
    paddingEnd: Constants.viewHorizontalPadding + 1,
  },
  descriptionText: {
    flex:1,
    fontSize: 14,
    color: '#E94949',
    lineHeight: 19.2,
    height: 19,
    marginStart:Constants.viewHorizontalPadding,
    marginBottom:Constants.viewHorizontalPadding + 1,
    ...Styles.pretendard600,
  },
  naverPayGuideText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 9,
    lineHeight: 12,
    marginTop: 1,
    marginStart: 3,
  },
  naverPayButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'rgb(0, 204, 8)',
    paddingVertical: 10,
    paddingHorizontal: 25.5,
  },
  naverPayUnabledButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'rgb(120, 120, 120)',
    paddingVertical: 10,
    paddingHorizontal: 25.5,
  },
  naverPayButtonText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 12,
    lineHeight: 16,
    color: '#ffffff',
    marginStart: 3,
  },
});


export class CartPage extends React.Component {

  state = {
    checkedItems: [],
    isNaverPay : true
  }

  constructor(props) {
    super(props);

    initAlert(this);

    this.showAlert = showAlert(this);
  }

  loadData = async () => {
    const errorMessage = '장바구니의 상품을 불러오는 중 오류가 발생하였습니다.';
    this.setState({ isFetchingData: true });
    try {
      const {
        loadItemListInCart,
        loadItemListInCartWithoutAuth,
        auth
      } = this.props;

      let ret;
      if (!auth || !auth.token){
        ret = await loadItemListInCartWithoutAuth();
      }else{
        ret = await loadItemListInCart();
      }

      // console.log('loadData ret', ret);

      if (!ret.success) {
        this.showAlert(`${errorMessage} (E1${ret.errorCode})`);
        return;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('loadData error', error);

      if (error.errorCode) {
        this.showAlert(`${errorMessage} (E2${error.errorCode})`);
      } else {
        this.showAlert(errorMessage);
      }
    } finally {
      this.setState({ isFetchingData: false });
    }
  };

  loadTotalPrice = async checkedItems => {
    const errorMessage = '결제 금액 계산 중 오류가 발생하였습니다.';

    try {
      const { calculatePriceForItemsInCart } = this.props;
      const ret = await calculatePriceForItemsInCart(checkedItems);
      // console.log('loadData ret', ret);

      if (!ret.success) {
        this.showAlert(`${errorMessage} (E0${ret.errorCode})`);
        return;
      }
      const { totalGoodsPrice, totalDeliveryCharge, totalMemberDcPrice, totalSettlePrice } = ret.data;

      this.setState({
        totalGoodsPrice,
        totalDeliveryCharge,
        totalMemberDcPrice,
        totalSettlePrice,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('loadData error', error);

      if (error.errorCode) {
        this.showAlert(`${errorMessage} (E1${error.errorCode})`);
      } else {
        this.showAlert(errorMessage);
      }
    }
  };

  loadTotalPriceWithoutAuth = async checkedItems => {
    const errorMessage = '결제 금액 계산 중 오류가 발생하였습니다.';

    try {
      const { calculatePriceForItemsInCartWithoutAuth } = this.props;
      const ret = await calculatePriceForItemsInCartWithoutAuth(checkedItems);
      // console.log('loadData ret', ret);

      if (!ret.success) {
        this.showAlert(`${errorMessage} (E0${ret.errorCode})`);
        return;
      }
      const { totalGoodsPrice, totalDeliveryCharge, totalMemberDcPrice, totalSettlePrice } = ret.data;

      this.setState({
        totalGoodsPrice,
        totalDeliveryCharge,
        totalMemberDcPrice,
        totalSettlePrice,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('loadData error', error);

      if (error.errorCode) {
        this.showAlert(`${errorMessage} (E1${error.errorCode})`);
      } else {
        this.showAlert(errorMessage);
      }
    }
  };

  deleteItem = item => async () => {
    const { logEventClick,
      deleteItemInCart,
      deleteItemInCartWithoutAuth,
      auth } = this.props;
    const errorMessage = '삭제 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.';

    try {
      logEventClick({
        page_name: 'Cart',
        click_object_name: 'cart_del',
        click_object_key: item.goodsNo
      });
      let ret;

      if (!auth || !auth.token){
        ret = await deleteItemInCartWithoutAuth(item.sno);
      }else{
        ret = await deleteItemInCart(item.sno);
      }

      if (!ret.success) {
        this.showAlert(`${errorMessage} (E0${ret.errorCode})`);
        return;
      }

      this.showAlert('삭제되었습니다.');
      this.loadData();
    } catch (error) {
      // console.log('onPressDeleteItem error', error);

      if (error.errorCode) {
        this.showAlert(
          `${errorMessage} (E1${error.errorCode})`,
        );
      } else {
        this.showAlert(errorMessage);
      }
    }
  };

  deleteCheckedItem = async () => {
    const { itemListInCart,
      logEventClick,
      deleteItemInCart,
      deleteItemInCartWithoutAuth,
      auth } = this.props;
    const { checkedItems } = this.state;
    const errorMessage = '삭제 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.';

    try {
      for (let i = 0; i < checkedItems.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        logEventClick({
          page_name: 'Cart',
          click_object_name: 'cart_del',
          click_object_key: itemListInCart.filter(c => c.sno == checkedItems[i])[0].goodsNo
        });

        let ret;

        if (!auth || !auth.token){
          ret = await deleteItemInCartWithoutAuth(checkedItems[i]);
        }else{
          ret = await deleteItemInCart(checkedItems[i]);
        }
        // console.log('deleteCheckedItem deleteItemInCart return', ret);

        if (!ret.success) {
          this.showAlert(`${errorMessage} (E0${ret.errorCode})`);
          return;
        }
      }

      this.showAlert('삭제되었습니다.');
      this.loadData();
    } catch (error) {
      // console.log('deleteCheckedItem error', error);

      if (error.errorCode) {
        this.showAlert(
          `${errorMessage} (E1${error.errorCode})`,
        );
      } else {
        this.showAlert(errorMessage);
      }
    }
  };

  onPressCheckoutItem = item => () => {
    // console.log('onPressCheckoutItem item=', item);
    const { auth, navigation } = this.props;
    checkoutItems([item.sno], auth, navigation);
  }


  onPressCheckItem = item => () => {

    const {
      auth
    } = this.props;

    console.log(`onPressCheckItem item=${JSON.stringify(item)}`);

    this.setState(prevState => {
      const { checkedItems: prevCheckedItems } = prevState;
      const checkedItems = [...prevCheckedItems];
      const itemId = item.sno;
      const index = checkedItems.indexOf(itemId);
      if (index >= 0) {
        checkedItems.splice(index, 1);
      } else {
        checkedItems.push(itemId);
      }

      if (!auth || !auth.token){
        this.loadTotalPriceWithoutAuth(checkedItems);
      }else{
        this.loadTotalPrice(checkedItems);
      }

      return {
        checkedItems,
      };
    });
  };

  onPressDeleteItem = item => () => {
    this.showAlert('선택한 상품을 삭제하시겠습니까?', {
      buttons: [
        {
          text: '삭제',
          onPress: this.deleteItem(item),
        },
        {
          text: '취소',
          onPress: () => {},
        },
      ],
    });
  }

  onPressCheckAllItem = () => {

    const {
      auth
    } = this.props;

    this.setState(prevState => {
      const { checkedItems: prevCheckedItems } = prevState;
      const { itemListInCart } = this.props;
      const isCheckedAll = prevCheckedItems.length === itemListInCart.length;
      const newCheckedItems = isCheckedAll ? [] : itemListInCart.map(item => item.sno);

      if (!auth || !auth.token){
        this.loadTotalPriceWithoutAuth(newCheckedItems);
      }else{
        this.loadTotalPrice(newCheckedItems);
      }

      return {
        checkedItems: newCheckedItems,
      };
    });
  };

  onPressDeleteCheckedItems = () => {
    const { checkedItems } = this.state;

    if (checkedItems.length === 0) {
      this.showAlert('선택한 상품이 없습니다. 삭제할 상품을 선택해주세요.');
      return;
    }

    this.showAlert('선택한 상품을 삭제하시겠습니까?', {
      buttons: [
        {
          text: '삭제',
          onPress: this.deleteCheckedItem,
        },
        {
          text: '취소',
          onPress: () => {},
        },
      ],
    });
  };

  onPressCheckoutCheckedItems = () => {
    const { checkedItems } = this.state;
    const { auth, navigation } = this.props;

    if (checkedItems.length === 0) {
      this.showAlert('주문할 상품을 선택해주세요.');
      return;
    }

    checkoutItems(checkedItems, auth);
  };

  componentDidMount() {
    const { auth, logEventPageview } = this.props;

    /*if (!auth || !auth.token) {
      this.showAlert('로그인해주시기 바랍니다.', {
        buttons: [
          {
            text: '확인',
            onPress: () => {
              browserHistory.replace('/accounts/login');
            },
          },
        ]
      });
      return;
    }*/

    this.loadData();

    const { itemListInCart } = this.props;
    if (itemListInCart.length > 0) {
      this.onPressCheckAllItem();
    }

    logEventPageview({ page_name: 'Cart' });
  }

  componentDidUpdate(prevProps) {
    const { itemListInCart: prevItemListInCart } = prevProps;
    const { itemListInCart: curItemListInCart, auth } = this.props;

    const prevItemIndexList = prevItemListInCart.map(item => item.sno);
    const curItemIndexList = curItemListInCart.map(item => item.sno);

    const { checkedItems } = this.state;
    const newCheckedItems = checkedItems.filter(index => curItemIndexList.indexOf(index) >= 0);

    curItemIndexList.forEach(index => {
      if (prevItemIndexList.indexOf(index) < 0) {
        newCheckedItems.push(index);
      }
    });

    let needUpdate = (checkedItems.length !== newCheckedItems.length);

    if (!needUpdate) {
      for (let i = 0; i < newCheckedItems.length; i++) {
        if (checkedItems.indexOf(newCheckedItems[i]) < 0) {
          needUpdate = true;
          break;
        }
      }
    }

    if (prevItemListInCart !== curItemListInCart) {
      needUpdate = true;
    }

    if (needUpdate) {

      if (!auth || !auth.token){
        this.loadTotalPriceWithoutAuth(newCheckedItems);
      }else{
        this.loadTotalPrice(newCheckedItems);
      }


      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ checkedItems: newCheckedItems });
    }
  }

  renderSummary() {
    const {
      totalGoodsPrice,
      totalDeliveryCharge,
      totalMemberDcPrice,
      totalSettlePrice,
    } = this.state;

    return (
      <PaymentSummary
        totalGoodsPrice={totalGoodsPrice}
        totalDeliveryCharge={totalDeliveryCharge}
        totalMemberDcPrice={totalMemberDcPrice}
        totalSettlePrice={totalSettlePrice}
        />
    );
  }

  onPressNaverPay = () => {
    const { checkedItems } = this.state;
    const { itemListInCart, auth, navigation } = this.props;

    if (checkedItems.length === 0) {
      alert('주문할 상품을 선택해주세요.');
      return;
    }

    let isPromotionIncluded = false;
    for (let idx = 0; idx < checkedItems.length; idx++) {
      const item = itemListInCart.find(item => item.sno === checkedItems[idx]);
      if (!!!item) {
        continue;
      }

      if (item.crawled === true) {
        alert('파트너사 배송 상품은 네이버페이로 결제할 수 없습니다.');
        return;
      }

      if (item.preOrder === 'y') {
        alert('프리오더 상품은 네이버페이로 결제할 수 없습니다.');
        return;
      }
      if (item.cateNm.toUpperCase() === 'LIVING') {
        alert('오더메이드 제품은 네이버페이로 결제할 수 없습니다.');
        return;
      }

      /*if (item.cateAllCd.map(el => el.cateCd).includes("018")) {
        alert('해당 프로모션은 네이버페이로 결제할 수 없습니다.');
        return;
      }*/
    }
    let newWindow;
    if (!auth || !auth.token){
      newWindow = window.open('/shop/checkoutNaverPayNoAuthCart/', '_blank');
      navigateToCheckoutNaverPayCartWithoutAuthGateway({
        orderItems: checkedItems,
        holdPage: true,
        newWindow
      });
    }else{
      newWindow = window.open('/shop/checkoutNaverPay/', '_blank');
      navigateToCheckoutNaverPayGateway({
        orderItems: checkedItems,
        holdPage: true,
        newWindow
      });
    }

  }

  renderNaverPay() {

    const { checkedItems } = this.state;
    const { itemListInCart, auth, navigation } = this.props;

    let isPromotionIncluded = false;
    for (let idx = 0; idx < checkedItems.length; idx++) {
      const item = itemListInCart.find(item => item.sno === checkedItems[idx]);
      if (!!!item) {
        continue;
      }

      if (item.cateAllCd.map(el => el.cateCd).includes("018")) {
        isPromotionIncluded = true;
      }
    }

    return (
      <View style={styles.naverPayMainContainer}>
        {isPromotionIncluded && <Text style={styles.descriptionText}>
          * 네이버 페이 결제시 프로모션이 적용되지 않습니다
        </Text>}
        <View style={styles.naverPayContainer}>
          <View style={styles.naverPayGuideContainer}>
            <NaverPayTextType />
            <Text style={styles.naverPayGuideText}>네이버 ID로 간편구매</Text>
          </View>
          <TouchableHighlightWithContainer
            onPress={this.onPressNaverPay}
          >
            <View style={this.state.isNaverPay ? styles.naverPayButton : styles.naverPayUnabledButton}>
              <NaverPaySymbolType />
              <Text style={styles.naverPayButtonText}>Pay 구매</Text>
            </View>
          </TouchableHighlightWithContainer>
        </View>
      </View>
    );
  }

  renderBody() {
    const {
      itemListInCart, showSmartBanner,
      setModalProps, modalProps,
    } = this.props;
    const { isFetchingData, checkedItems } = this.state;

    const scrollViewStyls = [
      styles.scrollVie,
      {
        height: windowHeight
          - (showSmartBanner && Constants.smartBannerHeight)
          - Constants.mainHeaderHeight
          - 44 - 10.5
          - Constants.bottomTabBarHeight,
      },
    ];

    return (
      <View style={styles.container}>
        <ScrollView
          style={scrollViewStyls}
          {...Constants.scrollViewCommonProps}
        >
          <ItemList
            checkedItems={checkedItems}
            itemListInCart={itemListInCart}
            isFetchingData={isFetchingData}
            loadData={this.loadData}
            onPressCheckItem={this.onPressCheckItem}
            onPressDeleteItem={this.onPressDeleteItem}
            onPressCheckoutItem={this.onPressCheckoutItem}
            setModalProps={setModalProps}
            modalProps={modalProps}
            />
          {this.renderSummary()}
          {this.renderNaverPay()}
        </ScrollView>

        <View style={styles.footer}>
          <Button
            containerStyle={styles.checkoutCheckedItemButton}
            onPress={this.onPressCheckoutCheckedItems}
            underlayColor={Colors.touchEffectColorBlack}
            title={`${checkedItems.length}개 상품 주문하기`}
            titleStyle={styles.checkoutCheckedItemText}
            disabled={checkedItems.length == 0}
          />
        </View>
      </View>
    );

    return null;
  }

  render() {
    const { isFetchingData, checkedItems } = this.state;
    const { itemListInCart, router } = this.props;

    const isCheckedAll = (itemListInCart.length > 0) && (checkedItems.length === itemListInCart.length);
    const isEmpty = !isFetchingData && (!itemListInCart || itemListInCart.length === 0);

    return (
      <View style={styles.container}>
        <Header
          isCheckedAll={isCheckedAll}
          isEmpty={isEmpty}
          itemListInCart={itemListInCart}
          onPressCheckAllItem={this.onPressCheckAllItem}
          onPressDeleteCheckedItems={this.onPressDeleteCheckedItems}
          />

        {isEmpty ? (
          <BlankPage router={router} />
        ) : this.renderBody()}
        {renderAlert(this)}
      </View>
    );
  }
}


export default connect(
  state => ({
    auth: state.auth,
    itemListInCart: state.entities.shop.cart.items,
  }),
  actions,
)(CartPage);
