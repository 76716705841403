import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, Text } from 'react-native';

import Styles from '../constants/Styles';

const styles = StyleSheet.create({
  labelText: {
    backgroundColor: 'black',
    color: 'white',
    overflow: 'hidden',
    paddingTop: 2,
    paddingBottom: 1,
    paddingHorizontal: 4,
    lineHeight: 12,
    borderRadius: 1,
    height: 15,
    ...Styles.SFUITextSemiBold,
    fontSize: 10,
  },
});


function ProductLabel(props) {
  const { children, style, backgroundColor } = props;
  return (
    <Text style={[styles.labelText, style, { backgroundColor }]}>
      {children}
    </Text>
  );
}

export default ProductLabel;
