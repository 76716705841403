import { normalize } from 'normalizr';
import get from 'lodash/get';
import set from 'lodash/set';
import setWith from 'lodash/setWith';
import qs from 'qs';

import * as schemas from '../schemas';
import createFetchAction from "../util/createFetchAction";


function getNextPageUrl(response) {
  const link = response.headers.get('link');
  return link;
}

export function loadRecommendedHeartistsSortByRecentActivity() {
  const key = 'recommendedHeartistsSortByRecentActivity';

  return createFetchAction({
    type: 'LOAD_RECOMMENDED_HEARTISTS_RECENT_ACTIVITY',
    endpoint: '/api/v2/users/heartists/recent',
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: 'REFRESH' },
      meta: { entities: { update: true } },
    },
    transform: ({ json }) => {
      return normalize(json, schemas.heartists);
    },
  });
}

export function loadMostSearchedKeywords() {
  return createFetchAction({
    type: 'LOAD_MOST_SEARCHED_KEYWORDS',
    endpoint: '/api/v2/search/keyword/recommended',
    transform: ({ json }) => {
      if(json.hasOwnProperty('keywordData')) {
        return set({}, 'entities.search.mostSearchedKeywords', json.keywordData);
      } else {
        console.error(json);
        return {};
      }
    },
  });
}

export function loadShopSearchKeywordRecommendation(itemIndexList) {
  return createFetchAction({
    type: 'LOAD_SHOP_SEARCH_KEYWORD_RECOMMENDATION',
    method: 'GET',
    endpoint: '/api/v2/shop/product/search/keywordsV2',
    doDispatch: false,
  });
}

export function getProductListByKeyword(query, nextPage) {
  const params = query ? `?${qs.stringify(query).replace('%20', '+')}` : '';

  const key = 'productSearchResult';
  return createFetchAction({
    type: 'GET_PRODUCT_LIST_KEYWORD',
    method: 'GET',
    endpoint: state => {
      const base = '/api/v2/shop/product/search' + params;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
      meta: { entities: { update: true } },
    },
    transform: ({ json, state, response }) => {
      if (json.success !== true) {
        // TODO: 에러 처리 필요
        console.error("GET_PRODUCT_LIST_KEYWORD FAILED", json);
        return {};
      }

      const { data : { goodsData, page: { totalCount } }} = json;
      let result = Object.assign(
        normalize(goodsData, schemas.shopProducts),
        {
          nextPageUrl: getNextPageUrl(response),
          countResult: totalCount,
        }
      );

      return result;
    },
  });
}

export function getHeartistSearchResult(query, nextPage) {
  const key = 'heartistSearchResult';

  const params = query ? `?${qs.stringify(query).replace('%20', '+')}` : '';
  const base = '/api/v2/search/heartist' + params;

  return createFetchAction({
    type: 'GET_HEARTISTS_SEARCH_RESULT',
    method: 'GET',
    endpoint: state => {
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
      meta: { entities: { update: true } },
    },
    transform: ({ json, state, response }) => {
      if (!json.hasOwnProperty('searchResult')) {
        console.error('GET_HEARTISTS_SEARCH_RESULT FAILED', json);
        return {};
      }

      const { hasNext, searchResult, countResult } = json;

      let result = {
        nextPageUrl: null,
        countResult: countResult,
      };
      Object.assign(result, normalize(searchResult, schemas.heartists));

      if (hasNext && searchResult.length > 0) {
        result.nextPageUrl = base + '&after=' + searchResult[searchResult.length - 1]._id;
      }

      return result;
    },
  });
}

export function getTagSearchResult(query) {
  const params = query ? `?${qs.stringify(query).replace('%20', '+')}` : '';
  return createFetchAction({
    type: 'GET_TAG_SEARCH_RESULT',
    endpoint: '/api/v2/tags/search' + params,
    doDispatch: false,
  });
}
