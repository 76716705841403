import React from 'react';

import {
  View, StyleSheet, Text, FlatList, TouchableOpacity, Dimensions,
} from 'react-native';

import { TouchableHighlightWithContainer } from '../../../components-v2';
import { Constants, Styles } from '../../../constants';

const { height: windowHeight } = Dimensions.get('window');


const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  background: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    start: 0,
    end: 0,
  },
  listContainer: {
    flexDirection: 'row',
    maxHeight: windowHeight * 0.7,
    paddingBottom: 10,
    backgroundColor: '#ffffff',
  },
  categoryListContainer: {
    flex: 1,
    maxWidth: 122,
  },
  subCategoryListContainer: {
    flex: 1,
    position: 'absolute',
    top: 0,
    start: 122,
    bottom: 0,
    end: 0,
  },
  bottomShadow: {
    position: 'absolute',
    bottom: 0,
    start: 0,
    end: 0,
    height: 100,
  },
  itemContainer: {
    backgroundColor: '#fff',
    paddingStart: Constants.viewHorizontalPadding,
    paddingVertical: 12,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  itemTextContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#ffffff',
    paddingBottom: 2,
    flexShrink:1
  },
  itemTextContainerSelected: {
    borderBottomColor: '#000000',
  },
  itemText: {
    ...Styles.appleSDGothicNeoMedium,
    fontSize: 15,
    lineHeight: 18,
    ...Styles.pretendard400,
  },
  itemTextSelected: {
    ...Styles.pretendard700,
  }
});

class CategoryDropdown extends React.PureComponent {

  renderSubCategoryItem(item) {
    const {
      selectedSubCategoryList,
      onSelected,
      selectedCategoryItem,
    } = this.props;

    const itemTextContainerStyles = [styles.itemTextContainer];
    const itemTextStyles = [styles.itemText];

    let isSelected = false;
    if (!selectedSubCategoryList || selectedSubCategoryList.length === 0) {
      if (item.cateNm === "전체") {
        isSelected = true;
      }
    } else {
      selectedSubCategoryList.forEach(subCategoryItem => {
        if (item.cateCd === subCategoryItem.cateCd) {
          isSelected = true;
        }
      });
    }


    if (isSelected) {
      itemTextContainerStyles.push(styles.itemTextContainerSelected);
      itemTextStyles.push(styles.itemTextSelected);
    }

    const onPress = (Object.keys(item).length === 0) ? null : () => onSelected(selectedCategoryItem, item);
    const name = (Object.keys(item).length === 0) ? '' : item.cateNm;

    return (
      <TouchableHighlightWithContainer
        onPress={onPress}
        >
        <View style={styles.itemContainer}>
          <View style={itemTextContainerStyles}>
            <Text style={itemTextStyles}>{name}</Text>
          </View>
        </View>
      </TouchableHighlightWithContainer>
    )
  }

  renderSubCategory() {
    const {
      selectedCategoryItem,
    } = this.props;

    if (selectedCategoryItem.cateNm === "전체" || !selectedCategoryItem.children) {
      return null;
    }

    const children = selectedCategoryItem.children ? selectedCategoryItem.children : [];
    const data = [
      ...children,
      {},
    ];

    return (
      <View
        style={styles.subCategoryListContainer}
        >
        <FlatList
          ref={(ref) => { this.subCategoryListRef = ref; }}
          keyExtractor={(item, index) => `${index}`}
          data={data}
          renderItem={({ item }) => this.renderSubCategoryItem(item)}
          bounces={false}
          />
        <View
          style={styles.bottomShadow}
          pointerEvents={'none'}>
          <div style={{ background: "linear-gradient(transparent, white)", height: '100%'}}></div>
        </View>
      </View>
    )
  }

  renderItem(item) {
    const {
      selectedCategoryItem,
      onSelected,
    } = this.props;

    const itemTextContainerStyles = [styles.itemTextContainer];
    const itemTextStyles = [styles.itemText];

    if (item.cateCd === selectedCategoryItem.cateCd) {
      itemTextContainerStyles.push(styles.itemTextContainerSelected);
      itemTextStyles.push(styles.itemTextSelected);
    }

    const onPress = () => {
      const subCategory = item.children && item.children.length > 0 ? item.children[0] : null;
      onSelected(item, subCategory);
      try {
        this.subCategoryListRef && this.subCategoryListRef.scrollToIndex({
          index: 0,
        });
      } catch (e) {
        // TODO: 카테고리 설정이 완료되기 전까지는 오류가 발생할 수 있음
      }
    }

    return (
      <TouchableHighlightWithContainer
        onPress={onPress}
        >
        <View style={styles.itemContainer}>
          <View style={itemTextContainerStyles}>
            <Text style={itemTextStyles}>{item.cateNm}</Text>
          </View>
        </View>
      </TouchableHighlightWithContainer>
    );
  }

  render() {
    const {
      containerStyle,
      categories,
      onPressClose,
      getGlobalLayoutInfo,
    } = this.props;

    return (
      <View
        style={[styles.container, containerStyle]}
        >
          <TouchableOpacity
            style={styles.background}
            onPress={onPressClose}
          >
            <View />
          </TouchableOpacity>
          <View style={styles.listContainer}>
            <FlatList
              keyExtractor={(item, index) => `${index}`}
              data={categories}
              renderItem={({ item }) => this.renderItem(item)}
              style={styles.categoryListContainer}
              bounces={false}
              />
            {this.renderSubCategory()}
          </View>
      </View>
    );
  }
}

export default CategoryDropdown;
