import React from 'react'
import PropTypes from 'prop-types';
import { StyleSheet, View, Text } from 'react-native';

import { Constants } from '../constants';
import { SquareCheckBoxIcon } from '../icons-v2';

import CloudinaryImage from './CloudinaryImage';
import TouchableHighlightWithContainer from './TouchableHighlightWithContainer';


const styles = StyleSheet.create({
  button: {
    backgroundColor: 'white',
    paddingStart: Constants.viewHorizontalPadding,
    paddingEnd: Constants.viewHorizontalPadding + 8,
  },
  itemContentContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 24.5,
    marginBottom: 24.5,
  },
  itemImage: {
    width:30,
    height:30,
    objectFit:'contain'
  },
  itemImageContainer: {
    width:30,
    height:30,
    marginEnd:12,
    transform:'translateY(-25%)'
  },
  itemCheckBox: {
    marginTop: 2.5,
    marginEnd: 16,
  },
  itemText: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
  },
  itemTextChecked: {
    fontWeight: 400,
  },
});


class CheckBoxListItem extends React.Component {

  renderItemName(itemName, checked) {
    const { ItemNameComponent } = this.props;

    const component = ItemNameComponent(itemName, checked);
    if (component) {
      return component;
    }

    const { ItemNameStyle } = this.props;

    const itemTextStyles = [styles.itemText, ItemNameStyle];
    if (checked) {
      itemTextStyles.push(styles.itemTextChecked);
    }

    return (
      <Text style={itemTextStyles}>{itemName}</Text>
    );
  }

  renderItemIcon(){
    const { item } = this.props;

    if ( item.image && item.image.length > 0 ){
      return (
        <View
          style={[styles.itemImageContainer]}>
          <CloudinaryImage
            style={styles.itemImage}
            containerStyles={styles.itemImage}
            options={{
              crop: 'limit',
              height: 30,
              width: 30,
            }}
            publicId={item.image}/>
        </View>
      )
    }else{
      return (
        <View
          style={[styles.itemImageContainer]}/>
      )
    }
  }

  render() {
    const {
      name, checked,
      style, containerStyle,
      onPress: onPressOld,
      ItemCheckBoxStyle,
      disabled,
      isIcon
    } = this.props;

    const buttonStyles = [styles.button, style];
    const containerStyles = [styles.itemContentContainer, containerStyle];

    let onPress = null;
    if (!disabled) {
      onPress = onPressOld;
    }

    return (
      <TouchableHighlightWithContainer
        style={buttonStyles}
        onPress={onPress}
        >
        <View style={containerStyles}>
          <View style={[styles.itemCheckBox, ItemCheckBoxStyle]}>
            <SquareCheckBoxIcon
              checked={checked}
              />
          </View>
          {isIcon && this.renderItemIcon()}
          {this.renderItemName(name, checked)}
        </View>
      </TouchableHighlightWithContainer>
    );
  }
}


CheckBoxListItem.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onPress: PropTypes.func,
  ItemNameComponent: PropTypes.func,
};

CheckBoxListItem.defaultProps = {
  name: '',
  checked: false,
  onPress: () => null,
  ItemNameComponent: (itemName, checked) => null,
};


export default CheckBoxListItem;
