import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloudinary } from '../cloudinary';

export default class FacebookProfile extends Component {
  render() {
    const { facebookId, options, style } = this.props;
    const optionsWithDefault = Object.assign({}, options, {
      type: 'facebook',
      default_image: 'default-profile.jpg',
    });
    return (
      <img src={cloudinary.url(facebookId, optionsWithDefault)} style={style} alt="" />
    );
  }
}

FacebookProfile.defaultProps = {
  options: {},
};

FacebookProfile.propTypes = {
  facebookId: PropTypes.string.isRequired,
  options: PropTypes.object,
  style: PropTypes.object,
};
