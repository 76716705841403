import React from 'react';
import { View, Image, Text, FlatList, StyleSheet, Dimensions } from 'react-native';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { actions } from 'shared';

import { Constants, Styles } from '../../../constants';

import { uploadStylePostPhoto } from '../../../utils/uploadFile';
import { CrossIcon, FeedUploadDeleteIcon } from '../../../icons-v2';
import { TouchableHighlightWithContainer } from '../../../components-v2';
import Button from '../../../components-v2/Button';
import Product from '../../../components-v2/Product';
import StyleUploadButton from '../../../components-v2/StyleUploadButton';
import StyleUploadAddProductPage from './StyleUploadAddProductPage';
import StyleUploadAddTagPage from './StyleUploadAddTagPage';
import { initAlert, showAlert, renderAlert } from '../../../components/AlertDialog';
import CloudinaryImage from '../../../components-v2/CloudinaryImage';
import CloudinaryVideo from '../../../nativeComponents/CloudinaryVideo';


const {
  width: windowWidth,
} = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 22,
  },
  rowContainer: {
  },
  rowTitleContainer: {
    marginHorizontal: Constants.viewHorizontalPadding,
    marginBottom: 13,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rowTitleText: {
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard600,
  },
  rowTitleButtonText: {
    fontSize: 14,
    lineHeight: 17,
    ...Styles.pretendard400,
  },
  rowImageItemContainer: {
  },
  rowProductItemContainer: {
  },
  deleteButtonContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 999,
  },
  tagContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: Constants.viewHorizontalPadding,
  },
  tagButtonDefaultContainer: {
    borderWidth: 0.5,
    borderColor: '#cfcfcf',
    paddingHorizontal: 15,
    paddingVertical: 6,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tagButtonDefaultTitle: {
    color: '#0000007F',
    fontSize: 15,
    lineHeight: 18,
    justifyContent: 'center',
    ...Styles.pretendard400,
  },
  tagButtonActiveContainer: {
    borderColor: '#000',
    marginEnd: 10,
    marginBottom: 10,
  },
  tagButtonActiveTitle: {
    color: '#000',
    ...Styles.pretendard600,
  },
  productContainer: {
  },
  productImageContainer: {
    aspectRatio: 112/149,
    width: 112,
    borderWidth: 0.5,
    borderColor: '#cfcfcf',
  },
  mediaContainer: {
    width: 112,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  mediaStyle: {
    width: '100%',
    resizeMode: 'contain',
  },
  bottomContainer: {
    position: 'absolute',
    start: 0,
    end: 0,
    bottom: 0,
    backgroundColor: '#000',
    flexDirection: 'row',
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  buttonContainerStyle: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomButtonText: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    textAlign: 'center',
    color: '#ffffff',
    marginTop: 20,
    marginBottom: 21,
    height: 19,
    width: "100%",
  },
});


class StyleUploadPage extends React.Component {
  state = {
    'products': [],
    'tags': [],
    'images': [],
    'uploadedImages':[],
    'deleteImages':[],
    saving: false,
  }

  constructor(props) {
    super(props);
    initAlert(this);
    this.showAlert = showAlert(this);
  }

  componentDidMount() {
    const {
      auth,
      post,
      stylePostId,
      logEventPageview,
    } = this.props;

    if (!auth || !auth.token) {
      this.showAlert('로그인해주시기 바랍니다.', {
        buttons: [
          {
            text: '확인',
            onPress: () => {
              browserHistory.replace('/accounts/login');
            },
          },
        ]
      });
      return;
    }

    if (post) {
      const { products = [], media = [], tags = [] } = post;

      this.setState({
        images: [ ...media ],
        uploadedImages: [ ...media ],
        products: [ ...products ],
        tags: [ ...tags.map(x => x.title) ]
      });
    } else if (stylePostId) {
      this.loadData();
    }

    logEventPageview({ page_name: 'StyleUpload', page_key: stylePostId });
  }

  loadData = () => {
    const { auth, stylePostId = null, post = null, loadStylePost } = this.props;
    loadStylePost(stylePostId)
      .then((post) => {
        if (auth._id !== post._id) {
          this.showAlert('로그인해주시기 바랍니다.', {
            buttons: [
              {
                text: '확인',
                onPress: () => {
                  browserHistory.replace('/accounts/login');
                },
              },
            ]
          });
          return;
        } else {
          const { products = [], media = [], tags = [] } = post;

          this.setState({
            images: [ ...media ],
            uploadedImages: [ ...media ],
            products: [ ...products ],
            tags: [ ...tags.map(x => x.title) ]
          });
        }
      });
  }

  isUploadedImage = (image) => {
    return image.hasOwnProperty('publicId');
  }

  isEditPost = () => {
    const { post } = this.props;
    return !!post;
  }

  deleteUploadedImage = async () => {
    // FIXME
    const { deleteImages } = this.state;
  }

  onPressDeleteImage = (image, index) => {
    const isUploaded = this.isUploadedImage(image);
    if (isUploaded) {
      this.setState((state) => {
        state.images.splice(index, 1);
        const uploadedIndex = state.uploadedImages.findIndex(x => x.publicId === image.publicId);
        state.uploadedImages.splice(uploadedIndex, 1);
        state.deleteImages.push(image);
        return {
          ...state
        };
      });
    } else {
      this.setState((state) => {
        state.images.splice(index, 1);
        return {
          ...state
        };
      });
    }
  }

  onPressUpload = async () => {
    const { router, auth, createStylePost, updateStylePost } = this.props;
    const { images, products, tags, saving } = this.state;
    if (saving) {
      return;
    }

    if (images.length === 0) {
      alert('피드 이미지를 추가해주세요.');
      return;
    }
    if (products.length === 0) {
      alert('관련 상품을 추가해주세요.');
      return;
    }
    if (tags.length === 0) {
      alert('태그를 추가해주세요.');
      return;
    }

    this.setState({ saving: true });

    try {
      await images.filter(x => !this.isUploadedImage(x)).reduce(async (promise, image) => {
        await promise;
        const ret = await uploadStylePostPhoto(auth.token, image.file);
        this.setState(({ uploadedImages }) => ({
          uploadedImages: uploadedImages.concat(ret.data),
        }));
      }, Promise.resolve());
    } catch(error) {
      console.log(error);
      alert('이미지 업로드 중 오류가 발생했습니다.\n다시 시도해주세요.');
      this.setState({ saving: false });
      return;
    }

    const post = {
      media: this.state.uploadedImages,
      tags,
      products,
    };
    try {
      if (this.isEditPost()) {
        await this.deleteUploadedImage();
        await updateStylePost(this.props.post._id, post);
      } else {
        await createStylePost(post);
      }
      this.setState({ saving: false }, () => router.goBack());
    } catch(error) {
      console.log(error);
      alert('피드 업로드 중 오류가 발생했습니다.\n다시 시도해주세요.');
      this.setState({ saving: false });
    }
  }

  selectProductCallback = (selectedProducts) => {
    const { setModalProps } = this.props;

    if (!Array.isArray(selectedProducts)) {
      selectedProducts = [selectedProducts];
    }
    const newProducts = this.state.products.concat(selectedProducts.filter(x => !this.state.products.includes(x)));
    this.setState({
      'products': [...newProducts]
    });

    setModalProps({
      isVisible: false,
      children: null,
    });
  }

  selectTagCallback = (selectedTags) => {
    console.log('selecteTagCallback', selectedTags);
    const newTags = this.state.tags.concat(selectedTags.filter(x => !this.state.tags.includes(x)));
    this.setState({
      'tags': [...newTags]
    });
  }

  openAddImageDialog() {
    const { images } = this.state;
    const input = document.createElement('input');
    input.type = 'file';
    document.body.appendChild(input);
    input.onchange = async (evt) => {
      const file = evt.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e) => {
        const image = {
          file,
          fileName: file.name,
          uri: '',
          type: `${file.type}:base64`,
          data: e.target.result,
        }
        images.push(image);
        this.setState({ images });
        document.body.removeChild(input);
      };
    };
    input.click();
  }

  renderAddImage() {
    const { images } = this.state;

    return (
      <View style={styles.rowContainer}>
        <View style={styles.rowTitleContainer}>
          <Text style={styles.rowTitleText}>피드 이미지 추가</Text>
          { images.length > 0 && (
            <TouchableHighlightWithContainer
              onPress={() => this.openAddImageDialog()}
            >
              <Text style={styles.rowTitleButtonText}>이미지 첨부</Text>
            </TouchableHighlightWithContainer>
          )}
        </View>
        <FlatList
          horizontal
          keyExtractor={(item, idx) => { return `${item._id}-${idx}`}} // eslint-disable-line no-underscore-dangle
          showsHorizontalScrollIndicator={false}
          ListHeaderComponent={<View style={{ width: Constants.viewHorizontalPadding }} />}
          ListFooterComponent={<View style={{ width: Constants.viewHorizontalPadding }} />}
          data={images.concat([{ _id: 'ignoreForButton' }])}
          ItemSeparatorComponent={() => (<View style={{ width: Constants.viewHorizontalPadding }} />)}
          renderItem={({ item, index }) => {
            if (images.length == 0) {
              return (
                <View style={styles.rowImageItemContainer}>
                  <StyleUploadButton
                    type="camera"
                    onPress={() => this.openAddImageDialog()}
                  />
                </View>
              );
            } else if (images.length === index) {
              return null;
            } else {
              return (
                <View style={styles.rowImageItemContainer}>
                  <View style={styles.deleteButtonContainer}>
                    <Button
                      containerStyle={{padding: 5, borderWidth: 0, backgroundColor: '#000'}}
                      innerComponent={<FeedUploadDeleteIcon />}
                      onPress={() => this.onPressDeleteImage(item, index)}
                    />
                  </View>
                  <View style={styles.mediaContainer}>
                    {(this.isUploadedImage(item) && item.resourceType === 'video') && (
                      <CloudinaryVideo
                        publicId={item.publicId}
                        resizeMode="cover"
                        options={{
                          crop: 'limit',
                          format: 'jpg',
                          height: 640,
                          width: 640,
                          start_offset: 0,
                        }}
                        thumbnailOnly={true}
                        style={{width: '100%', aspectRatio: 1, objectFit: 'cover'}}
                      />
                    )}
                    {(this.isUploadedImage(item) && item.resourceType !== 'video') && (
                      <CloudinaryImage
                        publicId={item.publicId}
                        options={{
                          crop: 'limit',
                          height: 1080,
                          width: 1080,
                        }}
                        style={{width: '100%', aspectRatio: 1, objectFit: 'cover'}}
                      />
                    )}
                    {!this.isUploadedImage(item) && (
                      <Image
                        source={{ uri: item.data }}
                        style={{width: '100%', aspectRatio: 1, objectFit: 'cover'}}
                      />
                    )}
                  </View>
                </View>
              );
            }
          }}
        />
      </View>
    );
  }

  showAddProductDialog() {
    const {
      modalProps,
      setModalProps,
      loadStylePostProducts,
    } = this.props;
    const modal = (
      <StyleUploadAddProductPage
        modalProps={modalProps}
        setModalProps={setModalProps}
        callback={this.selectProductCallback.bind(this)}
        loadStylePostProducts={loadStylePostProducts}
      />
    );
    setModalProps({
      isVisible: true,
      children: modal,
    });
  }

  renderAddProduct() {
    const { products } = this.state;

    return (
      <View style={styles.rowContainer}>
        <View style={styles.rowTitleContainer}>
          <Text style={styles.rowTitleText}>관련 상품 추가</Text>
          { products.length > 0 && (
            <TouchableHighlightWithContainer
              onPress={() => this.showAddProductDialog()}
            >
              <Text style={styles.rowTitleButtonText}>상품 추가</Text>
            </TouchableHighlightWithContainer>
          )}
        </View>
        <FlatList
          horizontal
          keyExtractor={(item, idx) => { return `${item._id}-${idx}`}} // eslint-disable-line no-underscore-dangle
          showsHorizontalScrollIndicator={false}
          ListHeaderComponent={<View style={{ width: Constants.viewHorizontalPadding }} />}
          ListFooterComponent={<View style={{ width: Constants.viewHorizontalPadding }} />}
          data={products.concat([{ _id: 'ignoreForButton' }])}
          ItemSeparatorComponent={() => (<View style={{ width: Constants.viewHorizontalPadding }} />)}
          renderItem={({ item, index }) => {
            if (products.length == 0) {
              return (
                <View style={styles.rowProductItemContainer}>
                  <StyleUploadButton
                    type="product"
                    onPress={() => this.showAddProductDialog()}
                  />
                </View>
              );
            } else if (products.length === index) {
              return null;
            } else {
              return (
                <View style={styles.rowProductItemContainer}>
                  <View style={styles.deleteButtonContainer}>
                    <Button
                      containerStyle={{padding: 5, borderWidth: 0, backgroundColor: '#000'}}
                      innerComponent={<FeedUploadDeleteIcon />}
                      onPress={() => {
                        this.setState((state) => {
                          state.products.splice(index, 1);
                          return {
                            ...state
                          };
                        });
                      }}
                    />
                  </View>
                  <Product
                    product={item}
                    isShopProduct
                    isSmallVersion
                    containerStyle={styles.productContainer}
                    imageContainerStyle={styles.productImageContainer}
                  />
                </View>
              );
            }
          }}
        />
      </View>
    );
  }

  showAddTagDialog() {
    const {
      modalProps,
      setModalProps,
      loadStylePostTags,
    } = this.props;
    const modal = (
      <StyleUploadAddTagPage
        modalProps={modalProps}
        setModalProps={setModalProps}
        callback={this.selectTagCallback.bind(this)}
        loadStylePostTags={loadStylePostTags}
      />
    );
    setModalProps({
      isVisible: true,
      children: modal,
    });
  }


  renderAddTag() {
    const { tags } = this.state;

    return (
      <View style={styles.rowContainer}>
        <View style={styles.rowTitleContainer}>
          <Text style={styles.rowTitleText}>태그 추가</Text>
          { tags.length > 0 && (
            <TouchableHighlightWithContainer
              onPress={() => this.showAddTagDialog()}
            >
              <Text style={styles.rowTitleButtonText}>태그 추가</Text>
            </TouchableHighlightWithContainer>
          )}
        </View>
        <View style={styles.tagContainer}>
          { tags.length == 0 && (
             <Button
               titleStyle={styles.tagButtonDefaultTitle}
               containerStyle={styles.tagButtonDefaultContainer}
               title="태그 입력하기"
               onPress={() => this.showAddTagDialog()}
             />
          )}
          { tags.length > 0 && tags.map((item, index) => {
            return (
              <Button
                key={`tag-${index}`}
                containerStyle={[styles.tagButtonDefaultContainer, styles.tagButtonActiveContainer]}
                innerComponent={(
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={[styles.tagButtonDefaultTitle, styles.tagButtonActiveTitle]}>
                      #{item}
                    </Text>
                    <CrossIcon style={{ marginLeft: 4 }} />
                  </View>
                )}
                onPress={() => {
                  this.setState((state) => {
                    state.tags.splice(index, 1);
                    return {
                      ...state
                    };
                  });
                }}
              />
            );
          })}
        </View>
      </View>
    );
  }

  onPressCancel = () => {
    const { router } = this.props;

    const result = window.confirm('취소하시겠습니까?');
    if (result) {
      router.goBack();
    }
  }

  renderBottomButtons() {
    const { router } = this.props;
    const { saving } = this.state;
    return (
      <View style={styles.bottomContainer}>
        <TouchableHighlightWithContainer
          containerStyle={styles.buttonContainerStyle}
          onPress={this.onPressCancel}
        >
          <Text style={styles.bottomButtonText}>취소</Text>
        </TouchableHighlightWithContainer>
        <TouchableHighlightWithContainer
          containerStyle={styles.buttonContainerStyle}
          onPress={this.onPressUpload}
        >
          <Text style={styles.bottomButtonText}>{this.isEditPost() ? '수정하기': '올리기'}</Text>
        </TouchableHighlightWithContainer>
      </View>
    );
  }

  render() {
    return (
      <div>
        <View style={styles.container}>
          {this.renderAddImage()}
          <View style={{ height: 33 }} />
          {this.renderAddProduct()}
          <View style={{ height: 33 }} />
          {this.renderAddTag()}
          {renderAlert(this)}
        </View>
        {this.renderBottomButtons()}
      </div>
    );
  }
}


export default connect(
  (state, { params }) => {
    const { stylePostId = null } = params;
    let post = null;
    if (stylePostId !== null) {
      post = state.entities.stylePosts[stylePostId] || state.entities.followingStylePosts[stylePostId];
      if (post && (post.owner._id != state.auth._id)) {
        post = null;
      }
    }

    return {
      auth: state.auth,
      stylePostId,
      post,
    }
  },
  actions,
)(StyleUploadPage);
