exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".bottomButtonContainer_1biWGrLdqP{\n  position: absolute;\n  left: 0px;\n  right: 0px;\n  bottom: 0px;\n  background-color: #000;\n  flex-direction: row;\n}\n\n@media (max-width: 449px){\n  .bottomButtonContainer_1biWGrLdqP{\n    position:fixed;\n    bottom:0px;\n    width:100%;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"bottomButtonContainer": "bottomButtonContainer_1biWGrLdqP"
};