import * as React from 'react';

function SvgIcPlus(props) {
  return (
    <svg width={24} height={24} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={12} cy={12} r={12} fill="#FFF" opacity={0.15} />
        <path stroke="#FFF" strokeWidth={1.5} d="M12 6v12M6 12h12" />
      </g>
    </svg>
  );
}

export default SvgIcPlus;

