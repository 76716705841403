import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"

function HeaderSearchIcon(props) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Circle cx={10.5} cy={10.5} r={6.75} stroke="#000" strokeWidth={1.5} />
      <Path
        d="M16.53 15.47l-.53-.53L14.94 16l.53.53 1.06-1.06zm2.94 5.06a.75.75 0 101.06-1.06l-1.06 1.06zm-4-4l4 4 1.06-1.06-4-4-1.06 1.06z"
        fill="#000"
      />
    </Svg>
  )
}

export default HeaderSearchIcon

