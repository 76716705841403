import assign from 'lodash/assign';
import get from 'lodash/get';
import { normalize } from 'normalizr';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';
import { getNextPageUrl } from '../util';

// admin actions for products
export function adminLoadProducts({ nextPage, keyword, isGodoOnly, filters=null }) {
  const key = 'adminProducts';

  return createFetchAction({
    type: 'ADMIN_LOAD_PRODUCTS',
    endpoint: state => {
      const base = '/api/v2/admin/products?page=0';
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      if (nextPage) {
        return next;
      }

      let url = base;
      if (keyword) { url += `&keyword=${encodeURIComponent(keyword)}`; }
      if (isGodoOnly) { url += '&godoOnly=true'; }
      if (filters) {
        filters.forEach(f => {
          const key = "filter" + f.type.charAt(0).toUpperCase() + f.type.slice(1);
          if (f.type == "brand") {
            url += `&${key}=${encodeURIComponent(f.id)}`
          } else {
            url += `&${key}=${encodeURIComponent(f.name)}`
          }
        });
      }
      return url;
    },
    transform: ({ json, response }) => assign(normalize(json, schemas.adminProducts), {
      nextPageUrl: getNextPageUrl(response),
    }),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}

export function adminCreateProduct({ product }) {
  return createFetchAction({
    type: 'ADMIN_CREATE_PRODUCT',
    endpoint: '/api/v2/admin/products/',
    method: 'POST',
    body: product,
    transform: ({ json }) => normalize(json, schemas.adminProduct),
    success: () => ({
      pagination: {
        key: 'adminProducts',
        type: 'PREPEND',
      },
    }),
  });
}

export function adminGetProduct({ productId }) {
  return createFetchAction({
    type: 'ADMIN_GET_PRODUCT',
    endpoint: `/api/v2/admin/products/${productId}`,
    method: 'GET',
    doDispatch: false,
  });
}

export function adminUpdateProduct({ product }) {
  return createFetchAction({
    type: 'ADMIN_UPDATE_PRODUCT',
    endpoint: `/api/v2/admin/products/${product._id}`,
    method: 'PUT',
    body: product,
    transform: ({ json }) => normalize(json, schemas.adminProduct),
  });
}

export function adminDeleteProduct({ productId }) {
  const payload = { entities: { adminProducts: {} } };
  payload.entities.adminProducts[productId] = null;

  return createFetchAction({
    type: 'ADMIN_DELETE_PRODUCT',
    endpoint: `/api/v2/admin/products/${productId}`,
    method: 'DELETE',
    success: {
      payload,
    },
  });
}

export function adminLoadProductMatchingPosts({ productId, nextPage }) {
  const key = 'adminProductMatchingPosts';

  return createFetchAction({
    type: 'ADMIN_LOAD_PRODUCT_MATCHING_POSTS',
    endpoint: state => {
      const base = `/api/v2/admin/products/${productId}/related/posts?page=0`;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      if (nextPage) {
        return next;
      }
      return base;
    },
    transform: ({ json, response }) => assign(normalize(json, schemas.adminProductMatchingPosts), {
      nextPageUrl: getNextPageUrl(response),
    }),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}
