import React from 'react';
import {
  StyleSheet, View, Text, Image, TouchableHighlight,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';

import NumberFormat from 'react-number-format';

import { formatPrice } from '../../../shared/util';
import CheckNormalIcon from '../icons/CheckNormalIcon';
import CheckSelectedIcon from '../icons/CheckSelectedIcon';
import CloseIcon from '../icons/CloseIcon';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';

import LikeButton from './LikeButton';
import ProductLabel from './ProductLabel';


const labelStyles = StyleSheet.create({
  labelContainer: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  itemBrand: {
    marginBottom: 5,
    minHeight: 16,
    lineHeight: 16,
    ...Styles.SFUIDisplaySemiBold,
    fontSize: 14,
    color: 'black',
  },
  itemName: {
    marginBottom: 5,
    minHeight: 17,
    lineHeight: 17,
    fontSize: 14,
    ...Styles.appleSDGothicNeoRegular,
  },
  itemOption: {
    color: 'black',
    opacity: 0.6,
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 12,
    lineHeight: 15,
  },
});

const styles = StyleSheet.create({
  itemContainer: {
    padding: 20,
  },
  itemContentContainer: {
  },
  itemContentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  deleteButton: {
    marginTop: -4,
    marginEnd: -4,
  },
  itemInfoContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  itemContent: {
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginEnd: 10,
  },
  itemBrand: {
    marginTop: 4,
    marginBottom: 6,
    minHeight: 16,
    lineHeight: 16,
    ...Styles.SFUIDisplaySemiBold,
    fontSize: 14,
  },
  itemName: {
    marginBottom: 8,
    minHeight: 17,
    lineHeight: 17,
    fontSize: 14,
    ...Styles.appleSDGothicNeoRegular,
  },
  itemOption: {
    color: '#000',
    opacity: 0.6,
    fontSize: 12,
    ...Styles.appleSDGothicNeoRegular,
    lineHeight: 15,
  },
  itemLikeButtonContainer: {
    ...Styles.touchEffectContainer,
  },
  itemLikeButton: {
  },
  itemContentControlContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    marginHorizontal: -1 * 7 / 2,
  },
  itemContentControlButtonContainer: {
    flex: 1,
    marginHorizontal: 7 / 2,
  },
  itemContentControlButton: {
    flex: 1,
    backgroundColor: Colors.semiGray,
    paddingTop: 11,
    paddingBottom: 10,
    height: 38,
    alignItems: 'center',
  },
  itemContentControlButtonDark: {
    backgroundColor: '#000',
  },
  itemContentControlText: {
    lineHeight: 17,
    height: 17,
    fontSize: 14,
    ...Styles.appleSDGothicNeoBold,
  },
  itemContentControlTextDark: {
    color: '#fff',
  },
  itemPriceContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    height: 30,
  },
  itemSellPrice: {
    height: 30,
    ...Styles.SFUITextSemiBold,
    fontSize: 24,
  },
  itemOrgPrice: {
    marginTop: 10,
    height: 20,
    ...Styles.SFUITextRegular,
    fontSize: 16,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  colorHidden: {
    color: '#0000',
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});

const stylesHtml = {
  itemImage: {
    width: 100,
    height: 100,
    marginRight: 10,
  },
}

function renderTopControl(props) {
  const {
    onPressCheckItem,
    onPressDeleteItem,
    isChecked,
  } = props;

  return (
    <View style={styles.itemContentHeader}>
      <TouchableHighlight
        onPress={onPressCheckItem}
      >
        {isChecked ? <CheckSelectedIcon /> : <CheckNormalIcon />}
      </TouchableHighlight>
      <TouchableHighlight
        underlayColor={Colors.touchEffectColorWhite}
        style={[styles.deleteButton, styles.touchEffect]}
        onPress={onPressDeleteItem}
      >
        <CloseIcon />
      </TouchableHighlight>
    </View>
  );
}


function renderItemInfo(props) {
  const { item, showLikeButton } = props;
  const {
    goodsNo: productId,
    goodsNm: itemName,
    goodsImageURL,
    brandNm,
    option,
    goodsCnt: itemCount,
    preOrder,
  } = item;

  const optionList = option.map(element => {
    const { optionName, optionValue, optionPrice } = element;
    let optionPriceInfo = '';
    // console.log('renderItem optionPrice', optionPrice);
    if (optionPrice !== undefined) {
      const optionPriceInt = Number.parseInt(optionPrice, 10);
      if (optionPriceInt !== 0) {
        optionPriceInfo = ` (${formatPrice(optionPriceInt)})`;
      }
    }

    return (
      <Text key={`option_${optionValue}`} style={styles.itemOption}>
        {optionName}
        {' '}
        :
        {' '}
        {optionValue}
        {optionPriceInfo}
      </Text>
    );
  });

  const showPreOrderLabel = preOrder === 'y';
  const currentStyles = (showPreOrderLabel) ? labelStyles : styles;

  return (
    <View style={styles.itemInfoContainer}>
      <img
        referrerPolicy='no-referrer'
        src={ goodsImageURL }
        style={stylesHtml.itemImage}
      />
      <View style={styles.itemContent}>
        {showPreOrderLabel && (
          <View style={labelStyles.labelContainer}>
            <ProductLabel backgroundColor={Colors.labelPreOrder}>PRE ORDER</ProductLabel>
          </View>
        )}
        <Text key="itemBrand" style={currentStyles.itemBrand}>{brandNm}</Text>
        <Text key="itemName" style={currentStyles.itemName}>{itemName}</Text>
        {optionList}
        <Text key="itemOption" style={currentStyles.itemOption}>
          수량 :
          {' '}
          <Text style={Styles.SFUITextRegular}>{itemCount}</Text>
        </Text>
      </View>
      { showLikeButton && (
        <View style={styles.itemLikeButtonContainer}>
          <LikeButton
            item={Object.assign(item, { _id: productId })}
            styles={styles.itemLikeButton}
            isShopProduct
          />
        </View>
      )}
    </View>
  );
}


function renderBottomControl(props) {
  const {
    onPressModifyItem,
    onPressCheckoutItem,
  } = props;

  return (
    <View style={styles.itemContentControlContainer}>
      <View style={[styles.itemContentControlButtonContainer, { backgroundColor: 'white' }]}>
        <TouchableHighlight
          onPress={onPressModifyItem}
          style={styles.itemContentControlButton}
          underlayColor={Colors.touchEffectColorWhite}
        >
          <Text style={styles.itemContentControlText}>주문 수정</Text>
        </TouchableHighlight>
      </View>
      <View style={[styles.itemContentControlButtonContainer, { backgroundColor: 'black' }]}>
        <TouchableHighlight
          onPress={onPressCheckoutItem}
          style={[styles.itemContentControlButton, styles.itemContentControlButtonDark]}
          underlayColor={Colors.touchEffectColorBlack}
        >
          <Text
            style={[styles.itemContentControlText, styles.itemContentControlTextDark]}
          >
            바로 구매
          </Text>
        </TouchableHighlight>
      </View>
    </View>
  );
}


function renderPrice(props) {
  const {
    item: {
      goodsCnt: itemCount,
      price: {
        fixedPrice,
        optionPrice,
        optionPriceSum,
        goodsPrice,
      },
    },
  } = props;

  const itemOrgPrice = (Number.parseInt(fixedPrice, 10) + Number.parseInt(optionPrice, 10)) * itemCount;
  const itemSellPrice = (Number.parseInt(goodsPrice, 10) + Number.parseInt(optionPrice, 10)) * itemCount;

  return (
    <View style={styles.itemPriceContainer}>
      <NumberFormat
        value={itemOrgPrice}
        displayType="text"
        thousandSeparator
        renderText={value => (
          <Text style={[
            styles.itemOrgPrice,
            ((itemOrgPrice > 0) ? {} : styles.colorHidden),
          ]}
          >
            {value}
            <Text style={Styles.appleSDGothicNeoRegular}>원</Text>
          </Text>
        )}
      />
      <NumberFormat
        value={itemSellPrice}
        displayType="text"
        thousandSeparator
        renderText={value => (
          <Text style={styles.itemSellPrice}>
            {value}
            <Text style={Styles.appleSDGothicNeoSemiBold}>원</Text>
          </Text>
        )}
      />
    </View>
  );
}


export default function CartItem(props) {
  const {
    showTopControl,
    showBottomControl,
  } = props;

  return (
    <View style={styles.itemContainer}>
      <View style={styles.itemContentContainer}>
        {showTopControl && renderTopControl(props)}
        {renderItemInfo(props)}
        {showBottomControl && renderBottomControl(props)}
      </View>
      {renderPrice(props)}
    </View>
  );
}
