import React from 'react';
import {
  StyleSheet, Text, View, FlatList,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions, loadEntities } from 'shared';

import {
  MyPageHeartist
 } from '../../../components-v2';

import {
  Styles, Constants,
} from '../../../constants';

import BasePage from './BasePage';


const styleHtml = {
  blankContainer: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: `${-1 * (Constants.mainHeaderHeight + 49)}px`,
    marginBottom: `${-1 * Constants.bottomMargin}px`,
    overflow: 'hidden',
    touchAction: 'none',
  },
};


const styles = StyleSheet.create({
  blankContainer: {
    flex: 1,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: 240,
    margin: 'auto',
  },
  blankText: {
    fontSize: 16,
    lineHeight: 23,
    ...Styles.pretendard400,
    height: 23,
  },
  container: {
  },
  listHeaderContainer: {
    paddingHorizontal: Constants.viewHorizontalPadding,
    paddingVertical: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listHeaderText: {
    fontSize: 20,
    lineHeight: 24,
    height: 24,
    color: 'black',
    ...Styles.appleSDGothicNeoBold,
  },
  showAllButtonContainer: {
    paddingTop: 3,
    paddingBottom: 4,
  },
  showAllText: {
    fontSize: 14,
    lineHeight: 17,
    color: 'black',
    ...Styles.appleSDGothicNeoSemiBold,
  },
  emptyFollowingHeartistsContainer: {
    paddingTop: 31,
    paddingBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyFollowingHeartistsMessage: {
    height: 21,
    marginTop: 10,
    opacity: 0.5,
    ...Styles.appleSDGothicNeoMedium,
    fontSize: 18,
    lineHeight: 21,
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});


class FollowerHeartistsPage extends BasePage {
  constructor(props) {
    super(props);

    this.type = 'follower';
  }

  componentDidMount() {
    super.componentDidMount();

    const {
      logEventPageview,
    } = this.props;

    logEventPageview({ page_name: 'FollowerHeartists' });
  }

  renderBlank() {
    return (
      <div style={styleHtml.blankContainer}>
        <View style={styles.blankContainer}>
          <Text style={styles.blankText}>
            팔로우하는 하티스트가 없습니다.
          </Text>
        </View>
      </div>
    )
  }

  renderHeartist(heartist) {
    const { auth, router, loadFollowerHeartists, loadFollowingHeartists, followUser, unfollowUser } = this.props;

    return (
      <MyPageHeartist
        heartist={heartist}
        router={router}
        followUser={(userId) => {
          followUser(userId)
            .then(() => {
              loadFollowerHeartists();
              loadFollowingHeartists();
            });;
        }}
        unfollowUser={(userId) => {
          unfollowUser(userId)
            .then(() => {
              loadFollowerHeartists();
              loadFollowingHeartists();
            });
        }}
        auth={auth}
      />
    );
  }

  renderBody() {
    const {
      followers=[],
    } = this.props;

    if (followers.length === 0) {
      return this.renderBlank();
    }

    return (
      <View style={styles.container}>
        <FlatList
          ListHeaderComponent={(<View style={{height: 10}}/>)}
          data={followers}
          keyExtractor={item => item._id} // eslint-disable-line no-underscore-dangle
          renderItem={({ item }) => this.renderHeartist(item)}
        />
      </View>
    );
  }
}


export default connect(
  state => ({
    auth: state.auth,
    ...loadEntities(state, 'followers', 'followers', 'pagination', 'followers'),
  }),
  actions,
)(FollowerHeartistsPage);
