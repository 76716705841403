import React from 'react'
import { connect } from 'react-redux';

import { 
  StyleSheet, View, Text, Platform
} from 'react-native';

import { actions } from 'shared';

import { Styles, Colors, Constants } from '../../constants';
import {
  TouchableHighlightWithContainer,
  SizeGuide,
} from '../../components-v2';

const headerMarginHeight = Constants.mainHeaderMarginTop
  + ((Platform.OS === 'android') ? 0 : Constants.statusBarHeight);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: headerMarginHeight,
    backgroundColor: '#fff',
  },
  headerContainer: {
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: Colors.defaultGray02,
    height: 52,
    paddingTop: 15,
    paddingBottom: 18,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  headerTitleText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 16,
    lineHeight: 18,
    color: Colors.defaultGray08,
  },
  headerCloseButton: {
    position: 'absolute',
    top: 16,
    end: Constants.viewHorizontalPadding,
  },
  headerCloseButtonText: {
    ...Styles.appleSDGothicNeoMedium,
    fontSize: 11,
    lineHeight: 18,
    color: Colors.defaultGray08,
  },
});


class SizeGuidePage extends React.Component {

  componentDidMount() {
    const {
      logEventPageview,
    } = this.props;

    logEventPageview({ page_name: 'SizeGuide' });
  }

  onClose() {
    const { navigation } = this.props;
    navigation.dismiss();
    const callback = navigation.getParam('callback');
    callback();
  }

  renderHeader() {
    return (
      <View style={styles.headerContainer}>
        <Text style={styles.headerTitleText}>사이즈 가이드</Text>
        <TouchableHighlightWithContainer
          containerStyle={styles.headerCloseButton}
          onPress={() => this.onClose()}
        >
          <Text style={styles.headerCloseButtonText}>닫기</Text>
        </TouchableHighlightWithContainer>
      </View>
    )
  }

  renderBody() {
    const { navigation } = this.props;
    const targetSubType = navigation.getParam('targetSubType');

    return (
      <SizeGuide
        targetSubType={targetSubType}
        />
    );
  }

  render() {
    return (
      <View style={styles.container}>
        {this.renderHeader()}
        {this.renderBody()}
      </View>
    );
  }
}


export default connect(
  state => ({ auth: state.auth }),
  actions,
)(SizeGuidePage);
