import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, MenuItem, Select, Typography, withStyles,
} from '@material-ui/core';
import { Link } from 'react-router';
import { compose } from 'recompose';
import dayjs from 'dayjs';
import {
  adminDeleteStylePost,
  adminLoadStylePosts,
  loadCreators,
} from 'react-shared/adminActions';
import { loadEntities } from 'react-shared/util';
import Spinner from 'components/Spinner';
import ThumbnailList from 'components/admin/ThumbnailList';
import Media from 'components/Media';

import FilterNoneIcon from '@material-ui/icons/FilterNone';

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    minHeight: 50,
    padding: 20,
  },
});

class ListStylePostPage extends Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.renderCaption = this.renderCaption.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.state = {
      _id: '',
      // setFeaturedDialogOpen: false,
      // unsetFeaturedDialogOpen: false,
      featuredDialogOpen: false,
      selectedPost: null,
      hideItemPost: false,
    };
  }

  componentDidMount() {
    this.props.loadCreators();
    ListStylePostPage.fetchData(this.props);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if (this.props.userId !== prevProps.userId) {
      ListStylePostPage.fetchData(this.props);
    }
  }

  static fetchData(props, nextPage) {
    props.adminLoadStylePosts(nextPage, props.userId);
  }

  static getMedia(post) {
    return post.media[0];
  }

  handleScroll() {
    if (window.pageYOffset > document.body.clientHeight - window.innerHeight - 1
        && !this.props.pagination.isFetching && this.props.pagination.nextPageUrl) {
      this.handleLoadMore();
    }
  }

  handleLoadMore() {
    ListStylePostPage.fetchData(this.props, true);
  }

  static guessMedia(item) {
    if (Array.isArray(item.media)) { return item.media[0]; }
    return item.media || item;
  }

  static renderEditButton(post) {
    return (
      <Link className="btn btn-default btn-small" to={`/admin/stylePosts/${post._id}`}>
        <span className="glyphicon glyphicon-edit"></span>
      </Link>
    );
  }

  renderDeleteButton(post) {
    return (
      <button
        type="button"
        className="btn btn-default btn-small"
        onClick={
          () => confirm('Are you sure to delete this post?') &&  //eslint-disable-line
                this.props.adminDeleteStylePost(post._id)
        }
      >
        <span className="glyphicon glyphicon-trash"></span>
      </button>
    );
  }

  renderCaption(post) {
    const captionStyle = { height: 30, textAlign: 'center' };
    return (
      <div>
        <p>{post.owner.username}</p>
        <div style={captionStyle}>
          {ListStylePostPage.renderEditButton(post)}
          {this.renderDeleteButton(post)}
        </div>
      </div>
    );
  }

  static renderDate(post) {
    return (
      <span style={{fontSize: '10px' }}>
        {'created at ' + dayjs(parseInt(post.created)).format('YYYY-MM-DD (HH:mm)')}
      </span>
    );
  }

  render() {
    const { classes, stylePosts, pagination: { isFetching }, router, users } = this.props;
    const menuItems = [{ username: '모든 포스트 보기', userId: '', _id: '' }].concat(users);

    return (
      <div className="container">
        <div className={`row ${classes.root}`}>
          <Select
            value={this.state._id}
            onChange={event => {
              this.setState({ _id: event.target.value });
              router.push(event.target.value
                ? `/admin/stylePosts?userId=${event.target.value}`
                : '/admin/stylePosts');
            }}
            style={{ width: 300, marginRight: 10 }}
            displayEmpty
          >
            {menuItems.map(menuItem => (
              <MenuItem
                key={`listpostpage_menuitem_${menuItem._id}`}
                value={menuItem._id}
              >
                <Typography variant="subheading">
                  {menuItem.username}
                </Typography>
              </MenuItem>
            ))}
          </Select>
          <Link className="btn btn-primary" to="/admin/stylePosts/new">
            New Style Post
          </Link>
        </div>
        <ThumbnailList
          getMedia={ListStylePostPage.getMedia}
          items={stylePosts}
          renderCaption={this.renderCaption}
          isSortable={false}
          renderHeader={post => ListStylePostPage.renderDate(post)}
        />
        {isFetching && <Spinner />}
        {this.state.selectedPost && this.renderFeaturedDialog(
          this.state.selectedPost.featured && this.state.selectedPost.featured.score
            ? 'unset' : 'set'
        )}
      </div>
    );
  }
}

ListStylePostPage.propTypes = {
  adminDeleteStylePost: PropTypes.func.isRequired,
  loadCreators: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  adminLoadStylePosts: PropTypes.func.isRequired,
  stylePosts: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  userId: PropTypes.string,
  users: PropTypes.array.isRequired,
};

ListStylePostPage.defaultProps = {
  userId: '',
};

export default compose(
  withStyles(styles),
  connect((state, ownProps) => {
    const { userId } = ownProps.location.query;
    return {
      userId,
      // username: state.auth.username,
      users: loadEntities(state, 'creators', 'users').users,
      ...loadEntities(state, userId ? `userStylePosts.${userId}` : 'stylePosts', 'stylePosts'),
    };
  }, {
    adminDeleteStylePost,
    adminLoadStylePosts,
    loadCreators,
  }),
)(ListStylePostPage);
