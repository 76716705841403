import * as React from 'react';

function SvgControlRadioSelected(props) {
  return (
    <svg width={24} height={24} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={12} cy={12} r={12} fill="#000" />
        <circle cx={12} cy={12} r={5} fill="#FFF" />
      </g>
    </svg>
  );
}

export default SvgControlRadioSelected;
