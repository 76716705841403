import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { map, pick, keys, get } from 'lodash';


const statNameMap = {
  'wishlist': '위시리스트',
  'cart': '카트',
};

class SnapshotStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visitStatisticsOptions: {
        title: {
          text: '',
        },
        series: [],
        xAxis: {
          tickmarkPlacement: 'on',
          reversed: true,
        },
      },
      kind: 'daily',
      visitStatRawData: {},
    };
  }

  loadProductStatistics(startDate, endDate) {
    const { loadData, snapshotKey } = this.props;
    const { kind } = this.state;

    // console.log(`loadProductStatistics snapshotKey=${snapshotKey}, kind=${kind}`);

    loadData(startDate, endDate, kind, snapshotKey)
      .then(resp => {
        const {
          data,
          product,
        } = resp.content;
        this.setState({
          visitStatRawData: data,
          title: product.name,
          description: '',
        });

        return data;
      })
      .then(rawData => {
        const categories = Object.keys(rawData);
        const data = {};
        const statNames = Object.keys(statNameMap);
        statNames.forEach(key => { data[key] = [] });
        categories.forEach(date => {
          statNames.forEach(key => {
            data[key].push(parseInt(get(rawData, `${date}.pages.${key}`, "0")));
          });
        });

        const series = statNames.map((key) => {
          return {
            name: statNameMap[key],
            data: data[key]
          };
        });

        const { visitStatisticsOptions: visitStatisticsOptionsPrev } = this.state;

        this.setState({
          visitStatisticsOptions: {
            ...visitStatisticsOptionsPrev,
            xAxis: {
              categories,
              reversed: false,
            },
            series,
          },
        });
      });
  }

  componentDidMount() {
    const { startDate, endDate } = this.props;
    this.loadProductStatistics(startDate, endDate);
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate } = this.props;
    const { kind } = this.state;
    if (prevProps.startDate !== startDate || prevProps.endDate !== endDate || prevState.kind !== kind) {
      this.loadProductStatistics(startDate, endDate);
    }
  }

  renderItemName() {
    const { snapshotKey } = this.props;
    const {
      title,
      description,
    } = this.state;

    return (
      <a href={`/product/${snapshotKey}`} target='_blank' title={description}>{title || snapshotKey}</a>
    );
  }

  render() {
    const {
      visitStatisticsOptions,
      kind,
      title,
    } = this.state;

    return (
      <div>
        <div style={{ fontSize: '20px', marginLeft: '20px', fontWeight: 'bold' }}>
          # {this.renderItemName()} Snapshot Statistics&nbsp;&nbsp;
          <select onChange={event => this.setState({ kind: event.target.value })} value={kind}>
            <option value="daily">일별</option>
            <option value="monthly">월별</option>
          </select>
        </div>

        <HighchartsReact
          containerProps={{ style: { height: '600px' } }}
          highcharts={Highcharts}
          options={visitStatisticsOptions} />
      </div>
    );
  }
}


SnapshotStatistics.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};


export default SnapshotStatistics;
