import React from 'react';
import {
  ScrollView, StyleSheet, Text, TextInput, View,
  TouchableHighlight, Dimensions,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';
import { isEmail } from 'validator';
import { Snackbar } from '@material-ui/core';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';
import BirthDateSelect from '../../components/BirthDateSelect';
import CustomDialog from '../../components/CustomDialog';

import CheckBox from '../../nativeComponents/CheckBox';
import { default as PhoneVerificationForm, isPhoneNumber } from '../../nativeComponents/PhoneVerificationForm';
import Button from '../../nativeComponents/Button';

import CheckComplete from '../../icons/CheckComplete';

import Colors from '../../constants/Colors';
import Styles from '../../constants/Styles';
import Constants from '../../constants/Constants';
import LegalPolicyPage from '../LegalPolicyPage';


const { width: windowWidth } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollContainer: {
    flex: 1,
    paddingTop: 20,
    paddingHorizontal: 20,
  },
  inputContainer: {
    alignItems: 'center',
  },
  termContainer: {
    marginTop: 10,
    marginBottom: 30,
  },
  joinButtonContainer: {
    ...Styles.buttonDark,
    marginBottom: 96,
  },
  joinButton: {
    backgroundColor: 'transparent',
    width: '100%',
    height: 48,
    padding: 0,
    borderWidth: 0,
  },
  joinText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 14,
    marginTop: 16,
    marginBottom: 15,
    height: 17,
    lineHeight: 17,
    ...Styles.buttonDarkText,
  },
  inputText: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    color: 'black',
    paddingTop: 17,
    paddingBottom: 16,
    paddingHorizontal: 16,
    height: 52,
  },
  inputBorder: {
    width: '100%',
    marginBottom: 10,
    backgroundColor: Colors.semiGray,
  },
  checkIcon: {
    position: 'absolute',
    right: 14,
    top: 14,
    bottom: 14,
    width: 24,
    height: 24,
  },
  errorInputBorder: {
    borderWidth: 1,
    borderColor: 'rgb(255, 62, 62)',
  },
  agreeContainer: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  agreeCheckboxContainer: {
    marginEnd: 12,
  },
  agreeCheckbox: {
    marginEnd: 12,
    width: 24,
    height: 24,
    backgroundColor: 'rgba(0, 0, 0, 0.201)',
  },
  agreeContentContainer: {
    maxWidth: windowWidth - 20 * 2 - 24 - 12,
  },
  agreeText: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 14,
    lineHeight: 17,
    marginTop: 3,
    marginBottom: 4,
    color: 'black',
  },
  agreeLink: {
    ...Styles.appleSDGothicNeoMedium,
    fontSize: 14,
    color: 'black',
    textDecorationLine: 'underline',
    lineHeight: 17,
    height: 17,
  },
  agreeLinkButton: {
    height: 17,
  },
  agreeAllContainer: {
    marginTop: 10,
    marginBottom: 20,
  },
  agreeAllText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 15,
    lineHeight: 19,
    height: 19,
    marginTop: 2,
    marginBottom: 3,
  },
  agreeTermContainer: {
  },
  agreeTermText: {
  },
  agreePrivacyContainer: {
  },
  agreePrivacyText: {
  },
  agreeMarketingContainer: {
    marginBottom: 0,
  },
  agreeMarketingText: {
  },
  errorContainer: {
    width: '100%',
    marginTop: -4,
    marginBottom: 15,
  },
  errorText: {
    flexWrap: 'wrap',
    lineHeight: 20,
    fontSize: 15,
    color: 'rgb(252,43,56)',
    ...Styles.appleSDGothicNeoLight,
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});

export class JoinWithEmailScreen extends React.Component {
  state = {
    birthYear: '',
    email: '',
    name: '',
    phone: '',
    phoneVerified: false,
    phoneIgnoreOldUser: false,
    password: '',
    passwordCheck: '',
    termAgreed: false,
    privacyAgreed: false,
    marketingAgreed: false,
    snackbarMsg: '',
    snackbarOpen: false,
    emailError: '',
    nameError: '',
    phoneError: '',
    passwordError: '',
    passwordCheckError: '',
    termAgreedError: '',
    privacyAgreedError: '',
    showAccountRecoveryDialog: false,
    showAgreeDialog: false,
    showIgnoreOldUserDialog: false,
    ignoreOldUserEmail: '',
  };

  button = React.createRef();

  passwordInputRef = null;

  passwordCheckInputRef = null;

  nameInputRef = null;

  phoneNumberInputRef = null;

  resetErrorMessage = ({
    emailError = '',
    nameError = '',
    phoneError = '',
    passwordError = '',
    passwordCheckError = '',
    termAgreedError = '',
    privacyAgreedError = '',
  }, callback = () => {}) => {
    this.setState({
      emailError,
      nameError,
      phoneError,
      passwordError,
      passwordCheckError,
      termAgreedError,
      privacyAgreedError,
    }, callback);
  }

  componentDidMount() {
    const { auth, router } = this.props;
    if (auth && auth.token) {
      router.push('/');
    }
  }

  onPressCheckboxAll = () => {
    const { termAgreed, privacyAgreed, marketingAgreed } = this.state;
    const checked = termAgreed && privacyAgreed && marketingAgreed;
    this.setState({
      termAgreed: !checked,
      privacyAgreed: !checked,
      marketingAgreed: !checked,
    });
  }

  onPressTerms = type => () => {
    const { router } = this.props;

    router.push(`/accounts/join/email/legal/${type}`);
  };

  async handleJoin() {
    const { router, signup, loadCurrentUser } = this.props;
    const { name, email, phone, phoneVerified, password, passwordCheck,
      birthYear: birthYearInDate, termAgreed, privacyAgreed, marketingAgreed } = this.state;
    const username = email;
    let birthYear;
    if (birthYearInDate) {
      const year = birthYearInDate.getFullYear();
      const month = birthYearInDate.getMonth() + 1;
      const day = birthYearInDate.getDate();
      birthYear = [year, month, day].join('/');
    }

    try {
      const json = await signup({
        username,
        email,
        password,
        passwordCheck,
        name,
        tel: phone,
        telVerified: phoneVerified,
        termAgreed,
        privacyAgreed,
        marketingAgreed,
        birthYear,
      });
      await loadCurrentUser(json.bearerToken);
      router.push({
        pathname: '/',
        state: { toast: '가입이 완료되었습니다.' },
      });
    } catch (e) {
      if (e.status === 400) {
        this.setState({
          showAccountRecoveryDialog: true,
        });
        return;
      } else if (e.status === 401) {
        this.verifyInputValues({ phoneError: '휴대폰번호를 인증해야 합니다.' });
      }
      const msg = '가입에 실패했습니다. 잠시 후 다시 시도하세요.';
      this.showSnackbar(msg);
    }
  }

  showSnackbar(msg) {
    this.setState({
      snackbarMsg: msg,
      snackbarOpen: true,
    });
  }

  verifyEmail = (val = null) => {
    let { email } = this.state;
    if (val !== null) {
      email = val;
    }

    if (!email || email === '') {
      return '이메일을 입력하세요.';
    }

    if (!isEmail(email)) {
      return '이메일 형식을 확인하세요.';
    }

    return '';
  }

  verifyName = (val = null) => {
    let { name } = this.state;
    if (val !== null) {
      name = val;
    }

    if (!name || name === '') {
      return '이름을 입력하세요.';
    }

    return '';
  }

  verifyPassword = (val = null) => {
    let { password } = this.state;
    if (val !== null) {
      password = val;
    }

    if (!password || password === '') {
      return '비밀번호를 입력하세요.';
    } else if (!password.match(Constants.passwordPattern)) {
      return '비밀번호는 8 ~ 32 자의 영문 대소문자, 숫자, 특수문자를 조합하여 입력하세요.';
    }

    return '';
  }

  verifyPasswordCheck = (passwordVal = null, passwordCheckVal = null) => {
    let { password, passwordCheck } = this.state;

    if (passwordVal !== null) {
      password = passwordVal;
    }
    if (passwordCheckVal !== null) {
      passwordCheck = passwordCheckVal;
    }

    if (!passwordCheck || passwordCheck === '') {
      return '비밀번호를 한 번 더 입력하세요.';
    }

    if (password !== passwordCheck) {
      return '비밀번호가 서로 다릅니다. 다시 입력하세요.';
    }

    return '';
  }

  verifyInputValues(defaultValue = {}) {
    const { phone, phoneVerified, termAgreed, privacyAgreed } = this.state;

    let verified = true;
    let newState = {};

    newState.emailError = this.verifyEmail();
    newState.nameError = this.verifyName();

    if (!phone || phone === '') {
      newState.phoneError = '휴대폰 번호를 입력하세요.';
    } else if (!isPhoneNumber(phone)) {
      newState.phoneError = '휴대폰 번호 형식을 확인하세요.';
    } else if (!phoneVerified) {
      newState.phoneError = '휴대폰 번호 인증을 해주세요.';
    }

    newState.passwordError = this.verifyPassword();
    newState.passwordCheckError = this.verifyPasswordCheck();

    if (!termAgreed) {
      newState.termAgreedError = '이용약관 동의가 필요합니다';
    }
    if (!privacyAgreed) {
      newState.privacyAgreedError = '개인정보 수집 및 이용 동의가 필요합니다';
    }

    Object.values(newState).forEach(msg => {
      if (msg.length > 0) {
        verified = false;
      }
    });

    newState = { ...newState, ...defaultValue };
    verified = verified && Object.values(defaultValue).length <= 0;
    this.resetErrorMessage(newState, this.showAgreeCheckAlert);

    return verified;
  }

  showAgreeCheckAlert = () => {
    const { termAgreed, privacyAgreed } = this.state;
    if (termAgreed && privacyAgreed) {
      return;
    }

    this.setState({ showAgreeDialog: true });
  }

  showIgnoreOldUserAlert = email => {
    this.setState({
      showIgnoreOldUserDialog: true,
      ignoreOldUserEmail: email,
    });
  }

  async handleAll() {
    if (!this.verifyInputValues()) {
      return;
    }
    this.handleJoin();
  }

  renderErrorMsg = msg => (
    <View style={styles.errorContainer}>
      <Text style={styles.errorText}>
        {msg}
      </Text>
    </View>
  )

  isJoinButtonDisabled = () => {
    const {
      email, phone, phoneVerified, name, termAgreed, privacyAgreed,
      password, passwordCheck,
      emailError, nameError, phoneError,
      passwordError, passwordCheckError,
    } = this.state;
    const { requestJoinOngoing } = this.props;

    return (
      email.length === 0 || phone.length === 0 || name.length === 0 || !phoneVerified
      || emailError.length > 0 || nameError.length > 0 || phoneError.length > 0
      || !termAgreed || !privacyAgreed
      || password.length === 0 || passwordCheck.length === 0
      || passwordError.length > 0 || passwordCheckError.length > 0
      || requestJoinOngoing
    );
  }

  renderPhoneVerificationForm() {
    const { router } = this.props;
    const {
      phone, phoneVerified, phoneIgnoreOldUser,
      phoneError,
      showIgnoreOldUserDialog, ignoreOldUserEmail,
    } = this.state;

    return (
      <View style={{ width: '100%' }}>
        <PhoneVerificationForm
          onPhoneNumberInputRef={ref => { this.phoneNumberInputRef = ref; }}
          defaultPhoneNumber={phone}
          defaultVerified={phoneVerified}
          showAlert={msg => { this.setState({ phoneError: msg }); }}
          showIgnoreOldUserAlert={oldUserEmail => this.showIgnoreOldUserAlert(oldUserEmail)}
          ignoreOldUser={phoneIgnoreOldUser}
          onChangePhoneNumber={value => { this.setState({ phone: value }); }}
          verificationChanged={verified => { this.setState({ phoneVerified: verified }); }}
          showErrorBorder={phoneError.length > 0}
        />
        { phoneError.length > 0 && this.renderErrorMsg(phoneError) }
        <CustomDialog
          dialogOpen={showIgnoreOldUserDialog}
          onConfirm={() => {
            this.setState({
              showIgnoreOldUserDialog: false,
              phoneIgnoreOldUser: true,
            });
          }}
          onCancel={() => {
            this.setState({
              showIgnoreOldUserDialog: false,
              phoneIgnoreOldUser: false,
            }, () => {
              router.push({
                pathname: '/accounts/login/',
              });
            });
          }}
          onClose={() => {
            this.setState({ showIgnoreOldUserDialog: false });
          }}
          confirmColor="default"
          confirmText="가입 진행하기"
          cancelText="로그인하기"
          title="이미 가입된 휴대폰 번호입니다."
          description={(
            <div>
              {`${ignoreOldUserEmail} 으로 가입된 번호입니다. 그래도 가입을 진행하시겠습니까?`}
            </div>
          )}
        />

      </View>
    );
  }

  renderJoinForm() {
    const {
      birthYear, email, name,
      password, passwordCheck,
      termAgreed, privacyAgreed, marketingAgreed,
      emailError, nameError,
      passwordError,
      passwordCheckError, termAgreedError, privacyAgreedError,
      showAccountRecoveryDialog, showAgreeDialog,
    } = this.state;
    const { router } = this.props;

    return (
      <View style={styles.container}>
        <ScrollView style={styles.scrollContainer}>
          <View
            style={styles.inputContainer}
            keyboardShouldPersistTaps="handled"
          >
            <View style={styles.inputBorder}>
              <TextInput
                autoCapitalize="none"
                keyboardType="email-address"
                onChangeText={val => this.setState({ email: val, emailError: this.verifyEmail(val) })}
                placeholder="이메일"
                placeholderTextColor={Colors.placeholderColor}
                style={[
                  styles.inputText,
                  emailError.length > 0 ? styles.errorInputBorder : {},
                  { outline: 'none' },
                ]}
                value={email}
                onSubmitEditing={() => {
                  if (this.passwordInputRef) {
                    this.passwordInputRef.focus();
                  }
                }}
              />
              {email.length > 0 && emailError.length <= 0 && (
                <View style={styles.checkIcon}>
                  <CheckComplete />
                </View>
              )}
            </View>
            { emailError.length > 0 && this.renderErrorMsg(emailError) }

            <View style={styles.inputBorder}>
              <TextInput
                ref={ref => { this.passwordInputRef = ref; }}
                autoCapitalize="none"
                keyboardType="default"
                onChangeText={val => this.setState({
                  password: val,
                  passwordError: this.verifyPassword(val),
                  passwordCheckError: this.verifyPasswordCheck(val, passwordCheck),
                })}
                placeholder="비밀번호 (8자 이상 영문, 숫자, 특수문자 조합)"
                placeholderTextColor={Colors.placeholderColor}
                style={[
                  styles.inputText,
                  passwordError.length > 0 ? styles.errorInputBorder : {},
                  { outline: 'none' },
                ]}
                secureTextEntry
                value={password}
                onSubmitEditing={() => {
                  if (this.passwordCheckInputRef) {
                    this.passwordCheckInputRef.focus();
                  }
                }}
              />
              {password.length > 0 && passwordError.length <= 0 && (
                <View style={styles.checkIcon}>
                  <CheckComplete />
                </View>
              )}
            </View>
            { passwordError.length > 0 && this.renderErrorMsg(passwordError) }

            <View style={styles.inputBorder}>
              <TextInput
                ref={ref => { this.passwordCheckInputRef = ref; }}
                autoCapitalize="none"
                keyboardType="default"
                onChangeText={val => this.setState({
                  passwordCheck: val,
                  passwordCheckError: this.verifyPasswordCheck(password, val),
                })}
                placeholder="비밀번호 확인"
                placeholderTextColor={Colors.placeholderColor}
                style={[
                  styles.inputText,
                  passwordCheckError.length > 0 ? styles.errorInputBorder : {},
                  { outline: 'none' },
                ]}
                secureTextEntry
                value={passwordCheck}
                onSubmitEditing={() => {
                  if (this.nameInputRef) {
                    this.nameInputRef.focus();
                  }
                }}
              />
              {passwordCheck.length > 0 && passwordCheckError.length <= 0 && (
                <View style={styles.checkIcon}>
                  <CheckComplete />
                </View>
              )}
            </View>
            { passwordCheckError.length > 0 && this.renderErrorMsg(passwordCheckError) }

            <View style={styles.inputBorder}>
              <TextInput
                ref={ref => { this.nameInputRef = ref; }}
                autoCapitalize="none"
                keyboardType="default"
                textContentType="name"
                onChangeText={val => this.setState({ name: val, nameError: this.verifyName(val) })}
                placeholder="이름"
                placeholderTextColor={Colors.placeholderColor}
                style={[
                  styles.inputText,
                  nameError.length > 0 ? styles.errorInputBorder : {},
                  { outline: 'none' },
                ]}
                value={name}
                onSubmitEditing={() => {
                  if (this.phoneNumberInputRef) {
                    this.phoneNumberInputRef.focus();
                  }
                }}
              />
              {name.length > 0 && nameError.length <= 0 && (
                <View style={styles.checkIcon}>
                  <CheckComplete />
                </View>
              )}
            </View>
            { nameError.length > 0 && this.renderErrorMsg(nameError) }

            {this.renderPhoneVerificationForm()}

            <BirthDateSelect
              containerStyle={[styles.inputBorder, styles.inputText]}
              onBirthDateChanged={val => this.setState({ birthYear: val })}
              value={birthYear}
              placeholder="생년월일 입력 (선택 사항)"
            />
          </View>

          <View style={styles.termContainer}>
            <View style={[styles.agreeContainer, styles.agreeAllContainer]}>
              <View style={styles.agreeCheckboxContainer}>
                <CheckBox
                  onPress={this.onPressCheckboxAll}
                  checked={termAgreed && privacyAgreed && marketingAgreed}
                />
              </View>
              <Text style={[styles.agreeText, styles.agreeAllText]}>
                전체 동의
              </Text>
            </View>
            <View style={[styles.agreeContainer, styles.agreeTermContainer]}>
              <View style={styles.agreeCheckboxContainer}>
                <CheckBox
                  onPress={() => this.setState({ termAgreed: !termAgreed })}
                  checked={termAgreed}
                />
              </View>
              <View style={styles.agreeContentContainer}>
                <Text style={[styles.agreeText, styles.agreeTermText]}>
                  (필수)본인은 14세 이상이며 이용약관에 동의합니다

                  <View style={styles.agreeLinkButton}>
                    <TouchableHighlight
                      onPress={this.onPressTerms('terms')}
                      underlayColor={Colors.touchEffectColorWhite}
                      style={styles.touchEffect}
                    >
                      <Text style={styles.agreeLink}>
                        본문보기
                      </Text>
                    </TouchableHighlight>
                  </View>
                </Text>
              </View>
            </View>
            <View style={[styles.agreeContainer, styles.agreePrivacyContainer]}>
              <View style={styles.agreeCheckboxContainer}>
                <CheckBox
                  onPress={() => this.setState({ privacyAgreed: !privacyAgreed })}
                  checked={privacyAgreed}
                />
              </View>
              <View style={styles.agreeContentContainer}>
                <Text style={[styles.agreeText, styles.agreePrivacyText]}>
                  (필수)개인정보 수집 및 이용에 동의합니다

                  <View style={styles.agreeLinkButton}>
                    <TouchableHighlight
                      onPress={this.onPressTerms('privacy')}
                      underlayColor={Colors.touchEffectColorWhite}
                      style={styles.touchEffect}
                    >
                      <Text style={styles.agreeLink}>
                        본문보기
                      </Text>
                    </TouchableHighlight>
                  </View>
                </Text>
              </View>
            </View>
            <View style={[styles.agreeContainer, styles.agreeMarketingContainer]}>
              <View style={styles.agreeCheckboxContainer}>
                <CheckBox
                  onPress={() => this.setState({ marketingAgreed: !marketingAgreed })}
                  checked={marketingAgreed}
                />
              </View>
              <View style={styles.agreeContentContainer}>
                <Text style={[styles.agreeText, styles.agreeMarketingText]}>
                  (선택)마케팅 정보 수신에 동의합니다

                  {/* <View style={styles.agreeLinkButton}>
                    <TouchableHighlight
                      onPress={this.onPressTerms('marketing')}
                      underlayColor={Colors.touchEffectColorWhite}
                      style={styles.touchEffect}
                    >
                      <Text style={styles.agreeLink}>
                        본문보기
                      </Text>
                    </TouchableHighlight>
                  </View> */}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.joinButtonContainer}>
            <Button
              containerStyle={styles.joinButton}
              backgroundColor="black"
              underlayColor={Colors.touchEffectColorBlack}
              onPress={() => this.handleAll()}
              title="가입하기"
              titleStyle={styles.joinText}
              disabled={this.isJoinButtonDisabled()}
            />
          </View>
        </ScrollView>

        <Snackbar
          autoHideDuration={2000}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
          style={{ textAlign: 'center' }}
        />

        <CustomDialog
          dialogOpen={showAccountRecoveryDialog}
          onConfirm={() => {
            router.push({
              pathname: '/accounts/login/email/recovery',
              state: { findPassword: true },
            });
          }}
          onCancel={() => this.setState({ showAccountRecoveryDialog: false })}
          onClose={() => this.setState({ showAccountRecoveryDialog: false })}
          description={(
            <div style={{ textAlign: 'center' }}>
              이미 가입된 이메일입니다.
              <br />
              비밀번호 찾기로 이동할까요?
            </div>
          )}
        />

        <CustomDialog
          dialogOpen={showAgreeDialog}
          onConfirm={() => this.setState({ showAgreeDialog: false })}
          onClose={() => this.setState({ showAgreeDialog: false })}
          showCancel={false}
          confirmColor="default"
          description={(
            <div style={{ textAlign: 'center' }}>
              { (!termAgreed) ? termAgreedError : privacyAgreedError }
            </div>
          )}
        />
      </View>
    );
  }

  render() {
    const { legalPolicyType } = this.props;

    if (legalPolicyType) {
      return <LegalPolicyPage params={{ type: legalPolicyType }} />;
    }

    return this.renderJoinForm();
  }
}

export default connect(
  (state, { params }) => {
    const { legalPolicyType = null } = params;

    return {
      legalPolicyType,
    };
  },
  actions
)(JoinWithEmailScreen);
