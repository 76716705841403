import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { getDateTimeString } from 'shared/util';
import InstagramProfileImage from './InstagramProfileImage';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';

export default class ProfileHeader extends React.Component {

  render() {
    const { user, name, username, date } = this.props;

    return (
      <View style={styles.container}>
        <InstagramProfileImage
          style={styleHtml.profileImage}
          user={user}
          username={username}
        />
        <View style={styles.nameDateContainer}>
          <Text style={styles.nameText}>
            {name}
          </Text>
          <Text style={styles.dateText}>
            {getDateTimeString(date)}
          </Text>
        </View>
      </View>
    );
  }
}

const styleHtml = {
  profileImage: {
    borderRadius: 22,
    borderWidth: 0,
    backgroundColor: '#cfcfca',
    height: 44,
    width: 44,
    marginRight: 10,
  },
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  nameDateContainer: {
    flexGrow: 1,
    flexDirection: 'column',
    marginTop: 3,
    marginBottom: 1,
  },
  nameText: {
    fontSize: 15,
    lineHeight: 18,
    ...Styles.pretendard600,
  },
  dateText: {
    fontSize: 13,
    lineHeight: 15.6,
    opacity: 0.5,
    marginTop: 2,
    ...Styles.pretendard400,
  },
});
