import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from 'components/Spinner';
import { loadEntities } from 'react-shared';
import * as adminActions from 'react-shared/adminActions';
import { Link } from 'react-router';
import Sortable from 'react-sortablejs';
import PreorderExhibition from '../../../components-v2/Admin/PreorderExhibitionItem';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import moment from 'moment';
import 'moment/locale/ko';

moment.locale('ko');

const styles = {
  container: {
    position:'relative',
    margin: '0 auto',
    width: '100%',
    maxWidth: '1200px',
  },
  add_link :{
    position:'absolute',
    right:'0px',
    top:'24px'
  },
  add_button :{
    fontSize:'16px'
  },
  top_header:{
    position:'relative',
    width:'100%',
    marginBottom:'24px'
  },
  top_container:{
    position:'relative',
    width:'100%',
    padding:'18px 0px',
  },
  top_container_box:{
    border:'1px solid #aaaaaa',
    borderRadius:'6px',
    padding:'12px'
  },
  top_header_title : {
    fontSize:'14px',
    color:'#444444'
  },
  top_header_value : {
    fontSize:'17px',
    fontWeight:'900',
    color:'#444444'
  }
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export class PreorderExhibitionPage extends Component {

  constructor(props){
    super(props);
    this.handleSortable = this.handleSortable.bind(this);
    this.handleUpdateSort = this.handleUpdateSort.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      sortable : false,
      sorting : [],
      open:false
    };
  }

  componentDidMount() {
    const { adminPreorderExhibitions, adminLoadPreorderExhibitions } = this.props;
    if (!adminPreorderExhibitions || adminPreorderExhibitions.length === 0) {
      adminLoadPreorderExhibitions();
    }
  }

  handleSortable(event) {

    const { adminPreorderExhibitions } = this.props;

    this.setState( {
      sortable : event.target.checked,
      sorting : event.target.checked && [...adminPreorderExhibitions]
    });
  }

  handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({open:false});
  };

  handleUpdateSort(event){
    const { adminUpdatePreorderExhibitions, adminLoadPreorderExhibitions, adminPreorderExhibitions } = this.props;
    const { sorting } = this.state;

    adminUpdatePreorderExhibitions(sorting).then(() => {
      adminLoadPreorderExhibitions().then(() => {
        this.setState({open:true});
      });
    });

  }

  handleUpdate(event) {
    const { onUpdate, adminPreorderExhibitions } = this.props;
    const { sorting } = this.state;
    const ordered = sorting.slice();
    const { oldIndex, newIndex } = event;

    ordered.splice(newIndex, 0, ordered.splice(oldIndex, 1)[0]);
    ordered.forEach((el, index) => {
      el.sorting = index;
    });

    this.setState(prevState => ({
      sorting : [...ordered]
    }));

  }

  render() {
    const {
      adminPreorderExhibitions,
      pagination: { isFetching },
    } = this.props;

    const {
      sortable,
      sorting,
      open
    } = this.state;
    const _adminPreorderExhibitions = adminPreorderExhibitions.map((el) => ({
      ...el,
      fromNow : (moment().isAfter(moment(el.preorderEndDate*1000)) ? -1 :
                 moment().isBefore(moment(el.preorderStartDate*1000)) ? 1 : 0)
    }));

    const preorderExhibitionList = _adminPreorderExhibitions.sort(
      (a, b) => {
        if(!a.visible && b.visible){
          return 1;
        }
        if(!b.visible && a.visible){
          return -1;
        }
        if(a.sorting !== b.sorting){
          return a.sorting - b.sorting;
        }else{
          return b.created - a.created;
        }
      },
    );

    return (
      <div style={styles.container}>
        <Container sx={styles.top_container}>
        <Stack
          sx={styles.top_header}
          justifyContent="flex-start"
          alignItems="center"
          direction="row" spacing={2}>
          <Stack
            sx={styles.top_container_box}
            direction="column" spacing={2}>
            <Typography sx={styles.top_header_title}>
              프리오더 수
            </Typography>
            <Typography sx={styles.top_header_value}>
              {preorderExhibitionList.length}
            </Typography>
          </Stack>
          <Stack
            sx={styles.top_container_box}
            direction="column" spacing={2}>
            <Typography sx={styles.top_header_title}>
              활성화된 프리오더
            </Typography>
            <Typography sx={styles.top_header_value}>
              {preorderExhibitionList.filter((el) => el.visible).length}
            </Typography>
          </Stack>
          <Stack
            direction="column" spacing={1}>
            <FormControlLabel
              control={
                <Switch
                  checked={sortable}
                  onChange={this.handleSortable} />}
              label="순서 변경" />
              {sortable &&
              <Button
                onClick={this.handleUpdateSort}
                variant="outlined">저장하기</Button>}
          </Stack>
        </Stack>


        <Grid sx={{width:'100%'}} container spacing={2}>
        {sortable ?  (
          <Grid container spacing={2}>
          <Sortable
            md={12}
            style={{
              width:'100%',
              display:'flex',
              flexWrap: "wrap",
              flexDirection: "row"
            }}
            option={{ handle: '.handle' }}
            onChange={(order, sortable, event) => this.handleUpdate(event)}
          >

          {sorting.length > 0
            && sorting.map(preorderExhibition => (
              <PreorderExhibition
                isSortable={true}
                preorderExhibition={preorderExhibition}
                key={`preorderExhibition_${preorderExhibition.id}`}
              />
            ))}

          </Sortable>
          </Grid>
        )
        : (preorderExhibitionList.length > 0
          && preorderExhibitionList.map(preorderExhibition => (
            <PreorderExhibition
              isSortable={false}
              preorderExhibition={preorderExhibition}
              key={`preorderExhibition_${preorderExhibition.id}`}
            />
          )))}
        </Grid>
        {isFetching && <Spinner />}
        <Link
          style={styles.add_link}
          to="/admin/preorderExhibitions/new">
          <Button
            size="large"
            sx={styles.add_button}
            variant="outlined">
            프리오더 추가
          </Button>
        </Link>
        </Container>

        <Snackbar open={open} autoHideDuration={6000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="success" sx={{ width: '100%' }}>
            성공적으로 순서가 변경되었습니다
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

PreorderExhibitionPage.propTypes = {
  adminLoadPreorderExhibitions: PropTypes.func.isRequired,
  adminUpdatePreorderExhibitions: PropTypes.func.isRequired,
  adminPreorderExhibitions: PropTypes.array,
  // pagination: PropTypes.object,
};

PreorderExhibitionPage.defaultProps = {
  adminPreorderExhibitions: {},
};

export const mapStateToProps = state => ({
  ...loadEntities(state, 'adminPreorderExhibitions', 'adminPreorderExhibitions'),
});

export default connect(
  mapStateToProps,
  adminActions,
)(PreorderExhibitionPage);
