/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheet, Text, View, TouchableHighlight,
  Dimensions,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
// import uniqueId from 'react-html-id';

import '../css/carousel.css';

import CloudinaryImage from './CloudinaryImage';
import CloudinaryVideo from './CloudinaryVideo';
import ProfileHeader from './ProfileHeader';
import Products from './Products';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';
import Constants from '../constants/Constants';

const windowLayout = {
  width: Dimensions.get('window').width,
};

const stylesHtml = {
  postContainer: {
    position: 'relative',
    height: 0,
    paddingTop: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  carouselIndicatorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    padding: 0,
  },
  mediaContainer: {
    position: 'relative',
    height: 0,
    paddingTop: '100%',
  },
  mediaImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  mediaVideo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
};

const styles = StyleSheet.create({
  container: {
  },
  productContainer: {
    marginHorizontal: -1 * Constants.viewHorizontalPadding,
  },
  headerContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  postContainer: {
    position: 'relative',
    height: 0,
    paddingTop: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  promotionLabelContainer: {
    position: 'absolute',
    bottom: 8,
    left: 8,
    zIndex: 997,
    borderRadius: 10,
    paddingHorizontal: 7,
    paddingTop: 4,
    paddingBottom: 5,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  promotionLabelText: {
    color: 'white',
    fontSize: 9,
    lineHeight: 11,
    letterSpacing: 0,
    ...Styles.SFUITextRegular,
  },
  carouselContainerStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  buttonDescriptionContainer: {
    marginTop: 11,
  },
  buttonDescriptionImage: {
    width: 24,
    height: 24,
  },
  descriptionBackdrop: {
    position: 'absolute',
    zIndex: 998,
    backgroundColor: '#000',
    opacity: 0.5,
    top: 0,
    bottom: 0,
    start: 0,
    end: 0,
    alignItems: 'flex-start',
  },
  descriptionTextContainer: {
    position: 'absolute',
    zIndex: 999,
    top: 0,
    left: 0,
    padding: 20,
  },
  descriptionText: {
    lineHeight: 22,
    color: 'white',
    fontSize: 16,
    ...Styles.appleSDGothicNeoSemiBold,
  },
  iconContainer: {
    flex: 1,
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  },
  postLinkButton: {
    position: 'absolute',
    left: 5,
    bottom: 15,
    width: 30,
    height: 30,
    borderRadius: 14,
    backgroundColor: 'gray',
    overflow: 'hidden',
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});


function renderMedia(media, displayAspectRatio) {
  const mediaContainerStyle = {
    ...stylesHtml.mediaContainer,
    paddingTop: `${1 / displayAspectRatio * 100}%`,
  };

  return (
    <div
      key={`image-${media.publicId}`}
      style={mediaContainerStyle}
    >
      {media.resourceType === 'video' ? (
        <CloudinaryVideo
          publicId={media.publicId}
          style={stylesHtml.mediaVideo}
        />
      ) : (
        <CloudinaryImage
          publicId={media.publicId}
          style={stylesHtml.mediaImage}
        />
      )}
    </div>
  );
}


const onPressShowDescription = (flagShowDescription, setFlagShowDescription) => {
  setFlagShowDescription(!flagShowDescription);
};


const renderProducts = (
  post, products, onPressProduct, enableProductLabelSimilar, enableProductLabelHeartitLogo,
  enableProductDeemedSeasonOut,
) => {
  const items = [
    ...products.filter(product => !product.isRecommend),
    ...products.filter(product => product.isRecommend),
  ];
  return (
    <View style={styles.productContainer}>
      <Products
        horizontal
        products={items}
        post={post}
        onPressProduct={onPressProduct}
        ListHeaderComponent={<View style={{ width: Constants.viewHorizontalPadding }} />}
        ListFooterComponent={<View style={{ width: Constants.viewHorizontalPadding }} />}
        enableProductLabelSimilar={enableProductLabelSimilar}
        enableProductLabelHeartitLogo={enableProductLabelHeartitLogo}
        enableProductDeemedSeasonOut={enableProductDeemedSeasonOut}
      />
    </View>
  );
};

const indicatorStyles = {
  backgroundColor: 'rgba(0,0,0,0.1)',
  width: 24,
  height: 2,
  display: 'inline-block',
  margin: '0 2px 0 2px',
};

function PostContent(props) {
  const {
    postId,
    flagShowDescription,
    isDescriptionExist,
    description,
    promotion,
    media,
    displayAspectRatio,
  } = props;

  const hideSwipeIndicator = (media.length < 2);

  const [currentSlide, setCurrentSlide] = useState(0);

  const updateCurrentSlide = index => {
    let newIndex = index;
    if (!newIndex && newIndex !== 0) {
      newIndex = 0;
    }

    if (currentSlide !== newIndex) {
      setCurrentSlide(newIndex);
    }
  };


  const renderIndicator = (m, index) => {
    if (index === currentSlide) {
      return (
        <li
          key={`indicator-${m.publicId}`}
          style={{ ...indicatorStyles, backgroundColor: '#000' }}
        />
      );
    }
    return (
      <li
        style={indicatorStyles}
        value={index}
        key={`indicator-${m.publicId}`}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        role="button"
        tabIndex={0}
        title={`${index + 1}`}
        aria-label={`${index + 1}`}
      />
    );
  };

  const postContainerStyle = {
    ...stylesHtml.postContainer,
    paddingTop: `${1 / displayAspectRatio * 100}%`,
  };

  const className = `postContainer${postId}`;
  const imageHeight = (Math.min(windowLayout.width, Constants.maxWidth) - 2 * Constants.viewHorizontalPadding)
    / displayAspectRatio;
  const style = document.createElement('style');
  document.head.appendChild(style);
  style.sheet.insertRule(`.${className} .carousel .slider li { background: none; height: ${imageHeight}px; }`, 0);

  return (
    <React.Fragment>
      <div
        style={postContainerStyle}
        className={className}
      >
        {(flagShowDescription && isDescriptionExist()) && (
          <React.Fragment>
            <View style={styles.descriptionBackdrop}></View>
            <View
              style={styles.descriptionTextContainer}
            >
              <Text
                style={styles.descriptionText}
                numberOfLines={8}
                ellipsizeMode="tail"
              >
                {description}
              </Text>
            </View>
          </React.Fragment>
        )}

        {promotion && (
          <View style={styles.promotionLabelContainer}>
            <Text style={styles.promotionLabelText}>Sponsored</Text>
          </View>
        )}

        <View style={styles.carouselContainerStyle}>
          <Carousel
            style={styles.carouselStyle}
            renderThumbs={() => []}
            renderIndicator={() => null}
            statusFormatter={() => ''}
            selectedItem={idx => setCurrentSlide(idx)}
            onChange={updateCurrentSlide}
            renderArrowPrev={() => null}
            renderArrowNext={() => null}
          >
            {media.map(m => renderMedia(m, displayAspectRatio))}
          </Carousel>
        </View>
      </div>
      <ul style={(hideSwipeIndicator) ? { display: 'none' } : stylesHtml.carouselIndicatorContainer}>
        {media.map((m, idx) => renderIndicator(m, idx))}
      </ul>
    </React.Fragment>
  );
}

function isPostEqual(prevProps, nextProps) {
  return prevProps.post._id === nextProps.post._id;
}

const Post = React.memo(function PostRaw(props) {
  const {
    disableHeartistPress, onPressHeartist, onPressProduct, post, postDate,
    enableProductLabelSimilar, enableProductLabelHeartitLogo, enableProductDeemedSeasonOut,
  } = props;
  const {
    products, media, owner, description = null, promotion,
    displayAspectRatio = 1,
    _id,
  } = post;
  const [flagShowDescription, setFlagShowDescription] = useState(false);

  const isDescriptionExist = () => description !== null && description.length > 0;

  // eslint-disable-next-line global-require
  let descriptionButtonImage = require('../../../app/assets/images/icCaptionNormal.png').default;
  if (flagShowDescription) {
    // eslint-disable-next-line global-require
    descriptionButtonImage = require('../../../app/assets/images/icCaptionSelected.png').default;
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <View style={styles.touchEffectContainer}>
          <TouchableHighlight
            underlayColor={Colors.touchEffectColorWhite}
            onPress={() => onPressHeartist && onPressHeartist(post.owner._id, post.owner.username)}
            style={styles.touchEffect}
            disabled={disableHeartistPress}
          >
            <ProfileHeader
              date={postDate(post)}
              username={owner.username}
            />
          </TouchableHighlight>
        </View>

        {isDescriptionExist() && (
          <View style={styles.touchEffectContainer}>
            <TouchableHighlight
              style={[styles.buttonDescriptionContainer, styles.touchEffect]}
              underlayColor={Colors.touchEffectColorWhite}
              onPress={() => {
                onPressShowDescription(flagShowDescription, setFlagShowDescription);
              }}
            >
              <img
                alt=""
                styles={styles.buttonDescriptionImage}
                src={descriptionButtonImage}
              />
            </TouchableHighlight>
          </View>
        )}
      </View>

      <PostContent
        postId={_id}
        flagShowDescription={flagShowDescription}
        description={description}
        isDescriptionExist={isDescriptionExist}
        promotion={promotion}
        media={media}
        displayAspectRatio={displayAspectRatio}
      />

      {products && renderProducts(post, products, onPressProduct, enableProductLabelSimilar, enableProductLabelHeartitLogo, enableProductDeemedSeasonOut)}

    </View>
  );
}, isPostEqual);


Post.propTypes = {
  enableProductLabelSimilar: PropTypes.bool,
  enableProductLabelHeartitLogo: PropTypes.bool,
  enableProductDeemedSeasonOut: PropTypes.bool,
};

Post.defaultProps = {
  enableProductLabelSimilar: false,
  enableProductLabelHeartitLogo: false,
  enableProductDeemedSeasonOut: false,
};

export default Post;
