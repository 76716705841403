import * as React from "react"
import Svg, { Path } from "react-native-svg"

function HeaderBagIcon(props) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M3.825 7.018a.25.25 0 01.249-.268h15.852a.25.25 0 01.25.268l-.858 12a.25.25 0 01-.25.232H4.932a.25.25 0 01-.25-.232l-.856-12z"
        stroke="#000"
        strokeWidth={1.5}
      />
      <Path
        d="M8 7V6a4 4 0 014-4v0a4 4 0 014 4v1"
        stroke="#000"
        strokeWidth={1.5}
      />
    </Svg>
  )
}

export default HeaderBagIcon
