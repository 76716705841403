const SORTING_RULE_PRODUCT_LIST = [
  { id: '1002', value: 'popularity', name: '인기순' },
  { id: '1001', value: 'g.regDt desc', name: '최신순' },
  { id: '1003', value: 'g.orderGoodsCnt desc', name: '누적판매순' },
  { id: '1004', value: 'g.goodsPrice asc', name: '낮은가격순' },
  { id: '1005', value: 'g.goodsPrice desc', name: '높은가격순' },
];

const SORTING_RULE_EXHIBITION = [
  { id: '1002', value: 'popularity', name: '인기순' },
  { id: '1001', value: 'g.regDt desc', name: '최신순' },
  { id: '1004', value: 'g.goodsPrice asc', name: '낮은가격순' },
  { id: '1005', value: 'g.goodsPrice desc', name: '높은가격순' },
]

const SORTING_RULE_STYLE_POST_LIST = [
  { id: '2001', value: 'new', name: '최신순' },
  { id: '2002', value: 'view', name: '조회순' },
  { id: '2003', value: 'recommend', name: '추천순' },
  { id: '2004', value: 'reply', name: '댓글순' },
];


export default {
  SORTING_RULE_PRODUCT_LIST,
  SORTING_RULE_EXHIBITION,
  SORTING_RULE_STYLE_POST_LIST,
};
