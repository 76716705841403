import * as React from 'react';

function FilterIcon(props) {
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M20 5.357l-6 7.928V20l-4-2v-4.715L4 5.357h16z"
        stroke="#000"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default FilterIcon;
