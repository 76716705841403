import React from 'react';

import {
  StyleSheet, View, Text,
} from 'react-native';
import { TouchableHighlightWithContainer } from '../../../components-v2';
import { Styles } from '../../../constants';
import { ChevronDownIcon } from '../../../icons-v2';


const styles = StyleSheet.create({
  container: {
  },
  titleContainer: {
    paddingVertical: 24.5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleText: {
    ...Styles.pretendard400,
    fontSize: 16,
    lineHeight: 19.2,
  },
  bodyContainer: {
    marginBottom: 30,
  },
  sectionContentsText: {
    ...Styles.pretendard400,
    fontSize: 15,
    lineHeight: 22,
  },
});

function removeHTMLTags(exchangeInfo) {
  if (!exchangeInfo) {
    return '';
  }

  let result = exchangeInfo.replace(/<p>/gi, '');
  result = result.replace(/<\/p>/gi, '\n');
  result = result.replace(/&nbsp;/gi, ' ');
  result = result.replace(/^\s+|\s+$/g, '');

  return result;
}

class ShippingAndRefund extends React.PureComponent {

  state = {
    opened: false,
    updated : false
  }

  render() {
    const {
      product: { infoExchange='' },
      containerStyle,
      collapsed
    } = this.props;

    const {
      opened,
      updated
    } = this.state;

    if(collapsed){
      if(!updated){
        this.setState({opened: collapsed, updated:true});
      }
    }

    const titleIconStyle = {};
    const onPress = () => {
      this.setState({ opened: !opened });
    }

    if (opened) {
      titleIconStyle.transform = [
        { scaleY: -1 }
      ];
    }

    return (
      <View style={[styles.container, containerStyle]}>
        <TouchableHighlightWithContainer
          onPress={onPress}
        >
          <View style={styles.titleContainer}>
            <Text style={styles.titleText}>배송 및 반품 안내</Text>
            <ChevronDownIcon style={[styles.titleIcon, titleIconStyle]} />
          </View>
        </TouchableHighlightWithContainer>
        { opened && (
          <View style={styles.bodyContainer}>
            <Text style={styles.sectionContentsText}>{ removeHTMLTags(infoExchange) }</Text>
          </View>
        )}
      </View>
    );
  }
}

export default ShippingAndRefund;
