import assign from 'lodash/assign';
import get from 'lodash/get';
import merge from 'lodash/merge';
import forEach from 'lodash/forEach';
import set from 'lodash/set';
import union from 'lodash/union';

import { combineReducers } from 'redux';

function auth(state = {}, action) {
  if (action.type === 'LOGOUT') {
    return {};
  }
  if (action.payload && action.payload.auth && action.payload.auth.token) {
    return assign({}, state, action.payload.auth);
  }
  return state;
}

// Updates an entity cache in payload to any action with payload.entities.
function entities(
  state = {
    heartists: {},
    heartistsPicks: {},
    histories: {},
    users: {},
    usersPostsCount: {},
    pushHistoryCount: { count: 0 },
    posts: {},
    stylePosts: {},
    stylePostsCount: {},
    followingStylePosts: {},
    promotions: {},
    collections: {},
    comments: {},
    shortens: {},
    editorials: {},
    adminEditorials: {},
    mdCurations: {},
    adminMdCurations: {},
    categorySales: {},
    adminCategorySales: {},
    heartitCurations: {},
    adminHeartitCurations: {},
    preorderExhibitions: {},
    adminPreorderExhibitions: {},
    banners: {},
    partnerShippingProducts: [],
    homeDailyPopularProducts: [],
    homeWeeklyPopularProducts: [],
    homeNewProducts: [],
    homeMdCurations: [],
    homeHeartitCurations: [],
    homeCategorySales: [],
    homeTimeSales: [],
    shop: {
      products: {},
      categories: [],
      cart: {
        items: [],
        deliveries: {},
      },
      token: []
    },
    userOrderList: {},
    orderDetails: {},
    search: {
      mostSearchedKeywords: [],
    },
    shopProducts: {},
    shopProductsRelatedPosts: {},
    likedProducts: {},
    shippingAddresses: {},
    userReviewList: {},
    faqList: {},
    marketingPopups: {},
    shopBrandList: [],
  },
  action
) {
  const nextState = assign({}, state);
  forEach(get(action, 'payload.entities'), (val, key) => {
    if (get(action, 'meta.entities.update')) {
      const next = assign({}, state[key]);
      nextState[key] = next;
      forEach(val, (entity, id) => {
        next[id] = merge({}, next[id], entity);
      });
    } else {
      nextState[key] = assign({}, state[key], val);
    }
  });
  return nextState;
}

// Updates the pagination data for different actions.
function pagination(state = {}, action) {
  function mergeId(type, curIds, result) {
    if (result) {
      const newIds = Array.isArray(result) ? result : [result];
      if (type === 'PREPEND') {
        return union(newIds, curIds);
      }
      if (type === 'REFRESH') {
        return newIds;
      }
      return union(curIds, newIds);
    }
    return Array.isArray(curIds) ? curIds : [];
  }

  if (action.pagination) {
    const { key, type } = action.pagination;
    const current = get(state, key, {});

    const { result, nextPageUrl, countResult, isFetching } = action.payload;
    const nextIds = mergeId(type, current.ids, result);

    return set(merge({}, state), key, {
      ids: nextIds,
      nextPageUrl,
      countResult,
      isFetching: isFetching || false,
    });
  }
  return state;
}

function viewModes(state = { initial: {} }, action) {
  if (action.type === 'POSTS_VIEW_MODE') {
    return assign({}, state, { [action.page]: action.viewMode });
  }
  return state;
}

function admin(state = { postClickCount: {} }, action) {
  const nextState = assign({}, state);
  if (action.type === 'GET_POST_CLICK_COUNT') {
    forEach(get(action.payload, 'admin.postClickCount'), (clickCounts, postId) => {
      const key = `postClickCount.${postId}`;
      const currentClickCounts = get(state, key);
      const nextClickCounts = assign({}, currentClickCounts);
      forEach(clickCounts, (postClickCount, date) => {
        nextClickCounts[date] = postClickCount;
      });
      set(nextState, key, nextClickCounts);
    });
  }
  if (action.type === 'GET_CLICK_COUNT_OVERALL') {
    forEach(get(action.payload, 'admin.clickCountOverall'), (clickCounts, kind) => {
      const key = `clickCountOverall.${kind}`;
      const currentClickCounts = get(state, key);
      const nextClickCounts = assign({}, currentClickCounts);
      forEach(clickCounts, (clickCount, date) => {
        nextClickCounts[date] = clickCount;
      });
      set(nextState, key, nextClickCounts);
    });
  }
  if (action.type === 'GET_PAGEVIEW_COUNT_OVERALL') {
    forEach(get(action.payload, 'admin.pageviewCountOverall'), (pageviewCounts, kind) => {
      const key = `pageviewCountOverall.${kind}`;
      const currentPageviewCounts = get(state, key);
      const nextPageviewCounts = assign({}, currentPageviewCounts);
      forEach(pageviewCounts, (pageviewCount, date) => {
        nextPageviewCounts[date] = pageviewCount;
      });
      set(nextState, key, nextPageviewCounts);
    });
  }
  if (action.type === 'GET_SNAPSHOT_COUNT') {
    forEach(get(action.payload, 'admin.snapshotCount'), (snapshotCounts, kind) => {
      const key = `snapshotCount.${kind}`;
      const currentSnapshotCounts = get(state, key);
      const nextSnapshotCounts = assign({}, currentSnapshotCounts);
      forEach(snapshotCounts, (snapshotCount, date) => {
        nextSnapshotCounts[date] = snapshotCount;
      });
      set(nextState, key, nextSnapshotCounts);
    });
  }

  if (action.type === 'GET_GODOMALL_REQUEST_COUNT') {
    forEach(get(action.payload, 'admin.godomallRequest'), (godomallRequestCounts, date) => {
      const key = `godomallRequest`;
      const currentGodomallRequestCounts = get(state, key);
      const nextGodomallRequestCounts = assign({}, currentGodomallRequestCounts);

      nextGodomallRequestCounts[date] = godomallRequestCounts;
      set(nextState, key, nextGodomallRequestCounts);
    });
  }

  if (action.type === 'GET_ACTIVE_USERS') {
    set(nextState, 'userStatistics', get(action.payload, 'admin.userStatistics'));
  }
  if (action.type === 'ADMIN_GET_PRODUCT_SUMMARY') {
    set(nextState, 'productSummary', get(action.payload, 'admin.productSummary'));
  }
  return nextState;
}

// settings initial state: offState
function settings(state = { historySwitch: true }, action) {
  if (action.type === 'HISTORY_SWITCH_ON') {
    state.historySwitch = true;
  }
  if (action.type === 'HISTORY_SWITCH_OFF') {
    state.historySwitch = false;
  }
  return state;
}

function fetchStatus(state = {}, action) {
  if (action.type === 'GET_PRODUCT_LIST_CATEGORY') {
    if (action.payload.isFetching) {
      state.isShopProductListCategoryFetching = true;
    } else {
      state.isShopProductListCategoryFetching = false;
    }
  }
  return state;
}

function globalLayoutInfo(state = {}, action) {
  if (action.type === 'UPDATE_GLOBAL_LAYOUT_INFO') {
    return assign({}, state, { [action.name]: action.value });
  }

  return state;
}

function routerHistory(state = {}, action) {
  if (action.type === 'UPDATE_ROUTER_HISTORY') {
    switch (action.mode) {
      case 'replace':
        state.routerHistory = action.value;
        break;

      default:
      case 'append':
        if (!state.routerHistory) {
          state.routerHistory = [ action.value ];
        } else {
          const [...newRouterHistory] = state.routerHistory;
          newRouterHistory.push(action.value);
          state.routerHistory = newRouterHistory;
        }
        break;
    }
  }

  return state;
}

const rootReducer = combineReducers({
  auth,
  entities,
  pagination,
  viewModes,
  admin,
  settings,
  fetchStatus,
  globalLayoutInfo,
  routerHistory,
});

export default (state = {}, action) => {
  if (action.type === 'RESET') {
    return rootReducer({}, action);
  }
  if (action.type === 'CLEARED_ALL_HISTORY') {
    state.entities.histories = {};
    state.pagination.histories.nextPageUrl = null;
    return rootReducer(state, action);
  }
  return rootReducer(state, action);
};
