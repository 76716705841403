import React from 'react'

import { 
  StyleSheet, View, ScrollView, Text, Alert,
} from 'react-native';

import { Constants, Styles } from '../../../constants';
import { TouchableHighlightWithContainer } from '../..';

import FilterHeader from './FilterHeader';
import BottomSheetModal from '../../BottomSheetModal';


const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexGrow: 1,
  },
  bodyContainer: {
    flex: 1,
    flexGrow: 1,
  },
  bottomContainer: {
    height: 60,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#000000',
  },
  applyButton: {
    height: 60,
    paddingTop: 20,
    paddingBottom: 21,
    backgroundColor: '#000000',
    alignItems: 'center',
  },
  applyButtonText: {
    fontSize: 16,
    lineHeight: 19.2,
    color: '#ffffff',
    ...Styles.pretendard600,
  }
});



class FilterBaseModal extends BottomSheetModal {

  constructor(props) {
    super(props);
  }

  // TODO: Implement feature
  title = '';
  leftButtonTitle = null;
  isBottomVisible = true;


  renderHeader = () => {
    const {
      setModalProps
    } = this.props;

    const title = this.title;
    const leftButtonTitle = this.leftButtonTitle;
    const headerProps = {
      title,
      setModalProps,
    };

    if (leftButtonTitle) {
      headerProps.leftButtonTitle = leftButtonTitle;
    }

    return (
      <FilterHeader
        title={title}
        onPressLeftButton={() => this.onPressHeaderLeftButton()}
        {...headerProps}
        />
    )
  }

  onPressHeaderLeftButton() {
    const {
      modalProps,
      setModalProps,
    } = this.props;

    const {
      historyStack=[],
    } = modalProps();

    const children = historyStack.pop();

    setModalProps({
      children,
      historyStack,
    });
  }

  onPressApplyButton() {
    Alert.alert('onPressApplyButton');
  }

  renderBody() {
    const {
      clothesSizeItems,
      shoesSizeItems,
    } = this.state;

    return (
      <ScrollView style={styles.bodyContainer}>
      </ScrollView>
    )
  }

  getModalState() {
    // override됨
    return {
      filterItemList: []
    }
  }

  renderBottom() {
    if (!this.isBottomVisible) {
      return null;
    }
    const { filterItemList = [] } = this.getModalState();

    return (
      <View style={styles.bottomContainer}>
        {this.leftButtonTitle && (
          <TouchableHighlightWithContainer
            containerStyle={[{ flexGrow: 1 }, (filterItemList.length == 0) ? {opacity: 0.5}: {}]}
            style={styles.applyButton}
            onPress={() => this.onPressHeaderLeftButton()}
            disabled={filterItemList.length == 0}
            >
            <Text style={styles.applyButtonText}>초기화</Text>
          </TouchableHighlightWithContainer>
        )}
        <TouchableHighlightWithContainer
          containerStyle={{ flexGrow: 1 }}
          style={styles.applyButton}
          onPress={() => this.onPressApplyButton()}
          >
          <Text style={styles.applyButtonText}>필터 적용하기</Text>
        </TouchableHighlightWithContainer>
      </View>
    );
  }

  renderContent() {
    return (
      <View style={styles.container}>
        <View
          style={styles.bodyContainer}>
          {this.renderBody()}
        </View>
        {this.renderBottom()}
      </View>
    );
  }
}

export default FilterBaseModal;
