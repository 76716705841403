import React from 'react';
import {
  StyleSheet, Text, View,
} from 'react-native';

import { connect } from 'react-redux';
import { actions } from 'shared';
import NumberFormat from 'react-number-format';

import {
  Styles, Constants,
} from '../../../constants';

import {
  Button,
} from '../../../components-v2';


const styles = StyleSheet.create({
  container: {
  },
  sectionHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  sectionHeaderTitleText: {
    color: '#000000',
    lineHeight: 19.2,
    height: 19,
    fontSize: 16,
    ...Styles.pretendard600,
    marginTop: 12.5,
    marginBottom: 12,
  },
  sectionHeaderButtonContainer: {
    marginTop: 13.5,
    marginBottom: 13,
  },
  sectionHeaderButton: {
    borderWidth: 0,
    borderRadius: Constants.touchEffectWidth,
    padding: Constants.touchEffectWidth,
    margin: -1 * Constants.touchEffectWidth,
    marginEnd: 1 -1 * Constants.touchEffectWidth,
  },
  sectionHeaderButtonText: {
    color: '#000000',
    fontSize: 14,
    lineHeight: 16.8,
    height: 17,
    ...Styles.pretendard400,
  },
  sectionContentButtonContainer: {
    marginHorizontal: 20,
    marginVertical: 10,
  },
  sectionContentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  sectionContentSubContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    paddingVertical: 11,
  },
  sectionContentSubContainerLeftBorder: {
    borderTopWidth: 0.5,
    borderLeftWidth: 0.5,
    borderBottomWidth: 0.5,
    borderColor: '#000',
  },
  sectionContentSubContainerCenterBorder: {
    borderWidth: 0.5,
    borderColor: '#000',
  },
  sectionContentSubContainerRightBorder: {
    borderTopWidth: 0.5,
    borderRightWidth: 0.5,
    borderBottomWidth: 0.5,
    borderColor: '#000',
  },
  sectionContentValueButton: {
    borderWidth: 0,
    borderRadius: Constants.touchEffectBorderRadius,
    padding: Constants.touchEffectWidth,
    margin: -1 * Constants.touchEffectWidth,
  },
  sectionContentValue: {
    fontSize: 16,
    height: 19,
    lineHeight: 19.2,
    color: '#000000',
    marginTop: 3,
    ...Styles.pretendard600,
  },
  sectionContentText: {
    fontSize: 13,
    lineHeight: 15.6,
    height: 15,
    color: '#000',
    opacity: 0.5,
    ...Styles.pretendard400,
  },
});


class UserInfoSection extends React.Component {
  isLoggedIn(props = null) {
    if (props === null) {
      const { auth } = this.props;
      // eslint-disable-next-line no-prototype-builtins
      return auth.hasOwnProperty('_id');
    }
    // eslint-disable-next-line no-prototype-builtins
    return props.auth.hasOwnProperty('_id');
  }

  getLevel() {
    const { auth: { summary } } = this.props;
    if (this.isLoggedIn() && summary) {
      return summary.levelName;
    }

    return '-';
  }

  getCouponCount() {
    const { auth: { summary } } = this.props;
    if (this.isLoggedIn() && summary) {
      return summary.couponCount;
    }

    return '-';
  }

  getPoint() {
    const { auth: { summary } } = this.props;
    if (this.isLoggedIn() && summary) {
      return summary.mileagePoint;
    }

    return '-';
  }

  render() {

    const { router, auth } = this.props;
    const username = auth.name || auth.username;

    return (
      <View style={styles.container}>
        <View style={styles.sectionHeaderContainer}>
          <Text style={styles.sectionHeaderTitleText}>
            {username}
          </Text>
          <View style={styles.sectionHeaderButtonContainer}>
            <Button
              containerStyle={styles.sectionHeaderButton}
              titleStyle={styles.sectionHeaderButtonText}
              title="회원정보수정"
              onPress={() => router.push('/accounts/edit')}
            />
          </View>
        </View>

        <View style={styles.sectionContentButtonContainer}>
          <Button
            containerStyle={{ margin: 0, padding:0, borderWidth: 0}}
            onPress={() => { router.push('/accounts/memberInfo')}}
            innerComponent={(
              <View style={styles.sectionContentContainer}>
                <View style={[styles.sectionContentSubContainer, styles.sectionContentSubContainerLeftBorder]}>
                  <Text style={styles.sectionContentText}>
                    회원 등급
                  </Text>
                  <Text style={styles.sectionContentValue}>
                    {this.getLevel()}
                  </Text>
                </View>

                <View style={[styles.sectionContentSubContainer, styles.sectionContentSubContainerCenterBorder]}>
                  <Text style={styles.sectionContentText}>
                    쿠폰
                  </Text>
                  <Text style={styles.sectionContentValue}>
                    {this.getCouponCount()}
                  </Text>
                </View>

                <View style={[styles.sectionContentSubContainer, styles.sectionContentSubContainerRightBorder]}>
                  <Text style={styles.sectionContentText}>
                    포인트
                  </Text>
                  <NumberFormat
                    value={this.getPoint()}
                    displayType="text"
                    thousandSeparator
                    allowEmptyFormatting
                    defaultValue="-"
                    decimalScale={0}
                    renderText={value => {
                      return (
                        <Text style={styles.sectionContentValue}>
                          {value}
                        </Text>
                      );
                    }}
                  />
                </View>
              </View>
            )}
            />
        </View>
      </View>
    );
  }
}


export default connect(
  state => ({
    auth: state.auth,
  }),
  actions,
)(UserInfoSection);
