import React from 'react';
import { Dimensions, Platform, View, Image, Text, StyleSheet, FlatList } from 'react-native';
import { connect } from 'react-redux';

import { actions } from 'shared';

import Button from '../../../components-v2/Button';
import FeedUploadImageSelect from '../../../icons-v2/FeedUploadImageSelectIcon';
import { Constants, Styles, Colors } from '../../../constants';


const { width } = Dimensions.get('window');
const itemWidth = (width - 15.75 * 2) / 3;


class StyleUploadAddImageScreen extends React.Component {
  state = {
    selectedMedia: [],
    selectedAlbum: null,
    albums: [],
    mediaList: [],
    pageInfo: null,
    count: 20,
  };

  loadData = async (album) => {
    const { count, pageInfo } = this.state;
    if (pageInfo && !pageInfo.has_next_page) {
      return;
    }

    let params = {
      first: count,
      include: ['filename', 'fileSize', 'imageSize'],
    };
    if (!!album) {
      params['groupName'] = album.title;
      if (Platform.OS == "android" && album.title == "최근 항목") {
        params['groupTypes'] = "All";
        delete params['groupName'];
      } else {
        params['groupTypes'] = Platform.select({
          ios: album.type,
          android: 'Album',
        });
      }
    }
    if (pageInfo) {
      params['after'] = pageInfo.end_cursor;
    }
    console.log(album, params);
    let photos = result.edges;
    // SmartAlbum은 응답에 groupName 필터 걸리지 않은 상태
    if (album.type == "SmartAlbum") {
      photos = photos.filter(p => p.node.group_name == album.title);
    }

    this.setState({
      mediaList: [...this.state.mediaList, ...photos]
    });
  }

  getAlbums = async () => {
    return {};
  }

  changeAlbumTo = (album) => {
    this.setState({
      mediaList: [],
      pageInfo: null,
      selectedAlbum: album
    });
  }

  onPressConfirm = () => {
    const { navigation } = this.props;
    const callback = navigation.getParam('callback');
    callback(this.state.selectedMedia);
    navigation.pop();
  }

  componentDidMount() {
    const {
      navigation,
      logEventPageview,
    } = this.props;

    navigation.setParams({
      onPressConfirm: this.onPressConfirm.bind(this),
      selectedMediaCount: this.state.selectedMedia.length
    })

    this.getAlbums()
      .then((albums) => {
        console.log('getAlbums', albums);
        if (albums.length > 0) {
          this.changeAlbumTo(albums[0]);
        } else {
          this.loadData(null);
        }
      })

    logEventPageview({ page_name: 'StyleUploadAddImage' });
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedAlbum: prevAlbum } = prevState;
    const { selectedAlbum: newAlbum } = this.state;
    if (prevAlbum != newAlbum) {
      this.loadData(newAlbum);
    }
  }

  bootstrapAsync = async () => {
    const { loadCurrentUser, navigation } = this.props;
    const loading = navigation.getParam('loading');

    if (loading) {
      const [bearerToken, _historySwitch] = await Promise.all([
        AsyncStorage.getItem('bearerToken'),
      ]);

      if (bearerToken) {
        try {
          await loadCurrentUser(bearerToken);
        } catch (err) {
          navigation.navigate(loading);
        }
      } else {
        navigation.navigate(loading);
      }
    }
  };

  renderListHeaderComponent = () => {
    const { selectedAlbum, albums } = this.state;

    return (
      <View style={styles.albumContainer}>
        <FlatList
          horizontal
          data={albums}
          ListHeaderComponent={<View style={{ width: Constants.innerViewHorizontalPadding }}/>}
          ListFooterComponent={<View style={{ width: Constants.innerViewHorizontalPadding }}/>}
          ItemSeparatorComponent={() => (<View style={{ width: 13 }} />)}
          removeClippedSubviews={false}
          showsHorizontalScrollIndicator={false}
          renderItem={({ item, index }) => {
            const isActive = selectedAlbum == item;
            return (
              <Button
                containerStyle={{ padding: 0, borderWidth: 0, borderColor: 'transparent' }}
                innerComponent={(
                  <View style={styles.albumItemContainer}>
                    <View style={styles.albumImageContainer}>
                    </View>
                    <Text style={[styles.albumText, (isActive) ? styles.albumTextActive: {}]}>
                      {item.title}
                    </Text>
                  </View>
                )}
                onPress={() => {
                  if(!isActive) {
                    this.changeAlbumTo(item);
                  }
                }}
              />
            );
          }}
        />
      </View>
    );
  }

  renderMedia = ({ item, index }) => {
    const { navigation } = this.props;
    const { selectedMedia } = this.state;

    const isSelected = selectedMedia.includes(item);
    let onPress = null;
      onPress = () => {
        if (isSelected) {
          this.setState({
            selectedMedia: [...selectedMedia.filter(x => x != item) ]
          });
          navigation.setParams({ selectedMediaCount: selectedMedia.length - 1 });
        } else if (selectedMedia.length < 10) {
          this.setState({
            selectedMedia: [...selectedMedia, item ]
          });
          navigation.setParams({ selectedMediaCount: selectedMedia.length + 1 });
        }
      };

    const imageRatio = item.node.image.width / item.node.image.height;
    return (
      <Button
        containerStyle={[styles.photoContainer, { aspectRatio: imageRatio }]}
        innerComponent={
          <View style={styles.innerPhotoContainer}>
            {isSelected && (
              <View style={styles.selectedPhotoBorder} >
                <View style={styles.selectedPhotoIconContainer}>
                  <FeedUploadImageSelect />
                </View>
              </View>
              )
            }
            <Image
              source={{ uri: item.node.image.uri }}
              style={styles.photo}
            />
          </View>
        }
        onPress={onPress}
      />
    );
  }

  render() {
    const { navigation } = this.props;
    const { selectedAlbum, mediaList } = this.state;

    return (
      <View style={styles.container}>
        <FlatList
          numColumns={3}
          keyExtractor={(item, index) => `media-${index}`}
          ListHeaderComponent={this.renderListHeaderComponent}
          ItemSeparatorComponent={() => (<View style={{ height: 17 }} />)}
          data={mediaList}
          renderItem={this.renderMedia}
          columnWrapperStyle={{ alignItems: 'flex-end', justifyContent: 'space-between' }}
          onEndReached={() => this.loadData(selectedAlbum)}
          onEndReachedThreshold={0.6}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  albumContainer: {
    marginTop: 22,
    marginBottom: 38,
  },
  albumItemContainer: {
    width: 93,
    height: 121,
  },
  albumImageContainer: {
    width: 93,
    height: 93,
    backgroundColor: Colors.defaultGray02,
  },
  albumText: {
    marginTop: 10,
    fontSize: 11,
    lineHeight: 18,
    textAlign: 'left',
    color: 'rgb(131, 131, 133)',
    ...Styles.appleSDGothicNeoBold,
  },
  albumTextActive: {
    color: Colors.defaultGray08,
  },
  photoContainer: {
    padding: 0,
    width: itemWidth,
    borderWidth: 0,
    borderColor: 'transparent',
    backgroundColor: 'transparent',
  },
  innerPhotoContainer: {
    width: '100%',
    height: '100%',
  },
  selectedPhotoBorder: {
    position: 'absolute',
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#000',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    ...Platform.select({
      ios: {
        zIndex: 999,
      },
      android: {
        elevation: 999,
      },
    })
  },
  selectedPhotoIconContainer: {
    position: 'absolute',
    backgroundColor: 'transparent',
    bottom: 10 - 1,
    right: 8.25 - 1,
    ...Platform.select({
      ios: {
        zIndex: 999,
      },
      android: {
        elevation: 999,
      },
    })
  },
  photo: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  headerButtonCountContainer: {
    flexDirection: 'row',
    height: Constants.mainHeaderHeight,
  },
  headerCountContainer: {
    backgroundColor: '#000',
    paddingStart: 8,
    paddingEnd: 8,
    height: 25,
    ...Platform.select({
      ios: {
        paddingTop: 2,
        paddingBottom: 1,
      },
      android: {
        paddingTop: 1,
        paddingBottom: 2,
      },
    }),
    borderRadius: 60,
    marginRight: 5,
    marginTop: 12 - 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerCount: {
    fontSize: 11,
    lineHeight: 22,
    textAlign: 'left',
    color: '#fff',
    ...Styles.appleSDGothicNeoBold,
  },
});

export default connect(
  (state, { navigation }) => {
    return {
      auth: state.auth,
    }
  },
  actions,
)(StyleUploadAddImageScreen);
