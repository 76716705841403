exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".order_container_3fvn8vs8Mh{\n  position:relative;\n  width:100%;\n  min-height:calc(100vh - 66px);\n}\n.blank_container_3nhpwClS6x{\n  position:absolute;\n  top:calc(50vh - 18px);\n  left:0px;\n  right:0px;\n  width:100%;\n}\n", ""]);

// exports
exports.locals = {
	"order_container": "order_container_3fvn8vs8Mh",
	"blank_container": "blank_container_3nhpwClS6x"
};