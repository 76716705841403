import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import dayjs from 'dayjs';
import { loadEntities } from 'react-shared';
import * as adminActions from 'react-shared/adminActions';

const styles = {
  container: {
    border: '5px solid #fff',
    marginBottom: '150px',
    marginTop: '95px',
    padding: '0px 15px',
    textAlign: 'center',
  },
  headerLeft: {
    display: 'inline-block',
    float: 'left',
    height: '40px',
    marginBottom: '12.5px',
    textAlign: 'left',
    textTransform: 'lowercase',
    width: '100%',
  },
  headerRight: {
    display: 'inline-block',
    height: '40px',
    lineHeight: '40px',
    // marginBottom: '12.5px',
    textAlign: 'right',
    width: '100%',
  },
  date: {
    color: '#909090',
    fontSize: '13px',
  },
  mainImage: {
    marginBottom: '14px',
    width: '100%',
  },
  // abstract: {
  //   color: 'black',
  //   fontSize: '10px',
  //   textAlign: 'left',
  //   textDecoration: 'none',
  // },
  link: {
    color: 'black',
    textAlign: 'right',
  },
  offline: {
    color: '#000080',
  },
  online: {
    color: '#FF0000',
  },
};

// export default
class Banner extends Component {
  static renderEditButton(bannerId) {
    return (
      <Link className="btn btn-primary" to={`/admin/banners/${bannerId}`}>
        <span className="glyphicon glyphicon-edit" />
      </Link>
    );
  }

  renderDeleteButton(bannerId) {
    return (
      <button
        type="button"
        className="btn btn-danger"
        onClick={
          () => confirm('Are you sure to delete this banner post?') && this.props.adminDeleteBanner(bannerId) //eslint-disable-line
        }
      >
        <span className="glyphicon glyphicon-trash" />
      </button>
    );
  }

  renderCaption(bannerId) {
    const captionStyle = { height: 30 };
    return (
      <div style={captionStyle}>
        {Banner.renderEditButton(bannerId)}
        {this.renderDeleteButton(bannerId)}
      </div>
    );
  }

  static renderDate(date) {
    if (!date) {
      return 'unspecified';
    }
    return dayjs(date * 1000).format('YYYY-MM-DD (HH:mm)');
  }

  render() {
    const {
      banner: { id, image, url, prod, test, open, close, title, weight, deeplink },
    } = this.props;

    return (
      <div style={styles.container}>
        <img src={image} style={styles.mainImage} alt="" />
        {/* <p style={styles.abstract}>
          {abstract.split('\n').map((line, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={`editorial_${created}_abstract_${index}`}>{line}</span>
          ))}
        </p> */}
        <div style={styles.headerLeft}>
          <div>
            Title:
            {title}
          </div>
          <div>
            ID:
            {id}
          </div>
          <div>
            Weight:
            {weight}
          </div>
          <div>
            Open:
            {Banner.renderDate(open)}
          </div>
          <div>
            Close:
            {Banner.renderDate(close)}
          </div>
          <div>
            Prod:
            {prod ? <span style={styles.online}>true</span> : <span style={styles.offline}>false</span>}
          </div>
          <div>
            Test:
            {test ? <span style={styles.online}>true</span> : <span style={styles.offline}>false</span>}
          </div>
          <div>
            url:
            {url}
          </div>
          <div>
            deeplink:
            {deeplink}
          </div>
        </div>
        <div style={styles.headerRight}>
          {this.renderCaption(id)}
          {/* <button type="button" className="btn btn-primary">
            <span className="glyphicon glyphicon-edit" />
          </button>
          <button type="button" className="btn btn-danger">
            <span className="glyphicon glyphicon-trash" />
          </button> */}
        </div>
      </div>
    );
  }
}

Banner.propTypes = {
  banner: PropTypes.object.isRequired,
};

export const mapStateToProps = state => ({
  ...loadEntities(state, 'banners', 'banners'),
});

export default connect(
  mapStateToProps,
  adminActions,
)(Banner);
