import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { loadEntities } from 'react-shared';
import * as adminActions from 'react-shared/adminActions';

import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Media from 'components/Media';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

import { green, pink, grey } from '@mui/material/colors';

import moment from 'moment';
import 'moment/locale/ko';

moment.locale('ko');

const styles = {
  notificationTitle : {
    fontSize:'18px',
    color:'#222222'
  },
  notificationDescription : {
    fontSize:'14px',
    color:'#666666',
    whiteSpace:'pre-wrap',
  },
};

const ThumbnailListContainer = styled.div`
  position:relative;
  &:after {
    clear: both;
    content: "";
    display: table;
  }
`;

// export default
class NotificationList extends Component {

  constructor(props){
    super(props);
    //this.handleEditNotification = this.handleEditNotification.bind(this);
    //this.handleRemoveNotification = this.handleRemoveNotification.bind(this);
  }

  componentDidMount() {
    const { mdCurationId, adminLoadNotifications } = this.props;

  }

  renderDeleteButton(mdCurationId) {
    return (
      <Button
        sx={{height:"32px"}}
        color="secondary"
        variant="outlined" startIcon={<DeleteIcon />}
        onClick={
          () => confirm('Are you sure to delete this MD Curation post?') && this.props.adminDeleteMdCuration(mdCurationId) //eslint-disable-line
        }>
        삭제하기
      </Button>
    );
  }

  handleEditNotification(pushNotificationId){
    const { adminEditNotification } = this.props;
    if(pushNotificationId && pushNotificationId.length > 0){
      adminEditNotification(pushNotificationId);
    }
  }

  handleRemoveNotification(pushNotificationId){
    const { adminDeleteNotification } = this.props;
    if(pushNotificationId && pushNotificationId.length > 0){
      confirm('정말 해당 푸시 알림을 삭제하시겠습니까?') && adminDeleteNotification(pushNotificationId);
    }
  }

  render() {
    const {
      /*pushNotification: {
        createUser,
        notificationType,
        contentKey,
        title,
        description,
        pending,
        handled,
        createdAt,
        sentAt,
      },*/
      pushNotifications
    } = this.props;

    const pushNoti = pushNotifications.map((push) => ({
      ...push,
      sentString : moment(push.sentAt).format('YYYY-MM-DD HH:mm'),
      sentFromNow : moment(push.sentAt).fromNow(),
    }));
    // const convertedUrl = getChangedUrl(url, window.location.protocol, window.location.host, null, {
    //   utm_source: window.location.hostname,
    //   utm_medium: 'heartit-mdCuration-list',
    //   utm_campaign: 'heartit-mdCuration-list',
    //   agent: 'web',
    // });
    return (
      <List sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper' }}>
      {
        (pushNoti.length > 0) ? pushNoti.map((_pushNotification, index) => (
          <React.Fragment key={_pushNotification.id}>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar sx={{ bgcolor:
                (_pushNotification.handled === 0) ?
                ((_pushNotification.pending === 0) ? pink[500] : green[500]) : grey[500] }}>
                <NotificationsNoneIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              children={
                <>
                <Typography
                  sx={styles.notificationTitle}>
                  {_pushNotification.title}
                </Typography>
                <Typography
                  sx={styles.notificationDescription}>
                  {_pushNotification.description}
                </Typography>
                <Typography
                  sx={styles.notificationDescription}>
                  {_pushNotification.sentString} ({_pushNotification.sentFromNow})
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}>
                  <Button
                    onClick={() => this.handleEditNotification(_pushNotification.id)}
                    variant="outlined" color="success">수정하기</Button>
                  <Button
                    onClick={() => this.handleRemoveNotification(_pushNotification.id)}
                    variant="outlined">삭제하기</Button>
                </Stack>
                </>
              }
            />
          </ListItem>
          {index < pushNoti.length-1 && <Divider variant="inset" component="li" />}
          </React.Fragment>
        )) :
        (
          <Typography
            sx={styles.notificationDescription}>
            지정된 알림이 없습니다.
          </Typography>
        )
      }
    </List>
    );
  }
}

NotificationList.propTypes = {
  mdCurationId: PropTypes.string.isRequired,
  pushNotifications: PropTypes.array.isRequired,
  adminDeleteNotification: PropTypes.func.isRequired,
  adminEditNotification: PropTypes.func.isRequired,
  //pushNotification: PropTypes.object.isRequired,
};

export default NotificationList;
