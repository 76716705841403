import React from 'react';
import {
  StyleSheet, View, Text, Dimensions, TextInput, TouchableOpacity,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';

import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';

import Select from 'react-select';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';
// eslint-disable-next-line import/no-extraneous-dependencies
import { formatPrice } from 'shared/util';
import Colors from '../../../constants/Colors';
import Styles from '../../../constants/Styles';


const windowLayout = {
  width: Dimensions.get('window').width,
};
const pointUseAllButtonWidth = 100;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 20,
    marginHorizontal: 20,
  },
  couponPickerIcon: {
    marginRight: 20,
    marginTop: 7,
  },
  pointDiscountContainer: {
    marginTop: 10,
  },
  pointInputContainer: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  pointInputBox: {
    width: windowLayout.width - pointUseAllButtonWidth - 20 * 2 - 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: Colors.semiGray,
    marginEnd: 10,
  },
  pointInputText: {
    opacity: 0.4,
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    marginTop: 1,
    height: 19,
    textAlign: 'right',
  },
  pointInputMark: {
    marginEnd: 16,
    opacity: 0.4,
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    marginTop: 1,
    height: 19,
  },
  pointUseAllButton: {
    backgroundColor: Colors.semiGray,
    alignItems: 'center',
    height: 52,
    width: pointUseAllButtonWidth,
    justifyContent: 'center',
  },
  pointUseAllText: {
    textAlign: 'center',
    ...Styles.appleSDGothicNeoBold,
    fontSize: 14,
    lineHeight: 17,
    height: 17,
    marginTop: 1,
  },
  pointDiscountInfoText: {
    fontSize: 13,
    color: 'rgba(0, 0, 0, 0.5)',
    lineHeight: 16,
    ...Styles.appleSDGothicNeoRegular,
  },
});

const couponSelectStyles = {
  control: base => ({
    ...base,
    backgroundColor: Colors.semiGray,
    borderWidth: 0,
    borderRadius: 0,
    outline: 'none',
    color: '#000',
    height: 52,
    fontSize: 15,
    paddingStart: 16,
    ...Styles.appleSDGothicNeoRegular,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  indicatorSeparator: base => ({
    ...base,
    width: 0,
  }),
  valueContainer: base => ({
    ...base,
    padding: 0,
    height: 52,
  }),
  placeholder: base => ({
    ...base,
    color: '#000',
    top: 27,
    marginLeft: 16,
    height: 19,
  }),
  singleValue: base => ({
    ...base,
    color: '#000',
    top: 27,
    marginLeft: 16,
    height: 19,
  }),
  indicatorsContainer: base => ({
    ...base,
    marginRight: 8,
  }),
};

export class DiscountInfo extends React.Component {
  state = {
    couponList: null,
    discountInfo: {},
  };

  componentDidMount() {
    // console.log('DiscountInfo componentDidMount');

    this.loadCouponList();
  }

  componentDidUpdate() {
    // console.log('DiscountInfo componentDidUpdate');

    this.loadCouponList();
  }

  loadCouponList = async () => {
    const { couponList } = this.state;

    if (couponList !== null) {
      return;
    }

    const { itemIndexList, getPromotionCouponList } = this.props;

    try {
      const ret = await getPromotionCouponList(itemIndexList);
      // console.log('DiscountInfo getPromotionCouponList return', ret);

      if (!ret.success) {
        return;
      }

      this.updateCouponList(ret.data);
    } catch (error) {
      // console.log('DiscountInfo loadCouponList errro=', error);
    }
  };


  onCouponSelected = (coupon = {}) => {
    // console.log('DiscountInfo onCouponSelected coupon=', coupon);

    // const { onDiscountApplied } = this.props;
    const { discountInfo: oldDiscountInfo } = this.props;
    const {
      memberCouponNo = null,
      couponDcPrice = 0,
    } = coupon;

    const newDiscountInfo = {
      ...oldDiscountInfo,
      couponApplyOrderNo: memberCouponNo,
      totalCouponOrderDcPrice: couponDcPrice,
    };

    this.setState({
      discountInfo: newDiscountInfo,
    }, () => this.applyPointDiscount());

    // onDiscountApplied(newDiscountInfo);
  }


  updateCouponList = data => {
    const {
      // convertMemberCouponArrData: {
      //   order: convertMemberCouponArrDataOrder,
      // },
      convertMemberCouponPriceArrData: {
        order: {
          memberCouponSalePrice,
        },
      },
      memberCouponArrData: {
        // delivery: memberCouponArrDataDelivery,
        order: memberCouponArrDataOrder,
      },
    } = data;

    const couponList = memberCouponArrDataOrder;

    couponList.forEach(element => {
      const { memberCouponNo } = element;
      element.couponDcPrice = memberCouponSalePrice[memberCouponNo];
    });

    this.setState({ couponList });
  }

  applyPointDiscount = async () => {
    const {
      itemIndexList,
      applyPromotion,
      onDiscountUpdateStart,
      onDiscountUpdateEnd,
    } = this.props;

    const { orderInfo } = this.props;

    const {
      discountInfo: {
        couponApplyOrderNo = '',
        totalCouponOrderDcPrice = 0,
      },
      pointForDiscount = 0,
    } = this.state;


    const {
      totalDeliveryCharge,
      deliveryFree,
      mileageAvailable,
    } = orderInfo;

    const params = {
      cartSno: itemIndexList,
      couponApplyOrderNo,
      totalCouponOrderDcPrice,
      mileageApplyOrderNo: pointForDiscount,
      mileageAvailable,
      totalDeliveryCharge,
      deliveryFree,
    };

    onDiscountUpdateStart();

    try {
      // console.log('applyPointDiscount applyPromotion send', params);
      const ret = await applyPromotion(params);
      // console.log('applyPointDiscount applyPromotion recv', ret);

      if (!ret.success) {
        // console.log('applyPointDiscount error=', ret.success);
        return;
      }

      const {
        data: {
          settlePrice,
          mileageApplyOrderNo,
          totalSettlePrice,
        },
      } = ret;

      const discountInfo = {
        totalSettlePrice,
        settlePrice,
        couponApplyOrderNo,
        totalCouponOrderDcPrice,
        useMileage: mileageApplyOrderNo,
      };

      onDiscountUpdateEnd(discountInfo);
    } catch (error) {
      // console.log('applyPointDiscount error=', error);
    } finally {
      onDiscountUpdateEnd();
    }
  }

  renderCoupontPicker = () => {
    const {
      couponList,
    } = this.state;

    const items = (couponList || []).map(item => {
      const {
        couponNm,
        memberCouponNo,
        couponDcPrice,
      } = item;

      const dcPrice = formatPrice(-couponDcPrice);

      return {
        label: `${couponNm} (${dcPrice})`,
        value: memberCouponNo,
        item,
      };
    });

    // console.log('renderCoupontPicker items', items);

    return (
      <Select
        onChange={data => this.onCouponSelected(data.item)}
        options={items}
        clearable={false}
        searchable={false}
        placeholder="쿠폰을 선택해 주세요"
        styles={couponSelectStyles}
        clearIndicator
      />
    );
  };

  setPointForDiscount = (pointText, applyPoint = false) => {
    // console.log('setPointForDiscount pointText', pointText);

    const {
      orderInfo: {
        mileageAvailable,
      },
    } = this.props;


    let point = pointText;
    point = point.replace(/,/g, '');
    point = Number.parseInt(point, 10);

    if (point > mileageAvailable) {
      point = mileageAvailable;
    }

    if (!point) {
      point = 0;
    }

    this.setState({
      pointForDiscount: point,
    }, () => { if (applyPoint) { this.applyPointDiscount(); } });
  }

  onPointSubmitEdting = () => {
    this.applyPointDiscount();
  };

  onPointEndEditing = () => {
    // console.log('onPointEndEditing');
    this.applyPointDiscount();
  };


  renderPointDiscount = () => {
    const {
      pointForDiscount = 0,
    } = this.state;

    const {
      orderInfo: {
        mileageAvailable,
        mileageUse: {
          minimumLimit,
        },
      },
    } = this.props;

    const onPressPointUseAll = async () => {
      this.setPointForDiscount(`${mileageAvailable}`, true);
    };

    return (
      <View style={styles.pointDiscountContainer}>
        <View style={styles.pointInputContainer}>
          <View style={styles.pointInputBox}>
            <NumberFormat
              value={pointForDiscount}
              displayType="text"
              thousandSeparator
              renderText={value => (
                <TextInput
                  style={[styles.pointInputText, { outline: 'none' }]}
                  value={value}
                  onChangeText={text => this.setPointForDiscount(text)}
                  onEndEditing={this.onPointEndEditing}
                  onBlur={this.onPointEndEditing}
                  onSubmitEditing={this.onPointSubmitEdting}
                  keyboardType="number-pad"
                />
              )}
            />
            <Text style={styles.pointInputMark}>P</Text>
          </View>
          <TouchableOpacity
            style={styles.pointUseAllButton}
            onPress={onPressPointUseAll}
          >
            <Text style={styles.pointUseAllText}>전액 사용</Text>
          </TouchableOpacity>
        </View>
        <View>
          <NumberFormat
            value={mileageAvailable}
            displayType="text"
            thousandSeparator
            renderText={value => (
              <Text
                style={styles.pointDiscountInfoText}
              >
                {'잔여 포인트 '}
                {value}
                {'P'}
              </Text>
            )}
          />
          <NumberFormat
            value={minimumLimit}
            displayType="text"
            thousandSeparator
            renderText={value => (
              <Text
                style={styles.pointDiscountInfoText}
              >
                {value}
                원 이상 구매시 포인트 사용 가능
              </Text>
            )}
          />
        </View>
      </View>
    );
  }

  render() {
    return (
      <View style={styles.container}>
        {this.renderCoupontPicker()}
        {this.renderPointDiscount()}
      </View>
    );
  }
}


export default connect(
  null,
  actions,
)(DiscountInfo);
