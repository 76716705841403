import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { signup, facebookLogin, createFacebookAccessToken } from 'react-shared/actions';
import ga from 'react-ga';
import { Snackbar } from '@material-ui/core';
import get from 'lodash/get';
import Spinner from 'components/Spinner';

import { default as ProfileInputForm } from '../../nativeComponents/ProfileInputForm';
import { runAfterJoinDone } from './util';

const stylesHtml = {
  container: {
    margin: 20,
  },
  snackbar: {
    textAlign: 'center',
  },
};

class FacebookCallbackPage extends Component {
  constructor(props) {
    super(props);
    this.inputForm = React.createRef();
    this.username = React.createRef();
    this.facebookAccessToken = React.createRef();

    this.handleSignup = this.handleSignup.bind(this);

    this.state = {
      showSignup: false,
      snackbarMsg: '',
      snackbarOpen: false,
    };
  }

  componentDidMount() {
    const { code } = this.props;
    if (!code) {
      this.props.router.push('/');
    }

    function login(token) {
      return this.props.facebookLogin(token.access_token).then(
        data => {
          if ($pt.isApp()) {
            $pt.msg.call('session', 'login', {
              token: data.token,
              userId: data._id,
              greeting: false,
            });
            ga.event({
              category: '(ignore) Dev $pt',
              action: 'FacebookCallbackPage',
              label: 'componentDidMount',
            });
          } else {
            this.props.router.push('/');
          }
        },
        err => {
          if (err.status === 404) {
            if ($pt.isApp()) {
              $pt.msg.call('session', 'signup', {
                facebookAccessToken: token.access_token,
                facebookId: token.id,
                name: token.name,
                email: token.email,
                username: token.id,
              });
            } else {
              this.setState({
                showSignup: true,
                facebookAccessToken: token.access_token,
                // facebookId: token.id,
                name: token.name,
                email: token.email,
                username: token.id,
              });
            }
          } else {
            this.props.router.push('/');
          }
        }
      );
    }

    this.props.createFacebookAccessToken(code).then(
      login.bind(this),
      () => {
        // 원래는 해당 코드가 실행되길 바랐던 것 같으나, 이전까지는 분기를 잘못 태워서 실제로 실행된 경우는 없었음
        // this.props.router.push('/');
      },
      () => {
      }
    );
  }

  showSnackbar = msg => {
    this.setState({
      snackbarMsg: msg,
      snackbarOpen: true,
    });
  }

  handleSignup(inputValues) {
    const { name, email, phone, birthYear,
      termAgreed, privacyAgreed, marketingAgreed, validationComplete } = inputValues;
    let username = this.username.current.value;
    const facebookAccessToken = this.facebookAccessToken.current.value;

    if (!validationComplete) {
      return false;
    }

    if (!username) {
      username = email;
    }

    $('.edit-profile-save-btn').attr('disabled', true);

    this.props.signup({
      username,
      email,
      name,
      tel: phone,
      termAgreed,
      privacyAgreed,
      marketingAgreed,
      birthYear,
      facebookAccessToken,
    }).then(data => {
      if ($pt.isApp()) {
        $pt.msg.call('session', 'login', {
          token: data.token,
          userId: data._id,
          greeting: true,
        });
        ga.event({
          category: '(ignore) Dev $pt',
          action: 'FacebookCallbackPage',
          label: 'handleSignup',
        });
      } else {
        // TODO: greeting page hidden. Need to add intro greeting page after register.
        // this.props.router.push('/greeting');
        runAfterJoinDone(this.props.router);
      }
      ga.event({
        category: 'SignUp',
        action: 'SignUp',
        label: 'Success',
      });
    }, () => {
      this.props.router.push('/intro');
      ga.event({
        category: 'SignUp',
        action: 'SignUp',
        label: 'Fail',
      });
    });
    return true;
  }

  render() {
    const { router, legalPolicyType } = this.props;
    const { showSignup, username, email, name = '',
      facebookAccessToken,
      snackbarMsg, snackbarOpen } = this.state;

    if (!showSignup) {
      return <Spinner />;
    }

    return (
      <div style={stylesHtml.container}>
        <ProfileInputForm
          ref={this.inputForm}
          router={router}
          handleUpdate={this.handleSignup}
          btnText="가입하기"
          emailDefault={email}
          nameDefault={name}
          showAlertMessage={this.showSnackbar}
          showPolicy
          type="facebook"
          legalPolicyType={legalPolicyType}
        >
          <input type="hidden" ref={this.username} autoCapitalize="none" defaultValue={username} />
          <input type="hidden" ref={this.facebookAccessToken} defaultValue={facebookAccessToken} />
        </ProfileInputForm>

        <Snackbar
          autoHideDuration={2000}
          message={snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={snackbarOpen}
          style={stylesHtml.snackbar}
        />
      </div>
    );
  }
}


FacebookCallbackPage.propTypes = {
  code: PropTypes.string.isRequired,
  signup: PropTypes.func.isRequired,
  facebookLogin: PropTypes.func.isRequired,
  createFacebookAccessToken: PropTypes.func.isRequired,
};

export default connect(
  (state, ownProps) => {
    const { params } = ownProps;
    const { legalPolicyType = null } = params;

    return {
      code: get(ownProps.location, 'query.code'),
      legalPolicyType,
    };
  },
  { signup, facebookLogin, createFacebookAccessToken }
)(FacebookCallbackPage);
