import * as React from 'react';

function SvgIcTabMy(props) {
  return (
    <svg width={24} height={24} {...props}>
      <defs>
        <path
          id="icTabMy_svg__a"
          d="M22 11c0 6.075-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0s11 4.925 11 11z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)">
          <mask id="icTabMy_svg__b" fill="#fff">
            <use xlinkHref="#icTabMy_svg__a" />
          </mask>
          <path
            stroke="#FFF"
            strokeWidth={1.5}
            d="M20 24a9 9 0 11-18.001-.001A9 9 0 0120 24z"
            mask="url(#icTabMy_svg__b)"
          />
        </g>
        <path
          stroke="#FFF"
          strokeWidth={1.5}
          d="M15.385 9.385a3.385 3.385 0 11-6.77-.002 3.385 3.385 0 016.77.002z"
        />
        <path
          stroke="#FFF"
          strokeWidth={1.5}
          d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11z"
        />
      </g>
    </svg>
  );
}

export default SvgIcTabMy;

