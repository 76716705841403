import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Alarm18WhiteIcon(props) {
  return (
    <Svg
      width={19}
      height={18}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M14 6.75a4.5 4.5 0 10-9 0c0 1.181-.114 2.923-.29 3.75-.32 1.5-1.21 2.25-1.21 2.25h12s-.89-.75-1.21-2.25c-.176-.827-.29-2.569-.29-3.75z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M7.378 12.75a2.25 2.25 0 104.244 0"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Alarm18WhiteIcon

