import * as React from "react"
import Svg, { Path } from "react-native-svg"

function HeaderBellIcon(props) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M18 9A6 6 0 106 9c0 1.575-.152 3.897-.387 5C5.186 16 4 17 4 17h16s-1.186-1-1.613-3C18.152 12.897 18 10.575 18 9zM9.17 17a3 3 0 105.659 0"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default HeaderBellIcon
