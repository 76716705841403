import React from 'react';
import {
  StyleSheet, Text, View,
} from 'react-native';

import { Styles, Colors, Constants } from '../constants';

import {
  Ic24EditIcon, InstagramIcon, CheckIcon, PlusIcon,
} from '../icons-v2';

import {
  initAlert, renderAlert,
} from '../components/AlertDialog';

import InstagramProfileImage from './InstagramProfileImage';
import Button from './Button';


const styleHtml = {
  profileImage: {
    backgroundColor: '#cfcfca',
    borderRadius: 32,
    height: 64,
    width: 64,
  },
};


const styles = StyleSheet.create({
  container: {
    marginHorizontal: Constants.viewHorizontalPadding,
    marginTop: 10,
  },
  profileButtonContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  profileContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  sizeTotalContainer: {
    marginTop: 6,
  },
  sizeText: {
    lineHeight: 15.6,
    fontSize: 13,
    textAlign: 'left',
    color: '#000',
    opacity: 0.5,
    ...Styles.pretendard400,
  },
  profile: {
  },
  profileImage: {
    backgroundColor: '#cfcfca',
    borderRadius: 32,
    height: 64,
    width: 64,
  },
  profileText: {
    color: Colors.defaultGray08,
    fontSize: 18,
    lineHeight: 21.6,
    textAlign: 'left',
    ...Styles.pretendard600,
  },
  buttonsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 17,
    marginBottom: 17,
  },
  buttonFollow: {
    backgroundColor: "#000",
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 0,
    paddingVertical: 6,
    paddingLeft: 11,
    paddingRight: 7,
  },
  buttonFollowing: {
    backgroundColor: '#fff',
    borderWidth: 0.5,
    borderColor: '#000',
  },
  buttonInstagram: {
    marginLeft: 3,
    padding: 0,
    borderWidth: 0,
  },
  buttonTextFollow: {
    fontSize: 15,
    lineHeight: 18,
    color: "#fff",
    ...Styles.pretendard600,
    marginRight: 4,
  },
  buttonTextFollowing: {
    color: '#000',
  },
  buttonTextInstagram: {
    color: '#000',
  },
  editButtonContainer: {
    marginTop: 30 - 10,
    justifyContent: 'flex-start',
  },
  editButton: {
    padding: 0,
    borderWidth: 0,
  },
});


export default class Heartist extends React.PureComponent {
  constructor(props) {
    super(props);
    initAlert(this);
  }


  componentDidMount() {
    this.loadData();
  }

  onPressFollow() {
    const { followStatus, followUser, unfollowUser, user, auth, router } = this.props;
    const { token } = auth;

    if (!auth || !token) {
      this.alert(
        '로그인해주시기 바랍니다.',
        {
          buttons: [
            {
              text: '닫기',
              onPress: () => router.push('/accounts/login'),
            }
          ],
        }
      );
      return;
    }
    if (auth._id === user._id) {
      this.alert("나 자신을 팔로우할 수 없습니다.");
      return;
    }
    if (followStatus.following) {
      unfollowUser(user._id);
    } else {
      followUser(user._id);
    }
  }

  getHeartistSize = () => {
    const { user } = this.props;
    if (!user.creatorProfile) {
      user.creatorProfile = { height: '' };
    }

    const height = user.creatorProfile.height ? `${user.creatorProfile.height}cm` : '';
    const clothingSize = user.mySize && user.mySize.clothing && user.mySize.clothing.length > 0 && user.mySize.clothing.join(',') || '';
    const shoeSize = user.mySize && user.mySize.shoes && user.mySize.shoes.length > 0 && `${user.mySize.shoes.join(',')}mm` || '';

    return {
      tall: height,
      clothing: clothingSize,
      shoe: shoeSize,
    };
  }

  getHeartistDescription = () => {
    const { user } = this.props;
    return user.oneLineSummary || user.username;
  }

  loadData() {
    const { auth, getFollowStatus, user } = this.props;

    if (auth && auth.token && getFollowStatus && user) {
      getFollowStatus(user._id);
    }
  }

  renderDescription = () => {
    const { user, auth } = this.props;
    if (!user || !auth) {
      return (<View style={styles.sizeTotalContainer} />);
    }

    const isOwner = user._id == auth._id;

    const { oneLineSummary } = user;
    if (oneLineSummary) {
      return (
        <View style={styles.sizeTotalContainer}>
          <Text style={styles.sizeText} numberOfLines={1}>
            {oneLineSummary}
          </Text>
        </View>
      );
    }

    const heartistSize = this.getHeartistSize();
    if (!heartistSize) {
      return (<View style={styles.sizeTotalContainer} />);
    }
    return (
      <View style={styles.sizeTotalContainer}>
        {isOwner && (
          <Text style={styles.sizeText}>
            키 {heartistSize.tall ? heartistSize.tall : '미설정'} / 신발 {heartistSize.shoe ? heartistSize.shoe : '미설정'} / 옷 사이즈 {heartistSize.clothing ? heartistSize.clothing : '미설정'}
          </Text>
        )}

        {!isOwner && (
          <View>
            <Text style={styles.sizeText}>
              키 {heartistSize.tall ? heartistSize.tall : '비공개'} / 신발 {heartistSize.shoe? heartistSize.shoe : '비공개'}
            </Text>
            <Text style={styles.sizeText}>
              옷 사이즈 {heartistSize.clothing ? heartistSize.clothing : '비공개'}
            </Text>
          </View>
        )}
      </View>
    );
  }

  render() {
    const {
      auth, followStatus: { following }, user, router,
    } = this.props;
    if (!user.username) {
      return null;
    }

    const name = user.name ? user.name : user.username.split('@')[0];
    const instagramUsername = user.instagramUsername ? user.instagramUsername.slice(1) : null;
    const isOwner = user._id == auth._id;

    return (
      <View style={[styles.container]}>
        <View style={styles.profileButtonContainer}>
          <View style={styles.profileContainer}>
            <View style={styles.profileImage}>
              <InstagramProfileImage
                user={user}
                username={user.username}
                style={styleHtml.profileImage}
                />
            </View>
            <View style={{ marginStart: 12, justifyContent: 'center', flex: 1, }}>
              <View style={styles.profile}>
                <Text style={styles.profileText}>{name}</Text>
              </View>
              {this.renderDescription()}
            </View>
          </View>

          {!isOwner && (
            <View style={styles.buttonsContainer}>
              <Button
                innerComponent={(
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={[styles.buttonTextFollow, (following && styles.buttonTextFollowing)]}>
                      { following ? '팔로우' : '팔로우' }
                    </Text>
                    { following ? (<CheckIcon />) : (<PlusIcon />)}
                  </View>
                )}
                containerStyle={[styles.buttonFollow, (following && styles.buttonFollowing)]}
                onPress={() => this.onPressFollow()}
              />
              { instagramUsername && (
                <Button
                  innerComponent={(
                    <InstagramIcon />
                  )}
                  containerStyle={styles.buttonInstagram}
                  onPress={() => window.open(`https://www.instagram.com/${instagramUsername}`)}
                />
              )}
            </View>
          )}

          {isOwner && (
            <View style={styles.editButtonContainer}>
              <Button
                containerStyle={styles.editButton}
                innerComponent={(
                  <Ic24EditIcon />
                )}
                onPress={() => router.push('/accounts/profile/edit')}
              />
            </View>
          )}
        </View>
        {renderAlert(this)}
      </View>
    );
  }
}
