import * as React from 'react';

function SvgIcTabHomeSelected(props) {
  const { active = true } = props;

  const body = active ? '#fff' : '#000';

  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M12 2l10 5.372V22h-7v-7H9v7H2V7.372L12 2z"
        fill={body}
        stroke="#FFF"
        strokeWidth={1.2}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIcTabHomeSelected;
