import React from 'react';
import {
  Dimensions, StyleSheet, View, ScrollView, Text,
} from 'react-native';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import qs from 'qs';

import { actions } from 'shared';

import { Styles, Constants } from '../../../constants';
import {
  initAlert, renderAlert,
} from '../../../components/AlertDialog';
import { SquareCheckBoxIcon } from '../../../icons-v2';
import {
  Button, OrderItem, Spinner, ShippingAddressInfo, TouchableHighlightWithContainer,
} from '../../../components-v2';
import { navigateToCheckoutFinish } from '../../../containers/shop/CheckoutFinishPage';
import { navigateToCheckoutPaymentGateway } from '../../../containers/shop/CheckoutPaymentGatewayPage';

import Section from './Section';
import DiscountInfo from './DiscountInfo';
import PaymentMethod from './PaymentMethod';
import Terms from './Terms';
import Statement from './Statement';



const {
  height: windowHeight,
  width: windowWidth,
} = Dimensions.get('window');


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  preOrderContainer: {
    flex: 1,
    paddingBottom: 41,
  },
  preOrderDescriptionText: {
    fontSize: 14,
    lineHeight: 16.8,
    color: '#000',
    ...Styles.pretendard400,
  },
  preOrderCheckContainer: {
    marginTop: 21,
    flex: 1,
    flexDirection: 'row',
  },
  preOrderCheckLabelText: {
    fontSize: 14,
    lineHeight: 16.8,
    marginStart: 8,
    color: '#000',
    ...Styles.pretendard400,
  },
  preOrderCheckLabelTextChecked: {
    ...Styles.pretendard600,
  },
  orderItemLabelContainer: {
    marginEnd: 2
  },
  orderItemLabelText: {
    color: '#000',
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard400,
  },
  orderItemContainer: {
    flex: 1,
    marginTop: 20,
    marginBottom: 40,
  },
  itemDivider: {
    height: 3,
  },
  footer: {
    backgroundColor: '#000',
  },
  checkoutButton: {
    position: 'relative',
    borderWidth: 0,
    borderColor: 'transparent',
    backgroundColor: '#000',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 0,
  },
  checkoutButtonText: {
    fontSize: 16,
    color: '#fff',
    textAlign: 'center',
    lineHeight: 19.2,
    ...Styles.pretendard600,
    marginTop: 20,
    marginBottom: 21,
    height: 19,
    width: windowWidth - Constants.viewHorizontalPadding * 2,
  },
});


export class CheckoutPage extends React.Component {
  shippingAddressInfoRef = null;

  paymentMethodRef = React.createRef();

  termsRef = React.createRef();

  state = {
    checkoutInfo: {
      totalSettlePrice: 0,
      settlePrice: 0,
      couponApplyOrderNo: null,
      totalCouponOrderDcPrice: 0,
      totalMemberDcPrice: 0,
      useMileage: 0,
    },
    preOrderShippingChecked: false,
  };

  constructor(props) {
    super(props);

    initAlert(this);
  }

  componentDidMount() {
    const { logEventPageview } = this.props;

    this.createOrder();

    logEventPageview({ page_name: 'Checkout' });
  }

  createOrder = async () => {
    const { router, itemIndexList, loadShippingAddressList, createOrder } = this.props;
    const errorMessage = '주문 생성 중 오류가 발생하였습니다. 다시 시도해주시기 바랍니다.';
    try {
      await loadShippingAddressList();
      // const shippingAddressRet = await loadShippingAddressList();
      // console.log('ShippingAddressInfo loadShippingAddressList ret', shippingAddressRet);

      // console.log('createOrder send', itemIndexList);
      const ret = await createOrder(itemIndexList);
      // console.log('createOrder return', ret);
      if (!ret.success) {
        let msg;
        switch (ret.errorCode) {
        case 500:
          msg = '품절 혹은 판매 예정/종료된 상품이 포함되어 있어 주문하실 수 없습니다.';
          break;
        default:
          msg = `${errorMessage} (E0${ret.errorCode})`;
          break;
        }
        this.alert(msg, {
          buttons: [
            {
              text: '확인',
              onPress: () => {
                router.go(-1);
              },
            },
          ]
        })
        return;
      }

      const orderInfo = ret.data;

      const {
        orderItems,
        totalSettlePrice,
        settlePrice,
        totalDeliveryCharge,
        totalMemberDcPrice,
        totalGoodsPrice,
      } = orderInfo;

      this.setState(({ prevCheckoutInfo }) => ({
        orderInfo,
        checkoutInfo: {
          ...prevCheckoutInfo,
          orderItems,
          totalGoodsPrice,
          totalSettlePrice,
          settlePrice,
          totalDeliveryCharge,
          totalMemberDcPrice,
        },
      }), () => {
        this.sendPixelEvent('InitiateCheckout', {});
      });
    } catch (error) {
      // console.log('createOrder error', error);

      let msg = errorMessage;
      if (error.errorCode) {
        msg += ` (E1${error.errorCode})`;
      }

      this.alert(msg, {
        buttons: [
          {
            text: '확인',
            onPress: () => {
              router.go(-1);
            },
          },
        ]
      })
    }
  }


  onShippingAddressChanged = shippingAddressInfo => {
    console.log('onShippingAddressChanged shippingAddress=', shippingAddressInfo);
    const { auth } = this.props;

    const {
      sno,
      shippingTitle,
      shippingPhone: orderPhone,
      shippingCellPhone,
      shippingName,
      shippingZonecode,
      shippingZipcode: receiverZipcode,
      shippingAddress,
      shippingAddressSub,
      orderMemo = '',
      saveToShippingAddressList,
      newDefaultFl,
    } = shippingAddressInfo;

    this.setState(({ checkoutInfo: prevCheckoutInfo }) => ({
      selectedAddressIndex: sno,
      checkoutInfo: {
        ...prevCheckoutInfo,
        orderPhone,
        orderCellPhone: shippingCellPhone,
        orderEmail: auth.email,
        receiverName: shippingName,
        receiverZonecode: shippingZonecode,
        receiverZipcode,
        receiverAddress: shippingAddress,
        receiverAddressSub: shippingAddressSub,
        receiverPhone: orderPhone,
        receiverCellPhone: shippingCellPhone,
        orderMemo,
      },
      shippingAddressInfo: {
        shippingTitle,
        shippingName,
        shippingZonecode,
        shippingAddress,
        shippingAddressSub,
        shippingCellPhone,
        saveToShippingAddressList,
        defaultFl: newDefaultFl ? 'y' : 'n',
      },
    }));
  }

  onDiscountUpdateStart = () => {
    // Disable checkout button;
    // console.log('onDiscountUpdateStart');
  }

  onDiscountUpdateEnd = discountInfo => {
    // console.log('onDiscountUpdateEnd', discountInfo);
    if (!discountInfo) {
      return;
    }

    this.setState(({ checkoutInfo: prevCheckoutInfo }) => ({
      checkoutInfo: {
        ...prevCheckoutInfo,
        ...discountInfo,
      },
    }));
  }

  onPaymentMethodSelected = paymentInfo => {
    // console.log('onPaymentMethodSelected', paymentInfo);
    if (!paymentInfo) {
      return;
    }

    const {
      settleKind,
      bankSender,
      bankAccount,
    } = paymentInfo;

    this.setState(({ checkoutInfo: prevCheckoutInfo }) => ({
      checkoutInfo: {
        ...prevCheckoutInfo,
        settleKind,
        bankSender,
        bankAccount,
      },
    }));
  }

  sendPixelEvent = (eventType, param) => {
    const {
      orderInfo,
      checkoutInfo,
    } = this.state;
    const {
      totalSettlePrice,
    } = checkoutInfo;
    const {
      orderItems
    } = orderInfo;

    const parameters = Object.assign({
      content_type: "product",
      contents: JSON.stringify(orderItems.map((item) => {
        return {
          id: item.goodsNo,
          quantity: item.goodsCnt
        }
      })),
      value: totalSettlePrice,
      currency: "KRW",
      num_items: orderItems.reduce((sum, currentItem) => { return sum + Number.parseInt(currentItem.goodsCnt, 10) }, 0)
    }, param);
    console.log('sendPixelEvent', eventType, totalSettlePrice, parameters);
    fbq('track', eventType, parameters);
  }

  doCheckout = async () => {
    console.log('CheckoutPage.doCheckout');

    const { checkoutOrder, setGlobalLayoutInfo, loadItemListInCart } = this.props;

    const errorMessage = '결제 준비 중 오류가 발생하였습니다.';

    const {
      checkoutInfo, shippingAddressInfo,
    } = this.state;

    setGlobalLayoutInfo('showProgress', true);

    try {
      if (shippingAddressInfo.saveToShippingAddressList) {
        const { createShippingAddress } = this.props;
        // console.log("createShippingAddress send shippingAddressInfo=", shippingAddressInfo);
        const createShippingAddressRet = await createShippingAddress(shippingAddressInfo);
        // console.log("createShippingAddress ret=", createShippingAddressRet);
        if (!createShippingAddressRet.success) {
          alert(`${errorMessage} (E0${ret.errorCode})`);
          return;
        }
      }

      // console.log("checkoutOrder send=", checkoutInfo);
      const ret = await checkoutOrder(checkoutInfo);
      // console.log("checkoutOrder ret=", ret);

      if (ret.success) {
        // 무통장입금 결제 성공시
        const { data: { orderNo } } = ret;
        this.sendPixelEvent('Purchase', {'order_id': orderNo});
        loadItemListInCart();
        // router.goBack();

        navigateToCheckoutFinish(orderNo);
        setGlobalLayoutInfo('showProgress', false);
        return;
      }

      // PG사 이용 결제
      if (ret.errorCode === 200) {
        const { data: { orderNo, uniqueOrderCheckData } } = ret;
        this.sendPixelEvent('Purchase', {'order_id': orderNo});
        navigateToCheckoutPaymentGateway(orderNo, uniqueOrderCheckData);
        setGlobalLayoutInfo('showProgress', false);
        return;
      }

      // 그 외 오류
      alert(`${errorMessage} (E1${ret.errorCode})`);
      setGlobalLayoutInfo('showProgress', false);
      return;
    } catch (error) {
      console.log('Checkout doCheckout error=', error);

      const msg = error.errorCode ? `${errorMessage} (E2${error.errorCode})` : errorMessage;

      alert(msg);
      setGlobalLayoutInfo('showProgress', false);
    }
  }

  onPressCheckoutButton = () => {
    console.log('CheckoutPage.onPressCheckoutButton');

    const {
      checkoutInfo,
    } = this.state;

    const {
      settlePrice,
    } = checkoutInfo;

    if (!this.validateInputField()) {
      console.log('CheckoutPage onPressCheckoutButton validateInputField failed');
      return;
    }

    if (settlePrice >= 5000000) {
      this.alert('결제사(온라인PG) 정책으로\n현재 전 업종 1회 500만원 이상 결제가 불가합니다.');
      console.log('결제사(온라인PG) 정책으로\n현재 전 업종 1회 500만원 이상 결제가 불가합니다.');
      return;
    }

    this.doCheckout();
  }

  validateInputField() {
    console.log('CheckoutPage.validateInputField');

    if (!this.shippingAddressInfoRef.validateInputField()) {
      this.alert('배송지 정보를 입력해주세요.');
      console.log('CheckoutPage validateInputField shippingAddressInfoRef failed');
      return false;
    }

    if (!this.paymentMethodRef.current.validateInputField()) {
      console.log('CheckoutPage validateInputField paymentMethodRef failed');
      return false;
    }

    if(this.hasPreOrderItem() && !this.state.preOrderShippingChecked) {
      this.alert('프리오더 상품 배송 기간 안내 확인에 체크해주세요.');
      console.log('프리오더 상품 배송 기간 안내 확인에 체크해주세요.');
      return false;
    }

    if (!this.termsRef.current.validateInputField()) {
      console.log('CheckoutPage validateInputField termsRef failed');
      return false;
    }

    return true;
  }

  hasPreOrderItem = () => {
    const { orderInfo } = this.state;
    const {
      orderItems,
    } = orderInfo;

    // FIXME (woosuk, 22.07.17.) 프리오더 상품 배송 안내 체크박스 임시 비활성화
    // return orderItems.filter(x => x.preOrder === 'y').length > 0;
    return false;
  }

  addressSelectedCallback = (selectedAddressIndex) => {
    this.setState({
      selectedAddressIndex: selectedAddressIndex
    });
  }

  renderShippingAddress() {
    const { router, setModalProps } = this.props;

    const onRef = ref => {
      if (!ref)
        return;

      this.shippingAddressInfoRef = ref.getWrappedInstance();
    }

    const headerButtonPress = () => {
      if (!this.shippingAddressInfoRef)
        return;

      this.shippingAddressInfoRef.showAddressListScreen();
    }

    return (
      <Section
        key="shippingAddressInfo"
        title="배송지 정보"
        headerButtonText="배송지 목록"
        headerButtonPress={headerButtonPress}
        titleContainerStyle={{paddingTop: 12.5}}
        childrenContainerStyle={{ paddingTop: 32 }}
      >
        <ShippingAddressInfo
          ref={onRef}
          router={router}
          onDataChanged={this.onShippingAddressChanged.bind(this)}
          setModalProps={setModalProps}
        />
      </Section>
    );
  }

  renderDiscountInfo() {
    const { itemIndexList } = this.props;
    const { orderInfo, checkoutInfo } = this.state;

    return (
      <Section key="discountInfo" title="할인 쿠폰/포인트 사용">
        <DiscountInfo
          itemIndexList={itemIndexList}
          orderInfo={orderInfo}
          checkoutInfo={checkoutInfo}
          onDiscountUpdateStart={this.onDiscountUpdateStart}
          onDiscountUpdateEnd={this.onDiscountUpdateEnd}
        />
      </Section>
    );
  }

  renderPaymentInfo() {
    const { orderInfo: { bank } } = this.state;

    return (
      <Section key="paymentMethod" title="결제 방법">
        <PaymentMethod
          ref={this.paymentMethodRef}
          bank={bank}
          onPaymentMethodSelected={this.onPaymentMethodSelected}
        />
      </Section>
    );
  }

  renderItemInfo() {
    const { orderInfo: { orderItems } } = this.state;

    return (
      <Section
        key="itemInfo"
        title="주문 상품 정보"
      >
        <View style={styles.orderItemLabelContainer}>
          <Text style={styles.orderItemLabelText}>상품 총 {orderItems.length}건</Text>
          <View style={styles.orderItemContainer}>
            {orderItems.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <View key={`cartItemWrapper${index}`}>
                {index > 0 && <View style={styles.itemDivider} />}
                <OrderItem item={item} key={item.sno} />
              </View>
            ))}
          </View>
        </View>
      </Section>
    );
  }

  renderPreOrderItemInfo() {

    const {
      preOrderShippingChecked,
    } = this.state;

    if (!this.hasPreOrderItem()) {
      return null;
    }

    return (
      <Section key="preorder_term" title="배송 기간 안내">
        <View style={styles.preOrderContainer}>
          <Text style={styles.preOrderDescriptionText}>
            프리오더 상품의 경우 프리오더 기간 마감후 8주 이후에 배송이 시작됩니다.
          </Text>
          <View style={styles.preOrderCheckContainer}>
            <TouchableHighlightWithContainer
              onPress={() => {
                this.setState(prevState => ({
                  preOrderShippingChecked: !prevState.preOrderShippingChecked
                }));
              }}
            >
              <SquareCheckBoxIcon
                checked={preOrderShippingChecked}
                />
            </TouchableHighlightWithContainer>
            <Text style={[styles.preOrderCheckLabelText, preOrderShippingChecked && styles.preOrderCheckLabelTextChecked]}>프리오더 상품 배송 기간 안내 확인</Text>
          </View>
        </View>
      </Section>
    );
  }

  renderStatement() {
    const {
      checkoutInfo,
    } = this.state;
    
    return (
      <Section
        key="stateent"
        title="최종 결제 금액"
        childrenContainerStyle={{ paddingTop: 32 }}
      >
        <Statement
          checkoutInfo={checkoutInfo}
        />
      </Section>
    );
  }

  renderFooter() {
    const { checkoutInfo: { settlePrice } } = this.state;

    return (
      <View style={styles.footer}>
        <Button
          containerStyle={styles.checkoutButton}
          onPress={this.onPressCheckoutButton}
          innerComponent={
            <NumberFormat
              value={settlePrice}
              displayType="text"
              thousandSeparator
              renderText={value => (
                <Text style={styles.checkoutButtonText}>{`${value}원 결제하기`}</Text>
              )}
            />
          }
        />
      </View>
    );
  }

  render() {
    const {
      showSmartBanner
    } = this.props;

    const {
      orderInfo,
    } = this.state;

    if (orderInfo === undefined) {
      return (
        <View>
          <Spinner
            visible
            textContent="주문 준비 중..."
            textStyle={Styles.spinnerTextStyle}
            />
          {renderAlert(this)}
        </View>
      );
    }

    const scrollViewStyles = [
      styles.scrollView,
      {
        height: windowHeight
          - (showSmartBanner && Constants.smartBannerHeight)
          - Constants.mainHeaderHeight
          - Constants.bottomTabBarHeight,
      }
    ];

    return (
      <View style={styles.container}>
        <ScrollView
          style={scrollViewStyles}
          showsVerticalScrollIndicator={false}
          {...Constants.scrollViewCommonProps}
        >
          {this.renderShippingAddress()}

          {this.renderDiscountInfo()}

          {this.renderPaymentInfo()}

          {this.renderItemInfo()}

          {this.renderPreOrderItemInfo()}

          {this.renderStatement()}

          <Terms ref={this.termsRef} />
        </ScrollView>

        {this.renderFooter()}
        {renderAlert(this)}
      </View>
    );
  }
}


export default connect(
  (state, props) => {
    const { params: { query } } = props;

    const queryObject = qs.parse(query);
    const { itemIndexList } = queryObject;

    return {
      auth: state.auth,
      itemIndexList,
    };
  },
  actions,
)(CheckoutPage);
