import React, { Component } from 'react';
import {
  StyleSheet, View,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { signup, naverLogin, createNaverAccessToken, getNaverProfile } from 'react-shared/actions';
import ga from 'react-ga';
import { Snackbar } from '@material-ui/core';
import get from 'lodash/get';
import Spinner from 'components/Spinner';

import { default as ProfileInputForm } from '../../nativeComponents/ProfileInputForm';
import { runAfterJoinDone } from './util';

const styles = StyleSheet.create({
  container: {
    margin: 20,
  },
});

const stylesHtml = {
  snackbar: {
    textAlign: 'center',
  },
};


class NaverCallbackPage extends Component {
  constructor(props) {
    super(props);
    this.inputForm = React.createRef();
    this.username = React.createRef();
    this.naverAccessToken = React.createRef();

    this.handleSignup = this.handleSignup.bind(this);

    this.state = {
      showSignup: false,
      snackbarMsg: '',
      snackbarOpen: false,
    };
  }

  componentDidMount() {
    const {
      code, state, router,
      createNaverAccessToken: createNaverAccessTokenFromProps,
    } = this.props;
    if (!code) {
      router.push('/');
    }

    createNaverAccessTokenFromProps({ code, state })
      .then(resp => {
        if (resp.success) {
          this.handleLogin(resp.access_token);
        } else {
          alert('네이버 로그인에 실패했습니다. 잠시 후 다시 시도하세요.');
          this.props.router.push('/accounts/login');
        }
      });
  }

  showSnackbar = msg => {
    this.setState({
      snackbarMsg: msg,
      snackbarOpen: true,
    });
  }

  handleSignup(inputValues) {
    const { name, email, phone, birthYear,
      termAgreed, privacyAgreed, marketingAgreed, validationComplete } = inputValues;
    let username = this.username.current.value;
    const naverAccessToken = this.naverAccessToken.current.value;

    if (!validationComplete) {
      return false;
    }

    if (!username) {
      username = email;
    }

    $('.edit-profile-save-btn').attr('disabled', true);

    this.props.signup({
      username,
      email,
      name,
      tel: phone,
      termAgreed,
      privacyAgreed,
      marketingAgreed,
      birthYear,
      naverAccessToken,
    }).then(() => {
      runAfterJoinDone(this.props.router);
      ga.event({
        category: 'SignUp',
        action: 'SignUp',
        label: 'Success',
      });
    }, () => {
      this.props.router.push('/accounts/login');
      ga.event({
        category: 'SignUp',
        action: 'SignUp',
        label: 'Fail',
      });
    });
    return true;
  }

  handleLogin = accessToken => this.props.naverLogin(accessToken).then(
    () => {
      this.props.router.push('/');
    },
    err => {
      if (err.status === 404) {
        this.props.getNaverProfile({ accessToken })
          .then(response => {
            this.setState({
              showSignup: true,
              naverAccessToken: accessToken,
              // naverId: response.id,
              email: response.email,
              username: response.email,
              name: response.name,
            });
          })
          .catch(err2 => {
            // eslint-disable-next-line no-console
            console.error(err2);
            alert('네이버 로그인에 실패했습니다. 잠시 후 다시 시도하세요.');
            this.props.router.push('/accounts/login');
          });
      } else if (err.status === 400) {
        alert('이미 가입되어 있는 이메일 계정입니다.');
        this.props.router.push('/accounts/login');
      } else {
        alert('네이버 로그인에 실패했습니다. 잠시 후 다시 시도하세요.');
        this.props.router.push('/accounts/login');
      }
    }
  )

  render() {
    const { router, legalPolicyType } = this.props;
    const { showSignup, username, name, email,
      naverAccessToken,
      snackbarMsg, snackbarOpen } = this.state;

    if (!showSignup) {
      return <Spinner />;
    }

    return (
      <View style={styles.container}>
        <ProfileInputForm
          ref={this.inputForm}
          router={router}
          handleUpdate={this.handleSignup}
          btnText="가입하기"
          emailDefault={email}
          nameDefault={name}
          showAlertMessage={this.showSnackbar}
          showPolicy
          type="naver"
          legalPolicyType={legalPolicyType}
        >
          <input type="hidden" ref={this.username} autoCapitalize="none" defaultValue={username} />
          <input type="hidden" ref={this.naverAccessToken} defaultValue={naverAccessToken} />
        </ProfileInputForm>

        <Snackbar
          autoHideDuration={2000}
          message={snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={snackbarOpen}
          style={stylesHtml.snackbar}
        />
      </View>
    );
  }
}

NaverCallbackPage.propTypes = {
  code: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  signup: PropTypes.func.isRequired,
  naverLogin: PropTypes.func.isRequired,
  createNaverAccessToken: PropTypes.func.isRequired,
};

export default connect(
  (state, ownProps) => {
    const { params } = ownProps;
    const { legalPolicyType = null } = params;

    return {
      code: get(ownProps.location, 'query.code'),
      state: get(ownProps.location, 'query.state'),
      legalPolicyType,
    };
  },
  { signup, naverLogin, createNaverAccessToken, getNaverProfile }
)(NaverCallbackPage);
