import React from 'react'
import { connect } from 'react-redux';
import { 
  StyleSheet, View,
  ScrollView, Text,
} from 'react-native';

import { actions } from 'shared';

import {
  Constants, Styles, Colors, SizeData,
} from '../../../../constants';

import { 
  TouchableHighlightWithContainer,
  SizeStandardSelector,
} from '../../..';

import FilterBaseModal from '../FilterBaseModal';
import SizeSelector from './SizeSelector';
import showFilterSizeGuideModal from '../FilterSizeGuideModal';


const styles = StyleSheet.create({
  container: {
  },
  sizeStandardSelector: {
    marginTop: 18,
    marginBottom: 33,
  },
  sizeSelector: {
  },
  sectionContainer: {
    marginTop: 30,
    marginHorizontal: Constants.viewHorizontalPadding,
  },
  sizeHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sectionTitle: {
    ...Styles.pretendard600,
    fontSize: 14,
    lineHeight: 18,
    height: 18,
    color: Colors.defaultGray08,
  },
  sectionGuide: {
  },
  sectionGuideTitle: {
    ...Styles.pretendard400,
    fontSize: 14,
    lineHeight: 16.8,
    height: 17,
  },
});


class FilterSizeModal extends FilterBaseModal {

  constructor(props) {
    super(props);

    this.state.updateToggle = false;
  }

  title = '사이즈'

  componentDidMount() {
    const { selectedSizeList, logEventPageview } = this.props;
    this.setModalState({ selectedSizeList });

    logEventPageview({ page_name: 'FilterSize' });
  }

  getModalState() {
    const {
      modalProps,
    } = this.props;

    const {
      filterSizeModalState = {
        selectedSizeStandard: {
          clothing: SizeData.SIZE_STANDARD_LIST.filter((item) => item.subType === 'clothing')[0],
          shoes: SizeData.SIZE_STANDARD_LIST.filter((item) => item.subType === 'shoes')[0],
        },
        selectedSizeList: [],
      },
    } = modalProps();

    // console.log(`filterMainModalState=${JSON.stringify(filterMainModalState)}`);

    return filterSizeModalState;
  }

  setModalState(newState, callback) {
    // console.log(`setModalState newState=${JSON.stringify(newState)}`);
    const filterSizeModalState = this.getModalState();

    const {
      setModalProps,
    } = this.props;

    setModalProps({
      filterSizeModalState: {
        ...filterSizeModalState,
        ...newState,
      },
    }, () => {
      callback && callback();
      const { updateToggle } = this.state;
      this.setState({ updateToggle: !updateToggle });
    });
  }

  onPressApplyButton() {
    const { onChange } = this.props;
    const { selectedSizeList } = this.getModalState();

    const {
      modalProps,
      setModalProps,
    } = this.props;

    const {
      historyStack=[],
    } = modalProps();

    const children = historyStack.pop();

    setModalProps({
      children,
      historyStack,
    }, () => {
      onChange(selectedSizeList);
    });
  }

  getSizeList(subType) {
    const sizeList = SizeData.SIZE_LIST;

    return sizeList.filter((item) => item.subType === subType);
  }

  showSizeGuide(subType) {
    showFilterSizeGuideModal(this, subType);
  }

  onChangeSizeStandard = (subType) => (item, callback) => {
    const { selectedSizeStandard } = this.getModalState();

    selectedSizeStandard[subType] = item;

    this.setModalState({ selectedSizeStandard }, callback);
  }

  onChangeSelectedSizeList(subType, items) {
    const { selectedSizeList } = this.getModalState();

    const newSelectedSizeList = selectedSizeList.filter((item) => item.subType !== subType);

    this.setModalState({
      selectedSizeList: [
        ...newSelectedSizeList,
        ...items,  
      ],
    });
  }

  renderSection(title, subType) {
    const {
      modalProps,
      setModalProps,
    } = this.props;

    const { selectedSizeStandard, selectedSizeList } = this.getModalState();
    const sizeStandardList = SizeData.SIZE_STANDARD_LIST.filter((item) => item.subType === subType);
    const sizeItems = SizeData.SIZE_LIST.filter((item) => item.subType === subType);

    const selectedItems = selectedSizeList.filter((item) => item.subType === subType);

    return (
      <View style={styles.sectionContainer}>
        <View style={styles.sizeHeader}>
          <Text style={styles.sectionTitle}>{title} 사이즈</Text>
          <TouchableHighlightWithContainer
            style={styles.sectionGuide}
            onPress={() => this.showSizeGuide(subType)}
          >
            <Text style={styles.sectionGuideTitle}>사이즈 가이드</Text>
          </TouchableHighlightWithContainer>
        </View>

        <SizeStandardSelector
          containerStyle={styles.sizeStandardSelector}
          sizeStandardList={sizeStandardList}
          selectedSizeStandard={selectedSizeStandard[subType]}
          onChange={this.onChangeSizeStandard(subType).bind(this)}
          setModalProps={setModalProps}
          modalProps={modalProps}
          />

        <SizeSelector
          containerStyle={styles.sizeSelector}
          sizeItems={sizeItems}
          selectedSizeList={selectedItems}
          onChange={(items) => this.onChangeSelectedSizeList(subType, items)}
          selectedSizeStandard={selectedSizeStandard[subType]}
          />
      </View>
    );
  }

  renderBody() {
    return (
      <ScrollView
        style={styles.container}
        bounces={false}
        >
        {this.renderSection('옷', 'clothing')}
        {this.renderSection('신발', 'shoes')}
      </ScrollView>
    )
  }
}


export default connect(
  null,
  actions,
)(FilterSizeModal);
