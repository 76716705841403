import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  GridList,
  GridListTile,
  GridListTileBar,
} from '@material-ui/core';
import InstagramProfile from '../InstagramProfile';

export default class InstagramSearchDialog extends Component {
  render() {
    const { classes, cols, onCloseButtonClicked, onProfileClicked, open, users } = this.props;
    return (
      <Dialog
        title="인플루언서"
        open={open}
        maxWidth="md"
      >
        <GridList cols={cols} cellHeight="auto">
          {users.map(user => (
            <GridListTile
              key={user.instagram.id}
              onClick={() => onProfileClicked(user)}
              className={classes.gridListTile}
            >
              <InstagramProfile instagramId={user.username} style={{ width: 240 }} />
              <GridListTileBar
                title={user.username}
              />
            </GridListTile>
          ))}
        </GridList>
        <DialogActions>
          <Button
            className={classes.cancelConfirmButton}
            onClick={onCloseButtonClicked}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

InstagramSearchDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  cols: PropTypes.number,
  onCloseButtonClicked: PropTypes.func.isRequired,
  onProfileClicked: PropTypes.func,
  open: PropTypes.bool,
  users: PropTypes.array,
};

InstagramSearchDialog.defaultProps = {
  cols: 4,
  onProfileClicked: () => {},
  open: false,
  users: [],
};
