import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from 'components/Spinner';
import { loadEntities } from 'react-shared';
import * as adminActions from 'react-shared/adminActions';
import { Link } from 'react-router';
import Sortable from 'react-sortablejs';
import MDCuration from '../../../components-v2/Admin/MDCurationItem';
import PreorderExhibition from '../../../components-v2/Admin/PreorderExhibitionItem';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import moment from 'moment';
import 'moment/locale/ko';

moment.locale('ko');


const styles = {
  container: {
    position:'relative',
    margin: '0 auto',
    width: '100%',
    maxWidth: '1200px',
  },
  add_link :{
    position:'absolute',
    right:'0px',
    top:'24px'
  },
  add_button :{
    fontSize:'16px'
  },
  top_header:{
    position:'relative',
    width:'100%',
    minHeight:'120px',
    marginBottom:'24px'
  },
  top_container:{
    position:'relative',
    width:'100%',
    padding:'18px 0px',
  },
  top_container_box:{
    border:'1px solid #aaaaaa',
    borderRadius:'6px',
    padding:'12px'
  },
  top_header_title : {
    fontSize:'14px',
    color:'#444444'
  },
  top_header_value : {
    fontSize:'17px',
    fontWeight:'900',
    color:'#444444'
  }
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export class MDCurationPage extends Component {

  constructor(props){
    super(props);
    this.handleSortable = this.handleSortable.bind(this);
    this.handleWithPre = this.handleWithPre.bind(this);
    this.handleUpdateSort = this.handleUpdateSort.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      sortable : false,
      with_pre : false,
      sorting : [],
      sorting_full : [],
      open:false
    };
  }

  componentDidMount() {
    const { adminMdCurations, adminLoadMdCurations } = this.props;
    if (!adminMdCurations || adminMdCurations.length === 0) {
      adminLoadMdCurations();
    }

    const { adminPreorderExhibitions, adminLoadPreorderExhibitions } = this.props;
    if (!adminPreorderExhibitions || adminPreorderExhibitions.length === 0) {
      adminLoadPreorderExhibitions();
    }
  }

  handleSortable(event) {

    const { adminMdCurations, adminPreorderExhibitions } = this.props;

    const _adminMdCurations = adminMdCurations.map((el) => ({
      ...el,
      t : "curation"
    }));

    const _adminPreorderExhibitions = adminPreorderExhibitions.map((el) => ({
      ...el,
      t : "preorder",
      fromNow : (moment().isAfter(moment(el.preorderEndDate*1000)) ? -1 :
                 moment().isBefore(moment(el.preorderStartDate*1000)) ? 1 : 0)
    }));

    const fullList = [..._adminMdCurations, ..._adminPreorderExhibitions].sort(
      (a, b) => {
        if(!a.visible && b.visible){
          return 1;
        }
        if(!b.visible && a.visible){
          return -1;
        }
        if(a.sorting !== b.sorting){
          return a.sorting - b.sorting;
        }else{
          return b.created - a.created;
        }
      },
    );

    this.setState( {
      sortable : event.target.checked,
      sorting : event.target.checked && [...adminMdCurations],
      sorting_full : event.target.checked && [...fullList]
    });
  }

  handleWithPre(event) {
    const { adminMdCurations } = this.props;

    this.setState( {
      with_pre : event.target.checked,
    });
  }

  handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({open:false});
  };

  handleUpdateSort(event){
    const { adminUpdateMdCurations, adminLoadMdCurations, adminMdCurations,
    adminLoadPreorderExhibitions, adminUpdateFullCurations} = this.props;
    const { sorting, sorting_full, with_pre } = this.state;
    if(with_pre){
      adminUpdateFullCurations(sorting_full).then(() => {
        adminLoadMdCurations().then(() => {
          adminLoadPreorderExhibitions().then(() => {
            this.setState({open:true});
          });
        });
      });
    }else{
      adminUpdateMdCurations(sorting).then(() => {
        adminLoadMdCurations().then(() => {
          this.setState({open:true});
        });
      });
    }
  }

  handleUpdate(event) {
    const { onUpdate, adminMdCurations } = this.props;
    const { sorting, sorting_full, with_pre } = this.state;

    const { oldIndex, newIndex } = event;
    if(with_pre){
      const ordered = sorting_full.slice();
      ordered.splice(newIndex, 0, ordered.splice(oldIndex, 1)[0]);
      ordered.forEach((el, index) => {
        el.sorting = index;
      });

      this.setState(prevState => ({
        sorting_full : [...ordered]
      }));
    }else{
      const ordered = sorting.slice();
      ordered.splice(newIndex, 0, ordered.splice(oldIndex, 1)[0]);
      ordered.forEach((el, index) => {
        el.sorting = index;
      });

      this.setState(prevState => ({
        sorting : [...ordered]
      }));
    }
  }

  render() {
    const {
      adminMdCurations,
      adminPreorderExhibitions,
      pagination: { isFetching },
    } = this.props;

    const {
      sortable,
      sorting,
      open,
      with_pre,
      sorting_full
    } = this.state;

    const _adminMdCurations = adminMdCurations.map((el) => ({
      ...el,
      t : "curation"
    }));

    const _adminPreorderExhibitions = adminPreorderExhibitions.map((el) => ({
      ...el,
      t : "preorder",
      fromNow : (moment().isAfter(moment(el.preorderEndDate*1000)) ? -1 :
                 moment().isBefore(moment(el.preorderStartDate*1000)) ? 1 : 0)
    }));

    const mdCurationsList = adminMdCurations.sort(
      (a, b) => {
        if(!a.visible && b.visible){
          return 1;
        }
        if(!b.visible && a.visible){
          return -1;
        }
        if(a.sorting !== b.sorting){
          return a.sorting - b.sorting;
        }else{
          return b.created - a.created;
        }
      },
    );

    const fullList = [..._adminMdCurations, ..._adminPreorderExhibitions].sort(
      (a, b) => {
        if(!a.visible && b.visible){
          return 1;
        }
        if(!b.visible && a.visible){
          return -1;
        }
        if(a.sorting !== b.sorting){
          return a.sorting - b.sorting;
        }else{
          return b.created - a.created;
        }
      },
    );

    return (
      <div style={styles.container}>
        <Container sx={styles.top_container}>
        <Stack
          sx={styles.top_header}
          justifyContent="flex-start"
          alignItems="center"
          direction="row" spacing={2}>
          <Stack
            sx={styles.top_container_box}
            direction="column" spacing={2}>
            <Typography sx={styles.top_header_title}>
              총 기획전
            </Typography>
            <Typography sx={styles.top_header_value}>
              {mdCurationsList.length}
            </Typography>
          </Stack>
          <Stack
            sx={styles.top_container_box}
            direction="column" spacing={2}>
            <Typography sx={styles.top_header_title}>
              활성화된 기획전
            </Typography>
            <Typography sx={styles.top_header_value}>
              {mdCurationsList.filter((el) => el.visible).length}
            </Typography>
          </Stack>
          <Stack
            direction="column" spacing={0}>
            <FormControlLabel control={<Checkbox
              checked={with_pre}
              onChange={this.handleWithPre}/>} label="프리오더 까지" />
            <FormControlLabel
              control={
                <Switch
                  checked={sortable}
                  onChange={this.handleSortable} />}
              label="순서 변경" />
              {sortable &&
                <Button
                  onClick={this.handleUpdateSort}
                  variant="outlined">저장하기</Button>}
          </Stack>
        </Stack>


        <Grid sx={{width:'100%'}} container spacing={2}>
        {sortable ?  (
          <Grid container spacing={2}>
          <Sortable
            md={12}
            style={{
              width:'100%',
              display:'flex',
              flexWrap: "wrap",
              flexDirection: "row"
            }}
            option={{ handle: '.handle' }}
            onChange={(order, sortable, event) => this.handleUpdate(event)}
          >

          {with_pre ? (sorting_full.length > 0
            && sorting_full.map(mdCuration => (
              (mdCuration.t === "curation") ?
              <MDCuration
                isSortable={true}
                mdCuration={mdCuration}
                key={`mdCuration_${mdCuration.id}`}
              /> :
              <PreorderExhibition
                isSortable={true}
                preorderExhibition={mdCuration}
                key={`preorderExhibition_${mdCuration.id}`}
              />
            ))) : (sorting.length > 0
            && sorting.map(mdCuration => (
              <MDCuration
                isSortable={true}
                mdCuration={mdCuration}
                key={`mdCuration_${mdCuration.id}`}
              />
            )))}

          </Sortable>
          </Grid>
        )
        : with_pre ?
        (fullList.length > 0
          && fullList.map(mdCuration => (
            (mdCuration.t === "curation") ? (<MDCuration
              isSortable={false}
              mdCuration={mdCuration}
              key={`mdCuration_${mdCuration.id}`}
            />) : (
              <PreorderExhibition
                isSortable={false}
                preorderExhibition={mdCuration}
                key={`preorderExhibition_${mdCuration.id}`}
              />
            )
          )))
        : (mdCurationsList.length > 0
          && mdCurationsList.map(mdCuration => (
            <MDCuration
              isSortable={false}
              mdCuration={mdCuration}
              key={`mdCuration_${mdCuration.id}`}
            />
          )))}
        </Grid>
        {isFetching && <Spinner />}
        <Link
          style={styles.add_link}
          to="/admin/mdCurations/new">
          <Button
            size="large"
            sx={styles.add_button}
            variant="outlined">
            기획전 추가
          </Button>
        </Link>
        </Container>

        <Snackbar open={open} autoHideDuration={6000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="success" sx={{ width: '100%' }}>
            성공적으로 순서가 변경되었습니다
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

MDCurationPage.propTypes = {
  adminLoadMdCurations: PropTypes.func.isRequired,
  adminUpdateMdCurations: PropTypes.func.isRequired,
  adminUpdateFullCurations : PropTypes.func.isRequired,
  adminMdCurations: PropTypes.array,

  adminLoadPreorderExhibitions: PropTypes.func.isRequired,
  adminUpdatePreorderExhibitions: PropTypes.func.isRequired,
  adminPreorderExhibitions: PropTypes.array,
  // pagination: PropTypes.object,
};

MDCurationPage.defaultProps = {
  adminMdCurations: {},
  adminPreorderExhibitions: {},
};

export const mapStateToProps = state => ({
  ...loadEntities(state, 'adminMdCurations', 'adminMdCurations'),
  ...loadEntities(state, 'adminPreorderExhibitions', 'adminPreorderExhibitions'),
});

export default connect(
  mapStateToProps,
  adminActions,
)(MDCurationPage);
