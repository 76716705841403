import React from 'react';
import { StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import { actions, loadEntities } from 'shared';
import { Waypoint } from 'react-waypoint';

import HomeFeedSectionHeader from './HomeFeedSectionHeader';
import { navigateToProductPage } from '../../../shop/ProductPage';
import { convertMongoProductToGodoShopListProduct } from 'shared/util/product';

import { Products } from '../../../../components-v2';
import { Colors } from '../../../../constants';


const styles = StyleSheet.create({
  container: {
  },
  productListContainerStyle: {
    margin: 0,
  },
  productContainerStyle: {
    width: 78,
  },
  productImageStyle: {
    width: 78,
    aspectRatio: 78/103,
    borderWidth: 0.5,
    borderColor: Colors.productImageBorder,
    overflow: 'hidden',
  },
  productTextContainer: {
    height: 0,
  },
});


class FeedCategorySaleList extends React.PureComponent {
  products1Ref = React.createRef();
  products2Ref = React.createRef();
  containerRef = React.createRef();

  state = {
    page: 0,
    limit: 30,
    products: [],
    hasNext: false,
    loading: false,
    categorySale: null,
  }

  componentDidMount() {
    const { categorySale } = this.props;
    this.setState({ categorySale }, () => this.loadProducts(true));
  }

  componentDidUpdate(prevProps, prevState) {
    const { categorySale } = this.props;
    const { categorySale: prevCategorySale } = prevProps;

    if (!prevCategorySale && categorySale || prevCategorySale._id != categorySale._id) {
      this.setState({ categorySale: { ...categorySale } }, () => this.loadProducts(true, true));
    }
  }

  loadProducts = async (refresh, forced = false) => {
    const { getCategorySaleProducts } = this.props;
    let { categorySale, loading, hasNext, page, limit } = this.state;

    if (!forced && (loading || (!refresh && !hasNext))) {
      return;
    }

    if (refresh) {
      page = 0;
    } else {
      page += 1;
    }
    const query = {
      page,
      limit
    };

    await new Promise((resolve, reject) => {
      this.setState({
        ...query,
        loading: true,
      }, () => resolve());
    });

    const result = await getCategorySaleProducts(categorySale._id, query);
    this.setState(prev => {
      const { products: prevProducts = []} = prev;
      let products = [];
      if (refresh) {
        products = [...result.data];
      } else {
        products = [...prevProducts, ...result.data];
      }

      return {
        ...prev,
        products,
        loading: false,
        page,
        hasNext: result.hasNext,
      };
    });
  }

  onScroll = ({ nativeEvent }) => {
    const { contentOffset: {x, y}, contentSize, layoutMeasurement } = nativeEvent;
    const near = 500;
    if (x + layoutMeasurement.width >= contentSize.width - near) {
      this.loadProducts(false);
    }
  }

  render() {
    const {
      title,
      description,
      onPressSeeAll=null,
    } = this.props;
    let { categorySale, products } = this.state;

    if (!categorySale) {
      return (<View />);
    }

    const headerProps = {}
    if (onPressSeeAll != null) {
      headerProps.showSeeAll = true
      headerProps.onPressSeeAll = onPressSeeAll
    }

    const onPressProduct = item => {
      const { router, logEventClick } = this.props;
      const productId = item.godoGoodsNo ? item.godoGoodsNo : item.goodsNo;
      logEventClick({
        page_name: 'Home',
        page_key: title,
        click_object_name: 'product',
        click_object_key: productId,
      });
      navigateToProductPage({ productId }, router);
    };

    products = products.map(p => convertMongoProductToGodoShopListProduct(p));

    return (
      <View key={`${categorySale._id}`} style={styles.container}>
        <HomeFeedSectionHeader
          title={title}
          description={description}
          {...headerProps}
        />
        <Products
          key={`${categorySale._id}-odd`}
          ref={ref => this.products1Ref = ref }
          horizontal
          products={products.filter((p, idx) => idx % 2 === 0)}
          onPressProduct={onPressProduct}
          listHeaderComponentStyle={{ width: 20 }}
          listFooterComponentStyle={{ width: 20 }}
          itemContainerStyle={styles.productContainerStyle}
          containerStyle={styles.productListContainerStyle}
          productImageStyle={styles.productImageStyle}
          productTextContainerStyle={styles.productTextContainer}
          productViewType={1}
          smallVersionImageTextSpace={0}
          name={title}
          onScroll={(evt) => this.onScroll(evt)}
        />
        <View style={{ height: 3 }}/>
        <Products
          key={`${categorySale._id}-even`}
          ref={ref => this.products2Ref = ref }
          horizontal
          products={products.filter((p, idx) => idx % 2 === 1)}
          onPressProduct={onPressProduct}
          listHeaderComponentStyle={{ width: 20 }}
          listFooterComponentStyle={{ width: 20 }}
          itemContainerStyle={styles.productContainerStyle}
          containerStyle={styles.productListContainerStyle}
          productImageStyle={styles.productImageStyle}
          productTextContainerStyle={styles.productTextContainer}
          smallVersionImageTextSpace={0}
          productViewType={1}
          name={title}
          onScroll={(evt) => this.onScroll(evt)}
        />
      </View>
    );
  }
}


export default connect(
  (state) => {
    return {
    };
  },
  actions,
  null,
  { withRef: true }
)(FeedCategorySaleList);
