import React from 'react';
import { connect } from 'react-redux';

import * as adminActions from 'react-shared/adminActions';

import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

import '../../../../css/datepicker.css';
import '../../../../css/admin/datepicker.css';

import StatsSnapshotEventList from './StatsSnapshotEventList'


const styles = {
  dataPickerContainer: {
    margin: '10px',
  },
  dataPickerItemContainer: {
    float: 'left',
  },
};

const MAX_DATE = dayjs().toDate();


class StatsSnapshotPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: dayjs().subtract(7, 'day').toDate(),
      endDate: MAX_DATE,
      allPeriod: false,
      timeUnit: 'dau',
      kind: 'wishlist',
    };
  }

  static getDays(startDate, endDate) {
    return parseInt((endDate - startDate + 20000) / 86400000, 10) + 1;
  }

  handleDateChange(date, which) {
    this.setState({
      [which]: date,
    });
  }

  handleAllPeriodChange() {
    this.setState(prevState => ({ allPeriod: !prevState.allPeriod }));
  }

  renderDatePicker() {
    const { startDate, endDate, allPeriod } = this.state;

    return (
      <div style={styles.dataPickerContainer}>
        <div style={styles.dataPickerItemContainer}>
          <DatePicker
            dateFormat="yyyy-MM-dd"
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={date => this.handleDateChange(date, 'startDate')}
            disabled={allPeriod}
            selectsStart
            maxDate={MAX_DATE}
          />
        </div>
        <div style={styles.dataPickerItemContainer}>
        ~
        </div>
        <div style={styles.dataPickerItemContainer}>
          <DatePicker
            dateFormat="yyyy-MM-dd"
            selected={endDate}
            startDate={startDate}
            endDate={endDate}
            onChange={date => this.handleDateChange(date, 'endDate')}
            disabled={allPeriod}
            selectsEnd
            minDate={startDate}
            maxDate={MAX_DATE}
          />
        </div>
        <div style={styles.dataPickerItemContainer}>
          &nbsp;
          <input type="checkbox" checked={allPeriod} onChange={() => this.handleAllPeriodChange()} />
          전체 기간 검색
        </div>
      </div>
    );
  }

  renderContent() {
    const { allPeriod, startDate, endDate, kind } = this.state;

    const startDateStr = allPeriod
      ? dayjs('2019-07-05').format('YYYY-MM-DD')
      : dayjs(startDate).format('YYYY-MM-DD');

    const endDateStr = allPeriod
      ? dayjs(MAX_DATE).format('YYYY-MM-DD')
      : dayjs(endDate).format('YYYY-MM-DD');

      return (
      <StatsSnapshotEventList
        kind={kind}
        startDate={startDateStr}
        endDate={endDateStr}
        />
    );
  }

  renderBody() {
    const { kind } = this.state;

    return (
      <div style={{ clear: 'both' }}>
          <div style={{ marginTop: '10px', marginLeft: '20px' }}>
            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>현황</span> &nbsp;&nbsp;
            <select onChange={event => this.setState({ kind: event.target.value })} value={kind}>
              <option value="wishlist">위시리스트</option>
              <option value="cart">카트</option>
            </select>
            {this.renderContent()}
        </div>
      </div>
    );
  }

  render() {

    return (
      <div class="dashboard">
        {this.renderDatePicker()}
        {this.renderBody()}
      </div>
    );
  }
}


export default connect(
  state => ({
    snapshotCount: state.admin.snapshotCount,
  }),
  adminActions,
) (StatsSnapshotPage);
