import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadEntities } from 'react-shared';
import { compose } from 'recompose';
import { camelizeKeys } from 'humps';
import dayjs from 'dayjs';
import Flatpickr from 'react-flatpickr';

import { SizeData, SortingRule } from '../../constants';

// import { browserHistory } from 'react-router';
// all codes using browserHistory are legacy in react-router v3;
// anyways, the solution preventing admins from accidently clicking backbutton, reload, or close window
// eslint-disable-next-line max-len
// I follow the https://stackoverflow.com/questions/32841757/detecting-user-leaving-page-with-react-router/50388073#50388073
// but this.props.route.path doesn't have exact path, I instead used this.props.router.location.pathname
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControlLabel,
  Paper,
  Select,
  MenuItem,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  ItemList,
  ProductSelector,
} from 'components/admin';

import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import FiberNew from '@material-ui/icons/FiberNew';
import ExpandMore from '@material-ui/icons/ExpandMore';
import has from 'lodash/has';
import pick from 'lodash/pick';
import without from 'lodash/without';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import findIndex from 'lodash/findIndex';

import * as adminActions from 'react-shared/adminActions';

import {
  objectDiff,
} from 'react-shared/util';

import config from '../../config';

const styles = theme => ({
  cancelConfirmButton: {

  },
  createPostButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  deletePostButton: {
    position: 'absolute',
    right: theme.spacing(12),
    top: theme.spacing(2),
  },
  snackbar: {
    textAlign: 'center',
  },
  formControlLabel: {

  },
  select: {
    width: 200,
  },
  crawlerSelect: {
    flexGrow: 1,
  },
  link: {
    width: '100%',
  },
  crawlerContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  crawlerSelectorContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  priceKeywordContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  priceRangeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  priceInput: {
    flexGrow: 1,
  },
  filterKeywordInput: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  outerButton: {
    marginRight: theme.spacing(2),
  },
  subMenuTitles: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    minHeight: 50,
  },
  thumbnailArea: {
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'table-cell',
  },
  filterContainer: {
    borderWidth: 0.5,
    borderColor: '#afadad',
    borderStyle: 'solid',
    marginRight: 3,
  },
  filterTypeText: {
    fontSize: 12,
  },
  filterNameText: {
    fontSize: 14,
    marginRight: 8,
  },
  productCaption: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  productCaptionName: {
    fontSize: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginBottom: 5,
  },
});

const sortByList = SortingRule.SORTING_RULE_EXHIBITION;

class CategorySalePage extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAddFilter = this.handleAddFilter.bind(this);
    this.handleAddProduct = this.handleAddProduct.bind(this);
    this.handleAddProductSubmit = this.handleAddProductSubmit.bind(this);
    this.renderProductCaption = this.renderProductCaption.bind(this);
    this.state = {
      weight: 0,
      title: '',
      subtitle: '',
      description: '',
      visible: true,
      isUpdatingPost: false,
      // Filter
      category: 'none',
      categoryList: [],
      sortByValue: sortByList[0].value,
      sortByList,
      brandList: [],
      filterPriceLow: null,
      filterPriceHigh: null,
      filterKeyword: '',
      filterCrawler: null,
      filterList: [],
      newFilterList: [],
      // Products
      products: [],
      crawlerList: [],
      checkedProducts: {},
      newCheckedProducts: {},
      isProductDialogOpen: false,
    };
  }

  async componentDidMount() {
    const { adminGetCrawlerList, adminGetCategorySale, categorySaleId, categorySale, route, router } = this.props;
    const { getProductCategoryList, loadShopBrandList } = this.props;

    if (categorySaleId !== 'new') {
      if (categorySale) {
        const checkedProducts = {};
        categorySale.products.forEach((product) => {
          checkedProducts[product._id] = product;
        });
        await this.setState({
          ...categorySale,
          checkedProducts,
          sortByValue: categorySale.sortBy.value,
        });
      } else {
        await adminGetCategorySale(categorySaleId)
          .then(categorySaleData => {
            const checkedProducts = {};
            categorySaleData.products.forEach((product) => {
              checkedProducts[product._id] = product;
            });
            this.setState({
              ...categorySaleData,
              checkedProducts,
              sortByValue: categorySaleData.sortBy.value,
            })
          });
        // Todo: route to admin/posts when there's no post with such categorySaleId 
      }
    }
    const { data: crawlerList } = await adminGetCrawlerList();
    const brandData = await loadShopBrandList();
    const brandList = brandData.data.map(d => ({
      id: d.cateCd,
      type: 'brand',
      name: d.cateNm,
    }));
    this.setState({ crawlerList, brandList });

    const { data: categoryData } = await getProductCategoryList();
    const categoryList = categoryData.reduce((acc, category) => {
      acc.push(category);
      if (category.children) {
        category.children.forEach(subcategory => {
          subcategory.cateNm = `${category.cateNm} > ${subcategory.cateNm}`;
          acc.push(subcategory)
        });
      }
      return acc;
    }, []);
    this.setState({ categoryList });
    if (categoryList.length > 0 && this.state.category === 'none') {
      const category = categoryList[0].cateCd;
      this.setState({ category });
    }

    this.saveState();
    window.addEventListener('resize', () => this.handleResize());
    window.addEventListener('beforeunload', event => this.handleClose(event));
    this.mutationObserver = new MutationObserver(() => this.removeBodyStyle());
    this.mutationObserver.observe(document.body, {
      attributes: true,
      attributeFilter: ['style'],
    });
    router.setRouteLeaveHook(route, () => this.handleBackbutton());
  }

  componentDidUpdate(prevProps, prevState) {
    const { categorySaleId } = this.props;
    if (prevProps.categorySaleId !== categorySaleId && categorySaleId === 'new') {
      window.location.reload();
    }
  }

  componentWillUnmount() {
    this.mutationObserver.disconnect();
    window.removeEventListener('resize', () => this.handleResize());
    window.removeEventListener('beforeunload', event => this.handleClose(event));
  }

  saveState() {
    this.savedState = cloneDeep(this.state);
  }

  hasCategorySaleEdited() {
    return !!Object.keys(objectDiff(this.savedState, this.state)).length;
  }

  showSnackbar(message) {
    this.setState({
      snackbarMsg: message,
      snackbarOpen: true,
    });
  }

  handleClose(event) {
    if (this.hasCategorySaleEdited()) {
      event.preventDefault();
      event.returnValue = '카테고리 세일을 작성중이시네요, 정말 창을 닫으시겠어요?';
      return event.returnValue;
    }
    return event;
  }

  removeBodyStyle() { // FIX!
    const {
      isFilterDialogOpen: filter,
      isProductDialogOpen: product,
    } = this.state;
    if (!filter && !product && document.body.style.overflow === 'hidden') {
      document.body.style.overflow = '';
    }
  }

  handleBackbutton() {
    const { router: { location: { pathname } } } = this.props;
    if (this.hasCategorySaleEdited()) {
      // eslint-disable-next-line no-restricted-globals
      const toBeClosed = confirm('카테고리 세일을 작성중이시네요, 정말 창을 닫으시겠어요?');
      if (!toBeClosed) {
        window.history.pushState(null, null, pathname);
        return false;
      }
    }
    return true;
  }

  handleResize() {
    if (window.innerWidth <= 600 && this.state.tilesInRow === 4) {
      this.setState({ tilesInRow: 1 });
    } else if (window.innerWidth > 600 && this.state.tilesInRow === 1) {
      this.setState({ tilesInRow: 4 });
    }
  }

  handleTitleChange(event) {
    this.setState({ title: event.target.value });
  }

  handleSubtitleChange(event) {
    this.setState({ subtitle: event.target.value });
  }

  handleDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  handleVisibleChange(event) {
    this.setState({ visible: event.target.value });
  }

  handleSubmit(event) {
    const fields = [
      'weight', 'title', 'subtitle', 'description',
      'products', 'category', 'filterList', 'visible'
    ];
    const categorySale = pick(this.state, fields);
    categorySale.sortBy = sortByList.filter(s => s.value === this.state.sortByValue)[0];
    const { adminLoadCategorySales, adminCreateCategorySale, adminUpdateCategorySale, categorySaleId, router } = this.props;

    if (!categorySale.title) {
      alert('제목을 입력해주세요.');
      return;
    }
    if (!categorySale.description) {
      alert('설명을 입력해주세요.');
      return;
    }
    if (categorySaleId === 'new') {
      this.setState(
        { isUpdatingPost: true },
        () => adminCreateCategorySale(categorySale)
          .then(() => this.saveState())
          .then(() => adminLoadCategorySales()) // weight 변경 적용
          .then(() => {
            this.showSnackbar('카테고리 세일을 생성했습니다.');
          })
          .catch(() => this.showSnackbar('카테고리 세일 생성에 실패했습니다.'))
          .then(() => router.push('/admin/categorySales'))
      );
    } else {
      this.setState(
        { isUpdatingPost: true },
        () => adminUpdateCategorySale(categorySaleId, categorySale)
          .then(() => this.setState({ isUpdatingPost: false }, () => this.saveState()))
          .then(() => adminLoadCategorySales()) // weight 변경 적용
          .then(() => this.showSnackbar('카테고리 세일을 업데이트 했습니다.'))
          .catch(() => this.showSnackbar('카테고리 세일 업데이트에 실패했습니다.'))
          .finally(() => this.setState({ isUpdatingPost: false }))
      );
    }
    event.preventDefault();
  }

  handleAddFilter() {
    const { filterList } = this.state;
    const newFilterList = [...filterList];
    const filterCrawler = filterList.filter(f => f.type === 'crawler');
    const filterPrice = filterList.filter(f => f.type === 'price');
    this.setState({
      newFilterList,
      isFilterDialogOpen: true,
      filterCrawler: (filterCrawler.length > 0) ? filterCrawler[0].name: null,
      filterPriceLow: (filterPrice.length > 0) ? filterPrice[0].name.split('~')[0].trim(): null,
      filterPriceHigh: (filterPrice.length > 0) ? filterPrice[0].name.split('~')[1].trim(): null,
    });
  }

  handleAddProduct() {
    const { checkedProducts } = this.state;
    const newCheckedProducts = { ...checkedProducts };
    this.setState({ newCheckedProducts, isProductDialogOpen: true });
  }

  handleAddProductSubmit() {
    const { products: prevProducts, checkedProducts, newCheckedProducts } = this.state;
    let products = cloneDeep(prevProducts);
    const oldKeys = Object.keys(checkedProducts);
    const newKeys = Object.keys(newCheckedProducts);

    const deletedKeys = difference(oldKeys, newKeys);
    deletedKeys.forEach((key) => {
      delete checkedProducts[key];
      products = products.filter(product => (product._id !== key));
    });

    newKeys.forEach((key) => {
      const newProduct = cloneDeep(newCheckedProducts[key]);
      checkedProducts[key] = newProduct;
      const productIdx = findIndex(products, ['_id', key]);
      if (productIdx >= 0) {
        const oldProduct = products[productIdx];
        products[productIdx] = newProduct;
      } else {
        products.push(newProduct);
      }
    });

    this.setState({
      products,
      checkedProducts,
      newCheckedProducts: {},
      isProductDialogOpen: false,
    });
  }

  renderWeight() {
    const { categorySaleId, adminCategorySales = [], classes } = this.props;
    let { weight } = this.state;

    let categorySalesTotalCount = adminCategorySales.length;
    if (categorySaleId === 'new') {
      categorySalesTotalCount += 1;
    }
    return (
      <Paper className={classes.paper}>
        <Typography variant="h6" className={classes.subMenuTitles}>배치 순서</Typography>
        <Typography variant="subheading">
          순서 선택:&nbsp;&nbsp;
          <Select
            className={classes.select}
            value={weight}
            onChange={event => {
              this.setState({
                weight: event.target.value,
              });
            }}
          >
            {Array.from(Array(categorySalesTotalCount).keys()).map(w => (
              <MenuItem value={w} key={w}>
                <Typography variant="subheading">{w + 1}</Typography>
              </MenuItem>
            ))}
          </Select>
        </Typography>
      </Paper>
    );
  }

  renderProductCaption(product, index) {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.productCaptionName}>{product.name}</div>
        <div className={classes.productCaption}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              window.open(`/admin/products/${product._id}`, '_blank');
            }}
          >
            <Edit />
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              this.setState(prevState => {
                const checkedProducts = prevState.checkedProducts;
                delete checkedProducts[product._id];
                return {
                  products: without(prevState.products, product),
                  checkedProducts,
                };
              });
            }}
          >
            <Delete color="error" />
          </Button>
        </div>
      </div>
    );
  }

  renderActiveFilter(filterListKey) {
    const { classes } = this.props;
    const { [filterListKey]: filterList } = this.state;
    return (
      <Grid container style={{ paddingBottom: 10 }}>
        {filterList.map((filter, idx) => {
          const filterType = ((type) => {
            if (type === 'brand') {
              return '브랜드';
            } else if (type === 'category') {
              return '카테고리'
            } else if (type === 'price') {
              return '가격'
            } else if (type === 'keyword') {
              return '키워드'
            } else if (type === 'crawler') {
              return '크롤링 사이트'
            } else {
              return '알수없음';
            }
          })(filter.type)
          return (
            <Grid item key={`${idx}`} className={classes.filterContainer}>
              <Grid container alignItems='center'>
                <Grid item>
                  <IconButton
                    size='small'
                    onClick={() => {
                      filterList.splice(idx, 1);
                      const newState = {
                        [filterListKey]: filterList,
                        products: [],
                        checkedProducts: {},
                      };
                      if (filter.type === 'price') {
                        newState['filterPriceLow'] = null;
                        newState['filterPriceHigh'] = null;
                      }
                      this.setState(newState);
                    }}
                  >
                    <Close size='small' />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Grid container direction='column'>
                    <Grid item className={classes.filterTypeText}>{filterType}</Grid>
                    <Grid item className={classes.filterNameText}>{filter.name}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    );
  }

  renderBrandTable() {
    const { newFilterList, brandList } = this.state;
    const filteredBrandList = brandList
      .filter((b) => findIndex(newFilterList, function(f) { return (b.type !== 'brand' || f.id === b.id) }) < 0);
    return (
      <Table style={{ borderWidth: 0.5, borderColor: '#cfcfcf', borderStyle: 'solid', marginRight: 5 }}>
        <TableHead>
          <TableRow>
            <TableCell className=''></TableCell>
            <TableCell className=''>브랜드명</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredBrandList.map(brand => (
            <TableRow key={`brand_${brand.id}`}>
              <TableCell>
                <Button
                  onClick={() => {
                    const { newFilterList } = this.state;
                    const oldIdx = findIndex(
                      newFilterList,
                      function(f) { return (f.id === brand.id && f.type === 'brand') }
                    );
                    if (oldIdx < 0) {
                      newFilterList.push(brand);
                      this.setState(newFilterList);
                    }
                  }}
                >
                  <Add />
                </Button>
              </TableCell>
              <TableCell>
                {brand.name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  renderPriceRange() {
    const { classes } = this.props;
    const { newFilterList, filterPriceLow, filterPriceHigh } = this.state;

    return (
      <div className={classes.priceRangeContainer}>
        <TextField
          className={classes.priceInput}
          value={filterPriceLow}
          onChange={(event) => this.setState({filterPriceLow: event.target.value})}
          inputProps={{ type: 'number'}}
        />
        &nbsp;~&nbsp;
        <TextField
          className={classes.priceInput}
          value={filterPriceHigh}
          onChange={(event) => this.setState({filterPriceHigh: event.target.value})}
          inputProps={{ type: 'number'}}
        />

        <Button
          onClick={() => {
            const { newFilterList } = this.state;

            let name = `${filterPriceLow} ~ ${filterPriceHigh}`;
            if ((filterPriceHigh == null || filterPriceHigh.trim() == '') && (filterPriceLow == null || filterPriceLow.trim() == '')) {
              return;
            } else if (filterPriceLow == null || filterPriceLow.trim() == '') {
              name = ` ~ ${filterPriceHigh}`;
            } else if (filterPriceHigh == null || filterPriceHigh.trim() == '') {
              name = `${filterPriceLow} ~ `;
            } else {
              if (filterPriceLow > filterPriceHigh) {
                window.alert('가격 범위 하단은 상단보다 커야합니다.');
                return;
              }
            }

            const price = {
              type: 'price',
              name: name,
            }
            const oldIdx = findIndex(
              newFilterList,
              function(f) { return (f.type === 'price') }
            );
            if (oldIdx >= 0) {
              newFilterList.splice(oldIdx, 1);
            }
            newFilterList.push(price);
            this.setState(newFilterList);
          }}
        >
          <Add />
        </Button>
      </div>
    );
  }

  renderKeyword() {
    const { classes } = this.props;
    const { newFilterList, filterKeyword } = this.state;

    return (
      <div className={classes.priceRangeContainer}>
        <TextField
          className={classes.filterKeywordInput}
          value={filterKeyword}
          onChange={(event) => this.setState({filterKeyword: event.target.value})}
        />

        <Button
          onClick={() => {
            const { newFilterList } = this.state;

            if (filterKeyword == null || filterKeyword.trim() == '') {
              return;
            }
            let keywordSplited = filterKeyword.split(' ');
            keywordSplited = keywordSplited.filter(x => x.length > 0);
            keywordSplited = keywordSplited.reduce((prev, cur)  => {
              if (prev.filter(k => k.toLowerCase() == cur.toLowerCase()).length == 0) {
                return [...prev, cur];
              }
              return [...prev];
            }, []);
            let newKeywordList = keywordSplited.filter(newK => {
              return newFilterList.filter(k => k.type === 'keyword' && k.name.toLowerCase() == newK.toLowerCase()).length <= 0;
            });
            if (newKeywordList.length == 0) {
              window.alert('이미 등록된 키워드입니다.')
              return;
            }
            newKeywordList = newKeywordList.map(k => {
              return {
                type: 'keyword',
                name: k,
              };
            });
            this.setState({
              newFilterList: newFilterList.concat(newKeywordList),
              filterKeyword: '',
            });
          }}
        >
          <Add />
        </Button>
      </div>
    );
  }

  renderCrawler() {
    const { classes } = this.props;
    const { newFilterList, filterCrawler, crawlerList } = this.state;

    return (
      <div className={classes.crawlerContainer}>
        <div className={classes.crawlerSelectorContainer}>
          <Select
            className={classes.crawlerSelect}
            value={filterCrawler}
            onChange={event => {
              this.setState({
                filterCrawler: event.target.value,
              });
            }}
          >
            { crawlerList.map(crawler => {
              return (
                <MenuItem value={crawler} key={crawler}>
                  <Typography variant="subheading">{crawler}</Typography>
                </MenuItem>
              )
            })}
          </Select>
        </div>

        <Button
          onClick={() => {
            if (!filterCrawler || filterCrawler.length === 0) {
              return;
            }
            const { newFilterList } = this.state;

            const crawlerFilter = {
              type: 'crawler',
              name: filterCrawler,
            }
            const oldIdx = findIndex(
              newFilterList,
              function(f) { return (f.type === 'crawler') }
            );
            if (oldIdx >= 0) {
              newFilterList.splice(oldIdx, 1);
            }
            newFilterList.push(crawlerFilter);
            this.setState(newFilterList);
          }}
        >
          <Add />
        </Button>
      </div>
    );
  }

  renderFilterDialog() {
    const { classes } = this.props;
    const { newFilterList } = this.state;
    return (
      <Dialog open={this.state.isFilterDialogOpen} fullWidth maxWidth={'lg'} classes={{ paper: classes.dialogPaperProductList }}>
        <DialogTitle>
          필터 추가/수정
        </DialogTitle>
        <DialogContent>
          <Accordion defaultExpanded={true} style={{ marginTop: '16px' }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
            >
              <Typography>선택된 필터가 {newFilterList.length}개 있습니다.</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {this.renderActiveFilter('newFilterList')}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionDetails>
              <Grid container spacing={2} style={{ paddingBottom: 10 }}>
                <Grid item xs={8}>
                  <Typography>브랜드</Typography>
                  {this.renderBrandTable()}
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.priceKeywordContainer}>
                    <Typography>가격범위</Typography>
                    {this.renderPriceRange()}
                    <Typography style={{ marginTop: 40 }}>키워드</Typography>
                    {this.renderKeyword()}
                    <Typography style={{ marginTop: 40 }}>크롤링 사이트</Typography>
                    {this.renderCrawler()}
                  </div>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.cancelConfirmButton}
            onClick={() => {
              this.setState({ filterList: newFilterList, newFilterList: [], products: [], checkedProducts: {}, isFilterDialogOpen: false });
            }}
          >
            Confirm
          </Button>
          <Button
            className={classes.cancelConfirmButton}
            onClick={() => {
              this.setState({ newFilterList: [], filterCrawler: null, isFilterDialogOpen: false });
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  getFiltersForProductSelector() {
    const { category, filterList, newFilterList } = this.state;

    const result = [...filterList];
    if (category && category != 'none') {
      result.push({
        type: 'category',
        name: category
      });
    }
    return result;
  }

  renderProductDialog() {
    const { classes } = this.props;
    const { newFilterList } = this.state;
    return (
      <Dialog open={this.state.isProductDialogOpen} fullWidth maxWidth={'lg'} classes={{ paper: classes.dialogPaperProductList }}>
        <DialogTitle>
          제외 상품 추가/수정
        </DialogTitle>
        <DialogContent>
          <ProductSelector
            searchKeyword={this.state.productSearchKeyword}
            isGodoOnly={true}
            checkedProducts={this.state.newCheckedProducts}
            onChangeKeyword={event => this.setState({ productSearchKeyword: event.target.value })}
            onChangeIsGodoOnly={() => null}
            onCheckProduct={product => {
              const { newCheckedProducts } = this.state;
              if (!has(newCheckedProducts, product._id)) {
                newCheckedProducts[product._id] = product;
              } else {
                delete newCheckedProducts[product._id];
              }
              this.setState({ newCheckedProducts });
            }}
            filters={this.getFiltersForProductSelector()}
          />
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.cancelConfirmButton}
            onClick={this.handleAddProductSubmit}
          >
            Confirm
          </Button>
          <Button
            className={classes.cancelConfirmButton}
            onClick={() => {
              this.setState({ newCheckedProducts: {}, isProductDialogOpen: false });
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { classes, categorySaleId } = this.props;
    const {
      weight, title, subtitle, description, products,
      sortByValue, sortByList,
      category, categoryList, filterList } = this.state;
    return (
      <div className="container">
        {this.renderWeight()}
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>
            카테고리 세일 제목
          </Typography>
          <div>
            <TextField className={classes.link} value={title} onChange={event => this.handleTitleChange(event)} />
          </div>
        </Paper>
        {/*
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>
            카테고리 세일 부제목
          </Typography>
          <div>
            <TextField className={classes.link} value={subtitle} onChange={event => this.handleSubtitleChange(event)} />
          </div>
        </Paper>
        */}
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>
            카테고리 세일 설명
          </Typography>
          <div>
            <TextField
              className={classes.link}
              value={description}
              multiline={true}
              minRows={5}
              maxRows={10}
              placeholder="카테고리 세일 상세 설명 입력"
              onChange={event => this.handleDescriptionChange(event)}
            />
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>카테고리 세일 상태</Typography>
          <div>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={this.state.visible}
                  onChange={(event, checked) => this.setState({ visible: checked })}
                />
              )}
              label="공개여부"
            />
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>정렬</Typography>
          <Typography variant="subheading">
            정렬 선택:&nbsp;&nbsp;
            <Select
              className={classes.select}
              value={sortByValue}
              onChange={event => {
                this.setState({
                  sortByValue: event.target.value,
                });
              }}
            >
              { sortByList.map(sort => {
                return (
                  <MenuItem value={sort.value} key={sort.value}>
                    <Typography variant="subheading">{sort.name}</Typography>
                  </MenuItem>
                )
              })}
            </Select>
          </Typography>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>카테고리</Typography>
          <Typography variant="subheading">
            카테고리 선택:&nbsp;&nbsp;
            <Select
              className={classes.select}
              value={category}
              onChange={event => {
                this.setState({
                  category: event.target.value,
                  products: [],
                  checkedProducts: {},
                });
              }}
            >
              { categoryList.map(ct => {
                return (
                  <MenuItem value={ct.cateCd} key={ct.cateNm}>
                    <Typography variant="subheading">{ct.cateNm}</Typography>
                  </MenuItem>
                )
              })}
            </Select>
          </Typography>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>필터</Typography>
          {
            filterList.length > 0 ? (
              this.renderActiveFilter('filterList')
            ) : (
                <Typography
                  variant="caption"
                  className={classes.thumbnailArea}
                  style={{ height: 100 }}
                >
                  등록된 필터가 없습니다
                </Typography>
              )
          }
          <Button
            className={classes.outerButton}
            onClick={this.handleAddFilter}
            variant="contained"
          >
            필터 추가/수정
          </Button>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>제외 상품</Typography>
          {
            products.length > 0 ? (
              <ItemList
                getMedia={(item) => item.media}
                items={products}
                renderCaption={this.renderProductCaption}
                onChange={(normals, recommends) => this.setState(
                  { products: normals.concat(recommends) }
                )
                }
                isRecommendList={false}
                showRecommend={false}
                showSeasonOut={false}
              />
            ) : (
                <Typography
                  variant="caption"
                  className={classes.thumbnailArea}
                  style={{ height: 100 }}
                >
                  등록된 상품이 없습니다
                </Typography>
              )
          }
          <Button
            className={classes.outerButton}
            onClick={this.handleAddProduct}
            variant="contained"
          >
            제외 상품 추가/수정
          </Button>
        </Paper>
        <div style={{ height: 200, position: 'relative' }}>
          <Button
            variant="fab"
            onClick={this.handleSubmit}
            className={classes.createPostButton}
            color="primary"
            disabled={this.state.isUpdatingPost}
          >
            {categorySaleId === 'new' ? '업로드' : '완료'}
          </Button>
          {
            categorySaleId !== 'new' && (
              <Button
                variant="fab"
                onClick={() => {
                  if (confirm('카테고리 세일을 제거할까요?')) { // eslint-disable-line
                    this.props.adminDeleteCategorySale(this.props.categorySaleId);
                    this.props.router.push('/admin/categorySales');
                  }
                }}
                color="secondary"
                className={classes.deletePostButton}
              >
                제거
              </Button>
            )
          }
        </div>
        {this.renderFilterDialog()}
        {this.renderProductDialog()}
        <Snackbar
          autoHideDuration={2000}
          className={classes.snackbar}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
        />
      </div>
    );
  }
}

CategorySalePage.propTypes = {
  categorySaleId: PropTypes.string.isRequired,
  router: PropTypes.object.isRequired,
  adminCreateCategorySale: PropTypes.func.isRequired,
  adminDeleteCategorySale: PropTypes.func.isRequired,
  adminGetCategorySale: PropTypes.func.isRequired,
  adminUpdateCategorySale: PropTypes.func.isRequired,
};

export default compose(
  withStyles(
    styles
  ),
  connect((state, ownProps) => ({
    categorySaleId: ownProps.params.categorySaleId,
    categorySale: state.entities.categorySales[ownProps.params.categorySaleId],
    ...loadEntities(state, 'adminCategorySales', 'adminCategorySales'),
  }), adminActions),
)(CategorySalePage);
