import { normalize } from 'normalizr';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';


export * from '../actions';


// admin actions for Heartit curations
export function adminLoadHeartitCurations() {
  const key = 'adminHeartitCurations';

  return createFetchAction({
    type: 'ADMIN_LOAD_HEARTIT_CURATIONS',
    endpoint: '/api/v2/admin/heartitCurations',
    transform: ({ json }) => normalize(json, schemas.adminHeartitCurations),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}

export function adminGetHeartitCuration(heartitCurationId) {
  return createFetchAction({
    type: 'ADMIN_GET_HEARTIT_CURATION',
    endpoint: `/api/v2/admin/heartitCurations/${heartitCurationId}`,
    transform: ({ json }) => normalize(json, schemas.adminHeartitCuration),
  });
}

export function adminCreateHeartitCuration(heartitCuration) {
  return createFetchAction({
    type: 'ADMIN_CREATE_HEARTIT_CURATION',
    endpoint: '/api/v2/admin/heartitCurations',
    method: 'POST',
    body: heartitCuration,
    transform: ({ json }) => normalize(json, schemas.adminHeartitCuration),
    success: () => ({
      pagination: {
        key: 'adminHeartitCurations',
        type: 'PREPEND',
      },
    }),
  });
}

export function adminUpdateHeartitCuration(heartitCurationId, heartitCuration) {
  return createFetchAction({
    type: 'ADMIN_UPDATE_HEARTIT_CURATION',
    endpoint: `/api/v2/admin/heartitCurations/${heartitCurationId}`,
    method: 'PUT',
    body: heartitCuration,
    transform: ({ json }) => normalize(json, schemas.adminHeartitCuration),
  });
}

export function adminDeleteHeartitCuration(heartitCurationId) {
  const payload = { entities: { adminHeartitCurations: {} } };
  payload.entities.adminHeartitCurations[heartitCurationId] = null;
  return createFetchAction({
    type: 'ADMIN_DELETE_HEARTIT_CURATION',
    endpoint: `/api/v2/admin/heartitCurations/${heartitCurationId}`,
    method: 'DELETE',
    success: {
      payload,
    },
  });
}
