import React from 'react';
import PropTypes from 'prop-types';
import {
  View, StyleSheet, ActivityIndicator,
  // eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

// eslint-disable-next-line import/no-extraneous-dependencies
import { loadEntities } from 'shared';
import Spinner from '../../../components/Spinner';
import Product from '../../../nativeComponents/Product';
import LoadMoreButton from '../../../nativeComponents/LoadMoreButton';
import Constants from '../../../constants/Constants';


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  listContainer: {
    marginHorizontal: 6,
    marginTop: 5,
  },
  product: {
    marginBottom: 11,
  },
  productContainer: {
    width: '100%',
  },
  loadMoreContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});


class ProductList extends React.Component {
  state = {
    isFetchingNextPage: false,
  };

  componentDidUpdate(prevProps) {
    const { pagination } = this.props;

    if (prevProps.pagination.isFetching && !pagination.isFetching) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isFetchingNextPage: false });
    }
  }

  onPressProduct = product => () => {
    const { router } = this.props;
    router.push(`/product/${product.goodsNo}`);
  }

  renderProduct = product => (
    <GridListTile key={product.goodsNo}>
      <Product
        onPressProduct={this.onPressProduct(product)}
        product={product}
        style={styles.product}
        containerStyle={styles.productContainer}
        isShopProduct
        isPreOrder={this.isPreOrder}
        enableLabelPreOrder
      />
    </GridListTile>
  );

  renderFooter = () => {
    const { isFetchingNextPage } = this.state;
    const { router, pagination } = this.props;

    const showIndicator =  pagination.nextPage !== null && isFetchingNextPage;

    return (
      <View>
        {showIndicator && (
        <View
          style={{
            position: 'relative',
            width: '100%',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            paddingVertical: 20,
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <ActivityIndicator animating size="small" color="gray" />
        </View>
        )}
      </View>
    );
  }

  loadData(nextPage) {
    const { loadData } = this.props;
    this.setState({ isFetchingNextPage: nextPage }, () => {
      loadData(nextPage);
    });
  }

  renderLoadMore() {
    const onPress = () => {
      const {
        pagination,
      } = this.props;

      if (pagination.nextPageUrl) {
        this.loadData(true);
      }
    };

    return (
      <View style={styles.loadMoreContainer}>
        <LoadMoreButton onPress={onPress} />
      </View>
    );
  }

  render() {
    const { shopProducts, pagination } = this.props;

    const {
      isFetchingNextPage,
    } = this.state;

    if (pagination.isFetching && !isFetchingNextPage && (!shopProducts || shopProducts.length === 0)) {
      return (<Spinner />);
    }

    return (
      <View style={styles.container}>
        {shopProducts.length > 0 ? (
          <View style={styles.listContainer}>
            <GridList
              cellHeight="auto"
              cols={this.isPreOrder ? 1 : 2}
              spacing={this.isPreOrder ? 1 : 12}
            >
              {shopProducts.map(product => this.renderProduct(product))}
            </GridList>
          </View>
        ) : (
          null
        )}
        {(!pagination.isFetching && pagination.nextPageUrl) ? (
          this.renderLoadMore()
        ) : (
          null
        )}
        {this.renderFooter()}
      </View>
    );
  }
}

ProductList.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  categoryId: PropTypes.string.isRequired,
  shopProducts: PropTypes.array,
  loadData: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

ProductList.defaultProps = {
  shopProducts: [],
};


export default connect(
  (state, props) => {
    const { categoryId } = props;
    const key = `shopProducts_${categoryId}`;

    return ({
      ...loadEntities(state, key, 'shopProducts', 'pagination', 'shopProducts'),
    });
  },
)(ProductList);
