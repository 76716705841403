import React from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheet, View, Text, Alert,
} from 'react-native';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { actions } from 'shared';

import NumberFormat from 'react-number-format';

import { formatPrice } from 'shared/util';
import { convertCartProductToGodoShopDetailProduct, getCrawlerProductImageStyle } from 'shared/util/product';

import { PlusIcon, MinusIcon, ChevronDownIcon } from '../icons-v2';
import Button from './Button';
import LikeButton from './LikeButton';
import CheckBox from './CheckBox';

import { Constants, Colors, Styles } from '../constants';

import CustomPickerSelect from './CustomPickerSelect';
import { addToWishlist } from './WishlistControl';


const styles = StyleSheet.create({
  itemContainer: {
    paddingTop: 20.5,
    paddingBottom: 40,
    flexDirection: 'column',
  },
  itemHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  itemHeaderButtonContainer: {
    flexDirection: 'row',
    marginHorizontal: -10,
  },
  itemHeaderButton: {
    marginVertical: 0,
    marginHorizontal: 10 - Constants.touchEffectWidth,
    padding: Constants.touchEffectWidth,
    backgroundColor: 'transparent',
    borderWidth: 0,
    borderRadius: Constants.touchEffectBorderRadius,
    borderColor: 'transparent',
  },
  itemHeaderButtonText: {
    fontSize: 14,
    lineHeight: 16.8,
    color: '#000',
    ...Styles.pretendard400,
  },
  underline: {
    textDecorationLine: 'underline',
  },
  itemContentContainer: {
    flexDirection: 'row',
    marginTop: 30,
    marginBottom: 20,
    paddingStart: Constants.viewHorizontalPadding,
  },
  itemContent: {
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    marginEnd: Constants.viewHorizontalPadding,
  },
  itemBrandNameContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  itemBrand: {
    fontSize: 14,
    lineHeight: 16.8,
    height: 17,
    color: '#000',
    ...Styles.pretendard600,
  },
  itemName: {
    fontSize: 14,
    lineHeight: 16.8,
    height: 17,
    color: '#000',
    marginTop: 2,
    ...Styles.pretendard400,
  },
  itemPriceContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  itemSellPrice: {
    fontSize: 16,
    lineHeight: 19.2,
    color: '#000',
    marginTop: 4,
    ...Styles.pretendard400,
  },
  itemOrgPrice: {
    fontSize: 14,
    lineHeight: 16.8,
    color: '#000',
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
    ...Styles.pretendard400,
  },
  itemDiscount: {
    fontSize: 14,
    lineHeight: 16.8,
    color: '#e94949',
    textDecorationColor: 'transparent',
    ...Styles.pretendard600,
  },
  optionQuantityContainer: {
    flexDirection: 'row',
    marginStart: Constants.viewHorizontalPadding,
    marginEnd: 2 + Constants.viewHorizontalPadding,
  },
  optionContainer: {
    flex: 225,
  },
  quantityContainer: {
    flex: 115,
    marginStart: 3,
  },
  optionQuantityHeaderText: {
    color: '#000',
    opacity: 0.5,
    fontSize: 14,
    lineHeight: 14,
    paddingBottom: 6,
    ...Styles.pretendard400,
  },
  optionQuantityControlText: {
    fontSize: 15,
    lineHeight: 18,
    color: '#000',
    ...Styles.pretendard400,
  },
  optionPickerContainerStyle: {
    flex: 1,
    borderWidth: 0.5,
    borderColor: '#8E8E9380',
  },
  quantityContainerStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: 0.5,
    borderColor: '#8E8E9380',
    paddingHorizontal: 13,
    height: 44,
  },
  quantityControlButton: {
    padding: Constants.touchEffectWidth,
    margin: -1 * Constants.touchEffectWidth,
    borderWidth: 0,
    borderColor: 'transparent',
    borderRadius: Constants.touchEffectBorderRadius,
    flexShrink: 1,
  },
  quantityControlButtonText: {
    color: '#8E8E9399',
  },
});

const stylesHtml = {
  itemImage: {
    width: 112,
    height: 149,
    objectFit: 'cover',
    marginRight: 13.73,
    border: 'solid',
    borderWidth: 0.5,
    borderColor: '#cfcfcf',
  },
}

const pickerInputCommonContainer = {
  paddingVertical: 13,
  paddingStart: 20,
  paddingEnd: 15.5,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};


const pickerInputCommon = {
  flex: 1,
  color: '#000',
  fontSize: 15,
  lineHeight: 18,
  height: 18,
  ...Styles.pretendard400,
};

const pickerSelectStyles = StyleSheet.create({
  inputIOSContainer: {
    ...pickerInputCommonContainer,
  },
  inputAndroidContainer: {
    ...pickerInputCommonContainer,
  },
  inputIOS: {
    ...pickerInputCommon,
  },
  inputAndroid: {
    ...pickerInputCommon,
  },
  placeholder: {
    ...pickerInputCommon,
  },
  iconContainer: {
    marginTop: 15.74,
    marginEnd: 14,
    marginBottom: 14.33
  },
});


class CartItem extends React.Component {
  state = {
    itemCount: 0,
    selectedOptionList: [],
    optionTree: [],
    optionTreeInitialized: false,
    optionCount: 0,
    soldOut: false,
    isLoadingIndicatorContainerVisible: true,
  }

  componentDidMount() {
    const { item } = this.props;
    const {
      goodsCnt: itemCount,
    } = item;

    this.loadOptionTree();

    this.setState({
      itemCount: Number.parseInt(itemCount, 10),
      product: convertCartProductToGodoShopDetailProduct(item),
    });
  }

  isItemSoldOut = () => {
    const { item } = this.props;
    const {
       soldOutFl,
       sellStopFl,
       stockFl,
       stockCnt,
    } = item;

    if (stockFl === 'y' && Number.parseInt(stockCnt, 10) <= 0) {
      return true;
    }
    if (soldOutFl === 'y') {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedOptionList: prevSelectedOptionList, itemCount: prevItemCount } = prevState;
    const { selectedOptionList, itemCount } = this.state;

    if (prevItemCount !== 0 && prevItemCount != itemCount) {
      this.updateCartGoodsCount();
    }
    if (prevSelectedOptionList.length > 0) {
      const prevSelectedOptionId = this.getSelectedOptionId(prevSelectedOptionList);
      const selectedOptionId = this.getSelectedOptionId();
      if (prevSelectedOptionId !== null && prevSelectedOptionId !== selectedOptionId) {
        console.log("componentDidUpdate selectedOptionId", prevSelectedOptionId, selectedOptionId);
        if (prevSelectedOptionId != selectedOptionId) {
          this.updateCartGoodsOption();
        }
      }
    }
  }

  updateCartGoodsCount = async () => {
    console.log("updateCartGoodsCount");
    const {
      loadData,
      item,
      updateItemInCart,
      updateItemInCartWithoutAuth,
      auth } = this.props;
    const { goodsNo: productId } = item;
    const { itemCount } = this.state;

    const errorMsg = '오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.';

    this.setState({ isLoadingIndicatorContainerVisible: true });

    try {
      const selectedOptionId = this.getSelectedOptionId();

      let ret;
      if (!auth || !auth.token){
        ret = await updateItemInCartWithoutAuth(item.sno, productId, itemCount, selectedOptionId);
      }else{
        ret = await updateItemInCart(item.sno, productId, itemCount, selectedOptionId);
      }


      // console.log('onPressCart updateItemInCart return', ret);
      if (!ret.success) {
        Alert.alert(`${errorMsg} (E1${ret.errorCode})`);
        return;
      }
      loadData();
    } catch (error) {
      // console.log('loadNextOption getShopProductOption failed:', error);

      if (error.errorCode) {
        Alert.alert(`${errorMsg} (E2${error.errorCode})`);
      } else {
        Alert.alert(errorMsg);
      }
    } finally {
      this.setState({ isLoadingIndicatorContainerVisible: false });
    }
  }

  updateCartGoodsOption = async () => {
    const { loadData,
      item,
      updateItemInCart,
      updateItemInCartWithoutAuth,
      auth } = this.props;
    const { goodsNo: productId } = item;
    const { itemCount } = this.state;

    const errorMsg = '오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.';

    this.setState({ isLoadingIndicatorContainerVisible: true });

    const selectedOptionId = this.getSelectedOptionId();
    try {
      let ret;
      if (!auth || !auth.token){
        ret = await updateItemInCartWithoutAuth(item.sno, productId, itemCount, selectedOptionId);
      }else{
        ret = await updateItemInCart(item.sno, productId, itemCount, selectedOptionId);
      }

      // console.log('onPressCart updateItemInCart return', ret);
      if (!ret.success) {
        Alert.alert(`${errorMsg} (E1${ret.errorCode})`);
        return;
      }
      loadData();
    } catch (error) {
      // console.log('loadNextOption getShopProductOption failed:', error);

      if (error.errorCode) {
        Alert.alert(`${errorMsg} (E2${error.errorCode})`);
      } else {
        Alert.alert(errorMsg);
      }
    } finally {
      this.setState({ isLoadingIndicatorContainerVisible: false });
    }
  }

  loadOptionTree = async () => {
    const { item } = this.props;

    const product = convertCartProductToGodoShopDetailProduct(item);

    // console.log(`loadOptionTree optionTreeInitialized=${this.state.optionTreeInitialized}`);
    // console.log('loadOptionTree product=', product);
    const { optionName = '', option = [], optionDivision = {}, optionDivisionStock = {}, optionDisplayFl } = product;

    let optionCount = 0;
    if (optionName) {
      optionCount = Array.isArray(optionName) ? optionName.length : 1;
    }

    const optionTree = [];

    if (optionCount === 1) {
      optionTree[0] = option.map(item => ({
        name: item.optionValue,
        optionPrice: Number.parseInt(item.optionPrice, 10),
        stockCnt: Number.parseInt(item.stockCnt, 10),
        sno: item.sno,
        id: item.sno || item.optionValue,
      }));
    } else if (optionCount > 1) {
      optionTree[0] = Object.keys(optionDivision).map(key => ({
        name: option[key].optionValue1,
        stockCnt: Number.parseInt(optionDivisionStock[key].stockCnt, 10),
        id: item.optionValue,
      }));
    }

    // console.log(`componentDidUpdate optionCount`, optionCount);
    // console.log(`componentDidUpdate optionTree`, optionTree);

    // 카트내 상품 정보 수정인 경우 해당 정보가 있어야 함
    const selectedItemOption = item.option;
    // console.log('loadOptionTree selectedItemOption', selectedItemOption);


    const selectedOptionList = [];

    this.setState({ isLoadingIndicatorContainerVisible: true });

    try {
      let optionList = optionTree[0];

      let targetSelectedItemOption = selectedItemOption;
      // 다단계 옵션이 1단계로 펼쳐져 처리되는 경우
      if (optionDisplayFl === 's') {
        targetSelectedItemOption = [{
          optionValue: selectedItemOption.map(e => e.optionValue).join('/'),
        }];
      }

      //console.log('loadOptionTree targetSelectedItemOption', targetSelectedItemOption);

      for (let depth = 0; depth < targetSelectedItemOption.length; depth++) {
        // const optionList = this.getOptionList(depth);
        // console.log('loadOptionTree optionList', optionList);
        const selectedOption = targetSelectedItemOption[depth];
        const filteredOptionList = optionList.filter(e => e.name === selectedOption.optionValue);
        if (filteredOptionList.length <= 0) {
          break;
        }

        // console.log('loadOptionTree filteredOptionList', filteredOptionList);

        const [firstFilteredOptionList] = filteredOptionList;
        selectedOptionList[depth] = firstFilteredOptionList;
        // selectedOptionList[depth] = filteredOptionList[0];

        const selectedOptionNameList = selectedOptionList.map(item => (item.name));
        // console.log('loadOptionTree filteredOptionList', filteredOptionList);

        // eslint-disable-next-line no-await-in-loop
        const nextOptionList = await this.loadNextOptionFromServer(depth, selectedOptionList);
        const nextOptionDepth = depth + 1;
        optionList = nextOptionList;

        const newOptionTreeNode = setWith(
          optionTree[nextOptionDepth] || {},
          selectedOptionNameList,
          nextOptionList,
          Object,
        );
        optionTree[nextOptionDepth] = newOptionTreeNode;
      }

      // console.log('loadOptionTree selectedOptionList', selectedOptionList);
    } catch (error) {
      // console.log('loadOptionTree error', error);
    }

    this.setState({
      optionCount,
      optionTree,
      optionTreeInitialized: true,
      selectedOptionList,
      isLoadingIndicatorContainerVisible: false,
    });
  }

  loadNextOptionFromServer = async (depth, selectedOptionList) => {
    // console.log(`loadNextOption(${depth})`, selectedOptionList);

    const { getShopProductOption } = this.props;
    const { product } = this.state;
    if (product === undefined) {
      // console.log('loadNextOption product is not ready yet');
      return [];
    }

    const { goodsNo: productId, mileageFl } = product;

    const selectedOptionNameList = selectedOptionList.map(item => (item.name));

    const params = {
      optionVal: selectedOptionNameList,
      optionKey: depth,
      mileageFl,
    };

    try {
      // console.log(`loadNextOptionFromServer getShopProductOption send productId=${productId}`, params);
      const ret = await getShopProductOption(productId, params);
      // console.log('loadNextOptionFromServer getShopProductOption return', ret);

      if (ret.success !== true) {
        Alert.alert(
          `옵션 목록을 불러오는 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다. (E0${ret.errorCode})`,
        );
        return [];
      }

      const { data } = ret;
      const { nextOption = [], optionPrice, stockCnt } = data;

      // console.log('loadNextOptionFromServer getShopProductOption nextOption', nextOption);

      const optionList = nextOption.map((item, index) => ({
        name: item,
        optionPrice: Number.parseInt(optionPrice[index], 10),
        stockCnt: Number.parseInt(stockCnt[index], 10),
      }));
      // console.log('loadNextOptionFromServer getShopProductOption optionList', optionList);

      return optionList;
    } catch (error) {
      // console.log('loadNextOptionFromServer getShopProductOption failed:', error);

      if (error.errorCode) {
        Alert.alert(
          `옵션 목록을 불러오는 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다. (E1${error.errorCode})`,
        );
      } else {
        Alert.alert(
          '옵션 목록을 불러오는 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.',
        );
      }

      return [];
    }
  }


  loadNextOption = async (depth, selectedOptionList) => {
    const optionList = await this.loadNextOptionFromServer(depth, selectedOptionList);

    if (optionList.length === 0) {
      return;
    }

    const selectedOptionNameList = selectedOptionList.map(item => (item.name));
    const nextOptionDepth = depth + 1;

    this.setState(prevState => {
      const { optionTree } = prevState;

      const newOptionTree = cloneDeep(optionTree);
      // console.log('loadNextOption getShopProductOption selectedOptionList', selectedOptionNameList);
      const newOptionTreeNode = setWith(
        newOptionTree[nextOptionDepth] || {},
        selectedOptionNameList,
        optionList,
        Object,
      );
      newOptionTree[nextOptionDepth] = newOptionTreeNode;
      // console.log('loadNextOption getShopProductOption optionTree', optionTree);
      // console.log('loadNextOption getShopProductOption newOptionTreeNode', newOptionTreeNode);
      // console.log('loadNextOption getShopProductOption newOptionTree', newOptionTree);

      return {
        optionTree: newOptionTree,
      };
    });
  }

  getOptionName = depth => {
    const { item } = this.props;
    const { godoOptionName: optionName } = item;
    return Array.isArray(optionName) ? optionName[depth] : (optionName ? optionName : '옵션');
  }

  getSelectedOptionName = depth => {
    const nameList = this.getSelectedOptionNameList();
    return nameList[depth];
  }

  getSelectedOptionId = (selectedOptionList = null) => {
    const { optionCount, selectedOptionList: stateSelectedOptionList } = this.state;
    if (selectedOptionList === null) {
      selectedOptionList = stateSelectedOptionList;
    }

    if (optionCount === 0) {
      return 0;
    }

    if (optionCount === 1) {
      return selectedOptionList[0].sno || 0;
    }

    const selectedOptionNameList = this.getSelectedOptionNameList();

    const { product } = this.state;
    const { option } = product;

    const matchedOption = option.filter(item => {
      const matchedName = selectedOptionNameList.filter((name, index) => {
        const key = `optionValue${index + 1}`;
        return item[key] === name;
      });
      return matchedName.length === optionCount;
    });

    // console.log('getSelectedOptionId matchedOption', matchedOption);

    return matchedOption[0].sno || 0;
  };

  getSelectedOptionNameList = selectedOptionList => {
    let target = selectedOptionList;
    if (target === undefined) {
      const { selectedOptionList: targetSelectedOptionList } = this.state;
      target = targetSelectedOptionList;
    }

    const selectedOptionNameList = target.map(item => (item.name));

    return selectedOptionNameList;
  }

  getOptionList = depth => {
    const { optionTree } = this.state;

    // console.log(`getOptionList(${depth}) optionTree`, optionTree);
    if (optionTree.length === 0) {
      return [];
    }

    if (depth === 0) {
      const optionList = optionTree[0];
      // console.log(`getOptionList(${depth}) return`, optionList);
      return optionList || [];
    }
    const selectedOptionNameList = this.getSelectedOptionNameList().slice(0, depth);
    const optionList = get(optionTree[depth], selectedOptionNameList);
    // console.log(`getOptionList(${depth}) return`, optionList);
    return optionList || [];
  }

  onOptionSelected = (depth, option) => {
    const { item, updateCartGoodsOption } = this.props;
    const { product, itemCount } = this.state;
    console.log(`CartItem.onOptionSelected(${depth}) option=`, option);

    if (product.stockFl === 'y' && option.stockCnt === 0) {
      return;
    }

    this.setState(prevState => {
      const { optionCount, selectedOptionList } = prevState;

      const newSelectedOptionList = selectedOptionList.slice(0, depth);
      newSelectedOptionList[depth] = option;

      const newState = {
        selectedOptionList: newSelectedOptionList,
      };

      if (depth < optionCount - 1) {
        this.loadNextOption(depth, newSelectedOptionList);
      }

      return newState;
    });
  };

  renderTopControl = () => {
    const {
      modalProps,
      setModalProps,
      likeProduct,
      unlikeProduct,
      onPressCheckItem,
      onPressDeleteItem,
      onPressCheckoutItem,
      isChecked,
      item,
      logEventClick,
    } = this.props;
    const onPressLikeButton = async () => {
      const { selectedOptionList } = this.state;
      const productId = item.goodsNo;
      const selectedOptionId = this.getSelectedOptionId();
      if (item.liked) {
        logEventClick({
          page_name: 'Cart',
          click_object_name: 'wishlist_del',
          click_object_key: productId
        });
        await unlikeProduct(productId, { shopProduct: item }, selectedOptionId);
      } else {
        addToWishlist(this, item, selectedOptionList, 'result');
      }
    };

    return (
      <View style={styles.itemHeaderContainer}>
        <CheckBox
          onPress={onPressCheckItem}
          checked={isChecked}
        />
        <View style={styles.itemHeaderButtonContainer}>
          <LikeButton
            modalProps={modalProps}
            setModalProps={setModalProps}
            item={Object.assign(item, {
              _id: item.goodsNo,
              imagesDetail: [item.goodsImageURL],
            })}
            selectedOptionValue={this.getSelectedOptionNameList().join('/')}
            optionName={this.getOptionName()}
            isShopProduct
            textButton={true}
            textButtonProps={{
              containerStyle:styles.itemHeaderButton,
              titleStyle:[styles.itemHeaderButtonText],
            }}
            onPress={onPressLikeButton}
          />
          <Button
            containerStyle={styles.itemHeaderButton}
            titleStyle={[styles.itemHeaderButtonText]}
            title="바로 구매하기"
            onPress={onPressCheckoutItem}
          />
          <Button
            containerStyle={styles.itemHeaderButton}
            titleStyle={styles.itemHeaderButtonText}
            title="삭제"
            onPress={onPressDeleteItem}
          />
        </View>
      </View>
    );
  }

  renderOptionPicker = depth => {
    const {
      setModalProps,
      modalProps,
    } = this.props;

    const { product, optionCount, selectedOptionList } = this.state;

    const optionName = this.getOptionName(depth);
    const optionList = this.getOptionList(depth);

    // console.log(`renderOptionPicker(${depth}) optionName=`, optionName);
    // console.log(`renderOptionPicker(${depth}) optionList`, optionList);

    const placeholder = {
      label: optionName,
      value: null,
    };

    const items = optionList.map(option => {
      let label = option.name;
      let soldOut = false;
      if ((depth === (optionCount - 1)) && option.optionPrice) {
        const optionPrice = Number.parseInt(option.optionPrice, 10);
        if (optionPrice !== 0) {
          label += `: ${formatPrice(optionPrice)}`;
        }
      }
      if (product.stockFl === 'y' && option.stockCnt === 0) {
        label += '  Sold Out';
        soldOut = true;
      }
      return {
        ...option,
        displayName: label,
        soldOut,
      };
    });

    // console.log(`renderOptionPicker(${depth}) optionList`, optionList);
    const props = {
      key: `picker_${depth}`,
      onValueChange: (_value, index) => index >= 0 && this.onOptionSelected(depth, optionList[index]),
      placeholder,
      style: pickerSelectStyles,
      items,
      value: selectedOptionList[depth],
      Icon: () => (
        <ChevronDownIcon />
      ),
      setModalProps,
      modalProps,
    };

    return (
      <CustomPickerSelect
        {...props}
      />
    );
  }

  renderOptionPickerList = () => {
    const { setModalProps, modalProps } = this.props;
    const { product = {}, optionCount } = this.state;
    const { optionName = '' } = product;

    // 상품 옵션이 없는 경우
    if (!optionName) {
      const soldOut = (product.soldOut === 'y' || product.soldOutFl === 'y' || product.stockCnt === 0);
      const items = [{
        label: '단일 옵션',
        value: '단일 옵션',
        displayName: '단일 옵션',
        soldOut,
      }];
      const placeholder = {
        label: optionName,
        value: null,
      };

      const props = {
        key: 'picker_undefined',
        onValueChange: () => null,
        style: pickerSelectStyles,
        placeholder,
        items,
        value: { name: '단일 옵션' },
        Icon: () => (
          <ChevronDownIcon />
        ),
        setModalProps,
        modalProps,
      };
      return (
        <View style={styles.optionPickerContainerStyle}>
          <CustomPickerSelect
            {...props}
          />
        </View>
      );
    }

    const optionPickerList = [];

    // console.log(`renderOptionPickerList optionCount`, optionCount);

    for (let i = 0; i < optionCount; i++) {
      optionPickerList.push(this.renderOptionPicker(i));
    }

    return (
      <View style={styles.optionPickerContainerStyle}>
        {optionPickerList}
      </View>
    );
  }

  renderItemCounter = () => {
    const { itemCount } = this.state;
    const disabled = this.isItemSoldOut();

    return (
      <View style={styles.quantityContainerStyle}>
        <Button
          containerStyle={styles.quantityControlButton}
          titleStyle={[styles.optionQuantityControlText, styles.quantityControlButtonText]}
          innerComponent={(<MinusIcon stroke='#000' />)}
          onPress={(itemCount > 1) ? () => {
            this.setState({itemCount: itemCount - 1})
          } : () => {}}
          disabledOpacity={1}
          disabled={disabled || itemCount == 1}
        />
        <Text style={[styles.optionQuantityControlText, disabled ? {opacity: 0.5}: {}]}>
          {itemCount}
        </Text>
        <Button
          containerStyle={styles.quantityControlButton}
          titleStyle={[styles.optionQuantityControlText, styles.quantityControlButtonText]}
          innerComponent={(<PlusIcon stroke='#000' />)}
          onPress={() => this.setState({itemCount: itemCount + 1})}
          disabledOpacity={1}
          disabled={disabled}
        />
      </View>
    );
  }

  getOptionPrice = () => {
    const { optionCount, selectedOptionList } = this.state;

    // 상품 옵션이 없는 경우
    if (optionCount === 0) {
      return 0;
    }

    const { optionPrice = 0 } = (selectedOptionList[optionCount - 1] || {});
    return optionPrice;
  };

  getTotalPrice = (original = false) => {
    const { item } = this.props;
    const { itemCount } = this.state;

    let productPrice = 0;
    if (!original) {
      productPrice = Number.parseInt(item.price.goodsPrice, 10);
    } else {
      productPrice = Number.parseInt(item.price.fixedPrice, 10);
    }

    const optionPrice = this.getOptionPrice();
    // console.log("getTotalPrice optionPrice=", optionPrice);

    productPrice += optionPrice;
    // console.log("getTotalPrice productPrice=", productPrice);

    const totalPrice = itemCount * productPrice;

    return totalPrice;
  }

  renderItemInfo = () => {
    const { item, showLikeButton } = this.props;
    const {
      goodsNo: productId,
      goodsNm: itemName,
      goodsImageURL,
      brandNm,
    } = item;

    const { itemCount } = this.state;
    const disabled = this.isItemSoldOut();

    const itemOrgPrice = this.getTotalPrice(true);
    const itemSellPrice = this.getTotalPrice();
    const discount = Math.round(100 * (1 - itemSellPrice / itemOrgPrice));

    const crawlerImageStyle = getCrawlerProductImageStyle(item);
    const crawlerImageWebStyle = {
      objectFit: crawlerImageStyle.resizeMode,
      backgroundColor: crawlerImageStyle.backgroundColor
    };

    return (
      <View style={[styles.itemContentContainer, disabled ? {opacity: 0.5}: {}]}>
        <img
          referrerPolicy="no-referrer"
          src={goodsImageURL}
          style={{...stylesHtml.itemImage, ...crawlerImageWebStyle}}
        />
        <View style={styles.itemContent}>
          <View style={styles.itemBrandNameContainer}>
            <Text style={styles.itemBrand}>{brandNm}</Text>
            <Text style={styles.itemName}>{itemName}</Text>
            <Text style={styles.itemName}></Text>
          </View>
          <View style={styles.itemPriceContainer}>
            <NumberFormat
              value={itemOrgPrice}
              displayType="text"
              thousandSeparator
              renderText={value => (
                <Text style={[styles.itemOrgPrice]}>
                  {`${value}원`}
                  <Text style={styles.itemDiscount}>
                    {`  ${discount}%`}
                  </Text>
                </Text>
              )}
            />
            <NumberFormat
              value={itemSellPrice}
              displayType="text"
              thousandSeparator
              renderText={value => (
                <Text style={styles.itemSellPrice}>
                  {`${value}원`}
                </Text>
              )}
            />
          </View>
        </View>
      </View>
    );
  }

  renderItemOptionQuantityControl = () => {
    return (
      <View style={styles.optionQuantityContainer}>
        <View style={styles.optionContainer}>
          <Text style={styles.optionQuantityHeaderText}>{this.getOptionName()}</Text>
          {this.renderOptionPickerList()}
        </View>
        <View style={styles.quantityContainer}>
          <Text style={styles.optionQuantityHeaderText}>수량</Text>
          {this.renderItemCounter()}
        </View>
      </View>
    );
  }

  render() {
    return (
      <View style={styles.itemContainer}>
        {this.renderTopControl()}
        {this.renderItemInfo()}
        {this.renderItemOptionQuantityControl()}
      </View>
    );
  }
}

CartItem.propTypes = {
  setModalProps: PropTypes.func,
  modalProps: PropTypes.func,
};

CartItem.defaultProps = {

}


export default connect(
  (state, props) => {
    const { item: { goodsNo: productId }} = props;

    return {
      auth: state.auth,
      product: state.entities.shopProducts[productId],
    };
  },
  actions,
)(withRouter(CartItem));
