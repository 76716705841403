import React from 'react';
import PropTypes from 'prop-types';
import {
  View, StyleSheet, FlatList,
} from 'react-native';

import Product from './Product';

import Constants from '../constants/Constants';
import Styles from '../constants/Styles';


class Products extends React.Component {
  renderItem = item => {
    let { post } = this.props;
    const {
      onPressProduct,
      heartistsPick,
      enableProductLabelSimilar,
      enableProductLabelPreOrder,
      enableProductLabelHeartitLogo,
      enableProductDeemedSeasonOut,
    } = this.props;

    if (!post && item.hasOwnProperty('post')) {
      post = item.post;
    }

    return (
      <Product
        containerStyle={styles.itemContainer}
        onPressProduct={onPressProduct && onPressProduct.bind(this, item, { post })}
        heartistsPick={heartistsPick}
        post={post}
        product={item}
        isSmallVersion={true}
        enableLabelSimilar={enableProductLabelSimilar}
        enableLabelPreOrder={enableProductLabelPreOrder}
        enableLabelHeartitLogo={enableProductLabelHeartitLogo}
        enableDeemedSeasonOut={enableProductDeemedSeasonOut}
      />
    );
  }

  render = () => {
    const { products, horizontal, ...props } = this.props;

    return (
      <View style={[styles.products, Styles.touchEffectContainer]}>
        { horizontal ? (
          <FlatList
            {...props}
            horizontal
            style={styles.flatlist}
            data={products}
            keyExtractor={(item, idx) => { return `${item._id}-${idx}`}} // eslint-disable-line no-underscore-dangle
            ItemSeparatorComponent={() => <View style={{ width: 8 - Constants.touchEffectWidth * 2 }} />}
            renderItem={({ item }) => this.renderItem(item)}
            showsHorizontalScrollIndicator={false}
          />
        ) : (
          products.map(item => this.renderItem(item))
        )
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  products: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  flatlist: {
  },
  itemContainer: {
  },
});

Products.propTypes = {
  enableProductLabelSimilar: PropTypes.bool,
  enableProductLabelPreOrder: PropTypes.bool,
  enableProductLabelHeartitLogo: PropTypes.bool,
  enableProductDeemedSeasonOut: PropTypes.bool,
};

Products.defaultProps = {
  enableProductLabelSimilar: false,
  enableProductLabelPreOrder: false,
  enableProductLabelHeartitLogo: false,
  enableProductDeemedSeasonOut: false,
};

export default Products;
