import React from 'react';
import { Dimensions, StyleSheet, Text, View, TextInput } from 'react-native';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import { actions, loadEntities } from 'shared';

import ShippingAddress from './ShippingAddress';

import { Colors, Styles, Constants } from '../constants';

import { showShippingAddressSelectList } from '../containers-v2/checkout/ShippingAddressListPage';
import { showShippingAddressEdit } from '../containers-v2/checkout/ShippingAddressEditPage';


const initialLayout = { width: Dimensions.get('window').width };

const styles = StyleSheet.create({
  container: {
  },
  memoInputContainer: {
    flexGrow: 1,
    borderColor: '#000',
    marginStart: 42 - Constants.viewHorizontalPadding,
    marginTop: 20,
    paddingVertical: 13 - 0.5,
    paddingHorizontal: 20 - 0.5,
    borderWidth: 0.5,
    justifyContent: 'center',
  },
  memoInputBox: {
    fontSize: 15,
    lineHeight: 18,
    textAlign: 'left',
    color: '#000',
    padding:0,
    borderWidth: 0,
    ...Styles.pretendard400,
  },
  newAddressContainer: {
    marginVertical: 35,
    flexDirection: 'row',
  },
  newAddressRadioContainer: {
    flexDirection: 'row',
    marginTop: 2,
  },
  newAddressRadioText: {
    color: '#000',
    textAlign: 'left',
    textAlignVertical: 'top',
    fontSize: 11,
    lineHeight: 19,
    ...Styles.appleSDGothicNeoRegular,
  },
  newAddressFormContainer: {
  },
});


export class ShippingAddressInfo extends React.Component {
  listAddrRef = null;
  newAddrRef = null;

  constructor(props) {
    super(props);

    const { shippingAddresses } = this.props;
    const defaultAddressIndex = shippingAddresses.findIndex(({ defaultFl }) => defaultFl === 'y');
    this.state = {
      selectedAddressIndex: defaultAddressIndex,
      newAddress: false,
      shippingAddressInfo: {
        orderMemo: '',
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { shippingAddresses: prevShippingAddress } = prevProps;
    const { shippingAddresses } = this.props;
    if (prevShippingAddress.length == 0 && shippingAddresses.length > 0) {
      const defaultAddressIndex = shippingAddresses.findIndex(({ defaultFl }) => defaultFl === 'y');
      this.setState({
        selectedAddressIndex: defaultAddressIndex,
      });
    }
  }

  validateInputField = () => {
    const { newAddress } = this.state;
    if (newAddress) {
      return this.newAddrRef.validateInputField();
    } else {
      return this.listAddrRef && this.listAddrRef.validateInputField && this.listAddrRef.validateInputField();
    }
  }

  onDataChanged = data => {
    const { shippingAddressInfo } = this.state;
    const { onDataChanged } = this.props;

    const newShippingAddressInfo = {
      ...shippingAddressInfo,
      ...data,
    };

    if (!isEqual(shippingAddressInfo, newShippingAddressInfo)) {
      this.setState({ shippingAddressInfo: newShippingAddressInfo });
    }
    onDataChanged(newShippingAddressInfo);
  }

  onChangeOrderMemo = orderMemo => {
    this.setState({ shippingAddressInfo: { orderMemo } });
  }

  addressSelectedCallback = (selectedAddressId) => {
    const { shippingAddresses } = this.props;
    const selectedAddressIndex = shippingAddresses.findIndex(x => x.sno === selectedAddressId);
    if (selectedAddressIndex >= 0) {
      this.setState({
        selectedAddressIndex: selectedAddressIndex,
        newAddress: false,
      });
    }
  }

  showAddressListScreen() {
    const { shippingAddresses } = this.props;

    const { selectedAddressIndex } = this.state;

    if (selectedAddressIndex === null || selectedAddressIndex === undefined)
      return;

    const shippingAddress = shippingAddresses[selectedAddressIndex];

    if (shippingAddress === null || shippingAddress === undefined)
      return;

    const selectedAddressId = shippingAddress.sno;
    const callback = this.addressSelectedCallback;
    showShippingAddressSelectList(this, selectedAddressId, callback);
  }

  render() {
    const {
      addressEditScreenName = "CheckoutShippingAddressCreate",
      router, shippingAddresses = [], setModalProps,
    } = this.props;

    const {
      selectedAddressIndex,
      shippingAddressInfo,
      newAddressData,
      newAddress,
    } = this.state;
    const { orderMemo } = shippingAddressInfo;

    // console.log(`shippingAddresses`, shippingAddresses);

    const hasAddress = shippingAddresses.length > 0;

    const onShippingAddressEditDone = () => {
      const { loadShippingAddressList } = this.props;
      loadShippingAddressList();
    };

    return (
      <View>
        { hasAddress && (<ShippingAddress
          ref={ref => { if (ref) this.listAddrRef = ref.getWrappedInstance(); }}
          item={shippingAddresses[selectedAddressIndex]}
          selected={!newAddress}
          isNewAddress={false}
          onPressRadioButton={() => this.setState({ newAddress: false })}
          onDataChanged={this.onDataChanged}
          addressEditScreenName={addressEditScreenName}
          setModalProps={setModalProps}
        />)}

        { hasAddress && (
          <View style={styles.memoInputContainer}>
            <TextInput
              style={styles.memoInputBox}
              placeholder="배송시 요청사항 남기기"
              placeholderTextColor='#00000080'
              value={orderMemo}
              onChangeText={this.onChangeOrderMemo}
              multiline
            />
          </View>
        )}

        { hasAddress && (<View style={{ height: (!newAddress) ? 39 : 41 }}/>)}

        <ShippingAddress
          ref={ref => { if (ref) this.newAddrRef = ref.getWrappedInstance(); }}
          selected={newAddress}
          isNewAddress={true}
          onPressRadioButton={() => {
            this.setState({ newAddress: false });
            showShippingAddressEdit(this, null, onShippingAddressEditDone);
          }}
          onDataChanged={this.onDataChanged}
          addressEditScreenName={addressEditScreenName}
          setModalProps={setModalProps}
        />

        {newAddress && (
          <TextInput
            style={[styles.memoInputBox, {marginTop: 30}]}
            placeholder="배송시 요청사항 남기기"
            placeholderTextColor='#00000080'
            value={orderMemo}
            onChangeText={this.onChangeOrderMemo}
            multiline
          />
        )}

        <View style={{ height: (!newAddress) ? 34 : 40 }}/>
      </View>
    );
  }
}


export default connect(
  state => ({
    ...loadEntities(state, 'shippingAddresses', 'shippingAddresses'),
  }),
  actions,
  null,
  { withRef: true }
)(ShippingAddressInfo);
