import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import qs from 'qs';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';

import config from '../../config';
import Styles from '../../constants/Styles';

const styles = {
  container: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    display: 'table',
  },
  message: {
    textAlign: 'center',
    fontSize: 24,
    display: 'table-cell',
    verticalAlign: 'middle',
    paddingBottom: 150,
    ...Styles.appleSDGothicNeoBold,
  },
};


class CheckoutNaverPayGatewayPage extends React.Component {
  constructor(props) {
    super(props);
    const { setGlobalLayoutInfo } = props;
    setGlobalLayoutInfo('showProgress', true);
  }

  componentDidMount() {
    const { orderItems, logEventPageview } = this.props;
    if (orderItems) {
      document.getElementById('naverPayForm').submit();
    }

    logEventPageview({ page_name: 'CheckoutNaverPayGateway' });
  }

  render() {
    const {
      auth: { token },
      orderItems,
    } = this.props;

    if (!orderItems) {
      return (
        <div></div>
      );
    }

    const path = '/heartit_api/order_naver_pay_web.php';
    const godomallMobileBaseUrl = config.get('godomallMobileBaseUrl');

    const targetUrl = `${godomallMobileBaseUrl}${path}`;

    const { protocol } = window.location;
    const { host } = window.location;
    const returnUrl = `${protocol}//${host}/shop/checkoutFinish/`;

    return (
      <div style={styles.container}>
        <form
          id="naverPayForm"
          method="POST"
          action={targetUrl}
        >
          <input
            type="hidden"
            id="token"
            name="token"
            value={token}
            readOnly
          />

          {orderItems.map(sno => (
            <input
              type="hidden"
              id={`cartSno_${sno}`}
              name="cartSno[]"
              value={sno}
              readOnly
            />
          ))}

          <input
            type="hidden"
            id="returnUrl"
            name="returnUrl"
            value={returnUrl}
            readOnly
          />
        </form>
      </div>
    );
  }
}


export function navigateToCheckoutNaverPayGateway({ orderItems, newWindow, holdPage=false }) {
  const query = { orderItems, holdPage };

  const path = `/shop/checkoutNaverPay/${qs.stringify(query)}`;
  if (newWindow) {
    newWindow.location = path;
  } else {
    window.open(path);
  }

  if (!holdPage) {
    browserHistory.push('/shop');
  }
}


export default connect(
  (state, props) => {
    const { params: { query } } = props;
    const queryObject = qs.parse(query);
    const {
      orderItems,
    } = queryObject;

    return {
      auth: state.auth,
      shopSessionId: state.entities.shop.sessionId,
      orderItems,
    };
  },
  actions,
)(CheckoutNaverPayGatewayPage);
