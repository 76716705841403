import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, Text } from 'react-native';
import {
  TouchableHighlightWithContainer, ArrowBack,
} from '../../../components-v2';
import { Constants, Styles } from '../../../constants';
import { ChevronDownIcon } from '../../../icons-v2';


const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: Constants.viewHorizontalPadding,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  title: {
    ...Styles.pretendard700,
    fontSize: 16,
    lineHeight: 19.2,
  },
});


class ShopHeader extends React.Component {
  renderBackButton() {
    const {
      showHeaderBackButton,
      backButtonStyle,
      onPressHeaderBackButton,
    } = this.props;

    if (!showHeaderBackButton) {
      return null;
    }

    return (
      <ArrowBack
        backButtonStyle={backButtonStyle}
        onPress={onPressHeaderBackButton}
      />
    )
  }

  renderTitle() {
    const {
      showCategoryDropdown,
      showHeaderDropdownButton,
      onPressHeaderDropdownButton,
      headerTitle,
    } = this.props;

    const iconStyles = [];
    if (showCategoryDropdown) {
      iconStyles.push({
        transform: [
          { scaleY: -1 }
        ],
      })
    }

    const titleComponent = (
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{headerTitle}</Text>
        { showHeaderDropdownButton && (
          <React.Fragment>
            <View style={{ width: 7 }} />
            <ChevronDownIcon style={iconStyles} />
          </React.Fragment>
        )}
      </View>
    );

    if (!showHeaderDropdownButton) {
      return titleComponent;
    }

    const onPress = () => {
      console.log('onPressHeaderDropdownButton');
      onPressHeaderDropdownButton();
    }

    return (
      <TouchableHighlightWithContainer
        onPress={onPress}>
        {titleComponent}
      </TouchableHighlightWithContainer>
    ); 
  }

  render() {
    return (
      <View style={styles.container}>
        {this.renderBackButton()}
        {this.renderTitle()}
      </View>
    )
  }
}


ShopHeader.propTypes = {
  showHeaderBackButton: PropTypes.bool,
  backButtonStyle: PropTypes.string,
  onPressHeaderBackButton: PropTypes.func,
  showCategoryDropdown: PropTypes.bool,
  onPressHeaderDropdownButton: PropTypes.func,
  headerTitle: PropTypes.string,
}


ShopHeader.defaultProps = {
  showHeaderBackButton: false,
  backButtonStyle: 'arrow',
  onPressHeaderBackButton: null,
  showCategoryDropdown: false,
  showHeaderDropdownButton: false,
  onPressHeaderDropdownButton: null,
  headerTitle: '전체상품',
}


export default ShopHeader;
