import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions, loadEntities } from 'shared';
import Heartist from '../../../nativeComponents/Heartist';
import Spinner from '../../../components/Spinner';
import HeartistProfileTabView from './HeartistProfileTabView';
import Constants from '../../../constants/Constants';
import { openOutlinkPage } from '../../OutlinkGatewayPage';
import { generateFirebaseEvent } from '../../../utils';


const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: -Constants.mainBottomTabBarHeight,
  },
  header: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
  profileContainer: {
    zIndex: 99,
    width: '100%',
    overflow: 'hidden',
  },
});


export class HeartistProfilePage extends React.Component {
  lastScrollY = 0;

  constructor(props) {
    super(props);

    this.state = {
      userId: null,
      posts: null,
      postsCount: null,
      headerHeight: 187,
    };
  }

  componentDidMount() {
    const { loadHeartists } = this.props;

    loadHeartists()
      .then(() => {
        this.prepareHeartistData();
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { state } = this.props;
    const { userId } = this.state;
    if (!userId || !prevProps.state.entities.posts || !prevState.posts) {
      return;
    }
    const prevPostCount = Object.keys(prevProps.state.entities.posts).length;
    const currPostCount = Object.keys(state.entities.posts).length;
    if (prevPostCount !== currPostCount) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        postsCount: state.entities.usersPostsCount[userId],
        ...loadEntities(state, `userPosts.${userId}`, 'posts'),
      });
    }
  }

  async prepareHeartistData() {
    const { router, state } = this.props;
    const { username } = router.params;
    const { heartists } = state.entities;
    let user;
    if (!Object.keys(heartists).includes(username)) {
      [user] = Object.values(heartists).filter(heartist => heartist.username === username);
    } else {
      user = heartists[username];
    }
    const userId = user._id;

    this.setState({ userId });
    this.preparePostData();
  }

  async preparePostData() {
    const { loadRecentPosts, loadUsersPostsCount } = this.props;
    const { userId } = this.state;

    const promises = [];
    promises.push(loadRecentPosts(userId));
    promises.push(loadUsersPostsCount(userId));
    await Promise.all(promises);

    const { state } = this.props;
    this.setState({
      postsCount: state.entities.usersPostsCount[userId],
      ...loadEntities(state, `userPosts.${userId}`, 'posts'),
    });
  }

  componentWillUnmount() {
  }

  onPressPostThumbnail = postId => {
    const {
      navigation,
    } = this.props;

    navigation.navigate({
      routeName: 'Post',
      params: { postId },
      key: `Post.${postId}`,
    });
  };

  onPressProduct = (product, { post }) => {
    const { router, historySwitch, auth } = this.props;
    const { token } = auth;

    // eslint-disable-next-line no-prototype-builtins
    if (!post && product.hasOwnProperty('post')) {
      // eslint-disable-next-line prefer-destructuring
      post = product.post;
    }

    // TODO: Post 정보가 없는 경우 이벤트를 보내지 않아도 괜찮은지 확인 필요
    if (post && post.owner) {
      generateFirebaseEvent({ product, post, where: 'post' });
    }

    if (product.godoGoodsNo) {
      router.push(`/product/${product.godoGoodsNo}`);
      return;
    }

    openOutlinkPage(product, post, historySwitch, token, router);
  }

  // onLayoutHeader = ({ nativeEvent }) => {
  // }
  onLayoutHeader = ({ nativeEvent }) => {
    const headerHeight = nativeEvent.layout.height;
    // console.log('onLayoutHeader headerHeight=', headerHeight);
    this.setState({ headerHeight });
  };

  renderHeader = () => {
    const {
      router, auth, state: { entities }, getFollowStatus, followUser, unfollowUser,
    } = this.props;
    const { userId } = this.state;
    const user = entities.heartists[userId];

    return (
      <View
        style={styles.header}
        onLayout={this.onLayoutHeader}
      >
        <View style={styles.profileContainer}>
          <Heartist
            auth={auth}
            router={router}
            getFollowStatus={getFollowStatus}
            followUser={followUser}
            unfollowUser={unfollowUser}
            userId={userId}
            user={user}
          />
        </View>
      </View>
    );
  };

  render() {
    const {
      auth, router, historySwitch, loadRecentPosts,
    } = this.props;
    const {
      posts, postsCount, pagination, userId,
      headerHeight,
    } = this.state;
    // const user = entities.heartists[userId];

    if (!posts) {
      return (<Spinner />);
    }

    return (
      <View style={styles.container}>
        {this.renderHeader()}
        <HeartistProfileTabView
          router={router}
          postsCount={postsCount}
          onPressPostThumbnail={this.onPressPostThumbnail}
          onPressProduct={this.onPressProduct}
          // eslint-disable-next-line react/jsx-no-bind
          loadRecentPosts={loadRecentPosts.bind(this, userId)}
          pagination={pagination}
          auth={auth}
          historySwitch={historySwitch}
          posts={posts}
          headerHeight={headerHeight}
        />
      </View>
    );
  }
}


export default connect(
  state => ({
    state,
    auth: state.auth,
    historySwitch: state.settings.historySwitch,
  }),
  actions,
)(HeartistProfilePage);
