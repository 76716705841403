import * as React from "react"
import Svg, { Rect, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Rect width={50} height={50} rx={12} fill="#111" />
      <Path
        d="M6.587 22.707h-.932v4.532h.932v-1.985h2.259v1.985h.931v-4.532h-.931v1.714H6.587v-1.714zM12.793 25.343h2.196v-.827h-2.196v-.958h2.412v-.851h-3.347v4.586h3.321v-.852h-2.386v-1.098z"
        fill="#fff"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.771 27.29l1.955-4.583h.858l1.954 4.583h-.955l-.373-.879h-2.12l-.372.88h-.947zm2.388-3.42l-.747 1.78h1.486l-.74-1.78zM25.813 25.509c.534-.226.865-.73.865-1.315 0-.417-.159-.772-.473-1.053a1.617 1.617 0 00-1.13-.434h-1.951v4.583h.942v-1.584h.806l.007.01 1.038 1.574h1.065l-1.194-1.77.025-.011zm-.846-.563h-.901v-1.388h.984c.396 0 .694.287.694.669 0 .436-.305.719-.777.719z"
        fill="#fff"
      />
      <Path
        d="M30.86 27.29h-.933v-3.732H28.6v-.851h3.588v.85H30.86v3.733zM41.512 27.27h.93v-3.716h1.32v-.847H40.19v.847h1.322v3.716zM37.402 22.707h.918v4.563h-.918v-4.563z"
        fill="#fff"
      />
    </Svg>
  )
}

export default SvgComponent
