import React from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { connect } from 'react-redux';

import { actions } from 'shared';

import LoginForm from './LoginForm';


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});


class AuthPage extends React.Component {

  componentDidMount() {
    const { logEventPageview } = this.props;

    logEventPageview({ page_name: 'Auth' });
  }

  render() {
    const { router } = this.props;
    return (
      <View style={styles.container}>
        <ScrollView
          bounces={false}
        >
          <LoginForm router={router} />
        </ScrollView>
      </View>
    );
  }
}


export default connect(
  state => ({}),
  actions,
)(AuthPage);
