import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from 'components/Spinner';
import { loadEntities } from 'react-shared';
import { Link } from 'react-router';
import MarketingPopup from 'components/admin/MarketingPopup';
import * as adminActions from 'react-shared/adminActions';

const styles = {
  container: {
    margin: '0 auto',
    maxWidth: '450px',
  },
};

export class ListMarketingPopupPage extends Component {
  componentDidMount() {
    const { adminLoadMarketingPopups } = this.props;
    adminLoadMarketingPopups();
  }

  render() {
    const {
      adminMarketingPopups: popups,
      pagination: { isFetching },
    } = this.props;
    return (
      <div style={styles.container}>
        <br />
        <Link className="btn btn-primary" to="/admin/marketingPopups/new">
          New Marketing Popup
        </Link>
        {popups.length > 0 && popups.map(popup => <MarketingPopup popup={popup} key={`popup${popup._id}`} />)}
        {isFetching && <Spinner />}
      </div>
    );
  }
}

ListMarketingPopupPage.propTypes = {
  adminLoadMarketingPopups: PropTypes.func.isRequired,
  adminMarketingPopups: PropTypes.array,
  // pagination: PropTypes.object,
};

ListMarketingPopupPage.defaultProps = {
  adminMarketingPopups: {},
};

export const mapStateToProps = state => ({
  ...loadEntities(state, 'adminMarketingPopups', 'adminMarketingPopups'),
});

export default connect(
  mapStateToProps,
  adminActions,
)(ListMarketingPopupPage);
