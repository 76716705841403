import * as React from "react"
import Svg, { Path, Defs, LinearGradient, Stop } from "react-native-svg"

function InstagramIcon(props) {
  return (
    <Svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path fill="url(#paint0_linear_815_11806)" d="M0 0H30V30H0z" />
      <Path
        d="M11.666 15a3.332 3.332 0 116.665-.001 3.332 3.332 0 01-6.665 0zm-1.802 0a5.134 5.134 0 1010.268 0 5.134 5.134 0 00-10.268-.002V15zm9.272-5.34a1.2 1.2 0 102.4 0 1.2 1.2 0 00-2.4 0zm-8.178 13.476c-.977-.044-1.504-.207-1.856-.344a3.31 3.31 0 01-1.9-1.9c-.136-.351-.299-.88-.343-1.856-.049-1.054-.06-1.37-.06-4.04 0-2.669.011-2.984.06-4.04.044-.977.207-1.504.344-1.856a3.317 3.317 0 011.899-1.9c.352-.136.882-.3 1.856-.344 1.054-.048 1.37-.058 4.04-.058s2.985.01 4.04.058c.977.045 1.505.208 1.857.344a3.31 3.31 0 011.9 1.9c.136.352.299.881.344 1.856.047 1.054.057 1.37.057 4.04s-.01 2.985-.057 4.04c-.045.977-.208 1.505-.345 1.857a3.31 3.31 0 01-1.9 1.899c-.351.137-.88.3-1.855.344-1.055.048-1.37.059-4.04.059-2.671 0-2.985-.01-4.04-.059zM10.876 5.06c-1.064.049-1.79.217-2.427.465a5.113 5.113 0 00-2.924 2.923c-.247.637-.416 1.364-.464 2.428C5.013 11.94 5 12.283 5 15c0 2.717.012 3.055.06 4.124.05 1.068.218 1.79.465 2.427a5.115 5.115 0 002.924 2.924c.636.247 1.363.416 2.427.465 1.064.048 1.407.06 4.124.06 2.718 0 3.056-.011 4.124-.06 1.068-.05 1.791-.217 2.428-.465a5.116 5.116 0 002.923-2.925c.248-.635.417-1.362.465-2.426.049-1.065.06-1.407.06-4.124 0-2.718-.01-3.056-.06-4.124-.049-1.068-.217-1.791-.465-2.428a5.113 5.113 0 00-2.924-2.923c-.635-.248-1.363-.416-2.427-.465C18.06 5.012 17.718 5 15 5c-2.717 0-3.055.011-4.124.06z"
        fill="#fff"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_815_11806"
          x1={8.5}
          y1={5.1558e-7}
          x2={21.5}
          y2={30}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#7946AA" />
          <Stop offset={0.385417} stopColor="#C14180" />
          <Stop offset={0.71875} stopColor="#D36256" />
          <Stop offset={1} stopColor="#F2B561" />
        </LinearGradient>
      </Defs>
    </Svg>
  )
}

export default InstagramIcon

