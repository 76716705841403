import React from 'react';
import {
  View, StyleSheet, Text, TouchableHighlight,
} from 'react-native';

import InstagramProfileImage from './InstagramProfileImage';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';


export default class HeartistProfile extends React.PureComponent {
  render() {
    const { heartist, onPressHeartist } = this.props;

    return (
      <TouchableHighlight
        key={heartist._id}
        style={[styles.container, styles.touchEffect]}
        underlayColor={Colors.touchEffectColorWhite}
        onPress={onPressHeartist}
      >
        <View
          style={styles.profileContainer}
        >
          <InstagramProfileImage username={heartist.username} style={stylesHtml.profileImage} />
          <Text style={styles.profileText}>{heartist.username}</Text>
        </View>
      </TouchableHighlight>
    );
  }
}

const styles = StyleSheet.create({
  container: {
  },
  profileContainer: {
    alignItems: 'center',
    width: 64,
  },
  profileText: {
    width: '100%',
    fontSize: 12,
    marginTop: 7,
    textAlign: 'center',
    ...Styles.SFUITextRegular,
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});

const stylesHtml = {
  profileImage: {
    height: 64,
    width: 64,
    border: `1px solid ${Colors.profileImageBorder}`,
    borderRadius: 32,
  },
};
