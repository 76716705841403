import React from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, Dimensions, StyleSheet, View, Text } from 'react-native';

import Masonry from 'react-masonry-css'
import { Waypoint } from 'react-waypoint';

import {
  Styles, Constants,
} from '../../../constants';

import {
  Button, StyleFeed
} from '../../../components-v2';


const {
  height: windowHeight,
} = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    minHeight:200
  },
  blankContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  blankContentContainer: {
    width: 240,
    alignItems: 'center',
  },
  blankText: {
    marginBottom: 20,
    fontSize: 16,
    lineHeight: 23,
    height: 46,
    ...Styles.pretendard400,
    textAlign: 'center',
  },
  blankButton: {
    width: 200,
    paddingVertical: 10.5,
    borderWidth: 0,
    borderColor: 'transparent',
    backgroundColor: '#000',
    justifyContent: 'center',
    alignItems: 'center',
  },
  blankButtonText: {
    fontSize: 16,
    lineHeight: 19.2,
    color: '#fff',
    ...Styles.pretendard600,
    height: 19,
  },
  itemContainer: {
    marginBottom: 40,
  },
});


class TabFeed extends React.Component {

  state = {
    refreshing: false,
    isFetchingNextPage: false,
  };

  componentDidMount() {
    const { loadData } = this.props;
    if (loadData) {
      loadData(false, {});
    }
  }

  componentDidUpdate(prevProps) {
    const { loadData, posts } = this.props;
    if (!prevProps.loadData && loadData) {
      loadData(false, {});
    }

    const { pagination, isFeedUpdate } = this.props;

    if (prevProps.pagination.isFetching && !pagination.isFetching) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isFetchingNextPage: false, refreshing: false });
    }

    const { onFeedUpdated } = this.props;

    if(isFeedUpdate){
      onFeedUpdated();
      this.setState({ isFetchingNextPage: true});
    }

  }

  shouldComponentUpdate(nextProps, nextState){
    const { isFeedUpdate } = nextProps;
    const { loadData, pagination, onFeedUpdated, posts } = this.props;
    const { isFetchingNextPage, refreshing } = this.state;

    if(posts.length != nextProps.posts.length){
      return true;
    }

    if(isFeedUpdate){
      if (isFetchingNextPage || refreshing || pagination.isFetching || pagination.nextPageUrl === null) {
        return true;
      }
      //
      loadData(true, {});
      //onFeedUpdated();
      return true;
    }
    //this.setState({ isFetchingNextPage : true });
    return true;
  }

  renderFooter = () => {
    const { loadData, pagination, isFeedUpdate } = this.props;
    const { isFetchingNextPage, refreshing } = this.state;

    return (
      <React.Fragment>
        { ( pagination.nextPageUrl !== null && pagination.isFetching && isFetchingNextPage) && <ActivityIndicator size="small" color="gray" /> }
        <View style={{ height: Constants.viewHorizontalPadding }} />
      </React.Fragment>
    );
  }

  renderStyleFeedItem({ item }) {
    const { router } = this.props;
    return (
      <StyleFeed
        key={item._id}
        containerStyle={styles.itemContainer}
        styleFeed={item}
        enableViewsCount
        router={router}
      />
    );
  }

  render() {
    const {
      posts,
    } = this.props;

    return (
      <View style={styles.container}>
        <View style={{ height: 10 }} />

        <Masonry
          breakpointCols={2}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column">
          {posts.map(item => {
            return this.renderStyleFeedItem({ item })
          })}
        </Masonry>
        {posts.length > 0 && this.renderFooter()}
      </View>
    )
  }
}


TabFeed.propTypes = {
  showSmartBanner: PropTypes.bool,
  posts: PropTypes.array,
}


TabFeed.defaultProps = {
  showSmartBanner: false,
  posts: [],
};


export default TabFeed;
