import React from 'react';
import {
  ScrollView, Text, Alert, 
  FlatList, StyleSheet, View
} from 'react-native';
import { connect } from 'react-redux';
import queryString from "query-string";
import NumberFormat from 'react-number-format';

import { actions } from 'shared';

import Spinner from '../../../components/Spinner';
import ProductInfo from '../../../components-v2/ProductInfo';
import { getOrderProductInfo } from '../../../components-v2/ProductInfo';
import { Colors, Styles, Constants } from '../../../constants';
import Button from '../../../components-v2/Button';
import CheckBox from '../../../components-v2/CheckBox';
import { navigateToProductPage } from '../../shop/ProductPage';

import { getPrices } from './utils';
import ReasonSelector from './ReasonSelector';
import PickupSelector from './PickupSelector';
import RefundFeeSelector from './RefundFeeSelector';
import { sectionStyles } from './commonStyles';


const settlePriceStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 16,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  containerLast: {
    marginTop: 4,
    marginBottom: 0,
  },
  labelText: {
    color: Colors.defaultGray08,
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard400,
  },
  labelTextBold: {
    ...Styles.pretendard600,
  },
  valueText: {
    color: Colors.defaultGray08,
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard400,
  },
  valueTextLarge: {
    color: '#000',
    fontSize: 24,
    lineHeight: 28.8,
    marginTop: -1,
    ...Styles.pretendard400,
  },
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerContainer: {
    flexDirection: 'row',
    paddingHorizontal: Constants.viewHorizontalPadding,
    paddingTop: 13,
    paddingBottom: 22,
  },
  orderNameContainer: {
    flex: 1,
    flexGrow: 1,
  },
  orderDateContainer: {
    flexShrink: 1,
    marginBottom: 1,
  },
  orderDate: {
    fontSize: 14,
    color: 'black',
    lineHeight: 16.8,
    opacity: 0.5,
    ...Styles.pretendard400,
  },
  orderName: {
    fontSize: 16,
    color: 'black',
    lineHeight: 19.2,
    ...Styles.pretendard600,
  },
  orderNumberContainer: {
    paddingHorizontal: Constants.viewHorizontalPadding,
    paddingVertical: 20,
  },
  orderNumberText: {
    fontSize: 20,
    color: '#000',
    lineHeight: 24,
    ...Styles.pretendard600,
  },
  orderItemContainer: {
    marginBottom: 40,
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  paymentButtonContainer: {
    paddingVertical: 0,
    paddingStart: 10,
    paddingEnd: 16,
    borderWidth: 0.5,
    color: '#000',
    width: '100%',
    height: 40,
  },
  paymentInnerContainer: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paymentText: {
    color: '#000',
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard600,
  },
  bottomButtonContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: '#000',
  },
  bottomButton: {
    width: '100%',
    height: 60,
    borderWidth: 0,
    paddingTop: 20,
    paddingBottom: 21,
    paddingHorizontal: 28,
    backgroundColor: '#000',
  },
  bottomButtonText: {
    color: '#fff',
    fontSize: 16,
    lineHeight: 19.2,
    letterSpacing: 2,
    ...Styles.pretendard600,
  },
});


class OrderManagePage extends React.Component {
  state = {
    selectedItems: [],
    additionalReason: '',
    selectedReason: null,
    selectedPickupMethod: "requestPickup",
    returnTracking: '',
    selectedCourier: null,
    withoutTracking: false,
    selectedRefundFeeMethod: null,
    selectedBank: null,
    accountName: '',
    accountNumber: '',
  }

  componentDidMount() {
    const {
      order, selectedItemSno,
      loadShippingAddressList,
      setGlobalState,
      logEventPageview, orderNo,
    } = this.props;

    setGlobalState({ showMainBottomTabBar: false });
    loadShippingAddressList();

    if (!order) {
      this.loadData();
    }

    if (order) {
      this.setState({
        selectedItems: [...order.goods.filter(x => x.sno === selectedItemSno)]
      });
    }

    logEventPageview({ page_name: 'OrderManage', page_key: orderNo });
  }

  componentWillUnmount() {
    const { setGlobalState } = this.props;

    setGlobalState({ showMainBottomTabBar: true });
  }

  componentDidUpdate(prevProps) {
    const { order, selectedItemSno, isGodoLoggedIn } = this.props;
    const { order: prevOrder, selectedItemSno: prevSelectedItemSno } = prevProps;

    if (!prevProps.isGodoLoggedIn && isGodoLoggedIn) {
      this.loadData();
    }

    if (!prevOrder && order || selectedItemSno != prevSelectedItemSno) {
      this.setState({
        selectedItems: [...order.goods.filter(x => x.sno === selectedItemSno)]
      });
    }
  }

  loadData = () => {
    const { getOrderDetail, orderNo } = this.props;

    getOrderDetail(orderNo);
  }

  cancelUserOrder = async () => {
    const { cancelUserOrder, order, orderNo } = this.props;

    let msg = '입금대기중에는 개별 상품 취소가 불가능합니다. 전체 주문을 취소하시겠습니까?';
    if (order.goods.length === 1) {
      msg = '주문을 취소하시겠습니까?';
    }

    Alert.alert(
      null,
      msg,
      [
        {
          text: '아니오',
          style: 'cancel',
        },
        {
          text: '네',
          onPress: async () => {
            try {
              await cancelUserOrder(orderNo);
              this.loadData();
            } catch (e) {
              // eslint-disable-next-line no-console
              console.error(e);
            }
          },
        },
      ],
      { cancelable: false },
    );
  }

  onPressSubmit = () => {
    const { type, orderNo, router, exchangeUserOrder, returnUserOrder } = this.props;
    const {
      selectedItems,
      selectedReason,
      additionalReason,
      selectedPickupMethod,
      selectedCourier,
      returnTracking,
      checkoutInfo,
      selectedRefundFeeMethod,
      selectedBank,
      accountName,
      accountNumber,
    } = this.state;

    const goodsInfo = {};
    selectedItems.forEach(item => {
      goodsInfo[item.sno] = item.goodsCnt;
    });

    let pickupType = '수거하러 와주세요';
    let pickupAddress = '';
    let pickupRequest = '';
    if (selectedPickupMethod === "requestPickup") {
      const { receiverName, receiverZonecode, receiverAddress, receiverAddressSub, receiverCellPhone, orderMemo } = checkoutInfo;
      pickupAddress = `${receiverName}\n${receiverAddress} ${receiverAddressSub} ${receiverZonecode}\n${receiverCellPhone}`;
      pickupRequest = `${orderMemo}`;
    } else if (selectedPickupMethod === "alreadySent") {
      pickupType = '이미 보냈어요';
      pickupRequest = `${selectedCourier}\n${returnTracking}`;
    } else if (selectedPickupMethod === "direct") {
      pickupType = '직접 택배로 보낼게요';
    }
    const body = {
      goodsInfo,
      userHandleReason: selectedReason,
      userHandleDetailReason: additionalReason,
      pickupType,
      pickupAddress,
      pickupRequest,
    }

    if (type === "refund") {
      let refundFeeMethod = '';
      if (selectedRefundFeeMethod === "deduction") {
        refundFeeMethod = '환불금액에서 차감';
      } else if (selectedRefundFeeMethod === "insideBox") {
        refundFeeMethod = '택배박스에 동봉';
      } else if (selectedRefundFeeMethod === "bankTransfer") {
        refundFeeMethod = '계좌 이체';
      }
      body.returnCost = refundFeeMethod;
      body.userRefundBankName = selectedBank;
      body.userRefundAccountNumber = accountNumber;
      body.userRefundDepositor = accountName;
    }

    if (type === "refund") {
      returnUserOrder(orderNo, body)
        .then(() => {
          router.push(`/accounts/orderManageComplete/${type}/${orderNo}`);
        })
        .catch((error) => {
          alert("주문 상태를 변경하는데 실패했습니다. 다시 시도해주세요.");
        });
    } else {
      exchangeUserOrder(orderNo, body)
        .then(() => {
          router.push(`/accounts/orderManageComplete/${type}/${orderNo}`);
        })
        .catch((error) => {
          alert("주문 상태를 변경하는데 실패했습니다. 다시 시도해주세요.");
        });
    }
  }

  getTypeString = () => {
    const { type } = this.props;
    return type == "refund" ? "반품": "교환";
  }

  renderSettlePriceLine = (label, value, prefix, setLast) => (
    <View style={[settlePriceStyles.container, setLast && settlePriceStyles.containerLast]}>
      <Text style={[
        settlePriceStyles.labelText,
        setLast && settlePriceStyles.labelTextBold,
      ]}
      >
        {label}
      </Text>
      <NumberFormat
        value={value}
        displayType="text"
        thousandSeparator
        decimalScale={0}
        prefix={prefix}
        renderText={
          text => (
            <Text style={[
              settlePriceStyles.valueText,
              setLast && settlePriceStyles.valueTextLarge,
            ]}
            >
              {text}원
            </Text>
          )}
      />
    </View>
  )

  renderSettlePrice = () => {
    const { order } = this.props;
    const { settlePrice, settleName } = order;
    const {
      totalGoodsPrice,
      totalMileage,
      totalCoupon,
      totalRefundMileage,
      totalRefundPrice,
    } = getPrices(order.goods);
    const totalDelivery = order.orderDeliveryInfo.deliveryPolicyCharge;

    let { paymentDt } = order.goods[0];
    if (paymentDt === '0000-00-00 00:00:00') {
      paymentDt = '입금 대기중';
    } else {
      paymentDt = paymentDt.slice(0, -3).replace(/-/gi, '.');
    }

    //FIXME
    return (
      <View style={[sectionStyles.sectionContainer, { marginTop: 32 }]}>
        {this.renderSettlePriceLine('총 상품 금액', totalGoodsPrice, '', false)}
        {this.renderSettlePriceLine('총 배송비', totalDelivery, '+ ', false)}
        {this.renderSettlePriceLine('쿠폰 할인', totalCoupon, '- ', false)}
        {this.renderSettlePriceLine('포인트 사용', totalMileage, '- ', false)}
        {this.renderSettlePriceLine('배송비 차감액', 0, '- ', false)}
        {this.renderSettlePriceLine('총 결제 금액', settlePrice, '', true)}

      </View>
    );
  }

  getOrderDate = item => {
    const datetime = item.regDt.split(' ');
    const ymd = datetime[0].split('-');

    return ymd.join('. ');
  }

  onPressProduct = goodsNo => {
    const { router } = this.props;
    navigateToProductPage({ productId: goodsNo }, router);
  }

  renderProducts = ({ item, index }) => {
    const { selectedItems } = this.state;

    const checked = selectedItems.filter(x => x.sno == item.sno).length > 0;

    return (
      <View style={styles.orderItemContainer}>
        <CheckBox
          baseStyle={{ marginBottom: 20 }}
          checked={checked}
          onPress={() => {
            if (checked) {
              this.setState((prevState) => {
                return {
                  selectedItems: [...prevState.selectedItems.filter(x => x.sno != item.sno)]
                };
              });
            } else {
              this.setState((prevState) => {
                return {
                  selectedItems: [...prevState.selectedItems, item]
                };
              });
            }
          }}
        />
        <ProductInfo
          {...getOrderProductInfo(item)}
        />
      </View>
    );
  }

  render() {
    const { type, navigation, auth, historySwitch, order, orderNo, setModalProps, ...props } = this.props;
    const { selectedReason, additionalReason,
      selectedCourier, withoutTracking, selectedItems,
      selectedRefundFeeMethod,
      selectedBank, accountName, accountNumber,
      selectedPickupMethod, returnTracking } = this.state;

    if (!order) {
      return (<Spinner />);
    }

    const orderInfo = order.orderInfo || order;

    let orderName = order.goods[0].goodsNm;
    if (order.goods.length > 1) {
      orderName += ` 외 ${order.goods.length - 1}건`;
    }

    const returnFee = 8000;

    return (
      <View style={styles.container}>

        <View style={styles.headerContainer}>
          <CheckBox
            baseStyle={{ marginVertical: 2.5, marginEnd: 16 }}
            checked={selectedItems.length == order.goods.length}
            onPress={() => {
             const checked = selectedItems.length == order.goods.length;
             if (checked) {
              this.setState({ selectedItems: []});
             } else {
              this.setState({ selectedItems: [...order.goods]});
             }
            }}
          />
          <Text style={sectionStyles.sectionHeaderText}>
            {this.getTypeString()} 요청 상품 ({selectedItems.length}/{order.goods.length})
          </Text>
        </View>

        <View style={sectionStyles.sectionSeparator} />

        <ScrollView
          style={styles.scrollView}
          {...Constants.scrollViewCommonProps}
        >
          <FlatList
            {...props}
            data={order.goods}
            keyExtractor={item => item.sno}
            ListHeaderComponent={() => {
              return (<View style={{ height: 20.5 }}/>);
            }}
            renderItem={this.renderProducts}
          />

          <View style={sectionStyles.sectionSeparator} />

          <ReasonSelector
            type={this.getTypeString()}
            selectedReason={selectedReason}
            additionalReason={additionalReason}
            onChangeSelector={(reason) => { this.setState({ selectedReason: reason}) }}
            onChangeAdditinalInput={(text) => { this.setState({ additionalReason: text })}}
            onChangePicture={() => {}}
            setModalProps={setModalProps}
          />

          <View style={sectionStyles.sectionSeparator} />

          <PickupSelector
            auth={auth}
            orderInfo={orderInfo}
            selectedPickupMethod={selectedPickupMethod}
            selectedCourier={selectedCourier}
            withoutTracking={withoutTracking}
            returnTracking={returnTracking}
            onChangeReturnTracking={(tracking) => { this.setState({ returnTracking: tracking })}}
            onChangeSelectedPickupMethod={(pickupMethod) => { this.setState({ selectedPickupMethod: pickupMethod })}}
            onChangeSelectedCourier={(courier) => this.setState({ selectedCourier: courier })}
            onChangeWithoutTracking={(flag) => this.setState({ withoutTracking: flag })}
            onChangeCheckoutInfo={(checkoutInfo) => this.setState({ checkoutInfo })}
            setModalProps={setModalProps}
          />

          {type == "refund" && (
            <View style={sectionStyles.sectionSeparator} />
          )}

          {type == "refund" && (
            <View style={sectionStyles.sectionHeaderContainer}>
              <Text style={sectionStyles.sectionHeaderText}>
                반품 비용
              </Text>
              <NumberFormat
                value={returnFee}
                displayType="text"
                thousandSeparator
                decimalScale={0}
                renderText={
                  text => (
                    <Text style={[sectionStyles.sectionHeaderSubtext, { opacity: 0.5}]}>
                      {text}원
                    </Text>
                  )}
              />
            </View>
          )}

          {type == "refund" && (
            <RefundFeeSelector
              orderInfo={orderInfo}
              selectedRefundFeeMethod={selectedRefundFeeMethod}
              onChangeSelectedRefundFeeMethod={(method) => {
                this.setState({ selectedRefundFeeMethod: method});
              }}
              onChangeSelectedBank={(bank) => {this.setState({ selectedBank: bank })}}
              onChangeAccountName={(name) => { this.setState({ accountName: name })}}
              onChangeAccountNumber={(number) => { this.setState({ accountNumber: number })}}
              selectedBank={selectedBank}
              accountName={accountName}
              accountNumber={accountNumber}
              setModalProps={setModalProps}
            />
          )}

          {type == "refund" && (
            <View style={sectionStyles.sectionSeparator} />
          )}

          {type == "refund" && (
            <View style={sectionStyles.sectionHeaderContainer}>
              <Text style={sectionStyles.sectionHeaderText}>
                환불 금액
              </Text>
            </View>
          )}

          {type == "refund" && this.renderSettlePrice()}

          <View style={{ height: Constants.innerViewBottomSpace }} />
        </ScrollView>

        <View style={styles.bottomButtonContainer}>
          <Button
            containerStyle={styles.bottomButton}
            titleStyle={styles.bottomButtonText}
            title={this.getTypeString() + " 요청하기"}
            onPress={() => this.onPressSubmit()}
          />
        </View>

      </View>
    );
  }
}


export default connect(
  (state, ownProps) => {
    const { type, orderNo } = ownProps.params;
    // eslint-disable-next-line no-prototype-builtins
    const isOrderInList = state.entities.userOrderList.hasOwnProperty(orderNo);
    let order = null;
    try {
      order = (isOrderInList) ? state.entities.userOrderList[orderNo] : state.entities.orderDetails[orderNo];
    // eslint-disable-next-line no-empty
    } catch (ignore) {
    }

    const { search } = ownProps.router.location;
    const query = queryString.parse(search);
    const selectedItemSno = query.selectedItem;

    return {
      auth: state.auth,
      // eslint-disable-next-line no-prototype-builtins
      isGodoLoggedIn: state.entities.shop.hasOwnProperty('sessionId'),
      orderNo,
      order,
      type,
      selectedItemSno,
    };
  },
  actions,
)(OrderManagePage);
