import { normalize } from 'normalizr';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';


export function sendSmsToUser(userId, msg) {
  return createFetchAction({
    type: 'SEND_SMS_TO_USER',
    method: 'POST',
    endpoint: `/api/v2/admin/users/${userId}/sendSms`,
    body: {
      msg,
    },
  });
}

export function loadUsersByPrefixAndRole(prefix, role) {
  return createFetchAction({
    type: 'LOAD_USERS',
    endpoint: `/api/v2/admin/users?prefix=${prefix}&role=${role}`,
    transform: ({ json }) => normalize(json, schemas.users),
    success: {
      pagination: {
        key: 'users',
        type: 'REFRESH',
      },
    },
  });
}

// admin actions for users
// export function adminGetUserCount() {
//   return createFetchAction({
//     type: 'USER_COUNT',
//     endpoint: '/api/v2/admin/users/count',
//     doDispatch: false,
//   });
// }
