import React from 'react';
import { Dimensions, StyleSheet, Text, AsyncStorage } from 'react-native';
import { actions, loadEntities } from 'shared';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

import { Constants, Colors, Styles } from '../constants';

import ProductSearchResultList from '../components-v2/ProductSearchResultList';
import TagSearchResultList from '../components-v2/TagSearchResultList';


const initialLayout = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
};

const TabBarHeight = 49;
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scene: {
    flex: 1,
  },
  tabBar: {
    backgroundColor: '#fff',
    elevation: 0,
    height: TabBarHeight,
    shadowOffset: { height: 0, width: 0 },
    shadowColor: 'transparent',
    shadowOpacity: 0,
  },
  tabLabel: {
    fontSize: 18,
    lineHeight: 21.6,
    opacity: 0.5,
    textAlign: 'center',
    display: 'block',
    ...Styles.pretendard700,
  },
  tabLabelFocused: {
    opacity: 1,
  },
  tabLabelCount: {
    marginTop: 1,
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard400,
  },
  tabIndicatorStyle: {
    width: 0,
    backgroundColor: 'transparent',
  },
  tabIndicator: {
    backgroundColor: 'transparent',
  },
});

const useStyles = () => ({
  tabs: {
    backgroundColor: '#fff',
  },
  indicator: {
    backgroundColor: '#000',
    height: 0,
  },
  tab: {
    height: TabBarHeight,
    paddingTop: 4,
    paddingBottom: 4,
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.3,
  },
  tabSelected: {
    opacity: 1,
  },
  tabWrapper: {
    fontSize: 18,
    lineHeight: 21.6,
    color: '#000',
    ...Styles.pretendard700,
  },
  rippleVisible: {
    opacity: 1,
    color: Colors.touchEffectColorWhite,
    animation: '0ms',
  },
});


const stylesHtml = {
  appBar: {
    shadows: ['none'],
    top: 0,
    left:0
  },
  appBarWithSmartBanner: {
    shadows: ['none'],
    top: 0,
    left: 0
  },
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

class SearchResultPage extends React.Component {

  state = {
    index: 0,
    currentTab: 'shop',
    routes: [
      { key: 'shop', title: '상품' },
      { key: 'tag', title: '태그' },
    ],
    searchKeyword: '',
    shopCountResult: 0,
    tagSearchResult: [],
    tagCountResult: 0,
  };

  componentDidMount() {
    const {
      router,
      loadShopBrandList,
      logEventPageview,
      getPartnerShippingProducts,
    } = this.props;

    getPartnerShippingProducts();
    loadShopBrandList()
      .then(result => {
        const brandList = result.data.map(d => ({
          id: d.cateCd,
          type: 'brand',
          name: d.cateNm,
        }));
        this.setState({ brandList });
      });

    const keyword = router.location.state.searchKeyword || '';
    this.setState({
      searchKeyword: keyword,
    });
    this.searchTags({ keyword });

    logEventPageview({ page_name: 'SearchResult', page_key: keyword });
  }

  componentDidUpdate(prevProps) {
    const { pagination, router } = this.props;

    const newSearchKeyword = (router.location.state ? router.location.state.searchKeyword : '') || '';
    const prevSearchKeyword = (prevProps.router.location.state ? prevProps.router.location.state.searchKeyword : '') || '';
    if (newSearchKeyword !== prevSearchKeyword) {
      this.setState({
        searchKeyword: newSearchKeyword,
      });
    }

    if (prevProps.pagination.product.isFetching && !pagination.product.isFetching) {
      this.setState({
        shopCountResult: pagination.product.countResult,
      });
    }
  }

  handleIndexChange = (event, index) => {
    const { routes } = this.state;
    this.setState({
      index,
      currentTab: routes[index].key,
    });
  }

  searchProducts = (query, nextPage) => {
    const { getProductListByKeyword } = this.props;

    if(!nextPage) {
      this.setState({
        productSearchResult: 0,
      });
    }
    return getProductListByKeyword(query, nextPage);
  }

  searchTags = async ({ keyword }) => {
    const { getTagSearchResult } = this.props;

    const result = await getTagSearchResult({ keyword });
    this.setState({
      tagSearchResult: result,
      tagCountResult: result.length,
    });
  }

  renderScene = ({ route }) => {
    const { index } = this.state;
    switch (route.key) {
      case 'shop':
        return (
          <TabPanel key={"shopTabPanel"} value={index} index={0}>
            {this.renderProductResult()}
          </TabPanel>
        );

      case 'tag':
        return (
          <TabPanel key={"tagTabPanel"} value={index} index={1}>
            {this.renderTagResult()}
          </TabPanel>
        );

      default:
        return null;
    }
  }

  renderProductResult = () => {
    const { productSearchResult, router, pagination, ...props } = this.props;
    const { searchKeyword, shopCountResult, brandList } = this.state;

    return (
      <ProductSearchResultList
        searchResults={productSearchResult}
        searchKeyword={searchKeyword}
        loadData={this.searchProducts}
        router={router}
        pagination={pagination.product}
        brandList={brandList}
        {...props}
      />
    );
  }

  renderTagResult = () => {
    const { router, pagination, ...props } = this.props;
    const { searchKeyword, tagSearchResult, tagCountResult } = this.state;

    const searchKeywordLowercase = searchKeyword.toLowerCase();
    const searchResult = [];
    tagSearchResult.forEach(item => {
      const keyword = item.title.toLowerCase();
      if (!keyword.includes(searchKeywordLowercase)) {
        return;
      }

      const indexStart = keyword.indexOf(searchKeywordLowercase);
      const indexEnd = indexStart + searchKeyword.length;
      searchResult.push({
        id: item._id,
        keyword: item.title,
        keywordPrefix: item.title.slice(0, indexStart),
        keywordInfix: item.title.slice(indexStart, indexEnd),
        keywordSuffix: item.title.slice(indexEnd, item.length),
        indexStart,
        indexEnd,
        stylePostCount: item.stylePostCount,
      });
    });

    return (
      <TagSearchResultList
        searchResults={searchResult}
        searchKeyword={searchKeyword}
        loadData={this.searchTags}
        router={router}
        {...props}
      />
    );
  }

  renderLabel = ({ route, focused }) => {
    const { shopCountResult, tagCountResult } = this.state;

    let count = route.key === 'shop' ? shopCountResult : tagCountResult;
    const title = route.title;

    return (
      <div style={{ flexDirection: 'column' }}>
        <Text
          style={[styles.tabLabel, focused && styles.tabLabelFocused]}
        >
          {title}
        </Text>
        <Text
          style={[styles.tabLabel, focused && styles.tabLabelFocused, styles.tabLabelCount, {marginTop: 2}]}>
          {count}
        </Text>
      </div>
    );
  }

  renderTabBar = props => {
    const { classes, showSmartBanner } = this.props;
    const { index, routes } = this.state;

    const appBarStyle = showSmartBanner ? stylesHtml.appBarWithSmartBanner : stylesHtml.appBar;

    return (
      <AppBar position="sticky" style={appBarStyle} elevation={0}>
        <Tabs
          value={index}
          onChange={this.handleIndexChange}
          variant="fullWidth"
          classes={{
            root: classes.tabs,
            indicator: classes.indicator,
          }}
        >
          {routes.map((route, idx) => {
            return (
              <Tab
                key={route.key}
                icon={this.renderLabel({ route, focused: index == idx})}
                classes={{ root: classes.tab, selected: classes.tabSelected, wrapper: classes.tabWrapper }}
                TouchRippleProps={{ classes: { rippleVisible: classes.rippleVisible } }}
              />
            );
          })}
        </Tabs>
      </AppBar>
    );
  }

  render() {
    const { routes } = this.state;
    return (
      <div>
        {this.renderTabBar()}

        <div>
          {routes.map(route => {
            return this.renderScene({ route });
          })}
        </div>
      </div>
    );
  }
}


export default withStyles(useStyles)(connect(
  state => {
    const productResult = loadEntities(
      state,
      'productSearchResult', 'shopProducts',
      'pagination', 'productSearchResult'
    );

    return ({
      auth: state.auth,
      historySwitch: state.settings.historySwitch,
      productSearchResult: productResult.productSearchResult,
      pagination: {
        product: productResult.pagination,
      },
      showSmartBanner: state.globalLayoutInfo.showSmartBanner,
    });
  },
  actions,
)(SearchResultPage));
