import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';

import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';

// import KakaoLogin from 'react-kakao-login';

import KakaoLinkButton from './KakaoLinkButton';

import { default as KakaotalkIcon } from '../icons/KakaotalkIcon';


const webStyles = {
  snsIcon: {
    fontSize: 34,
  },
  snsList: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: 20,
    marginStart: 20,
    marginEnd: 20,
  },
};


const styles = StyleSheet.create({
  snsList: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: 20,
    marginStart: 20,
    marginEnd: 20,
  },
});

const useStyles = makeStyles({
  dialog: {
    paddingStart: 100,
  },
  listItemText: {
    fontSize: 18,
  },
});


function ShareDialog(props) {
  const classes = useStyles();
  const {
    onClose,
    open,
  } = props;

  let shareUrl = '';
  let title = '';
  if (props.product) {
    const productId = props.product._id;
    const title = props.product.title;
    shareUrl = `${window.location.protocol}//${window.location.host}/share/p/${productId}`;
  } else if (props.post) {
    shareUrl = `${window.location.protocol}//${window.location.host}/style/feed/${props.post._id}`;
    const title = '';
  }

  const handleClose = () => {
    onClose();
  };


  return (
    <Dialog
      onClose={handleClose}
      open={open}
      classes={{ root: classes.dialog }}
    >
      <div style={{ minWidth: 220 }}>
        <DialogTitle id="share-dialog-title">
          <span style={{ fontSize: 20 }}>Share</span>
        </DialogTitle>

        <View style={styles.snsList}>
          <FacebookShareButton
            url={shareUrl}
            quote={title}
            className="Demo__some-network__share-button"
          >
            <FacebookIcon size={40} round />
          </FacebookShareButton>
          <TwitterShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button"
          >
            <TwitterIcon size={40} round />
          </TwitterShareButton>
          <KakaoLinkButton
            jsKey="6464bc1f819b05ed51da5ac4a609cdc5"
            url={shareUrl}
          >
            <KakaotalkIcon style={webStyles.snsIcon} color="primary" />
          </KakaoLinkButton>
        </View>
      </div>
    </Dialog>
  );
}

ShareDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  product: PropTypes.object,
  post: PropTypes.object,
};


export default ShareDialog;
