import * as React from "react"
import Svg, { Path } from "react-native-svg"

function ArrowBackIosIcon(props) {
  const {
    color="#000",
    ...subProps
  } = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      height={24}
      width={24}
      {...subProps}>
      <Path
        stroke={color}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        d="M7.5 12L14.5 5"/>
      <Path
        stroke={color}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        d="M7.5 12L14.5 19"/>
    </Svg>
  )
}

export default ArrowBackIosIcon
