import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadEntities } from 'react-shared';
import * as adminActions from 'react-shared/adminActions';
import { Tab, Tabs, Box } from '@material-ui/core';
import { ListAppVersion } from 'components/admin';

const styles = {
  container: {
    margin: '0 auto',
  },
};

export class ListAppVersionPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
    }
  }

  renderTabView(value, index, children) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`appversion-tabpanel-${index}`}
        aria-labelledby={`appversion-tab-${index}`}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
  }

  render() {
    const { 
      androidAppVersions, adminLoadAndroidAppVersions, androidAppVersionPagination,
      iosAppVersions, adminLoadIosAppVersions, iosAppVersionPagination,
      adminCreateAppVersion, adminUpdateAppVersion, adminDeleteAppVersion,
    } = this.props;
    const createAppVersion = platform => {
      return (data) => adminCreateAppVersion({ appVersion: { ...data, platform } });
    };
    const updateAppVersion = platform => {
      return (data) => adminUpdateAppVersion({ appVersion: { ...data, platform } });
    };
    console.log(androidAppVersions);
    return (
      <div style={styles.container}>
        <Tabs value={this.state.currentTab} onChange={(_, currentTab) => this.setState({ currentTab })}>
          <Tab label='Android' />
          <Tab label='Ios' />
        </Tabs>
        {this.renderTabView(
          this.state.currentTab,
          0,
          <ListAppVersion
            loadAppVersions={adminLoadAndroidAppVersions}
            createAppVersion={createAppVersion('android')}
            updateAppVersion={updateAppVersion('android')}
            deleteAppVersion={adminDeleteAppVersion}
            pagination={androidAppVersionPagination}
            appVersions={androidAppVersions}
          />
        )}
        {this.renderTabView(
          this.state.currentTab,
          1,
          <ListAppVersion
            loadAppVersions={adminLoadIosAppVersions}
            createAppVersion={createAppVersion('ios')}
            updateAppVersion={updateAppVersion('ios')}
            deleteAppVersion={adminDeleteAppVersion}
            pagination={iosAppVersionPagination}
            appVersions={iosAppVersions}
          />
        )}
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  ...loadEntities(state, 'adminAndroidAppVersions', 'adminAndroidAppVersions', 'androidAppVersionPagination', 'androidAppVersions'),
  ...loadEntities(state, 'adminIosAppVersions', 'adminIosAppVersions', 'iosAppVersionPagination', 'iosAppVersions'),
});

export default connect(
  mapStateToProps,
  adminActions,
)(ListAppVersionPage);
