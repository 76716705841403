import * as React from "react"
import Svg, { G, Rect, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={51}
      height={30}
      viewBox="0 0 51 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G opacity={0.4}>
        <Rect width={51} height={30} rx={15} fill="#B0B0B0" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 28c7.18 0 13-5.82 13-13S22.18 2 15 2 2 7.82 2 15s5.82 13 13 13z"
          fill="#fff"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
