import * as React from "react"
import Svg, { Path, Circle } from "react-native-svg"

function SvgIcFeedUploadCamera(props) {
  return (
    <Svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M20.533 5.857a1 1 0 011 1V18a1 1 0 01-1 1h-16a1 1 0 01-1-1V6.857a1 1 0 011-1H7.47a1 1 0 00.855-.482l.541-.893A1 1 0 019.722 4h5.623a1 1 0 01.855.482l.541.893a1 1 0 00.856.482h2.936z"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
      <Circle
        cx={12.5334}
        cy={12}
        r={4}
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </Svg>
  )
}

export default SvgIcFeedUploadCamera
