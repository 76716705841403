import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { camelizeKeys } from 'humps';
import dayjs from 'dayjs';
import Flatpickr from 'react-flatpickr';
// import { browserHistory } from 'react-router';
// all codes using browserHistory are legacy in react-router v3;
// anyways, the solution preventing admins from accidently clicking backbutton, reload, or close window
// eslint-disable-next-line max-len
// I follow the https://stackoverflow.com/questions/32841757/detecting-user-leaving-page-with-react-router/50388073#50388073
// but this.props.route.path doesn't have exact path, I instead used this.props.router.location.pathname
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  GridList,
  GridListTile,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import FiberNew from '@material-ui/icons/FiberNew';
import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import partition from 'lodash/partition';
import pick from 'lodash/pick';
import without from 'lodash/without';
import difference from 'lodash/difference';
import findIndex from 'lodash/findIndex';

import * as adminActions from 'react-shared/adminActions';

import {
  loadEntities,
  objectDiff,
  textToClipboard,
  bigDecimalToBase64,
} from 'react-shared/util';

import {
  Media,
  Spinner,
} from 'components';

import {
  ItemList,
  ThumbnailList,
  ProductSelector,
} from 'components/admin';

import config from '../../config'
import { Update } from '@material-ui/icons';
import { has } from 'lodash';

const styles = theme => ({
  cancelConfirmButton: {

  },
  createPostButton: {
    position: 'absolute',
    right: theme.spacing.unit * 2,
    top: theme.spacing.unit * 2,
  },
  deletePostButton: {
    position: 'absolute',
    right: theme.spacing.unit * 12,
    top: theme.spacing.unit * 2,
  },
  deleteMediaButton: {
    width: theme.spacing.unit * 3,
    height: theme.spacing.unit * 3,
    float: 'right',
  },
  snackbar: {
    textAlign: 'center',
  },
  formControlLabel: {

  },
  dialogPaper: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
    width: '100%',
  },
  dialogPaperProductList: {
    marginTop: theme.spacing.unit * 2,
    paddingLeft: 10,
    paddingRight: 10,
    width: 960,
    maxWidth: 960,
  },
  select: {
    width: 200,
  },
  mediaId: {
    width: 280,
  },
  link: {
    width: '100%',
  },
  gridListTileBarIconButton: {
    color: '#ffffff',
  },
  paper: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
  },
  outerButton: {
    marginRight: theme.spacing.unit * 2,
  },
  subMenuTitles: {
    marginBottom: theme.spacing.unit * 2,
  },
  thumbnailArea: {
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'table-cell',
  },
  textField: {
    minHeight: 50,
  },
  textFieldHalf: {
    minHeight: 50,
    width: 280,
  },
  year: {
    width: 55,
    marginLeft: 20,
    textAlign: 'center',
  },
  dateTime: {
    width: 32,
    marginLeft: 20,
    textAlign: 'center',
  },
  productCaption: {
    display: 'flex',
    justifyContent: 'space-around',
  }
});

class StylePostPage extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUploadImage = this.handleUploadImage.bind(this);
    this.handleUploadVideo = this.handleUploadVideo.bind(this);
    this.handleAddProduct = this.handleAddProduct.bind(this);
    this.handleAddProductSubmit = this.handleAddProductSubmit.bind(this);
    this.handleOwnerUsernameChange = this.handleOwnerUsernameChange.bind(this);
    this.handleTagTextChange = this.handleTagTextChange.bind(this);
    this.renderProductCaption = this.renderProductCaption.bind(this);
    this.renderMediaCaption = this.renderMediaCaption.bind(this);
    this.state = {
      isItemDialogOpen: false,
      isRecommend: false,
      isUpdatingPost: false,
      products: [],
      checkedProducts: {},
      newCheckedProducts: {},
      productSearchKeyword: '',
      isGodoOnly: false,
      media: [],
      tagText: '',
      ownerUsername: '',
      tilesInRow: 4,
      displayAspectRatio: 1,
      visible: false,
      featured: {},
    };
  }

  async componentDidMount() {
    const { loadCreators, loadStylePost, stylePostId, stylePost, route, router } = this.props;
    if (stylePostId !== 'new') {
      if (stylePost) {
        const checkedProducts = {};
        stylePost.products.forEach((product) => {
          checkedProducts[product._id] = product;
        });
        if (stylePost.visible !== false) {
          stylePost.visible = true;
        }
        this.setState({
          checkedProducts,
          newCheckedProducts: {},
          ownerUsername: stylePost.owner.username,
          tagText: stylePost.tags.map(t => t.title).join(','),
          ...stylePost,
        });
      } else {
        await loadStylePost(stylePostId)
          .then(postData => {
            const checkedProducts = {};
            postData.products.forEach((product) => {
              checkedProducts[product._id] = product;
            });
            if (postData.visible !== false) {
              postData.visible = true;
            }
            this.setState({
              checkedProducts,
              newCheckedProducts: {},
              ownerUsername: postData.owner.username,
              tagText: postData.tags.map(t => t.title).join(','),
              ...postData,
            });
          });
        // Todo: route to admin/posts when there's no post with such stylePostId
      }
    }
    this.saveState();
    window.addEventListener('resize', () => this.handleResize());
    window.addEventListener('beforeunload', event => this.handleClose(event));
    this.mutationObserver = new MutationObserver(() => this.removeBodyStyle());
    this.mutationObserver.observe(document.body, {
      attributes: true,
      attributeFilter: ['style'],
    });
    router.setRouteLeaveHook(route, () => this.handleBackbutton());
  }

  componentDidUpdate(prevProps) {
    const { stylePostId } = this.props;
    if (prevProps.stylePostId !== stylePostId && stylePostId === 'new') {
      window.location.reload();
    }
  }

  componentWillUnmount() {
    this.mutationObserver.disconnect();
    window.removeEventListener('resize', () => this.handleResize());
    window.removeEventListener('beforeunload', event => this.handleClose(event));
  }

  saveState() {
    this.savedState = cloneDeep(this.state);
  }

  hasPostEdited() {
    return !!Object.keys(objectDiff(this.savedState, this.state)).length;
  }

  static getMediaFromItem(item) {
    return item.media;
  }

  showSnackbar(message) {
    this.setState({
      snackbarMsg: message,
      snackbarOpen: true,
    });
  }

  handleClose(event) {
    if (this.hasPostEdited()) {
      event.preventDefault();
      event.returnValue = '포스트를 작성중이시네요, 정말 창을 닫으시겠어요?';
      return event.returnValue;
    }
    return event;
  }

  removeBodyStyle() {
    const {
      isItemDialogOpen: item,
    } = this.state;
    if (!item && document.body.style.overflow === 'hidden') {
      document.body.style.overflow = '';
    }
  }

  handleBackbutton() {
    const { router: { location: { pathname } } } = this.props;
    if (this.hasPostEdited()) {
      // eslint-disable-next-line no-restricted-globals
      const toBeClosed = confirm('포스트를 작성중이시네요, 정말 창을 닫으시겠어요?');
      if (!toBeClosed) {
        window.history.pushState(null, null, pathname);
        return false;
      }
    }
    return true;
  }

  handleResize() {
    if (window.innerWidth <= 600 && this.state.tilesInRow === 4) {
      this.setState({ tilesInRow: 1 });
    } else if (window.innerWidth > 600 && this.state.tilesInRow === 1) {
      this.setState({ tilesInRow: 4 });
    }
  }

  handleSubmit(event) {
    const fields = [
      'ownerUsername', 'media', 'displayAspectRatio', 'products',
      'visible', 'featured',
    ];
    const post = pick(this.state, fields);
    if (!post.ownerUsername) {
      alert('작성자 정보가 입력되지 않았습니다');
      return;
    }
    if (post.media.length === 0) {
      alert('포스트 이미지/동영상이 등록되지 않았습니다');
      return;
    }
    if (this.state.tagText.trim().length === 0) {
      alert('태그가 입력되지 않았습니다');
      return;
    }

    const tags = this.state.tagText.split(",").map(t => {
      return t.trim();
    });
    post.tags = tags.filter(t => t.length > 0);
    if (post.tags.length === 0) {
      alert('태그가 입력되지 않았습니다');
      return;
    }

    const { adminCreateStylePost, adminUpdateStylePost, stylePostId, router } = this.props;
    if (stylePostId === 'new') {
      this.setState(
        { isUpdatingPost: true },
        () => adminCreateStylePost(post)
          .then(() => this.saveState())
          .then(() => {
            this.showSnackbar('Post uploaded successfully.');
          })
          .then(() => router.push('/admin/stylePosts'))
          .catch(() => this.setState({ isUpdatingPost: false }, () => this.showSnackbar('Update failed.')))
      );
    } else {
      this.setState(
        { isUpdatingPost: true },
        () => adminUpdateStylePost(stylePostId, post)
          .then(() => this.setState({ isUpdatingPost: false }, () => this.saveState()))
          .then(() => this.showSnackbar('Post updated succesfully.'))
          .catch(() => this.setState({ isUpdatingPost: false }, () => this.showSnackbar('Update failed.')))
      );
    }
    event.preventDefault();
  }

  handleUploadImage() {
    const {
      displayAspectRatio,
    } = this.state;

    const croppingAspectRatio = Number.parseFloat(displayAspectRatio);
    // console.log('croppingRatio=', croppingRatio);

    const uploadWidget = window.cloudinary.openUploadWidget({
      ...config.get('cloudinary'),
      sources: ['local', 'url', 'camera'],
      default_source: 'local',
      cropping: true,
      croppingShowDimensions: true,
      croppingAspectRatio,
      client_allowed_formats: ['jpg', 'jpeg', 'png', 'gif'],
      resource_type: 'image',
      folder: `stylePost-photos/${dayjs().format('YYYY-MM-DD')}`,
      multiple: false,
    }, (error, result) => {
      if (error) {
        // eslint-disable-next-line no-console
        console.error('handleUploadImage openUploadWidget error=', error);
      } else {
        // eslint-disable-next-line no-console
        // console.log('handleUploadImage openUploadWidget result=', result);
      }

      if (result && result.event === 'success') {
        uploadWidget.close();
        this.setState(prevState => ({ media: prevState.media.concat(camelizeKeys([result.info])) }));
      }
    });

    uploadWidget.open();
  }

  handleUploadVideo() {
    const cloudinaryConfig = config.get('cloudinary');
    cloudinaryConfig.upload_preset = cloudinaryConfig.video_upload_preset;
    const uploadWidget = window.cloudinary.openUploadWidget({
      ...cloudinaryConfig,
      sources: ['local', 'url', 'camera'],
      default_source: 'local',
      client_allowed_formats: ['mp4', 'mov', 'avi'],
      resource_type: 'video',
      folder: `stylePost-videos/${dayjs().format('YYYY-MM-DD')}`,
      multiple: false,
    }, (error, result) => {
      if (error) {
        // eslint-disable-next-line no-console
        console.error('handleUploadImage openUploadWidget error=', error);
      } else {
        // eslint-disable-next-line no-console
        // console.log('handleUploadImage openUploadWidget result=', result);
      }

      if (result && result.event === 'success') {
        uploadWidget.close();
        this.setState(prevState => ({ media: prevState.media.concat(camelizeKeys([result.info])) }));
      }
    });

    uploadWidget.open();
  }


  handleAddProduct() {
    const { checkedProducts } = this.state;
    const newCheckedProducts = { ...checkedProducts };
    this.setState({ newCheckedProducts, isItemDialogOpen: true });
  }

  handleAddProductSubmit() {
    const { products: prevProducts, checkedProducts, newCheckedProducts } = this.state;
    let products = cloneDeep(prevProducts);
    const oldKeys = Object.keys(checkedProducts);
    const newKeys = Object.keys(newCheckedProducts);

    const deletedKeys = difference(oldKeys, newKeys);
    deletedKeys.forEach((key) => {
      delete checkedProducts[key];
      products = products.filter(product => (product._id !== key));
    });

    newKeys.forEach((key) => {
      const newProduct = cloneDeep(newCheckedProducts[key]);
      checkedProducts[key] = newProduct;
      const productIdx = findIndex(products, ['_id', key]);
      if (productIdx >= 0) {
        const oldProduct = products[productIdx];
        newProduct.isRecommend = oldProduct.isRecommend;
        newProduct.isSeasonOut = oldProduct.isSeasonOut;
        products[productIdx] = newProduct;
      } else {
        newProduct.isRecommend = false;
        newProduct.isSeasonOut = false;
        products.push(newProduct);
      }
    });

    this.setState({
      products,
      checkedProducts,
      newCheckedProducts: {},
      isItemDialogOpen: false,
    });
  }

  handleOwnerUsernameChange(event) {
    this.setState({ ownerUsername: event.target.value });
  }

  handleTagTextChange(event) {
    this.setState({ tagText: event.target.value });
  }

  renderMediaCaption(item) {
    return (
      <Button
        variant="outlined"
        onClick={() => this.setState(prevState => ({ media: without(prevState.media, item) }))}
      >
        Delete
      </Button>
    );
  }

  renderProductCaption(product, index) {
    const { classes } = this.props;
    return (
      <div className={classes.productCaption}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            window.open(`/admin/products/${product._id}`, '_blank');
          }}
        >
          <Edit />
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            this.setState(prevState => {
              const checkedProducts = prevState.checkedProducts;
              delete checkedProducts[product._id];
              return {
                products: without(prevState.products, product),
                checkedProducts,
              };
            });
          }}
        >
          <Delete color="error" />
        </Button>
      </div>
    );
  }

  renderFeatured() {
    const { featured: { score } } = this.state;
    return (
      <div>
        <FormControlLabel
          control={(
            <Checkbox
              checked={!!score}
              disabled={score && score < 10000}
              onChange={event => {
                if (event.target.checked) {
                  this.setState({ featured: { score: dayjs().valueOf() } });
                } else {
                  this.setState({ featured: { score: null } });
                }
              }}
            />
          )}
          label={(score && score > 10000) ? 'Featured at' : 'Featured'}
        />
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css"></link>
        <Flatpickr
          key={score}
          value={score}
          options={{
            enableTime: true,
          }}
          onChange={event => this.setState({ featured: { score: (new Date(event).getTime()) } })}
        />
      </div>
    );
  }


  renderPostImageVideo() {
    const { classes } = this.props;
    const {
      displayAspectRatio,
    } = this.state;

    return (
      <Paper className={classes.paper}>
        <Typography variant="h6" className={classes.subMenuTitles}>포스트 이미지/동영상</Typography>
        <Typography variant="subheading">
          표시 비율:&nbsp;&nbsp;
          <Select
            className={classes.select}
            value={displayAspectRatio}
            onChange={event => {
              this.setState({
                displayAspectRatio: event.target.value,
              });
            }}
          >
            <MenuItem value="1" key="postpage_posttype_normal">
              <Typography variant="subheading">1:1</Typography>
            </MenuItem>
            <MenuItem value="0.8" key="postpage_posttype_promotion">
              <Typography variant="subheading">4:5</Typography>
            </MenuItem>
            <MenuItem value="0.5625" key="postpage_posttype_collection">
              <Typography variant="subheading">9:16 (IGTV)</Typography>
            </MenuItem>
          </Select>
        </Typography>

        <br />
        <br />

        <ThumbnailList
          getMedia={media => media}
          items={this.state.media}
          renderCaption={this.renderMediaCaption}
          isSortable={false}
          displayAspectRatio={displayAspectRatio}
        />
        <Button
          className={classes.outerButton}
          onClick={this.handleUploadImage}
          variant="contained"
        >
          이미지 업로드
        </Button>
        <Button
          className={classes.outerButton}
          onClick={this.handleUploadVideo}
          variant="contained"
        >
          동영상 업로드
        </Button>
      </Paper>
    );
  }

  render() {
    const { classes, stylePostId } = this.props;
    const { ownerUsername, products, newProduct, tagText } = this.state;

    return (
      <div className="container">
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>작성자</Typography>
          <TextField
            className={classes.textField}
            type="text"
            label="작성자"
            placeholder="작성자"
            value={ownerUsername || ''}
            onChange={event => this.handleOwnerUsernameChange(event)}
          />
        </Paper>
        {this.renderPostImageVideo()}
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>상품</Typography>
          {
            products.length > 0 ? (
              <ItemList
                getMedia={StylePostPage.getMediaFromItem}
                items={products}
                renderCaption={this.renderProductCaption}
                onChange={(normals, recommends) => this.setState(
                  { products: normals.concat(recommends) }
                )
                }
                isRecommendList={false}
                showRecommend={false}
                showSeasonOut={false}
              />
            ) : (
                <Typography
                  variant="caption"
                  className={classes.thumbnailArea}
                  style={{ height: 100 }}
                >
                  등록된 상품이 없습니다
                </Typography>
              )
          }
          <Button
            className={classes.outerButton}
            onClick={this.handleAddProduct}
            variant="contained"
          >
            상품 추가/수정
          </Button>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>태그</Typography>
            <TextField
              className={classes.link}
              value={tagText}
              multiline={true}
              rows={5}
              rowsMax={10}
              placeholder="포스트 태그 입력(컴마로 구분)"
              onChange={event => this.handleTagTextChange(event)}
            />
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>포스트 상태</Typography>
          {this.renderFeatured()}
          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.visible}
                onChange={(event, checked) => this.setState({ visible: checked })}
              />
            )}
            label="Visible"
          />
        </Paper>
        <div style={{ height: 200, position: 'relative' }}>
          <Button
            variant="fab"
            onClick={this.handleSubmit}
            className={classes.createPostButton}
            color="primary"
            disabled={this.state.isUpdatingPost}
          >
            {stylePostId === 'new' ? '업로드' : '완료'}
          </Button>
          {
            stylePostId !== 'new' && (
              <Button
                variant="fab"
                onClick={() => {
                  if (confirm('포스트를 제거할까요?')) { // eslint-disable-line
                    this.props.adminDeleteStylePost(stylePostId);
                    this.props.router.push('/admin/stylePosts');
                  }
                }}
                color="secondary"
                className={classes.deletePostButton}
              >
                제거
              </Button>
            )
          }
        </div>
        <Dialog open={this.state.isItemDialogOpen} fullWidth maxWidth={'lg'} classes={{ paper: classes.dialogPaperProductList }}>
          <DialogTitle>
            상품 추가/수정
          </DialogTitle>
          <DialogContent>
            <ProductSelector
              searchKeyword={this.state.productSearchKeyword}
              isGodoOnly={this.state.isGodoOnly}
              checkedProducts={this.state.newCheckedProducts}
              onChangeKeyword={event => this.setState({ productSearchKeyword: event.target.value })}
              onChangeIsGodoOnly={event => this.setState({ isGodoOnly: event.target.checked })}
              onCheckProduct={product => {
                const { newCheckedProducts } = this.state;
                if (!has(newCheckedProducts, product._id)) {
                  newCheckedProducts[product._id] = product;
                } else {
                  delete newCheckedProducts[product._id];
                }
                this.setState({ newCheckedProducts });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.cancelConfirmButton}
              onClick={this.handleAddProductSubmit}
            >
              Confirm
            </Button>
            <Button
              className={classes.cancelConfirmButton}
              onClick={() => {
                this.setState({ newCheckedProducts: {}, isItemDialogOpen: false });
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {<Snackbar
          autoHideDuration={2000}
          className={classes.snackbar}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
        />}
      </div>
    );
  }
}

StylePostPage.defaultProps = {
  post: null,
};

StylePostPage.propTypes = {
  auth: PropTypes.object.isRequired,
  stylePost: PropTypes.object,
  stylePostId: PropTypes.string.isRequired,
  router: PropTypes.object.isRequired,
  adminCreateStylePost: PropTypes.func.isRequired,
  adminDeleteStylePost: PropTypes.func.isRequired,
  loadCreators: PropTypes.func.isRequired,
  loadStylePost: PropTypes.func.isRequired,
  adminUpdateStylePost: PropTypes.func.isRequired,
};

export default compose(
  withStyles(
    styles
  ),
  connect((state, ownProps) => {
      console.log(state.entities);
      return {
    auth: state.auth,
    stylePostId: ownProps.params.stylePostId,
    stylePost: state.entities.stylePosts[ownProps.params.stylePostId],
  };}, adminActions),
)(StylePostPage);
