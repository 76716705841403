/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  StyleSheet, Platform, View, Text, Dimensions, TouchableOpacity,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';

import qs from 'qs';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';

import Section from './Section';
import CartItem from '../../../nativeComponents/CartItem';
import ShippingAddressInfo from './ShippingAddressInfo';
import DiscountInfo from './DiscountInfo';
import PaymentMethod from './PaymentMethod';
import Terms from './Terms';
import Statement from './Statement';
import { navigateToCheckoutFinish } from '../CheckoutFinishPage';
import { navigateToCheckoutPaymentGateway } from '../CheckoutPaymentGatewayPage';
import Styles from '../../../constants/Styles';

const initialLayout = { width: Dimensions.get('window').width };

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  orderItemContainer: {
    marginTop: -20,
  },
  itemDivider: {
    height: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  arrowButtonContainer: {
    flex: 1,
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    marginStart: 16,
  },
  title: {
    ...Platform.select({
      android: {
        marginEnd: 40,
      },
    }),
  },
  spinnerTextStyle: {
    color: '#FFF',
  },
  footer: {
    marginTop: 10,
    marginBottom: 38,
    alignItems: 'center',
  },
  checkoutButton: {
    alignItems: 'center',
    backgroundColor: '#000',
    height: 48,
    width: initialLayout.width - 40,
    justifyContent: 'center',
  },
  checkoutButtonText: {
    fontSize: 18,
    color: '#fff',
    textAlign: 'center',
    ...Styles.appleSDGothicNeoExtraBold,
    height: 21,
    marginTop: 1,
  },
  dividerLine: {
    height: 10,
    backgroundColor: 'rgb(246, 247, 248)',
  },
});


export class CheckoutPage extends React.Component {
  shippingAddressInfoRef = null;

  paymentMethodRef = React.createRef();

  termsRef = React.createRef();

  state = {
    checkoutInfo: {
      totalSettlePrice: 0,
      settlePrice: 0,
      couponApplyOrderNo: null,
      totalCouponOrderDcPrice: 0,
      totalMemberDcPrice: 0,
      useMileage: 0,
    },
  };

  itemIndexList = [];

  componentDidMount() {
    this.createOrder();
  }

  componentWillUnmount() {
    const { setGlobalLayoutInfo } = this.props;
    setGlobalLayoutInfo('showProgress', false);
  }

  createOrder = async () => {
    const { setGlobalLayoutInfo, loadShippingAddressList, createOrder } = this.props;
    const { itemIndexList } = this.props;
    const errorMessage = '주문 생성 중 오류가 발생하였습니다. 다시 시도해주시기 바랍니다.';
    setGlobalLayoutInfo('showProgress', true);
    try {
      await loadShippingAddressList();
      // const shippingAddressRet = await loadShippingAddressList();
      // console.log('ShippingAddressInfo loadShippingAddressList ret', shippingAddressRet);

      // console.log('createOrder send', itemIndexList);
      const ret = await createOrder(itemIndexList);
      // console.log('createOrder return', ret);
      setGlobalLayoutInfo('showProgress', false);
      if (!ret.success) {
        switch (ret.errorCode) {
        case 500:
          alert('품절 혹은 판매 예정/종료된 상품이 포함되어 있어 주문하실 수 없습니다.');
          break;

        default:
          alert(`${errorMessage} (E0${ret.errorCode})`);
          break;
        }
        const { router } = this.props;
        router.goBack();
        return;
      }

      const orderInfo = ret.data;

      const {
        orderItems,
        totalSettlePrice,
        settlePrice,
        totalDeliveryCharge,
        totalMemberDcPrice,
        totalGoodsPrice,
      } = orderInfo;

      this.setState(({ prevCheckoutInfo }) => ({
        orderInfo,
        checkoutInfo: {
          ...prevCheckoutInfo,
          orderItems,
          totalGoodsPrice,
          totalSettlePrice,
          settlePrice,
          totalDeliveryCharge,
          totalMemberDcPrice,
        },
      }));
    } catch (error) {
      // console.log('createOrder error', error);
      setGlobalLayoutInfo('showProgress', false);

      if (error.errorCode) {
        alert(`${errorMessage} (E1${error.errorCode})`);
      } else {
        alert(errorMessage);
      }

      const { router } = this.props;
      router.goBack();
    }
  }


  onShippingAddressChanged = shippingAddressInfo => {
    // console.log('onShippingAddressChanged shippingAddress=', shippingAddress);
    const { auth } = this.props;

    const {
      shippingTitle,
      shippingPhone: orderPhone,
      shippingCellPhone,
      shippingName,
      shippingZonecode,
      shippingZipcode: receiverZipcode,
      shippingAddress,
      shippingAddressSub,
      orderMemo = '',
      saveToShippingAddressList,
      newDefaultFl,
    } = shippingAddressInfo;

    const { checkoutInfo: prevCheckoutInfo } = this.state;

    this.state.checkoutInfo = {
      ...prevCheckoutInfo,
      orderPhone,
      orderCellPhone: shippingCellPhone,
      orderEmail: auth.email,
      receiverName: shippingName,
      receiverZonecode: shippingZonecode,
      receiverZipcode,
      receiverAddress: shippingAddress,
      receiverAddressSub: shippingAddressSub,
      receiverPhone: orderPhone,
      receiverCellPhone: shippingCellPhone,
      orderMemo,
    };

    this.state.shippingAddressInfo = {
      shippingTitle,
      shippingName,
      shippingZonecode,
      shippingAddress,
      shippingAddressSub,
      shippingCellPhone,
      saveToShippingAddressList,
      defaultFl: newDefaultFl ? 'y' : 'n',
    };
  }

  onDiscountUpdateStart = () => {
    // Disable checkout button;
    // console.log('onDiscountUpdateStart');
    // const { setGlobalLayoutInfo } = this.props;
    // setGlobalLayoutInfo('showProgress', true);
  }

  onDiscountUpdateEnd = discountInfo => {
    // console.log('onDiscountUpdateEnd', discountInfo);
    // const { setGlobalLayoutInfo } = this.props;
    // setGlobalLayoutInfo('showProgress', false);
    if (!discountInfo) {
      return;
    }

    this.setState(({ checkoutInfo: prevCheckoutInfo }) => ({
      checkoutInfo: {
        ...prevCheckoutInfo,
        ...discountInfo,
      },
    }));
  }

  onPaymentMethodSelected = paymentInfo => {
    // console.log('onPaymentMethodSelected', paymentInfo);
    if (!paymentInfo) {
      return;
    }

    const {
      settleKind,
      bankSender,
      bankAccount,
    } = paymentInfo;

    this.setState(({ checkoutInfo: prevCheckoutInfo }) => ({
      checkoutInfo: {
        ...prevCheckoutInfo,
        settleKind,
        bankSender,
        bankAccount,
      },
    }));
  }

  doCheckout = async () => {
    const { checkoutOrder, loadItemListInCart, setGlobalLayoutInfo } = this.props;

    const errorMessage = '결제 준비 중 오류가 발생하였습니다.';

    const {
      checkoutInfo, shippingAddressInfo,
    } = this.state;

    setGlobalLayoutInfo('showProgress', true);

    try {
      if (shippingAddressInfo.saveToShippingAddressList) {
        const { createShippingAddress } = this.props;
        // console.log("createShippingAddress send shippingAddressInfo=", shippingAddressInfo);
        const createShippingAddressRet = await createShippingAddress(shippingAddressInfo);
        // console.log("createShippingAddress ret=", createShippingAddressRet);
        if (!createShippingAddressRet.success) {
          alert(`${errorMessage} (E0${createShippingAddressRet.errorCode})`);
          return;
        }
      }

      // console.log("checkoutOrder send=", checkoutInfo);
      const ret = await checkoutOrder(checkoutInfo);
      // console.log('checkoutOrder ret=', ret);

      if (ret.success) {
        // 무통장입금 결제 성공시
        const { data: { orderNo } } = ret;
        loadItemListInCart();
        // const { router } = this.props;
        // router.goBack();

        navigateToCheckoutFinish(orderNo);
        setGlobalLayoutInfo('showProgress', false);
        return;
      }

      // PG사 이용 결제
      if (ret.errorCode === 200) {
        const { data: { orderNo, uniqueOrderCheckData } } = ret;
        navigateToCheckoutPaymentGateway(orderNo, uniqueOrderCheckData);
        setGlobalLayoutInfo('showProgress', false);
        return;
      }

      // 그 외 오류
      alert(`${errorMessage} (E1${ret.errorCode})`);
      setGlobalLayoutInfo('showProgress', false);
      return;
    } catch (error) {
      // console.log('Checkout doCheckout error=', error);

      const msg = error.errorCode ? `${errorMessage} (E2${error.errorCode})` : errorMessage;

      alert(msg);
      setGlobalLayoutInfo('showProgress', false);
    }
  }

  onPressCheckoutButton = () => {
    const {
      checkoutInfo,
    } = this.state;

    const {
      settlePrice,
    } = checkoutInfo;

    if (!this.validateInputField()) {
      return;
    }

    if (settlePrice >= 5000000) {
      alert('결제사(온라인PG) 정책으로\n현재 전 업종 1회 500만원 이상 결제가 불가합니다.');
      return;
    }

    this.doCheckout();
  }

  validateInputField() {
    const {
      shippingAddressInfoRef,
    } = this;

    if (!shippingAddressInfoRef || !shippingAddressInfoRef.validateInputField()) {
      return false;
    }

    if (!this.paymentMethodRef.current.validateInputField()) {
      return false;
    }

    if (!this.termsRef.current.validateInputField()) {
      return false;
    }

    return true;
  }


  render() {
    const {
      orderInfo,
      checkoutInfo,
    } = this.state;

    if (orderInfo === undefined) {
      return null;
    }

    const {
      settlePrice,
    } = checkoutInfo;

    const {
      orderItems, bank,
    } = orderInfo;
    const { itemIndexList } = this.props;

    // console.log("render() itemIndexList=", itemIndexList);

    return (
      <View style={styles.container}>
        <Section
          key="itemInfo"
          title="주문 상품 정보"
        >
          <View style={styles.orderItemContainer}>
            {orderItems.map((item, index) => (
              <View key={`cartItemContainer${index}`}>
                {index > 0 && <View key={`itemDivier${index}`} style={styles.itemDivider} />}
                <CartItem item={item} key={item.sno} />
              </View>
            ))}
          </View>
        </Section>

        <View key="dividerLine1" style={styles.dividerLine} />

        <Section key="shippingAddressInfo" title="배송지 정보">
          <ShippingAddressInfo
            onRef={ref => { this.shippingAddressInfoRef = ref; }}
            onDataChanged={this.onShippingAddressChanged}
          />
        </Section>

        <View key="dividerLine2" style={styles.dividerLine} />

        <Section key="discountInfo" title="할인 쿠폰/포인트 사용">
          <DiscountInfo
            itemIndexList={itemIndexList}
            orderInfo={orderInfo}
            onDiscountUpdateStart={this.onDiscountUpdateStart}
            onDiscountUpdateEnd={this.onDiscountUpdateEnd}
          />
        </Section>

        <View key="dividerLine3" style={styles.dividerLine} />

        <Section key="paymentMethod" title="결제 방법">
          <PaymentMethod
            ref={this.paymentMethodRef}
            bank={bank}
            onPaymentMethodSelected={this.onPaymentMethodSelected}
          />
        </Section>

        <View key="dividerLine4" style={styles.dividerLine} />

        <Section key="stateent" title="최종 결제 금액">
          <Statement
            checkoutInfo={checkoutInfo}
          />
        </Section>

        <Terms ref={this.termsRef} />

        <View key="footer" style={styles.footer}>
          <TouchableOpacity
            style={styles.checkoutButton}
            onPress={this.onPressCheckoutButton}
          >
            <NumberFormat
              value={settlePrice}
              displayType="text"
              thousandSeparator
              renderText={value => (
                <Text style={styles.checkoutButtonText}>{`${value}원 결제하기`}</Text>
              )}
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}


export default connect(
  (state, props) => {
    const { params: { query } } = props;

    const queryObject = qs.parse(query);
    const { itemIndexList } = queryObject;

    return {
      auth: state.auth,
      itemIndexList,
    };
  },
  actions,
)(CheckoutPage);
