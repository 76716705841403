import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Checkbox,
  Dialog,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
  withStyles,
} from '@material-ui/core';
import { compose } from 'recompose';

import Spinner from 'components/Spinner';
import ListWassilyCSReservation from 'components/admin/ListWassilyCSReservation';
import * as adminActions from 'react-shared/adminActions';

const styles = theme => ({
  container: {
    width: 1100,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export class ListWassilyCSReservationPage extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <ListWassilyCSReservation />
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(state => ({}), adminActions),
)(ListWassilyCSReservationPage);
