import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View } from 'react-native';

import ShippingAddress from '../../../nativeComponents/ShippingAddress';
import { navigateToEditShippingAddress } from '../../ShippingAddressEditPage';


const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 10,
    marginBottom: -10,
  },
});

export default class ShippingAddressInfoTabList extends React.Component {
  state = {
    selectedIndex: 0,
  };

  componentDidMount() {
    const { shippingAddresses } = this.props;

    if (shippingAddresses !== undefined) {
      const defaultAddressIndex = shippingAddresses.findIndex(({ defaultFl }) => defaultFl === 'y');
      if (defaultAddressIndex >= 0) {
        this.state.selectedIndex = defaultAddressIndex;
      }
    }
  }

  componentDidUpdate(prevProp) {
    // console.log('ShippingAddressInfoTabList componentDidUpdate');

    const { shippingAddresses: prevShippingAddresses } = prevProp;
    const { shippingAddresses: curShippingAddresses } = this.props;
    if (prevShippingAddresses.length === 0 && curShippingAddresses.length !== 0) {
      const defaultAddressIndex = curShippingAddresses.findIndex(({ defaultFl }) => defaultFl === 'y');
      if (defaultAddressIndex >= 0) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ selectedIndex: defaultAddressIndex });
      }
    }

    const { isFocused } = this.props;
    if (isFocused && curShippingAddresses.length > 0) {
      const { onDataChanged } = this.props;
      const { selectedIndex } = this.state;
      onDataChanged({
        ...curShippingAddresses[selectedIndex],
        isValid: true,
      });
    }
  }

  onPressEditShippingAddress = item => () => {
    navigateToEditShippingAddress(item.sno);
  }

  onPressDeleteShippingAddress = item => () => {
    // console.log('delete')
    const { deleteShipppingAddress } = this.props;
    deleteShipppingAddress(item.sno);
  }

  // eslint-disable-next-line class-methods-use-this
  validateInputField() {
    // console.log("ShippingAddressInfoTabList validateInputField");
    return true;
  }

  render() {
    const { isFocused } = this.props;
    if (!isFocused) {
      return false;
    }

    const { shippingAddresses } = this.props;
    const { selectedIndex } = this.state;

    // console.log('shippingAddresses=', shippingAddresses);
    // console.log('selectedIndex=', selectedIndex);

    const onPressShippingAddress = (item, index) => () => {
      // console.log('onPressShippingAddress item', item.sno);
      this.setState({ selectedIndex: index });
    };

    return (
      <View style={styles.container}>
        {shippingAddresses.map((item, index) => (
          <ShippingAddress
            key={item.sno}
            item={item}
            selected={index === selectedIndex}
            onPressRadioButton={onPressShippingAddress(item, index)}
            onPressEditButton={this.onPressEditShippingAddress(item)}
            onPressDeleteButton={this.onPressDeleteShippingAddress(item)}
          />
        ))}
      </View>
    );
  }
}
