import * as React from 'react';

function SvgIcLikeSelected(props) {
  return (
    <svg width={14} height={14} {...props}>
      <path
        // eslint-disable-next-line max-len
        d="M8.412 10.849l2.776-2.713a2.848 2.848 0 000-4.094 3.01 3.01 0 00-4.188 0 3.01 3.01 0 00-4.188 0 2.848 2.848 0 000 4.094L7 12.229l1.412-1.38z"
        fill="#EE2424"
        stroke="#EE2424"
        strokeWidth={1.5}
        fillOpacity={0.6}
        fillRule="evenodd"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgIcLikeSelected;
