import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MenuItem, Select, Typography, withStyles } from '@material-ui/core';
import { Link } from 'react-router';
import { compose } from 'recompose';
import {
  adminDeletePost,
  adminLoadPostsHidden,
  loadCreators,
  adminSetPostFeatured,
} from 'react-shared/adminActions';
import { loadEntities } from 'react-shared/util';
import Spinner from 'components/Spinner';
import ThumbnailList from 'components/admin/ThumbnailList';

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    height: 50,
  },
});

class ListHiddenPostPage extends Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.renderCaption = this.renderCaption.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.state = {
      _id: '',
    };
  }

  componentDidMount() {
    this.props.loadCreators();
    ListHiddenPostPage.fetchData(this.props);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.userId !== nextProps.userId) {
      ListHiddenPostPage.fetchData(nextProps);
    }
  }

  static fetchData(props, nextPage) {
    props.adminLoadPostsHidden(nextPage, props.userId);
  }

  static getMedia(post) {
    return post.media[0];
  }

  handleScroll() {
    if (window.pageYOffset > document.body.clientHeight - window.innerHeight - 1
        && !this.props.pagination.isFetching && this.props.pagination.nextPageUrl) {
      this.handleLoadMore();
    }
  }

  handleLoadMore() {
    ListHiddenPostPage.fetchData(this.props, true);
  }

  renderSetFeatured(post) {
    if (!post.featured || !post.featured.score) {
      return (
        <button
          type="button"
          className="btn btn-default"
          onClick={() => this.props.adminSetPostFeatured(post._id)}
        >
          <span className="glyphicon glyphicon-thumbs-up"></span>
        </button>
      );
    }
    return null;
  }

  static renderEditButton(post) {
    return (
      <Link className="btn btn-default" to={`/admin/posts/${post._id}`}>
        <span className="glyphicon glyphicon-edit"></span>
      </Link>
    );
  }

  renderDeleteButton(post) {
    return (
      <button
        type="button"
        className="btn btn-default"
        onClick={
          () => confirm('Are you sure to delete this post?') &&  //eslint-disable-line
                this.props.adminDeletePost(post._id)
        }
      >
        <span className="glyphicon glyphicon-trash"></span>
      </button>
    );
  }

  renderCaption(post) {
    const captionStyle = { height: 30 };
    return (
      <div>
        <p>{post.owner.username}</p>
        <div style={captionStyle}>
          {this.renderSetFeatured(post)}
          {ListHiddenPostPage.renderEditButton(post)}
          {this.renderDeleteButton(post)}
        </div>
      </div>
    );
  }

  render() {
    const { classes, posts, pagination: { isFetching }, router, users } = this.props;
    router.replace('/admin/stylePosts');

    const menuItems = [{ username: '숨겨진 모든 포스트 보기', userId: '', _id: '' }].concat(users);

    return (
      <div className="container">
        <div className={`row ${classes.root}`}>
          <Select
            value={this.state._id}
            onChange={event => {
              this.setState({ _id: event.target.value });
              router.push(event.target.value
                ? `/admin/posts/hidden?userId=${event.target.value}`
                : '/admin/posts/hidden');
            }}
            style={{ width: 300 }}
            displayEmpty
          >
            {menuItems.map(menuItem => (
              <MenuItem
                key={`listpostpage_menuitem_${menuItem._id}`}
                value={menuItem._id}
              >
                <Typography variant="subheading">
                  {menuItem.username}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </div>
        <ThumbnailList
          getMedia={ListHiddenPostPage.getMedia}
          items={posts}
          renderCaption={this.renderCaption}
          // {...pagination}
          // onLoadMore={this.handleLoadMore.bind(this)}
          isSortable={false}
        />
        {isFetching && <Spinner />}
      </div>
    );
  }
}

ListHiddenPostPage.propTypes = {
  adminDeletePost: PropTypes.func.isRequired,
  loadCreators: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  adminLoadPostsHidden: PropTypes.func.isRequired,
  posts: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  // role: PropTypes.string,
  adminSetPostFeatured: PropTypes.func.isRequired,
  userId: PropTypes.string,
  // username: PropTypes.string,
  users: PropTypes.array.isRequired,
};

ListHiddenPostPage.defaultProps = {
  userId: '',
};

export default compose(
  withStyles(styles),
  connect((state, ownProps) => {
    const { userId } = ownProps.location.query;
    // console.log('usernames: ' + state.auth.username);
    return {
      userId,
      // username: state.auth.username,
      users: loadEntities(state, 'creators', 'users').users,
      ...loadEntities(state, userId ? `hiddenUserPosts.${userId}` : 'hiddenPosts', 'posts'),
    };
  }, {
    adminDeletePost,
    adminLoadPostsHidden,
    loadCreators,
    adminSetPostFeatured,
  }),
)(ListHiddenPostPage);
