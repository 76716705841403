/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Platform, Image, StyleSheet, Text, View, TouchableHighlight,
  Dimensions, FlatList, ScrollView, Alert,
} from 'react-native';
import '../../../css/carousel.css';
import { Carousel } from 'react-responsive-carousel';

import CloudinaryImage from '../../../components-v2/CloudinaryImage';
import CloudinaryVideo from '../../../nativeComponents/CloudinaryVideo';
import ProfileHeader from '../../../nativeComponents/ProfileHeader';
import Products from '../../../components-v2/Products';
import Button from '../../../components-v2/Button';

import { CheckIcon, PlusIcon } from '../../../icons-v2';
import MoreIcon from '../../../icons-v2/MoreIcon';
import Ic24Comment from '../../../icons-v2/Ic24CommentIcon';
import Ic24Like from '../../../icons-v2/Ic24LikeIcon';

import Colors from '../../../constants/Colors';
import Styles from '../../../constants/Styles';
import Constants from '../../../constants/Constants';

import MoreModal from './MoreModal';

import _styles from '../../../css/modules/carousel.module.css';
import { ArrowForwardIosIcon, ArrowBackIosIcon } from '../../../icons-v2';


const windowWidth = Dimensions.get('window').width;
const imageWidth = windowWidth - Constants.viewHorizontalPadding;

const styles = StyleSheet.create({
  container: {
    paddingLeft: Constants.viewHorizontalPadding,
  },
  productContainer: {
    marginLeft: -Constants.viewHorizontalPadding,
  },
  hashtagsContainer: {
    flex: 1,
    marginTop: 20,
    marginStart: -3,
    marginEnd: Constants.innerViewHorizontalPadding - 3,
  },
  hashtagButton: {
    borderWidth: 0,
    padding: 0,
    marginHorizontal: 3,
    alignItems: 'center',
    justifyContent: 'center',
  },
  hashtagButtonText: {
    fontSize: 11,
    lineHeight: 13.2,
    color: 'black',
    opacity: 0.5,
    ...Styles.pretendard400,
  },
  recommendReplyCountContainer: {
    marginTop: 14,
    marginBottom: 40,
    flexDirection: 'row',
  },
  replyCountContainer: {
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  replyCountButtonInnerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  replyCountButton: {
    height: 20,
    padding: 0,
    borderWidth: 0,
    borderColor: 'transparent',
    borderRadius: 0,
  },
  replyCountButtonText: {
    marginStart: 3,
    color: '#000',
    fontSize: 13,
    lineHeight: 15.6,
    ...Styles.pretendard400,
  },
  replyCountButton: {
    backgroundColor: '#fff',
    padding: Constants.touchEffectWidth,
    borderWidth: 0,
    borderColor: 'transparent',
  },
  headerContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 1,
    paddingEnd: Constants.viewHorizontalPadding,
  },
  postContainer: {
    position: 'relative',
    marginTop: 10,
    marginBottom: 40,
  },
  swiper: {
    resizeMode: 'contain',
    width: '100%',
    paddingLeft: -20,
  },
  swiperDot: {
    flex: 1,
    backgroundColor: 'black',
    width: 'auto',
    height: 2,
    marginRight: 2,
    opacity: 0.2,
  },
  swiperDotActive: {
    flex: 1,
    backgroundColor: 'black',
    width: 'auto',
    height: 2,
    marginRight: 2,
  },
  swiperPageTextStyle: {
    fontSize: 13,
    lineHeight: 15.6,
    marginTop: 2,
    ...Styles.pretendard700,
  },
  productContainerStyle: {
    width: 112,
  },
  productImageStyle: {
    width: 112,
    aspectRatio: 112/149,
    borderWidth: 1,
    borderColor: Colors.productImageBorder,
    overflow: 'hidden',
  },
  productTextContainer: {
    marginTop: 4,
  },
  mediaImage: {
    resizeMode: 'cover',
    width: '100%',
  },
  mediaVideo: {
    width: '100%',
  },
  followButtonContainer: {
    backgroundColor: "#000",
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 0,
    paddingVertical: 6,
    paddingLeft: 11,
    paddingRight: 7,
  },
  followingButtonContainer: {
    backgroundColor: '#fff',
    borderWidth: 0.5,
    borderColor: '#000',
  },
  followButtonText: {
    fontSize: 15,
    lineHeight: 18,
    color: "#fff",
    ...Styles.pretendard600,
    marginRight: 4,
  },
  followingButtonText: {
    color: '#000',
  },
  moreButtonContainer: {
    backgroundColor: "#fff",
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 0,
    padding: Constants.touchEffectWidth,
  },
});

function PostMedia(props) {
  const {
    inFocusedScreen,
    displayAspectRatio,
    mediaPublicId,
    resourceType,
  } = props;

  return resourceType === 'video' ? (
    <View style={{ paddingTop: `${1 / displayAspectRatio * 100}%` }}>
      <CloudinaryVideo
        key={`video${mediaPublicId}`}
        publicId={mediaPublicId}
        resizeMode="cover"
        style={styles.mediaVideo}
        aspectRatio={displayAspectRatio}
        inFocusedScreen={inFocusedScreen}
      />
    </View>
  ) : (
    <CloudinaryImage
      key={`image${mediaPublicId}`}
      publicId={mediaPublicId}
      options={{
        crop: 'limit',
        height: 1080,
        width: 1080,
      }}
      aspectRatio={displayAspectRatio}
      style={styles.mediaImage}
    />
  );
}

const renderHashtags = (router, hashtags) => {

  return (
    <View style={styles.hashtagsContainer}>
      <FlatList
        horizontal
        showsHorizontalScrollIndicator={false}
        style={styles.hashtagsList}
        data={hashtags}
        ItemSeparatorComponent={() => <View style={{ width: 4 }}/>}
        renderItem={({item, index}) =>
          <Button
            containerStyle={styles.hashtagButton}
            titleStyle={styles.hashtagButtonText}
            key={`hashtag-${index}`}
            title={`#${item}`}
            onPress={() => {
              router.push({
                pathname: '/style/all',
                state: { hashtag: item },
              });
            }}
          />
        }
      />
    </View>
  );
}

const renderRecommendCount = (recommendCount, onPress) => {
  return (
    <View style={[styles.replyCountContainer, {marginEnd: 20}]}>
      <Button
        containerStyle={styles.replyCountButton}
        innerComponent={(
          <View style={styles.replyCountButtonInnerContainer}>
            <Ic24Like />
            <Text style={styles.replyCountButtonText}>{`추천 ${recommendCount}`}</Text>
          </View>
        )}
        onPress={onPress}
      />
    </View>
  );
}

const renderReplyCount = (replyCount, onPress) => {
  return (
    <View style={styles.replyCountContainer}>
      <Button
        containerStyle={styles.replyCountButton}
        innerComponent={(
          <View style={styles.replyCountButtonInnerContainer}>
            <Ic24Comment />
            <Text style={styles.replyCountButtonText}>{`댓글 ${replyCount}`}</Text>
          </View>
        )}
        onPress={onPress}
      />
    </View>
  );
}

const renderProducts = (
  post, products, onPressProduct,
) => {
  return (
    <View style={styles.productContainer}>
      <Products
        horizontal
        products={products}
        post={post}
        onPressProduct={onPressProduct}
        ListHeaderComponentStyle={{ width: Constants.viewHorizontalPadding }}
        ListFooterComponentStyle={{ width: Constants.viewHorizontalPadding }}
        itemContainerStyle={styles.productContainerStyle}
        productImageStyle={styles.productImageStyle}
        productTextContainerStyle={styles.productTextContainer}
      />
    </View>
  );
};

function PostContent(props) {
  const {
    post,
    media,
    inFocusedScreen,
    displayAspectRatio,
  } = props;

  const hideSwipeIndicator = (media.length < 2);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const [isLeftEnd, setIsLeftEnd] = useState(false);
  const [isRightEnd, setIsRightEnd] = useState(false);

  useEffect(() => {
    setCurrentSlideIndex(0);
  }, [post._id]);

  const handleLeftClickEvent = (e) => {

    const nowIndex = currentSlideIndex;
    let newIndex = nowIndex-1;
    if(newIndex < 0){
      newIndex = 0;
    }
    setCurrentSlideIndex(newIndex);
  }

  const handleRightClickEvent = (e) => {
    if (media && media.length > 0){
      const nowIndex = currentSlideIndex;
      let newIndex = nowIndex+1;
      if(newIndex > media.length - 1){
        newIndex = media.length - 1;
      }
      setCurrentSlideIndex(newIndex);
    }
  }

  const updateCurrentSlide = index => {
    let newIndex = index;
    if (!newIndex && newIndex !== 0) {
      newIndex = 0;
    }

    if (currentSlideIndex !== newIndex) {
      setCurrentSlideIndex(newIndex);
    }
  };

  return (
    <View style={[styles.postContainer, (hideSwipeIndicator) ? { marginBottom: 10 } : {}]}>
      <div className={_styles._desktop_carousel}>
        <Carousel
          key={`carousel-${post._id}`}
          renderThumbs={() => []}
          renderIndicator={(handler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <View style={styles.swiperDotActive} />
              );
            } else {
              return (
                <View style={styles.swiperDot} />
              );
            }
          }}
          statusFormatter={(currentItem, total) => `${currentItem}/${total}`}
          selectedItem={currentSlideIndex}
          onChange={updateCurrentSlide}
          renderArrowPrev={() => null}
          renderArrowNext={() => null}
          autoPlay
          interval={5000}
          infiniteLoop
          autoFocus
          emulateTouch
          swipeable
        >
          {media.map((m, index) => (
            <PostMedia
              key={index}
              mediaPublicId={m.publicId}
              resourceType={m.resourceType}
              displayAspectRatio={displayAspectRatio}
              {...(m.resourceType === 'video' && {
                inFocusedScreen: inFocusedScreen && index === currentSlideIndex,
              })}
            />
          ))}
        </Carousel>

        {!isLeftEnd &&
        <div className={_styles._desktop_carousel__left_exhibition}
          onClick={handleLeftClickEvent}>
          <ArrowBackIosIcon
            color={"#000000"}
            style={{position:'absolute', top:'50%', left:'50%', width:"24px", height:'24px', transform:'translate(-50%, -50%)'}}/>
        </div>}
        {!isRightEnd &&
        <div className={_styles._desktop_carousel__right_exhibition}
          onClick={handleRightClickEvent}>
          <ArrowForwardIosIcon
            color={"#000000"}
            style={{position:'absolute', top:'50%', left:'50%', width:"24px", height:'24px', transform:'translate(-50%, -50%)'}}/>
        </div>}
      </div>
    </View>
  );
}

function isPostEqual(prevProps, nextProps) {
  return (
    prevProps.post._id === nextProps.post._id &&
    prevProps.followStatus.following === nextProps.followStatus.following &&
    prevProps.inFocusedScreen === nextProps.inFocusedScreen &&
    prevProps.post.countRecommend === nextProps.post.countRecommend &&
    prevProps.post.countReply === nextProps.post.countReply
  );
}

const StyleFeedDetail = React.memo(function RawPost(props) {
  const {
    disableHeartistPress, onPressHeartist, onPressProduct, post, postDate, comments,
    inFocusedScreen, showBottomLine = true,
    followStatus: { following }, followUser, unfollowUser,
    toggleLikeStylePost, deleteStylePost,
    auth, router, setModalProps, onPressShare,
  } = props;
  const {
    products, media, owner, tags,
    displayAspectRatio = 1,
  } = post;
  const hashtags = tags.map(t => t.title);

  const showMoreControl = () => {
    const children = (
      <MoreModal
        setModalProps={setModalProps}
        router={router}
        onPressShare={onPressShare}
        post={post}
        deleteStylePost={deleteStylePost}
      />
    );

    setModalProps({
      isVisible: true,
      children: children,
    });
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <View style={Styles.touchEffectContainer}>
          <TouchableHighlight
            underlayColor={Colors.touchEffectColorWhite}
            onPress={() => onPressHeartist && onPressHeartist(post.owner._id)}
            style={Styles.touchEffect}
            disabled={disableHeartistPress}
          >
            <ProfileHeader
              date={postDate(post)}
              user={owner}
              name={owner.name}
              username={owner.username}
            />
          </TouchableHighlight>
        </View>

        {((!auth || !auth.token) || auth._id !== post.owner._id) && (
          <Button
            innerComponent={(
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text style={[styles.followButtonText, (following && styles.followingButtonText)]}>
                  { following ? '팔로우' : '팔로우' }
                </Text>
                { following ? (<CheckIcon />) : (<PlusIcon />)}
              </View>
            )}
            containerStyle={[styles.followButtonContainer, (following && styles.followingButtonContainer)]}
            onPress={() => {
              if (!auth || !auth.token) {
                alert('로그인해주시기 바랍니다.');
                router.push('/accounts/login');
                return;
              }
              if (following) {
                unfollowUser(post.owner._id);
              } else {
                followUser(post.owner._id);
              }
            }}
          />
        )}
        {(auth && auth.token && auth._id === post.owner._id) && (
          <Button
            containerStyle={styles.moreButtonContainer}
            innerComponent={(
              <MoreIcon />
            )}
            onPress={() => {
              showMoreControl();
            }}
          />
        )}
      </View>

      <PostContent
        post={post}
        media={media}
        displayAspectRatio={displayAspectRatio}
        inFocusedScreen={inFocusedScreen}
      />

      {products && renderProducts(post, products, onPressProduct)}

      {hashtags && renderHashtags(router, hashtags)}

      <View style={styles.recommendReplyCountContainer}>
        {renderRecommendCount(
          (post.countRecommend ? post.countRecommend : 0),
          () => toggleLikeStylePost(),
        )}
        {renderReplyCount(
          (post.countReply ? post.countReply : 0),
          () => {
            router.push(`/style/feed/${post._id}/replies`);
          }
        )}
      </View>
    </View>
  );
}, isPostEqual);

export default StyleFeedDetail;
