import React from 'react';
import PropTypes from 'prop-types';

import {
  View, StyleSheet, FlatList,
} from 'react-native';
import { connect } from 'react-redux';
import { actions, loadEntities } from 'shared';

import { Colors, Constants } from '../../../constants';
import ShippingAddress from '../../../components-v2/ShippingAddress';
import OverlayPage from '../../../components-v2/OverlayPage';


const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
});

class ShippingAddressSelectListPage extends OverlayPage {

  title = '배송지 목록';

  constructor(props) {
    super(props);
    const { shippingAddresses } = this.props;

    let { selectedAddressId } = this.props;
    if (selectedAddressId === null || selectedAddressId === undefined) {
      selectedAddressId = shippingAddresses.findIndex(({ defaultFl }) => defaultFl === 'y')
    }

    this.state = {
      selectedAddressId: selectedAddressId,
    };
  }

  componentDidMount() {
    const { logEventPageview } = this.props;

    logEventPageview({ page_name: 'ShippingAddressSelectList' });
  }

  renderContent() {
    const {
      shippingAddresses, selectedAddressId,
      addressSelectedCallback: callback,
    } = this.props;

    return (
      <FlatList
        style={styles.container}
        data={shippingAddresses}
        showsVerticalScrollIndicator={false}
        renderItem={({ item, index }) => {
          return (
            <React.Fragment>
              <ShippingAddress
                item={item}
                selected={item.sno === selectedAddressId}
                onPressRadioButton={() => {
                  this.setState({ selectedAddressId: item.sno });
                  if (callback) {
                    callback(item.sno);
                  }
                  this.onPressBackButton();
                }}
                onDataChanged={() => {}}
                hideEndControl
                disabledSimpleInfo
              />
              <View style={{ height: 40, borderBottomWidth: 0.5, borderBottomColor: Colors.lineGray }} />
            </React.Fragment>
          )
        }}
        ListHeaderComponent={<View style={{height: 20, borderTopWidth: 0.5, borderColor: Colors.lineGray}}/>}
        ItemSeparatorComponent={() => (<View style={{ height: 19.5 }} />)}
      />
    );
  }
}


ShippingAddressSelectListPage.propTypes = {
  setModalProps: PropTypes.func,
  selectedAddressId: PropTypes.number,
  addressSelectedCallback: PropTypes.func,
};


ShippingAddressSelectListPage.defaultProps = {

};


// export default ShippingAddressSelectListPage;
export default connect(
  state => ({
    auth: state.auth,
    ...loadEntities(state, 'shippingAddresses', 'shippingAddresses'),
  }),
  actions,
)(ShippingAddressSelectListPage);
