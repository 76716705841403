import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

import TabFeed from './TabFeed';
import TabPick from './TabPick';

import Styles from '../../../constants/Styles';
import Colors from '../../../constants/Colors';
import Constants from '../../../constants/Constants';
import { openOutlinkPage } from '../../OutlinkGatewayPage';
import { generateFirebaseEvent } from '../../../utils';


const tabBarHeight = 56;
const useStyles = () => ({
  tabs: {
    backgroundColor: '#fff',
  },
  indicator: {
    backgroundColor: '#000',
    height: 3,
  },
  tab: {
    color: 'rgba(0,0,0,0.4)',
    height: tabBarHeight,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabSelected: {
    color: '#000',
  },
  tabWrapper: {
    height: 16,
    lineHeight: 16,
    ...Styles.SFUITextHeavy,
    ...Styles.fontSize15,
  },
  rippleVisible: {
    opacity: 1,
    color: Colors.touchEffectColorWhite,
    animation: '0ms',
  },
});

const styles = {
  appBar: {
    top: Constants.mainHeaderHeight,
    shadows: ['none'],
  },
  appBarWithSmartBanner: {
    top: Constants.mainHeaderHeight + Constants.smartBannerHeight,
    shadows: ['none'],
  },
  tabbarBorderBottom: {
    position: 'absolute',
    bottom: 0,
    height: 1,
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


class HeartistProfileTabView extends React.Component {
  state = {
    index: 0,
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate() {
    // console.log('TabView componentDidUpdate props=', this.props);
  }

  updateDimensions = () => {
    this.setState({
      // windowHeight: window.innerHeight,
    });
  };

  onChange = (event, newValue) => {
    this.setState({ index: newValue });
  };

  onPressPostThumbnail = postId => {
    const { router } = this.props;
    // console.log(this);
    // console.log(this.props);
    router.push(`/posts/${postId}`);
  }

  onPressProduct = (product, { post }) => {
    const { router, historySwitch, auth } = this.props;
    const { token } = auth;

    // eslint-disable-next-line no-prototype-builtins
    if (!post && product.hasOwnProperty('post')) {
      // eslint-disable-next-line prefer-destructuring
      post = product.post;
    }

    // TODO: Post 정보가 없는 경우 이벤트를 보내지 않아도 괜찮은지 확인 필요
    if (post && post.owner) {
      generateFirebaseEvent({ product, post, where: 'post' });
    }

    if (product.godoGoodsNo) {
      router.push({
        pathname: `/product/${product.godoGoodsNo}`,
        state: {
          postId: post._id,
        },
      });
      return;
    }
    openOutlinkPage(product, post, historySwitch, token, router);
  }

  render() {
    // console.log('TabView render props=', this.props);
    const {
      index,
    } = this.state;
    const {
      classes, showSmartBanner, loadRecentPosts,
      posts, pagination, postsCount,
      headerHeight,
    } = this.props;

    let totalSmallHeaderHeight = Constants.mainHeaderHeight + tabBarHeight;
    if (showSmartBanner) {
      totalSmallHeaderHeight += Constants.smartBannerHeight;
    }
    const totalLargeHeaderHeight = totalSmallHeaderHeight + headerHeight;

    const appBarStyle = showSmartBanner ? styles.appBarWithSmartBanner : styles.appBar;

    const tabLabelFeed = postsCount ? `FEED ${postsCount.feedCount}` : 'FEED';
    const tabLabelPick = postsCount ? `PICK ${postsCount.pickCount}` : 'PICK';

    return (
      <div>
        <AppBar position="sticky" style={appBarStyle} elevation={0}>
          <Tabs
            value={index}
            onChange={this.onChange}
            variant="fullWidth"
            classes={{
              root: classes.tabs,
              indicator: classes.indicator,
            }}
          >
            <Tab
              label={tabLabelFeed}
              classes={{ root: classes.tab, selected: classes.tabSelected, wrapper: classes.tabWrapper }}
              TouchRippleProps={{ classes: { rippleVisible: classes.rippleVisible } }}
            />
            <Tab
              label={tabLabelPick}
              classes={{ root: classes.tab, selected: classes.tabSelected, wrapper: classes.tabWrapper }}
              TouchRippleProps={{ classes: { rippleVisible: classes.rippleVisible } }}
            />
            <div style={styles.tabbarBorderBottom} />
          </Tabs>
        </AppBar>

        <div style={{ paddingLeft: Constants.viewHorizontalPadding, paddingRight: Constants.viewHorizontalPadding }}>
          <TabPanel value={index} index={0}>
            <TabFeed
              posts={posts}
              smallHeaderHeight={totalSmallHeaderHeight}
              largeHeaderHeight={totalLargeHeaderHeight}
              tabBarHeight={tabBarHeight}
              loadRecentPosts={loadRecentPosts}
              pagination={pagination}
              onPressPostThumbnail={this.onPressPostThumbnail}
            />
          </TabPanel>
          <TabPanel value={index} index={1}>
            <TabPick
              posts={posts}
              smallHeaderHeight={totalSmallHeaderHeight}
              largeHeaderHeight={totalLargeHeaderHeight}
              loadRecentPosts={loadRecentPosts}
              pagination={pagination}
              onPressProduct={this.onPressProduct}
              itemCount={postsCount.pickCount}
            />
          </TabPanel>
        </div>
      </div>
    );
  }
}


export default withStyles(useStyles)(connect(
  state => ({
    showSmartBanner: state.globalLayoutInfo.showSmartBanner,
  }),
  null,
)(HeartistProfileTabView));
