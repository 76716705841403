import React, { Component } from 'react';
import PropTypes from 'prop-types';


const statDayOfWeekMap = {
  "1": "월",
  "2": "화",
  "3": "수",
  "4": "목",
  "5": "금",
  "6": "토",
  "7": "일",
};

const styles = {
  table: {
    margin: "auto",
    width: "calc(100% - 40px)",
    marginTop: 20,
    marginBottom: 20,
  },
  table_cell: {
    padding: "8px 15px",
    height: 43,
    textAlign: 'center',
    border: '1px solid',
    fontSize: 12,
  },
  table_header: {
    background: '#F6F6F6',
  },
  table_body: {
  },
}


class UserStatList extends Component{

  render() {

    const {
      visitStatRawData,
      kind,
    } = this.props;

    const styleTableHeader = {
      ...styles.table_cell,
      ...styles.table_header,
    };

    const styleTableBody = {
      ...styles.table_cell,
      ...styles.table_body,
    }

    const list = Object.values(visitStatRawData);

    return (
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styleTableHeader}>
                
            </th>
            <th style={styleTableHeader}>
              방문자수
            </th>
            <th style={styleTableHeader}>
              방문횟수
            </th>
            <th style={styleTableHeader}>
              신규방문자수
            </th>
            <th style={styleTableHeader}>
              재방문자수
            </th>
          </tr>
        </thead>
        <tbody>
          {list.map(item => {
            let key = item.date;
            switch (kind) {
              case 'hourOfDay':
                key = `${key}:00`;
                break;

              case 'dayOfWeek':
                key = statDayOfWeekMap[key];
                break;
            }

            return (
              <tr>
                <td style={styleTableBody}>
                  {key}
                </td>
                <td style={styleTableBody}>
                  {item.visit_unique.toLocaleString()}
                </td>
                <td style={styleTableBody}>
                  {item.visit_sum.toLocaleString()}
                </td>
                <td style={styleTableBody}>
                  {item.visit_unique_member_new.toLocaleString()}
                </td>
                <td style={styleTableBody}>
                  {item.visit_unique_member_return.toLocaleString()}
                </td>
              </tr>
            );
         })}
        </tbody>
      </table>
    );
  }
}

export default UserStatList;
