import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, Dimensions, View, Text, TextInput, TouchableOpacity } from 'react-native';
import findIndex from 'lodash/findIndex';

import Select from 'react-select';

import AlertDialog from '../../../components/AlertDialog';
import Colors from '../../../constants/Colors';
import Styles from '../../../constants/Styles';

const windowLayout = {
  width: Dimensions.get('window').width,
};

const titleWidth = 75;
const bodyWidth = windowLayout.width - titleWidth - 20 * 2 - 10;


const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: 20,
    marginBottom: 13,
  },
  button: {
    backgroundColor: 'rgb(246, 247, 248)',
    width: (windowLayout.width - 20 * 2),
    marginBottom: 7,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonActive: {
    backgroundColor: '#000',
  },
  buttonText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 14,
    textAlign: 'center',
    marginTop: 1,
  },
  buttonTextActive: {
    color: '#fff',
  },
  contentContainer: {
    marginStart: 10,
    marginTop: 20,
    marginBottom: 10,
  },
  contentRow: {
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'center',
  },
  contentTitle: {
    fontSize: 15,
    width: titleWidth,
    ...Styles.appleSDGothicNeoRegular,
  },
  contentBody: {
    width: bodyWidth,
    backgroundColor: 'rgb(246, 247, 248)',
    fontSize: 15,
    height: 52,
    paddingHorizontal: 16,
    ...Styles.appleSDGothicNeoRegular,
  },
  pickerIcon: {
    marginEnd: 10,
    marginTop: 7,
  },
});


const backAcountSelectStyles = {
  control: base => ({
    ...base,
    width: bodyWidth,
    fontSize: 18,
    backgroundColor: Colors.lightGray,
    paddingStart: 16,
    marginTop: 4,
    height: 52,
    color: '#000',
    borderWidth: 0,
    borderRadius: 0,
    outline: 'none',
    ...Styles.appleSDGothicNeoRegular,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  indicatorSeparator: base => ({
    ...base,
    width: 0,
  }),
  valueContainer: base => ({
    ...base,
  }),
  placeholder: base => ({
    ...base,
    color: '#000',
    paddingTop: 2,
    paddingLeft: 6,
  }),
  singleValue: base => ({
    ...base,
    color: '#000',
    paddingTop: 2,
    paddingLeft: 6,
  }),
  indicatorsContainer: base => ({
    ...base,
    marginRight: 12,
  }),
};


const itemList = [
  { key: 'card', name: '신용/체크카드', settleKind: 'pc' },
  // { key: 'transfer', name: '계좌이체', settleKind: 'pb' },
  // { key: 'bank', name: '무통장입금', settleKind: 'gb' },
  // { key: 'phone', name: '휴대폰결제', settleKind: 'ph' },
  // { key: 'kakaoPay', name: '카카오페이', settleKind: '' },
  // { key: 'naverPay', name: '네이버페이', settleKind: 'nv' },
];


export default class PaymentMethod extends React.Component {
  state = {
    selectedKey: 'card',
    paymentInfo: {
      settleKind: 'pc',
      isValid: false,
    },
    alert: {
      open: false,
      title: null,
      content: null,
      buttons: [],
    },
  };

  componentDidMount() {
    const { onPaymentMethodSelected } = this.props;
    const { paymentInfo } = this.state;
    onPaymentMethodSelected(paymentInfo);
  }


  onPressItem = key => () => {
    const { onPaymentMethodSelected } = this.props;
    const { paymentInfo } = this.state;

    const itemIdx = findIndex(itemList, ['key', key]);
    if (itemIdx < 0) {
      this.alert('아직 지원하지 않는 결제수단입니다.');
      return;
    }

    if (key !== 'bank') {
      delete paymentInfo.bankAccount;
      delete paymentInfo.bankSender;
    }

    const item = itemList[itemIdx];
    const { settleKind } = item;
    if (!settleKind) {
      this.alert('아직 지원하지 않는 결제수단입니다.');
      return;
    }
    paymentInfo.settleKind = settleKind;

    this.setState({
      paymentInfo,
      selectedKey: key,
    });
    onPaymentMethodSelected(paymentInfo);
  }

  validateInputField() {
    const {
      paymentInfo: {
        settleKind,
        bankSender,
        bankAccount,
      },
    } = this.state;

    if (settleKind === 'gb') {
      if (!bankSender) {
        this.alert('입금자명을 입력해주세요.');
        return false;
      }

      if (!bankAccount) {
        this.alert('입금계좌를 선택해주세요.');
        return false;
      }
    }
    return true;
  }

  renderItem(item) {
    const {
      name,
      key,
    } = item;

    const { selectedKey } = this.state;

    const isSelected = key === selectedKey;

    return (
      <TouchableOpacity
        key={key}
        style={[styles.button, isSelected && styles.buttonActive]}
        onPress={this.onPressItem(key)}
      >
        <Text style={[styles.buttonText, isSelected && styles.buttonTextActive]}>
          {name}
        </Text>
      </TouchableOpacity>
    );
  }

  renderContent() {
    const { selectedKey, paymentInfo: { bankSender } } = this.state;
    const { bank } = this.props;

    if (selectedKey === 'bank') {
      const onChangeText = text => {
        const { onPaymentMethodSelected } = this.props;
        this.setState(({ paymentInfo: prevPaymentInfo }) => ({
          paymentInfo: {
            ...prevPaymentInfo,
            bankSender: text,
            isValid: false,
          },
        }), () => {
          const { paymentInfo } = this.state;
          onPaymentMethodSelected(paymentInfo);
        });
      };

      const items = bank.map(item => ({
        label: `${item.bankName} ${item.accountNumber} ${item.depositor}`,
        value: item.sno,
      }));

      const onBackAccountSelected = sno => {
        const { onPaymentMethodSelected } = this.props;
        this.setState(({ paymentInfo: prevPaymentInfo }) => ({
          paymentInfo: {
            ...prevPaymentInfo,
            bankAccount: sno,
          },
        }), () => {
          const { paymentInfo } = this.state;
          onPaymentMethodSelected(paymentInfo);
        });
      };

      return (
        <View style={styles.contentContainer}>
          <View style={styles.contentRow}>
            <Text style={styles.contentTitle}>입금자명</Text>
            <TextInput
              style={[styles.contentBody, { outline: 'none' }]}
              value={bankSender}
              onChangeText={onChangeText}
            />
          </View>

          <View style={styles.contentRow}>
            <Text style={styles.contentTitle}>입금계좌</Text>
            <Select
              onChange={data => onBackAccountSelected(data.value)}
              options={items}
              clearable={false}
              searchable={false}
              placeholder="입금하실 계좌를 선택해 주세요"
              styles={backAcountSelectStyles}
              clearIndicator
              menuPortalTarget={document.body}
            />
          </View>
        </View>
      );
    }

    return null;
  }

  alert(msg) {
    this.setState({
      alert: {
        open: true,
        title: msg,
        content: null,
        buttons: [
          {
            text: '확인',
            onPress: () => {
              this.setState({ alert: { open: false } });
            },
          },
        ],
      },
    });
  }

  renderAlert() {
    const {
      alert: {
        open,
        title,
        content,
        buttons,
      },
    } = this.state;

    return (
      <div>
        <AlertDialog
          open={open}
          title={title}
          content={content}
          buttons={buttons}
        />
      </div>
    );
  }

  render() {
    return (
      <View style={styles.container}>
        {itemList.map(item => this.renderItem(item))}
        {this.renderContent()}
        {this.renderAlert()}
      </View>
    );
  }
}
