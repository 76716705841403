import { StyleSheet } from 'react-native';
import { Colors, Styles, Constants } from '../../../constants';


export const sectionStyles = StyleSheet.create({
  sectionSeparator: {
    height: 0.5,
    marginHorizontal: Constants.viewHorizontalPadding,
    backgroundColor: Colors.lineGray,
  },
  sectionContainer: {
    marginTop: 22,
    marginBottom: 40,
    marginHorizontal: Constants.viewHorizontalPadding,
  },
  sectionHeaderContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 12.5,
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  sectionHeaderText: {
    fontSize: 16,
    lineHeight: 19.2,
    color: '#000',
    ...Styles.pretendard600,
  },
  sectionHeaderSubtext: {
    color: '#000',
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard400,
  },
});

export const modalStyles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 0,
    justifyContent: 'flex-end',
  },
  innerContainer: {
    backgroundColor: '#fff',
    height: 54 + 431,
    borderTopLeftRadius: 13,
    borderTopRightRadius: 13,
    width: 'min(960px, 100vw)',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  headerContainer: {
    height: 54,
    justifyContent: 'space-between', 
    flexDirection: 'row',
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  headerTitleContainer: {
    flexGrow: 1,
    alignItems: 'center',
    paddingTop: 23,
    paddingBottom: 12,
  },
  headerTitleText: {
    color: '#000',
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard700,
  },
  headerButton:{
    padding: Constants.touchEffectWidthInHeader,
    marginHorizontal: -1 * Constants.touchEffectWidthInHeader,
    marginTop: 23 - Constants.touchEffectWidthInHeader,
    marginBottom: 13 - Constants.touchEffectWidthInHeader,
    borderWidth: 0,
    borderRadius: Constants.touchEffectBorderRadiusInHeader,
  },
  headerButtonText:{
    color: '#000',
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard400,
  },
  contentContainer: {
    flex: 1,
  },
  selectorButton: {
    height: 68,
    padding: 0,
    borderWidth: 0,
  },
  contentLineContainer: {
    width: '100%',
    paddingTop: 25,
    paddingBottom: 24,
    paddingStart: Constants.viewHorizontalPadding,
    paddingEnd: Constants.viewHorizontalPadding + 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contentText: {
    color: '#000',
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard400,
  },
  selectedContent: {
    ...Styles.pretendard600,
  },
});
