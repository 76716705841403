import * as React from 'react';

function SvgIcUnfoldSmall(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <path stroke="#000" strokeWidth="1.2" d="M5 12.262L10 7.5 15 12.262" opacity=".403" transform="translate(-333 -422) translate(333 422) rotate(-180 10 9.881)"/>
                </g>
            </g>
        </g>
    </svg>
  );
}

export default SvgIcUnfoldSmall;
