import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  const {
    checked=false,
    ...subProps
  } = props;

  return (
    <Svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...subProps}
    >
      { checked ? (
        <React.Fragment>
          <Path fill="#000" d="M0 0H14V14H0z" />
          <Path
            d="M3.438 7l2.374 2.375M10.563 4.625l-4.75 4.75"
            stroke="#fff"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
            />
        </React.Fragment>
      ) : (
        <Path stroke="#000" d="M0.5 0.5H13.5V13.5H0.5z" />
      )}
    </Svg>
  )
}

export default SvgComponent
