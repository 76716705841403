import * as React from 'react';

function SvgIcLikeSelected(props) {
  return (
    <svg width={24} height={24} {...props}>
      <path
        // eslint-disable-next-line max-len
        d="M14.421 17.555l4.759-4.804a5.163 5.163 0 000-7.25 5.044 5.044 0 00-7.18 0 5.044 5.044 0 00-7.18 0 5.163 5.163 0 000 7.25L12 20l2.421-2.445z"
        fill="#EE2424"
        stroke="#EE2424"
        strokeWidth={1.5}
        fillOpacity={0.6}
        fillRule="evenodd"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgIcLikeSelected;
