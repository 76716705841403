import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, View, Text } from 'react-native';

import '../../../../css/carousel.css';
import { Carousel } from 'react-responsive-carousel';

import { actions, loadEntities } from 'react-shared';
import { productsSortByPopularity } from 'shared/util/product';

import {
  TouchableHighlightWithContainer,
  CloudinaryImage,
} from '../../../../components-v2';

import { navigateToExhibitionPage } from '../../ExhibitionPage';
import { navigateToPreOrderDetailPage } from '../../PreOrderDetailPage';
import { navigateToProductPage } from '../../../shop/ProductPage';
import { Constants, Colors, Styles } from '../../../../constants';
import { Products } from '../../../../components-v2';

import _styles from '../../../../css/modules/carousel.module.css';
import { ArrowForwardIosIcon, ArrowBackIosIcon } from '../../../../icons-v2';


const styleHtml = {
  container: {
    marginLeft: Constants.viewHorizontalPadding,
    marginBottom: -30,
  },
  productListContainer: {
    marginLeft: -1 * Constants.viewHorizontalPadding,
  },
  containerContent: {
    position:'relative',
    objectFit: 'cover',
  },
  swiperContainer: {
  },
};

const styles = StyleSheet.create({
  productContainerStyle: {
    width: 78,
  },
  productImageStyle: {
    width: 78,
    aspectRatio: 78/103,
    borderWidth: 0.5,
    borderColor: Colors.productImageBorder,
    overflow: 'hidden',
  },
  productTextContainer: {
    height: 0,
  },
  itemContainer: {
  },
  itemTextBackground: {
    position: 'absolute',
    bottom: 0,
    start: 0,
    end: 0,
    height: 160,
  },
  itemTextContainer: {
    position: 'absolute',
    bottom: 25,
    start: 25,
    end: 25,
  },
  itemName: {
    ...Styles.pretendard700,
    fontSize: 24,
    lineHeight: 28.8,
    paddingRight: 50,
    textAlign: 'left',
    alignSelf: 'start',
    alignItems: 'center',
    color: '#ffffff',
    textShadowColor: 'rgba(0, 0, 0, 0.2)',
    textShadowOffset: {width: 0, height: 0},
    textShadowRadius: 5,
  },
  itemSubtitle: {
    ...Styles.pretendard500,
    fontSize: 16,
    lineHeight: 19.2,
    alignSelf: 'start',
    alignItems: 'center',
    textAlign: 'left',
    color: '#ffffff',
    marginTop: 7,
    textShadowColor: 'rgba(0, 0, 0, 0.2)',
    textShadowOffset: {width: 0, height: 0},
    textShadowRadius: 5,
  },
  swiperDot: {
    flex: 1,
    backgroundColor: 'black',
    width: 'auto',
    height: 2,
    marginRight: 2,
    opacity: 0.2,
  },
  swiperDotActive: {
    flex: 1,
    backgroundColor: 'black',
    width: 'auto',
    height: 2,
    marginRight: 2,
  },
});


class FeedExhibition extends React.Component {
  productsRef = React.createRef();

  state = {
    currentSlideIndex: 0,
    isLeftEnd:false,
    isRightEnd:false,
  };

  handleLeftClickEvent(e) {

    const nowIndex = this.state.currentSlideIndex;
    let newIndex = nowIndex-1;
    if(newIndex < 0){
      newIndex = 0;
    }
    this.setState({currentSlideIndex : newIndex});
  }

  handleRightClickEvent(e) {
    if (this.props.homeMdCurations && this.props.homeMdCurations.length > 0){
      const nowIndex = this.state.currentSlideIndex;
      let newIndex = nowIndex+1;
      if(newIndex > this.props.homeMdCurations.length - 1){
        newIndex = this.props.homeMdCurations.length - 1;
      }
      this.setState({currentSlideIndex : newIndex});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentSlideIndex != this.state.currentSlideIndex) {
      this.productsRef.getWrappedInstance().getWrappedInstance().scrollToTop();
    }
  }

  onPressItem(item) {
    const { router, logEventClick } = this.props;
    if (item.preorderStartDate) {
      navigateToPreOrderDetailPage({ preorderExhibitionId: item.id }, router);

      logEventClick({
        page_name: 'Home',
        click_object_name: 'preorder',
        click_object_key: item.id,
      });
    } else {
      navigateToExhibitionPage({ exhibitionId: item.id }, router);

      logEventClick({
        page_name: 'Home',
        click_object_name: 'exhibition',
        click_object_key: item.id,
      });
    }
  }

  renderMedia(item) {
    const { publicId } = item.thumbnailList[0];
    return (
      <CloudinaryImage
        key={`image${publicId}`}
        publicId={publicId}
        options={{
          crop: 'limit',
          height: 1080,
          width: 1080,
        }}
      />
    );
  }

  renderItem(item, index) {
    return (
      <TouchableHighlightWithContainer
        key={index}
        onPress={() => this.onPressItem(item)}
        >
          <View style={styles.itemContainer}>
            {this.renderMedia(item)}
            <View
              style={styles.itemTextBackground}
              pointerEvents={'none'}>
              <div style={{ background: "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3))", height: '100%'}}></div>
            </View>
            <View style={styles.itemTextContainer}>
              <Text numberOfLines={2} style={styles.itemName}>{item.title}</Text>
              <Text numberOfLines={2} style={styles.itemSubtitle}>{item.subtitle}</Text>
            </View>
          </View>
      </TouchableHighlightWithContainer>
    );
  }

  renderIndicator(handler, isSelected, index, label) {
    if (isSelected) {
      return (
        <View style={styles.swiperDotActive} />
      );
    } else {
      return (
        <View style={styles.swiperDot} />
      );
    }
  }

  render() {
    const {
      homeMdCurations,
      router,
      logEventClick,
    } = this.props;

    const {
      currentSlideIndex,
      isLeftEnd,
      isRightEnd
    } = this.state;

    const updateCurrentSlide = index => {
      let newIndex = index;
      if (!newIndex && newIndex !== 0) {
        newIndex = 0;
      }

      if (currentSlideIndex !== newIndex) {
        this.setState({ currentSlideIndex: newIndex });
      }
    };

    const onPressProduct = item => {
      const productId = item.godoGoodsNo ? item.godoGoodsNo : item.goodsNo;

      const currentItem = homeMdCurations[currentSlideIndex];
      logEventClick({
        page_name: 'Home',
        page_key: currentItem.title,
        click_object_name: 'product',
        click_object_key: productId,
      });
      navigateToProductPage({ productId }, router);
    };

    let products = [];
    if (homeMdCurations && homeMdCurations.length > 0) {
      products = productsSortByPopularity(homeMdCurations[currentSlideIndex].products);
    }

    // console.log(`homeMdCurations=${JSON.stringify(homeMdCurations)}`);

    return (
      <div style={styleHtml.container}>
        <div style={styleHtml.containerContent} className={_styles._desktop_carousel}>
          <Carousel
            style={styleHtml.swiperContainer}
            renderThumbs={() => []}
            renderIndicator={this.renderIndicator}
            statusFormatter={(currentItem, total) => `${currentItem}/${total}`}
            selectedItem={currentSlideIndex}
            onChange={updateCurrentSlide}
            renderArrowPrev={() => null}
            renderArrowNext={() => null}
            autoPlay
            interval={5000}
            autoFocus
            emulateTouch
            swipeable
          >
            {homeMdCurations.map((item, index) => (
              this.renderItem(item, index)
            ))}
          </Carousel>

          {!isLeftEnd &&
          <div className={_styles._desktop_carousel__left_exhibition}
            onClick={this.handleLeftClickEvent.bind(this)}>
            <ArrowBackIosIcon
              color={"#ffffff"}
              style={{position:'absolute', top:'50%', left:'50%', width:"24px", height:'24px', transform:'translate(-50%, -50%)'}}/>
          </div>}
          {!isRightEnd &&
          <div className={_styles._desktop_carousel__right_exhibition}
            onClick={this.handleRightClickEvent.bind(this)}>
            <ArrowForwardIosIcon
              color={"#ffffff"}
              style={{position:'absolute', top:'50%', left:'50%', width:"24px", height:'24px', transform:'translate(-50%, -50%)'}}/>
          </div>}

        </div>

        <div style={styleHtml.productListContainer}>
          <Products
            ref={ref => this.productsRef = ref }
            horizontal
            products={products}
            onPressProduct={onPressProduct}
            listHeaderComponentStyle={{ width: 20 }}
            listFooterComponentStyle={{ width: 20 }}
            itemContainerStyle={styles.productContainerStyle}
            productImageStyle={styles.productImageStyle}
            productTextContainerStyle={styles.productTextContainer}
            productViewType={1}
          />
        </div>
      </div>
    );
  }
}


export default connect(
  (state, { router }) => ({
    ...loadEntities(state, 'homeMdCurations', 'homeMdCurations'),
  }),
  actions,
)(FeedExhibition);
