import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadEntities } from 'react-shared';
import { Link } from 'react-router';
import {
  Checkbox,
  Dialog,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
  withStyles,
} from '@material-ui/core';
import { compose } from 'recompose';

import Spinner from 'components/Spinner';
import ListProduct from 'components/admin/ListProduct';
import PropTypes from 'prop-types';
import * as adminActions from 'react-shared/adminActions';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
  container: {
    width: 1100,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export class ListProductPage extends Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(this.props.location.search);
    const keyword = params.get('keyword');
    this.state = {
      searchKeyword: keyword || '',
      isGodoOnly: false,
      checkedProducts: [],
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <ListProduct
          addMode={false}
          searchKeyword={this.state.searchKeyword}
          isGodoOnly={this.state.isGodoOnly}
          checkedProducts={this.state.checkedProducts}
          onChangeKeyword={event => this.setState({ searchKeyword: event.target.value })}
          onChangeIsGodoOnly={event => this.setState({ isGodoOnly: event.target.checked })}
        />
      </div>
    );
  }
}

ListProductPage.propTypes = {};

export default compose(
  withStyles(styles),
  connect(state => ({}), adminActions),
)(ListProductPage);
