import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View } from 'react-native';

import ShippingAddressEditForm from '../../../nativeComponents/ShippingAddressEditForm';


const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    paddingHorizontal: 20,
  },
});

export default class ShippingAddressInfoTabNew extends React.Component {
  editFormRef = React.createRef();

  state = {
    data: {
      newDefaultFl: false,
      shippingTitle: '',
      shippingName: '',
      shippingZonecode: '',
      shippingAddress: '',
      shippingAddressSub: '',
      shippingCellPhone: '',
      saveToShippingAddressList: false,
    },
  };

  componentDidUpdate(prevProps, prevState) {
    const { isFocused: prevIsFocused } = prevProps;
    const { isFocused } = this.props;
    if ((!prevIsFocused && isFocused) || (prevState !== this.state)) {
      const { onDataChanged } = this.props;
      const { data } = this.state;
      onDataChanged(data);
    }
  }

  validateInputField() {
    // console.log("ShippingAddressInfoTabNew validateInputField");
    return this.editFormRef.current.validateInputField();
  }

  render() {
    const {
      isFocused,
    } = this.props;
    const { data } = this.state;

    if (!isFocused) {
      return null;
    }

    return (
      <View style={styles.container}>
        <ShippingAddressEditForm
          ref={this.editFormRef}
          data={data}
          onDataChanged={
            newData => this.setState(
              ({ data: prevData }) => ({ data: Object.assign({}, prevData, newData) }),
            )
          }
          showCheckBoxSetDefault
          showCheckBoxSaveToShippingAddress
        />
      </View>
    );
  }
}
