import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View, Text } from 'react-native';

import {
  Constants,
  Styles,
  Colors,
 } from '../../constants';


import ArrowBack from './ArrowBack';


const styles = StyleSheet.create({
  container: {
    paddingLeft: 16,
    paddingEnd: Constants.viewHorizontalPadding,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: Constants.mainHeaderHeight,
    height: 66,
  },
  titleText: {
    ...Styles.pretendard700,
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
    marginTop: 24,
    marginBottom: 23,
  }
});


class HeaderLeftText extends React.Component {

  render() {

    const {
      containerStyle,
      router,
      title,
      defaultRouterBackPath,
      showBackButton,
    } = this.props;

    return (
      <View style={[styles.container, containerStyle]}>
        { showBackButton && (
          <ArrowBack
            backButtonStyle="arrow"
            router={router}
            defaultRouterBackPath={defaultRouterBackPath}
          />
        )}
        <Text style={styles.titleText}>{title}</Text>
      </View>
    );
  }
}


HeaderLeftText.propTypes = {
  title: PropTypes.string.isRequired,
};


HeaderLeftText.defaultProps = {
};


export default HeaderLeftText;
