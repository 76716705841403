/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import {
  View, Text, StyleSheet, TouchableHighlight,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import NumberFormat from 'react-number-format';

// eslint-disable-next-line import/no-extraneous-dependencies
import { parseDate, getRemaingTime, convertKstToUtc } from 'shared/util';
import CloudinaryImage from './CloudinaryImage';
import LikeButton from './LikeButton';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';
import Constants from '../constants/Constants';
import SymbolHeartitLogoIcon from '../icons/SymbolHeartitLogoIcon';
import ProductLabel from '../nativeComponents/ProductLabel';


const styles = StyleSheet.create({
  container: {
    width: 100,
  },
  itemImageContainer: {
    position: 'relative',
    height: 0,
    paddingTop: '100%',
    backgroundColor: Colors.semiGray,
  },
  button: {
    width: 24 + Constants.touchEffectWidth * 2,
    height: 24 + Constants.touchEffectWidth * 2,
  },
  smallButton: {
    width: 18 + Constants.touchEffectWidth * 2,
    height: 18 + Constants.touchEffectWidth * 2,
  },
  itemLikeButton: {
    position: 'absolute',
    top: 1,
    right: 1,
  },
  outlinkButton: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  outlinkIcon: {
    color: '#959595',
  },
  seasonOutOverlayContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
  },
  seasonOutOverlayText: {
    color: '#fff',
    textShadowColor: 'rgba(0, 0, 0, 0.1)',
    textShadowOffset: {
      width: 0,
      height: 0,
    },
    textShadowRadius: 6,
    letterSpacing: 0,
    fontSize: 12,
    marginTop: 6,
    height: 28,
    lineHeight: 14,
    textAlign: 'center',
    ...Styles.SFUITextBold,
  },
  closedOverlayContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
  },
  closedOverlayText: {
    height: 24,
    ...Styles.SFUITextBold,
    fontSize: 20,
    lineHeight: 24,
    color: '#fff',
    textShadowColor: 'rgba(0, 0, 0, 0.1)',
    textShadowOffset: {
      width: 0,
      height: 0,
    },
    textShadowRadius: 6,
  },
  closedOverlayTextSmall: {
    height: 14,
    fontSize: 12,
    lineHeight: 14,
  },
  textContainer: {
    marginTop: 8,
  },
  textContainerSmall: {
    marginTop: 7,
  },
  itemPreOrderInfo: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 20,
    lineHeight: 24,
    marginTop: 2,
    marginBottom: 5,
  },
  itemBrandText: {
    marginBottom: 5,
    lineHeight: 17,
    ...Styles.SFUIDisplaySemiBold,
    ...Styles.fontSize14,
  },
  itemBrandTextSmall: {
    marginBottom: 2,
    lineHeight: 14,
    ...Styles.SFUIDisplaySemiBold,
    ...Styles.fontSize12,
  },
  itemTitleText: {
    marginBottom: 5,
    lineHeight: 17,
    ...Styles.appleSDGothicNeoRegular,
    ...Styles.fontSize14,
  },
  productSellPrice: {
    lineHeight: 17,
    ...Styles.SFUITextRegular,
    ...Styles.fontSize14,
  },
  productSellPriceSmall: {
    lineHeight: 15,
    ...Styles.fontSize12,
  },
  productSellPriceSoldout: {
    opacity: 0.35,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  priceContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  colorHidden: {
    color: '#0000',
  },
  colorGray: {
    color: 'gray',
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 6,
  },
  labelContainerSmall: {
    marginBottom: 5,
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
  discountRate: {
    ...Styles.SFUITextSemiBold,
    fontSize: 14,
    height: 16,
    color: 'rgb(235, 69, 69)',
    marginStart: 8,
  },
  discountRateSmall: {
    marginStart: 3,
    height: 12,
    fontSize: 10,
  },
});

const stylesHtml = {
  itemImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
};


function getRemaingTimeText(targetDate) {
  const {
    minutes,
    hours,
    days,
  } = getRemaingTime(targetDate);

  let preOrderRemaingTimeText = '';
  if (days > 0) {
    preOrderRemaingTimeText += `${days}일 `;
  }

  if (hours > 0) {
    preOrderRemaingTimeText += `${hours}시간 `;
  }

  if (minutes > 0) {
    preOrderRemaingTimeText += `${minutes}분 `;
  }

  preOrderRemaingTimeText += '남음';

  return preOrderRemaingTimeText;
}

function getProductInfo(product) {
  const {
    launchYmd,
    salesStartYmd,
    salesEndYmd,
    timestampNowYmd,
    godoGoodsNo,
    soldOut,
  } = product;
  let { isSeasonOut } = product;

  let isPreOrder = !!launchYmd && launchYmd !== '0000-00-00';
  let launchDate = null;
  let salesStartDate = null;
  let salesEndDate = null;
  let timestampNow = Date.now();
  if (isPreOrder) {
    launchDate = convertKstToUtc(parseDate(launchYmd));
    salesStartDate = convertKstToUtc(parseDate(salesStartYmd, true));
    salesEndDate = convertKstToUtc(parseDate(salesEndYmd, true));
  }
  if (!!timestampNowYmd) {
    timestampNow = convertKstToUtc(parseDate(timestampNowYmd, true));
  }

  let isShopProduct = !!godoGoodsNo;
  let isSoldOut = soldOut === 'y';
  isSeasonOut = !!isSeasonOut;

  return {
    isPreOrder,
    launchDate,
    salesStartDate,
    salesEndDate,
    timestampNow,
    isShopProduct,
    isSoldOut,
    isSeasonOut,
  };
}


class Product extends React.Component {
  constructor(props) {
    super(props);

    const { product } = props;
  }

  componentDidMount() {
    const { product } = this.props;
  }

  componentDidUpdate(prevProps) {
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { product } = nextProps;
    const { product: prevProduct } = this.props;

    const prevProductInfo = getProductInfo(prevProduct);
    const productInfo = getProductInfo(product);

    let update = false;
    const keys = Object.keys(productInfo);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      let newVal = productInfo[key];
      let prevVal = prevProductInfo[key];
      if (newVal && typeof newVal !== 'string') {
        newVal = newVal.toString();
      }
      if (prevVal && typeof prevVal !== 'string') {
        prevVal = prevVal.toString();
      }
      if (newVal !== prevVal) {
        update = true;
        break;
      }
    }
    return update;
  }

  renderImage() {
    const {
      heartistsPick, product,
      isShopProduct, isSmallVersion = false,
      enableDeemedSeasonOut,
      imageContainerStyle = {},
    } = this.props;
    const { isSeasonOut, isPreOrder, salesEndDate, timestampNow,
      isShopProduct: hasGodoGoodsNo,
    } = getProductInfo(product);

    let {
      post,
    } = this.props;

    const {
      media,
    } = product;
    let { _id } = product;

    // eslint-disable-next-line no-prototype-builtins
    if (!post && product.hasOwnProperty('post')) {
      // For histories in home tab
      // eslint-disable-next-line prefer-destructuring
      post = product.post;
    }

    let imageElement = null;

    if (media !== undefined) {
      imageElement = (
        <CloudinaryImage
          options={{
            crop: 'limit',
            height: 360,
            width: 360,
          }}
          publicId={media.publicId}
          style={stylesHtml.itemImage}
        />
      );
    }

    if (isShopProduct) {
      imageElement = (
        <img
          referrerPolicy="no-referrer"
          alt=""
          src={product.goodsImageSrc}
          style={stylesHtml.itemImage}
        />
      );
      _id = product.goodsNo;
    }

    let seasonOutOverlay = null;
    if (enableDeemedSeasonOut && isSeasonOut) {
      seasonOutOverlay = (
        <View style={styles.seasonOutOverlayContainer}>
          <Text style={styles.seasonOutOverlayText}>{'SEASON\nOUT'}</Text>
        </View>
      );
    }

    let closedOverlay = null;
    if (isPreOrder) {
      if ((salesEndDate - timestampNow) <= 0) {
        closedOverlay = (
          <View style={styles.closedOverlayContainer}>
            <Text style={[
              styles.closedOverlayText,
              isSmallVersion && styles.closedOverlayTextSmall,
            ]}
            >
              CLOSED
            </Text>

          </View>
        );
      }
    }

    return (
      <View
        style={[styles.itemImageContainer, imageContainerStyle]}
      >
        {imageElement}

        {seasonOutOverlay}
        {closedOverlay}

        {(!isSeasonOut || hasGodoGoodsNo) && (
        <LikeButton
          post={post}
          item={Object.assign({}, product, { _id })}
          styles={[(isSmallVersion) ? styles.smallButton : styles.button, styles.itemLikeButton]}
          heartistsPick={heartistsPick}
          isShopProduct={isShopProduct}
          isSmallVersion={isSmallVersion}
        />
        )}
      </View>
    );
  }

  renderLabel() {
    const {
      product,
      isSmallVersion = false,
      enableLabelSimilar, enableLabelPreOrder,
      enableLabelHeartitLogo,
    } = this.props;
    const { isPreOrder, isShopProduct } = getProductInfo(product);

    let {
      post,
    } = this.props;

    const {
      isRecommend,
    } = product;

    // eslint-disable-next-line no-prototype-builtins
    if (!post && product.hasOwnProperty('post')) {
      // For histories in home tab
      // eslint-disable-next-line prefer-destructuring
      post = product.post;
    }

    const showPreOrder = enableLabelPreOrder && isPreOrder;
    const showSimilarItemLabel = enableLabelSimilar && isRecommend;
    const showHeartitLogo = enableLabelHeartitLogo && isShopProduct;

    if (!showPreOrder
      && !showSimilarItemLabel
      && !showHeartitLogo) {
      return null;
    }

    return (
      <View style={[styles.labelContainer, (isSmallVersion) ? styles.labelContainerSmall : {}]}>
        { showPreOrder && (
          <ProductLabel
            style={{ marginEnd: 3 }}
            backgroundColor={Colors.labelPreOrder}
          >
            PRE ORDER
          </ProductLabel>
        )}
        { showSimilarItemLabel && (
          <ProductLabel
            style={{ marginEnd: 3 }}
            backgroundColor={Colors.labelSimilar}
          >
            SIMILAR
          </ProductLabel>
        )}
        { showHeartitLogo && (
          <SymbolHeartitLogoIcon />
        )}
      </View>
    );
  }

  renderPreOrderInfo() {
    const {
      product,
      isPreOrder: isInPreOrderCategory = false,
    } = this.props;
    const {
      isPreOrder,
      salesStartDate,
      salesEndDate,
      timestampNow,
    } = getProductInfo(product);

    if (!isInPreOrderCategory || !isPreOrder) {
      return null;
    }

    let preOrderInfoText = '프리오더 종료';
    if ((salesEndDate - timestampNow) > 0) {
      if ((salesStartDate - timestampNow) < 0) {
        preOrderInfoText = getRemaingTimeText(salesEndDate);
      } else {
        // eslint-disable-next-line max-len
        let hours = salesStartDate.getHours();
        const ampm = hours < 12 ? '오전' : '오후';
        if (hours > 12) {
          hours -= 12;
        }

        preOrderInfoText = `${salesStartDate.getFullYear()}년`
          + ` ${salesStartDate.getMonth() + 1}월`
          + ` ${salesStartDate.getDate()}일`
          + ` ${ampm} ${hours}시`
          + '부터\n주문 가능';
      }
    }

    return (
      <Text style={styles.itemPreOrderInfo}>
        {preOrderInfoText}
      </Text>
    );
  }

  render() {
    const {
      style, containerStyle, onPressProduct, product,
      isShopProduct, isSmallVersion,
    } = this.props;
    const { isSoldOut } = getProductInfo(product);

    let {
      post,
    } = this.props;

    const {
      retails = null,
    } = product;
    let { _id, name, brand } = product;

    // eslint-disable-next-line no-prototype-builtins
    if (!post && product.hasOwnProperty('post')) {
      // For histories in home tab
      // eslint-disable-next-line prefer-destructuring
      post = product.post;
    }

    if ((!retails || retails.length <= 0) && isShopProduct === false) {
      return null;
    }

    let orgPrice = 0;
    let sellPrice = 0;
    // let link = '';

    if (retails !== null && retails.length > 0) {
      try {
        const {
          orgPrice: { amount: orgPriceAmount },
        } = retails[0];
        orgPrice = orgPriceAmount;
      } catch(e) {
        //console.error(e);
      }

      try {
        const {
          sellPrice: { amount: sellPriceAmount },
        } = retails[0];
        sellPrice = sellPriceAmount;
      } catch(e) {
        //console.error(e);
      }
      // const retail = product.retails[product.defaultRetailIdx];
      // link = retail.orgLink || retail.link;
    }

    if (isShopProduct) {
      orgPrice = Number.parseInt(product.fixedPrice, 10);
      sellPrice = Number.parseInt(product.goodsPrice, 10);
      name = product.goodsNm;
      brand = product.brandNm;
      _id = product.goodsNo;
    }

    // const showOutlinkButton = (!product.godoGoodsNo) && link !== undefined && link !== '';

    const discountRate = orgPrice > sellPrice ? Math.round((1 - sellPrice / orgPrice) * 100) : 0;

    return (
      <TouchableHighlight
        key={_id}
        underlayColor={Colors.touchEffectColorWhite}
        onPress={onPressProduct}
        style={[styles.touchEffect, style]}
      >
        <View
          style={[styles.container, containerStyle]}
        >
          {this.renderImage()}

          <View style={(isSmallVersion) ? styles.textContainerSmall : styles.textContainer}>
            {this.renderLabel()}

            {this.renderPreOrderInfo()}

            <Text
              numberOfLines={1}
              style={(isSmallVersion) ? styles.itemBrandTextSmall : styles.itemBrandText}
            >
              {brand}
            </Text>
            {!isSmallVersion && (
              <Text
                numberOfLines={1}
                style={styles.itemTitleText}
              >
                {name}
              </Text>
            )}

            <View style={styles.priceContainer}>
              {sellPrice != 0 && (
                <NumberFormat
                  value={sellPrice}
                  displayType="text"
                  thousandSeparator
                  renderText={value => (
                    <Text
                      style={[
                        styles.productSellPrice,
                        isSmallVersion && styles.productSellPriceSmall,
                        isSoldOut && styles.productSellPriceSoldout,
                      ]}
                    >
                      {value}
                      <Text style={[Styles.appleSDGothicNeoRegular]}>원</Text>
                    </Text>
                  )}
                />
              )}
              {(discountRate > 0 && !isSoldOut) && (
                <Text style={[
                  styles.discountRate,
                  isSmallVersion && styles.discountRateSmall,
                ]}
                >
                  {`${discountRate}%`}
                </Text>
              )}
            </View>
          </View>
        </View>
      </TouchableHighlight>
    );
  }
}

Product.propTypes = {
  enableLabelSimilar: PropTypes.bool,
  enableLabelPreOrder: PropTypes.bool,
  enableLabelHeartitLogo: PropTypes.bool,
  enableDeemedSeasonOut: PropTypes.bool,
  isShopProduct: PropTypes.bool,
  isSmallVersion: PropTypes.bool,
};

Product.defaultProps = {
  enableLabelSimilar: false,
  enableLabelPreOrder: false,
  enableLabelHeartitLogo: false,
  enableDeemedSeasonOut: false,
  isShopProduct: false,
  isSmallVersion: false,
};

export default Product;
