import * as React from "react"
import Svg, { Rect, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M4.74084 3.96704C4.72933 3.82895 4.8383 3.71053 4.97687 3.71053H19.9703C20.1089 3.71053 20.2178 3.82895 20.2063 3.96704L19.4168 13.4407C19.4066 13.5635 19.304 13.6579 19.1808 13.6579H5.76634C5.64316 13.6579 5.54054 13.5635 5.53031 13.4407L4.74084 3.96704Z"
        stroke="#000"
        strokeWidth={1.42105}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <Path
        d="M5.36842 13.8945H19.5789L21.5317 19.7529C21.7362 20.3663 21.2796 20.9998 20.633 20.9998H4.3144C3.66777 20.9998 3.21117 20.3663 3.41565 19.7529L5.36842 13.8945Z"
        stroke="#000"
        strokeWidth={1.42105}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default SvgComponent
