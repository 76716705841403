import * as React from 'react';

function SvgIcTabHome(props) {
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M12 2l10 5.372V22h-7.5v-7h-5v7H2V7.372L12 2z"
        stroke="#FFF"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgIcTabHome;

