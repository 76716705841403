/* eslint-disable no-restricted-globals */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View, Text, TouchableHighlight } from 'react-native';
import { Link } from 'react-router';
import NumberFormat from 'react-number-format';

// eslint-disable-next-line import/no-extraneous-dependencies
import { parseDate } from 'shared/util';

import ShareDialog from '../../../components/ShareDialog';

import LikeNormalIcon from '../../../icons/LikeNormalIcon';
import LikeSelectedIcon from '../../../icons/LikeSelectedIcon';
import ShareIcon from '../../../icons/ShareIcon';

import Colors from '../../../constants/Colors';
import Styles from '../../../constants/Styles';
import ProductLabel from '../../../nativeComponents/ProductLabel';


const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingHorizontal: 20,
    flexDirection: 'column',
  },
  shareButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  brand: {
    fontSize: 18,
    lineHeight: 21,
    height: 21,
    marginTop: 2,
    marginBottom: 6,
    textAlign: 'center',
    color: 'black',
    ...Styles.SFUITextSemibold,
  },
  title: {
    fontSize: 18,
    lineHeight: 21,
    height: 21,
    textAlign: 'center',
    color: 'black',
    ...Styles.appleSDGothicNeoRegular,
  },
  reviewInfo: {
    flexDirection: 'row',
    marginTop: 24,
    marginBottom: 7,
    justifyContent: 'center',
  },
  reviewCount: {
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 30,
    ...Styles.appleSDGothicNeoSemiBold,
  },
  emptyReviewSpacer: {
    height: 40,
  },
  promoInfo: {
    alignItems: 'center',
    marginTop: 10,
  },
  promoInfoText: {
    color: 'rgb(238, 36, 36)',
  },
  priceInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  priceInfoSoldOut: {
    opacity: 0.45,
  },
  priceDiscountInfo: {
    flexDirection: 'row',
  },
  sellPrice: {
    fontSize: 24,
    height: 30,
    textAlign: 'right',
    ...Styles.SFUITextSemiBold,
  },
  discountRate: {
    fontSize: 16,
    height: 20,
    marginStart: 8,
    color: 'rgb(238, 36, 36)',
    ...Styles.SFUITextHeavy,
  },
  productOrgPrice: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
    fontSize: 16,
    paddingLeft: 5,
    height: 20,
    ...Styles.SFUITextRegular,
  },
  labelContainer: {
    alignItems: 'center',
    marginBottom: 6,
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});

const webStyles = {
  reviewHeartIcon: {
    marginLeft: 3,
    marginRight: 3,
  },
  brandContainer: {
    textDecoration: 'none',
    textAlign: 'center',
    marginTop: 2,
    marginBottom: 6,
  },
};

function renderReviewStar(reviewStar) {
  const count = Math.max(Math.min(Math.floor(reviewStar), 5), 0);

  const stars = [];
  for (let i = 0; i < count; i++) {
    stars.push(
      <LikeSelectedIcon key={`starOn${i}`} style={webStyles.reviewHeartIcon} />
    );
  }

  for (let i = 0; i < 5 - count; i++) {
    stars.push(
      <LikeNormalIcon key={`starOff${i}`} style={webStyles.reviewHeartIcon} />
    );
  }
  return (
    <View style={{ flexDirection: 'row' }}>
      {stars}
    </View>
  );
}

class ProductInfo extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isPreOrder: false,
      // launchYmd: '',
      // salesEndYmd: '',
      // soldOut: false,
      // isSeasonOut: false,
      // isRecommend: false,
      // isSale: false,
      openShareModal: false,
    };
  }

  setPreOrderState = () => {
    const {
      product: {
        launchYmd,
        salesEndYmd,
      },
    } = this.props;

    const newState = {
      isPreOrder: launchYmd && launchYmd !== '0000-00-00',
    };

    if (newState.isPreOrder) {
      newState.launchYmd = parseDate(launchYmd);
      newState.salesEndYmd = parseDate(salesEndYmd);
    }

    this.setState(newState);
  }

  componentDidMount() {
    this.setPreOrderState();
  }

  componentDidUpdate(prevProps) {
    const { product } = this.props;

    if (prevProps.product._id !== product._id) {
      this.setPreOrderState();
    }
  }

  onPressShareButton = () => {
    // console.log('onPressShareButton');
    this.setState({ openShareModal: true });
  };

  handleShareModalClose = () => {
    // console.log(`handleShareModalClose()`);
    this.setState({ openShareModal: false });
  }

  onPressBrandNm = () => {
    const { router, product } = this.props;
  }

  renderLabel = () => {
    const { isPreOrder } = this.state;

    if (!isPreOrder) {
      return null;
    }

    return (
      <View style={styles.labelContainer}>
        { isPreOrder && (
          <ProductLabel backgroundColor={Colors.labelPreOrder}>PRE ORDER</ProductLabel>
        )}
      </View>
    );
  }

  isReviewExist = () => {
    const { product: { reviewCnt = 0 } } = this.props;
    return reviewCnt > 0;
  }
  render() {
    const { product } = this.props;
    const { openShareModal } = this.state;
    const discountRate = Math.round(product.discPricePct * 100);
    const isSoldOut = product.soldOut === 'y';

    return (
      <View style={styles.container}>
        <View style={styles.shareButtonContainer}>
          <View style={styles.touchEffectContainer}>
            <TouchableHighlight
              underlayColor={Colors.touchEffectColorWhite}
              onPress={this.onPressShareButton}
              style={styles.touchEffect}
            >
              <ShareIcon />
            </TouchableHighlight>
          </View>
        </View>

        { this.renderLabel() }

        <Link
          to={`/shop?brandNm=${product.brandNm}`}
          style={webStyles.brandContainer}
        >
          <Text style={styles.brand}>
            {product.brandNm}
          </Text>
        </Link>
        <Text style={styles.title}>{product.title}</Text>

        {product.cateNm && product.cateNm.toUpperCase() === 'LIVING' && (
          <View style={styles.promoInfo}>
            <Text style={styles.promoInfoText}>290만원 이상 구매시 5% 할인 적용</Text>
          </View>
        )}


        {this.isReviewExist() && (
        <View style={styles.reviewInfo}>
          {renderReviewStar(product.reviewStar)}
        </View>
        )}
        {this.isReviewExist() && (
        <Text style={styles.reviewCount}>
          {product.reviewCnt}
          건 리뷰
        </Text>
        )}
        {!this.isReviewExist() && (
          <View style={styles.emptyReviewSpacer}/>
        )}

        <View
          style={[
            styles.priceInfo,
            isSoldOut && styles.priceInfoSoldOut,
          ]}
        >
          <View>
            {discountRate > 0 && (
              <View style={styles.priceDiscountInfo}>
                <NumberFormat
                  value={Math.floor(product.fixedPrice)}
                  displayType="text"
                  thousandSeparator
                  renderText={value => (
                    <Text style={[
                      styles.productOrgPrice,
                      (product.goodsPrice > 0 ? styles.colorGray : styles.colorHidden),
                    ]}
                    >
                      {value}
                      <Text style={Styles.appleSDGothicNeoRegular}>원</Text>
                    </Text>
                  )}
                />
                <Text style={styles.discountRate}>
                  {isNaN(discountRate) ? '' : `${discountRate}%`}
                </Text>
              </View>
            )}
          </View>

          <NumberFormat
            value={product.goodsPrice}
            displayType="text"
            thousandSeparator
            decimalScale={0}
            renderText={value => (
              <Text style={styles.sellPrice}>
                {value}
                <Text style={Styles.appleSDGothicNeoSemiBold}>원</Text>
              </Text>
            )}
          />
        </View>
        <ShareDialog
          open={openShareModal}
          onClose={this.handleShareModalClose}
          product={product}
        />
      </View>
    );
  }
}

export default ProductInfo;
