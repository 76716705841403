import React from 'react';
import PropTypes from 'prop-types';

import {
  StyleSheet,
  TouchableHighlight,
  View,
  Text,
} from 'react-native';

import {
  Colors, Styles,
} from '../../constants';

import PickerModal from './PickerModal';


const styles = StyleSheet.create({
  container: {
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputText: {
  },

  pickerLayoutContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    paddingHorizontal: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    paddingTop: 201,
    paddingBottom: 239,
  },
  pickerContainer: {
    backgroundColor: '#fff',
    paddingBottom: 17,
    borderRadius: 2,
  },
  pickerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 60,
    paddingStart: 20,
    paddingEnd: 16,
  },
  pickerHeaderText: {
    height: 27,
    marginTop: 15,
    ...Styles.appleSDGothicNeoBold,
    fontSize: 18,
    lineHeight: 27,
  },
  pickerHeaderCloseButton: {
    marginTop: 14,
    height: 32,
  },
  itemListContainer: {
    paddingHorizontal: 20,
  },
  itemSeparater: {
    height: 1,
    backgroundColor: '#efefef',
  },
  itemContainer: {
    height: 20 + 22 + 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemText: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 22,
  },
});


class CustomPickerSelect extends React.PureComponent {
  state = {
    visible: false,
  }

  onPressOpen = () => {
    // this.setState({ visible: true });
   
    const {
      setModalProps,
      modalProps,
      onValueChange,
      items,
      value,
    } = this.props;

    const onChange = (item, index) => {
      // console.log(`CustomPickerSelect.onChange(${index}) item=`, item);
      onValueChange(item, index);

      setModalProps({
        isVisible: false,
        children: null,
      });
    };

    const children = (
      <PickerModal
        title='사이즈'
        selectedItem={value}
        onChange={onChange}
        setModalProps={setModalProps}
        modalProps={modalProps}
        itemList={items}
        backdropStyle={{ height: '40vh' }}
        />
    );

    setModalProps({
      isVisible: true,
      children: children,
    });
  }

  onPressClose = () => {
    this.setState({ visible: false });
  }

  onPressItem = (item, index) => () => {
    const { onValueChange } = this.props;
    onValueChange(item.value, index + 1);
    this.setState({ visible: false });
  }

  // renderPicker() {
  //   const {
  //     placeholder: { label: placeholderLabel },
  //     items: data,
  //   } = this.props;

  //   const { visible } = this.state;
  //   const items = [
  //     ...data,
  //   ];

  //   return (
  //     <Modal
  //       visible={visible}
  //       transparent
  //     >
  //       <View style={styles.pickerLayoutContainer}>
  //         <View style={styles.pickerContainer}>
  //           <View style={styles.pickerHeader}>
  //             <Text style={styles.pickerHeaderText}>{placeholderLabel}</Text>
  //             <TouchableHighlight
  //               underlayColor={Colors.touchEffectColorWhite}
  //               style={[styles.pickerHeaderCloseButton, Styles.touchEffect]}
  //               onPress={this.onPressClose}
  //             >
  //               <CloseIcon />
  //             </TouchableHighlight>
  //           </View>

  //           <ScrollView
  //             contentContainerStyle={styles.itemListContainer}
  //           >
  //             {items.map((item, index) => (
  //               // eslint-disable-next-line react/no-array-index-key
  //               <React.Fragment key={`item_${index}`}>
  //                 {index > 0 && (
  //                   <View style={styles.itemSeparater} />
  //                 )}
  //                 {
  //                   <TouchableHighlight
  //                     onPress={this.onPressItem(item, index)}
  //                     underlayColor={Colors.touchEffectColorWhite}
  //                     style={styles.itemContainer}
  //                     disabled={item.soldOut}
  //                   >
  //                     <Text style={styles.itemText}>
  //                       {item.label}
  //                       {item.soldOut && (<Text style={{color: 'rgb(123,123,129)'}}>  Sold Out</Text>)}
  //                     </Text>

  //                   </TouchableHighlight>
  //                 }
  //               </React.Fragment>
  //             ))}
  //           </ScrollView>
  //           {/* <FlatList
  //           /> */}

  //         </View>
  //       </View>
  //     </Modal>
  //   );
  // }

  render() {
    const {
      style: customStyle,
      placeholder,
      value,
      innerComponent = null,
      Icon,
      items
    } = this.props;

    let text = placeholder.label;
    let soldOut = false;
    if (value) {
      // const selectedOption = items.find(x => x.value == value);
      text = value.displayName || value.name;
      soldOut = !!value.soldOut;
    }

    return (
      <React.Fragment>
        <TouchableHighlight
          underlayColor={Colors.touchEffectColorBlack}
          style={[
            customStyle.inputAndroidContainer,
          ]}
          onPress={this.onPressOpen}
        >
          <View style={styles.buttonContainer}>
            <Text
              style={[
                customStyle.inputAndroid,
                styles.inputText,
              ]}
            >
              {text}
              {soldOut && (<Text style={{color: 'rgb(123,123,129)'}}>  Sold Out</Text>)}
            </Text>

            {Icon()}
          </View>
        </TouchableHighlight>
        {/* {this.renderPicker()} */}
      </React.Fragment>
    );
  }
}


CustomPickerSelect.propTypes = {
  items: PropTypes.array.isRequired,
  value: PropTypes.object,
}


CustomPickerSelect.defaultProps = {

}


export default CustomPickerSelect;
