import React from 'react'
import { browserHistory } from 'react-router';

import WishlistControl from './WishlistControl';


function addToWishlist(parent, product, selectedItemList, mode='addToWishlist') {
  // console.log('addToWishlist product=', product);
  // console.log('addToWishlist selectedItemList=', selectedItemList);
  // console.log('addToWishlist mode=', mode);

  const {
    auth,
    modalProps,
    setModalProps,
    onRefresh = () => {},
  } = parent.props;

  if (!auth || !auth.token) {
    alert('로그인해주시기 바랍니다.');
    browserHistory.push('/accounts/login');
  }

  const productId = product._id || product.goodsNo;
  const productIdForEvent = product.goodsNo || product.godoGoodsNo || product._id;

  const { router, params, location, routes, logEventClick } = parent.props;
  const currentRoute = routes[routes.length - 1];
  const page_name = currentRoute.name;
  const paramValues = Object.values(params);
  let page_key = null;
  if (paramValues.length > 0 && page_name !== "MyProfile") {
    page_key = paramValues[0];
  } else if(page_name == "SearchResult") {
    page_key = router.location.state.searchKeyword || '';
  }

  // 위시리스트에 바로 추가
  if (selectedItemList && selectedItemList.length > 0 && mode == 'result') {
    const { likeProduct } = parent.props;
    const body = {
      optionKeys: selectedItemList.map(i => i.id),
      optionVals: selectedItemList.map(i => i.name),
    };

    const children = (
      <WishlistControl
        mode='result'
        product={product}
        modalProps={modalProps}
        setModalProps={setModalProps}
        selectedItemList={selectedItemList}
        />
    );

    logEventClick({
      page_name,
      page_key,
      click_object_name: 'wishlist_add',
      click_object_key: productIdForEvent
    });

    likeProduct(productId, body, { shopProduct: product })
      .then(() => {
        onRefresh();
        setModalProps({
          isVisible: true,
          children: children,
        }, () => {
          setTimeout( () => { setModalProps({ isVisible: false }); }, 2000);
        });
      });
    return;
  }


  // 단일 옵션 상품
  if (!product.option || product.option.length === 0) {

    if (product.liked) {
      const { unlikeProduct } = parent.props;

      logEventClick({
        page_name,
        page_key,
        click_object_name: 'wishlist_del',
        click_object_key: productIdForEvent
      });

      unlikeProduct(productId, { shopProduct: product })
        .then(() => {
          onRefresh();
        })
    } else {
      const { likeProduct } = parent.props;
      const body = {
        optionKeys: ['-1'],
        optionVals: ['단일 옵션'],
      }

      const children = (
        <WishlistControl
          mode='result'
          product={product}
          modalProps={modalProps}
          setModalProps={setModalProps}
          />
      );

      logEventClick({
        page_name,
        page_key,
        click_object_name: 'wishlist_add',
        click_object_key: productIdForEvent
      });

      likeProduct(productId, body, { shopProduct: product })
        .then(() => {
          onRefresh();
          setModalProps({
            isVisible: true,
            children: children,
          }, () => {
            setTimeout( () => { setModalProps({ isVisible: false }); }, 2000);
          });
        });
    }
    return;
  }


  // 옵션 선택창을 표시
  const optionLikes = [];

  product.option.forEach(option => {
    if (option.liked) {
      optionLikes.push({
        id: option.sno,
        name: option.optionValue
      });
    }
  });
  // console.log('addToWishlist selectedItemList=', selectedItemList);
  // console.log('addToWishlist optionLikes=', optionLikes);

  const children = (
    <WishlistControl
      mode={mode}
      product={product}
      selectedItemList={selectedItemList || optionLikes}
      modalProps={modalProps}
      setModalProps={setModalProps}
      onRefresh={onRefresh}
      />
  );

  setModalProps({
    isVisible: true,
    children: children,
  });
}


export {
  addToWishlist,
};
