import * as React from 'react';

function SvgBtnSoundX(props) {
  return (
    <svg width={32} height={32} {...props}>
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <circle cx={15} cy={15} r={15} fill="#000" opacity={0.348} />
        <path
          fill="#FFF"
          d="M15.08 8.57l-4.313 3.525h-2.51a.59.59 0 00-.59.59v4.75c0 .325.264.59.59.59h2.51l4.313 3.525c.202.165.504.02.504-.24V8.81a.309.309 0 00-.504-.24"
        />
        <g stroke="#FFF" strokeLinecap="square" strokeWidth={1.1}>
          <path d="M17.45 16.95l3.6-3.6M21.05 16.95l-3.6-3.6" />
        </g>
      </g>
    </svg>
  );
}

export default SvgBtnSoundX;

