/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  StyleSheet, Dimensions, View, Text, TextInput, TouchableOpacity,
  ActivityIndicator, TouchableHighlight,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';
import CloseIcon from '../icons/CloseIcon';
import SearchIcon from '../icons/HeaderSearchIcon';


const initialLayout = { width: Dimensions.get('window').width };

const cancelButtonWidth = 36;
const searchButtonWidth = 32;
const paddingHorizental = 20;
const searchInputWidth = initialLayout.width
  - searchButtonWidth - paddingHorizental * 2 - 20;

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingStart: 20,
    paddingEnd: 16,
    paddingTop: 14,
    paddingBottom: 15,
  },
  headerText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 18,
    height: 21,
    marginTop: 6,
  },
  headerCloseButton: {
  },
  searchInputContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingStart: 20,
    paddingEnd: 16,
    alignItems: 'center',
    height: 55,
    borderTopColor: Colors.lightGray,
    borderTopWidth: 1,
  },
  searchInput: {
    width: searchInputWidth,
    fontSize: 16,
    ...Styles.appleSDGothicNeoRegular,
    lineHeight: 19,
    height: 19,
    marginTop: 1,
  },
  cancelButton: {
    width: cancelButtonWidth,
    paddingEnd: 0,
  },
  cancelIcon: {
  },
  scrollView: {
    paddingTop: 10,
  },
  searchTipContainer: {
    paddingHorizontal: 8,
    paddingBottom: 13,
  },
  searchTipDescription: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    lineHeight: 22,
    marginTop: 15,
  },
  searchTipExample: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 13,
    color: 'rgb(69, 108, 235)',
    height: 16,
    marginTop: 2,
  },
  noSearchResultContainer: {
    marginTop: 8,
    marginHorizontal: 8,
    marginBottom: 8,
  },
  noSearchResultTitle: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    marginBottom: 10,
  },
  noSearchResultDescription: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 13,
  },
  loading: {
    marginVertical: 100,
  },
  itemListContainer: {
  },
  itemContainer: {
    marginTop: 0,
    marginBottom: 0,
    paddingHorizontal: 8,
    paddingTop: 20,
    paddingBottom: 10,
  },
  itemRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
  itemTitle: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    width: 70,
    fontWeight: 'bold',
  },
  itemContent: {
    ...Styles.appleSDGothicNeoRegular,
    flex: 1,
    fontSize: 15,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  itemDivider: {
    height: 1,
    backgroundColor: Colors.lightGray,
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});

const materialUIStyles = () => ({
  root: {
    margin: 0,
    padding: 0,
  },
});


const DialogTitle = withStyles(materialUIStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <View style={styles.headerContainer}>
        <Text style={styles.headerText}>{children}</Text>
        {onClose ? (
          <TouchableHighlight
            style={[styles.headerCloseButton, styles.touchEffect]}
            onPress={onClose}
            underlayColor={Colors.touchEffectColorWhite}
          >
            <CloseIcon />
          </TouchableHighlight>
        ) : null}
      </View>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(1.5),
  },
  dividers: {
    borderColor: Colors.lightGray,
  },
}))(MuiDialogContent);


class AddressSearch extends React.Component {
  state = {
    searchInputText: '',
    searchResult: null,
    loading: false,
    open: true,
  };

  descriptionElementRef = React.createRef();

  componentDidUpdate() {
    const { open } = this.state;

    if (open) {
      const { current: descriptionElement } = this.descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }

  loadSearchResult = async searchKeyword => {
    this.setState({ loading: true });
    const errorMessage = '검색 중 오류가 발생하였습니다.\n다시 시도해 주세요.';

    const { getAddressSearchResult } = this.props;
    try {
      const ret = await getAddressSearchResult(searchKeyword);
      // console.log('getAddressSearchResult return', ret);

      if (!ret.success) {
        const { data: { codeData } } = ret;
        if (codeData) {
          const { message, detailCode } = codeData;
          alert(`${message} (${detailCode})`);
        } else {
          alert(`${errorMessage} (E0${ret.errorCode})`);
        }
        return;
      }

      this.setState({ searchResult: ret.data.addressData });
    } catch (error) {
      // console.log('getAddressSearchResult error', error);

      if (error.errorCode) {
        alert(`${errorMessage} (E1${error.errorCode})`);
      } else {
        alert(errorMessage);
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  onPressSearchButton = () => {
    const { searchInputText } = this.state;

    if (searchInputText === '') {
      return;
    }

    // console.log('onPressSearchButton searchInputText=', searchInputText);

    this.loadSearchResult(searchInputText);
  };

  onPressCancelButton = () => {
    this.setState({
      searchInputText: '',
      searchResult: null,
      loading: false,
    });
  };

  onPressItem = item => () => {
    const { onPressItem } = this.props;
    this.setState({ open: false });
    onPressItem(item);
  };

  handleClose = () => {
    // console.log('handleClose()');
    this.setState({ open: false });

    const { onClose } = this.props;
    onClose();
  };

  renderSearchInputBar() {
    const { searchInputText } = this.state;
    return (
      <View style={styles.searchInputContainer}>
        <TextInput
          style={[styles.searchInput, { outline: 'none' }]}
          value={searchInputText}
          onChangeText={text => this.setState({ searchInputText: text, searchResult: null })}
          onSubmitEditing={this.onPressSearchButton}
          placeholder="예) 강남대로 16길 14"
          placeholderTextColor={Colors.placeholderColor}
        />

        {/* <TouchableHighlight
          style={styles.cancelButton}
          onPress={this.onPressCancelButton}
          underlayColor={Colors.touchEffectColorWhite}
        >
          <CloseIcon
            color={searchInputText === '' ? '#fff' : '#999'}
          />
        </TouchableHighlight> */}

        <TouchableHighlight
          style={styles.touchEffect}
          onPress={this.onPressSearchButton}
          underlayColor={Colors.touchEffectColorWhite}
        >
          <SearchIcon />
        </TouchableHighlight>
      </View>
    );
  }


  renderItem = (item, index) => {
    const {
      zoneCode,
      roadAddress,
      groundAddress,
    } = item;

    return (
      <TouchableOpacity
        style={styles.itemContainer}
        onPress={this.onPressItem(item)}
        key={index}
      >
        <View style={styles.itemRow}>
          <Text style={styles.itemTitle}>우편번호</Text>
          <Text style={styles.itemContent}>{zoneCode}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.itemTitle}>도 로 명</Text>
          <Text style={styles.itemContent}>{roadAddress}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.itemTitle}>지    번</Text>
          <Text style={styles.itemContent}>{groundAddress}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  renderContent() {
    const { loading, searchInputText, searchResult } = this.state;

    if (loading) {
      return null;
    }

    if (searchResult === null || searchInputText === '') {
      return (
        <View style={styles.searchTipContainer}>
          <Text style={[
            styles.searchTipDescription,
            { marginTop: 8, marginBottom: 5 },
          ]}
          >
            아래와 같은 조합으로 검색하면 더욱 정확한 결과를 검색할 수 있습니다.
          </Text>

          <Text style={styles.searchTipDescription}>
            도로명 + 건물번호
          </Text>
          <Text style={styles.searchTipExample}>예) 테헤란로 105</Text>

          <Text style={styles.searchTipDescription}>
            지역명(동/리) + 번지
          </Text>
          <Text style={styles.searchTipExample}>예) 역삼동 822-1</Text>

          <Text style={styles.searchTipDescription}>
            지역명(동/리) + 건물명(아파트명)
          </Text>
          <Text style={styles.searchTipExample}>예) 영통구 그대가프리미어아파트</Text>

          <Text style={styles.searchTipDescription}>
            사서함명 + 번호
          </Text>
          <Text style={styles.searchTipExample}>예) 분당 우체국 사서함 1 - 100</Text>
        </View>
      );
    }

    // console.log('renderContent searchResult=', searchResult);

    if (searchResult.length === 0) {
      return (
        <View style={styles.noSearchResultContainer}>
          <Text style={styles.noSearchResultTitle}>검색된 결과가 없습니다.</Text>
          <Text style={styles.noSearchResultDescription}>정확한 주소인지 다시 확인해 주세요.</Text>
        </View>
      );
    }

    return (
      <View style={styles.itemListContainer}>
        {searchResult.map((item, index) => (
          <View key={`searchItem${index}`}>
            {index > 0 && <View key={`itemDivider${index}`} style={styles.itemDivider} />}
            {this.renderItem(item, index)}
          </View>
        ))}
      </View>
    );
  }

  render() {
    const { loading, open } = this.state;


    return (
      <Dialog
        open={open}
        scroll="paper"
      >
        <DialogTitle id="dialog-title" onClose={this.handleClose}>
          우편번호
        </DialogTitle>

        {this.renderSearchInputBar()}

        <DialogContent dividers>
          { this.renderContent() }
          { loading && (
            <ActivityIndicator size="large" color="gray" style={styles.loading} />
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

export default connect(null, actions)(AddressSearch);
