import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import { StyleSheet, View, TouchableWithoutFeedback } from 'react-native';


const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: absolute;
  ${(props) => (!props.bottom ? 'top: 0;': '')}
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  overflow: hidden;
  outline: 0;
`

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1999;
`


class Modal extends React.Component {
  render() {
    const {
      isVisible,
      isBottomPlaced = false,
      children,
      style,
      onBackdropPress,
      backdropOpacity,
      setModalProps,
      className,
    } = this.props;

    if (!isVisible) {
      return null;
    }

    return (
      <>
        <ModalOverlay visible={isVisible} />
        <ModalWrapper className={className} tabIndex="-1" visible={isVisible} bottom={isBottomPlaced}>
          {children}
        </ModalWrapper>
      </>
    );
  }
}


Modal.propTypes = {
  isVisible: PropTypes.bool,
  // style
  onBackdropPress: PropTypes.func,
  backdropOpacity: PropTypes.number,
  onModalHide: PropTypes.func,
};


Modal.defaultProps = {
  isVisible: false,
  backdropOpacity: 0.5,
};


export default Modal;
