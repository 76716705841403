import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, Text, TouchableHighlight, View } from 'react-native';
import NumberFormat from 'react-number-format';

// eslint-disable-next-line import/no-extraneous-dependencies
import { config } from 'shared';
// eslint-disable-next-line import/no-extraneous-dependencies
import { parseDate, convertKstToUtc } from 'shared/util';

import { openOutlinkPage } from '../containers/OutlinkGatewayPage';

import CloudinaryImage from './CloudinaryImage';
import LikeButton from './LikeButton';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';
import CloseIcon from '../icons/CloseIcon';
import SymbolHeartitLogoIcon from '../icons/SymbolHeartitLogoIcon';
import ProductLabel from './ProductLabel';
import { generateFirebaseEvent } from '../utils';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  touchArea: {
    margin: 16,
  },
  productImageContainer: {
    marginRight: 10,
    width: 100,
    height: 100,
  },
  closedOverlayContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
  },
  closedOverlayText: {
    height: 14,
    ...Styles.SFUITextBold,
    fontSize: 12,
    lineHeight: 14,
    color: '#fff',
    textShadowColor: 'rgba(0, 0, 0, 0.1)',
    textShadowOffset: {
      width: 0,
      height: 0,
    },
    textShadowRadius: 6,
  },
  productInfoContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonContainerBetween: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonContainerEnd: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  productInfo: {
    flexDirection: 'column',
  },
  productLableContainer: {
    marginTop: 5,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  priceContainer: {
    flexDirection: 'row',
    paddingBottom: 4,
  },
  productSellPrice: {
    ...Styles.SFUITextRegular,
    fontSize: 14,
    color: 'black',
    height: 17,
  },
  productSellPriceSoldout: {
    opacity: 0.35,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  discountRate: {
    ...Styles.SFUITextSemiBold,
    fontSize: 14,
    height: 16,
    color: 'rgb(235, 69, 69)',
    marginStart: 8,
  },
  image: {
    aspectRatio: 1,
    resizeMode: 'contain',
    width: 100,
    height: 100,
  },
  deleteButtonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemBrand: {
    ...Styles.SFUIDisplaySemiBold,
    fontSize: 14,
    color: 'black',
    height: 17,
    marginTop: 4,
  },
  itemTitle: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 14,
    color: 'black',
    height: 17,
    marginTop: 5,
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});

export default class FavItem extends React.Component {
  constructor(props) {
    super(props);

    const {
      clickedItem: {
        product: {
          godoGoodsNo,
          soldOut,
          preOrder,
        },
      },
    } = props;

    this.isPreOrder = preOrder === 'y';
    this.isShopProduct = !!godoGoodsNo;
    this.isSoldOut = soldOut === 'y';
  }

  onPressProduct() {
    const { clickedItem, auth, historySwitch, router } = this.props;
    const { token } = auth;
    const { _id, product, post, editorial } = clickedItem;
    const { godoGoodsNo } = product;

    // eslint-disable-next-line no-prototype-builtins
    if (!post && product.hasOwnProperty('post')) {
      // eslint-disable-next-line prefer-destructuring
      post = product.post;
    }

    if (post && post.owner) {
      generateFirebaseEvent({ product, post, where: 'history' });
    }

    if (godoGoodsNo && godoGoodsNo.length > 0) {
      router.push({
        pathname: `/product/${product.godoGoodsNo}`,
        state: {
          postId: post && post._id,
        },
      });
      return;
    }

    openOutlinkPage(product, post, historySwitch, token, router);
  }

  renderImage() {
    const { clickedItem } = this.props;
    const {
      product: {
        media,
        salesEndYmd,
        timestampNowYmd,
      } = {
        media: {},
      },
    } = clickedItem;

    let closedOverlay = null;
    if (this.isPreOrder && salesEndYmd) {
      const salesEndDate = convertKstToUtc(parseDate(salesEndYmd, true));
      let timestampNow = Date.now();
      if (!!timestampNowYmd) {
        timestampNow = convertKstToUtc(parseDate(timestampNowYmd, true));
      }

      if ((salesEndDate - timestampNow) <= 0) {
        closedOverlay = (
          <View style={styles.closedOverlayContainer}>
            <Text style={styles.closedOverlayText}>CLOSED</Text>
          </View>
        );
      }
    }

    return (
      <View
        style={styles.productImageContainer}
      >
        <CloudinaryImage
          options={{
            crop: 'limit',
            height: 100,
            width: 100,
          }}
          style={styles.image}
          // eslint-disable-next-line no-prototype-builtins
          publicId={(media.hasOwnProperty('publicId')) ? media.publicId : media.public_id}
        />

        {closedOverlay}
      </View>
    );
  }

  renderLabel() {
    const showPreOrderLabel = this.isPreOrder;
    const showHeartitLogo = this.isShopProduct;

    return (
      <View style={styles.productLableContainer}>
        { showPreOrderLabel && (
          <ProductLabel
            style={{ marginEnd: 3 }}
            backgroundColor={Colors.labelPreOrder}
          >
            PRE ORDER
          </ProductLabel>
        )}
        { showHeartitLogo && (
          <SymbolHeartitLogoIcon />
        )}
      </View>
    );
  }

  render() {
    const { clickedItem, deleteHistory } = this.props;
    const { _id, post, product = { media: {} } } = clickedItem;
    let { isSeasonOut, godoGoodsNo } = product;
    isSeasonOut = !!isSeasonOut;
    const hasGodoGoodsNo = !!godoGoodsNo;

    const retails = (product && product.retails) ? product.retails[0] : {
      orgPrice: { amount: 0 },
      sellPrice: { amount: 0 },
    };

    const {
      orgPrice: { amount: orgPrice },
      sellPrice: { amount: sellPrice },
    } = retails;

    const showDeleteButton = !!(deleteHistory);
    const discountRate = orgPrice > sellPrice ? Math.round((1 - sellPrice / orgPrice) * 100) : 0;

    return (
      <View>
        <TouchableHighlight
          underlayColor={Colors.touchEffectColorWhite}
          onPress={() => this.onPressProduct()}
          style={[styles.touchArea, styles.touchEffect]}
        >
          <View style={styles.container}>
            {this.renderImage()}
            <View style={styles.productInfoContainer}>
              <View style={styles.productInfo}>
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={styles.itemBrand}
                >
                  {product.brand}
                </Text>
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={styles.itemTitle}
                >
                  {product.name}
                </Text>
                {this.renderLabel()}
              </View>
              <View style={styles.priceContainer}>
                <NumberFormat
                  value={sellPrice}
                  displayType="text"
                  thousandSeparator
                  renderText={value => (
                    <Text
                      style={[
                        styles.productSellPrice,
                        this.isSoldOut && styles.productSellPriceSoldout,
                      ]}
                    >
                      {value}
                      <Text style={Styles.appleSDGothicNeoRegular}>원</Text>
                    </Text>
                  )}
                />
                {(discountRate > 0 && !this.isSoldOut) && (
                  <Text style={styles.discountRate}>
                    {`${discountRate}%`}
                  </Text>
                )}
              </View>
            </View>
            <View
              style={[
                (showDeleteButton)
                  ? styles.buttonContainerBetween
                  : styles.buttonContainerEnd, styles.touchEffectContainer,
              ]}
            >
              { (showDeleteButton) ? (
                <TouchableHighlight
                  onPress={() => deleteHistory(_id)}
                  underlayColor={Colors.touchEffectColorWhite}
                  style={[styles.deleteButtonContainer, styles.touchEffect]}
                >
                  <CloseIcon />
                </TouchableHighlight>
              ) : null
              }
              {(!isSeasonOut || hasGodoGoodsNo) && (
              <LikeButton
                item={product}
                post={post}
                isFavProduct
                historyId={showDeleteButton ? _id : null}
              />
              )}
            </View>
          </View>
        </TouchableHighlight>
      </View>
    );
  }
}
