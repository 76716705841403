import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { loadEntities } from 'react-shared';
import * as adminActions from 'react-shared/adminActions';

import Media from 'components/Media';


const styles = {
  container: {
    border: '5px solid #fff',
    padding: '5px 15px',
    textAlign: 'center',
  },
  headerContainer: {
    height: '40px',
    marginBottom: '12.5px',
    width: '100%',
  },
  headerLeft: {
    display: 'inline-block',
    float: 'left',
    height: '40px',
    marginBottom: '12.5px',
    textAlign: 'left',
    width: '100%',
  },
  headerImage: {
    borderRadius: '50%',
    height: '40px',
    width: '40px',
  },
  username: {
    color: 'black',
    fontFamily: 'Avenir Next',
    fontSize: '16px',
    marginLeft: '7.5px',
  },
  headerRight: {
    display: 'inline-block',
    height: '40px',
    lineHeight: '40px',
    // marginBottom: '12.5px',
    textAlign: 'right',
    width: '100%',
  },
  date: {
    color: '#909090',
    fontSize: '13px',
  },
  dateSuffix: {
    color: '#909090',
    fontSize: '8px',
    lineHeight: '36px',
    verticalAlign: 'top',
  },
  thumbnailImage: {
    width: 250,
    height: 250,
    crop: 'limit',
  },
  abstract: {
    color: 'black',
    fontSize: '10px',
    textAlign: 'left',
    textDecoration: 'none',
  },
  link: {
    color: 'black',
    textAlign: 'right',
  },
  arrow: {
    height: '7px',
    marginLeft: '5px',
    width: '15px',
  },
  offline: {
    color: '#000080',
  },
  online: {
    color: '#FF0000',
  },
};

const ThumbnailListContainer = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  &:after {
    clear: both;
    content: "";
    display: table;
  }
`;

// export default
class MDCuration extends Component {
  static renderEditButton(mdCurationId) {
    return (
      <Link className="btn btn-primary" to={`/admin/mdCurations/${mdCurationId}`}>
        <span className="glyphicon glyphicon-edit" />
      </Link>
    );
  }

  renderDeleteButton(mdCurationId) {
    return (
      <button
        type="button"
        className="btn btn-danger"
        onClick={
          () => confirm('Are you sure to delete this MD Curation post?') && this.props.adminDeleteMdCuration(mdCurationId) //eslint-disable-line
        }
      >
        <span className="glyphicon glyphicon-trash" />
      </button>
    );
  }

  renderCaption(mdCurationId) {
    const captionStyle = { height: 30 };
    return (
      <div style={captionStyle}>
        {MDCuration.renderEditButton(mdCurationId)}
        {this.renderDeleteButton(mdCurationId)}
      </div>
    );
  }

  static renderDate(date) {
    if (!date) {
      return '없음';
    }
    return dayjs(date).format('YYYY-MM-DD (HH:mm)');
  }

  render() {
    const {
      mdCuration: {
        id,
        title,
        description,
        thumbnailList,
        thumbnailAspectRatio,
        created,
        visible,
      },
    } = this.props;
    // const convertedUrl = getChangedUrl(url, window.location.protocol, window.location.host, null, {
    //   utm_source: window.location.hostname,
    //   utm_medium: 'heartit-mdCuration-list',
    //   utm_campaign: 'heartit-mdCuration-list',
    //   agent: 'web',
    // });
    return (
      <div style={styles.container}>
        <ThumbnailListContainer>
          <Media
            media={thumbnailList[0]}
            options={{ width: 250, height: 250, crop: 'limit' }}
            style={{ paddingTop: `${1 / thumbnailAspectRatio * 100}%` }}
          />
        </ThumbnailListContainer>
        <div style={styles.headerLeft}>
          <div>
            제목:
            {title}
          </div>
          <div>
            고유아이디:
            {id}
          </div>
          <div>
            등록일:
            {MDCuration.renderDate(created)}
          </div>
          <div>
            공개여부:
            {visible ? <span style={styles.online}>true</span> : <span style={styles.offline}>false</span>}
          </div>
        </div>
        <div style={styles.headerRight}>
          {this.renderCaption(id)}
          {/* <button type="button" className="btn btn-primary">
            <span className="glyphicon glyphicon-edit" />
          </button>
          <button type="button" className="btn btn-danger">
            <span className="glyphicon glyphicon-trash" />
          </button> */}
        </div>
      </div>
    );
  }
}

MDCuration.propTypes = {
  mdCuration: PropTypes.object.isRequired,
};

export const mapStateToProps = state => ({
  ...loadEntities(state, 'mdCurations', 'mdCurations'),
});

export default connect(
  mapStateToProps,
  adminActions,
)(MDCuration);
