import * as React from 'react';

function SvgIcClose(props) {
  return (
    <svg width={18} height={18} {...props}>
      <g stroke="#FFF" strokeWidth={1.5} fill="none" fillRule="evenodd">
        <path d="M3.75 14.25l10.5-10.5M3.75 3.75l10.5 10.5" />
      </g>
    </svg>
  );
}

export default SvgIcClose;
