import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { camelizeKeys } from 'humps';
import dayjs from 'dayjs';
import Flatpickr from 'react-flatpickr';
import { loadEntities } from 'react-shared';

import { SizeData, SortingRule } from '../../constants';

// import { browserHistory } from 'react-router';
// all codes using browserHistory are legacy in react-router v3;
// anyways, the solution preventing admins from accidently clicking backbutton, reload, or close window
// eslint-disable-next-line max-len
// I follow the https://stackoverflow.com/questions/32841757/detecting-user-leaving-page-with-react-router/50388073#50388073
// but this.props.route.path doesn't have exact path, I instead used this.props.router.location.pathname
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControlLabel,
  Paper,
  Select,
  MenuItem,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  ItemList,
  ThumbnailList,
  ProductSelector,
} from 'components/admin';

import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import FiberNew from '@material-ui/icons/FiberNew';
import ExpandMore from '@material-ui/icons/ExpandMore';
import has from 'lodash/has';
import pick from 'lodash/pick';
import without from 'lodash/without';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import findIndex from 'lodash/findIndex';

import * as adminActions from 'react-shared/adminActions';

import {
  objectDiff,
} from 'react-shared/util';

import config from '../../config';

const styles = theme => ({
  cancelConfirmButton: {

  },
  createPostButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  deletePostButton: {
    position: 'absolute',
    right: theme.spacing(12),
    top: theme.spacing(2),
  },
  snackbar: {
    textAlign: 'center',
  },
  formControlLabel: {

  },
  select: {
    width: 200,
  },
  link: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  outerButton: {
    marginRight: theme.spacing(2),
  },
  subMenuTitles: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    minHeight: 50,
  },
  thumbnailArea: {
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'table-cell',
  },
  filterContainer: {
    borderWidth: 0.5,
    borderColor: '#afadad',
    borderStyle: 'solid',
    marginRight: 3,
  },
  filterTypeText: {
    fontSize: 12,
  },
  filterNameText: {
    fontSize: 14,
    marginRight: 8,
  },
  productCaption: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  productCaptionName: {
    fontSize: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginBottom: 5,
  },
});

const sortByList = SortingRule.SORTING_RULE_EXHIBITION;

class HeartitCurationPage extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAddProduct = this.handleAddProduct.bind(this);
    this.handleAddProductSubmit = this.handleAddProductSubmit.bind(this);
    this.renderProductCaption = this.renderProductCaption.bind(this);
    this.state = {
      title: '',
      subtitle: '',
      description: '',
      visible: true,
      isUpdatingPost: false,
      // Products
      products: [],
      checkedProducts: {},
      newCheckedProducts: {},
      isProductDialogOpen: false,
    };
  }

  async componentDidMount() {
    const { adminGetHeartitCuration, heartitCurationId, heartitCuration, route, router } = this.props;

    if (heartitCurationId !== 'new') {
      if (heartitCuration) {
        const checkedProducts = {};
        heartitCuration.products.forEach((product) => {
          checkedProducts[product._id] = product;
        });
        await this.setState({
          ...heartitCuration,
          checkedProducts,
        });
      } else {
        await adminGetHeartitCuration(heartitCurationId)
          .then(heartitCurationData => {
            const checkedProducts = {};
            heartitCurationData.products.forEach((product) => {
              checkedProducts[product._id] = product;
            });
            this.setState({
              ...heartitCurationData,
              checkedProducts,
            })
          });
        // Todo: route to admin/posts when there's no post with such heartitCurationId 
      }
    }

    this.saveState();
    window.addEventListener('resize', () => this.handleResize());
    window.addEventListener('beforeunload', event => this.handleClose(event));
    this.mutationObserver = new MutationObserver(() => this.removeBodyStyle());
    this.mutationObserver.observe(document.body, {
      attributes: true,
      attributeFilter: ['style'],
    });
    router.setRouteLeaveHook(route, () => this.handleBackbutton());
  }

  componentDidUpdate(prevProps) {
    const { heartitCurationId } = this.props;
    if (prevProps.heartitCurationId !== heartitCurationId && heartitCurationId === 'new') {
      window.location.reload();
    }
  }

  componentWillUnmount() {
    this.mutationObserver.disconnect();
    window.removeEventListener('resize', () => this.handleResize());
    window.removeEventListener('beforeunload', event => this.handleClose(event));
  }

  saveState() {
    this.savedState = cloneDeep(this.state);
  }

  hasHeartitCurationEdited() {
    return !!Object.keys(objectDiff(this.savedState, this.state)).length;
  }

  showSnackbar(message) {
    this.setState({
      snackbarMsg: message,
      snackbarOpen: true,
    });
  }

  handleClose(event) {
    if (this.hasHeartitCurationEdited()) {
      event.preventDefault();
      event.returnValue = 'Heartit Curation을 작성중이시네요, 정말 창을 닫으시겠어요?';
      return event.returnValue;
    }
    return event;
  }

  removeBodyStyle() { // FIX!
    const {
      isFilterDialogOpen: filter,
      isProductDialogOpen: product,
    } = this.state;
    if (!filter && !product && document.body.style.overflow === 'hidden') {
      document.body.style.overflow = '';
    }
  }

  handleBackbutton() {
    const { router: { location: { pathname } } } = this.props;
    if (this.hasHeartitCurationEdited()) {
      // eslint-disable-next-line no-restricted-globals
      const toBeClosed = confirm('Heartit Curation을 작성중이시네요, 정말 창을 닫으시겠어요?');
      if (!toBeClosed) {
        window.history.pushState(null, null, pathname);
        return false;
      }
    }
    return true;
  }

  handleResize() {
    if (window.innerWidth <= 600 && this.state.tilesInRow === 4) {
      this.setState({ tilesInRow: 1 });
    } else if (window.innerWidth > 600 && this.state.tilesInRow === 1) {
      this.setState({ tilesInRow: 4 });
    }
  }

  handleTitleChange(event) {
    this.setState({ title: event.target.value });
  }

  handleSubtitleChange(event) {
    this.setState({ subtitle: event.target.value });
  }

  handleDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  handleVisibleChange(event) {
    this.setState({ visible: event.target.value });
  }

  handleSubmit(event) {
    const fields = [
      'title', 'subtitle', 'description',
      'products', 'visible'
    ];
    const heartitCuration = pick(this.state, fields);
    const { adminCreateHeartitCuration, adminUpdateHeartitCuration, heartitCurationId, router } = this.props;
    const { adminHeartitCurations } = this.props;

    if (!heartitCuration.title) {
      alert('제목을 입력해주세요.');
      return;
    }
    if (heartitCuration.products.length == 0) {
      alert('상품을 등록해주세요.');
      return;
    }
    if (heartitCuration.products.length > 20) {
      alert('최대 등록 상품 수는 20개입니다');
      return;
    }

    const currentVisibleItems = adminHeartitCurations.filter(x => x.visible);
    if (heartitCuration.visible && currentVisibleItems.length > 0 && currentVisibleItems[0]._id != heartitCurationId) {
      const yes = confirm('이미 공개중인 다른 Heart it curation이 있습니다. 변경하시겠습니까?');
      if (!yes) {
        return;
      }
    }
    if (!heartitCuration.visible && currentVisibleItems.filter(x => x._id != heartitCurationId).length < 1) {
      const yes = confirm('공개중인 다른 Heart it curation이 없습니다. 비공개로 저장하시겠습니까?');
      if (!yes) {
        return;
      }
    }

    if (heartitCurationId === 'new') {
      this.setState(
        { isUpdatingPost: true },
        () => adminCreateHeartitCuration(heartitCuration)
          .then(() => this.saveState())
          .then(() => {
            this.showSnackbar('Heartit Curation을 생성했습니다.');
          })
          .catch(() => this.showSnackbar('Heartit Curation 생성에 실패했습니다.'))
          .then(() => router.push('/admin/heartitCurations'))
      );
    } else {
      this.setState(
        { isUpdatingPost: true },
        () => adminUpdateHeartitCuration(heartitCurationId, heartitCuration)
          .then(() => this.setState({ isUpdatingPost: false }, () => this.saveState()))
          .then(() => this.showSnackbar('Heartit Curation을 업데이트 했습니다.'))
          .catch(() => this.showSnackbar('Heartit Curation 업데이트에 실패했습니다.'))
          .finally(() => this.setState({ isUpdatingPost: false }))
      );
    }
    event.preventDefault();
  }

  handleAddProduct() {
    const { checkedProducts } = this.state;
    const newCheckedProducts = { ...checkedProducts };
    this.setState({ newCheckedProducts, isProductDialogOpen: true });
  }

  handleAddProductSubmit() {
    const { products: prevProducts, checkedProducts, newCheckedProducts } = this.state;
    let products = cloneDeep(prevProducts);
    const oldKeys = Object.keys(checkedProducts);
    const newKeys = Object.keys(newCheckedProducts);

    const deletedKeys = difference(oldKeys, newKeys);
    deletedKeys.forEach((key) => {
      delete checkedProducts[key];
      products = products.filter(product => (product._id !== key));
    });

    newKeys.forEach((key) => {
      const newProduct = cloneDeep(newCheckedProducts[key]);
      checkedProducts[key] = newProduct;
      const productIdx = findIndex(products, ['_id', key]);
      if (productIdx >= 0) {
        const oldProduct = products[productIdx];
        products[productIdx] = newProduct;
      } else {
        products.push(newProduct);
      }
    });

    this.setState({
      products,
      checkedProducts,
      newCheckedProducts: {},
      isProductDialogOpen: false,
    });
  }

  renderProductCaption(product, index) {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.productCaptionName}>{product.name}</div>
        <div className={classes.productCaption}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              window.open(`/admin/products/${product._id}`, '_blank');
            }}
          >
            <Edit />
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              this.setState(prevState => {
                const checkedProducts = prevState.checkedProducts;
                delete checkedProducts[product._id];
                return {
                  products: without(prevState.products, product),
                  checkedProducts,
                };
              });
            }}
          >
            <Delete color="error" />
          </Button>
        </div>
      </div>
    );
  }

  renderProductDialog() {
    const { classes } = this.props;
    const { newFilterList } = this.state;
    return (
      <Dialog open={this.state.isProductDialogOpen} fullWidth maxWidth={'lg'} classes={{ paper: classes.dialogPaperProductList }}>
        <DialogTitle>
          상품 추가/수정
        </DialogTitle>
        <DialogContent>
          <ProductSelector
            searchKeyword={this.state.productSearchKeyword}
            isGodoOnly={true}
            checkedProducts={this.state.newCheckedProducts}
            onChangeKeyword={event => this.setState({ productSearchKeyword: event.target.value })}
            onChangeIsGodoOnly={() => null}
            onCheckProduct={product => {
              const { newCheckedProducts } = this.state;
              if (!has(newCheckedProducts, product._id)) {
                newCheckedProducts[product._id] = product;
              } else {
                delete newCheckedProducts[product._id];
              }
              this.setState({ newCheckedProducts });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.cancelConfirmButton}
            onClick={this.handleAddProductSubmit}
          >
            Confirm
          </Button>
          <Button
            className={classes.cancelConfirmButton}
            onClick={() => {
              this.setState({ newCheckedProducts: {}, isProductDialogOpen: false });
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { classes, heartitCurationId } = this.props;
    const { title, subtitle, description, products } = this.state;
    return (
      <div className="container">
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>
            Heartit Curation 제목
          </Typography>
          <div>
            <TextField className={classes.link} value={title} onChange={event => this.handleTitleChange(event)} />
          </div>
        </Paper>
        {/*
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>
            Heartit Curation 부제목
          </Typography>
          <div>
            <TextField className={classes.link} value={subtitle} onChange={event => this.handleSubtitleChange(event)} />
          </div>
        </Paper>
        */}
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>
            Heartit Curation 설명
          </Typography>
          <div>
            <TextField
              className={classes.link}
              value={description}
              multiline={true}
              minRows={5}
              maxRows={10}
              placeholder="Heartit Curation 상세 설명 입력"
              onChange={event => this.handleDescriptionChange(event)}
            />
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>Heartit Curation 상태</Typography>
          <div>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={this.state.visible}
                  onChange={(event, checked) => this.setState({ visible: checked })}
                />
              )}
              label="공개여부"
            />
          </div>
        </Paper>

        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>상품</Typography>
          {
            products.length > 0 ? (
              <ItemList
                getMedia={(item) => item.media}
                items={products}
                renderCaption={this.renderProductCaption}
                onChange={(normals, recommends) => this.setState(
                  { products: normals.concat(recommends) }
                )
                }
                isRecommendList={false}
                showRecommend={false}
                showSeasonOut={false}
              />
            ) : (
                <Typography
                  variant="caption"
                  className={classes.thumbnailArea}
                  style={{ height: 100 }}
                >
                  등록된 상품이 없습니다
                </Typography>
              )
          }
          <Button
            className={classes.outerButton}
            onClick={this.handleAddProduct}
            variant="contained"
          >
            상품 추가/수정
          </Button>
        </Paper>
        <div style={{ height: 200, position: 'relative' }}>
          <Button
            variant="fab"
            onClick={this.handleSubmit}
            className={classes.createPostButton}
            color="primary"
            disabled={this.state.isUpdatingPost}
          >
            {heartitCurationId === 'new' ? '업로드' : '완료'}
          </Button>
          {
            heartitCurationId !== 'new' && (
              <Button
                variant="fab"
                onClick={() => {
                  if (confirm('Heartit Curation을 제거할까요?')) { // eslint-disable-line
                    this.props.adminDeleteHeartitCuration(this.props.heartitCurationId);
                    this.props.router.push('/admin/heartitCurations');
                  }
                }}
                color="secondary"
                className={classes.deletePostButton}
              >
                제거
              </Button>
            )
          }
        </div>
        {this.renderProductDialog()}
        <Snackbar
          autoHideDuration={2000}
          className={classes.snackbar}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
        />
      </div>
    );
  }
}

HeartitCurationPage.propTypes = {
  heartitCurationId: PropTypes.string.isRequired,
  router: PropTypes.object.isRequired,
  adminCreateHeartitCuration: PropTypes.func.isRequired,
  adminDeleteHeartitCuration: PropTypes.func.isRequired,
  adminGetHeartitCuration: PropTypes.func.isRequired,
  adminUpdateHeartitCuration: PropTypes.func.isRequired,
};

export default compose(
  withStyles(
    styles
  ),
  connect((state, { params }) => {
    const { heartitCurationId } = params;

    return {
      heartitCurationId: heartitCurationId,
      heartitCuration: state.entities.heartitCurations[heartitCurationId],
      ...loadEntities(state, 'adminHeartitCurations', 'adminHeartitCurations'),
    };
  },
  adminActions),
)(HeartitCurationPage);
