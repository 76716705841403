import React from 'react';
import { StyleSheet, View, Text, TouchableHighlight } from 'react-native';

import CollapseSection from './CollapseSection';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';
import Constants from '../constants/Constants';


const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.semiGray,
    paddingBottom: 19,
    marginBottom: -1 * Constants.mainHeaderHeight,
  },
  touchContainer: {
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  titleContainer: {
    paddingTop: 16,
    paddingBottom: 13,
  },
  titleText: {
    lineHeight: 17,
    height: 17,
    fontSize: 14,
    marginTop: 5,
    marginBottom: 2,
    ...Styles.appleSDGothicNeoRegular,
  },
  detailContainer: {
    paddingHorizontal: Constants.viewHorizontalPadding,
    paddingBottom: 33 - 19,
  },
  sellerInfoContainer: {
    marginBottom: 5,
  },
  sellerInfoText: {
    color: 'rgba(34, 34, 34, 0.8)',
    fontSize: 12,
    lineHeight: 18,
    letterSpacing: 0,
    ...Styles.appleSDGothicNeoLight,
  },
  copyright: {
    color: 'rgba(34, 34, 34, 0.4)',
    fontSize: 10,
    lineHeight: 12,
    letterSpacing: 0,
    ...Styles.appleSDGothicNeoRight,
  },
  termsContainer: {
    marginTop: 16,
    flexDirection: 'row',
  },
  termsLinkContainer: {
    marginRight: 30,
  },
  termsLinkText: {
    fontSize: 12,
    color: 'rgba(34, 34, 34, 0.84)',
    letterSpacing: 0,
    lineHeight: 18,
    ...Styles.appleSDGothicNeoSemiBold,
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});


export default class SellerInfo extends React.Component {
  renderSellerInfo = () => {
    const { onPressTerms, onPressPrivacy } = this.props;

    return (
      <View style={styles.detailContainer}>
        <View style={styles.sellerInfoContainer}>
          <Text style={styles.sellerInfoText}>
              주소: 서울 용산구 이태원로 200 제니타스빌딩 5F
          </Text>
          <Text style={styles.sellerInfoText}>
              (주) 해브잇   |   대표이사 : 서수아
          </Text>
          <Text style={styles.sellerInfoText}>
              메일: cs@heartit.kr
          </Text>
          <Text style={styles.sellerInfoText}>
              전화번호: 02-518-7795
          </Text>
          <Text style={styles.sellerInfoText}>
              사업자등록번호: 227-87-01833
          </Text>
          <Text style={styles.sellerInfoText}>
              개인정보관리자: 박종호
          </Text>
          <Text style={styles.sellerInfoText}>
              통신판매사업자번호: 2021-서울서초-0032
          </Text>
          <Text style={styles.sellerInfoText}>
              호스팅 제공자:  엔에이치엔고도(주)
          </Text>
        </View>

        <Text style={styles.copyright}>
          HAVIT Corp. All rights reserved.
        </Text>

         <View style={styles.termsContainer}>
           <View style={[styles.termsLinkContainer, styles.touchEffectContainer]}>
             <TouchableHighlight
               onPress={onPressPrivacy}
               underlayColor={Colors.touchEffectColorWhite}
               style={styles.touchEffect}
             >
               <Text style={styles.termsLinkText}>개인정보처리방침</Text>
             </TouchableHighlight>
           </View>
           <View style={[styles.termsLinkContainer, styles.touchEffectContainer, { marginRight: 0 }]}>
             <TouchableHighlight
               onPress={onPressTerms}
               underlayColor={Colors.touchEffectColorWhite}
               style={styles.touchEffect}
             >
               <Text style={styles.termsLinkText}>이용약관</Text>
             </TouchableHighlight>
           </View>
         </View>
      </View>
    );
  }

  render() {
    return (
      <CollapseSection
        initialCollapse
        title='(주)해브잇 사업자 정보'
        containerStyle={styles.container}
        touchContainerStyle={styles.touchContainer}
        titleTextStyle={styles.titleText}
        titleContainerStyle={styles.titleContainer}
        children={this.renderSellerInfo()}
      />
    );
  }
}
