import React from 'react';
import PropTypes from 'prop-types';
import {
  View, StyleSheet, Animated, Dimensions,
  ActivityIndicator,
} from 'react-native';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { actions } from 'shared';
import {
  convertMongoProductToGodoShopListProduct,
  getProductId, getProductSellPrice } from 'shared/util/product';
import { default as sharedCache } from 'react-shared/cache';

import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';

import { Constants, Styles } from '../constants';
import Spinner from '../components/Spinner';

import Product from './Product';

import {
 addToWishlist,
} from './WishlistControl';

import _styles from '../css/modules/carousel.module.css';
import { ArrowForwardIosIcon, ArrowBackIosIcon } from '../icons-v2';


const DEBUG_TARGET_NAMES = [];

const { width: windowWidth } = Dimensions.get('window');

const CAROUSEL_THRESHOLD = 18;


const styles = StyleSheet.create({
  products: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  flatlist: {
  },
  productImage: {
    width: windowWidth / 2,
    aspectRatio: 166/221,
  },
  smallProductImage: {
    width: 112,
    aspectRatio: 112/149,
  },
});

class Products extends React.Component {
  listRef = React.createRef();

  static getDerivedStateFromProps(props, state) {

    const {
      productLikes,
      productIds,
      productPrices,
    } = state;
    const { products } = props;

    const results = [];

    const nextProductLikes = products.map(p => p.liked);
    if (!isEqual(productLikes, nextProductLikes)) {
      results.productLikes = nextProductLikes;
    }

    const nextProductIds = products.map(p => getProductId(p));
    if (!isEqual(productIds, nextProductIds)) {
      results.productIds = nextProductIds;
    }

    const nextProductPrices = products.map(p => getProductSellPrice(p));
    if (!isEqual(productPrices, nextProductPrices)) {
      results.productPrices = nextProductPrices;
    }

    return results;
  }

  state = {
    refreshing: false,
    isFetchingNextPage: false,
    isVisibleAddToWishlist: false,
    selectedProduct: null,
    productLikes: [],
    productIds: [],
    productCached: [],
    isLeftEnd:false,
    isRightEnd:false,
    nowIndex:0
  }

  scrollToTop(animated = false) {
    this.listRef.scrollToOffset(0);
  }

  handleScrollEvent(e) {
    if(e.nativeEvent && e.nativeEvent.contentOffset && e.nativeEvent.contentOffset.x < CAROUSEL_THRESHOLD){
      this.setState({isLeftEnd : true});
    }else{
      this.setState({isLeftEnd : false});
    }

    if(e.nativeEvent && e.nativeEvent.contentOffset && e.nativeEvent.contentOffset.x > e.nativeEvent.contentSize.width - windowWidth + CAROUSEL_THRESHOLD){
      this.setState({isRightEnd : true});
    }else{
      this.setState({isRightEnd : false});
    }
  }
  handleMomentEnd(event) {
    const index = Math.floor(
        Math.floor(event.nativeEvent.contentOffset.x) /
        Math.floor(event.nativeEvent.layoutMeasurement.width));
    this.setState({nowIndex : index});
  }

  handleLeftClickEvent(e) {

    const nowIndex = this.state.nowIndex;
    let newIndex = 0;
    if(nowIndex > 2){
      newIndex = nowIndex - 2;
      this.listRef.scrollToIndex({animated: true, index: newIndex});
    }else{
      newIndex = 0;
      this.listRef.scrollToOffset({animated: true, index: newIndex});
    }
    this.setState({nowIndex : newIndex});
  }

  handleRightClickEvent(e) {
    const nowIndex = this.state.nowIndex;
    let newIndex = nowIndex + 2;
    try{
      this.listRef.scrollToIndex({animated: true, index: newIndex});
      this.setState({nowIndex : newIndex});
    }catch(e){

    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { name } = this.props;
    if (DEBUG_TARGET_NAMES.includes(name)) {
      console.log(`Products(${name}).shouldComponentUpdate`);
    }

    let { products, post } = this.props;
    let { products: nextProducts, nextPost } = nextProps;

    let postId = post ? post._id : null;
    let nextPostId = nextPost ? nextPost._id : null;

    if (postId !== nextPostId) {
      if (DEBUG_TARGET_NAMES.includes(name)) {
        console.log(`Products(${name}).shouldComponentUpdate postId has changed`);
      }
      return true;
    }

    const { productLikes } = this.state;
    const { productLikes: nextProductLikes } = nextState;
    if (DEBUG_TARGET_NAMES.includes(name)) {
      console.log(`Products(${name}).shouldComponentUpdate productLikes=`, productLikes)
      console.log(`Products(${name}).shouldComponentUpdate nextProductLikes=`, nextProductLikes)
    }
    if (!isEqual(productLikes, nextProductLikes)) {
      if (DEBUG_TARGET_NAMES.includes(name)) {
        console.log(`Products(${name}).shouldComponentUpdate productLikes has changed`);
      }
      return true;
    }

    const { productIds } = this.state;
    const { productIds: nextProductIds } = nextState;
    if (DEBUG_TARGET_NAMES.includes(name)) {
      console.log(`Products(${name}).shouldComponentUpdate productIds=`, productIds)
      console.log(`Products(${name}).shouldComponentUpdate nextProductIds=`, nextProductIds)
    }
    if (!isEqual(productIds, nextProductIds)) {
      if (DEBUG_TARGET_NAMES.includes(name)) {
        console.log(`Products(${name}).shouldComponentUpdate productIds has changed`);
      }
      return true;
    }

    const { productPrices } = this.state;
    const { productPrices: nextProductPrices } = nextState;
    if (DEBUG_TARGET_NAMES.includes(name)) {
      console.log(`Products(${name}).shouldComponentUpdate productPrices=`, productPrices)
      console.log(`Products(${name}).shouldComponentUpdate nextProductPrices=`, nextProductPrices)
    }
    if (!isEqual(productPrices, nextProductPrices)) {
      if (DEBUG_TARGET_NAMES.includes(name)) {
        console.log(`Products(${name}).shouldComponentUpdate productPrices has changed`);
      }
      return true;
    }

    //if (shouldWishlistUpdate(this, nextProps, nextState)) {
    //  return true;
    //}

    if (DEBUG_TARGET_NAMES.includes(name)) {
      console.log(`Products(${name}).shouldComponentUpdate return false`);
    }
    return false;
  }

  cacheRenderedItem = ({ productId }) => {
    const { loadShopProduct } = this.props;
    const { productCached } = this.state;

    let sharedProductCache = sharedCache.get('productCached');
    if (!sharedProductCache) {
      sharedProductCache = [];
    }

    if (includes(sharedProductCache, productId)) {
      return;
    }

    loadShopProduct({ productId })
      .then(() => {
        sharedProductCache.push(productId);
        sharedCache.set('productCached', sharedProductCache);
        this.setState({ productCached: sharedProductCache });
      });
  }

  renderItem = item => {
    let { post } = this.props;
    const {
      auth,
      onPressProduct,
      productImageStyle={},
      productTextContainerStyle={},
      itemContainerStyle={},
      isSmallVersion=true,
      showLikeButton=false,
      showTags=false,
      loadShopProduct,
      numColumns = 1,
      smallVersionImageTextSpace = 7.07,
    } = this.props;

    if (!post && item.hasOwnProperty('post')) {
      post = item.post;
    }

    const onPressLike = () => {
      // console.log('Products.onPressLike item=', item);
      const productId = item.goodsNo || item.godoGoodsNo || item._id;
      // console.log('Products.onPressLike productId=', productId);

      let product = item;
      if (!product.hasOwnProperty("goodsNo")) {
        product = convertMongoProductToGodoShopListProduct(product)
      } else {
        if (!product.hasOwnProperty("goodsNm")) {
          product.goodsNm = item.title;
        }
        if (!product.hasOwnProperty("goodsImageSrc")) {
          product.goodsImageSrc = item.imagesDetail[0];
        }
      }
      addToWishlist(this, product);
    };

    const onPressProductWithEvent = () => {
      const { router, params, location, routes, logEventClick } = this.props;
      const productId = item.goodsNo || item.godoGoodsNo || item._id;

      const currentRoute = routes[routes.length - 1];
      const page_name = currentRoute.name;

      if (page_name && page_name !== "Home") {
        const paramValues = Object.values(params);
        let page_key = null;
        if (paramValues.length > 0 && page_name !== "MyProfile") {
          page_key = paramValues[0];
        } else if(page_name == "SearchResult") {
          page_key = router.location.state.searchKeyword || '';
        }

        logEventClick({
          page_name,
          page_key,
          click_object_name: 'product',
          click_object_key: productId
        });
      }

      return onPressProduct(item, { post });
    }

    return (
      <Product
        containerStyle={itemContainerStyle}
        onPressProduct={onPressProductWithEvent}
        product={item}
        post={post}
        isSmallVersion={isSmallVersion}
        showLikeButton={showLikeButton}
        imageContainerStyle={[(isSmallVersion) ? styles.smallProductImage : styles.productImage, productImageStyle]}
        textContainerStyle={[productTextContainerStyle]}
        showTags={showTags}
        onPressLike={onPressLike.bind(this)}
        numColumns={numColumns}
        loadShopProductWithCache={this.cacheRenderedItem}
        enableImageFitContain={true}
        smallVersionImageTextSpace={smallVersionImageTextSpace}
      />
    );
  }

  loadData(nextPage) {
    const { loadData } = this.props;

    this.setState({ isFetchingNextPage: nextPage }, () => {
      loadData(nextPage);
    });
  }

  renderVerticalFooter() {
    const { listFooterComponentStyle, pagination } = this.props;

    //console.log('renderVerticalFooter pagination=', pagination);

    const isFetchingNextPage = pagination && (pagination.isFetching || pagination.nextPageUrl);

    return (
      <View>
        {isFetchingNextPage && (
          <View
            style={{
              position: 'relative',
              width: '100%',
              height: window.innerHeight / 5,
              paddingVertical: 20,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <ActivityIndicator animating size="small" color="gray" />
          </View>
        )}
        <View style={listFooterComponentStyle} />
      </View>
    );
  }

  render() {
    const {
      products,
      horizontal = true,
      itemSeparatorStyle = {
        width: 3 - Constants.touchEffectWidth * 2,
      },
      listHeaderComponentStyle = {
        width: Constants.viewHorizontalPadding,
      },
      listFooterComponentStyle = {
        width: Constants.viewHorizontalPadding,
      },
      containerStyle={},
      flatListStyle={},
      pagination,
      productViewType = 0,
      isSmallVersion = true,
      ...props
    } = this.props;

    const {
      refreshing,
      isFetchingNextPage,
      isLeftEnd,
      isRightEnd,
    } = this.state;

    const commonProps = {
      ...props,
      keyExtractor: (item, idx) => { return `${item._id}-${idx}`}, // eslint-disable-line no-underscore-dangle
      ListHeaderComponent: <View style={listHeaderComponentStyle}/>,
      ItemSeparatorComponent: () => <View style={itemSeparatorStyle} />,
      showsHorizontalScrollIndicator: false,
      renderItem: ({ item }) => this.renderItem(item),
      data: products,
      style: [styles.flatlist, flatListStyle],
      horizontal: horizontal,
    }

    if (horizontal) {
      return (
        <div className={_styles._desktop_carousel}>
          <View style={[styles.products, Styles.touchEffectContainer, containerStyle]}>
            <Animated.FlatList
              ref={ref => this.listRef = ref }
              ListFooterComponent={<View style={listFooterComponentStyle}/>}
              onScroll={(e) => this.handleScrollEvent(e)}
              onMomentumScrollEnd={this.handleMomentEnd}
              {...commonProps}
            />
            {!isLeftEnd &&
            <div className={_styles._desktop_carousel__left}
              style={{height : productViewType === 0 ? (isSmallVersion ? `${147}px` : `${220}px`) : '100%'}}
              onClick={this.handleLeftClickEvent.bind(this)}>
              <ArrowBackIosIcon
                color={"#333333"}
                style={{position:'absolute', top:'50%', left:'50%', width:"24px", height:'24px', transform:'translate(-50%, -50%)'}}/>
            </div>}
            {!isRightEnd &&
            <div className={_styles._desktop_carousel__right}
              style={{height : productViewType === 0 ? (isSmallVersion ? `${147}px` : `${220}px`) : '100%'}}
              onClick={this.handleRightClickEvent.bind(this)}>
              <ArrowForwardIosIcon
                color={"#333333"}
                style={{position:'absolute', top:'50%', left:'50%', width:"24px", height:'24px', transform:'translate(-50%, -50%)'}}/>
            </div>}
          </View>
        </div>
      );
    } else {
      if (pagination && pagination.isFetching && !isFetchingNextPage && !refreshing && (!products || products.length === 0)) {
        return (<Spinner />);
      }
      return (
        <View style={[styles.products, Styles.touchEffectContainer, containerStyle]}>
          <Animated.FlatList
            {...commonProps}
            ref={ref => this.listRef = ref }
            ListFooterComponent={() => this.renderVerticalFooter()}
            onEndReachedThreshold={2.0}
            onEndReached={() => {
              if (!pagination) {
                return;
              }
              if (isFetchingNextPage || refreshing || pagination.isFetching || pagination.nextPageUrl === null) {
                return;
              }
              this.loadData(true);
            }}
          />
        </View>
      );
    }
  }
}


Products.propTypes = {
  refreshing: PropTypes.bool,
};


Products.defaultProps = {
  refreshing: false,
}


export default connect(
  (state) => ({ auth: state.auth }),
  actions,
  null,
  { withRef: true}
)(withRouter(Products, { withRef: true }));
