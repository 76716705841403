import assign from 'lodash/assign';
import get from 'lodash/get';
import set from 'lodash/set';
import { normalize } from 'normalizr';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';
import { getNextPageUrl } from '../util';


// admin actions for posts
export function adminCreatePost(post) {
  return createFetchAction({
    type: 'ADMIN_CREATE_POST',
    endpoint: '/api/v2/admin/posts',
    method: 'POST',
    body: post,
    transform: ({ json }) => normalize(json, schemas.post),
    success: state => ({
      pagination: {
        key: `userPosts.${state.auth._id}`,
        type: 'PREPEND',
      },
    }),
  });
}

export function adminDeletePost(postId) {
  return createFetchAction({
    type: 'ADMIN_DELETE_POST',
    endpoint: `/api/v2/admin/posts/${postId}`,
    method: 'DELETE',
    success: {
      payload: set({}, `entities.posts.${postId}`, null),
    },
  });
}

export function adminUpdatePost(postId, post) {
  return createFetchAction({
    type: 'ADMIN_UPDATE_POST',
    endpoint: `/api/v2/admin/posts/${postId}`,
    method: 'PUT',
    body: post,
    transform: ({ json }) => normalize(json, schemas.post),
  });
}

export function adminLoadPostsFeatured(nextPage) {
  const key = 'featured';
  return createFetchAction({
    type: 'ADMIN_LOAD_POSTS_FEATURED',
    endpoint: state => {
      const base = '/api/v2/admin/posts?featured=yes';
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    transform: ({ json, response }) => assign(normalize(json, schemas.posts), {
      nextPageUrl: getNextPageUrl(response),
    }),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}

export function adminLoadPostsHidden(nextPage, ownerId) {
  const key = ownerId ? `hiddenUserPosts.${ownerId}` : 'hiddenPosts';
  const ownerQuery = ownerId ? '&owner=' + ownerId : '';

  return createFetchAction({
    type: 'ADMIN_LOAD_POSTS_HIDDEN',
    endpoint: state => {
      const base = '/api/v2/admin/posts?featured=no' + ownerQuery;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    transform: ({ json, response }) => assign(normalize(json, schemas.posts), {
      nextPageUrl: getNextPageUrl(response),
    }),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}

export function adminLoadPosts(nextPage, ownerId) {
  const key = ownerId ? `userPosts.${ownerId}` : 'posts';
  const ownerQuery = ownerId ? '&owner=' + ownerId : '';

  return createFetchAction({
    type: 'ADMIN_LOAD_POSTS',
    endpoint: state => {
      const base = '/api/v2/admin/posts?page=0' + ownerQuery;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    transform: ({ json, response }) => assign(normalize(json, schemas.posts), {
      nextPageUrl: getNextPageUrl(response),
    }),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}

export function adminSearchPosts({ instagramMediaId }) {
  const querystring = `instagramMediaId=${instagramMediaId}`;
  return createFetchAction({
    type: 'ADMIN_SEARCH_POST',
    endpoint: `/api/v2/admin/posts/search?${querystring}`,
    transform: ({ json }) => normalize(json, schemas.posts),
    doDispatch: false,
  });
}

export function adminSetPostFeatured(postId) {
  return createFetchAction({
    type: 'ADMIN_SET_POST_FEATURED',
    endpoint: `/api/v2/admin/posts/${postId}/set_featured`,
    method: 'PUT',
    transform: ({ json }) => normalize(json, schemas.post),
  });
}

export function adminSetPostUnfeatured(postId) {
  return createFetchAction({
    type: 'ADMIN_SET_POST_UNFEATURED',
    endpoint: `/api/v2/admin/posts/${postId}/unset_featured`,
    method: 'PUT',
    transform: ({ json }) => normalize(json, schemas.post),
  });
}

// TODO: (hyunuk) Delete the following action if not needed.
export function adminUpdateFeaturedList(featured) {
  return {
    type: 'ADMIN_UPDATE_FEATURED',
    payload: { result: featured },
    pagination: { key: 'featured', type: 'REFRESH' },
  };
}

export function adminRemoveSalePrice(postId) {
  return createFetchAction({
    type: 'ADMIN_REMOVE_SALE_PRICE',
    endpoint: `/api/v2/admin/posts/${postId}/removeSalePrice`,
    method: 'PUT',
    transform: ({ json }) => normalize(json, schemas.post),
  })
}

// export function hideFeatured(postId) {
//   return createFetchAction({
//     type: 'HIDE_FEATURED',
//     endpoint: `/api/v2/admin/posts/${postId}/hide_featured`,
//     method: 'PUT',
//     transform: ({ json }) => normalize(json, schemas.post),
//   });
// }

// export function toggleVisible(postId) {
//   return createFetchAction({
//     type: 'TOGGLE_VISIBLE',
//     endpoint: `/api/v2/admin/posts/${postId}/toggle_visible`,
//     method: 'PUT',
//     transform: ({ json }) => normalize(json, schemas.post),
//   });
// }

// export function adminGetPostCount() {
//   return createFetchAction({
//     type: 'POST_COUNT',
//     endpoint: '/api/v2/admin/posts/count',
//     doDispatch: false,
//   });
// }
