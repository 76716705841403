import * as React from 'react';

function SvgControlCheckSelected(props) {
  return (
    <svg width={24} height={24} {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="#000" d="M0 0h24v24H0z" />
        <path
          stroke="#FFF"
          strokeWidth={1.5}
          d="M6.574 10.856l4.46 4.46 6.698-6.7"
        />
      </g>
    </svg>
  );
}

export default SvgControlCheckSelected;
