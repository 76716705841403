import get from 'lodash/get';

import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, Dimensions, ActivityIndicator, Text, View } from 'react-native';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions, loadEntities } from 'shared';

import MyOrdersItem from '../../nativeComponents/MyOrdersItem';
import BlankResult from '../../nativeComponents/BlankResult';

import Styles from '../../constants/Styles';
import Colors from '../../constants/Colors';


const { width: windowWidth, height: windowHeight } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
  },
  noticeContainer: {
  },
  noticeHeader: {
    flex: 1,
    paddingTop: 20,
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#ecedee',
  },
  noticeSubject: {
    flexGrow: 1,
    fontSize: 16,
    fontWeight: 'bold',
    ...Styles.appleSDGothicNeo,
  },
  noticeDate: {
    marginTop: 5,
    fontSize: 15,
    color: 'rgba(0, 0, 0, 0.5)',
    ...Styles.appleSDGothicNeo,
  },
  noticeBody: {
    marginTop: 20,
    marginBottom: 112,
  },
  noticeContent: {
    fontSize: 14,
    lineHeight: 20,
    ...Styles.appleSDGothicNeo,
  },
  footer: {
  },
  empty: {
  },
  footerSpinnerContainer: {
    position: 'relative',
    width: '100%',
    height: windowHeight / 5,
    paddingVertical: 20,
    marginTop: 10,
    marginBottom: 10,
  },
  loadMoreContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const webStyles = {
  buttonLoadMore: {
    width: 340,
    marginTop: 15,
    marginBottom: 15,
  },
};


function getTimeStr(timestamp) {
  if (!timestamp) {
    return '';
  }

  const datetime = new Date(timestamp);
  const year = datetime.getFullYear();
  const month = datetime.getMonth() + 1;
  const date = datetime.getDate();

  return year + '.' + month + '.' + date;
}

export class NoticeViewPage extends React.Component {
  state = {
    notice: { _id: null },
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { loadNotice, noticeId } = this.props;
    const notice = await loadNotice(noticeId);
    this.setState({ notice });
  }

  renderFooter = () => {
    return (
      <View style={styles.footer} />
    );
  }

  render() {
    const { notice } = this.state;

    return (
      <View style={styles.container}>
        <View style={styles.noticeContainer}>
          <View style={styles.noticeHeader}>
            <Text style={styles.noticeSubject}>{ notice.subject }</Text>
            <Text style={styles.noticeDate}>{ getTimeStr(notice.createdAt) }</Text>
          </View>
          <View style={styles.noticeBody}>
            <Text style={styles.noticeContent}>{ notice.content }</Text>
          </View>
        </View>
        {this.renderFooter()}
      </View>
    );
  }
}

export default connect(
  (state, { params }) => {
    const { noticeId } = params;
    return {
      auth: state.auth,
      noticeId,
    };
  },
  actions,
)(NoticeViewPage);
