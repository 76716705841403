import React from 'react'
import { connect } from 'react-redux';
import { StyleSheet, View, FlatList, Text } from 'react-native';

import { actions } from 'shared';

import { Styles } from '../../../../constants';
import { ListSelectedIcon } from '../../../../icons-v2';
import TouchableHighlightWithContainer from '../../../TouchableHighlightWithContainer';

import FilterBaseModal from '../FilterBaseModal';


const styles = StyleSheet.create({
  itemContainer: {
    paddingTop: 25,
    paddingBottom: 24,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingStart: 20,
    paddingEnd: 24,
  },
  itemText: {
    ...Styles.pretendard400,
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
  },
  itemTextSelected: {
    ...Styles.pretendard600,
  },
});


class FilterSizeStandardModal extends FilterBaseModal {

  title = '사이즈 기준';
  isBottomVisible = true;

  renderItem = ({ item }) => {
    const { selectedSizeStandard=this.props.selectedSizeStandard } = this.state;

    const onPress = () => {
      this.setState({ selectedSizeStandard: item });
    };

    const textStyles = [styles.itemText];
    const isSelected = item.id === selectedSizeStandard.id;
    if (isSelected) {
      textStyles.push(styles.itemTextSelected);
    }

    return (
      <TouchableHighlightWithContainer
        onPress={onPress.bind(this)}
        >
        <View style={styles.itemContainer}>
          <Text style={textStyles}>{item.name}</Text>
          { isSelected && (
            <ListSelectedIcon />
          )}
        </View>
      </TouchableHighlightWithContainer>
    )
  }

  onPressApplyButton() {
    const { onChange } = this.props;
    const { selectedSizeStandard=this.props.selectedSizeStandard } = this.state;

    const {
      modalProps,
      setModalProps,
    } = this.props;

    const {
      historyStack=[],
    } = modalProps();

    const children = historyStack.pop();
    const isVisible = !!children;

    onChange(selectedSizeStandard, () => {
      setModalProps({
        isVisible,
        children,
        historyStack,
      });  
    });
  }

  renderBody() {
    const { sizeStandardList } = this.props;

    return (
      <FlatList
        renderItem={this.renderItem.bind(this)}
        data={sizeStandardList}
        />
    );
  }
}


export default connect(
  null,
  actions,
)(FilterSizeStandardModal);
