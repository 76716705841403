import React from 'react';
import { connect } from 'react-redux';

import { actions, loadEntities, getDateString } from 'shared';

import HomeFeed from './HomeFeed';

import { Constants } from '../../../constants';
import { showPopup as showPopupModal } from './PopupModal';


class HomePage extends React.Component {
  homeFeedRef = null;
  state = {};

  onFocus = () => {
    this.loadMarketingPopups().then(() => { this.showPopup(); });
  }

  isLoggedIn = () => {
    const { auth } = this.props;
    if (!auth || !auth.token) {
      return false;
    }
    return true;
  }

  loadMarketingPopups = async () => {
    const { loadMarketingPopups } = this.props;
    const { lastLoadMarketingPopups } = this.state;

    const now = Date.now();
    const minRefresh = 60000;  // milliseconds
    const threshold = lastLoadMarketingPopups + minRefresh;

    if (threshold > now) {
      console.log(`HomePage loadMarketingPopups threshold check: ${threshold} > ${now}`);
      return;
    }

    try {
      this.setState({ lastLoadMarketingPopups: now });
      await loadMarketingPopups();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('HomePage loadMarketingPopups failed error=', error);
    }
  }

  showPopup = async () => {
    const {
      marketingPopups, userCouponList,
    } = this.props;

    if (marketingPopups.length <= 0) {
      return;
    }
    // console.log('HomePage marketingPopups=', marketingPopups);

    const userDisabledDict = JSON.parse(sessionStorage.getItem(Constants.marketingPopupStorageKey) || '{}');
    // console.log('HomePage userDisabledDict=', userDisabledDict);
    const enabledPopups = marketingPopups.filter(popup => {
      if (popup.displayTab !== 'home')
        return false;

      if (popup.userClosed)
        return false;

      const dateStr = getDateString(new Date());
      if (userDisabledDict[popup._id] == dateStr)
        return false;

      if (this.isLoggedIn() && popup.linkType == "register_coupon" && userCouponList.filter(c => c.couponNo == popup.linkedUrl).length == 0) {
        return false;
      }

      return true;
    });

    // console.log('HomePage enabledPopups=', enabledPopups);

    // Open popup screen
    if (enabledPopups.length > 0) {
      this.setState({ 
        currentPopup: enabledPopups.shift(),
        popupList: enabledPopups,
      }, () => {
        showPopupModal(this);
      });
    }
  }

  componentDidMount() {
    this.loadMarketingPopups().then(() => { this.showPopup(); });

    window.addEventListener('focus', this.onFocus);
  }

  componentDidUpdate(prevProps) {
    const { marketingPopups = [] } = this.props;
    const { marketingPopups: prevMarketingPopups = [] } = prevProps;
    if (prevMarketingPopups.length === 0 && marketingPopups.length > 0) {
      this.showPopup();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.onFocus);
  }

  render() {
    const { router } = this.props;

    return (
      <React.Fragment>
        <HomeFeed
          router={router}
          />
          {/* {renderPopup(this)} */}
      </React.Fragment>
    );
  }
}


export default connect(
  (state) => ({
    marketingPopups: loadEntities(state, 'marketingPopups', 'marketingPopups').marketingPopups,
    userCouponList: loadEntities(state, 'userCouponList', 'userCouponList').userCouponList,
    auth: state.auth,
  }),
  actions,
)(HomePage);
