function preloadInitialData(parent) {
  // console.log('prealodInitialData');

  loadInitialDataForShop(parent);
}


async function loadInitialDataForShop(parent) {
  // console.log('loadInitialDataForShop');

  const {
    getProductCategoryList,
    loadShopBrandList,
    loadHomeTimeSales,
    loadHomeCategorySales,
    getProductListByCategory,
    getPartnerShippingProducts,
    getProductListByCategoryWithFilter,
  } = parent.props;

  loadShopBrandList();
  getPartnerShippingProducts();
  const res = await getProductCategoryList();
  // console.log('loadInitialDataForShop getProductCategoryList return ', res);
  let categoryId = '';
  if (res) {
    const { data: categoryData = [] } = res;
    categoryData.forEach(datum => {
      let cateCd = datum.cateCd;
      if (datum.children && datum.children.length > 0) {
        cateCd = datum.children[0].cateCd;
      }
      const query = {
        cateCd,
        count: 12,
        sortBy: 'popularity',
      };
      getProductListByCategory(cateCd, query, false);
    });
  }

  loadHomeCategorySales()
    .then((categorySales) => {
        categorySales.forEach(cs => {
          const query = {
            'cateGoods[]': cs.category,
            count: 12,
            sortBy: cs.sortBy,
          };
          const brandList = [];
          const colorList = [];
          const keywordList = [];
          let crawler = null;
          cs.filterList.forEach(f => {
            if (f.type === 'brand') {
              brandList.push(f.id);
            }
            if (f.type === 'keyword') {
              keywordList.push(f.name);
            }
            if (f.type === 'price') {
              const priceSplit = f.name.split('~');
              if (priceSplit.length == 2) {
                let min = priceSplit[0].trim();
                let max = priceSplit[1].trim();
                if (min.length > 0) {
                  min = Number.parseInt(min, 10);
                } else {
                  min = 0;
                }
                if (max.length > 0) {
                  max = Number.parseInt(max, 10);
                } else {
                  max = 999999999;
                }
                query['priceList'] = [min, max].join(',');
              }
            }
            if (f.type === 'crawler') {
              crawler = f.name;
            }
          });
          if (brandList.length > 0) {
            query['brandList'] = brandList.join(',');
          }
          if (keywordList.length > 0) {
            query['keyword'] = keywordList.join(' ');
          }
          if (crawler && keywordList.length > 0) {
            query['reSearchKey'] = 'memo';
            query['reSearchKeyword'] = crawler;
          } else if (crawler && keywordList.length <= 0) {
            query['key'] = 'memo';
            query['keyword'] = crawler;
          }

          getProductListByCategoryWithFilter(cs.category, query, false);
        });
    })
}


export {
  preloadInitialData,
}
