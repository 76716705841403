import React from 'react';
import {
  TouchableHighlight, StyleSheet, Text, View,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import NumberFormat from 'react-number-format';

import config from '../../config';
import packageJson from '../../../package.json';

import SellerInfo from '../../nativeComponents/SellerInfo';
import Colors from '../../constants/Colors';
import Constants from '../../constants/Constants';
import Styles from '../../constants/Styles';


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  profileContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: Constants.viewHorizontalPadding,
    marginTop: 34,
    marginBottom: 24,
  },
  profileUsernameContainer: {
  },
  profileUsernameText: {
    color: 'black',
    lineHeight: 21,
    fontSize: 18,
    ...Styles.appleSDGothicNeoBold,
  },
  profileLoginText: {
    textDecorationLine: 'underline',
  },
  userInfoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 20,
    paddingHorizontal: Constants.viewHorizontalPadding,
    borderBottomWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
  userInfoSubContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    flex: 1,
  },
  userInfoValue: {
    ...Styles.SFUITextSemiBold,
    fontSize: 16,
    color: 'black',
    height: 19,
    marginBottom: 4,
  },
  userInfoText: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 13,
    color: 'black',
    height: 16,
  },
  menuContainer: {
    marginHorizontal: Constants.viewHorizontalPadding,
    marginBottom: 10,
  },
  sectionHeaderContainer: {
    justifyContent: 'center',
    paddingBottom: 4,
    paddingLeft: 4,
  },
  sectionHeaderText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 14,
    height: 17,
    lineHeight: 17,
    color: 'black',
  },
  menuButton: {
    flex: 1,
    justifyContent: 'center',
    padding: 16,
    marginTop: 4,
    height: 52,
    backgroundColor: Colors.semiGray,
  },
  menuButtonContent: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  menuButtonText: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    color: 'black',
    height: 19,
    lineHeight: 19,
    marginTop: 1,
  },
  menuButtonSubText: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 13,
    height: 17,
    lineHeight: 17,
    marginTop: 1,
    opacity: 0.45,
  },
  spacer: {
    height: 20,
  },
  dialogTitleText: {
    fontSize: 16,
  },
  dialogContentText: {
    fontSize: 14,
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
  versionText: {
    opacity: 0.7,
  },
});

const guestStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: -12,
    marginBottom: -16,
  },
  buttonContainer: {
    flex: 1,
  },
  button: {
    flex: 1,
    alignItems: 'center',
    borderColor: 'black',
    borderWidth: 1,
    paddingTop: 14,
    paddingBottom: 13,
  },
  buttonText: {
    fontSize: 14,
    lineHeight: 17,
    ...Styles.appleSDGothicNeoBold,
  },
});


function sectionHeader(title) {
  return (
    <View style={styles.sectionHeaderContainer}>
      <Text style={styles.sectionHeaderText}>
        {title}
      </Text>
    </View>
  );
}

function sectionSpacer() {
  return (
    <View style={styles.spacer}>
    </View>
  );
}

function showChatInquiry() {
  const chatUrl = config.get('chatInquiryUrl');
  window.location.href = chatUrl;
}


export class SettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoutDialogOpen: false,
    };
  }

  async componentDidMount() {
    const { getUserSummary } = this.props;

    if (this.isLoggedIn()) {
      getUserSummary();
    }
  }

  isLoggedIn(props = null) {
    if (props === null) {
      const { auth } = this.props;
      // eslint-disable-next-line no-prototype-builtins
      return auth.hasOwnProperty('_id');
    }
    // eslint-disable-next-line no-prototype-builtins
    return props.auth.hasOwnProperty('_id');
  }


  moveToLoginPage = () => {
    const { router } = this.props;

    router.push({
      pathname: '/accounts/login',
    });
  }

  moveToJoinPage = () => {
    const { router } = this.props;

    router.push({
      pathname: '/accounts/login',
    });
  }


  menuButton(label, onPress, { buttonHideWithoutLogin, loginOnlyMenu, isEnd, subText = '' }) {
    const loggedIn = this.isLoggedIn();

    if (buttonHideWithoutLogin && !loggedIn) {
      return null;
    }

    const onPressButton = () => {
      if (!loggedIn && loginOnlyMenu) {
        this.moveToLoginPage();
        return;
      }
      if (onPress) {
        onPress();
      }
    };

    let endStyle = {};
    if (isEnd) {
      endStyle = { marginBottom: 20 };
    }

    return (
      <TouchableHighlight
        style={[styles.menuButton, endStyle]}
        underlayColor={Colors.touchEffectColorWhite}
        onPress={onPressButton}
      >
        <View style={styles.menuButtonContent}>
          {typeof label === 'function' ? label() : (
            <Text style={[
              styles.menuButtonText,
              !loggedIn && styles.menuButtonTextInactive,
            ]}
            >
              {label}
            </Text>
          )}
          <Text style={styles.menuButtonSubText}>{subText}</Text>
        </View>
      </TouchableHighlight>
    );
  }

  reset = async () => {
    const { logout } = this.props;
    // OneSignal.removeExternalUserId();
    if (global.rollbar) {
      global.rollbar.clearPerson();
    }

    logout();
    this.setState({ logoutDialogOpen: false });

    // Logout from godomall and return
    const { protocol } = window.location;
    const { host } = window.location;
    const returnUrl = `${protocol}//${host}`;
    const godoMobileBaseUrl = config.get('godomallMobileBaseUrl');
    const godoLogoutUrl = `${godoMobileBaseUrl}heartit_api/user_logout.php`
      + '?noMessage=y'
      + `&returnUrl=${encodeURIComponent(returnUrl)}`;
    window.location.href = godoLogoutUrl;
  }

  getLevel() {
    const { auth: { summary } } = this.props;
    if (this.isLoggedIn() && summary) {
      return summary.levelName;
    } else {
      return '-';
    }
  }

  getCouponCount() {
    const { auth: { summary } } = this.props;
    if (this.isLoggedIn() && summary) {
      return summary.couponCount;
    } else {
      return '-';
    }
  }

  getPoint() {
    const { auth: { summary } } = this.props;
    if (this.isLoggedIn() && summary) {
      return summary.mileagePoint;
    } else {
      return '-';
    }
  }

  showUserName() {
    const { auth } = this.props;

    const username = auth.name || auth.username;
    if (username) {
      return (
        <View style={styles.profileUsernameContainer}>
          <Text style={styles.profileUsernameText}>
            {username}
          </Text>
        </View>
      );
    }

    return (
      <View style={guestStyles.container}>
        <View style={[guestStyles.buttonContainer, {backgroundColor: 'black', marginRight: 7}]}>
          <TouchableHighlight
            style={guestStyles.button}
            underlayColor={Colors.touchEffectColorBlack}
            onPress={() => { this.moveToLoginPage(); }}
          >
            <Text style={[guestStyles.buttonText, {color: 'white',}]}>
              로그인
            </Text>
          </TouchableHighlight>
        </View>

        <View style={[guestStyles.buttonContainer, {backgroundColor: 'white'}]}>
          <TouchableHighlight
            style={guestStyles.button}
            underlayColor={Colors.touchEffectColorWhite}
            onPress={() => { this.moveToJoinPage(); }}
          >
            <Text style={[guestStyles.buttonText, {color: 'black',}]}>
              회원가입
            </Text>
          </TouchableHighlight>
        </View>
      </View>
    );
  }

  render() {
    const { auth, router } = this.props;
    const { logoutDialogOpen } = this.state;

    return (
      <View style={styles.container}>
        <View style={styles.profileContainer}>
          { this.showUserName() }
        </View>

        {sectionSpacer()}

        <View style={styles.userInfoContainer}>
          <View style={styles.userInfoSubContainer}>
            <Text style={styles.userInfoValue}>
              {this.getLevel()}
            </Text>
            <Text style={styles.userInfoText}>
              회원 등급
            </Text>
          </View>

          <View style={styles.userInfoSubContainer}>
            <Text style={styles.userInfoValue}>
              {this.getCouponCount()}
            </Text>
            <Text style={styles.userInfoText}>
              쿠폰
            </Text>
          </View>

          <View style={styles.userInfoSubContainer}>
            <NumberFormat
              value={this.getPoint()}
              displayType="text"
              thousandSeparator
              allowEmptyFormatting
              defaultValue="-"
              decimalScale={0}
              renderText={value => <Text style={styles.userInfoValue}>{value}</Text>}
            />
            <Text style={styles.userInfoText}>
              포인트
            </Text>
          </View>
        </View>

        {sectionSpacer()}

        <View style={[
          styles.menuContainer,
          !this.isLoggedIn() && { opacity: 0.4 },
        ]}
        >
          {sectionHeader('나의 쇼핑')}
          {this.menuButton(
            '주문 내역',
            () => router.push('/accounts/orders'),
            {
              buttonHideWithoutLogin: false,
              loginOnlyMenu: true,
            }
          )}
          {this.menuButton(
            '상품 리뷰',
            () => router.push('/accounts/reviews'),
            {
              buttonHideWithoutLogin: false,
              loginOnlyMenu: true,
            }
          )}

          {sectionSpacer()}

          {sectionHeader('설정')}
          {this.menuButton(
            '팔로우한 하티스트 목록',
            () => router.push('/accounts/following'),
            {
              buttonHideWithoutLogin: false,
              loginOnlyMenu: true,
            }
          )}
          {this.menuButton(
            '회원 정보 수정',
            () => router.push('/accounts/edit'),
            {
              buttonHideWithoutLogin: false,
              loginOnlyMenu: true,
            }
          )}
          {this.menuButton(
            '앱 설정',
            () => {},
            {
              buttonHideWithoutLogin: false,
              loginOnlyMenu: true,
            }
          )}

          {sectionSpacer()}

          {sectionHeader('고객센터')}
          {this.menuButton(
            '1:1 문의',
            () => { showChatInquiry(); },
            {
              buttonHideWithoutLogin: false,
              loginOnlyMenu: true,
            }
          )}
          {this.menuButton(
            'FAQ',
            () => router.push('/accounts/faq'),
            {
              buttonHideWithoutLogin: false,
              loginOnlyMenu: true,
            }
          )}
          {this.menuButton(
            '공지사항',
            () => router.push('/notice'),
            {
              buttonHideWithoutLogin: false,
              loginOnlyMenu: false,
            }
          )}
          {this.menuButton(
            '이용약관',
            () => router.push('/legal/terms'),
            {
              buttonHideWithoutLogin: false,
              loginOnlyMenu: false,
            }
          )}
          {this.menuButton(
            '개인정보 처리방침',
            () => router.push('/legal/privacy'),
            {
              buttonHideWithoutLogin: false,
              loginOnlyMenu: false,
            }
          )}
          {this.menuButton(
            () => (
              <Text style={[
                styles.menuButtonText,
                !this.isLoggedIn() && styles.menuButtonTextInactive,
              ]}
              >
                {'버전 정보 '}
                <Text style={styles.versionText}>{packageJson.version}</Text>
              </Text>
            ),
            () => {},
            {
              buttonHideWithoutLogin: false,
              loginOnlyMenu: false,
              isEnd: !this.isLoggedIn(),
              subText: true ? '최신 버전 사용 중' : '업데이트 필요',
            }
          )}

          {this.isLoggedIn() && sectionSpacer()}

          {this.menuButton(
            '로그아웃',
            () => { this.setState({ logoutDialogOpen: true }); },
            {
              buttonHideWithoutLogin: true,
              loginOnlyMenu: true,
              isEnd: true,
            }
          )}
        </View>

        <SellerInfo
          onPressTerms={() => router.push('/legal/terms')}
          onPressPrivacy={() => router.push('/legal/privacy')}
        />

        <Dialog
          open={logoutDialogOpen}
          onClose={() => this.setState({ logoutDialogOpen: false })}
          aria-labelledby="logout-alert-dialog-title"
          aria-describedby="logout-alert-dialog-description"
        >
          <DialogTitle id="logout-alert-dialog-title">
            <Text style={styles.dialogTitleText}>로그아웃</Text>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="logout-alert-dialogdescription">
              <Text style={styles.dialogContentText}>
                로그아웃하시겠습니까?
              </Text>
            </DialogContentText>
            <DialogActions>
              <Button onClick={() => this.setState({ logoutDialogOpen: false })} color="primary">
                취소
              </Button>
              <Button onClick={this.reset}>
                로그아웃
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </View>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
  }),
  actions,
)(SettingsPage);
