import assign from 'lodash/assign';
import get from 'lodash/get';
import { normalize } from 'normalizr';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';
import { getNextPageUrl } from '../util';

// admin actions for notices
export function adminLoadNotifications( query ) {
  const key = 'adminNotifications';

  return createFetchAction({
    type: 'ADMIN_LOAD_NOTIFICATION',
    endpoint: '/api/v2/admin/notification/search',
    method:'POST',
    body:query,
    transform: ({ json, response }) => assign(normalize(json, schemas.pushNotification), {
      nextPageUrl: getNextPageUrl(response),
    }),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}

export function adminCreateNotification({ notification }) {
  return createFetchAction({
    type: 'ADMIN_CREATE_NOTIFICATION',
    endpoint: '/api/v2/admin/notification',
    method: 'POST',
    body: notification,
    transform: ({ json }) => normalize(json, schemas.pushNotification),
    success: () => ({
      pagination: {
        key: 'adminNotices',
        type: 'PREPEND',
      },
    }),
  });
}

export function adminGetNotification(notificationId) {
  return createFetchAction({
    type: 'ADMIN_GET_NOTIFICATION',
    endpoint: `/api/v2/admin/notification/${notificationId}`,
    method: 'GET',
    doDispatch: false,
  });
}

export function adminUpdateNotification({ notificationId, notification }) {
  return createFetchAction({
    type: 'ADMIN_UPDATE_NOTIFICATION',
    endpoint: `/api/v2/admin/notification/${notificationId}`,
    method: 'PUT',
    body: notification,
    transform: ({ json }) => normalize(json, schemas.pushNotification),
  });
}

export function adminDeleteNotification({ notificationId }) {
  const payload = { entities: { adminNotifications: {} } };
  payload.entities.adminNotifications[notificationId] = null;

  return createFetchAction({
    type: 'ADMIN_DELETE_NOTIFICATION',
    endpoint: `/api/v2/admin/notification/${notificationId}`,
    method: 'DELETE',
    success: {
      payload,
    },
  });
}
