import * as React from 'react';

function SvgBtnSoundOn(props) {
  return (
    <svg width={32} height={32} {...props}>
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <circle cx={15} cy={15} r={15} fill="#000" opacity={0.348} />
        <path
          fill="#FFF"
          d="M15.08 8.57a.309.309 0 01.504.24v12.5c0 .26-.302.405-.504.24l-4.313-3.525h-2.51a.59.59 0 01-.59-.59v-4.75a.59.59 0 01.59-.59h2.51zm3.78.78A6.767 6.767 0 0122 15.077c0 2.33-1.174 4.47-3.14 5.725l-.617-.967c1.634-1.044 2.61-2.822 2.61-4.758s-.976-3.715-2.61-4.759zm-1.489 2.338a4.002 4.002 0 011.858 3.388c0 1.38-.694 2.646-1.858 3.39l-.617-.968a2.861 2.861 0 001.327-2.422 2.86 2.86 0 00-1.327-2.421z"
        />
      </g>
    </svg>
  );
}

export default SvgBtnSoundOn;

