import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';


export default class CustomDialog extends Component {
  render() {
    const {
      dialogOpen, title, description, key_ = '',
      onClose, onCancel, onConfirm, confirmText = '확인', cancelText = '취소',
      showCancel = true, showConfirm = true,
      cancelColor = 'default', confirmColor = 'primary'
    } = this.props;

    return (
      <Dialog
        open={dialogOpen}
        onClose={onCancel}
        onClick={(e) => { e.stopPropagation() }}
        aria-labelledby={`dialog-title-${key_}`}
        aria-describedby={`dialog-description-${key_}`}
      >
        {title && (
        <DialogTitle id={`dialog-title-${key_}`} style={styles.dialogTitleText}>
          {title}
        </DialogTitle>
        )}

        <DialogContent>
          {description && (
          <DialogContentText id={`dialog-description-${key_}`} style={styles.dialogContentText}>
            {description}
          </DialogContentText>
          )}

          <DialogActions>
            {showCancel && (
            <Button
              onClick={onCancel}
              color={cancelColor}
            >
              {cancelText}
            </Button>
            )}

            {showConfirm && (
            <Button
              onClick={onConfirm}
              color={confirmColor}
            >
              {confirmText}
            </Button>
            )}
          </DialogActions>

        </DialogContent>
      </Dialog>
    );
  }
}

const styles = {
  dialogTitleText: {
    fontSize: 16,
  },
  dialogContentText: {
    fontSize: 14,
  },
};
