import React from 'react';
import {
  Dimensions, ActivityIndicator, View, Text,
  StyleSheet, Platform, FlatList, TouchableHighlight,
} from 'react-native';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';


const { width: windowWidth, height: windowHeight } = Dimensions.get('window');


const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 10,
  },
  tagContainer: {
    marginHorizontal: 20,
    paddingVertical: 20,
    borderBottomWidth: 0.5,
    borderBottomColor: '#cfcfcf',
  },
  keyword: {
    fontSize: 16,
    lineHeight: 19.2,
    opacity: 0.5,
    ...Styles.pretendard400,
  },
  keywordBold: {
    fontSize: 16,
    lineHeight: 19.2,
    color: 'black',
    opacity: 1,
    ...Styles.pretendard600,
  },
  keywordFeedCount: {
    fontSize: 14,
    lineHeight: 16.8,
    color: 'black',
    opacity: 0.5,
    ...Styles.pretendard400,
  },
  emptyResultContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyResultText: {
    fontSize: 16,
    paddingBottom: 100,
  },
});

export default class TagSearchResultList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      isFetchingNextPage: false,
    };
  }

  componentDidMount() {
    this.loadData(false);
  }

  componentDidUpdate(prevProps) {
    const { searchKeyword } = this.props;

    if (prevProps.searchKeyword !== searchKeyword) {
      this.loadData(false);
    }
  }

  loadData(nextPage) {
    const { loadData, searchKeyword } = this.props;

    const query = {
      keyword: searchKeyword,
    };

    this.setState({ isFetchingNextPage: nextPage }, () => {
      loadData(query)
        .then(() => {
          this.setState({ refreshing: false });
        });
    });
  }

  onPressTag(tag) {
    const { router } = this.props;
    router.push({
      pathname: '/style/all',
      state: { hashtag: tag },
    });
  }

  renderItem = item => {
    return (
      <TouchableHighlight
        onPress={() => this.onPressTag(item.keyword)}
        style={[Styles.touchEffect, styles.tagContainer]}
        underlayColor={Colors.touchEffectColorWhite}
      >
        <View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <Text style={styles.keyword}>
                { `#${item.keywordPrefix}` }
              </Text>
              <Text style={styles.keywordBold}>
                { item.keywordInfix }
              </Text>
              <Text style={styles.keyword}>
                { item.keywordSuffix }
              </Text>
            </View>
            <View>
              <Text style={styles.keywordFeedCount}>
                피드 { item.stylePostCount }개
               </Text>
            </View>
          </View>
        </View>
      </TouchableHighlight>
    );
  }

  renderHeader = () => {
    return null;
  }

  render() {
    const { searchResults, router, ...props } = this.props;
    const { refreshing, isFetchingNextPage } = this.state;

    return (
      <View style={styles.container}>
        <FlatList
          {...props}
          showsVerticalScrollIndicator={false}
          ListHeaderComponent={this.renderHeader}
          data={searchResults}
          keyExtractor={({ index }) => `${index}`} // eslint-disable-line no-underscore-dangle
          ListFooterComponent={this.renderFooter}
          ListEmptyComponent={() => (
            <View style={styles.emptyResultContainer}>
              <Text style={styles.emptyResultText}>검색 결과가 없습니다.</Text>
            </View>
          )}
          renderItem={({ item }) => this.renderItem(item)}
          onRefresh={() => {
            if (!refreshing) {
              this.setState({ refreshing: true }, () => {
                this.loadData(false);
              });
            }
          }}
          refreshing={refreshing}
        />
      </View>
    );
  }
}
