import React from 'react';
import {
  Dimensions, StyleSheet, View, Animated, Text,
} from 'react-native';
import { connect } from 'react-redux';
import { actions } from 'shared';

import { Constants, Styles, Colors } from '../../../constants';

import Header from './Header';
import BlankPage from './BlankPage';

import Spinner from '../../../nativeComponents/Spinner';

import {
  CheckIcon
} from '../../../icons-v2';

import {
  Button, Products, SortModal,
} from '../../../components-v2';


const {
  height: windowHeight,
} = Dimensions.get('window');


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  listContainer: {

  },
  brandItemContainer: {
  },
  brandItemHeaderContainer: {
    paddingHorizontal: Constants.viewHorizontalPadding,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
  },
  brandNameContainer: {
    marginTop: 2 - 1 * Constants.touchEffectWidth,
    marginBottom: 1 - 1 * Constants.touchEffectWidth,
    marginHorizontal: -1 * Constants.touchEffectWidth,
    padding: Constants.touchEffectWidth,
    borderWidth: 0,
    borderColor: 'transparent',
    borderRadius: Constants.touchEffectBorderRadius,
  },
  brandNameText: {
    color: '#000',
    fontSize: 18,
    lineHeight: 21.6,
    height: 22,
    ...Styles.pretendard700,
  },
  followButtonContainer: {
    backgroundColor: "#000",
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 0,
    paddingVertical: 6,
    paddingLeft: 11,
    paddingRight: 7,
  },
  followingButtonContainer: {
    backgroundColor: '#fff',
    borderWidth: 0.5,
    borderColor: '#000',
  },
  followButtonText: {
    fontSize: 15,
    lineHeight: 18,
    color: "#fff",
    ...Styles.pretendard600,
    marginRight: 4,
  },
  followingButtonText: {
    color: '#000',
  },
  productContainerStyle: {
    width: 112,
  },
  productImageStyle: {
    width: 112,
    aspectRatio: 112/149,
    borderWidth: 1,
    borderColor: Colors.productImageBorder,
    overflow: 'hidden',
  },
  productTextContainer: {
    marginTop: 4,
  },
});


const SORTING_RULE_LIST = [
  { id: 1001, value: 'new', name: '최신 등록순' },
  { id: 1002, value: 'brandName', name: '브랜드명 ABC순' },
  { id: 1003, value: 'old', name: '오래된 순' },
];


export class MyBrandPage extends React.Component {
  state = {
    refreshing: false,
    isFetchingNextPage: false,
    followingBrands: [],
    sortingRule: SORTING_RULE_LIST[0],
  };

  listRef = React.createRef();

  constructor(props) {
    super(props);

    this.onChangeSortingRule = this.onChangeSortingRule.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    global.favScreenRef = this;
  }

  scrollToTop = () => {
    this.listRef.scrollToOffset(0);
  }

  loadData = nextPage => {
    const { getFollowingBrandList } = this.props;
    const { sortingRule } = this.state;

    this.setState({ isFetchingNextPage: nextPage }, () => {
      getFollowingBrandList({ sortBy: sortingRule.value })
        .then((result) => {
          this.setState({
            refreshing: false,
            isFetchingNextPage: false,
            followingBrands: result.data
          });
        });
    });
  }

  unfollowBrand = async (brandCd) => {
    const { unfollowBrand, getBrandFollowStatus } = this.props;

    try {
      await unfollowBrand(brandCd);
      await await getBrandFollowStatus(brandCd);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('unfollowBrand', error);
    }
  }

  onChangeSortingRule = (sortingRule) => {
    // console.log(`onChangeSortingRule sortingRule=${sortingRule}`);

    this.setState({ sortingRule });
  }

  renderHeader = (disabled = false) => {
    const { followingBrands, sortingRule } = this.state;

    const onPressSortingRule = () => {
      const {
        setModalProps,
        modalProps,
      } = this.props;
  
      const children = (
        <SortModal
          title='정렬 기준'
          selectedItem={sortingRule}
          onChange={this.onChangeSortingRule.bind(this)}
          setModalProps={setModalProps}
          modalProps={modalProps}
          itemList={SORTING_RULE_LIST}
          backdropStyle={{ height: '50vh' }}
          />
      );
  
      setModalProps({
        isVisible: true,
        children: children,
      });
    }

    return (
      <React.Fragment>
        <Header
          disabled={disabled}
          followingBrands={followingBrands}
          sortingRule={sortingRule}
          onPressSortingRule={onPressSortingRule.bind(this)}
          />
      </React.Fragment>
    );
  }

  componentDidMount() {
    const { logEventPageview } = this.props;

    this.loadData(false);

    logEventPageview({ page_name: 'MyBrand' });
  }

  componentDidUpdate(prevProps, prevState) {
    const { getFollowingBrandList } = this.props;
    const { sortingRule } = this.state;

    if ((!prevProps.getFollowingBrandList && getFollowingBrandList)
      || (prevState.sortingRule !== sortingRule)
    ) {
      this.loadData(false);
    }
  }

  renderItem = ({ item }) => {
    const products = item.products;

    return (
      <View style={styles.brandItemContainer}>
        <View style={styles.brandItemHeaderContainer}>
          <Button
            containerStyle={styles.brandNameContainer}
            titleStyle={styles.brandNameText}
            title={item.brandName}
            onPress={() => {}}
          />
          {this.renderFollowButton(item)}
        </View>

        <Products
          horizontal
          products={products}
          onPressProduct={(item) => {
            const { router } = this.props;
            router.push(`/product/${item.godoGoodsNo}`);
          }}
          listHeaderComponentStyle={{width: Constants.viewHorizontalPadding}}
          listFooterComponentStyle={{width: Constants.viewHorizontalPadding}}
          itemContainerStyle={styles.productContainerStyle}
          productImageStyle={styles.productImageStyle}
          productTextContainerStyle={styles.productTextContainer}
        />
      </View>
    );
  }

  renderFollowButton(item) {
    const onPress = () => {
      this.unfollowBrand(item.brandCd)
        .then(result => this.loadData(false));
    }

    return (
      <Button
        innerComponent={(
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={[styles.followButtonText, styles.followingButtonText]}>
              팔로우
            </Text>
            <CheckIcon />
          </View>
        )}
        containerStyle={[styles.followButtonContainer, styles.followingButtonContainer]}
        onPress={onPress}
      />
    );
  }

  renderBrandList() {
    const {
      showSmartBanner
    } = this.props;

    const { 
      followingBrands=[],
      refreshing,
    } = this.state;

    const listStyles = [styles.listContainer];
    listStyles.push({
      height: windowHeight
        - (showSmartBanner && Constants.smartBannerHeight)
        - Constants.mainHeaderHeight
        - 54
        - Constants.bottomTabBarHeight,
    });

    return (
      <View style={styles.container}>
        {this.renderHeader(false)}
        <Animated.FlatList
          ref={ref => { this.listRef = ref; }}
          data={followingBrands}
          keyExtractor={item => item._id}
          onEndReached={() => {
            if (refreshing) {
              return;
            }
            this.loadData(true);
          }}
          onEndReachedThreshold={0.2}
          onRefresh={() => {
            if (!refreshing) {
              this.setState({ refreshing: true }, () => {
                this.loadData(false);
              });
            }
          }}
          refreshing={refreshing}
          style={listStyles}
          renderItem={this.renderItem}
          ListHeaderComponent={() => <View style={{ height: 10.5 }} />}
          ListFooterComponent={() => <View style={{ height: 31 }} />}
          ItemSeparatorComponent={() => <View style={{ height: 20 }} />}
          showsVerticalScrollIndicator={false}
          />
      </View>
    );
  }

  render() {
    const {
      router,
    } = this.props;

    const { 
      followingBrands=[],
      isFetchingNextPage,
      refreshing,
    } = this.state;

    if (!isFetchingNextPage && refreshing) {
      return (<Spinner />);
    }

    if (followingBrands.length === 0) {
      return (
        <BlankPage
          HeaderComponent={this.renderHeader(true)}
          router={router}
          />
      );
    }

    return this.renderBrandList();
  }
}


export default connect(
  state => {
    return {
      auth: state.auth,
    };
  },
  actions,
)(MyBrandPage);
