import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M5.613 13.19l.55.117a.563.563 0 00-.235-.584l-.315.467zM4 15.833l-.257-.5A.563.563 0 004 16.396v-.563zm3.69-1.69l.155-.54a.562.562 0 00-.546.137l.392.404zm8.414-4.83c0 2.406-2.589 4.585-6.104 4.585v1.125c3.849 0 7.23-2.43 7.23-5.71h-1.126zM10 4.73c3.515 0 6.104 2.18 6.104 4.585h1.125c0-3.28-3.38-5.71-7.229-5.71V4.73zM3.896 9.314c0-2.406 2.589-4.585 6.104-4.585V3.604c-3.848 0-7.23 2.43-7.23 5.71h1.126zm2.032 3.41c-1.281-.866-2.032-2.094-2.032-3.41H2.77c0 1.775 1.014 3.32 2.528 4.342l.63-.933zm-1.67 3.61c1.161-.599 1.68-1.968 1.906-3.027l-1.1-.234c-.215 1.006-.644 1.911-1.321 2.26l.514 1zm3.041-2.594C6.065 14.937 4.671 15.27 4 15.27v1.126c.953 0 2.63-.44 4.082-1.848L7.3 13.74zm2.701.158a7.87 7.87 0 01-2.155-.295l-.308 1.082c.77.22 1.6.338 2.463.338v-1.125z"
        fill="#000"
      />
    </Svg>
  )
}

export default SvgComponent

