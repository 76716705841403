import React from 'react';
import { StyleSheet, Text, View, TouchableHighlight } from 'react-native';

import CloudinaryImage from './CloudinaryImage';
import { openOutlinkPage } from '../containers/OutlinkGatewayPage';

import Constants from '../constants/Constants';
import Styles from '../constants/Styles';
import Colors from '../constants/Colors';

import RadioNormal from '../icons/controlRadioNormal';
import RadioSelected from '../icons/controlRadioSelected';
import { generateFirebaseEvent } from '../utils';


const stylesHtml = {
  imgContainer: {
    marginBottom: 17,
    position: 'relative',
    height: 0,
    paddingTop: '100%',
  },
  imgStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
};

const styles = StyleSheet.create({
  container: {
    position: 'fixed',
    zIndex: 9999,
    top: 0,
    bottom: 0,
    margin: 'auto',
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    alignItems: 'center',
  },
  centerContainer: {
    backgroundColor: 'white',
    borderRadius: 4,
    flexDirection: 'column',
    width: '100%',
    overflowY: 'auto',
    maxWidth: 960,
    maxHeight: '100%',
  },
  bottomContainer: {
    backgroundColor: 'white',
    paddingBottom: Constants.mainBottomMargin + 15,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: '100%',
    maxWidth: 960,
  },
  imgControlContainer: {
    flexDirection: 'column',
    paddingTop: 20,
    paddingHorizontal: 20,
  },
  controlContainer: {
    height: 24,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  doNotShowAgainButton: {
    flexDirection: 'row',
    ...Styles.touchEffect,
  },
  controlText: {
    ...Styles.appleSDGothicNeoSemiBold,
    fontSize: 16,
    color: 'black',
    lineHeight: 19,
  },
  controlTextDoNotShowAgain: {
    height: 19 + 1,
    marginTop: 4,
    textAlignVertical: 'top',
  },
  closeButtonContainer: {
    marginTop: 4,
    marginBottom: 1,
    marginRight: 15,
  },
  detailButton: {
    height: 46,
    marginHorizontal: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    marginTop: 19,
    marginBottom: 20,
    alignItems: 'center',
  },
  detailButtonText: {
    ...Styles.appleSDGothicNeoSemiBold,
    fontSize: 16,
    color: 'black',
    lineHeight: 19,
    height: 19 + 3,
    textAlignVertical: 'top',
    marginTop: 14,
    marginBottom: 13 - 3,
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
    borderRadius: Constants.touchEffectBorderRadius,
  },
});


export default class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doNotShowAgain: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { _id } = this.props;

    if (prevProps._id !== _id) {
      this.setState({ doNotShowAgain: false });
    }
  }

  onPressDoNotShowAgain = () => {
    const { doNotShowAgain } = this.state;
    this.setState({ doNotShowAgain: !doNotShowAgain });
  }

  onPressClose = async (detailPressed) => {
    const { _id, closeMarketingPopup, onPopupClosedCallback } = this.props;
    const { doNotShowAgain } = this.state;

    if (doNotShowAgain) {
      const disabledList = JSON.parse(sessionStorage.getItem(Constants.marketingPopupStorageKey) || '[]');
      disabledList.push(_id);
      sessionStorage.setItem(Constants.marketingPopupStorageKey, JSON.stringify(disabledList));
    }
    await closeMarketingPopup(_id);
    if (onPopupClosedCallback) {
      onPopupClosedCallback(detailPressed);
    }
  }

  onPressOutlink = () => {
    const { linkedUrl } = this.props;
    window.open(linkedUrl);
  }

  onPressProduct = () => {
    const { linkedPost: post, linkedProduct: product,
      router, auth, historySwitch } = this.props;
    const { token } = auth;

    if (!product) {
      alert('삭제된 상품입니다.');
      return;
    }

    // TODO: Post 정보가 없는 경우 이벤트를 보내지 않아도 괜찮은지 확인 필요
    if (post && post.owner) {
      generateFirebaseEvent({ product, post, where: 'post' });
    }

    if (product.godoGoodsNo) {
      router.push(`/product/${product.godoGoodsNo}`);
      return;
    }
    openOutlinkPage(product, post, historySwitch, token, router);
  }

  onPressPost = () => {
    const { linkedPost: post, router } = this.props;
    if (!post) {
      alert('삭제된 포스트입니다.');
      return;
    }

    router.push(`/posts/${post._id}`);
  }

  onPressDetail = () => {
    const { linkType } = this.props;

    switch(linkType) {
      case 'product':
        this.onPressClose(true);
        this.onPressProduct();
        break;

      case 'outlink':
        this.onPressClose(true)
        this.onPressOutlink();
        break;

      default:
      case 'post':
        this.onPressClose(true);
        this.onPressPost();
        break;
    }
  }

  renderImageControl() {
    const { media: { publicId, width, height } } = this.props;
    const { doNotShowAgain } = this.state;

    return (
      <View style={styles.imgControlContainer}>
        <View style={stylesHtml.imgContainer}>
          <CloudinaryImage
            publicId={publicId}
            options={{
              crop: 'limit',
              format: 'jpg',
              height,
              width,
            }}
            style={stylesHtml.imgStyle}
          />
        </View>
        <View style={styles.controlContainer}>
          <TouchableHighlight
            underlayColor={Colors.touchEffectColorWhite}
            onPress={this.onPressDoNotShowAgain}
            style={styles.touchEffectContainer}
          >
            <View style={styles.doNotShowAgainButton}>
              <View style={{ marginRight: 12 }}>
                {doNotShowAgain ? <RadioSelected /> : <RadioNormal />}
              </View>
              <Text style={[styles.controlText, styles.controlTextDoNotShowAgain]}>
                다시 보지 않기
              </Text>
            </View>
          </TouchableHighlight>

          <View style={styles.closeButtonContainer}>
            <TouchableHighlight
              underlayColor={Colors.touchEffectColorWhite}
              onPress={() => this.onPressClose(false)}
              style={styles.touchEffectContainer}
            >
              <Text style={[styles.controlText, styles.touchEffect]}>
                닫기
              </Text>
            </TouchableHighlight>
          </View>
        </View>
      </View>
    );
  }

  renderBottomPopup() {
    return(
      <View style={styles.bottomContainer}>
        {this.renderImageControl()}
      </View>
    );
  }

  renderCenterPopupDetailButton() {
    const { linkType, linkedUrl } = this.props;

    if (linkType === 'outlink' && !linkedUrl) {
      return (
        <View style={{ marginBottom: 17 }}></View>
      );
    } else {
      return (
        <TouchableHighlight
          onPress={this.onPressDetail}
          underlayColor={Colors.touchEffectColorWhite}
          style={styles.detailButton}
        >
          <Text style={styles.detailButtonText}>
            자세히 보기
          </Text>
        </TouchableHighlight>
      );
    }
  }

  renderCenterPopup() {
    return(
      <View style={styles.centerContainer}>
        {this.renderImageControl()}
        {this.renderCenterPopupDetailButton()}
      </View>
    );
  }

  render() {
    const { position = 'bottom' } = this.props;
    return (
      <View style={[
        styles.container,
        position === 'center'
          ? { justifyContent: 'center', paddingHorizontal: Constants.viewHorizontalPadding }
          : { justifyContent: 'flex-end' },
      ]}
      >
        {position === 'center' ? this.renderCenterPopup() : this.renderBottomPopup()}
      </View>
    );
  }
}
