import React from 'react';
import {
  StyleSheet, Text, View,
} from 'react-native';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions, loadEntities } from 'shared';


import {
  Styles,
} from '../../../constants';

import { Button } from '../../../components-v2';


const styles = StyleSheet.create({
  headerContainer: {
    height: 49,
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    ...Styles.fullWidth,
  },
  labelButton: {
    flex: 1,
    borderWidth: 0,
    padding: 0,
  },
  labelContainer: {
    marginVertical: 4,
  },
  labelText: {
    fontSize: 18,
    lineHeight: 21.6,
    height: 22,
    color: '#000',
    opacity: 0.5,
    textAlign: 'center',
    ...Styles.pretendard700,
  },
  labelTextFocused: {
    opacity: 1,
  },
  labelCountText: {
    marginTop: 2,
    fontSize: 14,
    lineHeight: 16.8,
    height: 17,
    color: '#000',
    opacity: 0.5,
    textAlign: 'center',
    ...Styles.pretendard400,
  },
  labelCountTextFocused: {
    opacity: 1,
  },
});


export class Header extends React.Component {
  tabs = [
    { key: 'follower', title: 'Followers' },
    { key: 'following', title: 'Following' },
  ];

  constructor(props) {
    super(props);

    this.type = '';
  }

  componentDidMount() {
    this.loadData(false);
  }

  loadData(nextPage) {
    const { loadFollowerHeartists, loadFollowingHeartists, loadFeaturedHeartists } = this.props;
    loadFollowerHeartists(nextPage);
    loadFollowingHeartists(nextPage);
    loadFeaturedHeartists(nextPage);
  }

  renderHeaderLabel = ({ tab, focused }) => {
    const {
      router,
      followers,
      followingHeartists,
    } = this.props;

    let count = 0;
    if (tab.key === 'follower') {
      count = followers.length;
    } else if (tab.key === 'following') {
      count = followingHeartists.filter(heartist => heartist.following).length;
    }

    const onChange = () => {
      router.push(`/accounts/${tab.key}`);
    }

    return (
      <View style={{ flex: 1 }}>
        <Button
          containerStyle={styles.labelButton}
          onPress={onChange}
          key={tab.key}
          innerComponent={(
            <View style={styles.labelContainer}>
              <Text
                style={[styles.labelText, focused && styles.labelTextFocused]}
              >
                {tab.title}
              </Text>
              <Text
                style={[styles.labelCountText, focused && styles.labelCountTextFocused]}
              >
                { count }
              </Text>
            </View>
          )}
        />
      </View>
    );
  }

  render() {
    const { type } = this.props;
    return (
      <View style={styles.headerContainer}>
        {this.tabs.map(tab => 
          this.renderHeaderLabel({ tab, focused: tab.key == type })
        )}
      </View>
    );
  }
}


export default connect(
  state => ({
    auth: state.auth,
    ...loadEntities(state, 'followers', 'followers', 'pagination', 'followers'),
    ...loadEntities(state, 'followingHeartists', 'heartists', 'pagination', 'followingHeartists'),
    ...loadEntities(state, 'featuredHeartists', 'heartists', 'pagination', 'featuredHeartists'),
  }),
  actions,
)(Header);
