import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Sortable from 'react-sortablejs';
import styled from 'styled-components';
import Media from '../Media';

const CaptionContainer = styled.div`
  padding: 9px;
  color: #333;
`;

const MoveIcon = styled.span`
  cursor: pointer;
`;

const HeaderContainer = styled.span`
  float: right;
  font-size: 11px;
`;

const ThumbnailResponsiveContainer = styled.div`
  @media (min-width: 992px) {
    width: 25%;
  }
  float: left;
  position: relative;
  min-height: 1px;
  padding: 0px 15px;
  width: 50%;
`;

const Thumbnail = styled.div`
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const ThumbnailListContainer = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  &:after {
    clear: both;
    content: "";
    display: table;
  }
`;

export default class ThumbnailList extends Component {
  handleUpdate(event) {
    const { onUpdate } = this.props;
    const ordered = this.props.items.slice();
    const { oldIndex, newIndex } = event;
    if (onUpdate) {
      ordered.splice(newIndex, 0, ordered.splice(oldIndex, 1)[0]);
      onUpdate(ordered, oldIndex, newIndex);
    }
  }

  renderHeaders(item) {
    const { isSortable, renderHeader } = this.props;
    return (
      <div>
        {isSortable && <MoveIcon>&#10021;</MoveIcon>}
        {renderHeader && (
          <span>
            &nbsp;
            <HeaderContainer>
              {renderHeader(item)}
            </HeaderContainer>
          </span>
        )}
      </div>
    );
  }

  renderItem(item, index) {
    const { getMedia, renderCaption } = this.props;
    const { displayAspectRatio = 1 } = this.props;
    const media = getMedia(item);
    const mediaOptions = {
      width: 200,
      height: 200,
      crop: 'limit',
    };

    // if (item.displayAspectRatio) {
    //   // eslint-disable-next-line prefer-destructuring
    //   displayAspectRatio = item.displayAspectRatio;
    // }

    const style = {
      paddingTop: `${1 / displayAspectRatio * 100}%`,
    };

    return (
      <ThumbnailResponsiveContainer key={item._id || media.publicId}>
        <Thumbnail>
          {this.renderHeaders(item)}
          <Media media={media} options={mediaOptions} style={style} />
          <CaptionContainer>
            {renderCaption(item, index)}
          </CaptionContainer>
        </Thumbnail>
      </ThumbnailResponsiveContainer>
    );
  }

  render() {
    const { items, isSortable } = this.props;
    return (
      <ThumbnailListContainer>
        {isSortable
          ? (
            <Sortable
              option={{ handle: '.handle' }}
              onChange={(order, sortable, event) => this.handleUpdate(event)}
            >
              {items.map((item, index) => this.renderItem(item, index))}
            </Sortable>
          )
          : items.map((item, index) => this.renderItem(item, index))
        }
      </ThumbnailListContainer>
    );
  }
}

ThumbnailList.propTypes = {
  items: PropTypes.array.isRequired,
  isSortable: PropTypes.bool,
  getMedia: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  renderCaption: PropTypes.func.isRequired,
  renderHeader: PropTypes.func,
};

ThumbnailList.defaultProps = {
  isSortable: true,
  onUpdate: null,
  renderHeader: null,
};
