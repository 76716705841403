import * as React from "react"
import Svg, { Path } from "react-native-svg"

function LoginFacebookIcon(props) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.703 6.468v2.539h4.02l-.53 4.125h-3.49v10.049H8.472V13.132H5.087V9.007h3.385V5.41c0-6.135 8.25-4.442 8.25-4.442v3.596h-2.115c-2.035 0-1.904 1.904-1.904 1.904z"
        fill="#3C5CA9"
      />
    </Svg>
  )
}

export default LoginFacebookIcon

