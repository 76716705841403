import * as React from "react"
import Svg, { Path } from "react-native-svg"

function LikeSelectedIcon(props) {
  return (
    <Svg
      width={24}
      height={26}
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M10.284 19.05c.617.517 1.2 1.004 1.716 1.472v.007a51.234 51.234 0 011.717-1.473l.008-.007c2.172-1.812 4.633-3.867 5.72-6.33.357-.784.547-1.639.555-2.505a4.46 4.46 0 00-1.291-3.156A4.261 4.261 0 0015.6 5.796a4.843 4.843 0 00-2.662.804 4.655 4.655 0 00-.938.832c-.273-.32-.589-.6-.938-.832A4.848 4.848 0 008.4 5.796a4.261 4.261 0 00-3.109 1.262A4.46 4.46 0 004 10.214c.009.865.198 1.718.555 2.502 1.085 2.457 3.539 4.509 5.707 6.322l.014.011h.008z"
        fill="#000"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default LikeSelectedIcon

