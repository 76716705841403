import React from 'react';

const styles = {
  container: {
    margin: '32.5px auto 60px',
    textAlign: 'center',
    display: 'table',
  },
};

const Spinner = ({ width = 150, height = 150 }) => (
  <div style={styles.container}>
    <img
      width={width}
      height={height}
      src="https://s3.ap-northeast-2.amazonaws.com/heartit-cdn/img/r2/icon/Spinner-1s-200px.gif"
      alt=""
      style={{ objectFit: 'contain' }}
    />
  </div>
);

export default Spinner;
