import React from 'react';
import { StyleSheet, View, TouchableHighlight, Text, FlatList } from 'react-native';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';
import Constants from '../constants/Constants';


export default class SearchKeyword extends React.Component {
  renderRoundKeyword = ({ item: keyword }) => {
    const { onPressKeyword } = this.props;

    return (
      <TouchableHighlight
        style={styles.keywordRoundContainer}
        underlayColor={Colors.touchEffectColorWhite}
        onPress={() => onPressKeyword(keyword)}
        key={keyword}
      >
        <Text style={styles.keywordText}>{keyword}</Text>
      </TouchableHighlight>
    );
  }

  render() {
    const { data = [], containerStyle = {} } = this.props;

    return (
      <View style={[styles.container, containerStyle]}>
        <FlatList
          style={[styles.sectionContainer]}
          horizontal
          data={data}
          renderItem={this.renderRoundKeyword}
          showsHorizontalScrollIndicator={false}
          keyExtractor={(_item, index) => index.toString()} // eslint-disable-line no-underscore-dangle
          ItemSeparatorComponent={() => (<View style={{ width: 3 }} />)}
          ListHeaderComponent={<View style={{ width: Constants.viewHorizontalPadding  }}/>}
          ListFooterComponent={<View style={{ width: Constants.viewHorizontalPadding  }}/>}
        />
      </View>
    );
  }
}

const keywordContainerHorizontalMargin = 3;
const keywordContainerVerticalMargin = 2.5;
const keywordContainerHeight = 42;
const styles = StyleSheet.create({
  container: {
    maxWidth: 960,
  },
  sectionContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
  keywordRoundContainer: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingStart: 11,
    paddingEnd: 11,
    borderWidth: 0.5,
    borderColor: 'black',
  },
  keywordText: {
    fontSize: 15,
    lineHeight: 18,
    color: 'black',
    fontWeight: '400',
   ...Styles.pretendardVariable,
  },
});
