import * as React from 'react';

function SvgBtnCloseBanner(props) {
  return (
    <svg width={12} height={12} {...props}>
      <g stroke="#FFF" strokeWidth={1.3} fill="none" fillRule="evenodd">
        <path d="M1 11L11 1M1 1l10 10" />
      </g>
    </svg>
  );
}

export default SvgBtnCloseBanner;
