// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet } from 'react-native';

import Constants from './Constants';
import Colors from './Colors';

const textCommon = {
  // 디자인 영역 확인 용 코드
  // borderWidth: 1,
};

const baseStyles = {
  fullWidth: {
    flex: 1,
    flexGrow: 1,
    flexShrink: 0,
    width: '100%',
  },
  sectionInput: {
    fontSize: 13,
    paddingTop: 23,
    paddingBottom: 23,
  },
  touchEffect: {
    padding: Constants.touchEffectWidth,
    borderRadius: Constants.touchEffectBorderRadius,
  },
  touchEffectContainer: {
    margin: -1 * Constants.touchEffectWidth,
  },
  touchEffectInHeader: {
    padding: Constants.touchEffectWidthInHeader,
    borderRadius: Constants.touchEffectBorderRadiusInHeader,
  },
  touchEffectContainerInHeader: {
    margin: -1 * Constants.touchEffectWidthInHeader,
  },
  buttonDark: {
    backgroundColor: '#000',
  },
  buttonDarkDisabled: {
  },
  buttonDarkText: {
    color: '#fff',
  },
  buttonDarkTextDisabled: {
    opacity: 0.45,
  },
  buttonLight: {
    backgroundColor: Colors.semiGray,
  },
  buttonLightText: {
  },
  pretendard100: {
    fontFamily: 'PretendardJP-Thin',
    fontWeight: 100,
  },
  pretendard200: {
    fontFamily: 'PretendardJP-ExtraLight',
    fontWeight: 200,
  },
  pretendard300: {
    fontFamily: 'PretendardJP-Light',
    fontWeight: 300,
  },
  pretendard400: {
    fontFamily: 'PretendardJP-Regular',
    fontWeight: 400,
  },
  pretendard500: {
    fontFamily: 'PretendardJP-Medium',
    fontWeight: 500,
  },
  pretendard600: {
    fontFamily: 'PretendardJP-SemiBold',
    fontWeight: 600,
  },
  pretendard700: {
    fontFamily: 'PretendardJP-Bold',
    fontWeight: 700,
  },
  pretendard800: {
    fontFamily: 'PretendardJP-ExtraBold',
    fontWeight: 800,
  },
  pretendard900: {
    fontFamily: 'PretendardJP-Black',
    fontWeight: 900,
  },
  appleSDGothicNeoLight: {
    fontFamily: 'AppleSDGothicNeo-Light',
    fontWeight: '300',
    ...textCommon,
  },
  appleSDGothicNeoRegular: {
    fontFamily: 'AppleSDGothicNeo-Regular',
    fontWeight: '400',
    ...textCommon,
  },
  appleSDGothicNeoMedium: {
    fontFamily: 'AppleSDGothicNeo-Medium',
    fontWeight: '500',
    ...textCommon,
  },
  appleSDGothicNeoSemiBold: {
    fontFamily: 'AppleSDGothicNeo-SemiBold',
    fontWeight: '600',
    ...textCommon,
  },
  appleSDGothicNeoBold: {
    fontFamily: 'AppleSDGothicNeo-Bold',
    fontWeight: '700',
    ...textCommon,
  },
  appleSDGothicNeoExtraBold: {
    fontFamily: 'AppleSDGothicNeo-Bold',
    fontWeight: '800',
    ...textCommon,
  },
  SFUITextRegular: {
    fontFamily: 'System',
    fontWeight: '400',
    ...textCommon,
  },
  SFUITextMedium: {
    fontFamily: 'System',
    fontWeight: '500',
    ...textCommon,
  },
  SFUITextSemiBold: {
    fontFamily: 'System',
    fontWeight: '600',
    ...textCommon,
  },
  SFUITextBold: {
    fontFamily: 'System',
    fontWeight: '700',
    ...textCommon,
  },
  SFUITextHeavy: {
    fontFamily: 'System',
    fontWeight: '800',
    ...textCommon,
  },
  SFUIDisplayRegular: {
    fontFamily: 'System',
    fontWeight: '400',
    ...textCommon,
  },
  SFUIDisplaySemiBold: {
    fontFamily: 'System',
    fontWeight: '600',
    ...textCommon,
  },
  SFUIDisplayBold: {
    fontFamily: 'System',
    fontWeight: '700',
    ...textCommon,
  },
  pretendardVariable: {
    fontFamily: 'Pretendard Variable',
    ...textCommon,
  },
  fontSize10: {
    fontSize: 10,
  },
  fontSize11: {
    fontSize: 11,
  },
  fontSize12: {
    fontSize: 12,
  },
  fontSize13: {
    fontSize: 13,
  },
  fontSize14: {
    fontSize: 14,
  },
  fontSize15: {
    fontSize: 15,
  },
  fontSize16: {
    fontSize: 16,
  },
  fontSize17: {
    fontSize: 17,
  },
  fontSize18: {
    fontSize: 18,
  },
  fontSize19: {
    fontSize: 19,
  },
  fontSize20: {
    fontSize: 20,
  },
};


const specificStyles = {
  appleSDGothicNeoLight15: {
    fontSize: 15,
    ...baseStyles.appleSDGothicNeoLight,
  },
  appleSDGothicNeoRegular12: {
    fontSize: 12,
    ...baseStyles.appleSDGothicNeoRegular,
  },
  appleSDGothicNeoRegular13: {
    fontSize: 13,
    ...baseStyles.appleSDGothicNeoRegular,
  },
  appleSDGothicNeoRegular14: {
    fontSize: 14,
    ...baseStyles.appleSDGothicNeoRegular,
  },
  appleSDGothicNeoRegular15: {
    fontSize: 15,
    ...baseStyles.appleSDGothicNeoRegular,
  },
  appleSDGothicNeoRegular16: {
    fontSize: 16,
    ...baseStyles.appleSDGothicNeoRegular,
  },
  appleSDGothicNeoSemiBold14: {
    fontSize: 14,
    ...baseStyles.appleSDGothicNeoSemiBold,
  },
  appleSDGothicNeoSemiBold15: {
    fontSize: 15,
    ...baseStyles.appleSDGothicNeoSemiBold,
  },
  appleSDGothicNeoSemiBold16: {
    fontSize: 16,
    ...baseStyles.appleSDGothicNeoSemiBold,
  },
  appleSDGothicNeoSemiBold24: {
    fontSize: 24,
    ...baseStyles.appleSDGothicNeoSemiBold,
  },
  appleSDGothicNeoBold13: {
    fontSize: 13,
    ...baseStyles.appleSDGothicNeoBold,
  },
  appleSDGothicNeoBold14: {
    fontSize: 14,
    ...baseStyles.appleSDGothicNeoBold,
  },
  appleSDGothicNeoBold15: {
    fontSize: 15,
    ...baseStyles.appleSDGothicNeoBold,
  },
  appleSDGothicNeoBold18: {
    fontSize: 18,
    ...baseStyles.appleSDGothicNeoBold,
  },
  appleSDGothicNeoBold24: {
    fontSize: 24,
    ...baseStyles.appleSDGothicNeoBold,
  },
  appleSDGothicNeoBold20: {
    fontSize: 20,
    ...baseStyles.appleSDGothicNeoBold,
  },
  appleSDGothicNeoExtraBold15: {
    fontSize: 15,
    ...baseStyles.appleSDGothicNeoExtraBold,
  },
  appleSDGothicNeoExtraBold20: {
    fontSize: 20,
    ...baseStyles.appleSDGothicNeoExtraBold,
  },
  SFUITextRegular14: {
    fontSize: 14,
    ...baseStyles.SFUITextRegular,
  },
  SFUITextRegular16: {
    fontSize: 16,
    ...baseStyles.SFUITextRegular,
  },
  SFUITextSemiBold10: {
    fontSize: 10,
    ...baseStyles.SFUITextSemiBold,
  },
  SFUITextSemiBold14: {
    fontSize: 14,
    ...baseStyles.SFUITextSemiBold,
  },
  SFUITextSemiBold15: {
    fontSize: 15,
    ...baseStyles.SFUITextSemiBold,
  },
  SFUITextSemiBold16: {
    fontSize: 16,
    ...baseStyles.SFUITextSemiBold,
  },
  SFUITextSemiBold18: {
    fontSize: 18,
    ...baseStyles.SFUITextSemiBold,
  },
  SFUITextSemiBold24: {
    fontSize: 24,
    ...baseStyles.SFUITextSemiBold,
  },
};


const styles = {
  ...baseStyles,
  ...specificStyles,
};

export const reactNativeStyles = StyleSheet.create(styles);

export default styles;
