/* eslint-disable no-console */
import React from 'react';
import {
  TouchableHighlight, StyleSheet, Text, View,
  Animated,
} from 'react-native';
import { connect } from 'react-redux';
import { actions } from 'shared';


import { Button } from '../../../components-v2';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  Button as DialogButton,
} from '@material-ui/core';

import { ArrowRightIcon } from '../../../icons-v2';

import {
  Colors, Styles, Constants,
} from '../../../constants';

import config from '../../../config';
import { getOrderCount } from '../../../utils';

import LoginGuideSection from './LoginGuideSection';
import UserInfoSection from './UserInfoSection';
import OrderInfoSection from './OrderInfoSection';
import CustomerSupportSection from './CustomerSupportSection';
import { initAlert, renderAlert, showAlert } from '../../../components/AlertDialog';
import { moveToMemberInfo } from '../MemberInfoPage';


const styleHtml = {
  container: {
    height: '100vh',
    width: '100vw',
    justifyContent: 'flex-start',
  },
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: Constants.innerViewBottomSpace,
  },
  scrollView: {
    flex: 1,
  },
  menuButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'stretch',
    backgroundColor: '#fff',
  },
  menuButtonContent: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingStart: Constants.viewHorizontalPadding,
    paddingEnd: 24,
    paddingTop: 25,
    paddingBottom: 24,
  },
  menuButtonText: {
    color: '#000',
    height: 19,
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard400,
  },
  menuButtonSubText: {
    color: '#000',
    lineHeight: 16.8,
    fontSize: 14,
    opacity: 0.5,
    paddingVertical: 1,
    ...Styles.pretendard400,
  },
  menuButtonChevronRightContainer:{
    paddingTop: 27 - 25,
    paddingBottom: 27 - 24,
  },
  versionStatusText: {
  },
  logoutButtonContainer: {
    flex: 1,
    marginHorizontal: Constants.viewHorizontalPadding,
    marginTop: 20,
    marginBottom: 40,
  },
  logoutButton: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000000',
    paddingVertical: 10.5,
  },
  logoutButtonText: {
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
    color: '#ffffff',
    ...Styles.pretendard600,
  },
  modalView: {
    justifyContent: 'flex-start',
    margin: 0,
  },
  modalTextContainer: {
    height: 99,
    paddingTop: 40,
    paddingBottom: 40,
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  modalText: {
    fontSize: 16,
    lineHeight: 19.2,
    color: '#000',
    ...Styles.pretendard400,
  },
  itemSeperator: {
    backgroundColor: '#CFCFCF',
    height: 0.5,
    marginHorizontal: Constants.viewHorizontalPadding,
  },
});


export class SettingsPage extends React.Component {
  scrollViewRef = React.createRef();

  constructor(props) {
    super(props);

    global.settingsPageRef = this;

    this.state = {
      isResultModalVisible: true,
      showJoinDoneDialog: false,
    };

    this.menuButton = this.menuButton.bind(this);
    this.moveToLoginPage = this.moveToLoginPage.bind(this);

    initAlert(this);
    this.showAlert = showAlert(this);
  }

  componentDidMount() {
    const {
      location: { search: query },
      setModalProps,
      logEventPageview,
      moveFrom,
      router,
    } = this.props;

    if (query.includes("withdraw=true")) {
      this.showWithdrawResultModal();
      window.setTimeout(() => {
        setModalProps({
          isVisible: false,
        });
      }, 5000);
    }
    this.bootstrapAsync();

    logEventPageview({ page_name: 'Settings' });

    const hasShowenJoineDoneCouponeAlert = sessionStorage.getItem('hasShowenJoineDoneCouponeAlert') || false;
    if (moveFrom == 'JoinDone' && !hasShowenJoineDoneCouponeAlert) {
      sessionStorage.setItem('hasShowenJoineDoneCouponeAlert', 'true');
      /*
      this.showAlert('가입 완료! 15% 할인쿠폰이\n발급되었습니다.', {
        buttons: [
          {
            text: '닫기',
          },
          {
            text: '쿠폰 확인하기',
            onPress: () => {
              moveToMemberInfo(router, 'coupon');
            }
          }
        ],
        titleStyle: {
          display : 'inline-block',
          whiteSpace: 'pre-wrap',
          textAlign: 'center',
        },
      });
      */
    }
  }

  componentDidUpdate() {
  }

  componentWillUnmount() {
  }

  bootstrapAsync = async () => {
    const {
      auth,
      getUserSummary,
      getUserCouponList,
      getUserMileageList,
      loadCurrentUser,
    } = this.props;

    if (this.isLoggedIn()) {
      try {
        await loadCurrentUser(auth.token);
        await getUserCouponList(false);
        await getUserMileageList(false);
        await getUserSummary();
      } catch (err) {
        console.error(err);
      }
    }
  }

  moveToLoginPage = () => {
    const { router } = this.props;

    router.push({
      pathname: '/accounts/login',
    });
  }

  moveToJoinPage = () => {
    const { navigation } = this.props;
    navigation.navigate('Auth');
  }

  scrollToTop() {
    this.scrollViewRef.scrollTo({ x: 0, y: 0, animated: true });
  }

  isLoggedIn(props = null) {
    if (props === null) {
      const { auth } = this.props;
      // eslint-disable-next-line no-prototype-builtins
      return auth.hasOwnProperty('_id');
    }
    // eslint-disable-next-line no-prototype-builtins
    return props.auth.hasOwnProperty('_id');
  }

  menuButton = (label, onPress, props) => {
    const loggedIn = this.isLoggedIn();

    const {
      buttonHideWithoutLogin,
      loginOnlyMenu,
      isEnd,
      subText,
      buttonStyle,
    } = props;

    if (buttonHideWithoutLogin && !loggedIn) {
      return null;
    }

    let onPressButton = null;
    if (onPress !== null) {
      onPressButton = () => {
        if (!loggedIn && loginOnlyMenu) {
          this.moveToLoginPage();
          return;
        }
        if (onPress) {
          onPress();
        }
      };
    }

    let endStyle = {};
    if (isEnd) {
      endStyle = { marginBottom: 20 };
    }

    const menuButtonContentStyles = [styles.menuButtonContent];
    if (buttonStyle) {
      menuButtonContentStyles.push(buttonStyle);
    }

    return (
      <TouchableHighlight
        style={[styles.menuButton, endStyle]}
        underlayColor={Colors.touchEffectColorWhite}
        onPress={onPressButton}
        disabled={!loggedIn && loginOnlyMenu}
      >
        <View style={menuButtonContentStyles}>
          {typeof label === 'function' ? label() : (
            <Text style={[
              styles.menuButtonText,
              !loggedIn && styles.menuButtonTextInactive,
            ]}
            >
              {label}
            </Text>
          )}
          {typeof label === 'function' ?  (
            <Text style={[styles.menuButtonSubText, styles.versionStatusText]}>{subText}</Text>
          ) : (
            <View style={{alignItems: 'center', flexDirection: 'row', marginEnd: 4}}>
              <Text style={[styles.menuButtonSubText, {marginEnd: 20}]}>{subText}</Text>
              <View style={styles.menuButtonChevronRightContainer}>
                <ArrowRightIcon />
              </View>
            </View>
          )}
        </View>
      </TouchableHighlight>
    );
  }


  reset = async () => {
    const { logout } = this.props;
    // OneSignal.removeExternalUserId();
    if (global.rollbar) {
      global.rollbar.clearPerson();
    }

    logout();
    this.setState({ logoutDialogOpen: false });

    // Logout from godomall and return
    const { protocol } = window.location;
    const { host } = window.location;
    const returnUrl = `${protocol}//${host}`;
    const godoMobileBaseUrl = config.get('godomallMobileBaseUrl');
    const godoLogoutUrl = `${godoMobileBaseUrl}heartit_api/user_logout.php`
      + '?noMessage=y'
      + `&returnUrl=${encodeURIComponent(returnUrl)}`;
    window.location.href = godoLogoutUrl;
  }

  renderLogoutDialog() {
    const { logoutDialogOpen } = this.state;

    return (
      <Dialog
        open={logoutDialogOpen}
        onClose={() => this.setState({ logoutDialogOpen: false })}
        aria-labelledby="logout-alert-dialog-title"
        aria-describedby="logout-alert-dialog-description"
      >
        <DialogTitle id="logout-alert-dialog-title">
          <Text style={styles.dialogTitleText}>로그아웃</Text>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-alert-dialogdescription">
            <Text style={styles.dialogContentText}>
              로그아웃하시겠습니까?
            </Text>
          </DialogContentText>
          <DialogActions>
            <DialogButton onClick={() => this.setState({ logoutDialogOpen: false })} color="primary">
              취소
            </DialogButton>
            <DialogButton onClick={this.reset}>
              로그아웃
            </DialogButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }

  getOrderCount() {
    const { auth: { summary } } = this.props;
    if (this.isLoggedIn() && summary) {
      return getOrderCount(summary);
    }

    return {
      orderConfirm: 0,
      shippingPending: 0,
      shipping: 0,
      delivered: 0,
      cancel: 0,
    };
  }

  showWithdrawResultModal = () => {
    const { setModalProps } = this.props;
    setModalProps({
      isVisible: true,
      children: this.renderWithdrawResultModal()
    });
  }

  renderWithdrawResultModal = () => {
    const { setModalProps } = this.props;

    const onClick = () => {
      setModalProps({isVisible: false});
    };

    return (
      <div style={styleHtml.container} onClick={onClick}>
        <div onClick={e => e.stopPropagation()}>
          <View style={styles.modalView}>
            <View style={styles.modalTextContainer}>
              <Text style={styles.modalText}>탈퇴가 완료되었습니다.</Text>
            </View>
          </View>
        </div>
      </div>
    );
  }

  renderGuestMenu() {
    return (
      <LoginGuideSection
      onPressLogin={this.moveToLoginPage}
      />
    );
  }

  renderMemberMenu() {
    const { router, auth } = this.props;
    const username = auth.name || auth.username;

    const size = auth.mySize && auth.mySize.clothing.length > 0 ? auth.mySize.clothing.join(', ') : '선택해주세요';
    const footSize = auth.mySize && auth.mySize.shoes.length > 0 ? auth.mySize.shoes.join(', ') : '선택해주세요';
    const orderCount = this.getOrderCount();

    return (
      <React.Fragment>
        <View style={styles.itemSeperator} />

        <UserInfoSection router={router} />

        {this.menuButton(
          '내 옷 사이즈',
          () => {
            router.push(`/accounts/mysize/clothing`);
          },
          {
            buttonHideWithoutLogin: true,
            loginOnlyMenu: true,
            subText: (size) ? size: "선택필요",
          }
        )}

        {this.menuButton(
          '내 신발 사이즈',
          () => {
            router.push(`/accounts/mysize/shoes`);
          },
          {
            buttonHideWithoutLogin: true,
            loginOnlyMenu: true,
            subText: (footSize) ? footSize: "선택필요",
          }
        )}

        <View style={{ height: 40 }} />
        <View style={styles.itemSeperator} />

        <OrderInfoSection
          orderCount={orderCount}
          menuButton={this.menuButton}
          router={router}
          />

        {this.menuButton(
          '내가 쓴 리뷰 보기',
          () => router.push('/accounts/reviews'),
          {
            buttonHideWithoutLogin: true,
            loginOnlyMenu: true,
          }
        )}
      </React.Fragment>
    );
  }

  render() {
    const { router } = this.props;

    return (
      <View style={styles.container}>
        <Animated.ScrollView
          ref={ref => { this.scrollViewRef = ref; }}
          style={styles.scrollView}
          {...Constants.scrollViewCommonProps}
          bounces={false}
          {...this.scrollViewProps}
        >
          {!this.isLoggedIn() ? (
            this.renderGuestMenu()
          ) : (
            this.renderMemberMenu()
          )}

          <View style={{ height: 40 }} />
          <View style={styles.itemSeperator} />

          <CustomerSupportSection
            menuButton={this.menuButton}
            router={router}
            />

          {this.isLoggedIn() && (
            <View style={styles.logoutButtonContainer}>
              <Button
                containerStyle={styles.logoutButton}
                titleStyle={styles.logoutButtonText}
                title="로그아웃"
                onPress={() => { this.setState({ logoutDialogOpen: true }); }}
              />
            </View>
          )}

          {this.renderLogoutDialog()}

        </Animated.ScrollView>
        {renderAlert(this)}
      </View>
    );
  }
}


export function myScrollToTop() {
  if (global.settingsPageRef) {
    global.settingsPageRef.scrollToTop();
  }
}

export function navigateToSettingsAfterJoinDone(router) {
  if (!router) {
    return;
  }

  router.push({
    pathname: '/accounts/settings',
    state: { moveFrom: 'JoinDone' },
  });
}

export default connect(
  (state, { router }) => {
    let moveFrom = null;

    if (router.location.hasOwnProperty('state')
        && router.location.state
        && router.location.state.hasOwnProperty('moveFrom')) {
      moveFrom = router.location.state.moveFrom;
    }

    return {
      auth: state.auth,
      moveFrom,
    };
  },
  actions,
)(SettingsPage);
