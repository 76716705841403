import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { TouchableHighlight, StyleSheet, Text, View } from 'react-native';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';


const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    paddingBottom: 20,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    textTransform: 'uppercase',
    color: 'black',
    letterSpacing: 0,
    lineHeight: 24,
    fontSize: 20,
    ...Styles.SFUITextBold,
  },
  buttonSeeAllContainer: {
  },
  buttonSeeAll: {
    color: 'black',
    lineHeight: 16,
    fontSize: 14,
    ...Styles.SFUITextSemiBold,
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});


export default ({ title, showSeeAll, onPressSeeAll }) => (
  <View style={styles.container}>
    <Text style={styles.title}>
      {title}
    </Text>
    {showSeeAll && (
      <TouchableHighlight
        underlayColor={Colors.touchEffectColorWhite}
        style={[styles.buttonSeeAllContainer, styles.touchEffect, styles.touchEffectContainer]}
        onPress={() => onPressSeeAll()}
      >
        <Text style={styles.buttonSeeAll}>
          SEE ALL
        </Text>
      </TouchableHighlight>
    )}
  </View>
);
