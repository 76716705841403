import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import qs from 'qs';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';

import config from '../../config';
import Styles from '../../constants/Styles';

const styles = {
  container: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    display: 'table',
  },
  message: {
    textAlign: 'center',
    fontSize: 24,
    display: 'table-cell',
    verticalAlign: 'middle',
    paddingBottom: 150,
    ...Styles.appleSDGothicNeoBold,
  },
};


class CheckoutNaverPayWithoutAuthGatewayPage extends React.Component {
  constructor(props) {
    super(props);
    const { setGlobalLayoutInfo } = props;
    setGlobalLayoutInfo('showProgress', true);
  }

  componentDidMount() {
    const { logEventPageview } = this.props;

    document.getElementById('naverPayForm').submit();

    logEventPageview({ page_name: 'CheckoutNaverPayGatewayWithoutAuth' });
  }

  render() {
    const {
      productId,
      itemCount,
      selectedOptionId,
    } = this.props;

    const path = `/heartit_api/order_naver_pay_web_without_auth.php?goodsNo=${productId}`;
    const godomallMobileBaseUrl = config.get('godomallMobileBaseUrl');

    const targetUrl = `${godomallMobileBaseUrl}${path}`;

    const { protocol } = window.location;
    const { host } = window.location;
    const returnUrl = `${protocol}//${host}/shop/checkoutFinish/`;

    return (
      <div style={styles.container}>
        <form
          id="naverPayForm"
          method="POST"
          action={targetUrl}
        >
          <input
            type="hidden"
            id="count"
            name="count"
            value={itemCount}
            readOnly
          />
          <input
            type="hidden"
            id="optionSno"
            name="optionSno"
            value={selectedOptionId}
            readOnly
          />
          <input
            type="hidden"
            id="returnUrl"
            name="returnUrl"
            value={returnUrl}
            readOnly
          />
        </form>
      </div>
    );
  }
}

export function navigateToCheckoutNaverPayWithoutAuthGateway({
  productId,
  itemCount,
  selectedOptionId,
  newWindow,
  holdPage=false,
}) {
  const query = { productId, itemCount, selectedOptionId, holdPage };

  const path = `/shop/checkoutNaverPayNoAuth/${qs.stringify(query)}`;
  if (newWindow) {
    newWindow.location = path;
  } else {
    window.open(path);
  }

  if (!holdPage) {
    browserHistory.push('/shop');
  }
}


export default connect(
  (state, props) => {
    const { params: { query } } = props;
    const queryObject = qs.parse(query);
    const {
      productId,
      itemCount,
      selectedOptionId,
    } = queryObject;

    return {
      productId,
      itemCount,
      selectedOptionId,
    };
  },
  actions,
)(CheckoutNaverPayWithoutAuthGatewayPage);
