import { camelCase } from 'lodash';
import React from 'react';
import { StyleSheet, View, ScrollView, Image, Text, TextInput, Switch } from 'react-native';
import { connect } from 'react-redux';
import { actions } from 'shared';
import { isEmail } from 'validator';
import { Snackbar } from '@material-ui/core';

import Button from '../nativeComponents/Button';
import Colors from '../constants/Colors';

export class LoginWithEmailScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      snackbarMsg: '',
      snackbarOpen: false,
    };
  }

  componentDidMount() {
    const { auth, router } = this.props;
    if (auth && auth.token) {
      router.push('/');
    }
  }

  async handleLogin() {
    const { router, loginWithEmailAccount, loadCurrentUser } = this.props;
    const { email, password } = this.state;

    try {
      const userData = await loginWithEmailAccount(email, password);
      await loadCurrentUser(userData.token);
      router.push('/');
    } catch (e) {
      if (e.status === 403) {
        router.push({
          pathname: '/accounts/login/email/verify',
          state: { email },
        });
        return;
      }

      let msg;
      if (e.status === 400) {
        msg = '이메일 또는 비밀번호가 잘못되었습니다.';
      } else if (e.status === 401) {
        msg = '이메일 또는 비밀번호가 잘못되었습니다.';
      }
      this.showSnackbar(msg);
    }
  }

  showSnackbar(msg) {
    this.setState({
      snackbarMsg: msg,
      snackbarOpen: true,
    });
  }

  createInputCheckMsg() {
    const { email, password } = this.state;
    let msg;
    if (!email || email === '') {
      msg = '이메일을 입력하세요.';
    } else if (!isEmail(email)) {
      msg = '이메일 형식을 확인하세요.';
    } else if (!password || password === '') {
      msg = '비밀번호를 입력하세요.';
    }
    return msg;
  }

  async handleAll() {
    const { email, password } = this.state;
    const msg = await this.createInputCheckMsg();
    if (msg) {
      this.showSnackbar(msg);
    }
    if (email && isEmail(email) && password) {
      this.handleLogin();
    }
  }

  render() {
    const { router } = this.props;
    const { email, password } = this.state;

    return (
      <View style={styles.container}>
        <ScrollView
          contentContainerStyle={styles.inputContainer}
          keyboardShouldPersistTaps="handled"
          scrollEnabled={false}
        >
          <Text style={styles.loginTitleText}>Login</Text>
          <TextInput
            autoCapitalize="none"
            keyboardType="email-address"
            onChangeText={val => this.setState({ email: val })}
            placeholder="이메일 / EMAIL"
            style={[
              styles.inputBorder,
              styles.inputText,
              email && !isEmail(email) ? styles.emailError : null,
            ]}
            value={email}
            autoFocus={true}
          />
          <TextInput
            autoCapitalize="none"
            keyboardType="default"
            onChangeText={val => this.setState({ password: val })}
            placeholder="비밀번호 / PASSWORD"
            style={[
              styles.inputBorder,
              styles.inputText,
            ]}
            secureTextEntry={true}
            value={password}
          />
          <Button
            containerStyle={
              email && password ? styles.loginButton: styles.disabled
            }
            onPress={() => this.handleAll()}
            title="로그인 / LOGIN"
            titleStyle={styles.loginText}
          />
          <Button
            containerStyle={ styles.joinButton }
            onPress={() => router.push('/accounts/join/email')}
            title="회원가입 / REGISTER"
          />
          <Button
            containerStyle={ styles.findPasswordButton }
            onPress={() => router.push('/accounts/login/email/findPassword')}
            title="비밀번호 찾기 / FIND PASSWORD"
          />
        </ScrollView>
        <Snackbar
          autoHideDuration={2000}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
          style={{ textAlign: 'center' }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    flex: 1,
    justifyContent: 'space-between',
    margin: 25,
  },
  disabled: {
    backgroundColor: '#cccccc',
    borderColor: 'lightgrey',
    marginTop: 15,
    width: '100%',
  },
  inputContainer: {
    alignItems: 'center',
  },
  loginTitleText: {
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 20,
  },
  inputText: {
    fontSize: 13,
    padding: 10,
  },
  inputBorder: {
    borderColor: 'lightgrey',
    borderWidth: 1,
    marginTop: 12,
    marginBottom: 12,
    width: '100%',
  },
  loginButton: {
    backgroundColor: 'black',
    marginTop: 15,
    width: '100%',
  },
  joinButton: {
    marginTop: 15,
    width: '100%',
  },
  findPasswordButton: {
    borderWidth: 0,
    marginTop: 15,
    padding: 5,
    width: '100%',
  },
  loginText: {
    color: 'white',
  },
  emailError: {
    borderColor: 'red',
  },
});

export default connect(
  state => ({ auth: state.auth }),
  actions,
)(LoginWithEmailScreen);
