import React from 'react';
import PropTypes from 'prop-types';
import {
  View, Text, StyleSheet, TouchableHighlight,
} from 'react-native';
import NumberFormat from 'react-number-format';
import VisibilitySensor from 'react-visibility-sensor';
import { connect } from 'react-redux';
import { actions, loadEntities } from 'shared';

import {
  getThumbnailBackgroundColor
} from '../../../shared/util';
import { getProductId, getProductInfo, isHeartitProduct } from 'shared/util/product';

import { CloudinaryImage, Image } from '../components-v2';
import LikeButton from './LikeButton';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';
import Constants from '../constants/Constants';


const stylesHtml = {
  itemImage: {
    flex: 1,
    objectFit: 'cover',
  },
}

const styles = StyleSheet.create({
  container: {
  },
  itemImageContainer: {
    backgroundColor: Colors.semiGray,
  },
  button: {
    ...Styles.touchEffect,
    width: 24 + Constants.touchEffectWidth * 2,
    height: 24 + Constants.touchEffectWidth * 2,
  },
  smallButton: {
    ...Styles.touchEffect,
    width: 18 + Constants.touchEffectWidth * 2,
    height: 18 + Constants.touchEffectWidth * 2,
  },
  itemLikeButton: {
    position: 'absolute',
    end: 4,
    bottom: 4.21,
  },
  textContainer: {
    marginTop: 8,
  },
  textContainerSmall: {
    marginTop: 10,
    alignItems: 'flex-start',
  },
  itemBrandText: {
    fontSize: 12,
    lineHeight: 14.4,
    ...Styles.pretendard600,
    height: 14,
  },
  itemTitleText: {
    marginTop: 2,
    fontSize: 12,
    lineHeight: 14.4,
    ...Styles.pretendard400,
    height: 14 + 2,
  },
  productSellPrice: {
    fontSize: 12,
    lineHeight: 14.4,
    ...Styles.pretendard400,
    height: 14 + 2,
  },
  productSellPriceSoldout: {
    opacity: 0.35,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  itemSmallText: {
    fontSize: 11,
    color: '#000',
    height: 13,
    lineHeight: 13.2,
  },
  itemBrandTextSmall: {
    ...Styles.pretendard600,
    height: 15,
    marginBottom: 0,
  },
  itemTitleTextSmall: {
    ...Styles.pretendard400,
    height: 15,
    marginBottom: 0,
  },
  productSellPriceSmall: {
    ...Styles.pretendard400,
  },
  priceContainer: {
    flex: 1,
    flexDirection: 'row',
    height: 14,
  },
  colorHidden: {
    color: '#0000',
  },
  colorGray: {
    color: 'gray',
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 6,
  },
  labelContainerSmall: {
    marginBottom: 5,
  },
  discountRate: {
    ...Styles.pretendard600,
    fontSize: 12,
    lineHeight: 14.4,
    color: 'rgb(255, 0, 0)',
    marginStart: 4,
    height: 14,
  },
  discountRateSmall: {
    marginStart: 3,
    marginBottom: 3,
    height: 12,
    lineHeight: 12,
    fontSize: 10,
  },
  tagContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: 74,
  },
  tag: {
    color: 'black',
    ...Styles.pretendard400,
    fontSize: 11,
    lineHeight: 13.2,
    borderColor: 'black',
    borderWidth: 0.5,
    marginTop: 6,
    paddingHorizontal: 6,
    paddingVertical: 4,
    height: 13 + 4 * 2 + 1 * 2,
  },
});

const DEBUG_PRODUCT_IDS = [];


class Product extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    const { product, partnerShippingProducts } = nextProps;
    const { product: prevProduct, partnerShippingProducts: prevPartnerShippingProducts } = this.props;

    const productId = getProductId(product);
    if (DEBUG_PRODUCT_IDS.includes(productId)) {
      console.log(`Product(${productId}).shouldComponentUpdate product=`, product);
    }

    if (product.goodsNo !== prevProduct.goodsNo) {
      if (DEBUG_PRODUCT_IDS.includes(productId)) {
        console.log(`Product(${productId}).shouldComponentUpdate return true`);
      }
      return true;
    }

    if (product.liked !== prevProduct.liked) {
      if (DEBUG_PRODUCT_IDS.includes(productId)) {
        console.log(`Product(${productId}).shouldComponentUpdate return true`);
      }
      return true;
    }

    let prevPartnerShipping = prevPartnerShippingProducts.filter(p => p.goodsNo == productId);
    prevPartnerShipping = (prevPartnerShipping.length > 0) ? prevPartnerShipping[0]: null;
    let partnerShipping = partnerShippingProducts.filter(p => p.goodsNo == productId);
    partnerShipping = (partnerShipping.length > 0) ? partnerShipping[0]: null;
    if (!!(prevPartnerShipping) != !!(partnerShipping)
        || (prevPartnerShipping && partnerShipping && prevPartnerShipping.heartitBadge != partnerShipping.heartitBadge)) {
      console.log(`Product(${productId}).shouldComponentUpdate return true`);
      return true;
    }

    const prevProductInfo = getProductInfo(prevProduct);
    const productInfo = getProductInfo(product);

    let update = false;
    const keys = Object.keys(productInfo);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      let newVal = productInfo[key];
      let prevVal = prevProductInfo[key];
      if (newVal && typeof newVal !== 'string') {
        newVal = newVal.toString();
      }
      if (prevVal && typeof prevVal !== 'string') {
        prevVal = prevVal.toString();
      }
      if (newVal !== prevVal) {
        update = true;
        break;
      }
    }

    if (DEBUG_PRODUCT_IDS.includes(productId)) {
      console.log(`Product(${productId}).shouldComponentUpdate return `, update);
    }
    return update;
  }

  renderImage() {
    const {
      product, showLikeButton,
      isSmallVersion = false,
      imageContainerStyle,
      onPressLike,
      enableImageFitContain,
    } = this.props;
    const { isSeasonOut, isPreOrder, salesEndDate, timestampNow,
      isShopProduct,
    } = getProductInfo(product);
    
    let {
      post,
    } = this.props;

    const {
      media,
      memo: memoRaw,
      crawlerInfo,
    } = product;
    let { _id } = product;

    let parser = null;
    let memo = {};
    try {
      memo = JSON.parse(memoRaw);
    } catch (e) {
      // Ignore JSON parse error for crawled product metadata
      memo = crawlerInfo ? crawlerInfo : {};
    }
    if (memo) {
      parser = (memo.parser) ? memo.parser : null;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (!post && product.hasOwnProperty('post')) {
      // For histories in home tab
      // eslint-disable-next-line prefer-destructuring
      post = product.post;
    }

    const imageStyle = {
      ...stylesHtml.itemImage
    };
    if (enableImageFitContain) {
      if (isHeartitProduct(product)) {
        imageStyle.objectFit = 'contain';
      } else {
        switch (parser) {
          case '24s':
          case 'farfetch':
          case 'mytheresa':
          case 'cettire':
          case 'mustit':
          case 'ln-cc':
            imageStyle.objectFit = 'contain';
            break;
          default:
            break;
        }
      }
    }

    let imageElement = null;
    if (media !== undefined) {
      imageElement = (
        <CloudinaryImage
          options={{
            crop: 'fit',
            height: 665,
            width: 500,
            quality: 'auto:best',
            fetchFormat: 'auto',
            dpr: 'auto',
          }}
          publicId={media.publicId}
          style={imageStyle}
          resizeMethod='resize'
        />
      );
    }

    if (!imageElement && isShopProduct) {
      imageElement = (
        <Image
          style={imageStyle}
          source={{ uri: product.goodsImageSrc }}
        />
      );
      _id = product.goodsNo;
    }

    let backgroundColor = Colors.semiGray;
    if (parser) {
      backgroundColor = getThumbnailBackgroundColor(parser);
    }

    return (
      <View
        style={[styles.itemImageContainer, imageContainerStyle, {backgroundColor: backgroundColor}]}
      >
        {imageElement}

        {((!isSeasonOut || isShopProduct) && showLikeButton ) && (
        <LikeButton
          post={post}
          item={Object.assign({}, product, { _id })}
          styles={[(isSmallVersion) ? styles.smallButton : styles.button, styles.itemLikeButton]}
          isShopProduct={isShopProduct}
          isSmallVersion={isSmallVersion}
          { ...(onPressLike && {
            onPress: onPressLike,
          })}
        />
        )}
      </View>
    );
  }

  preloadProduct() {
    const { product, loadShopProductWithCache } = this.props;
    const productId = product.goodsNo || product.godoGoodsNo;
    if (!productId) {
      return;
    }
    try{
      loadShopProductWithCache({ productId })
    }catch(e){

    }

  }

  render() {
    let {
      style, containerStyle, onPressProduct, product,
      isSmallVersion,
      textContainerStyle={},
      showTags,
      smallVersionImageTextSpace = 7.07,
      partnerShippingProducts = [],
      numColumns = 1,
    } = this.props;
    let { isShopProduct, isSoldOut } = getProductInfo(product);

    let {
      post,
    } = this.props;

    const {
      retails = null,
    } = product;
    let { _id, name, brand } = product;

    // eslint-disable-next-line no-prototype-builtins
    if (!post && product.hasOwnProperty('post')) {
      // For histories in home tab
      // eslint-disable-next-line prefer-destructuring
      post = product.post;
    }

    if ((!retails || retails.length <= 0) && isShopProduct === false) {
      return null;
    }

    let orgPrice = 0;
    let sellPrice = 0;
    // let link = '';

    if (retails !== null && retails.length > 0) {
      try {
        const {
          orgPrice: { amount: orgPriceAmount },
        } = retails[0];
        orgPrice = orgPriceAmount;
      } catch (e) {
        // console.error(e);
      }

      try {
        const {
          sellPrice: { amount: sellPriceAmount },
        } = retails[0];
        sellPrice = sellPriceAmount;
      } catch (e) {
        // console.error(e);
      }
      // const retail = product.retails[product.defaultRetailIdx];
      // link = retail.orgLink || retail.link;
    }

    if (isShopProduct) {
      orgPrice = Number.parseInt(product.fixedPrice, 10);
      sellPrice = Number.parseInt(product.goodsPrice, 10);
      name = product.goodsNm;
      brand = product.brandNm;
      _id = product.goodsNo;
    }

    const discountRate = orgPrice > sellPrice ? Math.round((1 - sellPrice / orgPrice) * 100) : 0;

    const {
      tags: tagsFromProduct=[]
    } = product;
    const tags = [...tagsFromProduct];

    if (isHeartitProduct(product)) {
      const productId = product.goodsNo || product.godoGoodsNo;
      const partnerShipping = partnerShippingProducts.filter(p => p.goodsNo == productId);
      if (partnerShipping.length > 0 && !(partnerShipping[0].heartitBadge)) {
        // do nothing
      } else {
        tags.push({ name: '하트잇상품' });
      }
    }

    return (
      <View style={{ flex: 1 / numColumns }}>
        <TouchableHighlight
          key={_id}
          underlayColor={Colors.touchEffectColorWhite}
          onPress={onPressProduct}
          style={[Styles.touchEffect, style]}
        >
          <VisibilitySensor onChange={isVisible => (isVisible && this.preloadProduct())}>
          <View
            style={[styles.container, containerStyle]}
          >
            {this.renderImage()}

            <View style={[(isSmallVersion) ? styles.textContainerSmall : styles.textContainer, textContainerStyle, {marginTop: smallVersionImageTextSpace}]}>
              <Text
                numberOfLines={1}
                style={(isSmallVersion) ? [styles.itemSmallText, styles.itemBrandTextSmall] : styles.itemBrandText}
              >
                {brand}
              </Text>
              <Text
                numberOfLines={1}
                style={(isSmallVersion) ? [styles.itemSmallText, styles.itemTitleTextSmall] : styles.itemTitleText}
              >
                {name}
              </Text>

              <View style={styles.priceContainer}>
                <NumberFormat
                  value={sellPrice}
                  displayType="text"
                  thousandSeparator
                  renderText={value => (
                    <Text
                      style={[
                        styles.productSellPrice,
                        isSmallVersion && styles.itemSmallText,
                        isSmallVersion && styles.productSellPriceSmall,
                        (isSoldOut || sellPrice === 0) && styles.productSellPriceSoldout,
                      ]}
                    >
                      {`${value}원`}
                    </Text>
                  )}
                />
                {(!isSmallVersion && discountRate > 0 && !isSoldOut) && (
                  <Text style={[
                    styles.discountRate,
                    isSmallVersion && styles.discountRateSmall,
                  ]}
                  >
                    {`${discountRate}%`}
                  </Text>
                )}
              </View>

              { showTags && (<View style={styles.tagContainer}>
                {tags.map((item, index) => (
                  <Text key={index} style={styles.tag}>{item.name}</Text>
                ))}
              </View>)}
            </View>
          </View>
          </VisibilitySensor>
        </TouchableHighlight>
      </View>
    );
  }
}

Product.propTypes = {
  isShopProduct: PropTypes.bool,
  isSmallVersion: PropTypes.bool,
  showLikeButton: PropTypes.bool,
  enableImageFitContain: PropTypes.bool,
};

Product.defaultProps = {
  isShopProduct: false,
  isSmallVersion: false,
  showLikeButton: false,
  enableImageFitContain: true,
};

export default connect(
  (state) => ({
    ...loadEntities(state, 'partnerShippingProducts', 'partnerShippingProducts'),
  }),
  actions,
)(Product);
