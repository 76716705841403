import React from 'react'

import FilterBrandModal from "./FilterBrandModal";


function showFilterBrandModal(parent, brandList, selectedBrandList, onChange) {
  const {
    modalProps,
    setModalProps,
  } = parent.props;

  // console.log(`showFilterBrandModal brandList=${JSON.stringify(brandList)}`);
  // console.log(`showFilterBrandModal selectedBrandList=${JSON.stringify(selectedBrandList)}`);
  // console.log(`showFilterBrandModal modalProps=${JSON.stringify(modalProps())}`);

  const {
    children,
    historyStack=[],
  } = modalProps();

  const newChildren = (
    <FilterBrandModal
      brandList={brandList}
      selectedBrandList={selectedBrandList}
      onChange={onChange}
      setModalProps={setModalProps}
      modalProps={modalProps}
      />
  );

  historyStack.push(children);

  setModalProps({
    children: newChildren,
    historyStack,
  });
}


export default showFilterBrandModal;
