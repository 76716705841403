import React from 'react'
import { connect } from 'react-redux';

import { 
  StyleSheet, View,
} from 'react-native';

import { actions } from 'shared';

import { ListWithCheckBox } from '../../..';

import FilterBaseModal from '../FilterBaseModal';


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  listContainer: {
  },
  itemContainerStyle: {
    marginTop: 25,
    marginBottom: 24,
  },
});



class FilterColorModal extends FilterBaseModal {
  title = '색상';

  state = {
    selectedColorList: [],
    colorList: [],
  };

  onChangeSelectedColorList = (selectedColorList) => {
    // console.log(`selectedColorList=${selectedColorList}`);
    this.setState({
      selectedColorList,
    })
  };

  componentDidMount() {
    const {
      selectedColorList = [],
      loadShopColorList,
      logEventPageview,
    } = this.props;

    loadShopColorList()
      .then(result => {
        const colorList = result.data.map(d => ({
          id: d.itemCd,
          type: 'color',
          name: d.itemNm.split('^|^')[0],
          code: d.itemNm.split('^|^')[1],
        }));
        this.setState({ colorList });
      });

    this.setState({ selectedColorList });

    logEventPageview({ page_name: 'FilterColor' });
  }

  onPressApplyButton() {
    const { onChange } = this.props;
    const { selectedColorList } = this.state;

    const {
      modalProps,
      setModalProps,
    } = this.props;

    const {
      historyStack=[],
    } = modalProps();

    const children = historyStack.pop();

    setModalProps({
      children,
      historyStack,
    }, () => {
      onChange(selectedColorList);
    });
  }


  renderBody() {
    const {
      selectedColorList,
      colorList,
    } = this.state;

    return (
      <View style={styles.container}>
        <ListWithCheckBox
          onChangeSelectedItemList={this.onChangeSelectedColorList}
          selectedItemList={selectedColorList}
          itemList={colorList}
          ItemContainerStyle={styles.itemContainerStyle}
          containerStyle={styles.listContainer}
          />
      </View>
    )
  }
}


export default connect(
  state => ({ auth: state.auth }),
  actions,
)(FilterColorModal);
