import React from 'react'
import PropTypes from 'prop-types';
import { StyleSheet, View, Text } from 'react-native';

import { Styles, Constants } from '../../constants';

import CloudinaryImage from '../CloudinaryImage';
import AddToWishlist from '../AddToWishlist';
import SizeGuideModal from '../SizeGuideModal';
import Image from '../Image';


const styleHtml = {
  container: {
    height: '100vh',
    height: '-webkit-fill-available',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'flex-start',
  },
  itemImage: {
  },
};

const styles = StyleSheet.create({

  resultContentContainer: {
    paddingTop: 19,
    paddingBottom: 20,
    paddingHorizontal: Constants.viewHorizontalPadding,
    backgroundColor: '#ffffff',
  },
  resultContentImage: {
    width: 64 - 1,
    height: 85 - 1,
    aspectRatio: (64 - 1)/(85 - 1),
    borderWidth: 0.5,
    borderColor: '#CFCFCF',
  },
  resultContentDetailContainer: {
    justifyContent: 'flex-end',
    position: 'absolute',
    start: 63 + 15,
    top: 0,
    bottom: 0,
    end: 0,
  },
  resultContentDescriptionText: {
    ...Styles.pretendard400,
    fontSize: 13,
    lineHeight: 15.6,
    position: 'absolute',
    top: 0,
    start: 0,
    end: 0,
  },
  resultContentBrandText: {
    ...Styles.pretendard600,
    fontSize: 14,
    lineHeight: 17,
  },
  resultContentName: {
    ...Styles.pretendard400,
    fontSize: 14,
    lineHeight: 17,
    marginTop: 2,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  resultContentOption: {
    ...Styles.pretendard400,
    fontSize: 14,
    lineHeight: 17,
    marginTop: 2,
  },
});


class WishlistControl extends React.Component {

  state = {
  };

  getModalState() {
    const {
      modalProps,
    } = this.props;

    const {
      wishlistControlState = {
        mode: 'addToWishlist',
      },
    } = modalProps();

    // console.log(`filterMainModalState=${JSON.stringify(filterMainModalState)}`);

    return wishlistControlState;
  }

  setModalState(newState, callback) {
    // console.log(`setModalState newState=${JSON.stringify(newState)}`);
    const wishlistControlState = this.getModalState();

    const {
      setModalProps,
    } = this.props;

    setModalProps({
      wishlistControlState: {
        ...wishlistControlState,
        ...newState,
      },
    }, () => {
      callback && callback();
      const { updateToggle } = this.state;
      this.setState({ updateToggle: !updateToggle });
    });
  }

  resetModalState(callback) {
    const {
      setModalProps,
    } = this.props;

    setModalProps({
      wishlistControlState: undefined,
    }, () => {
      callback && callback();
      const { updateToggle } = this.state;
      this.setState({ updateToggle: !updateToggle });
    });
  }

  componentDidMount () {
    const { mode } = this.props;
    this.setModalState({ mode });
  }

  componentWillUnmount () {
    // console.log(`WishlistControl componentWillUnmount`);
  }

  renderResultContentImage() {

    const {
      product: {
        media,
        goodsImageURL,
        goodsImageSrc,
      }
    } = this.props;

    let imageElement = null;
    // console.log(`product=${JSON.stringify(this.props.product)}`);
    // console.log(`media=${media}`);

    if (media !== undefined) {
      imageElement = (
        <CloudinaryImage
          options={{
            crop: 'limit',
            height: 300,
            width: 300,
          }}
          publicId={media.publicId}
          style={styles.itemImage}
        />
      );
    }

    if (goodsImageURL) {
      // const goodsImageSrc = imagesDetail[0];
      // console.log(`goodsImageSrc=${goodsImageSrc}`);
      imageElement = (
        <Image
          style={styles.itemImage}
          source={{ uri: goodsImageURL }}
        />
      );
    }

    if (goodsImageSrc) {
      imageElement = (
        <Image
          style={styles.itemImage}
          source={{ uri: goodsImageSrc }}
        />
      );
    }

    return (
      <View style={styles.resultContentImage}>
        {imageElement}
      </View>
    );
  }

  renderResultContent() {
    const {
      product,
      selectedItemList: selectedItemListFromProps,
      setModalProps,
    } = this.props;

    const {
      selectedItemList = selectedItemListFromProps,
    } = this.getModalState();

    if (!product) {
      return <View />;
    }

    const {
      brandNm: brand,
      goodsNm: name,
    } = product;
    // console.log(`renderResultContent selectedItemList=${JSON.stringify(selectedItemList)}`);
    // console.log(`product=${JSON.stringify(product)}`);

    const option = selectedItemList.length > 0 ? selectedItemList.map((item) => item.name).join(', ') : '단일 옵션';

    const onClick = () => {
      this.resetModalState(() => {
        setModalProps({ isVisible: false });
      });
    };

    return (
      <div style={styleHtml.container} onClick={onClick}>
        <div onClick={e => e.stopPropagation()}>
          <View style={styles.resultContentContainer}>
            <View>
              {this.renderResultContentImage()}
              <View style={styles.resultContentDetailContainer} >
                <Text style={styles.resultContentDescriptionText}>위시리스트에 상품이 추가되었습니다.</Text>
                <Text style={styles.resultContentBrandText}>{brand}</Text>
                <Text style={styles.resultContentName}>{name}</Text>
                <Text style={styles.resultContentOption}>{option}</Text>
              </View>
            </View>
          </View>
        </div>
      </div>
    );
  }

  renderAddToWishlist() {

    const {
      onChangeSelectedItemList: onChangeSelectedItemListFromProps,
      product,
      selectedItemList: selectedItemListFromProps,
      modalProps,
      setModalProps,
      subType,
      onRefresh,
    } = this.props;

    const {
      selectedItemList = selectedItemListFromProps,
    } = this.getModalState();


    const onClose = () => {
      setModalProps({ isVisible: false });
    };

    const onChangeSelectedItemList = (selectedItemList) => {
      // console.log(`onChangeSelectedItemList selectedItemList=${JSON.stringify(selectedItemList)}`);
      this.setModalState({
        selectedItemList,
      })

      onChangeSelectedItemListFromProps(selectedItemList)
    };

    const onPressSizeGuide = () => {
      this.setModalState({ mode: 'sizeGuide' });
    };

    const onConfirm = (selectedItemList) => {
      onRefresh();
      if (product.option && selectedItemList.length > 0) {
        // 상품에 선택할 옵션이 없거나, 선택한 옵션이 있는 경우
        this.setModalState({ mode: 'result' }, () => {
          setTimeout(() => {
            this.resetModalState(() => {
              setModalProps({ isVisible: false });
            });
          }, 2000);
        });
      } else {
        // 상품에 선택할 옵션이 있으나, 선택한 옵션이 없는 경우
        this.resetModalState(() => {
          setModalProps({ isVisible: false });
        });
      }
    };

    // console.log('AddToWishlist selectedItemList=', selectedItemList);

    return (
      <AddToWishlist
        product={product}
        onClose={onClose}
        onPressSizeGuide={onPressSizeGuide}
        onConfirm={onConfirm}
        orgSelectedItemList={selectedItemListFromProps}
        selectedItemList={selectedItemList}
        onChangeSelectedItemList={onChangeSelectedItemList}
        subType={subType}
        modalProps={modalProps}
        setModalProps={setModalProps}
        />
    );
  }

  renderSizeGuide() {

    const { subType, setModalProps } = this.props;

    const onClose = () => {
      setModalProps({ isVisible: false });
    };

    const onPressBack = () => {
      this.setModalState({ mode: 'addToWishlist'})
    };

    return (
      <SizeGuideModal
        onClose={onClose}
        onPressBack={onPressBack}
        targetSubType={subType}
        />
    );
  }

  render() {
    const {
      mode
    } = this.getModalState();

    if (mode === 'addToWishlist') {
      return this.renderAddToWishlist();
    }

    if (mode === 'sizeGuide') {
      return this.renderSizeGuide();
    }

    if (mode === 'result') {
      return this.renderResultContent();
    }

    return null;
  }
}


WishlistControl.propTypes = {
  selectedItemList: PropTypes.array,
  onChangeSelectedItemList: PropTypes.func,
  subType: PropTypes.string,
};


WishlistControl.defaultProps = {
  selectedItemList: [],
  onChangeSelectedItemList: () => null,
  subType: 'clothing',
};


export default WishlistControl;
