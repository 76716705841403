/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  StyleSheet, View, Text, TextInput, TouchableOpacity,
  ActivityIndicator, TouchableHighlight,
  Modal, Alert, ScrollView, Dimensions,
} from 'react-native';
import { connect } from 'react-redux';

import { actions } from 'shared';

import { Styles, Colors, Constants } from '../constants';
import CloseIcon from '../icons/CloseIcon';
import SearchIcon from '../icons/HeaderSearchIcon';


const windowHeight = Dimensions.get("window").height;

const styles = StyleSheet.create({
  modal : {
    position:'absolute',
    top: 0,
    bottom: 0,
    start: 0,
    end: 0
  },
  container: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    position: 'absolute',
    top: 0,
    bottom: 0,
    start: 0,
    end: 0,
    height: windowHeight,
  },
  outside: {
    flex: 1,
    justifyContent: 'center',
  },
  modalContainer: {
    backgroundColor: '#fff',
    marginHorizontal: 20,
    height: 431,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 19,
  },
  headerText: {
    fontSize: 18,
    lineHeight: 21,
    marginTop: 2,
    marginBottom: 1,
    ...Styles.appleSDGothicNeoBold,
  },
  headerCloseButton: {
  },
  searchInputContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderColor: Colors.lightGray,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  searchInput: {
    fontSize: 16,
    color: 'black',
    lineHeight: 19,
    padding: 0,
    height: 19 + 2,
    textAlignVertical: 'top',
    marginTop: 3,
    ...Styles.appleSDGothicNeoRegular,
    outlineStyle: 'none',
  },
  searchTipContainer: {
    paddingHorizontal: 20,
    marginBottom: 10,
  },
  searchTipDescription: {
    fontSize: 15,
    ...Styles.appleSDGothicNeoRegular,
    lineHeight: 22,
    color: 'black',
  },
  searchTipDescriptionHeader: {
    fontSize: 15,
    ...Styles.appleSDGothicNeoRegular,
    lineHeight: 22,
    marginVertical: 20,
    color: 'black',
  },
  searchTipExample: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 13,
    color: 'rgb(69, 108, 235)',
    height: 16,
    lineHeight: 16,
    marginTop: 2,
    marginBottom: 15,
  },
  noSearchResultContainer: {
    marginTop: 20,
    marginHorizontal: 20,
    marginBottom: 25,
  },
  noSearchResultTitle: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    marginBottom: 10,
  },
  noSearchResultDescription: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 13,
  },
  loading: {
    marginVertical: 100,
  },
  itemListContainer: {
  },
  itemContainer: {
    marginTop: 0,
    marginBottom: 0,
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 10,
  },
  itemRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
  itemTitle: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    width: 70,
    fontWeight: 'bold',
  },
  itemContent: {
    ...Styles.appleSDGothicNeoRegular,
    flex: 1,
    fontSize: 15,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  itemDivider: {
    height: 1,
    backgroundColor: Colors.lightGray,
  },
});


class AddressSearch extends React.Component {
  state = {
    searchInputText: '',
    searchResult: null,
    loading: false,
    open: true,
  };

  searchInputRef = null;

  componentDidMount() {
    if (this.searchInputRef) {
      this.searchInputRef.focus();
    }
  }

  loadSearchResult = async searchKeyword => {
    this.setState({ loading: true });
    const errorMessage = '검색 중 오류가 발생하였습니다.\n다시 시도해 주세요.';

    const { getAddressSearchResult } = this.props;
    try {
      const ret = await getAddressSearchResult(searchKeyword);
      // console.log('getAddressSearchResult return', ret);

      if (!ret.success) {
        const { data: { codeData } } = ret;
        if (codeData) {
          const { message, detailCode } = codeData;
          Alert.alert(`${message} (${detailCode})`);
        } else {
          Alert.alert(`${errorMessage} (E0${ret.errorCode})`);
        }
        return;
      }

      this.setState({ searchResult: ret.data.addressData });
    } catch (error) {
      // console.log('getAddressSearchResult error', error);

      if (error.errorCode) {
        Alert.alert(`${errorMessage} (E1${error.errorCode})`);
      } else {
        Alert.alert(errorMessage);
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  onPressSearchButton = () => {
    const { searchInputText } = this.state;

    if (searchInputText === '') {
      return;
    }

    // console.log('onPressSearchButton searchInputText=', searchInputText);

    this.loadSearchResult(searchInputText);
  };

  onPressCancelButton = () => {
    this.setState({
      searchInputText: '',
      searchResult: null,
      loading: false,
    });
  };


  onPressItem = item => () => {
    const { onPressItem } = this.props;
    this.setState({ open: false });
    onPressItem(item);
  };

  handleClose = () => {
    // console.log('handleClose()');
    this.setState({ open: false });

    const { onClose } = this.props;
    onClose();
  };

  renderSearchInputBar() {
    const { searchInputText } = this.state;
    return (
      <View style={styles.searchInputContainer}>
        <TextInput
          ref={ref => { this.searchInputRef = ref; }}
          style={styles.searchInput}
          value={searchInputText}
          onChangeText={text => this.setState({ searchInputText: text, searchResult: null })}
          onSubmitEditing={this.onPressSearchButton}
          placeholder="예) 강남대로 16길 14"
          placeholderTextColor={Colors.placeholderColor}
        />

        {/* <TouchableHighlight
          style={styles.cancelButton}
          onPress={this.onPressCancelButton}
          underlayColor={Colors.touchEffectColorWhite}
        >
          <CloseIcon
            color={searchInputText === '' ? '#fff' : '#999'}
          />
        </TouchableHighlight> */}

        <View style={Styles.touchEffectContainer}>
          <TouchableHighlight
            style={Styles.touchEffect}
            onPress={this.onPressSearchButton}
            underlayColor={Colors.touchEffectColorWhite}
          >
            <SearchIcon />
          </TouchableHighlight>
        </View>
      </View>
    );
  }

  renderItem(item, index) {
    const {
      zoneCode,
      roadAddress,
      groundAddress,
    } = item;

    return (
      <TouchableOpacity
        style={styles.itemContainer}
        onPress={this.onPressItem(item)}
        key={index}
      >
        <View style={styles.itemRow}>
          <Text style={styles.itemTitle}>우편번호</Text>
          <Text style={styles.itemContent}>{zoneCode}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.itemTitle}>도 로 명</Text>
          <Text style={styles.itemContent}>{roadAddress}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text style={styles.itemTitle}>지    번</Text>
          <Text style={styles.itemContent}>{groundAddress}</Text>
        </View>
      </TouchableOpacity>
    );
  }

  renderContent() {
    const { loading, searchInputText, searchResult } = this.state;

    if (loading) {
      return null;
    }

    if (searchResult === null || searchInputText === '') {
      return (
        <View style={styles.searchTipContainer}>
          <Text style={styles.searchTipDescriptionHeader}>
            아래와 같은 조합으로 검색하면 더욱 정확한 결과를 검색할 수 있습니다.
          </Text>

          <Text style={styles.searchTipDescription}>
            도로명 + 건물번호
          </Text>
          <Text style={styles.searchTipExample}>예) 테헤란로 105</Text>

          <Text style={styles.searchTipDescription}>
            지역명(동/리) + 번지
          </Text>
          <Text style={styles.searchTipExample}>예) 역삼동 822-1</Text>

          <Text style={styles.searchTipDescription}>
            지역명(동/리) + 건물명(아파트명)
          </Text>
          <Text style={styles.searchTipExample}>예) 영통구 그대가프리미어아파트</Text>

          <Text style={styles.searchTipDescription}>
            사서함명 + 번호
          </Text>
          <Text style={styles.searchTipExample}>예) 분당 우체국 사서함 1 - 100</Text>
        </View>
      );
    }

    // console.log('renderContent searchResult=', searchResult);

    if (searchResult.length === 0) {
      return (
        <View style={styles.noSearchResultContainer}>
          <Text style={styles.noSearchResultTitle}>검색된 결과가 없습니다.</Text>
          <Text style={styles.noSearchResultDescription}>정확한 주소인지 다시 확인해 주세요.</Text>
        </View>
      );
    }

    return (
      <ScrollView
        style={styles.itemListContainer}
        {...Constants.scrollViewCommonProps}
      >
        {searchResult.map((item, index) => (
          <View key={`searchItem${index}`}>
            {index > 0 && <View key={`itemDivider${index}`} style={styles.itemDivider} />}
            {this.renderItem(item, index)}
          </View>
        ))}
      </ScrollView>
    );
  }

  render() {
    const { loading, open } = this.state;

    return (
      <Modal
        visible={open}
        transparent
        style={styles.modal}
      >
        <View style={styles.container}>
          <View style={styles.outside}>
            <View style={styles.modalContainer}>
              <View style={styles.headerContainer}>
                <Text style={styles.headerText}>우편번호</Text>
                <View style={Styles.touchEffectContainer}>
                  <TouchableHighlight
                    style={[Styles.touchEffect, styles.headerCloseButton]}
                    onPress={this.handleClose}
                    underlayColor={Colors.touchEffectColorWhite}
                  >
                    <CloseIcon />
                  </TouchableHighlight>
                </View>
              </View>

              {this.renderSearchInputBar()}
              { this.renderContent() }
              { loading && (
                <ActivityIndicator size="large" color="gray" style={styles.loading} />
              )}
            </View>
          </View>
        </View>
      </Modal>
    );
  }
}

export default connect(null, actions)(AddressSearch);
