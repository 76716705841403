import React from 'react';
import {
  Dimensions, Text, View, StyleSheet, FlatList,
  ScrollView,
} from 'react-native';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import Masonry from 'react-masonry-css'

import { Constants, Styles, SortingRule } from '../../../constants';

import { SortIcon, CrossIcon } from '../../../icons-v2';
import Spinner from '../../../components/Spinner';
import Button from '../../../components-v2/Button';
import ListFooterSpinner from '../../../components-v2/ListFooterSpinner';
import StyleFeed from '../../../components-v2/StyleFeed';
import SortModal from '../../../components-v2/ProductFilterControl/SortModal';

import { debounce } from 'lodash';

import '../../../css/styleFeedMasonry.css';


const {
  height: windowHeight,
} = Dimensions.get('window');

const horizontalItemSpacing = 15;
const verticalItemSpacing = 40;
const columnCount = 2;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
  scrollView: {
    marginBottom: Constants.innerViewBottomSpace,
  },
  innerScrollView: {
    paddingStart: Constants.viewHorizontalPadding - horizontalItemSpacing / 2,
    paddingEnd: Constants.viewHorizontalPadding - horizontalItemSpacing / 2,
    marginBottom: -1 * verticalItemSpacing / 2,
  },
  itemContainer: {
    paddingTop: verticalItemSpacing / 2,
    paddingBottom: verticalItemSpacing / 2,
  },
  headerContainer: {
  },
  header: {
    marginTop: 10,
    marginBottom: 20,
    paddingStart: 20,
    paddingEnd: 11,
  },
  headerButtonDefaultStyle: {
    borderWidth: 0.5,
    borderColor: 'black',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 16,
    paddingVertical: 6,
  },
  headerButtonDefaultTextStyle: {
    color: 'black',
    fontSize: 15,
    lineHeight: 18,
    ...Styles.pretendard400,
  },
  headerButtonActiveStyle: {
  },
  headerButtonActiveTextStyle: {
    color: 'black',
    fontSize: 15,
    lineHeight: 18,
    ...Styles.pretendard600,
  },
  sortButtonStyle: {
    borderWidth: 0.5,
    borderColor: 'black',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    paddingHorizontal: 16,
    paddingVertical: 6,
  },
  sortButtonText: {
    color: 'white',
    fontSize: 15,
    lineHeight: 18,
    ...Styles.pretendard600,
  },
});


class StyleFeedList extends React.Component {
  listRef = React.createRef();
  masonryListRef = React.createRef();
  onSavingScrollPosition = false;

  state = {
    refreshing: false,
    isFetchingNextPage: false,
    sortingRule: SortingRule.SORTING_RULE_STYLE_POST_LIST[0],
    selectedHashtag: undefined,
    windowHeight : windowHeight
  };

  updateDimensions = () => {
    if(window.innerHeight && window.innerHeight > 0){
      this.setState({ windowHeight: window.innerHeight });
    }
  };

  componentDidMount() {
    const { stylePostList } = this.props;
    this.updateDimensions();
    if (stylePostList.length > 0) {
      // 이미 글을 한 번 읽어온 경우는 다시 로드하지 않고 태그 선택, 스크롤 상태를 복원
      this.restoreState();
    } else {
      this.loadData(false);
    }
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  scrollToTop() {
    this.listRef.scrollTo({ x: 0, y: 0, animated: true });
  }

  componentDidUpdate(prevProps) {
    const { pagination, selectedHashtag } = this.props;

    if (prevProps.pagination.isFetching && !pagination.isFetching) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isFetchingNextPage: false, refreshing: false });
    }
  }

  onChangeSortingRule = (sortingRule) => {
    this.setState({ sortingRule }, () => this.loadData(false));
  }

  showSortControl = () => {
    const { setModalProps } = this.props;
    const { sortingRule } = this.state;

    const children = (
      <SortModal
        itemList={SortingRule.SORTING_RULE_STYLE_POST_LIST}
        selectedItem={sortingRule}
        onChange={this.onChangeSortingRule}
        setModalProps={setModalProps}
        />
    );

    setModalProps({
      isVisible: true,
      children: children,
    });
  }


  loadData(nextPage) {
    const { loadData } = this.props;
    const { sortingRule, selectedHashtag } = this.state;

    this.setState({ isFetchingNextPage: nextPage }, () => {
      const query = { sortBy: sortingRule.value };
      if (selectedHashtag) {
        query['tags'] = selectedHashtag;
      }
      loadData(nextPage, query);
      this.saveState();
    });
  }

  saveState() {
    const {
      sortingRule,
      selectedHashtag,
    } = this.state;

    // console.log('StyleFeedList saveState sortingRule=', sortingRule);
    // console.log('StyleFeedList saveState selectedHashtag=', selectedHashtag);

    const { state: prevState = {} } = window.history;
    window.history.replaceState({
      ...prevState,
      StyleFeedListState: {
        sortingRuleId: sortingRule,
        selectedHashtag,
      }
    }, null, window.location.pathname + window.location.search);
  }

  restoreState() {
    const { state: prevState = {} } = window.history;
    const { StyleFeedListState } = prevState;
    // console.log('StyleFeedList restoreState prevState=', prevState);

    if (StyleFeedListState) {
      this.setState({
        ...StyleFeedListState,
      });
      this.restoreScrollPosition();
    } else {
      this.loadData(false);
    }
  }

  saveScrollPosition(scrollPosition) {
    if (this.onSavingScrollPosition) {
      return;
    }

    this.onSavingScrollPosition = true;

    requestAnimationFrame(() => {
      // console.log('StyleFeedList saveScrollPosition scrollPosition: ', scrollPosition);
      const { state: prevState = {} } = window.history;
      window.history.replaceState({
        ...prevState,
        scrollPosition,
      }, null, window.location.pathname + window.location.search);

      this.onSavingScrollPosition = false;
    });
  }

  restoreScrollPosition() {

    const { state: prevState = {} } = window.history;

    const { scrollPosition: prevPosition } = prevState;
    // console.log('StyleFeedList restoreScrollPosition prevPosition=', prevPosition);

    if (prevPosition) {
      this.listRef.scrollTo({x:0, y: prevPosition, animated: false});
    }
  }

  renderHeader() {
    const { router, hashtags } = this.props;
    const { selectedHashtag } = this.state;

    let headerButtons = ["정렬"];
    if (selectedHashtag) {
      headerButtons.push(selectedHashtag);
    }

    hashtags.forEach(t => {
      if (headerButtons.some(b => b === t)) {
        return;
      }
      headerButtons.push(t);
    });

    const renderItem = ({item, index}) => {
      if (index == 0) {
        return (
          <Button
            key={index}
            innerComponent={
              <View style={{ flex:1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                  <SortIcon style={{ marginRight: 4 }} />
                  <Text style={styles.sortButtonText}>{item}</Text>
              </View>
            }
            onPress={this.showSortControl}
            containerStyle={styles.sortButtonStyle}
          />
        );
      } else if (index === 1 && selectedHashtag) {
        return (
          <Button
            key={index}
            innerComponent={(
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text style={[styles.headerButtonDefaultTextStyle, styles.headerButtonActiveTextStyle]}>
                   #{item}
                </Text>
                <CrossIcon style={{ marginLeft: 4 }} />
              </View>
            )}
            containerStyle={[styles.headerButtonDefaultStyle, styles.headerButtonActiveStyle]}
            onPress={() => {
              router.push({ pathname: router.location.pathname });
            }}
          />
        );
      } else {
        return (
        <Button
          key={index}
          title={`#${item}`}
          containerStyle={[styles.headerButtonDefaultStyle]}
          titleStyle={[styles.headerButtonDefaultTextStyle]}
          onPress={() => {
            this.setState({ selectedHashtag: item }, () => {
              this.loadData(false);
            });
          }}
        />);
      }
    }

    return (
      <FlatList
        style={styles.header}
        horizontal
        data={headerButtons}
        showsHorizontalScrollIndicator={false}
        renderItem={renderItem}
        ItemSeparatorComponent={() => (<View style={{ width: 3 }} />)}
        ListFooterComponent={<View style={{ width: Constants.innerViewHorizontalPadding  }}/>}
      />
    );
  }

  renderFooter() {
    const { pagination } = this.props;
    const { isFetchingNextPage, refreshing } = this.state;

    return (
      <>
        <Waypoint
          onEnter={() => {
              //console.log("entered");
              /*if (isFetchingNextPage || refreshing || pagination.isFetching || pagination.nextPageUrl === null) {
                return;
              }
              this.loadData(true);*/
          }}
          onLeave={() => {
            //console.log("left");
          }}
        />
        { pagination.nextPageUrl !== null && <ListFooterSpinner /> }
        <View style={{ height: Constants.viewHorizontalPadding }} />
      </>
    );
  }

  renderStyleFeedItem({ item }) {
    const { router } = this.props;
    return (
      <View style={styles.itemContainer} key={item._id}>
        <StyleFeed
          styleFeed={item}
          enableViewsCount
          router={router}
        />
      </View>
    );
  }

  render() {
    let {
      containerStyle, stylePostList, pagination,
      showSmartBanner,
    } = this.props;

    const {
      refreshing,
      isFetchingNextPage,
      windowHeight
    } = this.state;

    if (pagination.isFetching && !isFetchingNextPage && !refreshing && (!stylePostList || stylePostList.length === 0)) {
      return (<Spinner />);
    }

    const contentContainerStyles = [
      styles.contentContainer,
      {
        height: windowHeight
          - (showSmartBanner && Constants.smartBannerHeight)
          - Constants.mainHeaderHeight
          - (10 + 32 + 20)
          - Constants.bottomTabBarHeight,
      }
    ];

    const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
      const paddingToBottom = 100;
      return layoutMeasurement.height + contentOffset.y >=
        contentSize.height - paddingToBottom;
    };

    const handleScroll = event => {
      const scrollPosition = event.nativeEvent.contentOffset.y;
      try{
          this.saveScrollPosition(scrollPosition);
      }catch(e){

      }

      if(isCloseToBottom(event.nativeEvent)){

        if (isFetchingNextPage || refreshing || pagination.isFetching || pagination.nextPageUrl === null) {
          return;
        }
        try{
            this.loadData(true);
        }catch(e){

        }

      }
    };

    const scrollHandler = debounce(handleScroll, 200);

    return (
      <View style={[styles.container, containerStyle]}>
        <View style={styles.headerContainer}>
          {this.renderHeader()}
        </View>
        <ScrollView
          style={contentContainerStyles}
          ref={(ref) => this.listRef = ref}
          onScroll={scrollHandler}
          scrollEventThrottle={16}
          showsVerticalScrollIndicator={false}
          >
          <Masonry
            ref={(ref) => this.masonryListRef = ref}
            breakpointCols={columnCount}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {stylePostList.map(item => {
              return this.renderStyleFeedItem({ item })
            })}
          </Masonry>
          { pagination.nextPageUrl !== null && <ListFooterSpinner
            isFetchingNextPage={isFetchingNextPage}
            /> }
          {this.renderFooter()}
        </ScrollView>
      </View>
    );
  }
}

export default connect(
  (state, props) => {
    return {
      ...props,
    };
  },
  null,
  null,
  { withRef: true }
)(StyleFeedList);
