exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".hi_product_footer_3VXIDwQ69L{\n  position: absolute;\n  width: 100%;\n  margin:'auto';\n  bottom: 0px;\n  left: 0px;\n  right: 0px;\n  background-color: #ffffff;\n  height: 60px;\n}\n@media (max-width: 449px){\n  .hi_product_footer_3VXIDwQ69L{\n    position:fixed;\n    bottom:0px;\n    width:100%;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"hi_product_footer": "hi_product_footer_3VXIDwQ69L"
};