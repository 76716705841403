import React, { Component } from "react";
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { map } from "lodash";
import DateRangeSelector from "./DateRangeSelector";
export default class WAUStatistics extends Component {

  constructor(props) {
    super(props);
    const today = moment().subtract(1, 'days').day(0);
    const dateRanges = [
      {
        since: today.clone().subtract(1, 'weeks').format('YYYY-MM-DD'), 
        until: today.format('YYYY-MM-DD'), 
        name: '지난주',
      },
      { 
        since: today.clone().subtract(3, 'weeks').format('YYYY-MM-DD'), 
        until: today.format('YYYY-MM-DD'), 
        name: '3주',
      },
      { 
        since: today.clone().subtract(12, 'weeks').format('YYYY-MM-DD'), 
        until: today.format('YYYY-MM-DD'), 
        name: '12주',
      },
      { 
        since: today.clone().subtract(26, 'weeks').format('YYYY-MM-DD'), 
        until: today.format('YYYY-MM-DD'), 
        name: '26주',
      },
    ];
    this.state = {
      dateRanges,
      since: dateRanges[1].since,
      until: dateRanges[1].until,
      wauOptions: {
        title: null,
        series: [],
        xAxis: {
          tickmarkPlacement: 'on',
        },
        yAxis: {
          type: 'logarthmic',
        },
      },
    };
  }


  loadData(since, until) {
    const { loadWAUs } = this.props;
    loadWAUs({ since, until }).then((data) => {
      this.setState({
        wauOptions: {
          title: {
            text: `Weekly Active Users`,
            floating: false,
            margin: 15,
            align: 'center',
            style: {
              fontSize: '15px',
              fontWeight: 'bold',
            }
          },
          yAxis: {
            title: {
              text: 'Active users',
            },
            type: 'logarthmic'
          },
          xAxis: {
            title: {
              text: 'date',
            },
            categories: map(data, (datum) => {
              const date = moment(datum.week);
              return `${date.format('MMM-DD')}~${date.add(6, 'days').format('DD')}`
            }),
            reversed: false,
          },
          series: [
            { name: 'android', data: map(data, (datum) => datum.androidCount) },
            { name: 'ios', data: map(data, (datum) => datum.iosCount) },
          ]
        }
      })
    })
  }

  componentDidMount() {
    const { since, until } = this.state;
    this.loadData(since, until);
  }

  componentDidUpdate(_, prevState) {
    const { since, until } = this.state;
    if (prevState.since !== since || prevState.until !== until) {
      this.loadData(since, until);
    }
  }

  onChangeDateRange({ since, until }) {
    this.setState({ 
      since: moment(since).format('YYYY-MM-DD'), 
      until: moment(until).format('YYYY-MM-DD'),
    });
  }

  render() {
    const { wauOptions, dateRanges } = this.state;
    return (
    <div style={{ margin: '6px 12px'}}>
      <h2 style={{ fontWeight: 'bold', fontSize: '20px' }}># WAU</h2>
      <DateRangeSelector dateRanges={dateRanges} initialDateRangeIndex={1} onChangeDateRange={(dateRange) => this.onChangeDateRange(dateRange)} />
      <HighchartsReact highcharts={Highcharts} options={wauOptions} />
    </div>
    )
  }
}
