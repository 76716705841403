import { normalize } from 'normalizr';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';


export * from '../actions';


// admin actions for Category sales
export function adminLoadCategorySales() {
  const key = 'adminCategorySales';

  return createFetchAction({
    type: 'ADMIN_LOAD_CATEGORY_SALES',
    endpoint: '/api/v2/admin/categorySales',
    transform: ({ json }) => normalize(json, schemas.adminCategorySales),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}

export function adminGetCategorySale(categorySaleId) {
  return createFetchAction({
    type: 'ADMIN_GET_CATEGORY_SALE',
    endpoint: `/api/v2/admin/categorySales/${categorySaleId}`,
    transform: ({ json }) => normalize(json, schemas.adminCategorySale),
  });
}

export function adminCreateCategorySale(categorySale) {
  return createFetchAction({
    type: 'ADMIN_CREATE_CATEGORY_SALE',
    endpoint: '/api/v2/admin/categorySales',
    method: 'POST',
    body: categorySale,
    transform: ({ json }) => normalize(json, schemas.adminCategorySale),
    success: () => ({
      pagination: {
        key: 'adminCategorySales',
        type: 'PREPEND',
      },
    }),
  });
}

export function adminUpdateCategorySale(categorySaleId, categorySale) {
  return createFetchAction({
    type: 'ADMIN_UPDATE_CATEGORY_SALE',
    endpoint: `/api/v2/admin/categorySales/${categorySaleId}`,
    method: 'PUT',
    body: categorySale,
    transform: ({ json }) => normalize(json, schemas.adminCategorySale),
  });
}

export function adminDeleteCategorySale(categorySaleId) {
  const payload = { entities: { adminCategorySales: {} } };
  payload.entities.adminCategorySales[categorySaleId] = null;
  return createFetchAction({
    type: 'ADMIN_DELETE_CATEGORY_SALE',
    endpoint: `/api/v2/admin/categorySales/${categorySaleId}`,
    method: 'DELETE',
    success: {
      payload,
    },
  });
}
