import React from 'react'

import {
  StyleSheet, View, Text, TouchableWithoutFeedback, Dimensions,
} from 'react-native';

import { Styles } from '../constants';
import { BackIcon } from '../icons-v2';
import TouchableHighlightWithContainer from './TouchableHighlightWithContainer';

const {
  height: windowHeight,
} = Dimensions.get('window');


const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-end',
    height: '100vh',
    height: '-webkit-fill-available',
    width: '100%',
    position:'absolute'
  },
  backdrop: {
    height: '10vh',
    width: '100%',
  },
  contentContainer: {
    flex: 1,
    width:'100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#ffffff',
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  headerContainer: {
    alignItems: 'center',
  },
  headerLeftContainer: {
    position: 'absolute',
    top: 21,
    bottom: 9,
    start: 16,
  },
  headerTitleText: {
    ...Styles.pretendard700,
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
    marginTop: 23,
    marginBottom: 12,
    textAlign: 'center',
  },
  headerRightContainer: {
    position: 'absolute',
    top: 24,
    end: 20,
  },
  headerCloseButtonText: {
    ...Styles.pretendard400,
    fontSize: 14,
    lineHeight: 16.8,
    height: 16,
  },
});


class BottomSheetModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      windowHeight: window.innerHeight,
    };
  }

  title = '';
  subTitle = '';
  titleClose = '닫기';
  enableBackButton = false;
  contentRef = null;

  renderHeader = () => {
    const {
      setModalProps,
    } = this.props;

    const onCloseDefault = () => {
      setModalProps({
        isVisible: false,
        children: null,
      });
    }

    const {
      onClose = onCloseDefault,
      title: titleFromProps,
      titleClose: titleCloseFromProps,
      headerContainerStyle,
      onPressBack,
      enableBackButton=this.enableBackButton,
    } = this.props;
    let title = titleFromProps || this.title;
    let titleClose = titleCloseFromProps || this.titleClose;

    return (
      <View style={[styles.headerContainer, headerContainerStyle]}>
        <View style={styles.headerLeftContainer}>
          {enableBackButton && (
            <TouchableHighlightWithContainer
              onPress={onPressBack}
              >
              <BackIcon />
            </TouchableHighlightWithContainer>
          )}
        </View>

        <Text style={styles.headerTitleText}>{title}</Text>

        <View style={styles.headerRightContainer}>
          <TouchableHighlightWithContainer
            onPress={onClose}
          >
            <Text style={styles.headerCloseButtonText}>{titleClose}</Text>
          </TouchableHighlightWithContainer>
        </View>
      </View>
    );
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleResize)

    if (this.contentRef) {
      const contentHeight = this.contentRef.clientHeight;
      if (contentHeight) {
        this.setState({ contentHeight });
      }
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidUpdate(prevProps, prevState) {

    const { contentHeight: prevContentHeight } = prevState;

    if (this.contentRef) {
      const contentHeight = this.contentRef.clientHeight;
      if (prevContentHeight != contentHeight) {
        this.setState({ contentHeight });
      }
    }
  }

  handleResize = () => {
    this.setState({ windowHeight: window.innerHeight })
  }

  renderBody() {
    return (
      <View></View>
    )
  }

  renderContent() {
    return (
      <View ref={ref => { this.contentRef = ref }}>
        {this.renderBody()}
      </View>
    )
  }

  render() {
    const {
      setModalProps,
      backdropStyle,
      onClose,
      contentHeightOffset=0,
    } = this.props;

    const {
      contentHeight,
    } = this.state;

    const onBackdropPress = onClose || (() => {
      setModalProps({
        isVisible: false,
        children: null,
      });
    });

    const backdropStyles = [
      styles.backdrop,
      backdropStyle,
    ];
    if (contentHeight) {
      const backdropHeight = windowHeight - 54 - contentHeight - contentHeightOffset;
      backdropStyles.push({
        height: backdropHeight,
      })
    }

    return (
      <View className={"test"} style={styles.container}>

        <TouchableWithoutFeedback
          onPress={onBackdropPress}
        >
          <View style={backdropStyles} />
        </TouchableWithoutFeedback>

        <View style={styles.contentContainer}>
          {this.renderHeader()}
          {this.renderContent()}
        </View>
      </View>
    );
  }
}


export default BottomSheetModal;
