import React from 'react'
import PropTypes from 'prop-types';
import { 
  Text, StyleSheet, View,
} from 'react-native';

import { Colors, Constants, Styles, SizeData } from '../constants';

import SizeStandardSelector from './SizeStandardSelector';
import ListWithCheckBox from './ListWithCheckBox';
import TouchableHighlightWithContainer from './TouchableHighlightWithContainer';


const styles = StyleSheet.create({
  container: {
  },
  sizeStandardContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: Constants.viewHorizontalPadding, 
    paddingTop: 14,
    paddingBottom: 40 - 24.5,
  },
  sizeStandardSelector: {
  },
  sizeGuideContainer: {
    paddingTop: 1,
  },
  sizeGuideText: {
    ...Styles.pretendard400,
    fontSize: 14,
    lineHeight: 16.8,
    height: 17,
  },
  listContainer: {
  },
  listItemSeparator: {
    marginStart: 20,
    marginEnd: 20,
  },
});


class SizeListWithCheckBox extends React.Component {

  state = {
    updateToggle: false,
  };

  getModalState() {
    const {
      modalProps,
    } = this.props;

    const {
      sizeListSelectorState = {
        selectedSizeStandard: {
          clothing: SizeData.SIZE_STANDARD_LIST.filter((item) => item.subType === 'clothing')[0],
          shoes: SizeData.SIZE_STANDARD_LIST.filter((item) => item.subType === 'shoes')[0],
        },
        selectedItemList: [],
      },
    } = modalProps();

    // console.log(`filterMainModalState=${JSON.stringify(filterMainModalState)}`);

    return sizeListSelectorState;
  }

  setModalState(newState, callback) {
    // console.log(`setModalState newState=${JSON.stringify(newState)}`);
    const sizeListSelectorState = this.getModalState();

    const {
      setModalProps,
    } = this.props;

    setModalProps({
      sizeListSelectorState: {
        ...sizeListSelectorState,
        ...newState,
      },
    }, () => {
      callback && callback();
    });
  }

  getData() {
    const { subType, itemList } = this.props;
    const { selectedSizeStandard } = this.getModalState();

    const resultList = [];

    itemList.forEach((item) => {
      let name = item.name;

      // console.log(`selectedSizeStandard=${JSON.stringify(selectedSizeStandard)}`);

      const sizeStandardName = selectedSizeStandard[subType].name;
      // console.log(`sizeStandardName=${sizeStandardName}`);
      if (sizeStandardName !== 'International') {
        const itemSize = SizeData.SIZE_LIST.filter((e) => e.subType === subType && e.name === item.name)[0];
        if (itemSize) {
          const aliasName = itemSize.alias[sizeStandardName];
          name = `${sizeStandardName} ${aliasName}`;
        }
      }
      if (item.isSoldout) {
        name += ' - 품절'
      }

      const result = {
        ...item,
        displayName: name,
      }

      if (item.isSoldout) {
        result.onPress = null;
      }

      resultList.push(result);
    })

    return resultList;
  }

  onChangeSizeStandard = (subType) => (item, callback) => {
    const { selectedSizeStandard } = this.getModalState();

    selectedSizeStandard[subType] = item;

    // console.log(`onChangeSizeStandard selectedSizeStandard=${JSON.stringify(item)}`);

    this.setModalState({ selectedSizeStandard }, callback);
  }

  renderSizeStandard() {
    const {
      subType,
      onPressSizeGuide,
      modalProps,
      setModalProps,
      isInModal,
    } = this.props;

    const {
      selectedSizeStandard
    } = this.getModalState();

    const sizeStandardList = SizeData.SIZE_STANDARD_LIST.filter((item) => item.subType === subType);

    return (
      <View style={styles.sizeStandardContainer}>
        <SizeStandardSelector
          containerStyle={styles.sizeStandardSelector}
          sizeStandardList={sizeStandardList}
          selectedSizeStandard={selectedSizeStandard[subType]}
          onChange={this.onChangeSizeStandard(subType).bind(this)}
          modalProps={modalProps}
          setModalProps={setModalProps}
          isInModal={isInModal}
          />
        <View style={styles.sizeGuideContainer}>
          <TouchableHighlightWithContainer
            style={styles.sizeGuide}
            onPress={() => onPressSizeGuide(subType)}
          >
            <Text style={styles.sizeGuideText}>사이즈 가이드</Text>
          </TouchableHighlightWithContainer>
        </View>
      </View>
    )
  }

  render() {
    const {
      containerStyle,
      onChangeSelectedItemList,
      selectedItemList,
      ListFooterComponent,
      ListItemComponent,
      ListContainerStyle
    } = this.props;

    const itemList = this.getData();

    // console.log(`itemList=${JSON.stringify(itemList)}`);

    const containerStyles = [
      styles.container,
      containerStyle,
    ];

    return (
      <View style={containerStyles}>
        { itemList.length > 0 && this.renderSizeStandard()}
        <ListWithCheckBox
          onChangeSelectedItemList={onChangeSelectedItemList}
          selectedItemList={selectedItemList}
          itemList={itemList}
          containerStyle={[styles.listContainer, ListContainerStyle]}
          enableIndex={false}
          ListFooterComponent={ListFooterComponent}
          ListItemSeparatorStyle={styles.listItemSeparator}
          ListItemComponent={ListItemComponent}
          />
      </View>
    );
  }
}

SizeListWithCheckBox.propTypes = {
  searchKeyword: PropTypes.string,
  enableIndex: PropTypes.bool,
  subType: PropTypes.string,
  isInModal: PropTypes.bool,
};

SizeListWithCheckBox.defaultProps = {
  searchKeyword: '',
  enableIndex: false,
  subType: 'clothing',
  isInModal: true,
};

export default SizeListWithCheckBox;
