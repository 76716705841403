import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { View, StyleSheet, Text } from 'react-native';

import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import ProductRelatedPost from './ProductRelatedPost';
import Styles from '../constants/Styles';


const MAX_COLLETIONS = 4;

const styles = StyleSheet.create({
  collectionTitle: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 20,
    marginBottom: 10,
  },
  collectionItem: {
    margin: 1,
    width: '100%',
    padding: 4,
    borderRadius: 4,
  },
  collectionEmptyView: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgb(246, 247, 248)',
    borderRadius: 5,
    height: 250,
  },
  collectionEmptyText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
});

const useStyles = () => ({
  root: {
  },
});

class ProductRelatedPosts extends React.Component {
  render() {
    const {
      productRelatedPosts, onPressPost, onPressHeartist,
      classes,
    } = this.props;

    const data = productRelatedPosts.slice(0, MAX_COLLETIONS);

    return (
      <View>
        <Text style={styles.collectionTitle}>이 상품을 착용한 하티스트</Text>
        {/* GridList의 -2 margin이 ProductPage에서 스크롤 위아래 흔들림을 만들어서 임의로 추가함 */}
        <View style={{ margin: 2 }}>
          <GridList
            cellHeight="auto"
            cols={2}
            classes={classes}
          >
            {data.map(item => (
              <GridListTile
                key={item._id}
              >
                <View />
                <ProductRelatedPost
                  style={styles.collectionItem}
                  post={item}
                  onPressPost={onPressPost}
                  onPressHeartist={onPressHeartist}
                />
              </GridListTile>
            ))}
          </GridList>
        </View>
        {data.length === 0 ? (
          <View style={styles.collectionEmptyView}>
            <Text style={styles.collectionEmptyText}>항목이 없습니다.</Text>
          </View>
        ) : (
          null
        )}
      </View>
    );
  }
}

export default withStyles(useStyles)(ProductRelatedPosts);
