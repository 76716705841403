import React from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, StyleSheet, View, Text } from 'react-native';
import { Waypoint } from 'react-waypoint';

import {
  Styles, Constants, Colors,
} from '../../../constants';

import {
  Button, Products,
} from '../../../components-v2';


const stylesHtml = {
  blankContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    touchAction: 'none',
  },
  productImage: {
    width: '100%',
    aspectRatio: 166/221,
    borderWidth: 1,
    borderColor: Colors.productImageBorder,
    overflow: 'hidden',
  },
};

const styles = StyleSheet.create({
  container: {
  },
  blankContentContainer: {
    width: 240,
    alignItems: 'center',
  },
  blankText: {
    marginBottom: 20,
    fontSize: 16,
    lineHeight: 23,
    height: 46,
    ...Styles.pretendard400,
    textAlign: 'center',
  },
  blankButton: {
    width: 200,
    paddingVertical: 10.5,
    borderWidth: 0,
    borderColor: 'transparent',
    backgroundColor: '#000',
    justifyContent: 'center',
    alignItems: 'center',
  },
  blankButtonText: {
    fontSize: 16,
    lineHeight: 19.2,
    color: '#fff',
    ...Styles.pretendard600,
    height: 19,
  },
  productListContainer: {
    ...Styles.fullWidth,
  },
  itemContainerStyle: {
    flex: 1,
    marginHorizontal: 3 / 2,
  },
  columnWrapperStyle: {
    marginHorizontal: -3 / 2,
    ...Styles.fullWidth,
  },
  productContainer: {
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  productTextContainer: {
    marginTop: 6,
  },
});


class TabPick extends React.Component {

  state = {
    refreshing: false,
  }

  componentDidMount() {
    this.loadData(false);
  }

  componentDidUpdate(prevProps) {
    const { loadData } = this.props;
    if (!prevProps.loadData && loadData) {
      this.loadData(false);
    }
  }

  loadData = (nextPage) => {
    const { loadData } = this.props;
    if (loadData) {
      return loadData(nextPage, {});
    }
  }

  renderFooter = () => {
    const { pagination } = this.props;
    const { refreshing } = this.state;

    return (
      <React.Fragment>
        <Waypoint
          onEnter={() => {
              if (refreshing || pagination.isFetching || pagination.nextPageUrl === null) {
                return;
              }
              this.loadData(true);
          }}
        />
        { pagination.nextPageUrl !== null && <ActivityIndicator size="small" color="gray" /> }
        <View style={{ height: Constants.viewHorizontalPadding }} />
      </React.Fragment>
    );
  }


  renderBlank() {
    const {
      showSmartBanner,
      router
    } = this.props;

    const containerStyles = {
        ...stylesHtml.blankContainer,
        height: `calc(100vh - ${(Constants.mainHeaderHeight + 94 + 49) + (showSmartBanner && Constants.smartBannerHeight) + Constants.bottomTabBarHeight}px)`,
    };

    return (
      <div style={containerStyles}>
        <View style={styles.blankContainer}>
          <View style={styles.blankContentContainer}>
            <Text style={styles.blankText}>상품을 위시리스트에 담으면 내 픽에 공유됩니다.</Text>
            <Button
              title="상품 보러가기"
              containerStyle={styles.blankButton}
              titleStyle={styles.blankButtonText}
              onPress={() => {router.push('/shop')}}
              />
          </View>
        </View>
      </div>
    )
  }

  render() {
    const {
      likedProducts, pagination, onPressProduct,
      modalProps,
      setModalProps,
    } = this.props;

    const { refreshing } = this.state;

    if(likedProducts.length === 0) {
      return this.renderBlank();
    }

    return (
      <View>
        <Products
          scrollToOverflowEnabled
          onRefresh={() => {
            if (!refreshing) {
              this.setState({ refreshing: true });
              this.loadData(false).then(this.setState({ refreshing: false }));
            }
          }}
          refreshing={refreshing}
          columnWrapperStyle={styles.columnWrapperStyle}
          containerStyle={styles.productContainer}
          flatListStyle={styles.productFlatListContainer}
          itemContainerStyle={styles.itemContainerStyle}
          productImageStyle={stylesHtml.productImage}
          productTextContainerStyle={styles.productTextContainer}
          horizontal={false}
          numColumns={2}
          products={likedProducts}
          pagination={pagination}
          onPressProduct={onPressProduct}
          onPressLike={() => {
            if (!refreshing) {
              this.setState({ refreshing: true });
              this.loadData(false).then(this.setState({ refreshing: false }));
            }
          }}
          loadData={this.loadData}
          listHeaderComponentStyle={{ height: 10 }}
          listFooterComponentStyle={{ width: 0 }}
          isSmallVersion={false}
          showLikeButton={true}
          showTags={true}
          disableSpinner={true}
          setModalProps={setModalProps}
          modalProps={modalProps}
          {...Constants.scrollViewCommonProps}
        />
        {this.renderFooter()}
      </View>
    );
  }
}


TabPick.propTypes = {
  showSmartBanner: PropTypes.bool,
  likedProducts: PropTypes.array.isRequired,
  loadData: PropTypes.func,
  pagination: PropTypes.object,
  onPressProduct: PropTypes.func,
}


TabPick.defaultProps = {
  showSmartBanner: false,
};


export default TabPick;
