import React from 'react'

import { StyleSheet, View, Text, FlatList } from 'react-native';

import { Styles, Colors, Constants, SortingRule } from '../../constants';
import TouchableHighlightWithContainer from '../TouchableHighlightWithContainer';

import BottomSheetModal from '../BottomSheetModal';
import { CheckIcon } from '../../icons-v2';


const styles = StyleSheet.create({
  container: {
    paddingBottom: 39 + Constants.bottomMargin,
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 20,
    paddingTop: 25,
    paddingBottom: 25,
  },
  itemText: {
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard400,
  },
  itemTextSelected: {
    ...Styles.pretendard600,
  },
});


class SortModal extends BottomSheetModal {

  title = '정렬'

  state = {};

  componentDidMount() {
    const {
      itemList=SortingRule.SORTING_RULE_PRODUCT_LIST,
    } = this.props;

    this.setState({ itemList });
  }

  renderItem(item) {
    const { itemList } = this.state;
    const {
      selectedItem = itemList[0],
      onChange,
      setModalProps
    } = this.props;

    const isSelected = item.id === selectedItem.id;

    const itemTextStyles = [
      styles.itemText,
      isSelected && styles.itemTextSelected,
    ];

    const onPress = () => {
      onChange(item);
      setModalProps({
        isVisible: false,
        children: null,
      });
    }

    return (
      <TouchableHighlightWithContainer
        onPress={onPress}
        >
        <View style={styles.itemContainer}>
          <Text style={itemTextStyles}>{item.name}</Text>
          {isSelected && (<CheckIcon />)}
        </View>
      </TouchableHighlightWithContainer>
    );
  }

  renderBody() {
    const { itemList } = this.state;

    return (
      <View style={styles.container}>
        <FlatList
          keyExtractor={(item, index) => `${index}`}
          data={itemList}
          renderItem={({ item }) => this.renderItem(item)}
          ListHeaderComponent={() => <View style={{ height: 6 }} />}
          bounces={false}
          />
      </View>
    );
  }
}


export default SortModal;
