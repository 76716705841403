import React from 'react';
import { connect }  from 'react-redux';
import { actions, loadEntities } from 'shared';
import { browserHistory } from 'react-router';
import includes from 'lodash/includes';

import {
  StyleSheet, View, Text,
} from 'react-native';
import { Constants, Styles } from '../../../constants';
import { TouchableHighlightWithContainer } from '../../../components-v2';

import { ArrowRightIcon } from '../../../icons-v2';
import { navigateToAuthPage } from '../../auth/AuthPage';


const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    paddingBottom: 11,
    backgroundColor: '#000',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textWhite: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    color: '#fff',
  },
  textRed: {
    ...Styles.pretendard700,
    fontSize: 16,
    lineHeight: 19.2,
    color: '#D85550',
  },
  arrowRight: {
  },
});

class RegistrationCoupon extends React.PureComponent {
  isLoggedIn = () => {
    const { auth } = this.props;
    if (!auth || !auth.token) {
      return false;
    }
    return true;
  }

  hasCoupon = () => {
    const { userCouponList } = this.props;
    return (userCouponList.filter(c => Constants.registrationCouponNo.includes(c.couponNo)).length > 0);
  }

  render() {
    // (2022/10/23) 10/27 샘플세일 진행을 위한 CTA 버튼 임시 제거
    return (<View style={{ height: 15 }} />);

    const {
      containerStyle,
      userCouponList,
      router,
      productId,
      callback=() => null,
    } = this.props;

    const onPress = () => {
      if (this.isLoggedIn()) {
        if (this.hasCoupon()) {
          alert('이미 발급되었습니다.');
        } else {
          alert('발급대상이 아닙니다.');
        }
      } else {
        alert('로그인해주시기 바랍니다.');
        navigateToAuthPage(router, `/product/${productId}`)
        if (callback) callback();
      }
    }

    return (
      <View style={containerStyle}>
        <TouchableHighlightWithContainer
          onPress={onPress}
        >
          <View style={[styles.container, styles.row]}>
            <Text style={styles.textWhite}>
              지금 하트잇 가입하고&nbsp;
              <Text style={styles.textRed}>
                15% 할인쿠폰&nbsp;
                <Text style={styles.textWhite}>
                  받기
                </Text>
              </Text>
            </Text>
            <View style={styles.arrowRight}>
              <ArrowRightIcon color='#fff' />
            </View>
          </View>
        </TouchableHighlightWithContainer>
      </View>
    );
  }
}

export default connect(
  (state) => ({
    auth: state.auth,
    userCouponList: loadEntities(state, 'userCouponList', 'userCouponList').userCouponList,
  }),
  actions
)(RegistrationCoupon);
