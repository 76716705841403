import React from 'react';
import {
  Platform, Animated, View, StyleSheet, Text, Dimensions,
} from 'react-native';
import { connect } from 'react-redux';

import { actions } from 'shared';
import { getDateTimeString } from 'shared/util';

import { Constants, Styles, Colors } from '../../../constants';
import Button from '../../../components-v2/Button';
import InstagramProfileImage from '../../../nativeComponents/InstagramProfileImage';


const {
  height: windowHeight,
} = Dimensions.get('window');


const stylesHtml = {
  profileImage: {
    borderRadius: 22,
    borderWidth: 0,
    borderColor: Colors.profileImageBorder,
    backgroundColor: '#cfcfca',
    height: 44,
    width: 44,
  },
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginHorizontal: 14,
    marginBottom: Constants.bottomMargin,
  },
  bottomContainer: {
    position: 'fixed',
    backgroundColor: '#fff',
    left: 0,
    right: 0,
    bottom: 0,
  },
  inputContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginHorizontal: 14,
    marginBottom: Constants.bottomMargin,
    paddingHorizontal: 20,
    paddingVertical: 14.5,
    borderWidth: 0.5,
    borderColor: '#000',
  },
  input: {
    fontSize: 15,
    lineHeight: 18,
    ...Styles.pretendard400,
    outlineStyle: 'none',
  },
  submitButtonContainer: {
    marginLeft: 10,
  },
  submitButton: {
    borderWidth: 0,
    borderColor: 'transparent',
    padding: 0,
  },
  submitButtonText: {
    textAlign: 'right',
    fontSize: 16,
    lineHeight: 21,
    color: '#000',
    ...Styles.pretendard600,
  },
  blankContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 75,
  },
  emptyListContainer: {
    flex: 1,
    paddingTop: '30vh',
    justifyContent: 'center',
  },
  emptyListText: {
    fontSize: 16,
    height: 19.2,
    textAlign: 'center',
    color: '#2C2C2E',
    ...Styles.pretendard400,
  },
  contentContainer: {
  },
  itemContainer: {
    marginStart: Constants.viewHorizontalPadding - 14,
    marginEnd: Constants.viewHorizontalPadding - 14,
    marginBottom: 25,
    flex: 1,
    flexDirection: 'row',
    paddingTop: 10,
  },
  itemSubContainer: {
    marginStart: -2,
    paddingStart: 18,
  },
  itemSubLastContainer: {
    marginBottom: 0,
  },
  subReplyContainer: {
    marginTop: -38,
    paddingTop: 38,
    marginStart: 28,
    marginBottom: 25,
    paddingLeft: 0,
    marginEnd: Constants.viewHorizontalPadding,
    flex: 1,
    flexDirection: 'column',
    borderLeftWidth: 2,
    borderLeftColor: '#cfcfcf',
    zIndex: -10,
  },
  itemContentDateButtonContainer: {
    flex: 1,
    height: '100%',
    flexDirection: 'column',
    marginTop: 3,
    marginLeft: 10,
  },
  itemContentContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  replyText: {
    fontSize: 15,
    lineHeight: 18,
    color: '#000',
    flexWrap: 'wrap',
    ...Styles.pretendard400,
  },
  nameText: {
    ...Styles.pretendard600,
  },
  dateButtonContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 6,
  },
  dateText: {
    color: '#000',
    opacity: 0.5,
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard400,
    marginEnd: 10,
  },
  replyButton: {
    padding: 0,
    borderWidth: 0,
    marginRight: 10,
    borderColor: 'transparent',
  },
  replyButtonText: {
    color: '#000',
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard400,
  },
});



class StyleReplyPage extends React.Component {
  state = {
    inputValue: '',
    replyList: [],
    replyTo: null,
  };

  componentDidMount() {
    const {
      logEventPageview, postId,
    } = this.props;

    this.loadData();

    logEventPageview({ page_name: 'StyleReply', page_key: postId });
  }

  loadData() {
    const { postId, loadStylePostComments } = this.props;
    loadStylePostComments(postId)
      .then(result => {
        this.setState({ replyList: result });
      });
  }

  onSubmitEditing = async (_evt) => {
    const { auth, postId, createStylePostComment } = this.props;
    const { inputValue, replyTo } = this.state;

    const comment = {
      content: inputValue.trim(),
    };

    await createStylePostComment(postId, replyTo, comment);
    this.loadData();

    this.setState({
      inputValue: '',
      replyTo: null,
    });
    this.inputRef.blur();
    this.inputRef.innerText = '';
  }

  onChangeText = (value) => {
    this.setState({inputValue: value});
  }

  onFocus = _evt => {
    const { router, auth } = this.props;
    const { token } = auth;
    if (!auth || !token) {
      router.push('/accounts/login');
    }
  }

  renderItem = ({ item }, isSub = false, parentId = '', isLast) => {
    const { auth, deleteStylePostComment, postId } = this.props;
    return (
      <View key={item._id}>
        <View style={[styles.itemContainer, isSub && styles.itemSubContainer, isLast && styles.itemSubLastContainer ]}>
          <InstagramProfileImage
            style={stylesHtml.profileImage}
            user={item.owner}
            username={item.owner.username}
          />
          <View style={styles.itemContentDateButtonContainer}>
            <View style={styles.itemContentContainer}>
              <Text style={styles.replyText}>
                <Text style={styles.nameText}>{`${item.owner.name}  `}</Text>
                {item.content}
              </Text>
            </View>
            <View style={[styles.dateButtonContainer, isLast && styles.dateButtonLastContainer]}>
              <Text style={styles.dateText}>
                {getDateTimeString(new Date(item.created))}
              </Text>
              <Button
                containerStyle={styles.replyButton}
                titleStyle={styles.replyButtonText}
                title="답글 달기"
                onPress={() => {
                  this.setState({ replyTo: (isSub) ? parentId : item._id });
                  this.inputRef.focus();
                }}
              />
              { auth && (auth._id === item.owner._id) && (
                <Button
                  containerStyle={styles.replyButton}
                  titleStyle={styles.replyButtonText}
                  title="삭제"
                  onPress={async () => {
                    deleteStylePostComment(postId, item._id)
                      .then(() => this.loadData());
                  }}
                />
              )}
            </View>
          </View>
        </View>
        {item.childReplies && item.childReplies.length > 0 && (
          <View style={styles.subReplyContainer}>
            {item.childReplies.map((subItem, idx) => {
              const isLast = (idx + 1) === item.childReplies.length;
              return this.renderItem({item: subItem}, true, item._id, isLast)
            })}
          </View>
        )}
      </View>
    );
  }

  renderEmptyComponent = () => {
    return (
      <View style={styles.blankContainer}>
        <Text style={styles.emptyListText}>
          이 피드의 첫 댓글을 남겨주세요.
        </Text>
      </View>
    );
  }

  render() {
    const { showSmartBanner } = this.props;
    const { replyList } = this.state;

    const onInput = e => {
      let newValue = '';
      let isOnFreshLine = true;

      function parseChildNodesForValueAndLines(childNodes) {
        for (let i = 0; i < childNodes.length; i++) {
          const childNode = childNodes[i];
      
          if (childNode.nodeName === 'BR') {
            // BRs are always line breaks which means the next loop is on a fresh line
            newValue += '\n';
            isOnFreshLine = true;
            continue;
          }
      
          // We may or may not need to create a new line
          if (childNode.nodeName === 'DIV' && isOnFreshLine === false) {
            // Divs create new lines for themselves if they aren't already on one
            newValue += '\n';
          }
      
          // Whether we created a new line or not, we'll use it for this content so the next loop will not be on a fresh line:
          isOnFreshLine = false;
      
          // Add the text content if this is a text node:
          if (childNode.nodeType === 3 && childNode.textContent) {
            newValue += childNode.textContent;
          }
      
          // If this node has children, get into them as well:
          parseChildNodesForValueAndLines(childNode.childNodes);
        }
      }

      parseChildNodesForValueAndLines(e.currentTarget.childNodes);
      // console.log('StyleReplyPage newValue=', newValue);

      this.onChangeText(newValue);
    }

    const listHeight =  windowHeight
      - (showSmartBanner && Constants.smartBannerHeight)
      - Constants.mainHeaderHeight
      - 75;
    const listStyles = [styles.contentContainer];
    listStyles.push({
      height: listHeight,
    });

    console.log('height: ', listHeight);

    return (
      <View
        style={[Styles.screenHeaderSpace, styles.container]}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        keyboardVerticalOffset={Constants.mainHeaderHeight + Constants.bottomMargin + 20}
      >
        <Animated.FlatList
          style={listStyles}
          contentContainerStyle={replyList.length > 0 ? null : { flex: 1 }}
          ListEmptyComponent={this.renderEmptyComponent()}
          data={replyList}
          renderItem={this.renderItem}
          keyExtractor={item => item._id} // eslint-disable-line no-underscore-dangle
          removeClippedSubviews={false}
          {...Constants.scrollViewCommonProps}
        />
        <View style={styles.bottomContainer}>
          <View style={styles.inputContainer}>
            <span
              ref={(ref) => {this.inputRef = ref;}}
              className='reply_textarea'
              role='textbox'
              contentEditable
              onFocus={this.onFocus}
              onInput={onInput}
              />
            <View style={styles.submitButtonContainer}>
              <Button
                containerStyle={styles.submitButton}
                titleStyle={styles.submitButtonText}
                title="등록"
                onPress={this.onSubmitEditing}
              />
            </View>
          </View>
        </View>
      </View>
    );
  }
}

export default connect(
  (state, { params }) => {
    const { stylePostId: postId } = params;
    return {
      auth: state.auth,
      postId,
    }
  },
  actions,
)(StyleReplyPage);
