import React from 'react'

import FilterSizeModal from "./FilterSizeModal";

function showFilterSizeModal(parent, selectedSizeList, onChange) {
  const {
    modalProps,
    setModalProps,
  } = parent.props;

  const {
    children,
    historyStack=[],
  } = modalProps();

  const newChildren = (
    <FilterSizeModal
      selectedSizeList={selectedSizeList}
      onChange={onChange}
      setModalProps={setModalProps}
      modalProps={modalProps}
      />
  );

  historyStack.push(children);

  setModalProps({
    children: newChildren,
    historyStack,
  });
}

export default showFilterSizeModal;
