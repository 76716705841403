import React from 'react'
import { withRouter } from 'react-router';
import { Dimensions, StyleSheet, View, Text } from 'react-native';

import { connect } from 'react-redux';
import { actions } from 'shared';
import { browserHistory } from 'react-router';

import { Styles, Constants } from '../constants';
import TouchableHighlightWithContainer from './TouchableHighlightWithContainer';

import BottomSheetModal from './BottomSheetModal';
import SizeListWithCheckBox from './SizeListWithCheckBox';
import { unlikeProduct } from 'shared/actions';


const { height: windowHeight } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
  },
  description: {
    ...Styles.pretendard400,
    fontSize: 12,
    lineHeight: 14.4,
    height: 14,
    opacity: 50,
    textAlign: 'center',
    marginTop: 10,
  },
  listContainer: {
    maxHeight: windowHeight * 0.9 - (
      // Constants.statusBarHeight
        // + Constants.mainHeaderMarginTop
        Constants.mainHeaderHeight
        + 20 + 19 + 21 + 3
    ),
    marginBottom: 60,
  },
  sizeListContainer: {
    maxHeight: '70vh',
  },
  bottomButtonContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  bottomButton: {
    backgroundColor: '#000000',
  },
  confirmButton: {
    ...Styles.pretendard600,
    color: '#ffffff',
    fontSize: 16,
    lineHeight: 19.2,
    letterSpacing: '2%',
    height: 19,
    marginTop: 20,
    textAlign: 'center',
    marginBottom: 21,
  }
});


class AddToWishlist extends BottomSheetModal {

  title = '위시리스트 담기';

  onPressConfirm = () => {
    const {
      auth, onConfirm, product, selectedItemList, orgSelectedItemList,
      unlikeProduct, likeProduct,
    } = this.props;
    if (!auth || !auth.token) {
      alert('로그인해주시기 바랍니다.');
      browserHistory.push('/accounts/login');
      return;
    }

    // console.log('AddToWishlist.onPressConfirm orgSelectedItemList=', orgSelectedItemList);
    // console.log('AddToWishlist.onPressConfirm selectedItemList=', selectedItemList);

    const newSelectedItems = [];
    const deslectedItems = [];

    orgSelectedItemList.forEach(prevItem => {
      if (selectedItemList.some(item => prevItem.id === item.id)) {
        return;
      }

      deslectedItems.push(prevItem);
    });

    selectedItemList.forEach(item => {
      if (orgSelectedItemList.some(prevItem => prevItem.id === item.id)) {
        return;
      }

      newSelectedItems.push(item);
    });

    // console.log('AddToWishlist.onPressConfirm deslectedItems=', deslectedItems);
    // console.log('AddToWishlist.onPressConfirm newSelectedItems=', newSelectedItems);

    const productId = product._id || product.goodsNo;
    const promises = [];

    deslectedItems.forEach(item => {
      promises.push(unlikeProduct(productId, { shopProduct: product, optionKeys: [item.id] }, item.id));
    });

    if (newSelectedItems.length > 0) {
      const newOptionKeys = newSelectedItems.map(i => i.id);
      const body = {
        optionKeys: newOptionKeys,
        optionVals: newSelectedItems.map(i => i.name),
      }
      promises.push(likeProduct(productId, body, { shopProduct: product, optionKeys: newOptionKeys }));
    }

    const { router, params, location, routes, logEventClick } = this.props;
    const currentRoute = routes[routes.length - 1];
    const page_name = currentRoute.name;
    const paramValues = Object.values(params);
    const productIdForEvent = product.goodsNo || product.godoGoodsNo || product._id;
    let page_key = null;
    if (paramValues.length > 0 && page_name !== "MyProfile") {
      page_key = paramValues[0];
    } else if(page_name == "SearchResult") {
      page_key = router.location.state.searchKeyword || '';
    }

    if (deslectedItems.length > 0) {
      logEventClick({
        page_name,
        page_key,
        click_object_name: 'wishlist_del',
        click_object_key: productIdForEvent
      });
    }

    if (newSelectedItems.length > 0) {
      logEventClick({
        page_name,
        page_key,
        click_object_name: 'wishlist_add',
        click_object_key: productIdForEvent
      });
    }

    Promise.all(promises)
      .then(() => onConfirm(selectedItemList));
  }

  renderBottomButton() {
    return (
      <View style={styles.bottomButtonContainer}>
        <TouchableHighlightWithContainer
          containerStyle={styles.bottomButton}
          onPress={this.onPressConfirm}
        >
          <Text style={styles.confirmButton}>선택완료</Text>
        </TouchableHighlightWithContainer>
      </View>
    );
  }

  renderBody() {
    const {
      product,
      selectedItemList,
      onPressSizeGuide,
      onChangeSelectedItemList,
      modalProps,
      setModalProps,
    } = this.props;

    if (!product)
      return null;

    // console.log(product);
    // console.log(product.option);
    let optionList = product && product.option ? product.option : [];

    const optionImageList = product.optionImage;

    optionList = optionList ? optionList : (product.option.optionList ? product.option.optionList : []);
    const itemList = optionList.map(o => {

      const _id = o.sno;
      const image = optionImageList.find(el => el.sno === _id);

      return {
        id: o.sno,
        optionPrice: o.optionPrice,
        name: o.optionValue,
        image: image ? image.url : ""
      }
    });

    const {
      windowHeight
    } = this.state;

    const listContainerStyle = {
      maxHeight: windowHeight * 0.9 - 87 - 57 - 49 - 3,
    };

    // console.log('AddToWishlist.renderBody selectedItemList=', selectedItemList);

    return (
      <View style={styles.container}>
        <Text style={styles.description}>
          사이즈 중복 선택 가능, 품절 상품 선택시 재입고 알림 제공
        </Text>
        <SizeListWithCheckBox
          onChangeSelectedItemList={onChangeSelectedItemList}
          selectedItemList={selectedItemList}
          itemList={itemList}
          containerStyle={styles.listContainer}
          onPressSizeGuide={onPressSizeGuide}
          ListContainerStyle={listContainerStyle}
          modalProps={modalProps}
          setModalProps={setModalProps}
          />
        {this.renderBottomButton()}
      </View>
    );
  }
}


export default connect(
  (state) => ({ auth: state.auth }),
  actions,
)(withRouter(AddToWishlist));
