import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M16.95 3.515a1 1 0 011.414 0l2.121 2.121a1 1 0 010 1.414l-10.44 10.44a1 1 0 01-.39.242l-3.182 1.06a1 1 0 01-1.265-1.265l1.06-3.182a1 1 0 01.242-.39l10.44-10.44z"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        stroke="#000"
        strokeWidth={1.5}
        d="M15.0052 5.45893L18.5407 8.99446"
      />
    </Svg>
  )
}

export default SvgComponent

