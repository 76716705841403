import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
// import { adminGetPostCount, adminGetUserCount } from 'react-shared/adminActions';
import { loadEntities } from 'react-shared/util';
import { Link } from 'react-router';
import {
  AppBar,
  Drawer,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MuiThemeProvider,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import MapIcon from '@material-ui/icons/Map';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleIcon from '@material-ui/icons/People';
import ReorderIcon from '@material-ui/icons/Reorder';
import ReplyIcon from '@material-ui/icons/Reply';
import StarsIcon from '@material-ui/icons/Stars';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import EditorialIcon from '@material-ui/icons/FilterNone';
import BannerIcon from '@material-ui/icons/Exposure';
import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import NewReleasesIcon from '@material-ui/icons/NewReleases';

// const styles = theme => ({
const styles = () => ({
  list: {
    minWidth: 260,
  },
});

const theme = createTheme({
  typography: {
    fontSize: 24,
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    require('../../css/modules/html.module.css');
    this.state = {
      drawerOpen: false,
      // userCount: '',
      // postCount: '',
    };
    // this.toggleDrawer.bind(this);
  }

  toggleDrawer = () => {
    // this.setState({ drawerOpen: !this.state.drawerOpen });
    this.setState(prevState => ({ drawerOpen: !prevState.drawerOpen }));
  };

  handleNavChange(event, selectedIndex, menuItem) {
    this.props.router.push(menuItem.route);
  }

  componentDidMount() {
    // this.props.adminGetUserCount().then(json => this.setState({ userCount: json.count }));
    // this.props.adminGetPostCount().then(json => this.setState({ postCount: json.count }));
  }

  render() {
    const { children, classes } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <AppBar style={{ background: '#ff6699' }} position="sticky">
          <Toolbar>
            <IconButton color="inherit" aria-label="Menu" onClick={this.toggleDrawer}>
              {/* .bind(this)}> */}
              <MenuIcon />
            </IconButton>
            <Link to="/admin/posts" style={{ textDecoration: 'none', color: 'white' }}>
              <Typography color="inherit" variant="h4">
                Admin
              </Typography>
            </Link>
          </Toolbar>
        </AppBar>
        <Drawer open={this.state.drawerOpen} onClose={this.toggleDrawer}>
          {/* .bind(this)}> */}
          <div role="button" tabIndex="0" onClick={this.toggleDrawer}>
            {/* .bind(this)}> */}
            <List className={classes.list}>
              <ListItem button dense onClick={() => this.props.router.push('/')}>
                <ListItemIcon>
                  <ReplyIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Go to the main page" />
              </ListItem>
              <Divider />
              <ListItem button dense onClick={() => this.props.router.push('/admin/dashboard')}>
                <ListItemIcon>
                  <TrendingUpIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Dashboard" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/dashboard2')}>
                <ListItemIcon>
                  <TrendingUpIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Dashboard v2 (beta)" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/stats/click')}>
                <ListItemIcon>
                  <TrendingUpIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Stats (PV & Click)" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/stats/snapshot')}>
                <ListItemIcon>
                  <TrendingUpIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Stats (Snapshot)" />
              </ListItem>
              <Divider />
              <ListItem button dense onClick={() => this.props.router.push('/admin/stylePosts/new')}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="New style post" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/stylePosts')}>
                <ListItemIcon>
                  <ReorderIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="List style posts" />
              </ListItem>
              <Divider />
              <ListItem button dense onClick={() => this.props.router.push('/admin/editorials')}>
                <ListItemIcon>
                  <ReorderIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Manage editorials" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/mdCurations')}>
                <ListItemIcon>
                  <ReorderIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Manage exhibitions" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/mdCurations-v2')}>
                <ListItemIcon>
                  <ReorderIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Manage exhibitions v2" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/preorderExhibitions')}>
                <ListItemIcon>
                  <ReorderIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Manage preorders" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/preorderExhibitions-v2')}>
                <ListItemIcon>
                  <ReorderIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Manage preorders v2" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/stylePosts')}>
                <ListItemIcon>
                  <ReorderIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Manage style posts" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/heartitCurations')}>
                <ListItemIcon>
                  <ReorderIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Manage Heart it Curations" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/categorySales')}>
                <ListItemIcon>
                  <ReorderIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Manage Category Sales" />
              </ListItem>
              <Divider />
              <ListItem button dense onClick={() => this.props.router.push('/admin/products')}>
                <ListItemIcon>
                  <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Products" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/products/new')}>
                <ListItemIcon>
                  <AddShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="New Product" />
              </ListItem>
              <Divider />
              <ListItem button dense onClick={() => this.props.router.push('/admin/faq/category')}>
                <ListItemIcon>
                  <HelpOutlineIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="FAQ Category" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/faq')}>
                <ListItemIcon>
                  <HelpOutlineIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="FAQ" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/notice')}>
                <ListItemIcon>
                  <AnnouncementIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Notice" />
              </ListItem>
              <Divider />
              <ListItem button dense onClick={() => this.props.router.push('/admin/marketingPopups')}>
                <ListItemIcon>
                  <EditorialIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Marketing Popups" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/banners')}>
                <ListItemIcon>
                  <BannerIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Banners" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/users')}>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Users" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/creators')}>
                <ListItemIcon>
                  <StarsIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Creators" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/creators/profile')}>
                <ListItemIcon>
                  <StarsIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Creators Profile" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/searchKeywords')}>
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Search Keywords" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/guide')}>
                <ListItemIcon>
                  <MapIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="Guide" />
              </ListItem>
              <ListItem button dense onClick={() => this.props.router.push('/admin/appVersions')}>
                <ListItemIcon>
                  <NewReleasesIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary="App Versions" />
              </ListItem>
            </List>
          </div>
        </Drawer>
        {children}
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  children: PropTypes.node.isRequired,
  // adminGetPostCount: PropTypes.func.isRequired,
  // adminGetUserCount: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  connect(
    state => ({ users: loadEntities(state, 'users', 'users').users }),
    {},
    // { adminGetPostCount, adminGetUserCount },
  ),
)(App);
