import React from 'react';
import { StyleSheet, View, Text, FlatList } from 'react-native';
import { connect } from 'react-redux';

import { actions, loadEntities } from 'shared';
import ReviewItem from '../../../nativeComponents/ReviewItem';
import Spinner from '../../../nativeComponents/Spinner';
import Colors from '../../../constants/Colors';
import Styles from '../../../constants/Styles';
import Constants from '../../../constants/Constants';

import LikeNormalIcon from '../../../icons/LikeNormalIcon';
import LikeSelectedIcon from '../../../icons/LikeSelectedIcon';
import LikeSelectedHalfIcon from '../../../icons/LikeSelectedHalfIcon';


const REVIEW_LIST_PAGE_SIZE = 24;

const styles = StyleSheet.create({
  container: {
  },
  itemSeparator: {
    borderTopWidth: 1,
    borderTopColor: Colors.lightGray,
    marginStart: 20,
  },
  list: {
  },
  headerContainer: {
    paddingTop: 30,
    paddingBottom: 10,
    alignItems: 'center',
  },
  reviewStarText: {
    color: 'rgb(238, 36, 36)',
    fontSize: 14,
    marginTop: 7,
    ...Styles.appleSDGothicNeoSemiBold,
  },
  reviewIconContainer: {
    flexDirection: 'row',
  },
  reviewCount: {
    fontSize: 18,
    lineHeight: 21,
    marginBottom: 20,
    ...Styles.appleSDGothicNeoRegular,
  },
  likeIcon: {
    marginHorizontal: 3,
  },
});


function renderReviewStar(reviewStar) {
  const starCount = Math.max(Math.min(reviewStar, 5), 0);

  const stars = [];
  for (let i = 0; i < Math.floor(starCount); i++) {
    stars.push(
      <View key={`starOn${i}`} style={styles.likeIcon}>
        <LikeSelectedIcon  />
      </View>
    );
  }

  if (starCount % 1 >= 0.5) {
    stars.push(
      <View style={styles.likeIcon}>
        <LikeSelectedHalfIcon />
      </View>
    );
  }

  const iconCount = stars.length;
  for (let i = 0; i < 5 - iconCount; i++) {
    stars.push(
      <View key={`starOff${i}`} style={styles.likeIcon}>
        <LikeNormalIcon opacity={0.251} />
      </View>
    );
  }
  return (
    <View style={styles.reviewIconContainer}>
      {stars}
    </View>
  );
}


class ProductReviewsPage extends React.Component {
  state = {
    refreshing: false,
    isFetchingNextPage: false,
  };

  componentDidMount() {
    const {
      productId,
      loadShopProduct,
      logEventPageview,
    } = this.props;

    loadShopProduct({ productId });
    this.loadReviewList(false, { count: REVIEW_LIST_PAGE_SIZE });

    const { setGlobalLayoutInfo } = this.props;
    setGlobalLayoutInfo('hideChatInquiry', true);

    logEventPageview({ page_name: 'ProductReviews', page_key: productId });
  }

  componentWillUnmount() {
    const { setGlobalLayoutInfo } = this.props;
    setGlobalLayoutInfo('hideChatInquiry', false);
  }

  componentDidUpdate(prevProps, prevState) {
    const { productId, reviewListPagination,
      reviewListArticles, loadShopProductReviewArticle } = this.props;
    const { reviewListPagination: prevPagination } = prevProps;
    const { isFetchingNextPage: prevIsFetchingNextPage } = prevState;

    if (prevPagination.isFetching && !reviewListPagination.isFetching) {
      /*
      if (!prevIsFetchingNextPage) {
        loadShopProductReviewArticle(productId, reviewListArticles[0].sno);
      }
      */
      this.setState({ isFetchingNextPage: false, refreshing: false });
    }
  }

  renderItem = ({ item, index }) => {
    const { productId } = this.props;

    return (
      <ReviewItem
        //largeThumbnail={index === 0}
        item={item}
        productId={productId}
        detailEnabled
        onDeleteItem={this.onDeleteItem}
        controlButtonEnabled={false}
      />
    );
  };

  onDeleteItem = () => {
    this.refresh();
  }

  loadReviewList = (nextPage, query) => {
    const { productId, loadShopProductReviewList } = this.props;
    this.setState({ isFetchingNextPage: nextPage }, () => {
      loadShopProductReviewList(productId, nextPage, query);
    });
  }

  refresh() {
    const { refreshing } = this.state;
    if (!refreshing) {
      this.setState({ refreshing: true }, () => {
        this.loadReviewList(false, { count: REVIEW_LIST_PAGE_SIZE });
      });
    }
  }

  renderHeader = () => {
    const { product: { reviewCnt, reviewStar } } = this.props;

    return (
      <View style={styles.headerContainer}>
        <Text style={styles.reviewCount}>
          {`리뷰 총 ${reviewCnt}건`}
        </Text>
        {renderReviewStar(reviewStar)}
        <Text style={styles.reviewStarText}>
          {`평균 ${reviewStar}점`}
        </Text>

      </View>
    );
  }

  render() {
    const {
      reviewListArticles: articles,
      reviewListPagination,
      product,
    } = this.props;

    if (!product) {
      return null;
    }

    const { refreshing, isFetchingNextPage } = this.state;
    if (reviewListPagination.isFetching && !isFetchingNextPage) {
      return (
        <Spinner />
      );
    }

    // console.log('MyReviewsScreen render reviewListArticles=', reviewListArticles);

    return (
      <View style={styles.container}>
        <FlatList
          contentContainerStyle={styles.list}
          data={articles}
          keyExtractor={item => item.sno}
          renderItem={this.renderItem}
          ListHeaderComponent={this.renderHeader}
          ListFooterComponent={this.renderFooter}
          ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
          onEndReached={() => {
            if(reviewListPagination.nextPageUrl !== null && !reviewListPagination.isFetching) {
              this.loadReviewList(true, { count: REVIEW_LIST_PAGE_SIZE });
            }
          }}
          onRefresh={() => { this.refresh(); }}
          refreshing={refreshing}
          onLayout={this.onLayout}
        />
      </View>
    );
  }
}

export default connect(
  (state, ownProps) => {
    const { productId } = ownProps.params;
    if (!productId) {
      return null;
    }
    let product = null;
    if (state.entities.shopProducts.hasOwnProperty(productId)) {
      product = state.entities.shopProducts[productId];
    }

    return {
      ...loadEntities(state,
        actions.getShopProductReviewListPaginationKey(productId),
        actions.getShopProductReviewListEntitiesKey(productId),
        'reviewListPagination',
        'reviewListArticles'),
      productId,
      product,
    };
  },
  actions,
)(ProductReviewsPage);
