import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from 'components/Spinner';
import { loadEntities } from 'react-shared';
import * as adminActions from 'react-shared/adminActions';
import { Link } from 'react-router';
import HeartitCuration from 'components/admin/HeartitCuration';

const styles = {
  container: {
    margin: '0 auto',
    maxWidth: '450px',
  },
};

export class ListHeartitCurationPage extends Component {
  componentDidMount() {
    const { adminHeartitCurations, adminLoadHeartitCurations } = this.props;
    if (!adminHeartitCurations || adminHeartitCurations.length === 0 || adminHeartitCurations.filter(x => x.visible).length > 1) {
      adminLoadHeartitCurations();
    }
  }

  render() {
    const {
      adminHeartitCurations,
      pagination: { isFetching },
    } = this.props;
    const heartitCurationsList = adminHeartitCurations
      .sort(
      (a, b) => {
        if (b.visible && !a.visible) {
          return 1;
        } else if (a.visible && !b.visible) {
          return -1;
        } else {
          return b.created - a.created;
        }
        return 0;
      });

    return (
      <div style={styles.container}>
        <br />
        <Link className="btn btn-primary" to="/admin/heartitCurations/new">
          Heartit Curation 추가
        </Link>
        {heartitCurationsList.length > 0
          && heartitCurationsList.map(heartitCuration => (
            <HeartitCuration
              heartitCuration={heartitCuration}
              key={`heartitCuration_${heartitCuration._id}`}
            />
          ))}
        {isFetching && <Spinner />}
      </div>
    );
  }
}

ListHeartitCurationPage.propTypes = {
  adminLoadHeartitCurations: PropTypes.func.isRequired,
  adminHeartitCurations: PropTypes.array,
  // pagination: PropTypes.object,
};

ListHeartitCurationPage.defaultProps = {
  adminHeartitCurations: {},
};

export const mapStateToProps = state => ({
  ...loadEntities(state, 'adminHeartitCurations', 'adminHeartitCurations'),
});

export default connect(
  mapStateToProps,
  adminActions,
)(ListHeartitCurationPage);
