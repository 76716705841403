import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Editorial from 'components/Editorial';
import Spinner from 'components/Spinner';
import { actions, loadEntities } from 'react-shared';

const styles = {
  container: {
    margin: '0 auto',
    maxWidth: '450px',
  },
};

export class EditorialPage extends Component {
  componentDidMount() {
    const { editorials, loadEditorials } = this.props;
    if (!editorials || editorials.length === 0) {
      loadEditorials();
    }
  }

  render() {
    const { editorials, pagination: { isFetching } } = this.props;
    const editorialsList = editorials
      .map(editorial => Object.assign({}, editorial, { user: 'heartit.kr', createdDate: editorial.createdDate * 1000 }))
      .sort((a, b) => b.createdDate - a.createdDate);
    return (
      <div style={styles.container}>
        {editorialsList.length > 0 && editorialsList.map(
          editorial => <Editorial editorial={editorial} key={`editorial_${editorial.createdDate}`} />
        )}
        {isFetching && <Spinner />}
      </div>
    );
  }
}

EditorialPage.propTypes = {
  loadEditorials: PropTypes.func.isRequired,
  editorials: PropTypes.array,
  pagination: PropTypes.object.isRequired,
};

EditorialPage.defaultProps = {
  editorials: {},
};

export const mapStateToProps = state => ({ ...loadEntities(state, 'editorials', 'editorials') });

export default connect(
  mapStateToProps,
  actions
)(EditorialPage);
