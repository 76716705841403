import * as React from "react"
import Svg, { Path } from "react-native-svg"

function ChevronRightIcon(props) {
  const { color='#000' } = props;
  return (
    <Svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M5 11l4-4M5 3l4 4"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default ChevronRightIcon

