import React from 'react';
import { StyleSheet, View, Text } from 'react-native';

import { Constants, Styles } from '../../../constants';

import {
  CheckBox, Button,
} from '../../../components-v2';

const styles = StyleSheet.create({
  headerContainer: {
    flexShrink: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: Constants.viewHorizontalPadding,
    paddingTop: 13,
    paddingBottom: 12,
    width: '100%',
    backgroundColor: '#ffffff',
  },
  headerSelectAllContainer: {
    flexDirection: 'row'
  },
  headerText: {
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
    ...Styles.pretendard600,
  },
  headerButtonContainer: {
    flexDirection: 'row',
    paddingTop: 1,
    marginHorizontal: -25 / 2,
  },
  headerButton: {
    marginVertical: -1 * Constants.touchEffectWidth,
    marginHorizontal: 25 / 2 - 1 * Constants.touchEffectWidth,
    padding: Constants.touchEffectWidth,
    borderRadius: Constants.touchEffectBorderRadius,
    borderWidth: 0,
    borderColor: 'transparent',
  },
  headerButtonText: {
    fontSize: 14,
    lineHeight: 16.8,
    height: 17,
    ...Styles.pretendard400,
  },
});

class Header extends React.Component {
  render() {
    const {
      disabled,
      countResult,
      likedProducts,
      editMode,
      selectedItem,
      onPressSelectAll,
      onPressEdit,
      onPressAddToCart,
      onPressDeleteWishlist,
    } = this.props;

    return (
      <View style={styles.headerContainer}>
        <View style={styles.headerSelectAllContainer}>
          {editMode && (
            <CheckBox
              baseStyle={{ marginEnd: 10, marginTop: 3, marginBottom: 4 }}
              checked={selectedItem.length === likedProducts.length}
              onPress={onPressSelectAll}
            />
          )}
          <Text style={[styles.headerText, disabled ? { opacity: 0.3 } : {}]}>전체 상품 {countResult}개</Text>
        </View>
        <View style={styles.headerButtonContainer}>
          {!editMode && (
            <Button
              containerStyle={styles.headerButton}
              titleStyle={styles.headerButtonText}
              title="편집"
              disabled={disabled}
              disabledOpacity={0.3}
              onPress={onPressEdit}
            />
          )}
          {editMode && (
            <Button
              containerStyle={styles.headerButton}
              titleStyle={styles.headerButtonText}
              title="선택 쇼핑백에 담기"
              onPress={() => {
                onPressAddToCart(selectedItem);
              }}
            />
          )}
          {editMode && (
            <Button
              containerStyle={styles.headerButton}
              titleStyle={styles.headerButtonText}
              title="선택 삭제"
              onPress={() => {
                selectedItem.forEach(onPressDeleteWishlist);
              }}
            />
          )}
        </View>
      </View>
    );    
  }
}

export default Header;
