import React from 'react'
import PropTypes from 'prop-types';
import { Dimensions, StyleSheet, View, Text } from 'react-native';

import { Styles, Constants, SizeData } from '../../../constants';
import {
  BottomSheetModal,
  TouchableHighlightWithContainer,
  SizeListWithCheckBox,
 } from '../../../components-v2';
import showFilterSizeGuideModal from '../../../components-v2/ProductFilterControl/filter/FilterSizeGuideModal';


const {
  width: windowWidth,
  height: windowHeight,
} = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
  },
  listContainer: {
    maxHeight: windowHeight - (
      Constants.mainHeaderHeight
      + 83
      + 20 + 16 + 14 + Constants.bottomMargin
    ),
  },
  footerContainer: {
    position: 'absolute',
    width:"100%",
    bottom: 0,
  },
  footerBottomContainer: {
    width: '100%',
    backgroundColor: '#ffffff',
    flexDirection: 'column',
    backgroundColor: '#000',
  },
  footerButtonContainer: {
    width: '100%',
    paddingTop: 20,
    alignItems: 'center',
    paddingBottom: 12 + Constants.bottomMargin,
  },
  footerButtonText: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    color: '#fff',
    opacity: 0.5,
  },
  footerButtonTextActive: {
    opacity: 1,
  },
});


class SizeSelectorModal extends BottomSheetModal {
  title = '사이즈 선택하기'
  subTitle = '사이즈 중복 선택 가능'

  componentDidMount() {
    const { selectedSizeList } = this.props;
    this.setState({ selectedItemList : selectedSizeList });
  }

  renderBottomButton() {
    const {
      onPressSelector: onPressSelectorParent,
    } = this.props;
    const {
      selectedItemList=[],
    } = this.state;

    const footerButtonTextStyles = [
      styles.footerButtonText,
    ];


    let onPressSelector = () => null;

    if (selectedItemList.length > 0) {
      footerButtonTextStyles.push(styles.footerButtonTextActive)
      onPressSelector = onPressSelectorParent;
    }

    return (
      <View style={styles.footerContainer}>
        <View style={styles.footerBottomContainer}>
          <TouchableHighlightWithContainer
            onPress={() => onPressSelector(selectedItemList)}
            >
            <View style={styles.footerButtonContainer}>
              <Text style={footerButtonTextStyles}>선택완료</Text>
            </View>
          </TouchableHighlightWithContainer>
        </View>
      </View>
    );
  }

  renderBody() {
    const {
      type,
      modalProps,
      setModalProps,
    } = this.props;
    const {
      selectedItemList=[]
    } = this.state;

    const optionList = SizeData.SIZE_LIST.filter((e) => e.subType == type);

    const onChangeSelectedItemList = selectedItemList => {
      this.setState({ selectedItemList });
    };

    const onPressSizeGuide = () => {
      showFilterSizeGuideModal(this, type);
    }

    return(
      <View style={styles.container}>
        <SizeListWithCheckBox
          onChangeSelectedItemList={onChangeSelectedItemList}
          selectedItemList={selectedItemList}
          itemList={optionList}
          containerStyle={styles.listContainer}
          onPressSizeGuide={onPressSizeGuide}
          subType={type}
          modalProps={modalProps}
          setModalProps={setModalProps}
          />
        {this.renderBottomButton()}
      </View>
    )
  }
}

SizeSelectorModal.propTypes = {
  type: PropTypes.string.isRequired,
  selectedItemList: PropTypes.array,
}

SizeSelectorModal.defaultProps = {
  selectedItemList: [],
}

export default SizeSelectorModal;
