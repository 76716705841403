import React from 'react'
import { connect } from 'react-redux';
import { 
  StyleSheet, View, Text, 
} from 'react-native';
import MultiRangeSlider from "multi-range-slider-react";
import NumberFormat from 'react-number-format';

import { actions } from 'shared';

import { Colors, Constants, Styles } from '../../../../constants';

import FilterBaseModal from '../FilterBaseModal';


const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  title: {
    ...Styles.pretendard600,
    fontSize: 14,
    lineHeight: 18,
    color: Colors.defaultGray08,
    marginBottom: 22,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginStart: 3,
    marginEnd: 1,
  },
  priceGuideTitleText: {
    ...Styles.pretendard600,
    fontSize: 14,
    lineHeight: 16.8,
    height: 17,
  },
  priceGuideValueText: {
    ...Styles.pretendard400,
    fontSize: 14,
    lineHeight: 16.8,
    height: 17,
  },
  sliderContainer: {
    marginTop: 30,
    marginHorizontal: Constants.viewHorizontalPadding + 10,
  },
  sliderTextContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 1,
  },
  sliderText: {
    ...Styles.pretendard400,
    fontSize: 16,
    lineHeight: 22,
  },
});


class FilterPriceModal extends FilterBaseModal {
  title = '가격';

  state = {
    priceMin: 0,
    priceMax: 100000,
  };

  componentDidMount() {
    const {
      priceMin, priceMax, priceFilter,
      logEventPageview,
    } = this.props;

    this.setState({ priceMin, priceMax });

    if (priceFilter) {
      this.setState({ priceFilter });
    }

    logEventPageview({ page_name: 'FilterPrice' });
  }

  onSliderValuesChange(values) {

    this.setState({
      priceFilter: {
        min: values[0],
        max: values[1],
        type: 'price',
      }
    });
  }

  onPressApplyButton() {
    const { onChange } = this.props;
    const { priceMin, priceMax } = this.state;

    const {
      priceFilter={
        min: priceMin,
        max: priceMax,
        type: 'price',
      }
    } = this.state;

    const {
      modalProps,
      setModalProps,
    } = this.props;

    const {
      historyStack=[],
    } = modalProps();

    const children = historyStack.pop();

    setModalProps({
      children,
      historyStack,
    }, () => {
      onChange([priceFilter]);
    });
  }

  renderSlider() {
    const { priceMin, priceMax } = this.state;

    const {
      priceFilter={
        min: priceMin,
        max: priceMax,
        type: 'price',
      }
    } = this.state;

    const {
      min: selectedPriceMin,
      max: selectedPriceMax,
     } = priceFilter;

     const handleInput = (e) => {
      this.onSliderValuesChange([e.minValue, e.maxValue]);
    };

    return (
      <View style={styles.sliderContainer}>
        <MultiRangeSlider
          min={priceMin}
          max={priceMax}
          step={1000}
          minValue={selectedPriceMin}
          maxValue={selectedPriceMax}
          onInput={(e) => {
            handleInput(e);
          }}
          ruler={false}
          label={false}
          preventWheel={true}
          />
        <View style={styles.sliderTextContainer}>
          <NumberFormat
            value={selectedPriceMin}
            displayType="text"
            thousandSeparator
            renderText={value => (
              <Text style={styles.sliderText}>{value}원</Text>
            )}
            />
          <Text style={styles.sliderText}> ~ </Text>
          <NumberFormat
            value={selectedPriceMax}
            displayType="text"
            thousandSeparator
            renderText={value => (
              <Text style={styles.sliderText}>{value}원</Text>
            )}
            />
        </View>
      </View>
    );
  }

  renderBody() {
    const {
      priceMin,
      priceMax,
    } = this.state;

    return (
      <View style={styles.container}>
        <Text style={styles.title}>가격 범위</Text>
        <View style={styles.row}>
          <Text style={styles.priceGuideTitleText}>Min</Text>
          <Text style={styles.priceGuideTitleText}>Max</Text>
        </View>
        <View style={styles.row}>
          <NumberFormat
            value={priceMin}
            displayType="text"
            thousandSeparator
            renderText={value => (
              <Text style={styles.priceGuideValueText}>{value}원</Text>
            )}
            />
          <NumberFormat
            value={priceMax}
            displayType="text"
            thousandSeparator
            renderText={value => (
              <Text style={styles.priceGuideValueText}>{value}원</Text>
            )}
            />
        </View>
        {this.renderSlider()}
      </View>
    )
  }
}


export default connect(
  state => ({ auth: state.auth }),
  actions,
)(FilterPriceModal);
