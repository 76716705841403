import React from 'react';
import {
  StyleSheet, Text, View, TouchableHighlight,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions, loadEntities } from 'shared';
import MyPageHeartist from '../../nativeComponents/MyPageHeartist';

import Styles from '../../constants/Styles';
import Colors from '../../constants/Colors';
import Constants from '../../constants/Constants';
import HeartitBlankPageLogoSmall from '../../icons/HeartitBlankPageLogoSmall';


const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
  },
  listHeaderContainer: {
    paddingHorizontal: Constants.viewHorizontalPadding,
    paddingVertical: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listHeaderText: {
    fontSize: 20,
    lineHeight: 24,
    height: 24,
    color: 'black',
    ...Styles.appleSDGothicNeoBold,
  },
  showAllButtonContainer: {
    paddingTop: 3,
    paddingBottom: 4,
  },
  showAllText: {
    fontSize: 14,
    lineHeight: 17,
    color: 'black',
    ...Styles.appleSDGothicNeoSemiBold,
  },
  emptyFollowingHeartistsContainer: {
    paddingTop: 31,
    paddingBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyFollowingHeartistsMessage: {
    height: 21,
    marginTop: 10,
    opacity: 0.5,
    ...Styles.appleSDGothicNeoMedium,
    fontSize: 18,
    lineHeight: 21,
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});


export class FollowingHeartistsPage extends React.Component {
  componentDidMount() {
    this.loadData(false);
  }

  loadData(nextPage) {
    const { loadFollowingHeartists, loadFeaturedHeartists } = this.props;
    loadFollowingHeartists(nextPage);
    loadFeaturedHeartists(nextPage);
  }


  renderHeartist(heartist) {
    const { router, followUser, unfollowUser, auth } = this.props;

    return (
      <GridListTile key={heartist._id}>
        <MyPageHeartist
          heartist={heartist}
          router={router}
          followUser={followUser}
          unfollowUser={unfollowUser}
          auth={auth}
        />
      </GridListTile>
    );
  }

  renderListHeader = (title, showAll) => {
    const { router } = this.props;

    return (
      <View style={styles.listHeaderContainer}>
        <Text style={styles.listHeaderText}>
          {title}
        </Text>
        { showAll && (
          <View style={[styles.touchEffectContainer, styles.showAllButtonContainer]}>
            <TouchableHighlight
              onPress={() => router.push('/heartists')}
              style={styles.touchEffect}
              underlayColor={Colors.touchEffectColorWhite}
            >
              <Text style={styles.showAllText}>전체 보기</Text>
            </TouchableHighlight>
          </View>
        )}
      </View>
    );
  }

  renderFollowingHeartists() {
    const {
      followingHeartists,
    } = this.props;

    const data = followingHeartists.filter(heartist => heartist.following);

    return (
      <View>
        {this.renderListHeader('팔로우 중인 하티스트')}

        {data.length > 0 ? (
          <GridList
            cellHeight="auto"
            cols={1}
            spacing={0}
          >
            {data.map(item => this.renderHeartist(item))}
          </GridList>
        ) : (
          <View style={styles.emptyFollowingHeartistsContainer}>
            <HeartitBlankPageLogoSmall />
            <Text style={styles.emptyFollowingHeartistsMessage}>팔로우 중인 하티스트가 없습니다</Text>
          </View>
        )}

        <View style={{ height: 20 }} />
      </View>
    );
  }

  render() {
    const {
      featuredHeartists,
    } = this.props;

    return (
      <View style={styles.container}>
        {this.renderFollowingHeartists()}

        {this.renderListHeader('다른 하티스트 추천', true)}
        <GridList
          cellHeight="auto"
          cols={1}
          spacing={0}
        >
          {featuredHeartists
            .filter(heartist => !heartist.following)
            .map(item => this.renderHeartist(item))}
        </GridList>
      </View>
    );
  }
}


export default connect(
  state => ({
    auth: state.auth,
    ...loadEntities(state, 'followingHeartists', 'heartists', 'pagination', 'followingHeartists'),
    ...loadEntities(state, 'featuredHeartists', 'heartists', 'pagination', 'featuredHeartists'),
  }),
  actions,
)(FollowingHeartistsPage);
