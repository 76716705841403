import React from 'react';
import {
  StyleSheet, View, FlatList,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions, loadEntities } from 'shared';
import AlertDialog from '../../components/AlertDialog';
import Colors from '../../constants/Colors';
import Styles from '../../constants/Styles';
import ReviewItem from '../../nativeComponents/NewReviewItem';
import BlankResult from '../../nativeComponents/BlankResult';

const REVIEW_LIST_PAGE_SIZE = 24;


const styles = StyleSheet.create({
  container: {
  },
  itemSeparator: {
    borderTopWidth: 1,
    borderTopColor: Colors.lightGray,
    marginStart: 20,
  },
  list: {
  },
  emptyViewContiner: {
    position: 'absolute',
    start: 0,
    end: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyViewContentContainer: {
    alignItems: 'center',
    marginBottom: 30,
  },
  emptyViewTitle: {
    height: 24,
    ...Styles.appleSDGothicNeoBold,
    fontSize: 20,
    marginTop: 15,
    textAlign: 'center',
  },
  emptyViewDesc: {
    height: 19,
    opacity: 0.5,
    ...Styles.appleSDGothicNeoMedium,
    fontSize: 16,
    marginTop: 7,
    textAlign: 'center',
  },
  emptyViewButton: {
    marginTop: 30,
    height: 48,
    width: 250,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
  },
  emptyViewButtonText: {
    height: 17,
    ...Styles.appleSDGothicNeoBold,
    fontSize: 14,
    marginTop: 1,
    color: '#fff',
  },
});

class MyReviewsPage extends React.Component {
  state = {
    refreshing: false,
    alert: {
      open: false,
      title: null,
      content: null,
      buttons: [],
    },
  };

  componentDidMount() {
    // console.log('MyReviewsScreen componentDidMount');

    const { logEventPageview } = this.props;

    this.loadReviewList(false, { count: REVIEW_LIST_PAGE_SIZE });

    logEventPageview({ page_name: 'MyReviews' });
  }

  loadArticleDetail = articleId => () => {
    const { loadUserReviewDetail } = this.props;
    loadUserReviewDetail(articleId);
  }

  onPressModifyArticle = articleId => () => {
    const { router } = this.props;

    router.push(`/accounts/reviews/edit/${articleId}`);
  }

  onPressDeleteArticle = articleId => () => {
    const { deleteUserReview } = this.props;

    this.setState({
      alert: {
        open: true,
        title: '리뷰을 삭제하시겠습니까?',
        content: null,
        buttons: [
          {
            text: '삭제',
            onPress: async () => {
              this.setState({ alert: { open: false } });
              try {
                const ret = await deleteUserReview(articleId);

                if (!ret.success) {
                  this.alert(
                    `삭제 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다. (E0${ret.errorCode})`,
                  );
                  return;
                }

                this.alert('삭제되었습니다.', () => {
                  this.refresh();
                });
              } catch (error) {
                // console.log('onPressItemDeleteButton error', error);

                if (error.errorCode) {
                  this.alert(`삭제 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다. (E1${error.errorCode})`);
                } else {
                  this.alert('삭제 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.');
                }
              }
            },
          },
          {
            text: '취소',
            onPress: () => {
              this.setState({ alert: { open: false } });
            },
          },
        ],
      },
    });
  }

  renderItem = ({ item }) => (
    <ReviewItem
      article={item}
      detailEnabled
      loadArticleDetail={this.loadArticleDetail(item.sno)}
      controlButtonEnabled
      onPressModify={this.onPressModifyArticle(item.sno)}
      onPressDelete={this.onPressDeleteArticle(item.sno)}
    />
  );

  onDeleteItem = () => {
    this.refresh();
  }

  async loadReviewList(nextPage, query) {
    const { loadUserReviewList } = this.props;
    return loadUserReviewList(nextPage, query);
  }

  refresh() {
    const { refreshing } = this.state;
    if (!refreshing) {
      this.setState({ refreshing: true });
      this.loadReviewList(false, { count: REVIEW_LIST_PAGE_SIZE }).then(this.setState({ refreshing: false }));
    }
  }

  alert(msg, cb) {
    this.setState({
      alert: {
        open: true,
        title: msg,
        content: null,
        buttons: [
          {
            text: '확인',
            onPress: () => {
              if (cb) {
                cb();
              }
              this.setState({ alert: { open: false } });
            },
          },
        ],
      },
    });
  }

  renderAlert() {
    const {
      alert: {
        open,
        title,
        content,
        buttons,
      },
    } = this.state;

    return (
      <div>
        <AlertDialog
          open={open}
          title={title}
          content={content}
          buttons={buttons}
        />
      </div>
    );
  }

  renderEmptyView() {
    const { router } = this.props;

    return (
      <BlankResult
        router={router}
        title="아직 작성한 리뷰가 없습니다"
        description="내가 쓴 리뷰를 이곳에서 모아 볼 수 있습니다"
      />
    );
  }

  render() {
    const {
      userReviewList,
      pagination,
    } = this.props;

    // console.log('MyReviewsPage render userReviewList=', userReviewList);

    const { refreshing } = this.state;
    const articles = [
      ...userReviewList.slice(0, REVIEW_LIST_PAGE_SIZE),
    ];

    if (!pagination.isFetching && articles.length === 0) {
      return this.renderEmptyView();
    }

    return (
      <View style={styles.container}>
        <FlatList
          contentContainerStyle={styles.list}
          data={articles}
          keyExtractor={item => item.sno}
          renderItem={this.renderItem}
          ListHeaderComponent={this.renderHeader}
          ListFooterComponent={this.renderFooter}
          ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
          onEndReached={() => pagination.nextPageUrl
            && this.loadReviewList(true, { count: REVIEW_LIST_PAGE_SIZE })}
          onRefresh={() => { this.refresh(); }}
          refreshing={refreshing}
          onLayout={this.onLayout}
        />
        {this.renderAlert()}
      </View>
    );
  }
}

export default connect(
  state => ({
    ...loadEntities(state, 'userReviewList', 'userReviewList'),
  }),
  actions,
)(MyReviewsPage);
