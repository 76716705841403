import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View, Text } from 'react-native';
import NumberFormat from 'react-number-format';

import Styles from '../../../constants/Styles';

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    marginHorizontal: 20,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  rowLast: {
  },
  title: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    height: 19,
  },
  titleLast: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 15,
    height: 19,
  },
  body: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 15,
    height: 19,
  },
  bodyLast: {
    ...Styles.SFUITextSemiBold,
    fontSize: 24,
    height: 30,
  },
  promo: {
    color: '#ee2424',
  },
});


export default function Statement(props) {
  const {
    checkoutInfo: {
      settlePrice,
      totalDeliveryCharge = 0,
      totalCouponOrderDcPrice = 0,
      useMileage = 0,
      totalMemberDcPrice = 0,
      totalGoodsPrice,
    } = {},
  } = props;

  const items = [
    { key: 'totalGoodsPrice', name: '총 상품 금액', value: totalGoodsPrice, sign: '' },
    { key: 'totalDeliveryCharge', name: '총 배송비', value: totalDeliveryCharge, sign: '+' },
    { key: 'totalCouponOrderDcPrice', name: '쿠폰 할인', value: totalCouponOrderDcPrice, sign: '-' },
    { key: 'useMileage', name: '적립금 사용', value: useMileage, sign: '-' },
    { key: 'totalMemberDcPrice', name: '프로모션 할인', value: totalMemberDcPrice, sign: '-' },
  ];

  return (
    <View style={styles.container}>
      { items.map(item => {
        if (item.key === 'totalMemberDcPrice') {
          if (item.value) {
            return (
              <View key={item.key} style={styles.row}>
                <Text style={[styles.title, styles.promo]}>{item.name}</Text>
                <NumberFormat
                  value={item.value}
                  displayType="text"
                  thousandSeparator
                  renderText={value => (
                    <Text style={[styles.body, styles.promo]}>
                      {`${item.sign} ${value}원`}
                    </Text>
                  )}
                />
              </View>
            );
          } else {
            return null;
          }
        } else {
          return (
            <View key={item.key} style={styles.row}>
              <Text style={styles.title}>{item.name}</Text>
              <NumberFormat
                value={item.value}
                displayType="text"
                thousandSeparator
                renderText={value => (
                  <Text style={styles.body}>
                    {`${item.sign} ${value}원`}
                  </Text>
                )}
              />
            </View>
          );
        }
      })}

      <View style={[styles.row, styles.rowLast]}>
        <Text style={[styles.title, styles.titleLast]}>총 결제 금액</Text>
        <NumberFormat
          value={settlePrice}
          displayType="text"
          thousandSeparator
          renderText={value => (
            <Text style={[styles.body, styles.bodyLast]}>
              {value}
              <Text style={Styles.appleSDGothicNeoSemiBold}>원</Text>
            </Text>
          )}
        />
      </View>
    </View>
  );
}
