/* eslint-disable max-len */
import React from 'react';
import { Route } from 'react-router';
// import ga from 'react-ga';
import {
  ChangePasswordPage,
  FAQPage,
  ReviewWritePage,
  ReviewWriteSuccessPage,
  MyReviewsPage,
  EditorialPage,
  GreetingPage,
  ProductReviewsPage,
  CheckoutPaymentGatewayPage,
  CheckoutNaverPayGatewayPage,
  CheckoutNaverPayWithoutAuthGatewayPage,
  CheckoutNaverPayCartWithoutAuthGatewayPage,
  CheckoutFinishPage,
  HeartistsPage,
  HistoryPage,
  IntroPage,
  PostListPage,
  LegalPolicyPage,
  LoginWithEmailPage,
  LoginWithEmailVerifyPage,
  FindPasswordWithEmailPage,
  OutlinkGatewayPage,
} from '../containers';

import {
  App,
  HomePage,
  PreOrderPage,
  PreOrderDetailPage,
  ExhibitionPage,
  StylePage,
  StyleFollowPage,
  StyleDetail,
  StyleReply,
  StyleUpload,
  SearchPage,
  SearchResultPage,
  HeartistProfilePage,
  ShopPage,
  ProductPage,
  CartPage,
  WishlistPage,
  MyBrandPage,
  SettingsPage,
  EditProfilePage,
  AuthPage,
  NoticePage,
  NoticeViewPage,
  JoinWithEmailPage,
  JoinDonePage,
  AccountRecoveryPage,
  AppleIdCallbackPage,
  FacebookCallbackPage,
  NaverCallbackPage,
  KakaoCallbackPage,
  MySizeSelectPage,
  ShippingAddressEditPage,
  MembershipWithdrawPage,
  MemberInfoPage,
  MyOrdersPage,
  OrderDetailPage,
  OrderManagePage,
  OrderManageCompletePage,
  ProfilePage,
  EditMyProfilePage,
  FollowingHeartistsPage,
  FollowerHeartistsPage,
  CheckoutPage,
  BusinessInfoPage,
  PushHistoryPage,
  NoticeMainPage,
} from '../containers-v2';

export default function configure(store) {
  function redirectToIntro(nextState, replace) {
    const state = store.getState();
    if (!state.auth.token) {
      //alert('로그인해주시기 바랍니다.');
      //replace('/accounts/login');
    }
  }

  function redirectToNotice(nextState, replace){
    replace('/update');
  }

  return (
    <Route component={App}>
      <Route path="/update" component={NoticeMainPage} />
      <Route path="*" onEnter={redirectToNotice}/>
      <Route path="/accounts/faq" component={FAQPage} />
      <Route path="/accounts/login" component={AuthPage} />
      <Route path="/accounts/login/email" component={LoginWithEmailPage} />
      <Route path="/accounts/login/email/verify" component={LoginWithEmailVerifyPage} />
      <Route path="/accounts/login/email/recovery" component={AccountRecoveryPage} />
      <Route path="/accounts/login/email/findPassword" component={FindPasswordWithEmailPage} />
      <Route path="/accounts/settings" component={SettingsPage} />
      <Route path="/accounts/profile/edit" component={EditMyProfilePage} onEnter={redirectToIntro} />
      <Route name="MyProfile" path="/accounts/profile/:tabname" component={ProfilePage} onEnter={redirectToIntro} />
      <Route path="/accounts/edit" component={EditProfilePage} onEnter={redirectToIntro} />
      <Route path="/accounts/changePassword" component={ChangePasswordPage} onEnter={redirectToIntro} />
      <Route path="/accounts/withdraw" component={MembershipWithdrawPage} onEnter={redirectToIntro} />
      <Route path="/accounts/following" component={FollowingHeartistsPage} onEnter={redirectToIntro} />
      <Route path="/accounts/follower" component={FollowerHeartistsPage} onEnter={redirectToIntro} />
      <Route path="/accounts/orders" component={MyOrdersPage} onEnter={redirectToIntro} />
      <Route path="/accounts/orders/:orderNo" component={OrderDetailPage} onEnter={redirectToIntro} />
      <Route path="/accounts/orderManage/:type/:orderNo" component={OrderManagePage} onEnter={redirectToIntro} />
      <Route path="/accounts/orderManageComplete/:type/:orderNo" component={OrderManageCompletePage} onEnter={redirectToIntro} />
      <Route path="/accounts/orders/:orderNo/orderItem/:orderItemNo/reviewWrite" component={ReviewWritePage} onEnter={redirectToIntro} />
      <Route path="/accounts/reviewWriteSuccess" component={ReviewWriteSuccessPage} onEnter={redirectToIntro} />
      <Route path="/accounts/reviewEditSuccess" component={ReviewWriteSuccessPage} onEnter={redirectToIntro} />
      <Route path="/accounts/reviews" component={MyReviewsPage} onEnter={redirectToIntro} />
      <Route path="/accounts/reviews/edit/:articleId" component={ReviewWritePage} onEnter={redirectToIntro} />
      <Route path="/accounts/login/facebook" component={FacebookCallbackPage} />
      <Route path="/accounts/login/naver" component={NaverCallbackPage} />
      <Route path="/accounts/login/kakao" component={KakaoCallbackPage} />
      <Route path="/accounts/login/apple" component={AppleIdCallbackPage} />
      <Route path="/accounts/join/email" component={JoinWithEmailPage} />
      <Route path="/accounts/join/done" component={JoinDonePage} />
      <Route path="/accounts/shippingAddress/edit/:shippingAddressId" component={ShippingAddressEditPage} />
      <Route path="/accounts/shippingAddress/create" component={ShippingAddressEditPage} />
      <Route path="/accounts/mysize/:type" component={MySizeSelectPage} onEnter={redirectToIntro} />
      <Route path="/accounts/memberInfo" component={MemberInfoPage} onEnter={redirectToIntro} />
      <Route path="/notice" component={NoticePage} />
      <Route path="/notice/:noticeId" component={NoticeViewPage} />
      <Route path="/editorial" component={EditorialPage} />
      <Route path="/greeting" component={GreetingPage} onEnter={redirectToIntro} />
      <Route name="HeartistProfile" path="/heartists/:userId" component={HeartistProfilePage} />
      <Route path="/heartists" component={HeartistsPage} />
      <Route path="/style/all" component={StylePage} />
      <Route path="/style/following" component={StyleFollowPage} />
      <Route name="StyleDetail" path="/style/feed/:stylePostId" component={StyleDetail} />
      <Route path="/style/feed/:stylePostId/replies" component={StyleReply} />
      <Route path="/style/upload(/:stylePostId)" component={StyleUpload} />
      <Route name="ShopScreen" path="/shop" component={ShopPage} />
      <Route path="/shop/cart" component={CartPage} />
      <Route path="/shop/checkout/:query" component={CheckoutPage} />
      <Route path="/shop/checkoutNaverPay" component={CheckoutNaverPayGatewayPage} />
      <Route path="/shop/checkoutNaverPayNoAuthCart" component={CheckoutNaverPayCartWithoutAuthGatewayPage} />
      <Route path="/shop/checkoutNaverPayNoAuthCart/:query" component={CheckoutNaverPayCartWithoutAuthGatewayPage} />
      <Route path="/shop/checkoutNaverPay/:query" component={CheckoutNaverPayGatewayPage} />
      <Route path="/shop/checkoutNaverPayNoAuth/:query" component={CheckoutNaverPayWithoutAuthGatewayPage} />
      <Route path="/shop/checkoutPaymentGateway/:query" component={CheckoutPaymentGatewayPage} />
      <Route path="/shop/checkoutFinish/:query" component={CheckoutFinishPage} />
      <Route path="/shop/search" component={SearchPage} />
      <Route name="SearchResult" path="/shop/search/result" component={SearchResultPage} />
      <Route name="Product" path="/product/:productId" component={ProductPage} />
      <Route path="/product/:productId/reviews" component={ProductReviewsPage} />
      <Route name="MyBrand" path="/favorite/brand" component={MyBrandPage} onEnter={redirectToIntro} />
      <Route name="Wishlist" path="/favorite" component={WishlistPage} onEnter={redirectToIntro} />
      <Route path="/intro" component={IntroPage} />
      <Route path="/legal/:type" component={LegalPolicyPage} />
      <Route path="/businessInfo" component={BusinessInfoPage} />
      <Route path="/history" component={HistoryPage} onEnter={redirectToIntro} />
      <Route path="/posts/:postId" component={PostListPage} />
      <Route path="/push" component={PushHistoryPage} onEnter={redirectToIntro} />
      <Route path="/outlink" component={OutlinkGatewayPage} />
      <Route name="Exhibition" path="/exhibition/:exhibitionId" component={ExhibitionPage} />
      <Route path="/preorder" component={PreOrderPage} />
      <Route name="PreOrderDetail" path="/preorder/detail/:preorderExhibitionId" component={PreOrderDetailPage} />
      <Route path="/:userId" component={HeartistProfilePage} />
      <Route name="Home" path="/" component={HomePage} />
    </Route>
  );
}
