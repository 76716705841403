import React from 'react';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const daySuffix = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];

const styles = {
  monthAndDateAndYear: {
    color: '#909090',
    fontSize: '13px',
  },
  daySuffix: {
    color: '#909090',
    fontSize: '8px',
    lineHeight: '36px',
    verticalAlign: 'top',
  },
};
// eslint-disable-next-line no-confusing-arrow
const PostDate = props => props.date instanceof Date ? (
  <div>
    <span style={styles.monthAndDateAndYear}>{`${months[props.date.getMonth()]} ${props.date.getDate()}`}</span>
    <span style={styles.daySuffix}>{daySuffix[props.date.getDate() % 10]}</span>
    <span style={styles.monthAndDateAndYear}>{` ${props.date.getFullYear()}`}</span>
  </div>
) : '';

export default PostDate;
