import * as React from "react"
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg"

function LoginAppleIcon(props) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G clipPath="url(#clip0_586_7660)" fill="#000">
        <Path d="M18.034 12.686c-.027-2.784 2.284-4.12 2.383-4.182-1.297-1.892-3.32-2.155-4.039-2.187-1.721-.173-3.362 1.01-4.237 1.01s-2.222-.981-3.648-.953C6.613 6.4 4.886 7.46 3.919 9.13c-1.95 3.366-.5 8.356 1.402 11.085.927 1.337 2.033 2.838 3.486 2.784 1.402-.054 1.93-.9 3.624-.9 1.694 0 2.168.9 3.65.878 1.505-.032 2.46-1.369 3.379-2.705a12.156 12.156 0 001.532-3.132c-.025-.012-2.93-1.121-2.958-4.454zM15.248 4.511c.774-.93 1.295-2.223 1.15-3.511-1.11.042-2.457.738-3.252 1.665-.718.826-1.347 2.145-1.176 3.405 1.238.094 2.508-.628 3.278-1.559z" />
      </G>
      <Defs>
        <ClipPath id="clip0_586_7660">
          <Path fill="#fff" transform="translate(3 1)" d="M0 0H18V22H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default LoginAppleIcon

