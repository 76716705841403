import React from 'react';

import {
  StyleSheet, View, Text,
} from 'react-native';
import { TouchableHighlightWithContainer } from '../../../components-v2';
import { Styles } from '../../../constants';
import { ChevronDownIcon } from '../../../icons-v2';

import { browserHistory } from 'react-router';


const styles = StyleSheet.create({
  container: {
  },
  titleContainer: {
    paddingVertical: 24.5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleTextContainer: {
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleText: {
    ...Styles.pretendard400,
    fontSize: 16,
    lineHeight: 19.2,
  },
  reviewRateText: {
    ...Styles.pretendard400,
    fontSize: 14,
    lineHeight: 16.8,
    color: 'black',
    opacity: 0.5,
    marginRight: 20,
  },
  titleIcon: {
    marginEnd: 3,
  },
  bodyContainer: {
  },
});


class ProductReviews extends React.PureComponent {

  state = {
    opened: false,
  }

  render() {
    const {
      containerStyle,
      product,
      productId,
    } = this.props;

    const {
      opened
    } = this.state;

    const titleIconStyle = {};
    const onPress = () => {
      browserHistory.push(`/product/${productId}/reviews`);
      this.setState({ opened: !opened });
    }

    if (opened) {
      titleIconStyle.transform = [
        { scaleY: -1 }
      ];
    }

    const reviewCount = product.reviewCnt;
    const reviewRate = product.reviewStar;

    return (
      <View style={[styles.container, containerStyle]}>
        <TouchableHighlightWithContainer
          onPress={onPress}
        >
          <View style={styles.titleContainer}>
            <View style={styles.titleTextContainer}>
              <Text style={styles.titleText}>상품 리뷰 {reviewCount} 개</Text>
              <Text style={styles.reviewRateText}>{reviewRate}점</Text>
            </View>
            <ChevronDownIcon style={[styles.titleIcon, titleIconStyle]} />
          </View>
        </TouchableHighlightWithContainer>
        <View style={styles.bodyContainer}>

        </View>
      </View>
    );
  }
}

export default ProductReviews;
