import React from 'react';
import { connect } from 'react-redux';
import { actions } from 'shared';
import { StyleSheet, View, Animated, TouchableHighlight } from 'react-native';

import HomeFeedSectionHeader from './HomeFeedSectionHeader';

import TimeSaleProduct from '../../../../components-v2/TimeSaleProduct';
import { Constants, Colors, Styles } from '../../../../constants';

import { navigateToProductPage } from '../../../shop/ProductPage';

import _styles from '../../../../css/modules/carousel.module.css';
import { ArrowForwardIosIcon, ArrowBackIosIcon } from '../../../../icons-v2';

const styles = StyleSheet.create({
  container: {
    marginTop: 24,
  },
  listContainer: {
  },
  productContainerStyle: {
    width: 112,
  },
  productImageStyle: {
    width: 112,
    aspectRatio: 112/149,
    borderWidth: 1,
    borderColor: Colors.productImageBorder,
    overflow: 'hidden',
  },
  productTextContainer: {
    marginTop: 4,
  },
  products: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  flatlist: {
  },
});


class FeedTimeSaleList extends React.PureComponent {

  listRef = React.createRef();

  state = {
    isLeftEnd:false,
    isRightEnd:false,
    nowIndex:0
  }

  renderItem = (product) => {
    const { router, title, logEventClick } = this.props;

    const onPressProduct = item => {
      const {
        goodsNo: productId,
      } = product;

      logEventClick({
        page_name: 'Home',
        page_key: title,
        click_object_name: 'product',
        click_object_key: productId
      });
      navigateToProductPage({
        productId,
      }, router);
    };

    return (
      <TouchableHighlight
        underlayColor={Colors.touchEffectColorWhite}
        onPress={onPressProduct}
        style={Styles.touchEffect}
      >
        <TimeSaleProduct
          product={product}
        />
      </TouchableHighlight>
    );
  }

  handleLeftClickEvent(e) {

    const nowIndex = this.state.nowIndex;
    let newIndex = 0;
    if(nowIndex > 2){
      newIndex = nowIndex - 2;
      this.listRef.scrollToIndex({animated: true, index: newIndex});
    }else{
      newIndex = 0;
      this.listRef.scrollToOffset({animated: true, index: newIndex});
    }
    this.setState({nowIndex : newIndex});
  }

  handleRightClickEvent(e) {
    const nowIndex = this.state.nowIndex;
    let newIndex = nowIndex + 2;
    try{
      this.listRef.scrollToIndex({animated: true, index: newIndex});
      this.setState({nowIndex : newIndex});
    }catch(e){

    }
  }

  render() {
    const {
      title,
      products,
      onPressSeeAll=null,
    } = this.props;

    // 품절 상품 제거
    const _products = products.filter((el) => (el.soldOut && (el.soldOut.toUpperCase() === 'N') ));

    const headerProps = {}
    if (onPressSeeAll != null) {
      headerProps.showSeeAll = true
      headerProps.onPressSeeAll = onPressSeeAll
    }

    const {
      isLeftEnd,
      isRightEnd,
    } = this.state;

    return (
      <View style={styles.container}>
        <HomeFeedSectionHeader
          title={title}
          {...headerProps}
          />
        <div className={_styles._desktop_carousel}>
          <View style={[styles.products, Styles.touchEffectContainer, styles.listContainer]}>
            <Animated.FlatList
              ref={ref => this.listRef = ref }
              ListFooterComponent={<View style={{ width: Constants.viewHorizontalPadding }}/>}
              keyExtractor={(item, idx) => { return `${item._id}-${idx}`}} // eslint-disable-line no-underscore-dangle
              ListHeaderComponent={<View style={{ width: Constants.viewHorizontalPadding }}/>}
              ItemSeparatorComponent={() => <View style={{ width: 3 - Constants.touchEffectWidth * 2 }} />}
              showsHorizontalScrollIndicator={false}
              renderItem={({ item }) => this.renderItem(item)}
              data={_products}
              style={styles.flatlist}
              horizontal
            />
            {!isLeftEnd &&
            <div className={_styles._desktop_carousel__left}
              style={{height : `${221}px`}}
              onClick={this.handleLeftClickEvent.bind(this)}>
              <ArrowBackIosIcon
                color={"#333333"}
                style={{position:'absolute', top:'50%', left:'50%', width:"24px", height:'24px', transform:'translate(-50%, -50%)'}}/>
            </div>}
            {!isRightEnd &&
            <div className={_styles._desktop_carousel__right}
              style={{height : `${221}px`}}
              onClick={this.handleRightClickEvent.bind(this)}>
              <ArrowForwardIosIcon
                color={"#333333"}
                style={{position:'absolute', top:'50%', left:'50%', width:"24px", height:'24px', transform:'translate(-50%, -50%)'}}/>
            </div>}
          </View>
        </div>
      </View>
    );
  }
}


export default connect(
  (state) => {
    return {
    };
  },
  actions,
)(FeedTimeSaleList);
