import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InstagramProfile from './InstagramProfile';
import FacebookProfile from './FacebookProfile';

const styles = {
  container: {
    height: '57px',
    lineHeight: '57px',
    margin: '40px 0',
    textAlign: 'center',
  },
  image: {
    backgroundColor: '#fbfbfb',
    borderRadius: '50%',
    boxSizing: 'border-box',
    display: 'inline-block',
    height: '50px',
    marginRight: '15px',
    width: '50px',
  },
};

export default class UserAvatarName extends Component {
  render() {
    const { auth: { instagram, facebook, username } } = this.props;
    return (
      <div style={styles.container}>
        {instagram && <InstagramProfile instagramId={username} style={styles.image} />}
        {!instagram && facebook && <FacebookProfile facebookId={facebook.id} style={styles.image} />}
        {username}
      </div>
    );
  }
}

UserAvatarName.propTypes = {
  auth: PropTypes.object.isRequired,
};
