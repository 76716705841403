import React from 'react';
import {
  StyleSheet, View, Text,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import queryString from 'query-string';

// eslint-disable-next-line import/no-extraneous-dependencies
import { getTrackingLink } from 'shared';

import Styles from '../constants/Styles';

import spinnerImage from '../assets/images/heartit_loading.gif';


const REDIRECT_DELAY_MS = 2000;

const styles = StyleSheet.create({
  spinnerContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  spinnerInnerContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinnerText: {
    ...Styles.appleSDGothicNeoMedium,
    width: 227,
    fontSize: 14,
    lineHeight: 20,
    color: 'rgba(0, 0, 0, 0.4)',
    textAlign: 'center',
  },
});

const webStyles = {
  spinnerImage: {
    width: 100,
    height: 100,
  },
};

class OutlinkGatewayPage extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      const parsed = queryString.parse(this.props.location.search.substring(1));
      const { trackingLink } = parsed;
      window.location.href = trackingLink;
    }, REDIRECT_DELAY_MS);
  }

  render() {
    return (
      <View style={styles.spinnerContainer}>
        <View style={styles.spinnerInnerContainer}>
          <img src={spinnerImage} alt="Logo" style={webStyles.spinnerImage} />
          <Text style={styles.spinnerText}>
            하트잇은 본 상품판매에 관여하지 않으며 상품 주문, 배송 및 환불의 의무와 책임은 해당 쇼핑몰에 있으니 상품정보와 가격을 신중히 확인해 주세요.
          </Text>
        </View>
      </View>
    );
  }
}

export function openOutlinkPage(product, post, historySwitch, token) {
  // eslint-disable-next-line no-prototype-builtins
  if (!post && product.hasOwnProperty('post')) {
    const { post: productPost } = product;
    post = productPost;
  }

  const retail = product.retails[product.defaultRetailIdx];
  const link = retail.orgLink || retail.link;
  const trackingLink = getTrackingLink(link, post, historySwitch, token);

  const qs = queryString.stringify({
    trackingLink,
  });

  window.open(`/outlink?${qs}`);
}

export function openTrackingOutlinkPage(trackingLink) {
  const qs = queryString.stringify({
    trackingLink,
  });

  window.open(`/outlink?${qs}`);
}

export default OutlinkGatewayPage;
