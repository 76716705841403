import * as React from 'react';

function SvgSymbolHeartitLogo(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
        <g fill="none" fillRule="evenodd">
            <g stroke="#FF3E3E">
                <g>
                    <g transform="translate(-236 -632) translate(235 631) translate(.5 .5)">
                        <rect width="14.5" height="14.5" x=".75" y=".75" fill="#FFF" strokeWidth=".5" rx="1"/>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth=".4" d="M7.909 11.064c-.58-.278-1.148-.593-1.677-.98-.53-.386-1.018-.852-1.407-1.413-.19-.283-.359-.585-.488-.908-.129-.322-.225-.66-.282-1.006-.137-.691-.018-1.466.367-2.058.188-.298.44-.549.73-.729.289-.18.622-.283.957-.28.167-.001.334.027.493.077.158.052.31.125.45.219.278.187.513.436.701.716.095.14.182.285.259.437.042.08.068.162.092.247.023.084.04.17.047.26.007.089.005.183-.027.274-.03.09-.099.17-.182.21-.086.042-.183.042-.268.01-.086-.031-.16-.093-.212-.169-.108-.153-.129-.347-.132-.517-.004-.173.002-.348.029-.521.05-.346.18-.685.387-.963.204-.28.475-.5.77-.658.295-.157.613-.261.945-.3.339-.039.677.02.992.148.314.127.605.327.84.588.238.26.418.58.526.923.055.173.091.345.12.52.029.175.047.35.055.527.035.708-.091 1.412-.312 2.07-.227.656-.55 1.269-.94 1.822-.391.553-.847 1.049-1.346 1.484-.25.217-.51.42-.778.607-.269.188-.545.362-.829.52-.283.158-.573.3-.868.43-.294.13-.593.248-.897.349l-.01-.031c1.172-.513 2.28-1.193 3.223-2.078.469-.443.898-.934 1.261-1.475.363-.54.666-1.127.871-1.75.212-.62.323-1.28.305-1.933-.005-.163-.02-.326-.043-.487-.024-.16-.056-.321-.101-.471-.09-.303-.242-.582-.445-.812-.202-.232-.455-.411-.73-.532-.139-.06-.283-.104-.43-.132-.147-.026-.295-.04-.443-.027-.299.026-.6.108-.878.24-.278.133-.534.321-.73.57-.198.246-.33.552-.39.875-.03.162-.043.328-.041.495.002.084.002.165.019.242.015.077.041.15.082.212.04.062.097.11.162.134.064.025.137.024.196-.004.062-.03.11-.083.133-.153.025-.069.03-.15.023-.229-.006-.08-.023-.16-.046-.239-.023-.078-.05-.158-.086-.225-.076-.147-.16-.288-.256-.42-.19-.264-.419-.5-.688-.658-.133-.08-.276-.14-.422-.18-.147-.038-.298-.056-.448-.048-.3.014-.592.12-.844.294-.252.173-.464.41-.627.678-.161.27-.271.573-.326.888-.026.158-.037.319-.036.48 0 .16.018.318.045.482.102.65.342 1.272.695 1.816.353.545.808 1.013 1.308 1.42.5.409 1.044.758 1.596 1.092l-.014.03z"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  );
}

export default SvgSymbolHeartitLogo;
