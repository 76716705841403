import { schema } from 'normalizr';

export const user = new schema.Entity(
  'users',
  {},
  {
    idAttribute: '_id',
  }
);

export const users = [user];

export const comment = new schema.Entity(
  'comments',
  {},
  {
    idAttribute: '_id',
  }
);

export const comments = [comment];

export const post = new schema.Entity(
  'posts',
  {
    comments,
  },
  {
    idAttribute: '_id',
  }
);

export const posts = [post];

export const stylePost = new schema.Entity(
  'stylePosts',
  {
    comments,
  },
  {
    idAttribute: '_id',
    processStrategy: (entity) => {
      if (!entity.owner.name) {
        entity.owner.name = entity.owner.username.split('@')[0];
      }
      return entity;
    },
  }
);

export const stylePosts = [stylePost];

export const shopBrand = new schema.Entity(
  'shopBrandList',
  {},
  {
    idAttribute: 'cateCd',
  }
);

export const shopBrandList = [shopBrand];

export const followingStylePost = new schema.Entity(
  'followingStylePosts',
  {
    comments,
  },
  {
    idAttribute: '_id',
    processStrategy: (entity) => {
      if (!entity.owner.name) {
        entity.owner.name = entity.owner.username.split('@')[0];
      }
      return entity;
    },
  }
);

export const followingStylePosts = [followingStylePost];

export const feedBeforePost = new schema.Entity(
  'feedBeforePosts',
  {},
  {
    idAttribute: '_id',
  }
);

export const feedBeforePosts = [feedBeforePost];

export const feedAfterPost = new schema.Entity(
  'feedAfterPosts',
  {},
  {
    idAttribute: '_id',
  }
);

export const feedAfterPosts = [feedAfterPost];

export const promotion = new schema.Entity(
  'promotions',
  {},
  {
    idAttribute: '_id',
  }
);

export const promotions = [promotion];

export const collection = new schema.Entity(
  'collections',
  {},
  {
    idAttribute: '_id',
  }
);

export const collections = [collection];

export const follower = new schema.Entity(
  'followers',
  {},
  {
    idAttribute: '_id',
  }
);

export const followers = [follower];

export const heartist = new schema.Entity(
  'heartists',
  {},
  {
    idAttribute: '_id',
  }
);

export const heartists = [heartist];

export const heartistsPick = new schema.Entity(
  'heartistsPicks',
  {},
  {
    idAttribute: '_id',
  }
);

export const heartistsPicks = [heartistsPick];


export const editorial = new schema.Entity(
  'editorials',
  {},
  {
    idAttribute: 'id',
  }
);

export const editorials = [editorial];

export const adminEditorial = new schema.Entity(
  'adminEditorials',
  {},
  {
    idAttribute: 'id',
  }
);

export const adminEditorials = [adminEditorial];

export const mdCuration = new schema.Entity(
  'mdCurations',
  {},
  {
    idAttribute: 'id',
  }
);

export const mdCurations = [mdCuration];

export const adminMdCuration = new schema.Entity(
  'adminMdCurations',
  {},
  {
    idAttribute: 'id',
  }
);

export const adminMdCurations = [adminMdCuration];

export const categorySale = new schema.Entity(
  'categorySales',
  {},
  {
    idAttribute: 'id',
  }
);

export const categorySales = [categorySale];

export const adminCategorySale = new schema.Entity(
  'adminCategorySales',
  {},
  {
    idAttribute: 'id',
  }
);

export const adminCategorySales = [adminCategorySale];

export const heartitCuration = new schema.Entity(
  'heartitCurations',
  {},
  {
    idAttribute: '_id',
  }
);

export const heartitCurations = [heartitCuration];

export const adminHeartitCuration = new schema.Entity(
  'adminHeartitCurations',
  {},
  {
    idAttribute: '_id',
  }
);

export const adminHeartitCurations = [adminHeartitCuration];

export const adminPreorderExhibition = new schema.Entity(
  'adminPreorderExhibitions',
  {},
  {
    idAttribute: 'id',
  }
);

export const adminPreorderExhibitions = [adminPreorderExhibition];

export const preorderExhibition = new schema.Entity(
  'preorderExhibitions',
  {},
  {
    idAttribute: 'id',
  }
);

export const preorderExhibitions = [preorderExhibition];

export const banner = new schema.Entity(
  'banners',
  {},
  {
    idAttribute: 'id',
  }
);

export const banners = [banner];

export const adminMarketingPopup = new schema.Entity(
  'adminMarketingPopups',
  {},
  {
    idAttribute: '_id',
  }
);

export const adminMarketingPopups = [adminMarketingPopup];

export const adminProduct = new schema.Entity(
  'adminProducts',
  {},
  {
    idAttribute: '_id',
  }
);

export const adminProducts = [adminProduct];

export const adminProductMatchingPost = new schema.Entity(
  'adminProductMatchingPosts',
  {},
  {
    idAttribute: '_id',
  }
);

export const adminProductMatchingPosts = [adminProductMatchingPost];

export const adminFaqCategory = new schema.Entity(
  'adminFaqCategories',
  {},
  {
    idAttribute: '_id',
  }
);

export const adminFaqCategories = [adminFaqCategory];

export const adminFaq = new schema.Entity(
  'adminFaqs',
  {},
  {
    idAttribute: '_id',
  }
);

export const adminFaqs = [adminFaq];

export const adminNotice = new schema.Entity(
  'adminNotices',
  {},
  {
    idAttribute: '_id',
  }
);

export const adminNotices = [adminNotice];

export const notice = new schema.Entity(
  'notices',
  {},
  {
    idAttribute: '_id',
  }
);

export const notices = [notice];

export const history = new schema.Entity(
  'histories',
  {},
  {
    idAttribute: '_id',
  }
);

export const histories = [history];

export const homeNewProduct = new schema.Entity(
  'homeNewProducts',
  {},
  {
    idAttribute: 'goodsNo',
  }
);

export const homeNewProducts = [homeNewProduct];

export const homeDailyPopularProduct = new schema.Entity(
  'homeDailyPopularProducts',
  {},
  {
    idAttribute: 'goodsNo',
  }
);

export const homeDailyPopularProducts = [homeDailyPopularProduct];

export const homeWeeklyPopularProduct = new schema.Entity(
  'homeWeeklyPopularProducts',
  {},
  {
    idAttribute: 'goodsNo',
  }
);

export const homeWeeklyPopularProducts = [homeWeeklyPopularProduct];

export const homeStylePickPost = new schema.Entity(
  'homeStylePickPosts',
  {},
  {
    idAttribute: '_id',
  }
);

export const homeStylePickPosts = [homeStylePickPost];

export const homeMdCuration = new schema.Entity(
  'homeMdCurations',
  {},
  {
    idAttribute: 'id',
  }
);

export const homeMdCurations = [homeMdCuration];

export const homeCategorySale = new schema.Entity(
  'homeCategorySales',
  {},
  {
    idAttribute: 'id',
  }
);

export const homeCategorySales = [homeCategorySale];

export const homeHeartitCuration = new schema.Entity(
  'homeHeartitCurations',
  {},
  {
    idAttribute: '_id',
  }
);

export const homeTimeSale = new schema.Entity(
  'homeTimeSales',
  {},
  {
    idAttribute: 'goodsNo',
  }
);

export const homeTimeSales = [homeTimeSale];

export const homeHeartitCurations = [homeHeartitCuration];

export const shopProduct = new schema.Entity(
  'shopProducts',
  {},
  {
    idAttribute: 'goodsNo',
  }
);

export const shopProducts = [shopProduct] ;

export const shopProductsRelatedPost = new schema.Entity(
  'shopProductsRelatedPosts',
  {},
  {
    idAttribute: '_id',
  }
);

export const shopProductsRelatedPosts = [shopProductsRelatedPost];

export const likedProduct = new schema.Entity(
  'likedProducts',
  {},
  {
    idAttribute: 'likeId',
  }
);
export const likedProducts = [likedProduct];

export const shippingAddress = new schema.Entity(
  'shippingAddresses',
  {},
  {
    idAttribute: 'sno',
  }
);

export const shippingAddresses = [shippingAddress];

export const order = new schema.Entity(
  'userOrderList',
  {},
  {
    idAttribute: 'orderNo',
  }
);

export const userOrderList = [order];

export const userCoupon = new schema.Entity(
  'userCouponList',
  {},
  {
    idAttribute: 'memberCouponNo',
  }
);

export const userCouponList = [userCoupon];

export const userMileage = new schema.Entity(
  'userMileageList',
  {},
  {
    idAttribute: 'sno',
  }
);

export const userMileageList = [userMileage];

export const orderDetail = new schema.Entity(
  'orderDetails',
  {},
  {
    idAttribute: 'orderNo',
  }
);

export const orderDetails = [orderDetail];

export const pushNotification = new schema.Entity(
  'pushNotifications',
  {},
  {
    idAttribute: 'id',
  }
);

export const pushNotifications = [pushNotification];

export const userReview = new schema.Entity(
  'userReviewList',
  {},
  {
    idAttribute: 'sno',
  }
);

export const userReviewList = [userReview];

export const accumulatedStatProduct = new schema.Entity(
  'accumulatedStatProduct',
  {},
  {
    idAttribute: (value) => value.product._id,
  }
)

export const accumulatedStatProducts = [accumulatedStatProduct]

export const marketingPopup = new schema.Entity(
  'marketingPopups',
  {},
  {
    idAttribute: '_id',
  }
);

export const marketingPopups = [marketingPopup];

export const adminAndroidAppVersion = new schema.Entity(
  'adminAndroidAppVersions',
  {},
  {
    idAttribute: '_id',
  }
);

export const adminAndroidAppVersions = [adminAndroidAppVersion];

export const adminIosAppVersion = new schema.Entity(
  'adminIosAppVersions',
  {},
  {
    idAttribute: '_id',
  }
);

export const adminIosAppVersions = [adminIosAppVersion];

export const pushHistory = new schema.Entity(
  'pushHistories',
  {},
  {
    idAttribute: '_id',
  }
);

export const pushHistories = [pushHistory];

export const partnerShippingProduct = new schema.Entity(
  'partnerShippingProducts',
  {},
  {
    idAttribute: '_id',
  }
);

export const partnerShippingProducts = [partnerShippingProduct];
