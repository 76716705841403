import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloudinary } from '../cloudinary';
import config from '../config';

const styles = {
  container: {
    position: 'relative',
    height: 0,
    paddingTop: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    objectFit: 'cover',
  },
  video: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    objectFit: 'cover',
  },
};

export default class Media extends Component {
  render() {
    const { media: { publicId, resourceType, thumbnailUrl, url }, style, options } = this.props;

    function renderVideo() {
      /* const videoOptions = Object.assign({}, options, {
        controls: true,
        preload: 'auto',
      }); */
      return null;
    }
    return (
      <div style={Object.assign({}, styles.container, style)}>
        {resourceType === 'image' ? (
          <img
            src={cloudinary.url(publicId, options)}
            style={styles.image}
            alt=""
          />
        ) : (
          <video
            src={url}
            style={styles.video}
            controls={'controls'}
          />
        )}
      </div>
    );
  }
}

Media.defaultProps = {
  options: {},
  style: {},
};

Media.propTypes = {
  media: PropTypes.shape({
    publicId: PropTypes.string.isRequired,
    resourceType: PropTypes.string.isRequired,
  }).isRequired,
  style: PropTypes.object,
  options: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    crop: PropTypes.string,
    format: PropTypes.string,
  }),
};
