import React from 'react';
import { ScrollView, Text, Alert, Platform,
  TextInput, TouchableHighlight,
  StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import { actions, loadEntities } from 'shared';

import { Colors, Styles, Constants }from '../../../constants';

import Button from '../../../components-v2/Button';
import CheckBox from '../../../components-v2/CheckBox';

import Ic14ArrowDown from '../../../icons-v2/Ic14ArrowDown';
import Ic14ListSelected from '../../../icons-v2/Ic14ListSelected';
import RadioNormal from '../../../icons-v2/RadioNormalIcon';
import RadioSelected from '../../../icons-v2/RadioSelectedIcon';

import { sectionStyles, modalStyles } from './commonStyles';


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  pickupMethodButton: {
    height: 17,
    padding: 0,
    marginVertical: 10,
    borderWidth: 0,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexShrink: 1,
  },
  pickupMethodLine: {
    flexShrink: 1,
    flexDirection: 'row',
  },
  pickupMethodText: {
    color: '#000',
    fontSize: 14,
    lineHeight: 16.8,
    marginStart: 8,
    ...Styles.pretendard400,
  },
  addressText: {
    marginBottom: 10,
    color: '#000',
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard400,
  },
  bankInput: {
    paddingVertical: 13,
    paddingHorizontal: 20,
    borderWidth: 0.5,
    borderColor: '#000',
    marginTop: 10,
  },
  bankSelector: {
    height: 44,
    borderWidth: 0.5,
    paddingHorizontal: 20,
    paddingVertical: 13,
  },
  bankSelectorInnerContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bankSelectorText: {
    color: '#000',
    fontSize: 15,
    lineHeight: 18,
    ...Styles.pretendard400,
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
  checkboxLabel: {
    marginStart: 8,
    color: '#000',
    fontSize: 14,
    lineHeight: 16.8,
    ...Styles.pretendard600,
  },
  returnAddressLine: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  returnAddressValue: {
    flexGrow: 1,
  },
  returnAddressValueText: {
    fontSize: 16,
    lineHeight: 19.2,
    color: '#000',
    ...Styles.pretendard400,
  },
  returnAddressLabel: {
    width: 100,
  },
  returnAddressLabelText: {
    color: '#00000080',
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard400,
  },
  headerButton: {
    borderWidth: 0,
    borderRadius: Constants.touchEffectBorderRadius,
    padding: Constants.touchEffectWidth,
    marginVertical: 1 - Constants.touchEffectWidth,
    marginHorizontal: -1 * Constants.touchEffectWidth,
  },
});


function renderReceiverAddress(orderInfo) {
  if (!orderInfo) {
    return null;
  }

  const {
    receiverName, receiverCellPhone,
    receiverAddress, receiverAddressSub,
    orderMemo,
  } = orderInfo;
  return (
    <View style={sectionStyles.sectionContainer}>
      <View style={{ width: 185 }}>
        <Text style={styles.addressText}>{receiverName}</Text>
        <Text style={styles.addressText}>{receiverAddress + ', ' + receiverAddressSub}</Text>
        <Text style={[styles.addressText, {marginBottom: 0}]}>{receiverCellPhone}</Text>
      </View>
    </View>
  );
}

class RefundFeeSelector extends React.Component {
  shippingAddressInfoRef = React.createRef();

  showSelectorModal = () => {
    const { setModalProps } = this.props;
    setModalProps({
      isVisible: true,
      isBottomPlaced: true,
      children: this.renderSelectorModal()
    });
  }

  renderSelectorModal = () => {
    const { setModalProps, selectedBank, onChangeSelectedBank } = this.props;
    const bankList = [
      "KB국민은행",
      "IBK기업은행",
      "신한은행",
      "우리은행",
      "KEB하나은행",
      "KDB산업은행",
      "SC제일은행",
      "한국씨티은행",
      "농협",
      "수협",
      "신협",
      "우체국",
      "새마을금고",
      "HSBC",
      "경남은행",
      "광주은행",
      "대구은행",
      "부산은행",
      "전북은행",
      "제주은행",
      "서울상호저축은행",
      "솔로몬저축은행",
      "신민상호저축은행",
      "제일상호저축은행",
      "토마토상호저축은행",
      "푸른상호저축은행",
      "한국진흥경기상호저축은행",
      "현대스위스상호저축은행",
      "HK상호저축은행"
    ];

    const onClick = () => {
      setModalProps({isVisible: false, isBottomPlaced: false});
    };

    return (
      <div onClick={onClick}>
        <View style={modalStyles.innerContainer} onClick={e => e.stopPropagation()}>
          <View style={modalStyles.headerContainer}>
            <View style={{flex:1}}/>
            <View style={modalStyles.headerTitleContainer}>
              <Text style={modalStyles.headerTitleText}>
                은행 선택
              </Text>
            </View>
            <View style={{flex:1, alignItems:'flex-end'}}>
              <TouchableHighlight
                onPress={() => onClick()}
                underlayColor={Colors.touchEffectColorWhite}
                style={modalStyles.headerButton}
              >
                <Text style={modalStyles.headerButtonText}>닫기</Text>
              </TouchableHighlight>
            </View>
          </View>
          <ScrollView style={modalStyles.contentContainer}>
            {bankList.map((bank, index) => {
              return (
                <TouchableHighlight
                  key={index}
                  style={modalStyles.selectorButton}
                  underlayColor={Colors.touchEffectColorWhite}
                  onPress={() => {
                    onClick();
                    onChangeSelectedBank(bank);
                  }}
                >
                  <View style={modalStyles.contentLineContainer}>
                    <Text style={[modalStyles.contentText, (bank == selectedBank) ? modalStyles.selectedContent: {}]}>
                      {bank}
                    </Text>
                    {(bank == selectedBank) && (
                      <Ic14ListSelected />
                    )}
                  </View>
                </TouchableHighlight>
              );
            })}
          </ScrollView>
        </View>
      </div>
    );
  }

  renderRefundBankAccount = () => {
    const {
      selectedBank, onChangeSelectedBank,
      accountName, onChangeAccountName,
      accountNumber, onChangeAccountNumber,
    } = this.props;

    return (
      <View>
        <View style={sectionStyles.sectionHeaderContainer}>
          <Text style={sectionStyles.sectionHeaderText}>
            환불 받을 계좌
          </Text>
        </View>

        <View style={sectionStyles.sectionContainer}>
          <Button
            containerStyle={styles.bankSelector}
            innerComponent={(
              <View style={styles.bankSelectorInnerContainer}>
                <Text style={styles.bankSelectorText}>
                  {selectedBank ? selectedBank: "은행 선택"}
                </Text>
                <Ic14ArrowDown />
              </View>
            )}
            onPress={() => this.showSelectorModal()}
          />

          <TextInput
            onChangeText={(text) => onChangeAccountName(text)}
            value={accountName}
            style={styles.bankInput}
            placeholder="예금주"
            placeholderTextColor="#00000080"
          />

          <TextInput
            onChangeText={(text) => onChangeAccountNumber(text)}
            value={accountNumber}
            style={styles.bankInput}
            placeholder="계좌번호"
            placeholderTextColor="#00000080"
          />
        </View>
      </View>
    );
  }

  renderRefundFeeMethod = () => {
    const { selectedRefundFeeMethod, onChangeSelectedRefundFeeMethod } = this.props;

    return (
      <View style={sectionStyles.sectionContainer}>
        <Button
          containerStyle={styles.pickupMethodButton}
          innerComponent={(
            <View style={styles.pickupMethodLine}>
              {selectedRefundFeeMethod == "deduction" ? <RadioSelected /> : <RadioNormal />}
              <Text style={[styles.pickupMethodText, selectedRefundFeeMethod == "deduction" ? {...Styles.pretendard600}: {}]}>환불금액에서 차감</Text>
            </View>
          )}
          onPress={() => {
            onChangeSelectedRefundFeeMethod("deduction");
          }}
        />
        <Button
          containerStyle={styles.pickupMethodButton}
          innerComponent={(
            <View style={styles.pickupMethodLine}>
              {selectedRefundFeeMethod == "insideBox" ? <RadioSelected /> : <RadioNormal />}
              <Text style={[styles.pickupMethodText, selectedRefundFeeMethod == "insideBox" ? {...Styles.pretendard600}: {}]}>택배박스에 동봉</Text>
            </View>
          )}
          onPress={() => {
            onChangeSelectedRefundFeeMethod("insideBox");
          }}
        />
        <Button
          containerStyle={[styles.pickupMethodButton, {marginBottom: 0}]}
          innerComponent={(
            <View style={styles.pickupMethodLine}>
              {selectedRefundFeeMethod == "bankTransfer" ? <RadioSelected /> : <RadioNormal />}
              <Text style={[styles.pickupMethodText, selectedRefundFeeMethod == "bankTransfer" ? {...Styles.pretendard600}: {}]}>계좌 이체</Text>
            </View>
          )}
          onPress={() => {
            onChangeSelectedRefundFeeMethod("bankTransfer");
          }}
        />
      </View>
    );
  }

  render() {
    const { selectedRefundFeeMethod } = this.props;
    return (
      <View style={styles.container}>
        {this.renderRefundFeeMethod()}

        <View style={sectionStyles.sectionSeparator} />
        {this.renderRefundBankAccount()}

      </View>
    );
  }
}

export default RefundFeeSelector;
