import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';

import CheckNormalIcon from '../icons/CheckNormalIcon';
import CheckSelectedIcon from '../icons/CheckSelectedIcon';


export default function CheckBox(props) {
  const {
    onPress: onPressCallback,
    baseStyle,
    activeStyle,
    fixed,
    checked,
  } = props;

  const [isChecked, setChecked] = useState(false);

  if (checked !== isChecked) {
    setChecked(checked);
  }

  const onPress = () => {
    if (fixed) {
      return;
    }

    setChecked(!isChecked);
    if (onPressCallback !== undefined) {
      onPressCallback(!isChecked);
    }
  };

  const Icon = isChecked ? CheckSelectedIcon : CheckNormalIcon;

  const styles = {
    ...baseStyle,
    ...(isChecked && activeStyle),
  };

  return (
    <TouchableOpacity
      onPress={onPress}
    >
      <Icon style={styles} />
    </TouchableOpacity>
  );
}
