import set from 'lodash/set';
import { normalize } from 'normalizr';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';


export function loadHomeDailyPopularProducts() {
  const key = 'homeDailyPopularProducts';

  return createFetchAction({
    type: 'LOAD_HOME_DAILY_POPULAR_PRODUCTS',
    endpoint: '/api/v2/shop/product/popular/daily',
    transform: ({ json }) => normalize(json.data.goodsData, schemas.homeDailyPopularProducts),
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}

export function loadHomeWeeklyPopularProducts() {
  const key = 'homeWeeklyPopularProducts';

  return createFetchAction({
    type: 'LOAD_HOME_WEEKLY_POPULAR_PRODUCTS',
    endpoint: '/api/v2/shop/product/popular/weekly',
    transform: ({ json }) => normalize(json.data.goodsData, schemas.homeWeeklyPopularProducts),
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}

export function loadHomeNewProducts() {
  const key = 'homeNewProducts';

  return createFetchAction({
    type: 'LOAD_HOME_NEW_PRODUCTS',
    endpoint: '/api/v2/shop/product/new',
    transform: ({ json }) => normalize(json.data.goodsData, schemas.homeNewProducts),
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}

export function loadHomeStylePickPosts() {
  const key = 'homeStylePickPosts';

  return createFetchAction({
    type: 'LOAD_HOME_STYLE_PICK_POSTS',
    endpoint: '/api/v2/stylePosts/popular/tag',
    transform: ({ json }) => {
      const data = json.map(datum => {
        const post = datum.stylePost;
        post.popularTag = datum.tag;
        return post;
      });
      return normalize(data, schemas.homeStylePickPosts);
    },
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}

export function loadHomeMdCurations() {
  const key = 'homeMdCurations';

  return createFetchAction({
    type: 'LOAD_HOME_MD_CURATIONS',
    endpoint: '/api/v2/mdCurations/',
    transform: ({ json }) => normalize(json, schemas.homeMdCurations),
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}

export function loadHomeHeartitCurations() {
  const key = 'homeHeartitCurations';

  return createFetchAction({
    type: 'LOAD_HOME_HEARTIT_CURATIONS',
    endpoint: '/api/v2/heartitCurations/',
    transform: ({ json }) => normalize(json, schemas.homeHeartitCurations),
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}

export function loadHomeCategorySales() {
  const key = 'homeCategorySales';

  return createFetchAction({
    type: 'LOAD_HOME_CATEGORY_SALES',
    endpoint: '/api/v2/categorySales/',
    transform: ({ json }) => normalize(json, schemas.homeCategorySales),
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}

export function loadHomeTimeSales() {
  const key = 'homeTimeSales';

  return createFetchAction({
    type: 'LOAD_HOME_TIME_SALES',
    endpoint: '/api/v2/shop/product/timesale',
    transform: ({ json }) => {
      if (json.success !== true) {
        return {};
      }

      const { data = [] } = json;
      const result = data.reduce((result, timeSale) => {
        const { productList, timeSaleInfo } = timeSale;

        productList.forEach(product => {
          product.timeSaleStartDt = timeSaleInfo.startDt;
        });

        return result.concat(productList);
      }, []);

      return normalize(result, schemas.homeTimeSales);
    },
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}

export function loadExhibition(exhibitionId) {
  return createFetchAction({
    type: 'LOAD_EXHIBITION',
    endpoint: `/api/v2/mdCurations/${exhibitionId}`,
    transform: ({ json }) => normalize(json, schemas.homeMdCuration),
  });
}
