import React from 'react';
import {
  StyleSheet,
  View, Text, TouchableOpacity, TouchableHighlight,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';

import { browserHistory } from 'react-router';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';

import AlertDialog from '../components/AlertDialog';

import ShippingAddressEditForm from '../nativeComponents/ShippingAddressEditForm';

import Styles from '../constants/Styles';
import Colors from '../constants/Colors';


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    paddingTop: 20,
    paddingStart: 20,
    paddingEnd: 20,
  },
  spinnerTextStyle: {
    color: '#FFF',
  },
  arrowButtonContainer: {
    flex: 1,
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    marginStart: 16,
  },
  title: {
  },
  footer: {
    flexDirection: 'row',
    paddingHorizontal: 20 - 3.5,
  },
  cancelButton: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: Colors.semiGray,
    height: 48,
    justifyContent: 'center',
    marginHorizontal: 3.5,
  },
  cancelButtonText: {
    height: 17,
    marginStart: 1,
    ...Styles.appleSDGothicNeoBold,
    fontSize: 14,
    lineHeight: 17,
    color: '#000',
  },
  saveButton: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#000',
    height: 48,
    justifyContent: 'center',
    marginHorizontal: 3.5,
  },
  saveButtonText: {
    height: 17,
    marginStart: 1,
    ...Styles.appleSDGothicNeoBold,
    fontSize: 14,
    lineHeight: 17,
    color: '#fff',
  },
});

const webStyles = {
  footer: {
    height: 48 + 20 + 20,
    paddingTop: 20,
    paddingBottom: 20,
    position: 'fixed',
    bottom: 0,
    start: 0,
    end: 0,
    backgroundColor: '#fff',
  },
};

export class ShippingAddressEditPage extends React.Component {
  editFormRef = React.createRef();

  state = {
    changedData: {},
    alert: {
      open: false,
      title: null,
      content: null,
      buttons: [],
    },
  };

  componentDidMount() {
    const { loadShippingAddressList } = this.props;
    loadShippingAddressList();
  }

  componentWillUnmount() {
    const { setGlobalLayoutInfo } = this.props;
    setGlobalLayoutInfo('showProgress', false);
    this.setState = () => null;
  }

  getMergedData = (forSave = false) => {
    const {
      shippingAddress: orgShippingAddress,
      shippingAddressEmpty,
    } = this.props;
    const { changedData } = this.state;

    const mergedData = {
      ...orgShippingAddress,
      ...changedData,
      ...(shippingAddressEmpty && { defaultFl: 'y' }),
    };

    const {
      sno,
      defaultFl,
      newDefaultFl,
      shippingTitle,
      shippingName,
      shippingZonecode,
      shippingAddress,
      shippingAddressSub,
      shippingCellPhone,
    } = mergedData;

    const data = {
      sno,
      defaultFl,
      newDefaultFl,
      shippingTitle,
      shippingName,
      shippingZonecode,
      shippingAddress,
      shippingAddressSub,
      shippingCellPhone,
    };

    if (forSave) {
      data.defaultFl = (defaultFl === 'y' || newDefaultFl) ? 'y' : 'n';
      delete data.newDefaultFl;
    }

    return data;
  }

  onPressCancelButton = () => {
    const { router } = this.props;
    router.goBack();
  }

  onPressSaveButton = async () => {
    if (!this.validateInputField()) {
      return;
    }

    const { shippingAddressId, setGlobalLayoutInfo, router } = this.props;

    const params = this.getMergedData(true);

    const errorMessage = '저장 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.';
    setGlobalLayoutInfo('showProgress', true);

    try {
      const { createShippingAddress, updateShippingAddress } = this.props;
      let ret;
      if (shippingAddressId) {
        ret = await updateShippingAddress(shippingAddressId, params);
      } else {
        ret = await createShippingAddress(params);
      }

      setGlobalLayoutInfo('showProgress', false);
      if (ret.success === true) {
        this.setState({
          alert: {
            open: true,
            title: '저장되었습니다.',
            content: null,
            buttons: [
              {
                text: '확인',
                onPress: () => {
                  router.goBack();
                },
              },
            ],
          },
        });
      } else {
        alert(`${errorMessage} (E0${ret.errorCode})`);
      }
    } catch (error) {
      if (error.errorCode) {
        alert(`${errorMessage} (E1${error.errorCode})`);
      } else {
        alert(errorMessage);
      }
      setGlobalLayoutInfo('showProgress', false);
    }
  };

  onDataChanged = changedData => {
    this.setState(({ changedData: prevChangedData }) => ({
      changedData: {
        ...prevChangedData,
        ...changedData,
      },
    }));
  }

  validateInputField() {
    // console.log("ShippingAddressInfoTabNew validateInputField");
    return this.editFormRef.current.validateInputField();
  }

  renderAlert() {
    const {
      alert: {
        open,
        title,
        content,
        buttons,
      },
    } = this.state;

    return (
      <div>
        <AlertDialog
          open={open}
          title={title}
          content={content}
          buttons={buttons}
        />
      </div>
    );
  }

  render() {
    const data = this.getMergedData();
    // console.log('ShippingAddressEditScreen data=', data);

    return (
      <View style={styles.container}>
        <View style={styles.scrollView}>
          <ShippingAddressEditForm
            ref={this.editFormRef}
            data={data}
            onDataChanged={this.onDataChanged}
            showCheckBoxSetDefault
          />
        </View>
        <View style={webStyles.footer}>
          <View style={styles.footer}>
            <TouchableHighlight
              style={styles.cancelButton}
              onPress={this.onPressCancelButton}
              underlayColor={Colors.touchEffectColorWhite}
            >
              <Text style={styles.cancelButtonText}>
                취소
              </Text>
            </TouchableHighlight>
            <TouchableOpacity
              style={styles.saveButton}
              onPress={this.onPressSaveButton}
            >
              <Text style={styles.saveButtonText}>
                저장하기
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        {this.renderAlert()}
      </View>
    );
  }
}


export function navigateToEditShippingAddress(shippingAddressId) {
  const path = `/accounts/shippingAddress/edit/${shippingAddressId}`;
  browserHistory.push(path);
}

export function navigateToCreateShippingAddress() {
  const path = '/accounts/shippingAddress/create';
  browserHistory.push(path);
}

export default connect(
  (state, props) => {
    const { params: { shippingAddressId } } = props;

    return {
      auth: state.auth,
      shippingAddressId,
      shippingAddress: state.entities.shippingAddresses[shippingAddressId],
      shippingAddressEmpty: Object.keys(state.entities.shippingAddresses).length === 0,
    };
  },
  actions,
)(ShippingAddressEditPage);
