import React from 'react';
import { StyleSheet, TouchableHighlight, View, Text } from 'react-native';

import NaverPayTextType from '../icons/NaverPayTextType';
import NaverPaySymbolType from '../icons/NaverPaySymbolType';
import NaverPayTextTypeDisabled from '../icons/NaverPayTextTypeDisabled';
import NaverPaySymbolTypeDisabled from '../icons/NaverPaySymbolTypeDisabled';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';


const styles = StyleSheet.create({
  outer: {
    paddingHorizontal: 20,
  },
  outerDarkMode: {
    backgroundColor: '#000',
    paddingHorizontal: 20,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  descContainer: {
    justifyContent: 'center',
    paddingTop: 5,
    paddingBottom: 4,
  },
  descText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 9,
    lineHeight: 12,
    letterSpacing: -0.2,
    color: 'rgb(34, 34, 34)',
  },
  descTextDarkMode: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 9,
    lineHeight: 12,
    letterSpacing: -0.2,
    color: 'white',
  },
  buttonContainer: {
    flex: 1,
    backgroundColor: 'rgb(90, 196, 81)',
    height: 48,
    marginLeft: 45,
  },
  buttonContainerDisabled: {
    flex: 1,
    backgroundColor: '#989797',
    height: 48,
    marginLeft: 45,
  },
  buttonTextContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    color: '#fff',
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: 16,
    marginLeft: 6,
    ...Styles.appleSDGothicNeoRegular,
  },
});

export default class NaverPayButton extends React.PureComponent {
  render() {
    const { onPressButton, darkMode, disabled = false } = this.props;
    return (
      <View style={darkMode ? styles.outerDarkMode: styles.outer}>
        <View style={styles.container}>
          <View style={styles.descContainer}>
            {disabled ? <NaverPayTextTypeDisabled style={{ marginBottom: 5 }} /> : <NaverPayTextType style={{ marginBottom: 5 }} />}
            <Text style={darkMode ? styles.descTextDarkMode : styles.descText}>네이버 ID로 간편구매</Text>
            <Text style={darkMode ? styles.descTextDarkMode : styles.descText}>네이버페이</Text>
          </View>
          <TouchableHighlight
            style={disabled ? styles.buttonContainerDisabled : styles.buttonContainer}
            onPress={onPressButton}
            underlayColor={darkMode? Colors.touchEffectColorBlack : Colors.touchEffectColorWhite}
          >
            <View style={styles.buttonTextContainer}>
              {disabled ? <NaverPaySymbolTypeDisabled /> : <NaverPaySymbolType />}
              <Text style={styles.buttonText}>
                Pay 구매
              </Text>
            </View>
          </TouchableHighlight>
        </View>
      </View>
    );
  }
}
