import * as React from 'react';

function SvgImgLogoNaver(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={54} height={54} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={27} cy={27} r={27} fill="#1EC800" />
        <path
          fill="#FFF"
          d="M30.496 17v10.091L23.531 17H16v20h7.505V26.909L30.472 37H38V17z"
        />
      </g>
    </svg>
  );
}

export default SvgImgLogoNaver;

