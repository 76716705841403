/* eslint-disable max-len */
import * as React from 'react';

function SvgIcTabLikeSelected(props) {
  const { active = true } = props;

  const body = active ? '#fff' : '#000';

  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M15.027 18.944l5.948-6.005c2.478-2.503 2.478-6.56 0-9.062a6.302 6.302 0 00-8.975 0 6.302 6.302 0 00-8.975 0c-2.478 2.502-2.478 6.56 0 9.061L12 22l3.027-3.056z"
        fill={body}
        stroke="#FFF"
        strokeWidth={1.2}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIcTabLikeSelected;
