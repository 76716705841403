import * as React from 'react';

function SvgIcHeaderSearch(props) {
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M17 10a7 7 0 11-14 0 7 7 0 0114 0zm-2 5l6.5 6.5"
        stroke="#000"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIcHeaderSearch;

