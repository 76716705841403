
const bottomTabBarHeight = 60;
const bottomMargin = 0;
const innerViewBottomSpace = bottomTabBarHeight + bottomMargin;


export default {
  mainHeaderHeight: 66,
  modalHeaderHeight: 60,
  touchEffectWidth: 0,
  touchEffectBorderRadius: 0,
  touchEffectWidthInHeader: 0,
  touchEffectBorderRadiusInHeader: 0,
  tabbarHeight: 56,
  viewHorizontalPadding: 20,
  innerViewHorizontalPadding: 11,
  innerViewBottomSpace,
  bottomTabBarHeight,
  bottomMargin,
  bottomSpaceForTabBar: bottomTabBarHeight + bottomMargin,
  // KAKKO 정책 동일: 8 ~ 32 자의 영문 대소문자, 숫자, 특수문자를 조합
  // eslint-disable-next-line max-len
  passwordPattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#\$%\^&\*\(\)_\+\-=\{\}\[\]\:;"'<>,\.\?])[A-Za-z\d!@#\$%\^&\*\(\)_\+\-=\{\}\[\]\:;"'<>,\.\?]{8,32}$/,
  scrollViewCommonProps: {
    scrollIndicatorInsets: { right: 1 },
  },
  initialPostCount: 6,
  initialStyleFeedCount: 6,
  shopProductFoooterHeight: 50,
  marketingPopupStorageKey: 'marketingPopupDisabledTodayList',
  registrationCouponNo: ["14"],
};
