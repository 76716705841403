import React from 'react';
import PropTypes from 'prop-types';
import {
    StyleSheet, View, Text,
} from 'react-native';

import {
  Styles, Colors
} from '../constants';

import InputForm from './InputForm';
import PhoneVerificationForm from './PhoneVerificationForm';
import BirthdaySelector from './BirthdaySelector';


const styles = StyleSheet.create({
  container: {
  },
  socialTypeContainer: {
    marginTop: 8,
  },
  socialTypeText: {
    flexWrap: 'wrap',
    fontSize: 12,
    lineHeight: 14.4,
    height: 14,
    color: Colors.primaryBlue,
    ...Styles.pretendard400,
  },
  birthdayContainerStyle: {
    paddingTop: 16,
    paddingBottom: 9,
    borderBottomWidth: 0.5,
    borderBottomColor: '#000',
  },
  birthdayInputContentStyle: {
    opacity: 1,
  },
});

class EditBasicProfileForm extends React.Component {

  constructor(props) {
    super(props);

  }

  validate() {
    const {
      disableName,
    } = this.props;

    if (!disableName && !this.inputNameRef.validate()) {
      return false;
    }

    if (!this.inputPhoneRef.validate()) {
      return false;
    }

    return true;
  }

  renderEmailInput() {
    const {
      email,
    } = this.props;

    const props = {
      editable: false,
      enableErrorMessage: false,
    };

    return (
      <React.Fragment>
        <InputForm
          type='email'
          title='이메일 주소'
          placeholder='이메일 주소를 입력해주세요. 예) heartit@heartit.kr'
          keyboardType='email-address'
          value={email}
          {...props}
          />

        {this.renderSocialAccountType() || (
          <View style={{ height: 10 }} />
        )}
      </React.Fragment>
    );
  }

  renderSocialAccountType() {
    const { auth } = this.props;

    console.log(`EditBasicProfileForm.renderSocialAccountType auth=${JSON.stringify(auth)}`);

    let socialAccountTypeStr = '';
    if (auth.facebook) {
      socialAccountTypeStr = 'Facebook으로 연결됨';
    } else if (auth.naver) {
      socialAccountTypeStr = 'Naver로 연결됨';
    } else if (auth.kakao) {
      socialAccountTypeStr = 'Kakao로 연결됨';
    } else if (auth.appleId) {
      socialAccountTypeStr = 'Apple ID로 연결됨';
    }

    if (!socialAccountTypeStr) {
      return null;
    }

    return (
      <View style={styles.socialTypeContainer}>
        <Text style={styles.socialTypeText}>
          { socialAccountTypeStr }
        </Text>
      </View>
    );
  }

  renderNameInput() {
    const { disableName, name, onChange } = this.props;

    if (disableName) {
      return null;
    }

    return (
      <InputForm
        title='이름'
        placeholder='사용자 이름을 입력해주세요.'
        value={name}
        onInputRef={ref => { this.inputNameRef = ref; }}
        onChangeText={name => onChange({ name })}
        onSubmitEditing={() => this.inputPhoneRef.focus()}
        enableErrorMessage
        minLength={2}
        />
    );
  }

  renderPhoneInput() {
    const { phone, phoneVerified, onChange } = this.props;

    return (
      <PhoneVerificationForm
        onRef={ref => { this.inputPhoneRef = ref; }}
        phone={phone}
        phoneVerified={phoneVerified}
        onChange={onChange}
        description=''
        />
    );
  }

  renderBirthdayInput() {
    const {
      birthday, onChange,
      modalProps, setModalProps,
    } = this.props;

    return (
      <BirthdaySelector
        inputContainerStyle={styles.birthdayContainerStyle}
        inputContentStyle={birthday && styles.birthdayInputContentStyle}
        date={birthday}
        onChangeDate={birthday => onChange({ birthday })}
        modalProps={modalProps}
        setModalProps={setModalProps}
        />
    );
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={{ height: 20 }} />

        {this.renderEmailInput()}

        <View style={{ height: 30 }} />

        {this.renderNameInput()}

        <View style={{ height: 40 }} />

        {this.renderPhoneInput()}

        <View style={{ height: 40 }} />

        {this.renderBirthdayInput()}

        <View style={{ height: 40 }} />
      </View>
    );
  }
}


EditBasicProfileForm.propTypes = {
  email: PropTypes.string,
  auth: PropTypes.object,
};

EditBasicProfileForm.defaultProps = {
  auth: {},
};


export default EditBasicProfileForm;
