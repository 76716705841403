import React from 'react'

import ShippingAddressEditPage from './ShippingAddressEditPage';


export function showShippingAddressEdit(
  parent, shippingAddressId, onShippingAddressEditDone=() => null,
) {
  const { setModalProps } = parent.props;

  const children = (
    <ShippingAddressEditPage
      title={shippingAddressId === null ? '새로운 배송지 추가' : '기본 배송지 정보 수정'}
      setModalProps={setModalProps}
      shippingAddressId={shippingAddressId}
      showBackButton={false}
      onShippingAddressEditDone={onShippingAddressEditDone}
      />
  )

  setModalProps({
    isVisible: true,
    children,
  });
}
