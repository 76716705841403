import React from 'react';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions, loadEntities, selectPostDate } from 'shared';

import Popup from '../../../nativeComponents/Popup';

import Constants from '../../../constants/Constants';

import HomeFeed from './HomeFeed';
import { openOutlinkPage } from '../../OutlinkGatewayPage';
import { generateFirebaseEvent } from '../../../utils';


class HomePage extends React.Component {
  homeFeedRef = null;

  constructor(props) {
    super(props);
    this.unsubscribeFromBranch = null;

    this.state = {
      showPopup: false,
      popup: null,
    };
  }

  componentDidMount() {
    const { loadHeartists } = this.props;
    loadHeartists();

    const { setGlobalLayoutInfo } = this.props;
    setGlobalLayoutInfo('homeScrollTop', false);

    const { loadMarketingPopups } = this.props;
    loadMarketingPopups();
    this.showPopup();

    window.addEventListener('focus', this.onFocus);
  }

  componentDidUpdate(prevProps, prevState) {
    const { homeScrollTop } = this.props;
    if (homeScrollTop) {
      const { setGlobalLayoutInfo } = this.props;
      setGlobalLayoutInfo('homeScrollTop', false);
      this.homeFeedRef.scrollToTop();
    }

    const { marketingPopups = [] } = this.props;
    const { marketingPopups: prevMarketingPopups = [] } = prevProps;
    if (prevMarketingPopups.length === 0 && marketingPopups.length > 0) {
      this.showPopup();
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeFromBranch) {
      this.unsubscribeFromBranch();
      this.unsubscribeFromBranch = null;
    }

    window.removeEventListener('focus', this.onFocus);
  }

  onFocus = () => {
    this.showPopup(false);
  }

  showPopup = (closeOnly = false) => {
    const {
      marketingPopups,
    } = this.props;

    if (closeOnly || marketingPopups.length <= 0) {
      document.body.style.overflow = null;
      this.setState({ showPopup: false, popup: null });
      return;
    }

    const userDisabledList = JSON.parse(sessionStorage.getItem(Constants.marketingPopupStorageKey) || '[]');
    const enabledPopups = marketingPopups.filter(popup => {
      return !popup.userClosed && !userDisabledList.includes(popup._id) && popup.displayTab === 'home';
    });

    // Open popup screen
    if (enabledPopups.length > 0) {
      document.body.style.overflow = 'hidden';
      this.setState({ showPopup: true, popup: enabledPopups[0] });
    } else {
      document.body.style.overflow = null;
      this.setState({ showPopup: false, popup: null });
    }
  }

  onPressProduct = (product, { post }) => {
    const { router, historySwitch, auth } = this.props;
    const { token } = auth;

    // eslint-disable-next-line no-prototype-builtins
    if (!post && product.hasOwnProperty('post')) {
      // eslint-disable-next-line prefer-destructuring
      post = product.post;
    }

    // TODO: Post 정보가 없는 경우 이벤트를 보내지 않아도 괜찮은지 확인 필요
    if (post && post.owner) {
      generateFirebaseEvent({ product, post, where: 'post' });
    }

    if (product.godoGoodsNo) {
      router.push({
        pathname: `/product/${product.godoGoodsNo}`,
        state: {
          postId: post && post._id,
        },
      });
      return;
    }

    openOutlinkPage(product, post, historySwitch, token, router);
  }

  renderPopup = () => {
    const { popup = null } = this.state;
    const { closeMarketingPopup, router, historySwitch, auth } = this.props;

    if (!popup) {
      return null;
    }

    return (
      <Popup
        {...popup}
        onPopupClosedCallback={this.showPopup}
        closeMarketingPopup={closeMarketingPopup}
        historySwitch={historySwitch}
        auth={auth}
        router={router}
      />
    );
  }

  render() {
    const {
      historySwitch, auth, router,
      pagination, posts, loadFeatured,
      promotions, loadPromotion,
      collections, loadCollections,
      histories, loadHistory,
      featuredHeartists,
      loadRecommendedHeartistsSortByRecentActivity,
      heartistsPicks, loadHeartistsPicks,
    } = this.props;
    const { showPopup } = this.state;

    return (
      <React.Fragment>
        <HomeFeed
          ref={ref => { this.homeFeedRef = ref; }}
          loadPosts={loadFeatured}
          onPressHeartist={(userId, username) => router.push(`/heartists/${username}`)}
          onPressPost={postId => router.push(`/posts/${postId}`)}
          onPressProduct={this.onPressProduct}
          loadFeaturedHeartists={loadRecommendedHeartistsSortByRecentActivity}
          featuredHeartists={featuredHeartists}
          posts={posts}
          pagination={pagination}
          postDate={selectPostDate('homeScreen')}
          historySwitch={historySwitch}
          auth={auth}
          router={router}
          promotions={promotions}
          loadPromotions={loadPromotion}
          collections={collections}
          loadCollections={loadCollections}
          heartistsPicks={heartistsPicks}
          loadHeartistsPicks={loadHeartistsPicks}
          histories={histories}
          loadHistory={loadHistory}
        />
        {showPopup && (this.renderPopup())}
      </React.Fragment>
    );
  }
}

export default connect(
  (state, { router }) => {
    return {
      auth: state.auth,
      historySwitch: state.settings.historySwitch,
      promotions: Object.values(state.entities.promotions),
      collections: Object.values(state.entities.collections),
      histories: loadEntities(state, 'histories', 'histories').histories,
      heartistsPicks: Object.values(state.entities.heartistsPicks),
      featuredHeartists: loadEntities(state, 'recommendedHeartistsSortByRecentActivity', 'heartists').heartists,
      ...loadEntities(state, 'featured', 'posts'),
      homeScrollTop: state.globalLayoutInfo.homeScrollTop || false,
      marketingPopups: loadEntities(state, 'marketingPopups', 'marketingPopups').marketingPopups,
    };
  },
  actions,
)(HomePage);
