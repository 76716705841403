import React from 'react';
import PropTypes from 'prop-types';


const styleHtml = {
  container: {
    width: '100%',
    height: 0,
    paddingTop: '100%',
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
};


class Image extends React.Component {
  state = {
    uri: null
  }

  constructor(props) {
    super(props);

    const { source } = props;
    this.state = {
      uri: (typeof source === 'object') ? source.uri : source
    }
  }

  componentDidUpdate(prevPros) {
    const { source: prevSource } = prevPros;
    const { source } = this.props;

    if (prevSource != source) {
      this.setState({
        uri: (typeof source === 'object') ? source.uri : source
      });
    }
  }

  render() {
    const {
      source = '',
      containerStyle,
      style,
      aspectRatio,
    } = this.props;
    const { uri } = this.state;

    const containerStyles = {
      ...styleHtml.container,
      ...containerStyle,
      paddingTop: `${1 / aspectRatio * 100}%`,
    };

    let imageStyles = {
      ...styleHtml.content,
    };

    if (Array.isArray(style)) {
      style.forEach(e => {
        imageStyles = Object.assign(imageStyles, e);
      })
    } else if (typeof style === 'object') {
      if (style.hasOwnProperty(0) || style.hasOwnProperty(1)){
        Object.values(style).forEach(e => {
          imageStyles = Object.assign(imageStyles, e);
        });
      }else{
        imageStyles = Object.assign(imageStyles, style);
      }
    }

    // console.log(`uri=${uri}`);
    //console.log(`styles=${JSON.stringify(imageStyles)}`);

    return (
      <div style={containerStyles}>
        <img
          style={imageStyles}
          referrerPolicy='no-referrer'
          src={uri}
          onError={() => {
            this.setState({
              uri: "data:image/jpeg;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
            });
          }}
        />
      </div>
    );
  }
}

Image.propTypes = {
  // source: PropTypes.string,
  aspectRatio: PropTypes.number,
  containerStyle: PropTypes.object,
  style: PropTypes.object,
}


Image.defaultProps = {
  aspectRatio: 1,
}


export default Image;
