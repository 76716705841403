import qs from 'qs';

import createFetchAction from '../util/createFetchAction';


export function loadCurrentUser(bearerToken) {
  return createFetchAction({
    type: 'LOGIN',
    endpoint: '/api/v2/auth/login',
    transform: ({ json }) => ({
      auth: Object.assign({ token: bearerToken }, json),
    }),
    bearerToken,
  });
}

export function instagramLogin(accessToken) {
  return createFetchAction({
    type: 'LOGIN',
    endpoint: '/api/v2/auth/instagram/token',
    credentials: 'same-origin',
    method: 'POST',
    body: { access_token: accessToken },
    transform: ({ json }) => ({ auth: json }),
  });
}

export function loadInstagramAuthUrl() {
  return createFetchAction({
    type: 'INSTAGRAM_AUTH_URL',
    endpoint: '/api/v2/auth/instagram/authorization_url',
    doDispatch: false,
  });
}
export function loadInstagramAuthUrlDispatch() {
  return createFetchAction({
    type: 'INSTAGRAM_AUTH_URL',
    endpoint: '/api/v2/auth/instagram/authorization_url',
  });
}

export function createInstagramAccessToken(code) {
  return createFetchAction({
    type: 'INSTAGRAM_ACCESS_TOKEN',
    endpoint: '/api/v2/auth/instagram/access_token',
    method: 'POST',
    body: { code },
  });
}

export function facebookLogin(accessToken) {
  return createFetchAction({
    type: 'LOGIN',
    endpoint: '/api/v2/auth/facebook/token',
    credentials: 'same-origin',
    method: 'POST',
    body: { access_token: accessToken },
    transform: ({ json }) => ({ auth: json }),
  });
}

export function naverLogin(accessToken) {
  return createFetchAction({
    type: 'LOGIN',
    endpoint: '/api/v2/auth/naver/token',
    credentials: 'same-origin',
    method: 'POST',
    body: { accessToken },
    transform: ({ json }) => ({ auth: json }),
  });
}

export function appleLogin(code, platform) {
  return createFetchAction({
    type: 'LOGIN',
    endpoint: '/api/v2/auth/apple/token',
    credentials: 'same-origin',
    method: 'POST',
    body: { code, platform },
    transform: ({ json }) => ({ auth: json }),
  });
}

export function kakaoLogin(accessToken) {
  return createFetchAction({
    type: 'LOGIN',
    endpoint: '/api/v2/auth/kakao/token',
    credentials: 'same-origin',
    method: 'POST',
    body: { accessToken },
    transform: ({ json }) => ({ auth: json }),
  });
}

export function loadFacebookAuthUrl() {
  return createFetchAction({
    type: 'FACEBOOK_AUTH_URL',
    endpoint: '/api/v2/auth/facebook/authorization_url',
    doDispatch: false,
  });
}

export function loadFacebookAuthUrlDispatch() {
  return createFetchAction({
    type: 'FACEBOOK_AUTH_URL',
    endpoint: '/api/v2/auth/facebook/authorization_url',
  });
}

export function loadNaverAuthUrl() {
  return createFetchAction({
    type: 'NAVER_AUTH_URL',
    endpoint: '/api/v2/auth/naver/authorization_url',
    doDispatch: false,
  });
}

export function loadKakaoAuthUrl() {
  return createFetchAction({
    type: 'KAKAO_AUTH_URL',
    endpoint: '/api/v2/auth/kakao/authorization_url',
    doDispatch: false,
  });
}

export function loadAppleAuthUrl() {
  return createFetchAction({
    type: 'APPLE_AUTH_URL',
    endpoint: '/api/v2/auth/apple/authorization_url',
    doDispatch: false,
  });
}

export function createFacebookAccessToken(code) {
  return createFetchAction({
    type: 'FACEBOOK_ACCESS_TOKEN',
    endpoint: '/api/v2/auth/facebook/access_token',
    method: 'POST',
    body: { code },
  });
}

export function createNaverAccessToken({ code, state }) {
  return createFetchAction({
    type: 'NAVER_ACCESS_TOKEN',
    endpoint: '/api/v2/auth/naver/access_token',
    method: 'POST',
    body: { code, state },
    doDispatch: false,
  });
}

export function createKakaoAccessToken({ code, state }) {
  return createFetchAction({
    type: 'KAKAO_ACCESS_TOKEN',
    endpoint: '/api/v2/auth/kakao/access_token',
    method: 'POST',
    body: { code, state },
    doDispatch: false,
  });
}

export function getNaverProfile({ accessToken }) {
  return createFetchAction({
    type: 'NAVER_GET_PROFILE',
    endpoint: '/api/v2/auth/naver/profile',
    method: 'POST',
    body: { accessToken },
    doDispatch: false,
  });
}

export function getKakaoProfile({ accessToken }) {
  return createFetchAction({
    type: 'KAKAO_GET_PROFILE',
    endpoint: '/api/v2/auth/kakao/profile',
    method: 'POST',
    body: { accessToken },
    doDispatch: false,
  });
}

export function loginWithEmailAccount(email, password) {
  return createFetchAction({
    type: 'LOGIN_WITH_EMAIL_ACCOUNT',
    endpoint: '/api/v2/auth/login',
    method: 'POST',
    doDispatch: false,
    body: { username: email, password },
  });
}

export function sendEmailVerification(email) {
  const query = qs.stringify({ e: email });
  return createFetchAction({
    type: 'SEND_EMAIL_VERIFICATION',
    endpoint: `/api/v2/auth/email/verify/send?${query}`,
    method: 'GET',
    doDispatch: false,
  });
}

export function sendFindPassword(email) {
  return createFetchAction({
    type: 'SEND_FIND_PASSWORD',
    endpoint: '/api/v2/auth/email/password/find',
    method: 'POST',
    doDispatch: false,
    body: { email },
  });
}

export function signup(params) {
  return createFetchAction({
    type: 'LOGIN',
    endpoint: '/api/v2/auth/users',
    credentials: 'same-origin',
    method: 'POST',
    body: params,
    transform: ({ json }) => ({ auth: json }),
  });
}

export function logout() {
  return createFetchAction({
    type: 'LOGOUT',
    endpoint: '/api/v2/auth/login',
    method: 'DELETE',
    credentials: 'same-origin',
  });
}

export function requestTelVerificationCode(tel, ignoreOldUser=false) {
  let endpoint = `/api/v2/auth/tel/verify/send/?tel=${tel}`;
  if (ignoreOldUser) {
    endpoint += '&ignoreOldUser=1';
  }
  return createFetchAction({
    type: 'REQUEST_TEL_VERIFICATION_CODE',
    endpoint,
    doDispatch: false,
  });
}

export function verifyTelVerificationCode(tel, code) {
  return createFetchAction({
    type: 'VERIFY_TEL',
    method: 'POST',
    endpoint: '/api/v2/auth/tel/verify',
    body: { tel, code },
    doDispatch: false,
  });
}

export function requestFindEmailWithSMSVerificationCode(name, tel) {
  const query = qs.stringify({ name, tel });
  return createFetchAction({
    type: 'REQUEST_FIND_EMAIL_WITH_SMS_VERIFICATION_CODE',
    endpoint: `/api/v2/auth/find/email/verify/send/?${query}`,
    doDispatch: false,
  });
}

export function verifyFindEmailWithSMSVerificationCode(name, tel, code) {
  return createFetchAction({
    type: 'VERIFY_FIND_EMAIL_WITH_SMS_VERIFICATION_CODE',
    method: 'POST',
    endpoint: '/api/v2/auth/find/email/verify',
    body: { name, tel, code },
    doDispatch: false,
  });
}

export function requestFindPasswordWithSMSVerificationCode(email, tel) {
  const query = qs.stringify({ email, tel });
  return createFetchAction({
    type: 'REQUEST_FIND_PASSWORD_WITH_SMS_VERIFICATION_CODE',
    method: 'GET',
    endpoint: `/api/v2/auth/find/password/verify/send/?${query}`,
    doDispatch: false,
  });
}

export function verifyFindPasswordWithSMSVerificationCode(email, tel, code) {
  return createFetchAction({
    type: 'VERIFY_FIND_PASSWORD_WITH_SMS_VERIFICATION_CODE',
    method: 'POST',
    endpoint: '/api/v2/auth/find/password/verify',
    doDispatch: false,
    body: { email, tel, code },
    doDispatch: false,
  });
}

export function changePasswordWithSMSVerificationCode(email, tel, code, password) {
  return createFetchAction({
    type: 'CHANGE_PASSWORD_WITH_SMS_VERIFICATION_CODE',
    method: 'POST',
    endpoint: '/api/v2/auth/find/password/change',
    body: { email, tel, code, password },
    doDispatch: false,
  });
}
