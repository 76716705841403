import React from 'react';

import {
  StyleSheet, Dimensions, View, Text, TouchableOpacity, TextInput,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';

import { connect } from 'react-redux';
import { Snackbar } from '@material-ui/core';

import { actions } from 'react-shared';
import { validatePassword, PASSWORD_INVALID_ERROR_TEXT } from 'react-shared/util';
import Styles from '../../constants/Styles';
import Colors from '../../constants/Colors';
import Constants from '../../constants/Constants';


const windowHeight = Dimensions.get('window').height;


const styles = StyleSheet.create({
  container: {
  },
  sectionHeaderContainer: {
    paddingStart: 20,
    paddingTop: 20,
    paddingBottom: 10,
  },
  sectionHeaderText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 18,
    height: 21,
    lineHeight: 21,
  },
  passwordInputContainer: {
    paddingHorizontal: 20,
    paddingTop: 10,
    paddingBottom: 20,
  },
  inputText: {
    fontSize: 15,
    color: 'black',
    paddingTop: 17,
    paddingBottom: 16,
    paddingHorizontal: 16,
  },
  inputBorder: {
    width: '100%',
    marginBottom: 10,
    backgroundColor: Colors.semiGray,
  },
  errorContainer: {
    width: '100%',
    marginTop: -4,
    marginBottom: 15,
  },
  errorText: {
    flexWrap: 'wrap',
    lineHeight: 20,
    fontSize: 15,
    color: 'rgb(252,43,56)',
    ...Styles.appleSDGothicNeoLight,
    marginTop: -4,
    marginBottom: 15,
  },
  bottomButtonContainer: {
    flexDirection: 'row',
    paddingTop: 23,
    paddingHorizontal: 20 - 3.5,
    position: 'absolute',
    width: '100%',
    bottom: 0,
    paddingBottom: 20,
  },
  bottomButton: {
    flex: 1,
    marginHorizontal: 3.5,
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomButtonLight: {
    backgroundColor: Colors.semiGray,
  },
  bottomButtonDark: {
    backgroundColor: '#000',
  },
  bottomButtonText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 14,
    height: 17,
    lineHeight: 17,
    marginTop: 1,
  },
  bottomButtonTextDark: {
    color: '#fff',
  },
  changePasswordDoneMessage: {
    height: 54,
    lineHeight: 27,
    ...Styles.appleSDGothicNeoBold,
    fontSize: 22,
    marginStart: 30,
    marginTop: 45,
  },
});


class ChangePasswordPage extends React.Component {
  state = {
    nowPassword: '',
    nowPasswordErrorMessage: '',
    newPassword: '',
    newPasswordErrorMessage: '',
    newPasswordCheck: '',
    newPasswordCheckErrorMessage: '',
    done: false,
  };

  passwordInputRef = null;

  newPasswordInpuRef = null;

  newPasswordCheckRef = null;

  componentDidMount() {
    const {
      logEventPageview,
    } = this.props;

    if (this.passwordInputRef) {
      this.passwordInputRef.focus();
    }

    logEventPageview({ page_name: 'ChangePassword' });
  }

  validateInputField() {
    const { nowPassword, newPassword, newPasswordCheck } = this.state;

    this.setState({
      nowPasswordErrorMessage: '',
      newPasswordErrorMessage: '',
      newPasswordCheckErrorMessage: '',
    });


    if (!nowPassword || nowPassword === '') {
      this.setState({ nowPasswordErrorMessage: '현재 비밀번호를 입력하세요.' });
      return false;
    }

    if (!newPassword || newPassword === '') {
      this.setState({ newPasswordErrorMessage: '새 비밀번호를 입력하세요.' });
      return false;
    }

    if (!validatePassword(newPassword)) {
      this.setState({ newPasswordErrorMessage: PASSWORD_INVALID_ERROR_TEXT });
      return false;
    }

    if (newPassword !== newPasswordCheck) {
      this.setState({ newPasswordCheckErrorMessage: '새 비밀번호가 일치하지 않습니다.' });
      return false;
    }

    return true;
  }

  onPressCancel = () => {
    const { router } = this.props;
    router.push('/accounts/settings');
  };

  onPressSave = async () => {
    if (!this.validateInputField()) {
      return;
    }

    const { nowPassword, newPassword, newPasswordCheck } = this.state;

    const params = {
      nowPassword,
      newPassword,
      newPasswordCheck,
    };

    const { changePassword, auth } = this.props;

    try {
      await changePassword(auth._id, params);
      this.setState({ done: true });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error=', error);
      if (error.status === 400) {
        this.setState({ nowPasswordErrorMessage: '현재 비밀번호가 올바르지 않습니다.' });
        return;
      }
      this.setState({ newPasswordCheckErrorMessage: '변경 중 오류가 발생하였습니다. 잠시 후 다시 시도해주세요.' });
    }
  };

  onPressDone = () => {
    const { router } = this.props;
    router.push('/accounts/settings');
  }

  showMessage(msg) {
    this.setState({
      snackbarMsg: msg,
      snackbarOpen: true,
    });
  }

  renderChangePasswordDone() {
    const {
      showSmartBanner,
    } = this.props;

    const containerHeight = windowHeight
      - Constants.mainHeaderHeight - (showSmartBanner ? Constants.smartBannerHeight : 0);

    return (
      <View style={[
        styles.container,
        {
          height: containerHeight,
        },
      ]}
      >
        <Text style={styles.changePasswordDoneMessage}>{'비밀번호 변경이\n완료되었습니다.'}</Text>
        <View style={[
          styles.bottomButtonContainer,
        ]}
        >
          <TouchableOpacity
            style={[styles.bottomButton, styles.bottomButtonDark]}
            onPress={this.onPressDone}
          >
            <Text style={[
              styles.bottomButtonText,
              styles.bottomButtonTextDark,
            ]}
            >
              확인
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  render() {
    const {
      nowPassword, nowPasswordErrorMessage,
      newPassword, newPasswordErrorMessage,
      newPasswordCheck, newPasswordCheckErrorMessage,
      done,
    } = this.state;

    if (done) {
      return this.renderChangePasswordDone();
    }

    const {
      showSmartBanner,
    } = this.props;

    const containerHeight = windowHeight
      - Constants.mainHeaderHeight - (showSmartBanner ? Constants.smartBannerHeight : 0);

    return (
      <View style={[
        styles.container,
        {
          height: containerHeight,
        },
      ]}
      >
        <View style={styles.sectionHeaderContainer}>
          <Text style={styles.sectionHeaderText}>비밀번호 재설정</Text>
        </View>
        <View style={styles.passwordInputContainer}>
          <TextInput
            ref={ref => { this.passwordInputRef = ref; }}
            autoCapitalize="none"
            keyboardType="default"
            onChangeText={val => this.setState({ nowPassword: val })}
            placeholder="현재 비밀번호"
            placeholderTextColor={Colors.placeholderColor}
            style={[
              styles.inputBorder,
              styles.inputText,
              { outline: 'none' },
            ]}
            value={nowPassword}
            secureTextEntry
            onSubmitEditing={() => {
              if (this.newPasswordInputRef) {
                this.newPasswordInputRef.focus();
              }
            }}
          />

          {!!nowPasswordErrorMessage && (
            <Text style={styles.errorText}>
              {nowPasswordErrorMessage}
            </Text>
          )}

          <TextInput
            ref={ref => { this.newPasswordInputRef = ref; }}
            autoCapitalize="none"
            keyboardType="default"
            textContentType="name"
            onChangeText={val => this.setState({ newPassword: val })}
            placeholder="새 비밀번호 (8자 이상 영문, 숫자, 특수문자 조합)"
            placeholderTextColor={Colors.placeholderColor}
            style={[
              styles.inputBorder,
              styles.inputText,
              { outline: 'none' },
            ]}
            value={newPassword}
            secureTextEntry
            onSubmitEditing={() => {
              if (this.newPasswordCheckInputRef) {
                this.newPasswordCheckInputRef.focus();
              }
            }}
          />

          {!!newPasswordErrorMessage && (
            <Text style={styles.errorText}>
              {newPasswordErrorMessage}
            </Text>
          )}

          <TextInput
            ref={ref => { this.newPasswordCheckInputRef = ref; }}
            autoCapitalize="none"
            keyboardType="default"
            textContentType="name"
            onChangeText={val => this.setState({ newPasswordCheck: val })}
            placeholder="새 비밀번호 확인"
            placeholderTextColor={Colors.placeholderColor}
            style={[
              styles.inputBorder,
              styles.inputText,
              { outline: 'none' },
            ]}
            value={newPasswordCheck}
            secureTextEntry
            onSubmitEditing={this.onPressSave}
          />

          {!!newPasswordCheckErrorMessage && (
            <Text style={styles.errorText}>
              {newPasswordCheckErrorMessage}
            </Text>
          )}
        </View>

        <View style={[
          styles.bottomButtonContainer,
        ]}
        >
          <TouchableOpacity
            style={[
              styles.bottomButton,
              styles.bottomButtonLight,
            ]}
            onPress={this.onPressCancel}
          >
            <Text style={[
              styles.bottomButtonText,
              styles.bottomButtonTextLight,
            ]}
            >
              취소
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.bottomButton, styles.bottomButtonDark]}
            onPress={this.onPressSave}
          >
            <Text style={[
              styles.bottomButtonText,
              styles.bottomButtonTextDark,
            ]}
            >
              저장
            </Text>
          </TouchableOpacity>
        </View>
        <Snackbar
          autoHideDuration={2000}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
          style={{ textAlign: 'center' }}
        />
      </View>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
    showSmartBanner: state.globalLayoutInfo.showSmartBanner,
  }),
  actions
)(ChangePasswordPage);
