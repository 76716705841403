export function getPrices(goods) {
  let totalGoodsPrice = 0;
  let totalMileage = 0;
  let totalCoupon = 0;
  let totalRefundMileage = 0;
  let totalRefundPrice = 0;

  goods.forEach(good => {
    const {
      orderStatus,
      goodsPrice, optionPrice, goodsCnt,
      totalDivisionCouponOrderDcPrice,
      totalDivisionUseMileage,
      refundUseMileage,
      refundDeliveryUseMileage, refundPrice,
    } = good;

    if (orderStatus.startsWith('c')) {
      return;
    }

    try {
      totalGoodsPrice += (parseFloat(goodsPrice) + parseFloat(optionPrice)) * parseInt(goodsCnt, 10);
      totalMileage += parseFloat(totalDivisionUseMileage);
      totalCoupon += parseFloat(totalDivisionCouponOrderDcPrice);
      if (refundUseMileage) {
        totalRefundMileage += parseFloat(refundUseMileage);
      }
      if (refundDeliveryUseMileage) {
        totalRefundMileage += parseFloat(refundDeliveryUseMileage);
      }
      if (refundPrice) {
        totalRefundPrice += parseFloat(refundPrice);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  });

  return {
    totalGoodsPrice,
    totalMileage,
    totalCoupon,
    totalRefundMileage,
    totalRefundPrice,
  };
}
