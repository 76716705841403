import React from 'react'
import {
  StyleSheet, View, Text,
} from 'react-native';

import { getTrackingLink, getDateString } from 'shared';

import { Styles, Constants } from '../../../constants';
import {
  BottomSheetModal,
  CheckBoxListItem,
  TouchableHighlightWithContainer,
  CloudinaryImage,
} from '../../../components-v2';
import { navigateToProductPage } from '../../shop/ProductPage';
import { navigateToExhibitionPage } from '../ExhibitionPage';
import { navigateToAuthPage } from '../../auth/AuthPage';


const styles = StyleSheet.create({
  container: {
  },
  headerContainer: {
    borderBottomWidth: 0,
  },
  contentContainer: {
  },
  contentImageContainer: {
    marginTop: 10,
    marginHorizontal: Constants.viewHorizontalPadding,
  },
  contentDescriptionContainer: {
    marginTop: 20,
    marginHorizontal: 57,
  },
  contentDescriptionText: {
    ...Styles.pretendard400,
    fontSize: 18,
    lineHeight: 28,
    textAlign: 'center',
  },
  imgStyle: {
    aspectRatio: 1,
    resizeMode: 'contain',
    width: '100%',
  },
  bottomButtonContainer: {
  },
  bottomButtonText: {
    ...Styles.pretendard600,
    fontSize: 16,
    height: 20 + 19 + 21,
    lineHeight: 19.2,
    textAlign: 'center',
    color: '#ffffff',
    backgroundColor: '#000000',
    paddingTop: 20,
    paddingBottom: 21,
  },
  hidePopupContainer: {
    marginTop: 25,
    marginBottom: 30,
    justifyContent: 'center',
  },
  hidePopupButton: {
    margin: 'auto',
    paddingStart: 0,
    paddingEnd: 0,
  },
  hidePopupItemContainer: {
    marginTop: 0,
    marginBottom: 0,
  },
  hidePopupCheckBox: {
    marginTop: 1.5,
    marginEnd: 8,
  },
  hidePopupText: {
    ...Styles.pretendard400,
    fontSize: 14,
    lineHeight: 16.8,
    height: 17,
  },
});



class PopupModal extends BottomSheetModal {

  onPressOutlink = () => {
    const { content: { linkedUrl } } = this.props;
    if (linkedUrl) {
      window.open(linkedUrl);
    }
  }

  onPressProduct = () => {
    const { content, auth, historySwitch, router } = this.props;
    const { linkedProduct: product } = content;

    const { token } = auth;
    let { linkedPost: post } = this.props;

    if (!product) {
      Alert.alert('삭제된 상품입니다.');
      return;
    }

    const retail = product.retails[product.defaultRetailIdx];
    const link = retail.orgLink || retail.link;

    if (product.godoGoodsNo) {
      navigateToProductPage({ productId: product.godoGoodsNo }, router);
      return;
    }

    const trackingLink = getTrackingLink(link, post, historySwitch, token);

    window.open(trackingLink);
  }

  onPressCuration = () => {
    const { content, router } = this.props;
    const { linkedCuration: {
      id: exhibitionId,
    } } = content;

    navigateToExhibitionPage({ exhibitionId }, router);
  }

  checkLogin = () => {
    const { auth } = this.props;
    if (!auth || !auth.token) {
      return false;
    }
    return true;
  }

  onPressRegisterCoupon = () => {
    const { router } = this.props;
    if (this.checkLogin()) {
      router.push('/accounts/settings');
    } else {
      navigateToAuthPage(router, '/accounts/settings');
    }
  }
  
  renderPopupContent() {
    const { content } = this.props;
    const {
      media: { publicId, width, height },
      description='',
    } = content;

    return (
      <View style={styles.contentContainer}>
        <View style={styles.contentImageContainer}>
          <CloudinaryImage
            publicId={publicId}
            options={{
              crop: 'limit',
              format: 'jpg',
              height,
              width,
            }}
            style={styles.imgStyle}
            aspectRatio={width / height}
          />
        </View>
        {description && (
          <View style={styles.contentDescriptionContainer}>
            <Text style={styles.contentDescriptionText}>{description}</Text>
          </View>
        )}
      </View>
    );
  }

  renderBottomButton() {
    const { content, onClose } = this.props;
    const {
      linkType,
      buttonName='확인',
    } = content;

    let onPress = () => onClose;

    switch (linkType) {
      case 'outlink':
        onPress = () => {
          this.onPressOutlink();
          onClose && onClose(false);
        };
        break;

      case 'product':
        onPress = () => {
          this.onPressProduct();
          onClose && onClose(false);
        };
        break;

      case 'curation':
        onPress = () => {
          this.onPressCuration();
          onClose && onClose(false);
        };
        break;

      case 'register_coupon':
        onPress = () => {
          this.onPressRegisterCoupon();
          onClose && onClose(false);
        };
        break;
    }

    return (
      <View style={styles.bottomButtonContainer}>
        <TouchableHighlightWithContainer
          onPress={onPress}
        >
          <Text style={styles.bottomButtonText}>{buttonName}</Text>
        </TouchableHighlightWithContainer>
      </View>
    )
  }

  renderHidePopup = () => {
    const { onClose, content } = this.props;

    const onPress = async () => {
      const disabledDict = JSON.parse(await sessionStorage.getItem(Constants.marketingPopupStorageKey) || '{}');
      const dateStr = getDateString(new Date());
      disabledDict[content._id] = dateStr;
      await sessionStorage.setItem(Constants.marketingPopupStorageKey, JSON.stringify(disabledDict));
      onClose && onClose();
    };

    return (
      <View style={styles.hidePopupContainer}>
        <CheckBoxListItem
          name={'오늘 하루 그만 보기'}
          ItemCheckBoxStyle={styles.hidePopupCheckBox}
          ItemNameStyle={styles.hidePopupText}
          containerStyle={[styles.hidePopupItemContainer]}
          style={styles.hidePopupButton}
          onPress={onPress}
          />
      </View>
    )
  }

  renderBody() {
    return (
      <View style={styles.container}>
        {this.renderPopupContent()}
        {this.renderHidePopup()}
        {this.renderBottomButton()}
      </View>
    );
  }
}


function showPopup(parent) {
  const {
    setModalProps,
    auth,
    router,
  } = parent.props;

  const {
    currentPopup,
    // popupList,
  } = parent.state;

  // console.log('PopupModal showPopup popupList=', popupList);

  if (!currentPopup) {
    return;
  }

  const {
    title,
  } = currentPopup;

  const onClose = (showNext=true) => {
    // console.log('PopupModal onClose showNext=', showNext);

    setModalProps({
      isVisible: false,
      children: null,
    }, () => {
      if (showNext) {
        parent.setState((prevState) => {
          const { popupList: prevPopupList } = prevState;

          // console.log('PopupModal showPopup prevPopupList=', prevPopupList);

          if (prevPopupList.length > 0) {
            return {
              currentPopup: prevPopupList.shift(),
              popupList: prevPopupList,
            };
          } else {
            return {
              currentPopup: null,
              popupList: [],
            };
          }
        }, () => {
          showPopup(parent);
        });
      }
    });
  }

  const children = (
    <PopupModal
      onClose={onClose}
      setModalProps={setModalProps}
      content={currentPopup}
      title={title}
      auth={auth}
      router={router}
      />
  );

  setModalProps({
    isVisible: true,
    children: children,
  });
}


export {
  showPopup,
};
