import React from 'react'
import { connect } from 'react-redux';
import { StyleSheet } from 'react-native';

import { actions } from 'shared';

import FilterBaseModal from '../FilterBaseModal';
import SizeGuide from '../../../SizeGuide';

const styles = StyleSheet.create({
});


class FilterSizeGuideModal extends FilterBaseModal {

  title = '사이즈 가이드';
  isBottomVisible = false;

  componentDidMount() {
    const { logEventPageview } = this.props;

    logEventPageview({ page_name: 'FilterSizeGuide' });
  }

  renderBody() {
    const { targetSubType } = this.props;

    return (
      <SizeGuide
        targetSubType={targetSubType}
        />
    );
  }
}


export default connect(
  null,
  actions,
)(FilterSizeGuideModal);
