import React from 'react';
import { range } from 'lodash';
import DatePicker from 'react-datepicker';
import '../css/datepicker.css';
import '../css/birthdatepicker.css';

import Colors from '../constants/Colors';


export default class BirthDateSelect extends React.Component {
  constructor(props) {
    super(props);

    const { value: defaultBirthDate } = props;
    this.state = {
      selectedDate: (defaultBirthDate) ? defaultBirthDate : null,
    };
  }

  handleChangeDate = (date) => {
    const { onBirthDateChanged: onBirthDateChangeCallback } = this.props;
    this.setState({
      selectedDate: date,
    });
    onBirthDateChangeCallback(date);
  }

  render() {
    const { selectedDate } = this.state;
    const { placeholder } = this.props;

    return (
      <DatePicker
        dateFormat="yyyy/MM/dd"
        selected={selectedDate}
        maxDate={new Date()}
        onChange={this.handleChangeDate}
        placeholderText={placeholder}
        popperPlacement="top-start"
      />
    );
  }
}
