import React from 'react';
import { View } from 'react-native';

import CloudinaryImage from './CloudinaryImage';

export default ({ user, username, style }) => {
  if (user && user.profileImage) {
    const publicId = user.profileImage.publicId;
    return (
      <CloudinaryImage
        options={{
          crop: 'limit',
          height: 160,
          width: 160,
        }}
        publicId={publicId}
        style={style}
      />
    );
  } else {
    return (
      <CloudinaryImage
        options={{
          type: 'instagram_name',
          default_image: 'default-profile.jpg',
        }}
        publicId={username}
        style={style}
      />
    );
  }
}
