import React from 'react';
import { ScrollView, FlatList, Platform, StyleSheet, View, TouchableHighlight, Text } from 'react-native';
import { actions, loadEntities, selectPostDate } from 'shared';
import { connect } from 'react-redux';

import SearchKeyword from '../components-v2/SearchKeyword';

import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import Styles from '../constants/Styles';

const SEARCH_HISTORY_STORAGE_KEY = 'searchKeywordHistory';
const SEARCH_HISTORY_MAX_LENGTH = 8;
const SEARCH_KEYWORD_RECOMMENDATION_STORAGE_KEY = 'searchKeywordRecommendation';

function dateFormat(date) {
  let month = date.getMonth() + 1;
  let day = date.getDate();

  return `${date.getFullYear()}년 ${month}월 ${day}일 업데이트`;
}

const defaultScreenStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  sectionHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10,
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  historyHeaderContainer: {
    paddingTop: 21,
    paddingBottom: 22,
  },
  sectionHeaderText: {
    fontSize: 16,
    lineHeight: 19.2,
    fontWeight: '600',
    ...Styles.pretendardVariable,
  },
  sectionContainer: {
    marginHorizontal: Constants.viewHorizontalPadding,
  },
  historySectionContainer: {
    marginBottom: 33,
    marginHorizontal: 0,
  },
  popularSectionContainer: {
    marginTop: 22,
    borderTopWidth: 0.5,
    borderTopColor: '#cfcfcf',
  },
  buttonDeleteHistoryAll: {
    fontSize: 14,
    lineHeight: 16.8,
    marginVertical: 1,
    ...Styles.pretendardVariable,
  },
  buttonDeleteHistoryAllContainer: {
  },
  mostSearchedKeywordUpdateText: {
    fontSize: 14,
    lineHeight: 16.8,
    marginVertical: 1,
    opacity: 0.5,
    ...Styles.pretendardVariable,
  },
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  listItemContainer: {
    flex: 1,
    flexDirection: 'row',
    marginHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 20,
    borderBottomWidth: 0.5,
    borderBottomColor: '#cfcfcf',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  keywordType: {
    fontSize: 16,
    lineHeight: 19.2,
    opacity: 0.5,
    fontWeight: '700',
    width: 50,
    ...Styles.pretendardVariable,
  },
  keyword: {
    fontSize: 16,
    lineHeight: 19.2,
    fontWeight: '400',
    opacity: 0.5,
    ...Styles.pretendardVariable,
  },
  keywordBold: {
    fontSize: 16,
    lineHeight: 19.2,
    fontWeight: '600',
    color: 'black',
    opacity: 1,
    ...Styles.pretendardVariable,
  },
  buttonDeleteHistoryKeywordContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinnerTextStyle: {
    color: '#FFF',
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
  mostSearchedKeywordContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: 19.5,
    paddingBottom: 20,
    borderBottomWidth: 0.5,
    borderBottomColor: '#e5e5ea',
  },
  mostSearchedKeywordRank: {
    fontSize: 16,
    lineHeight: 19.2,
    color: 'rgb(44, 44, 46)',
    fontWeight: '700',
    ...Styles.pretendardVariable,
  },
  mostSearchedKeyword: {
    fontSize: 16,
    lineHeight: 19.2,
    marginLeft: 24,
    color: 'rgb(44, 44, 46)',
    fontWeight: '400',
    ...Styles.pretendardVariable,
  },
  listRecommendHeaderStyle: {
    height: 11,
    marginHorizontal: 20,
    borderBottomWidth: 0.5,
    borderBottomColor: '#cfcfcf',
  },
});


class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKeyword: '',
      keywordType: 'history',
      historyList: [],
      recommendList: [],
      recommendListOriginal: [],
    };
  }

  componentDidMount() {
    const {
      router,
      loadMostSearchedKeywords,
      logEventPageview,
    } = this.props;

    loadMostSearchedKeywords();

    this.loadSearchHistory();
    this.loadSearchKeywordRecommendation();
    const callbacks = (router.location.state ? router.location.state.callbacks : {}) || {};
    if (!callbacks.hasOwnProperty('onChangeText')) {
      router.replace({
        pathname: '/shop/search',
        state: {
          searchKeyword: this.state.searchKeyword,
          callbacks: {
            onChangeText: this.updateSearchKeyword,
            onSubmitEditing: this.onSubmitEditing,
            onClear: () => this.updateSearchKeyword(''),
          },
        },
      });
    }

    logEventPageview({ page_name: 'Search' });
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchKeyword } = this.state;

    if (searchKeyword.length > 0) {
      if (prevState.searchKeyword.length === 0) {
        this.setState({ keywordType: 'recommend' });
      }
      if (prevState.searchKeyword !== searchKeyword) {
        const recommendList = this.updateSearchKeywordRecommendation(searchKeyword.toLowerCase());
        this.setState({ recommendList });
      }
    } else if (prevState.searchKeyword.length > 0 && searchKeyword.length === 0) {
      this.setState({ keywordType: 'history', recommendList: [] });
    }
  }


  onSubmitEditing = searchKeyword => {
    const { router } = this.props;

    router.push({
      pathname: '/shop/search/result',
      state: { searchKeyword }
    });
    this.updateSearchHistory(searchKeyword);
  }


  onAndroidBackPress() {
    const { navigation } = this.props;
    navigation.dismiss();
  }

  removeHistoryKeyword = (keyword, idx) => {
    this.deleteSearchHistory(keyword, idx);
  }

  updateSearchKeyword = searchKeyword => {
    this.setState({
      searchKeyword,
    });
  }

  removeHistoryAll = () => {
    this.clearSearchHistory();
  }

  onPressKeyword = keyword => {
    const { router } = this.props;

    this.setState({
      searchKeyword: keyword
    }, () => {
      router.push({
        pathname: '/shop/search/result',
        state: { searchKeyword: keyword },
      });
      this.updateSearchHistory(keyword);
    });
  }

  renderKeyword = ({ item: keyword, index }) => {
    const { keywordType } = this.state;

    return (
      <View style={[styles.listItemContainer, Styles.touchEffectContainer]}>
        <TouchableHighlight
          onPress={() => this.onPressKeyword(keyword.keyword)}
          style={Styles.touchEffect}
          underlayColor={Colors.touchEffectColorWhite}
        >
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <Text style={styles.keywordType}>
              { keyword.type }
            </Text>
            <Text style={styles.keyword}>
              { keyword.keywordPrefix }
            </Text>
            <Text style={styles.keywordBold}>
              { keyword.keywordInfix }
            </Text>
            <Text style={styles.keyword}>
              { keyword.keywordSuffix }
            </Text>
          </View>
        </TouchableHighlight>
      </View>
    );
  }

  async loadSearchHistory() {
    const historyList = JSON.parse(window.localStorage.getItem(SEARCH_HISTORY_STORAGE_KEY) || '[]');
    this.setState({ historyList });
  }


  clearSearchHistory() {
    const newHistoryList = [];
    this.setState({ historyList: newHistoryList });
    window.localStorage.setItem(SEARCH_HISTORY_STORAGE_KEY, JSON.stringify(newHistoryList));
  }

  /* Search keyword recommendations: fetched from the server */
  async loadSearchKeywordRecommendation() {
    const now = new Date();
    const threshold = 60 * 1000; // 1 minutes (ms)
    const localData = JSON.parse(window.localStorage.getItem(SEARCH_KEYWORD_RECOMMENDATION_STORAGE_KEY) || '{}');
    if (localData.timestamp && (now - localData.timestamp) < threshold) {
      const recommendList = localData.data;
      this.setState({ recommendListOriginal: recommendList });
      return;
    }

    const { loadShopSearchKeywordRecommendation } = this.props;
    const ret = await loadShopSearchKeywordRecommendation();
    if (!ret.success) {
      console.error(`Failed to load search keyword recommendation (E0${ret.errorCode})`);
      return;
    }
    const recommendList = ret.data.map(r => ({ type: r.split('^|^')[0], value: r.split('^|^')[1] }));
    this.setState({ recommendListOriginal: recommendList });
    const newLocalData = {
      timestamp: now,
      data: recommendList,
    };
    window.localStorage.setItem(SEARCH_KEYWORD_RECOMMENDATION_STORAGE_KEY, JSON.stringify(newLocalData));
  }

  updateSearchKeywordRecommendation(searchKeyword) {
    const { recommendListOriginal } = this.state;
    const recommendList = [];
    if (!searchKeyword) {
      return recommendList;
    }
    recommendListOriginal.forEach(item => {
      const keyword = item.value.toLowerCase();
      if (!keyword.includes(searchKeyword)) {
        return;
      }

      const indexStart = keyword.indexOf(searchKeyword);
      const indexEnd = indexStart + searchKeyword.length;
      recommendList.push({
        type: item.type,
        keyword: item.value,
        keywordPrefix: item.value.slice(0, indexStart),
        keywordInfix: item.value.slice(indexStart, indexEnd),
        keywordSuffix: item.value.slice(indexEnd, item.length),
        indexStart,
        indexEnd,
      });
    });
    return recommendList;
  }

  deleteSearchHistory(keyword, idx) {
    const { historyList } = this.state;
    historyList.splice(idx, 1);
    this.setState({ historyList });
    window.localStorage.setItem(SEARCH_HISTORY_STORAGE_KEY, JSON.stringify(historyList));
  }

  /* Search history: stored on device with LocalStorage */
  updateSearchHistory(keyword) {
    const { historyList } = this.state;
    let newHistoryList = historyList;
    newHistoryList = newHistoryList.filter(item => item !== keyword);
    newHistoryList.unshift(keyword);
    newHistoryList = newHistoryList.slice(0, SEARCH_HISTORY_MAX_LENGTH);
    this.setState({ historyList: newHistoryList });
    window.localStorage.setItem(SEARCH_HISTORY_STORAGE_KEY, JSON.stringify(newHistoryList));
  }

  renderMostSearchedKeyword = (keyword, idx) => (
    <TouchableHighlight
      key={keyword}
      onPress={() => {
        this.onPressKeyword(keyword);
      }}
      underlayColor={Colors.touchEffectColorWhite}
      key={keyword}
    >
      <View style={styles.mostSearchedKeywordContainer}>
        <Text style={styles.mostSearchedKeywordRank}>{idx + 1}</Text>
        <Text style={styles.mostSearchedKeyword}>{keyword}</Text>
      </View>
    </TouchableHighlight>
  )

  renderRecentSerarchKeyword() {
    const { historyList } = this.state;

    if (!historyList || historyList.length === 0) {
      return <View style={{ height: 10 }} />;
    }

    return (
      <React.Fragment>
        <View style={[defaultScreenStyles.sectionHeaderContainer, defaultScreenStyles.historyHeaderContainer]}>
          <Text style={defaultScreenStyles.sectionHeaderText}>
            최근 검색어
          </Text>
          <View style={styles.touchEffectContainer}>
            <TouchableHighlight
              style={styles.touchEffect}
              underlayColor={Colors.touchEffectColorWhite}
              onPress={this.removeHistoryAll}
            >
              <Text style={defaultScreenStyles.buttonDeleteHistoryAll}>
                전체 삭제
              </Text>
            </TouchableHighlight>
          </View>
        </View>

        <View style={[defaultScreenStyles.sectionContainer, defaultScreenStyles.historySectionContainer]}>
          <SearchKeyword
            data={historyList}
            limitLine={1}
            onPressKeyword={this.onPressKeyword}
          />
        </View>
      </React.Fragment>
    );
  }

  renderDefault() {
    const { router, mostSearchedKeywords } = this.props;

    return (
      <ScrollView
        style={defaultScreenStyles.container}
        contentContainerStyle={{ paddingBottom: Constants.touchEffectWidth }}
      >
        {this.renderRecentSerarchKeyword()}

        <View style={defaultScreenStyles.sectionHeaderContainer}>
          <Text style={defaultScreenStyles.sectionHeaderText}>
            인기 검색어
          </Text>
          <Text style={defaultScreenStyles.mostSearchedKeywordUpdateText}>
            {dateFormat(new Date())}
          </Text>
        </View>

        <View style={[defaultScreenStyles.sectionContainer, defaultScreenStyles.popularSectionContainer]}>
          <View>
            <View>
              {mostSearchedKeywords.map((value, idx) => this.renderMostSearchedKeyword(value, idx))}
            </View>
          </View>
        </View>
      </ScrollView>
    );
  }

  render() {
    const { keywordType, recommendList } = this.state;

    const data = [
      ...recommendList,
    ];

    return (
      <View style={styles.container}>
        { keywordType === 'history'
          && (this.renderDefault())
        }

        { keywordType === 'recommend'
          && (
            <FlatList
              data={data}
              ListHeaderComponent={() => (
                <View
                  style={{
                    height: 10,
                    borderBottomWidth: 0.5,
                    borderBottomColor: '#cfcfcf',
                    marginHorizontal: Constants.viewHorizontalPadding,
                  }}
                  />
              )}
              renderItem={this.renderKeyword}
              keyExtractor={(_item, index) => index.toString()} // eslint-disable-line no-underscore-dangle
              removeClippedSubviews={Platform.OS === 'android'}
              keyboardShouldPersistTaps="always"
            />
          )
        }
      </View>
    );
  }
}


export default connect(
  state => {
    return {
      mostSearchedKeywords: state.entities.search.mostSearchedKeywords,
      ...state,
    };
  },
  actions,
)(SearchPage);
