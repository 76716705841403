import React, { Component } from 'react';
import PropTypes from 'prop-types';
import config from '../config';
import { cloudinary } from '../cloudinary';

export default class InstagramProfile extends Component {
  render() {
    const { instagramId, options, onClick, style } = this.props;
    const optionsWithDefault = Object.assign({}, options, {
      type: 'instagram_name',
      default_image: 'default-profile.jpg',
    });
    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <img src={cloudinary.url(instagramId, optionsWithDefault)} style={style} alt="" onClick={onClick} />
    );
  }
}

InstagramProfile.defaultProps = {
  onClick: null,
  options: {},
};

InstagramProfile.propTypes = {
  instagramId: PropTypes.string.isRequired,
  options: PropTypes.object,
  style: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};
