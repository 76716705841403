import React from 'react'
import PropTypes from 'prop-types';
import { StyleSheet, View, Text } from 'react-native';

import { Styles, Constants } from '../constants';
import { HeaderBackIcon } from '../icons-v2';
import TouchableHighlightWithContainer from './TouchableHighlightWithContainer';


const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-end',
    height: '100vh',
    height: '-webkit-fill-available',
    width: '100%',
    backgroundColor: '#ffffff',
  },
  contentContainer: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  headerContainer: {
    paddingLeft: 16,
    paddingEnd: Constants.viewHorizontalPadding,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: Constants.mainHeaderHeight,
    height: 66,
  },
  headerBackButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 1,
    marginEnd: 10,
  },
  headerTitleText: {
    ...Styles.pretendard700,
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
    marginTop: 24,
    marginBottom: 23,
  },
});


class OverlayPage extends React.Component {

  state = {};
  title = '';

  onPressBackButton = () => {
    const { setModalProps } = this.props;

    setModalProps({
      isVisible: false,
      children: null,
    });
  }

  renderHeader = () => {
    const {
      title: titleFromProps,
      headerContainerStyle,
      showBackButton=true,
    } = this.props;
    let title = titleFromProps || this.title;

    return (
      <View style={[styles.headerContainer, headerContainerStyle]}>
        {showBackButton && (
          <View style={styles.headerBackButtonContainer}>
            <TouchableHighlightWithContainer
              style={Styles.touchEffectInHeader}
              onPress={this.onPressBackButton}
            >
              <HeaderBackIcon />
            </TouchableHighlightWithContainer>
          </View>
        )}
        <Text style={styles.headerTitleText}>{title}</Text>
      </View>
    );
  }

  renderContent() {
    return null;
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          {this.renderHeader()}
          {this.renderContent()}
        </View>
      </View>
    );
  }
}


OverlayPage.propTypes = {
  showBackButton: PropTypes.bool,
};


OverlayPage.defaultProps = {
  showBackButton: true,
};


export default OverlayPage;
