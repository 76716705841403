import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import dayjs from 'dayjs';
import { loadEntities } from 'react-shared';
import * as adminActions from 'react-shared/adminActions';

import config from '../../config';
import { Cloudinary } from 'cloudinary-core';
const cloudinary = Cloudinary.new();
cloudinary.config({
  ...config.get('cloudinary'),
  secure: true,
});

const styles = {
  container: {
    border: '5px solid #fff',
    marginBottom: '150px',
    marginTop: '95px',
    padding: '0px 15px',
    textAlign: 'center',
  },
  headerLeft: {
    display: 'inline-block',
    float: 'left',
    height: '40px',
    marginBottom: '12.5px',
    textAlign: 'left',
    width: '100%',
  },
  headerRight: {
    display: 'inline-block',
    height: '40px',
    lineHeight: '40px',
    // marginBottom: '12.5px',
    textAlign: 'right',
    width: '100%',
  },
  date: {
    color: '#909090',
    fontSize: '13px',
  },
  mainImage: {
    marginBottom: '14px',
    width: '100%',
  },
  // abstract: {
  //   color: 'black',
  //   fontSize: '10px',
  //   textAlign: 'left',
  //   textDecoration: 'none',
  // },
  link: {
    color: 'black',
    textAlign: 'right',
  },
  offline: {
    color: '#000080',
  },
  online: {
    color: '#FF0000',
  },
};

// export default
class MarketingPopup extends Component {
  static renderEditButton(popupId) {
    return (
      <Link className="btn btn-primary" to={`/admin/marketingPopups/${popupId}`}>
        <span className="glyphicon glyphicon-edit" />
      </Link>
    );
  }

  renderDeleteButton(popupId) {
    return (
      <button
        type="button"
        className="btn btn-danger"
        onClick={
          () => confirm('정말로 이 팝업을 삭제하시겠습니까?') && this.props.adminDeleteMarketingPopup(popupId) //eslint-disable-line
        }
      >
        <span className="glyphicon glyphicon-trash" />
      </button>
    );
  }

  renderCaption(popupId) {
    const captionStyle = { height: 30 };
    return (
      <div style={captionStyle}>
        {MarketingPopup.renderEditButton(popupId)}
        {this.renderDeleteButton(popupId)}
      </div>
    );
  }

  static renderDate(date) {
    if (!date) {
      return 'unspecified';
    }
    return dayjs(date * 1000).format('YYYY-MM-DD (HH:mm)');
  }

  render() {
    const { popup } = this.props;
    let linkValue = '';
    let linkType = 'URL';
    if (popup.linkType === 'post') {
      linkValue = popup.linkedPost ? popup.linkedPost._id : '포스트 삭제됨';
      linkType = '포스트 ID';
    } else if (popup.linkType === 'product') {
      linkValue = popup.linkedProduct ? popup.linkedProduct._id : '상품 삭제됨';
      linkType = '상품 ID';
    } else if (popup.linkType === 'curation') {
      linkValue = popup.linkedCuration ? popup.linkedCuration.id : '기획전 삭제됨';
      linkType = '기획전 ID';
    } else {
      linkValue = popup.linkedUrl;
      linkType = 'URL';
    }

    return (
      <div style={styles.container}>
        <img
          src={popup.media.secureUrl || cloudinary.url(popup.media.publicId)}
          style={styles.mainImage}
          alt="" />
        <div style={styles.headerLeft}>
          <div>
            Title:
            {popup.title}
          </div>
          <div>
            Open:
            {MarketingPopup.renderDate(popup.open)}
          </div>
          <div>
            Close:
            {MarketingPopup.renderDate(popup.close)}
          </div>
          <div>
            Enabled:
            {popup.enabled ? <span style={styles.online}>true</span> : <span style={styles.offline}>false</span>}
          </div>
          <div>
            Link Type:
            {linkType}
          </div>
          <div>
            Link Value:
            {linkValue}
          </div>
        </div>
        <div style={styles.headerRight}>
          {this.renderCaption(popup._id)}
        </div>
      </div>
    );
  }
}

MarketingPopup.propTypes = {
  popup: PropTypes.object.isRequired,
};

export const mapStateToProps = state => ({
  ...loadEntities(state, 'adminMarketingPopups', 'adminMarketingPopups'),
});

export default connect(
  mapStateToProps,
  adminActions,
)(MarketingPopup);
