const tintColor = '#2f95dc';

export default {
  tintColor,
  tabBarActive: 'black',
  tabIconDefault: '#9a9a9a',
  tabIconSelected: 'black',
  tabBar: '#fefefe',
  errorBackground: '#e94949',
  errorText: '#e94949',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
  trackColor: {
    true: 'black',
  },
  touchEffectColorWhite: 'rgba(0,0,0,0.1)',
  touchEffectColorBlack: 'rgba(255,255,255,0.2)',
  lightGray: 'rgb(236,237,238)',
  semiGray: 'rgb(246,247,248)',
  profileImageBorder: '#E5E5EA',
  labelSimilar: 'rgba(0, 0, 0, 0.6)',
  labelPreOrder: 'rgb(20, 196, 137)',
  placeholderColor: 'rgba(0, 0, 0, 0.4)',
  defaultGray02: '#E5E5EA',
  defaultGray03: '#D1D1D6',
  defaultGray04: '#AEAEB2',
  defaultGray05: '#8E8E93',
  defaultGray08: '#2C2C2E',
  guideRed: '#FF0000',
  primaryRed: '#E94949',
  primaryBlue: '#1F51FF',
  lineGray: '#CFCFCF',
  primaryBlue: '#1f51ff',
  productImageBorder: '#DEDEDE',
};
