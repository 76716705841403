import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, View, FlatList } from 'react-native';
import { actions, loadEntities } from 'shared';

import PreOrderItem from './PreOrderItem';
import { Constants } from '../../../constants';


const styles = StyleSheet.create({
  container: {
    marginBottom: Constants.bottomSpaceForTabBar,
  },
  itemSeperator: {
    height: 50,
  },
  footer: {
    height: 50,
  }
});


class PreOrderPage extends React.Component {
  state = {
    preorderFollow: [],
  };

  componentDidMount() {
    const {
      auth,
      loadPreorderExhibitions,
      logEventPageview,
    } = this.props;
    loadPreorderExhibitions();
    if (auth && auth.hasOwnProperty('_id')) {
      this.loadPreorderFollowings();
    }

    logEventPageview({ page_name: 'PreOrder' });
  }

  loadPreorderFollowings() {
    const {
      loadPreorderFollowings,
    } = this.props;
    loadPreorderFollowings()
      .then(result => this.setState({ preorderFollow: result.map(p => p.preorderExhibition.id) }));
  }

  setPreOrderAlarm(id) {
    const { router, auth, addPreorderFollowing } = this.props;
    if (!auth || !auth.token) {
      alert('로그인해주시기 바랍니다.');
      router.push('/accounts/login');
    }
    addPreorderFollowing(id)
      .then(() => this.loadPreorderFollowings());
  }

  unsetPreOrderAlarm(id) {
    const { router, auth, deletePreorderFollowing } = this.props;
    if (!auth || !auth.token) {
      alert('로그인해주시기 바랍니다.');
      router.push('/accounts/login');
    }
    deletePreorderFollowing(id)
      .then(() => this.loadPreorderFollowings());
  }

  render() {
    const { router, preorderExhibitions } = this.props;
    const { preorderFollow } = this.state;

    return (
      <FlatList
        keyExtractor={(item, idx) => { return `${idx}`}} // eslint-disable-line no-underscore-dangle
        style={styles.container}
        renderItem={({ item }) => (
          <PreOrderItem
            item={item}
            following={preorderFollow.indexOf(item.id) >= 0}
            setPreOrderAlarm={(id) => this.setPreOrderAlarm(id)}
            unsetPreOrderAlarm={(id) => this.unsetPreOrderAlarm(id)}
            router={router}
            />
        )}
        data={preorderExhibitions}
        ItemSeparatorComponent={() => <View style={styles.itemSeperator} />}
        ListFooterComponent={() => <View style={styles.footer} />}
        />
    );
  }
}


export default connect(
  (state, { router }) => {
    return {
      auth: state.auth,
      ...loadEntities(state, 'preorderExhibitions', 'preorderExhibitions', 'pagination', 'preorderExhibitions'),
    };
  },
  actions,
)(PreOrderPage);
