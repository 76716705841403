import * as React from 'react';

function CheckComplete(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24">
        <g fill="none" fillRule="evenodd" strokeLinecap="square">
            <g stroke="#00C709" strokeWidth="2">
                <g>
                    <path d="M5 11.2L9.996 16 18.067 8" transform="translate(-206 -413) translate(207 413)"/>
                </g>
            </g>
        </g>
    </svg>
  );
}

export default CheckComplete;
