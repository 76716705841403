import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ga from 'react-ga';
import Backdrop from '@material-ui/core/Backdrop';
import { withStyles } from '@material-ui/core/styles';

import * as actions from 'react-shared/actions';

import Navigation from '../components/Navigation';
import MarketingBanner from '../components/MarketingBanner';
import SmartBanner from '../nativeComponents/SmartBanner';
import ChatInquiryIcon from '../nativeComponents/ChatInquiryIcon';
import Spinner from '../nativeComponents/Spinner';

import Constants from '../constants/Constants';
import MainBottomTabBar from '../nativeComponents/MainBottomTabBar';

const styles = {
  header: {
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 1030,
    width: '100%',
  },
  main: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  footerMenuContainer: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: Constants.mainBottomMargin,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
  },
  footerMenu: {
    backgroundColor: 'black',
    display: 'flex',
    width: 244,
    height: Constants.mainBottomTabBarHeight,
    borderRadius: 28,
    boxShadow: '0px 6px 14px 0px rgba(0,0,0,0.4)',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingLeft: 10 - 5,
    paddingRight: 9 - 5,
  },
  footerMenuItem: {
    marginLeft: 18,
    marginRight: 18,
  },
  chatInquiryIconContainer: {
    float: 'right',
    position: 'sticky',
    bottom: Constants.shopProductFoooterHeight + 9,
    paddingRight: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
};


const useStyles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff',
  },
});

class App extends Component {
  state = {
    showSmartBanner: false,
    showMarketingBanner: true,
    platform: '',
    headerPosition: 'sticky',
  };

  componentDidMount() {
    const { router, auth, setGlobalLayoutInfo } = this.props;
    if (auth.token) {
      ga.set({ userId: auth._id });
    }
    ga.event({
      category: '(ignore) Dev $pt',
      action: 'App',
      label: 'componentDidMount',
    });
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      this.setState({
        showSmartBanner: true,
        platform: 'ios',
        headerPosition: '-webkit-sticky',
      });
      setGlobalLayoutInfo('showSmartBanner', true);
    } else if (navigator.userAgent.match(/Android/i)) {
      this.setState({
        showSmartBanner: true,
        platform: 'android',
      });
      setGlobalLayoutInfo('showSmartBanner', true);
    } else if (navigator.userAgent.match(/Safari/i)) {
      this.setState({
        headerPosition: '-webkit-sticky',
      });
    }

    this.updateRouterHistory(router.location.pathname);
  }

  updateRouterHistory = (pathname) => {
    const { updateRouterHistory } = this.props;
    updateRouterHistory(pathname, 'append');
  }

  componentDidUpdate(prevProps) {
    const { pathname: prevPathname } = prevProps;
    const { pathname, auth, updateRouterHistory } = this.props;
    if (!prevProps.auth.token && auth.token) {
      ga.set({ userId: auth._id });
    }

    console.log('pathname', pathname);
    if (pathname !== prevPathname) {
      this.updateRouterHistory(pathname);
    }
  }

  async handleLogout(event) {
    event.preventDefault();
    await this.props.logout();
    window.location.href = '/';
  }

  handleSmartBannerClose() {
    this.setState({ showSmartBanner: false });

    const { setGlobalLayoutInfo } = this.props;
    setGlobalLayoutInfo('showSmartBanner', false);
  }

  handleMarketingBannerClose() {
    this.setState({ showMarketingBanner: false });
  }

  showMarketingBanner() {
    const { pathname } = this.props;
    const { showMarketingBanner } = this.state;
    return showMarketingBanner && (pathname === '/' || pathname.includes('/liked') || pathname.includes('/posts/'));
  }

  renderMainBottomTabBar() {
    const {
      auth, router, pathname,
    } = this.props;

    return (
      <div style={styles.footerMenuContainer}>
        <MainBottomTabBar
          auth={auth}
          router={router}
          pathname={pathname}
        />
      </div>
    );
  }

  render() {
    // Injected by React Router
    const {
      children, router, classes,
      showProgress = false,
    } = this.props;
    const { headerPosition, platform, showSmartBanner } = this.state;

    const headerStyle = Object.assign({}, styles.header, { position: headerPosition });

    const pathName = router.location.pathname;

    if ($pt.isApp()) {
      ga.event({
        category: '(ignore) Dev $pt',
        action: 'App',
        label: 'componentDidMount',
      });
      return (
        <div>
          <div className="container">
            {children}
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ maxWidth: 960, marginLeft: 'auto', marginRight: 'auto' }}>
          <div id="header_container" style={headerStyle}>
            {showSmartBanner && <SmartBanner platform={platform} onClickClose={() => this.handleSmartBannerClose()} />}
            <Navigation router={router} pathName={pathName} />
            {this.showMarketingBanner() && <MarketingBanner onClose={() => this.handleMarketingBannerClose()} />}
          </div>
          <div style={styles.main}>
            {children}
          </div>
          {this.renderMainBottomTabBar()}
          <Backdrop className={classes.backdrop} open={showProgress}>
            <Spinner isOverlay />
          </Backdrop>
          <div style={styles.chatInquiryIconContainer}>
            <ChatInquiryIcon pathName={pathName} />
          </div>
        </div>
      );
    }
  }
}

App.propTypes = {
  auth: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  logout: PropTypes.func.isRequired,
  // loadFeatured: PropTypes.func.isRequired,
};

export default withStyles(useStyles)(connect(
  (state, { router }) => ({
    auth: state.auth,
    showProgress: state.globalLayoutInfo.showProgress,
    pathname: router.getCurrentLocation().pathname,
  }),
  actions,
)(App));
