import React from 'react';
import { connect } from 'react-redux';

import { actions, loadEntities } from 'shared';

import { Styles } from '../../../constants';

import StyleFeedList from './StyleFeedList';


class StyleFollowPage extends React.Component {

  styleFeedRef = React.createRef();

  constructor(props) {
    super(props);
    global.stylePageRef = this;
  }

  componentDidMount() {
    const {
      loadHomeStylePickPosts,
      logEventPageview,
    } = this.props;

    loadHomeStylePickPosts();

    logEventPageview({ page_name: 'StyleFollow' });
  }

  scrollToTop() {
    if (this.styleFeedRef) {
      this.styleFeedRef.getWrappedInstance().scrollToTop();
    }
  }

  loadStyleFeeds = async (nextPage, query) => {
    const { loadFollowingStylePostList } = this.props;
    loadFollowingStylePostList(nextPage, query);
  }

  render() {
    const {
      router,
      modalProps,
      setModalProps,
      stylePostList,
      homeStylePickPosts,
      pagination,
      showSmartBanner,
      selectedHashtag
    } = this.props;

    const hashtags = homeStylePickPosts.reduce((ret, post) => {
      const title = post.popularTag.tag[0].title;
      if (ret.indexOf(title) < 0) {
        ret.push(title);
      }
      return ret;
    }, []);

    return (
      <StyleFeedList
        ref={ref => { this.styleFeedRef = ref; }}
        loadData={this.loadStyleFeeds}
        stylePostList={stylePostList}
        pagination={pagination}
        hashtags={hashtags}
        selectedHashtag={selectedHashtag}
        containerStyle={Styles.screenHeaderSpace}
        router={router}
        modalProps={modalProps}
        setModalProps={setModalProps}
        showSmartBanner={showSmartBanner}
      />
    );
  }
}

export function styleScrollToTop() {
  if (global.stylePageRef) {
    global.stylePageRef.scrollToTop();
  }
}

export default connect(
  (state, { router }) => {
    return {
      auth: state.auth,
      ...loadEntities(state, 'homeStylePickPosts', 'homeStylePickPosts'),
      ...loadEntities(state, 'followingStylePosts', 'followingStylePosts', 'pagination', 'stylePostList'),
      selectedHashtag: router.location.state ? router.location.state.hashtag : undefined,
    }
  },
  actions,
)(StyleFollowPage);
