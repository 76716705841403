import React from 'react';

import config from '../config';


export default class KakaoLinkButton extends React.PureComponent {
  componentDidMount() {
    const {
      jsKey,
    } = config.get('kakao');

    ((id, cb) => {
      if (document.getElementById(id) == null) {
        const js = document.createElement('script');

        js.id = id;
        js.src = '//developers.kakao.com/sdk/js/kakao.min.js';
        js.onload = cb;

        document.body.append(js);
      }
    })('kakao-sdk', () => {
      window.Kakao.init(jsKey);
    });
  }

  onBtnClick = () => {
    const {
      url,
    } = this.props;

    if (window.Kakao) {
      window.Kakao.Link.sendScrap({
        requestUrl: url,
      });
    }
  }

  render() {
    const {
      children = null,
    } = this.props;

    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a type="button" onClick={this.onBtnClick}>
        {children}
      </a>
    );
  }
}
