import React from 'react'

import FilterSizeGuideModal from "./FilterSizeGuideModal";

function showFilterSizeGuideModal(parent, targetSubType) {
    const {
      modalProps,
      setModalProps,
    } = parent.props;
  
    const {
      children,
      historyStack=[],
    } = modalProps();
  
    const newChildren = (
      <FilterSizeGuideModal
        targetSubType={targetSubType}
        setModalProps={setModalProps}
        modalProps={modalProps}
        />
    );
  
    historyStack.push(children);
  
    setModalProps({
      children: newChildren,
      historyStack,
    });
  }
  
  
  export default showFilterSizeGuideModal;
  