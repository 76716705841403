import React from 'react';
import {
  View, TouchableHighlight as TouchableHighlightDefault,
} from 'react-native';


import { Styles, Colors } from '../constants';

class TouchableHighlightWithContainer extends React.Component {
  render() {

    const {
      style,
      containerStyle,
      ...props
    } = this.props;

    const touchStyle = [Styles.touchEffect];
    touchStyle.push((Array.isArray(style)) ? [...style] : [style]);
    props.style = touchStyle;
    props.underlayColor = Colors.touchEffectColorWhite;

    let touchableHighlight = (
      <TouchableHighlightDefault {...props} />
    );

    return (
      <View style={[Styles.touchEffectContainer, containerStyle]}>
        {touchableHighlight}
      </View>
    );
  }
}

export default TouchableHighlightWithContainer;
