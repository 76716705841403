import React from 'react';
import { StyleSheet, View } from 'react-native';
import { connect }  from 'react-redux';
import { actions, getTrackingLink } from 'shared';

import HomeFeedSectionHeader from './HomeFeedSectionHeader';

import { Products } from '../../../../components-v2';
import { Colors } from '../../../../constants';

import { navigateToProductPage } from '../../../shop/ProductPage';
import { openOutlinkPage } from '../../../OutlinkGatewayPage';


const styles = StyleSheet.create({
  container: {
  },
  listContainer: {
  },
  productContainerStyle: {
    width: 112,
  },
  productImageStyle: {
    width: 112,
    aspectRatio: 112/149,
    borderWidth: 1,
    borderColor: Colors.productImageBorder,
    overflow: 'hidden',
  },
  productTextContainer: {
    marginTop: 4,
  },
});


class FeedProductList extends React.PureComponent {

  render() {
    const {
      title,
      products,
      onPressSeeAll=null,
      router,
    } = this.props;

    const headerProps = {}
    if (onPressSeeAll != null) {
      headerProps.showSeeAll = true
      headerProps.onPressSeeAll = onPressSeeAll
    }

    const onPressProduct = item => {
      const { logEventClick, historySwitch, auth, router } = this.props;
      const { token } = auth;
      const post = undefined;

      const productId = item.godoGoodsNo ? item.godoGoodsNo : item.goodsNo;
      if (productId) {
        logEventClick({
          page_name: 'Home',
          page_key: title,
          click_object_name: 'product',
          click_object_key: productId
        });
        navigateToProductPage({ productId }, router);
        return;
      }

      const retail = item.retails[item.defaultRetailIdx];
      const link = retail.orgLink || retail.link;
      const trackingLink = getTrackingLink(link, post, historySwitch, token);

      openOutlinkPage(item, post, historySwitch, token, router);
    };

    return (
      <View style={styles.container}>
        <HomeFeedSectionHeader
          title={title}
          {...headerProps}
          />
        <Products
          horizontal
          products={products}
          onPressProduct={onPressProduct}
          listHeaderComponentStyle={{ width: 20 }}
          listFooterComponentStyle={{ width: 20 }}
          containerStyle={styles.listContainer}
          itemContainerStyle={styles.productContainerStyle}
          productImageStyle={styles.productImageStyle}
          productTextContainerStyle={styles.productTextContainer}
          name={title}
        />
      </View>
    );
  }
}


export default connect(
  (state) => ({ auth: state.auth }),
  actions
)(FeedProductList);
