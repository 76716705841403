import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { compose } from 'recompose';
import {
  Button,
  Checkbox,
  Dialog,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  withStyles,
} from '@material-ui/core';
import * as adminActions from 'react-shared/adminActions';
import { loadEntities } from 'react-shared/util';
import InstagramProfile from 'components/InstagramProfile';
import { Search } from '@material-ui/icons';

const styles = theme => ({
  searchBox: {
    marginTop: 16,
    marginBottom: 16,
  },
  textField: {
    width: 300,
  },
  input: {
    ...theme.typography.subheading,
  },
  label: {
    fontSize: 15,
    paddingLeft: 20,
  },
  table: {
    maxWidth: 900,
  },
  profileCell: {
    ...theme.typography.title,
    maxWidth: 50,
    borderBottom: 'none',
  },
  nameCell: {
    ...theme.typography.subheading,
    textAlign: 'center',
    minWidth: 240,
    borderBottom: 'none',
  },
  telCell: {
    ...theme.typography.body1,
    textAlign: 'center',
    minWidth: 180,
    fontWeight: 400,
    borderBottom: 'none',
  },
  telCellHeader: {
    ...theme.typography.subheading,
    textAlign: 'center',
    minWidth: 180,
    borderBottom: 'none',
  },
  noTelCell: {
    ...theme.typography.body2,
    textAlign: 'center',
    minWidth: 180,
    color: '#999',
    borderBottom: 'none',
  },
  checkCell: {
    ...theme.typography.subheading,
    maxWidth: 80,
    align: 'center',
    borderBottom: 'none',
  },
  profileImage: {
    width: 50,
    height: 50,
    cursor: 'pointer',
    borderBottom: 'none',
  },
  icon: {
    width: 40,
    height: 40,
  },
});

class ListUserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserInfoDialog: false,
      usernameFilter: '',
      telFilter: '',
      creatorOnly: false,
    };
    this.loadUsersByPrefixAndRole = this.loadUsersByPrefixAndRole.bind(this);
  }

  componentDidMount() {
    this.props.loadUsersByPrefixAndRole('', '');
  }

  loadUsersByPrefixAndRole() {
    const { loadUsersByPrefixAndRole } = this.props;
    const { usernameFilter, creatorOnly } = this.state;
    loadUsersByPrefixAndRole(
      usernameFilter, 
      creatorOnly ? 'creator' : ''
    );
  }

  handleProfileClick(user) {
    // if (!user.instagram.accessToken) {
    //   alert('no access token'); // eslint-disable-line no-alert
    // }
    this.setState({
      currentUser: user,
      showUserInfoDialog: true,
    });
  }

  renderLastLoginInfo() {
    const user = this.state.currentUser;
    if (!user) {
      return null;
    }
    return (
      <div>
        <p>Last Login Info</p>
        <p>{`  Time: ${new Date(user.lastLoginTime).toLocaleString()}`}</p>
        <hr />
      </div>
    );
  }

  renderUserInfoDialog() {
    return (
      <Dialog
        open={this.state.showUserInfoDialog}
        title="User Info"
      >
        {this.renderLastLoginInfo()}
        <Button onClick={() => this.handleOnDeleteUser()} color="primary">
          Delete User
        </Button>
        <Button onClick={() => this.handleOnUserInfoDialogClose()} color="primary" autoFocus>
          Close
        </Button>
      </Dialog>
    );
  }

  handleOnUserInfoDialogClose() {
    this.setState({ showUserInfoDialog: false });
  }

  handleOnDeleteUser() {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure?')) {
      this.props.deleteUser(this.state.currentUser._id).then(() => {
        this.props.users.splice(this.props.users.indexOf(this.state.currentUser), 1);
        // this.setState({ usernameFilter: this.state.usernameFilter });
        this.setState(prevState => ({ usernameFilter: prevState.usernameFilter }));
      });
      this.handleOnUserInfoDialogClose();
    }
  }

  render() {
    const { classes, users } = this.props;

    return (
      <div className="container" style={{ marginLeft: 'auto', marginRight: 'auto', width: 'max-content' }}>
        {this.renderUserInfoDialog()}
        <div className={classes.searchBox}>
          <TextField
            className={classes.textField}
            style={{ marginRight: 20 }}
            InputProps={{ className: classes.input }}
            onChange={event => this.setState({ usernameFilter: event.target.value })}
            placeholder="Search name or tel"
            value={this.state.usernamePrefix}
            onKeyPress={(evt) => {
              if (evt.key === 'Enter') {
                this.loadUsersByPrefixAndRole();
              }
            }}
          />
          <Button
            variant="contained"
            size="small"
            onClick={() => this.loadUsersByPrefixAndRole()}
            style={{ marginRight: 20 }}
          >
            <Search />
          </Button>
          <span className={classes.label}>creator only</span>
          <Checkbox
            name="creatorOnly"
            checked={this.state.creatorOnly}
            onClick={event => this.setState({ creatorOnly: event.target.checked }, this.loadUsersByPrefixAndRole)}
          />
        </div>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableCell}>
              <TableCell className={classes.profileCell}></TableCell>
              <TableCell className={classes.nameCell}>name</TableCell>
              <TableCell className={classes.telCellHeader}>tel</TableCell>
              <TableCell className={classes.checkCell}>admin</TableCell>
              <TableCell className={classes.checkCell}>creator</TableCell>
              <TableCell className={classes.checkCell}>rec.<br/>creator</TableCell>
              <TableCell className={classes.checkCell}>picked<br/>creator</TableCell>
              <TableCell className={classes.checkCell}>tester</TableCell>
              <TableCell className={classes.checkCell}>
                <img
                  className={classes.icon}
                  src="https://cdn.heartit.kr/img/r2/icon/ic_sns_insta.png"
                  alt=""
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => (
              <Fragment>
                <TableRow key={`listuserpage_table_${user._id}`}>
                  <TableCell className={classes.profileCell}>
                    <InstagramProfile
                      style={{ width: 50, height: 50, cursor: 'pointer' }}
                      instagramId={user.username || ''}
                      onClick={() => this.handleProfileClick(user)}
                    />
                  </TableCell>
                  <TableCell className={classes.nameCell}>
                    <Link to={`/${user.username}`}>{user.username}</Link>
                  </TableCell>
                  {user.tel && user.tel !== '*'
                    && (
                      <TableCell className={classes.telCell}>
                        {user.tel.length === 11
                          ? `${user.tel.substring(0, 3)}-${user.tel.substring(3, 7)}-${user.tel.substring(7, 11)}`
                          : user.tel}
                      </TableCell>
                    )
                  }
                  {(!user.tel || user.tel === '*')
                    && (
                      <TableCell className={classes.noTelCell}>
                        {'등록된 번호 없음'}
                      </TableCell>
                    )
                  }
                  <TableCell className={classes.checkCell}>
                    <Checkbox
                      checked={user.roles.admin === true}
                      onChange={(event, checked) => {
                        user.roles.admin = checked;
                        this.props.updateUser(user._id, {
                          roles: Object.assign({}, user.roles, { admin: checked }),
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.checkCell}>
                    <Checkbox
                      checked={user.roles.creator === true}
                      onChange={(event, checked) => {
                        if (checked) {
                          user.roles.creator = checked;
                          this.props.updateUser(user._id, {
                            roles: Object.assign({}, user.roles, { creator: checked }),
                          });
                        } else {
                          user.roles.creator = checked;
                          user.roles.recCreator = checked;
                          user.roles.pickedCreator = checked;
                          this.props.updateUser(user._id, {
                            roles: Object.assign({}, user.roles, {
                              creator: checked,
                              recCreator: checked,
                              pickedCreator: checked,
                            }),
                          });
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.checkCell}>
                    <Checkbox
                      checked={(user.roles.recCreator === true) && (user.roles.creator === true)}
                      onChange={(event, checked) => {
                        if (!user.roles.creator) {
                          alert('Only creators can be set as a recommended creator');
                          return;
                        }
                        user.roles.recCreator = checked;
                        this.props.updateUser(user._id, {
                          roles: Object.assign({}, user.roles, { recCreator: checked }),
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.checkCell}>
                    <Checkbox
                      checked={(user.roles.pickedCreator === true) && (user.roles.creator === true)}
                      onChange={(event, checked) => {
                        if (!user.roles.creator) {
                          alert('Only creators can be set as a picked creator');
                          return;
                        }
                        user.roles.pickedCreator = checked;
                        this.props.updateUser(user._id, {
                          roles: Object.assign({}, user.roles, { pickedCreator: checked }),
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.checkCell}>
                    <Checkbox
                      checked={user.roles.tester === true}
                      onChange={(event, checked) => {
                        user.roles.tester = checked;
                        this.props.updateUser(user._id, {
                          roles: Object.assign({}, user.roles, { tester: checked }),
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.checkCell}>
                    <Checkbox checked={user.instagram && user.instagram.id !== ''} disabled />
                  </TableCell>
                </TableRow>
                <TableRow key={`listuserpage_table_meta_${user.username}`}>
                  <TableCell colSpan={9} style={{ textAlign: 'right' }}>
                    <TextField
                      label="한 줄 소개"
                      value={user.oneLineSummary}
                      onChange={event => {
                        const newUsers = this.props.users;
                        const targetIdx = newUsers.findIndex((prevUser) => prevUser._id === user._id);
                        newUsers[targetIdx].oneLineSummary = event.target.value;
                        this.setState({ users: newUsers });
                      }}
                      style={{ width: 600, marginRight: 10 }}
                    />
                    <Button
                      variant="contained"
                      onClick={(event) => {
                        this.props.updateUser(user._id, {
                          oneLineSummary: user.oneLineSummary,
                        });
                      }}>
                      수정
                    </Button>
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}

ListUserPage.propTypes = {
  updateUser: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
};

export default compose(
  withStyles(styles),
  connect(state => ({
    auth: state.auth,
    users: loadEntities(state, 'users', 'users').users,
  }), adminActions)
)(ListUserPage);
