import React, { Component } from 'react';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Tooltip,
  Typography,
} from '@material-ui/core';

const styles = {
  panel: {
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, .125)',
  },
  panelDetailsContainer: {
    paddingTop: 25,
    flex: 1,
    flexDirection: 'column',
  },
  panelHeader: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    marginBottom: -1,
  },
};

export default class GuideExpansionPanel extends Component {
  render() {
    const { defaultExpanded, expandIcon, header, headerUrl, tooltip, children } = this.props;
    return (
      <ExpansionPanel
        style={styles.panel}
        defaultExpanded={defaultExpanded}
      >
        <ExpansionPanelSummary
          expandIcon={expandIcon}
          style={styles.panelHeader}
        >
          <Typography>
            { headerUrl ? (
              <Tooltip title={tooltip}>
                <a
                  href={headerUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {header}
                </a>
              </Tooltip>
            ) : (
              header
            )}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={styles.panelDetailsContainer}>
          {children || ''}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}
