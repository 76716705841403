import React, { Component } from 'react';
import range from 'lodash/range';
import PropTypes from 'prop-types';
import Select from 'react-select';

export default class ProfileInputForm extends Component {
  constructor(props) {
    super(props);
    this.birthYear = React.createRef();
    this.email = React.createRef();

    this.handleBirthYearChange = this.handleBirthYearChange.bind(this);
    this.state = {
      birthYear: {
        label: props.birthYearDefault,
        value: props.birthYearDefault
      },
    };
  }

  handleBirthYearChange(data) {
    this.setState({ birthYear: data });
  }

  renderBirthYearSection() {
    const currentYear = (new Date()).getFullYear();
    const years = range(currentYear - 5, currentYear - 100, -1).map(year => ({
      label: year.toString(),
      value: year.toString(),
    }));

    return (
      <div>
        <div className="top-15">
          <label htmlFor="birth-year-select1" className="profile-input-form-label birth-year col-md-6">
            <img
              src="https://cdn.heartit.kr/img/r2/icon/ic_asterisk_red.png"
              width="12px"
              height="12px"
              alt="asterisk"
            />
            출생년도 (birth year)
          </label>
          <div className="form-group col-md-6 birth-year-select">
            <Select
              className=""
              id="birth-year-select1"
              ref={this.birthYear}
              name="birthYear"
              searchable={false}
              clearable={false}
              value={this.state.birthYear}
              onChange={this.handleBirthYearChange}
              options={years}
              placeholder="출생년도를 선택해주세요."
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { handleUpdate, btnText, emailDefault, children } = this.props;

    return (
      <form onSubmit={handleUpdate}>
        <label htmlFor="email" className="profile-input-form-label">
          <img
            src="https://cdn.heartit.kr/img/r2/icon/ic_asterisk_red.png"
            width="12px"
            height="12px"
            alt="asterisk"
          />
          이메일 (email)
        </label>
        <div className="form-group">
          <input
            className="form-control"
            ref={this.email}
            type="email"
            id="email"
            defaultValue={emailDefault}
            placeholder="이메일 주소를 입력하세요."
          />
        </div>
        {this.renderBirthYearSection()}
        {children}
        <button className="btn btn-default edit-profile-save-btn" type="submit">{btnText}</button>
      </form>
    );
  }
}

// TODO: (hyunuk) Delete children if not needed.
ProfileInputForm.defaultProps = {
  children: [],
};

ProfileInputForm.propTypes = {
  btnText: PropTypes.string.isRequired,
  children: PropTypes.array,
  emailDefault: PropTypes.string.isRequired,
  birthYearDefault: PropTypes.string.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};
