import React from 'react';
import PropTypes from 'prop-types';
import { Dimensions, StyleSheet, View, Text } from 'react-native';
import NumberFormat from 'react-number-format';

import {
  Constants, Styles, Colors
} from '../../../constants';


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  summaryContainer: {
    marginBottom: 40,
  },
  summaryHeaderContainer: {
    paddingTop: 13.5,
    marginHorizontal: Constants.viewHorizontalPadding,
    borderTopWidth: 0.5,
    borderTopColor: Colors.defaultGray02,
  },
  summaryHeaderText: {
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
    color: '#000',
    ...Styles.pretendard600,
  },
  summaryContentContainer: {
    marginTop: 16,
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  summaryRow: {
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  summaryLargeRow: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  summarySmallText: {
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
    color: Colors.defaultGray08,
    ...Styles.pretendard400,
  },
  summaryLargeText: {
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
    color: '#000',
    ...Styles.pretendard600,
  },
  summaryLargePriceText: {
    fontSize: 24,
    lineHeight: 28.8,
    height: 29,
    color: '#000',
    ...Styles.pretendard400,
  },
});


class PaymentSummary extends React.Component {
  render() {
    const {
      totalGoodsPrice,
      totalDeliveryCharge,
      totalMemberDcPrice,
      totalSettlePrice,
    } = this.props;

    return (
      <View style={styles.summaryContainer}>
        <View style={styles.summaryHeaderContainer} >
          <Text style={styles.summaryHeaderText}>결제 요약</Text>
        </View>
        <View style={styles.summaryContentContainer}>
          <View style={styles.summaryRow}>
            <Text style={styles.summarySmallText}>총 상품 금액</Text>
            <NumberFormat
              value={totalGoodsPrice}
              displayType="text"
              thousandSeparator
              renderText={value => (
                <Text style={styles.summarySmallText}>
                  {value}
                  원
                </Text>
              )}
            />
          </View>
          <View style={styles.summaryRow}>
            <Text style={styles.summarySmallText}>총 배송비</Text>
            <NumberFormat
              value={totalDeliveryCharge}
              displayType="text"
              thousandSeparator
              renderText={value => (
                <Text style={styles.summarySmallText}>
                  {value}
                  원
                </Text>
              )}
            />
          </View>
          { totalMemberDcPrice > 0 && (
            <View style={styles.summaryRow}>
              <Text style={[styles.summarySmallText, styles.promoTitle]}>프로모션 할인</Text>
              <NumberFormat
                value={totalMemberDcPrice}
                displayType="text"
                thousandSeparator
                renderText={value => (
                  <Text style={[styles.summarySmallText, styles.promoContent]}>
                    -{value}
                    원
                  </Text>
                )}
              />
            </View>
          )}
          <View style={styles.summaryLargeRow}>
            <Text style={styles.summaryLargeText}>총 결제 예상 금액</Text>
            <NumberFormat
              value={totalSettlePrice}
              displayType="text"
              thousandSeparator
              renderText={value => (
                <Text style={[styles.summaryLargePriceText]}>
                  {value}
                  원
                </Text>
              )}
            />
          </View>
        </View>
      </View>
    );
  }
}


PaymentSummary.propTypes = {
  totalGoodsPrice: PropTypes.number.isRequired,
  totalDeliveryCharge: PropTypes.number.isRequired,
  totalMemberDcPrice: PropTypes.number.isRequired,
  totalSettlePrice: PropTypes.number.isRequired,
};


PaymentSummary.defaultProps = {
}


export default PaymentSummary;
