import React from 'react';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';
import Styles from '../../../constants/Styles';
import Colors from '../../../constants/Colors';
import Constants from '../../../constants/Constants';

import FindIdTab from './FindIdTab';
import FindPasswordTab from './FindPasswordTab';

const useStyles = () => ({
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: Constants.viewHorizontalPadding,
    paddingRight: Constants.viewHorizontalPadding,
    maxWidth: 960,
  },
  tabs: {
    backgroundColor: '#fff',
    marginLeft: -1 * Constants.viewHorizontalPadding,
    marginRight: -1 * Constants.viewHorizontalPadding,
  },
  indicator: {
    backgroundColor: '#000',
    height: 3,
  },
  tab: {
    color: 'rgb(0, 0, 0, 0.4)',
    fontSize: 15,
    ...Styles.SFUITextHeavy,
  },
  tabSelected: {
    color: '#000',
    fontSize: 15,
    ...Styles.SFUITextHeavy,
  },
});

const stylesHtml = {
  appBar: {
    top: Constants.mainHeaderHeight,
    shadows: ['none'],
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGray,
    borderBottomStyle: 'solid',
  },
  appBarWithSmartBanner: {
    top: Constants.mainHeaderHeight + Constants.smartBannerHeight,
    shadows: ['none'],
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGray,
    borderBottomStyle: 'solid',
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}


class AccountRecoveryPage extends React.Component {
  constructor(props) {
    super(props);

    const { router } = props;
    let findPassword = false;
    // eslint-disable-next-line no-prototype-builtins
    if (router.location.state && router.location.state.hasOwnProperty('findPassword')) {
      // eslint-disable-next-line prefer-destructuring
      findPassword = router.location.state.findPassword;
    }
    const initialIdx = (findPassword) ? 1 : 0;
    const initialTab = (findPassword) ? 'password' : 'id';

    this.state = {
      index: initialIdx,
      currentTab: initialTab,
      routes: [
        {
          key: 'id',
          title: '아이디 찾기',
        },
        {
          key: 'password',
          title: '비밀번호 찾기',
        },
      ],
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  onChange = (event, index) => {
    const { routes } = this.state;
    this.setState({
      index,
      currentTab: routes[index].key,
    });
  }

  renderFindIdTab = () => {
    const { currentTab } = this.state;
    const focused = currentTab === 'id';
    const { router } = this.props;


    const props = {
      focused,
      router,
    };

    return (
      <FindIdTab
        {...props}
      />
    );
  };

  renderFindPassword = () => {
    const { currentTab } = this.state;
    const focused = currentTab === 'password';
    const { router } = this.props;

    const props = {
      focused,
      router,
    };
    return (
      <FindPasswordTab
        {...props}
      />
    );
  };

  render() {
    const { classes, showSmartBanner } = this.props;
    const { index } = this.state;
    const appBarStyle = showSmartBanner ? stylesHtml.appBarWithSmartBanner : stylesHtml.appBar;

    return (
      <div
        className={classes.container}
      >
        <AppBar position="sticky" style={appBarStyle} elevation={0}>
          <Tabs
            value={index}
            onChange={this.onChange}
            variant="fullWidth"
            classes={{
              root: classes.tabs,
              indicator: classes.indicator,
            }}
          >
            <Tab label="아이디 찾기" classes={{ root: classes.tab, selected: classes.tabSelected }} />
            <Tab label="비밀번호 찾기" classes={{ root: classes.tab, selected: classes.tabSelected }} />
          </Tabs>
        </AppBar>

        <TabPanel value={index} index={0} style={{ height: '100%' }}>
          {this.renderFindIdTab()}
        </TabPanel>
        <TabPanel value={index} index={1} style={{ height: '100%' }}>
          {this.renderFindPassword()}
        </TabPanel>
      </div>
    );
  }
}

export default withStyles(useStyles)(connect(
  state => ({
    showSmartBanner: state.globalLayoutInfo.showSmartBanner,
  }),
  actions,
)(AccountRecoveryPage));
