import React from 'react';
import PropTypes from 'prop-types';
import { 
  StyleSheet, View, Text, Platform,
} from 'react-native';

import { Styles, Colors, Constants } from '../constants';

import TouchableHighlightWithContainer from './TouchableHighlightWithContainer';
import SizeGuide from './SizeGuide';
import BottomSheetModal from './BottomSheetModal';

const headerMarginHeight = Constants.mainHeaderMarginTop
  + ((Platform.OS === 'android') ? 0 : Constants.statusBarHeight);

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    // paddingTop: headerMarginHeight,
    backgroundColor: '#ffffff',
    height: '100vh',
    width: '100vw',
  },
  headerContainer: {
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: Colors.defaultGray02,
    paddingTop: 16,
    paddingBottom: 19,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  headerTitleText: {
    ...Styles.appleSDGothicNeoBold,
    fontSize: 16,
    lineHeight: 18,
    color: Colors.defaultGray08,
  },
  headerCloseButton: {
    position: 'absolute',
    top: 16,
    end: Constants.viewHorizontalPadding,
  },
  headerCloseButtonText: {
    ...Styles.appleSDGothicNeoMedium,
    fontSize: 11,
    lineHeight: 18,
    color: Colors.defaultGray08,
  },
});


class SizeGuideModal extends BottomSheetModal {
  title = '사이즈 가이드';
  enableBackButton = true;

  renderBody() {
    const { targetSubType } = this.props;

    return (
      <SizeGuide
        targetSubType={targetSubType}
        />
    );
  }
}


SizeGuideModal.propTypes = {
  onClose: PropTypes.func,
};


SizeGuideModal.defaultProps = {
  onClose: null,
};


export default SizeGuideModal;
