import * as React from 'react';

function SvgIcImage(props) {
  return (
    <svg width={40} height={41} {...props}>
      <g fill="none" fillRule="evenodd" opacity={0.5}>
        <path
          fill="#BCBCBC"
          d="M25.767 7.167c1.043 0 1.975.646 2.341 1.622l.642 1.711h3.75A2.5 2.5 0 0135 13v16.667a2.5 2.5 0 01-2.5 2.5h-25a2.5 2.5 0 01-2.5-2.5V13a2.5 2.5 0 012.5-2.5h3.75l.642-1.711a2.499 2.499 0 012.34-1.622h11.535z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M20 12.583a7.918 7.918 0 10.001 15.835A7.918 7.918 0 0020 12.583zm0 2.5a5.417 5.417 0 110 10.833 5.417 5.417 0 010-10.833z"
        />
      </g>
    </svg>
  );
}

export default SvgIcImage;

