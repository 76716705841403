import React from 'react';
import {
  Dimensions, StyleSheet, Text, View, FlatList,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions, loadEntities } from 'shared';

import {
  Styles, Colors, Constants,
} from '../../../constants';

import {
  MyPageHeartist
 } from '../../../components-v2';

import BasePage from './BasePage';


const {
  height: windowHeight,
} = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
  blankHeaderContainer: {
    height: 143,
    paddingTop: 60,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  blankHeaderText: {
    color: '#000',
    fontSize: 16,
    lineHeight: 23,
    ...Styles.pretendard400,
  },
  featuredTitleContainer: {
    paddingStart: Constants.viewHorizontalPadding,
    paddingTop: 19.5,
    paddingBottom: 2,
  },
  featuredTitleText: {
    color: Colors.defaultGray08,
    opacity: 0.5,
    fontSize: 14,
    lineHeight: 18,
    height: 18,
    ...Styles.pretendard400,
  },
});


class FollowingHeartistsPage extends BasePage {
  constructor(props) {
    super(props);

    this.type = 'following';
  }

  componentDidMount() {
    super.componentDidMount();

    const {
      logEventPageview,
    } = this.props;

    logEventPageview({ page_name: 'FollowingHeartists' });
  }

  renderBlankHeader() {
    return (
      <View>
        <View style={styles.blankHeaderContainer}>
          <Text style={styles.blankHeaderText}>팔로잉 중인 하티스트가 없습니다.</Text>
        </View>
        <View style={{ height: 0.5, backgroundColor: Colors.lineGray, marginHorizontal: Constants.viewHorizontalPadding }}/>
        <View style={styles.featuredTitleContainer}>
          <Text style={styles.featuredTitleText}>하티스트 추천</Text>
        </View>
      </View>
    );
  }

  renderHeartist(heartist) {
    const { auth, router, loadFollowerHeartists, loadFollowingHeartists, followUser, unfollowUser } = this.props;

    return (
      <MyPageHeartist
        heartist={heartist}
        router={router}
        followUser={(userId) => {
          followUser(userId)
            .then(() => {
              loadFollowerHeartists();
              loadFollowingHeartists();
            });;
        }}
        unfollowUser={(userId) => {
          unfollowUser(userId)
            .then(() => {
              loadFollowerHeartists();
              loadFollowingHeartists();
            });
        }}
        auth={auth}
      />
    );
  }

  renderBody() {
    const {
      showSmartBanner,
      followingHeartists,
      featuredHeartists,
    } = this.props;

    const height = windowHeight
      - (showSmartBanner && Constants.smartBannerHeight)
      - Constants.mainHeaderHeight
      - 49;

    const props = {
      keyExtractor: item => item._id, // eslint-disable-line no-underscore-dangle
      renderItem: ({ item }) => this.renderHeartist(item),
      style: { height },
    }

    const isEmpty = followingHeartists.filter(heartist => heartist.following).length == 0;
    if (isEmpty) {
      props.ListHeaderComponent = this.renderBlankHeader();
      props.data = featuredHeartists.filter(heartist => !heartist.following);
    } else {
      props.ListHeaderComponent = <View style={{ height: 10 }}/>;
      props.data = followingHeartists.filter(heartist => heartist.following);
    }

    return (
      <View style={styles.container}>
        <FlatList
          {...props}
        />
      </View>
    );
  }
}


export default connect(
  state => ({
    auth: state.auth,
    ...loadEntities(state, 'followingHeartists', 'heartists', 'pagination', 'followingHeartists'),
    ...loadEntities(state, 'featuredHeartists', 'heartists', 'pagination', 'featuredHeartists'),
  }),
  actions,
)(FollowingHeartistsPage);
