import React, { useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import { Colors } from '../constants';
import { SquareCheckBoxIcon } from '../icons-v2';


const styles = StyleSheet.create({
});

export default function CheckBox(props) {
  const {
    onPress: onPressCallback,
    baseStyle,
    activeStyle,
    fixed,
    checked,
    disabled = false,
  } = props;

  const [isChecked, setChecked] = useState(false);

  if (checked !== isChecked) {
    setChecked(checked);
  }

  const onPress = () => {
    if (fixed) {
      return;
    }

    setChecked(!isChecked);
    if (onPressCallback !== undefined) {
      onPressCallback(!isChecked);
    }
  };

  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabled}
    >
      <View style={baseStyle} >
        <SquareCheckBoxIcon checked={isChecked} />
      </View>
    </TouchableOpacity>
  );
}
