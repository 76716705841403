import assign from 'lodash/assign';
import get from 'lodash/get';
import set from 'lodash/set';
import setWith from 'lodash/setWith';
import { normalize, schema } from 'normalizr';
import qs from 'qs';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';
import { getNextPageUrl } from '../util';


export function loadLikedProducts(nextPage, count, filter) {
  const key = 'likedProducts';
  const query = {};
  if (count) {
    query.limit = count;
  }
  if (filter) {
    query.filter = filter;
  }
  return createFetchAction({
    type: 'LOAD_LIKED_PRODUCTS',
    endpoint: state => {
      const base = `/api/v2/products/liked?${qs.stringify(query)}`;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    transform: ({ json, response, state }) => {
      const nextPageUrl = getNextPageUrl(response);

      if(json.hasOwnProperty('data')) {
        let likedProducts = normalize(json.data, schemas.likedProducts);
        let likedProductsCountResult = 0;
        if (state.pagination.likedProducts) {
          likedProductsCountResult = state.pagination.likedProducts.countResult;
        }
        const countResult = !json.countResult ? likedProductsCountResult : json.countResult;
        return assign(likedProducts, { nextPageUrl, countResult });
      } else {
        return assign(normalize(json, schemas.likedProducts), { nextPageUrl });
      }
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}

export function loadLikedProductsByUserKey(userId) {
  return `pickedProducts_${userId}`;
}

export function loadLikedProductsByUser(userId, nextPage, count, filter) {
  const key = loadLikedProductsByUserKey(userId);
  const schemaPickedProductList = [new schema.Entity(key, {}, { idAttribute: '_id' })];
  const query = {};
  if (count) {
    query.limit = count;
  }
  if (filter) {
    query.filter = filter;
  }
  return createFetchAction({
    type: 'LOAD_PICKED_PRODUCTS_BY_USER',
    endpoint: state => {
      const base = `/api/v2/products/picked/${userId}?${qs.stringify(query)}`;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    transform: ({ json, response, state }) => {
      const nextPageUrl = getNextPageUrl(response);

      if(json.hasOwnProperty('data')) {
        let likedProducts = normalize(json.data, schemaPickedProductList);
        return assign(likedProducts, { nextPageUrl });
      } else {
        return assign(normalize(json, schemaPickedProductList), { nextPageUrl });
      }
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}


function updateLikeProductState(productId, liked, relatedObjects, state) {
  // console.log(`updateLikeProductState(${productId}, ${liked}) relatedObjects=`, relatedObjects);
  let payload = {};
  const { historyId, favProduct, post, heartistsPick } = relatedObjects;
  let { shopProduct, optionKeys=[] } = relatedObjects;

  if (!favProduct && post) {
    post.products.forEach((item, index, arrays) => {
      if (item._id === productId) {
        arrays[index].liked = liked;
      }
    });

    payload = set(payload, `entities.posts.${post._id}`, post);
  } else {
    Object.keys(state.entities.posts).forEach(key => {
      const value = state.entities.posts[key];
      let find = false;

      value.products.forEach((item, index, arrays) => {
        if (item._id === productId) {
          arrays[index].liked = liked;
          find = true;
        }
      });

      if (find) {
        payload = set(payload, `entities.posts.${key}`, value);
      }
    });
  }

  if (heartistsPick) {
    heartistsPick.products.forEach((item, index, arrays) => {
      if (item._id === productId) {
        arrays[index].liked = liked;
      }
    });

    payload = set(payload, `entities.heartistsPicks.${heartistsPick._id}`, heartistsPick);
  } else {
    Object.keys(state.entities.heartistsPicks).forEach(key => {
      const value = state.entities.heartistsPicks[key];
      let find = false;

      value.products.forEach((item, index, arrays) => {
        if (item._id === productId) {
          arrays[index].liked = liked;
          find = true;
        }
      });

      if (find) {
        payload = set(payload, `entities.heartistsPicks.${key}`, value);
      }
    });
  }

  if (favProduct) {
    favProduct.liked = liked;
    payload = set(payload, `entities.likedProducts.${productId}`, favProduct);

    if (!!historyId) {
      const { ...history } = state.entities.histories[historyId];
      history.product.liked = liked;
      payload = set(payload, `entities.histories.${historyId}`, history);
    }

    if (!shopProduct && (favProduct.godoGoodsNo)) {
      shopProduct = state.entities.shopProducts[favProduct.godoGoodsNo];
    }
  }

  if (shopProduct) {
    if (optionKeys) {
      optionKeys.forEach(optionKey => {
        for (let i = 0; i < shopProduct.option.length; i++) {
          if (shopProduct.option[i].sno != optionKey) continue;
  
          shopProduct.option[i].liked = liked;
          break;
        }
      });  
    }

    let productLiked = liked;
    if (shopProduct.option && shopProduct.option.length > 0) {
      productLiked = false;
      shopProduct.option.forEach(option => {
        if (option.liked) {
          productLiked = true;
        }
      });
    }
    shopProduct.liked = productLiked;

    // entities.shopProducts 정보 업데이트
    payload = setWith(payload, ['entities', 'shopProducts', shopProduct.goodsNo], shopProduct, Object);

    // entities.shopProducts_ 정보 업데이트 (카테고리 별 상품 목록)
    Object.keys(state.entities).forEach(key => {
      if (!key.startsWith('shopProducts_')) {
        return;
      }
      const value = state.entities[key];

      if (!(shopProduct.goodsNo in value)) {
        return;
      }

      payload = setWith(payload, ['entities', key, shopProduct.goodsNo], shopProduct, Object);
    });

    // entities.homeMdCurations 정보 업데이트
    Object.keys(state.entities.homeMdCurations).forEach(exhibitionId => {
      const exhibition = state.entities.homeMdCurations[exhibitionId];

      if (!exhibition || !exhibition.products) {
        return;
      }

      exhibition.products.forEach((product, index) => {

        const exhibitionProductId = product.goodsNo || product.godoGoodsNo || product._id;
        if (exhibitionProductId != productId) {
          return;
        }

        product.liked = productLiked;

        payload = setWith(
          payload,
          ['entities', 'homeMdCurations', exhibitionId, 'products', index],
          product,
          Object,
        );
      });
    });
  }


  return payload;
}

export function likeProduct(productId, body, relatedObjects = {}) {
  const key = 'likedProducts';

  return createFetchAction({
    type: 'LIKE_PRODUCT',
    method: 'POST',
    endpoint: `/api/v2/products/${productId}/like`,
    body,
    transform: ({ state }) => {
      const ret = { result: [] };
      if (state.pagination.likedProducts) {
        ret.countResult = state.pagination.likedProducts.countResult + 1;
        ret.nextPageUrl = state.pagination.likedProducts.nextPageUrl;
      }
      return assign(
        updateLikeProductState(productId, true, relatedObjects, state),
        ret,
      );
    },
    success: {
      // Cart에서 카트 아이템의 찜하기/상품 옵션 수정 창의 찜하기 버튼의 싱크를 위해 필요함
      meta: { entities: { update: true } },
      pagination: { key, type: 'APPEND' },
    },
  });
}

export function unlikeProduct(productId, relatedObjects = {}, optionKey=null) {
  const key = 'likedProducts';

  return createFetchAction({
    type: 'UNLIKE_PRODUCT',
    method: 'DELETE',
    endpoint: optionKey ? (
      `/api/v2/products/${productId}/like/${optionKey}`
    ) : (
      `/api/v2/products/${productId}/like`
    ),
    transform: ({ state }) => {
      const ret = { result: [] };
      if (state.pagination.likedProducts) {
        ret.countResult = state.pagination.likedProducts.countResult - 1;
        ret.nextPageUrl = state.pagination.likedProducts.nextPageUrl;
      }

      return assign(
        updateLikeProductState(productId, false, relatedObjects, state),
        ret,
      );
    },
    success: {
      // Cart에서 카트 아이템의 찜하기/상품 옵션 수정 창의 찜하기 버튼의 싱크를 위해 필요함
      meta: { entities: { update: true } },
      pagination: { key, type: 'APPEND' },
    },
  });
}

export function loadProductRelatedPosts(productId) {
  return createFetchAction({
    type: 'LOAD_PRODUCT_RELATED_POSTS',
    endpoint: `/api/v2/products/${productId}/related/posts`,
    transform: ({ json }) => {
      const posts = json;
      const payload = setWith({}, ['entities', 'shopProductsRelatedPosts', productId], posts, Object);
      return payload;
    },
  });
}

export function loadPreorderFollowingProduct() {
  return createFetchAction({
    type: 'LOAD_PRODUCT_RELATED_POSTS',
    endpoint: '/api/v2/products/preorder/following',
    doDispatch: false,
  });
}

export function loadSoldoutFollowingProduct(productId) {
  return createFetchAction({
    type: 'LOAD_SOLDOUT_FOLLOWING_PRODUCT',
    method: 'GET',
    endpoint: `/api/v2/products/${productId}/soldout/follow`,
    doDispatch: false,
  });
}

export function addSoldoutFollowingProduct(productId, body) {
  return createFetchAction({
    type: 'ADD_SOLDOUT_FOLLOWING_PRODUCT',
    method: 'POST',
    body,
    endpoint: `/api/v2/products/${productId}/soldout/follow`,
    doDispatch: false,
  });
}

export function deleteSoldoutFollowingProduct(productId, optionKey) {
  return createFetchAction({
    type: 'DELETE_SOLDOUT_FOLLOWING_PRODUCT',
    method: 'DELETE',
    endpoint: optionKey ? (
      `/api/v2/products/${productId}/soldout/follow/${optionKey}`
    ) : (
      `/api/v2/products/${productId}/soldout/follow`
    ),
    doDispatch: false,
  });
}
