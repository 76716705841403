import React from 'react'

import FilterMainModal from "./FilterMainModal";


function showFilterMainModal(parent, brandList, filterList, onChange) {
  const {
    modalProps,
    setModalProps,
  } = parent.props;

  // console.log(`showFilterMainModal modalProps=${JSON.stringify(modalProps())}`);

  const children = (
    <FilterMainModal
      brandList={brandList}
      filterList={filterList}
      onChange={onChange}
      modalProps={modalProps}
      setModalProps={setModalProps}
      />
  );

  setModalProps({
    isVisible: true,
    children: children,
    history: [],
  });
}


export default showFilterMainModal;
