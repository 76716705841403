import assign from 'lodash/assign';
import get from 'lodash/get';
import set from 'lodash/set';
import { normalize } from 'normalizr';
import qs from 'qs';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';
import { getNextPageUrl } from '../util';


export function loadStylePostList(nextPage, query={}) {
  const key = 'stylePosts';
  const params = query ? `?${qs.stringify(query).replace('%20', '+')}` : '';

  return createFetchAction({
    type: 'LOAD_STYLE_POST_LIST',
    method: 'GET',
    endpoint: state => {
      const base = '/api/v2/stylePosts' + params;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
      meta: { entities: { update: true } },
    },
    transform: ({ json, response }) => {
      return assign(
        normalize(json, schemas.stylePosts),
        { nextPageUrl: getNextPageUrl(response) }
      );
    },
  });
}

export function loadFollowingStylePostList(nextPage, query={}) {
  const key = 'followingStylePosts';
  const params = query ? `?${qs.stringify(query).replace('%20', '+')}` : '';

  return createFetchAction({
    type: 'LOAD_STYLE_FOLLOWING_POST_LIST',
    method: 'GET',
    endpoint: state => {
      const base = '/api/v2/stylePosts/following' + params;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
      meta: { entities: { update: true } },
    },
    transform: ({ json, response }) => {
      return assign(
        normalize(json, schemas.followingStylePosts),
        { nextPageUrl: getNextPageUrl(response) }
      );
    },
  });
}

export function loadStylePostListByUser(nextPage, userId) {
  const key = `stylePosts.${userId}`;

  return createFetchAction({
    type: 'LOAD_STYLE_POST_LIST_BY_USER',
    method: 'GET',
    endpoint: state => {
      const base = `/api/v2/stylePosts/latest/user/${userId}`;
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
      meta: { entities: { update: true } },
    },
    transform: ({ json, response }) => {

      return assign(
        normalize(json, schemas.stylePosts),
        { nextPageUrl: getNextPageUrl(response) }
      );
    },
  });
}
export function loadStylePostCountByUser(userId) {
  return createFetchAction({
    type: 'LOAD_STYLE_POST_COUNT_BY_USER',
    endpoint: `/api/v2/stylePosts/latest/user/${userId}/count`,
    transform: ({ json }) => {
      return set({}, `entities.stylePostsCount.${userId}`, json);
    }
  });
}

export function loadStylePost(postId) {
  return createFetchAction({
    type: 'LOAD_STYLE_POST',
    endpoint: `/api/v2/stylePosts/${postId}`,
    transform: ({ json }) => normalize(json, schemas.stylePost),
  });
}

export function loadStylePostByTag(tagId) {
  return createFetchAction({
    type: 'LOAD_STYLE_POST_BY_TAG',
    endpoint: `/api/v2/stylePosts/latest/tag/${tagId}`,
    transform: ({ json }) => {
      return json.map(p => {
        if (!p.owner.name) {
          p.owner.name = p.owner.username.split('@')[0];
        }
        return p;
      });
    },
  });
}

export function loadStylePostByUser(userId) {
  const key = `stylePosts.${userId}`;
  return createFetchAction({
    type: 'LOAD_STYLE_POST_BY_TAG',
    endpoint: `/api/v2/stylePosts/latest/user/${userId}`,
    transform: ({ json }) => {
      return json.map(p => {
        if (!p.owner.name) {
          p.owner.name = p.owner.username.split('@')[0];
        }
        return p;
      });
    },
  });
}

export function createStylePost(post) {
  return createFetchAction({
    type: 'CREATE_STYLE_POST',
    endpoint: '/api/v2/stylePosts',
    method: 'POST',
    body: post,
    transform: ({ json }) => normalize(json, schemas.stylePost),
    success: state => ({
      pagination: {
        key: `stylePosts.${state.auth._id}`,
        type: 'PREPEND',
      },
    }),
  });
}

export function updateStylePost(postId, post) {
  return createFetchAction({
    type: 'UPDATE_STYLE_POST',
    endpoint: `/api/v2/stylePosts/${postId}`,
    method: 'PUT',
    body: post,
    transform: ({ json }) => normalize(json, schemas.stylePost),
  });
}

export function deleteStylePost(postId) {
  return createFetchAction({
    type: 'DELETE_STYLE_POST',
    endpoint: `/api/v2/stylePosts/${postId}`,
    method: 'DELETE',
    doDispatch: false,
  });
}

export function loadStylePostComments(postId) {
  const key = `stylePostComments.${postId}`;
  return createFetchAction({
    type: 'LOAD_STYLE_POST_COMMENTS',
    endpoint: `/api/v2/stylePosts/${postId}/replies`,
    transform: ({ json }) => {
      const ret = json.map(c => {
        if (!c.owner.name) {
          c.owner.name = c.owner.username.split('@')[0];
        }
        c.childReplies = c.childReplies.map(ch => {
          if (!ch.owner.name) {
            ch.owner.name = ch.owner.username.split('@')[0];
          }
          return ch;
        });
        return c;
      });
      return set({}, `entities.${key}`, ret);
    },
    success: {
      meta: { entities: { update: true } },
    },
  });
}

export function createStylePostComment(postId, commentId, comment) {
  if (commentId) {
    return createFetchAction({
      type: 'CREATE_STYLE_POST_CHILD_COMMENT',
      endpoint: `/api/v2/stylePosts/${postId}/replies/${commentId}`,
      method: 'POST',
      body: comment,
      doDispatch: false,
    });
  } else {
    return createFetchAction({
      type: 'CREATE_STYLE_POST_COMMENT',
      endpoint: `/api/v2/stylePosts/${postId}/replies`,
      method: 'POST',
      body: comment,
      doDispatch: false,
    });
  }
}

export function deleteStylePostComment(postId, commentId) {
  return createFetchAction({
    type: 'DELETE_STYLE_POST_COMMENT',
    endpoint: `/api/v2/stylePosts/${postId}/replies/${commentId}`,
    method: 'DELETE',
    doDispatch: false,
  });
}

export function loadStylePostProducts(query) {
  const params = query ? `?${qs.stringify(query).replace('%20', '+')}` : '';
  return createFetchAction({
    type: 'LOAD_STYLE_POST_PRODUCTS',
    endpoint: '/api/v2/products' + params,
    doDispatch: false,
  });
}

export function loadStylePostTags(query) {
  const params = query ? `?${qs.stringify(query).replace('%20', '+')}` : '';
  return createFetchAction({
    type: 'LOAD_STYLE_POST_TAGS',
    endpoint: '/api/v2/tags' + params,
    doDispatch: false,
  });
}

export function toggleLikeStylePost(postId) {
  return createFetchAction({
    type: 'TOGGLE_LIKE_STYLE_POST',
    endpoint: `/api/v2/stylePosts/${postId}/like`,
    method: 'PUT',
    doDispatch: false,
  });
}
