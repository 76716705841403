import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as adminActions from 'react-shared/adminActions';
import { get, orderBy } from 'lodash';

const styles = {
  table: {
    margin: "auto",
    width: "calc(100% - 40px)",
    marginTop: 20,
    marginBottom: 20,
  },
  table_cell: {
    padding: "8px 15px",
    height: 43,
    textAlign: 'center',
    border: '1px solid',
    fontSize: 12,
  },
  table_header: {
    background: '#F6F6F6',
  },
  table_body: {
  },
  table_column_no: {
    width: 60,
  },
  table_column_pageview_godo_count: {
    width: 120,
  },
  table_column_click_count: {
    width: 100,
  },
  table_column_button: {
    width: 120,
  },
}

class StatsGodomallRequestList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortBy: 'reqCount',
    };
  }

  componentDidMount() {
    const { startDate, endDate } = this.props;
    this.loadData(startDate, endDate);
  }

  componentDidUpdate(prevProps, prevState) {
    const { kind, startDate, endDate } = this.props;
    if (prevProps.startDate !== startDate || prevProps.endDate !== endDate || prevProps.kind !== kind) {
      this.loadData(startDate, endDate);
    }
  }

  loadData(startDate, endDate) {
    const {
      getGodomallRequestCount,
    } = this.props;

    getGodomallRequestCount(startDate, endDate);
  }

  render() {

    const {
      godomallRequest,
    } = this.props;

    if (!godomallRequest) {
      return null;
    }

    const styleTableHeader = {
      ...styles.table_cell,
      ...styles.table_header,
    };

    const styleTableBody = {
      ...styles.table_cell,
      ...styles.table_body,
    }


    let items = Object.values(godomallRequest).map(item => {
      const ret = {
        ...item,
      };

      return ret;
    });

    let {
      sortBy
    } = this.state;

    items = orderBy(items, [sortBy], ['desc']);

    const onPressReqCount = () => {
      this.setState({ sortBy: 'reqCount'});
    };

    const onPressRespTimeAvg = () => {
      this.setState({ sortBy: 'respTimeAvg' });
    };

    const onPressRespTimeMax = () => {
      this.setState({ sortBy: 'respTimeMax' });
    };

    const onPressRespTimeMin = () => {
      this.setState({ sortBy: 'respTimeMin' });
    };

    return (
      <div>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={{
                ...styleTableHeader,
                ...styles.table_column_no,
              }} rowSpan="2">
                No
              </th>
              <th style={styleTableHeader} rowSpan="2">
                페이지
              </th>
              <th style={{
                ...styleTableHeader,
                ...styles.table_column_click_count,
                }} rowSpan="2">
                <a href='#' onClick={onPressReqCount}>요청수</a>
              </th>
              <th style={{
                ...styleTableHeader,
                ...styles.table_column_click_count,
                }} colSpan="3">
                응답시간 (ms)
              </th>
              <th style={{
                ...styleTableHeader,
                ...styles.table_column_button,
              }} rowSpan="2">
              </th>

            </tr>
            <tr>
            <th style={{
                ...styleTableHeader,
                ...styles.table_column_click_count,
                }}>
                <a href='#' onClick={onPressRespTimeAvg}>Avg</a>
              </th>
              <th style={{
                ...styleTableHeader,
                ...styles.table_column_click_count,
                }}>
                <a href='#' onClick={onPressRespTimeMax}>Max</a>
              </th>
              <th style={{
                ...styleTableHeader,
                ...styles.table_column_click_count,
                }}>
                <a href='#' onClick={onPressRespTimeMin}>Min</a>
              </th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td style={styleTableBody}>
                  {index + 1}
                </td>
                <td style={{ ...styleTableBody, textAlign: 'inherit'}}>
                  {item.name}
                </td>
                <td style={{ ...styleTableBody, textAlign: 'right'}}>
                  {item.reqCount.toLocaleString() || '-'}
                </td>
                <td style={{ ...styleTableBody, textAlign: 'right'}}>
                  {(Math.round(item.respTimeAvg * 100) / 100).toLocaleString(
                    undefined, {minimumFractionDigits: 2}
                  ) || '-'}
                </td>
                <td style={{ ...styleTableBody, textAlign: 'right'}}>
                  {(Math.round(item.respTimeMax * 100) / 100).toLocaleString(
                    undefined, {minimumFractionDigits: 2}
                  ) || '-'}
                </td>
                <td style={{ ...styleTableBody, textAlign: 'right'}}>
                  {(Math.round(item.respTimeMin * 100) / 100).toLocaleString(
                    undefined, {minimumFractionDigits: 2}
                  ) || '-'}
                </td>
                <td style={styleTableBody}>
                  <a href={`/admin/stats/godomall/${item.key}`}>자세히보기</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}


export default connect(
  (state, props) => {
    const { startDate, endDate } = props;
    const godomallRequest = get(state.admin.godomallRequest, `${startDate}~${endDate}`)

    return {
      godomallRequest,
    };
  },
  adminActions,
) (StatsGodomallRequestList);
