import React from 'react';
import { Text, StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';

import { actions } from 'shared';

import Button from '../../../components-v2/Button';
import { Constants, Styles } from '../../../constants';


const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  textContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginVertical: 20,
  },
  completeText: {
    fontSize: 20,
    lineHeight: 54 / 2,
    textAlign: 'center',
    color: '#000',
    ...Styles.pretendard600,
  },
  buttonContainer: {
    flex: 1,
    marginVertical: -10 / 2,
  },
  button: {
    width: '100%',
    height: 40,
    paddingVertical: 10,
    paddingHorizontal: 40,
    borderWidth: 0.5,
    backgroundColor: '#000',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 10 / 2,
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard600,
  },
});


export class OrderManageCompletePage extends React.Component {
  componentDidMount() {
    const { setGlobalState, logEventPageview, orderNo } = this.props;

    setGlobalState({ showMainBottomTabBar: false });

    logEventPageview({ page_name: 'OrderManageComplete', page_key: orderNo });
  }

  componentWillUnmount() {
    const { setGlobalState } = this.props;

    setGlobalState({ showMainBottomTabBar: true });
  }

  render() {
    const { type, orderNo, router } = this.props;
    const typeStr = (type == "refund") ? "반품" : "교환";

    return (
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <Text style={styles.completeText}>{`${typeStr} 요청이 정상적으로`}</Text>
          <Text style={styles.completeText}>완료되었습니다.</Text>
        </View>
        <View style={styles.buttonContainer}>
          <Button
            containerStyle={styles.button}
            titleStyle={styles.buttonText}
            onPress={() => { router.push(`/accounts/orders/${orderNo}`) }}
            title={typeStr + " 상세 내역 보기"}
          />
          <Button
            containerStyle={styles.button}
            titleStyle={styles.buttonText}
            onPress={() => { router.push('/shop') }}
            title="쇼핑하러 가기"
          />
        </View>
      </View>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    orderNo: ownProps.params.orderNo,
    type: ownProps.params.type,
  }),
  actions,
)(OrderManageCompletePage);
