import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

import Styles from '../../../constants/Styles';
import Constants from '../../../constants/Constants';

import TabDetail from './TabDetail';
import TabReview from './TabReview';
import { height as bottomBarHeight } from './Footer';

const useStyles = () => ({
  tabs: {
    backgroundColor: '#fff',
    paddingLeft: 20,
    paddingRight: 20,
  },
  indicator: {
    backgroundColor: '#000',
    height: 1,
  },
  tab: {
    color: '#000',
  },
  tabWrapper: {
    height: 19,
    marginTop: 8,
    marginBottom: 8,
    ...Styles.appleSDGothicNeoBold,
    fontSize: 15,
  },
});

const styles = {
  appBar: {
    top: Constants.mainHeaderHeight,
    shadows: ['none'],
  },
  appBarWithSmartBanner: {
    top: Constants.mainHeaderHeight + Constants.smartBannerHeight,
  },
  tabbarBorderBottom: {
    position: 'absolute',
    bottom: 0,
    height: 1,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const headerHeight = Constants.mainHeaderHeight;
const tabBarHeight = 42;


class TabView extends React.Component {
  state = {
    index: 0,
    windowHeight: window.innerHeight,
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate() {
    // console.log('TabView componentDidUpdate props=', this.props);
  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
    });
  };

  onChange = (event, newValue) => {
    this.setState({ index: newValue });
  };

  render() {
    // console.log('TabView render props=', this.props);
    const { index, windowHeight } = this.state;
    const { classes, showSmartBanner, isPreOrder } = this.props;
    const tabViewContentHeight = windowHeight - headerHeight - tabBarHeight - bottomBarHeight;

    const childProps = {
      ...this.props,
      tabViewContentHeight,
    };

    const appBarStye = {
      ...styles.appBar,
      ...(showSmartBanner && styles.appBarWithSmartBanner),
      ...(isPreOrder && { display: 'none' }),
    };

    return (
      <div>
        <AppBar
          position="sticky"
          style={appBarStye}
          elevation={0}
        >
          <Tabs
            value={index}
            onChange={this.onChange}
            variant="fullWidth"
            classes={{
              root: classes.tabs,
              indicator: classes.indicator,
            }}
          >
            <Tab label="상세정보" classes={{ root: classes.tab, wrapper: classes.tabWrapper }} />
            {!isPreOrder && (<Tab label="리뷰" classes={{ root: classes.tab, wrapper: classes.tabWrapper }} />)}
            <div style={styles.tabbarBorderBottom} />
          </Tabs>
        </AppBar>

        <TabPanel value={index} index={0}>
          <TabDetail {...childProps} />
        </TabPanel>
        <TabPanel value={index} index={1}>
          <TabReview {...childProps} />
        </TabPanel>
      </div>
    );
  }
}


export default withStyles(useStyles)(connect(
  state => ({
    showSmartBanner: state.globalLayoutInfo.showSmartBanner,
  }),
  null,
)(TabView));
