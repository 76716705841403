import React from 'react';
import { Text, TextInput, TouchableHighlight, StyleSheet, View } from 'react-native';

import { Colors, Styles, Constants } from '../../../constants';

import Button from '../../../components-v2/Button';

import Ic24Camera from '../../../icons-v2/Ic24Camera';
import Ic14ArrowDown from '../../../icons-v2/Ic14ArrowDown';
import Ic14ListSelected from '../../../icons-v2/Ic14ListSelected';

import { sectionStyles, modalStyles } from './commonStyles';


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  photoButton: {
    height: 40,
    paddingVertical: 8,
    paddingHorizontal: 40,
    backgroundColor: '#000',
    borderWidth: 0.5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  photoButtonInnerContainer: {
    flexDirection: 'row',
  },
  photoButtonText: {
    marginStart: 6,
    marginVertical: 10.5 - 8,
    color: '#fff',
    fontSize: 16,
    lineHeight: 19.2,
    height: 19,
    ...Styles.pretendard600,
  },
  reasonInputContainer: {
    marginBottom:20,
  },
  reasonInput: {
    marginTop:10,
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 30,
    borderWidth: 0.5,
    borderColor: '#000',
    height: 202,
    textAlignVertical: 'top',
  },
  reasonNumber: {
    position: 'absolute',
    right: 8,
    bottom: 8,
  },
  reasonNumberText: {
    opacity: 0.5,
    fontSize: 13,
    lineHeight: 15.6,
    ...Styles.pretendard400,
  },
  reasonSelector: {
    height: 44,
    borderWidth: 0.5,
    paddingHorizontal: 20,
    paddingVertical: 13,
  },
  reasonSelectorInnerContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  reasonSelectorText: {
    color: '#000',
    fontSize: 15,
    lineHeight: 18,
    ...Styles.pretendard400,
  },
});


class ReasonSelector extends React.Component {
  renderReason = () => {
    const {
      type, onChangeAdditinalInput, onChangePicture,
      additionalReason, selectedReason, 
    } = this.props;

    const additionalReasonLength = (additionalReason) ? additionalReason.length : 0;
    return (
      <View style={sectionStyles.sectionContainer}>
        <View style={styles.reasonInputContainer}>
          <Button
            containerStyle={styles.reasonSelector}
            innerComponent={(
              <View style={styles.reasonSelectorInnerContainer}>
                <Text style={styles.reasonSelectorText}>
                  {selectedReason ? selectedReason: `${type} 사유 선택하기`}
                </Text>
                <Ic14ArrowDown />
              </View>
            )}
            onPress={() => this.showSelectorModal()}
          />
          <TextInput
            placeholder="(선택) 세부 내용을 입력해주세요."
            placeholderTextColor="#00000080"
            style={styles.reasonInput}
            multiline
            numberOfLines={10}
            onChangeText={(text) => onChangeAdditinalInput(text)}
            value={additionalReason}
            maxLength={200}
          />
          <View style={styles.reasonNumber}>
            <Text style={styles.reasonNumberText}>{`${additionalReasonLength} / 200`}</Text>
          </View>
        </View>

        <View style={{ flex: 1}}>
          <Button
            containerStyle={styles.photoButton}
            innerComponent={(
              <View style={styles.photoButtonInnerContainer}>
                <Ic24Camera />
                <Text style={styles.photoButtonText}>사진 첨부하기</Text>
              </View>
            )}
          />
        </View>
      </View>
    );
  }

  showSelectorModal = () => {
    const { setModalProps } = this.props;
    setModalProps({
      isVisible: true,
      isBottomPlaced: true,
      children: this.renderSelectorModal()
    });
  }

  renderSelectorModal = () => {
    const { setModalProps, type, selectedReason, onChangeSelector } = this.props;
    const reasonList = ["색상 혹은 사이즈 변경", "주문 실수", "파손 및 불량", "오배송", "기타"];

    const onClick = () => {
      setModalProps({isVisible: false, isBottomPlaced: false});
    };

    return (
      <div onClick={onClick}>
        <View style={modalStyles.innerContainer} onClick={e => e.stopPropagation()}>
          <View style={modalStyles.headerContainer}>
            <View style={{flex:1}}/>
            <View style={modalStyles.headerTitleContainer}>
              <Text style={modalStyles.headerTitleText}>
                {`${type} 사유 선택하기`}
              </Text>
            </View>
            <View style={{flex:1, alignItems:'flex-end'}}>
              <TouchableHighlight
                onPress={() => onClick()}
                underlayColor={Colors.touchEffectColorWhite}
                style={modalStyles.headerButton}
              >
                <Text style={modalStyles.headerButtonText}>닫기</Text>
              </TouchableHighlight>
            </View>
          </View>
          <View style={modalStyles.contentContainer}>
            {reasonList.map((reason, index) => {
              return (
                <TouchableHighlight
                  key={index}
                  style={modalStyles.selectorButton}
                  underlayColor={Colors.touchEffectColorWhite}
                  onPress={() => {
                    onClick();
                    onChangeSelector(reason);
                  }}
                >
                  <View style={modalStyles.contentLineContainer}>
                    <Text style={[modalStyles.contentText, (reason == selectedReason) ? modalStyles.selectedContent: {}]}>
                      {reason}
                    </Text>
                    {(reason == selectedReason) && (
                      <Ic14ListSelected />
                    )}
                  </View>
                </TouchableHighlight>
              );
            })}
          </View>
        </View>
      </div>
    );
  }

  render() {
    const { type, onChangeSelector, onChangeAdditinalInput, onChangePicture } = this.props;

    return (
      <View style={styles.container}>
        <View style={sectionStyles.sectionHeaderContainer}>
          <Text style={sectionStyles.sectionHeaderText}>
            {`${type} 사유`}
          </Text>
        </View>

        {this.renderReason()}
      </View>
    );
  }
}

export default ReasonSelector;

