import set from 'lodash/set';
import { normalize } from 'normalizr';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';

export function loadPreorderExhibitions() {
  const key = 'preorderExhibitions';

  return createFetchAction({
    type: 'LOAD_HOME_MD_CURATIONS',
    endpoint: '/api/v2/preorderExhibitions/',
    transform: ({ json }) => normalize(json, schemas.preorderExhibitions),
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}

export function loadPreorderExhibition(exhibitionId) {
  return createFetchAction({
    type: 'LOAD_EXHIBITION',
    endpoint: `/api/v2/preorderExhibitions/${exhibitionId}`,
    transform: ({ json }) => normalize(json, schemas.preorderExhibition),
  });
}

export function loadPreorderFollowings() {
  return createFetchAction({
    type: 'LOAD_PREORDER_FOLLOWINGS',
    endpoint: '/api/v2/preorderExhibitions/following',
    doDispatch: false,
  });
}

export function addPreorderFollowing(exhibitionId) {
  return createFetchAction({
    type: 'ADD_PREORDER_FOLLOWING',
    method: 'POST',
    endpoint: `/api/v2/preorderExhibitions/${exhibitionId}/follow`,
    doDispatch: false,
  });
}

export function deletePreorderFollowing(exhibitionId) {
  return createFetchAction({
    type: 'DELETE_PREORDER_FOLLOWING',
    method: 'DELETE',
    endpoint: `/api/v2/preorderExhibitions/${exhibitionId}/follow`,
    doDispatch: false,
  });
}

