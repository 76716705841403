import React, { Component } from 'react';
import PropTypes from 'prop-types';

const styles = {
  table: {
    margin: "auto",
    width: "calc(100% - 40px)",
    marginTop: 20,
    marginBottom: 20,
  },
  table_cell: {
    padding: "8px 15px",
    height: 43,
    textAlign: 'center',
    border: '1px solid',
  },
  table_header: {
    background: '#F6F6F6',
  },
  table_body: {

  },
  table_body_internal_list: {
    margin: "10px 0 5px",
    padding: "10px 10px 0",
    borderTop: "1px dotted #A9A9A9",
    listStyle: "none",
  },
  table_body_internal_list_item: {
    overflow: 'hidden',
    marginBottom: 10,
  },
  table_body_internal_key: {
    float: "left",
  },
  table_body_internal_value: {
    float: "right",
  },
}


class Summary extends Component{
  render() {

    const { visitStatSummary: {
      visit_unique_app,
      visit_unique_web,
      visit_sum_app,
      visit_sum_web,
      visit_unique_member_new_app,
      visit_unique_member_new_web,
      visit_unique_member_return_app,
      visit_unique_member_return_web,
    } = {} } = this.props;

    

    const styleTableHeader = {
      ...styles.table_cell,
      ...styles.table_header,
    };

    const styleTableBody = {
      ...styles.table_cell,
      ...styles.table_body,
    }

    const data = [
      {
        web: visit_unique_web,
        app: visit_unique_app,
      },
      {
        web: visit_sum_web,
        app: visit_sum_app,
      },
      {
        web: visit_unique_member_new_web,
        app: visit_unique_member_new_app,
      },
      {
        web: visit_unique_member_return_web,
        app: visit_unique_member_return_app,
      },
    ];

    return (
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styleTableHeader}>
              방문자수
            </th>
            <th style={styleTableHeader}>
              방문횟수
            </th>
            <th style={styleTableHeader}>
              신규방문자수
            </th>
            <th style={styleTableHeader}>
              재방문자수
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
          {data.map(({ app=0, web=0 }) => (
            <td style={styleTableBody}>
              <strong>{(app + web).toLocaleString()}</strong>
              <ul style={styles.table_body_internal_list}>
                <li style={styles.table_body_internal_list_item}>
                  <strong style={styles.table_body_internal_key}>
                    App
                  </strong>
                  <span style={styles.table_body_internal_value}>
                    {app.toLocaleString()}
                  </span>
                </li>
                <li style={styles.table_body_internal_list_item}>
                  <strong style={styles.table_body_internal_key}>
                    Web
                  </strong>
                  <span style={styles.table_body_internal_value}>
                    {web.toLocaleString()}
                  </span>
                </li>
              </ul>
            </td>
          ))}
          </tr>
        </tbody>
      </table>
    );
  }
}

export default Summary;
