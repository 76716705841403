import * as React from "react"
import Svg, { Path, Rect } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={51}
      height={30}
      viewBox="0 0 51 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M0 15C0 6.716 6.716 0 15 0h21c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15H15C6.716 30 0 23.284 0 15z"
        fill="#B0B0B0"
      />
      <Rect x={0.5} y={0.5} width={50} height={29} rx={14.5} stroke="#B0B0B0" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 28c7.18 0 13-5.82 13-13S22.18 2 15 2 2 7.82 2 15s5.82 13 13 13z"
        fill="#fff"
      />
    </Svg>
  )
}

export default SvgComponent
