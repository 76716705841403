import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgIcFeedUploadSelect(props) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path fill="#000" d="M0 0H24V24H0z" />
      <Path
        d="M6.5 12l3.667 3.667M17.5 8.334l-7.333 7.333"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default SvgIcFeedUploadSelect
