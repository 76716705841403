import React from 'react';
import { View, TouchableHighlight } from 'react-native';
import { connect } from 'react-redux';
import { actions } from 'react-shared';

import HeaderBackIcon from '../../icons-v2/HeaderBackIcon';
import CloseIcon from '../../icons/CloseIcon';

import {
  Colors,
  reactNativeStyles as Styles,
 } from '../../constants';

import Titles from '../../routes/Titles';


const styles = {
  closeButton: {
    marginStart: 12,
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 1,
    marginEnd: 10,
  },
};


class ArrowBack extends React.Component{
  onPressButton = () => {
    const { backButtonStyle, onPress,
      router, routerHistory, updateRouterHistory,
      defaultRouterBackPath,
    } = this.props;
    console.log('routerHistory', routerHistory);

    if (onPress) {
      onPress();
    } else if (backButtonStyle === 'close') {
      //navigation.dismiss();
    } else if (backButtonStyle === 'arrow') {
      //Remove current page from history
      let [...newRouterHistory] = routerHistory;
      newRouterHistory.splice(newRouterHistory.length - 1, 1);

      let i = newRouterHistory.length - 1;
      for (i = newRouterHistory.length - 1; i >= 0; i--) {
        const history = newRouterHistory[i];
        const matches = Titles.filter((reObj) => {
          const { pathRegex } = reObj;
          return history.match(pathRegex) !== null;
        });

        if (matches.length > 0 && matches[0].type === 'search') {
          continue;
        } else {
          break;
        }
      }

      const removeHistoryCount = newRouterHistory.length - i;
      if (removeHistoryCount > 0) {
        console.debug('newRouterHistory', i, newRouterHistory);
        console.debug('newRouterHistory remove', removeHistoryCount);
        newRouterHistory.splice(i, removeHistoryCount);
        console.debug('newRouterHistory spliced', newRouterHistory);
      }

      updateRouterHistory(newRouterHistory, 'replace')
      if (newRouterHistory.length <= 0) {
        router.push(defaultRouterBackPath || '/');
      } else {
        router.go(-1 * removeHistoryCount);
      }
    }
  };

  render() {
    const {
      backButtonStyle: type,
    } = this.props;

    if (type === 'close') {
      return (
        <View style={styles.buttonContainer}>
          <TouchableHighlight
            style={Styles.touchEffectInHeader}
            underlayColor={Colors.touchEffectColorWhite}
            onPress={this.onPressButton}
          >
            <CloseIcon />
          </TouchableHighlight>
        </View>
      );
    } else {
      return (
        <View style={styles.buttonContainer}>
          <TouchableHighlight
            style={Styles.touchEffectInHeader}
            underlayColor={Colors.touchEffectColorWhite}
            onPress={this.onPressButton}
          >
            <HeaderBackIcon />
          </TouchableHighlight>
        </View>
      );
    }
  };
};

export default connect(
  (state, ownProps) => ({
    routerHistory: state.routerHistory.routerHistory,
  }),
  actions,
)(ArrowBack);
