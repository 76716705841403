import { navigateToSettingsAfterJoinDone } from "../../containers-v2/my/SettingsPage";


function runAfterJoinDone(router) {

  if (!!!router) {
    return;
  }

  sessionStorage.removeItem('hasShowenJoineDoneCouponeAlert');

  const pathAfterAuthSuccess = sessionStorage.getItem('pathAfterAuthSuccess');
  if (!!!pathAfterAuthSuccess) {
    navigateToSettingsAfterJoinDone(router);
    return;
  }

  sessionStorage.removeItem('pathAfterAuthSuccess');
  router.push({
    pathname: pathAfterAuthSuccess,
    state: { moveFrom: 'JoinDone' },
  });
}


function runAfterLoginDone(router) {

  if (!!!router) {
    return;
  }

  sessionStorage.removeItem('hasShowenJoineDoneCouponeAlert');

  const pathAfterAuthSuccess = sessionStorage.getItem('pathAfterAuthSuccess');
  if (!!!pathAfterAuthSuccess) {
    router.push('/');
    return;
  }

  sessionStorage.removeItem('pathAfterAuthSuccess');
  router.push({
    pathname: pathAfterAuthSuccess,
    // state: { moveFrom: 'JoinDone' },
  });
}


export {
  runAfterJoinDone,
  runAfterLoginDone,
}
