import React from 'react';
import {
  StyleSheet, View, Text, Image, Platform,
} from 'react-native';
import NumberFormat from 'react-number-format';

import { formatPrice } from 'shared/util';
import { Constants, Colors, Styles } from '../constants';
import ProductInfo from './ProductInfo';


const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  itemContainer: {
    flexDirection: 'column',
  },
  itemContentContainer: {
    flexDirection: 'row',
    height: 98 + 3,
  },
  itemImage: {
    width: 73.5,
    height: 98,
    resizeMode: 'cover',
    marginEnd: 9.5,
  },
  itemContent: {
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  itemBrandNameContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 56,
  },
  itemBrand: {
    fontSize: 11,
    lineHeight: 16,
    color: '#000',
    marginBottom: 6,
    ...Styles.appleSDGothicNeoExtraBold,
  },
  itemName: {
    lineHeight: 16.8,
    fontSize: 14,
    color: '#000',
    ...Styles.pretendard400,
  },
  itemPriceContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 29,
  },
  itemSellPrice: {
    marginTop: 1,
    fontSize: 11,
    lineHeight: 14,
    height: 14,
    color: '#000',
    ...Styles.appleSDGothicNeoBold,
  },
  itemOrgPrice: {
    height: 14,
    lineHeight: 14,
    fontSize: 11,
    color: '#8E8E93',
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
    ...Styles.appleSDGothicNeoMedium,
  },
  preOrderLabelContainer: {
    flexDirection: 'row',
    marginBottom: 8,
    marginTop: 1,
  },
  preOrderLabel: {
    borderRadius: 3,
    borderWidth: 0.5,
    borderColor: '#000',
    height: 17,
    paddingHorizontal: 4,
    paddingVertical: 0,
    backgroundColor: '#fff',
  },
  preOrderLabelText: {
    fontSize: 10,
    color: '#000',
    lineHeight: 17,
    ...Styles.appleSDGothicNeoSemiBold,
  },
});


class OrderItem extends React.Component {

  renderItemInfo = () => {
    const { item } = this.props;
    const {
      goodsNo: productId,
      goodsNm: itemName,
      goodsImageURL,
      brandNm,
      option,
      goodsCnt: itemCount,
      preOrder,
    } = item;
    let {
      price: {
        fixedPrice,
        optionPrice,
        goodsPrice,
      },
    } = item;
    if (optionPrice === null) {
      optionPrice = 0;
    }

    const optionList = option.map(element => {
      const { optionName, optionValue, optionPrice } = element;
      let optionPriceInfo = '';
      // console.log('renderItem optionPrice', optionPrice);
      if (optionPrice !== undefined) {
        const optionPriceInt = Number.parseInt(optionPrice, 10);
        if (optionPriceInt !== 0) {
          optionPriceInfo = ` (${formatPrice(optionPriceInt)})`;
        }
      }

      return (
        <Text key={`option_${optionValue}`} style={styles.itemName}>
          {optionValue}
        </Text>
      );
    });

    const showPreOrderLabel = preOrder === 'y';
    const currentStyles = (showPreOrderLabel) ? styles : styles;

    const itemOrgPrice = (Number.parseInt(fixedPrice, 10) + Number.parseInt(optionPrice, 10)) * itemCount;
    const itemSellPrice = (Number.parseInt(goodsPrice, 10) + Number.parseInt(optionPrice, 10)) * itemCount;
    const discount = Math.round(100 * (1 - itemSellPrice / itemOrgPrice));

    return (
      <ProductInfo
        type="B"
        goodsImageURL={goodsImageURL}
        brandNm={brandNm}
        itemName={itemName}
        itemCount={itemCount}
        itemOrgPrice={itemOrgPrice}
        itemSellPrice={itemSellPrice}
        discount={discount}
        optionList={optionList}
      />
    );
    /*
    return (
      <View style={styles.container}>
        {showPreOrderLabel && (
          <View style={styles.preOrderLabelContainer}>
            <View style={styles.preOrderLabel}>
              <Text style={styles.preOrderLabelText}>프리오더</Text>
            </View>
          </View>
        )}
        <View style={styles.itemContentContainer}>
          <Image
            source={{ uri: goodsImageURL }}
            style={styles.itemImage}
          />
          <View style={styles.itemContent}>
            <View style={styles.itemBrandNameContainer}>
              <Text style={styles.itemBrand}>{brandNm}</Text>
              <Text style={styles.itemName}>{itemName}</Text>
              {optionList}
            </View>
            <View style={styles.itemPriceContainer}>
              <NumberFormat
                value={itemOrgPrice}
                displayType="text"
                thousandSeparator
                renderText={value => (
                  <Text style={[styles.itemOrgPrice]}>
                    {`${value}원`}
                    <Text style={{color: Colors.guideRed, textDecorationColor: 'transparent'}}>{`   ${discount}%`}</Text>
                  </Text>
                )}
              />
              <NumberFormat
                value={itemSellPrice}
                displayType="text"
                thousandSeparator
                renderText={value => (
                  <Text style={styles.itemSellPrice}>
                    {`${value}원`}
                  </Text>
                )}
              />
            </View>
          </View>
        </View>
      </View>
    );
    */
  }

  render() {
    return (
      <View style={styles.itemContainer}>
        {this.renderItemInfo()}
      </View>
    );
  }
}


export default OrderItem;
