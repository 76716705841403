import React from 'react';

import {
  StyleSheet, View, Text, TextInput, Alert,
} from 'react-native';
import { connect } from 'react-redux';

import { actions } from 'shared';
import { validatePassword, PASSWORD_INVALID_ERROR_TEXT } from 'shared/util';
import { Styles, Colors, Constants } from '../../../constants';
import { Button } from '../../../components-v2';
import {
  PasswordHideIcon,
  PasswordShowIcon,
} from '../../../icons-v2';


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  passwordInputContainer: {
    flex: 1,
  },
  fullWidthButtonContainer: {
    flex: 1,
  },
  fullWidthButton: {
    width: '100%',
    padding: 0,
    height: 40,
    backgroundColor: '#000',
    borderWidth: 0.5,
    borderColor: '#000',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullWidthButtonText: {
    color: '#fff',
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard600,
  },
  inputLineContainer: {
    marginBottom: 40,
  },
  inputLabelContatiner: {
    marginBottom: 16,
  },
  inputLabelText: {
    color: '#000',
    opacity: 0.5,
    fontSize: 14,
    lineHeight: 14,
    ...Styles.pretendard400,
  },
  inputText: {
    padding: 0,
    paddingBottom: 8,
    color: '#000',
    fontSize: 16,
    lineHeight: 19.2,
    textAlignVertical: 'center',
    ...Styles.pretendard400,
  },
  inputBorder: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 0,
    borderBottomWidth: 1,
    borderColor: '#000',
  },
  subMessageContainer: {
    marginTop: 8,
  },
  subMessageText: {
    flexWrap: 'wrap',
    fontSize: 12,
    lineHeight: 14.4,
    opacity: 0.5,
    color: '#000',
    ...Styles.pretendard400,
  },
  errorContainer: {
    marginTop: 8,
    justifyContent:'center',
  },
  errorText: {
    flexWrap: 'wrap',
    fontSize: 12,
    lineHeight: 14.4,
    color: Colors.primaryRed,
    ...Styles.pretendard400,
  },
  changePasswordDoneMessage: {
    height: 54,
    lineHeight: 27,
    ...Styles.appleSDGothicNeoBold,
    fontSize: 22,
    marginStart: 30,
    marginTop: 45,
  },
  passwordIcon: {
    position: 'absolute',
    borderWidth: 0,
    padding: Constants.touchEffectWidth,
    borderRadius: Constants.touchEffectBorderRadius,
    margin: -1 * Constants.touchEffectWidth,
    right: 0,
    top: -24 - 5,
  },
});


class ChangePasswordForm extends React.Component {
  state = {
    nowPassword: '',
    nowPasswordHidden: true,
    nowPasswordErrorMessage: '',
    newPassword: '',
    newPasswordHidden: true,
    newPasswordErrorMessage: '',
    newPasswordCheck: '',
    newPasswordCheckHidden: true,
    newPasswordCheckErrorMessage: '',
    done: false,
  };

  passwordInputRef = null;

  newPasswordInpuRef = null;

  newPasswordCheckRef = null;

  componentDidUpdate(prevProps, prevState) {
    const {
      nowPassword: prevNowPassword,
      newPassword: prevNewPassword,
      newPasswordCheck: prevNewPasswordCheck,
      done: prevDone,
    } = prevState;
    const { done, nowPassword, newPassword, newPasswordCheck } = this.state;

    if (nowPassword != prevNowPassword || newPassword != prevNewPassword || newPasswordCheck != prevNewPasswordCheck) {
      if (!done) {
        this.validateInputField();
      }
      if (done && done === prevDone) {
        this.setState({ done: false });
      }
    }
  }

  onPressSave = async () => {
    if (!this.validateInputField()) {
      return;
    }

    const { nowPassword, newPassword, newPasswordCheck } = this.state;

    const params = {
      nowPassword,
      newPassword,
      newPasswordCheck,
    };

    const { changePassword, auth } = this.props;

    try {
      await changePassword(auth._id, params);
      Alert.alert("비밀번호 변경이 완료되었습니다.");
      this.setState({
        done: true,
        nowPassword: '',
        nowPasswordErrorMessage: '',
        newPassword: '',
        newPasswordErrorMessage: '',
        newPasswordCheck: '',
        newPasswordCheckErrorMessage: '',
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error=', error);
      if (error.status === 400) {
        this.setState({ nowPasswordErrorMessage: '현재 비밀번호가 올바르지 않습니다.' });
        return;
      }
      this.setState({ newPasswordCheckErrorMessage: '변경 중 오류가 발생하였습니다. 잠시 후 다시 시도해주세요.' });
    }
  };

  onPressDone = () => {
    const { navigation } = this.props;
    navigation.navigate('Settings');
  }

  validateInputField(checkOnly=false) {
    const { nowPassword, newPassword, newPasswordCheck } = this.state;

    if (!checkOnly) {
      this.setState({
        nowPasswordErrorMessage: '',
        newPasswordErrorMessage: '',
        newPasswordCheckErrorMessage: '',
      });
    }

    if (!nowPassword || nowPassword === '') {
      if (checkOnly) {
        return false;
      }
      this.setState({ nowPasswordErrorMessage: '현재 비밀번호를 입력하세요.' });
      return false;
    }

    if (!newPassword || newPassword === '') {
      if (checkOnly) {
        return false;
      }
      this.setState({ newPasswordErrorMessage: '비밀번호 입력은 필수입니다.' });
      return false;
    }

    if (!validatePassword(newPassword)) {
      if (checkOnly) {
        return false;
      }
      if (newPassword.length < 8) {
        this.setState({ newPasswordErrorMessage: "8자 이상 입력해주세요." });
      } else {
        this.setState({ newPasswordErrorMessage: "영문, 숫자, 특수문자 조합으로 입력해주세요." });
      }
      return false;
    }

    if (newPassword !== newPasswordCheck) {
      if (checkOnly) {
        return false;
      }
      this.setState({ newPasswordCheckErrorMessage: '새 비밀번호가 일치하지 않습니다.' });
      return false;
    }

    return true;
  }

  render() {
    const {
      nowPassword, nowPasswordErrorMessage, nowPasswordHidden,
      newPassword, newPasswordErrorMessage, newPasswordHidden,
      newPasswordCheck, newPasswordCheckErrorMessage, newPasswordCheckHidden,
    } = this.state;

    return (
      <View style={styles.container}>
        <View style={styles.passwordInputContainer}>
          <View style={styles.inputLabelContatiner}>
            <Text style={styles.inputLabelText}>기존 비밀번호 입력</Text>
          </View>

          <View style={styles.inputLineContainer}>
            <View>
              <TextInput
                ref={ref => { this.passwordInputRef = ref; }}
                autoCapitalize="none"
                keyboardType="default"
                onChangeText={val => this.setState({ nowPassword: val })}
                placeholder="기존 비밀번호를 입력해주세요."
                placeholderTextColor={'#00000033'}
                style={[
                  styles.inputBorder,
                  styles.inputText,
                  !!nowPasswordErrorMessage ? {borderBottomColor: Colors.primaryRed} : {},
                ]}
                value={nowPassword}
                secureTextEntry={nowPasswordHidden}
                onSubmitEditing={() => {
                  if (this.newPasswordInputRef) {
                    this.newPasswordInputRef.focus();
                  }
                }}
              />
              <Button
                containerStyle={styles.passwordIcon}
                innerComponent={
                  nowPasswordHidden ? (<PasswordHideIcon />) : (<PasswordShowIcon />)
                }
                onPress={() => this.setState({ nowPasswordHidden: !nowPasswordHidden })}
              />
            </View>
            {!!nowPasswordErrorMessage && (
              <View style={styles.errorContainer}>
                <Text style={styles.errorText}>
                  {nowPasswordErrorMessage}
                </Text>
              </View>
            )}
          </View>

          <View style={styles.inputLabelContatiner}>
            <Text style={styles.inputLabelText}>새 비밀번호 입력</Text>
          </View>

          <View style={styles.inputLineContainer}>
            <View>
              <TextInput
                ref={ref => { this.newPasswordInputRef = ref; }}
                autoCapitalize="none"
                keyboardType="default"
                textContentType="name"
                onChangeText={val => this.setState({ newPassword: val })}
                placeholder="새 비밀번호를 입력해주세요."
                placeholderTextColor={Colors.defaultGray04}
                style={[
                  styles.inputBorder,
                  styles.inputText,
                  !!newPasswordErrorMessage ? {borderBottomColor: Colors.primaryRed} : {},
                ]}
                value={newPassword}
                secureTextEntry={newPasswordHidden}
                onSubmitEditing={() => {
                  if (this.newPasswordCheckInputRef) {
                    this.newPasswordCheckInputRef.focus();
                  }
                }}
              />
              <Button
                containerStyle={styles.passwordIcon}
                innerComponent={
                  newPasswordHidden ? (<PasswordHideIcon />) : (<PasswordShowIcon />)
                }
                onPress={() => this.setState({ newPasswordHidden: !newPasswordHidden })}
              />
            </View>

            {!newPasswordErrorMessage && (
              <View style={styles.subMessageContainer}>
                <Text style={styles.subMessageText}>
                  8자 이상 영문, 숫자, 특수문자 조합
                </Text>
              </View>
            )}

            {!!newPasswordErrorMessage && (
              <View style={styles.errorContainer}>
                <Text style={styles.errorText}>
                  {newPasswordErrorMessage}
                </Text>
              </View>
            )}
          </View>

          <View style={styles.inputLabelContatiner}>
            <Text style={styles.inputLabelText}>새 비밀번호 확인</Text>
          </View>

          <View style={styles.inputLineContainer}>
            <View>
              <TextInput
                ref={ref => { this.newPasswordCheckInputRef = ref; }}
                autoCapitalize="none"
                keyboardType="default"
                textContentType="name"
                onChangeText={val => this.setState({ newPasswordCheck: val })}
                placeholder="새 비밀번호를 한번 더 입력해주세요."
                placeholderTextColor={Colors.defaultGray04}
                style={[
                  styles.inputBorder,
                  styles.inputText,
                  !!newPasswordCheckErrorMessage ? {borderBottomColor: Colors.primaryRed} : {},
                ]}
                value={newPasswordCheck}
                secureTextEntry={newPasswordCheckHidden}
                onSubmitEditing={this.onPressSave}
              />
              <Button
                containerStyle={styles.passwordIcon}
                innerComponent={
                  newPasswordCheckHidden ? (<PasswordHideIcon />) : (<PasswordShowIcon />)
                }
                onPress={() => this.setState({ newPasswordCheckHidden: !newPasswordCheckHidden })}
              />
            </View>

            {!!newPasswordCheckErrorMessage && (
              <View style={styles.errorContainer}>
                <Text style={styles.errorText}>
                  {newPasswordCheckErrorMessage}
                </Text>
              </View>
            )}
          </View>
        </View>

        <View style={styles.fullWidthButtonContainer}>
          <Button
            containerStyle={styles.fullWidthButton}
            titleStyle={styles.fullWidthButtonText}
            onPress={this.onPressSave}
            title="비밀번호 변경"
            disabled={!this.validateInputField(true)}
          />
        </View>
      </View>
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
  }),
  actions
)(ChangePasswordForm);
