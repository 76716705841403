import React, { useState } from 'react';
import { StyleSheet, View, Text, TouchableHighlight } from 'react-native';

import Constants from '../constants/Constants';
import Colors from '../constants/Colors';
import Styles from '../constants/Styles';

import FoldIcon from '../icons/FoldIcon';
import UnfoldIcon from '../icons/UnfoldIcon';
import FoldIconSmall from '../icons/FoldIconSmall';
import UnfoldIconSmall from '../icons/UnfoldIconSmall';


export default function Section(props) {
  const {
    containerStyle, touchContainerStyle,
    title, titleTextStyle, titleContainerStyle,
    children, initialCollapse = true,
    iconSize = 'large',
    outerTouch = true,
  } = props;

  const [isCollapsed, setCollapsed] = useState(initialCollapse);

  let icon;
  if (iconSize === 'small') {
    icon = (isCollapsed) ? <UnfoldIconSmall /> : <FoldIconSmall />;
  } else {
    icon = (isCollapsed) ? <UnfoldIcon /> : <FoldIcon />;
  }

  const OuterComponent = outerTouch ? TouchableHighlight : View;

  return (
    <View style={[styles.container, containerStyle]}>
      <OuterComponent
        onPress={() => setCollapsed(!isCollapsed)}
        underlayColor={Colors.touchEffectColorWhite}
        style={touchContainerStyle}
      >
        <View
          style={[styles.title, titleContainerStyle]}
        >
          <Text style={[styles.titleText, titleTextStyle]}>{title}</Text>

          <View style={(iconSize === 'small') ? styles.smallIconContainer : {}}>
          <View style={styles.touchEffectContainer}>
            <TouchableHighlight
              onPress={() => setCollapsed(!isCollapsed)}
              style={[styles.itemCollapseButton, styles.touchEffect]}
              underlayColor={Colors.touchEffectColorWhite}
            >
              {icon}
            </TouchableHighlight>
          </View>
          </View>
        </View>
      </OuterComponent>

      <View style={{ display: isCollapsed ? 'none' : 'flex' }}>
        {children}
      </View>

    </View>
  );
}


const styles = StyleSheet.create({
  container: {
  },
  title: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemCollapseButton: {
  },
  titleText: {
    fontSize: 18,
    color: 'black',
    ...Styles.appleSDGothicNeoBold,
  },
  smallIconContainer: {
    marginHorizontal: 2,
    marginTop: 2,
    marginBottom: 1,
  },
  touchEffect: {
    ...Styles.touchEffect,
  },
  touchEffectContainer: {
    ...Styles.touchEffectContainer,
  },
});
