import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import Sortable from 'react-sortablejs';
import partition from 'lodash/partition';
import Media from '../Media';

export default class ItemList extends Component {
  handleRecommendUpdate(event, itemIndex) {
    const { onChange } = this.props;
    const items = this.props.items.slice();
    const updatedItem = items[itemIndex];
    if (onChange) {
      items.splice(itemIndex, 1);
      const [normals, recommends] = partition(items, { isRecommend: false });
      updatedItem.isRecommend = event.target.checked;
      if (event.target.checked) { // normal -> recommend
        recommends.push(updatedItem);
      } else {
        normals.push(updatedItem);
      }
      onChange(normals, recommends);
    }
  }

  handleIsSeasonOutUpdate(event, itemIndex) {
    const { onChange } = this.props;
    const items = this.props.items.slice();
    const updatedItem = items[itemIndex];
    updatedItem.isSeasonOut = event.target.checked;

    const [normals, recommends] = partition(items, { isRecommend: false });
    onChange(normals, recommends);
  }

  handleOrderUpdate(event, isRecommendList) {
    const items = this.props.items.slice();
    const { onChange } = this.props;
    const { oldIndex, newIndex } = event;
    if (onChange) {
      const [normals, recommends] = partition(items, { isRecommend: false });
      if (isRecommendList) {
        recommends.splice(newIndex, 0, recommends.splice(oldIndex, 1)[0]);
      } else {
        normals.splice(newIndex, 0, normals.splice(oldIndex, 1)[0]);
      }
      onChange(normals, recommends);
    }
  }

  renderItem(item, index, isRecommend, showRecommend, showSeasonOut) {
    const { getMedia } = this.props;
    const media = getMedia(item);
    const mediaOptions = {
      width: 100,
      height: 100,
      crop: 'limit',
    };
    if (item.isRecommend === undefined) {
      item.isRecommend = false;
    }
    if (item.isRecommend !== isRecommend) {
      return null;
    }
    return (
      <div
        className="col-xs-6 col-md-3"
        data-id={item._id || media.publicId}
        key={item._id || media.publicId}
      >
        <div className="thumbnail">
          {this.props.sortable && (
            <div>
              <span style={{ cursor: 'pointer' }}>&#10021;</span>
            </div>
          )}
          <Media media={media} options={mediaOptions} />
          { showRecommend && (
            <FormControlLabel
              control={(
                <Checkbox
                  checked={item.isRecommend}
                  onChange={event => this.handleRecommendUpdate(event, index)}
                />
              )}
              label={<Typography variant="body2">유사상품</Typography>}
            />
          )}
          { showSeasonOut && (
            <FormControlLabel
              control={(
                <Checkbox
                  checked={item.isSeasonOut}
                  onChange={event => this.handleIsSeasonOutUpdate(event, index)}
                />
              )}
              label={<Typography variant="body2">시즌아웃 상품</Typography>}
            />
          )}
          <div className="caption" style={{ display: 'inline' }}>
            {this.props.renderCaption(item, index)}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { items, isRecommendList, showRecommend=true, showSeasonOut=true } = this.props;
    return (
      <div className="row">
        <Sortable
          option={{ handle: '.handle' }}
          onChange={(order, sortable, event) => this.handleOrderUpdate(event, isRecommendList)}
        >
          {items.map((item, index) => this.renderItem(item, index, isRecommendList, showRecommend, showSeasonOut))}
        </Sortable>
      </div>
    );
  }
}

ItemList.propTypes = {
  items: PropTypes.array.isRequired,
  sortable: PropTypes.bool,
  isRecommendList: PropTypes.bool.isRequired,
  getMedia: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  renderCaption: PropTypes.func.isRequired,
};

ItemList.defaultProps = {
  sortable: true,
};
