import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { camelizeKeys } from 'humps';
import dayjs from 'dayjs';
import Flatpickr from 'react-flatpickr';

// import { browserHistory } from 'react-router';
// all codes using browserHistory are legacy in react-router v3;
// anyways, the solution preventing admins from accidently clicking backbutton, reload, or close window
// eslint-disable-next-line max-len
// I follow the https://stackoverflow.com/questions/32841757/detecting-user-leaving-page-with-react-router/50388073#50388073
// but this.props.route.path doesn't have exact path, I instead used this.props.router.location.pathname
import {
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Select,
  MenuItem,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import FiberNew from '@material-ui/icons/FiberNew';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';

import * as adminActions from 'react-shared/adminActions';
import { objectDiff } from 'react-shared/util';

import config from '../../config';
import { Cloudinary } from 'cloudinary-core';
const cloudinary = Cloudinary.new();
cloudinary.config({
  ...config.get('cloudinary'),
  secure: true,
});

const styles = theme => ({
  cancelConfirmButton: {

  },
  createPostButton: {
    position: 'absolute',
    right: theme.spacing.unit * 2,
    top: theme.spacing.unit * 2,
  },
  deletePostButton: {
    position: 'absolute',
    right: theme.spacing.unit * 12,
    top: theme.spacing.unit * 2,
  },
  deleteMediaButton: {
    width: theme.spacing.unit * 3,
    height: theme.spacing.unit * 3,
    float: 'right',
  },
  snackbar: {
    textAlign: 'center',
  },
  formControlLabel: {

  },
  dialogPaper: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
    width: '100%',
  },
  select: {
    width: 100,
  },
  mediaId: {
    width: 280,
  },
  link: {
    width: '100%',
  },
  gridListTileBarIconButton: {
    color: '#ffffff',
  },
  gridListTile: {
    cursor: 'pointer',
    width: 240,
    height: 240,
  },
  paper: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
  },
  outerButton: {
    marginLeft: 10,
    marginRight: 10,
    width: 660,
    marginTop: 5,
    marginBottom: 15,
  },
  subMenuTitles: {
    marginBottom: theme.spacing.unit * 2,
  },
  thumbnailArea: {
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'table-cell',
  },
  textField: {
    minHeight: 50,
  },
  year: {
    width: 55,
    marginLeft: 20,
    textAlign: 'center',
  },
  dateTime: {
    width: 32,
    marginLeft: 20,
    textAlign: 'center',
  },
  textDetailDesc: {
    fontWeight: 400,
    lineHeight: 1,
    color: '#777',
    fontSize: '0.9em',
  },
});

class MarketingPopupPage extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUploadImage = this.handleUploadImage.bind(this);
    this.state = {
      media: null,
      linkType: 'outlink',
      linkValue: '',
      linkedPost: {},
      linkedProduct: {},
      linkedUrl: '',
      enabled: false,
      open: 0,
      close: 0,
      position: 'bottom',
      displayTab: 'home',
      isUpdatingPost: false,
    };
  }

  async componentDidMount() {
    const { adminGetMarketingPopup, popupId, route, router, postId, productId } = this.props;
    if (popupId === 'new') {
      if (postId) {
        this.setState({
          linkType: 'post',
          linkValue: postId,
        });
        this.showSnackbar('팝업 링크 설정에 포스트 ID가 적용되었습니다.');
      } else if (productId) {
        this.setState({
          linkType: 'product',
          linkValue: productId,
        });
        this.showSnackbar('팝업 링크 설정에 상품 ID가 적용되었습니다.');
      }
    } else {
      await adminGetMarketingPopup(popupId)
        .then(popupData => {
          const data = { ...popupData };
          if (data.linkType === 'post') {
            data.linkValue = data.linkedPost ? data.linkedPost._id : '';
          } else if (data.linkType === 'product') {
            data.linkValue = data.linkedProduct ? data.linkedProduct._id : '';
          } else if (data.linkType === 'curation') {
            data.linkValue = data.linkedCuration ? data.linkedCuration.id : '';
          } else {
            data.linkValue = data.linkedUrl;
          }
          this.setState({ ...data });
        });
    }
    this.saveState();
    window.addEventListener('resize', () => this.handleResize());
    window.addEventListener('beforeunload', event => this.handleClose(event));
    this.mutationObserver = new MutationObserver(() => this.removeBodyStyle());
    this.mutationObserver.observe(document.body, {
      attributes: true,
      attributeFilter: ['style'],
    });
    router.setRouteLeaveHook(route, () => this.handleBackbutton());
  }

  componentDidUpdate(prevProps) {
    const { popupId } = this.props;
    if (prevProps.popupId !== popupId && popupId === 'new') {
      window.location.reload();
    }
  }

  componentWillUnmount() {
    this.mutationObserver.disconnect();
    window.removeEventListener('resize', () => this.handleResize());
    window.removeEventListener('beforeunload', event => this.handleClose(event));
  }

  saveState() {
    this.savedState = cloneDeep(this.state);
  }

  hasPopupEdited() {
    return !!Object.keys(objectDiff(this.savedState, this.state)).length;
  }

  showSnackbar(message) {
    this.setState({
      snackbarMsg: message,
      snackbarOpen: true,
    });
  }

  handleClose(event) {
    if (this.hasPopupEdited()) {
      event.preventDefault();
      event.returnValue = '마케팅 팝업을 작성중이시네요, 정말 창을 닫으시겠어요?';
      return event.returnValue;
    }
    return event;
  }

  removeBodyStyle() { // FIX!
    const {
      isItemDialogOpen: item,
      isWebItemDialogOpen: webItem,
    } = this.state;
    if (!item && !webItem && document.body.style.overflow === 'hidden') {
      document.body.style.overflow = '';
    }
  }

  handleBackbutton() {
    const { router: { location: { pathname } } } = this.props;
    if (this.hasPopupEdited()) {
      // eslint-disable-next-line no-restricted-globals
      const toBeClosed = confirm('마케팅 팝업을 작성중이시네요, 정말 창을 닫으시겠어요?');
      if (!toBeClosed) {
        window.history.pushState(null, null, pathname);
        return false;
      }
    }
    return true;
  }

  handleResize() {
    if (window.innerWidth <= 600 && this.state.tilesInRow === 4) {
      this.setState({ tilesInRow: 1 });
    } else if (window.innerWidth > 600 && this.state.tilesInRow === 1) {
      this.setState({ tilesInRow: 4 });
    }
  }

  handleTitleChange(event) {
    this.setState({ title: event.target.value });
  }

  handleDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  handleLinkValueChange(event) {
    this.setState({ linkValue: event.target.value });
  }

  handleOpenChange(event) {
    this.setState({ open: Math.floor(new Date(event).getTime() / 1000) });
  }

  handleCloseChange(event) {
    this.setState({ close: Math.floor(new Date(event).getTime() / 1000) });
  }

  handleSubmit(event) {
    const fields = ['media', 'title', 'description', 'linkType',
        'linkValue', 'enabled', 'open', 'close', 'position', 'displayTab'];
    const marketingPopup = pick(this.state, fields);
    const { adminCreateMarketingPopup, adminUpdateMarketingPopup, popupId, router } = this.props;

    if (!marketingPopup.media) {
      alert('팝업 이미지가 등록되지 않았습니다');
      return;
    }

    if (!marketingPopup.title) {
      alert('팝업 제목이 등록되지 않았습니다');
      return;
    }

    if ((marketingPopup.linkType !== 'outlink' && !marketingPopup.linkValue)
      || !marketingPopup.open
      || !marketingPopup.close
      || !marketingPopup.position
      || !marketingPopup.displayTab) {
      alert('팝업 링크, 디스플레이 시간 설정은 반드시 입력해야 하는 값입니다.');
      return;
    }

    if (marketingPopup.linkType === 'post') {
      marketingPopup.postId = marketingPopup.linkValue;
    } else if (marketingPopup.linkType === 'product') {
      marketingPopup.productId = marketingPopup.linkValue;
    } else if (marketingPopup.linkType === 'curation') {
      marketingPopup.curationId = marketingPopup.linkValue;
    } else {
      marketingPopup.linkedUrl = marketingPopup.linkValue;
    }

    if (popupId === 'new') {
      this.setState(
        { isUpdatingPost: true },
        () => adminCreateMarketingPopup(marketingPopup)
          .then(() => {
            this.showSnackbar('Marketing popup created successfully.');
          })
          .then(() => this.saveState())
          .catch(() => this.showSnackbar('Update failed.'))
          .then(() => router.push('/admin/marketingPopups'))
      );
    } else {
      this.setState(
        { isUpdatingPost: true },
        () => adminUpdateMarketingPopup(popupId, marketingPopup)
          .then(() => this.setState({ isUpdatingPost: false }, () => this.saveState()))
          .then(() => this.showSnackbar('Marketing popup updated succesfully.'))
          .catch(() => this.showSnackbar('Update failed.'))
      );
    }
    event.preventDefault();
  }

  handleUploadImage() {
    const uploadWidget = window.cloudinary.openUploadWidget({
      ...config.get('cloudinary'),
      sources: ['local', 'url', 'camera'],
      default_source: 'local',
      cropping: 'server',
      cropping_default_selection_ratio: 1.0,
      cropping_aspect_ratio: 1.0,
      client_allowed_formats: ['jpg', 'jpeg', 'png', 'gif'],
      resource_type: 'image',
      multiple: false,
    }, (error, result) => {
      if (error) {
        // eslint-disable-next-line no-console
        console.error('handleUploadImage error=', error);
      } else {
        // eslint-disable-next-line no-console
        // console.log('handleUploadImage openUploadWidget result=', result);
      }

      if (result && result.event === 'success') {
        uploadWidget.close();
        this.setState(prevState => ({ media: camelizeKeys(result.info) }));
      }
    });
  }

  renderOpen() {
    const { open } = this.state;

    return (
      <div>
        <FormControlLabel
          control={(
            <Checkbox
              checked={!!open}
              // disabled={open && open < 10000}
              onChange={event => {
                if (event.target.checked) {
                  this.setState({ open: (dayjs().valueOf() / 1000) });
                } else {
                  this.setState({ open: null });
                }
              }}
            />
          )}
          label={(open && open > 10000) ? 'Open at' : 'Open'}
        />
        <Flatpickr
          key={open * 1000}
          value={open * 1000}
          options={{
            enableTime: true,
            minTime: '06:00',
            maxTime: '23:59',
            dateFormat: 'Y-m-d H:i:S',
          }}
          data-enable-time
          onChange={event => this.handleOpenChange(event)}
        />
      </div>
    );
  }

  renderClose() {
    const { close } = this.state;

    return (
      <div>
        <FormControlLabel
          control={(
            <Checkbox
              checked={!!close}
              // disabled={close && close < 10000}
              onChange={event => {
                if (event.target.checked) {
                  this.setState({ close: (dayjs().valueOf() / 1000) });
                } else {
                  this.setState({ close: null });
                }
              }}
            />
          )}
          label={(close && close > 10000) ? 'Closed at' : 'Closed'}
        />
        <Flatpickr
          key={close * 1000}
          value={close * 1000}
          options={{
            enableTime: true,
            minTime: '06:00',
            maxTime: '23:59',
            dateFormat: 'Y-m-d H:i:S',
          }}
          data-enable-time
          onChange={event => this.handleCloseChange(event)}
        />
      </div>
    );
  }

  render() {
    const { classes, popupId } = this.props;
    const { media, title, description, linkType, linkValue, position, displayTab } = this.state;
    // const [normalItems, recommendItems] = partition(items, { isRecommend: false });
    return (
      <div className="container">
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>팝업 이미지</Typography>
          <div style={{ width: 468, height: 468, marginLeft: 126 }}>
            {media ? (
              <img
                src={media.secureUrl || cloudinary.url(media.publicId)}
                style={{ width: 468, height: 'auto' }} />
            ) : (
              <div
                className={classes.thumbnailArea}
                style={{ width: 468, height: 468 }}
              >
                팝업 이미지를 업로드하면
                <br />
                이곳에 표시됩니다
              </div>
            )}
          </div>
          <Button
            className={classes.outerButton}
            onClick={this.handleUploadImage}
            variant="outlined"
          >
            팝업 이미지 업로드
          </Button>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>팝업 제목</Typography>
          <div>
            <TextField
              className={classes.link}
              value={title}
              label="팝업 제목"
              placeholder="팝업 제목"
              onChange={event => this.handleTitleChange(event)} />
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>팝업 본문</Typography>
          <div>
            <TextField
              className={classes.link}
              value={description}
              multiline={true}
              rows={5}
              rowsMax={10}
              label="팝업 본문"
              placeholder="팝업 본문"
              onChange={event => this.handleDescriptionChange(event)} />
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>팝업 링크 설정</Typography>
          <div>
            링크 종류&nbsp;&nbsp;
            <Select
              className={classes.select}
              value={linkType}
              onChange={event => {
                this.setState({
                  linkType: event.target.value,
                });
              }}
            >
              <MenuItem value="post" key="marketingpopuppage_linktype_post">
                <Typography variant="subtitle1">포스트 ID</Typography>
              </MenuItem>
              <MenuItem value="product" key="marketingpopuppage_linktype_product">
                <Typography variant="subtitle1">상품 ID</Typography>
              </MenuItem>
              <MenuItem value="curation" key="marketingpopuppage_linktype_curation">
                <Typography variant="subtitle1">기획전 ID</Typography>
              </MenuItem>
              <MenuItem value="outlink" key="marketingpopuppage_linktype_outlink">
                <Typography variant="subtitle1">URL</Typography>
              </MenuItem>
            </Select>
          </div>
          <div>
            <TextField
              className={classes.link}
              value={linkValue}
              label="팝업 링크로 사용할 값을 입력하세요"
              placeholder="팝업 링크 값"
              onChange={event => this.handleLinkValueChange(event)} />
          </div>
          <div style={{ marginTop: 10 }}>
            <span className={classes.textDetailDesc}>
              * 포스트/상품 ID는 'Posts', 'Products'에서 팝업 생성 버튼(사각형 2개 버튼)을 사용하면 간편하게 설정 가능합니다.<br />
              * 링크를 사용하지 않으려면 링크 종류로 'URL'을 고르고 값을 비워두면 됩니다.
            </span>
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>팝업 디스플레이 시간</Typography>
          <div>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css"></link>
            {this.renderOpen()}
            {this.renderClose()}
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>팝업 위치 설정</Typography>
          <div>
            <Select
              className={classes.select}
              value={position}
              onChange={event => {
                this.setState({
                  position: event.target.value,
                });
              }}
            >
              <MenuItem value="bottom" key="marketingpopuppage_position_bottom">
                <Typography variant="subtitle1">하단팝업</Typography>
              </MenuItem>
            </Select>
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>팝업 표시 탭 설정</Typography>
          <div>
            <Select
              className={classes.select}
              value={displayTab}
              onChange={event => {
                this.setState({
                  displayTab: event.target.value,
                });
              }}
            >
              <MenuItem value="home" key="marketingpopuppage_displaytab_home">
                <Typography variant="subtitle1">HOME 탭</Typography>
              </MenuItem>
            </Select>
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.subMenuTitles}>팝업 상태</Typography>
          <div>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={this.state.enabled}
                  onChange={(event, checked) => this.setState({ enabled: checked })}
                />
              )}
              label="Enabled"
            />
          </div>
        </Paper>
        <div style={{ height: 200, position: 'relative' }}>
          <Button
            variant="fab"
            onClick={this.handleSubmit}
            className={classes.createPostButton}
            color="primary"
            disabled={this.state.isUpdatingPost}
          >
            {popupId === 'new' ? '업로드' : '완료'}
          </Button>
          {
            popupId !== 'new' && (
              <Button
                variant="fab"
                onClick={() => {
                  if (confirm('정말로 이 팝업을 삭제하시겠습니까?')) { // eslint-disable-line
                    this.props.adminDeleteMarketingPopup(this.props.popupId);
                    this.props.router.push('/admin/marketingPopups');
                  }
                }}
                color="secondary"
                className={classes.deletePostButton}
              >
                제거
              </Button>
            )
          }
        </div>
        <Snackbar
          autoHideDuration={2000}
          className={classes.snackbar}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
        />
      </div>
    );
  }
}

MarketingPopupPage.propTypes = {
  adminCreateMarketingPopup: PropTypes.func.isRequired,
  adminDeleteMarketingPopup: PropTypes.func.isRequired,
  adminGetMarketingPopup: PropTypes.func.isRequired,
  adminUpdateMarketingPopup: PropTypes.func.isRequired,
  popupId: PropTypes.string.isRequired,
  router: PropTypes.object.isRequired,
};

export default compose(
  withStyles(
    styles
  ),
  connect((state, ownProps) => {
    const { productId, postId } = ownProps.location.query;
    return {
      postId,
      productId,
      popupId: ownProps.params.popupId,
    };
  }, adminActions),
)(MarketingPopupPage);
