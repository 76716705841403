import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { br2nl } from '../../utils';
import NumberFormat from 'react-number-format';

import has from 'lodash/has';
import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import findIndex from 'lodash/findIndex';
import { camelizeKeys } from 'humps';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  GridList,
  GridListTile,
  IconButton,
  MenuItem,
  List,
  ListItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import { Media, Spinner } from 'components';
import { parseDate, textToClipboard } from 'react-shared/util';
import * as adminActions from 'react-shared/adminActions';
import * as actions from 'react-shared/actions';

import {
  MatchingPostSelector,
} from 'components/admin';

import { Cloudinary } from 'cloudinary-core';
import config from '../../config'
const cloudinary = Cloudinary.new();
cloudinary.config({
  ...config.get('cloudinary'),
  secure: true,
});

const styles = theme => ({
  subMenuTitles: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
  },
  thumbnailArea: {
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'table-cell',
  },
  buttonAddProductImage: {
    marginLeft: 10,
    marginRight: 10,
    width: 340,
    marginTop: 5,
    marginBottom: 15,
  },
  textField: {
    marginLeft: 10,
    marginRight: 10,
    minHeight: 50,
    width: 700,
  },
  textFieldHalf: {
    marginLeft: 10,
    marginRight: 10,
    minHeight: 50,
    width: 340,
  },
  confirmButton: {
    marginLeft: 10,
    marginRight: 10,
    width: 700,
    marginTop: 5,
    marginBottom: 15,
  },
  dialogPaper: {
    marginTop: 10,
    padding: 10,
    width: 740,
  },
  dialogueGridListTile: {
    cursor: 'pointer',
    width: 240,
    height: 240,
  },
  dialogPaperMatchingPost: {
    marginTop: theme.spacing(2),
    paddingLeft: 10,
    paddingRight: 10,
    width: 960,
    maxWidth: 960,
  },
});

function getTimestampStr(date) {
  return `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} `
    + `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
}

class ProductPage extends Component {
  constructor(props) {
    super(props);
    this.handleAddProductImage = this.handleAddProductImage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleConflictError = this.handleConflictError.bind(this);
    this.handleAddFromUrlDialog = this.handleAddFromUrlDialog.bind(this);
    this.parseImagesFromUrl = this.parseImagesFromUrl.bind(this);
    this.handleAddMatchingPosts = this.handleAddMatchingPosts.bind(this);
    this.handleAddMatchingPostsSubmit = this.handleAddMatchingPostsSubmit.bind(this);
    this.state = {
      product: { _id: null },
      shopProduct: null,
      orgLink: '',
      orgPriceNum: 0,
      sellPriceNum: 0,
      addFromUrlDialogOpen: false,
      webProductUrl: '',
      webProductImages: [],
      webProductTilesInRow: 4,
      webProductImagesFetching: false,
      checkedMatchingPosts: {},
      newMatchingPosts: {},
      isMatchingPostDialogOpen: false,
      snackbarMsg: '',
      snackbarOpen: false,
      ongoingRequest: false,
    };
  }

  async componentDidMount() {
    const { adminGetProduct, productId, loadShopProduct } = this.props;
    if (productId !== 'new') {
      const product = await adminGetProduct({ productId });
      const newState = { product };
      if (product.godoGoodsNo) {
        const { godoGoodsNo } = product;
        const shopProduct = await loadShopProduct({ productId: godoGoodsNo, updateRecent: true });
        newState.shopProduct = shopProduct.data;
      }

      const retail = product.retails[product.defaultRetailIdx]
      if (retail) {
        newState.orgPriceNum = retail.orgPrice ? retail.orgPrice.amount : 0;
        newState.sellPriceNum = retail.sellPrice ? retail.sellPrice.amount : 0;
        newState.orgLink = retail.orgLink ? retail.orgLink : '';
      }

      const checkedMatchingPosts = {};
      product.matchingPosts.forEach(post => {
        checkedMatchingPosts[post._id] = post;
      });
      newState.matchingPosts = product.matchingPosts;
      newState.checkedMatchingPosts = checkedMatchingPosts;
      this.setState(newState);
    }
  }

  componentDidUpdate(prevProps) {
    const { productId } = this.props;
    if (prevProps.productId !== productId && productId === 'new') {
      window.location.reload();
    }
  }

  showSnackbar(message) {
    this.setState({
      snackbarMsg: message,
      snackbarOpen: true,
    });
  }

  openUploadWidget(mediaOrgLink) {
    const uploadWidget = window.cloudinary.openUploadWidget({
      ...config.get('cloudinary'),
      sources: ['local', 'url', 'camera'],
      default_source: 'url',
      cropping: 'server',
      cropping_default_selection_ratio: 1.0,
      cropping_aspect_ratio: 1.0,
      client_allowed_formats: ['jpg', 'jpeg', 'png', 'gif'],
      resource_type: 'image',
      multiple: false,
    }, (error, result) => {
      if (error) {
        // eslint-disable-next-line no-console
        console.error('handleAddProductImage openUploadWidget error=', error);
      } else {
        // eslint-disable-next-line no-console
        // console.log('handleAddProductImage openUploadWidget result=', result);
      }

      if (result && result.event === 'success') {
        uploadWidget.close();
        this.setState(prevState => ({
          product: assign({}, prevState.product, {
            media: camelizeKeys(result.info),
            mediaOrgLink,
          }),
        }));
      }
    });
  }

  handleAddProductImage() {
    if (!navigator.clipboard) {
      this.openUploadWidget();
      return;
    }

    navigator.clipboard.readText().then(mediaOrgLink => {
      if (mediaOrgLink.endsWith('jpg') || mediaOrgLink.endsWith('png') || mediaOrgLink.endsWith('jpeg')) {
        return mediaOrgLink;
      }
      return undefined;
    }).then(mediaOrgLink => {
      this.openUploadWidget(mediaOrgLink);
    });
  }

  handleAddFromUrlDialog() {
    this.setState({
      webProductUrl: '',
      webProductImages: [],
      webProductImagesFetching: false,
      addFromUrlDialogOpen: true,
    });
  }

  handleConflictError(e, product, callback) {
    console.log(e);
    if (e.status !== 409) {
      return Promise.reject(e);
    }
    const msg = '이미 같은 링크가 등록된 상품이 있습니다. 등록을 계속 하시겠습니까?\n\n'
      + '확인을 누르면 중복을 무시하고 상품을 등록합니다.\n'
      + '취소를 누르면 해당 링크가 등록되어 있는 기존 상품을 새 탭으로 표시합니다.';
    if (confirm(msg)) {
      return callback({ product: { ...product, forceUpsert: true }});
    } else {
      window.open(`/admin/products/${e.duplicatedId}`, '_blank');
      this.setState({ ongoingRequest: false });
      return Promise.reject(e.message);
    }
  }

  handleSubmit(event) {
    const { product, orgLink, orgPriceNum, sellPriceNum, matchingPosts } = this.state;
    const { adminCreateProduct, adminUpdateProduct, productId, router } = this.props;
    if (!product.media || (product.media && product.media.length === 0)) {
      alert('상품 이미지가 등록되지 않았습니다');
      return;
    }
    if (!product.brand || !orgLink) {
      alert('브랜드명과 쇼핑몰링크는 필수로 입력해야 합니다.');
      return;
    }
    product.name = product.name || '';

    let retails = product.retails || [];
    let retail = retails[product.defaultRetailIdx];
    if (retail) {
      retail.orgLink = orgLink.replace(/%20/gi, '+');
      retail.orgPrice.amount = orgPriceNum || 0;
      retail.sellPrice.amount = sellPriceNum || 0;
    } else {
      retail = {
        seqId: 0,
        orgLink: orgLink.replace(/%20/gi, '+'),
        orgPrice: { amount: orgPriceNum || 0, currency: 'KRW' },
        sellPrice: { amount: sellPriceNum || 0, currency: 'KRW' },
      }
      retails.push(retail);
    }
    product.retails = retails;
    product.matchingPosts = matchingPosts;

    if (productId === 'new') {
      this.setState(
        { ongoingRequest: true },
        () => adminCreateProduct({ product })
          .catch((e) => this.handleConflictError(e, product, adminCreateProduct))
          .then(() => {
            this.showSnackbar('상품 추가에 성공했습니다.');
            router.push('/admin/products');
          })
          .catch((e) => {
            this.showSnackbar(`상품 추가에 실패했습니다: ${e}`)
            this.setState({ ongoingRequest: false });
          })
      );
    } else {
      this.setState(
        { ongoingRequest: true },
        () => adminUpdateProduct({ product })
          .then((updatedProduct) => this.setState({ product: updatedProduct, ongoingRequest: false }))
          .catch((e) => this.handleConflictError(e, product, adminUpdateProduct))
          .then(() => this.showSnackbar('상품 수정에 성공했습니다.'))
          .catch((e) => this.showSnackbar(`상품 수정에 실패했습니다: ${e}`))
          .finally(() => this.setState({ ongoingRequest: false }))
      );
    }
    event.preventDefault();
  }

  parseImagesFromUrl() {
    const { webProductUrl: url } = this.state;
    const { auth: { token } } = this.props;
    const parseEndpoint = '/api/v2/admin/posts/parse';
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    const method = 'POST';
    const data = JSON.stringify({ url });
    this.setState({ webProductImagesFetching: true }, () => fetch(parseEndpoint, { headers, method, body: data })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(webProductImages => {
        this.setState({ webProductImages, webProductImagesFetching: false });
      })
      .catch(error => alert(error)));
  }

  handleAddMatchingPosts() {
    const { checkedMatchingPosts } = this.state;
    const newMatchingPosts = { ...checkedMatchingPosts };
    this.setState({ newMatchingPosts, isMatchingPostDialogOpen: true });
  }

  handleAddMatchingPostsSubmit() {
    const { matchingPosts: prevMatchingPosts, checkedMatchingPosts, newMatchingPosts } = this.state;
    let matchingPosts = cloneDeep(prevMatchingPosts);
    const oldKeys = Object.keys(checkedMatchingPosts);
    const newKeys = Object.keys(newMatchingPosts);

    const deletedKeys = difference(oldKeys, newKeys);
    deletedKeys.forEach((key) => {
      delete matchingPosts[key];
      delete checkedMatchingPosts[key];
      matchingPosts = matchingPosts.filter(post => (post._id !== key));
    });

    newKeys.forEach((key) => {
      const newPost = cloneDeep(newMatchingPosts[key]);
      matchingPosts[key] = newPost;
      checkedMatchingPosts[key] = newPost;
      const postIdx = findIndex(matchingPosts, ['_id', key]);
      if (postIdx < 0) {
        matchingPosts.push(newPost);
      }
    });

    this.setState({
      matchingPosts,
      checkedMatchingPosts,
      newMatchingPosts: {},
      isMatchingPostDialogOpen: false,
    });
  }

  render() {
    const { classes, productId } = this.props;
    const { product, orgLink, orgPriceNum, sellPriceNum,
        matchingPosts, addFromUrlDialogOpen,
        webProductUrl, webProductImages, webProductImagesFetching, shopProduct } = this.state;

    const godoAddInfo = product.godoAddInfo ? JSON.parse(product.godoAddInfo) : [];
    let godoExchangeInfo = product.godoExchangeInfo ? product.godoExchangeInfo : '';
    godoExchangeInfo = godoExchangeInfo.replace(/<p>/gi, '');
    godoExchangeInfo = godoExchangeInfo.replace(/<\/p>/gi, '<br />');
    godoExchangeInfo = godoExchangeInfo.replace(/&nbsp;/gi, ' ');
    godoExchangeInfo = godoExchangeInfo.replace(/^\s+|\s+$/g, '');

    const fxRateObj = (product.crawled && product.crawlerInfo.fxrate && product.crawlerInfo.fxrate[0]) || undefined;
    const fxRate = (fxRateObj && fxRateObj.rate) || 'N/A';
    const fxCurrency = (fxRateObj && fxRateObj.currency) || 'N/A';

    return (
      <div className="container" style={{ marginTop: 20 }}>
        <Typography variant="h6">
          {product._id ? '상품 수정' : '상품 추가'}
        </Typography>
        <Paper className={classes.paper}>
          <Typography variant="subtitle1" className={classes.subMenuTitles}>
            상품 이미지
          </Typography>
          <div style={{ width: 468, height: 468, marginLeft: 126 }}>
            {
              product.media ? (
                <img src={product.media.secure_url || product.media.secureUrl || cloudinary.url(product.media.publicId)} style={{ width: 468, height: 468 }} />
              ) : (
                <div
                  className={classes.thumbnailArea}
                  style={{ width: 468, height: 468 }}
                >
                  상품 사진을 추가하면
                  <br />
                  이곳에 썸네일이 표시됩니다
                </div>
              )
            }
          </div>
          <Button className={classes.buttonAddProductImage} onClick={this.handleAddProductImage} variant="outlined">
            사진 직접
            {product.media ? '변경' : '추가'}
          </Button>
          <Button className={classes.buttonAddProductImage} onClick={this.handleAddFromUrlDialog} variant="outlined">
            상품 URL로 사진
            {product.media ? '변경' : '추가'}
          </Button>
        </Paper>
        {shopProduct && (
          <Paper>
            <Typography variant="subtitle1" className={classes.subMenuTitles}>
              상품 상세정보 이미지(고도몰)
            </Typography>
            <div>
              <List>
              {shopProduct.imagesDetail.map((image, idx) => (
                <ListItem style={{ dispaly: 'flex' }} key={`${image}-${idx}`}>
                  <img referrerPolicy="no-referrer" width="120" src={image} />
                  <span style={{ wordBreak: 'break-all', flex: 1, padding: '0 16px' }}>{image}</span>
                </ListItem>
              ))}
              </List>
            </div>
          </Paper>
        )}
        <Paper className={classes.paper}>
          <Typography variant="subtitle1" className={classes.subMenuTitles}>
            상품 기본 정보
          </Typography>
          <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', marginLeft: 10, marginRight: 10, marginBottom: 10 }}>
            <div style={{ flex: 1 }}>
              <b>상품 종류</b>: {product.godoGoodsNo ? '고도몰 상품' : '아웃링크 상품'}
            </div>
            <div style={{ flex: 1 }}>
              <b>고도몰 상품번호</b>:&nbsp;
              {product.godoGoodsNo ? (
                <span>{product.godoGoodsNo}&nbsp;
                  <a style={{ fontSize: 10 }} target="_blank" href={"http://gdadmin.heartitr9527.godomall.com/goods/goods_register.php?goodsNo=" + product.godoGoodsNo}>[고도몰 상품관리로 이동]</a>
                </span>
              ) : (
                '고도몰 상품 아님'
              )}
            </div>
          </div>
          <TextField
            className={classes.textField}
            type="text"
            label="해당 상품에 대한 쇼핑몰 링크를 입력하세요"
            placeholder="URL"
            onChange={event => {
              this.setState({ orgLink: event.target.value });
            }}
            value={orgLink || ''}
          />
          <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', paddingBottom: 15 }}>
            <TextField
              className={classes.textFieldHalf}
              type="text"
              label="영문 브랜드(하트잇 서비스에 표시되는 값, 필수)"
              placeholder="영문 브랜드(하트잇 서비스에 표시되는 값, 필수)"
              onChange={event => {
                product.brand = event.target.value;
                this.setState({ product });
              }}
              value={product.brand || ''}
            />
            <TextField
              className={classes.textFieldHalf}
              type="text"
              label="한글 브랜드(내부 관리용, 선택사항)"
              placeholder="한글 브랜드(내부 관리용, 선택사항)"
              onChange={event => {
                product.brandKr = event.target.value;
                this.setState({ product });
              }}
              value={product.brandKr || ''}
            />
            <TextField
              className={classes.textField}
              type="text"
              label="상품명을 입력하세요"
              placeholder="상품명"
              onChange={event => {
                product.name = event.target.value;
                this.setState({ product });
              }}
              value={product.name || ''}
            />
            <NumberFormat
              customInput={TextField}
              className={classes.textFieldHalf}
              type="text"
              label="상품의 판매가(할인가)"
              placeholder="판매가"
              thousandSeparator={true}
              onValueChange={values => {
                const value = values.value;
                this.setState({ sellPriceNum: value });
              }}
              value={sellPriceNum || ''}
            />
            <NumberFormat
              customInput={TextField}
              className={classes.textFieldHalf}
              type="text"
              label="상품의 정상가"
              placeholder="정상가 (할인 없을시 판매가 입력)"
              thousandSeparator={true}
              onValueChange={values => {
                const value = values.value;
                this.setState({ orgPriceNum: value });
              }}
              value={orgPriceNum || ''}
            />
          </div>
        </Paper>
        {product.crawled && (
          <Paper className={classes.paper}>
            <Typography variant="subtitle1" className={classes.subMenuTitles}>
              크롤링 상품 정보
            </Typography>
            <TextField
              className={classes.textField}
              type="text"
              label="원 쇼핑몰 상품주소"
              placeholder="URL"
              InputProps={{
                  readonly: true,
              }}
              value={product.crawlerInfo.productUrl || ''}
            />
            <TextField
              className={classes.textFieldHalf}
              type="text"
              label="원 쇼핑몰명"
              placeholder="원 쇼핑몰명"
              InputProps={{
                  readonly: true,
              }}
              value={product.crawlerInfo.parser || ''}
            />
            <TextField
              className={classes.textFieldHalf}
              type="text"
              label="크롤링 일시"
              placeholder="크롤링 일시"
              InputProps={{
                  readonly: true,
              }}
              value={getTimestampStr(parseDate(product.crawlerInfo.crawledAt, true)) || ''}
            />
            <TextField
              className={classes.textFieldHalf}
              type="text"
              label="적용환율"
              placeholder="적용환율"
              InputProps={{
                  readonly: true,
              }}
              value={fxRate}
            />
            <TextField
              className={classes.textFieldHalf}
              type="text"
              label="환율국가"
              placeholder="환율국가"
              InputProps={{
                  readonly: true,
              }}
              value={fxCurrency}
            />
            <Typography variant="subtitle1" className={classes.subMenuTitles}>
              현재가격 및 재고정보
            </Typography>
            { product.crawlerCurrentInfo ? (
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', paddingBottom: 15, marginLeft: 10, marginRight: 10 }}>
                <div style={{ flex: 1, flexDirection: 'column', marginTop: 5, marginBottom: 5 }}>
                  <div style={{ display: 'inline-flex', width: 100, fontWeight: 'bold' }}>현재 소비자가</div>
                  <div style={{ display: 'inline-flex', flexGrow: 1 }}>{product.crawlerCurrentInfo.price_retail}</div>
                </div>
                <div style={{ flex: 1, flexDirection: 'column', marginTop: 5, marginBottom: 5 }}>
                  <div style={{ display: 'inline-flex', width: 100, fontWeight: 'bold' }}>현재 판매가</div>
                  <div style={{ display: 'inline-flex', flexGrow: 1 }}>{product.crawlerCurrentInfo.price_sale}</div>
                </div>
                <div style={{ flex: 1, flexDirection: 'column', marginTop: 5, marginBottom: 5 }}>
                  <div style={{ display: 'inline-flex', width: 100, fontWeight: 'bold' }}>품절여부</div>
                  <div style={{ display: 'inline-flex', flexGrow: 1 }}>{product.crawlerCurrentInfo.is_soldout}</div>
                </div>
              </div>
            ) : (
              <div style={{ width: '100%', textAlign: 'center', paddingBottom: 15 }}>
                현재가격 및 재고정보를 조회하는데 실패했습니다.
              </div>
            )}
          </Paper>
        )}
        <Paper className={classes.paper}>
          <Typography variant="subtitle1" className={classes.subMenuTitles}>
            상품 상태
          </Typography>
          <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', marginLeft: 10, marginRight: 10, marginBottom: 10 }}>
            <FormControlLabel
              className={classes.textFieldHalf}
              control={(
                <Checkbox
                  checked={(product.soldOut && product.soldOut === 'y')|| false}
                  onChange={(event, checked) => {
                    product.soldOut = checked ? 'y' : 'n';
                    this.setState({ product });
                  }}
                />
              )}
              label="품절"
            />
            <FormControlLabel
              className={classes.textFieldHalf}
              control={(
                <Checkbox
                  checked={(product.preOrder && product.preOrder === 'y') || false}
                  onChange={(event, checked) => {
                    product.preOrder = checked ? 'y' : 'n';
                    this.setState({ product });
                  }}
                />
              )}
              label="프리오더 상품"
            />
          </div>
        </Paper>
        {product.godoGoodsNo ? (
          <Fragment>
            <Paper className={classes.paper} style={{ paddingBottom: 15 }}>
              <Typography variant="subtitle1" className={classes.subMenuTitles}>
              EDITORS NOTE <small>(SHOP 탭 상품상세)</small>
              </Typography>
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                <TextField
                  className={classes.textField}
                  type="text"
                  label="EDITOR'S NOTE"
                  placeholder="SHOP 탭 상품상세 화면의 'EDITOR'S NOTE'에 표시될 텍스트"
                  onChange={event => {
                    product.godoEditorsNote = event.target.value;
                    this.setState({ product });
                  }}
                  multiline={true}
                  rows={5}
                  value={product.godoEditorsNote || ''}
                />
              </div>
            </Paper>
            <Paper className={classes.paper} style={{ paddingBottom: 15 }}>
              <Typography variant="subtitle1" className={classes.subMenuTitles}>
              DETAILS <small>(SHOP 탭 상품상세)</small>
              </Typography>
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', marginLeft: 10, marginRight: 10 }}>
                <div style={{ flex: 1, marginTop: -5 }}>
                  {godoAddInfo.length > 0 ?
                    godoAddInfo.map(data => {
                      return (
                        <div key={`godoAddInfo-${data.sno}`} style={{ flex: 1, flexDirection: 'column', marginTop: 5, marginBottom: 5 }}>
                          <div style={{ display: 'inline-flex', width: 100, fontWeight: 'bold' }}>{data.infoTitle}</div>
                          <div style={{ display: 'inline-flex', flexGrow: 1 }}>{data.infoValue}</div>
                        </div>
                      );
                    }
                  ) : (
                    <div style={{ flex: 1, flexDirection: 'column', marginTop: 5, marginBottom: 5 }}>
                      해당 상품에 대하여 고도몰에 등록된 정보가 없습니다.
                    </div>
                  )}
                </div>
              </div>
              <div style={{ flex: 1, marginLeft: 10, marginRight: 10, marginTop: 10, fontSize: '0.85em' }}>
                'DETAILS' 정보는&nbsp;
                <a
                  target="_blank"
                  style={{ fontSize: '1.0em' }}
                  href={"http://gdadmin.heartitr9527.godomall.com/goods/goods_register.php?goodsNo=" + product.godoGoodsNo}
                >
                  고도몰 상품관리 페이지
                </a>
                의 '추가 정보' > '추가 항목'에서 설정할 수 있습니다.
              </div>
            </Paper>
            <Paper className={classes.paper} style={{ paddingBottom: 15 }}>
              <Typography variant="subtitle1" className={classes.subMenuTitles}>
              SHIPPING & RETURNS <small>(SHOP 탭 상품상세)</small>
              </Typography>
              {godoExchangeInfo ? (
                <div
                  style={{ flex: 1, flexDirection: 'column', marginLeft: 10, marginRight: 10, marginTop: 5, marginBottom: 5 }}
                  dangerouslySetInnerHTML={{ __html: godoExchangeInfo}}
                >
                </div>
              ) : (
                <div style={{ flex: 1, flexDirection: 'column', marginLeft: 10, marginRight: 10, marginTop: 5, marginBottom: 5 }}>
                  해당 상품에 대하여 고도몰에 등록된 정보가 없습니다.
                </div>
              )}
              <div style={{ flex: 1, marginLeft: 10, marginRight: 10, marginTop: 10, fontSize: '0.85em' }}>
                'SHIPPING & RETURNS' 정보는&nbsp;
                <a
                  target="_blank"
                  style={{ fontSize: '1.0em' }}
                  href={"http://gdadmin.heartitr9527.godomall.com/goods/goods_register.php?goodsNo=" + product.godoGoodsNo}
                >
                  고도몰 상품관리 페이지
                </a>
                의 '이용 안내' > '교환안내 선택'에서 설정할 수 있습니다.
              </div>
            </Paper>
            <Paper className={classes.paper} style={{ paddingBottom: 15 }}>
              <Typography variant="subtitle1" className={classes.subMenuTitles}>
              이 상품을 착용한 하티스트
              </Typography>
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                <GridList cols={5} cellHeight={200}>
                  {matchingPosts.map((post, idx) => {
                    return (
                      <GridListTile
                        key={`matchingpostselector_${post._id}_${idx}`}
                        className={classes.dialogueGridListTile}
                      >
                        {MatchingPostSelector.renderPostDate(post)}
                        <Media
                          media={post.media[0]}
                          options={{ width: 200, height: 200, crop: 'limit' }}
                          style={{ paddingTop: '100%' }} />
                      </GridListTile>
                    );
                  })}
                </GridList>
                <Button
                  className={classes.outerButton}
                  onClick={this.handleAddMatchingPosts}
                  variant="contained"
                >
                  포스트 추가/수정
                </Button>
              </div>
            </Paper>
          </Fragment>
        ) : (
          null
        )}
        <div style={{ padding: 5 }}></div>
        <Paper className={classes.paper}>
          <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
            <Button
              className={classes.confirmButton}
              onClick={this.handleSubmit}
              variant="contained"
              disabled={this.state.ongoingRequest}
            >
              상품
              {product._id ? '변경' : '추가'}
            </Button>
          </div>
        </Paper>

        <Dialog
          classes={{ paper: classes.dialogPaper }}
          open={this.state.addFromUrlDialogOpen}
          maxWidth="md"
        >
          <DialogTitle>쇼핑몰에서 상품 이미지 추출하기</DialogTitle>
          <TextField
            className={classes.textField}
            type="text"
            label="url"
            onChange={event => this.setState({ webProductUrl: event.target.value })}
            value={webProductUrl}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                this.parseImagesFromUrl();
              }
            }}
          />
          <Button
            variant="contained"
            className={classes.confirmButton}
            onClick={this.parseImagesFromUrl}
            disabled={!webProductUrl.match(/https?:\/\/.+/)}
          >
            Parse
          </Button>
          <GridList cols={this.state.webProductTilesInRow} cellHeight={240}>
            {webProductImagesFetching ? <Spinner /> : webProductImages.map((imageUrl, idx) => (
              <GridListTile
                key={`productpage_webProductDialog_${imageUrl}_${idx}`}
                className={classes.dialogueGridListTile}
                onClick={() => {
                  this.setState(prevState => ({
                    newProduct: assign({}, prevState.newProduct, {
                      orgLink: prevState.webProductUrl,
                    }),
                    addFromUrlDialogOpen: false,
                  }), () => {
                    textToClipboard(imageUrl);
                    alert('선택한 이미지 주소가 클립보드로 복사되었습니다.\n다음 화면에서 붙여넣기하여 업로드하세요.');
                    this.handleAddProductImage(imageUrl);
                  });
                }}
              >
                <img
                  src={imageUrl}
                  style={{ cursor: 'pointer' }}
                  alt="Clipboard Img"
                />
              </GridListTile>
            ))}
          </GridList>
          <DialogActions>
            {
              product._id && (
                <Button
                  className={classes.cancelConfirmButton}
                  onClick={this.handleConfirmWebItemDialog}
                >
                  Confirm
                </Button>
              )
            }
            <Button
              className={classes.cancelConfirmButton}
              onClick={() => this.setState({ addFromUrlDialogOpen: false })}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.isMatchingPostDialogOpen} fullWidth maxWidth={'lg'} classes={{ paper: classes.dialogPaperMatchingPost }}>
          <DialogTitle>
            '이 상품을 착용한 하티스트' 추가/수정<br />
          </DialogTitle>
          <DialogContent>
            <span style={{ fontWeight: 400, lineHeight: 1, color: '#777' }}>
              * 아무 포스트도 선택하지 않으면 기존 방식(동일 상품 최신순으로 4개까지 표시)으로 앱/웹에 표시됩니다.<br/>
              * 동일 상품이 포함된 포스트가 목록에 표시되며, '+' 버튼으로 추가, '-' 버튼으로 제거할 수 있습니다.<br/>
              * 연필모양 버튼을 누르면 해당 포스트에 대한 수정 화면이 새 창으로 표시되어 자세한 정보를 확인할 수 있습니다.<br/>
            </span>
            <MatchingPostSelector
              productId={productId}
              matchingPosts={this.state.newMatchingPosts}
              onCheckMatchingPost={post => {
                const { newMatchingPosts } = this.state;
                if (!has(newMatchingPosts, post._id)) {
                  newMatchingPosts[post._id] = post;
                } else {
                  delete newMatchingPosts[post._id];
                }
                this.setState({ newMatchingPosts });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.cancelConfirmButton}
              onClick={this.handleAddMatchingPostsSubmit}
            >
              Confirm
            </Button>
            <Button
              className={classes.cancelConfirmButton}
              onClick={() => {
                this.setState({ newMatchingPosts: {}, isMatchingPostDialogOpen: false });
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {<Snackbar
          autoHideDuration={2000}
          className={classes.snackbar}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
        />}
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect((state, ownProps) => ({
    auth: state.auth,
    productId: ownProps.params.productId,
  }), 
  { ...adminActions, ...actions },
),
)(ProductPage);
