import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import Media from './Media';
import InstagramProfile from './InstagramProfile';

const styles = {
  container: {
    border: '5px solid #fff',
    marginTop: '65px',
    marginBottom: '20px',
    textAlign: 'center',
    paddingLeft: 0,
    paddingRight: 0,
    width: '50%',
    display: 'inline-block',
  },
  header: {
    display: 'inline-block',
    marginBottom: '15px',
    textAlign: 'center',
    width: '100%',
  },
  profileImage: {
    borderRadius: '50%',
    height: '35px',
    marginBottom: '10px',
    width: '35px',
  },
  username: {
    color: 'black',
    fontSize: '16.5px',
    textAlign: 'center',
  },
};

export default class Heartist extends Component {
  render() {
    const { heartist: { _id, username, lastPost: { media: { 0: latestMedia } } } } = this.props;
    return (
      <Link key={_id} to={`/${username}`}>
        <div style={styles.container}>
          <div style={styles.header}>
            <InstagramProfile style={styles.profileImage} instagramId={username} />
            <br />
            <span style={styles.username}>{username}</span>
          </div>
          <Media media={latestMedia} options={{ format: 'jpg' }} />
        </div>
      </Link>
    );
  }
}

Heartist.propTypes = {
  heartist: PropTypes.object.isRequired,
};
