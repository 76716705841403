import React from 'react';
import {
  Dimensions, ActivityIndicator,
  FlatList, StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import queryString from 'query-string';
import intersection from 'lodash/intersection';

import { actions, loadEntities } from 'shared';

import MyOrdersItem from '../../../../components-v2/MyOrdersItem';
import { Constants, Colors } from '../../../../constants';
import Header from './Header';
import Blank from './Blank';

import _styles from '../../../../css/modules/order.module.css';

const { width: windowWidth, height: windowHeight } = Dimensions.get('window');
const MAX_LOOKUP_PREV_YEAR = 5;
const MAX_COUNT_PER_PAGE = 10;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  separator: {
    height: 0.5,
    backgroundColor: Colors.lineGray,
    marginHorizontal: Constants.viewHorizontalPadding,
    marginTop: 20,
  },
  itemContainer: {
    marginHorizontal: Constants.viewHorizontalPadding,
    borderTopWidth: 0.5,
    borderTopColor: Colors.lineGray,
    marginTop: 20,
    paddingTop: 12.5,
    marginBottom: 20,
  },
});


function getTimeStr(timestamp) {
  const datetime = new Date(timestamp);
  const year = datetime.getFullYear();
  const month = datetime.getMonth() + 1;
  const date = datetime.getDate();

  return year + '-' + month + '-' + date;
}

export class MyOrdersPage extends React.Component {
  state = {
    refreshing: false,
    isFetchingNextPage: false,
    // 고도몰에서 주문을 1년 단위로만 끊어서 조회할 수 있음
    // 주문을 0-1년전, 1-2년전과 같이 끊어 겁색하기 위한 변수
    lookupPrevYear: 0,
    page: 1,
  };

  componentDidMount() {
    const { logEventPageview } = this.props;

    this.loadData(false);

    logEventPageview({ page_name: 'MyOrders' });
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps) {
    const { isGodoLoggedIn, pagination } = this.props;

    if (prevProps.pagination.isFetching && !pagination.isFetching) {
      this.setState({ isFetchingNextPage: false, refreshing: false });
    }

    if (!prevProps.isGodoLoggedIn && isGodoLoggedIn) {
      this.loadData(false);
    }
  }

  loadData = nextPage => {
    const { getUserSummary, getUserOrderList } = this.props;
    const { page, lookupPrevYear } = this.state;
    getUserSummary();

    const currentTimestamp = Date.now();
    const oneYearMs = (1000 * 86400 * 365);
    // NOTE: 조회 기간 기본 1년으로 지정
    const query = {
      startDate: getTimeStr(currentTimestamp - oneYearMs * (lookupPrevYear + 1)),
      endDate: getTimeStr(currentTimestamp - oneYearMs * (lookupPrevYear)),
      count: MAX_COUNT_PER_PAGE,
    };
    getUserOrderList(nextPage, page, query)
      .then((result) => {
        const data = result.data;
        const nowPage = parseInt(data.page.now, 10);
        const endPage = parseInt(data.page.end, 10);
        if (nowPage >= endPage) {
          const newLookupPrevYear = this.state.lookupPrevYear + 1;
          const newPage = 1;
          this.setState({
            lookupPrevYear: newLookupPrevYear,
            page: newPage,
          }, () => {
            if (newLookupPrevYear >= MAX_LOOKUP_PREV_YEAR) {
              return;
            }
            this.loadData(true, newPage);
          });
        } else {
          this.setState({ page: page + 1 });
        }
      });
  }

  cancelUserOrder = orderNo => {
    const { cancelUserOrder, getOrderDetail } = this.props;

    this.setState({ isFetchingNextPage: true }, async () => {
      await cancelUserOrder(orderNo);
      getOrderDetail(orderNo);
    });
  }

  renderFooter = () => {
    const { pagination, userOrderList } = this.props;
    if (pagination.isFetching) {
      return (
        <View
          style={{
            paddingVertical: 20,
            marginTop: 0,
            marginBottom: 40,
          }}
        >
          <ActivityIndicator animating size="small" color="gray" />
        </View>
      );
    }

    if (pagination.nextPageUrl === null || !userOrderList || userOrderList.length === 0) {
      return (
        <View style={{ marginBottom: 20 }} />
      );
    }

    return (
      <View
        style={{
          position: 'relative',
          width: windowWidth,
          height: windowHeight / 5,
          paddingVertical: 20,
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <ActivityIndicator animating size="small" color="gray" />
      </View>
    );
  }

  getOrderStatus(order) {
    const { goods } = order;
    return {
      orderStatus: goods.map(g => g.orderStatus),
    };
  }

  renderContent() {
    const {
      userOrderList: allOrderList, router, pagination, auth, historySwitch, step,
      showSmartBanner,
      ...props
    } = this.props;
    const { refreshing } = this.state;

    let userOrderList = allOrderList;
    if (step != "all") {
      userOrderList = allOrderList.filter((ord) => {
        const { orderStatus } = this.getOrderStatus(ord);
        let checkOrderStatus = [];
        switch(step) {
          case "orderConfirm":
            checkOrderStatus = ["p1"];
            break;
          case "shippingPending":
            checkOrderStatus = ["g1"];
            break;
          case "shipping":
            checkOrderStatus = ["d1"];
            break;
          case "delivered":
            checkOrderStatus = ["d2", "s1"];
            break;
          case "cancel":
            checkOrderStatus = ["b1", "b2", "b3", "b4", "e1", "e2", "e3", "e4", "e5", "r1", "r2", "r3"];
            break;
        }
        return (intersection(orderStatus, checkOrderStatus).length > 0);
      });
    }

    if (!pagination.isFetching && userOrderList.length === 0) {
      return (<Blank router={router} />);
    }

    const renderItem = ({ item }) => (
      <MyOrdersItem
        order={item}
        router={router}
        auth={auth}
        historySwitch={historySwitch}
        cancelUserOrder={this.cancelUserOrder}
        containerStyle={styles.itemContainer}
      />
    );

    const listStyles = {
      maxHeight: windowHeight
        - (showSmartBanner && Constants.smartBannerHeight)
        - Constants.mainHeaderHeight - 64,
    };

    return (
      <FlatList
        {...props}
        style={listStyles}
        showsVerticalScrollIndicator={false}
        renderItem={renderItem}
        data={userOrderList}
        keyExtractor={item => item.orderNo}
        onEndReached={() => {
          if (refreshing || pagination.isFetching || pagination.nextPageUrl === null) {
            return;
          }
          this.setState({ isFetchingNextPage: true }, () => {
            this.loadData(true);
          });
        }}
        onEndReachedThreshold={1.0}
        ListFooterComponent={this.renderFooter}
        onRefresh={() => {
          if (!refreshing) {
            this.setState({ refreshing: true }, () => {
              this.loadData(false);
            });
          }
        }}
        refreshing={refreshing}
      />
    );
  }

  render() {
    const {
      router, auth,
    } = this.props;

    return (
      <div className={_styles.order_container}>
        <View style={styles.container}>
          <Header router={router} auth={auth} />
          {this.renderContent()}
        </View>
      </div>
    )
  }
}


export default connect(
  (state, props) => {
    const parsed = queryString.parse(props.location.search.substring(1));
    const { step='all' } = parsed;

    return {
      auth: state.auth,
      step,
      // eslint-disable-next-line no-prototype-builtins
      isGodoLoggedIn: state.entities.shop.hasOwnProperty('sessionId'),
      ...loadEntities(state, 'userOrderList', 'userOrderList'),
    }
  },
  actions,
)(MyOrdersPage);
