import React from 'react';
import { isEqual } from 'lodash';
import { Dimensions, RefreshControl, StyleSheet, View, ScrollView, Text } from 'react-native';
import { connect } from 'react-redux';
import { actions, loadEntities } from 'shared';

import { Styles, Constants, Colors } from '../../../constants';
import {
  ProductFilterControl, Products,
} from '../../../components-v2';

import ShopHeader from './ShopHeader';
import CategoryList from './CategoryList';
import CategoryDropdown from './CategoryDropdown';

import { navigateToProductPage } from '../ProductPage';


const stylesHtml = {
  productImage: {
    width: '100%',
    aspectRatio: 166/221,
    borderWidth: 1,
    borderColor: Colors.productImageBorder,
    overflow: 'hidden',
  },
};

const {
  height: windowHeight,
} = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
  },
  categoryDropdown: {
    position: 'absolute',
    start: 0,
    bottom: 0,
    end: 0,
  },
  productFilterControl: {
    paddingTop: 10,
    paddingBottom: 20,
  },
  productListContainer: {
    ...Styles.fullWidth,
  },
  productFlatListContainer: {
    marginHorizontal: 20,
  },
  itemContainerStyle: {
    flex: 1,
    marginHorizontal: 3 / 2,
  },
  columnWrapperStyle: {
    marginHorizontal: -3 / 2,
    ...Styles.fullWidth,
  },
  productTextContainer: {
    marginTop: 6,
  },

  deliveryContainer: {
    backgroundColor: '#000',
    paddingTop: 11,
    height: 43,
  },
  deliveryTextContainer: {
    marginLeft: 18,
    marginRight: 18,
    flexDirection:'row'
  },
  deliveryTitleText: {
    color: '#fff',
    width: 160,
    marginEnd:12,
    ...Styles.pretendard400,
  },
  deliveryText: {
    color: '#fff',
    display:'inline-block',
    marginBottom:1,
    lineHeight:19.2,
    ...Styles.pretendard400,
  },
  deliveryBoldText: {
    color: '#fff',
    ...Styles.pretendard700,
  },
  deliveryBoldTextRed: {
    color: '#E94949',
    ...Styles.pretendard700,
  }
});

const sessionStorageKey = "heartitShopState";

class ShopPage extends React.Component {
  state = {
    headerVisible: false,
    showCategoryDropdown: false,
    brandList: [],
    filterList: [],
    selectedSubCategoryList: [],
    sortingRule: { id: '1002', value: 'popularity', name: '인기순' },
    headerParams: {
      showHeaderDropdownButton: true,
    },
    windowHeight: windowHeight,
    isFetchingNextPage: false,
    refreshing: false,
    isMarketing : false,
  };
  scrollPositionY = 0;

  scrollViewRef = null;

  constructor(props) {
    super(props);

    global.shopPageRef = this;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    try {
      const storedState = JSON.parse(window.sessionStorage.getItem(sessionStorageKey));
      window.sessionStorage.removeItem(sessionStorageKey)

      return { ...prevState, ...storedState};
    } catch (e) {
      console.debug(e);
    }

    return null;
  }

  renderHeaderLeft = (props) => {
    return (
      <ShopHeader
        {...props}
        />
    );
  }

  handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
    const stRaw = window.pageYOffset || document.documentElement.scrollTop;
    const y = Math.min(
      stRaw,
      scrollHeight,
    );

    // console.log(`handleScroll y=${y}`);
    this.scrollPositionY = y;

    this.updateHeaderStyle();
  }

  isCloseToBottom({ layoutMeasurement, contentOffset, contentSize }) {
    const paddingToBottom = 100;
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    );
  }

  onScroll({ nativeEvent }, pagination) {
    const {
      contentOffset: {y}
    } = nativeEvent;
    const {
      isFetchingNextPage,
      refreshing,
    } = this.state;

    this.scrollPositionY = y;
    this.updateHeaderStyle();

    if (this.isCloseToBottom(nativeEvent)) {
      if (!pagination) {
        return;
      }
      if (isFetchingNextPage || refreshing || pagination.isFetching || pagination.nextPageUrl === null) {
        return;
      }
      this.loadData(true);
    }
  }

  toggleHeaderCategoryDropdown = () => {
    const { showCategoryDropdown } = this.state;

    this.setState({ showCategoryDropdown: !showCategoryDropdown });

    const { setHeaderOptions, headerOptions: { headerLeftProps } } = this.props;

    setHeaderOptions({
      headerLeftProps: {
        ...headerLeftProps,
        showCategoryDropdown: !showCategoryDropdown,
      }
    })
  };

  onPressHeaderBackButton = () => {

    const {
      categories,
    } = this.props;

    const newState = {};

    newState.selectedCategoryItem = categories[0];
    newState.selectedSubCategoryList = [];

    this.setState(newState, () => {
      this.getProducts();
      this.updateHeaderStyle();
    });
  };

  onSelectCategory = (item, subCategoryItem=null) => {
    const newState = {};

    newState.selectedCategoryItem = item;

    if (subCategoryItem === null || subCategoryItem.cateNm === "전체") {
      newState.selectedSubCategoryList = [];
    } else {
      /*
      let { selectedSubCategoryList } = this.state;
      if (selectedSubCategoryList.some(e => e.cateCd == subCategoryItem.cateCd)) {
        selectedSubCategoryList = selectedSubCategoryList.filter(e => e.cateCd != subCategoryItem.cateCd);
      } else {
        selectedSubCategoryList.push(subCategoryItem);
      }
      newState.selectedSubCategoryList = selectedSubCategoryList;
      */
      newState.selectedSubCategoryList = [subCategoryItem];
    }
    if(item.cateCd === "018"){
      newState.isMarketing = true;
    }

    this.setState(newState, () => {
      this.getProducts();
      this.updateHeaderStyle();
    });


  }

  updateDimensions = () => {
    this.setState({
      windowHeight: window.innerHeight,
    });
  };

  componentDidMount() {
    const {
      router,
      getProductCategoryList,
      logEventPageview,
      getPartnerShippingProducts,
      shopBrandList,
      loadAndSaveShopBrandList,
    } = this.props;

    if (router.location.state && router.location.state.sortingRule) {
      this.setState({ sortingRule: router.location.state.sortingRule });
    }

    this.updateDimensions();
    loadAndSaveShopBrandList();

    const brandList = shopBrandList.map(d => ({
      id: d.cateCd,
      type: 'brand',
      name: d.cateNm,
    }));
    this.setState({ brandList });
    this.applyFilter();

    getProductCategoryList();
    getPartnerShippingProducts();

    const { setHeaderOptions, headerOptions: { headerLeftProps={} } } = this.props;
    setHeaderOptions({
      HeaderLeftComponent: this.renderHeaderLeft,
      headerLeftProps: {
        ...headerLeftProps,
        onPressHeaderDropdownButton: this.toggleHeaderCategoryDropdown,
        onPressHeaderBackButton: this.onPressHeaderBackButton,
      }
    });

    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.updateDimensions);

    logEventPageview({ page_name: 'Shop' });
  }

  applyFilter = () => {
    const filterList = this.getFilterList();
    const { selectedCategoryItem, selectedSubCategoryList } = this.getSelectedCategoryItemFromCateCd();
    const newState = {
    };
    if (filterList === null) {
      newState['filterList'] = [];
    } else if (filterList.length > 0) {
      newState['filterList'] = filterList;
    }

    if (selectedCategoryItem) {
      newState['selectedCategoryItem'] = selectedCategoryItem;
      newState['selectedSubCategoryList'] = selectedSubCategoryList;
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState, () => {
        this.updateHeaderStyle();
        this.getProducts();
      });
    }
  }

  getFilterList = () => {
    const { router } = this.props;

    let filterList = [];
    if (router.location.state && router.location.state.resetFilter) {
      return null;
    }

    if (router.location.state && router.location.state.brandCd) {
      const brandCd = router.location.state.brandCd;
      if (typeof brandCd == "object" && Array.isArray(brandCd)) {
        filterList = brandCd;
      } else {
        filterList = [brandCd];
      }
    }

    if (router.location.state && router.location.state.priceFilter) {
      const priceFilter = router.location.state.priceFilter;
      const price = {
        type: 'price',
      }
      const priceSplit = priceFilter.name.split('~');
      if (priceSplit.length == 2) {
        const min = priceSplit[0].trim();
        const max = priceSplit[1].trim();
        if (min.length > 0) {
          price['min'] = Number.parseInt(min, 10);
        } else {
          price['min'] = 0;
        }
        if (max.length > 0) {
          price['max'] = Number.parseInt(max, 10);
        } else {
          price['max'] = 999999999;
        }
        filterList.push(price);
      }
    }

    if (router.location.state && router.location.state.keywordFilter) {
      const keywordFilter = router.location.state.keywordFilter;
      keywordFilter.forEach(k => filterList.push({id: k.name, type: 'keyword', name: k.name}));
    }

    if (router.location.state && router.location.state.crawlerFilter) {
      const crawlerFilter = router.location.state.crawlerFilter;
      filterList.push({id: crawlerFilter.name, type: 'crawler', name: crawlerFilter.name});
    }

    if (router.location.state && router.location.state.productsFilter) {
      const productsFilter = router.location.state.productsFilter;
      filterList.push({id: 'products', type: 'products', name: '카테고리 세일 제외', data: productsFilter});
    }

    return filterList;
  }

  componentDidUpdate(prevProps, prevState) {

    const { categories: prevCategories = [] } = prevProps;
    const { categories = [] } = this.props;
    const { selectedCategoryItem: prevSelectedCategoryItem = prevCategories[0] } = prevState;
    const { selectedCategoryItem = categories[0] } = this.state;

    // console.log(`prevSelectedCategoryItem=${JSON.stringify(prevSelectedCategoryItem)}`);
    // console.log(`selectedCategoryItem=${JSON.stringify(selectedCategoryItem)}`);

    if (prevSelectedCategoryItem !== selectedCategoryItem) {
      this.getProducts();
    }

    const { shopBrandList } = this.props;
    const { shopBrandList: prevShopBrandList } = prevProps;
    if (!isEqual(shopBrandList, prevShopBrandList)) {
      this.setState({
        brandList: shopBrandList.map(b => ({ id: b.cateCd, type: 'brand', name: b.cateNm }))
      });
    }
  }

  componentWillUnmount() {

    const { setHeaderOptions } = this.props;
    setHeaderOptions({
      HeaderLeftComponent: null,
    });

    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.updateDimensions);

    window.sessionStorage.setItem(sessionStorageKey, JSON.stringify(this.state));
  }

  getSelectedCategoryItem() {
    const { categories } = this.props;
    const {
      selectedCategoryItem=categories[0],
    } = this.state;

    return selectedCategoryItem;
  }

  getSelectedCategoryItemFromCateCd = () => {
    const { router, categories } = this.props;
    if (router.location.state && router.location.state.cateCd) {
      let cateCd = router.location.state.cateCd;
      if (cateCd && cateCd.trim().length > 0) {
        cateCd = cateCd.trim();
        const topCategory = categories.find(c => c.cateCd == cateCd.substring(0, 3));
        if (topCategory) {
          let subCategory = [];
          if (cateCd.length > 3) {
            subCategory = topCategory.children.filter(c => c.cateCd == cateCd);
          }
          return {
            selectedCategoryItem: topCategory,
            selectedSubCategoryList: subCategory
          };
        }
      }
    }

    return {
      selectedCategoryItem: null,
      selectedSubCategoryList: []
    };
  }

  updateHeaderStyle() {
    const { showCategoryDropdown } = this.state;
    const selectedCategoryItem = this.getSelectedCategoryItem();

    const params = {
      showHeaderDropdownButton: false,
      showCategoryDropdown,
      headerTitle: '전체상품',
      showHeaderBackButton: false,
    };

    if (this.scrollPositionY > 135) {
      params.showHeaderDropdownButton = true;
    }

    if (selectedCategoryItem && selectedCategoryItem.cateNm !== "전체") {
      params.headerTitle = selectedCategoryItem.cateNm;

      if (selectedCategoryItem.children) {
        params.showHeaderDropdownButton = true;
        params.showHeaderBackButton = true;
      }
    }

    const { setHeaderOptions, headerOptions: { headerLeftProps } } = this.props;
    setHeaderOptions({
      headerLeftProps: {
        ...headerLeftProps,
        ...params,
      }
    })
  }

  getCurrentCategoryId = () => {
    const selectedCategoryItem = this.getSelectedCategoryItem();
    const { selectedSubCategoryList=[] } = this.state;

    let categoryId = selectedCategoryItem ? selectedCategoryItem.cateCd : '';
    if (selectedSubCategoryList.length > 0) {
      // FIXME:향후 다중 카테고리 선택으로 변경시 수정 필요함
      categoryId = selectedSubCategoryList[0].cateCd;
    }
    return categoryId;
  }

  convertFilterToQuery() {
    const { filterList, sortingRule } = this.state;
    const categoryId = this.getCurrentCategoryId();

    // eslint-disable-next-line no-unused-vars
    if (filterList.length > 0) {
      const query = {
        'cateGoods[]': categoryId,
        count: 12,
        sortBy: sortingRule.value,
      };
      const brandList = [];
      const colorList = [];
      const keywordList = [];
      let crawler = null;
      filterList.forEach(f => {
        if (f.type === 'brand') {
          brandList.push(f.id);
        }
        if (f.type === 'size') {
          // FIXME: apply size
        }
        if (f.type === 'color') {
          colorList.push(f.code);
        }
        if (f.type === 'keyword') {
          keywordList.push(f.name);
        }
        if (f.type === 'price') {
          query['priceList'] = [f.min, f.max].join(',');
        }
        if (f.type === 'crawler') {
          crawler = f.name;
        }
      });
      if (brandList.length > 0) {
        query['brandList'] = brandList.join(',');
      }
      if (colorList.length > 0) {
        query['colorList'] = colorList.join(',');
      }
      if (keywordList.length > 0) {
        query['key'] = 'all';
        query['keyword'] = keywordList.join(' ');
        query['keywordCombine'] = 'or';
      }
      if (crawler && keywordList.length > 0) {
        query['reSearchKeyList'] = 'memo';
        query['reSearchKeywordList'] = crawler;
      } else if (crawler && keywordList.length <= 0) {
        query['key'] = 'memo';
        query['keyword'] = crawler;
      }

      return query;
    } else {
      return null;
    }
  }

  getProducts(nextPage=false) {
    const {
      getProductListByCategory, getProductListByCategoryWithFilter,
      loadShopProduct,
    } = this.props;
    const { filterList, sortingRule } = this.state;
    const categoryId = this.getCurrentCategoryId();

    // eslint-disable-next-line no-unused-vars
    if (filterList.length > 0) {
      const query = this.convertFilterToQuery();
      getProductListByCategoryWithFilter(categoryId, query, nextPage)
        .catch((error) => console.error('ShopScreen getProductListByCategoryWithFilter error=', error))
        .finally(() => this.setState({ isFetchingNextPage: false, refreshing: false }));
    } else {
      const query = {
        cateCd: categoryId,
        count: 12,
        sortBy: sortingRule.value,
      };
      getProductListByCategory(categoryId, query, nextPage)
        .catch((error) => console.error('ShopScreen getProductListByCategory error=', error))
        .finally(() => this.setState({ isFetchingNextPage: false, refreshing: false }));
    }
  }

  loadData(nextPage) {
    this.setState({ isFetchingNextPage: nextPage }, () => {
      this.getProducts(nextPage);
    });
  }

  onChangeFilterList(newActiveFilterList) {
    this.setState({
      filterList: newActiveFilterList,
    }, () => {
      this.getProducts();
    });
  }

  onChangeSortingRule(newSortingRule) {
    // console.log(`onChangeSortingRule newSortingRule=${JSON.stringify(newSortingRule)}`);

    this.setState({
      sortingRule: newSortingRule,
    }, () => {
      this.getProducts();
    });
  }

  scrollToTop() {
    this.scrollViewRef.scrollTo({ x: 0, y: 0 });
  }

  renderCategoryDropdown() {
    const { categories, showSmartBanner } = this.props;

    const {
      showCategoryDropdown,
      selectedSubCategoryList=[],
    } = this.state;

    const selectedCategoryItem = this.getSelectedCategoryItem();

    if (!showCategoryDropdown) {
      return null;
    }

    const onPressClose = () => {
      this.setState(
        { showCategoryDropdown: false},
        () => this.updateHeaderStyle()
      );
    };

    const categoryDropdownStyles = [
      styles.categoryDropdown,
      {
        top: Constants.mainHeaderHeight
          + (showSmartBanner && Constants.smartBannerHeight),
      }
    ];

    return (
      <CategoryDropdown
        containerStyle={categoryDropdownStyles}
        categories={categories}
        onSelected={this.onSelectCategory}
        onPressClose={onPressClose}
        selectedCategoryItem={selectedCategoryItem}
        selectedSubCategoryList={selectedSubCategoryList}
        />
    )
  }

  render() {
    const {
      router,
      categories,
      upperState,
      modalProps,
      setModalProps,
      shopBrandList,
    } = this.props;

    const {
      selectedSubCategoryList=[],
      brandList,
      filterList,
      sortingRule,
      windowHeight,
      isFetchingNextPage,
      refreshing,
      isMarketing
    } = this.state;

    const selectedCategoryItem = this.getSelectedCategoryItem();

    const onPressProduct = item => {
      const {
        goodsNo: productId,
      } = item;

      navigateToProductPage({
        productId,
        brandList: this.state.brandList,
        onChangeFilterList: (newActiveFilterList) => this.onChangeFilterList(newActiveFilterList),
      }, router);
    };

    // console.log(`categories=${JSON.stringify(categories)}`);
    if (!selectedCategoryItem) {
      return null;
    }

    const categoryId = this.getCurrentCategoryId();
    const query = this.convertFilterToQuery();
    const key = actions.getShopProductListByCategoryKey(categoryId, false, query);
    let { [key]: shopProducts, pagination } = loadEntities(upperState, key, key);

    const productFilter = filterList.filter(f => f.type === "products");
    if (productFilter.length > 0) {
      const exemptProducts = productFilter[0].data;
      shopProducts = shopProducts.filter(sp => exemptProducts.filter(ep => sp.goodsNo == ep.godoGoodsNo).length == 0)
    }

    // console.log(`shopProducts=${JSON.stringify(shopProducts)}`);

    const scrollViewHeight = windowHeight - Constants.mainHeaderHeight - Constants.bottomTabBarHeight;
    const scrollViewStyles = [styles.scrollView];
    scrollViewStyles.push({
      height: scrollViewHeight,
    });

    return (
      <div>
      <View style={styles.container}>
        <ScrollView
          ref={ref => { this.scrollViewRef = ref; }}
          style={scrollViewStyles}
          onScroll={(events) => this.onScroll(events, pagination)}
          scrollEventThrottle={1}
          //stickyHeaderIndices={isMarketing ? [2]:[1]}
          stickyHeaderIndices={[2]}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => {
                if (!refreshing) {
                  this.setState({ refreshing: true }, () => {
                    this.loadData(false);
                  });
                }
              }}
            />
          }
          >
          {
            isMarketing &&
            <View style={styles.deliveryContainer}>
              <View style={styles.deliveryTextContainer}>
                  <Text style={styles.deliveryText}>
                      <Text style={styles.deliveryBoldText}>30/50/100만원 이상</Text> 구매시 추가 <Text style={styles.deliveryBoldTextRed}>1.5만/3만/7만원</Text> 할인
                  </Text>
              </View>
            </View>
          }
          <CategoryList
            categories={categories}
            onSelected={this.onSelectCategory}
            selectedCategoryItem={selectedCategoryItem}
            selectedSubCategoryList={selectedSubCategoryList}
            />
          <ProductFilterControl
            router={router}
            activeFilterList={filterList}
            sortingRule={sortingRule}
            brandList={brandList}
            onChangeFilterList={(newActiveFilterList) => this.onChangeFilterList(newActiveFilterList)}
            onChangeSortingRule={(newSortingRule) => this.onChangeSortingRule(newSortingRule)}
            containerStyle={styles.productFilterControl}
            setModalProps={setModalProps}
            modalProps={modalProps}
            />
          <Products
            horizontal={false}
            numColumns={2}
            products={shopProducts}
            pagination={pagination}
            onPressProduct={onPressProduct}
            brandList={brandList}
            loadData={(nextPage) => this.getProducts(nextPage)}
            listHeaderComponentStyle={{ width: 0 }}
            listFooterComponentStyle={{ width: 0, height: 80 }}
            containerStyle={styles.productListContainer}
            flatListStyle={styles.productFlatListContainer}
            itemContainerStyle={styles.itemContainerStyle}
            productImageStyle={stylesHtml.productImage}
            productTextContainerStyle={styles.productTextContainer}
            isFetchingNextPage={isFetchingNextPage}
            isSmallVersion={false}
            showLikeButton={true}
            showTags={true}
            router={router}
            setModalProps={setModalProps}
            modalProps={modalProps}
            columnWrapperStyle={styles.columnWrapperStyle}
            />
        </ScrollView>
      </View>
      {this.renderCategoryDropdown()}
      </div>
    );
  }
}


export default connect(
  (state, { router }) => {
    return {
      categories: state.entities.shop.categories.filter(c => {
        return (
          c.cateNm !== "PRE ORDER" &&
          c.cateNm !== "NEW" &&
          c.cateNm !== "BEST" &&
          c.cateNm !== "BRAND"
        );
      }),
      ...loadEntities(state, 'shopBrandList', 'shopBrandList'),
      upperState: state,
    };
  },
  actions,
)(ShopPage);
