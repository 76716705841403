// 주소 정규표현식이 중복해서 매칭되는 경우, 목록 순서상 앞 항목이 우선됨

export default [
  {
    pathRegex: /^\/$/, // HomePage
    type: 'home',
    title: '',
    showSearchButton: true,
    showBellButton: true,
    showCartButton: true,
    selectedTab: 'today',
  },
  {
    pathRegex: /^\/preorder\/detail/, // PreOrderDetailPage
    type: 'text',
    title: '프리오더',
    showSearchButton: true,
    showBellButton: true,
    showCartButton: true,
  },
  {
    pathRegex: /^\/preorder/, // PreorderPage
    type: 'home',
    title: '',
    showSearchButton: true,
    showBellButton: true,
    showCartButton: true,
    selectedTab: 'preorder',
  },
  {
    pathRegex: /^\/exhibition/, // ExhibitionPage
    type: 'text',
    title: '기획전',
    showSearchButton: true,
    showBellButton: true,
    showCartButton: true,
  },
  {
    pathRegex: /\/legal\/terms/, // TermsPolicyPage
    type: 'text',
    title: '',
    showCartButton: false,
    showSearchButton: false,
  },
  {
    pathRegex: /\/legal\/privacy/, // TermsPolicyPage
    type: 'text',
    title: '',
    showCartButton: false,
    showSearchButton: false,
  },
  {
    pathRegex: /\/legal\/marketing/, // TermsPolicyPage
    type: 'text',
    title: '마케팅 정보 수신동의',
    borderWidth: 1,
    showCartButton: false,
    showSearchButton: false,
  },
  {
    pathRegex: /\/businessInfo/, // BusinesssInfoPage
    type: 'text',
    title: '사업자 정보',
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/accounts\/login$/, // LoginPage
    type: 'text',
    title: '로그인',
    borderWidth: 1,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/accounts\/join\/email/, // JoinWithEmailPage
    type: 'text',
    title: '가입하기',
    borderWidth: 1,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/accounts\/login\/facebook/, // FacebookCallbackPage
    type: 'text',
    title: '가입하기',
    borderWidth: 1,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/accounts\/login\/naver/, // NaverCallbackPage
    type: 'text',
    title: '가입하기',
    borderWidth: 1,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/accounts\/login\/kakao/, // KakaoCallbackPage
    type: 'text',
    title: '가입하기',
    borderWidth: 1,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/accounts\/login\/apple/, // AppleIdCallbackPage
    type: 'text',
    title: '가입하기',
    borderWidth: 1,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/accounts\/login\/instagram/, // InstagramCallbackPage
    type: 'text',
    title: '가입하기',
    borderWidth: 1,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/accounts\/login\/email\/verify/, // LoginWithEmailVerifyPage
    type: 'text',
    title: '가입하기',
    borderWidth: 1,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/accounts\/login\/email\/recovery/, // AccountRecoveryPage
    type: 'text',
    title: '아이디/비밀번호 찾기',
    borderWidth: 0,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/accounts\/login\/email\/findPassword/, // FindPasswordWithEmailPage
    type: 'text',
    title: '가입하기',
    borderWidth: 0,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/notice$/, // Notice
    type: 'text',
    title: '공지사항',
    defaultRouterBackPath: '/accounts/settings',
  },
  { pathRegex: /\/notice\//, // NoticeView
    type: 'text',
    title: '',
    defaultRouterBackPath: '/notice',
  },
  { pathRegex: /\S+reviewWrite$/, // ReviewWritePage //accounts/orders/:orderNo/orderItem/:orderItemNo/reviewWrite/
    type: 'text',
    title: '리뷰 작성',
    borderWidth: 1,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/accounts\/edit$/, // EditProfilePage
    type: 'text',
    title: '회원 정보 수정',
    borderWidth: 1,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/accounts\/changePassword$/, // ChangePasswordPage
    type: 'text',
    title: '비밀번호 변경',
    borderWidth: 1,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/accounts\/withdraw$/, // MembershipWithdrawPage
    type: 'text',
    title: '회원 탈퇴하기',
    borderWidth: 1,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/accounts\/memberInfo$/, // MemberInfoPage
    type: 'text',
    title: '등급 및 적립 내역',
    borderWidth: 0,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/accounts\/orders\/[^\/\s]+$/, // OrderDetailPage /accounts/orders/:orderNo
    type: 'text',
    title: '주문 상세 정보',
    borderWidth: 0,
    showBellButton: true,
    showCartButton: true,
    showSearchButton: true,
  },
  { pathRegex: /\/accounts\/orderManage\/exchange\/[^\/\s]+$/, // OrderManagePage /accounts/orderManage/exchange/:orderNo
    type: 'text',
    title: '교환 요청',
    borderWidth: 0,
    showBellButton: true,
    showCartButton: true,
    showSearchButton: true,
  },
  { pathRegex: /\/accounts\/orderManageComplete\/exchange\/[^\/\s]+$/, // OrderManageCompletePage /accounts/orderManageComplete/exchange/:orderNo
    type: 'text',
    title: '교환 요청 완료',
    borderWidth: 0,
    showBellButton: true,
    showCartButton: true,
    showSearchButton: true,
  },
  { pathRegex: /\/accounts\/orderManage\/refund\/[^\/\s]+$/, // OrderManagePage /accounts/orderManage/refund/:orderNo
    type: 'text',
    title: '반품 요청',
    borderWidth: 0,
    showBellButton: true,
    showCartButton: true,
    showSearchButton: true,
  },
  { pathRegex: /\/accounts\/orderManageComplete\/refund\/[^\/\s]+$/, // OrderManageCompletePage /accounts/orderManageComplete/refund/:orderNo
    type: 'text',
    title: '반품 요청 완료',
    borderWidth: 0,
    showBellButton: true,
    showCartButton: true,
    showSearchButton: true,
  },
  { pathRegex: /\/accounts\/orders$/, // MyOrdersPage
    type: 'text',
    title: '주문 내역',
    borderWidth: 1,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/accounts\/reviews\/edit/, // ReviewWritePage /accounts/reviews/edit/:articleId/
    type: 'text',
    title: '리뷰 수정',
    borderWidth: 1,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/accounts\/reviewWriteSuccess/, // ReviewWriteSuccessPage
    type: 'logo',
  },
  { pathRegex: /\/accounts\/reviewEditSuccess/, // ReviewWriteSuccessPage
    type: 'logo',
  },
  { pathRegex: /\/accounts\/review/, // MyReviewsPage
    type: 'text',
    title: '상품 리뷰',
    borderWidth: 1,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/product\/[^\/\s]+\/reviews$/, // ProductReviewsPage
    type: 'text',
    title: '리뷰',
    borderWidth: 1,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/style\/all$/, // Styles
    type: 'style',
    title: '',
    borderWidth: 0,
    showCameraButton: true,
    showSearchButton: true,
    showBellButton: true,
    showCartButton: true,
    selectedTab: 'all',
  },
  { pathRegex: /\/style\/following$/, // Styles
    type: 'style',
    title: '',
    borderWidth: 0,
    showCameraButton: true,
    showSearchButton: true,
    showBellButton: true,
    showCartButton: true,
    selectedTab: 'following',
  },
  { pathRegex: /\/style\/feed\/[^\/\s]+$/, // StyleDetail //style/:stylePostId
    type: 'text',
    title: '스타일 피드',
    borderWidth: 0,
  },
  { pathRegex: /\/style\/upload$/, // StyleDetail //style/:stylePostId
    type: 'text',
    title: '피드 올리기',
    borderWidth: 0,
    showBackButton: false,
  },
  { pathRegex: /\/style\/feed\/[^\/\s]+\/replies$/, // StyleDetail //style/:stylePostId
    type: 'text',
    title: '댓글',
    borderWidth: 0,
  },
  { pathRegex: /\/shop\/cart/, // CartPage
    type: 'text',
    title: '쇼핑백',
  },
  { pathRegex: /\/shop\/checkout\/[^\/\s]+$/, // CheckoutPage
    type: 'text',
    title: '구매하기',
    borderWidth: 1,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/shop\/checkoutPaymentGateway\/[^\/\s]+$/, // CheckoutPaymentGatewayPage
    type: 'text',
    title: '결제',
    borderWidth: 1,
    showCartButton: false,
    showSearchButton: false,
  },
  { pathRegex: /\/accounts\/shippingAddress\/edit\/[^\/\s]+$/, // ShippingAddressEditPage /accounts/shippingAddress/edit/:shippingAddressId
    type: 'text',
    title: '배송지 수정',
    showSearchButton: true,
    showBellButton: true,
    showCartButton: true,
  },
  {
    pathRegex: /\/accounts\/shippingAddress\/create$/, // ShippingAddressEditPage /accounts/shippingAddress/create
    type: 'text',
    title: '배송지 추가',
    showSearchButton: true,
    showBellButton: true,
    showCartButton: true,
  },
  {
    pathRegex:   /\/accounts\/settings/, // SettingsPage
    type: 'my',
    showSearchButton: true,
    showBellButton: true,
    showCartButton: true,
    selectedTab: 'shippingInfo',
  },
  {
    pathRegex:   /\/accounts\/profile\/edit/, // EditMyProfilePage
    type: 'text',
    showBackButton: true,
    title: '프로필 수정',
  },
  {
    pathRegex:   /\/accounts\/profile/, // ProfilePage
    type: 'my',
    showCameraButton: true,
    showSearchButton: true,
    showBellButton: true,
    showCartButton: true,
    selectedTab: 'profile',
  },
  {
    pathRegex:   /\/accounts\/mysize\/clothing/, // MySizeSelectPage
    type: 'text',
    title: '내 옷 사이즈',
    showSearchButton: true,
    showBellButton: true,
    showCartButton: true,
  },
  {
    pathRegex:   /\/accounts\/mysize\/shoes/, // MySizeSelectPage
    type: 'text',
    title: '내 신발 사이즈',
    showSearchButton: true,
    showBellButton: true,
    showCartButton: true,
  },
  { pathRegex: /\/heartists\/[^\/\s]+$/, // HeartistProfilePage
    type: 'text',
    title: '',
  },
  { pathRegex: /\/heartists/, // HeartistsPage
    type: 'text',
    title: '',
    borderWidth: 0,
    showCartButton: true,
    showSearchButton: true,
  },
  { pathRegex: /\/posts\/[^\/\s]+$/, // PostPage
    type: 'text',
    title: '',
    borderWidth: 0,
    showCartButton: true,
    showSearchButton: true,
  },
  { pathRegex: /\/push$/, // PushHistoryPage
    type: 'text',
    title: '알림',
    borderWidth: 0,
  },
  { pathRegex: /\/shop$/, // ShopPage
    type: 'text',
    title: '',
    borderWidth: 0,
    showSearchButton: true,
    showBellButton: true,
    showCartButton: true,
  },
  { pathRegex: /\/product\/[^\/\s]+$/, // ProductPage //product/:productId
    type: 'text',
    title: '',
    borderWidth: 0,
    showShareButton: true,
    showCartButton: true,
    darkContent: true,
  },
  { pathRegex: /^\/favorite$/, // MyHistoryPage /favorite
    type: 'favorite',
    title: '',
    borderWidth: 0,
    showSearchButton: true,
    showBellButton: true,
    showCartButton: true,
    selectedTab: 'history',
  },
  { pathRegex: /^\/favorite\/brand$/, // MyBrandPage /favorite/brand
    type: 'favorite',
    title: '',
    borderWidth: 0,
    showSearchButton: true,
    showBellButton: true,
    showCartButton: true,
    selectedTab: 'brand',
  },
  { pathRegex: /\/accounts\/following/, // FollowingHeartistsPage
    type: 'text',
    title: '팔로워 및 팔로잉 리스트',
  },
  { pathRegex: /\/accounts\/follower/, // FollowerHeartistsPage
    type: 'text',
    title: '팔로워 및 팔로잉 리스트',
  },
  { pathRegex: /\/accounts\/faq/, // FAQPage
    type: 'text',
    title: 'FAQ',
    borderWidth: 1,
    showCartButton: false,
    showSearchButton: false,
  },
  /*
  { pathRegex: /\/accounts\/edit/, // EditProfilePage
    type: 'text',
    title: '',
    borderWidth: 1,
  },
  { pathRegex: /\/heartists/, // HeartistsPage
    type: 'text',
    title: '',
    borderWidth: 1,
  },
  */
  { pathRegex: /\/shop\/search$/, // SearchPage
    type: 'search',
    title: '',
    borderWidth: 1,
  },
  { pathRegex: /\/shop\/search\/result/, // SearchResultPage
    type: 'search',
    title: '',
    borderWidth: 0,
  },
];

  /*
  /logo/: [
    /\/shop$/, // ShopPage
    /\/history/, // HistoryPage} onEnter={redirectToIntro
    /\/shop\/checkoutFinish/, // CheckoutFinishPage
    /\/accounts\/login$/, // AuthPage
    /\/accounts\/login\/email$/, // LoginWithEmailPage
    /\/editorial/, // EditorialPage
    /\/greeting/, // GreetingPage
    /\/intro/, // IntroPage
  ],
  */
