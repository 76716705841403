import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import {
  instagramMediaIdToDate,
  objectIdToDate,
} from 'react-shared/util/commonFunctions';

import Media from '../Media';
import InstagramProfile from '../InstagramProfile';
import PostDate from '../PostDate';

const styles = {
  profileHeadContainer: {
    height: '40px',
    marginBottom: '12.5px',
    width: '100%',
  },
  profileHead: {
    display: 'inline-block',
    height: '40px',
    float: 'left',
    marginBottom: '12.5px',
    textAlign: 'left',
    textTransform: 'lowercase',
    width: '70%',
  },
  profileLink: {
    color: '#777',
  },
  profileImage: {
    borderRadius: '50%',
    height: '40px',
    width: '40px',
  },
  username: {
    color: 'black',
    fontSize: '16px',
    marginLeft: '7.5px',
    fontFamily: '"Avenir New"',
  },
  date: {
    display: 'inline-block',
    height: '40px',
    lineHeight: '40px',
    marginBottom: '12.5px',
    textAlign: 'right',
    width: '30%',
  },
  itemRow: {
    marginLeft: '-1px',
    marginRight: '-1px',
    width: '100%',
    textAlign: 'left',
    fontSize: 0,
  },
  itemContainer: {
    border: '1px solid #fff',
    paddingLeft: 0,
    paddingRight: 0,
    width: '25%',
    display: 'inline-block',
    position: 'relative',
  },
  recommendationHeader: {
    textAlign: 'left',
    width: '100%',
    marginTop: '20px',
    marginBottom: '7px',
  },
  recommendationHeaderText: {
    textDecoration: 'underline',
    fontSize: '14px',
  },
  mainImage: {
    marginBottom: '4px',
  },
  itemClickCount: {
    textAlign: 'center',
    color: 'white',
    lineHeight: '66px',
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    top: 0,
    width: '100%',
    height: '100%',
  },
  postClickCount: {
    lineHeight: '270px',
    color: 'white',
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    top: 0,
    width: '100%',
    height: '100%',
  },
};

export default class PostPerformance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showClickCount: false,
    };
  }

  static getPostDate(post) {
    if (post.featured && post.featured.score > 10000) {
      return new Date(post.featured.score);
    }
    if (post.instagramMedias.length === 0) {
      return objectIdToDate(post._id);
    }
    if (post.featured && post.featured.at) {
      return new Date(post.featured.at);
    }
    let targetMediaId = post.instagramMedias[0].id;
    for (let i = 0; i < post.instagramMedias.length; ++i) {
      if (post.instagramMedias[i].id.split('_')[1] === '1672806696') {
        targetMediaId = post.instagramMedias[i].id;
        break;
      }
    }
    return instagramMediaIdToDate(targetMediaId);
  }

  renderItem(item) {
    const { media, media: { publicId }, retails } = item;
    const { showClickCount } = this.state;
    const { clickCount } = this.props;
    const orgLink = retails.length && retails[0].orgLink;
    const filteredItems = clickCount && clickCount.itemCount.filter(i => i.url === orgLink);
    const itemClickCount = filteredItems && filteredItems.length && filteredItems[0].count || 0;
    const itemOptions = {
      width: 150,
      height: 150,
      crop: 'limit',
    };

    return (
      <div style={styles.itemContainer} key={`${publicId}-${Date.now}`}>
        <Media media={media} options={itemOptions} />
        {showClickCount && (
          <div style={styles.itemClickCount}>{itemClickCount}</div>
        )}
      </div>
    );
  }

  renderProfileHead() {
    const { post, post: { owner: { username } } } = this.props;
    const date = PostPerformance.getPostDate(post);

    return (
      <div style={styles.profileHeadContainer}>
        <div style={styles.profileHead}>
          <Link to={`/${username}`} style={styles.profileLink}>
            <InstagramProfile instagramId={username} style={styles.profileImage} />
            <span style={styles.username}>{username}</span>
          </Link>
        </div>
        <div style={styles.date}>
          <PostDate date={date} />
        </div>
      </div>
    );
  }

  render() {
    const { post, post: { media: { 0: media }, _id }, needProfileHead, clickCount } = this.props;
    const { showClickCount } = this.state;
    const items = post.products.filter(item => (item.isRecommend !== true));
    const recommendItems = post.products.filter(item => (item.isRecommend === true));

    const mediaOptions = {
      width: 640,
      height: 640,
      crop: 'limit',
    };

    return (
      <div>
        {needProfileHead ? this.renderProfileHead() : <div />}
        {<a href={`/posts/${_id}`} style={{ fontSize: '10px', lineHeight: '11px' }}>{_id}</a>}
        <div
          style={{ position: 'relative' }}
          onMouseEnter={() => this.setState({ showClickCount: true })}
          onMouseLeave={() => this.setState({ showClickCount: false })}
        >
          <Media media={media} options={mediaOptions} style={styles.mainImage} />
          {showClickCount && (
            <div style={styles.postClickCount}>{clickCount ? clickCount.totalPostCount : 'loading...'}</div>
          )}
        </div>
        <div style={styles.itemRow}>
          {items.map(item => this.renderItem(item))}
        </div>
        {(recommendItems.length > 0
          ? (
            <div>
              <div style={styles.recommendationHeader}>
                <span style={styles.recommendationHeaderText}>유사상품</span>
              </div>
              <div style={styles.itemRow}>
                {recommendItems.map(item => this.renderItem(item))}
              </div>
            </div>
          ) : ''
        )}
      </div>
    );
  }
}

PostPerformance.defaultProps = {
  clickCount: null,
  needProfileHead: true,
};

PostPerformance.propTypes = {
  post: PropTypes.shape({
    media: PropTypes.array.isRequired,
    items: PropTypes.array,
  }).isRequired,
  needProfileHead: PropTypes.bool,
  clickCount: PropTypes.object,
};
