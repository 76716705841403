import React from 'react';
import { StyleSheet, View, Text, Dimensions, TextInput, TouchableOpacity } from 'react-native';

import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import Select from 'react-select';

import { actions } from 'shared';
import { formatPrice } from 'shared/util';

import {
  Constants, Styles,
} from '../../../constants';

import {
  ChevronDownIcon,
} from '../../../icons-v2';

import {
  Button
} from '../../../components-v2/';


const styles = StyleSheet.create({
  container: {
    paddingBottom: 20,
  },
  labelInputContainer: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  labelContainer: {
    width: 105,
  },
  labelText: {
    color: '#000',
    fontSize: 14,
    lineHeight: 16.8,
    textAlign: 'left',
    textAlignVertical: 'center',
    ...Styles.pretendard400,
  },
  valueContainer: {
    flex: 1,
  },
  pointDiscountContainer: {
  },
  pointInputContainer: {
    marginBottom: 6,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingHorizontal: 20,
    paddingVertical: 13,
    borderWidth: 0.5,
    borderColor: '#000',
  },
  pointInputText: {
    padding: 0,
    fontSize: 15,
    lineHeight: 18,
    height: 18,
    ...Styles.pretendard400,
    outlineStyle: 'none',
  },
  pointUseAllButtonContainer: {
    flex: 1,
  },
  pointUseAllButton: {
    height: 40,
    width: '100%',
    padding: 0,
    backgroundColor: '#fff',
    borderWidth: 0.5,
    borderColor: '#000',
    alignItems: 'center',
    marginTop: 24,
    justifyContent: 'center',
  },
  pointUseAllText: {
    fontSize: 16,
    lineHeight: 19.2,
    color: '#000',
    textAlign: 'center',
    textAlignVertical: 'center',
    ...Styles.pretendard600,
  },
  pointDiscountInfoContainer: {
  },
  pointDiscountInfoText: {
    fontSize: 14,
    lineHeight: 16.8,
    opacity: 0.5,
    color: '#000',
    textAlign: 'left',
    textAlignVertical: 'top',
    ...Styles.pretendard400,
  },
});


const couponSelectStyles = {
  
  control: base => ({
    ...base,
    backgroundColor: '#ffffff',
    borderWidth: 0.5,
    borderColor: '#000000',
    borderRadius: 0,
    outline: 'none',
    color: '#000',
    height: 44,
  }),
  option: base => ({
    ...base,
    fontSize: 15,
    ...Styles.pretendard400,
  }),
  indicatorSeparator: base => ({
    ...base,
    width: 0,
  }),
  valueContainer: base => ({
    ...base,
    padding: 0,
    paddingLeft: Constants.viewHorizontalPadding,
  }),
  placeholder: base => ({
    ...base,
    color: '#000',
    height: 18,
    opacity: 0.5,
    fontSize: 15,
    ...Styles.pretendard400,
  }),
  singleValue: base => ({
    ...base,
    color: '#000',
    height: 18,
    fontSize: 15,
    ...Styles.pretendard400,
  }),
  indicatorsContainer: base => ({
    ...base,
    marginRight: Constants.viewHorizontalPadding,
  }),
};


export class DiscountInfo extends React.Component {
  state = {
    couponList: null,
    couponDisabledList: null,
    conponDisabledProducts: [],
    discountInfo: {},
  };

  componentDidMount() {
    // console.log('DiscountInfo componentDidMount');

    this.loadCouponDisabledList();
    this.loadCouponList();
  }

  componentDidUpdate() {
    // console.log('DiscountInfo componentDidUpdate');

    this.loadCouponDisabledList();
    this.loadCouponList();
  }

  hasPreOrderItem = () => {
    const { orderInfo: { orderItems } } = this.props;
    return orderItems.filter(x => x.preOrder === 'y').length > 0;
  }

  getCouponDisabledProducts = () => {
    const { couponDisabledList } = this.state;
    if (couponDisabledList === null) {
      return null;
    }

    const { orderInfo: { orderItems }} = this.props;
    if (!orderItems) {
      // console.log('DiscountInfo cannot get orderItems');
      return null;
    }

    const couponDisabledProducts = orderItems.filter((orderItem) => {
      const goodsNo = orderItem.goodsNo;
      const brandCd = orderItem.brandCd;
      const cateCdList = orderItem.cateAllCd ? orderItem.cateAllCd.map(x => x.cateCd) : [];

      const disabledWithGoodsNo = couponDisabledList.product.map(x => x.goodsNo).includes(goodsNo);
      const disabledWithBrandCd = couponDisabledList.brand.map(x => x.brandCd).includes(brandCd);
      const disabledWithCateCd = cateCdList.filter(cateCd => couponDisabledList.category.map(x => x.cateCd).filter(disabledCateCd => cateCd.startsWith(disabledCateCd)).length > 0).length > 0;
      // console.log('disabledWithGoodsNo', orderItem.goodsNm, disabledWithGoodsNo);
      // console.log('disabledWithBrandCd', orderItem.goodsNm, disabledWithBrandCd);
      // console.log('disabledWithCateCd', orderItem.goodsNm, disabledWithCateCd);

      return disabledWithGoodsNo || disabledWithBrandCd || disabledWithCateCd;
    });

    return couponDisabledProducts;
  }

  loadCouponList = async () => {
    const { couponList } = this.state;

    if (couponList !== null) {
      return;
    }

    const { itemIndexList, getPromotionCouponList } = this.props;

    try {
      const ret = await getPromotionCouponList(itemIndexList);
      // console.log('DiscountInfo getPromotionCouponList return', ret);

      if (!ret.success) {
        return;
      }

      this.updateCouponList(ret.data);
    } catch (error) {
      // console.log('DiscountInfo loadCouponList errro=', error);
    }
  };

  loadCouponDisabledList = async () => {
    const { orderInfo: { orderItems }, getCouponDisabledList } = this.props;
    const { couponDisabledList } = this.state;

    if (couponDisabledList !== null) {
      return;
    }

    try {
      const ret = await getCouponDisabledList();
      // console.log('loadCouponDisabledList', JSON.stringify(ret));

      this.setState({ couponDisabledList: ret });
    } catch (error) {
      // console.log('DiscountInfo loadCouponDisabledList errro=', error);
    }
  }

  onCouponSelected = (coupon = {}) => {
    console.log('DiscountInfo onCouponSelected coupon=', coupon);

    // const { onDiscountApplied } = this.props;
    const { discountInfo: oldDiscountInfo } = this.props;
    const {
      memberCouponNo = null,
      couponDcPrice = 0,
    } = coupon;

    const newDiscountInfo = {
      ...oldDiscountInfo,
      couponApplyOrderNo: memberCouponNo,
      totalCouponOrderDcPrice: couponDcPrice,
    };

    this.setState({
      discountInfo: newDiscountInfo,
    }, () => this.applyPointDiscount());

    // onDiscountApplied(newDiscountInfo);
  }


  updateCouponList = data => {
    const {
      // convertMemberCouponArrData: {
      //   order: convertMemberCouponArrDataOrder,
      // },
      convertMemberCouponPriceArrData: {
        order: {
          memberCouponSalePrice,
        },
      },
      memberCouponArrData: {
        // delivery: memberCouponArrDataDelivery,
        order: memberCouponArrDataOrder,
      },
    } = data;

    const couponList = memberCouponArrDataOrder;

    couponList.forEach(element => {
      const { memberCouponNo } = element;
      element.couponDcPrice = memberCouponSalePrice[memberCouponNo];
    });

    this.setState({ couponList });
  }

  applyPointDiscount = async () => {
    const {
      itemIndexList,
      applyPromotion,
      onDiscountUpdateStart,
      onDiscountUpdateEnd,
    } = this.props;

    const { orderInfo } = this.props;

    const {
      discountInfo: {
        couponApplyOrderNo = '',
        totalCouponOrderDcPrice = 0,
      },
      pointForDiscount = 0,
    } = this.state;


    const {
      totalDeliveryCharge,
      deliveryFree,
      mileageAvailable,
    } = orderInfo;

    const params = {
      cartSno: itemIndexList,
      couponApplyOrderNo,
      totalCouponOrderDcPrice,
      mileageApplyOrderNo: pointForDiscount,
      mileageAvailable,
      totalDeliveryCharge,
      deliveryFree,
    };

    onDiscountUpdateStart();

    try {
      // console.log('applyPointDiscount applyPromotion send', params);
      const ret = await applyPromotion(params);
      // console.log('applyPointDiscount applyPromotion recv', ret);

      if (!ret.success) {
        // console.log('applyPointDiscount error=', ret.success);
        return;
      }

      const {
        data: {
          settlePrice,
          mileageApplyOrderNo,
          totalSettlePrice,
        },
      } = ret;

      const discountInfo = {
        totalSettlePrice,
        settlePrice,
        couponApplyOrderNo,
        totalCouponOrderDcPrice,
        useMileage: mileageApplyOrderNo,
      };

      onDiscountUpdateEnd(discountInfo);
    } catch (error) {
      // console.log('applyPointDiscount error=', error);
    } finally {
      onDiscountUpdateEnd();
    }
  }

  renderCouponPicker = () => {
    const placeholder = {
      label: '쿠폰 선택하기',
      value: null,
    };

    const {
      couponList=[],
      discountInfo: {
        couponApplyOrderNo,
      },
    } = this.state;

    const items = (couponList || []).map(item => {
      const {
        couponNm,
        memberCouponNo,
        couponDcPrice,
      } = item;

      const dcPrice = formatPrice(-couponDcPrice);

      return {
        label: `${couponNm} (${dcPrice})`,
        value: memberCouponNo,
        item,
      };
    });

    const selectedCoupon = items.find(coupon => coupon.value === couponApplyOrderNo);

    // console.log('renderCoupontPicker items', items);
    // console.log('renderCoupontPicker selectedCoupon', selectedCoupon);

    const components = {
      DropdownIndicator: ChevronDownIcon,
    }

    const couponDisabledProducts = this.getCouponDisabledProducts();
    const hasPreOrder = this.hasPreOrderItem();
    if (hasPreOrder || (couponDisabledProducts !== null && couponDisabledProducts.length > 0)) {
      let alertMsg = '';
      if (hasPreOrder) {
        alertMsg = '프리오더 상품에는 쿠폰을 적용할 수 없습니다.';
      } else {
        alertMsg = '해당 상품에는 쿠폰을 적용할 수 없습니다.';
      }

      return (
        <TouchableOpacity
          onPress={() => {
            alert(alertMsg);
          }}
        >
          <Select
            onChange={() => {}}
            options={items}
            isClearable={false}
            isSearchable={false}
            placeholder="쿠폰 선택하기"
            styles={couponSelectStyles}
            value={selectedCoupon}
            components={components}
            noOptionsMessage={() => "쿠폰 선택하기"}
            isDisabled={true}
          />
        </TouchableOpacity>
      );
    }

    return (
      <Select
        onChange={data => this.onCouponSelected(data.item)}
        options={items}
        isClearable={false}
        isSearchable={false}
        placeholder="쿠폰 선택하기"
        styles={couponSelectStyles}
        value={selectedCoupon}
        components={components}
        noOptionsMessage={() => "쿠폰 선택하기"}
        // menuIsOpen={true}
      />
    );
  };

  setPointForDiscount = (pointText, applyPoint = false) => {
    // console.log('setPointForDiscount pointText=', pointText);

    const {
      orderInfo: {
        mileageAvailable,
      },
      checkoutInfo: {
        settlePrice,
        useMileage = 0,
      }
    } = this.props;


    let point = pointText;
    point = point.replace(/,/g, '');

    if (point.includes(' P')) {
      // 123 P 뒤에 숫자를 입력하여 123 P1일 때, 1231로 처리하기 위함
      point = point.replace(' P', '');
    } else if (point.endsWith(' ')) {
      // 123 P 뒤에서 백스페이스로 P를 지웠을 때, 마지막 숫자를 지워서 12로 만들기 위함
      point = point.substring(0, point.length - 2);
    }

    point = Number.parseInt(point, 10);

    const maxMileage = Math.min(mileageAvailable, settlePrice + useMileage);
    if (point > maxMileage) {
      point = maxMileage;
    }

    if (!point) {
      point = 0;
    }

    this.setState({
      pointForDiscount: point,
    }, () => { if (applyPoint) { this.applyPointDiscount(); } });
  }

  onPointSubmitEdting = () => {
    this.applyPointDiscount();
  };

  onPointEndEditing = () => {
    // console.log('onPointEndEditing');
    this.applyPointDiscount();
  };


  renderPointDiscount = () => {
    const {
      orderInfo: {
        mileageAvailable,
        mileageUse: {
          minimumLimit,
        },
      },
      checkoutInfo: {
        settlePrice,
        useMileage,
      },
    } = this.props;

    const {
      pointForDiscount = 0,
    } = this.state;

    // console.log('renderPointDiscount pointForDiscount', pointForDiscount);

    const onPressPointUseAll = async () => {
      const maxMileage = Math.min(mileageAvailable, settlePrice + useMileage);

      this.setPointForDiscount(`${maxMileage}`, true);
    };

    return (
      <View style={styles.pointDiscountContainer}>
        <View style={styles.pointInputContainer}>
          <NumberFormat
            value={pointForDiscount}
            displayType="text"
            thousandSeparator
            suffix=" P"
            renderText={value => (
              <TextInput
                style={styles.pointInputText}
                value={value}
                onChangeText={text => this.setPointForDiscount(text)}
                onEndEditing={this.onPointEndEditing}
                onSubmitEditing={this.onPointSubmitEdting}
                keyboardType="number-pad"
              />
            )}
          />
        </View>
        <View style={styles.pointDiscountInfoContainer}>
          <NumberFormat
            value={mileageAvailable}
            displayType="text"
            thousandSeparator
            prefix="잔여 포인트: "
            suffix="P"
            renderText={value => (
              <Text
                style={styles.pointDiscountInfoText}
              >
                {value}
              </Text>
            )}
          />
          <NumberFormat
            value={minimumLimit}
            displayType="text"
            thousandSeparator
            style={{ marginTop: 4 }}
            renderText={value => (
              <Text
                style={styles.pointDiscountInfoText}
              >
                {value}
                원 이상 구매시 포인트 사용 가능
              </Text>
            )}
          />
        </View>
        <View style={styles.pointUseAllButtonContainer}>
          <Button
            containerStyle={styles.pointUseAllButton}
            titleStyle={styles.pointUseAllText}
            onPress={onPressPointUseAll}
            title="전액 사용"
            disabledOpacity={0.3}
          />
        </View>
      </View>
    );
  }

  render() {

    const couponContainerStyles = [
      styles.labelInputContainer,
      { zIndex: 100 }, // For react-select
    ];

    return (
      <View style={styles.container}>
        <View style={couponContainerStyles}>
          <View style={styles.labelContainer}>
            <Text style={styles.labelText}>
              쿠폰 사용
            </Text>
          </View>
          <View style={styles.valueContainer}>
            {this.renderCouponPicker()}            
          </View>
        </View>
        <View style={styles.labelInputContainer}>
          <View style={styles.labelContainer}>
            <Text style={styles.labelText}>
              포인트 사용
            </Text>
          </View>
          <View style={styles.valueContainer}>
            {this.renderPointDiscount()}            
          </View>
        </View>
      </View>
    );
  }
}


export default connect(
  null,
  actions,
)(DiscountInfo);
