import React from 'react';
import {
  StyleSheet, View, Text,
} from 'react-native';

import { Constants, Styles } from '../../../constants';

import { CheckBox, Button } from '../../../components-v2';


const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 13,
    paddingBottom: 22,
    marginHorizontal: Constants.viewHorizontalPadding,
    backgroundColor: '#fff',
    borderBottomWidth: 0.5,
    borderBottomColor: '#cfcfcf',
  },
  checkAllItemButton: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  checkAllItemButtonText: {
    fontSize: 16,
    marginStart: 16,
    lineHeight: 19.2,
    height: 19,
    color: '#000',
    ...Styles.pretendard600,
  },
  deleteCheckedItemButton: {
    padding: Constants.touchEffectWidth,
    margin: -1 * Constants.touchEffectWidth,
    borderRadius: Constants.touchEffectBorderRadius,
    borderColor: 'transparent',
    borderWidth: 0,
  },
  deleteCheckedItemText: {
    marginTop: 1,
    fontSize: 14,
    lineHeight: 16.8,
    height: 17,
    color: '#000',
    ...Styles.pretendard400,
  },
});


export class Header extends React.Component {

  render() {
    const {
      itemListInCart, isEmpty, isCheckedAll,
      onPressCheckAllItem, onPressDeleteCheckedItems,
    } = this.props;

    return (
      <View style={[styles.container, isEmpty ? {opacity: 0.5} : {}]}>
        <View style={styles.checkAllItemButton}>
          <CheckBox
            checked={isCheckedAll}
            onPress={onPressCheckAllItem}
            />
          <Text style={styles.checkAllItemButtonText}>
            {`전체 상품 ${itemListInCart.length}개`}
          </Text>
        </View>

        <Button
          containerStyle={styles.deleteCheckedItemButton}
          onPress={onPressDeleteCheckedItems}
          titleStyle={styles.deleteCheckedItemText}
          title="선택 삭제"
          disabled={isEmpty}
          disabledOpacity={1}
        />
      </View>
    );
  }
}


export default Header;
