import * as React from 'react';

function SvgIcTabShop(props) {
  return (
    <svg width={24} height={24} {...props}>
      <defs>
        <path id="icTabShop_svg__a" d="M0 0h7v8H0z" />
        <path id="icTabShop_svg__c" d="M0 0h7v8H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(15 2)">
          <mask id="icTabShop_svg__b" fill="#fff">
            <use xlinkHref="#icTabShop_svg__a" />
          </mask>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M9 0v2.042l-.005.203C8.891 4.405 7.142 6.125 5 6.125c-2.21 0-4-1.828-4-4.083h0V0"
            mask="url(#icTabShop_svg__b)"
          />
        </g>
        <g transform="matrix(-1 0 0 1 9 2)">
          <mask id="icTabShop_svg__d" fill="#fff">
            <use xlinkHref="#icTabShop_svg__c" />
          </mask>
          <path
            stroke="#FFF"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M9 0v2.042l-.005.203C8.891 4.405 7.142 6.125 5 6.125c-2.21 0-4-1.828-4-4.083h0V0"
            mask="url(#icTabShop_svg__d)"
          />
        </g>
        <path
          stroke="#FFF"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M22 22H2V2h20z"
        />
        <path
          stroke="#FFF"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M16 2v2.042l-.005.203c-.104 2.16-1.853 3.88-3.995 3.88-2.21 0-4-1.828-4-4.083h0V2"
        />
      </g>
    </svg>
  );
}

export default SvgIcTabShop;

