import * as React from 'react';

function SvgIcClose(props) {
  const { color = '#000' } = props;
  return (
    <svg width={24} height={24} {...props}>
      <g stroke={color} strokeWidth={1.5} fill="none" fillRule="evenodd">
        <path d="M5 19L19 5M5 5l14 14" />
      </g>
    </svg>
  );
}

export default SvgIcClose;
