import React, { Component } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, View, TouchableHighlight } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import SupervisorAccountIconOutlined from '@material-ui/icons/SupervisorAccountOutlined';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';

import HeaderSearchBar from '../nativeComponents/HeaderSearchBar';
import HeaderTextTitleBar from './HeaderTextTitleBar';
import Toast from '../nativeComponents/Toast';

import Constants from '../constants/Constants';
import Colors from '../constants/Colors';
import { reactNativeStyles as Styles } from '../constants/Styles';

import Titles from '../routes/Titles';

import LogoHeartitIcon from '../icons/LogoHeartitIcon';
import HeaderSearchIcon from '../icons/HeaderSearchIcon';
import HeaderBagIcon from '../icons/HeaderBagIcon';


const styles = StyleSheet.create({
  border: {
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  container: {
    width: '100%',
    height: Constants.mainHeaderHeight,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 960,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftContainer: {
    paddingLeft: Constants.viewHorizontalPadding,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  titleContainer: {
    position: 'absolute',
    zIndex: -1,
    width: '100%',
    height: '100%',
    paddingTop: 8,
    paddingBottom: 3,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightContainer: {
    paddingRight: Constants.viewHorizontalPadding,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  toastContainer: {
    position: 'absolute',
    zIndex: 1000,
    width: '100%',
    height: 52,
    marginTop: 10,
  },
  button: {
  },
});

function setHomeFeedPadding(showToast) {
  const elem = document.getElementById('header_container');
  if (!elem) {
    return;
  }
  elem.style.marginBottom = showToast ? '-6px' : '0px';
}

class Navigation extends Component {
  constructor(props) {
    super(props);

    const { toastMsg } = props;
    this.state = {
      showToast: false,
    };
  }

  componentDidMount() {
    const { toastMsg } = this.props;
    if (toastMsg !== null) {
      this.setState({ showToast: true });
      setHomeFeedPadding(true);
    }
  }

  componentDidUpdate(prevProps) {
    const { toastMsg } = this.props;
    const { toastMsg: prevToastMsg } = prevProps;

    if (toastMsg !== prevToastMsg && toastMsg !== null) {
      this.setState({ showToast: true });
      setHomeFeedPadding(true);
    }
  }

  reload() {
    const { router, loadFeatured } = this.props;
    if (window.location.pathname === '/') {
      // loadFeatured();
      const { setGlobalLayoutInfo } = this.props;
      setGlobalLayoutInfo('homeScrollTop', true);
    } else {
      router.push('/');
    }
  }

  renderHeader = () => {
    const { router } = this.props;
    const path = router.location.pathname;

    const matches = Titles.filter((reObj) => {
      const { pathRegex } = reObj;
      return path.match(pathRegex) !== null;
    });

    if (matches.length <= 0) {
      return this.renderDefaultHeader();
    }

    const { type, title, borderWidth, ...props } = matches[0];
    switch (type) {
    case 'search':
      return this.renderSearchHeader(props);
    case 'logo':
      return this.renderDefaultHeader();
    case 'text':
    default:
      return this.renderTextHeader(title, borderWidth <= 0, props);
    }
  }

  renderTextHeader = (title, noborder, props) => {
    const { router } = this.props;

    return (
      <View style={styles.border}>
        <HeaderTextTitleBar
          {...props}
          router={router}
          containerStyle={styles.container}
          noborder={noborder}
          title={title}
        />
      </View>
    );
  }

  renderSearchHeader = (props) => {
    const { router } = this.props;

    const callbacks = (router.location.state ? router.location.state.callbacks : {}) || {};
    const searchKeyword = (router.location.state ? router.location.state.searchKeyword : '') || '';

    return (
      <View style={styles.border}>
        <View style={styles.container}>
          <HeaderSearchBar
            {...props}
            router={router}
            searchKeyword={searchKeyword}
            callbacks={callbacks}
          />
        </View>
      </View>
    );
  }

  renderToastHeader = () => {
    const { toastMsg } = this.props;
    const { showToast } = this.state;

    return (
      <View style={styles.border}>
        <View style={[styles.container, { height: Constants.mainHeaderHeight + 6 }]}>
          <View style={styles.toastContainer}>
            <Toast
              show={showToast}
              duration={2000}
              msg={toastMsg}
              hideToastCallback={() => {
                setHomeFeedPadding(false);
                this.setState({ showToast: false });
              }}
            />
          </View>
        </View>
      </View>
    );
  }

  renderDefaultHeader = () => {
    const { auth: { roles, username }, router, history } = this.props;
    const { showToast } = this.state;

    if (showToast) {
      return this.renderToastHeader();
    }

    return (
      <View style={styles.border}>
        <View style={styles.container}>
          <View style={styles.leftContainer}>
            <View style={Styles.touchEffectContainerInHeader}>
              <TouchableHighlight
                style={[Styles.touchEffectInHeader, styles.button]}
                onPress={() => {
                  router.push('/shop/search');
                }}
                underlayColor={Colors.touchEffectColorWhite}
              >
                <HeaderSearchIcon />
              </TouchableHighlight>
            </View>
            {/*(roles && roles.admin) ? (
              <View style={{marginLeft: 20}}>
                <View style={styles.touchEffectContainer}>
                  <TouchableHighlight
                    style={[styles.button, styles.touchEffect]}
                    onPress={() => {
                      router.push('/admin/posts');
                    }}
                    underlayColor={Colors.touchEffectColorWhite}
                  >
                    <SupervisorAccountIconOutlined style={{ color: 'black', fontSize: 24 }} />
                  </TouchableHighlight>
                </View>
              </View>
            ) : (
              null
            )*/}
          </View>

          <View style={styles.titleContainer}>
            <View style={Styles.touchEffectContainer}>
              <TouchableHighlight
                style={[Styles.touchEffect, styles.button]}
                onPress={() => {
                  this.reload();
                }}
                underlayColor={Colors.touchEffectColorWhite}
              >
                <LogoHeartitIcon />
              </TouchableHighlight>
            </View>
          </View>

          <View style={styles.rightContainer}>
            <View style={Styles.touchEffectContainerInHeader}>
              <TouchableHighlight
                style={[Styles.touchEffectInHeader, styles.button]}
                onPress={() => {
                  router.push('/shop/cart');
                }}
                underlayColor={Colors.touchEffectColorWhite}
              >
                <HeaderBagIcon />
              </TouchableHighlight>
            </View>
          </View>
        </View>
      </View>
    );
  }

  render() {
    return this.renderHeader();
  }
}

Navigation.propTypes = {
  auth: PropTypes.object.isRequired,
  loadFeatured: PropTypes.func.isRequired,
};


export default connect(
  (state, { router }) => ({
    auth: state.auth,
    toastMsg: (
      router.location.hasOwnProperty('state')
      && router.location.state
      && router.location.state.hasOwnProperty('toast')) ? router.location.state.toast : null,
  }),
  actions
)(Navigation);
