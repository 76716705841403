import React from 'react';
import {
  Dimensions, StyleSheet, View, Text, 
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';
import { connect } from 'react-redux';

import { browserHistory } from 'react-router';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';

import { Styles, Constants } from '../../constants';
import AlertDialog from '../../components/AlertDialog';
import {
  ShippingAddressEditForm, TouchableHighlightWithContainer,
} from '../../components-v2';


const {
  width: windowWidth,
} = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    paddingTop: 20,
    paddingStart: 20,
    paddingEnd: 20,
  },
  spinnerTextStyle: {
    color: '#FFF',
  },
  arrowButtonContainer: {
    flex: 1,
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    marginStart: 16,
  },
  title: {
  },
  footer: {
    flexDirection: 'row',
    backgroundColor: '#000000',
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  footerButton: {
    flex: 1,
    alignItems: 'center',
    height: 60,
    justifyContent: 'center',
  },
  footerButtonText: {
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    textAlign: 'center',
    color: '#fff',
    marginTop: 20,
    marginBottom: 21,
    height: 19,
    width: (windowWidth - Constants.viewHorizontalPadding * 2) / 2,
  },
});

const webStyles = {
  footer: {
    height: 20 + 19 + 21
    ,
    position: 'fixed',
    bottom: 0,
    start: 0,
    end: 0,
    backgroundColor: '#fff',
  },
};

export class ShippingAddressEditPage extends React.Component {
  editFormRef = React.createRef();

  state = {
    changedData: {},
    alert: {
      open: false,
      title: null,
      content: null,
      buttons: [],
    },
  };

  componentDidMount() {
    const {
      loadShippingAddressList,
      logEventPageview, shippingAddressId,
    } = this.props;

    loadShippingAddressList();

    logEventPageview({
      page_name: 'ShippingAddressEdit',
      page_key: shippingAddressId,
    });
  }

  componentWillUnmount() {
    const { setGlobalLayoutInfo } = this.props;
    setGlobalLayoutInfo('showProgress', false);
    this.setState = () => null;
  }

  getMergedData = (forSave = false) => {
    const {
      shippingAddress: orgShippingAddress,
      shippingAddressEmpty,
    } = this.props;
    const { changedData } = this.state;

    const mergedData = {
      ...orgShippingAddress,
      ...changedData,
      ...(shippingAddressEmpty && { defaultFl: 'y' }),
    };

    const {
      sno,
      defaultFl,
      newDefaultFl,
      shippingTitle,
      shippingName,
      shippingZonecode,
      shippingAddress,
      shippingAddressSub,
      shippingCellPhone,
    } = mergedData;

    const data = {
      sno,
      defaultFl,
      newDefaultFl,
      shippingTitle,
      shippingName,
      shippingZonecode,
      shippingAddress,
      shippingAddressSub,
      shippingCellPhone,
    };

    if (forSave) {
      data.defaultFl = (defaultFl === 'y' || newDefaultFl) ? 'y' : 'n';
      delete data.newDefaultFl;
    }

    return data;
  }

  onPressCancelButton = () => {
    const { router } = this.props;
    router.goBack();
  }

  onPressSaveButton = async () => {
    if (!this.validateInputField()) {
      return;
    }

    const { shippingAddressId, setGlobalLayoutInfo, router } = this.props;

    const params = this.getMergedData(true);

    const errorMessage = '저장 중 오류가 발생하였습니다.\n다시 시도해주시기 바랍니다.';
    setGlobalLayoutInfo('showProgress', true);

    try {
      const { createShippingAddress, updateShippingAddress } = this.props;
      let ret;
      if (shippingAddressId) {
        ret = await updateShippingAddress(shippingAddressId, params);
      } else {
        ret = await createShippingAddress(params);
      }

      setGlobalLayoutInfo('showProgress', false);
      if (ret.success === true) {
        this.setState({
          alert: {
            open: true,
            title: '저장되었습니다.',
            content: null,
            buttons: [
              {
                text: '확인',
                onPress: () => {
                  router.goBack();
                },
              },
            ],
          },
        });
      } else {
        alert(`${errorMessage} (E0${ret.errorCode})`);
      }
    } catch (error) {
      if (error.errorCode) {
        alert(`${errorMessage} (E1${error.errorCode})`);
      } else {
        alert(errorMessage);
      }
      setGlobalLayoutInfo('showProgress', false);
    }
  };

  onDataChanged = changedData => {
    this.setState(({ changedData: prevChangedData }) => ({
      changedData: {
        ...prevChangedData,
        ...changedData,
      },
    }));
  }

  validateInputField() {
    // console.log("ShippingAddressInfoTabNew validateInputField");

    return this.editFormRef.current && this.editFormRef.current.validateInputField();
  }

  renderAlert() {
    const {
      alert: {
        open,
        title,
        content,
        buttons,
      },
    } = this.state;

    return (
      <div>
        <AlertDialog
          open={open}
          title={title}
          content={content}
          buttons={buttons}
        />
      </div>
    );
  }

  render() {
    const { isNewAddress } = this.props;
    const data = this.getMergedData();
    // console.log('ShippingAddressEditScreen data=', data);

    const saveButtonTextStyles = [
      styles.footerButtonText,
    ];

    const saveButtonText = isNewAddress ? '배송지 추가' : '배송지 수정';

    return (
      <View style={styles.container}>
        <View style={styles.scrollView}>
          <ShippingAddressEditForm
            ref={this.editFormRef}
            data={data}
            isNewAddress={isNewAddress}
            onDataChanged={this.onDataChanged}
            showCheckBoxSetDefault
            profileEditStyle={true}
          />
        </View>
        <View style={webStyles.footer}>
          <View style={styles.footer}>
            <TouchableHighlightWithContainer
              containerStyle={styles.footerButton}
              onPress={this.onPressCancelButton}
            >
              <Text style={styles.footerButtonText}>
                취소
              </Text>
            </TouchableHighlightWithContainer>
            <TouchableHighlightWithContainer
              containerStyle={styles.footerButton}
              onPress={this.onPressSaveButton}
            >
              <Text style={saveButtonTextStyles}>
                {saveButtonText}
              </Text>
            </TouchableHighlightWithContainer>
          </View>
        </View>
        {this.renderAlert()}
      </View>
    );
  }
}


export function navigateToEditShippingAddress(shippingAddressId) {
  const path = `/accounts/shippingAddress/edit/${shippingAddressId}`;
  browserHistory.push(path);
}

export function navigateToCreateShippingAddress() {
  const path = '/accounts/shippingAddress/create';
  browserHistory.push(path);
}

export default connect(
  (state, props) => {
    const { params: { shippingAddressId=null } } = props;

    return {
      auth: state.auth,
      shippingAddressId,
      isNewAddress: shippingAddressId === null,
      shippingAddress: state.entities.shippingAddresses[shippingAddressId],
      shippingAddressEmpty: Object.keys(state.entities.shippingAddresses).length === 0,
    };
  },
  actions,
)(ShippingAddressEditPage);
