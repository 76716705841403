/* eslint-disable max-len */
import * as React from 'react';

function SvgIcAppLogoBanner(props) {
  return (
    <svg width={44} height={32} {...props}>
      <defs>
        <path id="icAppLogoBanner_svg__a" d="M.091.058h3.376v4.351H.091z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FF3E3E"
          stroke="#FF3E3E"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.2}
          d="M21.596 25.418c-1.713-.99-3.385-2.047-4.888-3.302a19.096 19.096 0 01-1.092-.976 18.237 18.237 0 01-1.015-1.048c-.326-.36-.628-.74-.92-1.126a17.852 17.852 0 01-.814-1.202c-.074-.121-.138-.2-.209-.324h-.98c.158.278.315.508.49.775.276.43.581.838.898 1.236.323.392.657.776 1.011 1.137a18.155 18.155 0 002.282 1.981c1.613 1.197 3.378 2.143 5.189 2.946l.048-.097z"
        />
        <path
          fill="#FF3E3E"
          stroke="#FF3E3E"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.2}
          d="M21.262 6.84c.275.4.51.83.715 1.274.097.209.17.445.228.675.057.233.097.47.101.703.003.232-.027.462-.117.655a.814.814 0 01-.435.419.812.812 0 01-.597-.016 1.07 1.07 0 01-.484-.406 1.707 1.707 0 01-.248-.622c-.05-.224-.052-.46-.058-.706a7.352 7.352 0 01.123-1.447c.07-.384.18-.76.325-1.118.158.189.307.385.447.589m13.2 1.545c-.047-.259-.1-.514-.166-.771a7.707 7.707 0 00-1.937-3.446 8.06 8.06 0 00-2.585-1.785c-.964-.41-2.02-.649-3.086-.583a8.549 8.549 0 00-2.997.758c-.94.43-1.809 1.062-2.452 1.89-.243.31-.45.645-.62.997a6.687 6.687 0 00-1.198-.972 5.562 5.562 0 00-2.96-.835 6.335 6.335 0 00-2.999.801 7.323 7.323 0 00-3.615 4.966 7.962 7.962 0 00-.149 1.585c.003.328.035.33.08.654h.965c-.047-.33-.077-.334-.078-.665-.006-.465.035-.93.122-1.385a6.9 6.9 0 011.066-2.551c.262-.384.566-.739.898-1.065a6.516 6.516 0 011.102-.855 5.662 5.662 0 012.628-.862 4.895 4.895 0 012.706.617c.46.257.88.588 1.255.968a6.467 6.467 0 00-.387 1.449 8.44 8.44 0 00-.078 1.53c.01.497.08 1.069.406 1.524.159.227.384.416.649.522.264.103.57.122.848.009a1.148 1.148 0 00.608-.592c.118-.262.146-.539.142-.803a3.757 3.757 0 00-.104-.774 4.15 4.15 0 00-.241-.74c-.206-.461-.45-.906-.724-1.333a7.409 7.409 0 00-.57-.777c.188-.377.417-.73.69-1.045.614-.714 1.42-1.241 2.293-1.59a7.806 7.806 0 011.355-.401 7.782 7.782 0 011.394-.163c.912-.019 1.83.207 2.671.59.841.39 1.61.94 2.24 1.617a6.654 6.654 0 011.621 3.008c.055.226.098.46.137.691.075.466.116.94.13 1.414.044 1.903-.33 3.823-.998 5.627-.657 1.812-1.604 3.521-2.754 5.08-1.147 1.564-2.508 2.971-4.004 4.219-3.002 2.495-6.556 4.304-10.301 5.545l.028.105c.966-.22 1.921-.494 2.863-.81a28.376 28.376 0 005.444-2.475 25.001 25.001 0 002.499-1.684 23.575 23.575 0 004.31-4.253 20.556 20.556 0 002.994-5.322c.704-1.925 1.123-3.987 1.028-6.073-.024-.52-.077-1.04-.169-1.556z"
        />
        <path
          fill="#222"
          d="M4.019 12.451h-.88v4.321h.88V14.88h2.136v1.892h.88v-4.321h-.88v1.635H4.019zm6.468 2.513h1.906v-.788h-1.906v-.913h2.039v-.812H9.604v4.373h2.969v-.812h-2.086zm5.763.295l.705-1.698.698 1.698H16.25zm.296-2.808l-1.846 4.37h.894l.353-.838h2.003l.353.838h.902l-1.847-4.37h-.81zm6.522 2.135h-.852v-1.323h.93c.375 0 .657.274.657.638 0 .416-.289.685-.735.685zm.8.537c.504-.215.817-.695.817-1.254 0-.397-.15-.735-.447-1.004a1.52 1.52 0 00-1.068-.414h-1.844v4.37h.89v-1.51h.762l.006.01.981 1.5h1.007l-1.128-1.688.023-.01zm4.218 1.698h.882v-3.558h1.254v-.812h-3.39v.812h1.254z"
        />
        <g transform="translate(39.148 12.394)">
          <mask id="icAppLogoBanner_svg__b" fill="#fff">
            <use xlinkHref="#icAppLogoBanner_svg__a" />
          </mask>
          <path
            fill="#222"
            d="M1.34 4.409h.878V.865h1.249V.058H.091v.807H1.34z"
            mask="url(#icAppLogoBanner_svg__b)"
          />
        </g>
        <path fill="#222" d="M36.029 16.802h.868v-4.351h-.868z" />
      </g>
    </svg>
  );
}

export default SvgIcAppLogoBanner;
