import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ScrollView, Text, StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';
import OrderDetailItem from '../../nativeComponents/OrderDetailItem';
import Spinner from '../../nativeComponents/Spinner';
import CustomDialog from '../../components/CustomDialog';

import Colors from '../../constants/Colors';
import Styles from '../../constants/Styles';
import Constants from '../../constants/Constants';


const settlePriceStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 20,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  containerLast: {
    marginBottom: 0,
  },
  labelText: {
    height: 19,
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 15,
    lineHeight: 19,
  },
  labelTextBold: {
    ...Styles.appleSDGothicNeoBold,
  },
  valueText: {
    height: 19,
    ...Styles.appleSDGothicNeoBold,
    fontSize: 15,
    lineHeight: 19,
  },
  valueTextLarge: {
    height: 30,
    ...Styles.appleSDGothicNeoSemiBold,
    fontSize: 24,
    lineHeight: 30,
  },
  smallLineContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  smallLabel: {
    height: 16,
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 13,
    lineHeight: 16,
    opacity: 0.5,
  },
  smallValue: {
    height: 16,
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 13,
    lineHeight: 16,
    opacity: 0.5,
  },
});

const addressStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 20,
  },
  labelText: {
    width: 69,
    height: 19,
    ...Styles.appleSDGothicNeoRegular,
    lineHeight: 19,
    fontSize: 15,
  },
  valueText: {
    flex: 1,
    ...Styles.appleSDGothicNeoBold,
    lineHeight: 19,
    fontSize: 15,
  },
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  goodsSeparator: {
    height: 1,
    backgroundColor: Colors.lightGray,
  },
  sectionSeparator: {
    height: 10,
    backgroundColor: Colors.semiGray,
  },
  sectionContainer: {
    marginVertical: 20,
    marginHorizontal: Constants.viewHorizontalPadding,
  },
  sectionHeaderContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  sectionHeaderText: {
    height: 21,
    ...Styles.appleSDGothicNeoBold,
    fontSize: 18,
    lineHeight: 21,
  },
  orderNumberText: {
    marginStart: 20,
    height: 21,
    ...Styles.SFUITextMedium,
    fontSize: 18,
    lineHeight: 21,
  },
  orderItemContainer: {
    marginVertical: 20,
  },
});

const CONFIRM_MSG_CANCEL = '주문을 취소하시겠습니까?';
const CONFIRM_MSG_CANCEL_ALL = '입금대기중에는 개별 상품 취소가 불가능합니다. 전체 주문을 취소하시겠습니까?';

function getPrices(goods) {
  let totalGoodsPrice = 0;
  let totalMileage = 0;
  let totalCoupon = 0;
  let totalRefundMileage = 0;
  let totalRefundPrice = 0;

  goods.forEach(good => {
    const {
      orderStatus,
      goodsPrice, optionPrice, goodsCnt,
      totalDivisionCouponOrderDcPrice,
      totalDivisionUseMileage,
      refundUseMileage,
      refundDeliveryUseMileage,
      refundPrice,
    } = good;

    if (orderStatus.startsWith('c')) {
      return;
    }

    try {
      totalGoodsPrice += (parseFloat(goodsPrice) + parseFloat(optionPrice)) * parseInt(goodsCnt, 10);
      totalMileage += parseFloat(totalDivisionUseMileage);
      totalCoupon += parseFloat(totalDivisionCouponOrderDcPrice);
      if (refundUseMileage) {
        totalRefundMileage += parseFloat(refundUseMileage);
      }
      if (refundDeliveryUseMileage) {
        totalRefundMileage += parseFloat(refundDeliveryUseMileage);
      }
      if (refundPrice) {
        totalRefundPrice += parseFloat(refundPrice);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  });

  return {
    totalGoodsPrice,
    totalMileage,
    totalCoupon,
    totalRefundMileage,
    totalRefundPrice,
  };
}

function renderReceiverAddressLine(label, value, last = false) {
  return (
    <View style={[addressStyles.container, last && { marginBottom: 0 }]}>
      <Text style={addressStyles.labelText}>
        {label}
      </Text>
      <Text style={addressStyles.valueText}>
        {value}
      </Text>
    </View>
  );
}

function renderReceiverAddress(orderInfo) {
  if (!orderInfo) {
    return null;
  }

  const {
    receiverName, receiverCellPhone,
    receiverAddress, receiverAddressSub,
    orderMemo,
  } = orderInfo;
  return (
    <View style={styles.sectionContainer}>
      {renderReceiverAddressLine('받는사람', receiverName)}
      {renderReceiverAddressLine('전화번호', receiverCellPhone)}
      {renderReceiverAddressLine('주소', receiverAddress + ' ' + receiverAddressSub)}
      {renderReceiverAddressLine('요청사항', orderMemo, true)}
    </View>
  );
}

class OrderDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
    };
  }

  componentDidMount() {
    const { order } = this.props;

    if (!order) {
      this.loadData();
    }
  }

  componentDidUpdate(prevProps) {
    const { isGodoLoggedIn } = this.props;

    if (!prevProps.isGodoLoggedIn && isGodoLoggedIn) {
      this.loadData();
    }
  }

  loadData = () => {
    const { getOrderDetail, orderNo } = this.props;

    getOrderDetail(orderNo);
  }

  openCancelDialog = () => {
    this.setState({
      dialogOpen: true,
    });
  }

  orderCancel = async () => {
    const { cancelUserOrder, orderNo } = this.props;

    try {
      await cancelUserOrder(orderNo);
      this.loadData();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }


  renderSettlePriceLine = (label, value, prefix, setLast) => (
    <View style={[settlePriceStyles.container, setLast && settlePriceStyles.containerLast]}>
      <Text style={[
        settlePriceStyles.labelText,
        setLast && settlePriceStyles.labelTextBold,
      ]}
      >
        {label}
      </Text>
      <NumberFormat
        value={value}
        displayType="text"
        thousandSeparator
        decimalScale={0}
        prefix={prefix}
        renderText={
          text => (
            <Text style={[
              settlePriceStyles.valueText,
              setLast && settlePriceStyles.valueTextLarge,
            ]}
            >
              {text}
              <Text style={[
                Styles.appleSDGothicNeoBold,
                setLast && Styles.appleSDGothicNeoSemiBold,
              ]}
              >
                원
              </Text>
            </Text>
          )}
      />
    </View>
  )


  renderSettlePrice = () => {
    const { order } = this.props;
    const {
      settlePrice, settleName,
    } = order;
    const {
      totalGoodsPrice,
      totalMileage,
      totalCoupon,
      totalRefundMileage,
      totalRefundPrice,
    } = getPrices(order.goods);
    const totalDelivery = order.orderDeliveryInfo.deliveryPolicyCharge;

    let { paymentDt } = order.goods[0];
    if (paymentDt === '0000-00-00 00:00:00') {
      paymentDt = '입금 대기중';
    } else {
      paymentDt = paymentDt.slice(0, -3).replace(/-/gi, '.');
    }

    return (
      <View style={styles.sectionContainer}>
        {this.renderSettlePriceLine('총 상품 금액', totalGoodsPrice, '', false)}
        {this.renderSettlePriceLine('총 배송비', totalDelivery, '+ ', false)}
        {this.renderSettlePriceLine('쿠폰 할인', totalCoupon, '- ', false)}
        {this.renderSettlePriceLine('적립금 사용', totalMileage, '- ', false)}
        {this.renderSettlePriceLine('총 결제 금액', settlePrice, '', true)}

        <View style={{ height: 4 }} />

        <View style={settlePriceStyles.smallLineContainer}>
          <Text style={settlePriceStyles.smallLabel}>
            {'결제 수단 : '}
          </Text>
          <Text style={settlePriceStyles.smallValue}>
            {settleName}
          </Text>
        </View>
        <View style={settlePriceStyles.smallLineContainer}>
          <Text style={settlePriceStyles.smallLabel}>
            {'결제 일시 : '}
          </Text>
          <Text style={settlePriceStyles.smallValue}>
            {paymentDt}
          </Text>
        </View>

        { totalRefundPrice > 0 && (
          this.renderSettlePriceLine('환불 금액', totalRefundPrice, '', false)
        )}
        { totalRefundMileage > 0 && (
          this.renderSettlePriceLine('적립금 환불', totalRefundMileage, '', false)
        )}
      </View>
    );
  }

  onPressProduct = goodsNo => {
    const { router } = this.props;
    router.push({
      pathname: `/product/${goodsNo}`,
    });
  }

  renderGoods = (goods, index) => {
    const { auth, historySwitch, orderNo } = this.props;

    return (
      <GridListTile
        key={index.toString()}
      >
        {index > 0 && (<View style={styles.goodsSeparator} />)}
        <View style={styles.orderItemContainer}>
          <OrderDetailItem
            orderNo={orderNo}
            good={goods}
            auth={auth}
            historySwitch={historySwitch}
            cancelUserOrder={this.openCancelDialog}
            onPressProduct={this.onPressProduct}
          />
        </View>
      </GridListTile>
    );
  }


  render() {
    const { order, orderNo } = this.props;
    const { dialogOpen } = this.state;

    if (!order) {
      return (<Spinner />);
    }
    const orderInfo = order.orderInfo || order;

    return (
      <ScrollView style={styles.container}>
        <View style={[styles.sectionHeaderContainer, { marginBottom: 20 }]}>
          <Text style={styles.sectionHeaderText}>
            주문 번호
          </Text>
          <Text style={styles.orderNumberText}>
            {orderNo}
          </Text>
        </View>

        <View style={styles.sectionSeparator} />

        <View style={styles.sectionHeaderContainer}>
          <Text style={styles.sectionHeaderText}>
            주문 상품 정보
          </Text>
        </View>

        {order.goods.length >= 0 && (
          <GridList
            cellHeight="auto"
            cols={1}
            spacing={0}
          >
            {order.goods.map((goods, index) => this.renderGoods(goods, index))}
          </GridList>
        )}

        <View style={styles.sectionSeparator} />

        <View style={styles.sectionHeaderContainer}>
          <Text style={styles.sectionHeaderText}>
            배송지 정보
          </Text>
        </View>

        {renderReceiverAddress(orderInfo)}

        <View style={styles.sectionSeparator} />

        <View style={styles.sectionHeaderContainer}>
          <Text style={styles.sectionHeaderText}>
            최종 결제 금액
          </Text>
        </View>

        {this.renderSettlePrice()}

        <CustomDialog
          dialogOpen={dialogOpen}
          key_={orderNo}
          description={(order.goods.length > 1) ? CONFIRM_MSG_CANCEL_ALL : CONFIRM_MSG_CANCEL}
          confirmText="네"
          cancelText="아니오"
          cancelColor="primary"
          confirmColor="default"
          onCancel={() => {
            this.setState({ dialogOpen: false });
          }}
          onConfirm={() => {
            this.setState({ dialogOpen: false });
            this.orderCancel();
          }}
          onClose={() => {
            this.setState({ dialogOpen: false });
          }}
        />
      </ScrollView>
    );
  }
}


export default connect(
  (state, ownProps) => {
    const { orderNo } = ownProps.params;
    // eslint-disable-next-line no-prototype-builtins
    const isOrderInList = state.entities.userOrderList.hasOwnProperty(orderNo);
    let order = null;
    try {
      order = (isOrderInList) ? state.entities.userOrderList[orderNo] : state.entities.orderDetails[orderNo];
    // eslint-disable-next-line no-empty
    } catch (ignore) {
    }

    return {
      auth: state.auth,
      // eslint-disable-next-line no-prototype-builtins
      isGodoLoggedIn: state.entities.shop.hasOwnProperty('sessionId'),
      orderNo,
      order,
    };
  },
  actions,
)(OrderDetailPage);
