import React from 'react';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

// eslint-disable-next-line import/no-extraneous-dependencies
import { actions } from 'shared';
import { Styles, Colors, Constants }from '../../../constants';

import FindIdTab from './FindIdTab';
import FindPasswordTab from './FindPasswordTab';

const TabBarHeight = 40;

const useStyles = () => ({
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: Constants.viewHorizontalPadding,
    paddingRight: Constants.viewHorizontalPadding,
    paddingTop: 20,
    paddingBottom: 0,
    maxWidth: 960,
  },
  tabs: {
    backgroundColor: '#fff',
    height: TabBarHeight,
    minHeight: TabBarHeight,
    marginLeft: 0.5 / 2,
    marginRight: 0.5 / 2,
  },
  indicator: {
    backgroundColor: '#000',
    height: 0,
  },
  tab: {
    padding: 0,
    height: TabBarHeight,
    minHeight: TabBarHeight,
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.3,
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderColor: '#000',
    overflow: 'hidden',
    color: '#000',
    marginLeft: - 0.5 / 2,
    marginRight: - 0.5 / 2,
  },
  tabWrapper: {
    color: '#000',
    fontSize: 16,
    lineHeight: 19.2,
    ...Styles.pretendard600,
  },
  tabSelected: {
    opacity: 1,
  },
  rippleVisible: {
    opacity: 1,
    color: Colors.touchEffectColorWhite,
    animation: '0ms',
  },
});

const stylesHtml = {
  appBar: {
    top: '0px',//Constants.mainHeaderHeight,
    shadows: ['none'],
  },
  appBarWithSmartBanner: {
    top: '0px',//Constants.mainHeaderHeight + Constants.smartBannerHeight,
    shadows: ['none'],
  },
  container: {
    height : `calc(100vh - ${Constants.mainHeaderHeight}px)`,
  },
  containerWithSmartBanner: {
    height : `calc(100vh - ${Constants.mainHeaderHeight + Constants.smartBannerHeight}px)`,
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}


class AccountRecoveryPage extends React.Component {
  constructor(props) {
    super(props);

    const { router } = props;
    let findPassword = false;
    // eslint-disable-next-line no-prototype-builtins
    if (router.location.state && router.location.state.hasOwnProperty('findPassword')) {
      // eslint-disable-next-line prefer-destructuring
      findPassword = router.location.state.findPassword;
    }
    const initialIdx = (findPassword) ? 1 : 0;
    const initialTab = (findPassword) ? 'password' : 'id';

    this.state = {
      index: initialIdx,
      currentTab: initialTab,
      routes: [
        {
          key: 'id',
          title: '아이디 찾기',
        },
        {
          key: 'password',
          title: '비밀번호 찾기',
        },
      ],
    };
  }

  componentDidMount() {
    const { logEventPageview } = this.props;

    logEventPageview({ page_name: 'AccountRecovery' });
  }

  componentWillUnmount() {
  }

  onChange = (event, index) => {
    const { routes } = this.state;
    this.setState({
      index,
      currentTab: routes[index].key,
    });
  }

  renderFindIdTab = () => {
    const { currentTab } = this.state;
    const focused = currentTab === 'id';
    const { router } = this.props;


    const props = {
      focused,
      router,
    };

    return (
      <FindIdTab
        {...props}
      />
    );
  };

  renderFindPassword = () => {
    const { currentTab } = this.state;
    const focused = currentTab === 'password';
    const { router } = this.props;

    const props = {
      focused,
      router,
    };
    return (
      <FindPasswordTab
        {...props}
      />
    );
  };

  render() {
    const { classes, showSmartBanner } = this.props;
    const { routes, index } = this.state;
    const appBarStyle = showSmartBanner ? stylesHtml.appBarWithSmartBanner : stylesHtml.appBar;
    const containerStyle = showSmartBanner ? stylesHtml.containerWithSmartBanner : stylesHtml.container;

    return (
      <div
        hi-key={"test"}
        style={containerStyle}
        className={classes.container}
      >
        <AppBar position="sticky" style={appBarStyle} elevation={0}>
          <Tabs
            value={index}
            onChange={this.onChange}
            variant="fullWidth"
            classes={{
              root: classes.tabs,
              indicator: classes.indicator,
            }}
          >
            {routes.map(route => {
              return (
                <Tab
                  key={route.key}
                  label={route.title}
                  classes={{ root: classes.tab, selected: classes.tabSelected, wrapper: classes.tabWrapper }}
                  TouchRippleProps={{ classes: { rippleVisible: classes.rippleVisible } }}
                />
              );
            })}
          </Tabs>
        </AppBar>

        <TabPanel value={index} index={0} style={{ }}>
          {this.renderFindIdTab()}
        </TabPanel>
        <TabPanel value={index} index={1} style={{  }}>
          {this.renderFindPassword()}
        </TabPanel>
      </div>
    );
  }
}


export default withStyles(useStyles)(connect(
  state => ({
    showSmartBanner: state.globalLayoutInfo.showSmartBanner,
  }),
  actions,
)(AccountRecoveryPage));
