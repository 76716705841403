import React from 'react';
import {
    StyleSheet, View, ScrollView, Text,
} from 'react-native';
import { connect } from 'react-redux';

import { actions } from 'shared';
import { Snackbar } from '@material-ui/core';
import { Constants, Styles } from '../../../constants';
import { TouchableHighlightWithContainer } from '../../../components-v2';

import JoinForm from '../JoinForm';

import { showLegalPolicy } from '../LegalPolicyOverlayPage';

import _styles from '../../../css/modules/auth.module.css';

const styles = StyleSheet.create({
  container: {
  },
  listContainer: {
    paddingHorizontal: Constants.viewHorizontalPadding,
  },
  bottomButtonContainer: {
    position: 'fixed',
    start: 0,
    end: 0,
    bottom: 0,
    backgroundColor: '#000',
    flexDirection: 'row',
  },
  buttonContainerStyle: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 20,
    paddingBottom: Constants.bottomMargin,
  },
  bottomButtonText: {
    textAlign: 'center',
    ...Styles.pretendard600,
    fontSize: 16,
    lineHeight: 19.2,
    color: '#fff',
    opacity: 0.5,
  },
  bottomButtonTextEnabled: {
    opacity: 1,
  },
});


class JoinWithEmailPage extends React.Component {
  state = {
    snackbarOpen: false,
    snackbarMsg: '',
  };

  joinForm = null;

  componentDidMount() {
    const { logEventPageview } = this.props;

    logEventPageview({ page_name: 'JoinWithEmail' });
  }

  async handleJoin() {
    const { router, signup } = this.props;
    const { name, email, phone, phoneVerified, password,
      termAgreed, privacyAgreed, marketingAgreed } = this.state;
    const username = email;

    this.setState({ requestJoinOngoing: true });

    try {
      const { _id, token: bearerToken } = await signup({
        username,
        email,
        password,
        passwordCheck: password,
        name,
        tel: phone,
        telVerified: phoneVerified,
        termAgreed,
        privacyAgreed,
        marketingAgreed,
      });
      if (bearerToken) {
        firebase.analytics().logEvent('sign_up', {
          username,
          email,
          platform: 'web',
        });

        // Facebook Pixel event 전송
        fbq('track', 'CompleteRegistration', {
          content_name: 'email',
          currency: 'KRW',
          status: true,
          value: 0,
        });
      }
      router.push('/accounts/join/done');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      if (e.status === 400) {
        const yes = confirm('이미 가입된 이메일입니다.\n비밀번호 찾기로 이동할까요?');
        if (yes) {
          router.push('/accounts/login/email/findPassword');
        }
        return;
      }

      let msg = '가입에 실패했습니다. 잠시 후 다시 시도하세요.';
      if (e.status === 401) {
        msg = '휴대폰번호를 인증해야 합니다.';
      }

      const newState = {
        snackbarOpen: false,
        snackbarMsg: msg,
      };
      this.setState(newState);
    }
  }

  onChangeInputData(inputData) {
    let isEnabledJoinButton = false;

    // console.log(`onChangeInputData ${JSON.stringify(inputData)}`);
    if (inputData.email
      && inputData.password
      && inputData.name
      && inputData.phone
      && inputData.phoneVerified
      && inputData.termAgreed
      && inputData.privacyAgreed) {
      isEnabledJoinButton = true;
    }

    this.setState({
      ...inputData,
      isEnabledJoinButton
    });
  }

  renderInputForm() {
    const { router } = this.props;
    return (
      <JoinForm
        onRef={ref => { this.joinForm = ref; }}
        onChange={inputData => this.onChangeInputData(inputData)}
        onPressTerms={type => {
          showLegalPolicy(this, type);
        }}
        />
    );
  }

  renderBottomButton() {
    const { isEnabledJoinButton } = this.state;
    let onPress = null;

    const bottomButtonContainerStyles = [
      styles.bottomButtonContainer
    ];

    const buttonStyles = [
      styles.bottomButtonText,
    ];

    if (isEnabledJoinButton) {
      buttonStyles.push(styles.bottomButtonTextEnabled);
      onPress = () => {
        if (!this.joinForm.validateInput()) {
          return;
        }

        this.handleJoin();
      };
    }

    return (
      <div className={_styles.bottomButtonContainer}>
        <View>
          <TouchableHighlightWithContainer
            containerStyle={styles.buttonContainerStyle}
            onPress={onPress}
            >
            <Text style={buttonStyles}>가입하기</Text>
          </TouchableHighlightWithContainer>
        </View>
      </div>
    );
  }

  render() {
    const { showSmartBanner } = this.props;

    return (
      <div>
        <View style={styles.container}>
          <ScrollView
            bounces={false}
            style={[styles.listContainer, showSmartBanner && { paddingBottom: Constants.smartBannerHeight }]}
          >
            {this.renderInputForm()}
          </ScrollView>

          <Snackbar
            autoHideDuration={2000}
            message={this.state.snackbarMsg}
            onClose={() => this.setState({ snackbarOpen: false })}
            open={this.state.snackbarOpen}
            style={{ textAlign: 'center' }}
          />
        </View>
        {this.renderBottomButton()}
      </div>
    );
  }
}


export default connect(
  (state) => {
    return {
      showSmartBanner: state.globalLayoutInfo.showSmartBanner,
    };
  },
  actions,
)(JoinWithEmailPage);
