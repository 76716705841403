import { normalize } from 'normalizr';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';


export * from '../actions';


// admin actions for MD curations
export function adminLoadMdCurations() {
  const key = 'adminMdCurations';

  return createFetchAction({
    type: 'ADMIN_LOAD_MD_CURATIONS',
    endpoint: '/api/v2/admin/mdCurations',
    transform: ({ json }) => normalize(json, schemas.adminMdCurations),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: 'REFRESH' },
    },
  });
}

// admin MD curations sorting
export function adminUpdateMdCurations(mdCurations) {

  return createFetchAction({
    type: 'ADMIN_UPDATE_MD_CURATIONS',
    endpoint: '/api/v2/admin/mdCurations',
    method: 'PUT',
    body: mdCurations,
    transform: ({ json }) => normalize(json, schemas.adminMdCuration),
    success: {
      pagination: {
        key: 'adminMdCurations',
        type: 'PREPEND',
      }
    },
  });
}

// admin MD curations sorting with preorders
export function adminUpdateFullCurations(mdCurations) {

  return createFetchAction({
    type: 'ADMIN_UPDATE_FULL_CURATIONS',
    endpoint: '/api/v2/admin/mdCurations/full',
    method: 'PUT',
    body: mdCurations,
    transform: ({ json }) => normalize(json, schemas.adminMdCuration),
    success: {
      pagination: {
        key: 'adminMdCurations',
        type: 'PREPEND',
      }
    },
  });
}


export function adminGetMdCuration(mdCurationId) {
  return createFetchAction({
    type: 'ADMIN_GET_MD_CURATION',
    endpoint: `/api/v2/admin/mdCurations/${mdCurationId}`,
    transform: ({ json }) => normalize(json, schemas.adminMdCuration),
  });
}

export function adminCreateMdCuration(mdCuration) {
  return createFetchAction({
    type: 'ADMIN_CREATE_MD_CURATION',
    endpoint: '/api/v2/admin/mdCurations',
    method: 'POST',
    body: mdCuration,
    transform: ({ json }) => normalize(json, schemas.adminMdCuration),
    success: () => ({
      pagination: {
        key: 'adminMdCurations',
        type: 'PREPEND',
      },
    }),
  });
}

export function adminUpdateMdCuration(mdCurationId, mdCuration) {
  return createFetchAction({
    type: 'ADMIN_UPDATE_MD_CURATION',
    endpoint: `/api/v2/admin/mdCurations/${mdCurationId}`,
    method: 'PUT',
    body: mdCuration,
    transform: ({ json }) => normalize(json, schemas.adminMdCuration),
  });
}

export function adminDeleteMdCuration(mdCurationId) {
  const payload = { entities: { adminMdCurations: {} } };
  payload.entities.adminMdCurations[mdCurationId] = null;
  return createFetchAction({
    type: 'ADMIN_DELETE_MD_CURATION',
    endpoint: `/api/v2/admin/mdCurations/${mdCurationId}`,
    method: 'DELETE',
    success: {
      payload,
    },
  });
}
