import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyleSheet, Dimensions, View } from 'react-native';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Constants from '../../../constants/Constants';

import '../../../css/carousel.css';

const windowLayout = {
  width: Dimensions.get('window').width,
};

const imageHeight = Math.min(windowLayout.width, Constants.maxWidth);

const styles = StyleSheet.create({
  container: {
    aspectRatio: 1,
    height: imageHeight,
  },
  image: {
    aspectRatio: 1,
    resizeMode: 'contain',
  },
});

const indicatorStyles = {
  background: 'rgb(204, 204, 204)',
  width: 6,
  height: 6,
  borderRadius: 3,
  display: 'inline-block',
  margin: '0 2px 0 2px',
};

const style = document.createElement('style');
document.head.appendChild(style);
style.sheet.insertRule(`.carousel .slider li { background: none; height: ${imageHeight}px; }`, 0);

export default class ProductImages extends React.PureComponent {
  renderIndicator = (onClickHandler, isSelected, index, label) => {
    if (isSelected) {
      return (
        <li
          style={{ ...indicatorStyles, background: '#000' }}
        />
      );
    }
    return (
      <li
        style={indicatorStyles}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        role="button"
        tabIndex={0}
        title={`${label} ${index + 1}`}
        aria-label={`${label} ${index + 1}`}
      />
    );
  }

  render() {
    const { product } = this.props;
    const { imagesDetail } = product;

    const imageList = (imagesDetail || []).map((image, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`img_${index}`}>
        <img alt="" referrerPolicy="no-referrer" src={image} />
      </div>
    ));


    return (
      <View style={styles.container}>
        <Carousel
          renderThumbs={() => []}
          renderIndicator={this.renderIndicator}
          statusFormatter={() => ''}
          swipeable
        >
          {imageList}
        </Carousel>
      </View>
    );
  }
}
