import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
// import { getChangedUrl } from 'react-shared/util/urlFn';
import dayjs from 'dayjs';
// import InstagramProfile from './InstagramProfile';
import { loadEntities } from 'react-shared';
import * as adminActions from 'react-shared/adminActions';

const styles = {
  container: {
    border: '5px solid #fff',
    padding: '5px 15px',
    textAlign: 'center',
  },
  headerContainer: {
    height: '40px',
    marginBottom: '12.5px',
    width: '100%',
  },
  headerLeft: {
    display: 'inline-block',
    float: 'left',
    height: '40px',
    marginBottom: '12.5px',
    textAlign: 'left',
    width: '100%',
  },
  headerImage: {
    borderRadius: '50%',
    height: '40px',
    width: '40px',
  },
  username: {
    color: 'black',
    fontFamily: 'Avenir Next',
    fontSize: '16px',
    marginLeft: '7.5px',
  },
  headerRight: {
    display: 'inline-block',
    height: '40px',
    lineHeight: '40px',
    // marginBottom: '12.5px',
    textAlign: 'right',
    width: '100%',
  },
  date: {
    color: '#909090',
    fontSize: '13px',
  },
  dateSuffix: {
    color: '#909090',
    fontSize: '8px',
    lineHeight: '36px',
    verticalAlign: 'top',
  },
  mainImage: {
    marginBottom: '14px',
    width: '100%',
  },
  abstract: {
    color: 'black',
    fontSize: '10px',
    textAlign: 'left',
    textDecoration: 'none',
  },
  link: {
    color: 'black',
    textAlign: 'right',
  },
  arrow: {
    height: '7px',
    marginLeft: '5px',
    width: '15px',
  },
  offline: {
    color: '#000080',
  },
  online: {
    color: '#FF0000',
  },
};

// export default
class Editorial extends Component {
  static renderEditButton(editorialId) {
    return (
      <Link className="btn btn-primary" to={`/admin/editorials/${editorialId}`}>
        <span className="glyphicon glyphicon-edit" />
      </Link>
    );
  }

  renderDeleteButton(editorialId) {
    return (
      <button
        type="button"
        className="btn btn-danger"
        onClick={
          () => confirm('Are you sure to delete this editorial post?') && this.props.adminDeleteEditorial(editorialId) //eslint-disable-line
        }
      >
        <span className="glyphicon glyphicon-trash" />
      </button>
    );
  }

  renderCaption(editorialId) {
    const captionStyle = { height: 30 };
    return (
      <div style={captionStyle}>
        {Editorial.renderEditButton(editorialId)}
        {this.renderDeleteButton(editorialId)}
      </div>
    );
  }

  static renderDate(date) {
    if (!date) {
      return '없음';
    }
    return dayjs(date).format('YYYY-MM-DD (HH:mm)');
  }

  render() {
    const {
      editorial: {
        id,
        title,
        url,
        created,
        thumbnail,
        open,
        close,
        visible,
      },
    } = this.props;
    // const convertedUrl = getChangedUrl(url, window.location.protocol, window.location.host, null, {
    //   utm_source: window.location.hostname,
    //   utm_medium: 'heartit-editorial-list',
    //   utm_campaign: 'heartit-editorial-list',
    //   agent: 'web',
    // });
    return (
      <div style={styles.container}>
        <img src={thumbnail.secureUrl} style={styles.mainImage} alt="" />
        <div style={styles.headerLeft}>
          <div>
            제목:
            {title}
          </div>
          <div>
            URL:
            <a href={url} target="_blank">{url}</a>
          </div>
          <div>
            고유아이디:
            {id}
          </div>
          <div>
            등록일:
            {Editorial.renderDate(created)}
          </div>
          <div>
            게시시작일:
            {Editorial.renderDate(open)}
          </div>
          <div>
            게시종료일:
            {Editorial.renderDate(close)}
          </div>
          <div>
            공개여부:
            {visible ? <span style={styles.online}>true</span> : <span style={styles.offline}>false</span>}
          </div>
        </div>
        <div style={styles.headerRight}>
          {this.renderCaption(id)}
          {/* <button type="button" className="btn btn-primary">
            <span className="glyphicon glyphicon-edit" />
          </button>
          <button type="button" className="btn btn-danger">
            <span className="glyphicon glyphicon-trash" />
          </button> */}
        </div>
      </div>
    );
  }
}

Editorial.propTypes = {
  editorial: PropTypes.object.isRequired,
};

export const mapStateToProps = state => ({
  ...loadEntities(state, 'editorials', 'editorials'),
});

export default connect(
  mapStateToProps,
  adminActions,
)(Editorial);
