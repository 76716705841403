import * as React from 'react';

function SvgIcKakao(props) {
  return (
    <svg
      aria-hidden="true"
      width="1.08em"
      height="1em"
      style={{
        msTransform: 'rotate(360deg)',
        WebkitTransform: 'rotate(360deg)',
      }}
      viewBox="0 0 1792 1664"
      transform="rotate(360)"
      {...props}
    >
      <path
        d="M896 12q243 0 449.5 94.5t326.5 257T1792 718t-120 355-326 257.5-450 94.5q-77 0-159-11-356 247-379 250-11 4-21-1-4-3-6-8t-2-9v-4q6-39 91-325-193-96-306.5-254.5T0 718q0-192 120-354.5t326.5-257T896 12zM442 905V598h79q16 0 33.5-11.5T572 555t-16-33.5-31-13.5H265q-17 0-32 10t-15 33 16 35 36 12h79v307q0 25 12 41t34 16 34.5-18 12.5-39zm433 57q37-14 23-71-4-13-56.5-155.5T781 573q-27-65-74-65-51 0-79 65-8 17-61 165.5T512 891q-7 9-3.5 33t21.5 33q20 9 39 2t26-21l24-66h174q11 36 16 47 5 10 11 20t22 19.5 33 3.5zm269 0q21 0 38.5-13t17.5-35-16-34.5-40-12.5h-111V565q0-21-12.5-39T986 508t-34 16-12 41v340q0 25 12 41t34 16q1 0 3.5-.5t3.5-.5 3.5.5 3.5.5h144zm394-7q17-16 17-36.5t-13-36.5q-6-9-126-169 58-59 117-118 15-15 18.5-35.5T1540 521q-17-16-36.5-13t-36.5 20q-3 3-26 26.5t-59.5 60.5-64.5 66V565q0-21-12.5-39t-34.5-18-34 16-12 41v340q0 25 12 41t34 16 34.5-18 12.5-39v-91q4-4 15-15.5t18-18.5q66 90 118 159 14 19 32.5 24.5t37.5-8.5zM646 782l61-179 60 179H646z"
        fill="#626262"
      />
    </svg>
  );
}

export default SvgIcKakao;

