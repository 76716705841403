import React from 'react'
import PropTypes from 'prop-types';
import {
  Text,
  StyleSheet, View, FlatList,
} from 'react-native';

import { Styles, Colors, Constants } from '../constants';
import CheckBoxListItem from './CheckBoxListItem';


const styles = StyleSheet.create({
  container: {
  },
  itemContainer: {
    backgroundColor: 'white',
  },
  itemIndexContainer: {
    paddingStart: Constants.viewHorizontalPadding,
    paddingEnd: Constants.viewHorizontalPadding + 8,
    paddingTop: 19.5,
    paddingBottom: 2.5,
  },
  itemIndexText: {
    ...Styles.pretendard400,
    fontSize: 14,
    lineHeight: 18,
    color: Colors.defaultGray08,
  },
  itemDataContainer: {
  },
  itemContentContainer: {
  },
  itemText: {
    ...Styles.pretendard400,
    fontSize: 16,
    lineHeight: 19.2,
  },
  itemTextChecked: {
    ...Styles.pretendard600,
  },
  searchKeyword: {
    ...Styles.pretendard600,
  },
  itemSeparator: {
    marginStart: Constants.viewHorizontalPadding,
    marginEnd: Constants.viewHorizontalPadding + 8,
    height: 1,
    backgroundColor: Colors.defaultGray02,
  }
});


class ListWithCheckBox extends React.Component {

  state = {
    isIcon : false
  };

  getData() {
    const {
      itemList,
      searchKeyword,
      enableIndex,
      sortItemByName,
    } = this.props;

    const filteredItemList = itemList.filter((item) => {
      const name = item.name ? item.name.toUpperCase() : "";
      const searchKeywordUpper = searchKeyword.toUpperCase();
      return name.includes(searchKeywordUpper);
    });

    if (enableIndex) {
      const indexList = [];
      filteredItemList.forEach((item) => {
        const firstChar = item.name.toUpperCase()[0];
        if (!indexList.some((e) => e === firstChar)) {
          indexList.push(firstChar);
        }
      });

      indexList.forEach((item, index) => {
        filteredItemList.push({ name: item, type: 'index' });
      });
    }


    if (sortItemByName) {
      // const x = filteredItemList.sort((a, b) => a.name > b.name || ((a.name == b.name) && (a.type > b.type)));
      const x = filteredItemList.sort((a, b) => a.name.localeCompare(b.name) || ((a.name === b.name) && (a.type.localeCompare(b.type))));
      console.log(`filteredItemList=${JSON.stringify(filteredItemList)}`);
      console.log(`filteredItemList=${JSON.stringify(x)}`);
      return x;
    }

    return filteredItemList;
  }

  onSelect(item) {
    const {
      selectedItemList,
      onChangeSelectedItemList,
    } = this.props;

    // console.log(`onSelect(${item})`);

    let newSelectedItemList = [...selectedItemList];

    if (this.isChecked(item)) {
      newSelectedItemList = selectedItemList.filter((e) => e.id !== item.id);
    } else {
      newSelectedItemList.push(item);
    }

    onChangeSelectedItemList(newSelectedItemList);
  }

  isChecked(item) {
    const {
      selectedItemList
    } = this.props;

    return selectedItemList.some((e) => e.id === item.id);
  }

  renderItemName(itemName, checked) {
    const {
      searchKeyword
    } = this.props;

    const itemNameWords = [];

    if (searchKeyword === '') {
        itemNameWords.push(itemName);

    } else {
      const searchKeywordUpper = searchKeyword.toUpperCase();
      const words = itemName.toUpperCase().split(searchKeywordUpper);
      let position = 0;

      words.forEach((word, index) => {
        if (index > 0) {
          const length = searchKeyword.length;
          const wordToAdd = itemName.substring(position, position + length);
          position += length;

          itemNameWords.push((
            <Text key={`${index}`} style={styles.searchKeyword}>{wordToAdd}</Text>
          ));
        }

        const length = word.length;
        const wordToAdd = itemName.substring(position, position + length);
        position += length;

        itemNameWords.push(wordToAdd);
      });
    }

    const itemTextStyles = [styles.itemText];
    if (checked) {
      itemTextStyles.push(styles.itemTextChecked);
    }

    return (
      <Text style={itemTextStyles} children={itemNameWords} />
    );
  }

  renderItem(item) {
    const { ListItemComponent } = this.props;

    const itemContainerStyles = [
      styles.itemContainer,
      styles.itemDataContainer,
    ];

    if (ListItemComponent) {
      const component = ListItemComponent(item);
      if (component) {
        return (
          <View style={itemContainerStyles}>
            {component}
            {/* <View style={[styles.itemSeparator, ListItemSeparatorStyle]} /> */}
          </View>
        );
      }
    }

    if (item.type === 'index') {
      return (
        <View style={[styles.itemContainer, styles.itemIndexContainer]}>
          <Text style={styles.itemIndexText}>{item.name}</Text>
        </View>
      );
    }

    const {
      ItemContainerStyle,
      // ListItemSeparatorStyle,
    } = this.props;

    const checked = this.isChecked(item);

    const name = item.displayName || item.name;

    return (
      <View style={itemContainerStyles}>
        <CheckBoxListItem
          name={name}
          item={item}
          isIcon={this.state.isIcon}
          checked={checked}
          disabled={item.isSoldout}
          onPress={() => this.onSelect(item)}
          ItemNameComponent={(itemName, checked) => this.renderItemName(itemName, checked)}
          containerStyle={[styles.itemContentContainer, ItemContainerStyle]}
          />
        {/* <View style={[styles.itemSeparator, ListItemSeparatorStyle]} /> */}
    </View>
    );
  }

  componentDidMount(){
    const {
      itemList
    } = this.props;
    const isIcon = itemList.find((item) => item.image && item.image.length > 0);
    if(isIcon){
      this.setState({isIcon : true});
    }
  }

  render() {
    const {
      containerStyle,
      ListFooterComponent,
    } = this.props;
    const data = this.getData();
    const stickyHeaderIndices = [];

    data.forEach((item, index) => {
      if (item.type === 'index') {
        stickyHeaderIndices.push(index);
      }
    })

    // console.log(`ListWithCheckBox data=${JSON.stringify(data)}`);

    return (
      <FlatList
        keyExtractor={(item, index) => `${index}`} // eslint-disable-line no-underscore-dangle
        data={data}
        stickyHeaderIndices={stickyHeaderIndices}
        renderItem={({ item }) => this.renderItem(item)}
        style={[styles.container, containerStyle]}
        bounces={false}
        ListFooterComponent={ListFooterComponent}
        ListHeaderComponent={<View style={{ height: 0.5 }} />}
        />
    );
  }
}

ListWithCheckBox.propTypes = {
  searchKeyword: PropTypes.string,
  enableIndex: PropTypes.bool,
  onChangeSelectedItemList: PropTypes.func,
  sortItemByName: PropTypes.bool,
};

ListWithCheckBox.defaultProps = {
  searchKeyword: '',
  enableIndex: false,
  onChangeSelectedItemList: () => null,
  sortItemByName: false,
};

export default ListWithCheckBox;
