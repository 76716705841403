import React from 'react';
import {
  View, Text, StyleSheet, TouchableHighlight,
// eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-native';

import CustomDialog from '../components/CustomDialog';
import CloudinaryImage from './CloudinaryImage';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';


const styles = StyleSheet.create({
  heartistContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  profileImageContainer: {
    justifyContent: 'center',
  },
  profileInfoContainer: {
    flex: 1,
    paddingLeft: 10,
    paddingRight: 30,
    flexDirection: 'column',
  },
  buttonContainer: {
    paddingTop: 13,
  },
  button: {
    height: 32,
    width: 66,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  buttonUnfollow: {
    backgroundColor: 'black',
  },
  buttonFollow: {
    backgroundColor: Colors.semiGray,
  },
  buttonText: {
    height: 16,
    lineHeight: 16,
    marginTop: 2,
    fontSize: 13,
    ...Styles.appleSDGothicNeoBold,
  },
  buttonTextUnfollow: {
    color: 'white',
  },
  buttonTextFollow: {
    color: 'black',
  },
  profileUsernameText: {
    ...Styles.SFUITextSemiBold,
    fontSize: 15,
    color: 'black',
    height: 18,
    marginTop: 11,
  },
  profileDetailText: {
    ...Styles.appleSDGothicNeoRegular,
    fontSize: 13,
    color: 'black',
    height: 16,
    marginTop: 4,
  },
});

const stylesHtml = {
  profileImage: {
    border: `1px solid ${Colors.profileImageBorder}`,
    borderRadius: 32,
    height: 64,
    width: 64,
  },
};

class MyPageHeartist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoginDialog: false,
    };
  }

  onPressFollowing = heartist => {
    const { auth, followUser, unfollowUser } = this.props;
    const { token } = auth;

    if (!auth || !token) {
      this.setState({ showLoginDialog: true });
      return;
    }
    if (heartist.following) {
      unfollowUser(heartist._id);
    } else {
      followUser(heartist._id);
    }
  }

  render() {
    const { router, heartist } = this.props;
    const { showLoginDialog } = this.state;

    const onPressHeartist = () => router.push({
      pathname: `/heartists/${heartist.username}`,
    });

    const { feedCount = 0 } = heartist;
    // const subText = heartist.oneLineSummary || heartist.username;
    const subText = `게시물 ${feedCount}개`;

    return (
      <TouchableHighlight
        underlayColor={Colors.touchEffectColorWhite}
        onPress={onPressHeartist}
      >
        <View
          style={styles.heartistContainer}
        >
          <View
            style={styles.profileImageContainer}
          >
            <CloudinaryImage
              options={{
                type: 'instagram_name',
                default_image: 'default-profile.jpg',
              }}
              publicId={heartist.username}
              style={stylesHtml.profileImage}
            />
          </View>
          <View style={styles.profileInfoContainer}>
            <Text style={styles.profileUsernameText}>
              {heartist.username}
            </Text>
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={styles.profileDetailText}
            >
              {subText}
            </Text>
          </View>
          <View style={styles.buttonContainer}>
            <View style={(heartist.following) ? styles.buttonUnfollow : styles.buttonFollow}>
              <TouchableHighlight
                style={styles.button}
                underlayColor={(heartist.following) ? Colors.touchEffectColorBlack : Colors.touchEffectColorWhite}
                onPress={() => this.onPressFollowing(heartist)}
              >
                <Text
                  style={[
                    styles.buttonText,
                    (heartist.following) ? styles.buttonTextUnfollow : styles.buttonTextFollow,
                  ]}
                >
                  {(heartist.following) ? '팔로잉' : '팔로우'}
                </Text>
              </TouchableHighlight>
            </View>
          </View>

          <CustomDialog
            dialogOpen={showLoginDialog}
            onConfirm={() => {
              router.push({
                pathname: '/accounts/login',
              });
            }}
            onCancel={() => this.setState({ showLoginDialog: false })}
            onClose={() => this.setState({ showLoginDialog: false })}
            description={`${heartist.username} 하티스트를 팔로우하려면 로그인 하시기 바랍니다.`}
          />
        </View>
      </TouchableHighlight>
    );
  }
}

export default MyPageHeartist;
