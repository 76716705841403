import assign from 'lodash/assign';
import get from 'lodash/get';
import set from 'lodash/set';
import { normalize } from 'normalizr';

import createFetchAction from '../util/createFetchAction';
import * as schemas from '../schemas';
import { getNextPageUrl } from '../util';

export function loadNotices(nextPage, count) {
  const key = 'notices';
  return createFetchAction({
    type: 'LOAD_NOTICES',
    endpoint: state => {
      const base = count ? `/api/v2/notice?count=${count}` : '/api/v2/notice';
      const next = get(state.pagination, key, {}).nextPageUrl || base;
      return nextPage ? next : base;
    },
    transform: ({ json, response }) => assign(normalize(json, schemas.notices), {
      nextPageUrl: getNextPageUrl(response),
    }),
    request: {
      payload: { isFetching: true },
      pagination: { key },
    },
    success: {
      pagination: { key, type: nextPage ? 'APPEND' : 'REFRESH' },
    },
  });
}

export function loadNotice(noticeId) {
  return createFetchAction({
    type: 'LOAD_NOTICE',
    endpoint: `/api/v2/notice/${noticeId}`,
    doDispatch: false,
  });
}
