import React from 'react';
import { StyleSheet, Text, TouchableHighlight } from 'react-native';

import Colors from '../constants/Colors';

export default ({
  containerStyle, disabled, onPress, title, titleStyle,
  underlayColor = Colors.touchEffectColorWhite,
}) => (
  <TouchableHighlight
    disabled={disabled}
    onPress={onPress}
    underlayColor={underlayColor}
    style={[styles.container, containerStyle, disabled ? styles.disabled : null]}
  >
    <Text style={[styles.title, titleStyle]}>{title}</Text>
  </TouchableHighlight>
);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    borderColor: 'dimgrey',
    borderWidth: 1,
    padding: 13,
  },
  title: {
    color: 'dimgrey',
    fontSize: 13,
  },
  disabled: {
    opacity: 0.45,
  },
});
