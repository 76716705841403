import { camelCase } from 'lodash';
import React from 'react';
import { StyleSheet, View, ScrollView, Image, Text, TextInput, Switch } from 'react-native';
import { connect } from 'react-redux';
import { actions } from 'shared';
import { isEmail } from 'validator';
import { Snackbar } from '@material-ui/core';

import Button from '../nativeComponents/Button';
import Colors from '../constants/Colors';

export class FindPasswordWithEmailscreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      snackbarMsg: '',
      snackbarOpen: false,
    };
  }

  componentDidMount() {
    const { auth, router } = this.props;
    if (auth && auth.token) {
      router.push('/');
    }
  }

  async handleFindPassword() {
    const { sendFindPassword } = this.props;
    const { email } = this.state;

    let msg;
    try {
      await sendFindPassword(email);
      msg = '비밀번호 찾기 메일을 보냈습니다. 메일을 확인하세요.';
    } catch (e) {
      if (e.status === 404) {
        msg = '가입되지 않은 이메일입니다. 확인 후 다시 시도하세요.';
      } else {
        msg = '비밀번호 찾기 메일을 보내는데 실패했습니다. 잠시 후 다시 시도하세요.';
      }
    }
    this.showSnackbar(msg);
  }

  showSnackbar(msg) {
    this.setState({
      snackbarMsg: msg,
      snackbarOpen: true,
    });
  }


  createInputCheckMsg() {
    const { email } = this.state;
    let msg;
    if (!email || email === '') {
      msg = '이메일을 입력하세요.';
    } else if (!isEmail(email)) {
      msg = '이메일 형식을 확인하세요.';
    }
    return msg;
  }

  async handleAll() {
    const { email } = this.state;
    const msg = await this.createInputCheckMsg();
    if (msg) {
      this.showSnackbar(msg);
      return;
    }
    if (email && isEmail(email)) {
      this.handleFindPassword();
    }
  }

  render() {
    const { router } = this.props;
    const { email } = this.state;

    return (
      <View style={styles.container}>
        <ScrollView
          contentContainerStyle={styles.inputContainer}
          keyboardShouldPersistTaps="handled"
          scrollEnabled={false}
        >
          <Text style={styles.findPasswordTitleText}>Find Password</Text>
          <TextInput
            autoCapitalize="none"
            keyboardType="email-address"
            onChangeText={val => this.setState({ email: val })}
            placeholder="이메일 / EMAIL"
            style={[
              styles.inputBorder,
              styles.inputText,
              email && !isEmail(email) ? styles.emailError : null,
            ]}
            value={email}
            autoFocus={true}
          />
          <Button
            containerStyle={
              email ? styles.loginButton: styles.disabled
            }
            onPress={() => this.handleAll()}
            title="비밀번호 찾기 / FIND PASSWORD"
            titleStyle={styles.findPasswordText}
          />
          <Button
            containerStyle={styles.returnToLoginButton}
            onPress={() => router.push('/accounts/login')}
            title="이메일로 로그인하기 / LOGIN WITH EMAIL"
          />
        </ScrollView>
        <Snackbar
          autoHideDuration={2000}
          message={this.state.snackbarMsg}
          onClose={() => this.setState({ snackbarOpen: false })}
          open={this.state.snackbarOpen}
          style={{ textAlign: 'center' }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    flex: 1,
    justifyContent: 'space-between',
    margin: 25,
  },
  disabled: {
    backgroundColor: '#cccccc',
    borderColor: 'lightgrey',
    marginTop: 15,
    width: '100%',
  },
  inputContainer: {
    alignItems: 'center',
  },
  findPasswordTitleText: {
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 20,
  },
  inputText: {
    fontSize: 13,
    padding: 10,
  },
  inputBorder: {
    borderColor: 'lightgrey',
    borderWidth: 1,
    marginTop: 12,
    marginBottom: 12,
    width: '100%',
  },
  loginButton: {
    backgroundColor: 'black',
    marginTop: 15,
    width: '100%',
  },
  findPasswordText: {
    color: 'white',
  },
  emailError: {
    borderColor: 'red',
  },
  returnToLoginButton: {
    marginTop: 15,
    width: '100%',
  },
});

export default connect(
  state => ({ auth: state.auth }),
  actions,
)(FindPasswordWithEmailscreen);
